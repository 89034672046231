import React, { useState, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

import { stylesToCamelCase } from "../../../utils/StylesToString";
import InteractionModel from "../../../models/Interaction";

import { closestInteraction, closestBlockingInteraction, showNextInteraction, setUniqId} from "../utils/selection";

import { Button, Box } from "@mantine/core";

import { wideOrNarrow } from "../../../utils/PageWidth";

import tinycolor from "tinycolor2";

export default function InteractiveButton(props) {
  const navigate = useNavigate();
  const params = useParams();

  const auxPos = useRef();
  const [interacted, setInteracted] = useState(false);
  const interaction = useRef(null);
  const blockingInteraction = useRef(null);

  //setUniqId(props.editor, props.node, props.getPos);

  const getRadius = () => {
    if (props.node.attrs.radius == "no-radius") {
      return "xs";
    } else if (props.node.attrs.radius == "circle-radius") {
      return "xl";
    } else if (props.node.attrs.radius == "with-radius") {
      return "sm";
    } else {
      return "xs";
    }
  };

  const displayToTheUser = () => {
    if (window.$spaceUserInteractions) {
      if (window.$spaceUserInteractions.can_manage) {
        auxPos.current = "manage";
        return true;
      }

      blockingInteraction.current = closestBlockingInteraction(
        props.editor,
        props.getPos(),
        true
      );
      if (blockingInteraction.current) {
        auxPos.current = blockingInteraction.interactivePosition;
      }

      if (
        blockingInteraction.current != null &&
        window.$spaceUserInteractions.position < blockingInteraction.current.interactivePosition
      ) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const isActiveToTheUser = () => {
    if (window.$spaceUserInteractions) {
      if (window.$spaceUserInteractions.can_manage) {
        return true;
      }

      interaction.current = closestInteraction(
        props.editor,
        props.getPos(),
        true
      );

      blockingInteraction.current = closestBlockingInteraction(
        props.editor,
        props.getPos(),
        true
      );
      
      return (
        (!interaction.current) ||
        (interaction.current != null &&
          (window.$spaceUserInteractions.position ==
            interaction.current.interactivePosition) || (blockingInteraction.current != null && window.$spaceUserInteractions.position >= blockingInteraction.current.interactivePosition))
      );
    }
    return false;
  };

  const isInteractedWith = () => {
    if (window.$spaceUserInteractions) {
      if (window.$spaceUserInteractions.can_manage) {
        return false;
      }
      try {

        interaction.current = closestInteraction(
          props.editor,
          props.getPos(),
          true
        );

        return (
          window.$spaceUserInteractions.position >=
            props.node.attrs.interactivePosition
        );
      } catch {
        return false;
      }
    }
    return false;
  };

  const onSave = () => {
    if (props.editor && !props.editor.isEditable) {
      if (window.$spaceUserInteractions.can_manage) {
        return;
      }
      if (!window.$spaceUserInteractions.logged_in) {
        localStorage.setItem("join_slug", params.slug);
        navigate(`/user/start?s=${params.slug}`);
        return;
      }
      InteractionModel.onCreate(
        params.slug,
        {
          view_id: `circle-content-${params.slug}`,
          position: props.node.attrs.interactivePosition,
        },
        (data) => {
          window.$spaceUserInteractions = {
            count: window.$spaceUserInteractions
            ? window.$spaceUserInteractions.count
            : 1,
            position: props.node.attrs.interactivePosition,
            logged_in: window.$spaceUserInteractions
              ? window.$spaceUserInteractions.logged_in
              : false,
          };
          showNextInteraction(props.editor, props.getPos());
          setInteracted(true);
          if (data.auto_joined) {
            // setTimeout(() => {
            //   navigate(`/space/${params.slug}?refresh=true`);
            // }, 1000)
          }
        },
        (data) => {}
      );
    }
  };

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      style={{
        maxWidth: wideOrNarrow(),
      }}
      isActiveToTheUser={isActiveToTheUser() ? "yes" : "no"}
      isInteractedWith={isInteractedWith() ? "yes" : "no"}
      displayToTheUser={displayToTheUser() ? "yes" : "no"}
      userPosition={window.$spaceUserInteractions.position}
      interactivePosition={props.node.attrs.interactivePosition}
    >      
      {!(displayToTheUser() && (isInteractedWith() || interacted)) && (
        <NodeViewContent
          contentEditable="false"
          style={{
            ...stylesToCamelCase(props.node.attrs.styles),
            textAlign: props.node.attrs.textAlign,
          }}
          className={`node-content ${props.node.attrs.class}`}
          radius={props.node.attrs.radius}
        >
          <form>
            {props.editor && props.editor.isEditable ? (
              <Button
                fullWidth
                radius={getRadius()}
                sx={(theme) => ({
                  backgroundColor: props.node.attrs.buttonBackgroundColor,
                  color: props.node.attrs.buttonTextColor,
                  border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                  "&:hover": {
                    backgroundColor: tinycolor(
                      props.node.attrs.buttonBackgroundColor
                    )
                      .darken(5)
                      .toString(),
                  },
                })}
              >
                {`${props.node.attrs.buttonText}`}
              </Button>
            ) : (
              (displayToTheUser() || isActiveToTheUser()) &&
              !interacted && (
                <Button
                  onClick={() => onSave()}
                  fullWidth
                  radius={getRadius()}
                  sx={(theme) => ({
                    backgroundColor: props.node.attrs.buttonBackgroundColor,
                    color: props.node.attrs.buttonTextColor,
                    border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                    "&:hover": {
                      backgroundColor: tinycolor(
                        props.node.attrs.buttonBackgroundColor
                      )
                        .darken(5)
                        .toString(),
                    },
                  })}
                >
                  {`${props.node.attrs.buttonText}`}
                </Button>
              )
            )}
          </form>
        </NodeViewContent>
      )}
      {displayToTheUser() && (isInteractedWith() || interacted) && (
        <Box
          sx={(theme) => ({
            borderTop: `1px solid ${theme.colors.gray[4]}`,
            marginTop: 20,
            marginBottom: 20,
          })}
        >
        </Box>
      )}
    </NodeViewWrapper>
  );
}
