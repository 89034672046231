import { useTranslation } from "react-i18next";

const premiumMessages = () => {
  const { t } = useTranslation();

  return {
    can_edit_shareable_link: {
      title: t("premiumMessages.can_edit_shareable_link.title"),
      plan: "professional",
      message: t("premiumMessages.can_edit_shareable_link.message"),
    },

    can_upload_cover: {
      title: t("premiumMessages.can_upload_cover.title"),
      plan: "personal",
      message: t("premiumMessages.can_upload_cover.message"),
    },

    can_upload_logo: {
      title: t("premiumMessages.can_upload_logo.title"),
      plan: "professional",
      message: t("premiumMessages.can_upload_logo.message"),
    },

    can_set_layout: {
      title: t("premiumMessages.can_set_layout.title"),
      plan: "professional",
      message: t("premiumMessages.can_set_layout.message"),
    },

    can_set_theme: {
      title: t("premiumMessages.can_set_theme.title"),
      plan: "professional",
      message: t("premiumMessages.can_set_theme.message"),
    },

    can_set_format: {
      title: t("premiumMessages.can_set_format.title"),
      plan: "professional",
      message: t("premiumMessages.can_set_format.message"),
    },

    can_add_step_send_to_pending: {
      title: t("premiumMessages.can_add_step_send_to_pending.title"),
      plan: "personal",
      message: t("premiumMessages.can_add_step_send_to_pending.message"),
    },

    can_add_step_send_email: {
      title: t("premiumMessages.can_add_step_send_email.title"),
      plan: "personal",
      message: t("premiumMessages.can_add_step_send_email.message"),
    },

    can_add_add_to_existing_space: {
      title: t("premiumMessages.can_add_add_to_existing_space.title"),
      plan: "professional",
      message: t("premiumMessages.can_add_add_to_existing_space.message"),
    },

    can_add_step_wait: {
      title: t("premiumMessages.can_add_step_wait.title"),
      plan: "professional",
      message: t("premiumMessages.can_add_step_wait.message"),
    },

    can_add_step_webhook: {
      title: t("premiumMessages.can_add_step_webhook.title"),
      plan: "professional",
      message: t("premiumMessages.can_add_step_webhook.message"), 
    },

    can_add_step_survey_person: {
      title: t("premiumMessages.can_add_step_survey_person.title"),
      plan: "professional",
      message: t("premiumMessages.can_add_step_survey_person.message"),
    },

    can_schedule_states: {
      title: t("premiumMessages.can_schedule_states.title"),
      plan: "professional",
      message: t("premiumMessages.can_schedule_states.message"),
    },

    can_broadcast: {
      title: t("premiumMessages.can_broadcast.title"),
      plan: "personal",
      message: t("premiumMessages.can_broadcast.message"),
    },

    can_survey: {
      title: t("premiumMessages.can_survey.title"),
      plan: "professional",
      message: t("premiumMessages.can_survey.message"),
    },

    can_use_interest_form_block: {
      title: t("premiumMessages.can_use_interest_form_block.title"),
      plan: "professional",
      message: t("premiumMessages.can_use_interest_form_block.message"),
    },

    can_set_flexible_payments: {
      title: t("premiumMessages.can_set_flexible_payments.title"),
      plan: "professional",
      message: t("premiumMessages.can_set_flexible_payments.message"),
    },

    can_activate_completions: {
      title: t("premiumMessages.can_activate_completions.title"),
      plan: "professional",
      message: t("premiumMessages.can_activate_completions.message"),
    },

    max_allowed_spaces: {
      title: t("premiumMessages.max_allowed_spaces.title"),
      plan: "personal",
      message: t("premiumMessages.max_allowed_spaces.message"),
    },

    max_allowed_ij: {
      title: t("premiumMessages.max_allowed_ij.title"),
      plan: "professional",
      message: t("premiumMessages.max_allowed_ij.message"),
    },

    max_allowed_managers_pro: {
      title: t("premiumMessages.max_allowed_managers_pro.title"),
      plan: "professional",
      message: t("premiumMessages.max_allowed_managers_pro.message"),
    },

    max_allowed_managers_org: {
      title: t("premiumMessages.max_allowed_managers_org.title"),
      plan: "organization",
      message: t("premiumMessages.max_allowed_managers_org.message"),
    },

    can_see_participation_tab: {
      title: t("premiumMessages.can_see_participation_tab.title"),
      plan: "professional",
      message: t("premiumMessages.can_see_participation_tab.message"),
    },

    can_send_selective_broadcast: {
      title: t("premiumMessages.can_send_selective_broadcast.title"),
      plan: "organization",
      message: t("premiumMessages.can_send_selective_broadcast.message"),
    },

    can_send_message_broadcast: {
      title: t("premiumMessages.can_send_message_broadcast.title"),
      plan: "organization",
      message: t("premiumMessages.can_send_message_broadcast.message"),
    },

    can_add_from_children: {
      title: t("premiumMessages.can_add_from_children.title"),
      plan: "organization",
      message: t("premiumMessages.can_add_from_children.message"),
    },

    can_add_to_direct_open_children: {
      title: t("premiumMessages.can_add_to_direct_open_children.title"),
      plan: "organization",
      message: t("premiumMessages.can_add_to_direct_open_children.message"),
    },

    can_send_message: {
      title: t("premiumMessages.can_send_message.title"),
      plan: "organization",
      message: t("premiumMessages.can_send_message.message"),
    },

    can_use_interactive_block: {
      title: t("premiumMessages.can_use_interactive_block.title"),
      plan: "professional",
      message: t("premiumMessages.can_use_interactive_block.message"),
    },

    can_save_templates: {
      title: t("premiumMessages.can_save_templates.title"),
      plan: "professional",
      message: t("premiumMessages.can_save_templates.message"),
    },

    can_duplicate_spaces: {
      title: t("premiumMessages.can_duplicate_spaces.title"),
      plan: "professional",
      message: t("premiumMessages.can_duplicate_spaces.message"),
    },

    can_create_quizzes: {
      title: t("premiumMessages.can_create_quizzes.title"),
      plan: "organization",
      message: t("premiumMessages.can_create_quizzes.message"),
    },

    can_auto_tag: {
      title: t("premiumMessages.can_auto_tag.title"),
      plan: "organization",
      message: t("premiumMessages.can_auto_tag.message"),
    },

    can_use_zoom_recordings: {
      title: t("premiumMessages.can_use_zoom_recordings.title"),
      plan: "organization",
      message: t("premiumMessages.can_use_zoom_recordings.message"),
    },
  };
};

export default premiumMessages;
