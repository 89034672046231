import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import InteractiveInput from '../components/InteractiveInput';
import {stylesToString} from '../../../utils/StylesToString'

import {v4 as uuidv4} from 'uuid';

const InteractiveInputNode = Node.create({
  name: 'interactiveInput',
  draggable: false,
  allowGapCursor: true,
  selectable: true,
  allowGapCursor: false,
  group: "block",
  inline: false,

  addAttributes() {
    return {
      interactiveInputPlaceholder: {
        default: "Write something...",
      },
      interactiveInputLabel: {
        default: "https://sutra.co",
      },
      interactiveInputShowAll: {
        default: true,
      },
      interactiveInputAllowMultipost: {
        default: false,
      },
      interactiveInputAllowSkip: {
        default: false,
      },
      interactiveInputShowAllManager: {
        default: true,
      },
      interactiveInputPreShowAll: {
        default: false,
      },
      interactiveInputWithPrivacyCheck: {
        default: true,
      },
      interactiveInputNotificationOnEachPost: {
        default: false,
      },
      interactiveAdvancedEngagement: {
        default: true,
      },
      showProgressBar: {
        default: true,
      },
      autoTag: {
        default: false,
      },
      idName: {
        default: "interactive-input-node",
      },
      id: {
        default: null,
      },
      circle_id: {
        default: null,
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "10px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: attributes => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        })
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'interactiveinput',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {

    return ['interactiveinput', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addNodeView() {
    return ReactNodeViewRenderer(InteractiveInput)

  },
})

export default InteractiveInputNode;
