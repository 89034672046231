import React, { useState, useEffect, useRef } from "react";
import {
  Popover,
  Button,
  Group,
  ActionIcon,
  ColorSwatch,
  createStyles,
} from "@mantine/core";

import { X, ChevronDown } from "tabler-icons-react";

import TagModel from "../../../../models/SpaceTag";
import useTagColors from "../../../../collections/TagColors";

const useStyles = createStyles((theme) => ({
  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    height: 20,
    paddingRight: 2,
    paddingLeft: 3,
  },

  menuControl2: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    height: 20,
    paddingRight: 10,
    paddingLeft: 3,
  },

  removeTag: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

let manualUpdate = false;

export default function TagButton(props) {
  const mount = useRef();
  const { classes, theme } = useStyles();
  const [colorPicker, setColorPicker] = useState(false);
  const [name, setName] = useState("");
  const [color, setColor] = useState();
  const [tagId, setTagId] = useState(null);

  useEffect(() => {
    if (!props.tagId) {
      onCreate();
    }
  }, []);

  useEffect(() => {
    setName(props.name);
    setColor(swatches[props.color]);
    setTagId(props.tagId);
  }, [props]);

  useEffect(() => {
    if (tagId && mount.current && manualUpdate) {
      manualUpdate = false;
      onUpdate();
    } else {
      mount.current = true;
    }
  }, [color]);

  const onCreate = () => {
    if (props.action == "create") {
      TagModel.onCreate(
        props.spaceId,
        {name: props.name},
        (data) => {
          setTagId(data.id);
          props.onCreate();
        },
        (data) => {}
      );
    } else if (props.action == "tag") {
      TagModel.onTag(
        props.spaceId,
        props.name,
        (data) => {
          setTagId(data.id);
          props.onCreate();
        },
        (data) => {}
      );
    }
    else{
      // TagModel.onFetchByName(
      //   props.spaceId,
      //   props.name,
      //   (data) => {
      //     setTagId(data.id);
      //     props.onCreate({name: data.name, color: data.color, action: "add"});
      //   },
      //   (data) => {}
      // );
    }
  };

  const onUpdate = () => {
    if (props.action == "create") {
      TagModel.onUpdate(
        props.spaceId,
        tagId,
        { name, color: swatches.indexOf(color) },
        (data) => {
          props.onUpdate();
        },
        (data) => {}
      );
    } 
  };

  const onDestroy = () => {
    if (props.action == "create") {
      TagModel.onDestroy(
        props.spaceId,
        tagId,
        (data) => {
          props.onDestroy(name);
        },
        (data) => {}
      );
    } else if (props.action == "tag") {
      TagModel.onUntag(
        props.spaceId,
        props.name,
        (data) => {
          setTagId(data.id);
          props.onCreate();
        },
        (data) => {}
      );
    }
    else{
      props.onDestroy(name);
    }
  };

  const onUpdateColor = (_color) => {
    manualUpdate = true;
    setColor(_color);
  };

  const swatches = [
    theme.other.tagColors[0].backgroundColor,
    theme.other.tagColors[1].backgroundColor,
    theme.other.tagColors[2].backgroundColor,
    theme.other.tagColors[3].backgroundColor,
    theme.other.tagColors[4].backgroundColor,
    theme.other.tagColors[5].backgroundColor,
    theme.other.tagColors[6].backgroundColor,
    theme.other.tagColors[7].backgroundColor,
    theme.other.tagColors[8].backgroundColor,
    theme.other.tagColors[9].backgroundColor,
    theme.other.tagColors[10].backgroundColor,
    theme.other.tagColors[11].backgroundColor,
    theme.other.tagColors[12].backgroundColor,
    theme.other.tagColors[13].backgroundColor,
  ];

  return (
    <Group noWrap spacing={0}>
      <ActionIcon
        variant="filled"
        styles={{
          filled: {
            backgroundColor:
              theme.other.tagColors[useTagColors(color)].backgroundColor,
            "&:hover": {
              backgroundColor:
                theme.other.tagColors[useTagColors(color)].hoverColor,
            },
            color: theme.other.tagColors[useTagColors(color)].textColor,
          },
        }}
        size={20}
        radius="xl"
        className={classes.removeTag}
        onClick={() => onDestroy()}
      >
        <X size={12} />
      </ActionIcon>
        {props.action == "create" ? (

        <Popover
          zIndex={99999}
          opened={colorPicker}
          onClose={() => setColorPicker(false)}
          styles={{
            target: {
              lineHeight: 1,
            },
            inner: {
              paddingTop: 0,
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 5,
            },
          }}
          target={
            <Button
              className={classes.menuControl}
              sx={(theme) => ({
                backgroundColor:
                  theme.other.tagColors[useTagColors(color)].backgroundColor,
                "&:hover": {
                  backgroundColor:
                    theme.other.tagColors[useTagColors(color)].hoverColor,
                },
              })}
              onClick={() => setColorPicker(!colorPicker)}
              styles={{
                rightIcon: {
                  marginLeft: 2,
                  color: theme.other.tagColors[useTagColors(color)].textColor,
                },
                label: {
                  fontSize: 10,
                  color: theme.other.tagColors[useTagColors(color)].textColor,
                },
              }}
              radius="xl"
              size="xs"
              rightIcon={<ChevronDown size={16} />}
            >
              {props.name}
            </Button>
          }
          width={240}
          position="bottom"
          placement="end"
          withArrow
        >
          <Group spacing={5} position="center" pt={10} pb={10}>
            {swatches.map((_color) => (
              <ColorSwatch
                key={_color}
                color={_color}
                component="button"
                onClick={() => {
                  onUpdateColor(_color);
                }}
                style={{ cursor: "pointer" }}
              />
            ))}
          </Group>
        </Popover>
        ) : (
          <Button
              className={classes.menuControl2}
              sx={(theme) => ({
                backgroundColor:
                  theme.other.tagColors[useTagColors(color)].backgroundColor,
                "&:hover": {
                  backgroundColor:
                    theme.other.tagColors[useTagColors(color)].hoverColor,
                },
              })}
              onClick={() => setColorPicker(!colorPicker)}
              styles={{
                rightIcon: {
                  marginLeft: 2,
                  color: theme.other.tagColors[useTagColors(color)].textColor,
                },
                label: {
                  fontSize: 10,
                  color: theme.other.tagColors[useTagColors(color)].textColor,
                },
              }}
              radius="xl"
              size="xs"
            >
              {props.name}
            </Button>
        )}
    
    </Group>
  );
}
