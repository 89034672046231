import { Node, mergeAttributes } from "@tiptap/core";

import { setResizeable } from "../utils/setResizeable";
import { setLazyLoad } from "../utils/setLazyLoad";
import { checkInteractionAndHideIfNeeded, setUniqId} from "../utils/selection";
import { stylesToString } from "../../../utils/StylesToString";
import { wideOrNarrow } from "../../../utils/PageWidth";
import Broadcaster from "../../../helpers/Broadcaster";

export const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

const EmbedNode = Node.create({
  name: "embed",
  draggable: false,
  selectable: true,
  allowGapCursor: false,
  group: "block columnable",
  inline: false,

  addAttributes() {
    return {
      videoId: {
        default: "",
      },
      src: {
        default: null,
      },
      controls: {
        default: true,
      },
      viewAs: {
        default: "inline",
      },
      link: {
        default: {
          url: null,
          target: "_modal",
          title: "",
          description: "",
          image: {
            url: "",
          },
          thumbnail: {
            url: "",
          },
        },
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "0px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: (attributes) => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "embed",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "embed",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setEmbed:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("section");
      const span = document.createElement("div");
      if (!node.attrs.viewAs || node.attrs.viewAs == "inline") {
        span.style.maxWidth = wideOrNarrow();
        span.classList.add("embed-node");

        const resize = document.createElement("div");

        resize.style.display = "inline-block";

        const iframe = document.createElement("iframe");
        const attributes = mergeAttributes(HTMLAttributes, {});

        span.setAttribute("id", attributes["data-uid"]);

        Object.entries(attributes).forEach(([key, value]) =>
          dom.setAttribute(key, value)
        );

        const percWidth = parseFloat(
          attributes.resizeableWidth.replace("%", "")
        );
        let iframeHeight = 400;
        let divider = 1;

        const resolvedPos = editor.view.state.doc.resolve(getPos());

        if (resolvedPos.depth > 1) {
          divider = 12.0 / resolvedPos.path[6].attrs.size;
        }

        const videoWrapper = document.createElement("div");
        const prosemirrorInline = $(editor.view.dom).parents(
          "#tiptap-inline-container"
        );

        const nodeResource = $(editor.view.dom).parents(".node-resource");

        setTimeout(
          () => {
            if (prosemirrorInline.length == 0) {
              if ($(window).width() > 768) {
                iframeHeight =
                  (wideOrNarrow("num") * percWidth) / 100 / divider / 1.778;
              } else {
                iframeHeight =
                  ($(window).width() * percWidth) / 100 / divider / 1.778;
              }
            } else {
              if (
                nodeResource.length > 0 &&
                window.$resourceNodeWidth &&
                $(prosemirrorInline).width() == 0
              ) {
                iframeHeight =
                  (window.$resourceNodeWidth * percWidth) /
                  100 /
                  divider /
                  1.778;
              } else {
                iframeHeight =
                  ($(prosemirrorInline).width() * percWidth) /
                  100 /
                  divider /
                  1.778;

                if (
                  nodeResource.length > 0 &&
                  $(prosemirrorInline).width() != 0
                ) {
                  window.$resourceNodeWidth = $(prosemirrorInline).width();
                }
              }
            }

            if (attributes.resizeableWidth) {
              resize.style.width = attributes.resizeableWidth;
              resize.style.height = "100%";
              //span.style.height = `${Math.round(iframeHeight)}px`;
            }

            // iframe.setAttribute("width", "100%")
            // iframe.setAttribute("height", `${Math.round(iframeHeight)}px`)
            // iframe.setAttribute("height", `100%`)
            // iframe.setAttribute("src", attributes.src)
            iframe.setAttribute("allowfullscreen", true);
            iframe.setAttribute("fullscreen", true);
            iframe.setAttribute("picture-in-picture", true);
            iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share");
            
            if (attributes.src && attributes.src.indexOf("sensemaker") >= 0){
              iframe.setAttribute("title", "Embedded Content");
              iframe.setAttribute("allow", "accelerometer; clipboard-write;");
              iframe.setAttribute("type", "dashboard v2 widget");

            }

            // iframe.style.display = "block"
            // iframe.style["object-fit"] = "cover"
            // New
            iframe.style["position"] = "absolute";
            iframe.style["top"] = 0;
            iframe.style["left"] = 0;
            iframe.style["width"] = "100%";
            iframe.style["height"] = "100%";

            if (window.location.pathname.indexOf("/discussion") >= 0) {
              //iframe.style.minHeight = $(window).width() / 2 + "px";
            }
            
            videoWrapper.style.position = "relative";
            videoWrapper.style.paddingBottom = "56.25%";
            videoWrapper.style.paddingTop = 25;
            videoWrapper.style.height = 0;
            videoWrapper.style.overflow = "hidden";
            videoWrapper.style.referrerpolicy = "strict-origin-when-cross-origin";
          },
          nodeResource.length > 0 && !window.$resourceNodeWidth ? 1000 : 0
        );

        
        setLazyLoad(iframe, attributes.src, editor, resize, getPos);

        resize.append(videoWrapper);
        // resize.append(iframe)

        span.append(resize);
        //
        // if (editor.editing){
        const overlay = document.createElement("div");
        overlay.style.height = `${Math.round(iframeHeight)}px`;
        overlay.classList.add("overlay");
        resize.append(overlay);
        // }

        dom.append(span);

        if (editor.isEditable) {
          setTimeout(() => {
            setResizeable(resize, editor, getPos());
          }, 1000);
        }
        setTimeout(() => {
          videoWrapper.append(iframe);
        }, 100);
      } else {
        span.style.maxWidth = wideOrNarrow();
        span.classList.add("fancylink-node");

        const mainContent = document.createElement("div");
        mainContent.classList.add("main-content");

        const imageCont = document.createElement("div");
        const img = document.createElement("img");
        const container = document.createElement("div");
        const title = document.createElement("div");
        title.classList.add("fancylink-title");
        const description = document.createElement("div");
        const attributes = mergeAttributes(HTMLAttributes, {});

        span.setAttribute("id", attributes["data-uid"]);

        Object.entries(attributes).forEach(([key, value]) =>
          dom.setAttribute(key, value)
        );

        img.onload = function () {
          imageCont.style.display = "inline";
        };

        img.onerror = function () {
          imageCont.style.display = "none";
        };

        const image_url_ok = (attributes.link.thumbnail && attributes.link.thumbnail.url) || (attributes.link.image && attributes.link.image.url)

        img.setAttribute(
          "src",
          attributes.link.thumbnail && attributes.link.thumbnail.url
            ? attributes.link.thumbnail.url
            : attributes.link.image && attributes.link.image.url
            ? attributes.link.image.url
            : ""
        );

        title.innerHTML = attributes.link.title;
        description.innerHTML = attributes.link.description;

        imageCont.classList.add("imageCont");
        
          imageCont.append(img);
        

        container.append(title);
        container.append(description);
        if (image_url_ok){
          mainContent.append(imageCont);
        }
        mainContent.append(container);

        span.append(mainContent);

        dom.append(span);

        if (!editor.isEditable) {
          span.addEventListener("click", (event) => {
            console.log("open_VideVideo");
            Broadcaster.send("open_VideVideo", document.body, {
              node: { ...node },
            });
          });
        }
      }

      checkInteractionAndHideIfNeeded(editor, dom, getPos);

      //setUniqId(editor, node, getPos, dom);

      return {
        dom,
        contentDOM: span,
        ignoreMutation(mutation) {
          return true;
        },
      };
    };
  },
});

export default EmbedNode;
