import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import {
  Drawer,
  Button,
  Box,
  Group,
  Image,
  Text,
  Stack,
  Grid,
  Title,
  MantineProvider,
  createStyles,
  ScrollArea,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import UserModel from "../../models/User";
import DirectMessageModel from "../../models/DirectMessage";
import SpaceModel from "../../models/Space";
import DefaultAvatar from "../../src/images/fallback/default.png";
import SpaceListItem from "../spaces/presentations/List";

import SettingsProfile from "../settings/ProfileFormModal";

import { setHiddenSidebar, setTiptapSidemenu } from "../../store/app";

import { Helmet } from "react-helmet";
import { colors } from "../../collections/colors";

import tinycolor from "tinycolor2";

import isSpaceThemed from "../../utils/IsSpaceThemed";

const useStyles = createStyles((theme, { colors }) => ({
  userInfoWrap: {
    paddingBottom: 35,
  },
  avatarWrapMediumScreen: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  avatarMediumScreen: {
    width: 100,
    marginRight: 0,
  },
  avatar: {
    width: 300,
    marginRight: 30,
  },
  userDetailsWrap: {
    alignSelf: "center",
  },
  drawerWrap: {
    backgroundColor: colors.background_color,
  },
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  secondaryButtonStyle: {
    backgroundColor: colors.secondary_button_background_color,
    color: colors.secondary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.secondary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  buttonStyleOutline: {
    backgroundColor: "none",
    color: colors.primary_button_background_color,
    border: `1px solid ${colors.primary_button_background_color}`,
    "&:hover": {
      backgroundColor: tinycolor(colors.background_color).darken(1).toString(),
    },
  },
  themeTextColor: {
    color: colors.default_text_color,
  },
  closeButton: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    "&:hover": {
      backgroundColor: colors.background_color,
      color: colors.primary_button_background_color,
    },
  },
}));

const paginator = {
  per: 30,
  page: 1,
};

export default function ProfileModal(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const colors = useSelector((state) => state.colors);
  const { classes, theme } = useStyles({ colors });
  const smallScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.sm}px)`
  );
  const mediumScreen = true;
  const largeScreen = false;
  const extraLargeScreen = false;

  const user = useSelector((state) => state.user);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const [profileUser, setProfileUser] = useState({});
  const [spaces, setSpaces] = useState([]);
  const [opened, setOpened] = useState(false);
  const [showAllSpaces, setShowAllSpaces] = useState(false);
  const [username, setUsername] = useState();
  const [edit, setEdit] = useState(false);

  const openedFromUrl = useRef();

  useEffect(() => {
    if (username) {
      onFetch();
      onFetchSpaces();
    }
  }, [username]);

  useEffect(() => {
    if (openedFromUrl.current != location.pathname) {
      setOpened(false);
    }
  }, [location]);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "profile") {
      setUsername(tiptapSidemenu.option);
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      setEdit(false);
      if (tiptapSidemenu.opened == "profile") {
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    } else {
      openedFromUrl.current = location.pathname;
    }
  }, [opened]);

  const onFetch = () => {
    UserModel.onFetch(
      username,
      (data) => {
        setProfileUser(data);
      },
      (data) => {}
    );
  };

  const onFetchSpaces = () => {
    const ctrl = new AbortController();
    SpaceModel.onFetchByUser(
      username,
      ["members"],
      paginator,
      (data) => {
        if (paginator.page == 1) {
          setSpaces(data);
        } else {
          setSpaces(spaces.concat(data));
        }
      },
      (data) => {},
      ctrl.signal
    );
  };

  const onDirectMessage = () => {
    if (!user.authenticated) {
      navigate(`/user/start`);
      return;
    }
    DirectMessageModel.onFetchOrCreate(
      profileUser.id,
      (data) => {
        dispatch(
          setTiptapSidemenu({ opened: "direct", option: data.space.slug })
        );
      },
      (data) => {}
    );
  };

  if (!user) {
    return null;
  }

  return (
    <Drawer
      classNames={{
        drawer: classes.drawerWrap,
        closeButton: classes.closeButton,
      }}
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text className={classes.themeTextColor} weight="bold">
          {t("users.profileModal.profile")}
        </Text>
      }
      padding="xl"
      size="xl"
    >
      {edit ? (
        <>
          <Button
            className={classes.buttonStyleOutline}
            variant="outline"
            color="gray"
            radius="xl"
            mb={20}
            leftIcon={<FontAwesomeIcon icon={solid("arrow-left")} />}
            onClick={() => setEdit(false)}
          >
            {"Go back"}
          </Button>
          <SettingsProfile inModal={true} />
        </>
      ) : (
        <ScrollArea style={{ height: "100%" }}>
          <Box style={{ marginBottom: "50px" }} mt={20}>
            <Box
              className={classes.userInfoWrap}
              sx={(theme) => ({
                borderBottom:
                  profileUser.bio && profileUser.bio.length > 0
                    ? `1px solid ${
                      !isSpaceThemed(colors)
                      ? tinycolor(colors.sidebar_background_color).darken(10).toString()
                      : colors.sidebar_background_color
                      }`
                    : profileUser,
              })}
            >
              <Group noWrap>
                <Image
                  className={
                    mediumScreen ? classes.avatarMediumScreen : classes.avatar
                  }
                  radius={300}
                  src={
                    profileUser.avatar ? profileUser.avatar.url : DefaultAvatar
                  }
                  alt={profileUser.username}
                />
                <Box className={classes.userDetailsWrap}>
                  <Stack ml={10} spacing={0}>
                    <Stack spacing={0} mb={10}>
                      <Title
                        className={classes.themeTextColor}
                        order={3}
                        sx={(theme) => ({
                          fontWeight: 900,
                          marginRight: 10,
                          lineHeight: 1,
                        })}
                      >
                        {profileUser.full_name}
                      </Title>
                      <Text
                        className={classes.themeTextColor}
                        color="dimmed"
                        size="xl"
                      >
                        @{profileUser.username}
                      </Text>
                    </Stack>
                    <Group spacing={5}>
                      {profileUser && user.id != profileUser.id ? (
                        <Button
                          className={classes.primaryButtonStyle}
                          radius="xl"
                          size="sm"
                          onClick={() => onDirectMessage()}
                        >
                          {t("users.profileModal.message")}
                        </Button>
                      ) : (
                        <MantineProvider theme={{ primaryShade: 6 }}>
                          <Button
                            className={classes.primaryButtonStyle}
                            radius="xl"
                            size="sm"
                            onClick={() => setEdit(true)}
                          >
                            {t("users.profileModal.editProfile")}
                          </Button>
                        </MantineProvider>
                      )}
                      <MantineProvider theme={{ primaryShade: 6 }}>
                        <Button
                          component={Link}
                          to={`/${profileUser.username}`}
                          className={classes.secondaryButtonStyle}
                          radius="xl"
                          size="sm"
                          onClick={() => setOpened(false)}
                        >
                          {t("users.profileModal.openProfile")}
                        </Button>
                      </MantineProvider>
                    </Group>
                  </Stack>
                </Box>
              </Group>
            </Box>

            {profileUser.bio && profileUser.bio.length > 0 ? (
              <Text className={classes.themeTextColor} mt={30}>
                {profileUser.bio}
              </Text>
            ) : (
              ""
            )}

            {spaces && spaces.length > 0 ? (
              <>
                <Group
                  position="apart"
                  mt={30}
                  mb={15}
                  pb={10}
                  sx={(theme) => ({
                    borderBottom: `1px solid ${
                      !isSpaceThemed(colors)
                      ? tinycolor(colors.sidebar_background_color).darken(10).toString()
                      : colors.sidebar_background_color
                    }`,
                  })}
                >
                  <div>
                    <Title
                      className={classes.themeTextColor}
                      order={3}
                      sx={(theme) => ({ fontWeight: 900 })}
                    >
                      {t("users.profileModal.spaces")}
                    </Title>
                  </div>
                  {spaces.length > 6 && (
                    <MantineProvider theme={{ primaryShade: 8 }}>
                      <Button
                        variant="outline"
                        className={classes.buttonStyleOutline}
                        radius="xl"
                        onClick={() =>
                          setShowAllSpaces(showAllSpaces ? false : true)
                        }
                      >
                        {showAllSpaces ? "See less" : `See all`}
                      </Button>
                    </MantineProvider>
                  )}
                </Group>
                <Box mb={60}>
                  <Grid>
                    {spaces
                      .slice(0, showAllSpaces ? spaces.length : 6)
                      .map((space) => {
                        return (
                          <Grid.Col sm={12}>
                            <SpaceListItem
                              key={`space-list-item-${space.slug}`}
                              space={space}
                            />
                          </Grid.Col>
                        );
                      })}
                  </Grid>
                </Box>
              </>
            ) : (
              ""
            )}
          </Box>
        </ScrollArea>
      )}
    </Drawer>
  );
}
