import React from 'react';

import { Routes, Route } from "react-router-dom";

import SpaceMainActions from '../spaces/header/MainActions'
import SpaceMainActionsNeo from '../spaces_neo/header/MainActions'


export default function HeaderActions(props) {

  return (
    <Routes>
      <Route path="/space/:slug/*" element={<SpaceMainActionsNeo authenticated={props.authenticated} />} />
      <Route path="/space_legacy/:slug/*" element={<SpaceMainActions authenticated={props.authenticated} />} />
      <Route path="/circle/:slug/*" element={<SpaceMainActionsNeo authenticated={props.authenticated} />} />
    </Routes>
  );
}
