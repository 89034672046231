import React, { useState, useEffect, memo, useRef } from "react";
import { Text, Group, Box, Select } from "@mantine/core";

import { useTranslation } from "react-i18next";
import TagButton from "./TagButton";

import TagModel from "../../../../models/SpaceTag";

const Tag = memo((props) => {
  const [tags, setTags] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [error, setError] = useState("");
  const selectRef = useRef()

  const { t } = useTranslation();

  useEffect(() => {
    const tagsNames = props.tags.map((i) => i["name"]);
    if (props.action != "select"){
      setSelectedTags(tagsNames);
    }
    if (props.action == "select" && tags && tags.length == 0){
      onFetch();
    }
    else if (props.action != "select"){
      onFetch();
    }
  }, [props]);

  const onFetch = () => {
    if (props.parent){
      
      TagModel.onFetchParents(
        props.spaceId,
        (data) => {
          setTags([...data]);
        },
        (data) => {}
      );
    }
    else{
      TagModel.onFetch(
        props.spaceId,
        (data) => {
          setTags([...data]);
        },
        (data) => {}
      );
    }
    
  };

  const onAdd = (tag) => {
    if (tag.length > 22){
      setError("Tags must be less than 22 characters")
      return
    }
    setError("")
    if (selectedTags.indexOf(tag) === -1) {
      setSelectedTags(selectedTags.concat(tag));
      if (props.action == "select"){
        props.onUpdatedMessage({name: tag, color: 0, action: "add"});
      }
    }
    setTimeout(() => {
      selectRef.current.blur()
      setSelectValue("");
    }, 200)
  };

  const onRemove = (tag) => {
    const index = selectedTags.indexOf(tag);
    if (index >= 0) {
      const newSelectedTags = [...selectedTags];
      newSelectedTags.splice(index, 1);
      setSelectedTags(newSelectedTags);
      props.onUpdatedMessage({action: "replace", rTags: [...newSelectedTags]});
    }
  };

  const getIdForTagName = (tag) => {
    for (let i = 0; i < props.tags.length; i++) {
      if (props.tags[i].name == tag) {
        return props.tags[i].id;
      }
    }
    return null;
  };

  const getColorForTagName = (tag) => {
    for (let i = 0; i < props.tags.length; i++) {
      if (props.tags[i].name == tag) {
        return props.tags[i].color;
      }
    }
    return null;
  };

  const Item = ({ label, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  );

  return (
    <Box>
      <Box
      >
        <Select
          ref={selectRef}
          zIndex={99999}
          searchable
          placeholder={props.creatable ? t("spaces.presentations.tag.placeholder") : t("spaces.presentations.tag.placeholder2")}
          itemComponent={Item}
          creatable={props.creatable}
          getCreateLabel={(query) =>
            `+ ${t("spaces.presentations.tag.createAction")} ${query}`
          }
          data={tags}
          maxDropdownHeight={400}
          onChange={(tag) => onAdd(tag)}
          value={selectValue}
        />
        {error && <Text color="red" size="xs">{error}</Text>}

      </Box>
      <Group spacing={5}  pt={selectedTags.length > 0 ? 10 : 0} pb={selectedTags.length > 0 ? 10 : 0}>
        {selectedTags.map((tag) => {
          return (
            <TagButton
              type={props.type}
              tagId={getIdForTagName(tag)}
              messageId={props.messageId}
              spaceId={props.spaceId}
              onCreate={props.onUpdatedMessage}
              onUpdate={props.onUpdatedMessage}
              onDestroy={onRemove}
              name={tag}
              color={getColorForTagName(tag)}
              action={props.action}
            />
          );
        })}
      </Group>
    </Box>
  );
})

export default Tag
