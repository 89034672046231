import {
  Slice,
  Fragment,
  DOMParser,
  DOMSerializer,

} from "prosemirror-model";

let serializer = null;

export function serializeForClipboard(view, slice) {
  view.someProp("transformCopied", (f) => {
    slice = f((slice = null), view);
  });

  let context = [],
    { content, openStart, openEnd } = slice;
  while (
    openStart > 1 &&
    openEnd > 1 &&
    content.childCount == 1 &&
    content.firstChild &&
    content.firstChild.childCount == 1
  ) {
    openStart--;
    openEnd--;
    let node = content.firstChild ? content.firstChild : null;
    context.push(
      node.type.name,
      node.attrs != node.type.defaultAttrs ? node.attrs : null
    );
    content = node.content;
  }

  serializer =
    view.someProp("clipboardSerializer") ||
    DOMSerializer.fromSchema(view.state.schema);

  let doc = detachedDoc(),
    wrap = doc.createElement("div");
  wrap.appendChild(serializeFragment(content, { document: doc }));

  let firstChild = wrap.firstChild,
    needsWrap,
    wrappers = 0;
  while (
    firstChild &&
    firstChild.nodeType == 1 &&
    (needsWrap = wrapMap[firstChild.nodeName.toLowerCase()])
  ) {
    for (let i = needsWrap.length - 1; i >= 0; i--) {
      let wrapper = doc.createElement(needsWrap[i]);
      while (wrap.firstChild) wrapper.appendChild(wrap.firstChild);
      wrap.appendChild(wrapper);
      wrappers++;
    }
    firstChild = wrap.firstChild;
  }

  if (firstChild && firstChild.nodeType == 1)
    firstChild.setAttribute(
      "data-pm-slice",
      `${openStart} ${openEnd}${
        wrappers ? ` -${wrappers}` : ""
      } ${JSON.stringify(context)}`
    );

  let text =
    view.someProp("clipboardTextSerializer", (f) => f(slice, view)) ||
    slice.content.textBetween(0, slice.content.size, "\n\n");

  return { dom: wrap, text };
}

let _detachedDoc = null;
function detachedDoc() {
  return (
    _detachedDoc ||
    (_detachedDoc = document.implementation.createHTMLDocument("title"))
  );
}

const wrapMap = {
  thead: ["table"],
  tbody: ["table"],
  tfoot: ["table"],
  caption: ["table"],
  colgroup: ["table"],
  col: ["table", "colgroup"],
  tr: ["table", "tbody"],
  td: ["table", "tbody", "tr"],
  th: ["table", "tbody", "tr"],
};

function doc(options) {
  return options.document || window.document;
}

function serializeFragment(fragment, options = {}, target = null) {
  if (!target) target = doc(options).createDocumentFragment();

  let top = target,
    active = [];
  fragment.forEach((node) => {
    if (active.length || node.marks.length) {
      let keep = 0,
        rendered = 0;
      while (keep < active.length && rendered < node.marks.length) {
        let next = node.marks[rendered];
        if (!serializer.marks[next.type.name]) {
          rendered++;
          continue;
        }
        if (!next.eq(active[keep][0]) || next.type.spec.spanning === false)
          break;
        keep++;
        rendered++;
      }
      while (keep < active.length) top = active.pop()[1];
      while (rendered < node.marks.length) {
        let add = node.marks[rendered++];
        let markDOM = serializeMark(add, node.isInline, options);
        if (markDOM) {
          active.push([add, top]);
          top.appendChild(markDOM.dom);
          top = markDOM.contentDOM || markDOM.dom;
        }
      }
    }
    top.appendChild(serializeNodeInner(node, options));
  });

  return target;
}

/// @internal
function serializeNodeInner(node, options) {
  let { dom, contentDOM } = renderSpec(
    doc(options),
    serializer.nodes[node.type.name](node)
  );

  if (contentDOM) {
    if (node.isLeaf)
      throw new RangeError("Content hole not allowed in a leaf node spec");
    serializeFragment(node.content, options, contentDOM);
  }
  return dom;
}

function serializeMark(mark, inline, options = {}) {
  let toDOM = serializer.marks[mark.type.name];
  return toDOM && renderSpec(doc(options), toDOM(mark, inline));
}

function renderSpec(doc, structure, xmlNS = null) {
  if (typeof structure == "string")
    return { dom: doc.createTextNode(structure) };
  if (structure.nodeType != null) return { dom: structure };
  if (structure.dom && structure.dom.nodeType != null) return structure;

  let tagName = structure[0],
    space = tagName.indexOf(" ");
  if (space > 0) {
    xmlNS = tagName.slice(0, space);
    tagName = tagName.slice(space + 1);
  }
  let contentDOM;
  let dom = xmlNS
    ? doc.createElementNS(xmlNS, tagName)
    : doc.createElement(tagName);
  let attrs = structure[1],
    start = 1;
  if (
    attrs &&
    typeof attrs == "object" &&
    attrs.nodeType == null &&
    !Array.isArray(attrs)
  ) {
    start = 2;
    for (let name in attrs)
      if (attrs[name] != null) {
        let space = name.indexOf(" ");
        if (space > 0)
          dom.setAttributeNS(
            name.slice(0, space),
            name.slice(space + 1),
            attrs[name]
          );
        else {
          if (name == "styles") {
            dom.setAttribute(name, attrs[name]);
          } else {
            dom.setAttribute(name, attrs[name]);
          }
        }
      }

    if (tagName == "interactiveinput"){
      attrs["id"] = "new"
      attrs["circle_id"] = "current"
      dom.setAttribute("id", "new");
      dom.setAttribute("circle_id", "current");
    }
    // To avoid same UIDs after copy/paste
    attrs["uid"] = ""
    dom.setAttribute("uid", "");
  }

  for (let i = start; i < structure.length; i++) {
    let child = structure[i];
    if (child === 0) {
      if (i < structure.length - 1 || i > start)
        throw new RangeError(
          "Content hole must be the only child of its parent node"
        );
      return { dom, contentDOM: dom };
    } else {
      let { dom: inner, contentDOM: innerContent } = renderSpec(
        doc,
        child,
        xmlNS
      );
      dom.appendChild(inner);
      if (innerContent) {
        if (contentDOM) throw new RangeError("Multiple content holes");
        contentDOM = innerContent;
      }
    }
  }
  return { dom, contentDOM };
}

function readHTML(html) {
  let metas = /(\s*<meta [^>]*>)*/.exec(html);
  if (metas) html = html.slice(metas[0].length);
  let doc = document.implementation.createHTMLDocument("title");

  let elt = doc.createElement("div");
  let firstTag = /(?:<meta [^>]*>)*<([a-z][^>\s]+)/i.exec(html),
    wrap,
    depth = 0;
  if ((wrap = firstTag && wrapMap[firstTag[1].toLowerCase()])) {
    html =
      wrap.map((n) => "<" + n + ">").join("") +
      html +
      wrap
        .map((n) => "</" + n + ">")
        .reverse()
        .join("");
    depth = wrap.length;
  }
  elt.innerHTML = html;
  for (let i = 0; i < depth; i++) elt = elt.firstChild;

  return elt;
}

export function parseFromClipboard(view, text, html, plainText, $context) {
  let dom,
    inCode = $context.parent.type.spec.code,
    slice;
  
  if (!html && !text) return null;
  if ((plainText || inCode || !html) && text) {
    view.someProp("transformPastedText", (f) => {
      text = f(text);
    });
    if (inCode)
      return new Slice(Fragment.from(view.state.schema.text(text)), 0, 0);
    let parsed = view.someProp("clipboardTextParser", (f) => f(text, $context));
    if (parsed) {
      slice = parsed;
    } else {
      dom = document.createElement("div");
      text
        .trim()
        .split(/(?:\r\n?|\n)+/)
        .forEach((block) => {
          dom.appendChild(document.createElement("p")).textContent = block;
        });
    }
  } else {
    view.someProp("transformPastedHTML", (f) => (html = f(html)));
    dom = readHTML(html);
  }

  // Remove empty elements
  const emptyElements = dom.querySelectorAll(
    "br, p, div, blockquote, ul, ol, li, h1, h2, h3, h4, h5, h6"
  );
  for (let i = 0; i < emptyElements.length; i++) {
    const emptyElement = emptyElements[i];
    const content = emptyElement.textContent.trim();
    const mediaElements = emptyElement.querySelectorAll("img, video, audio");
    for (let j = 0; j < mediaElements.length; j++) {
      emptyElement.parentNode.insertBefore(mediaElements[j], emptyElement);
    }
    if (content.length === 0 && mediaElements.length === 0) {
      emptyElement.parentNode.removeChild(emptyElement);
    }
  }

  if (!slice) {
    let parser =
      view.someProp("clipboardParser") ||
      view.someProp("domParser") ||
      DOMParser.fromSchema(view.state.schema);
    slice = parser.parseSlice(dom, {
      preserveWhitespace: true,
      context: $context,
    });
  }

  slice = closeIsolatingStart(slice);
  let contextNode = dom && dom.querySelector("[data-pm-context]");
  let context = contextNode && contextNode.getAttribute("data-pm-context");
  if (context == "none") slice = new Slice(slice.content, 0, 0);
  else if (context) slice = addContext(slice, context);
  // HTML wasn't created by ProseMirror. Make sure top-level siblings are coherent
  else slice = normalizeSiblings(slice, $context);
  view.someProp("transformPasted", (f) => {
    slice = f(slice);
  });

  return slice;
}

function normalizeSiblings(slice, $context) {
  if (slice.content.childCount < 2) return slice;
  for (let d = $context.depth; d >= 0; d--) {
    let parent = $context.node(d);
    let match = parent.contentMatchAt($context.index(d));
    let lastWrap,
      result = [];
    slice.content.forEach((node) => {
      if (!result) return;
      let wrap = match.findWrapping(node.type),
        inLast;
      if (!wrap) return (result = null);
      if (
        (inLast =
          result.length &&
          lastWrap.length &&
          addToSibling(wrap, lastWrap, node, result[result.length - 1], 0))
      ) {
        result[result.length - 1] = inLast;
      } else {
        if (result.length)
          result[result.length - 1] = closeRight(
            result[result.length - 1],
            lastWrap.length
          );
        let wrapped = withWrappers(node, wrap);
        result.push(wrapped);
        match = match.matchType(wrapped.type, wrapped.attrs);
        lastWrap = wrap;
      }
    });
    if (result) return Slice.maxOpen(Fragment.from(result));
  }
  return slice;
}

function closeRight(node, depth) {
  if (depth == 0) return node;
  let fragment = node.content.replaceChild(
    node.childCount - 1,
    closeRight(node.lastChild, depth - 1)
  );
  let fill = node
    .contentMatchAt(node.childCount)
    .fillBefore(Fragment.empty, true);
  return node.copy(fragment.append(fill));
}

function closeIsolatingStart(slice) {
  let closeTo = 0,
    frag = slice.content;
  for (let i = 1; i <= slice.openStart; i++) {
    let node = frag.firstChild;
    if (node.type.spec.isolating) {
      closeTo = i;
      break;
    }
    frag = node.content;
  }

  if (closeTo == 0) return slice;
  return new Slice(
    closeFragment(slice.content, closeTo, slice.openEnd),
    slice.openStart - closeTo,
    slice.openEnd
  );
}

function closeFragment(frag, n, openEnd) {
  if (n == 0) return frag;
  let node = frag.firstChild;
  let content = closeFragment(node.content, n - 1, openEnd - 1);
  let fill = node.contentMatchAt(0).fillBefore(node.content, openEnd <= 0);
  return frag.replaceChild(0, node.copy(fill.append(content)));
}

function addToSibling(wrap, lastWrap, node, sibling, depth) {
  if (
    depth < wrap.length &&
    depth < lastWrap.length &&
    wrap[depth] == lastWrap[depth]
  ) {
    let inner = addToSibling(
      wrap,
      lastWrap,
      node,
      sibling.lastChild,
      depth + 1
    );
    if (inner)
      return sibling.copy(
        sibling.content.replaceChild(sibling.childCount - 1, inner)
      );
    let match = sibling.contentMatchAt(sibling.childCount);
    if (match.matchType(depth == wrap.length - 1 ? node.type : wrap[depth + 1]))
      return sibling.copy(
        sibling.content.append(
          Fragment.from(withWrappers(node, wrap, depth + 1))
        )
      );
  }
}

function withWrappers(node, wrap, from = 0) {
  for (let i = wrap.length - 1; i >= from; i--)
    node = wrap[i].create(null, Fragment.from(node));
  return node;
}

function addContext(slice, context) {
  if (!slice.size) return slice;
  let schema = slice.content.firstChild.type.schema,
    array;
  try {
    array = JSON.parse(context);
  } catch (e) {
    return slice;
  }
  let { content, openStart, openEnd } = slice;
  for (let i = array.length - 2; i >= 0; i -= 2) {
    let type = schema.nodes[array[i]];
    if (!type || type.hasRequiredAttrs()) break;
    content = Fragment.from(type.create(array[i + 1], content));
    openStart++;
    openEnd++;
  }
  return new Slice(content, openStart, openEnd);
}
