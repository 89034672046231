import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useTranslation } from "react-i18next";

const premiumFeatures = () => {
  const { t } = useTranslation();

  return {
    personal: [
      {
        description: t("premiumFeatures.personal.description1"),
        icon: <FontAwesomeIcon size="md" icon={regular("heart")} />,
      },
      {
        description: t("premiumFeatures.personal.description2"),
        icon: <FontAwesomeIcon size="md" icon={regular("image")} />,
      },
      {
        description: t("premiumFeatures.personal.description3"),
        icon: <FontAwesomeIcon size="md" icon={regular("envelope")} />,
      },
    ],
    professional: [
      {
        description: t("premiumFeatures.professional.description1"),
        icon: (
          <FontAwesomeIcon size="md" icon={solid("circle-dollar-to-slot")} />
        ),
      },
      {
        description: t("premiumFeatures.professional.description2"),
        icon: <FontAwesomeIcon size="md" icon={solid("palette")} />,
      },
      {
        description: t("premiumFeatures.professional.description3"),
        icon: <FontAwesomeIcon size="md" icon={solid("wand-sparkles")} />,
      },
      {
        description: t("premiumFeatures.professional.description4"),
        icon: <FontAwesomeIcon size="md" icon={solid("people-arrows")} />,
      },
    ],
    organization: [
      {
        description: t("premiumFeatures.organization.description1"),
        icon: (
          <FontAwesomeIcon size="md" icon={solid("circle-dollar-to-slot")} />
        ),
      },
      {
        description: t("premiumFeatures.organization.description2"),
        icon: <FontAwesomeIcon size="md" icon={solid("wand-sparkles")} />,
      },
      {
        description: t("premiumFeatures.organization.description3"),
        icon: <FontAwesomeIcon size="md" icon={solid("people-arrows")} />,
      },
      {
        description: t("premiumFeatures.organization.description4"),
        icon: <FontAwesomeIcon size="md" icon={solid("globe")} />,
      },
    ],
  };
};

export default premiumFeatures;
