function isIndexedDBAvailable() {
  try {
    if (typeof indexedDB === "undefined") {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export function storeJSON(storeId, recordId, data, callback) {
  // Open the IndexedDB database with the name 'sutra' and version 1
  if (!recordId || !isIndexedDBAvailable()){
    return
  }
  const request = indexedDB.open("sutra", 1);

  request.onerror = function (event) {
    console.error("IndexedDB error:", event.target.error);
  };

  request.onsuccess = function (event) {
    const db = event.target.result;

    // Create a new object store named 'json-store' if it doesn't already exist
    if (!db.objectStoreNames.contains(storeId)) {
      db.createObjectStore(storeId);
    }

    // Start a new transaction with readwrite access to the object store
    const tx = db.transaction(storeId, "readwrite");

    // Get a reference to the 'json-store' object store
    const store = tx.objectStore(storeId);

    // Put the provided data into the object store with the provided slug as the key
    data.stored_at = new Date()
    try{

      const putRequest = store.put(data, recordId);
  
      putRequest.onerror = function (event) {
        console.error("Error storing data in IndexedDB:", event.target.error);
      };
  
      putRequest.onsuccess = function (event) {
        // Wait for the transaction to complete
        tx.oncomplete = function () {
          // Close the database connection
          db.close();
  
          // Call the callback function with no arguments to indicate success
          if (callback) {
            callback();
          }
        };
      };
    }
    catch(err){
      console.error(err)
    }
  };

  request.onupgradeneeded = function (event) {
    const db = event.target.result;

    // Create a new object store named 'json-store' if it doesn't already exist
    if (!db.objectStoreNames.contains(storeId)) {
      db.createObjectStore(storeId);
    }
  };
}

export function getJSON(storeId, recordId, callback = null) {

  if (!recordId || !isIndexedDBAvailable()){
    return
  }

  // Open the IndexedDB database with the name 'sutra' and version 1
  const request = indexedDB.open("sutra", 1);

  request.onerror = function (event) {
    console.error("IndexedDB error:", event.target.error);
    if (callback) {
      callback();
    }
  };

  request.onsuccess = function (event) {
    const db = event.target.result;

    // Start a new transaction with readonly access to the object store
    const tx = db.transaction(storeId, "readonly");

    // Get a reference to the 'json-store' object store
    const store = tx.objectStore(storeId);

    // Retrieve the data from the object store with the provided slug as the key
    const getRequest = store.get(recordId ? recordId : "nothing");

    getRequest.onerror = function (event) {
      console.error(
        "Error retrieving data from IndexedDB:",
        event.target.error
      );
    };

    getRequest.onsuccess = function (event) {
      // Wait for the transaction to complete
      tx.oncomplete = function () {
        // Close the database connection
        db.close();

        if (callback) {
          // Call the callback function with the retrieved data
          callback(getRequest.result);
        }
      };
    };
  };

  request.onupgradeneeded = function (event) {
    const db = event.target.result;

    // Create a new object store named 'json-store' if it doesn't already exist
    if (!db.objectStoreNames.contains(storeId)) {
      db.createObjectStore(storeId);
    }
  };
}

export function deleteJSON(storeId, recordId, callback = null) {

  if (!recordId || !isIndexedDBAvailable()){
    return
  }

  // Open the IndexedDB database with the name 'sutra' and version 1
  const request = indexedDB.open("sutra", 1);

  request.onerror = function (event) {
    console.error("IndexedDB error:", event.target.error);
    if (callback) {
      callback();
    }
  };

  request.onsuccess = function (event) {
    const db = event.target.result;

    // Start a new transaction with readonly access to the object store
    const tx = db.transaction(storeId, "readwrite");

    // Get a reference to the 'json-store' object store
    const store = tx.objectStore(storeId);

    // Retrieve the data from the object store with the provided slug as the key
    const getRequest = store.delete(recordId ? recordId : "nothing");

    getRequest.onerror = function (event) {
      console.error(
        "Error deleting data from IndexedDB:",
        event.target.error
      );
    };

    getRequest.onsuccess = function (event) {
      // Wait for the transaction to complete
      tx.oncomplete = function () {
        // Close the database connection
        db.close();

        if (callback) {
          // Call the callback function with the retrieved data
          callback(getRequest.result);
        }
      };
    };
  };

  request.onupgradeneeded = function (event) {
    const db = event.target.result;

    // Create a new object store named 'json-store' if it doesn't already exist
    if (!db.objectStoreNames.contains(storeId)) {
      db.createObjectStore(storeId);
    }
  };
}

