import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useAuthUser from "../../utils/AuthUser";
import { setUser, setAlert } from "../../store/app";

import { Title, Container } from "@mantine/core";

export default function SignOut(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { removeAuthUser } = useAuthUser();

  useEffect(() => {
    removeAuthUser();
    dispatch(setUser({}));
    dispatch(
      setAlert({
        opened: true,
        title: t("users.signOut.title"),
        message: t("users.signOut.message"),
        color: "green",
      })
    );
    window.location.href= "https://sutra.co";
  }, []);

  return (
    <Container size={420} my={40}>
      <Title align="center" sx={(theme) => ({})}>
        {/* {t("users.signOut.loggedOut")} */}
      </Title>
    </Container>
  );
}
