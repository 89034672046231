import axios from 'axios';
import { getNeo4jHost } from '../../utils/Host';

class Space {
  constructor() {
    if (this instanceof Space) {
      throw Error('Space class cannot be instantiated.');
    }
  }

  static onFetch = async (slug, sucessCallback, errorCallback) => {
    const params = {
      auth_token: window.$currentUserAuthToken,
      prev_top_space: window.$fetchedTopSlug
    }

    await axios.get(`${getNeo4jHost()}/api/v1/spaces/${slug}`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }

  static onFetchByScope = async (scope, page, sucessCallback, errorCallback) => {
    const params = {
      auth_token: window.$currentUserAuthToken,
      page
    }

    await axios.get(`${getNeo4jHost()}/api/v1/spaces/by_scope/${scope}`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }

  static onFetchChildren = async (slug, _params, sucessCallback, errorCallback) => {
    const params = {
      auth_token: window.$currentUserAuthToken,
      ..._params
    }

    await axios.get(`${getNeo4jHost()}/api/v1/spaces/${slug}/children`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }

  static onFetchTree = async (slug, sucessCallback, errorCallback) => {
    const params = {
      auth_token: window.$currentUserAuthToken,
    }

    await axios.get(`${getNeo4jHost()}/api/v1/spaces/${slug}/tree`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }

}

export default Space;
