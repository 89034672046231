import React, { useState, useEffect, memo } from "react";
import { useEditor, EditorContent } from "@tiptap/react";

import { useDispatch } from "react-redux";
import { setTiptap, setTiptapSidemenu } from "../../../store/app";

import StarterKit from "@tiptap/starter-kit";

import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";

import TaskItem from "@tiptap/extension-task-item";

import UniqueID from "../plugins/CustomUniqId";
import TextAlign from "@tiptap/extension-text-align";
import Details from "@tiptap-pro/extension-details";
import DetailsSummary from "@tiptap-pro/extension-details-summary";
import DetailsContent from "@tiptap-pro/extension-details-content";
import Focus from "@tiptap/extension-focus";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";

import Link from "@tiptap/extension-link";
import CircleNode from "../nodes/CircleNode";
import Grid4Node from "../nodes/Grid4Node";
import Grid3Node from "../nodes/Grid3Node";
import Grid2Node from "../nodes/Grid2Node";
import Grid1Node from "../nodes/Grid1Node";
import InfoBoxNode from "../nodes/InfoBoxNode";
import ColumnNode from "../nodes/ColumnNode";
import ParagraphNode from "../nodes/ParagraphNode";
import HeadingNode from "../nodes/HeadingNode";
import ImageNode from "../nodes/ImageNode";
import VideoNode from "../nodes/VideoNode";
import AudioNode from "../nodes/AudioNode";
import EmbedNode from "../nodes/EmbedNode";
import FileNode from "../nodes/FileNode";
import ListItemNode from "../nodes/ListItemNode";
import ResourceNode from "../nodes/ResourceNode";
import SurveyNode from "../nodes/SurveyNode";
import FormNode from "../nodes/FormNode";
import ButtonNode from "../nodes/ButtonNode";
import InteractiveInputNode from "../nodes/InteractiveInputNode";
import InteractiveButtonNode from "../nodes/InteractiveButtonNode";
import InteractiveWaitNode from "../nodes/InteractiveWaitNode";
import FancyLinkNode from "../nodes/FancyLinkNode";
import HorizontalRule from "../nodes/HorizontalRuleNode";
import TagsFeedNode from "../nodes/TagsFeedNode";

import TaskList from "../nodes/TaskList";
import BulletList from "../nodes/BulletList";
import OrderedList from "../nodes/OrderedList";

import CollaborationCursor from "@tiptap/extension-collaboration-cursor";

import SideBlocksMenu from "./SideBlocksMenu";
import SideConfigurationsMenu from "./SideConfigurationsMenu";
import NewSpace from "./NewSpace";
import NewMedia from "./NewMedia";
import NewResource from "./NewResource";
import NewPage from "./NewPage";
import ViewPage from "./ViewPage";
import ViewVideo from "./ViewVideo";
import TakeSurvey from "./TakeSurvey";
import CreateSurvey from "./CreateSurvey";
import CreateBundle from "./CreateBundle";
import ShowSurvey from "./ShowSurvey";
import BlockMenuFloating from "./BlockMenuFloating";
import FormatMenu from "./FormatMenu";
import ActionsMenu from "./ActionsMenu";
import FixedBottomMenu from "./FixedBottomMenu";
import EmptyMessage from "./EmptyMessage";
import TiptapChannel from "./TiptapChannel";
import EventHandler from "../plugins/EventHandler";
import EditorEvents from "../plugins/EditorEvents";
import CustomStyles from "../plugins/CustomStyles";
import ListStyles from "../plugins/ListStyles";
import ColumnStyles from "../plugins/ColumnStyles";
import CustomSize from "../plugins/CustomSize";
import CustomResizeable from "../plugins/CustomResizeable";
import CustomButton from "../plugins/CustomButton";
import { CustomCollaboration } from "../plugins/CustomCollaboration";
import CustomRadius from "../plugins/CustomRadius";
import CustomNestedRadius from "../plugins/CustomNestedRadius";
import Placeholder from "../plugins/Placeholder";
import Interactive from "../plugins/Interactive";
import Incrementor from "../plugins/Incrementor";
import CustomBlockId from "../plugins/CustomBlockId";
import { isDocumentEmpty, isEmptySelection } from "../utils/selection";

import { Box, Container } from "@mantine/core";

import { EditorView } from "prosemirror-view";

import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";

import { Mention } from "../plugins/Mention";
import mentionSuggestion from "../plugins/mentionSuggestion";

import CommentInput from "../../spaces_neo/presentations/content/CommentInput";
import VersionControl from "./VersionControl";

// Tiptap -> prosemirror bug / issue
//https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState(state) {
  try {
    if (!this.docView) return; // This prevents the matchesNode error on hot reloads
    this.updateStateInner(state, this.state.plugins != state.plugins);
  } catch {
    return;
  }
};

const Tiptap = memo((props) => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const editor = useEditor({
    onCreate({ editor }) {
      // applyDevTools(editor.view);
    },
    editable: props.editable,
    extensions: [
      StarterKit.configure({
        history: false,
        paragraph: false,
        heading: false,
        listItem: false,
        orderedList: false,
        bulletList: false,
        horizontalRule: false,
        blockquote: false,
      }),
      UniqueID.configure({
        types: [
          "heading",
          "paragraph",
          "grid1",
          "grid2",
          "grid3",
          "grid4",
          "column",
          "tasklist",
          "taskItem",
          "circle",
          "resource",
          "video",
          "audio",
          "image",
          "embed",
          "file",
          "detailsSummary",
          "details",
          "detailsContent",
          "bulletList",
          "orderedList",
          "listItem",
          "taskItem",
          "resource",
          "actionbutton",
          "customform",
          "survey",
          "interactiveInput",
          "interactiveButton",
          "interactiveWait",
          "fancyLink",
          "horizontalRule",
          "infobox",
          "tagsFeed",
        ],
      }),
      Incrementor.configure({
        types: [
          "heading",
          "paragraph",
          "grid1",
          "grid2",
          "grid3",
          "grid4",
          "column",
          "tasklist",
          "taskItem",
          "circle",
          "resource",
          "video",
          "audio",
          "image",
          "embed",
          "file",
          "detailsSummary",
          "details",
          "detailsContent",
          "bulletList",
          "orderedList",
          "listItem",
          "resource",
          "actionbutton",
          "customform",
          "survey",
          "interactiveInput",
          "interactiveButton",
          "interactiveWait",
          "fancyLink",
          "horizontalRule",
          "infobox",
          "tagsFeed",
        ],
      }),
      CustomBlockId.configure({
        types: ["heading", "paragraph", "bulletList", "orderedList"],
      }),
      CustomNestedRadius.configure({
        types: [
          "paragraph",
          "heading",
          "bulletList",
          "orderedList",
          "taskList",
          "gird1",
          "gird2",
          "gird3",
          "gird4",
          "column",
          "infobox",
        ],
      }),
      Highlight.configure({
        multicolor: true,
      }),
      Underline,
      HorizontalRule,
      ListItemNode,
      TextStyle,
      Color,
      ParagraphNode,
      HeadingNode,
      CircleNode,
      ResourceNode,
      SurveyNode,
      FormNode,
      ButtonNode,
      InteractiveInputNode,
      InteractiveButtonNode,
      InteractiveWaitNode,
      TagsFeedNode,
      Grid4Node,
      Grid3Node,
      Grid2Node,
      Grid1Node,
      InfoBoxNode,
      ColumnNode,
      VideoNode,
      AudioNode,
      EmbedNode,
      FancyLinkNode,
      EventHandler,
      EditorEvents,
      FileNode,
      CustomStyles.configure({
        types: [
          "heading",
          "paragraph",
          "image",
          "video",
          "audio",
          "file",
          "embed",
          "grid1",
          "grid2",
          "grid3",
          "grid4",
          "circle",
          "resource",
          "details",
          "detailsSummary",
          "resource",
          "actionbutton",
          "customform",
          "survey",
          "interactiveInput",
          "interactiveButton",
          "interactiveWait",
          "fancyLink",
          "infobox",
          "horizontalRule",
          "tagsFeed",
        ],
      }),
      Interactive.configure({
        types: ["interactiveInput", "interactiveButton", "interactiveWait"],
      }),
      ListStyles.configure({
        types: ["tasklist", "orderedlist", "bulletList"],
      }),
      ColumnStyles.configure({
        types: ["column"],
      }),
      CustomSize.configure({
        types: [
          "file",
          "audio",
          "actionbutton",
          "customform",
          "survey",
          "interactiveButton",
          "fancyLink",
          "circle",
        ],
      }),
      CustomResizeable.configure({
        types: ["image", "video", "embed"],
      }),
      CustomRadius.configure({
        types: [
          "image",
          "video",
          "embed",
          "file",
          "audio",
          "actionbutton",
          "customform",
          "survey",
          "interactiveButton",
          "interactiveInput",
          "circle",
        ],
      }),
      CustomButton.configure({
        types: [
          "actionbutton",
          "customform",
          "interactiveInput",
          "interactiveButton",
          "circle",
          "survey",
        ],
      }),
      OrderedList,
      BulletList,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading") {
            return "";
          }
          if (node.type.name === "paragraph") {
            return "";
          }
          if (node.type.name === "detailsSummary") {
            return "";
          }

          return "";
        },
        showOnlyCurrent: false,
        includeChildren: true,
      }),
      Focus.configure({
        className: "is-focus",
      }),
      Link.configure({
        openOnClick: false,
      }),
      ImageNode,
      TextAlign.configure({
        types: [
          "heading",
          "paragraph",
          "detailsSummary",
          "image",
          "video",
          "embed",
          "file",
          "audio",
          "actionbutton",
          "customform",
          "survey",
          "interactiveButton",
          "fancyLink",
          "circle",
        ],
      }),
      Details.configure({
        HTMLAttributes: {
          openClassName: "is-open",
          class: "tiptap-details",
        },
      }),
      DetailsSummary.configure({
        HTMLAttributes: {
          class: "tiptap-details-summary",
        },
      }),
      DetailsContent.configure({
        HTMLAttributes: {
          class: "tiptap-details-content",
        },
      }),
      CustomCollaboration.configure({
        document: props.provider.document,
      }),
      // CollaborationCursor.configure({
      //   provider: props.provider,
      //   user: {
      //     name: props.userName,
      //     color: "#" + (Math.random().toString(16) + "00000").slice(2, 8),
      //   },
      //   render: (user) => {
      //     const cursor = document.createElement("span");
      //     const main = document.getElementById("tiptap-main");

      //     if (main && main.classList.contains("editing")) {
      //       cursor.classList.add("collaboration-cursor__caret");
      //       cursor.setAttribute("style", `border-color: ${user.color}`);

      //       const label = document.createElement("div");

      //       label.classList.add("collaboration-cursor__label");
      //       label.setAttribute("style", `background-color: ${user.color}`);
      //       label.insertBefore(document.createTextNode(user.name), null);
      //       cursor.insertBefore(label, null);
      //     }
      //     return cursor;
      //   },
      // }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        // suggestion,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: mentionSuggestion,
        renderLabel({ options, node }) {
          const value = node.attrs.label ?? node.attrs.id.split("|")[1];
          return `${options.suggestion.char}${value}`;
        },
      }),
    ],
  });

  useEffect(() => {
    return () => {
      props.provider.disconnect();

      dispatch(setTiptap({ editor: null }));
    };
  }, []);

  useEffect(() => {
    if (!editor) {
      const tiptapCont = document.getElementById("tiptap-main");
      if (tiptapCont) {
        tiptapCont.style.display = "none";
      }
    }
    if (editor && !loaded) {
      dispatch(setTiptap({ editor: editor }));
      setTimeout(() => {
        setLoaded(true);

        const isEmpty = isDocumentEmpty(editor);
        if (props.editable && isEmpty) {
          editor.chain().focus().run();
        }
      }, 1000);
      setTimeout(() => {
        const tiptapCont = document.getElementById("tiptap-main");
        if (tiptapCont) {
          tiptapCont.style.display = "block";
        }
        if (window.$openTemplatesOnEditMode) {
          window.$openTemplatesOnEditMode = null;
          dispatch(
            setTiptapSidemenu({ opened: "templatesModal", option: "Templates" })
          );
        }
        if (window.$openVersionHistoryOnEditMode) {
          window.$openVersionHistoryOnEditMode = null;

          dispatch(setTiptapSidemenu({ opened: "versionControl" }));
        }
        if (window.$openCreateSpaceOnEditMode) {
          window.$openCreateSpaceOnEditMode = null;

          AddLineBelowSelection();

          dispatch(
            setTiptapSidemenu({ opened: "newSpace", option: "content" })
          );
        }
      }, 800);
    }
  }, [editor]);

  const AddLineBelowSelection = () => {
    const { from, to, $anchor } = editor.view.state.selection;

    if (!isEmptySelection(editor)) {
      editor.commands.addNewEmptyLine();
      window.$tiptapLastSelectionRange = { from: to + 1, to: to + 1 };

      // tiptap.editor.chain().focus().setTextSelection({ from: to+1, to: to+1 }).run()
    } else {
      window.$tiptapLastSelectionRange = { from: to, to: to };
    }
  };

  const onFocusIfEditable = (event) => {
    console.log(window.$tiptapSidemenu);
    if (window.$tiptapSidemenu) {
      return;
    }

    let node = null;
    const pos = editor.view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    });

    let lastPos = null;
    if (editor.view.state.doc) {
      lastPos =
        editor.view.state.doc.nodeSize > 3
          ? editor.view.state.doc.nodeSize - 3
          : 0;
    }

    if (props.editable && !pos) {
      try {
        editor.chain().focus().setNodeSelection(lastPos).run();
        node = editor.view.state.selection.node;
      } catch (e) {
        editor.chain().focus().setTextSelection(lastPos).run();
        node = editor.view.state.selection.$anchor.path[3];
      } finally {
      }

      if (node) {
        if (node && node.type.name.indexOf("grid") >= 0) {
          const insertAt =
            editor.view.state.selection.$anchor.path[2] + node.nodeSize;
          editor
            .chain()
            .focus()
            .insertContentAt(insertAt, "<p></p>")
            .setTextSelection(insertAt)
            .selectTextblockStart()
            .selectAll()
            .selectTextblockEnd()
            .run();
        } else if (
          (node.type.name == "paragraph" && node.textContent) ||
          node.type.name != "paragraph"
        ) {
          editor.chain().focus().enter().selectAll().selectTextblockEnd().run();
        }
      }
    }
  };

  return (
    <div
      id="tiptap-main"
      className={`content-tiptap tiptap-wrapper ${
        props.editable ? "editing" : ""
      }`}
    >
      {props.editable ? (
        <>
          <ActionsMenu editor={editor} />
          <BlockMenuFloating
            editor={editor}
            excludedNodes={[
              "grid1",
              "grid2",
              "grid3",
              "grid4",
              "column",
              "circle",
              "resource",
              "image",
              "video",
              "embed",
              "file",
              "audio",
              "actionbutton",
              "customform",
              "survey",
              "interactiveInput",
              "interactiveButton",
              "interactiveWait",
              "fancyLink",
            ]}
          />
          <FormatMenu
            editor={editor}
            excludedNodes={[
              "grid1",
              "grid2",
              "grid3",
              "grid4",
              "column",
              "circle",
              "resource",
              "image",
              "video",
              "embed",
              "file",
              "audio",
              "actionbutton",
              "customform",
              "survey",
              "interactiveInput",
              "interactiveButton",
              "interactiveWait",
              "fancyLink",
              "tagsFeed",
            ]}
          />
          <SideBlocksMenu editor={editor} />
          <SideConfigurationsMenu editor={editor} />
          <NewSpace editor={editor} />
          <NewMedia editor={editor} name="newMedia" />
          <NewResource editor={editor} />
          <NewPage editor={editor} />
          <TakeSurvey editor={editor} />
          <CreateSurvey editor={editor} />
          <CreateBundle editor={editor} />
          <ShowSurvey editor={editor} />
          <VersionControl viewId={props.viewId} />
        </>
      ) : (
        editor &&
        isDocumentEmpty(editor) &&
        loaded &&
        !props.editable && <EmptyMessage />
      )}
      <ViewVideo editor={editor} />
      <ViewPage editor={editor} />
      <TakeSurvey editor={editor} />
      <CreateSurvey editor={editor} />
      <CreateBundle editor={editor} />
      <ShowSurvey editor={editor} />
      <TiptapChannel editor={editor} />
      <div
        id="tiptap-main-wrapper"
        style={{ height: "100vh" }}
        onClick={(event) => onFocusIfEditable(event)}
      >
        <EditorContent editor={editor} />
        <Box>
          <Container size={props.spaceWidth == "wide" ? 960 : 801}>
            {props.editable && (
              <FixedBottomMenu
                excludedNodes={[
                  "grid1",
                  "grid2",
                  "grid3",
                  "grid4",
                  "column",
                  "circle",
                  "resource",
                  "image",
                  "video",
                  "embed",
                  "file",
                  "audio",
                  "fancyLink",
                ]}
              />
            )}
            {props.editable && props.allowComments && (
              <CommentInput editable={true} />
            )}
          </Container>
        </Box>
      </div>
    </div>
  );
});

export default Tiptap;
