import React from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import "add-to-calendar-button/assets/css/atcb.css";

import { useTranslation } from "react-i18next";
import useLangUser from "../../utils/LangUser";
import getSymbolFromCurrency from "currency-symbol-map";

import { Box, Text, Group, Button, createStyles } from "@mantine/core";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  panelWrap: {
    // boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
    display: "flex",
    justifyContent: "center",
  },
  textStyle: {
    fontSize: 20,
    color: colors.sidebar_text_color,
  },
  linkStyle: {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  buttonColor: {
    border: isSpaceThemed(colors)
      ? `1px solid ${colors.sidebar_text_color}`
      : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : colors.primary_button_background_color,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
        : tinycolor(colors.sidebar_background_color).darken(1).toString(),
    },
  },
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
}));

export default function InvitationBar(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });
  const { t, i18n } = useTranslation();
  const { language, setLangUser } = useLangUser();

  if (props.isMember) {
    return null;
  }

  return (
    <Box
      radius={5}
      pt={20}
      pb={20}
      pl={10}
      pr={10}
      className={classes.panelWrap}
    >
      {props.isFull ? (
        <Group spacing={5}>
          <Text align="center" weight={500} className={classes.textStyle}>
            {`This space is full.`}
          </Text>
        </Group>
      ) : (
        <Group spacing={5}>
          <Text align="center" weight={500} className={classes.textStyle}>
            {`Invitation for `}
          </Text>
          {props.invitation.invitation && (
            <Text align="center" weight={700} className={classes.textStyle}>
              {`${props.invitation.invitation.email}`}
            </Text>
          )}
          {props.invitation.payment &&
            props.invitation.payment.invitation_has_different_price && (
              <>
                {!props.invitation.payment.is_flexible && (
                  <Text
                    align="center"
                    weight={500}
                    className={classes.textStyle}
                  >
                    {` - ${
                      getSymbolFromCurrency(props.invitation.payment.currency) +
                      props.invitation.payment.price
                    }`}
                  </Text>
                )}
                <Button
                  ml={20}
                  className={classes.primaryButtonStyle}
                  radius="xl"
                  component={Link}
                  to={`/space/${props.spaceId}/gateway`}
                >
                  {t("spaces.invitationBar.button.register")}
                </Button>
              </>
            )}
        </Group>
      )}
    </Box>
  );
}
