import React from "react";

import {
  Routes,
  Route
} from "react-router-dom";


import SpacesList from "../home/SpacesList";
import SettingsProfile from "../settings/Profile";
import SettingsAccount from "../settings/Account";
import SettingsAffiliates from "../settings/Affiliates";
import SettingsPaidSubscriptions from "../settings/PaidSubscriptions";
import SettingsEmail from "../settings/Email";
import SettingsBilling from "../settings/Billing";
import GettingStarted from "../app/GettingStarted";
import Welcome from "../app/Welcome";
import ScheduleDemo from "../app/ScheduleDemo";
import AppPricing from "../app/Pricing";
import AppPayment from "../app/payment/Payment";
import Space from "../spaces/Space";
import SpaceNeo from "../spaces_neo/Space";
import Start from "../users/Start";
import SignIn from "../users/SignIn";
import SignUp from "../users/SignUp";
import SignOut from "../users/SignOut";
import Recover from "../users/Recover";

import ResetPassword from "../users/ResetPassword";
import MagicLink from "../users/MagicLink";
import MagicSignIn from "../users/MagicSignIn";
import EditUser from "../users/Edit";
import User from "../users/Profile";
import Gateway from "../users/Gateway";
import UserCtrl from "./UserCtrl";

import ZoomIntegration from "../zoom/Integration";
import ZoomConnected from "../zoom/Connected";


export default function MainContent() {

  return (
    <>
    <UserCtrl />
    
    <Routes>
      <Route path="/" element={<SpacesList />} />
      <Route path="/home" element={<SpacesList />} />
      <Route path="/home/:scope" element={<SpacesList />} />
      <Route path="/settings/profile" element={<SettingsProfile />} />
      <Route path="/settings/account" element={<SettingsAccount />} />
      <Route path="/settings/email" element={<SettingsEmail />} />
      <Route path="/settings/billing" element={<SettingsBilling />} />
      <Route path="/settings/affiliates" element={<SettingsAffiliates />} />
      <Route path="/settings/paid_subscriptions" element={<SettingsPaidSubscriptions />} />

      <Route path="/app/pricing" element={<AppPricing />} />
      <Route path="/app/pay/:plan" element={<AppPayment />} />
      <Route path="/app/getting_started" element={<GettingStarted />} />
      <Route path="/app/schedule_demo" element={<ScheduleDemo />} />
      <Route path="/app/welcome" element={<Welcome />} />
      <Route path="/app/gateway/:plan" element={<Gateway />} />

      <Route path="/zoom/integration" element={<ZoomIntegration />} />
      <Route path="/zoom/connected" element={<ZoomConnected />} />

      <Route path="/space_legacy/:slug/*" element={<Space />} />
      <Route path="/space/:slug/*" element={<SpaceNeo />} />
      <Route path="/circle/:slug/*" element={<SpaceNeo />} />
      <Route path="/user/start" element={<Start />} />
      <Route path="/user/sign_in" element={<SignIn />} />
      <Route path="/user/sign_up" element={<SignUp />} />
      <Route path="/user/sign_out" element={<SignOut />} />
      <Route path="/user/recover" element={<Recover />} />
      <Route
        path="/user/reset_password/:reset_password_token"
        element={<ResetPassword />}
      />
      <Route path="/user/magic_link" element={<MagicLink />} />
      <Route
        path="/user/magic_sign_in/:magic_token"
        element={<MagicSignIn />}
      />
      <Route path="/:username" element={<User />} />
      <Route path="/:username/edit" element={<EditUser />} />
    </Routes>
    </>
  );
}
