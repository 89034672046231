import React, { useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Text, Box, MantineProvider, Alert, Container } from "@mantine/core";

import SpaceModel from "../../../../models/Space";

import { useTranslation } from "react-i18next";

export default function Processing(props) {
  const { t, i18n } = useTranslation();

  const intervalId = useRef();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    intervalId.current = setInterval(() => {
      onReset();
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  const onReset = () => {
    SpaceModel.onFetchSimple(
      params.slug,
      "basic",
      (data) => {
        if (data.status != "processing") {
          setTimeout(() => {
            location.href = `/space/${data.slug}/${data.version}`;
          }, 3000);
        }
      },
      (data) => {}
    );
  };

  return (
    <Container size={props.space.space_width == "wide" ? 960 : 801}>
      <Box>
        <MantineProvider theme={{ primaryShade: 6 }}>
          <Alert color="gray" radius="md" mt={15}>
            <Text color="dimmed">
              {t("spaces.presentations.static.processing.message")}
            </Text>
          </Alert>
        </MantineProvider>
      </Box>
    </Container>
  );
}
