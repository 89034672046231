import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box,
        Text,
        Button,
        Group,
        Paper,
   
        createStyles,
      } from '@mantine/core';



import ReflectionModel from "../../../../models/Reflection";

import tinycolor from 'tinycolor2'

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme, {colors}, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color
  },
  textColor: {
    color: colors.sidebar_text_color
  },
  buttonColor: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  }
}));

export default function NewReflections(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const colors = useSelector(state => state.colors);
  const { classes, cx, theme } = useStyles({colors});

  const [reflections, setReflections] = useState([])
  const loaded = useRef()

  useEffect(() => {
    return () => {
      loaded.current = false
    }
  }, [])

  useEffect(() => {
    if (props.space && props.space.slug && !loaded.current){
      loaded.current =true
      onFetchReflections()
    }
  }, [props])

  const onFetchReflections = () => {
    ReflectionModel.onFetchUnread(null, props.space.slug, (data) => {
      setReflections([...data.reflections])
    }, (data) => {

    })
  }

  const reflectionsCount = () => {
    let total = 0
    for (let i = 0; i < reflections.length; i++){
      total += reflections[i].unread
    }
    return total
  }

  const onOpenNext = () => {
    if (reflections.length > 0){
      const reflection = reflections[0]
      const auxReflections = [...reflections]
      auxReflections.splice(0, 1)
      setReflections(auxReflections)
      navigate(reflection.link)
    }
  }

  if (reflections.length == 0){
    return null
  }

  return (
    <Paper
      radius={10}
      mt={10}
      mb={0}
      pt={12} pb={12} pl={20} pr={20}
      className={classes.panelWrap}
      >
      <Group
        position="apart"
        >
        <Text
          weight={500}
          className={classes.textColor}>
          {`${t("spaces.presentations.message.newReflections.part1")} ${reflectionsCount()} ${t("spaces.presentations.message.newReflections.part2")} ${reflections.length} ${t("spaces.presentations.message.newReflections.part3")}`}
        </Text>
        <Box>
        <Button
          radius="xl"
          size="xs"
          className={classes.buttonColor}
          onClick={() => onOpenNext()}>
          {t("spaces.presentations.message.viewNextButton")}
        </Button>
        </Box>
      </Group>
    </Paper>
  );

}
