import React, { useState, useEffect, useRef } from "react";
import { Box, Button } from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import SurveyModel from "../../../../../models/Survey";
import CreateSurveyForm from "../../surveys/CreateSurveyForm";

export default function EditSurvey(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const [survey, setSurvey] = useState(null);
  const [surveyId, setSurveyId] = useState("");
  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    if (props.step && !loading && !mounted.current) {
      setSurveyId(props.step.object_id);
      setLoading(true);
      onFetch();
      mounted.current = true;
    }
  }, [props]);

  const onFetch = () => {
    if (props.step && props.step.object_id) {
      SurveyModel.onFetchById(
        props.step.object_id,
        space.slug,
        (data) => {
          setSurvey(data);
          setLoading(false);
        },
        (data) => {}
      );
    } else if (!loading && !props.step.object_id) {
      const survey_params = { title: "New survey" };

      SurveyModel.onCreate(
        space.slug,
        survey_params,
        (data) => {
          SurveyModel.onFetchById(
            data.id,
            space.slug,
            (data) => {
              setSurvey(data);
              setLoading(false);
            },
            (data) => {}
          );

          props.onUpdate({ object_id: data.id, object_type: "survey" }, false);
        },
        (data) => {}
      );
    }
  };

  const onUpdate = () => {};

  const onDestroy = (event) => {
    props.onDestroy(event);
  };

  if (loading) {
    return null;
  }

  return (
    <Box mt={20}>
      <CreateSurveyForm
        opened={props.opened}
        setOpened={props.setOpened}
        survey={survey}
        spaceId={space.slug}
        onUpdate={onUpdate}
        acceptPayments={false}
        extraButton={
          <Button
            onClick={(event) => onDestroy(event)}
            mt={15}
            radius="xl"
            // size="md"
            color="dark"
            leftIcon={<FontAwesomeIcon icon={regular("trash-can")} />}
          >
            {t("spaces.settings.registration.steps.editSurvey.deleteButton")}
          </Button>
        }
      />
    </Box>
  );
}
