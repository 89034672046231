import { getNeo4jWsHost } from "../utils/Host";
import { io } from "socket.io-client";

const url = getNeo4jWsHost("ws");

const socket = io(url);

const socketManager = {
  subscribe: (channelSlug, callback) => {
    console.log(`SOCKETS - > User subscribing to channel: ${channelSlug}`);
    socket.emit("subscribe", {
      channelSlug,
      auth_token: window.$currentUserAuthToken,
      username: window.$currentUsername
    });

    socket.on(channelSlug, callback);
  },
  unsubscribe: (channelSlug) => {
    socket.emit("unsubscribe", {
      channelSlug,
      auth_token: window.$currentUserAuthToken,
      username: window.$currentUsername
    });

    socket.off(channelSlug);
  },
};

export default socketManager;
