// import axios from 'axios';

class MediaFile {
  constructor() {
    if (this instanceof MediaFile) {
      throw Error('MediaFile class cannot be instantiated.');
    }
  }

  static onFetch = async (url, space_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id,
      url
    }

    await window.$api.get(`/api/v4/media_files/0.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onTranscribe = async (url, space_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id,
      url
    }

    await window.$api.post(`/api/v4/media_files/0/transcribe.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onCreate = async (media_file, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      media_file
    }

    await window.$api.post(`/api/v4/media_files.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDestroy = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/media_files/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }


}

export default MediaFile;
