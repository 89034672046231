import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Stack, NativeSelect, TextInput } from "@mantine/core";

export default function ButtonAction(props) {
  const location = useLocation();
  const timeoutId = useRef(null);
  const { t, i18n } = useTranslation();

  const loading = useRef(false);

  const tiptap = useSelector((state) => state.tiptap);
  const [url, setUrl] = useState("");
  const [target, setTarget] = useState("_current");

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newUrl = url;
    const newTarget = target;

    let edited = false;

    const oldLinkAttrs = { ...props.element.node.attrs.link };
    if (newUrl) {
      edited = true;

      if (
        newUrl.indexOf("http://") < 0 &&
        newUrl.indexOf("https://") < 0 &&
        newUrl.indexOf("mailto://") < 0 &&
        newUrl.indexOf("tel://") < 0
      ) {
        tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
          link: {
            ...oldLinkAttrs,
            url: `https://${newUrl}`,
            target: newTarget,
          },
        });
      } else {
        tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
          link: { ...oldLinkAttrs, url: newUrl, target: newTarget },
        });
      }
    } else {
      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        link: { ...oldLinkAttrs, url: null, target: null },
      });
    }

    if (edited) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        if (url) {
          props.saveNodeData();
        }
      }, 2000);
    }
  }, [url, target]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.link) {
      loading.current = props.element.node.attrs.uid;
      setUrl(props.element.node.attrs.link.url);
      setTarget(props.element.node.attrs.link.target);
    }
  }, [props.element]);

  return (
    <Stack mt={20}>
      <NativeSelect
        value={target}
        onChange={(event) => setTarget(event.target.value)}
        label={t("tiptap.components.sidebar.linkAction.label")}
        description={t("tiptap.components.sidebar.linkAction.description")}
        placeholder={t("tiptap.components.sidebar.linkAction.placeholder")}
        data={[
          {
            value: "_current",
            label: t("tiptap.components.sidebar.linkAction.label2"),
          },
          {
            value: "_blank",
            label: t("tiptap.components.sidebar.linkAction.label3"),
          },
        ]}
        styles={{
          label: {
            fontWeight: 400,
            color: "#000",
          },
        }}
      />
      <TextInput
        mt={5}
        value={url}
        onChange={(event) => setUrl(event.currentTarget.value)}
        placeholder={`Link url`}
        label={`Link url`}
        description={`Specify the URL link the user will be taken to`}
        styles={{
          label: {
            fontWeight: 400,
            color: "#000",
          },
        }}
      />
    </Stack>
  );
}
