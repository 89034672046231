import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Drawer, Text, ScrollArea, Box } from "@mantine/core";

import Uploader from "./Uploader";

import { setTiptapSidemenu } from "../../../store/app";

import { addDummyCursor, removeDummyCursor } from "../utils/dummyCursor";

const NewMedia = (props) => {
  const tiptap = useSelector((state) => state.tiptap);
  const space = useSelector((state) => state.space);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);

  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);
  const [mediaType, setMediaType] = useState("image");

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == props.name) {
      setOpened(true);
      setMediaType(tiptapSidemenu.option);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == props.name) {
        dispatch(setTiptapSidemenu({ opened: null }));
        removeDummyCursor();
      }
    } else {
      addDummyCursor();
    }
  }, [opened]);

  const onSave = () => {
    setTimeout(() => {
      setOpened(false);
    }, 250);
  };
  const { t, i18n } = useTranslation();
  return (
    <Drawer
      position="left"
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text size="xs">
          {t("tiptap.components.newMedia.add")} {mediaType}
        </Text>
      }
      padding="xl"
      size={"400px"}
      overlayOpacity={0.1}
      zIndex={99999}
    >
      <ScrollArea style={{ height: "100%" }}>
        <Box mb={80}>
          <Uploader
            unsplashHeight={400}
            editor={props.editor}
            onSave={onSave}
            uploadType={mediaType}
            action={tiptapSidemenu.action}
            exclude={[]}
          />
        </Box>
      </ScrollArea>
    </Drawer>
  );
};

export default NewMedia;
