import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack, Textarea, Button  } from "@mantine/core";

import MediaFileModel from "../../../../models/MediaFile";

import Swal from "sweetalert2";

export default function Transcript(props) {

  const loading = useRef(false);
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const [transcript, setTranscript] = useState("");

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      onFetch();
    }
  }, [props.element]);

  const onFetch = () => {
    MediaFileModel.onFetch(props.element.node.attrs.src, space.slug, (data) => {
      setTranscript(data.transcription)
    },
    (data) => {})
  }

  const onTranscribe = () => {
    MediaFileModel.onTranscribe(props.element.node.attrs.src, space.slug, (data) => {
      Swal.fire("In process", "This process may take a few minutes", "success");
    },
    (data) => {})
  }

  return (
    <Stack mt={20}>
      <Textarea
        label="Video transcript"
        readOnly={true}
        value={transcript}
      />
      <Button radius={"xl"} onClick={event => onTranscribe()}>{"Transcribe video"}</Button>
    </Stack>
  );
}
