import React, { useState } from "react";
import {
  Button,
  Title,
  Container,
  Divider,
  Text,
  MantineProvider,
  Drawer,
  ScrollArea,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setUser } from "../../store/app";
import { colors } from "../../collections/colors";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import PaymentMethod from "./billing/PaymentMethod";
import History from "./billing/History";

import StripeModel from "../../models/SutraStripe";

import { Helmet } from "react-helmet";

export default function Billing() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const [form, setForm] = useState({ user: {} });
  const [openedPaymentMethod, setOpenedPaymentMethod] = useState(false);

  const onCancel = (event) => {
    event.preventDefault();
    Swal.fire({
      title: t("settings.billing.cancelConfirmationTitle"),
      html: t("settings.billing.cancelConfirmationHtml"),
      showDenyButton: true,
      confirmButtonText: t("settings.billing.cancelConfirmButtonText"),
      denyButtonText: t("settings.billing.cancelDenyButtonText"),
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        onCancelConfirmation();
      } else if (result.isDenied) {
      }
    });
  };

  const onCancelConfirmation = () => {
    StripeModel.onCancelSubscription(
      null,
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );
        const newUser = { ...user };
        newUser.redirect_to = null;
        newUser.account_type = "basic";

        dispatch(setUser(newUser));
      },
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const goToPlans = () => {
    navigate("/app/pricing");
  };

  const getPlanButtonText = () => {
    if (user && user.account_type == "basic") {
      return t("settings.billing.upgradePlan");
    }
    return t("settings.billing.changePlan");
  };

  const getAccountTypeSimplified = () => {
    if (user.account_type === "organisation" || user.account_type === "organizationannual") {
      return "Gold";
    } else if(user.account_type === "professional" || user.account_type === "professionalannual") {
      return "Silver";
    } else if (user.account_type === "personal" || user.account_type === "personalannual") {
      return "Bronze";
    } else {
      return "Basic";
    }
  }

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{t("settings.billing.tabTitle")}</title>
      </Helmet>
      <Container
        style={{ marginBottom: "50px" }}
        size={1000}
        pt={20}
        pb={40}
        pl={40}
        pr={40}
      >
        <Title sx={(theme) => ({ fontWeight: 900 })}>
          {t("settings.billing.billing")}
        </Title>

        <Divider
          sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
          mt={10}
          mb={20}
        />

        <Text mb={20}>
          {t("settings.billing.planPrefix")} {getAccountTypeSimplified()}{" "}
          {t("settings.billing.planSuffix")}.
        </Text>
        <Button
          variant="filled"
          radius="xl"
          mr={10}
          onClick={() => goToPlans()}
        >
          {getPlanButtonText()}
        </Button>
        {user && user.account_type && user.account_type != "basic" ? (
          <>
            <Button
              variant="filled"
              radius="xl"
              mr={10}
              onClick={() => setOpenedPaymentMethod(true)}
            >
              {t("settings.billing.updatePaymentMethod")}
            </Button>
            <MantineProvider theme={{ primaryShade: 6 }}>
              <Button
                variant="outline"
                radius="xl"
                color="gray"
                onClick={(event) => onCancel(event)}
              >
                {t("settings.billing.cancelSubscription")}
              </Button>
            </MantineProvider>
          </>
        ) : (
          ""
        )}
        <Title sx={(theme) => ({ fontWeight: 600 })} order={2} mt={40}>
          {t("settings.billing.billingHistory")}
        </Title>
        <Divider
          sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
          mt={10}
          mb={20}
        />
        <History />
        <Drawer
          opened={openedPaymentMethod}
          onClose={() => setOpenedPaymentMethod(false)}
          title={<Text size="xs">{t("settings.billing.updatePaymentMethodMayus")}</Text>}
          padding="xl"
          size="xl"
        >
          <PaymentMethod setOpenedPaymentMethod={setOpenedPaymentMethod} />
        </Drawer>
      </Container>
    </ScrollArea>
  );
}
