import React, { useState, useEffect } from "react";
import { Box, MantineProvider, Select, Button, Group } from "@mantine/core";

import { useSelector } from "react-redux";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import BundleModel from "../../../models/Bundle";

export default function BundleSelection(props) {
  const space = useSelector((state) => state.space);
  const [bundles, setBundles] = useState([]);
  const { t, i18n } = useTranslation();
  const form = useForm({
    initialValues: {
      object_type: "bundle",
      object_id: "",
    },

    validate: {
      object_id: (value) =>
        value.length > 0 ? null : "Please select a bundle",
    },
  });

  useEffect(() => {
    onFetch();
  }, [props]);

  const onFetch = () => {
    
    BundleModel.onFetch(
      space.slug,
      (data) => {
        const _bundles = data.map((item, index) => ({
          value: `${item.id}`,
          label: item.name,
          group: "Select an existing bundle",
        }));
        setBundles([
          {
            value: "new_bundle",
            label: "Create a new bundle",
            group: "New bundle",
          },
          ..._bundles,
        ]);
      },
      (data) => {}
    );
  };

  const onSend = () => {
    props.onCreate(form.values.object_id);
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSend(values))}>
        <MantineProvider theme={{ primaryShade: 6 }}>
          <Select
            label={"Select or create a bundle"}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
            mt={20}
            data={bundles}
            {...form.getInputProps("object_id")}
            zIndex={99999}
          />
        </MantineProvider>
        <Button
          type="submit"
          // onClick={() => props.onCreate(params)}
          leftIcon={<FontAwesomeIcon icon={solid("check")} />}
          mt={25}
          radius="xl"
          size="md"
          fullWidth
        >
          {`Add bundle`}
        </Button>
      </form>
    </Box>
  );
}
