import axios from 'axios';

class SpaceSetting {
  constructor() {
    if (this instanceof SpaceSetting) {
      throw Error('SpaceSetting class cannot be instantiated.');
    }
  }

  static onFetch = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await axios.get(`/api/v4/spaces/${slug}/settings.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onUpdate = async (slug, space, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space
    }

    await axios.post(`/api/v4/spaces/${slug}/settings.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onUpdateUrlHandle = async (slug, url_handle, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      url_handle
    }

    await axios.post(`/api/v4/spaces/${slug}/settings/url_handle.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onRemoveMetaImage = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    }

    await axios.delete(`/api/v4/spaces/${slug}/settings/remove_meta_image.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default SpaceSetting;
