import React, { useState, useEffect } from "react";
import { Text, Table, Badge, MantineProvider, Box } from "@mantine/core";

import UserModel from "../../../models/User";

import { useTranslation } from "react-i18next";

import SubscriptionItem from "./Item";

export default function History() {
  const { t, i18n } = useTranslation();

  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    onFetch();
  }, []);

  const onFetch = () => {
    UserModel.onFetchSubs(
      (data) => {
        setSubscriptions(data);
      },
      (data) => {}
    );
  };

  return (
    <Box mt={20}>
      {subscriptions.map((space) => {
        return (
          <SubscriptionItem
            key={`subscription-item-${space.slug}`}
            parents={space.parents}
            spaceId={space.slug}
            spaceLink={`/space/${space.slug}/${space.version}`}
            spaceName={space.name}
            
          />
        );
      })}
      {subscriptions.length == 0 && (
        <Box
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "center",
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          {t("settings.paid_subscriptions.noPayments")}
        </Box>
      )}
    </Box>
  );
}
