import axios from 'axios';
import { getCookie } from "../utils/Cookies";

class Interest {
  constructor() {
    if (this instanceof Interest) {
      throw Error('Interest class cannot be instantiated.');
    }
  }

  static onFetch = async (slug, paginator, {term}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      term,
      ...paginator
    }

    await axios.get(`/api/v4/spaces/${slug}/interests.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onCreate = async (slug, _params, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ..._params,
      affiliate: getCookie("affiliate_space"),
      affiliate_date: getCookie("affiliate_space_date"),
      affiliate_sutra: getCookie("affiliate_sutra"),
      affiliate_sutra_date: getCookie("affiliate_sutra_date"),
    }

    await axios.post(`/api/v4/spaces/${slug}/interests.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDestroy = async (pod_invitation_id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await axios.delete(`/api/v4/spaces/${slug}/interests/${pod_invitation_id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default Interest;
