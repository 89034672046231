import React, { useEffect, useRef } from "react";
import {
  Text,
  Box,
  MantineProvider,
  Alert,
  Container,
  Loader,
  Center,
  Stack,
} from "@mantine/core";

import SpaceModel from "../../../../models/Space";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";

import { setHiddenSidebar } from "../../../../store/app";

export default function ProcessingPayment(props) {
  const { t, i18n } = useTranslation();

  const space = useSelector((state) => state.colors);

  const intervalId = useRef();

  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 1000);


    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    intervalId.current = setInterval(() => {
      onReset();
    }, 5000);
    
    return () => {
      clearInterval(intervalId.current);
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    const isMobile = new URLSearchParams(location.search).get("mobile");
    if (isMobile) {
      setTimeout(() => {
        dispatch(setHiddenSidebar({ hidden: true }));
      }, 1000);
    }
  }, [location]);

  const onReset = () => {
    SpaceModel.onAccess(
      location.pathname.split("/")[2],
      (data) => {
        if (data.role != "not_member") {
          navigate(data.redirect_to.url);
        }
      },
      (data) => {}
    );
  };

  return (
    <Container size={space.space_width == "wide" ? 960 : 801}>
      <Stack>
        <Center mt={200}>
          <Loader />
        </Center>
        <Center>
        <MantineProvider theme={{ primaryShade: 6 }}>
        
            <Text color="dimmed" size="lg" mt={15}>
              {/* {t("spaces.presentations.static.processing.message")} */}
              {"Your payment is being processed..."}
            </Text>
          
        </MantineProvider>
        </Center>
      </Stack>
    </Container>
  );
}
