import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { stylesToString } from "../../../utils/StylesToString";

import Form from "../components/Form";

const FormNode = Node.create({
  name: "customform",
  allowGapCursor: false,
  group: "block",
  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block columnable";
  },
  draggable: false,
  addAttributes() {
    return {
      fields: {
        default: [
          { placeholder: "First name", name: "name" },
          { placeholder: "Email", name: "email" },
        ],
      },
      idName: {
        default: "customform-node",
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "10px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: (attributes) => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        }),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "customform",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "customform",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(Form);
  },
});

export default FormNode;
