import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Stack,
  NativeSelect,
  Switch,
  TextInput,
  Select,
  Loader,
  Text,
  Button,
} from "@mantine/core";

import SpaceSettingModel from "../../../../models/SpaceSetting";
import SpaceModel from "../../../../models/Space";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function ButtonAction(props) {
  const location = useLocation();
  const timeoutId = useRef(null);
  const { t, i18n } = useTranslation();
  const loading = useRef(false);
  const searchTimeoutId = useRef(false);

  const space = useSelector((state) => state.space);
  const tiptap = useSelector((state) => state.tiptap);
  const [text, setText] = useState();
  const [callback, setCallback] = useState("message");
  const [regPageAction, setRegPageAction] = useState("");
  const [target, setTarget] = useState("_current");
  const [markComplete, setMarkComplete] = useState(false);
  const [markCompleteHeader, setMarkCompleteHeader] = useState(false);
  const [spacesList, setSpacesList] = useState("custom");
  const [searching, setSearching] = useState("custom");
  const [spaces, setSpaces] = useState([]);
  const [isSelection, setIsSelection] = useState(false);

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newText = text;
    const newCallback = callback;
    const newTarget = target;

    let edited = false;
    console.log(`BUTTON: ${newText}`)
    console.log(`BUTTON: ${props.element.node.attrs.actionCallbackValue}`)
    if (newText) {
      edited = true;
      if (
        callback == "link" &&
        newText.indexOf("http://") < 0 &&
        newText.indexOf("https://") < 0 &&
        newText.indexOf("mailto://") < 0 &&
        newText.indexOf("tel://") < 0
      ) {
        tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
          actionCallbackValue: `https://${newText}`,
          actionCallbackTarget: newTarget,
        });
      } else {
        tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
          actionCallbackValue: `${newText}`,
          actionCallbackTarget: newTarget,
        });
      }
    }
    if (newCallback) {
      edited = true;
      tiptap.editor.commands.setCustomButtonAttr("actionCallback", newCallback);
    }
    if (props.element.node.attrs.isMarkComplete != markComplete) {
      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        isMarkComplete: markComplete,
      });
    }

    if (edited) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        if (text) {
          props.saveNodeData();
        }
      }, 2000);
    }
  }, [text, callback, target, markComplete]);

  useEffect(() => {
    window.$showMarkCompleteButton = markCompleteHeader
    SpaceSettingModel.onUpdate(
      space.slug,
      { pod_settings_attributes: { show_mark_complete_button_in_header: markCompleteHeader } },
      (data) => {
        // setSpacesList("spaces");
      },
      (data) => {}
    );
  }, [markCompleteHeader]);

  useEffect(() => {
    const gatewayLink = getGatewayLink();
    if (regPageAction == "registration" && text != gatewayLink) {
      setText(getGatewayLink());
    } else {
      setText("");
    }
  }, [regPageAction]);

  useEffect(() => {
    if (space && space.slug){
      setMarkCompleteHeader(window.$showMarkCompleteButton)
    }
  }, [space]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;
      if (props.element && props.element.node.type.name != "actionbutton") {
        setCallback(props.element.node.attrs.actionCallback);
      } else {
        setCallback("link");
        if (location && location.pathname.indexOf("/registration") >= 0) {
          setRegPageAction(
            !props.element.node.attrs.actionCallbackValue ||
              props.element.node.attrs.actionCallbackValue == getGatewayLink()
              ? "registration"
              : "custom"
          );
        }
      }

      console.log("MARK COMPLETE: " + props.element.node.attrs.isMarkComplete);
      setText(props.element.node.attrs.actionCallbackValue);
      setTarget(props.element.node.attrs.actionCallbackTarget);
      setMarkComplete(props.element.node.attrs.isMarkComplete);

      if (
        props.element.node.attrs.actionCallbackValue &&
        props.element.node.attrs.actionCallbackValue.indexOf(
          "https://sutra.co/space"
        ) >= 0
      ) {
        setSpacesList("spaces");
      } else {
        setSpacesList("custom");
      }
    }
  }, [props.element]);

  const onSetText = (selection) => {
    if (selection != props.element.node.attrs.actionCallbackValue){
      setText(selection)
    }
  }

  const getGatewayLink = () => {
    return `${window.location.href.split("/space")[0]}/space/${
      location.pathname.split("/")[2]
    }/gateway`;
  };

  const onSearchSpaces = (query) => {
    // if (!query){
    //   return;
    // }
    setSearching(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query);
      setSearching(false);
    }, 1000);
  };

  const onFetchSpaces = (query = null) => {
    const include = ["members", "simple", `order-context-${window.$fetchedTopSlug}`];
    if (query) {
      include.push(`q=${query}`);
    }
    SpaceModel.onFetchManaged(
      null,
      include,
      { page: 1, per: 10 },
      (data) => {
        const _spaces = [];
        for (let i = 0; i < data.length; i++) {
          _spaces.push({
            value: `https://sutra.co/space/${data[i].slug}/${data[i].version}`,
            label: `${data[i].name} (${data[i].slug})`,
          });
        }
        setSpaces(_spaces);
      },
      (data) => {}
    );
  };

  return (
    <Stack mt={20}>
      {props.element &&
        props.element.node.type.name != "actionbutton" &&
        props.element.node.type.name.indexOf("interactive") < 0 && (
          <NativeSelect
            value={callback}
            onChange={(event) => setCallback(event.target.value)}
            label={t("tiptap.components.sidebar.buttonAction.label")}
            description={t(
              "tiptap.components.sidebar.buttonAction.description"
            )}
            placeholder={t(
              "tiptap.components.sidebar.buttonAction.placeholder"
            )}
            data={[
              { value: "message", label: "Pop up message" },
              { value: "link", label: "Redirect to custom link" },
            ]}
            styles={{
              label: {
                fontWeight: 400,
                color: "#000",
              },
            }}
          />
        )}

      {props.element &&
        props.element.node.type.name == "actionbutton" &&
        location &&
        location.pathname.indexOf("/registration") >= 0 && (
          <NativeSelect
            value={regPageAction}
            onChange={(event) => setRegPageAction(event.target.value)}
            label={t("tiptap.components.sidebar.buttonAction.label2")}
            placeholder={t(
              "tiptap.components.sidebar.buttonAction.placeholder2"
            )}
            data={[
              { value: "registration", label: "Space registration" },
              { value: "bundle", label: "Bundle payment" },
              { value: "custom", label: "Custom link" },
            ]}
            styles={{
              label: {
                fontWeight: 400,
                color: "#000",
              },
            }}
          />
        )}
      {props.element &&
        props.element.node.type.name == "actionbutton" &&
        location &&
        location.pathname.indexOf("/registration") < 0 && markComplete && (
          <Switch
            checked={markCompleteHeader}
            onChange={(event) => setMarkCompleteHeader(event.target.checked)}
            label={"Mark complete button in header"}
            placeholder={
              "Toggle to make a mark complete button appear in the header for all members"
            }
          />
        )}
      {regPageAction != "registration" && props.element &&
        props.element.node.type.name == "actionbutton" &&
        callback == "link" && (
          <>
            {markComplete ? (
              <NativeSelect
                value={target}
                onChange={(event) => setTarget(event.target.value)}
                label={t("tiptap.components.sidebar.buttonAction.label3")}
                description={t(
                  "tiptap.components.sidebar.buttonAction.description3"
                )}
                placeholder={t(
                  "tiptap.components.sidebar.buttonAction.placeholder3"
                )}
                data={[
                  { value: "_current", label: t("tiptap.components.sidebar.buttonAction.label7") },
                  { value: "_blank", label: t("tiptap.components.sidebar.buttonAction.label8") },
                  { value: "_nothing", label: t("tiptap.components.sidebar.buttonAction.label9") },
                ]}
                styles={{
                  label: {
                    fontWeight: 400,
                    color: "#000",
                  },
                }}
              />
            ) : (
              <NativeSelect
                value={target}
                onChange={(event) => setTarget(event.target.value)}
                label={t("tiptap.components.sidebar.buttonAction.label3")}
                description={t(
                  "tiptap.components.sidebar.buttonAction.description4"
                )}
                placeholder={t(
                  "tiptap.components.sidebar.buttonAction.description4"
                )}
                data={[
                  { value: "_current", label: t("tiptap.components.sidebar.buttonAction.label7") },
                  { value: "_blank", label: t("tiptap.components.sidebar.buttonAction.label8") },
                  { value: "_section", label: t("tiptap.components.sidebar.buttonAction.label4") },
                ]}
                styles={{
                  label: {
                    fontWeight: 400,
                    color: "#000",
                  },
                }}
              />
            )}
            {!(target === "_nothing" || target === "_section") && (
              <NativeSelect
                value={spacesList}
                onChange={(event) => setSpacesList(event.target.value)}
                label={t("tiptap.components.sidebar.buttonAction.label10")}
                placeholder={t("tiptap.components.sidebar.buttonAction.label11")}
                data={[
                  { value: "spaces", label: t("tiptap.components.sidebar.buttonAction.label12") },
                  { value: "custom", label: t("tiptap.components.sidebar.buttonAction.label13") },
                ]}
                styles={{
                  label: {
                    fontWeight: 400,
                    color: "#000",
                  },
                }}
              />
            )}
          </>
        )}
      {regPageAction != "registration" && !(target === "_nothing" || target === "_section") && spacesList == "spaces" && callback == "link" && (
        <Select
          zIndex={99999}
          icon={
            searching ? (
              <Loader size="xs" />
            ) : (
              <FontAwesomeIcon icon={solid("search")} />
            )
          }
          searchable
          limit={100}
          mt={5}
          data={spaces}
          onChange={(selection) => onSetText(selection)}
          label={t("tiptap.components.sidebar.buttonAction.callbacklinkTo")}
          placeholder={"Search space"}
          description={text && `Redirecting to ${text}`}
          value={text}
          onSearchChange={(query) => onSearchSpaces(query)}
          styles={{
            description: {
              fontWeight: 600
            }
          }}
        />
      )}
      
      {target === "_section" && (
         <>
          <Text size="xs" color="dimmed">
            {t("tiptap.components.sidebar.buttonAction.description5")}
          </Text>
          <Text size="xs" color="dimmed">
            {t("tiptap.components.sidebar.buttonAction.description6")}
          </Text>
          <Button
            radius="xl"
            color={isSelection ? "green" : null}
            onClick={() => {
              window.$sectionParams = {
                origin: {
                  from: tiptap.editor.view.state.selection.from,
                  to: tiptap.editor.view.state.selection.to
                },
                isSelection: true,
                swalTitle: t("tiptap.components.sidebar.buttonAction.label5"),
                onUpdateLink: (link) => {
                  setText(link);
                  setIsSelection(false);
                }
              };
              setIsSelection(true);
            }}
          >
            {isSelection ? t("tiptap.components.sidebar.buttonAction.label14") : t("tiptap.components.sidebar.buttonAction.selectSection")}
          </Button>
        </>
      )}

      {regPageAction != "registration" &&  location &&
        location.pathname.indexOf("/registration") < 0 &&
        props.element.node.type.name.indexOf("interactive") < 0 &&
        spacesList == "custom" &&
        !(target === "_nothing" || target === "_section") && (
          <TextInput
            mt={5}
            value={text}
            onChange={(event) => setText(event.currentTarget.value)}
            placeholder={`${
              callback == "message"
                ? "Completion action text"
                : "https://example.com"
            }`}
            label={`${
              callback == "message"
                ? t("tiptap.components.sidebar.buttonAction.callBackComplete")
                : t("tiptap.components.sidebar.buttonAction.callbacklinkTo")
            }`}
            description={`${
              callback == "message"
                ? t(
                    "tiptap.components.sidebar.buttonAction.completeDescription"
                  )
                : t("tiptap.components.sidebar.buttonAction.linkDescription")
            }`}
            styles={{
              label: {
                fontWeight: 400,
                color: "#000",
              },
            }}
          />
        )}
      {regPageAction != "registration" &&
        location &&
        location.pathname.indexOf("/registration") >= 0 &&
        target != "_nothing" && (
          <TextInput
            mt={5}
            value={text}
            onChange={(event) => setText(event.currentTarget.value)}
            placeholder={`${
              callback == "message"
                ? "Completion action text"
                : "https://example.com"
            }`}
            styles={{
              label: {
                fontWeight: 400,
                color: "#000",
              },
            }}
          />
        )}
    </Stack>
  );
}
