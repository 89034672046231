import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setAlert, setHiddenSidebar } from "../../../store/app";

import SpaceReference from "../../users/SpaceReference";
import FixedHeader from "../FixedHeader";
import { useTranslation } from "react-i18next";

import {
  TextInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Box,
  Divider,
  Stepper,
  Grid,
  ScrollArea,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useMediaQuery } from "@mantine/hooks";

export default function MagicLink(props) {
  const space = useSelector((state) => state.space);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim())
          ? null
          : t("users.magicLink.invalidEmailErrorMsg"),
    },
  });

  const [spaceReference, setSpaceReference] = useState();
  const [showStepper, setStepper] = useState(false);
  const [showSpaceReference, setShowSpaceReference] = useState(true);

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 250);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    if (space.slug){
      setSpaceReference(space.slug);
    }
  }, [space]);

  const onMagicLink = () => {
    dispatch(
      setAlert({
        opened: true,
        title: t("users.magicLink.alertTitle"),
        message: t("users.magicLink.alertMessage"),
        color: "green",
      })
    );
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={["dummy"]}
        />

        {!smallScreen && spaceReference && showStepper ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={0} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : (
          ""
        )}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} my={40}>
              <form onSubmit={form.onSubmit((values) => onMagicLink(values))}>
                <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                  <Box pt={20} pb={5} pl={35} pr={35}>
                    <Title sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}>
                      {t("users.magicLink.title")}
                    </Title>
                    <Text color="dimmed" size="md">
                      {t("users.magicLink.subtitle")}
                    </Text>
                  </Box>
                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                  <Box pt={10} pb={25} pl={35} pr={35}>
                    <TextInput
                      required
                      {...form.getInputProps("email")}
                      label={t("users.magicLink.email")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      type="submit"
                    >
                      {t("users.magicLink.submit")}
                    </Button>
                    <Divider
                      sx={(theme) => ({
                        borderTopColor: theme.colors.gray[2],
                        color: theme.colors.gray[2],
                      })}
                      my="sm"
                      label="OR"
                      labelPosition="center"
                      labelProps={{ color: "#666" }}
                    />
                    <Text size="md" align="center" mt={15}>
                      <Link to={`/space/${space.slug}/demo/sign_in`}>
                        {t("users.magicLink.footer")}
                      </Link>
                    </Text>
                  </Box>
                </Paper>
              </form>
            </Container>
          </Grid.Col>
          {spaceReference && showSpaceReference && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                slug={spaceReference}
                setSpaceReference={setSpaceReference}
                setShowSpaceReference={setShowSpaceReference}
                setStepper={setStepper}
              />
            </Grid.Col>
          )}
        </Grid>
      </Container>
    </ScrollArea>
  );
}
