import React, { useState, useEffect } from "react";

import { Text, Box, Drawer, SegmentedControl, ScrollArea, Divider } from "@mantine/core";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import InviteEmail from "./InviteEmail";
import InviteExisting from "./InviteExisting";
import InviteLink from "./InviteLink";

import { useForm } from "@mantine/form";

import InvitationModel from "../../../../models/Invitation";
import MemberModel from "../../../../models/Member";
import SpaceSettingModel from "../../../../models/SpaceSetting";

import Swal from "sweetalert2";

export default function InvitePeople(props) {
  const { t, i18n } = useTranslation();
  const [checked, setChecked] = useState(true);
  const [inviteSection, setInviteSection] = useState("inviteEmail");

  const space = useSelector((state) => state.space);

  const [settings, setSettings] = useState();
  const [spaces, setSpaces] = useState([]);
  const [activeMembers, setActiveMembers] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [allowCopySpaces, setAllowCopySpaces] = useState(false);
  const [allowCopyActiveMembers, setCopyAllowActiveMembers] = useState(false);
  const [allowCopyPendingMembers, setCopyAllowCopyPendingMembers] = useState(false);
  const [counts, setCounts] = useState({
    all: 0,
    active: 0,
    pending: 0,
    managers: 0,
  });

  useEffect(() => {
    if (space && space.slug) {
      onFetchMembersToCopy();
      onFetchCounts();
    }
  }, [space]);

  const onFetchCounts = () => {
    MemberModel.onFetchCounts(
      space.slug,
      (data) => {
        setCounts(data);
      },
      (data) => {}
    );
  };

  const form = useForm({
    initialValues: {
      add_people: "",
      message: "",
      notify_members: true,
    },

    validate: {
      add_people: (value) =>
        value.length > 0 && value.split(",").length > 0
          ? null
          : "Add at least one person",
    },
  });

  const onInvite = () => {
    InvitationModel.onCreate(
      form.values,
      space.slug,
      (data) => {
        Swal.fire(data.title, data.message, "success");
        props.setOpened(false);
      },
      (data) => {
        Swal.fire(data.title, data.message, "error");
      }
    );
  };

  const displayInviteSection = () => {
    if (inviteSection == "inviteEmail") {
      return (
        <InviteEmail
          settings={space}
          countsManagers={counts.managers}
          setOpened={props.setOpened}
          setRefresh={props.setRefresh}
          showTitle={
            !(allowCopySpaces || allowCopyActiveMembers || allowCopyPendingMembers)
          }
        />
      );
    } else if (inviteSection == "inviteExisting") {
      return (
        <InviteExisting
          spaces={spaces}
          settings={space}
          countsManagers={counts.managers}
          activeMembers={activeMembers}
          pendingMembers={pendingMembers}
          allowCopySpaces={allowCopySpaces}
          allowCopyActiveMembers={allowCopyActiveMembers}
          allowCopyPendingMembers={allowCopyPendingMembers}
          setOpened={props.setOpened}
          setRefresh={props.setRefresh}
        />
      );
    } else if (inviteSection == "invitelink") {
      return <InviteLink setRefresh={props.setRefresh} settings={space} />;
    } else {
      return (
        <InviteEmail
          settings={space}
          countsManagers={counts.managers}
          setOpened={props.setOpened}
          setRefresh={props.setRefresh}
          showTitle={
            !(allowCopySpaces || allowCopyActiveMembers || allowCopyPendingMembers)
          }
        />
      );
    }
  };

  const onFetchMembersToCopy = (tree, _spaces = []) => {
    MemberModel.onFetchMembersToCopy(
      space.slug,
      (data) => {
        setAllowCopySpaces(data.spaces.allowed);
        setSpaces(data.spaces.data);
        setCopyAllowActiveMembers(data.active.allowed);
        setActiveMembers(data.active.data);
        setCopyAllowCopyPendingMembers(data.pending.allowed);
        setPendingMembers(data.pending.data);
      },
      (data) => {
        setAllowCopySpaces(false);
        setCopyAllowActiveMembers(false);
        setCopyAllowCopyPendingMembers(false);
      }
    );
  };

  const getInviteOptions = () => {
    if (allowCopySpaces || allowCopyActiveMembers || allowCopyPendingMembers) {
      return [
        { label: "Send email", value: "inviteEmail" },
        { label: "Add existing members", value: "inviteExisting" },
      ];
    } else {
      return [{ label: "Send email", value: "inviteEmail" }];
    }
  };

  return (
    <Drawer
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {t("spaces.settings.members.invitePeople.invite.invitePeople")}
        </Text>
      }
      padding="xl"
      size={600}
    >
      <ScrollArea style={{ height: "100%" }}>
        <InviteLink setRefresh={props.setRefresh} settings={space} />
        {(allowCopySpaces ||
          allowCopyActiveMembers ||
          allowCopyPendingMembers) ? (
          <SegmentedControl
            fullWidth
            onChange={(value) => setInviteSection(value)}
            data={getInviteOptions()}
          />
        ) : (<Divider
          mb={20}
          mt={10}
          sx={(theme) => ({
            borderTop: `1px solid ${theme.colors.gray[2]}`,
          })}
        />)}
        

        {displayInviteSection()}
        <Box mt={100} />
      </ScrollArea>
    </Drawer>
  );
}
