import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setHiddenSidebar } from "../../../../store/app";

import TiptapReadonly from "../../../tiptap/components/TiptapReadonly";

import HeaderImage from "../../HeaderImage";

import FixedHeader from "../../FixedHeader";

import Loading from "./Loading";

import { useParams } from "react-router-dom";

import ViewModel from "../../../../models/View";

import {
  Container,
  Stack,
  ScrollArea,
  Box,
  Text,
  createStyles,
} from "@mantine/core";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
}));

export default function Pending(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const params = useParams();

  const colors = useSelector((state) => state.colors);

  const { classes, cx, theme } = useStyles({ colors });

  const [loaded, setLoaded] = useState(false);
  const content = useRef({});
  const lastSlug = useRef();

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    // debugger;
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: true }));

    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 500);

    return () => {
      setLoaded(false);
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {


    if (params.slug && lastSlug.current != params.slug) {
      lastSlug.current = params.slug;
      setLoaded(false);
      onFetch();
    }

  }, [params]);

  const onFetch = () => {
    ViewModel.onFetch("pending_member_view", params.slug, (data) => {
      content.current = data.content
      setLoaded(true);
    }, (data) => {

    })
  }

  if (!loaded) {
    return <Loading type="content" />;
  }

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea className="content-scrollview" style={{ height: "100%" }}>
        <HeaderImage />
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={props.editable}
          components={["breadcrumbs", "title"]}
        />
        <Container
          size={props.space.space_width == "wide" ? 960 : 801}
          mt={15}
          sx={(theme) => ({
            height: "100%",
          })}
        >
          {props.space && content.current ? (
            <>
              <TiptapReadonly
                content={{ type: "doc", content: content.current }}
              />
              <Box
                sx={{
                  height: "200px",
                }}
              />
            </>
          ) : (
            <Text color="dimmed">{t("spaces.presentations.static.pending.message")}</Text>
          )}
        </Container>
      </ScrollArea>
    </Stack>
  );
}
