import React from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";
import { useTranslation } from "react-i18next";

import useLangUser from "../../utils/LangUser";
import SpaceModel from "../../models/Space";

import { Text, Paper, Group, Button, createStyles } from "@mantine/core";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";

import { DateTime } from "luxon";

import Swal from "sweetalert2";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
  },
  textColor: {
    color: colors.sidebar_text_color,
  },
  buttonColor: {
    border: isSpaceThemed(colors)
      ? `1px solid ${colors.sidebar_text_color}`
      : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : colors.primary_button_background_color,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
        : tinycolor(colors.sidebar_background_color).darken(1).toString(),
    },
  },
}));

export default function StatusBar(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });
  const { t, i18n } = useTranslation();
  const { language, setLangUser } = useLangUser();

  const navigate = useNavigate();
  // React.useEffect(atcb_init, []);
  const [name, setName] = React.useState("Some event");
  const changeName = (e) => {
    setName(e.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    atcb_action({
      name: props.eventName,
      description: props.eventDescription,
      startDate: props.calendar.raw_start_date,
      endDate: props.calendar.raw_end_date,
      options: [
        "Apple",
        "Google",
        "iCal",
        "Microsoft365",
        "Outlook.com",
        "Yahoo",
      ],
      timeZone: "UTC",
      iCalFileName: "Reminder-Event",
    });
  };

  const onRestore = () => {
    Swal.fire({
      title: "Are you sure?",
      html: "This space will be restored and members will have access again.",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        SpaceModel.onRestore(
          props.space.slug,
          (data) => {
            navigate(data.redirect_to);
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const formatDateTime = (timestamp) => {
    if (props.space && props.space.launch_time) {
      const d = new Date(props.space.launch_time).toISOString();
      return DateTime.fromISO(d).toFormat("ff");
    }
  };

  const getStatusString = () => {
    console.log("space: ", props.space);
    let statusString = "This space ";
    if (props.statusName === "pending") {
      statusString = statusString + `will publish on ${formatDateTime()}`;
    } else {
      statusString = statusString + `is ${props.statusName}`;
    }
    return statusString;
  };

  if (
    ["archived", "deleted", "pending", "processing"].indexOf(props.statusName) <
    0
  ) {
    return null;
  }

  return (
    <Paper
      radius={10}
      mt={15}
      mb={15}
      pt={12}
      pb={12}
      pl={20}
      pr={20}
      className={classes.panelWrap}
    >
      <Group position="apart">
        <Text align="center" weight={500} className={classes.textColor}>
          {getStatusString()}
        </Text>
        {props.statusName === "deleted" && (
          <Button
            radius="xl"
            size="xs"
            variant="outline"
            className={classes.buttonColor}
            // leftIcon={
            //   <FontAwesomeIcon icon={solid('message')} />
            // }
            onClick={() => onRestore()}
          >
            {t("spaces.statusBar.restoreButton")}
          </Button>
        )}
      </Group>
    </Paper>
  );
}
