import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Chips, Chip, createStyles } from "@mantine/core";

const useStyles = createStyles((theme, _params, getRef) => ({
  chipChecked: {
    backgroundColor: `${theme.colors.dark[3]} !important`,
    color: theme.white,
    [`& .${getRef("iconWrapper")}`]: {
      color: theme.white,
    },
  },
  chipOutline3: {
    width: "80px",
    border: `1px solid ${theme.colors.dark[3]}`,
  },
  chipOutline4: {
    border: `1px solid ${theme.colors.dark[3]}`,
  },
  chipLabel: {
    textAlign: "center",
    fontSize: 9,
    fontWeight: 800,
    color: theme.colors.dark[3],
    height: 26,
  },
}));

export default function NestedRadius(props) {
  const timeoutId = useRef(null);
  const { t, i18n } = useTranslation();
  const loading = useRef(false);

  const { classes, cx, theme } = useStyles();

  const tiptap = useSelector((state) => state.tiptap);
  const [radius, setRadius] = useState("no-radius");

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newRadius = node.node.attrs.nestedRadius
      ? { ...node.node.attrs.nestedRadius }
      : null;

    if (newRadius == radius) {
      return;
    }

    if (
      node.node.type.name.indexOf("grid") < 0 &&
      node.node.type.name != "column"
    ) {
      tiptap.editor.commands.setCustomNestedRadius(radius);
    } else {
      const { tr } = tiptap.editor.view.state;
      tr.setNodeMarkup(node.pos, undefined, {
        ...node.node.attrs,
        nestedRadius: radius,
      });
      tiptap.editor.view.dispatch(tr);
    }

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      timeoutId.current = null;
      props.saveNodeData();
    }, 2000);
  }, [radius]);

  useEffect(() => {
    if (props.element && props.element.node.attrs) {
      loading.current = props.element.node.attrs.uid;
      setRadius(
        props.element.node.attrs.nestedRadius
          ? props.element.node.attrs.nestedRadius
          : null
      );
    }
  }, [props.element]);

  return (
    <Chips
      mt={10}
      sx={{ width: "100%" }}
      color="dark"
      radius="sm"
      size="sm"
      multiple={false}
      value={radius}
      onChange={setRadius}
      classNames={{
        checked: classes.chipChecked,
        iconWrapper: classes.iconWrapper,
        outline: classes.chipOutline3,
        label: classes.chipLabel,
      }}
    >
      <Chip value="no-radius">
        {t("tiptap.components.sidebar.radius.square")}
      </Chip>
      <Chip value="with-radius">
        {t("tiptap.components.sidebar.radius.round")}
      </Chip>
      <Chip value="circle-radius">
        {t("tiptap.components.sidebar.radius.circle")}
      </Chip>
    </Chips>
  );
}
