import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import BackupModel from "../../../models/Backup";

import { ScrollArea } from "@mantine/core";

export default function BackupsList(props) {
  const [backups, setBackups] = useState([]);

  const space = useSelector((state) => state.space);

  useEffect(() => {
    if (space.slug) {
      onFetch();
    }
  }, [space]);

  const onFetch = () => {
    BackupModel.onFetch(
      space.slug,
      "content",
      { page: 1, per: 50 },
      (data) => {
        setBackups(data.data);
      },
      (data) => {}
    );
  };

  const onPreview = () => {};

  const onRestore = () => {};

  return <ScrollArea></ScrollArea>;
}
