import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";

import MentionList from "./MentionList.jsx";


export default {

  items: ({ query }) => {
    return new Promise((resolve, reject) => {
      const _default = [
        { username: "space", full_name: "Space", avatar: { url: "" } },
      ];
      const slug = window.location.href.split("/")[4];
      const params = {
        locale: window.$currentUserLanguage,
        auth_token: window.$currentUserAuthToken,
        scope: "active",
        term: query,
        page: 1,
        per: 10,
        include_parents: true
      };

      window.$api
        .get(`/api/v4/spaces/${slug}/members.json`, { params })
        .then((res) => {
          resolve(_default.concat(res.data.data));
        })
        .catch((error) => {
          resolve(_default);
        });
    });
  },

  render: () => {
    let component;
    let popup;

    return {
      onStart: (props) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props) {
        if (component){

          component.updateProps(props);
  
          if (!props.clientRect) {
            return;
          }
  
          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        }
        return
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }
        try {
          return component.ref?.onKeyDown(props);
        } catch (e) {}
      },

      onExit() {
        if (popup && popup.length){
          popup[0].destroy();
        }
        if (component){
          component.destroy();
        }
      },
    };
  },
};
