import React, { useState, useEffect, useRef } from "react";
import {
  Text,
  Box,
  TextInput,
  ActionIcon,
  Radio,
  NativeSelect,
  Stack,
  Anchor,
  createStyles,
} from "@mantine/core";

import { sutraTheme } from "../../../../collections/sutraTheme";

import { X } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
const useStyles = createStyles((theme) => ({
  dragHandle: {
    ...theme.fn.focusStyles(),
    height: "100%",
    color: theme.colors.gray[6],
    cursor: "move",
    paddingTop: 3,
    width: 20,
    maxWidth: 20,
  },
}));

const frequency = [
  { value: "once", label: "Once" },
  { value: "per month", label: "Per month" },
  // { value: "per quarter", label: "Per quarter" },
  { value: "per year", label: "Per year" },
];

export default function PaymentItem(props) {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();

  const [showDescription, setShowDescription] = useState(false);
  const loaded = useState(false)

  useEffect(() => {
    if (props.description) {
      setShowDescription(true)
    }
    else{
      setShowDescription(false)
    }

    setTimeout(() => {
      loaded.current = true
    }, 1000)
  }, [props]);

  useEffect(() => {
    if (!loaded.current){
      return
    }
    if (!showDescription) {
      props.onDescriptionChange("");
    }
  }, [showDescription]);

  return (
    <>
  
      <TextInput
        placeholder={t(
          "spaces.settings.survey.paymentItem.paymentItem.placeholder"
        )}
        radius="sm"
        size="md"
        value={props.content}
        onChange={(event) => props.onContentChange(event.currentTarget.value)}
        sx={(theme) => ({
          width: "calc(40% - 30px)",
          marginRight: 10,
        })}
      />

      <TextInput
        icon={<Text>{props.currency.symbol}</Text>}
        placeholder={`Amount in ${
          props.currency.currency !== undefined
            ? props.currency.currency.toUpperCase()
            : props.currency.currency
        }`}
        radius="sm"
        size="md"
        value={props.paymentAmount}
        onChange={(event) =>
          props.onPaymentAmountChange(event.currentTarget.value)
        }
        sx={(theme) => ({
          width: "calc(40% - 30px)",
        })}
        rightSection={
          <ActionIcon variant="transparent" onClick={() => props.onDestroy()}>
            <X size={18} />
          </ActionIcon>
        }
        rightSectionWidth={40}
        disabled={props.disabled}
      />

      <NativeSelect
        value={props.frequency}
        disabled={props.disabled}
        onChange={(event) => props.onFrequencyChange(event.currentTarget.value)}
        data={frequency}
        size="md"
        radius="sm"
        ml={10}
        sx={(theme) => ({
          width: "calc(20% - 10px)",
        })}
      />
      {showDescription && (
        <TextInput
          ml={20}
          mt={10}
          radius="sm"
          size="md"
          value={props.description}
          onChange={(event) =>
            props.onDescriptionChange(event.currentTarget.value)
          }
          sx={(theme) => ({
            width: "calc(100% - 30px)",
          })}
          disabled={props.disabled}
        />
      )}
      <Anchor
        ml={20}
        mt={10}
        sx={(theme) => ({

          color: theme.colors.gray[7],
          textDecoration: "none",
          "&:hover": {
            color: theme.colors.gray[7],
            textDecoration: "underline",
          },
        })}
        onClick={() => setShowDescription(!showDescription)}
      >
        <Text size="xs" weight="400">
          {showDescription ? "Hide description" : "Show description"}
        </Text>
      </Anchor>
    </>
  );
}
