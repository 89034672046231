import { Extension } from '@tiptap/core'

// https://tiptap.dev/guide/custom-extensions#attributes

const CustomNestedRadius = Extension.create({
  name: "customNestedRadius",
  addOptions() {
    return {
      types: [],
    }
  },
  addGlobalAttributes() {

    return [
      {
        // Extend the following extensions
        types: [
          'paragraph',
          'heading',
          'bulletList',
          'orderedList',
          'taskList',
          'gird1',
          'gird2',
          'gird3',
          'column',
        ],
        // … with those attributes
        attributes: {
          nestedRadius:  {
            default: "no-radius",
            renderHTML: attributes => ({
              nestedRadius: `${attributes.nestedRadius}`,
            })
          }
        },
      },
    ]
  },
  addCommands() {
    return {
      setCustomNestedRadius: (_nestedRadius) => ({ commands }) => {

        return this.options.types.every(type => commands.updateAttributes(type, { nestedRadius: _nestedRadius }))
      },
    }
  },
})

export default CustomNestedRadius;
