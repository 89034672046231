import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import useSidebarStyles from "../../../utils/SidebarStyles";
import trackMixpanel from "../../../utils/TrackMixpanel";
import { useTranslation } from "react-i18next";
import SpaceSettingModel from "../../../models/SpaceSetting";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import tinycolor from "tinycolor2";

import {
  createStyles,
  Box,
  Text,
  UnstyledButton,
  Group,
  Tooltip,
} from "@mantine/core";

const useStyles = createStyles((theme, { colors, settingsView }) => ({
  spaceNameWrap: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    margin: "20px 20px 0px 20px",
    padding: "10px 10px 10px 20px",
    borderRadius: 5,
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    cursor: "pointer",
    fontWeight: 500,
    alignItems: "center",
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(5)
        .toString(),
    },
  },
  spaceNameClose: {
    color: "#fff",
    fontWeight: 600,
    padding: "5px 8px",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(15)
        .toString(),
    },
  },
}));

export default function SidebarTutorial(props) {
  const location = useLocation();

  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const { settingsView } = useSidebarStyles();
  const { classes, cx } = useStyles({ colors, settingsView });
  const navigate = useNavigate();

  const onOpenTutorial = (event) => {
    if (space.pod_state == "model"){
      return
    }
    trackMixpanel(user.username, "Tutorial Opened");
    navigate(`/space/${props.slug}/getting_started`);
  };

  const onCloseTutorial = (event) => {
    event.preventDefault();
    event.stopPropagation();
    SpaceSettingModel.onUpdate(
      props.slug,
      { pod_settings_attributes: { tutorial: "hide" } },
      (data) => {
        props.onCloseTutorial();
      },
      (data) => {}
    );
  };

  if (!space.slug) {
    return null;
  }

  return (
    <Box
      onClick={(event) => onOpenTutorial(event)}
      className={classes.spaceNameWrap}
    >
      <Tooltip
        disabled={window.$isTouchDevice && window.$isTouchDevice()}
        placement="start"
        gutter={20}
        label={"Only managers see this"}
        withArrow
      >
        <Group>
          <Text weight={600}>
            {t("spaces.sidebar.sidebarTutorial.gettingStarted")}
          </Text>
        </Group>
      </Tooltip>
      <UnstyledButton
        className={classes.spaceNameClose}
        onClick={(event) => onCloseTutorial(event)}
      >
        <FontAwesomeIcon size="sm" icon={solid("x")} />
      </UnstyledButton>
    </Box>
  );
}
