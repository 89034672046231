import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setHiddenSidebar } from "../../../store/app";

import getSymbolFromCurrency from "currency-symbol-map";

import SpaceReference from "../../users/SpaceReference";
import FixedHeader from "../FixedHeader";
import PaymentImage from "../../../src/images/app/emptyPayment.png";

import { useTranslation } from "react-i18next";

import {
  Paper,
  Title,
  Image,
  Container,
  Button,
  Box,
  Divider,
  Stepper,
  Grid,
  ScrollArea,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

export default function Payment(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [price, setPrice] = useState();
  const [currency, setCurrency] = useState();
  const [frequency, setFrequency] = useState();
  const [loaded, setLoaded] = useState(false);
  const [spaceReference, setSpaceReference] = useState();

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 1000);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    setSpaceReference(props.space.slug);
  }, [props]);

  useEffect(() => {
    if (props.space.slug) {
      onCreate();
    }
  }, [price]);

  const onCreate = () => {};

  const onPriceChange = (price, currency, frequency) => {
    setPrice(price);
    setCurrency(currency);
    setFrequency(frequency);
    setLoaded(true);
  };

  const onPay = () => {
    if (localStorage.getItem("dummy_was_registered") == "true") {
      navigate(`/space/${props.space.slug}/demo/edit_profile`);
    } else {
      if (props.space && props.space.permissions.block_until_approved) {
        navigate(`/space/${props.space.slug}/demo/pending`);
      } else {
        navigate(`/space/${props.space.slug}`);
      }
    }
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  const setStepper = () => {};
  const setShowSpaceReference = () => {};

  const onJoin = () => {
    if (localStorage.getItem("dummy_was_registered") == "true") {
      navigate(`/space/${props.space.slug}/demo/edit_profile`);
    } else {
      navigate(`/space/${props.space.slug}`);
    }
  };

  const getFrequency = () => {
    if (!frequency) {
      return "";
    }
    return frequency != "once" ? ` / ${frequency}` : "";
  };

  const buttonText = () => {
    const amount = price ? parseFloat(price) : 0;

    return `${t("users.payment.pay")} ${getSymbolFromCurrency(currency)}${amount
      .toFixed(2)
      .replace(".00", "")}${getFrequency()}`;
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={["dummy"]}
        />

        {!smallScreen && props.space ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={1} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : (
          ""
        )}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                <Box pt={20} pb={5} pl={35} pr={35}>
                  <Title
                    sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                    order={1}
                  >
                    {t("users.payment.completePayment")}
                  </Title>
                </Box>
                <Divider
                  sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                  my="sm"
                />
                <Box
                  pt={20}
                  pb={5}
                  pl={35}
                  pr={35}
                  key={`strip-payment-container-${new Date()}`}
                >
                  {props.space && price > 0 ? (
                    <>
                      <Image width={"100%"} src={PaymentImage} />
                      <Button
                        fullWidth
                        mt="xl"
                        radius="xl"
                        size="lg"
                        mb={15}
                        onClick={() => onPay()}
                      >
                        {buttonText()}
                      </Button>
                    </>
                  ) : (
                    props.space &&
                    loaded && (
                      <Button
                        fullWidth
                        mt={0}
                        radius="xl"
                        size="lg"
                        mb={15}
                        onClick={() => onJoin()}
                      >
                        {t("users.payment.join")}
                      </Button>
                    )
                  )}
                </Box>
              </Paper>
            </Container>
          </Grid.Col>
          {props.space && props.space.slug && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                slug={props.space.slug}
                setSpaceReference={setSpaceReference}
                onPriceChange={onPriceChange}
                setStepper={setStepper}
                setShowSpaceReference={setShowSpaceReference}
              />
            </Grid.Col>
          )}
        </Grid>
      </Container>
    </ScrollArea>
  );
}
