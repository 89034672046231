import { Node, mergeAttributes } from "@tiptap/core";

import { gridActions } from "../utils/gridActions";
import { checkInteractionAndHideIfNeeded, setUniqId} from "../utils/selection";
import { stylesToString } from "../../../utils/StylesToString";
import { wideOrNarrow } from "../../../utils/PageWidth";

const Grid3Node = Node.create({
  name: "grid3",
  content: "column column column",
  group: "block",
  inline: false,
  defining: true,
  isolating: false,
  allowGapCursor: false,
  draggable: false,
  selectable: true,
  addAttributes() {
    return {
      circle_id: {
        default: null,
      },
      dist: {
        default: [4, 4, 4],
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "10px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: (attributes) => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        }),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "grid3",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["grid3", mergeAttributes(HTMLAttributes), 0];
  },
  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("section");

      const attributes = mergeAttributes(HTMLAttributes, {
        "data-type": this.name,
      });
      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );
      const content = document.createElement("div");
      content.style.maxWidth = wideOrNarrow();
      content.setAttribute("id", attributes["data-uid"]);
      content.classList.add("c-grid");
      content.classList.add("grid3");
      dom.append(content);
      const subcontent = document.createElement("div");
      content.append(subcontent);

      dom.append(gridActions(editor));
      checkInteractionAndHideIfNeeded(editor, dom, getPos);
      //setUniqId(editor, node, getPos, dom);
      return {
        dom,
        contentDOM: content,
      };
    };
  },
});

export default Grid3Node;
