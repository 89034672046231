import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  ScrollArea,
  Stack,
  TextInput,
  Group,
  Text,
  Switch,
  createStyles,
  Box,
  Loader,
  Center,
} from "@mantine/core";
import ResourceModel from "../../models/Resource";

import { useForm } from "@mantine/form";
import { TimeInput, DatePicker } from "@mantine/dates";

import { useTranslation } from "react-i18next";

import { useFocusTrap } from "@mantine/hooks";

import TiptapInline from "../tiptap/components/TiptapInline";

import { storeJSON } from "../../utils/LocalDB";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";

const useStyles = createStyles((theme, { colors }) => ({
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
}));

export default function Form(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const [newSpaceLoading, setNewSpaceLoading] = useState(false);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const focusTrapRef = useFocusTrap();

  const [publish, setPublish] = useState(false);
  const [defaultOpened, setDefaultOpened] = useState(false);

  const form = useForm({
    initialValues: {
      title: "",
      publish_on_date: false,
      publish_date: new Date(new Date().getTime() + 86400000),
      publish_time: new Date(new Date().getTime() + 86400000),
      content: "",
      default_opened: false,
    },

    validate: {
      title: (value) => (value.length >= 3 ? null : "write a title"),
    },
  });

  useEffect(() => {
    if (props.resource) {
      form.setFieldValue("title", props.resource.title);
      setPublish(props.resource.publish_on_date);
      setDefaultOpened(props.resource.default_opened);
      form.setFieldValue("publish_date", new Date(props.resource.publish_time));
      form.setFieldValue("publish_time", new Date(props.resource.publish_time));
      form.setFieldValue("content", props.resource.content);
    }
  }, [props]);

  useEffect(() => {
    form.setFieldValue("publish_on_date", publish);
  }, [publish]);

  useEffect(() => {
    form.setFieldValue("default_opened", defaultOpened);
  }, [defaultOpened]);

  const onSave = () => {
    if (!props.resource) {
      onCreate();
    } else {
      onUpdate();
    }
  };

  const onCreate = () => {
    if (newSpaceLoading) {
      return;
    }
    setNewSpaceLoading(true);
    const spaceParams = { ...form.values };
    if (form.values.publish_on_date) {
      const _publishDate = new Date(form.values.publish_date);
      const _publishTime = new Date(form.values.publish_time);

      // spaceParams.publish_time = publishDate.toISOString().split("T")[0] +"T"+ publishTime.toISOString().split("T")[1]
      spaceParams.publish_time = new Date(
        `${_publishDate.toLocaleDateStringAbs()}, ${_publishTime.toLocaleTimeString()}`
      ).toISOString();
      spaceParams.publish_on_date = true;
    }

    ResourceModel.onCreate(
      props.parentId,
      spaceParams,
      (data) => {
        props.onSave(data.id);
        clearIndexdb();
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 500);
      },
      (data) => {
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 500);
      }
    );
  };

  const onUpdate = () => {
    if (newSpaceLoading) {
      return;
    }
    setNewSpaceLoading(true);
    const spaceParams = { ...form.values };
    if (form.values.publish_on_date) {
      const _publishDate = new Date(form.values.publish_date);
      const _publishTime = new Date(form.values.publish_time);

      // spaceParams.publish_time = publishDate.toISOString().split("T")[0] +"T"+ publishTime.toISOString().split("T")[1]
      spaceParams.publish_time = new Date(
        `${_publishDate.toLocaleDateStringAbs()}, ${_publishTime.toLocaleTimeString()}`
      ).toISOString();
      spaceParams.publish_on_date = true;
    }

    ResourceModel.onUpdate(
      props.resource.id,
      props.parentId,
      spaceParams,
      (data) => {
        props.onSave(data.id);
        clearIndexdb();
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 500);
      },
      (data) => {
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 500);
      }
    );
  };

  const clearIndexdb = () => {
    const context = props.resource
      ? `edit-resource-${props.parentId}-${props.resource.id}`
      : `new-resource-${props.parentId}`;
    storeJSON("spaces", context, {
      json: "",
      html: "",
    });
  };

  const onContentUpdate = (content) => {
    form.setFieldValue("content", content.content);
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <form
        onSubmit={form.onSubmit((values) => onSave(values))}
        ref={focusTrapRef}
      >
        <Stack spacing={0}>
          <TextInput
            {...form.getInputProps("title")}
            label={t("resources.form.title.label")}
            placeholder={t("resources.form.title.placeholder")}
            data-autofocus
            styles={{
              label: {
                color: colors.default_text_color,
              },
              input: {
                "&:focus": {
                  borderColor: isSpaceThemed(colors)
                    ? colors.primary_button_background_color
                    : theme.colors.sutrablue[3],
                },
              },
            }}
          />
          <Text size="sm" weight={500} mt={20} mb={5}>
            Description
          </Text>
          <TiptapInline
            editable={true}
            content={props.resource ? props.resource.content : ""}
            modals={true}
            onUpdate={onContentUpdate}
            exclude={[]}
            contextDb={
              props.resource
                ? `edit-resource-${props.parentId}-${props.resource.id}`
                : `new-resource-${props.parentId}`
            }
          />
          <Stack mt={20}>
            <Switch
              checked={publish}
              onChange={(event) => setPublish(event.currentTarget.checked)}
              label={t("resources.form.publish.label")}
              styles={{
                label: {
                  color: colors.default_text_color,
                },
              }}
            />
            {form.values.publish_on_date ? (
              <div>
                <Text size="sm" weight={500} mb={4} mt={5}>
                  Publish date and time
                </Text>
                <Group>
                  <DatePicker
                    {...form.getInputProps("publish_date")}
                    placeholder={t("resources.form.date.label")}
                    zIndex={99999}
                  />
                  <TimeInput
                    {...form.getInputProps("publish_time")}
                    format="12"
                  />
                </Group>
              </div>
            ) : (
              ""
            )}
            <Switch
              checked={defaultOpened}
              onChange={(event) =>
                setDefaultOpened(event.currentTarget.checked)
              }
              label={"Default opened"}
              styles={{
                label: {
                  color: colors.default_text_color,
                },
              }}
            />
          </Stack>
          {newSpaceLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <Button
              radius="xl"
              size="md"
              mt={20}
              type="submit"
              className={classes.primaryButtonStyle}
            >
              {props.resource
                ? t("resources.form.update")
                : t("resources.form.create")}
            </Button>
          )}
        </Stack>
      </form>
      <Box mb={100}></Box>
    </ScrollArea>
  );
}
