import React, { useState, useEffect } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setHiddenSidebar } from "../../../store/app";

import { useTranslation } from "react-i18next";

import SpaceReference from "../../users/SpaceReference";
import FixedHeader from "../FixedHeader";

import {
  Box,
  TextInput,
  PasswordInput,
  Checkbox,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Divider,
  Grid,
  Stepper,
  ScrollArea,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useMediaQuery } from "@mantine/hooks";

export default function SignUp(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      password: "",
      subscribe_to_sutra: true,
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim())
          ? null
          : t("users.signUp.invalidEmailErrorMsg"),
      password: (value) =>
        value.length >= 8 ? null : t("users.signUp.invalidPasswordErrorMsg"),
    },
  });

  const [spaceReference, setSpaceReference] = useState();
  const [showStepper, setStepper] = useState(false);
  const [showSpaceReference, setShowSpaceReference] = useState(true);

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 500);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    setSpaceReference(props.space.slug);
  }, [props]);

  const onSignUp = () => {
    localStorage.setItem("dummy_was_registered", true);
    if (props.space.is_paid) {
      navigate(`/space/${props.space.slug}/demo/pay`);
    } else {
      navigate(`/space/${props.space.slug}/demo/edit_profile`);
    }
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={["dummy"]}
        />
        {!smallScreen && spaceReference && showStepper ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={0} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : (
          ""
        )}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <form onSubmit={form.onSubmit((values) => onSignUp(values))}>
                <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                  <Box pt={20} pb={5} pl={35} pr={35}>
                    <Title sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}>
                      {t("users.signUp.title")}
                    </Title>
                    <Text color="dimmed" size="md">
                      {t("users.signUp.subtitle")}{" "}
                      <Link
                        to={`/space/${props.space.slug}/demo/sign_in`}
                        size="sm"
                      >
                        {t("users.signUp.subtitleLink")}
                      </Link>
                    </Text>
                  </Box>
                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                  <Box pt={10} pb={25} pl={35} pr={35}>
                    <TextInput
                      {...form.getInputProps("first_name")}
                      label={t("users.signUp.firstName")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <TextInput
                      {...form.getInputProps("last_name")}
                      label={t("users.signUp.lastName")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <TextInput
                      {...form.getInputProps("username")}
                      label={t("users.signUp.username")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <TextInput
                      {...form.getInputProps("email")}
                      label={t("users.signUp.email")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <PasswordInput
                      {...form.getInputProps("password")}
                      label={t("users.signUp.password")}
                      radius="sm"
                      size="md"
                      mb={20}
                    />
                    {window.location.hostname.indexOf("stablenavel.com") >=
                      0 && (
                      <PasswordInput
                        {...form.getInputProps("password_unicorn")}
                        label={"Beta access code"}
                        radius="sm"
                        size="md"
                        mb={20}
                      />
                    )}

                    {!spaceReference ? (
                      <Checkbox
                        {...form.getInputProps("subscribe_to_sutra", {
                          type: "checkbox",
                        })}
                        style={{ alignItems: "flex-start" }}
                        label={t("users.signUp.emailNewsletterConfirmation")}
                        mb={15}
                      />
                    ) : (
                      ""
                    )}
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      type="submit"
                    >
                      {t("users.signUp.submit")}
                    </Button>
                    <Text size="sm" align="center" mt={15}>
                      {t("users.signUp.legalPrefix")}
                      <br />
                      <Link to="/terms">
                        {t("users.signUp.terms")}
                      </Link> and{" "}
                      <Link to="/privacy">{t("users.signUp.privacy")}</Link>
                    </Text>
                  </Box>
                </Paper>
              </form>
            </Container>
          </Grid.Col>
          {spaceReference && showSpaceReference && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                slug={spaceReference}
                setSpaceReference={setSpaceReference}
                setShowSpaceReference={setShowSpaceReference}
                setStepper={setStepper}
              />
            </Grid.Col>
          )}
        </Grid>
      </Container>
    </ScrollArea>
  );
}
