import { Extension } from '@tiptap/core'

// https://tiptap.dev/guide/custom-extensions#attributes

const CustomRadius = Extension.create({
  name: "customRadius",
  addOptions() {
    return {
      types: [],
    }
  },
  addGlobalAttributes() {

    return [
      {
        // Extend the following extensions
        types: [
          'image',
          'video',
          'embed',
          'file',
          'audio',
          'actionbutton',
          'customform',
          'survey',
          'interactiveButton',
          'interactiveInput',
          'circle'
        ],
        // … with those attributes
        attributes: {
          radius:  {
            default: "no-radius",
            renderHTML: attributes => ({
              radius: `${attributes.radius}`,
            })
          }
        },
      },
    ]
  },
  addCommands() {
    return {
      setCustomRadius: (_radius) => ({ commands }) => {

        return this.options.types.every(type => commands.updateAttributes(type, { radius: _radius }))
      },
    }
  },
})

export default CustomRadius;
