import React, { useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import isSpaceThemed from "../../../utils/IsSpaceThemed";
import { sutraTheme } from "../../../collections/sutraTheme";
import tinycolor from "tinycolor2";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import Uploader from "./Uploader";

import { isDocumentEmpty } from "../utils/selection";

import {
  createStyles,
  Group,
  Button,
  Menu,
  Box,
  ActionIcon,
  MantineProvider,
  Popover,
  Tooltip,
} from "@mantine/core";

const useStyles = createStyles((theme, { colors, sutraTheme }) => ({
  menuButton: {
    borderRadius: 5,
    color: colors.default_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.background_color).darken(4).toString(),
    },
  },
  button: {
    borderRadius: 5,
    marginRight: 10,
    color: colors.default_text_color,

    "&:hover": {
      color: colors.default_text_color,
      backgroundColor: tinycolor(colors.background_color).darken(4).toString(),
    },
  },
  isActive: {
    color: !isSpaceThemed(colors)
      ? sutraTheme.colors.sutrablue[4]
      : colors.sidebar_text_color,
    backgroundColor: !isSpaceThemed(colors)
      ? "#fff"
      : tinycolor(colors.sidebar_background_color).darken(4).toString(),
  },
  sendButtonActive: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  sendButtonNotActive: {
    backgroundColor: tinycolor(colors.primary_button_background_color)
      .darken(20)
      .toString(),
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(20)
        .toString(),
    },
    cursor: "default",
  },
  cancelButton: {
    backgroundColor: colors.secondary_button_background_color,
    color: colors.secondary_button_text_color,
  },
  bottomIcon: {
    color: colors.default_text_color,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 4,
    marginBottom: 4,
    cursor: "pointer",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: tinycolor(colors.sidebar_background_color)
        .darken(4)
        .toString(),
    },
  },
}));

const BlocksMenuSimple = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors, sutraTheme });

  const [openedMedia, setOpenedMedia] = useState(false);
  const [mediaType, setMediaType] = useState("image");
  const [openedEmojis, setOpenedEmojis] = useState(false);

  const onEmojiSelect = (emoji) => {
    props.editor.chain().focus().setEmoji(emoji.id).run();
    setOpenedEmojis(false);
  };

  const setLink = useCallback(() => {
    const previousUrl = props.editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      props.editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    props.editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  }, [props.editor]);

  const setImage = () => {
    setMediaType("image");
    setOpenedMedia(true);
  };

  const setVideo = () => {
    setMediaType("video");
    setOpenedMedia(true);
  };

  const setAudio = () => {
    setMediaType("audio");
    setOpenedMedia(true);
  };

  const setFile = () => {
    setMediaType("file");
    setOpenedMedia(true);
  };

  const onMediaSave = () => {
    setOpenedMedia(false);
  };

  const cleanHtml = (html) => {
    const el = document.createElement("div");
    el.innerHTML = html;
    let lastNonEmptyEl = null;
    let currEl = el.lastElementChild;
    while (currEl) {
      if (
        currEl.textContent.trim().length > 0 ||
        ["img", "video", "audio", "section"].includes(
          currEl.tagName.toLowerCase()
        )
      ) {
        lastNonEmptyEl = currEl;
        break;
      }
      currEl = currEl.previousElementSibling;
    }
    if (lastNonEmptyEl) {
      let currEmptyEl = lastNonEmptyEl.nextElementSibling;
      while (currEmptyEl) {
        const nextEl = currEmptyEl.nextElementSibling;
        if (
          currEmptyEl.textContent.trim().length == 0 &&
          !["img", "video", "audio", "section"].includes(
            currEmptyEl.tagName.toLowerCase()
          )
        ) {
          currEmptyEl.remove();
        }
        currEmptyEl = nextEl;
      }
    }

    return el.innerHTML;
  };

  const onSave = () => {
    if (!isDocumentEmpty(props.editor)) {
      props.onSave(
        props.editor.getJSON(),
        cleanHtml($(props.editor.view.dom).first().html())
      );
      props.editor.commands.setContent("<p></p>");
    }
    props.editor.commands.focus();
  };

  const setShowFormatting = () => {
    props.editor.commands.focus();
    props.setShowFormatting(!props.showFormatting);
  };

  if (!props.editor) {
    return null;
  }

  return (
    <Box mr={10} ml={10} mb={10}>
      <Group spacing={0} position={"apart"}>
        <Group spacing={8}>
          <Menu
            zIndex={99999}
            control={
              <ActionIcon
                variant="transparent"
                sx={{
                  color: colors.default_text_color,
                }}
              >
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={t("tiptap.components.blocksMenuSimple.label1")}
                  withArrow
                  openDelay={400}
                  gutter={10}
                >
                  <FontAwesomeIcon size="xl" icon={solid("circle-plus")} />
                </Tooltip>
              </ActionIcon>
            }
          >
            <Menu.Item
              onClick={() => setFile()}
              icon={<FontAwesomeIcon icon={solid("file")} />}
            >
              {t("tiptap.components.blocksMenuSimple.file")}
            </Menu.Item>
            <Menu.Item
              onClick={() => setImage()}
              icon={<FontAwesomeIcon icon={solid("image")} />}
            >
              {t("tiptap.components.blocksMenuSimple.image")}
            </Menu.Item>
            <Menu.Item
              onClick={() => setVideo()}
              icon={<FontAwesomeIcon icon={solid("video")} />}
            >
              {t("tiptap.components.blocksMenuSimple.video")}
            </Menu.Item>
            <Menu.Item
              onClick={() => setAudio()}
              icon={<FontAwesomeIcon icon={solid("file-audio")} />}
            >
              {t("tiptap.components.blocksMenuSimple.audio")}
            </Menu.Item>
          </Menu>

          <Popover
            opened={openedEmojis}
            onClose={() => setOpenedEmojis(false)}
            zIndex={999999}
            target={
              <Box
                // ml={10}
                className={classes.bottomIcon}
                onClick={() => setOpenedEmojis(!openedEmojis)}
              >
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={t("tiptap.components.blocksMenuSimple.label2")}
                  withArrow
                  openDelay={400}
                  gutter={10}
                >
                  <FontAwesomeIcon size="md" icon={regular("face-smile")} />
                </Tooltip>
              </Box>
            }
            position="bottom"
            withArrow
            width={352}
            styles={{
              popover: {
                order: "none",
                backgroundColor: "rgba(255,255,255,0)",
              },
              wrapper: {
                order: "none",
                backgroundColor: "rgba(255,255,255,0)",
              },
              inner: {
                padding: 0,
                border: "none",
                backgroundColor: "rgba(255,255,255,0)",
              },
              body: {
                border: "none",
                backgroundColor: "rgba(255,255,255,0)",
              },
            }}
          >
            <Picker data={data} onEmojiSelect={onEmojiSelect} />
          </Popover>

          <Popover
            zIndex={99999}
            opened={openedMedia}
            onClose={() => setOpenedMedia(false)}
            target={
              <Box
                // ml={10}
                onClick={() => setImage()}
                className={classes.bottomIcon}
              >
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={t("tiptap.components.blocksMenuSimple.label3")}
                  withArrow
                  openDelay={400}
                  gutter={10}
                >
                  <FontAwesomeIcon size="md" icon={regular("image")} />
                </Tooltip>
              </Box>
            }
            width={400}
            position="top"
            withArrow
          >
            <Uploader
              notMain={true}
              editor={props.editor}
              onSave={onMediaSave}
              uploadType={mediaType}
              exclude={[]}
            />
          </Popover>

          <Box
            // ml={10}
            onClick={() => setShowFormatting()}
            className={classes.bottomIcon}
          >
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              label={t("tiptap.components.blocksMenuSimple.label4")}
              withArrow
              openDelay={400}
              gutter={10}
            >
              <FontAwesomeIcon size="md" icon={solid("font")} />
            </Tooltip>
          </Box>
        </Group>

        <Group spacing={10} position={"right"}>
          {props.editable && props.cancellable ? (
            <MantineProvider theme={{ primaryShade: 6 }}>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                onClick={() => props.onCancel(false)}
              >
                {t("tiptap.components.blocksMenuSimple.cancelButton")}
              </Button>
            </MantineProvider>
          ) : (
            ""
          )}

          <Button
            radius="xl"
            className={
              isDocumentEmpty(props.editor)
                ? classes.sendButtonNotActive
                : classes.sendButtonActive
            }
            leftIcon={<FontAwesomeIcon size="lg" icon={solid("paper-plane")} />}
            onClick={() => onSave()}
          >
            {`${props.saveTitle}`}
          </Button>
        </Group>
      </Group>
    </Box>
  );
};

export default BlocksMenuSimple;
