import React, { useState, useEffect, useRef } from "react";

import {
  Text,
  Box,
  Drawer,
  Select,
  ScrollArea,
  Stack,
  Group,
  Button,
  MultiSelect,
  Divider,
  Loader,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";

import SurveyModel from "../../../../models/Survey";
import SpaceModel from "../../../../models/Space";
import ManagerModel from "../../../../models/Manager";

import Swal from "sweetalert2";

export default function Add(props) {
  const { t, i18n } = useTranslation();

  const [managers, setManagers] = useState([]);

  const [spaces, setSpaces] = useState([]);
  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const [searching, setSearching] = useState(false);

  const searchTimeoutId = useRef();

  const form = useForm({
    initialValues: {
      spaces_ids: "",
      user_id: "",
    },

    validate: {
      spaces_ids: (value) => (value.length > 0 ? null : "Select a space"),
      user_id: (value) => (value.length > 0 ? null : "Select a sender"),
      // structure_message: (value) => value.length > 0 ? null : "Write a message",
    },
  });

  useEffect(() => {
    if (props.spaceId) {
      onFetchSpaces();
      onFetchManagers();
    }
  }, [props]);

  const onFetchSpaces = (query = null) => {
    const include = ["members", "simple"];
    if (query) {
      include.push(`q=${query}`);
    }

    SpaceModel.onFetchChildren(
      props.spaceId,
      include,
      { page: 1, per: 10 },
      ["active", "not_journey", "not_showcases", "not_list", "not_events", "not_interactive"],
      (data) => {
        setSpaces(
          data.data.map((item) => ({ label: item.name, value: item.slug }))
        );
      },
      (data) => {}
    );
  };

  const onFetchManagers = () => {
    ManagerModel.onFetch(
      props.spaceId,
      { page: 1, per: 1000 },
      {},
      (data) => {
        const _managers = data.data.map((manager) => {
          return { label: manager.full_name, value: `${manager.user_id}` };
        });

        setManagers(_managers);
      },
      (data) => {}
    );
  };

  const onSave = () => {
    const params = {
      ...form.values,
    };

    SurveyModel.onAddToSpace(
      props.survey.id,
      props.spaceId,
      params,
      (data) => {
        Swal.fire(data.title, data.message, "success");
        props.setOpened(false);
        onResetForm();
      },
      (data) => {}
    );
  };

  const onResetForm = () => {
    form.setFieldValue("spaces_ids", "");
    form.setFieldValue("user_id", "");
  };

  const onSearchSpaces = (query) => {
    setSearching(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query);
      setSearching(false);
    }, 1000);
  };

  return (
    <Drawer
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {t("spaces.settings.survey.add.add.title")}
        </Text>
      }
      padding="xl"
      size={600}
    >
      <form onSubmit={form.onSubmit((values) => onSave(values))}>
        <Stack justify="space-between">
          <ScrollArea style={{ height: "100%" }}>
            {/*<Textarea
              {...form.getInputProps('structure_message')}
              mt={10}
              autosize
              minRows={4}
              placeholder="Write something..."
              />*/}

            <Box mt={10}>
              <Text size="sm">
                {t("spaces.settings.survey.add.add.spaces")}
              </Text>
              <MultiSelect
                icon={
                  searching ? (
                    <Loader size="xs" />
                  ) : (
                    <FontAwesomeIcon icon={solid("search")} />
                  )
                }
                {...form.getInputProps("spaces_ids")}
                searchable
                mt={5}
                data={spaces}
                placeholder={t("spaces.settings.survey.add.add.placeholder1")}
                maxDropdownHeight={400}
                onSearchChange={(query) => onSearchSpaces(query)}
              />
            </Box>
            <Divider
              mt={20}
              sx={(theme) => ({
                borderTop: `1px solid ${theme.colors.gray[2]}`,
              })}
            />

            <Select
              {...form.getInputProps("user_id")}
              mt={15}
              data={managers}
              label={t("spaces.settings.survey.add.add.label")}
              placeholder={t("spaces.settings.survey.add.add.placeholder2")}
            />
            <Group mt={20}>
              <Button radius="xl" type="submit">
                {t("spaces.settings.survey.add.add.saveButton")}
              </Button>
            </Group>
          </ScrollArea>
        </Stack>
      </form>
    </Drawer>
  );
}
