import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import AuthModel from "../../models/Auth";
import useAuthUser from "../../utils/AuthUser";
import { setUser, setAlert, setHiddenSidebar } from "../../store/app";

import { useTranslation } from "react-i18next";

import SpaceReference from "./SpaceReference";
import PlanReference from "../app/payment/PlanReference";

import AffiliateData from "./AffiliateData";

import {
  Box,
  TextInput,
  PasswordInput,
  Checkbox,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Divider,
  Grid,
  Stepper,
  createStyles,
  ScrollArea,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useMediaQuery } from "@mantine/hooks";

import { Helmet } from "react-helmet";

export default function SignUp(props) {
  const dispatch = useDispatch();
  const queryParams = useLocation().search;
  const { t, i18n } = useTranslation();
  const { setAuthUser } = useAuthUser();

  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      password: "",
      subscribe_to_sutra: true,
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim())
          ? null
          : t("users.signUp.invalidEmailErrorMsg"),
      password: (value) =>
        value.length >= 8 ? null : t("users.signUp.invalidPasswordErrorMsg"),
    },
  });

  const [spaceReference, setSpaceReference] = useState();
  const [planReference, setPlanReference] = useState();
  const [showStepper, setStepper] = useState(false);
  const [showSpaceReference, setShowSpaceReference] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showNewsLetter, setShowNewsLetter] = useState(false);

  const [dialog, setDialog] = useState({
    opened: false,
    title: "",
    message: "",
    color: "indigo",
  });

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 500);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    const spaceReference = new URLSearchParams(queryParams).get("s");
    const planReference = new URLSearchParams(queryParams).get("p");
    let email = new URLSearchParams(queryParams).get("email");
    email = email === null ? "" : email.replace(/ /g, "+"); //https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    form.setFieldValue("email", email);
    setSpaceReference(spaceReference);
    setPlanReference(planReference);
  }, [queryParams]);

  const useStyles = createStyles((theme) => ({
    invalid: {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.fn.rgba(theme.colors.red[8], 0.15)
          : theme.colors.red[0],
    },

    icon: {
      color: theme.colors.red[theme.colorScheme === "dark" ? 7 : 6],
    },
  }));

  const onSignUp = () => {
    try {
      if (!grecaptcha) {
        dispatch(
          setAlert({
            opened: true,
            title: "Something came up...",
            message: "Recaptcha validation failed",
            color: "red",
          })
        );
        return;
      }

      grecaptcha.ready(function () {
        // debugger;
        grecaptcha
          .execute("6LdXdvIhAAAAAM_FJLyngH9fJSnS3XCODE-ouEvI", {
            action: "submit",
          })
          .then(function (token) {
            // debugger
            // Add your logic to submit to your backend server here.
            setLoading(true);
            const user_params = { ...form.values };
            user_params.email = user_params.email.trim();

            if (spaceReference) {
              user_params.subscribe_to_sutra = false;
            }

            AuthModel.onSignUp(
              { ...user_params, recaptcha_token: token },
              { slug: spaceReference, plan: planReference },
              (data) => {
                // Success callback
                // dispatch(setAlert({opened: true, title: data.title, message: data.message, color: "green"}))
                setLoading(false);
                data.user.redirect_to = data.redirect_to;

                setAuthUser(data.user);
                dispatch(setUser(data.user));
              },
              (data) => {
                setLoading(false);
                // Error callback
                dispatch(
                  setAlert({
                    opened: true,
                    title: data.title,
                    message: data.message,
                    color: "red",
                  })
                );
              }
            );
          });
      });
    } catch (err) {
      dispatch(
        setAlert({
          opened: true,
          title: "Something came up...",
          message: "Recaptcha validation failed",
          color: "red",
        })
      );
      return;
    }
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  const recaptchaVerifyCallback = (response) => {};

  const recaptchaOnLoadCallback = () => {};

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{`Sutra - Sign up`}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        {!smallScreen && spaceReference && showStepper ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={0} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : (
          ""
        )}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <form onSubmit={form.onSubmit((values) => onSignUp(values))}>
                <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                  <Box pt={20} pb={5} pl={35} pr={35}>
                    <Title sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}>
                      {t("users.signUp.title")}
                    </Title>
                    <Text color="dimmed" size="md">
                      {t("users.signUp.subtitle")}{" "}
                      <Link to={"/user/sign_in" + queryParams} size="sm">
                        {t("users.signUp.subtitleLink")}
                      </Link>
                    </Text>
                  </Box>
                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                  <Box pt={10} pb={25} pl={35} pr={35}>
                    <TextInput
                      {...form.getInputProps("first_name")}
                      label={t("users.signUp.firstName")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <TextInput
                      {...form.getInputProps("last_name")}
                      label={t("users.signUp.lastName")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <TextInput
                      {...form.getInputProps("username")}
                      label={t("users.signUp.username")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <TextInput
                      {...form.getInputProps("email")}
                      label={t("users.signUp.email")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <PasswordInput
                      {...form.getInputProps("password")}
                      label={t("users.signUp.password")}
                      radius="sm"
                      size="md"
                      mb={20}
                    />
                    {window.location.hostname.indexOf("stablenavel.com") >=
                      0 && (
                      <PasswordInput
                        {...form.getInputProps("password_unicorn")}
                        label={"Beta access code"}
                        radius="sm"
                        size="md"
                        mb={20}
                      />
                    )}

                    {(!spaceReference || showNewsLetter) ? (
                      <Checkbox
                        {...form.getInputProps("subscribe_to_sutra", {
                          type: "checkbox",
                        })}
                        style={{ alignItems: "flex-start" }}
                        label={t("users.signUp.emailNewsletterConfirmation")}
                        mb={15}
                      />
                    ) : (
                      ""
                    )}
                    <Button
                      disabled={loading}
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      type="submit"
                    >
                      {t("users.signUp.submit")}
                    </Button>
                    <Text size="sm" align="center" mt={15}>
                      {t("users.signUp.legalPrefix")}
                      <br />
                      <a href="https://sutra.co/terms" target="_blank">
                        {t("users.signUp.terms")}
                      </a>{" "}
                      and{" "}
                      <a href="https://sutra.co/privacy" target="_blank">
                        {t("users.signUp.privacy")}
                      </a>
                    </Text>
                  </Box>
                  <AffiliateData />
                </Paper>
              </form>
            </Container>
          </Grid.Col>
          {spaceReference && showSpaceReference && spaceReference != "zoom" && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                slug={spaceReference}
                setSpaceReference={setSpaceReference}
                setShowSpaceReference={setShowSpaceReference}
                setStepper={setStepper}
                setShowNewsLetter={setShowNewsLetter}
              />
            </Grid.Col>
          )}
          {planReference && (
            <Grid.Col xs={12} sm={6}>
              <PlanReference plan={planReference} />
            </Grid.Col>
          )}
        </Grid>
        <Box sx={{height: "100px"}}></Box>
      </Container>
    </ScrollArea>
  );
}
