// import axios from 'axios';

class Completion {
  constructor() {
    if (this instanceof Completion) {
      throw Error('Completion class cannot be instantiated.');
    }
  }

  static onFetch = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    }

    await window.$api.get(`/api/v4/spaces/${slug}/completions.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  // TODO:
  static onCreate = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.post(`/api/v4/spaces/${slug}/completions.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {

      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onDestroy = async (completion_id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/completions/${completion_id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }
}

export default Completion;
