class Broadcaster {
  constructor() {
    if (this instanceof Broadcaster) {
      throw Error('Broadcaster class cannot be instantiated.');
    }
  }

  static receive(eventName, element, callback) {

    Broadcaster.register(eventName, element, callback)

    element.addEventListener(eventName, (event) => {
      const responseData = window.$broadcasterEvents[`${eventName}`].data;

      callback(event, responseData);
    }, false);
  }

  static broadcast(eventName, data) {
    
    if (!window.$broadcasterEvents){
      window.$broadcasterEvents = {}
    }

    const event = window.$broadcasterEvents[`${eventName}`];
    if (!event){
      return
    }
    event.data = {};
    const elements = event.elements;

    for (var x = 0; x < elements.length; x++) {
      const element = elements[x].element;

      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        event.data[`${key}`] = data[key];
      }

      const eventToTrigger = new Event(eventName);
      element.dispatchEvent(eventToTrigger);
    }
  }

  static send(eventName, element, data) {

    if (!window.$broadcasterEvents){
      window.$broadcasterEvents = {}
    }

    const event = window.$broadcasterEvents[`${eventName}`];
    if (!event){
      return
    }
    event.data = {};
    const elements = event.elements;

    for (var x = 0; x < elements.length; x++) {
      const _element = elements[x];
      if (_element.element == element){
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];

          event.data[`${key}`] = data[key];
        }

        const eventToTrigger = new Event(eventName);
        element.dispatchEvent(eventToTrigger);
        return;
      }
    }
  }

  static register(eventName, element, callback) {

    if (!window.$broadcasterEvents){
      window.$broadcasterEvents = {}
    }
    if (!window.$broadcasterEvents[`${eventName}`]){
      window.$broadcasterEvents[`${eventName}`] = {
        eventName: eventName,
        elements: [{
          element: element,
          callback: callback
        }],
        data: {}
      }
    }
    else{
      const elements = window.$broadcasterEvents[`${eventName}`].elements;
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].element == element){
          return;
        }
      }

      window.$broadcasterEvents[`${eventName}`].elements.push({
        element: element,
        callback: callback
      })
    }

  }

  static unRegisterAll(eventName) {
    const event = window.$broadcasterEvents[`${eventName}`];
    for (var i = 0; i < event.elements.length; i++) {
      const element = event.elements[i];
      element.element.removeEventListener(`${eventName}`, element.callback);
    }
  }

  static unRegisterElement(eventName, element) {
    const event = window.$broadcasterEvents[`${eventName}`];
    for (var i = 0; i < event.elements.length; i++) {
      const _element = event.elements[i];
      if (_element.element == element){
        _element.element.removeEventListener(`${eventName}`, _element.callback);
        return;
      }
    }

  }
}

export default Broadcaster;
