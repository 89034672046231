import React, { useState, useEffect, useRef, useCallback } from "react";
import Tiptap from "../../../tiptap/components/Tiptap";

import { HocuspocusProvider } from "@hocuspocus/provider";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  setHiddenSidebar,
  setHiddenHeader,
  setForceLayoutUpdate,
} from "../../../../store/app";
import SpaceModel from "../../../../models/Space";

import Loading from "../static/Loading";

import { Stack, ScrollArea, createStyles, Box, Notification } from "@mantine/core";

import { getHocuspocusHost, getEnv } from "../../../../utils/Host";

import { useTranslation } from "react-i18next";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";

import * as Y from "yjs";

import { IndexeddbPersistence } from 'y-indexeddb'

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.registration_page_background_color,
    color: colors.registration_page_default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color,
    },
    ".ProseMirror .file-node svg": {
      stroke: colors.default_text_color,
    },
    ".ProseMirror .file-node a": {
      color: colors.default_text_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.sidebar_text_color,
      backgroundColor: colors.sidebar_background_color,
      
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.default_text_color,
      backgroundColor: isSpaceThemed(colors)
        ? isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(8).toString()
          : tinycolor(colors.background_color).darken(8).toString()
        : tinycolor(colors.background_color).darken(2).toString(),
      border: isSpaceThemed(colors)
        ? `1px solid ${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(20).toString()
              : tinycolor(colors.background_color).darken(20).toString()
          }`
        : `1px solid #e9ecef`,
        "&:hover": {
          // backgroundColor: theme.colors.gray[0],
          backgroundColor: isSpaceThemed(colors)
            ? (isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(10).toString()
              : tinycolor(colors.background_color).darken(10).toString())
            : tinycolor(colors.background_color).darken(2).toString(),
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
          cursor: "pointer",
          // color: "#000",
          textDecoration: "none",
        },
    },
  },
}));

export default function RegistationPage(props) {
  const { t, i18n } = useTranslation();
  const [loaded, setLoaded] = useState();

  const [forcedDisconnected, setForceDisconnect] = useState(false);
  const [unsaveChanges, setUnsaveChanges] = useState(0);

  const [invitation, setInvitation] = useState(null);

  const provider = useRef({
    provider: null,
    status: null,
    documentName: null,
    editable: false,
    unSyncCount: 0,
    forcedDisconnected: false,
  });

  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const forceLayoutUpdate = useSelector((state) => state.forceLayoutUpdate);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlesIntervalId = useRef();
  const layoutUpdateForced = useRef();

  const connectionInterval = useRef();
  const tiptapKey = useRef(`tiptap-${new Date()}`);

  const { classes, cx, theme } = useStyles({ colors });

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    // debugger;
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: true }));
    dispatch(setHiddenHeader({ hidden: true }));

    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
      dispatch(setHiddenHeader({ hidden: true }));
    }, 500);

    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
      dispatch(setHiddenHeader({ hidden: false }));
      dispatch(setForceLayoutUpdate({ update: false }));
      unMount();
    };
  }, []);

  useEffect(() => {
    if (!space.slug) {
      return;
    }
    const header =
      space.permissions && space.permissions.can_manage ? "admin" : false;

      if (window.location.pathname.indexOf("/registration/view") >= 0) {
        dispatch(setHiddenSidebar({ hidden: true }));
          dispatch(setHiddenHeader({ hidden: true }));
    
          setTimeout(() => {
            dispatch(setHiddenSidebar({ hidden: true }));
            dispatch(setHiddenHeader({ hidden: true }));
          }, 500);
      }
      else{

        if (header) {
          dispatch(setHiddenSidebar({ hidden: true }));
          dispatch(setHiddenHeader({ hidden: header }));
    
          setTimeout(() => {
            dispatch(setHiddenSidebar({ hidden: true }));
            dispatch(setHiddenHeader({ hidden: header }));
          }, 500);
        }
      }


    if (props.invitationSlug) {
      onFetchInvitation();
    }

    // Refactor for more elegant wway
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
    }, 500);

    if (window.location.pathname.indexOf("/registration/view") >= 0) {
      const regex = /{{space_name}}/g;
      if (handlesIntervalId.current) {
        return;
      }
      handlesIntervalId.current = setInterval(() => {
        const prosemirrorEditorDivs = document.querySelectorAll("div.is_text");
        console.log("INNNNN: " + prosemirrorEditorDivs);
        if (prosemirrorEditorDivs.length) {
          clearInterval(handlesIntervalId.current);
          for (let i = 0; i < prosemirrorEditorDivs.length; i++) {
            const child = prosemirrorEditorDivs[i];
            if (child && regex.test(`${child.innerHTML}`)) {
              child.innerHTML = `${child.innerHTML}`.replace(regex, space.name);
            }
          }
        }
      }, 100);
    }
  }, [space]);

  useEffect(() => {
    const handleScroll = () => {
      if (location.hash && location.hash.indexOf("jumpTo") !== -1) {
        const uid = location.hash.replace("#jumpTo=", "");

        const checkElementAndScroll = () => {
          let element = document.querySelector(`[data-uid="${uid}"]`) || document.querySelector(`[uid="${uid}"]`);
          
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            window.history.replaceState(null, null, location.pathname);
            return true;
          }
          return false;
        };

        // Check immediately
        if (checkElementAndScroll()) return;

        // Use setInterval to keep checking periodically
        const intervalId = setInterval(() => {
          if (checkElementAndScroll()) {
            clearInterval(intervalId);
          }
        }, 1000);

        // Stop checking after 10 seconds
        const timeoutId = setTimeout(() => {
          clearInterval(intervalId);
        }, 10000);

        // Clear interval and timeout when the component unmounts
        return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };

      }
    };

    handleScroll();

    window.addEventListener('hashchange', handleScroll);

    return () => {
      window.removeEventListener('hashchange', handleScroll);
    };
  }, [location]);

  useEffect(() => {
    provider.current.forcedDisconnected = forcedDisconnected;
  }, [forcedDisconnected]);

  useEffect(() => {
    if (!forceLayoutUpdate.update && !layoutUpdateForced.current) {
      layoutUpdateForced.current = true;
      dispatch(setForceLayoutUpdate({ update: true }));
    }
  }, [forceLayoutUpdate]);

  useEffect(() => {
    const newDocumentName = `circle-${props.documentName}-${params.slug}`;

    if (newDocumentName != provider.current.documentName) {
      provider.current.documentName = `circle-${props.documentName}-${params.slug}`;

      resetProvider();
    }
  }, [params]);

  const unMount = (mount = false) => {
    provider.current.documentName = null;
    provider.current.status = null;
    provider.current.editable = false;
    clearProvider();
  };

  const onFetchInvitation = () => {
    SpaceModel.onFetchSimple(
      params.slug,
      "payment",
      (data) => {
        setInvitation(data);
      },
      (data) => {}
    );
  };

  const clearProvider = () => {
    if (provider.current.provider) {
      provider.current.provider.off("destroy");
      provider.current.provider.off("disconnect");
      provider.current.provider.off("connect");
      provider.current.provider.off("synced");

      provider.current.provider.disconnect();
      provider.current.provider.destroy();
    }
    tiptapKey.current = `tiptap-${new Date()}`;
  };

  const onStatusChange = useCallback((status) => {
    if (status == "connect") {
    } else if (status == "synced") {
      console.log("3. PROVIDER synced: ");

      setTimeout(() => {
        setLoaded(true);
      }, 250);
    } else if (status == "disconnect") {
      console.log("PROVIDER disconnect: ");

      setForceDisconnect(true);
      setUnsaveChanges(provider.current.provider.unsyncedChanges);

      reconnector();
    } else if (status == "destroy") {
    } else if (status == "status") {
    }
  });

  const reconnector = () => {
    if (connectionInterval.current) {
      clearInterval(connectionInterval.current);
    }

    connectionInterval.current = setInterval(() => {
      console.log("PROVIDER reconnecting: " + provider.current.provider.status);
      if (
        provider.current.provider.status == "connected" ||
        provider.current.provider.isSynced
      ) {
        console.log("PROVIDER reconnecting OK: ");
        setForceDisconnect(false);
        clearInterval(connectionInterval.current);
      }
      provider.current.provider.connect();
    }, 1000);
  };

  const resetProvider = () => {
    const yDocument = new Y.Doc();

    new IndexeddbPersistence(provider.current.documentName, yDocument)

    clearProvider();

    provider.current.provider = new HocuspocusProvider({
      url: getHocuspocusHost(),
      name: provider.current.documentName,
      document: yDocument,
      token: window.$currentUserAuthToken,
      broadcast: getEnv() == "production",
      delay: 1000,
      factor: 0,
      forceSyncInterval: 1000,
    });

    provider.current.provider.on("status", () => {
      onStatusChange("status");
    });
    provider.current.provider.on("connect", () => {
      onStatusChange("connect");
    });
    provider.current.provider.on("synced", () => {
      onStatusChange("synced");
    });

    provider.current.provider.on("disconnect", () => {
      onStatusChange("disconnect");
    });

    provider.current.provider.on("destroy", () => {
      onStatusChange("destroy");
    });
  };

  if (!loaded) {
    return <Loading type="content" />;
  }

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea className="content-scrollview" style={{ height: "100%" }}>
        {forcedDisconnected && (
          <Box  sx={{
            width: "300px",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 9999
          }}>
            <Notification
              title={t("spaces.presentations.fallback.title")}
              color="red"
              disallowClose={true}
            >
              {t("spaces.presentations.fallback.message")}
            </Notification>
          </Box>
        )}
        <Tiptap
          key={tiptapKey.current}
          userName={window.$currentUsername}
          editable={props.editable}
          provider={provider.current.provider}
          spaceWidth={window.$registrationWideWidth}
          viewId={"editable_registration_page"}
        />
      </ScrollArea>
    </Stack>
  );
}
