// import axios from 'axios';

class Tag {
  constructor() {
    if (this instanceof Tag) {
      throw Error('Tag class cannot be instantiated.');
    }
  }

  static onFetch = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug
    }

    await window.$api.get(`/api/v4/tags.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onCreate = async (type, pod_message_id, slug, tag, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
      pod_message_id,
      tag,
      type
    }

    await window.$api.post(`/api/v4/tags.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onUpdate = async (id, tag, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      tag,
      global: window.location.pathname.indexOf("settings") >= 0 ? true : null
    }

    await window.$api.put(`/api/v4/tags/${id}.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDestroy = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      global: window.location.pathname.indexOf("settings") >= 0 ? true : null
    }

    await window.$api.delete(`/api/v4/tags/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default Tag;
