import React, { useState, useEffect } from "react";

import { Box, Divider, Title } from "@mantine/core";

import { useTranslation } from "react-i18next";

import SubscriptionModel from "../../../../models/SubscriptionModel";

import SubscriptionItem from "./Item";
import Searchbar from "./Searchbar";

const paginator = {
  per: 30,
  page: 1,
};

const loadingVariables = {
  firstLoad: false,
  reload: true,
  noMore: false,
};

export default function List(props) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ term: null, parent: null });

  const { t, i18n } = useTranslation();

  useEffect(() => {
    onFetch();
  }, [filters]);

  useEffect(() => {
    if (props.onScrollBottom) {
      props.setOnScrollBottom(false);
      onScrollBottom();
    }
  }, [props]);

  const onScrollBottom = () => {
    // if (scroll && scroll.atBottom){
    if (loadingVariables.noMore) {
      return;
    }
    if (!loading) {
      paginator.page++;
      onFetch();
    }
    // }
  };

  const onFetch = () => {
    setLoading(true);

    let newSubs = [...subscriptions];
    if (loadingVariables.reload) {
      newSubs = [];
      paginator.page = 1;
    }

    loadingVariables.reload = false;

    SubscriptionModel.onFetch(filters, paginator.page, (data) => {
      loadingVariables.firstLoad = true;

      setSubscriptions(newSubs.concat(data));
      setLoading(false);

      if (data.length == 0 && paginator.page > 1) {
        paginator.page--;
        loadingVariables.noMore = true;
      }
    });
  };

  const onSearchByTerm = () => {
    loadingVariables.reload = true;

    const newFilters = { ...filters };
    newFilters.term = document.getElementById("search-input").value;

    setFilters(newFilters);
  };

  const onClearSearch = () => {
    loadingVariables.reload = true;

    const newFilters = { ...filters };
    newFilters.term = null;
    document.getElementById("search-input").value = "";

    setFilters(newFilters);
  };

  const onKeyPressed = (event) => {
    if (event.key === "Enter") {
      onSearchByTerm();
    }
  };

  const onUpdateFrequency = (circleId, frequency) => {
    SubscriptionModel.onUpdateFrequency(circleId, frequency, (res) => {});
  };

  const onLeave = (circleId) => {
    SubscriptionModel.onLeave(circleId, (res) => {});
  };

  return (
    <Box pt={40} pb={40}>
      <Title sx={(theme) => ({ fontWeight: 600 })} order={2}>
        {t("settings.subscriptions.subscriptions")}
      </Title>

      <Divider
        sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
        mt={10}
        mb={20}
      />
      <Searchbar
        onKeyPressed={onKeyPressed}
        onSearchByTerm={onSearchByTerm}
        onClearSearch={onClearSearch}
      />

      <Box mt={20}>
        {subscriptions.map((space) => {
          return (
            <SubscriptionItem
              key={`subscription-item-${space.slug}`}
              parents={space.parents}
              spaceId={space.slug}
              spaceLink={space.actions.main}
              spaceName={space.intention}
              subscriptionType={space.subscription_type}
              currentFrequency={space.current_frequency}
              broadcastExcluded={space.broadcast_excluded}
              isInteractive={space.is_interactive}
              userEmail={props.userEmail}
              onUpdateFrequency={onUpdateFrequency}
              onLeave={onLeave}
            />
          );
        })}
      </Box>
      {subscriptions.length == 0 && loadingVariables.firstLoad && (
        <Box
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "center",
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          {t("settings.subscriptions.emptySubscriptions")}
        </Box>
      )}
    </Box>
  );
}
