import React, { useState, useEffect } from "react";

import { Container, Button, useMantineTheme, ScrollArea } from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SectionHeader from "./layout/SectionHeader";

import CreateStructure from "./structures/CreateStructure";
import ShowStructure from "./structures/ShowStructure";
import AddToSpaces from "./structures/Add";
import StructureItem from "./structures/StructureItem";

import StructureModel from "../../../models/Structure";

import { sutraTheme } from "../../../collections/sutraTheme";

export default function Structures(props) {
  const theme = useMantineTheme();
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const [structures, setStructures] = useState([]);
  const [createStructureOpened, setCreateStructureOpened] = useState(false);
  const [showStructureOpened, setShowStructureOpened] = useState(false);
  const [showAddToSpaces, setShowAddToSpaces] = useState(false);
  const [selectedStructure, setSelectedStructure] = useState(null);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [space]);

  useEffect(() => {
    if (!createStructureOpened) {
      setSelectedStructure(null);
      if (space && space.slug) {
        onFetch();
      }
    }
  }, [createStructureOpened]);

  const onFetch = () => {
    StructureModel.onFetch(
      space.slug,
      { page: 1, per: 10000 },
      (data) => {
        setStructures(data.data);
      },
      (data) => {}
    );
  };

  const onUpdateStructure = (structure) => {
    onFetch();
  };

  const onEditStructure = (structure) => {
    setSelectedStructure(structure);
    setCreateStructureOpened(structure);
  };

  const onSelectedStructure = (structure) => {
    setSelectedStructure(structure);
    setShowStructureOpened(structure);
  };

  const onAddStructure = (structure) => {
    setSelectedStructure(structure);
    setShowAddToSpaces(true);
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container
        pt={20}
        pb={40}
        pl={40}
        pr={40}
        sx={(theme) => ({
          // width: "960px",
          // maxWidth: "960px",
        })}
      >
        {props.scope != "payment" && (
          <SectionHeader
            spaceId={space.slug}
            spaceVersion={space.version}
            spaceName={space.name}
            title={t("spaces.settings.structures.title")}
            rightSide={
              <Button
                variant="filled"
                radius="xl"
                onClick={() => setCreateStructureOpened(true)}
                leftIcon={<FontAwesomeIcon icon={solid("pencil")} />}
                sx={{
                  backgroundColor: sutraTheme.colors.sutrablue[3],
                  "&:hover": {
                    backgroundColor: sutraTheme.colors.sutrablue[4],
                  },
                }}
              >
                {t("spaces.settings.structures.createStructure")}
              </Button>
            }
          />
        )}
        {structures.map((structure) => {
          return (
            <StructureItem
              spaceId={space.slug}
              onUpdate={onUpdateStructure}
              onEdit={onEditStructure}
              structure={structure}
              opened={showStructureOpened}
              setOpened={setShowStructureOpened}
              onSelect={onSelectedStructure}
              onAdd={onAddStructure}
            />
          );
        })}
        <CreateStructure
          opened={createStructureOpened}
          setOpened={setCreateStructureOpened}
          structure={selectedStructure}
          spaceId={space.slug}
          onUpdate={onUpdateStructure}
          acceptPayments={
            space.permissions ? space.permissions.can_accept_charges : false
          }
        />
        <ShowStructure
          opened={showStructureOpened}
          setOpened={setShowStructureOpened}
          structure={selectedStructure}
          spaceId={space.slug}
        />
        <AddToSpaces
          opened={showAddToSpaces}
          setOpened={setShowAddToSpaces}
          structure={selectedStructure}
          spaceId={space.slug}
        />
      </Container>
    </ScrollArea>
  );
}
