import React, { useState, useEffect } from "react";

import {
  Button,
  Grid,
  Group,
  Menu,
  Text,
  Title,
  Box,
  Divider,
  MantineProvider,
} from "@mantine/core";

import { Link } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import useFrequencyOptions from "../../../collections/FrequencyOptions";
import { useTranslation } from "react-i18next";
import { colors } from "../../../collections/colors";
import BroadcastModel from "../../../models/Broadcast";

import Swal from "sweetalert2";

export default function Item(props) {
  const [currentFrequency, setCurrentFrequency] = useState(-1);
  const [active, setActive] = useState(true);
  const [excluded, setExcluded] = useState(true);
  const { frequencyOptions } = useFrequencyOptions(props.isInteractive ? [1, 2, 3, 4, 5] : null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setCurrentFrequency(props.currentFrequency);
  }, []);

  useEffect(() => {
    setExcluded(props.broadcastExcluded);
  }, [props]);

  const onUpdateFrequency = (event, frequency) => {
    event.preventDefault();

    props.onUpdateFrequency(props.spaceId, frequency);
    setCurrentFrequency(frequency);
  };

  const onLeave = (event) => {
    event.preventDefault();
    Swal.fire({
      title: t("settings.subscriptions.leaveSpaceConfirmationTitle"),
      html: t("settings.subscriptions.leaveSpaceConfirmationHtml"),
      showDenyButton: true,
      confirmButtonText: t("settings.subscriptions.leaveSpaceButtonText"),
      denyButtonText: t("settings.subscriptions.leaveSpaceDenyButtonText"),
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        onLeaveConfirmation();
      } else if (result.isDenied) {
      }
    });
  };

  const onLeaveConfirmation = () => {
    props.onLeave(props.spaceId);
    setActive(false);
  };

  const getCurrentFrequencyLabel = () => {
    for (let i = 0; i < frequencyOptions.length; i++) {
      if (frequencyOptions[i].value == currentFrequency) {
        return frequencyOptions[i].label;
      }
    }

    return t("settings.subscriptions.options");
  };

  const extraSmall = useMediaQuery("(max-width: 576px)");

  const onInclude = () => {
    BroadcastModel.onInclude(
      props.spaceId,
      props.userEmail,
      (data) => {
        setExcluded(false);
      },
      (data) => {}
    );
  };

  const onExclude = () => {
    BroadcastModel.onExclude(
      props.spaceId,
      props.userEmail,
      (data) => {
        setExcluded(true);
      },
      (data) => {}
    );
  };

  return (
    <Box>
      <Divider
        sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
        mt={10}
        mb={10}
      />
      <Grid grow>
        <Grid.Col xs={2} sm={4} lg={9}>
          {props.parents.map((parent, index) => {
            return (
              <>
                <Text color="dimmed" component={Link} to={parent.link}>
                  {parent.name}
                </Text>
                {index < props.parents.length - 1 && (
                  <Text color="dimmed" component={Link} to={parent.link}>
                    {", "}
                  </Text>
                )}
              </>
            );
          })}
          <Box>
            <Title
              sx={(theme) => ({ color: theme.colors.sutrablue[4] })}
              component={Link}
              to={props.spaceLink}
              order={3}
            >
              {props.spaceName}
            </Title>
          </Box>
        </Grid.Col>
        <Grid.Col span={2}>
          <Group position={extraSmall ? "left" : "right"}>
            {active ? (
              <Menu
                placement="end"
                control={
                  <Button radius="xl" variant="outline">
                    {getCurrentFrequencyLabel()}
                  </Button>
                }
              >
                <Menu.Label>
                  {t("settings.subscriptions.frequencyOptions")}
                </Menu.Label>
                {props.subscriptionType == "member" ? (
                  <>
                    {frequencyOptions.map((option) => {
                      return (
                        <Menu.Item
                          icon={
                            option.value == currentFrequency ? (
                              <FontAwesomeIcon icon={solid("check")} />
                            ) : (
                              " "
                            )
                          }
                          onClick={(event) =>
                            onUpdateFrequency(event, option.value)
                          }
                        >
                          {option.label}
                        </Menu.Item>
                      );
                    })}
                    <Menu.Item icon={" "} onClick={(event) => onLeave(event)}>
                      {t("settings.subscriptions.leaveSpace")}
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Label>
                      {t("settings.subscriptions.otherActions")}
                    </Menu.Label>
                    <Menu.Item
                      icon={<FontAwesomeIcon icon={solid("arrow-left")} />}
                      onClick={(event) => onLeave(event)}
                    >
                      {t("settings.subscriptions.unsubscribe")}
                    </Menu.Item>
                  </>
                )}
              </Menu>
            ) : (
              <MantineProvider theme={{ primaryShade: 8 }}>
                <Button color="red" radius="xl" variant="outline">
                  {t("settings.subscriptions.unsubscribed")}
                </Button>
              </MantineProvider>
            )}
          </Group>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
