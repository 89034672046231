import axios from 'axios';

class Message {
  constructor() {
    if (this instanceof Message) {
      throw Error('Message class cannot be instantiated.');
    }
  }

  static onFetch = async (slug, _params, page, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      page: page,
      space_id: slug,
      ..._params,
      render_as: "index",
      update_last_seen: _params.update_last_seen == false ? null : true
    }

    await axios.get(`/api/v4/messages.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onFetchById = async (id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug
    }

    await axios.get(`/api/v4/messages/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onCreate = async (slug, {json, html, message_type, privacy, auto_tag}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      content: json,
      message_body: html,
      space_id: slug,
      message_type,
      privacy,
      auto_tag
    }

    await axios.post(`/api/v4/messages.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onUpdate = async (id, {json, html}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      content: json,
      message_body: html,
    }

    await axios.put(`/api/v4/messages/${id}.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onMakePublic = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    }

    await axios.post(`/api/v4/messages/${id}/make_public.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onMakePrivate = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    }

    await axios.post(`/api/v4/messages/${id}/make_private.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onPinMessage = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await axios.post(`/api/v4/messages/${id}/pin_message.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onDestroy = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await axios.delete(`/api/v4/messages/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onUnread = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
    }

    await axios.get(`/api/v4/messages/unread.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onFetchAiSummary = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
    }

    await axios.get(`/api/v4/messages/ai_summary.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onAiSummaryTrigger = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
    }

    await axios.post(`/api/v4/messages/ai_summary_trigger.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onHighlightEvent = async (slug, id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
    }

    await axios.post(`/api/v4/messages/${id}/highlight_event.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onGenerateContext = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
    }

    await axios.post(`/api/v4/messages/generate_ai_context.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onSuggestedPrompt = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
    }

    await axios.post(`/api/v4/messages/generate_ai_prompt.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onDummyPosts = async (slug, email, prompt, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
      email,
      prompt
    }

    await axios.post(`/api/v4/messages/generate_ai_dummy_posts.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onTriggerActions = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug
    }

    await axios.post(`/api/v4/messages/trigger_ai_actions.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onTriggerDispatcher = async (slug, email, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
      email
    }

    await axios.post(`/api/v4/messages/trigger_ai_dispatcher.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onTriggerAction = async (slug, email, action_slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
      action_slug,
      email
    }

    await axios.post(`/api/v4/messages/trigger_ai_action.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onGetMemberActions = async (slug, email, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id: slug,
      email
    }

    await axios.get(`/api/v4/messages/get_member_actions.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }
}

export default Message;
