import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setTiptapSidemenu } from "../../../store/app";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import { isEmptySelection, updateInteractionsPositions, closestInteraction } from "../utils/selection";

import { Group, Box, Paper, Tooltip, createStyles, Modal } from "@mantine/core";

import SpaceModel  from "../../../models/Space";
import MediaFileModel  from "../../../models/MediaFile";
import useSidebarStyles from "../../../utils/SidebarStyles";
import defaultSpaceColors from "../../../collections/defaultSpaceColors";
import tinycolor from "tinycolor2";

// import SpaceIcon from "../../../src/images/buttons/spaceIcon.png"
import SpacesIcon from "../../../src/images/buttons/spacesIcon.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import BackupsList from "./BackupsList";
import RegPageColors from "./RegPageColors";

import PremiumModal from "../../app/PremiumModal";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  moreButton: {
    color: colors.default_text_color,
    border: `solid 1px ${theme.colors.gray[7]}`,
    backgroundColor: theme.colors.gray[7],
    paddingTop: 15,
    paddingBottom: 15,
    // paddingLeft: 20,
    // paddingRight: 20,
    borderRadius: 100,
    textAlign: "center",
    cursor: "pointer",
    width: 95,

    "&:hover": {
      border: `solid 1px ${theme.colors.gray[8]}`,
      backgroundColor: theme.colors.gray[8],
    },
  },
  buttonItem: {
    // color: colors.default_text_color,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    margin: 5,
    borderRadius: 50,
    width: 100,
    textAlign: "center",
    cursor: "pointer",
    color: isSpaceThemed(colors)
      ? `${tinycolor(colors.default_text_color).lighten(30).toString()}`
      : `${tinycolor(colors.default_text_color).lighten(70).toString()}`,
    "&:hover": {
      color: colors.default_text_color,
    },
    // "&:hover": {
    //   backgroundColor: tinycolor(colors.sidebar_background_color).darken(4).toString()
    // },
  },
  buttonText: {
    userSelect: "none",
    // color: colors.sidebar_text_color
  },
  buttonIcon: {
    fontSize: 30,
    margin: 0,
    padding: 0,
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // buttonWrap2: {
  //   border: `dashed 1px ${theme.colors.gray[5]}`,
  //   borderRadius: 50,
  //   backgroundColor: theme.colors.gray[0]
  // },
  buttonWrap: {
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    backgroundColor: colors.sidebar_background_color,
    marginBottom: -3,
    border: `1px solid ${tinycolor(colors.sidebar_background_color)
      .darken(4)
      .toString()}`,
  },

  menuPanel: {
    // border: `1px solid ${tinycolor(colors.sidebar_background_color).darken(4).toString()}`,
    // marginTop: 50,
    borderRadius: 10,
    border: `1px solid ${tinycolor(colors.default_text_color)
      .lighten(90)
      .toString()}`,
    backgroundColor: "transparent",
  },

  menuWrap: {
    paddingBottom: 0,
    paddingTop: 50,
  },
}));

const FixedBottomMenu = (props) => {
  const { t, i18n } = useTranslation();
  const { registrationView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  colors = !registrationView ? colors : defaultSpaceColors;
  const { classes, cx, theme } = useStyles({ colors });

  const tiptap = useSelector((state) => state.tiptap);

  const space = useSelector((state) => state.space);

  const [showBlocksMenu, setShowBlocksMenu] = useState(false);
  const [backupsOpened, setBackupsOpened] = useState(false);
  const [colorsOpened, setColorsOpened] = useState(false);
  const [openedPremium, setOpenedPremium] = useState(false);

  const dispatch = useDispatch();

  const shouldShow = (view, state) => {
    // const { selection } = state
    // const { $anchor, empty } = selection
    // const isRootDepth = $anchor.depth === 1
    // const isEmptyTextBlock = $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && !$anchor.parent.textContent
    //
    // if (!view.hasFocus() || !empty || !isRootDepth || !isEmptyTextBlock || !props.editor.isActive('paragraph')) {
    //   if (showBlocksMenu){
    //     setShowBlocksMenu(false);
    //   }
    //   return false
    // }

    return true;
  };

  const insertIj = () => {
    if (window.location.pathname.indexOf("/registration") >= 0) {
      return;
    }
    AddLineBelowSelection();

    dispatch(
      setTiptapSidemenu({
        opened: "loading",
        text: "Discussion prompt is loading, please wait…",
      })
    );

    const spaceName =
      space.name < 90
        ? `${space.name} prompt`
        : `${space.name.slice(0, 90)}... prompt`;

    SpaceModel.onCreate(
      { intention: spaceName, version: "interactive-discussion" },
      space.id,
      (data) => {
        const interaction = closestInteraction(
          tiptap.editor,
          tiptap.editor.view.state.selection.to
        );

        const nextPos = interaction ? interaction.interactivePosition + 1 : 0;

        tiptap.editor
          .chain()
          .addNewEmptyLine()
          .insertContent(
            `<interactiveinput interactiveInputLabel="How do you feel?" interactiveInputPlaceholder="Write something..." interactivePosition="${nextPos}" buttonText="Send" buttonBackgroundColor="${colors.primary_button_background_color}" buttonBorderColor="${colors.primary_button_background_color}" buttonTextColor="${colors.primary_button_text_color}" id="${data.slug}" circle_id="${space.slug}" radius="circle-radius" ></interactiveinput><p></p>`
          )
          .run();

        dispatch(setTiptapSidemenu({ opened: null }));

        setTimeout(() => {
          updateInteractionsPositions(tiptap.editor);
        }, 500);

        if (!window.$spaceUserInteractions) {
          window.$spaceUserInteractions = {
            position: 9999,
            can_manage: true,
            count: 1,
          };
        }
        setTimeout(() => {
          onTranscriptsTrigger();
        }, 3000);
      },
      (data) => {
        dispatch(setTiptapSidemenu({ opened: null }));
        if (data.permissions) {
          setOpenedPremium([true, data.permissions[1], data.permissions[2]]);
        }
      }
    );
    return;
  };

  const onTranscriptsTrigger = () => {
    const doc = tiptap.editor.view.state.doc;

    doc.nodesBetween(0, doc.content.size, (node, pos) => {
      if (node.type.name == "embed") {
        const mediaFile = {
          original_url: node.attrs.src,
          url: node.attrs.src,
          upload_type: "embed",
          slug: window.$currentSlug,
          embed_id: node.attrs.videoId,
          apify: true,
        };

        MediaFileModel.onCreate(
          mediaFile,
          (data) => {
            console.log("MEDIA FILE STORED");
          },
          (data) => {}
        );
      }
    });
  };

  const AddLineBelowSelection = () => {
    const { from, to, $anchor } = tiptap.editor.view.state.selection;

    if (!isEmptySelection(tiptap.editor)) {
      tiptap.editor.commands.addNewEmptyLine();
      window.$tiptapLastSelectionRange = { from: to + 1, to: to + 1 };

      // tiptap.editor.chain().focus().setTextSelection({ from: to+1, to: to+1 }).run()
    } else {
      window.$tiptapLastSelectionRange = { from: to, to: to };
    }
  };

  const insertCircle = (event) => {
    event.stopPropagation();
    event.preventDefault();
    AddLineBelowSelection();

    dispatch(setTiptapSidemenu({ opened: "newSpace", option: "content" }));
  };

  const openBlocksSideMenu = (event, option = null) => {
    event.stopPropagation();
    event.preventDefault();
    AddLineBelowSelection();
    if (option == "Templates" || option == "Layouts"){
      dispatch(setTiptapSidemenu({ opened: "templatesModal", option: option }));
    }
    else{
      dispatch(setTiptapSidemenu({ opened: "blocks", option: option }));
    }
    
  };

  const openPageColorsMenu = (event, option = null) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(setTiptapSidemenu({ opened: "regPageColors", option: option }));
  };

  const onOpenBackups = () => {};

  if (!tiptap.editor) {
    return null;
  }
  if (space.slug && space.is_post) {
    return null;
  }

  return (
    <div className={`${classes.menuWrap} tiptap-bottom-floatin-menu`}>
      <Paper
        // shadow="sm"
        // radius="xl"
        // withBorder={isSpaceThemed ? false : true}
        className={classes.menuPanel}
      >
        <Group position="center" noWrap spacing={0}>
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            openDelay={400}
            label={t("tiptap.components.fixedBottomMenu.label1")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <Box
              className={classes.buttonItem}
              onClick={(event) => openBlocksSideMenu(event)}
            >
              <div className={classes.buttonIcon}>
                <FontAwesomeIcon icon={solid("plus")} />
              </div>
              <div className={classes.buttonText}>
                {t("tiptap.components.fixedBottomMenu.addBlock")}
              </div>
            </Box>
          </Tooltip>
          {window.location.pathname.indexOf("/registration") < 0 && (
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              openDelay={400}
              label={"Add a discussion prompt"}
              position="bottom"
              withArrow
              zIndex={999999}
            >
              <Box
                className={classes.buttonItem}
                onClick={(event) => insertIj(event)}
              >
                <div className={classes.buttonIcon}>
                  <FontAwesomeIcon icon={regular("comment")} />
                </div>
                <div className={classes.buttonText}>
                  {"Discussion"}
                </div>
              </Box>
            </Tooltip>
          )}
          {window.location.pathname.indexOf("/registration") < 0 && (
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              openDelay={400}
              label={t("tiptap.components.fixedBottomMenu.label2")}
              position="bottom"
              withArrow
              zIndex={999999}
            >
              <Box
                className={classes.buttonItem}
                onClick={(event) => insertCircle(event)}
              >
                <div className={classes.buttonIcon}>
                  <SpacesIcon />
                  {/*
                    <Image
                    width={"35px"}
                    src={SpaceIcon}
                    />
                    */}
                </div>
                <div className={classes.buttonText}>
                  {t("tiptap.components.fixedBottomMenu.space")}
                </div>
              </Box>
            </Tooltip>
          )}

          {/*


              <Box
              className={classes.buttonItem}
              onClick={(event) => openBlocksSideMenu(event, "Text")}
              >
              <div
              className={classes.buttonIcon}
              >
              <FontAwesomeIcon icon={solid('font')} />
              </div>
              <div
              className={classes.buttonText}
              >
              Heading
              </div>
              </Box>
              <Box
              className={classes.buttonItem}
              onClick={(event) => openBlocksSideMenu(event, "Media")}
              >
              <div
              className={classes.buttonIcon}
              >
              <FontAwesomeIcon icon={regular('image')} />
              </div>
              <div
              className={classes.buttonText}
              >
              Image
              </div>
              </Box>
              <Box
              className={classes.buttonItem}
              onClick={(event) => openBlocksSideMenu(event, "Media")}
              >
              <div
              className={classes.buttonIcon}
              >
              <FontAwesomeIcon icon={solid('video')} />
              </div>
              <div
              className={classes.buttonText}
              >
              Video
              </div>
              </Box>


              */}

          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            openDelay={400}
            label={t("tiptap.components.fixedBottomMenu.label3")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <Box
              className={classes.buttonItem}
              onClick={(event) => openBlocksSideMenu(event, "Grids")}
            >
              <div className={classes.buttonIcon}>
                <FontAwesomeIcon icon={solid("border-all")} />
              </div>
              <div className={classes.buttonText}>
                {t("tiptap.components.fixedBottomMenu.grid")}
              </div>
            </Box>
          </Tooltip>
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            openDelay={400}
            label={t("tiptap.components.fixedBottomMenu.label5")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <Box
              className={classes.buttonItem}
              onClick={(event) => openBlocksSideMenu(event, "Templates")}
            >
              <div className={classes.buttonIcon}>
                <FontAwesomeIcon icon={solid("map")} />
              </div>
              <div className={classes.buttonText}>
                {t("tiptap.components.fixedBottomMenu.template")}
              </div>
            </Box>
          </Tooltip>
        </Group>
      </Paper>
      <Modal
        opened={backupsOpened}
        onClose={() => setBackupsOpened(false)}
        title="Changes log"
      >
        <BackupsList />
      </Modal>
      <RegPageColors
        opened={colorsOpened}
        onClose={() => setColorsOpened(false)}
      />
      <PremiumModal opened={openedPremium} setOpened={setOpenedPremium} />
    </div>
  );
};

export default FixedBottomMenu;
