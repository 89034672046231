import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  ScrollArea,
  Stack,
  ColorInput,
  InputWrapper,
  Box,
  Group,
  Accordion,
  Image,
  Text,
  Grid,
  MantineProvider,
  createStyles,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import SpaceModel from "../../../models/Space";
import HeaderAndLogo from "./HeaderAndLogo";
import PageFormat from "./PageFormat";
import PageLayout from "./PageLayout";

import { useForm } from "@mantine/form";

import { setColors } from "../../../store/app";

import defaultSpaceColors from "../../../collections/defaultSpaceColors";

import Swal from "sweetalert2";

import PremiumModal from "../../app/PremiumModal";

const useStyles = createStyles((theme) => ({
  item: {
    borderBottom: "none",
  },
  control: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 16,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    // marginRight: 8,
    // marginLeft: -5
  },
  content: {
    backgroundColor: theme.colors.gray[0],
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
  },
  contentInner: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function Form(props) {
  const { classes, cx, theme } = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);

  const [themes, setThemes] = useState({});
  const [selectedTheme, setSelectedTheme] = useState(null);

  const [opened, setOpened] = useState(false);
  const [validatePremiumTheme, setValidatePremiumTheme] = useState(false);
  const [validatePremiumLayout, setValidatePremiumLayout] = useState(false);
  const [validatePremiumFormat, setValidatePremiumFormat] = useState(false);

  useEffect(() => {
    onFetch();
  }, []);

  useEffect(() => {
    onSelectedTheme(colors);
  }, [colors]);

  useEffect(() => {
    setValidatePremiumTheme(
      space && space.permissions && space.permissions.can_set_theme
    );
    setValidatePremiumLayout(
      space && space.permissions && space.permissions.can_set_layout
    );
    setValidatePremiumFormat(
      space && space.permissions && space.permissions.can_set_format
    );
  }, [space]);

  const form = useForm({
    initialValues: {
      ...defaultSpaceColors,
    },
  });

  const onFetch = () => {
    SpaceModel.onFetchThemes(
      (data) => {
        setThemes(data);
      },
      (data) => {}
    );
  };

  const onSelectedTheme = (theme) => {
    setSelectedTheme(theme.id);

    form.setFieldValue("header_color", theme.header_color);
    form.setFieldValue("header_link_color", theme.header_link_color);
    form.setFieldValue(
      "primary_button_background_color",
      theme.primary_button_background_color
    );
    form.setFieldValue(
      "primary_button_text_color",
      theme.primary_button_text_color
    );
    form.setFieldValue(
      "secondary_button_background_color",
      theme.secondary_button_background_color
    );
    form.setFieldValue(
      "secondary_button_text_color",
      theme.secondary_button_text_color
    );
    form.setFieldValue("background_color", theme.background_color);
    form.setFieldValue("default_text_color", theme.default_text_color);
    form.setFieldValue(
      "secondary_background_color",
      theme.secondary_background_color
    );
    form.setFieldValue("sub_header_text_color", theme.sub_header_text_color);
    form.setFieldValue(
      "sidebar_background_color",
      theme.sidebar_background_color
    );
    form.setFieldValue("sidebar_text_color", theme.sidebar_text_color);
    form.setFieldValue("default_link_color", theme.default_link_color);
    form.setFieldValue("default_badge_color", theme.default_badge_color);
    form.setFieldValue(
      "default_badge_text_color",
      theme.default_badge_text_color
    );
  };

  const onSave = (values) => {
    if (!validatePremiumTheme[0]) {
      setOpened([true, validatePremiumTheme[1], validatePremiumTheme[2]]);
      return;
    }
    SpaceModel.onSaveColors(
      space.slug,
      values,
      (data) => {
        dispatch(setColors({ ...values }));
      },
      (data) => {
        Swal.fire(data.title, data.message, "error");
      }
    );
  };

  const onReset = () => {
    SpaceModel.onSaveColors(
      space.slug,
      defaultSpaceColors,
      (data) => {
        dispatch(setColors({ ...defaultSpaceColors }));
      },
      (data) => {
        Swal.fire(data.title, data.message, "error");
      }
    );
  };

  return (
    <Box style={{ height: "100%", paddingBottom: "40px" }}>
      <form
        onSubmit={form.onSubmit((values) => onSave(values))}
        style={{ height: "100%" }}
      >
        <Stack spacing={0} style={{ height: "100%" }}>
          <ScrollArea style={{ height: "100%" }}>
            <Accordion
              offsetIcon={false}
              iconPosition="right"
              multiple
              mb={100}
              classNames={{
                item: classes.item,
                control: classes.control,
                icon: classes.icon,
                content: classes.content,
                contentInner: classes.contentInner,
              }}
            >
              <Accordion.Item label="Cover image and logo">
                <HeaderAndLogo />
              </Accordion.Item>
              <Accordion.Item
                label={
                  validatePremiumTheme[0] ? (
                    <Text>{t("spaces.colors.form.colorTheme")}</Text>
                  ) : (
                    <Group spacing={10}>
                      <Text>{t("spaces.colors.form.colorTheme")}</Text>
                      <Box
                        sx={(theme) => ({
                          padding: "3px 3px",
                          backgroundColor: theme.colors.green[6],
                          color: "#fff",
                          borderRadius: 10,
                          fontSize: 10,
                        })}
                      >
                        <FontAwesomeIcon icon={solid("star")} />
                      </Box>
                    </Group>
                  )
                }
              >
                <Grid>
                  {Object.keys(themes).map((theme) => {
                    return (
                      <Grid.Col xs={4}>
                        <Box
                          class={`${
                            selectedTheme == themes[theme].id
                              ? "theme-selected"
                              : ""
                          }`}
                          onClick={(event) => onSelectedTheme(themes[theme])}
                        >
                          <Image
                            radius="md"
                            src={`../../../themes/${themes[theme].file_name}`}
                            alt={themes[theme].title}
                          />
                          <Text>{themes[theme].title}</Text>
                        </Box>
                      </Grid.Col>
                    );
                  })}
                </Grid>

                <Accordion
                  iconSize={32}
                  offsetIcon={false}
                  mt={20}
                  sx={(theme) => ({
                    borderRadius: 10,
                    borderBottom: "none",
                    border: "solid 1px #f1f1f1",
                  })}
                  styles={{
                    item: {
                      borderBottom: "none",
                    },
                    control: {
                      borderRadius: 10,
                    },
                  }}
                >
                  <Accordion.Item label={t("spaces.colors.form.customize")}>
                    <Box style={{ paddingBottom: "20px" }}>
                      {Object.keys(defaultSpaceColors).map((inputName) => {
                        return (
                          <InputWrapper
                            mt={10}
                            id="input-demo"
                            label={t(`spaces.colors.${inputName}.label`)}
                            // description={t(`spaces.colors.${inputName}.description`)}
                          >
                            <ColorInput
                              zIndex={9999}
                              {...form.getInputProps(inputName)}
                            />
                          </InputWrapper>
                        );
                      })}
                    </Box>
                  </Accordion.Item>
                </Accordion>
                <Box mt={20}>
                  <Group>
                    <Button radius="xl" type="submit">
                      {t("spaces.colors.form.saveTheme")}
                    </Button>
                    <MantineProvider theme={{ primaryShade: 6 }}>
                      <Button
                        radius="xl"
                        color="gray"
                        onClick={() => onReset()}
                      >
                        {t("spaces.colors.form.resetColor")}
                      </Button>
                    </MantineProvider>
                  </Group>
                </Box>
              </Accordion.Item>

              <Accordion.Item
                label={
                  validatePremiumLayout[0] ? (
                    <Text>{t("spaces.colors.form.pageLayout")}</Text>
                  ) : (
                    <Group spacing={10}>
                      <Text>{t("spaces.colors.form.pageLayout")}</Text>
                      <Box
                        sx={(theme) => ({
                          padding: "3px 3px",
                          backgroundColor: theme.colors.green[6],
                          color: "#fff",
                          borderRadius: 10,
                          fontSize: 10,
                        })}
                      >
                        <FontAwesomeIcon icon={solid("star")} />
                      </Box>
                    </Group>
                  )
                }
              >
                <PageLayout />
              </Accordion.Item>
              <Accordion.Item
                label={
                  validatePremiumFormat[0] ? (
                    <Text>{t("spaces.colors.form.pageFormat")}</Text>
                  ) : (
                    <Group spacing={10}>
                      <Text>{t("spaces.colors.form.pageFormat")}</Text>
                      <Box
                        sx={(theme) => ({
                          padding: "3px 3px",
                          backgroundColor: theme.colors.green[6],
                          color: "#fff",
                          borderRadius: 10,
                          fontSize: 10,
                        })}
                      >
                        <FontAwesomeIcon icon={solid("star")} />
                      </Box>
                    </Group>
                  )
                }
              >
                <PageFormat setOpened={props.setOpened} />
              </Accordion.Item>
            </Accordion>
          </ScrollArea>
        </Stack>
      </form>

      <PremiumModal opened={opened} setOpened={setOpened} />
    </Box>
  );
}
