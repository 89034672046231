import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DefaultAvatar from "../../../src/images/fallback/default.png";

import { setHiddenSidebar } from "../../../store/app";
import FixedHeader from "../FixedHeader";

import { useTranslation } from "react-i18next";

import { useForm } from "@mantine/form";

import {
  Paper,
  Title,
  Text,
  Container,
  Button,
  Box,
  Divider,
  Image,
  Stepper,
  Grid,
  Textarea,
  ScrollArea,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

export default function Edit(props) {
  const { t, i18n } = useTranslation();

  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [showStepper, setStepper] = useState(false);

  const form = useForm({
    initialValues: {
      bio: "",
    },
  });

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 1000);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    initFormData();
  }, [user]);

  const initFormData = () => {
    form.setFieldValue("bio", user.bio);
  };

  const onSave = (values) => {
    navigate(getSkipLink());
  };

  const getSkipLink = () => {
    if (space.slug) {
      if (space && space.permissions.block_until_approved){
        return `/space/${space.slug}/demo/pending`;
      }
      else{
        return `/space/${space.slug}`;
      }
    } else if (user.open_invitations) {
      return `/home/invitations`;
    } else if (user.space_count > 0) {
      return `/space/${space.slug}/settings/main`;
    } else {
      return `/space/${space.slug}/settings/main`;
    }
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <ScrollArea style={{ height: "100%" }}>
      <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={["dummy"]}
        />

      <Container style={{ marginBottom: "50px" }} size={1000}>
        {!smallScreen && space.slug && showStepper ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={0} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : (
          ""
        )}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <form onSubmit={form.onSubmit((values) => onSave(values))}>
                <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                  <Box pt={20} pb={5} pl={35} pr={35}>
                    <Title
                      sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                      order={1}
                    >
                      {t("users.edit.title")}
                    </Title>
                  </Box>
                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                  <Box pt={10} pb={25} pl={35} pr={35}>
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          width: 130,
                          marginLeft: "auto",
                          marginRight: "auto",
                          textAlign: "center",
                        }}
                      >
                        <Image
                          radius={128}
                          src={
                            user.avatar.url ? user.avatar.url : DefaultAvatar
                          }
                          alt={user.username}
                        />
                      </div>
                      <Button
                        variant="default"
                        color="green"
                        radius="xl"
                        mt={20}
                      >
                        {t("users.edit.addProfileImage")}
                      </Button>
                    </div>
                    <Textarea
                      disabled={true}
                      {...form.getInputProps("bio")}
                      placeholder={t("users.edit.bioPlaceholder")}
                      label={t("users.edit.bio")}
                      autosize
                      minRows={3}
                      maxRows={8}
                    />
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      type="submit"
                    >
                      {t("users.edit.submit")}
                    </Button>
                    {space.slug ? (
                      <Text size="md" align="center" mt={15}>
                        <Link to={`/space/${space.slug}`}>
                          {t("users.edit.skip")}
                        </Link>
                      </Text>
                    ) : (
                      <Text size="md" align="center" mt={15}>
                        <Link to={getSkipLink()}>{t("users.edit.skip")}</Link>
                      </Text>
                    )}
                  </Box>
                </Paper>
              </form>
            </Container>
          </Grid.Col>
        </Grid>
      </Container>
    </ScrollArea>
  );
}
