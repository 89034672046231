import React, { useState } from "react";
import {
  Title,
  Container,
  Divider,
  Text,
  Drawer,
  ScrollArea,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import History from "./paid_subscriptions/History";


import { Helmet } from "react-helmet";

export default function PaidSubscriptions() {
  const { t, i18n } = useTranslation();

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{t("settings.paid_subscriptions.tabTitle")}</title>
      </Helmet>
      <Container
        style={{ marginBottom: "50px" }}
        size={1000}
        pt={20}
        pb={40}
        pl={40}
        pr={40}
      >
        <Title sx={(theme) => ({ fontWeight: 600 })} order={2} mt={40}>
          {t("settings.paid_subscriptions.title")}
        </Title>
        <Divider
          sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
          mt={10}
          mb={20}
        />
        <History />
        <Drawer
          // opened={openedPaymentMethod}
          // onClose={() => setOpenedPaymentMethod(false)}
          title={<Text size="xs">{t("settings.billing.updatePaymentMethodMayus")}</Text>}
          padding="xl"
          size="xl"
        >
          {/* <PaymentMethod setOpenedPaymentMethod={setOpenedPaymentMethod} /> */}
        </Drawer>
      </Container>
    </ScrollArea>
  );
}
