import { Extension } from '@tiptap/core'
import { TextSelection } from 'prosemirror-state';

import { setUniqId } from "../utils/selection";

import Swal from "sweetalert2";

import { setCookie } from "../../../utils/Cookies";
import { getRoot } from '../../../utils/Host';

const EditorEventsSimple = Extension.create({
  onBeforeCreate({ editor }) {

  },
  onCreate({ editor }) {
    
  },
  onUpdate({ editor }) {
    

    const { tr, doc } = editor.view.state;
    
    const types = [
      "heading",
      "paragraph",
      "grid1",
      "grid2",
      "grid3",
      "grid4",
      "column",
      "tasklist",
      "taskItem",
      "circle",
      "resource",
      "video",
      "audio",
      "image",
      "embed",
      "file",
      "detailsSummary",
      "details",
      "detailsContent",
      "bulletList",
      "orderedList",
      "listItem",
      "taskItem",
      "resource",
      "actionbutton",
      "customform",
      "survey",
      "interactiveInput",
      "interactiveButton",
      "interactiveWait",
      "fancyLink",
      "horizontalRule",
      "infobox",
      "tagsFeed"
    ]
    const usedUuid = [];
    doc.nodesBetween(0, doc.content.size, (node, pos) => {
      if (!node.isText && types.indexOf(node.type.name) >= 0 && (!node.attrs.uid || usedUuid.indexOf(node.attrs.uid) >= 0)) {
        usedUuid.push(setUniqId(editor, pos));
      }
      else{
        usedUuid.push(node.attrs.uid)
      }
    });



  },
  onSelectionUpdate({ editor }) {

    
  },
  onTransaction({ editor, transaction }) {
   
    
  },
  onFocus({ editor, event }) {


  },
  onBlur({ editor, event }) {

  },
  onDestroy() {
  },
})

export default EditorEventsSimple;
