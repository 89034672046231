import { Node, mergeAttributes } from "@tiptap/core";

import { setResizeable } from "../utils/setResizeable";

import { checkUploadStatus } from "../utils/uploaderStatus";
import { getFullDomain } from "../../../utils/Host";
import { checkInteractionAndHideIfNeeded, setUniqId} from "../utils/selection";
import { stylesToString } from "../../../utils/StylesToString";
import { wideOrNarrow } from "../../../utils/PageWidth";
import Broadcaster from "../../../helpers/Broadcaster";

export const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

const playIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>';

const VideoNode = Node.create({
  name: "video",
  allowGapCursor: false,
  group: "block columnable",

  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block columnable";
  },

  draggable: false,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      controls: {
        default: true,
      },
      viewAs: {
        default: "inline",
      },
      link: {
        default: {
          url: null,
          target: "_modal",
          title: "",
          description: "",
          image: {
            url: "",
          },
          thumbnail: {
            url: "",
          },
        },
      },
      zoom: {
        default: {
          meeting_number: null,
          meeting_password: "",
        },
      },
      uploader: {
        default: {
          id: null,
          status: null,
          progress: null,
        },
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "15px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: (attributes) => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "video",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "video",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setVideo:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("section");
      const span = document.createElement("div");
      if (!node.attrs.viewAs || node.attrs.viewAs == "inline") {
        span.style.maxWidth = wideOrNarrow();
        span.classList.add("video-node");

        const resize = document.createElement("div");

        resize.style.display = "inline-block";

        const video = document.createElement("video");
        const attributes = mergeAttributes(HTMLAttributes, {});

        span.setAttribute("id", attributes["data-uid"]);

        Object.entries(attributes).forEach(([key, value]) =>
          dom.setAttribute(key, value)
        );

        if (attributes.resizeableWidth) {
          resize.style.width = attributes.resizeableWidth;
          resize.style.height = attributes.resizeableHeight;
          span.style.height = attributes.resizeableParentHeight;
        }

        if (
          attributes.uploader &&
          attributes.uploader.status &&
          (attributes.uploader.status == "uploading" ||
            attributes.uploader.status == "pending" ||
            attributes.uploader.status == "zoom-pending" ||
            attributes.uploader.status == "processing" ||
            attributes.uploader.status.indexOf("error") >= 0)
        ) {
          checkUploadStatus(resize, editor, getPos, node.attrs, "video");
        }

        video.style.width = "100%";
        video.style.height = "100%";
        video.style.maxHeight = "600px";
        if (window.location.pathname.indexOf("/discussion") >= 0) {
          // video.style.minHeight = $(window).width() / 2 + "px";
        }

        video.setAttribute("controls", attributes.controls);

        video.style.display = "block";
        video.style["object-fit"] = "contain";

        // const fileToFetch = attributes.src.split("/").pop();
        const fileToFetch = attributes.src ? attributes.src.split("/").pop() : '';

        const source = document.createElement("source");
        // DELETE FOR FIREFOX
        // source.src = `https://sutra-froala.s3.us-west-1.amazonaws.com/placeholder.mp4`;

        video.append(source);
        // setLazyLoad(source, attributes.src, editor, resize, getPos)
        video.append("Your browser does not support the video tag.");

        resize.append(video);

        span.append(resize);
        dom.append(span);

        const overlay = document.createElement("div");
        overlay.style.width = resize.style.height;
        overlay.style.height = resize.style.height;
        overlay.classList.add("overlay");
        overlay.innerHTML = playIcon;
        resize.append(overlay);

        if (attributes.src && attributes.src.indexOf("m3u8") >= 0) {
          // https://github.com/video-dev/hls.js

          if (Hls.isSupported()) {
            let thumbnail = "";
            if (
              attributes.uploader &&
              attributes.uploader.thumbnail &&
              attributes.uploader.status != "completed"
            ) {
              thumbnail = attributes.uploader.thumbnail;
            } else {
              thumbnail = `${attributes.src
                .replace(".m3u8", "")
                .replace(
                  "sutra-video-output.s3.us-west-1.amazonaws.com",
                  "sutra-video-thumbnails.s3.us-west-1.amazonaws.com/thumbnails"
                )}_00001.png`;
            }
            if (attributes.link.thumbnail && attributes.link.thumbnail.url) {
              thumbnail = attributes.link.thumbnail.url;
            }
            video.setAttribute("poster", thumbnail);

            const hls_config = {
              maxBufferLength: 30, // seconds
              maxMaxBufferLength: 600, // seconds
              liveSyncDurationCount: 3,
              liveMaxLatencyDurationCount: 5,
              debug: false,
              xhrSetup: function (xhr, url) {
                // xhr.withCredentials = true; // do send cookie
                // xhr.setRequestHeader("Access-Control-Allow-Headers","Content-Type, Accept, X-Requested-With");
                xhr.setRequestHeader(
                  "Access-Control-Allow-Origin",
                  `${getFullDomain()}`
                );
                // xhr.setRequestHeader("Access-Control-Allow-Credentials","true");
              },
            };

            video.addEventListener("abort", () => {
              console.log(`Abort loading: addas`);
            });

            video.addEventListener("error", (e) => {
              console.log(`Error encountered: ${e.message}`);
              // Attempt to recover or log more details
            });

            overlay.addEventListener("click", () => {
              overlay.style.display = "none";
              try {
                const hls = new Hls(hls_config);

                hls.loadSource(
                  `/api/v4/proxy?url=https://sutra-video-output.s3.us-west-1.amazonaws.com/${fileToFetch}`
                );
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                  video.play().catch(function (error) {
                    console.error("Failed to start video playback:", error);
                  });
                });

                hls.on(Hls.Events.ERROR, (event, data) => {
                  if (data.fatal) {
                    switch (data.type) {
                      case Hls.ErrorTypes.NETWORK_ERROR:
                        // try to recover network error
                        console.log(
                          "Attempting to recover from network error..."
                        );
                        hls.startLoad();
                        break;
                      case Hls.ErrorTypes.MEDIA_ERROR:
                        console.log(
                          "Attempting to recover from media error..."
                        );
                        hls.recoverMediaError();
                        break;
                      default:
                        // cannot recover
                        hls.destroy();
                        break;
                    }
                  }
                });
              } catch (e) {}
            });
          }
        } else {
          overlay.style.display = "none";
          source.src = attributes.src;
        }

        if (editor.isEditable && attributes.src != null) {
          setTimeout(() => {
            setResizeable(resize, editor, getPos());
          }, 1000);
        }
      } else {
        span.style.maxWidth = wideOrNarrow();
        span.classList.add("fancylink-node");

        const mainContent = document.createElement("div");
        mainContent.classList.add("main-content");

        const imageCont = document.createElement("div");
        const img = document.createElement("img");
        const container = document.createElement("div");
        const title = document.createElement("div");
        title.classList.add("fancylink-title");
        const description = document.createElement("div");
        const attributes = mergeAttributes(HTMLAttributes, {});

        span.setAttribute("id", attributes["data-uid"]);

        Object.entries(attributes).forEach(([key, value]) =>
          dom.setAttribute(key, value)
        );

        img.onload = function () {
          imageCont.style.display = "inline";
        };

        img.onerror = function () {
          imageCont.style.display = "none";
        };

        img.setAttribute(
          "src",
          attributes.link.thumbnail && attributes.link.thumbnail.url
            ? attributes.link.thumbnail.url
            : attributes.link.image && attributes.link.image.url
            ? attributes.link.image.url
            : ""
        );

        title.innerHTML = attributes.link.title;
        description.innerHTML = attributes.link.description;

        imageCont.classList.add("imageCont");

        imageCont.append(img);

        container.append(title);
        container.append(description);

        mainContent.append(imageCont);
        mainContent.append(container);

        span.append(mainContent);

        dom.append(span);

        if (!editor.isEditable) {
          span.addEventListener("click", (event) => {
            Broadcaster.send("open_VideVideo", document.body, {
              node: { ...node },
            });
          });
        }
      }

      checkInteractionAndHideIfNeeded(editor, dom, getPos);
      //setUniqId(editor, node, getPos, dom);

      if (node.attrs.uploader.status == "zoom-pending" && !editor.isEditable) {
        dom.style.display = "none";
      }

      return {
        dom,
        contentDOM: span,
        ignoreMutation(mutation) {
          return true;
        },
      };
    };
  },
});

export default VideoNode;
