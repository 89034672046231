import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import {
  Breadcrumbs,
  Tooltip,
  Box,
  Text,
  createStyles,
  Menu,
  UnstyledButton,
} from "@mantine/core";

import { getBreadcrumbs, getCollapsed } from "../../utils/BreadcrumbsGenerator";

import isSpaceThemed from "../../utils/IsSpaceThemed";
// import {treeData} from '../../collections/testData'
import tinycolor from "tinycolor2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const offsetTop = {
  base: 60,
  origin: 0,
};

const useStyles = createStyles((theme, { colors }, getRef) => ({
  breadcrumbs: {
    marginTop: 30,
    marginBottom: 5,
    backgroundColor: colors.background_color,
  },
  breadcrumbWrap: {
    color: isSpaceThemed(colors)
      ? tinycolor(colors.default_text_color).darken(40).toString()
      : tinycolor(colors.default_text_color).lighten(60).toString(),
    textDecoration: "none",
  },
  breadcrumbLink: {
    color: tinycolor(colors.default_text_color).darken(40).toString(),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  breadcrumbButton: {
    color: tinycolor(colors.default_text_color).darken(40).toString(),
    textDecoration: "none",
    // backgroundColor: 'red',
    "&:hover": {
      // backgroundColor: "blue",
      // textDecoration: "underline",
    },
  },
  breadcrumbButtonHover: {
    backgroundColor: "red",
  },
  fixed: {
    position: "fixed",
  },
  topOffset: {
    top: theme.other.headerHeight,
  },
}));

export default function SystemBreadcrumbs(props) {
  const space = useSelector((state) => state.space);
  const scroll = useSelector((state) => state.scroll);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [fixed, setFixed] = useState(false);
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  useEffect(() => {
    if (space && space.tree) {
      // setBreadcrumbs(getBreadcrumbs(treeData, "mclh6s", "LALALA", space.version));

      const auxBreadcrumbs = getBreadcrumbs([
        ...space.paths[window.$currentTopSlug],
      ]);
      auxBreadcrumbs.pop();
      setBreadcrumbs(auxBreadcrumbs);
    }
  }, [space]);

  if (
    space.linked_parents &&
    space.linked_parents.length > 0 &&
    !props.breadcrumbsHidden
  ) {
    return (
      <Box
        id="breadcrumbs"
        className={cx(
          classes.breadcrumbs,
          { [classes.fixed]: fixed },
          { [classes.topOffset]: fixed }
        )}
      >
        <Breadcrumbs
          className={classes.breadcrumbWrap}
          styles={{
            root: { flexWrap: "wrap" },
            breadcrumb: { lineHeight: "1.5" },
            separator: {
              color: isSpaceThemed(colors)
                ? tinycolor(colors.default_text_color).darken(40).toString()
                : tinycolor(colors.default_text_color).lighten(60).toString(),
            },
          }}
        >
          <Text
            component={Link}
            key={`breadcrumb-${space.linked_parents[0].slug}`}
            to={`/space/${space.linked_parents[0].slug}/${space.linked_parents[0].version}`}
            className={classes.breadcrumbLink}
            onClick={(event) => event.stopPropagation()}
          >
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              label={`This space is linked to ${space.linked_parents[0].name}`}
              withArrow
            >
              <FontAwesomeIcon icon={solid("arrow-right-from-bracket")} />
              <span style={{ marginLeft: "8px" }}>
                {space.linked_parents[0].name}
              </span>
            </Tooltip>
          </Text>
        </Breadcrumbs>
      </Box>
    );
  }

  if (breadcrumbs.length == 0 || props.breadcrumbsHidden) {
    return <Box mt={20} />;
  }

  return (
    <Box
      id="breadcrumbs"
      className={cx(
        classes.breadcrumbs,
        { [classes.fixed]: fixed },
        { [classes.topOffset]: fixed }
      )}
    >
      <Breadcrumbs
        className={classes.breadcrumbWrap}
        styles={{
          root: { flexWrap: "wrap" },
          breadcrumb: { lineHeight: "1.5" },
          separator: {
            color: isSpaceThemed(colors)
              ? tinycolor(colors.default_text_color).darken(40).toString()
              : tinycolor(colors.default_text_color).lighten(60).toString(),
          },
        }}
      >
        {breadcrumbs.map((item, index) => {
          return (
            <>
              {item.version == "collapsed" ? (
                <Menu
                  control={
                    <UnstyledButton className={classes.breadcrumbButton}>
                      <FontAwesomeIcon icon={solid("ellipsis")} />
                    </UnstyledButton>
                  }
                >
                  {getCollapsed(space.path).map((c_item) => {
                    return (
                      <Menu.Item
                        component={Link}
                        key={`breadcrumb-${c_item.slug}`}
                        to={`/space/${c_item.slug}/${c_item.version}`}
                        onClick={(event) => event.stopPropagation()}
                      >
                        {c_item.name}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              ) : (
                <Text
                  component={Link}
                  key={`breadcrumb-${item.slug}`}
                  to={`/space/${item.slug}/${item.version}`}
                  className={classes.breadcrumbLink}
                  onClick={(event) => event.stopPropagation()}
                >
                  {item.name}
                </Text>
              )}
            </>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}
