import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import Notifications from "./Notifications";
import DirectMessages from "./DirectMessages";
import Uploader from "./Uploader";
import Search from "./Search";

import {
  Text,
  Menu,
  Divider,
  Group,
  Box,
  Avatar,
  createStyles,
  Drawer,
} from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useLangUser from "../../utils/LangUser";

const useStyles = createStyles((theme) => ({
  userProfileWrap: {
    width: "100%",
    marginBottom: 5,
    cursor: "pointer",
  },
  userProfileInner: {
    backgroundColor: theme.colors.gray[1],

    width: "100%",
    padding: "10px 10px 10px 10px",
    "&:hover": {
      backgroundColor: theme.colors.gray[2],
    },
  },
  userProfileLink: {
    textDecoration: "none",
    color: theme.colors.gray[7],
    "&:hover": {
      textDecoration: "none",
      color: theme.colors.gray[7],
    },
  },
  userProfileUsername: {
    color: theme.colors.gray[9],
    width: 150,
    fontWeight: 700,
    lineHeight: 1,
    marginBottom: 4,
  },
  userProfileLabel: {
    lineHeight: 1,
    fontSize: 14,
    color: theme.colors.gray[7],
  },
}));

export default function UserMenu(props) {
  const { t, i18n } = useTranslation();
  const { language, setLangUser } = useLangUser();
  const user = useSelector((state) => state.user);
  const { classes, cx, theme } = useStyles();
  const navigate = useNavigate();

  const [openedDirectMessages, setOpenedDirectMessages] = useState(false);
  const [openedNotifications, setOpenedNotifications] = useState(false);
  const [openedUploads, setOpenedUploads] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLangUser(lang);
  };

  useEffect(() => {
    if (language) {
      changeLanguage(language);
    }
  }, [language]);

  const UserProfile = () => (
    <Link
      className={classes.userProfileLink}
      to={`/${props.username.replace("@", "")}`}
    >
      <Box className={classes.userProfileWrap}>
        <Group spacing={10} noWrap className={classes.userProfileInner}>
          <Avatar src={props.image} radius="xl" />
          <Box>
            <Box>
              <Text className={classes.userProfileUsername} lineClamp={1}>
                {user.first_name} {user.last_name}
              </Text>
            </Box>

            <Text className={classes.userProfileLabel} lineClamp={1}>
              @{user.username}
            </Text>
          </Box>
        </Group>
      </Box>
    </Link>
  );

  return (
    <>
      <Menu
        position="bottom"
        placement="end"
        size="md"
        sx={{ cursor: "pointer" }}
        styles={{
          body: {
            width: 250,
            "&:first-child": {
              padding: 0,
            },
          },
          itemIcon: {
            color: theme.colors.gray[7],
            width: 20,
            display: "flex",
            justifyContent: "center",
          },
          itemLabel: {
            color: theme.colors.gray[7],
            fontSize: 16,
          },
        }}
        control={<Avatar src={props.image} radius="xl" />}
      >
        <Menu.Item
          component={UserProfile}
          to={`/${props.username.replace("@", "")}`}
          sx={{ padding: 0, margin: 0 }}
        ></Menu.Item>
        {window.$isTouchDevice && window.$isTouchDevice() && (
          <>
            <Menu.Item
              onClick={() => navigate("/home/top")}
              icon={<FontAwesomeIcon size="md" icon={solid("house")} />}
            >
              {"Home"}
            </Menu.Item>
            <Menu.Item
              onClick={() => setOpenedDirectMessages(true)}
              icon={<FontAwesomeIcon size="md" icon={solid("message")} />}
            >
              {"Direct messages"}
            </Menu.Item>
            <Menu.Item
              onClick={() => setOpenedNotifications(true)}
              icon={<FontAwesomeIcon size="md" icon={solid("bell")} />}
            >
              {"Notifications"}
            </Menu.Item>
            <Menu.Item
              onClick={() => setOpenedUploads(true)}
              icon={
                <FontAwesomeIcon size="md" icon={solid("cloud-arrow-up")} />
              }
            >
              {"Uploads"}
            </Menu.Item>
          </>
        )}
        <Menu.Item
          component={Link}
          to="/settings/profile"
          icon={<FontAwesomeIcon size="md" icon={solid("gear")} />}
        >
          {t("layout.userButton.settings")}
        </Menu.Item>
        <Menu.Item
          component="a"
          href="https://support.sutra.co"
          icon={
            <FontAwesomeIcon
              size="md"
              icon={regular("circle-question")}
              color="gray"
            />
          }
        >
          {t("layout.userButton.support")}
        </Menu.Item>
        <Menu.Item
          component={Menu}
          trigger="hover"
          position="right"
          placement="end"
          gutter={-10}
          withArrow
          sx={{ padding: 0 }}
          control={
            <Group
              spacing={13}
              sx={{
                padding: "8px 15px",
                fontSize: 16,
                color: theme.colors.gray[7],
              }}
            >
              <FontAwesomeIcon
                size="sm"
                icon={solid("globe")}
                color={theme.colors.gray[7]}
              />
              {t("layout.userButton.language")} ({language.toUpperCase()})
            </Group>
          }
        >
          <Menu.Item
            icon={
              language == "en" ? (
                <FontAwesomeIcon size="md" icon={solid("check")} />
              ) : (
                ""
              )
            }
            onClick={(event) => changeLanguage("en")}
          >
            English
          </Menu.Item>
          <Menu.Item
            icon={
              language == "es" ? (
                <FontAwesomeIcon size="md" icon={solid("check")} />
              ) : (
                ""
              )
            }
            onClick={(event) => changeLanguage("es")}
          >
            Español
          </Menu.Item>
          <Menu.Item
            icon={
              language == "ru" ? (
                <FontAwesomeIcon size="md" icon={solid("check")} />
              ) : (
                ""
              )
            }
            onClick={(event) => changeLanguage("ru")}
          >
            Русский
          </Menu.Item>
          <Menu.Item
            icon={
              language == "pt" ? (
                <FontAwesomeIcon size="md" icon={solid("check")} />
              ) : (
                ""
              )
            }
            onClick={(event) => changeLanguage("pt")}
          >
            Português
          </Menu.Item>
          <Menu.Item
            icon={
              language == "de" ? (
                <FontAwesomeIcon size="md" icon={solid("check")} />
              ) : (
                ""
              )
            }
            onClick={(event) => changeLanguage("de")}
          >
            Deutsch
          </Menu.Item>
          <Menu.Item
            icon={
              language == "it" ? (
                <FontAwesomeIcon size="md" icon={solid("check")} />
              ) : (
                ""
              )
            }
            onClick={(event) => changeLanguage("it")}
          >
            Italiano
          </Menu.Item>
          <Menu.Item
            icon={
              language == "fr" ? (
                <FontAwesomeIcon size="md" icon={solid("check")} />
              ) : (
                ""
              )
            }
            onClick={(event) => changeLanguage("fr")}
          >
            Français
          </Menu.Item>
        </Menu.Item>

        <Menu.Item
          component={Link}
          to="/user/sign_out"
          icon={
            <FontAwesomeIcon
              size="md"
              icon={solid("arrow-right-from-bracket")}
              color="gray"
            />
          }
        >
          {t("layout.userButton.logout")}
        </Menu.Item>
        <Divider />
        <Menu.Item
          component="a"
          href="https://sutra.co/terms"
          sx={(theme) => ({
            color: theme.colors.gray[6],
            marginTop: "4px",
            padding: "4px 12px",
            fontSize: "12px",
            fontWeight: 500,
          })}
        >
          <Text size="sm">{t("layout.userButton.terms")}</Text>
        </Menu.Item>
        <Menu.Label pt={0}>{`Sutra Spaces, LLC © ${new Date().getFullYear()}`}</Menu.Label>
      </Menu>
      {window.$isTouchDevice && window.$isTouchDevice() && (
        <>
          <DirectMessages
            component={"drawer"}
            opened={openedDirectMessages}
            setOpened={setOpenedDirectMessages}
          />
          <Notifications
            component={"drawer"}
            opened={openedNotifications}
            setOpened={setOpenedNotifications}
          />
          <Uploader
            component={"drawer"}
            opened={openedUploads}
            setOpened={setOpenedUploads}
          />
        </>
      )}
    </>
  );
}
