

export function wideOrNarrow(suffix="px", add=0, rest=0) {
  let width1 = 960 + add - rest
  let width2 = 801 + add - rest
  if (window.location.pathname.indexOf("/registration") >= 0 || window.location.pathname.indexOf("/register") >= 0){
    
    if (suffix == "px"){
      return window.$registrationWideWidth == "wide" ? `${width1}px` : `${width2}px`
    }
    else{
      return window.$registrationWideWidth == "wide" ? width1 : width2
    }
  }
  else{
    if (suffix == "px"){
      return window.$spaceWideWidth == "wide" ? `${width1}px` : `${width2}px`
    }
    else{
      return window.$spaceWideWidth == "wide" ? width1 : width2
    }
  }
}

