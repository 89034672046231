import React, { useState, useEffect } from "react";

import {
  Text,
  Box,
  Menu,
  Group,
  Stack,
  Button,
  Badge,
  Divider,
  MantineProvider,
  useMantineTheme,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Copy, Edit } from "tabler-icons-react";

import { Link } from "react-router-dom";

import { DateTime } from "luxon";

import BroadcastModel from "../../../../models/Broadcast";

import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

export default function BroadcastItem(props) {
  const { t, i18n } = useTranslation();

  const theme = useMantineTheme();
  const [broadcast, setBroadcast] = useState({ user: {} });

  useEffect(() => {
    if (props.broadcast && props.broadcast.id != broadcast.id) {
      setBroadcast(props.broadcast);
    }
  }, [props]);

  const onEdit = () => {
    props.onEdit(broadcast);
  };

  const onDuplicate = () => {
    BroadcastModel.onDuplicate(
      broadcast.id,
      props.spaceId,
      (data) => {
        props.onUpdate();
      },
      (data) => {}
    );
  };

  const onDestroy = () => {
    Swal.fire({
      title: t("spaces.settings.broadcast.onDestroy.alertTitle"),
      html: t("spaces.settings.broadcast.onDestroy.alertMessage"),
      showDenyButton: true,
      confirmButtonText: t("spaces.settings.broadcast.onDestroy.confirm"),
      denyButtonText: t("spaces.settings.broadcast.onDestroy.deny"),
      confirmButtonColor: theme.colors.red[8],
      denyButtonColor: theme.colors.gray[6],
    }).then((result) => {
      if (result.isConfirmed) {
        BroadcastModel.onDestroy(
          broadcast.id,
          props.spaceId,
          (data) => {
            props.onUpdate();
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <Box
      mb={17}
      pb={20}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <Group
        position="apart"
        noWrap
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Text
            component={Link}
            to=""
            sx={(theme) => ({
              color: theme.colors.sutrablue[3],
              fontWeight: 500,
              fontSize: 20,
            })}
            onClick={() => onEdit()}
          >
            {broadcast.title}
          </Text>
        </Box>
        <Box>
          <Stack justify="flex-start">
            <Menu
              transition="pop"
              withArrow
              placement="end"
              control={
                <Button
                  compact
                  variant="subtle"
                  color="dark"
                  sx={(theme) => ({
                    "&:hover": {
                      backgroundColor: theme.colors.gray[1],
                    },
                  })}
                >
                  <FontAwesomeIcon icon={solid("ellipsis")} />
                </Button>
              }
            >
              {!broadcast.is_sent && (
                <Menu.Item
                  onClick={() => onEdit()}
                  icon={<Edit size={18} strokeWidth={2} color={"black"} />}
                >
                  {t("spaces.settings.broadcast.actions.edit")}
                </Menu.Item>
              )}
              <Menu.Item
                onClick={() => onDuplicate()}
                icon={<Copy size={18} strokeWidth={2} color={"black"} />}
              >
                {t("spaces.settings.broadcast.actions.duplicate")}
              </Menu.Item>
              <Divider />
              <Menu.Item
                onClick={() => onDestroy()}
                icon={<FontAwesomeIcon icon={regular("trash-can")} />}
                color="red"
              >
                {t("spaces.settings.broadcast.actions.delete")}
              </Menu.Item>
            </Menu>
          </Stack>
        </Box>
      </Group>
      <Group
        mt={5}
        spacing="sm"
        sx={(theme) => ({ color: theme.colors.gray[6] })}
      >
        <Text>{broadcast.user.full_name}</Text>
        <Text>•</Text>
        {broadcast.is_scheduled &&
          !broadcast.is_sent &&
          broadcast.send_time && (
            <Group>
              <Text>
                {DateTime.fromSQL(broadcast.send_time).toFormat(
                  "LLL d, yyyy, h:mm a"
                )}
              </Text>
              <Text>•</Text>
            </Group>
          )}
        {broadcast.is_sent && (
          <Group>
            <Text>
              {DateTime.fromSQL(broadcast.sent_on).toFormat(
                "LLL d, yyyy, h:mm a"
              )}
            </Text>
            <Text>•</Text>
          </Group>
        )}
        {broadcast.send_count && broadcast.send_count > 0 && (
          <Group>
            <Text align="right">{broadcast.send_to_text}</Text>
            <Text>•</Text>
          </Group>
        )}
        <MantineProvider theme={{ primaryShade: 6 }}>
          {broadcast.is_sent ? (
            <Badge color="green">
              {t("spaces.settings.broadcast.statuses.sent")}
            </Badge>
          ) : (
            ""
          )}
          {broadcast.is_scheduled && !broadcast.is_sent ? (
            <Badge color="grape">
              {t("spaces.settings.broadcast.statuses.scheduled")}
            </Badge>
          ) : (
            ""
          )}
          {broadcast.is_draft &&
          !broadcast.is_scheduled &&
          !broadcast.is_sent ? (
            <Badge color="orange">
              {t("spaces.settings.broadcast.statuses.draft")}
            </Badge>
          ) : (
            ""
          )}
        </MantineProvider>
      </Group>
    </Box>
  );
}
