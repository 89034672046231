import React, { useState, useEffect, useRef } from "react";
import {useNavigate} from "react-router-dom"
import { useSelector } from "react-redux";

import TiptapSimple from "../../../tiptap/components/TiptapSimple";
import Message from "./Message";
import ReflectionModel from "../../../../models/Reflection";

import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import tinycolor from "tinycolor2";

import { Stack, ScrollArea, Divider, createStyles } from "@mantine/core";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  dividerStyle: {
    marginTop: 10,
    marginBottom: 10,
    borderTop: `1px solid ${
      !isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).darken(5).toString()
        : colors.header_color
    }`,
  },
}));

export default function Reflections(props) {
  const viewport = useRef();
  const navigate = useNavigate()

  const [tagging, setTagging] = useState(0);

  const user = useSelector((state) => state.user);
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  useEffect(() => {
    if (
      props.message &&
      props.message.reflections &&
      !props.message.reflectionId
    ) {
      scrollToBottom();
    } else if (
      props.message &&
      props.message.reflections &&
      props.message.reflectionId
    ) {
      scrollToReflection(props.message.reflectionId);
    }
  }, [props]);

  const onSave = (json, html) => {

    if (!user.authenticated) {

      localStorage.setItem("join_slug", props.spaceId);
      navigate(`/user/start?s=${props.spaceId}`);
      return;
    }

    json.attrs = { uid: `reflection-${new Date().getTime()}` };
    ReflectionModel.onCreate(
      props.message.id,
      { json, html },
      (data) => {
        if (props.useReflectCallback) {
          props.onUpdatedMessage(props.message.id);
        }
      },
      (data) => {}
    );
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (!viewport.current) {
        return;
      }
      const top = viewport.current.scrollHeight;
      viewport.current.scrollTo({ top: top, behavior: "smooth" });
    }, 500);
  };

  const scrollToReflection = (reflectionId) => {
    setTimeout(() => {
      if (!viewport.current) {
        return;
      }

      const $scrollTo = document.getElementById(`reflection_${reflectionId}`);

      if (!$scrollTo) {
        return;
      }

      const top =
        $scrollTo.offsetTop -
        viewport.current.offsetTop +
        viewport.current.scrollTop +
        80;

      viewport.current.scrollTo({ top: top, behavior: "smooth" });
    }, 500);
  };

  return (
    <Stack
      spacing="xs"
      justify="space-between"
      sx={(theme) => ({
        height: "100%",
      })}
    >
      <ScrollArea viewportRef={viewport} style={{ height: "100%" }}>
        {props.message ? (
          <Message
            key={`inner_message_${props.message.id}`}
            spaceId={props.spaceId}
            spaceVersion={props.spaceVersion}
            messageId={props.message.id}
            htmlId={`${props.message.type}_${props.message.id}`}
            messageCreatedAt={props.message.created_at}
            messageActions={props.message.actions}
            messageTitle={
              props.message.post ? props.message.post.subject : null
            }
            messageContent={
              props.message.version == "new"
                ? props.message.content
                : props.message.message_body
            }
            messageUnseenCount={props.message.unseen_count}
            tags={props.message.tags}
            reflections={[]}
            user={props.message.user ? props.message.user : {}}
            version={props.message.version}
            canPin={props.message.user ? props.message.user.is_manager : false}
            canTag={props.message.user ? props.message.user.can_tag : false}
            canLike={props.message.user ? props.message.user.can_like : false}
            canReflect={false}
            canEdit={props.message.user ? props.message.user.can_edit : false}
            canDelete={
              props.message.user ? props.message.user.can_delete : false
            }
            type={"pod_message"}
            tagging={tagging}
            setTagging={setTagging}
            onUpdated={props.onUpdatedMessage}
            text={props.message.text}
            isLiked={props.message.is_liked}
            likesCount={props.message.likes_count}
          />
        ) : (
          ""
        )}
        <Divider className={classes.dividerStyle} />
        {props.message && props.message.reflections
          ? props.message.reflections.map((reflection) => {
              return (
                <Message
                  key={`reflection_${reflection.slug}`}
                  spaceId={props.spaceId}
                  spaceVersion={props.spaceVersion}
                  messageId={reflection.id}
                  parentId={reflection.pod_message_id}
                  htmlId={`${reflection.type}_${reflection.id}`}
                  messageCreatedAt={reflection.created_at}
                  messageActions={reflection.actions}
                  messageContent={reflection.content}
                  tags={reflection.tags}
                  reflections={[]}
                  user={reflection.user ? reflection.user : {}}
                  version={reflection.version}
                  canPin={false}
                  canTag={reflection.user ? reflection.user.can_tag : false}
                  canLike={reflection.user ? reflection.user.can_like : false}
                  canReflect={false}
                  canEdit={reflection.user ? reflection.user.can_edit : false}
                  canDelete={
                    reflection.user ? reflection.user.can_delete : false
                  }
                  type={"reflection"}
                  tagging={tagging}
                  setTagging={setTagging}
                  onUpdated={props.onUpdatedMessage}
                  text={reflection.text}
                  isLiked={reflection.is_liked}
                  likesCount={reflection.likes_count}
                />
              );
            })
          : ""}
      </ScrollArea>
      {props.canReflect && (
        <div style={{ paddingBottom: "40px" }}>
          <TiptapSimple editable={true} onSave={onSave} modals={false} />
        </div>
      )}
    </Stack>
  );
}
