import React from 'react';

import { Button, TextInput, Group } from '@mantine/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTranslation } from 'react-i18next';

export default function Searchbar(props) {

  const { t, i18n } = useTranslation()

  return (
    <Group
      position="left"
      spacing="xs">
      <TextInput
        icon={<FontAwesomeIcon icon={solid('search')} />}
        placeholder={t('settings.subscriptions.searchBySpaceName')}
        id="search-input"
        radius="xl"
        onKeyDown={event => props.onKeyPressed(event)}
        />
      <Button
        variant="filled"
        radius="xl"
        onClick={event => props.onSearchByTerm()}>
        {t('settings.subscriptions.search')}
      </Button>
      <Button
        variant="outline"
        radius="xl"
        onClick={event => props.onClearSearch()}>
        {t('settings.subscriptions.clear')}
      </Button>
    </Group>
  )
}
