import React, { useState, useEffect, useRef } from "react";
import {
  Title,
  Container,
  Divider,
  Text,
  Group,
  Select,
  Switch,
  MantineProvider,
  ScrollArea,
} from "@mantine/core";

import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/app";
import UserSettingModel from "../../models/UserSetting";

import { useTranslation } from "react-i18next";
import useFrequencyOptions from "../../collections/FrequencyOptions";

import SubscriptionsList from "./Subscriptions/List";

import { useForm } from "@mantine/form";

import { Helmet } from "react-helmet";

let timeoutId = null;

const scrollTimeoutIds = [];

export default function Email() {
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const didMount = useRef(false);

  const { frequencyOptions } = useFrequencyOptions([]);

  const [groupNotification, setGroupNotification] = useState("");
  const [activityEmailFrequency, setActivityEmailFrequency] = useState(-1);

  const [notifyMentionsChecked, setNotifyMentionsChecked] = useState(false);
  const [weeklyDigestChecked, setWeeklyDigestChecked] = useState(false);

  const [onScrollBottom, setOnScrollBottom] = useState(false);

  const viewport = useRef();

  const form = useForm({
    initialValues: {
      group_notifications: "",
      message_email_frequency: "",
      weekly_digest: "",
      notify_mentions: "",
    },
  });

  useEffect(() => {
    if (user && user.id) {
      onFetch();
    }
  }, [user]);

  useEffect(() => {
    if (didMount.current) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        onSave();
      }, 1300);
    }
  }, [
    groupNotification,
    activityEmailFrequency,
    notifyMentionsChecked,
    weeklyDigestChecked,
  ]);

  const onFetch = () => {
    UserSettingModel.onFetch(
      user.id,
      (data) => {
        setGroupNotification(data.group_notifications);
        setActivityEmailFrequency(data.message_email_frequency + "");
        setWeeklyDigestChecked(data.weekly_digest);
        setNotifyMentionsChecked(data.notify_mentions);

        setTimeout(() => {
          didMount.current = true;
        }, 100);
      },
      (data) => {}
    );
  };

  const onSave = () => {
    UserSettingModel.onUpdate(
      user.id,
      {
        group_notifications: groupNotification,
        message_email_frequency: activityEmailFrequency,
        weekly_digest: weeklyDigestChecked,
        notify_mentions: notifyMentionsChecked,
      },
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );
      },
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onScrollPositionChange = (pos) => {
    const div = viewport.current;
    clearScrollTimeouts();

    if (pos.y + div.clientHeight >= div.scrollHeight - 50) {
      const scrollTimeoutId = setTimeout(() => {
        setOnScrollBottom(true);
      }, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else if (pos.y == 0) {
      const scrollTimeoutId = setTimeout(() => {}, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else {
      // In between
    }
  };

  const clearScrollTimeouts = () => {
    for (let i = 0; i < scrollTimeoutIds.length; i++) {
      const id = scrollTimeoutIds[i];
      clearTimeout(id);
    }
  };

  const digestPreferences = [
    {
      label: t("settings.digestPreferences.digestedLabel"),
      value: "digested",
      description: t("settings.digestPreferences.digestedDescription"),
    },

    {
      label: t("settings.digestPreferences.undigestedLabel"),
      value: "undigested",
      description: t("settings.digestPreferences.undigestedDescription"),
    },
  ];

  const SelectItem = ({ image, label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  return (
    <ScrollArea
      style={{ height: "100%" }}
      onScrollPositionChange={onScrollPositionChange}
      viewportRef={viewport}
    >
      <Helmet>
        <title>{t("settings.emailPreferences.tabTitle")}</title>
      </Helmet>
      <Container
        style={{ marginBottom: "50px" }}
        size={1000}
        pt={20}
        pb={40}
        pl={40}
        pr={40}
      >
        <form onSubmit={form.onSubmit((values) => onSave(values))}>
          <Title sx={(theme) => ({ fontWeight: 900 })}>
            {t("settings.emailPreferences.emailPreferences")}
          </Title>

          <Divider
            sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
            mt={10}
            mb={20}
          />

          <MantineProvider theme={{ primaryShade: 6 }}>
            <Select
              value={groupNotification}
              onChange={setGroupNotification}
              label={t("settings.emailPreferences.emailDigestPreferences")}
              placeholder="Pick one"
              itemComponent={SelectItem}
              data={digestPreferences}
              maxDropdownHeight={400}
            />

            <Select
              value={activityEmailFrequency}
              onChange={setActivityEmailFrequency}
              mt={20}
              label={t("settings.emailPreferences.emailDigestFrequency")}
              placeholder="Pick one"
              itemComponent={SelectItem}
              data={frequencyOptions}
              maxDropdownHeight={400}
            />
          </MantineProvider>

          <Text color="dimmed" mt={20}>
            {t("settings.emailPreferences.emailDigestMessage")}
          </Text>

          <Divider
            sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
            mt={20}
          />

          <Group position="apart" mt={20}>
            <Switch
              checked={weeklyDigestChecked}
              onChange={(event) =>
                setWeeklyDigestChecked(event.currentTarget.checked)
              }
              size="lg"
              onLabel="ON"
              offLabel="OFF"
              label={t("settings.emailPreferences.receiveWeeklyDigest")}
            />
          </Group>

          <Group position="apart" mt={20}>
            <Switch
              checked={notifyMentionsChecked}
              onChange={(event) =>
                setNotifyMentionsChecked(event.currentTarget.checked)
              }
              size="lg"
              onLabel="ON"
              offLabel="OFF"
              label={t("settings.emailPreferences.receiveAtMentions")}
            />
          </Group>
        </form>
        {groupNotification == "undigested" && (
          <SubscriptionsList
            userEmail={user.email}
            onScrollBottom={onScrollBottom}
            setOnScrollBottom={setOnScrollBottom}
          />
        )}
      </Container>
    </ScrollArea>
  );
}
