import React, { useState, useEffect, useRef } from "react";

import { Text, Box, Group, Drawer, Select, ScrollArea } from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AddSimple from "./steps/AddSimple";
import EditMessage from "./steps/EditMessage";

import SendEmail from "./steps/SendEmail";
import AddToExistingSpace from "./steps/AddToExistingSpace";
import AddToNewSpace from "./steps/AddToNewSpace";
import AddFromSubSpace from "./steps/AddFromSubSpace";
import AddStructure from "./steps/AddStructure";
import ApplyTemplate from "./steps/ApplyTemplate";
import SetToPending from "./steps/SetToPending";
import SurveyPerson from "./steps/SurveyPerson";
import Webhook from "./steps/Webhook";
import Wait from "./steps/Wait";

import StepModel from "../../../../models/Step";
import JourneyModel from "../../../../models/Journey";
import StepIcon from "./StepIcon";

import PremiumModal from "../../../app/PremiumModal";

export default function AddStep(props) {
  const { t, i18n } = useTranslation();
  const [firstSelection, setFirstSelection] = useState(null);
  const [actions, setActions] = useState([]);
  const [canSetToPending, setCanSetToPending] = useState(false);
  const [canAddToExistingSpace, setCanAddToExistingSpace] = useState(false);
  const [canWait, setWait] = useState(false);
  const [canSurveyPerson, setCanSurveyPerson] = useState([]);
  const [canSendEmail, setCanSendEmail] = useState([]);
  const [canSendMessage, setCanSendMessage] = useState([]);
  const [canAddFromChildren, setCanAddFromChildren] = useState([]);
  const [canAddToDirectOpenChildren, setCanAddToDirectOpenChildren] = useState(
    []
  );
  const [canWebhook, setCanWebhook] = useState([]);
  const [opened, setOpened] = useState(false);
  const lastOpenedState = useRef(false);

  const space = useSelector((state) => state.space);

  useEffect(() => {}, []);

  useEffect(() => {
    setCanSetToPending(
      space &&
        space.permissions &&
        space.permissions.can_add_step_send_to_pending
    );
    setCanAddToExistingSpace(
      space &&
        space.permissions &&
        space.permissions.can_add_add_to_existing_space
    );
    setWait(space && space.permissions && space.permissions.can_add_step_wait);
    setCanSurveyPerson(
      space && space.permissions && space.permissions.can_add_step_survey_person
    );
    setCanSurveyPerson(
      space && space.permissions && space.permissions.can_add_step_survey_person
    );
    setCanSendEmail(
      space && space.permissions && space.permissions.can_add_step_send_email
    );

    setCanWebhook(
      space && space.permissions && space.permissions.can_add_step_webhook
    );

    setCanSendMessage(
      space && space.permissions && space.permissions.can_send_message
    );
    setCanAddFromChildren(
      space && space.permissions && space.permissions.can_add_from_children
    );
    setCanAddToDirectOpenChildren(
      space &&
        space.permissions &&
        space.permissions.can_add_to_direct_open_children
    );
  }, [space]);

  useEffect(() => {
    if (props.opened == true && !lastOpenedState.current) {
      onFetchActions();
    }
    lastOpenedState.current = props.opened;
  }, [props]);

  useEffect(() => {
    if (firstSelection == "set_to_pending" && !canSetToPending[0]) {
      setOpened([true, canSetToPending[1], canSetToPending[2]]);
      setFirstSelection(null);
    }
    if (
      firstSelection == "add_to_existing_space" &&
      !canAddToExistingSpace[0]
    ) {
      setOpened([true, canAddToExistingSpace[1], canAddToExistingSpace[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "wait" && !canWait[0]) {
      setOpened([true, canWait[1], canWait[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "survey_person" && !canSurveyPerson[0]) {
      setOpened([true, canSurveyPerson[1], canSurveyPerson[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "send_email" && !canSendEmail[0]) {
      setOpened([true, canSendEmail[1], canSendEmail[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "send_message" && !canSendMessage[0]) {
      setOpened([true, canSendMessage[1], canSendMessage[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "can_add_from_children" && !canAddFromChildren[0]) {
      setOpened([true, canAddFromChildren[1], canAddFromChildren[2]]);
      setFirstSelection(null);
    }
    if (
      firstSelection == "can_add_to_direct_open_children" &&
      !canAddToDirectOpenChildren[0]
    ) {
      setOpened([
        true,
        canAddToDirectOpenChildren[1],
        canAddToDirectOpenChildren[2],
      ]);
      setFirstSelection(null);
    }
    if (firstSelection == "webhook" && !canWebhook[0]) {
      setOpened([true, canWebhook[1], canWebhook[2]]);
      setFirstSelection(null);
    }
  }, [firstSelection]);

  const onFetchActions = () => {
    JourneyModel.onFetchActions(
      props.trigger,
      props.position,
      space.slug,
      (data) => {
        setActions(data.actions);
      },
      (data) => {}
    );
  };

  const onCreate = (params) => {
    StepModel.onCreate(
      space.slug,
      props.journey,
      { action: firstSelection, position: props.position, ...params },
      (data) => {
        if (firstSelection == "send_email" && params.object_id == "new_email") {
          props.setTriggerEdit(data.id);
        } else if (
          firstSelection == "send_message" &&
          params.object_id == "new_message"
        ) {
          props.setTriggerEdit(data.id);
        } else if (
          firstSelection == "add_structure" &&
          params.object_id == "new_structure"
        ) {
          props.setTriggerEdit(data.id);
        } else if (
          firstSelection == "survey_person" &&
          params.object_id == "new_survey"
        ) {
          props.setTriggerEdit(data.id);
        }
        props.setOpened(false);
        setFirstSelection(null);
        setTimeout(() => props.onSettingsChange(), 1000);
      },
      (data) => {}
    );
  };

  const SelectItem = ({ label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <StepIcon value={others.value} />
        <div>
          <Text size="lg" weight={600}>
            {label}
          </Text>
          <Text size="md" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  const displaySecondSelection = () => {
    switch (firstSelection) {
      case "send_email":
        return (
          <SendEmail
            onCreate={onCreate}
            mode="add"
            trigger={props.trigger}
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "send_message":
        return (
          <EditMessage
            onCreate={onCreate}
            mode="add"
            trigger={props.trigger}
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_to_existing_space":
        return (
          <AddToExistingSpace
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_to_new_space":
        return (
          <AddToNewSpace
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_from_sub_space":
        return (
          <AddFromSubSpace
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_structure":
        return (
          <AddStructure
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "apply_template":
        return (
          <ApplyTemplate
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "set_to_pending":
        return (
          <SetToPending
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "survey_person":
        return (
          <SurveyPerson
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "webhook":
        return (
          <Webhook
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "wait":
        return (
          <Wait
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      default:
        return (
          firstSelection && (
            <AddSimple
              onCreate={onCreate}
              mode="add"
              settings={props.settings}
              onSettingsChange={props.onSettingsChange}
            />
          )
        );
        break;
    }
  };

  return (
    <>
      <Drawer
        opened={props.opened}
        onClose={() => {
          props.setOpened(false);
          setFirstSelection(null);
        }}
        title={
          <Text size="xl" weight="800">
            {t("spaces.settings.registration.addStep.addStep.description")}
          </Text>
        }
        padding="xl"
        size={600}
        offsetScrollbars
      >
        <ScrollArea style={{ height: "100%" }}>
          <Select
            label={t("spaces.settings.registration.addStep.addStep.label")}
            value={firstSelection}
            onChange={setFirstSelection}
            placeholder={t(
              "spaces.settings.registration.addStep.addStep.placeholder"
            )}
            itemComponent={SelectItem}
            data={actions}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
          />
          {displaySecondSelection()}
          <Box mt={100} />
        </ScrollArea>
      </Drawer>
      <PremiumModal opened={opened} setOpened={setOpened} />
    </>
  );
}
