import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Switch, Group, Text, createStyles } from "@mantine/core";

import SpaceModel from "../../../models/Space";
import SpaceSettingModel from "../../../models/SpaceSetting";
import {
  setHiddenSidebar,
  setHiddenHeader,
  setHiddenCover,
  setHiddenTitle,
  setHiddenBreadcrumbs,
  setWideWidth,
  setSpace,
} from "../../../store/app";

import PremiumModal from "../../app/PremiumModal";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemLast: {
    paddingBottom: 16,
    marginBottom: 18,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    textAlign: "center",
    paddingRight: `${theme.spacing.sm}px !important`,
    paddingLeft: `${theme.spacing.sm}px !important`,
    height: 28,
    flex: 1,
  },
}));

export default function PageLayout(props) {
  const { t, i18n } = useTranslation();

  const { classes } = useStyles();

  const hiddenLayoutElements = useRef([]);
  const loading = useRef(false);

  const [sidebar, setSidebar] = useState(false);
  const [header, setHeader] = useState(false);
  const [cover, setCover] = useState(false);
  const [title, setTitle] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState(true);
  const [opened, setOpened] = useState(false);
  const [width, setWidth] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  const space = useSelector((state) => state.space);
  const dispatch = useDispatch();

  useEffect(() => {
    if (space && space.slug) {
      loading.current = true;
      if (window.$hiddenLayoutElements) {
        hiddenLayoutElements.current = [...window.$hiddenLayoutElements];
      } else {
        hiddenLayoutElements.current = [...space.hidden_layout_elements];
      }

      setSidebar(hiddenLayoutElements.current.indexOf("sidebar") >= 0);
      setHeader(hiddenLayoutElements.current.indexOf("header") >= 0);
      setCover(hiddenLayoutElements.current.indexOf("cover") >= 0);
      setTitle(hiddenLayoutElements.current.indexOf("title") >= 0);
      setBreadcrumbs(hiddenLayoutElements.current.indexOf("breadcrumbs") >= 0);
      setWidth(space.space_width == "wide");

      setValidatePremium(
        space && space.permissions && space.permissions.can_set_layout
      );
      setTimeout(() => {
        loading.current = false;
      }, 1000);
    }
  }, [space]);

  useEffect(() => {
    if (sidebar && !validatePremium[0] && !loading.current) {
      setSidebar(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (sidebar) {
      if (!isHidden("sidebar")) {
        hiddenLayoutElements.current.push("sidebar");
      }
    } else {
      if (isHidden("sidebar")) {
        removeHiddenLayoutElements("sidebar");
      }
    }
    onSave();
  }, [sidebar]);

  useEffect(() => {
    if (header && !validatePremium[0] && !loading.current) {
      setHeader(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (header) {
      if (!isHidden("header")) {
        hiddenLayoutElements.current.push("header");
      }
    } else {
      if (isHidden("header")) {
        removeHiddenLayoutElements("header");
      }
    }
    onSave();
  }, [header]);

  useEffect(() => {
    if (cover && !validatePremium[0] && !loading.current) {
      setCover(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (cover) {
      if (!isHidden("cover")) {
        hiddenLayoutElements.current.push("cover");
      }
    } else {
      if (isHidden("cover")) {
        removeHiddenLayoutElements("cover");
      }
    }
    onSave();
  }, [cover]);

  useEffect(() => {
    if (title && !validatePremium[0] && !loading.current) {
      setTitle(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (title) {
      if (!isHidden("title")) {
        hiddenLayoutElements.current.push("title");
      }
    } else {
      if (isHidden("title")) {
        removeHiddenLayoutElements("title");
      }
    }
    onSave();
  }, [title]);

  useEffect(() => {
    if (breadcrumbs && !validatePremium[0] && !loading.current) {
      setBreadcrumbs(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (breadcrumbs) {
      if (!isHidden("breadcrumbs")) {
        hiddenLayoutElements.current.push("breadcrumbs");
      }
    } else {
      if (isHidden("breadcrumbs")) {
        removeHiddenLayoutElements("breadcrumbs");
      }
    }
    onSave();
  }, [breadcrumbs]);

  useEffect(() => {
    if (loading.current) {
      return;
    }
    onSaveWidth();
    const auxSpace = { ...space };
    auxSpace.space_width = width ? "wide" : "narrow";
    dispatch(setSpace(auxSpace));
    window.$spaceWideWidth = width;
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [width]);

  const isHidden = (element) => {
    return hiddenLayoutElements.current.indexOf(element) >= 0;
  };

  const removeHiddenLayoutElements = (element) => {
    const index = hiddenLayoutElements.current.indexOf(element);
    if (index >= 0) {
      hiddenLayoutElements.current.splice(index, 1);
    }
  };

  const onSaveWidth = () => {
    SpaceSettingModel.onUpdate(
      space.slug,
      {
        pod_settings_attributes: {
          space_width: width ? "wide" : "narrow",
        },
      },
      (data) => {},
      (data) => {}
    );
  };

  const onSave = () => {
    if (loading.current) {
      return;
    }
    window.$hiddenLayoutElements = [...hiddenLayoutElements.current];
    SpaceModel.onUpdate(
      space.slug,
      {
        hidden_layout_elements: [...hiddenLayoutElements.current],
        space_width: width ? "wide" : "narrow",
      },
      (data) => {
        const newSpace = { ...space };

        // dispatch(
        //   setHiddenSidebar({
        //     hidden: hiddenLayoutElements.current.indexOf("sidebar") >= 0,
        //   })
        // );

        // if (hiddenLayoutElements.current.indexOf("header") >= 0) {
        //   dispatch(
        //     setHiddenHeader({
        //       hidden: space.permissions.can_manage ? "admin" : true,
        //     })
        //   );
        // } else {
        //   dispatch(setHiddenHeader({ hidden: false }));
        // }
        // dispatch(
        //   setHiddenCover({
        //     hidden: hiddenLayoutElements.current.indexOf("cover") >= 0,
        //   })
        // );
        // dispatch(
        //   setHiddenTitle({
        //     hidden: hiddenLayoutElements.current.indexOf("title") >= 0,
        //   })
        // );
        // dispatch(
        //   setHiddenBreadcrumbs({
        //     hidden: hiddenLayoutElements.current.indexOf("breadcrumbs") >= 0,
        //   })
        // );
        // dispatch(setWideWidth({ on: width }));
      },
      (data) => {}
    );
  };

  return (
    <>
      <Group position="apart" className={classes.item} noWrap spacing="xl">
        <div>
          <Text className={classes.itemTitle}>
            {t("spaces.colors.pageLayout.widePage")}
          </Text>
          <Text className={classes.itemDescription}>
            {"Content on this page will display wider."}
          </Text>
        </div>
        <Switch
          checked={width}
          onChange={(event) => setWidth(event.currentTarget.checked)}
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
        />
      </Group>

      <Group position="apart" className={classes.item} noWrap spacing="xl">
        <div>
          <Text className={classes.itemTitle}>
            {t("spaces.colors.pageLayout.hideSidebar")}
          </Text>
          <Text className={classes.itemDescription}>
            {"The sidebar will no longer be accessible on this page."}
          </Text>
        </div>
        <Switch
          checked={sidebar}
          onChange={(event) => setSidebar(event.currentTarget.checked)}
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
        />
      </Group>

      <Group position="apart" className={classes.item} noWrap spacing="xl">
        <div>
          <Text className={classes.itemTitle}>
            {t("spaces.colors.pageLayout.hideHeader")}
          </Text>
          <Text className={classes.itemDescription}>
            {"The header on this page will only be visible to managers."}
          </Text>
        </div>
        <Switch
          checked={header}
          onChange={(event) => setHeader(event.currentTarget.checked)}
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
        />
      </Group>

      <Group position="apart" className={classes.item} noWrap spacing="xl">
        <div>
          <Text className={classes.itemTitle}>
            {t("spaces.colors.pageLayout.hideCoverImage")}
          </Text>
          <Text className={classes.itemDescription}>
            {"The cover image will not be visible on this page."}
          </Text>
        </div>
        <Switch
          checked={cover}
          onChange={(event) => setCover(event.currentTarget.checked)}
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
        />
      </Group>

      <Group position="apart" className={classes.item} noWrap spacing="xl">
        <div>
          <Text className={classes.itemTitle}>
            {t("spaces.colors.pageLayout.hidePageTitle")}
          </Text>
          <Text className={classes.itemDescription}>
            {"The page title will not show up at the top of the page."}
          </Text>
        </div>
        <Switch
          checked={title}
          onChange={(event) => setTitle(event.currentTarget.checked)}
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
        />
      </Group>

      <Group position="apart" className={classes.itemLast} noWrap spacing="xl">
        <div>
          <Text className={classes.itemTitle}>
            {t("spaces.colors.pageLayout.hideBreadcrumbs")}
          </Text>
          <Text className={classes.itemDescription}>
            {"The breadcrumb navigation links will not be visible at the top of this page."}
          </Text>
        </div>
        <Switch
          checked={breadcrumbs}
          onChange={(event) => setBreadcrumbs(event.currentTarget.checked)}
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
        />
      </Group>

      <PremiumModal opened={opened} setOpened={setOpened} />
    </>
  );
}
