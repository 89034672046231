import React, { useState, useEffect, useRef } from "react";
import { FloatingMenu } from "@tiptap/react";

import { isTextSelection } from "@tiptap/core";
import BlockMenuActions from "./BlockMenuActions";

import { isEmptySelection, isNonTextBlock } from "../utils/selection";

import { useSelector, useDispatch } from "react-redux";

const BlockMenuFloating = (props) => {
  const [isMultiple, setIsMultiple] = useState(false);
  const [isASpace, setIsASpace] = useState(false);
  const [isResource, setIsResource] = useState(false);
  const [isAMedia, setIsAMedia] = useState(false);
  const [isInteractive, setIsInteractive] = useState(false);
  const [excludedFound, setExcludedFound] = useState(false);
  const [isRange, setIsRange] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showInsides, setShowInsides] = useState(false);

  const dispatch = useDispatch();
  const tiptap = useSelector((state) => state.tiptap);

  let show = useRef(true);
  let shown = useRef(false);
  let force = useRef(false);
  let timeoutId = useRef(null);
  let lastText = useRef(-1);

  useEffect(() => {
    const scrollDiv = document
      .getElementsByClassName("content-scrollview")[0]
      .getElementsByTagName("div")[0];

    return () => {
      scrollDiv.onscroll = null; //scrollDiv.removeEventListener("scroll", setScrollEvents)
      scrollDiv.onmousemove = null; //scrollDiv.removeEventListener("scroll", setScrollEvents)
    };
  }, []);

  useEffect(() => {
    const scrollDiv = document
      .getElementsByClassName("content-scrollview")[0]
      .getElementsByTagName("div")[0];

    scrollDiv.onscroll = setScrollEvents;
    scrollDiv.onmousemove = setMouseMoveEvents;
  }, [props]);

  const setScrollEvents = (event) => {
    return;
    if (props.editor && shown.current) {
      const { doc, selection } = props.editor.view.state;
      const slice = selection.content();

      const { from, to, empty } = selection;
      const isEmptyTextBlock =
        !doc.textBetween(from, to).length && isTextSelection(selection);

      if (doc.textBetween(from, to).length == 0) {
        return;
      }

      show.current = false;
      props.editor.chain().blur().run();

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        show.current = true;
        // props.editor.chain().focus().run()
      }, 1000);
    }
  };

  const setMouseMoveEvents = (event) => {
    const tiptapWrapper = document.getElementsByClassName("tiptap-wrapper")[0];
    const focusElement = tiptapWrapper.getElementsByClassName("is-focus");

    if (!focusElement.length && !tiptapWrapper.dataset.focused) {
      return;
    } else if (focusElement.length) {
      tiptapWrapper.dataset.focused = true;
    }

    const { doc, selection } = props.editor.view.state;

    const { from, to } = selection;

    const slice = selection.content();

    if (doc.textBetween(from, to).length && slice.content.content.length <= 1) {
      return;
    }

    if (
      props.editor &&
      !shown.current &&
      !force.current &&
      !isNonTextBlock(props.editor)
    ) {
      force.current = true;
      props.editor.chain().blur().run();
      setTimeout(() => {
        props.editor.chain().focus().run();
      }, 100);
    }
  };

  const shouldShow = (view, state, from, to) => {
    const { doc, selection } = state;
    const { empty } = selection;
    const { $anchor } = selection;

    window.$tiptapAchorPos = $anchor.pos;

    const text = view.state.selection.$head.parent.textContent;

    if (window.$sectionParams?.isSelection) {
      return false;
    } else if (
      lastText.current != text.length &&
      !force.current &&
      !isNonTextBlock(props.editor)
    ) {
      lastText.current = text.length;
      shown.current = false;
      setShowInsides(false);
      return false;
    }

    // Sometime check for `empty` is not enough.
    // Doubleclick an empty paragraph returns a node size of 2.
    // So we check also for an empty text size.
    const node = state.selection.node;

    const isEmptyTextBlock =
      !doc.textBetween(selection.from, selection.to).length &&
      isTextSelection(state.selection);

    if (
      doc.textBetween(selection.from, selection.to).length &&
      isTextSelection(selection)
    ) {
      return false;
    }

    const slice = state.selection.content();

    const listTypes = [props.editor.view.state.doc.nodeAt(selection.from)];
    let exludedFoundAux = false;
    if (listTypes && listTypes.length > 0 && listTypes[0]) {
      exludedFoundAux = listTypes.some(
        (r) => props.excludedNodes.indexOf(r.type.name) >= 0
      );
    }
    const auxIsEmpty = isEmptySelection(props.editor);

    setIsASpace(listTypes.length == 1 && listTypes[0] == "circle");
    setIsResource(listTypes.length == 1 && listTypes[0] == "resource");
    setIsInteractive(
      listTypes.length == 1 &&
        listTypes[0] &&
        ["interactiveInput", "interactiveButton", "interactiveWait"].indexOf(
          listTypes[0].type.name
        ) >= 0
    );

    setIsAMedia(
      listTypes.length == 1 &&
        listTypes[0] &&
        ["image", "video", "file", "embed", "audio"].indexOf(
          listTypes[0].type.name
        ) >= 0
        ? listTypes[0].type.name
        : false
    );

    setIsEmpty(auxIsEmpty);

    setExcludedFound(exludedFoundAux);

    setIsMultiple(slice.content.content.length > 1);

    if (auxIsEmpty) {
      setShowInsides(false);
      return false;
    }

    if (
      listTypes &&
      listTypes.length > 0 &&
      listTypes[0] &&
      ["grid1", "grid2", "grid3"].indexOf(listTypes[0].type.name) >= 0
    ) {
      shown.current = false;
      setShowInsides(false);
      return false;
    }

    if (
      ((!view.hasFocus() || doc.textBetween(from, to).length) &&
        slice.content.content.length <= 1) ||
      (slice.content.content.length > 1 && !exludedFoundAux)
    ) {
      shown.current = false;
      setShowInsides(false);
      return false;
    }

    if (!show.current && !force.current) {
      show.current = true;
      shown.current = false;
      setShowInsides(false);
      return false;
    }

    shown.current = true;
    force.current = false;

    // setIsRange(doc.textBetween(from, to).length)

    setShowInsides(true);
    return true;
  };

  return (
    <FloatingMenu
      editor={props.editor}
      shouldShow={({ view, state, from, to }) =>
        shouldShow(view, state, from, to)
      }
      tippyOptions={{ duration: 100, placement: "bottom" }}
    >
      {showInsides && (
        <BlockMenuActions
          excludedFound={excludedFound}
          isMultiple={isMultiple}
          isASpace={isASpace}
          isResource={isResource}
          isInteractive={isInteractive}
          isAMedia={isAMedia}
          isEmpty={false}
          editor={props.editor}
        />
      )}
    </FloatingMenu>
  );
};

export default BlockMenuFloating;
