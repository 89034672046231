import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import "add-to-calendar-button/assets/css/atcb.css";

import useLangUser from "../../utils/LangUser";

import Broadcaster from "../../helpers/Broadcaster.js";

import { Box, Paper, Group, createStyles, Progress } from "@mantine/core";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
  },
  textColor: {
    color: colors.sidebar_text_color,
  },
  buttonColor: {
    border: isSpaceThemed(colors)
      ? `1px solid ${colors.sidebar_text_color}`
      : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : colors.primary_button_background_color,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
        : tinycolor(colors.sidebar_background_color).darken(1).toString(),
    },
  },
}));

export default function InteractionsBar(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const { language, setLangUser } = useLangUser();

  const [progress, setProgress] = useState(0);
  const [interactions, setInteractions] = useState(0);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    Broadcaster.receive(
      "open_InteractionsBar",
      document.body,
      (event, data) => {
        if (data.progress <= 100) {
          setProgress(data.progress);
        }
      }
    );

    return () => {
      Broadcaster.unRegisterElement("open_InteractionsBar", document.body);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    setPreview(urlParams.has("preview_ij") ? true : null);

    const auxProgress =
      ((window.$spaceUserInteractions.position + 1) * 100) /
      (window.$spaceUserInteractions.count);

    // console.log(window.$spaceUserInteractions)

    setProgress(auxProgress > 100 ? 100 : auxProgress);
  }, [props]);

  if (
    !props.space.slug ||
    (props.space && props.space.slug && !window.$spaceUserInteractions)
  ) {
    return null;
  }

  return (
    <>
      {(!window.$spaceUserInteractions.can_manage || preview) && (
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            top: "0px",
            zIndex: 100,
          }}
        >
          <Progress
            size="xl"
            radius={0}
            value={parseInt(progress)}
            styles={{
              root: {
                backgroundColor: colors.background_color,
              },
              bar: {
                backgroundColor: colors.primary_button_background_color,
                borderTopLeftRadius: "0 !important",
                borderTopRightRadius: "2px !important",
                borderBottomLeftRadius: "0 !important",
                borderBottomRightRadius: "2px !important",
              },
            }}
          />
        </Box>
      )}
    </>
  );
}
