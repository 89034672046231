import React, { useState, useEffect } from 'react';
import {

  Box,

  Button,
  TextInput,
  Group
} from '@mantine/core'

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import SpaceModel from "../../../../../models/Space";

export default function AddToNewSpace(props) {

  const space = useSelector(state => state.space);
  const [stepSlug, setStepSlug] = useState(null)
  const { t, i18n } = useTranslation();
  const form = useForm({
    initialValues: {
      intention: "",
      version: "discussion",
      privacy_level: "private",
      pod_state: "model",
    },

    validate: {
      intention: (value) => value.length > 0 ? null : "Please add a name for your space",
    }
  });

  useEffect(() => {
    if (props.mode === "edit") {
      onFetchSpace()
    }
  }, [props])

  const onFetchSpace = () => {
    if (props.step) {
      SpaceModel.onFetchSimple(props.step.object_id, "basic", (data) => {
        form.setFieldValue("intention", data.name)
        setStepSlug(data.slug)
      }, (data) => {

      })
    }
  }

  const onSend = () => {
    if (props.mode === "edit") {
      SpaceModel.onUpdate(stepSlug, form.values, (data) => {
        props.onUpdate({
          object_type: "pod",
          object_id: props.step.object_id
        })
      }, (data) => {
      })
    } else {
      SpaceModel.onCreate(form.values, space.id, (data) => {
        props.onCreate({
          object_type: "pod",
          object_id: data.id
        })
      }, (data) => {
      })
    }
  }

  const onDestroy = (event) => {
    SpaceModel.onDestroy(stepSlug, (data) => {
      props.onDestroy(event)
    }, (data) => {

    })
  }

  return (
    <Box>
      <form
        onSubmit={form.onSubmit((values) => onSend(values))}>
        <TextInput
          mt={20}
          placeholder={t("spaces.settings.registration.steps.addToNewSpace.placeholder")}
          description="Add a naming pattern for your space. You can use {{first_name}} and {{last_name}} to insert the person's name."
          label={t("spaces.settings.registration.steps.addToNewSpace.label")}
          value={`${space.name} - {{first_name}} {{last_name}}`}
          radius="sm"
          size="md"
          {...form.getInputProps('intention')}
        />

        {
          props.mode === "edit" ? (
            <Group
              position="apart"
            >
              <Button
                mt={25}
                radius="xl"
                // size="md"
                type="submit"
                leftIcon={<FontAwesomeIcon icon={solid('check')} />}
              >
                {t("spaces.settings.registration.steps.addToNewSpace.saveButton1")}
              </Button>
              <Button
                onClick={(event) => onDestroy(event)}
                mt={25}
                radius="xl"
                // size="md"
                color="dark"
                leftIcon={<FontAwesomeIcon icon={regular('trash-can')} />}
              >
                {t("spaces.settings.registration.steps.addToNewSpace.deleteButton")}
              </Button>
            </Group>
          ) : (
            <Button
              type="submit"
              // onClick={() => props.onCreate(params)}
              leftIcon={<FontAwesomeIcon icon={solid('check')} />}
              mt={25}
              radius="xl"
              // size="md"
              fullWidth
            >
              {t("spaces.settings.registration.steps.addToNewSpace.saveButton2")}
            </Button>
          )
        }
      </form>
    </Box>
  )
}
