import { useMantineTheme } from '@mantine/core';

export default function useTagColors(color) {

  const theme = useMantineTheme();

  switch(color) {
    case theme.other.tagColors[0].backgroundColor:
      return 0;
      break;
    case theme.other.tagColors[1].backgroundColor:
      return 1;
      break;
    case theme.other.tagColors[2].backgroundColor:
      return 2;
      break;
    case theme.other.tagColors[3].backgroundColor:
      return 3;
      break;
    case theme.other.tagColors[4].backgroundColor:
      return 4;
      break;
    case theme.other.tagColors[5].backgroundColor:
      return 5;
      break;
    case theme.other.tagColors[6].backgroundColor:
      return 6;
      break;
    case theme.other.tagColors[7].backgroundColor:
      return 7;
      break;
    case theme.other.tagColors[8].backgroundColor:
      return 8;
      break;
    case theme.other.tagColors[9].backgroundColor:
      return 9;
      break;
    case theme.other.tagColors[10].backgroundColor:
      return 10;
      break;
    case theme.other.tagColors[11].backgroundColor:
      return 11;
      break;
    case theme.other.tagColors[12].backgroundColor:
      return 12;
      break;
    case theme.other.tagColors[13].backgroundColor:
      return 13;
      break;
    default:
      return 5;
    }

}
