import React, { useState, useEffect, useRef } from "react";

import {
  Box,
  Text,
  Group,
  MantineProvider,
  Select,
  createStyles,
  Switch,
} from "@mantine/core";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Open from "../privacy/Open";
import Private from "../privacy/Private";
import Paid from "../privacy/Paid";

const useStyles = createStyles((theme) => ({
  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },
}));

export default function Privacy({
  onUpdate,
  privacyOptions,
  privacySelection,
  setPrivacySelection,
}) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);

  const [visibility, setVisibility] = useState();

  const { classes } = useStyles();

  const propsLoaded = useRef();

  const SelectItem = ({ label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <div>
          <Text size="lg" weight={600}>
            {label}
          </Text>
          <Text size="md" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  const onFetch = () => {
    
  }

  const displayPrivacySelection = () => {
    if (privacySelection == "open") {
      return <Open />;
    } else if (privacySelection == "private") {
      return <Private />;
    } else if (privacySelection == "payment") {
      return (
        <Paid
          showDisconnect={true}
          settings={space}
          onUpdate={onUpdate}
          onRefresh={onFetch}
          space={space}
          options={["paid", "flexible", "recurring"]}
        />
      );
    } else {
      return <Open />;
    }
  };

  useEffect(() => {
    if (!space.settings) {
      return;
    }
    propsLoaded.current = false;
    setPrivacySelection(space.privacy_level);
    setVisibility(space.visibility == "public");

    setTimeout(() => {
      propsLoaded.current = true;
      // setDisableActions(false);
    }, 1000);
  }, [space]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ visibility: visibility ? "public" : "" });
    }
  }, [visibility]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ privacy_level: privacySelection });
    }
  }, [privacySelection]);

  return (
    <Box mb={20}>
      <MantineProvider theme={{ primaryShade: 6 }}>
        <Select
          value={privacySelection}
          onChange={setPrivacySelection}
          placeholder={t("spaces.settings.main.main.privacySetting")}
          itemComponent={SelectItem}
          data={privacyOptions}
          maxDropdownHeight={400}
          radius="md"
          size="lg"
        />
      </MantineProvider>

      {privacySelection != "private" &&
        space.slug &&
        space.belongs_to_private_or_paid_circles && (
          <Group position="apart" noWrap spacing="xl" mb={20} mt={20}>
            <div>
              <Text className={classes.itemTitle}>
                {t("spaces.settings.main.main.title1")}
              </Text>
              <Text className={classes.itemDescription}>
                {t("spaces.settings.main.main.description1")}
              </Text>
            </div>
            <Switch
              onLabel={t("spaces.settings.main.main.on")}
              offLabel={t("spaces.settings.main.main.off")}
              className={classes.switch}
              size="lg"
              checked={visibility}
              onChange={(event) => setVisibility(event.currentTarget.checked)}
            />
          </Group>
        )}

      {displayPrivacySelection()}
    </Box>
  );
}
