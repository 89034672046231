import React from "react";

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStyles } from "@mantine/core";

import NavbarContent from "./layout/NavbarContent";
import MainContent from "./layout/MainContent";

import { setHiddenSidebar, setHiddenHeader } from "../../../store/app";

const useStyles = createStyles((theme) => ({
  settingsWrap: {
    display: "flex",
    flexFlow: "row",
    height: "100%",
  },
  settingSidebar: {
    width: "300px",
    boxSizing: "border-box",
    overflow: "hidden",
    backgroundColor: theme.colors.gray[7],
  },
  settingsMain: {
    width: "100%",
    height: "calc(100vh)",
    // backgroundColor: "red",
    "& > div": {
      height: "100%",
    },
  },
}));

function Settings(props) {
  const user = useSelector((state) => state.user);
  const timeoutId = useRef();
  const { classes, cx } = useStyles();

  const dispatch = useDispatch();

  const helpScouteLoaded = useRef();

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: false }));
    dispatch(setHiddenHeader({ hidden: false }));

    timeoutId.current = setTimeout(() => {
      timeoutId.current = null;
      dispatch(setHiddenSidebar({ hidden: false }));
      dispatch(setHiddenHeader({ hidden: false }));
    }, 1000);

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      if (window.$hiddenLayoutElements) {
        dispatch(
          setHiddenSidebar({
            hidden: window.$hiddenLayoutElements.indexOf("sidebar") >= 0,
          })
        );
        dispatch(
          setHiddenHeader({
            hidden: window.$hiddenLayoutElements.indexOf("header") >= 0,
          })
        );
      } else {
        dispatch(setHiddenSidebar({ hidden: false }));
        dispatch(setHiddenHeader({ hidden: false }));
      }

      window.Beacon("reset");
      window.Beacon("destroy");
      helpScouteLoaded.current = false;
      localStorage.removeItem("lastOpenedSettingsTabs");
    };
  }, []);

  useEffect(() => {
    if (user && user.authenticated) {
      if (!helpScouteLoaded.current && user.username) {
        helpScouteLoaded.current = true;
        setTimeout(() => {
          window.Beacon("init", "8baee135-5597-476a-8269-cb1460d638e4");
          window.Beacon("identify", {
            name: user.name,
            email: user.email,
            account_type: user.account_type,
            // avatar: user.avatar ? user.avatar.url : "",
          });
        }, 2000);
      }
    }
  }, [props]);

  return (
    <div className={classes.settingsWrap}>
      <div className={classes.settingSidebar}>
        <NavbarContent />
      </div>
      <div className={classes.settingsMain}>
        <MainContent />
      </div>
    </div>
  );
}

export default Settings;
