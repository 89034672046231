import i18n from "i18next";

import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          premiumFeatures: {
            personal: {
              description1:
                "Unlimited spaces and members to organize content and conversations for all your offerings",
              description2:
                "Beautiful registration and landing pages for all your programs and events in one place",
              description3:
                "Send newsletter emails to all your participants with our powerful broadcast function",
            },
            professional: {
              description1: "No transaction fees on collected payments",
              description2:
                "Custom branding and layout options and personalized URL subdomains",
              description3:
                "Unlimited automations and interactive conversational learning experiences",
              description4: "Up to 2 managers for all of your spaces",
            },
            organization: {
              description1: "No transaction fees on collected payments",
              description2: "Advanced customer support",
              description3: "Unlimited managers",
              description4: "Option for Single Sign On integration",
            },
          },
          premiumMessages: {
            can_auto_tag: {
              title: "Upgrade to use this feature",
              message: "Auto tagging with AI is a premium feature available on the Silver plan."
            },
            can_use_zoom_recordings: {
              title: "Upgrade to use this feature",
              message: "Zoom recordings is a premium feature available on the Gold plan."
            },
            can_create_quizzes: {
              title: "Upgrade to use this feature",
              message: "Quizzes are a premium feature available on the Gold plan."
            },
            can_duplicate_spaces: {
              title: "Upgrade to use this feature",
              message:
                "Duplicating a space is a premium feature available on the Silver plan.",
            },
            can_save_templates: {
              title: "Upgrade to use this feature",
              message:
                "Saving as space as a reusable template is a premium feature available on the Silver plan.",
            },
            can_edit_shareable_link: {
              title: "Upgrade to use this feature",
              message:
                "Custom shareable links are a premium feature available on the Silver plan.",
            },

            can_upload_cover: {
              title: "Upgrade to use this feature",
              message:
                "Uploading a custom cover image is a premium feature available on the Personal plan.",
            },

            can_upload_logo: {
              title: "Upgrade to use this feature",
              message:
                "Setting a custom logo is a premium feature available on the Silver plan.",
            },

            can_set_layout: {
              title: "Upgrade to use this feature",
              message:
                "Customizing the layout of your space is a premium feature available on the Silver plan.",
            },

            can_set_theme: {
              title: "Upgrade to use this feature",
              message:
                "Customizing the color theme of your space is a premium feature available on the Silver plan.",
            },

            can_set_format: {
              title: "Upgrade to use this feature",
              message:
                "Switching the format of your space is a premium feature available on the Silver plan.",
            },

            can_add_step_send_to_pending: {
              title: "Upgrade to use this feature",
              message:
                "Setting new members to a pending status is a premium feature available on the Personal plan.",
            },

            can_add_step_send_email: {
              title: "Upgrade to use this feature",
              message:
                "Sending automated emails is a premium feature available on the Personal plan.",
            },

            can_add_add_to_existing_space: {
              title: "Upgrade to use this feature",
              message:
                "Automatically adding members to select spaces is a premium feature available on the Silver plan.",
            },

            can_add_step_wait: {
              title: "Upgrade to use this feature",
              message:
                "Adding a wait condition for additional steps is a premium feature available on the Silver plan.",
            },

            can_add_step_webhook: {
              title: "Upgrade to use this feature",
              message:
                "Adding webhooks is a premium feature available on the Silver plan.",
            },

            can_add_step_survey_person: {
              title: "Upgrade to use this feature",
              message:
                "Surveying new members is a premium feature available on the Silver plan.",
            },

            can_schedule_states: {
              title: "Upgrade to use this feature",
              message:
                "Automated scheduling options for your space are a premium feature available on the Silver plan.",
            },

            can_broadcast: {
              title: "Upgrade to use this feature",
              message:
                "Sending email broadcasts is a premium feature available on the Personal plan.",
            },

            can_survey: {
              title: "Upgrade to use this feature",
              message:
                "Creating surveys is a premium feature available on the Silver plan.",
            },

            can_use_interest_form_block: {
              title: "Upgrade to use this feature",
              message:
                "Adding interest forms is a premium feature available on the Silver plan.",
            },

            can_use_interactive_block: {
              title: "Upgrade to use this feature",
              message:
                "Adding interactive blocks is a premium feature available on the Silver plan.",
            },

            can_set_flexible_payments: {
              title: "Upgrade to use this feature",
              message:
                "Setting flexible payment options is a premium feature available on the Silver plan.",
            },

            can_activate_completions: {
              title: "Upgrade to use this feature",
              message:
                "The ability to mark spaces as complete is a premium feature available on the Silver plan.",
            },

            max_allowed_spaces: {
              title: "Upgrade to use this feature",
              message:
                "Hey there - looks like you've used up all your free spaces!  Upgrading to the Personal plan gives you unlimited spaces and members so you can pretty much create anything.",
            },

            max_allowed_ij: {
              title: "Upgrade to use this feature",
              message:
                "You can only add up to 5 discussion prompts per space on the free plan. Please upgrade to the Silver plan to add unlimited discussion prompts to any space.",
            },

            max_allowed_managers_pro: {
              title: "Upgrade to add more managers",
              message:
                "Upgrade to the Silver plan to add up to 2 managers to your space or the Gold plan for unlimited managers.",
            },

            max_allowed_managers_org: {
              title: "Upgrade to add more managers",
              message:
                "Upgrade to the Gold plan to add unlimited managers to your space.",
            },

            can_see_participation_tab: {
              title: "Upgrade to use this feature",
              message:
                "Participation metrics are a premium feature available on the Silver plan.",
            },

            can_send_selective_broadcast: {
              title: "Upgrade to use this feature",
              message:
                "Selective broadcasting is a premium feature available on the Gold plan.",
            },

            can_send_message_broadcast: {
              title: "Upgrade to use this feature",
              message:
                "Message broadcasting is a premium feature available on the Gold plan.",
            },
            can_add_from_children: {
              title: "Upgrade to use this feature",
              message:
                "Automatically adding any members from sub spaces is a premium feature available on the Silver plan.",
            },
            can_add_to_direct_open_children: {
              title: "Upgrade to use this feature",
              message:
                "Automatically adding incoming members to all direct open sub spaces is a premium feature available on the Silver plan.",
            },
            can_send_message: {
              title: "Upgrade to use this feature",
              message:
                "Automatically sending direct messages to new members is a premium feature available on the Silver plan.",
            },

            
          },
          home: {
            sidebar: {
              createButton: "New space",
              spaces: "Your spaces",
              invitations: "Invitations",
              directMessages: "Direct messages",
              gettingStarted: "Getting started",
              scheduleDemo: "Schedule a demo",
              upgrade: "Upgrade your plan",
              promoBoxTitle:
                "Join hundreds of creators using Sutra's advanced features to deliver powerful group experiences online",
              promoBoxItem1: "Unlimited spaces",
              promoBoxItem2: "Full branding options",
              promoBoxItem3: "Extra managers",
              promoBoxButton: "Upgrade now",
            },
            spacesList: {
              yourSpaces: "Your spaces",
              directMessagesTitle: "Direct messages",
              invitationsTitle: "Invitations",
              topTitle: "Top level spaces",
              managedTitle: "Managed spaces",
              allTitle: "All spaces",
              archivedTitle: "Archived spaces",
              deletedTitle: "Deleted spaces",
              pendingTitle: "Scheduled spaces",
              directMessages: "Direct",
              top: "Top level",
              managed: "Managed",
              all: "All",
              archived: "Archived",
              invitations: "Invitations",
              deleted: "Deleted",
              pending: "Scheduled",
              empty: "You don't have any",
              tabTitle: "Sutra - Dashboard",
              learn: "Learn the basics",
              createFirst: "Create your first space",
            },
          },
          app: {
            payment: {
              payment: {
                tabTitle: "Sutra - Payment form",
                title: "Complete payment",
              },
              planOption: {
                frequencyDescription1: "per month",
                frequencyDescription2: "per month when billed annually",
                currentPlan: "Current plan",
                billedMonthly: "Billed monthly",
                billedAnnually: "Billed annually",
              },
              stripeForm: {
                due_in_trial: "Due in 14 days",
                pay: "Pay",
              },
            },
            gettingStarted: {
              items: {
                setup: "Setting up your program space",
                privacy: "Privacy and payment settings",
                registration: "Creating a registration page",
                invitations: "Inviting participants",
                settings: "How space settings work",
                interactive: "Interactive journeys",
                interest: "Interest forms",
                appearance: "Layout and color themes",
                grids: "Grid and block settings",
                broadcasts: "Sending email broadcasts",
                tagging: "Tagging",
                emojis: "Sidebar emojis",
              },

              templates: {
                groupprogram: {
                  label: "Group program",
                  description:
                    "A program space where you can organize everything you need for a group of people learning together.",
                },
                zoomworkshop: {
                  label: "Zoom workshop",
                  description:
                    "Organize everything you need for a simple zoom based workshop.",
                },
                mastermind: {
                  label: "Mastermind",
                  description:
                    "Invite a group of people to step into a mastermind process together.",
                },
                selfpacedcourse: {
                  label: "Self paced course",
                  description:
                    "A flexible template to create a self paced course.",
                },
                communityspace: {
                  label: "Community space",
                  description:
                    "Bring people together in a vibrant online community space that grows as your community grows.",
                },
                oneononecoaching: {
                  label: "One-on-one coaching",
                  description:
                    "Create a dedicated space for each of your coaching clients.",
                },
              },
              loading: {
                text: "Your template is loading, please wait…",
              },
              tabTitle: "Sutra - Getting started",
              mainTitle: "Getting started guide",
              selectTemplate: "Select this template",
              advanceFeatures: "Advanced Features",
              welcome: "Welcome",
              description1:
                "Sutra makes it easy to organize content and conversations for courses, communities, one-on-one coaching, and everything in between. Learn the basics in this five minute video.",
              watchVideo: "Watch the video",
              createFirstSpace: "Create your first space",
              gettingStartedGuide: "Getting started guide",
              description2:
                "Learn more about how Sutra works and get started with a launch checklist and more detailed guidance.",
              checkOutTheGuide: "Check out the guide",
              startWithATemplate: "Start with a template",
              description3:
                "Learn more about how Sutra works and get started with a launch checklist and more detailed guidance.",
              learnAbout: "Learn about advanced features",
              description4:
                "Sutra is designed to be flexible and powerful to accommodate almost any use case  can create uniquely engaging interactive experiences, capture interest for your various  offerings, send email newsletters, and harvest collective knowledge.",
              learnmore: "Learn more",
            },
            premium: {
              try: "Try Sutra",
              include: "Features include",
              upgrade: "Upgrade now",
            },
            pricing: {
              tabTitle: "Sutra - Pricing",
              upgrade: "Upgrade your plan",
              text: "Whatever you need for your course or community, we're here to support your work. Reach out to us with any questions you might have at support@sutra.co.",
            },
            scheduleDemo: {
              tabTitle: "Sutra - Schedule a demo",
            },
            welcome: {
              tabTitle: "Sutra - Welcome",
              title: "Welcome to Sutra!",
              description1:
                "We are so happy to have you here. Please take a moment to watch the video below...",
              description2:
                "Would you like to try one of our paid plans for free?",
              description3:
                "Pick a plan below to start a 14 day free trial. Once the trial is over you will automatically be billed for the plan you select.",
              yes: "Yes, please show me the options",
              no: "No, thank you",
            },
          },
          layout: {
            userButton: {
              home: "Home",
              viewProfile: "View profile",
              settings: "Settings",
              support: "Support",
              language: "Language",
              terms: "Terms & privacy",
              logout: "Logout",
              copyright: "Sutra Spaces, LLC © 2022",
            },
            directMessages: {
              label: "Direct messages",
              actionButton: "View all",
              empty: "You don't have any direct messages",
              directMessage: "Direct messages",
            },
            notifications: {
              label: "Notifications",
              actionButton: "Clear all",
              empty: "You don't have any notifications",
              notification: "Notifications",
            },
            headerContent: {
              registrationPage: "Registration page",
              nonManagersHidden: "Header hidden for non-managers",
            },
            search: {
              reflection: "Reflection",
              comment: "Comment",
              allSpaces: "All spaces",
              withinTop: "Within top level space",
              withinSpace: " Within this space",
              everything: "Everything",
              titlesOnly: "Titles only",
              tagsOnly: "Tags only",
              content: "Content",
              discussion: "Chat",
              events: "Events",
              users: "Users",
              placeholder: "Search...",
              nothingFound: "Nothing found",
              search: "Search",
            },
            uploader: {
              pending: "Pending",
              used: "Used",
              uploading: "Uploading",
              finishing: "Finishing",
              processing: "Processing",
              completed: "Completed",
            },
          },
          resources: {
            pages: {
              form: {
                label: "Title",
                placeholder: "What's your page topic?",
                create: "Create page",
                save: "Save page",
              },
            },
            form: {
              title: {
                label: "Title",
                placeholder: "What's your topic?",
              },
              publish: {
                label: "Publish on date",
              },
              date: {
                label: "Pick date",
              },
              update: "Update collapsable section",
              create: "Create collapsable section",
            },
          },
          settings: {
            editProfile: {
              tabTitle: "Sutra - Edit Profile",
              editProfile: "Edit profile",
              firstName: "First name",
              lastName: "Last name",
              username: "Username",
              email: "Email",
              removeProfileImage: "Remove profile image",
              addProfileImage: "Add profile image",
              about: "About",
              location: "Location",
              bio: "Bio",
              bioPlaceholder: "Share a little about yourself...",
              updateProfile: "Update profile",
            },
            accountSettings: {
              accountSettings: "Account settings",
              changePassword: "Change password",
              currentPassword: "Current password",
              newPassword: "New password",
              newPasswordConfirmation: "New password confirmation",
              updatePassword: "Update password",
              advanced: "Advanced",
              deleteAccount: "Delete account",
              warning: "Warning",
              deleteAccountWarning:
                "Deleting your account is irreversible and will permanently remove your account details from our system.",
              closeYourAccount: "Close your account",
              invalidCurrentPasswordErrorMsg: "Can't be empty",
              invalidPasswordErrorMsg:
                "Password must be at least 8 characters long",
              invalidPasswordConfirmationErrorMsg: "Passwords don't match",
              deleteAccountAlert: {
                title: "Important",
                message:
                  "Deleting your account is irreversible and will permanently remove your account details from our system",
                input: "Please type 'delete my account' to confirm this action",
                confirmButton: "Delete forever",
                cancelButton: "Cancel",
              },
              cancelSubscriptionAlert: {
                title: "Important",
                message: "Please cancel your subscription first",
                confirmButton: "Go to billing",
                cancelButton: "I'll do it later",
              },
              stripeAccount: {
                title: "Reset Stripe for all spaces",
                alertTitle: "Are you sure?",
                alertMessage:
                  "If you reset your Stripe connection, your Stripe credentials will be cleared from your user account on Sutra and you will be able to connect a new Stripe account.",
                buttonText: "Reset connection",
                confirmButton: "Yes",
                cancelButton: "No",
                confirmTitle: "Are you sure?",
                confirmMessage:
                  "Your Stripe credentials will be cleared from all spaces on Sutra",
              },
            },
            emailPreferences: {
              tabTitle: "Sutra - Email Preferences",
              emailPreferences: "Email preferences",
              emailDigestPreferences: "Email digest preferences",
              emailDigestFrequency: "Email digest frequency",
              emailDigestMessage:
                "To adjust email preferences by space, please view the subscriptions settings area. You can unsubscribe from a space completely by leaving it or you can set any individual space to 'Mute' in the subscriptions settings area.",
              receiveWeeklyDigest:
                "Receive a weekly digest of newly tagged items and discussions",
              receiveAtMentions:
                "Receive a notification when someone @mentions you",
            },
            subscriptions: {
              frequencyOptions: "Frequency options",
              otherActions: "Other actions",
              subscriptions: "Space specific preferences",
              searchBySpaceName: "Search by space name",
              search: "Search",
              clear: "Clear",
              options: "Options",
              otherActions: "Other actions",
              emptySubscriptions: "You don't have any subscriptions.",
              leaveSpaceConfirmationTitle:
                "Are you sure you want to leave this space?",
              leaveSpaceConfirmationHtml:
                "You will no longer be a member of this space and may not be able to join again without asking the manager.",
              leaveSpaceButtonText: "Yes, leave",
              leaveSpaceDenyButtonText: "No",
              leaveSpace: "Leave space",
              unsubscribe: "Unsubscribe",
              unsubscribed: "Unsubscribed",
            },
            digestPreferences: {
              digestedLabel: "Group all updates into one digest",
              digestedDescription:
                "New messages across all your spaces will be digested into one email update.",
              undigestedLabel: "Group updates by space",
              undigestedDescription:
                "You will receive separate email digests for each space that has new messages.",
            },
            paid_subscriptions: {
              tabTitle: "Sutra - Paid subscriptions",
              title: "Paid subscriptions",
              noPayments: "You don't have any paid subscriptions"
            },
            billing: {
              statuses: {
                pending: "Pending",
                processing: "Processing",
                paid: "Paid",
              },
              paymentMethod: {
                currentPaymentMethod: "Current Payment Method",
                noCurrentPaymentMethod: "No Current Payment Method",
                addNewPaymentMethod: "Add new payment method",
              },
              stripe: {
                updatePaymentMethod: "Update payment method",
              },
              tabTitle: "Sutra - Billing",
              updatePaymentMethodMayus: "UPDATE PAYMENT METHOD",
              payments: "PAYMENTS",
              pdf: "PDF",
              noPayments: "You have no payment history",
              billing: "Billing",
              upgradePlan: "Upgrade plan",
              changePlan: "Change plan",
              updatePaymentMethod: "Update payment method",
              cancelSubscription: "Cancel subscription",
              cancelConfirmationTitle:
                "Are you sure you want to cancel your subscription?",
              cancelConfirmationHtml:
                "You will no longer have access to your plan's premium features.",
              cancelConfirmButtonText: "Yes, cancel",
              cancelDenyButtonText: "No",
              billingHistory: "Billing History",
              tableDate: "Date",
              tableAmount: "Amount",
              tableStatus: "Status",
              tableInvoice: "Invoice",
              planPrefix: "You are currently on the",
              planSuffix: "plan",
              updatePayment: {
                success: {
                  title: "Success",
                  message: "You payment method has been updated",
                },
                error: {
                  title: "Opps, something went wrong!",
                },
              },
            },
            frequencyOptions: {
              mute: "Mute",
              always: "On every message",
              hour1: "Every hour",
              hour2: "Every 2 hours",
              hour4: "Every 4 hours",
              hour8: "Every 8 hours",
              hour24: "Once a day",
              hour168: "Once a week",
              hour336: "Every two weeks",
            },
            affiliates: {
              history: {
                empty: "You have no ambassador payout history",
                table: {
                  referralName: "Referral name",
                  startAt: "Start at",
                  endAt: "End at",
                  createdAt: "Charged on",
                  percent: "Commission",
                  total: "Total earned",
                  status: "Status",
                  value: "Amount",
                  revenue: "Payout",
                  payAt: "Paid at",
                  status: "Status",
                },
                statuses: {
                  null: "Pending",
                  pending: "Pending",
                  processing: "Processing",
                  paid: "Paid",
                  active: "Active",
                },
                paymentsHistory: "Payout history",
              },
              tabTitle: "Sutra - Ambassador program",
              title: "Ambassador program",
              affiliateLink: "This is your affiliate link",
              copyMessage: "Link copied to clipboard",
              stripeTitle: "Stripe connected to",
              stripeConnected:
                "This is your Stripe account ID.  To find this ID on Stripe, please click on the link to log into Stripe and scroll to the bottom of your account settings page.",
              stripeDisconnect: "Disconnect",
              description:
                "To process payments for the ambassador program program please connect your Stripe account by clicking on the button below.",
              description2:
                "When you share this link with others, you'll automatically receive 40% of the first year of any revenue generated from anyone who signs up for a paid Sutra account.",
              connect: "Connect Stripe",
              historyLabel: "Payout history",
            },
          },
          spaces: {
            settings: {
              layout: {
                navbarContent: {
                  mainSettings: "General settings",
                  members: "Members",
                  broadcast: "Broadcast",
                  surveys: "Surveys",
                  structures: "Structures",
                  sutraAdmin: "Sutra Admin",
                  scheduled: "Scheduled",
                  archived: "Archived",
                  participation: "Participation",
                },
                headerActions: {
                  shareableLink: "SHAREABLE LINK",
                },
              },
              main: {
                title: "General settings",
                main: {
                  invite: "Invite",
                  shareableLink: "Shareable link",
                  cancelLink: "Cancel",
                  saveLink: "Save",
                  editLinkButton: "Edit link",
                  subdomain: "Your subdomain",
                  sutra_co: ".sutra.co",
                  label1: "Link copied to clipboard",
                  privacySetting: "Select a privacy setting",
                  title1: "Make this space publically accessible",
                  description1:
                    "This space is within a private space and only visible to members of that space. You can make it publically accessible outside of this space by turning this setting on.",
                  title2: "Display registration page",
                  description2:
                    "Non-members will see a registration page when they try to access this space if this setting is turned on.",
                  title3: "Use legacy registration page",
                  description3:
                    "If this setting is turned on this space will use the registration page designed using our legacy editor.",
                  onButton: "ON",
                  offButton: "OFF",
                  editButton: "Edit page",
                  previewButton: "Preview page",
                  label2: "Privacy and payment settings",
                  label3: "Registration page settings",
                  description4:
                    "Active - People will land on a registration page before they can access this space",
                  label4: "Automation",
                  description5:
                    "Schedule emails and automatically add people to spaces when they register",
                  label5: "Scheduling settings",
                  description6:
                    "Set an event date or schedule publishing and archiving options",
                  label6: "Additional settings",
                  description7:
                    "Content creation permissions, membership visibility, manager notifications, and parent spaces",
                  label7: "SEO Settings",
                  description8:
                    "Set meta tag title, description, and image for social sharing and search engine optimization",
                  label8: "Open",
                  description9: "Anyone can access this space",
                  label9: "Private",
                  description10:
                    "This space is private and people must be invited to join",
                  label10: "Paid",
                  description11:
                    "This space is paid and private. People must pay to join",
                  description12:
                    "People will land on a registration page before they can access this open space.",
                  description13:
                    "People will land directly into this space when they access this open space.",
                  description14:
                    "People must be directly invited to access this private space.  If they are invited they will see a registration page.",
                  description15:
                    "People must be directly invited to access this private space.  If they are invited they will see an account creation page with no registration page.",
                  description16:
                    "People will land on a registration page before they can access this paid space.",
                  description17:
                    "People will land on an account creation and payment page before they can access this paid space.  They will not see a registration page.",
                  on: "ON",
                  off: "OFF",
                  label11: "Members",
                  label12: "Pending",
                  label13: "Interested",
                  label14: "Invited",
                  label15: "Payments",
                  label16: "Branding",
                },
                additional: {
                  allowMembersEdit:
                    "Allow members to edit content on this page",
                  membersWillAddContent:
                    "Members will be able to add and edit content on this page",
                  allowMembersCreatePosts: "Allow members to create new posts",
                  membersWillCreatePosts:
                    "Members will be able to create new posts in this space",
                  allowMembersCreateEvents:
                    "Allow members to create new events",
                  membersWillCreateEvents:
                    "Members will be able to create new events in this space",
                  allowMembersCreateSubspaces:
                    "Allow members to create new subspaces",
                  membersWillCreateSubspaces:
                    "Members will be able to create sub spaces on this page",
                  membersCanCreate: "Members can create",
                  circleCreation: {
                    options: {
                      discussion: "Chat spaces",
                      content: "Content spaces",
                      event: "Event spaces",
                    },
                    placeholder:
                      "Select the types of spaces that members can create in this space",
                  },
                  allowMembersToCopy: {
                    description1: "Allow members to copy members across spaces",
                    description2:
                      "Members will have the option to automatically add members from other circles",
                  },
                  podType: {
                    description1: "All members can post messages in this space",
                    description2:
                      "If this option is turned off, only managers can post messages",
                  },
                  allowReflections: {
                    description1: "Enable reflections for this space",
                    description2:
                      "If this option is turned off, the reflect option will not show up on messages",
                  },
                  showMembers: {
                    description1: "Show members",
                    description2: "Show the member directory for this space",
                  },
                  hideMmemberEmails: {
                    description1: "Display member emails in member directory",
                    description2:
                      "If this option is turned off, member emails will not be listed in the directory",
                  },
                  newMemberNotifications: {
                    description1: "Notify managers when new members join",
                    description2:
                      "Send a notification email on each new member sign up",
                  },
                  podSize: {
                    description1:
                      "Limit number of people that can join this space",
                    description2:
                      "Specify a maximum number of people that can join this space",
                  },
                  podSizeValue: {
                    description: "How many people can join this space?",
                  },
                  sidebarDefaultOpened: {
                    description1: "Space opened by default in sidebar",
                    description2:
                      " If this option is turned on this, this space will default to expanded in the sidebar",
                  },
                  sidebarHideChildren: {
                    description1: "Hide sub spaces in sidebar",
                    description2:
                      " If this option is turned on, sub spaces created in this space will not appear in the sidebar",
                  },
                  allowCompletions: {
                    description1: "Show mark complete button",
                    description2:
                      'If this option is turned on, members will see a "Mark complete" button for this space. After they select this option, the icon in the sidebar will be replaced with a check mark',
                  },
                  redirectToSubspace: {
                    description1: "Always redirect to sub space",
                    description2:
                      "If this option is turned on, members will always land in the selected sub space when accessing this space",
                  },
                  parentCircles: {
                    description1: "Parent spaces",
                    placeholder:
                      "Search for spaces to add as parents to this space",
                  },
                },
                scheduling: {
                  showStartDate: {
                    description1: "Display this space as an event",
                    description2:
                      "This space will display an event date wherever it is listed",
                  },
                  startDateTime: {
                    title: "Start date and time",
                    placeholder: "Pick date",
                  },
                  endDateTime: {
                    title: "End date and time",
                    placeholder: "Pick date",
                  },
                  showLaunchDate: {
                    description1: "Publish this space on a date",
                    description2:
                      "This space will become visible and accessible on the date specified",
                  },
                  launchDateTime: {
                    placeholder: "Pick date",
                  },
                  showArchiveDate: {
                    description1: "Archive this space on a date",
                    description2:
                      "This space will no longer be accessible on the date specified",
                  },
                  archiveDate: {
                    placeholder: "Pick date",
                  },
                  showReadonlyDate: {
                    description1: "Make this space read-only on a date",
                    description2:
                      "No new messages can be posted after the date specified",
                  },
                  readonlyDate: {
                    placeholder: "Pick date",
                  },
                },
                seo: {
                  title: {
                    title: "Tag title",
                    description:
                      "This is the title that will show up in search engine results and social shares.  Optimal length is 60 characters.",
                  },
                  description: {
                    title: "Meta description",
                    description:
                      "This is the description that will show up in search engine results and social shares.  Optimal length is 150 to 350 characters.",
                  },
                  image: {
                    title: "Meta image",
                    description:
                      "This is the image that will show up in social media shares.  The recommended dimension is 1200 pixels x 627 pixels (1.91/1 ratio).",
                  },
                },
              },
              members: {
                title: "Members",
                members: {
                  download: "Download XLS",
                  invite: "Invite",
                  member: "Members",
                  invited: "Invited",
                  interested: "Interested",
                  payment: "Payments",
                },
                interested: {
                  interested: {
                    button: "Search",
                    placeholder: "Search for more",
                    broadcast: "Send broadcast",
                    noRecordFound: "No records found",
                  },
                  table: {
                    firstname: "First name",
                    email: "Email",
                    date: "Date",
                  },
                  interestedrow: {
                    removeFromList: "Remove from list",
                  },
                },
                invited: {
                  invited: {
                    button: "Search",
                    placeholder: "Search for more",
                    noRecordFound: "No records found",
                  },
                  table: {
                    email: "Email",
                    invitationlink: "Invitation link",
                    amount: "Amount",
                    date: "Date sent",
                  },
                },
                invitedrow: {
                  invitation: {
                    resend: "Resend invitation",
                    cancel: "Cancel invitation",
                  },
                },
                inviteEmail: {
                  invite: {
                    placeholder:
                      "Type the email address or @mention people you would like to add to this space.  Entries can be separated by commas, spaces, or new lines.",
                    message: "Add an optional welcome message",
                    checkboxLabel:
                      "Send email to notify people that they have been invited",
                    alertMessage:
                      "You are currently on a basic plan and cannot send invitations through Sutra. When you click invite, a unique invitation link will be generated for each email address that you add.  You’ll be able to personally email each person with this link.",
                    alertbutton: "Upgrade account",
                    button: "Invite People",
                  },
                },
                inviteExisting: {
                  invite: {
                    copyMembers: "Copy members from another space",
                    copyMembersPlaceholder:
                      "Select spaces from which to copy members",
                    addActiveMembers: "Add active members from parent spaces",
                    addActiveMembersPlaceholder:
                      "Select active members to add to this space",
                    addPendingMembers: "Add pending members from parent spaces",
                    addPendingMembersPlaceholder:
                      "Select pending members to add to this space",
                    checkboxLabel:
                      "Send email to notify people that they have been invited",
                    alert:
                      "You are currently on a basic plan and cannot send invitations through Sutra. When you click invite, a unique invitation link will be generated for each email address that you add.  You’ll be able to personally email each person with this link.",
                    alertButton: "Upgrade account",
                    button: "Invite people",
                    thisOption: "This option is not enabled for this space",
                  },
                },
                inviteLink: {
                  invite: {
                    shareLinkText:
                      "People can register for this space directly with the following link.",
                    youCannotShare:
                      "You cannot share a link to a private space.",
                  },
                },
                invitePeople: {
                  invite: {
                    invitePeople: "Invite people",
                  },
                },
                membersActivity: {
                  members: {
                    placeholder: "Search for",
                    button: "Search",
                    emailButton: "Email",
                    messageButton: "Message",
                    noRecordFound: "No records found",
                  },
                  memberTable: {
                    noRecordFound: "Member",
                    lastSeen: "Last seen",
                    lastPosted: "Last posted",
                    date: "Date joined",
                  },
                },
                participationActivity: {
                  participation: {
                    searchPlaceholder: "Search for",
                    searchButton: "Search",
                    pickDatePlaceholder: "Pick date range",
                    emailButton: "Email",
                    messageButton: "Message",
                    noRecordFound: "No records found",
                  },
                  memberTable: {
                    member: "Member",
                    participation: "Participation",
                    posts: "Posts",
                    words: "Words",
                  },
                },
                participationActivityRow: {
                  posts: "posts",
                },
                paymentRow: {
                  payment: {
                    refundPayment: "Refund payment",
                  },
                },
                payments: {
                  payment: {
                    searchPlaceholder: "Search for",
                    searchButton: "Search",
                    noRecordFound: "No records found",
                  },
                  paymentTable: {
                    member: "Member",
                    amount: "Amount",
                    date: "Date",
                    status: "Status",
                  },
                },
                progressActivity: {
                  progress: {
                    searchPlaceholder: "Search for",
                    searchButton: "Search",
                    emailButton: "Email",
                    messageButton: "Message",
                    noRecordFound: "No records found",
                    progressTracking:
                      "Progress tracking is only available in spaces that have interactive prompts.",
                  },
                  progressTable: {
                    member: "Member",
                    progress: "Progress",
                  },
                },
                registered: {
                  registered: {
                    searchPlaceholder: "Search for",
                    searchButton: "Search",
                    email: "Email",
                    message: "Message",
                    noRecordFound: "No records found",
                    pending: "Pending",
                    manager: "Managers",
                    all: "All",
                    active: "Active",
                  },
                  registeredTable: {
                    member: "Member",
                    email: "Email",
                    status: "Status",
                    role: "Role",
                    roleApproveAll: "Approve all",
                  },
                },
                registeredRow: {
                  registered: {
                    roleApprove: "Approve",
                    joined: "Joined",
                    sendMessage: "Send message",
                    stopCharging: "Stop charging",
                    emailPreferences: "Email preferences",
                    removeMember: "Remove member",
                  },
                },
              },
              payment: {
                charging: {
                  NumberOfTimes: {
                    howManyTimes: "How many times?",
                  },
                  UntilDate: {
                    pickDate: "Pick an end date for charging",
                  },
                },
                fees: {
                  description: {
                    fees: "FEES:",
                    sutraFee: "SUTRA FEE +",
                    stripeProcessingFee: "STRIPE PROCESSING FEES",
                  },
                },
                flexible: {
                  flexible: {
                    description:
                      "To offer people flexible payment options such as a pay what you can model or multiple tiers of payment, you can create a payment survey which people will see when they register for your space.  Click on the edit button below to customize the survey.",
                  },
                },
                once: {
                  once: {
                    description: "How much will you charge?",
                    number: "10",
                  },
                },
                recurring: {
                  recurring: {
                    description1: "How much will you charge?",
                    description2: "Select a charging option",
                  },
                },
              },
              privacy: {
                open: {
                  open: {
                    description: "Anyone can access this space",
                  },
                },
                paid: {
                  paid: {
                    stripeConnected: "Stripe Connected to",
                    disconnectButton: "Disconnect",
                    description:
                      "To process payments for this space please connect your Stripe account by clicking on the button below.",
                    connectButton: "Connect Stripe",
                  },
                },
                private: {
                  private: {
                    description:
                      "This space is private and people must be invited to join",
                  },
                },
              },
              registration: {
                steps: {
                  addFromSubSpace: {
                    label: "Select a space to add member to",
                    description:
                      "In some situations, people are able to join a sub space without joining the parent space.  Sometimes, you want to be sure that they're also added to a top level space or some shared space.  This option lets you do that.  Whenever someone joins a sub space here, they will automatically be added to any spaces that you select below.",
                    placeholder: "Select spaces",
                    saveButton: "Save step",
                    deleteButton: "Delete step",
                    saveButton2: "Save step",
                  },
                  addStructure: {
                    label1: "Select structure",
                    placeholder1: "Select an option",
                    label2: "Send as",
                    placeholder2: "Select an option",
                    saveButton: "Save step",
                  },
                  addToExistingSpace: {
                    label: "Select a space to add member to",
                    placeholder: "Select spaces",
                    saveButton1: "Save step",
                    deleteButton: "Delete step",
                    saveButton2: "Save step",
                  },
                  addToNewSpace: {
                    placeholder: "Name of new space",
                    label: "Create a name for the new space",
                    saveButton1: "Save step",
                    deleteButton: "Delete step",
                    saveButton2: "Save step",
                  },
                  applyTemplate: {
                    label: "Select a space to copy",
                    description:
                      "Sometimes you want to create a template space and have that be replicated every time a new space is created.  You can create a space and set it up exactly the way you want.  Then all content and sub spaces will be copied into any newly created space here.",
                    saveButton1: "Save step",
                    deleteButton: "Delete step",
                    saveButton2: "Save step",
                  },
                  editEmail: {
                    placeholder1: "Subject line",
                    label1: "Send as",
                    placeholder2: "Select an option",
                    label2: "Send to",
                    placeholder3: "Select an option",
                    placeholder4: "someone@special.com",
                    label3: "Email address",
                    saveButton: "Save step",
                    deleteButton: "Delete step",
                  },
                  editStructure: {
                    deleteButton: "Delete step",
                  },
                  editSurvey: {
                    deleteButton: "Delete step",
                  },
                  sendEmail: {
                    label1: "Select email",
                    placeholder1: "Select an option",
                    label2: "Send as",
                    placeholder2: "Select an option",
                    label3: "Send to",
                    placeholder3: "Select an option",
                    label4: "someone@special.com",
                    placeholder4: "Email address",
                    saveButton: "Save step",
                    label5: "Managers",
                    label6: "Custom email address",
                  },
                  setToPending: {
                    title: "Welcome message",
                    description:
                      "This is the content that people will see right after they register for your space. This is a good place to explain when the program will launch and what they can expect in the meantime.",
                    saveButton1: "Save step",
                    deleteButton: "Delete step",
                    saveButton2: "Save step",
                  },
                  surveyPerson: {
                    placeholder: "Select an option",
                    label: "Select survey or quiz",
                    saveButton1: "Save step",
                    deleteButton: "Delete step",
                    saveButton2: "Save step",
                  },
                  wait: {
                    description1: "How many days?",
                    description2: "Wait until",
                    saveButton: "Save step",
                    deleteButton: "Delete step",
                    saveButton2: "Save step",
                    label1: "Select wait condition",
                    placeholder: "Select an option",
                    label2: "Number of days",
                    label3: "Until specific date and time",
                    label4: "Pick date",
                  },
                  webhook: {
                    label: "Webhook URL",
                    saveButton: "Save step",
                    deleteButton: "Delete step",
                  },
                },
                addStep: {
                  addStep: {
                    description: "Add step",
                    label: "Please select an action for this step",
                    placeholder: "Select an action",
                  },
                },
                registrationFlow: {
                  registration: {
                    description1:
                      "This feature allows you to sequence automated actions that are triggered when a person joins or becomes interested in this space.  Here you can do things like schedule emails and automatically add people to select spaces when they register.",
                    description2: "Perform these actions after someone...",
                    placeholder1: "Select a starting condition",
                    addButton: "Add step",
                    label1: "Becomes a member or joins the interested list",
                    description3:
                      "The following steps will be triggered when a person becomes a member of this space or joins the interested list by submitting their name and email address through an interest form.",
                    label2: "Becomes a member of this space",
                    description4:
                      "The following steps will be triggered when a person becomes a member of this space.",
                    label3: "Becomes interested in this space",
                    description5:
                      "The following steps will be triggered when a person joins the interested list by submitting their name and email address through an interest form.",
                  },
                },
              },
              structure: {
                add: {
                  add: {
                    title: "Add to spaces",
                    description: "Spaces",
                    placeholder1: "Select a space",
                    label1: "Send as",
                    placeholder2: "Choose sender",
                    placeholder3: "Pick date",
                    label2: "Send date",
                    label3: "Send time",
                    saveButton: "Save",
                    label4: "Schedule for later",
                  },
                },
                createStructure: {
                  title: {
                    createStructure: "Create structure",
                  },
                },
                structureItem: {
                  item: {
                    addToSpace: "Add to space",
                    open: "View responses",
                    edit: "Edit",
                    duplicate: "Duplicate",
                    delete: "Delete",
                  },
                },
              },
              survey: {
                add: {
                  add: {
                    title: "Add to spaces",
                    spaces: "Spaces",
                    placeholder1: "Select a space",
                    label: "Send as",
                    placeholder2: "Choose sender",
                    saveButton: "Save",
                  },
                },
                answer: {
                  answer: {
                    addOption: "Add option",
                  },
                },
                createSurvey: {
                  description: "Create survey or quiz",
                },
                createSurveyForm: {
                  form: {
                    title: "Title",
                    description:
                      "Flexible payments allow you to offer multiple payment options for your program. If you would like people to set their own price, just create a payment option without setting the amount for that option. That option will show up with an input for the person to set their own amount. For example, you can offer a pay what you can model with several suggested levels and a completely open option. If you add explanation text below, that text will show up just above the payment options.",
                    optionalDescription: "Optional description",
                    addQuestionButton: "Add question",
                    saveButton: "Save",
                  },
                },
                individual: {
                  individual: {
                    placeholder: "Select a person",
                  },
                },
                multipleItem: {
                  multipleItem: {
                    placeholder: "Option",
                  },
                },
                paymentItem: {
                  paymentItem: {
                    placeholder: "Payment option",
                  },
                },
                paymentSurveyItem: {
                  item: {
                    editButton: "Edit payment options",
                    viewButton: "View responses",
                  },
                },
                showSurvey: {
                  survey: {
                    title: "Survey responses",
                  },
                },
                singleItem: {
                  item: {
                    placeholder: "Option",
                  },
                },
                surveyItem: {
                  item: {
                    addToSpace: "Add to space",
                    open: "View responses",
                    edit: "Edit",
                    take: "Preview survey",
                    duplicate: "Duplicate",
                    download: "Download XLS",
                    delete: "Delete",
                    responses: "Responses",
                  },
                },
              },
              broadcast: {
                broadcast: {
                  button: {
                    broadcastButton: "New broadcast",
                  },
                },
              },
              broadcast: {
                title: "Broadcast",
                broadcastButton: "New broadcast",
                membersTypes: {
                  active: "Active members",
                  pending: "Pending members",
                  interested: "Interested members",
                },
                sentOn: "Broadcast sent on",
                onDestroy: {
                  alertTitle: "Are you sure?",
                  alertMessage: "This broadcast will be permanently deleted",
                  confirm: "Delete broadcast",
                  deny: "Cancel",
                },
                actions: {
                  edit: "Edit",
                  duplicate: "Duplicate",
                  delete: "Delete",
                },
                statuses: {
                  sent: "Sent",
                  scheduled: "Scheduled",
                  draft: "Draft",
                  procesing: "Processing",
                },
                form: {
                  composeBroadcast: "Compose Broadcast",
                  contentErrMessage: "Add some content",
                  targetErrMessage: "Select a receiver",
                  type: {
                    email: "Send email",
                    message: "Message space",
                  },
                  title: {
                    placeholder: "Subject line",
                  },
                  brackets: {
                    part1: "Use",
                    part2: "and",
                    part3: "to autopopulate the corresponding information",
                  },
                  target: {
                    placeholder: "Choose recipients",
                    label: "Send to",
                  },
                  sendAs: {
                    label: "Send as",
                    placeholder: "Choose sender",
                  },
                  schedule: {
                    label: "Schedule for later",
                  },
                  sendAtDate: {
                    label: "Send date",
                    placeholder: "Pick date",
                  },
                  sendAtTime: {
                    label: "Send time",
                    placeholder: "",
                  },
                  scheduleBroadcast: "Schedule broadcast",
                  sendBroadcast: "Send broadcast",
                  saveDraft: "Save draft",
                },
              },
              surveys: {
                title: "Surveys",
                createSurvey: "Create survey",
              },
              structures: {
                title: "Structures",
                createStructure: "Create structure",
              },
              sutraAdmin: {
                title: "Sutra admin",
              },
              scheduled: {
                title: "Scheduled",
              },
              archived: {
                title: "Archived",
              },
              participation: {
                title: "Participation",
                label1: "Presence",
                description1: "% of members who've visited in the last 7 days",
                label2: "Activity",
                description2: "% of members who've posted in the last 7 days",
                label3: "Posts",
                description3:
                  "Total posts across all sub spaces in the last 7 days",
                label4: "Participation",
                label5: "Member Activity",
                label6: "Advanced",
              },
            },
            sidebar: {
              iconSelect: {
                placeholder: "Search",
              },
              membersNode: {
                member: "Members",
              },
              nodeIcon: {
                reset: "Reset",
              },
              sidebarTutorial: {
                gettingStarted: "Getting started",
              },
            },
            alreadyMember: {
              member: {
                welcome: "Welcome! You're already a member of this space - ",
                clickHere: "Click here to enter",
              },
            },
            calender: {
              calender: {
                joinButton: "Join call",
                addButton: "Add to calendar",
                joinEventButton: "Register",
                title: "Event details",
              },
            },
            invitationBar: {
              button: {
                register: "Register",
              },
            },
            space: {
              sutraLoading: "Sutra - Loading...",
            },
            statusBar: {
              restoreButton: "Restore",
            },
            tagFilter: {
              resetButton: "Reset",
            },
            presentations: {
              discussion: {
                reflectionHeader: "Reflections",
                backToPost: "Back to post",
                pinnedMessageHeader: "Pinned message",
                delete: {
                  title: "Are you sure?",
                  message: "This action cannot be undone",
                  confirmButton: "Yes delete",
                  cancelButton: "Cancel",
                },
              },
              tag: {
                placeholder: "Search tags or create a new one",
                placeholder2: "Select a tag",
                createAction: "Create",
              },
              message: {
                expand: "Expand",
                reply: "Reflect",
                addTags: "Add tags",
                tag: "Tag",
                copyToClipboard: "Copy to clipboard",
                messageCopied: "Message copied to clipboard",
                pinMessageAction: "Pin message",
                editMessage: "Edit message",
                deleteMessage: "Delete message",
                makePrivate: "Make private",
                viewReplies1: "View",
                viewReplies2single: "reflection",
                viewReplies2plural: "reflections",
                privateMessage:
                  "This post is currently only visible to you and the managers of this space.  If you make it public it will be visible to other participants in this space. Click here to make it public.",
                privateMessage2:
                  "This person has chosen to not share their response with other participants.",
                unreadMessagesText: "You have unread messages",
                scrollDownButton: "Scroll down",
                newReflections: {
                  part1: "You have",
                  part2: "unread reflections across",
                  part3: "messages",
                },
                viewNextButton: "View next",
                pinMessage: {
                  mainText: "Pinned message",
                  viewButton: "View message",
                },
              },
              content: {
                commentInput: {
                  toggleLabel1:
                    "Comments are disabled for interactive journeys",
                  toggleLabel2: "Turn on comments for this post",
                  toggleLabel3: "Turn on comments for this event space",
                  toggleLabel4: "Turn on comments for this content space",
                  placeholder1:
                    "Comments cannot be posted while you are editing.",
                  placeholder2: "Write something...",
                },
              },
              fallback: {
                title: "Connection issue",
                message:
                  "It seems you got disconnected from the internet. When you're back online, we'll automatically reconnect you.",
              },
              events: {
                placeholder: "Write something...",
                upcomingButton: "Upcoming",
                pastButton: "Past",
                addEvent: "Add event",
                eventSettingsTooltip: "Space settings",
                newEventTitle: "NEW EVENT",
              },
              list: {
                placeholder: "Write something...",
                createButton: "Create post",
                listSettingsTooltip: "Space settings",
                duplicateFormTitle: "DUPLICATE SPACE",
              },
              showcase: {
                placeholder: "Write something...",
                createButton: "Create space",
                listSettingsTooltip: "Space settings",
                duplicateFormTitle: "DUPLICATE SPACE",
              },
              settings: {
                versions: {
                  content: "Content page",
                  posts: "Posts",
                  discussions: "Chat",
                  link: "Link",
                  template: "Template",
                },
                versionsDescriptions: {
                  content:
                    "New items will show up as a content page with full functionality.",
                  discussion: "New items will show up as a discussion chat space.",
                  posts:
                    "New items will show up as a simple post with limited functionality",
                  link: "This option allows you to submit a link to any space on Sutra which will then show up as an item here.",
                  template:
                    "Create a template space that will be duplicated each time a new item is created here.",
                },
                createNewTemplate: "Create new template",
                editTemplate: "Edit template",
                title: "SETTINGS",
                accordion1: {
                  label: "Display as",
                  description: "Display posts in a list or grid view",
                  listOption: "List",
                  gridOption: "Grid",
                  dateCreated: "Date created",
                  dateCreatedReversed: "Reverse date created",
                  lastActive: "Last active",
                  lastEdited: "Last edited",
                  alphabetic: "Alphabetic",
                  reverseAlphabetic: "Reverse alphabetic",
                },
                accordion2: {
                  label: "Display options",
                  description: "",
                  switches: {
                    onlyManagers: "Only managers can create a post",
                    showAuthor: "Show author information",
                    showPreview: "Show preview text",
                    showComments: "Show comments count",
                    showMembers: "Show member avatars",
                    showTimestamp: "Show post creation timestamp",
                    showThumbnail: "Show thumbnail",
                    autoThumbnail: "Auto create thumbnail",
                  },
                },
                accordion3: {
                  label: "New post format",
                  description: "Select format for new posts in this space",
                  templateName: {
                    label: "Name your template",
                    placeholder: "Template name",
                  },
                  templateDirectOption: "Direct subspace",
                  templateLinkedOption: "Linked space",
                  privacyDescription:
                    "Select the default privacy level for new posts",
                  privacyOpenOption: "Open",
                  privacyPrivateOption: "Private",
                  privacyAnyOption: "Creator's choice",
                },
                accordion4: {
                  label: "Button label",
                  createPost: {
                    label: "Create post",
                    description:
                      "Specify the text that shows up on the new post button",
                  },
                },
                accordion5: {
                  label: "Tags",
                },
                templateDescription:
                  "New template spaces can either be direct subspaces of this space or they can be linked spaces.  Linked spaces are independent top level spaces that will show up on this page.  The creator of the space will become a full manager of the space that they create.",
              },
              thumbnail: {
                title: "ADD THUMBNAIL",
                message: "Thumbnail image is being uploaded...",
              },
              static: {
                broadcastExclude: {
                  unsubscribe: {
                    part1: "Are you sure you want to unsubscribe from",
                    part2:
                      "You will no longer receive any direct broadcast emails from the managers of this space",
                    button: "Unsubscribe",
                  },
                  subscribe: {
                    part1:
                      "You have been unsubscribed and you will no longer receive broadcast emails from",
                    button: "Subscribe",
                  },
                },
                invitation: {
                  message: "You have being invited to this space",
                  button: "Accept invitation",
                },
                pending: {
                  message: "Your membership is pending",
                },
                private: {
                  message: {
                    part1: "This is a private space.",
                    part2: "If you're a member, please log in.",
                  },
                  button: "Log in",
                },
                processing: {
                  message: "This space is still processing...",
                },
              },
              gridPost: {
                onDelete: {
                  alertTitle: "Are you sure?",
                  alertMessage: "This space will no longer be linked to here.",
                  alertMessage2: "This space will no longer be linked to here.",
                  confirm: "Yes",
                  deny: "No",
                },
                actions: {
                  pin: "Pin to top",
                  unpin: "Unpin",
                  addThumbnail: "Add thumbnail",
                  removeThumbnail: "Remove thumbnail",
                  duplicate: "Duplicate",
                  unlink: "Unlink",
                  delete: "Delete",
                },
              },
              listContent: {
                publishTootip: "Will publish on this date",
                privateTooltip: "This is a private space",
              },
            },
            colors: {
              form: {
                colorTheme: "Color theme",
                customize: "Customize color selection",
                saveTheme: "Save theme",
                resetColor: "Reset color",
                pageLayout: "Page layout",
                pageFormat: "Page format",
              },
              headerAndLogo: {
                addCover: "Add cover",
                removeCover: "Remove cover",
                changeLogo: "Change logo",
                removeLogo: "Remove logo",
                dimensions:
                  "The recommended dimension for cover images is 1200 x 300 pixels",
                dimensions2:
                  "The recommended dimension for logos is 360 x 120 pixels",
              },
              pageFormat: {
                description:
                  "You can change the way that content in this space is presented by selecting an option below.",
                contentLabel: "Content",
                discussionLabel: "Chat",
                contentDescription:
                  "A space for learning modules, general content, or community resources.",
                discussionDescription:
                  "A space for discussion or announcements",
                eventsLabel: "Calendar",
                eventsDescription: "Create a calendar list of events",
                listLabel: "Forum",
                listDescription:
                  "Create a feed where people can post discussion topics",
                showcaseLabel: "Showcase",
                showcaseDescription:
                  "Create a gallery of spaces",
              },
              pageLayout: {
                widePage: "Wide page width",
                hideSidebar: "Hide sidebar",
                hideHeader: "Hide header",
                hideCoverImage: "Hide cover image",
                hidePageTitle: "Hide page title",
                hideBreadcrumbs: "Hide breadcrumbs",
              },
              header_color: {
                label: "Header color",
                description: "Description",
              },
              header_link_color: {
                label: "Header logo color",
                description: "Description",
              },
              primary_button_background_color: {
                label: "Primary button background color",
                description: "Description",
              },
              primary_button_text_color: {
                label: "Primary button text color",
                description: "Description",
              },
              secondary_button_background_color: {
                label: "Secondary button background color",
                description: "Description",
              },
              secondary_button_text_color: {
                label: "Secondary button text color",
                description: "Description",
              },
              background_color: {
                label: "Background color",
                description: "Description",
              },
              default_text_color: {
                label: "Default text color",
                description: "Description",
              },
              sidebar_background_color: {
                label: "Sidebar background color",
                description: "Description",
              },
              sidebar_text_color: {
                label: "Sidebar text color",
                description: "Description",
              },
              default_link_color: {
                label: "Default link color",
                description: "Description",
              },
              default_badge_color: {
                label: "Notification badge color",
                description: "Description",
              },
              default_badge_text_color: {
                label: "Notification badge text color",
                description: "Description",
              },
              registration_page_background_color: {
                label: "Registration page background color",
                description: "Description",
              },
              registration_page_default_text_color: {
                label: "Registration page text color",
                description: "Description",
              },
            },
            create: {
              invalidNameErrorMsg:
                "Space name must be between 3 and 100 characters long",
              invalidName2ErrorMsg:
                "Title must be between 3 and 100 characters long",
            },
            dummy: {
              magicLink: {
                alertTitle: "You're almost in...",
                alertMessage:
                  "We sent the magic link to your email. THIS IS A TEST NO ACTUAL EMAIL SENT.",
              },
            },
            pending: {
              message: "Your membership is pending",
            },
            recover: {
              alertTitle: "You're almost in...",
              alertMessage:
                "We just sent you an email with instructions to recover you password. THIS IS A  TEST NO ACTUAL EMAIL SENT.",
            },
            form: {
              calendarLinks: {
                text: "Information added below will be passed to the member's calendar when they add the event.",
                link: {
                  label: "Event URL (Zoom link, etc)",
                  placeholder: "What's the link of your video call",
                },
                description: {
                  label:
                    "Add a description for your event that will show up in the calendar information",
                  placeholder: "Calendar description",
                },
                eventStartDateAndTime: "Event start date and time",
                date: {
                  placeholder: "Pick date",
                },
                eventDateTime: "Event end date and time (optional)",
                allowInterests:
                  "Allow people to join event without registering for Sutra",
                automatedEmails: "Set up automated emails",
                save: "Save details",
              },
              duplicate: {
                prefix: "Copy of",
                intention: {
                  label: "Space name",
                  placeholder: "What's your space called?",
                },
                copyMembers: {
                  label: "Copy members",
                },
                copyManagers: {
                  label: "Copy managers",
                },
                copyContent: {
                  label: "Copy content",
                },
                copyPosts: {
                  label: "Copy posts",
                },
                copyBroadcasts: {
                  label: "Copy broadcasts",
                },
                copyAutomation: {
                  label: "Copy automations",
                },
                copyRegPage: {
                  label: "Copy registration page",
                },
                save: "Duplicate space",
                saveTemplate: "Create template",
              },
              form: {
                privacyDescription1:
                  "The space you create will be accessible to everyone within",
                rootPrivacyDescription1:
                  "The space you create will be accessible by anyone who has a link  to it.  You can set payment options once you create the space.",
                privacyDescription2:
                  "The space you create will only be visible to people who are added to it.",
                rootPrivacyDescription2:
                  "The space you create will only be visible to people who are invited to it.  You can set payment options once you create the space.",
                placeholderDescription: "Description",
                versions: {
                  content: {
                    label: "Content",
                    description:
                      "Create a space for a learning module, general content, or community resources.",
                  },
                  discussion: {
                    label: "Chat",
                    description:
                      "Create a chat space for discussion or announcements",
                  },
                  event: {
                    label: "Event",
                    description: "Create a single event",
                  },
                  events: {
                    label: "Calendar",
                    description: "Create a calendar list of events",
                  },
                  list: {
                    label: "Forum",
                    description:
                      "Create a feed where people can post discussion topics",
                  },
                  showcase: {
                    label: "Showcase",
                    description:
                      "Create a gallery of spaces",
                  },
                },
                segments: {
                  space: {
                    label: "Space",
                    description:
                      "Whether you’re creating a course or a community, everything in Sutra starts with a space.  This will be the home for your experience.",
                  },
                  event: {
                    label: "Event",
                    description:
                      "Create a simple event page.  Choose this option if you just want to organize a one-off event that you can publically share.",
                  },
                  discussion: {
                    label: "Chat",
                    description:
                      "Create a simple chat space for your group.  Choose this option if you just want to create a one-off discussion space for a group of people.",
                  },
                },
                intention: {
                  label: "Space name",
                  placeholder: "What's your space called?",
                },
                abstract: {
                  label: "Preview text",
                  placeholder: "What's your space description?",
                },
                content: {
                  label: "Space name",
                  description: "Write something...",
                  placeholder: "Write something...",
                },
                spaceFormat: {
                  label: "Space format",
                  placeholder: "Pick one",
                },
                eventDescription:
                  "Information added below will be passed to the member's calendar when they add the event.",
                hangoutLink: {
                  label: "Event URL (Zoom link, etc)",
                  placeholder: "What's the link of your video call",
                },
                eventStartTime: "Event start date and time",
                date: {
                  placeholder: "Pick date",
                },
                eventEndDate: "Event end date and time (optional)",
                privacyLevelText: "Privacy level",
                privacy: {
                  open: "Open",
                  private: "Private",
                },
                joinAsMember: {
                  label: "Join this space as a member",
                },
                publishDate: {
                  label: "Publish on date",
                },
                publishDateTime: "Publish date and time",
                create: "Create space",
              },
              interestedForm: {
                title: "Register for this event",
                createAlertTitle: "Thank you for registering!",
                createAlertButton: "Ok",
                name: {
                  label: "First name",
                  placeholder: "Write your first name",
                },
                email: {
                  label: "Email",
                  placeholder: "Write your email",
                },
                register: "Register",
                joinWithSutra: "Join with my Sutra Account",
              },
              linkForm: {
                linkErrMessage: "This is not a valid space link",
                link: {
                  label: "",
                  placeholder: "Link to space",
                  errors: {
                    space:
                      "The space you want to share is private or does not exist!",
                    event:
                      "The space you want to share is private, is not an event or does not exist!",
                  },
                },
              },
              listForm: {
                intention: {
                  placeholder: "Title",
                },
              },
            },
            header: {
              onLeave: {
                title: "Are you sure?",
                message:
                  "You will be removed as a member of this space and may need to be added again to restore access.",
                confirm: "Yes, please remove me",
                deny: "No",
              },
              onArchive: {
                title: "Are you sure?",
                message:
                  "This space will be archived and members will no longer have access.",
                confirm: "Yes",
                deny: "No",
              },
              onUnarchive: {
                title: "Are you sure?",
                message:
                  "This space will be unarchived and members will have access again.",
                confirm: "Yes",
                deny: "No",
              },
              onDelete: {
                title: "Are you sure?",
                message:
                  "This space will be deleted and members will no longer have access.",
                confirm: "Yes",
                deny: "No",
              },
              onFix: {
                title: "Are you sure?",
                message:
                  "Sutra will try to recreate this content space from the legacy data.",
                confirm: "Yes, fix",
                deny: "No",
                confirmationTitle: "Information",
                confirmationMessage: "Space fixed, reloading...",
                confirmationError: "This space cannot be fixed",
              },
              buttons: {
                uncomplete: "Completed",
                markComplete: "Mark complete",
                edit: "Edit",
                post: "post",
                page: "page",
                space: "space",
                returnTo: "Return to",
                editTitle: "Edit title",
                viewPage: "View page",
                editDescription: "Edit description",
                appearance: "Branding",
                manage: "Manage",
                members: "Members",
                leave: "Leave space",
                delete: "Delete space",
                duplicate: "Duplicate space",
                template: "Save as template",
                archive: "Archive space",
                unarchive: "Unarchive space",
                fix: "Fix",
                joinEvent: "Register",
                joinSpace: "Join space",
                preview: "Preview",
                draft: "Draft",
                published: "Published",
                publish: "Publish",
                returnToSettings: "Return to settings",
                returnToSpace: "Return to space",
                undo: "Undo",
                openFullScreen: "Open full screen",
                copyToClipboard: "Copy link",
                copiedMessage: "Space link copied to clipboard",
                versionHistory: "Version history",
                brandingOptions: "BRANDING OPTIONS",
              },
              regPagePublishing:
                "Your registration page is publishing, please wait...",
              spaceUpdating: "This space is being updated, please wait...",
            },
            members: {
              sectionTitle: "Members",
              manage: "Manage members",
              invite: "Invite",
              search: {
                button: "Search",
                placeholder: "Search for",
              },
              emptyMessage: "No records found",
              messageButton: "Message",
              emailButton: "Email",
              copyClipboard: "copy to clipboard",
            },
            payment: {
              onCheckIntent: {
                alertTitle: "Payment error",
                alertMessage:
                  "Your session has expired.  Please refresh the page to complete your payment.",
              },
              onConfirmPayment: {
                alertTitle: "Opps, something went wrong!",
                alertMessage: "something went wrong!",
              },
              completePayment: "Complete payment",
              join: "Join space",
            },
            stripe: {
              pay: "Pay",
            },
          },
          survey: {
            form: {
              text: "Your responses:",
              submitButton: "Submit",
            },
            question: {
              yourAnswer: "Your answer",
            },
          },
          tiptap: {
            blocks: {
              section1: "Basic",
              text: "Text",
              headline1: "Headline 1",
              headline2: "Headline 2",
              headline3: "Headline 3",
              divider: "Divider",
              infobox: "Info box",
              infobox: "Info box",
              section2: "Spaces",
              content: "Content space",
              discussion: "Chat space",
              event: "Event space",
              events: "Calendar",
              list: "Forum space",
              showcase: "Showcase space",
              section3: "List",
              section8: "Special",
              bulletList: "Bullet list",
              orderedList: "Ordered list",
              checklist: "Checklist",
              collapsableSection: "Collapsable section",
              survey: "Survey or quiz",
              section4: "Media",
              image: "Image",
              embed: "Embed code",
              video: "Video",
              audio: "Audio",
              file: "File",
              zoom: "Zoom recording",
              section5: "Grids",
              grid1: "1 column",
              grid2: "2 columns",
              grid3: "3 columns",
              grid4: "Left column",
              grid5: "Right column",
              grid6: "4 columns",
              section6: "Buttons",
              interestForm: "Interest form",
              actionButton: "Action button",
              bundleButton: "Bundle button",
              markComplete: "Mark complete",
              section7: "Interactive",
              interactiveButton: "Continue button",
              interactiveInput: "Discussion prompt",
              interactiveWait: "Wait condition",
            },
            nameToBlocks: {
              circle: "space",
              grid1: "grid",
              grid2: "grid",
              grid3: "grid",
              grid4: "grid",
              column: "column",
              resource: "collapsable section",
              survey: "survey",
              interactiveInput: "discussion prompt",
              tagsFeed: "tag feed",
              infobox: "info box",
            },
            components: {
              sidebar: {
                alignment: {
                  left: "Left",
                  center: "Center",
                  right: "Right",
                },
                backgroundColor: {
                  text: "Select background color",
                  clearColor: "Clear color",
                },
                backgroundImage: {
                  button: "Select image",
                  removeButton: "Remove image",
                  label: "Position",
                  placeholder: "Pick one",
                  leftTop: "left top",
                  leftCenter: "left center",
                  leftBottom: "left bottom",
                  rightTop: "right top",
                  rightCenter: "right center",
                  rightBottom: "right bottom",
                  centerTop: "right top",
                  centerCenter: "center center",
                  centerBottom: "center bottom",
                  label2: "Repeat",
                  placeholder2: "Pick one",
                  noRepeat: "No Repeat",
                  repeat: "Repeat",
                  "repeat-x": "Repeat X",
                  "repeat-y": "Repeat Y",
                  space: "Space",
                  round: "Round",
                },
                blockId: {
                  placeholder: "Block ID",
                  label: "Block ID",
                  description:
                    "Recommended IDs for AI briefDescription, aboutWorkshop, whatYouWillLearn1, whatYouWillLearn2, whatYouWillLearn3, whoThisIsFor, aboutUs, quote",
                },
                buttonAction: {
                  label: "Completion action",
                  description:
                    "Specify what happens after a person submits their information through the selected form.",
                  placeholder: "Pick one",
                  label2: "Link to",
                  placeholder2: "Pick one",
                  label3: "Button action",
                  description3:
                    "Specify if the link will open on current browser tab or another tab.",
                  placeholder3: "Pick one",
                  callBackComplete: "Completion action text",
                  callbacklinkTo: "Link to",
                  completeDescription:
                    "Add a short confirmation message that people will see after submitting their information.",
                  linkDescription:
                    "The person will be redirected to this link after clicking the button.",
                  label4: "Link to space section",
                  description4: "Specify if the link will open in the current browser tab or another tab or if the link will jump to a section on this page.",
                  selectSection: "Select section",
                  description5: "This option allows you to jump to a specific section on this page when the link is clicked.",
                  description6: "To select the section, click on the button below and then click on the section you would like to link to.",
                  label5: "Section is linked!",
                  label6: "Link url",
                  description7: "Specify the URL link the user will be taken to",
                  label7: "Opens in current tab",
                  label8: "Opens other tab",
                  label9: "Do nothing",
                  label10: "Redirect the user to",
                  label11: "Select an option",
                  label12: "Another space",
                  label13: "Custom link",
                  label14: "Click a section on the page",
                },
                buttonBackgroundColor: {
                  button: "Clear color",
                },
                buttonText: {
                  placeholder: "Button text",
                  label: "Button text",
                },
                fileName: {
                  placeholder: "File name",
                  label: "File name",
                },
                interactiveWaitTime: {
                  label1: "Select an option",
                  label2: "Wait until a date",
                  label3: "Wait number of days",
                  label4: "Date to show content",
                  placeholder: "Date to show content",
                  label5: "1 day",
                  label6: "2 days",
                  label7: "3 days",
                  label8: "4 days",
                  label9: "5 days",
                  label10: "6 days",
                  label11: "1 week",
                  label12: "2 weeks",
                  label13: "1 month",
                },
                linkAction: {
                  label: "Link action",
                  description:
                    "Specify if the link will open in current tab or in a new tab.",
                  placeholder: "Pick one",
                  label2: "Open in current tab",
                  label3: "Open in new tab",
                },
                margin: {
                  topMargin: "Top margin -",
                  text: "Bottom margin - ",
                  bottom: "Bottom",
                  left: "Left",
                  leftMargin: "Left margin -",
                  right: "Right",
                  rightMargin: "Right margin",
                },
                padding: {
                  top: "Top",
                  topPadding: "Top padding -",
                  bottom: "Bottom",
                  bottomPadding: "Bottom padding - ",
                  left: "Left",
                  leftPadding: "Left padding",
                  right: "Right",
                  rightPadding: "Right padding",
                },
                radius: {
                  square: "Square",
                  round: "Round",
                  circle: "Circle",
                },
                spaceAttrs: {
                  addCover: "Add Cover",
                  text: "The recommended dimension for cover images is 1200 x 300 pixels",
                  addCover2: "Add Cover",
                  text2:
                    "The recommended dimension for cover images is 1200 x 300 pixels",
                  removeCover: "Remove cover",
                },
                verticalAllignment: {
                  top: "Top",
                  center: "Center",
                  bottom: "Bottom",
                },
                width: {
                  full: "Full",
                  size: "3/4",
                  size2: "1/2",
                  actual: "Actual",
                },
              },
              blockMenuActions: {
                label1: "Add block",
                label2: "Add emoji",
                label3: "Settings",
                label4: "Delete",
                label5: "Move",
                label6: "Go to space or double click the block",
                label7: "Replace",
                label8: "Alignment",
                left: "Left",
                center: "Center",
                right: "Right",
                write: "Write something...",
                label9: "Add block",
                label10: "Heading 1",
                h1: "H1",
                label11: "Heading 2",
                h2: "H2",
                label12: "Add space",
                label13: "Add image",
                label14: "Add video",
                label15: "Add grid",
                label16: "Add template",
                label17: "Add layout",
                label18: "Duplicate",
              },
              blocksMenu: {
                basicBlock: "Basic Blocks",
                mediaBlock: "Media blocks",
                contentBlock: "Content blocks",
              },
              blocksMenuInline: {
                file: "File",
                image: "Image",
                video: "Video",
                label1: "Add emoji",
                label2: "Add image",
                label3: "Toggle formatbar",
              },
              blocksMenuSimple: {
                label1: "Add",
                file: "File",
                image: "Image",
                video: "Video",
                audio: "Audio",
                label2: "Add emoji",
                label3: "Add image",
                label4: "Show formatting",
                cancelButton: "Cancel",
              },
              emptyMessage: {
                text: "This is an empty content space.  You can",
                button1: "edit this page",
                text2: "or",
                button2: "use a template",
                text3: "to get started.",
                text4: "An empty space filled with potential...",
              },
              fixedBottomMenu: {
                label1: "Add elements like headlines, images and video",
                addBlock: "Add block",
                label2: "Add a content, forum, chat, or event space",
                space: "Space",
                label3: "Add a grid to use multiple columns for content",
                grid: "Grid",
                label4: "Add a pre-created design option",
                layouts: "Layouts",
                label5: "Add a template to automatically set up your space",
                template: "Templates ",
              },
              formatMenu: {
                text: "Text",
                smallText: "Small text",
                largeText: "Large text",
                heading1: "Heading 1",
                heading2: "Heading 2",
                heading3: "Heading 3",
                bulletedList: "Bulleted list",
                orderedList: "Ordered list",
                text2: "Text",
                label1: "Formatting",
                text3: "Text",
                numberedList: "Numbered list",
                label2: "Alignment",
                left: "Left",
                center: "Center",
                right: "Right",
                label3: "Link action",
                description:
                  "Specify if the link will open on current browser tab or another tab.",
                placeholder: "Pick one",
                label4: "Opens in current tab",
                label5: "Opens other tab",
                label6: "Link format",
                description2:
                  "Specify if the link will be shown as text or as a fancy link.",
                placeholder2: "Pick one",
                label7: "Display as text",
                label8: "Display as Fancy link",
                save: "Save",
                delete: "Delete",
                label9: "Strikethrough",
                label10: "Underline",
                label11: "Code block",
                label12: "Text color",
                placeholder3: "HEX value",
                unsetColor: "Unset color",
                label13: "Highlight color",
                label14: "Link to space section",
                description3: "Specify if the link will open in the current browser tab or another tab or if the link will jump to a section on this page.",
                selectSection: "Select section",
                description4: "This option allows you to jump to a specific section on this page when the link is clicked.",
                description5: "To select the section, click on the button below and then click on the section you would like to link to.",
                label15: "Section is linked!",
                label16: "Link url",
                description6: "Specify the URL link the user will be taken to",
              },
              formatMenuSimple: {
                t: "T",
                h1: "H1",
                h2: "H2",
                h3: "H3",
                label1: "Link action",
                description1:
                  "Specify if the link will open on current browser tab or another tab.",
                placeholder1: "Pick one",
                label2: "Opens in current tab",
                label3: "Opens other tab",
                label4: "Link format",
                description2:
                  "Specify if the link will be shown as text or as a fancy link.",
                placeholder2: "Pick one",
                label5: "Display as text",
                label6: "Display as Fancy link",
                save: "Save",
                delete: "Delete",
                label7: "Formatting",
                left: "Left",
                center: "Center",
                right: "Right",
                placeholder3: "HEX value",
                placeholder4: "Color Hex",
                unsetColor: "Unset color",
              },
              inlineMenu: {
                h1: "H1",
                h2: "H2",
                bulletList: "Bullet list",
                checkList: "Check List",
                circle: "Circle",
                moreOption: "More options",
              },
              interactiveInput: {
                hideResponses: "Hide other responses",
                showResponses: "Show other responses",
                hideResponses2: "Hide responses",
                hidePrivateMessages: "Hide private messages",
                showPrivateMessages: "Show private messages",
                showResponses2: "Show responses",
                loadMoreResponse: "Load more responses",
                placeholder: "Write something...",
                text: "Do you want this response to be visible to other participants?",
              },
              newMedia: {
                add: "Add",
              },
              regPageColor: {
                clearColor: "Clear color",
              },
              regPageColors: {
                text: "Registration page appearance",
                applyAppearance: "Apply Appearance",
                title1: "Select background color",
                title2: "Select text color",
                label1: "Background color",
                label2: "Default text color",
                label3: "Wide page width",
              },
              resource: {
                areYouSure: "Are you sure?",
                label1: "Will publish on this date",
                edit: "Edit",
                addPage: "Add Page",
              },
              sideBlocksMenuBak: {
                addBlock: "Add block",
                back: "Back",
              },
              sideBlocksMenu: {
                text: "Layouts are pre-created design options that you can add to your space.",
                text2:
                  "Get started with a selection of customizable full page templates",
                layout: "Layouts",
                template: "Templates",
                addBlock: "ADD BLOCK",
                block: "Blocks",
              },
              sideConfigurationsMenu: {
                title: "BLOCK SETTINGS",
                label1: "Space name and cover",
                label2: "File attributes",
                label3: "Discussion prompt options",
                attrPlaceholder1: "Write something...",
                attrLabel: "Placeholder",
                attrLabel2: "Allow participant to see all responses",
                attrLabel3: "Show responses before participant responds",
                attrLabel4:
                  "Allow participant to choose if their response is visible to others",
                attrLabel5: "Allow multiple responses per person",
                attrLabel6: "Allow participant to skip this prompt",
                attrLabel7: "Notify managers on each new post",
                label4: "Discussion prompt options",
                label5: "Padding",
                label6: "BLOCK",
                label7: "COLUMN",
                label8: "Margin",
                label9: "GRID",
                label10: "Vertical alignment",
                label11: "Button options",
                label12: "Button background color",
                label13: "Button text color",
                text: "Button corners",
                label14: "Background",
                label15: "Color",
                label16: "Image",
                label17: "Reset",
                resetBlock: "Reset block",
                label18: "Alignment",
                label19: "Border radius",
                label20: "corners",
                label21: "Block name",
                label22: "Email notification options",
                label23: "Send notification email",
                attrLabel8: "Include custom message",
                attrLabel9: "Include next section content",
                attrLabel10: "Include full content",
                attrLabel11: "Short preview",
                attrLabel12: "Subject",
                attrLabel13: "Message",
                defaultWaitLabel: "The next section has been released in {{space_name}}",
                defaultWaitMessage: "Hi {{first_name}} - just wanted to let you know that the next section in {{space_name}} has been released. {{space_link_button}}"
              },
              takeSurvey: {
                text: "This survey is empty",
              },
              tiptapAi: {
                placeholder: "Write a short description",
                label:
                  "Please write 2 or 3 sentences describing your offering.",
                placeholder2: "ex. coaches, consultants, and healers",
                label2: "Who is this offering for?",
                text: "Hey there, we're experimenting with a new AI feature.  Do you want Sutra to automatically generate language for your template using AI?",
              },
              uploader: {
                label1: "Link",
                label2: "Code",
                placeholder: "Paste the image or file link",
                label3: "Embed link",
                placeholder2: "Paste the embed code -> <iframe>...</iframe>",
                label4: "Embed code",
                saveEmbed: "Save embed",
                placeholder3: "Search images",
                saveImage: "Save image",
              },
              viewPage: {
                text: "This page is empty",
              },
            },
          },
          users: {
            payment: {
              completePayment: "Complete payment",
              join: "Join space",
              pay: "Pay",
            },
            stepper: {
              step1: "Step 1",
              step1Description: "Log in",
              step2: "Step 2",
              step2Description: "Complete payment",
              step3: "Step 3",
              step3Description: "Get access",
            },
            start: {
              title: "Welcome",
              subtitle: "Please enter your email address below",
              email: "Email",
              submit: "Continue",
              invalidEmailErrorMsg: "Invalid email",
            },
            signIn: {
              title: "Log in to your account",
              subtitle: "Don't have an account?",
              subtitleLink: "Sign up",
              email: "Email",
              password: "Password",
              submit: "Sign in",
              magicLink: "Get a magic link",
              forgotPassword: "Forgot password?",
              invalidEmailErrorMsg: "Invalid email",
            },
            signUp: {
              invalidEmailErrorMsg: "Invalid email",
              invalidPasswordErrorMsg:
                "Password must be at least 8 characters long",
              invalidFirstNameErrorMsg:
                "First name must be at least 3 characters long",
              title: "Create a new account",
              subtitle: "Already have an account?",
              subtitleLink: "Log in",
              firstName: "First Name",
              lastName: "Last Name",
              username: "Username",
              email: "Email",
              password: "Password",
              emailNewsletterConfirmation:
                "Sign up for the Sutra newsletter to hear about free webinars on group process, transformational teaching, and relational business.",
              submit: "Sign up",
              legalPrefix: "By signing up for Sutra you agree to our",
              terms: "Terms of Service",
              privacy: "Privacy Policy",
            },
            spaceReference: {
              total: "Total today",
            },
            planReference: {
              total: "Total",
              total_today: "Total today",
              due_in: "Due in 14 days",
              paymentDetails: {
                annual: "Annual subscription of",
                month: "Monthly subscription of",
              },
            },
            resetPassword: {
              title: "Reset your password",
              subtitle:
                "Please enter a new password for your Sutra account below.",
              password: "Password",
              passwordConfirmation: "Password confirmation",
              submit: "Reset password",
            },
            recover: {
              title: "Forgot your password?",
              subtitle:
                "Please enter your email address below and we'll send you instructions on how to reset your password.",
              email: "Email",
              submit: "Send me instructions",
              footer: "Already have an account?",
              footerLink: "Sign in",
            },
            magicLink: {
              invalidEmailErrorMsg: "Invalid email",
              title: "Request magic link",
              subtitle:
                "Please enter your email address below and we'll send you a link that will automatically log you in.",
              email: "Email",
              submit: "Continue",
              footer: "Log in with email",
              alertTitle: "You're almost in...",
              alertMessage:
                "We sent the magic link to your email. THIS IS A TEST NO ACTUAL EMAIL SENT.",
            },
            edit: {
              title: "Please complete your profile",
              addProfileImage: "Add profile image",
              removeProfileImage: "Remove profile image",
              bioPlaceholder: "Share a little about yourself...",
              bio: "Bio",
              submit: "Save",
              skip: "Skip this and do it later",
            },
            magicSignin: {
              text: "Signing in...",
            },
            profile: {
              button: "Message",
              button2: "Edit Profile",
              title: "Spaces",
            },
            profileModal: {
              profile: "Profile",
              message: "Message",
              editProfile: "Edit profile",
              openProfile: "Open profile",
              spaces: "Spaces",
              selectImage: "Select image",
            },
            signOut: {
              loggedOUt: "Logged out",
              title: "You're logged out",
              message: "See you later!",
            },
          },
          errors: {
            basic: "Opps, something went wrong!",
          },
        },
      },
      es: {
        translation: {
          premiumFeatures: {
            personal: {
              description1:
                "Espacios y miembros ilimitados para organizar el contenido y las conversaciones de todas tus ofertas",
              description2:
                "Hermosas páginas de registro y landings para todos tus cursos y eventos en la misma plataforma",
              description3:
                "Envía newsletters por correo electrónico a todos tus miembros a través de la potente función de envío masivo",
            },
            professional: {
              description1: "Sin comisiones por cobros recibidos",
              description2:
                "Opciones para la personalización de marca, de diseño y de subdominios URL.",
              description3:
                "Automatizaciones ilimitadas y experiencias de aprendizaje conversacionales interactivas",
              description4: "Hasta 2 administradores para todos tus espacios",
            },
            organization: {
              description1: "Sin comisiones por pagos recibidos",
              description2: "Soporte avanzado al cliente",
              description3: "Administradores ilimitados",
              description4: "Opción de integración de SSO",
            },
          },
          premiumMessages: {
            can_auto_tag: {
              title: "Upgrade to use this feature",
              message: "Auto tagging with AI is a premium feature available on the Professional plan."
            },
            can_use_zoom_recordings: {
              title: "Upgrade to use this feature",
              message: "Zoom recordings is a premium feature available on the Organization plan."
            },
            can_create_quizzes: {
              title: "Upgrade to use this feature",
              message: "Quizzes are a premium feature available on the Organization plan."
            },
            can_duplicate_spaces: {
              title: "Upgrade to use this feature",
              message:
                "Duplicating a space is a premium feature available on the Professional plan.",
            },
            can_save_templates: {
              title: "Upgrade to use this feature",
              message:
                "Saving as space as a reusable template is a premium feature available on the Professional plan.",
            },
            can_edit_shareable_link: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Compartir enlaces de acceso personalizables es una función premium disponible en el plan Profesional",
            },
            can_upload_cover: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Subir una imagen de portada personalizada es una función premium disponible en el plan Personal. ",
            },
            can_upload_logo: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Subir un logotipo personalizado es una función premium disponible en el plan Profesional.",
            },
            can_set_layout: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Personalizar el diseño de tu espacio es una función premium disponible en el plan Profesional.",
            },
            can_set_theme: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Personalizar el tema de color de tu espacio es una función premium disponible en el plan Profesional.",
            },
            can_set_format: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Cambiar el formato de tu espacio es una función premium disponible en el plan Profesional.",
            },
            can_add_step_send_to_pending: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Asignar el estado Pendiente a miembros nuevos es una función premium disponible en el plan Personal.",
            },
            can_add_step_send_email: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Enviar correos electrónicos automatizados es una función premium disponible en el plan Personal.",
            },
            can_add_add_to_existing_space: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Añadir automáticamente miembros a espacios seleccionados es una función premium disponible en el plan Profesional.",
            },
            can_add_step_wait: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Añadir una condición de espera en los pasos adicionales es una función premium disponible en el plan Profesional.",
            },
            can_add_step_survey_person: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Realizar encuestas a miembros nuevos es una función premium disponible en el plan Profesional.",
            },
            can_schedule_states: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Las opciones de programación automatizada de tu espacio son una función premium disponible en el plan Profesional.",
            },
            can_broadcast: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "El envío masivo de correos electrónicos es una función premium disponible en el plan Profesional.",
            },
            can_survey: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Crear encuestas es una función premium disponible en el plan Profesional.",
            },
            can_use_interest_form_block: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Añadir formularios de interés es una función premium disponible en el plan Profesional.",
            },
            can_use_interactive_block: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Añadir bloques interactivos es una función premium disponible en el plan Professional.",
            },
            can_set_flexible_payments: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Configurar opciones de flexibilidad de pago es una función premium disponible en el plan Profesional.",
            },
            can_activate_completions: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "La opción de marcar espacios como completados es una función premium disponible en el plan Profesional.",
            },
            max_allowed_spaces: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "¡Hola! Parece que te has quedado sin espacios gratuitos. Si mejoras tu suscripción al plan Personal tendrás espacios y miembros ilimitados, y podrás crear todo lo que quieras.",
            },
            max_allowed_ij: {
              title: "Upgrade to use this feature",
              message:
                "You can only add up to 5 discussion prompts per space on the free plan. Please upgrade to the Professional plan to add unlimited discussion prompts to any space.",
            },
            max_allowed_managers_pro: {
              title: "Mejora tu plan para agregar administradores adicionales",
              message:
                "Mejora tu suscripción al plan Profesional para añadir hasta 2 administradores a tu espacio, o al plan Organización para obtener administradores ilimitados.",
            },
            max_allowed_managers_org: {
              title: "Mejora tu plan para agregar administradores adicionales",
              message:
                "Mejora tu suscripción al plan Organización para añadir administradores ilimitados a tu espacio.",
            },
            can_see_participation_tab: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Las métricas de participación son una función premium disponible en el plan Profesional.",
            },
            can_send_selective_broadcast: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "El envío masivo selectivo es una función premium disponible en el plan Organización.",
            },
            can_send_message_broadcast: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "El envío masivo por mensaje es una función premium disponible en el plan Organización.",
            },
            can_add_from_children: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Añadir automáticamente cualquier miembro desde subespacios es una función premium disponible en el plan Profesional.",
            },
            can_add_to_direct_open_children: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "Añadir automáticamente  miembros nuevos a todos los subespacios directos abiertos es una función premium disponible en el plan Profesional.",
            },
            can_send_message: {
              title: "Mejora tu plan para utilizar esta función",
              message:
                "El envío automático de mensajes directos a miembros nuevos es una función premium disponible en el plan Profesional.",
            },
          },
          home: {
            sidebar: {
              createButton: "Nuevo espacio",
              spaces: "Tus espacios",
              invitations: "Invitaciones",
              directMessages: "Mensajes directos",
              gettingStarted: "Primeros pasos",
              scheduleDemo: "Agendar una demostración",
              upgrade: "Mejora tu plan",
              promoBoxTitle:
                "Únete a los centenares de creadores que ya están aprovechando todas las funciones avanzadas de Sutra para brindar experiencias grupales impactantes online.",
              promoBoxItem1: "Espacios ilimitados",
              promoBoxItem2: "Personalización completa de marca",
              promoBoxItem3: "Administradores adicionales",
              promoBoxButton: "Mejora el plan ahora",
            },
            spacesList: {
              yourSpaces: "Tus espacios",
              directMessagesTitle: "Mensajes directos",
              invitationsTitle: "Invitaciones",
              topTitle: "Espacios superiores",
              managedTitle: "Espacios administrados",
              allTitle: "Todos los espacios",
              archivedTitle: "Espacios archivados",
              deletedTitle: "Espacios eliminados",
              pendingTitle: "Espacios programados",
              directMessages: "Directos",
              top: "Superiores",
              managed: "Administrados",
              all: "Todos",
              archived: "Archivados",
              invitations: "Invitaciones",
              deleted: "Eliminados",
              pending: "Programados",
              empty: "No tienes ninguno",
              tabTitle: "Sutra - Panel de control",
              learn: "Aprende lo básico",
              createFirst: "Crea tu primer espacio",
            },
          },
          app: {
            payment: {
              payment: {
                tabTitle: "Sutra - Formulario de pago",
                title: "Completa el pago",
              },
              planOption: {
                frequencyDescription1: "por mes",
                frequencyDescription2: "por mes con suscripción anual",
                currentPlan: "Plan actual",
                billedMonthly: "Suscripción mensual",
                billedAnnually: "Suscripción anual",
              },
              stripeForm: {
                due_in_trial: "Vence en 14 días",
                pay: "Pagar",
              },
            },
            gettingStarted: {
              items: {
                setup: "Configuración de tu espacio de programa",
                privacy: "Configuraciones de privacidad y pago",
                registration: "Creación de una página de registro",
                invitations: "Invitar a los miembros",
                settings: "Cómo funcionan las configuraciones del espacio",
                interactive: "Recorridos interactivos",
                interest: "Formularios de interés",
                appearance: "Diseño y temas de color",
                grids: "Configuraciones de cuadrícula y bloque",
                broadcasts: "Envío de correos electrónicos masivos",
                tagging: "Etiquetas",
                emojis: "Emojis de la barra lateral",
              },
              templates: {
                groupprogram: {
                  label: "Programa grupal",
                  description:
                    "Un espacio de programa donde puedes organizar todo lo que necesitas para un grupo de personas que aprenden juntas.",
                },
                zoomworkshop: {
                  label: "Taller Zoom",
                  description:
                    "Organiza todo lo que necesitas para un taller sencillo hecho por Zoom.",
                },
                mastermind: {
                  label: "Mastermind",
                  description:
                    "Invita a un grupo de personas a participar juntas en un proceso de mastermind.",
                },
                selfpacedcourse: {
                  label: "Curso “a tu ritmo”",
                  description:
                    "Una plantilla flexible para crear un curso “a tu ritmo”",
                },
                communityspace: {
                  label: "Espacio de comunidad",
                  description:
                    "Reúne personas en un vibrante espacio online que crece junto a tu comunidad.",
                },
                oneononecoaching: {
                  label: "Coaching uno a uno",
                  description:
                    "Crea un espacio dedicado a cada uno de tus clientes de coaching.",
                },
              },
              loading: {
                text: "Tu plantilla se está cargando, por favor espera...",
              },
              tabTitle: "Sutra - Primeros pasos",
              mainTitle: "Guía de primeros pasos",
              selectTemplate: "Selecciona esta plantilla",
              advanceFeatures: "Funciones avanzadas",
              welcome: "Bienvenido/a",
              description1:
                "Con Sutra es fácil organizar el contenido y las conversaciones de tus cursos, comunidades, coaching individual y todo lo demás. Aprende lo básico en este video de cinco minutos.",
              watchVideo: "Ver el video",
              createFirstSpace: "Crea tu primer espacio",
              gettingStartedGuide: "Guía de primeros pasos",
              description2:
                "Aprende más sobre cómo funciona Sutra y empieza con una lista de pasos para el lanzamiento y una guía más detallada.",
              checkOutTheGuide: "Echale un ojo a la guía",
              startWithATemplate: "Empieza con una plantilla",
              description3:
                "Aprende más sobre cómo funciona Sutra y empieza con una lista de pasos para el lanzamiento y una guía más detallada.",
              learnAbout: "Aprende sobre las funciones avanzadas",
              description4:
                "Sutra está diseñada para ser una plataforma flexible, poderosa y para adaptarse casi a cualquier caso de uso. Puedes crear experiencias interactivas únicas y atractivas, captar el interés por tus diferentes ofertas, enviar newletter por correo electrónico y cosechar el conocimiento colectivo.",
              learnmore: "Descubre más ",
            },
            premium: {
              try: "Prueba Sutra",
              include: "Las funciones incluyen",
              upgrade: "Mejora el plan ahora",
            },
            pricing: {
              tabTitle: "Sutra - Precios",
              upgrade: "Mejora tu plan",
              text: "Cualquier cosa que necesites para tu curso o comunidad, estamos aquí para respaldar tu trabajo. Escríbenos a support@sutra.co si tienes alguna pregunta.",
            },
            scheduleDemo: {
              tabTitle: "Sutra - Agendar una demostración",
            },
            welcome: {
              tabTitle: "Sutra - Bienvenido/a",
              title: "¡Bienvenido/a a Sutra!",
              description1:
                "Estamos muy contentos de tenerte aquí. Por favor, tómate un momento para ver el siguiente video...",
              description2:
                "  ¿Te gustaría probar uno de nuestros planes de forma gratuita?",
              description3:
                "Elige uno de los planes que ves abajo para empezar tu prueba gratuita de 14 días. Una vez que termine la prueba, se te facturará automáticamente por el plan que has elegido.",
              yes: "Sí, por favor, muéstrame las opciones",
              no: "No, gracias",
            },
          },
          layout: {
            userButton: {
              home: "Inicio",
              viewProfile: "Ver perfil",
              settings: "Configuraciones",
              support: "Soporte",
              language: "Idioma",
              terms: "Términos y Política de Privacidad",
              logout: "Salir",
              copyright: "Sutra Espacios, LLC © 2022",
            },
            directMessages: {
              label: "Mensajes directos",
              actionButton: "Ver todos",
              empty: "No tienes ningún mensaje",
              directMessage: "Mensajes directos",
            },
            notifications: {
              label: "Notificaciones",
              actionButton: "Borrar todo",
              empty: "No tienes ninguna notificación",
              notification: "Notificaciones",
            },
            headerContent: {
              registrationPage: "Página de registro",
              nonManagersHidden: "Cabecera oculta para todos excepto para los administradores",
            },
            search: {
              reflection: "Reflexión",
              comment: "Comentario",
              allSpaces: "Todos los espacios",
              withinTop: "Dentro del espacio superior",
              withinSpace: "Dentro de este espacio",
              everything: "Todo",
              titlesOnly: "Solo títulos",
              tagsOnly: "Solo etiquetas",
              content: "Contenido",
              discussion: "Discusión",
              events: "Eventos",
              users: "Usuarios",
              placeholder: "Busca aquí...",
              nothingFound: "No se han encontrado resultados",
              search: "Buscar",
            },
            uploader: {
              pending: "Pendiente",
              used: "Utilizado",
              uploading: "Subiendo",
              finishing: "Completando",
              processing: "Procesando",
              completed: "Completado",
            },
          },
          resources: {
            pages: {
              form: {
                label: "Título",
                placeholder: "¿Cuál es el tema de tu página?",
                create: "Crear página",
                save: "Guardar página",
              },
            },
            form: {
              title: {
                label: "Título",
                placeholder: "¿Cuál es tu tema?",
              },
              publish: {
                label: "Publicar en una fecha",
              },
              date: {
                label: "Seleccionar una fecha",
              },
              update: "Actualizar colección de páginas",
              create: "Crear colección de páginas",
            },
          },
          settings: {
            editProfile: {
              tabTitle: "Sutra - Editar perfil",
              editProfile: "Editar perfil",
              firstName: "Nombre",
              lastName: "Apellido",
              username: "Nombre de usuario",
              email: "Email",
              removeProfileImage: "Eliminar foto de perfil",
              addProfileImage: "Añadir foto de perfil",
              about: "Sobre mí",
              location: "Ubicación",
              bio: "Presentación",
              bioPlaceholder: "Comparte un poco sobre ti...",
              updateProfile: "Actualizar perfil",
            },
            accountSettings: {
              accountSettings: "Configuración de la cuenta",
              changePassword: "Cambiar contraseña",
              currentPassword: "Contraseña actual",
              newPassword: "Nueva contraseña",
              newPasswordConfirmation: "Confirmar nueva contraseña",
              updatePassword: "Actualizar contraseña",
              advanced: "Confguraciones avanzadas",
              deleteAccount: "Eliminar cuenta",
              warning: "Advertencia",
              deleteAccountWarning:
                "Eliminar tu cuenta es una acción irreversible. Los datos de tu cuenta se eliminarán permanentemente de nuestro sistema.",
              closeYourAccount: "Eliminar la cuenta",
              invalidCurrentPasswordErrorMsg: "No se puede dejar vacío",
              invalidPasswordErrorMsg:
                "La contraseña debe tener al menos 8 caracteres",
              invalidPasswordConfirmationErrorMsg:
                "Las contraseñas no coinciden",
              deleteAccountAlert: {
                title: "Importante",
                message:
                  "Eliminar tu cuenta es irreversible y eliminará permanentemente los detalles de tu cuenta en nuestro sistema.",
                input:
                  "Por favor, escribe 'elimina mi cuenta' para confirmar esta acción",
                confirmButton: "Eliminar permanentemente",
                cancelButton: "Cancelar",
              },
              cancelSubscriptionAlert: {
                title: "Importante",
                message: "Por favor, primero cancela tu suscripción",
                confirmButton: "Ir a facturación",
                cancelButton: "Hacerlo más tarde",
              },
              stripeAccount: {
                title: "Restablecer vinculación con Stripe",
                alertTitle: "¿Estás seguro/a?",
                alertMessage:
                  "Si se restablece la vinculación con Stripe, tus credenciales de Stripe se borrarán de tu cuenta en Sutra y podrás vincular una nueva cuenta de Stripe.",
                buttonText: "Restablecer vinculación",
                confirmButton: "Sí",
                cancelButton: "No",
                confirmTitle: "¿Estás seguro/a?",
                confirmMessage:
                  "Tus credenciales de Stripe se borrarán de tu cuenta en Sutra",
              },
            },
            emailPreferences: {
              tabTitle: "Sutra - Preferencias de notificaciones",
              emailPreferences: "Preferencias de notificaciones",
              emailDigestPreferences:
                "Preferencias de notificaciones por correo electrónico",
              emailDigestFrequency:
                "Frecuencia de notificaciones por correo electrónico",
              emailDigestMessage:
                "Para modificar las preferencias de notificaciones por espacio, por favor consulta el área de configuración de suscripciones. Puedes darte de baja completamente de un espacio dejándolo o puedes silenciar un espacio específico en el área de configuración de suscripciones.",
              receiveWeeklyDigest:
                "Recibir un resumen semanal de nuevos elementos y discusiones etiquetadas",
              receiveAtMentions:
                "Recibir una notificación cuando alguien te @mencione",
            },
            subscriptions: {
              frequencyOptions: "Opciones de frecuencia",
              otherActions: "Otras acciones",
              subscriptions: "Preferencias específicas de espacio",
              searchBySpaceName: "Buscar el espacio por nombre",
              search: "Buscar",
              clear: "Borrar",
              options: "Opciones",
              otherActions: "Otras acciones",
              emptySubscriptions: "No tienes suscripciones.",
              leaveSpaceConfirmationTitle:
                "¿Estás seguro de que quieres dejar este espacio?",
              leaveSpaceConfirmationHtml:
                "Ya no serás miembro de este espacio y es posible que no puedas unirte nuevamente sin solicitarlo al administrador.",
              leaveSpaceButtonText: "Sí, abandonar",
              leaveSpaceDenyButtonText: "No",
              leaveSpace: "Abandonar espacio",
              unsubscribe: "Darse de baja",
              unsubscribed: "Dado de baja",
            },
            digestPreferences: {
              digestedLabel: "Agrupar todas las actualizaciones en un resumen",
              digestedDescription:
                "Los nuevos mensajes de todos tus espacios se agruparán en un único resumen por correo electrónico.",
              undigestedLabel: "Agrupar actualizaciones por espacio",
              undigestedDescription:
                "Recibirás resúmenes separados por correo electrónico para cada espacio que tenga nuevos mensajes.",
            },
            billing: {
              statuses: {
                pending: "Pendiente",
                processing: "Procesando",
                paid: "Pagado",
              },
              paymentMethod: {
                currentPaymentMethod: "Método de pago actual",
                noCurrentPaymentMethod: "Ningún método de pago guardado",
                addNewPaymentMethod: "Añadir nuevo método de pago",
              },
              stripe: {
                updatePaymentMethod: "Actualizar método de pago",
              },
              tabTitle: "Sutra - Facturación",
              updatePaymentMethodMayus: "ACTUALIZAR MÉTODO DE PAGO",
              payments: "PAGOS",
              pdf: "PDF",
              noPayments: "No tienes ningun historial de pagos",
              billing: "Facturación",
              upgradePlan: "Mejorar plan",
              changePlan: "Cambiar de plan",
              updatePaymentMethod: "Actualizar método de pago",
              cancelSubscription: "Cancelar suscripción",
              cancelConfirmationTitle:
                "¿Estás seguro de que quieres cancelar tu suscripción?",
              cancelConfirmationHtml:
                "Ya no tendrás acceso a las funciones premium de tu plan.",
              cancelConfirmButtonText: "Sí, cancelar",
              cancelDenyButtonText: "No",
              billingHistory: "Historial de facturación",
              tableDate: "Fecha",
              tableAmount: "Importe",
              tableStatus: "Estado",
              tableInvoice: "Factura",
              planPrefix: "Actualmente tienes suscripción al",
              planSuffix: "plan",
              updatePayment: {
                success: {
                  title: "Éxito",
                  message: "Se ha actualizado tu método de pago",
                },
                error: {
                  title: "¡Vaya, algo ha salido mal!",
                },
              },
            },
            frequencyOptions: {
              mute: "Silenciar",
              always: "Cada mensaje",
              hour1: "Cada hora",
              hour2: "Cada 2 horas",
              hour4: "Cada 4 horas",
              hour8: "Cada 8 horas",
              hour24: "Una vez por día",
              hour168: "Una vez por la semana",
              hour336: "Cada dos semanas",
            },
            affiliates: {
              empty: "No tienes historial de pagos del programa embajadores.",
              history: {
                table: {
                  referralName: "Nombre del referido",
                  startAt: "Comienza el",
                  endAt: "Termina el",
                  createdAt: "Creado el",
                  percent: "Porcentaje",
                  total: "Total ganado",
                  status: "Estado",
                  value: "Valor",
                  revenue: "Ingresos",
                  payAt: "Pagar en fecha",
                  status: "Estado",
                },
                statuses: {
                  null: "Pendiente",
                  pending: "Pendiente",
                  processing: "Procesando",
                  paid: "Pagado",
                  active: "Activo",
                },
                paymentsHistory: "Historial de pagos",
              },
              tabTitle: "Sutra - Programa embajadores",
              title: "Programa embajadores",
              affiliateLink: "Este es tu enlace de afiliado",
              copyMessage: "Enlace copiado al portapapeles",
              stripeTitle: "Stripe vinculado a",
              stripeConnected:
                "Este es el identificador de tu cuenta en Stripe. Para encontrarlo, inicia sesión en Stripe, ve a configuraciones de cuenta y baja hasta el final de la página.",
              stripeDisconnect: "Desvincular",
              description:
                "Para recibir pagos del programa de embajadores, por favor vincula tu cuenta de Stripe haciendo clic en el siguiente botón.",
              description2:
                "Al compartir este enlace, recibirás automáticamente el 40% de los ingresos generados durante lel primer año por cada persona que se suscriba a un plan de pago en Sutra.",
              connect: "Vincular Stripe",
              historyLabel: "Historial del programa de embajadores.",
            },
          },
          spaces: {
            settings: {
              layout: {
                navbarContent: {
                  mainSettings: "Configuración general",
                  members: "Miembros",
                  broadcast: "Envío masivo",
                  surveys: "Encuestas",
                  structures: "Estructuras",
                  sutraAdmin: "Sutra Admin",
                  scheduled: "Programados",
                  archived: "Archivados",
                  participation: "Participación",
                },
                headerActions: {
                  shareableLink: "ENLACE PARA COMPARTIR",
                },
              },
              main: {
                title: "Configuración general",
                main: {
                  invite: "Invitar",
                  shareableLink: "Enlace para compartir",
                  cancelLink: "Cancelar",
                  saveLink: "Guardar",
                  editButton: "Editar enlace",
                  subdomain: "Tu subdominio",
                  sutra_co: ".sutra.co",
                  label1: "Enlace copiado al portapapeles",
                  privacySetting: "Selecciona una configuración de privacidad",
                  title1: "Hacer este espacio accesible públicamente",
                  description1:
                    "Este espacio está dentro de un espacio privado y solo es visible a los miembros de ese espacio. Puedes hacerlo accesible públicamente fuera de este espacio activando esta opción.",
                  title2: "Mostrar página de registro",
                  description2:
                    "Si esta opción está activada, las personas que no son miembros verán una página de registro cuando intenten acceder a este espacio.",
                  title3: "Utilizar la versión antigua de la página de registro",
                  description3:
                    "Si esta opción está activada, este espacio utilizará la página de registro diseñada con nuestro editor anterior.",
                  onButton: "ON",
                  offButton: "OFF",
                  editButton: "Editar página",
                  previewButton: "Vista previa de la página",
                  label2: "Configuración de privacidad y pago",
                  label3: "Configuración de la página de registro",
                  description4:
                    "Activo - las personas llegarán a una página de registro antes de poder acceder a este espacio.",
                  label4: "Automatización",
                  description5:
                    "Programa el envío automático de los correos electrónicos y añade personas a los espacios de forma automática al momento de su registro",
                  label5: "Configuración de programación",
                  description6:
                    "Fija una fecha para tu evento o configura las opciones de publicación y archivado.",
                  label6: "Configuraciones adicionales",
                  description7:
                    "Permisos de creación de contenido, visibilidad de miembros, notificaciones de administradores y espacios raíz",
                  label7: "Configuración de SEO",
                  description8:
                    "Establece meta título, meta descripción e imagen para las redes sociales y el posicionamiento en motores de búsqueda",
                  label8: "Abierto",
                  description9: "Cualquiera puede acceder a este espacio",
                  label9: "Privado",
                  description10:
                    "Este espacio es privado y las personas tienen que ser invitadas para acceder",
                  label10: "Pago",
                  description11:
                    "Este espacio es de pago y privado. Las personas tienen que pagar para acceder",
                  description12:
                    "Las personas primero se dirigirán a una página de registro antes de poder acceder a este espacio abierto.",
                  description13:
                    "Las personas se dirigirán directamente a este espacio cuando accedan a este espacio abierto.",
                  description14:
                    "Las personas tienen que ser invitadas para poder acceder a este espacio privado. En caso de haber sido invitadas, se les dirigirá a una página de registro.",
                  description15:
                    "Las personas tienen que ser invitadas para poder acceder a este espacio privado.  En caso de haber sido invitadas, verán una página donde pueden crear una cuenta y no verán una página de registro.",
                  description16:
                    "Las personas se dirigirán a una página de registro antes de poder acceder a este espacio de pago.",
                  description17:
                    "Las personas llegarán a una página de creación de cuenta y pago antes de poder acceder a este espacio de pago. No verán una página de registro.",
                  on: "ON",
                  off: "OFF",
                  label11: "Miembros",
                  label12: "Pendientes",
                  label13: "Interesados",
                  label14: "Invitados",
                  label15: "Pagos",
                  label16: "Herrada",
                },
                additional: {
                  allowMembersEdit:
                    "Permitir a los miembros editar el contenido de esta página",
                  membersWillAddContent:
                    "Los miembros podrán añadir y editar contenido de esta página",
                  allowMembersCreatePosts:
                    "Permitir a los miembros crear nuevas publicaciones",
                  membersWillCreatePosts:
                    "Los miembros podrán crear nuevas publicaciones en este espacio",
                  allowMembersCreateEvents:
                    "Permitir a los miembros crear nuevos eventos",
                  membersWillCreateEvents:
                    "Los miembros podrán crear nuevos eventos en este espacio",
                  allowMembersCreateSubspaces:
                    "Permitir a los miembros crear nuevos subespacios",
                  membersWillCreateSubspaces:
                    "Los miembros podrán crear subespacios en esta página",
                  membersCanCreate: "Los miembros pueden crear",
                  circleCreation: {
                    options: {
                      discussion: "Espacios de discusión",
                      content: "Espacios de contenido",
                      event: "Espacios de evento",
                    },
                    placeholder:
                      "Selecciona los tipos de espacios que los miembros pueden crear en este espacio",
                  },
                  allowMembersToCopy: {
                    description1:
                      "Permitir a los miembros invitar a personas de otros espacios.",
                    description2:
                      "Los miembros tendrán la opción de añadir automáticamente miembros de otros círculos",
                  },
                  podType: {
                    description1:
                      "Todos los miembros pueden publicar mensajes en este espacio",
                    description2:
                      "Si esta opción está desactivada, solo los administradores pueden publicar mensajes",
                  },
                  allowReflections: {
                    description1: "Activar reflexiones para este espacio",
                    description2:
                      "Si esta opción está desactivada, la opción de reflexión no aparecerá en los mensajes",
                  },
                  showMembers: {
                    description1: "Mostrar miembros",
                    description2:
                      "Mostrar la lista de miembros de este espacio",
                  },
                  hideMmemberEmails: {
                    description1:
                      "Mostrar las direcciones de email de los miembros en la lista",
                    description2:
                      "Si esta opción está desactivada, las direcciones de email de los miembros no se mostrarán en la lista",
                  },
                  newMemberNotifications: {
                    description1:
                      "Notificar a los administradores cuando se unen nuevos miembros",
                    description2:
                      "Enviar una notificación por correo electrónico cada vez que se registre un nuevo miembro",
                  },
                  podSize: {
                    description1:
                      "Limitar el número de personas que pueden unirse a este espacio",
                    description2:
                      "Especificar un número máximo de personas que pueden unirse a este espacio",
                  },
                  podSizeValue: {
                    description:
                      "¿Cuántas personas pueden unirse a este espacio?",
                  },
                  sidebarDefaultOpened: {
                    description1:
                      "Espacio abierto por defecto en la barra lateral",
                    description2:
                      "Si esta opción está activada, este espacio se mostrará desplegado en la barra lateral",
                  },
                  sidebarHideChildren: {
                    description1: "Ocultar subespacios en la barra lateral",
                    description2:
                      "Si esta opción está activada, los subespacios creados en este espacio no aparecerán en la barra lateral",
                  },
                  allowCompletions: {
                    description1: "Mostrar botón de marcar como completado",
                    description2:
                      'Si esta opción está activada, los miembros verán un botón de "Marcar como completado" para este espacio. Después de seleccionar esta opción, el ícono en la barra lateral se reemplazará por un símbolo de completado.',
                  },
                  redirectToSubspace: {
                    description1: "Redirigir siempre a un subespacio",
                    description2:
                      "Si esta opción está activada, al acceder a este espacio los miembros serán dirigidos siempre al subespacio seleccionado.",
                  },
                  parentCircles: {
                    description1: "Espacios raíz",
                    placeholder:
                      "Busca espacios para añadir como espacio raíz a este espacio",
                  },
                },
                scheduling: {
                  showStartDate: {
                    description1: "Mostrar este espacio como un evento",
                    description2:
                      "Este espacio mostrará una fecha de evento en todos los lugares donde aparezca",
                  },
                  startDateTime: {
                    title: "Fecha y hora de inicio",
                    placeholder: "Elegir fecha",
                  },
                  endDateTime: {
                    title: "Fecha y hora de finalización",
                    placeholder: "Elegir fecha",
                  },
                  showLaunchDate: {
                    description1: "Publicar este espacio en una fecha específica",
                    description2:
                      "Este espacio será visible y accesible en la fecha especificada",
                  },
                  launchDateTime: {
                    placeholder: "Elegir fecha",
                  },
                  showArchiveDate: {
                    description1: "Archivar este espacio en una fecha específica",
                    description2:
                      "Este espacio dejará de ser accesible en la fecha especificada.",
                  },
                  archiveDate: {
                    placeholder: "Elegir fecha",
                  },
                  showReadonlyDate: {
                    description1:
                      "Convertir este espacio en un espacio solo de lectura en una fecha específica.",
                    description2:
                      "No se podrán publicar nuevos mensajes a partir de la fecha especificada",
                  },
                  readonlyDate: {
                    placeholder: "Elegir fecha",
                  },
                },
                seo: {
                  title: {
                    title: "Meta título",
                    description:
                      "Este título aparecerá en los resultados de los motores de búsqueda y cuando se comparta en redes sociales. La longitud óptima es de 60 caracteres.",
                  },
                  description: {
                    title: "Meta descripción",
                    description:
                      "Esta descripción aparecerá en los resultados de los motores de búsqueda y cuando se comparta en redes sociales.",
                  },
                  image: {
                    title: "Meta imagen",
                    description:
                      "Esta es la imagen que aparecerá al compartir en las redes sociales. La dimensión recomendada es 1200 píxeles x 627 píxeles (relación de aspecto 1.91/1).",
                  },
                },
              },
              members: {
                title: "Miembros",
                members: {
                  download: "Descargar CSV",
                  invite: "Invitar",
                  member: "Miembros",
                  invited: "Invitados",
                  interested: "Interesados",
                  payment: "Pagos",
                },
                interested: {
                  interested: {
                    button: "Buscar",
                    placeholder: "Buscar más",
                    broadcast: "Envío masivo",
                    noRecordFound: "No se encontraron registros",
                  },
                  table: {
                    firstname: "Nombre",
                    email: "Email",
                    date: "Fecha",
                  },
                  interestedrow: {
                    removeFromList: "Eliminar de la lista",
                  },
                },
                invited: {
                  invited: {
                    button: "Buscar",
                    placeholder: "Buscar más",
                    noRecordFound: "No se encontraron registros",
                  },
                  table: {
                    email: "Email",
                    invitationlink: "Enlace de invitación",
                    amount: "Importe",
                    date: "Fecha de envío",
                  },
                },
                invitedrow: {
                  invitation: {
                    resend: "Reenviar invitación",
                    cancel: "Cancelar invitación",
                  },
                },
                inviteEmail: {
                  invite: {
                    placeholder:
                      "Escribe la dirección de email o @menciona a las personas que quieres añadir a este espacio. Los datos ingresados pueden estar separados por comas, espacios o saltos de línea.",
                    message: "Añade un mensaje de bienvenida opcional",
                    checkboxLabel:
                      "Enviar un correo electrónico para notificar a las personas que han sido invitadas",
                    alertMessage:
                      "Actualmente tienes suscripción a un plan básico y no puedes enviar invitaciones a través de Sutra. Cuando hagas clic en Invitar, se generará un enlace único para cada dirección de correo electrónico que añades. Podrás enviar personalmente un correo electrónico a cada persona con este enlace.",
                    alertbutton: "Mejora tu plan",
                    button: "Invitar personas",
                  },
                },
                inviteExisting: {
                  invite: {
                    copyMembers: "Copiar miembros desde otro espacio",
                    copyMembersPlaceholder:
                      "Seleccionar espacios desde cuales quieres copiar miembros",
                    addActiveMembers:
                      "Añadir miembros activos desde espacios superiores",
                    addActiveMembersPlaceholder:
                      "Selecciona miembros activos para añadirlos a este espacio",
                    addPendingMembers:
                      "Añadir miembros pendientes desde los espacios raíz",
                    addPendingMembersPlaceholder:
                      "Seleccionar miembros pendientes para añadir a este espacio",
                    checkboxLabel:
                      "Enviar un correo electrónico para notificar a las personas que han sido invitadas",
                    alert:
                      "Actualmente tienes suscripción a un plan básico y no puedes enviar invitaciones a través de Sutra. Cuando hagas clic en Invitar, se generará un enlace único para cada dirección de correo electrónico que añades. Podrás enviar personalmente un correo electrónico a cada persona con este enlace.",
                    alertButton: "Mejora tu plan",
                    button: "Invitar personas",
                    thisOption:
                      "Esta opción no está activada para este espacio",
                  },
                },
                inviteLink: {
                  invite: {
                    shareLinkText:
                      "Las personas pueden registrarse en este espacio directamente con el siguiente enlace. Puedes compartir este enlace donde quieras (por correo electrónico, en las redes sociales, etc).",
                    youCannotShare:
                      "No puedes compartir un enlace a un espacio privado.",
                  },
                },
                invitePeople: {
                  invite: {
                    invitePeople: "Invitar personas",
                  },
                },
                membersActivity: {
                  members: {
                    placeholder: "Buscar",
                    button: "Buscar",
                    emailButton: "Email",
                    messageButton: "Mensaje",
                    noRecordFound: "No se encontraron registros",
                  },
                  memberTable: {
                    noRecordFound: "No se encontraron registros de miembros",
                    lastSeen: "Última conexión",
                    lastPosted: "Última publicación",
                    date: "Miembro desde",
                  },
                },
                participationActivity: {
                  participation: {
                    searchPlaceholder: "Buscar",
                    searchButton: "Buscar",
                    pickDatePlaceholder: "Seleccionar rango de fechas",
                    emailButton: "Email",
                    messageButton: "Mensaje",
                    noRecordFound: "No se encontraron registros",
                  },
                  memberTable: {
                    member: "Miembro",
                    participation: "Participación",
                    posts: "Publicaciones",
                    words: "Palabras",
                  },
                },
                participationActivityRow: {
                  posts: "publicaciones",
                },
                paymentRow: {
                  payment: {
                    refundPayment: "Reembolsar pago",
                  },
                },
                payments: {
                  payment: {
                    searchPlaceholder: "Buscar",
                    searchButton: "Buscar",
                    noRecordFound: "No se encontraron registros",
                  },
                  paymentTable: {
                    member: "Miembro",
                    amount: "Importe",
                    date: "Fecha",
                    status: "Estado",
                  },
                },
                progressActivity: {
                  progress: {
                    searchPlaceholder: "Buscar",
                    searchButton: "Buscar",
                    emailButton: "Email",
                    messageButton: "Mensaje",
                    noRecordFound: "No se encontraron registros",
                    progressTracking:
                      "El seguimiento del progreso está disponible solo en los espacios con recorridos interactivos.",
                  },
                  progressTable: {
                    member: "Miembro",
                    progress: "Progreso",
                  },
                },
                registered: {
                  registered: {
                    searchPlaceholder: "Buscar",
                    searchButton: "Buscar",
                    email: "Email",
                    message: "Mensaje",
                    noRecordFound: "No se encontraron registros",
                    pending: "Pendientes",
                    manager: "Administradores",
                    all: "Todos",
                    active: "Activos",
                  },
                  registeredTable: {
                    member: "Miembro",
                    email: "Email",
                    status: "Estado",
                    role: "Rol",
                    roleApproveAll: "Aprobar todos",
                  },
                },
                registeredRow: {
                  registered: {
                    roleApprove: "Aprobar",
                    joined: "Unido",
                    sendMessage: "Enviar mensaje",
                    stopCharging: "Suspender cobro",
                    emailPreferences: "Preferencias de notificaciones",
                    removeMember: "Eliminar miembro",
                  },
                },
              },
              payment: {
                charging: {
                  NumberOfTimes: {
                    howManyTimes: "¿Cuántas veces?",
                  },
                  UntilDate: {
                    pickDate:
                      "Elegir fecha de finalización del cobro",
                  },
                },
                fees: {
                  description: {
                    fees: "TARIFAS:",
                    sutraFee: "TARIFAS DE SUTRA +",
                    stripeProcessingFee: "TARIFAS DE PROCESAMIENTO DE STRIPE",
                  },
                },
                flexible: {
                  flexible: {
                    description:
                      "Para ofrecer opciones de flexibilidad de pago, como el modelo “paga lo que puedes” o múltiples niveles de pago, puedes crear una encuesta de pago que las personas verán al registrarse en tu espacio. Haz clic en el botón Editar abajo para personalizar la encuesta.",
                  },
                },
                once: {
                  once: {
                    description: "¿Cuánto cobrarás?",
                    number: "10",
                  },
                },
                recurring: {
                  recurring: {
                    description1: "¿Cuánto cobrarás?",
                    description2: "Selecciona una opción de cobro",
                  },
                },
              },
              privacy: {
                open: {
                  open: {
                    description: "Cualquiera puede acceder a este espacio",
                  },
                },
                paid: {
                  paid: {
                    stripeConnected: "Stripe vinculado a",
                    disconnectButton: "Desvincular",
                    description:
                      "Para procesar pagos de este espacio, por favor vincula tu cuenta de Stripe haciendo clic en el botón de abajo.",
                    connectButton: "Vincular Stripe",
                  },
                },
                private: {
                  private: {
                    description:
                      "Este espacio es privado y las personas tienen que ser invitadas para unirse",
                  },
                },
              },
              registration: {
                steps: {
                  addFromSubSpace: {
                    label: "Selecciona un espacio para añadir miembros",
                    description:
                      "En algunas circunstancias, los miembros pueden unirse a un subespacio sin unirse al espacio raíz. En estos casos, necesitarás asegurarte de que también estén agregados a un espacio raíz u otro espacio compartido. Con esta función puedes hacerlo de forma sencilla. Cada vez que alguien se una a un subespacio, se añadirá automáticamente a los espacios que selecciones aquí abajo.",
                    placeholder: "Seleccionar espacios",
                    saveButton: "Guardar paso",
                    deleteButton: "Eliminar paso",
                    saveButton2: "Guardar paso",
                  },
                  addStructure: {
                    label1: "Selecciona una estructura",
                    placeholder1: "Selecciona una opción",
                    label2: "Enviar como",
                    placeholder2: "Selecciona una opción",
                    saveButton: "Guardar paso",
                  },
                  addToExistingSpace: {
                    label: "Selecciona un espacio al que añadir el miembro",
                    placeholder: "Seleccionar espacios",
                    saveButton1: "Guardar paso",
                    deleteButton: "Eliminar paso",
                    saveButton2: "Guardar paso",
                  },
                  addToNewSpace: {
                    placeholder: "Nombre del nuevo espacio",
                    label: "Ponle un nombre al nuevo espacio",
                    saveButton1: "Guardar paso",
                    deleteButton: "Eliminar paso",
                    saveButton2: "Guardar paso",
                  },
                  applyTemplate: {
                    label: "Selecciona un espacio para duplicar",
                    description:
                      "En algunos casos querrás crear un espacio plantilla para replicarlo cada vez que se cree un nuevo espacio. Puedes crear un espacio y configurarlo exactamente como quieras. El contenido y los subespacios se copiarán desde la plantilla al espacio recién creado aquí.",
                    saveButton1: "Guardar paso",
                    deleteButton: "Eliminar paso",
                    saveButton2: "Guardar paso",
                  },
                  editEmail: {
                    placeholder1: "Asunto del correo",
                    label1: "Enviar como",
                    placeholder2: "Selecciona una opción",
                    label2: "Enviar a",
                    placeholder3: "Selecciona una opción",
                    placeholder4: "alguien@especial.com",
                    label3: "Dirección de correo electrónico",
                    saveButton: "Guardar paso",
                    deleteButton: "Eliminar paso",
                  },
                  editStructure: {
                    deleteButton: "Eliminar paso",
                  },
                  editSurvey: {
                    deleteButton: "Eliminar paso",
                  },
                  sendEmail: {
                    label1: "Selecciona correo",
                    placeholder1: "Selecciona una opción",
                    label2: "Enviar como",
                    placeholder2: "Selecciona una opción",
                    label3: "Enviar a",
                    placeholder3: "Selecciona una opción",
                    label4: "alguien@especial.com",
                    placeholder4: "Dirección de correo electrónico",
                    saveButton: "Guardar paso",
                    label5: "Administradores",
                    label6: "Dirección de correo electrónico personalizada",
                  },
                  setToPending: {
                    title: "Mensaje de bienvenida",
                    description:
                      "Este contenido será lo que las personas verán justo después de registrarse en tu espacio. Es el lugar ideal para explicar cuándo comenzará el programa y qué pueden esperar en el período previo.",
                    saveButton1: "Guardar paso",
                    deleteButton: "Eliminar paso",
                    saveButton2: "Guardar paso",
                  },
                  surveyPerson: {
                    placeholder: "Selecciona una opción",
                    label: "Seleccionar encuesta",
                    saveButton1: "Guardar paso",
                    deleteButton: "Eliminar paso",
                    saveButton2: "Guardar paso",
                  },
                  wait: {
                    description1: "¿Cuántos días?",
                    description2: "Esperar hasta",
                    saveButton: "Guardar paso",
                    deleteButton: "Eliminar paso",
                    saveButton2: "Guardar paso",
                    label1: "Seleccionar condición de espera",
                    placeholder: "Selecciona una opción",
                    label2: "Cantidad de días",
                    label3: "Hasta una fecha y hora específicas",
                    label4: "Elegir fecha",
                  },
                  webhook: {
                    label: "URL del Webhook",
                    saveButton: "Guardar paso",
                    deleteButton: "Eliminar paso",
                  },
                },
                addStep: {
                  addStep: {
                    description: "Añadir paso",
                    label: "Por favor selecciona una acción para este paso",
                    placeholder: "Selecciona una acción",
                  },
                },
                registrationFlow: {
                  registration: {
                    description1:
                      "Esta función te permite secuenciar acciones automatizadas que se activan cuando una persona se une o muestra interés en este espacio. Aquí puedes hacer cosas como programar el envío de correos electrónicos y añadir automáticamente personas a espacios seleccionados cuando se registran.",
                    description2:
                      "Realiza estas acciones después de que alguien...",
                    placeholder1: "Selecciona una condición de inicio",
                    addButton: "Añadir paso",
                    label1:
                      "Se convierte en miembro o se une a la lista de interesados",
                    description3:
                      "Los siguientes pasos se activarán cuando una persona se convierta en miembro de este espacio o se una a la lista de interesados enviando su nombre y correo electrónico a través de un formulario de interés.",
                    label2: "Se convierte en miembro de este espacio",
                    description4:
                      "Los siguientes pasos se activarán cuando una persona se convierta en miembro de este espacio.",
                    label3: "Muestra interés en este espacio",
                    description5:
                      "Los siguientes pasos se activarán cuando una persona se una a la lista de interesados enviando su nombre y correo electrónico a través de un formulario de interés.",
                  },
                },
              },
              structure: {
                add: {
                  add: {
                    title: "Añadir a espacios",
                    description: "Espacios",
                    placeholder1: "Seleccionar un espacio",
                    label1: "Enviar como",
                    placeholder2: "Elegir remitente",
                    placeholder3: "Seleccionar fecha",
                    label2: "Fecha de envío",
                    label3: "Hora de envío",
                    saveButton: "Guardar",
                    label4: "Programar para más tarde",
                  },
                },
                createStructure: {
                  title: {
                    createStructure: "Crear estructura",
                  },
                },
                structureItem: {
                  item: {
                    addToSpace: "Añadir al espacio",
                    open: "View responses",
                    edit: "Editar",
                    duplicate: "Duplicar",
                    delete: "Eliminar",
                  },
                },
              },
              survey: {
                add: {
                  add: {
                    title: "Añadir a espacios",
                    spaces: "espacios",
                    placeholder1: "Seleccionar un espacio",
                    label: "Enviar como",
                    placeholder2: "Elegir remitente",
                    saveButton: "Guardar",
                  },
                },
                answer: {
                  answer: {
                    addOption: "Añadir opción",
                  },
                },
                createSurvey: {
                  description: "Crear encuesta",
                },
                createSurveyForm: {
                  form: {
                    title: "Título de la encuesta",
                    description:
                      "La flexibilidad de pago te permite ofrecer múltiples opciones para tu programa. Si deseas que las personas establezcan cuánto pagar, simplemente crea una opción sin establecer el importe. Esa opción aparecerá con un campo para que la persona pueda establecer su propio importe. Por ejemplo, puedes ofrecer un modelo “paga lo que puedes” con varios niveles de precios sugeridos y una opción completamente abierta. Si agregas un texto de explicación abajo, este texto se mostrará justo encima de las opciones de pago.",
                    optionalDescription: "Descripción opcional de la encuesta",
                    addQuestionButton: "Añadir pregunta",
                    saveButton: "Guardar",
                  },
                },
                individual: {
                  individual: {
                    placeholder: "Seleccionar una persona",
                  },
                },
                multipleItem: {
                  multipleItem: {
                    placeholder: "Opción",
                  },
                },
                paymentItem: {
                  paymentItem: {
                    placeholder: "Opción de pago",
                  },
                },
                paymentSurveyItem: {
                  item: {
                    editButton: "Editar opciones de pago",
                    viewButton: "Ver respuestas",
                  },
                },
                showSurvey: {
                  survey: {
                    title: "Respuestas de la encuesta",
                  },
                },
                singleItem: {
                  item: {
                    placeholder: "Opción",
                  },
                },
                surveyItem: {
                  item: {
                    addToSpace: "Añadir al espacio",
                    open: "View responses",
                    edit: "Editar",
                    take: "Preview survey",
                    duplicate: "Duplicar",
                    download: "Descargar CSV",
                    delete: "Eliminar",
                    responses: "Respuestas",
                  },
                },
              },
              broadcast: {
                broadcast: {
                  button: {
                    broadcastButton: "Nuevo envío masivo",
                  },
                },
              },
              broadcast: {
                title: "Envío masivo",
                broadcastButton: "Nuevo envío masivo",
                membersTypes: {
                  active: "Miembros activos",
                  pending: "Miembros pendientes",
                  interested: "Miembros interesados",
                },
                sentOn: "Envío masivo enviado el",
                onDestroy: {
                  alertTitle: "¿Estás seguro/a?",
                  alertMessage: "Este envío masivo se eliminará permanentemente",
                  confirm: "Eliminar envío",
                  deny: "Cancelar",
                },
                actions: {
                  edit: "Editar",
                  duplicate: "Duplicar",
                  delete: "Eliminar",
                },
                statuses: {
                  sent: "Enviado",
                  scheduled: "Programado",
                  draft: "Borrador",
                  procesing: "Processing",
                },
                form: {
                  composeBroadcast: "Redactar envío",
                  contentErrMessage: "Agrega contenido",
                  targetErrMessage: "Selecciona un destinatario",
                  type: {
                    email: "Enviar por correo electrónico",
                    message: "Mensaje al espacio",
                  },
                  title: {
                    placeholder: "Asunto",
                  },
                  brackets: {
                    part1: "Utiliza",
                    part2: "y",
                    part3: "para autocompletar la información correspondiente",
                  },
                  target: {
                    placeholder: "Seleccionar destinatario",
                    label: "Enviar a",
                  },
                  sendAs: {
                    label: "Enviar como",
                    placeholder: "Seleccionar remitente",
                  },
                  schedule: {
                    label: "Programar para más tarde",
                  },
                  sendAtDate: {
                    label: "Fecha de envío",
                    placeholder: "Seleccionar fecha",
                  },
                  sendAtTime: {
                    label: "Hora de envío",
                    placeholder: "",
                  },
                  scheduleBroadcast: "Programar envío",
                  sendBroadcast: "Enviar",
                  saveDraft: "Guardar borrador",
                },
              },
              surveys: {
                title: "Encuestas",
                createSurvey: "Crear encuesta",
              },
              structures: {
                title: "Estructuras",
                createStructure: "Crear estructura",
              },
              sutraAdmin: {
                title: "Administrador de Sutra",
              },
              scheduled: {
                title: "Programado",
              },
              archived: {
                title: "Archivado",
              },
              participation: {
                title: "Participación",
                label1: "Presencia",
                description1:
                  "% de miembros que han visitado el espacio en los últimos 7 días",
                label2: "Actividad",
                description2:
                  "% de miembros que han creado publicaciones en los últimos 7 días",
                label3: "Publicaciones",
                description3:
                  "Total de publicaciones en todos los subespacios en los últimos 7 días",
                label4: "Participación",
                label5: "Actividad de los miembros",
                label6: "Avanzado",
              },
            },
            sidebar: {
              iconSelect: {
                placeholder: "Buscar",
              },
              membersNode: {
                member: "Miembros",
              },
              nodeIcon: {
                reset: "Restablecer",
              },
              sidebarTutorial: {
                gettingStarted: "Primeros pasos",
              },
            },
            alreadyMember: {
              member: {
                welcome: "¡Bienvenido/a! Ya eres miembro de este espacio -",
                clickHere: "Haz clic aquí para entrar",
              },
            },
            calender: {
              calender: {
                joinButton: "Unirse a la videoconferencia",
                addButton: "Añadir al calendario",
                joinEventButton: "Registrarse",
                title: "Detalles del evento",
              },
            },
            invitationBar: {
              button: {
                register: "Registrarse",
              },
            },
            space: {
              sutraLoading: "Sutra - Cargando...",
            },
            statusBar: {
              restoreButton: "Restaurar",
            },
            tagFilter: {
              resetButton: "Restablecer",
            },
            presentations: {
              discussion: {
                reflectionHeader: "Reflexiones",
                backToPost: "Volver a la publicación",
                pinnedMessageHeader: "Mensaje fijado",
                delete: {
                  title: "¿Estás seguro/a?",
                  message: "Esta acción no se puede deshacer",
                  confirmButton: "Sí, eliminar",
                  cancelButton: "Cancelar",
                },
              },
              tag: {
                placeholder: "Buscar etiquetas o crear una nueva",
                placeholder2: "Selecciona una etiqueta",
                createAction: "Crear",
              },
              message: {
                reply: "Reflexionar",
                addTags: "Añadir etiquetas",
                tag: "Etiqueta",
                copyToClipboard: "Copiar al portapapeles",
                messageCopied: "Mensaje copiado al portapapeles",
                pinMessageAction: "Fijar mensaje",
                editMessage: "Editar mensaje",
                deleteMessage: "Eliminar mensaje",
                makePrivate: "Hacerlo privado",
                viewReplies1: "Ver",
                viewReplies2single: "reflexión",
                viewReplies2plural: "reflexiones",
                privateMessage:
                  "Actualmente, solo los administradores de este espacio y tú pueden ver esta publicación. Si decides hacerla pública, todos los miembros la verán en este espacio.",
                privateMessage2:
                  "Esta persona ha elegido no compartir su respuesta con otros miembros.",
                unreadMessagesText: "Tienes mensajes sin leer",
                scrollDownButton: "Desplazarse hacia abajo",
                newReflections: {
                  part1: "Tienes",
                  part2: "reflexiones sin leer en",
                  part3: "mensajes",
                },
                viewNextButton: "Ver siguiente",
                pinMessage: {
                  mainText: "Mensaje fijado",
                  viewButton: "Ver mensaje",
                },
              },
              content: {
                commentInput: {
                  toggleLabel1:
                    "Los comentarios están desactivados para los recorridos interactivos",
                  toggleLabel2: "Activar comentarios para esta publicación",
                  toggleLabel3:
                    "Activar comentarios para este espacio de evento",
                  toggleLabel4:
                    "Activar comentarios para este espacio de contenido",
                  placeholder1:
                    "No se pueden publicar comentarios mientras estás editando.",
                  placeholder2: "Escribe algo...",
                },
              },
              fallback: {
                title: "Problemas de conexión",
                message:
                  "Parece que no tienes Internet. Cuando lo tengas de vuelta, te reconectaremos automáticamente.",
              },
              events: {
                placeholder: "Escribe algo...",
                upcomingButton: "Próximos",
                pastButton: "Pasados",
                addEvent: "Añadir evento",
                eventSettingsTooltip: "Configuraciones del evento",
                newEventTitle: "NUEVO EVENTO",
              },
              list: {
                placeholder: "Escribe algo...",
                createButton: "Crear publicación",
                listSettingsTooltip: "Configuraciones del foro",
                duplicateFormTitle: "DUPLICAR ESPACIO",
              },

              settings: {
                versions: {
                  posts: "Publicaciones",
                  discussions: "Discusiones",
                  link: "Enlace",
                  template: "Plantilla",
                },
                versionsDescriptions: {
                  content:
                    "Los nuevos elementos se mostrarán como una página de contenido con funcionalidad completa.",
                  discussion: "Vacío",
                  posts:
                    "Los nuevos elementos se mostrarán como una publicación simple con funcionalidad limitada.",
                  link: "Esta opción te permite enviar un enlace a cualquier espacio en Sutra que luego aparecerá como un elemento aquí.",
                  template:
                    "Crea un espacio de plantilla que se duplicará cada vez que se cree un nuevo elemento aquí.",
                },
                createNewTemplate: "Crear nueva plantilla",
                editTemplate: "Editar plantilla",
                title: "CONFIGURACIONES",
                accordion1: {
                  label: "Mostrar como",
                  description:
                    "Mostrar publicaciones en vista de lista o cuadrícula",
                  listOption: "Lista",
                  gridOption: "Cuadrícula",
                  dateCreated: "Fecha de creación",
                  dateCreatedReversed: "Fecha creación inverso",
                  lastActive: "Última actividad",
                  lastEdited: "Última edición",
                  alphabetic: "Orden alfabético",
                  reverseAlphabetic: "Orden alfabético inverso",
                },
                accordion2: {
                  label: "Opciones de visualización",
                  description: "",
                  switches: {
                    onlyManagers:
                      "Solo administradores pueden publicar",
                    showAuthor: "Mostrar información sobre el autor",
                    showPreview: "Mostrar vista previa del texto",
                    showComments: "Mostrar cantidad de comentarios",
                    showMembers: "Mostrar avatares de los miembros",
                    showTimestamp:
                      "Mostrar marca temporal",
                    showThumbnail: "Mostrar miniatura",
                    autoThumbnail: "Creación automática de miniatura",
                  },
                },
                accordion3: {
                  label: "Formato de nueva publicación",
                  description:
                    "Selecciona el formato de nuevas publicaciones en este espacio",
                  templateName: {
                    label: "Nombra tu plantilla",
                    placeholder: "Nombre de la plantilla",
                  },
                  templateDirectOption: "Subespacio directo",
                  templateLinkedOption: "Espacio vinculado",
                  privacyDescription:
                    "Selecciona la configuración de privacidad predeterminada para nuevas publicaciones",
                  privacyOpenOption: "Abierta",
                  privacyPrivateOption: "Privada",
                  privacyAnyOption: "Elección del creador",
                },
                accordion4: {
                  label: "Texto del botón",
                  createPost: {
                    label: "Crear publicación",
                    description:
                      "Especifica el texto que aparecerá en el botón de nueva publicación",
                  },
                },
                accordion5: {
                  label: "Etiquetas",
                },
                templateDescription:
                  "Los nuevos espacios plantilla pueden ser subespacios directos de este espacio o pueden ser espacios vinculados. Los espacios vinculados son espacios superiores independientes de los espacios raíz y aparecerán en esta página. El creador del espacio se convertirá en administrador completo del espacio que cree.",
              },
              thumbnail: {
                title: "Añadir MINIATURA",
                message: "La imagen de la miniatura se está cargando...",
              },
              static: {
                broadcastExclude: {
                  unsubscribe: {
                    part1:
                      "¿Estás seguro de que quieres darte de baja de",
                    part2:
                      "Ya no recibirás correos electrónicos de envío masivo de parte de los administradores de este espacio",
                    button: "Darse de baja",
                  },
                  subscribe: {
                    part1:
                      "Te has dado de baja y ya no recibirás correos electrónicos de envio masivo de parte de",
                    button: "Suscribirse",
                  },
                },
                invitation: {
                  message: "Has sido invitado/a a este espacio",
                  button: "Aceptar la invitación",
                },
                pending: {
                  message: "Tu solicitud está pendiente",
                },
                private: {
                  message: {
                    part1: "Este es un espacio privado.",
                    part2: "Si eres miembro, por favor, inicia sesión.",
                  },
                  button: "Iniciar sesión",
                },
                processing: {
                  message: "Este espacio aún se está procesando...",
                },
              },
              gridPost: {
                onDelete: {
                  alertTitle: "¿Estás seguro/a?",
                  alertMessage: "Este espacio ya no estará vinculado aquí.",
                  alertMessage2: "Este espacio ya no estará vinculado aquí.",
                  confirm: "Sí",
                  deny: "No",
                },
                actions: {
                  pin: "Fijar en la parte superior",
                  unpin: "Desfijar",
                  addThumbnail: "Añadir miniatura",
                  removeThumbnail: "Eliminar miniatura",
                  duplicate: "Duplicar",
                  unlink: "Desvincular",
                  delete: "Eliminar",
                },
              },
              listContent: {
                publishTootip: "Se publicará en esta fecha",
                privateTooltip: "Este es un espacio privado",
              },
            },
            colors: {
              form: {
                colorTheme: "Tema de color",
                customize: "Personalizar la selección de colores",
                saveTheme: "Guardar tema",
                resetColor: "Restablecer color",
                pageLayout: "Diseño de página",
                pageFormat: "Formato de página",
              },
              headerAndLogo: {
                addCover: "Añadir imagen de portada",
                removeCover: "Eliminar imagen de portada",
                changeLogo: "Cambiar logotipo",
                removeLogo: "Eliminar logotipo",
                dimensions:
                  "La dimensión recomendada para imágenes de portada es de 1200 x 300 píxeles",
                dimensions2:
                  "La dimensión recomendada para logotipos es de 360 x 120 píxeles",
              },
              pageFormat: {
                description:
                  "Puedes cambiar la forma en que se muestra el contenido en este espacio seleccionando una opción abajo.",
                contentLabel: "Contenido",
                discussionLabel: "Discusión",
                contentDescription:
                  "Un espacio para módulos de aprendizaje, contenido general o recursos de la comunidad.",
                discussionDescription: "Un espacio para discusión o anuncios",
                eventsLabel: "Calendario",
                eventsDescription: "Crear una lista de eventos en el calendario",
                listLabel: "Foro",
                listDescription:
                  "Crea un feed donde las personas puedan publicar temas de discusión",
              },
              pageLayout: {
                widePage: "Aumentar el ancho de la página",
                hideSidebar: "Ocultar barra lateral",
                hideHeader: "Ocular cabecera",
                hideCoverImage: "Ocultar imagen de portada",
                hidePageTitle: "Ocultar título de página",
                hideBreadcrumbs: "Ocultar breadcrumbs",
              },
              header_color: {
                label: "Color de cabecera",
                description: "Descripción",
              },
              header_link_color: {
                label: "Color del logotipo de cabecera",
                description: "Descripción",
              },
              primary_button_background_color: {
                label: "Color de fondo del botón primario",
                description: "Descripción",
              },
              primary_button_text_color: {
                label: "Color de texto del botón primario",
                description: "Descripción",
              },
              secondary_button_background_color: {
                label: "Color de fondo del botón secundario",
                description: "Descripción",
              },
              secondary_button_text_color: {
                label: "Color de texto del botón secundario",
                description: "Descripción",
              },
              background_color: {
                label: "Color de fondo",
                description: "Descripción",
              },
              default_text_color: {
                label: "Color de texto predeterminado",
                description: "Descripción",
              },
              sidebar_background_color: {
                label: "Color de fondo de la barra lateral",
                description: "Descripción",
              },
              sidebar_text_color: {
                label: "Color de texto de la barra lateral",
                description: "Descripción",
              },
              default_link_color: {
                label: "Color de enlace predeterminado",
                description: "Descripción",
              },
              default_badge_color: {
                label: "Color de ícono de notificación",
                description: "Descripción",
              },
              default_badge_text_color: {
                label: "Color de texto del ícono de notificación",
                description: "Descripción",
              },
              registration_page_background_color: {
                label: "Color de fondo de la página de registro",
                description: "Descripción",
              },
              registration_page_default_text_color: {
                label: "Color de texto de la página de registro",
                description: "Descripción",
              },
            },
            create: {
              invalidNameErrorMsg:
                "El nombre del espacio debe tener entre 3 y 100 caracteres",
            },
            dummy: {
              magicLink: {
                alertTitle: "Casi estás dentro...",
                alertMessage:
                  "Hemos enviado el enlace mágico a tu correo electrónico. ESTO ES UNA PRUEBA, NO SE HA ENVIADO NINGÚN EMAIL REAL.",
              },
            },
            pending: {
              message: "Tu membresía está pendiente",
            },
            recover: {
              alertTitle: "Casi estás dentro...",
              alertMessage:
                "Hemos enviado el enlace de acceso directo a tu email. ESTO ES UNA PRUEBA, NO SE HA ENVIADO NINGÚN EMAIL REAL.",
            },
            form: {
              calendarLinks: {
                text: "La información que se añada a continuación se transferirá al calendario del miembro cuando se agregue el evento.",
                link: {
                  label: "URL del evento (enlace de Zoom, etc.)",
                  placeholder: "¿Cuál es el enlace de tu videoconferencia?",
                },
                description: {
                  label:
                    "Añade una descripción para tu evento que aparecerá en la información del calendario",
                  placeholder: "Descripción del calendario",
                },
                eventStartDateAndTime: "Fecha y hora de inicio del evento",
                date: {
                  placeholder: "Seleccionar fecha",
                },
                eventDateTime:
                  "Fecha y hora de finalización del evento (opcional)",
                allowInterests:
                  "Permitir que las personas participen al evento sin registrarse en Sutra",
                automatedEmails: "Configurar correos electrónicos automáticos",
                save: "Guardar detalles",
              },
              duplicate: {
                prefix: "Duplicado de",
                intention: {
                  label: "Nombre del espacio",
                  placeholder: "¿Cómo se llama tu espacio?",
                },
                copyMembers: {
                  label: "Copiar miembros",
                },
                copyManagers: {
                  label: "Copiar administradores",
                },
                copyContent: {
                  label: "Copiar contenido",
                },
                copyPosts: {
                  label: "Copy posts",
                },
                copyBroadcasts: {
                  label: "Copiar envíos masivos",
                },
                copyAutomation: {
                  label: "Copiar automatizaciones",
                },
                copyRegPage: {
                  label: "Copiar página de registro",
                },
                save: "Duplicar espacio",
                saveTemplate: "Crear plantilla",
              },
              form: {
                privacyDescription1:
                  "El espacio que crees será accesible a todos dentro de",
                rootPrivacyDescription1:
                  "El espacio que crees será accesible a cualquier persona que tenga un enlace a él. Podrás configurar las opciones de pago una vez que el espacio haya sido creado.",
                privacyDescription2:
                  "El espacio que crees solo será visible a las personas agregadas.",
                rootPrivacyDescription2:
                  "El espacio que crees será visible solo a las personas invitadas.Podrás configurar las opciones de pago una vez que el espacio haya sido creado.",
                versions: {
                  content: {
                    label: "Contenido",
                    description:
                      "Crea un espacio para un módulo de aprendizaje, contenido general o recursos comunitarios.",
                  },
                  discussion: {
                    label: "Discusión",
                    description:
                      "Crea un espacio de chat para discusiones o anuncios.",
                  },
                  event: {
                    label: "Evento",
                    description: "Crea un único evento.",
                  },
                  events: {
                    label: "Calendario",
                    description: "Crea una lista de eventos en el calendario.",
                  },
                  list: {
                    label: "Foro",
                    description:
                      "Crea un feed donde las personas pueden publicar temas de discusión.",
                  },
                },
                segments: {
                  space: {
                    label: "Espacio",
                    description:
                      "Crea un espacio para tu curso o comunidad. Elige esta opción si quieres crear un grupo de formación, un curso a tu propio ritmo, una comunidad, o cualquier tipo de grupo que se reunirá periódicamente a lo largo del tiempo.",
                  },
                  event: {
                    label: "Evento",
                    description:
                      "Crea una página sencilla de evento. Elige esta opción si solo quieres organizar un único evento que puedas compartir públicamente.",
                  },
                  discussion: {
                    label: "Discusión",
                    description:
                      "Crea un espacio sencillo de conversación para tu grupo. Elige esta opción si solo quieres crear un único espacio de discusión para un grupo de personas.",
                  },
                },
                intention: {
                  label: "Nombre del espacio",
                  placeholder: "¿Cómo se llama tu espacio?",
                },
                content: {
                  label: "Nombre del espacio",
                  description: "Escribe algo...",
                },
                spaceFormat: {
                  label: "Formato del espacio",
                  placeholder: "Eligir",
                },
                eventDescription:
                  "La información añadida a continuación se sincronizará con el calendario del miembro al añadir el evento.",
                hangoutLink: {
                  label: "URL del evento (enlace de Zoom, etc.)",
                  placeholder: "¿Cuál es el enlace de tu videoconferencia?",
                },
                eventStartTime: "Fecha y hora de inicio del evento",
                date: {
                  placeholder: "Seleccionar fecha",
                },
                eventEndDate:
                  "Fecha y hora de finalización del evento (opcional)",
                privacyLevelText: "Configuración de privacidad",
                privacy: {
                  open: "Abierto",
                  private: "Privado",
                },
                joinAsMember: {
                  label: "Unirse a este espacio como miembro",
                },
                publishDate: {
                  label: "Publicar en fecha",
                },
                publishDateTime: "Fecha y hora de publicación",
                create: "Crear espacio",
              },
              interestedForm: {
                title: "Registrarse en este evento",
                createAlertTitle: "¡Gracias por registrarte!",
                createAlertButton: "Ok",
                name: {
                  label: "Nombre",
                  placeholder: "Escribe tu nombre",
                },
                email: {
                  label: "Correo electrónico",
                  placeholder: "Escribe tu correo electrónico",
                },
                register: "Registrarse",
                joinWithSutra: "Entrar con mi cuenta de Sutra",
              },
              linkForm: {
                linkErrMessage: "No es un enlace válido al espacio",
                link: {
                  label: "",
                  placeholder: "Enlace al espacio",
                  errors: {
                    space:
                      "¡El espacio que quieres compartir es privado o no existe!",
                    event:
                      "¡El espacio que quieres compartir es privado, no es un evento o no existe!",
                  },
                },
              },
              listForm: {
                intention: {
                  placeholder: "Título",
                },
              },
            },
            header: {
              onLeave: {
                title: "¿Estás seguro/a?",
                message:
                  "Serás eliminado de la lista de miembros de este espacio y podrías necesitar ser añadido de nuevo para recuperar el acceso.",
                confirm: "Sí, por favor, quiero ser eliminado",
                deny: "No",
              },
              onArchive: {
                title: "¿Estás seguro/a?",
                message:
                  "Este espacio se archivará y los miembros no tendrán más acceso a él.",
                confirm: "Sí",
                deny: "No",
              },
              onUnarchive: {
                title: "¿Estás seguro/a?",
                message:
                  "Este espacio se desarchivará y los miembros tendrán de nuevo acceso a él.",
                confirm: "Sí",
                deny: "No",
              },
              onDelete: {
                title: "¿Estás seguro/a?",
                message:
                  "Este espacio se eliminará y los miembros no volverán a tener acceso.",
                confirm: "Sí",
                deny: "No",
              },
              onFix: {
                title: "¿Estás seguro/a?",
                message:
                  "Sutra intentará recrear este espacio de contenido utilizando la información de la versión anterior.",
                confirm: "Sí, arreglar",
                deny: "No",
                confirmationTitle: "Información",
                confirmationMessage: "Espacio arreglado, cargando de nuevo...",
                confirmationError: "Este espacio no se puede arreglar",
              },
              buttons: {
                uncomplete: "Marcar como no completado",
                markComplete: "Marcar como completado",
                edit: "Editar",
                post: "publicaciónr",
                page: "página",
                space: "espacio",
                returnTo: "Volver a",
                editTitle: "Editar título",
                viewPage: "Ver página",
                editDescription: "Editar descripción",
                appearance: "Apariencia",
                manage: "Administrar",
                members: "Miembros",
                leave: "Abandonar espacio",
                delete: "Eliminar espacio",
                duplicate: "Duplicar espacio",
                archive: "Archivar espacio",
                unarchive: "Desarchivar espacio",
                template: "Crear plantilla",
                fix: "Arreglar",
                joinEvent: "Registrarse",
                joinSpace: "Unirse al espacio",
                preview: "Vista previa",
                draft: "Borrador",
                published: "Publicado",
                publish: "Publicar",
                returnToSettings: "Volver a configuraciones",
                returnToSpace: "Volver al espacio",
                undo: "Deshacer",
                openFullScreen: "Abrir en pantalla completa",
                copyToClipboard: "Copiar enlace",
                copiedMessage: "Enlace del espacio copiado al porta papeles",
                versionHistory: "Historico de versiones",
                brandingOptions: "OPCIONES DE MARCA",
              },
              regPagePublishing:
                "Tu página de registro se está publicando, por favor, espera...",
              spaceUpdating:
                "Este espacio se está actualizando, por favor espera...",
            },
            members: {
              sectionTitle: "Miembros",
              manage: "Administrar miembros",
              invite: "Invitar",
              search: {
                button: "Buscar",
                placeholder: "Buscar",
              },
              emptyMessage: "No se encontraron registros",
              messageButton: "Mensaje",
              emailButton: "Email",
              copyClipboard: "copiar al portapapeles",
            },
            payment: {
              onCheckIntent: {
                alertTitle: "Error de pago",
                alertMessage:
                  "Tu sesión ha caducado. Por favor, actualiza la página para completar el pago.",
              },
              onConfirmPayment: {
                alertTitle: "¡Vaya, algo ha salido mal!",
                alertMessage: "¡Algo ha salido mal!",
              },
              completePayment: "Completar el pago",
              join: "Unirse al espacio",
            },
            stripe: {
              pay: "Pagar",
            },
          },
          survey: {
            form: {
              text: "Ya has respondido a esta encuesta.",
              submitButton: "Enviar",
            },
            question: {
              yourAnswer: "Tu respuesta",
            },
          },
          tiptap: {
            blocks: {
              section1: "Básico",
              text: "Texto",
              headline1: "Título 1",
              headline2: "Título 2",
              headline3: "Título 3",
              divider: "Separador",
              infobox: "Caja de información",
              section2: "Espacios",
              content: "Espacio de contenido",
              discussion: "Espacio de discusión",
              event: "Espacio de evento",
              events: "Espacio de calendario",
              list: "Espacio de foro",
              showcase: "Showcase Space",
              section3: "Lista",
              section8: "Especial",
              bulletList: "Lista de viñetas",
              orderedList: "Lista ordenada",
              checklist: "Lista de verificación",
              collapsableSection: "Sección plegable",
              survey: "Survey or quiz",
              section4: "Multimedia",
              image: "Imagen",
              embed: "Código de incrustación",
              video: "Video",
              audio: "Audio",
              file: "Archivo",
              zoom: "Zoom recording",
              section5: "Cuadrículas",
              grid1: "1 columna",
              grid2: "2 columnas",
              grid3: "3 columnas",
              grid4: "Columna izquierda",
              grid5: "Columna derecha",
              grid6: "4 columnas",
              section6: "Botones",
              interestForm: "Formulario de interés",
              actionButton: "Botón de acción",
              markComplete: "Marcar como completado",
              section7: "Interactivo",
              interactiveButton: "Botón interactivo",
              interactiveInput: "Entrada interactiva",
              interactiveWait: "Espera interactiva"
            },
            components: {
              sidebar: {
                verticalAllignment: {
                  top: "Superior",
                  bottom: "Inferior",
                  center: "Centrar",
                },
                alignment: {
                  left: "Izquierda",
                  center: "Centro",
                  right: "Derecha",
                },
                backgroundColor: {
                  text: "Seleccionar color de fondo",
                  clearColor: "Eliminar color",
                },
                backgroundImage: {
                  button: "Seleccionar imagen",
                  removeButton: "Eliminar imagen",
                  label: "Posición",
                  placeholder: "Elegir",
                  leftTop: "izquierda superior",
                  leftCenter: "centro izquierda",
                  leftBottom: "izquierda inferior",
                  rightTop: "derecha superior",
                  rightCenter: "centro derecha",
                  rightBottom: "derecha inferior",
                  centerTop: "centro superior",
                  centerCenter: "centro centro",
                  centerBottom: "centro inferior",
                  label2: "Repetición",
                  placeholder2: "Elegir",
                  noRepeat: "No Repetir",
                  repeat: "Repetir",
                  "repeat-x": "Repetir en eje X",
                  "repeat-y": "Repetir en eje Y",
                  space: "Espacio",
                  round: "Redondo",
                },
                blockId: {
                  placeholder: "Identificador del bloque",
                  label: "Identificador del bloque",
                  description:
                    "Los identificadores recomendados para IA: breve descripción, acerca del taller, lo que aprenderás 1, lo que aprenderás 2, lo que aprenderás 3, para quién es esto, sobre nosotros, cita",
                },
                buttonAction: {
                  label: "Acción al completar",
                  description:
                    "Especifica lo que sucede después de que una persona envía su información a través del formulario seleccionado.",
                  placeholder: "Elegir",
                  label2: "Enlace a",
                  placeholder2: "Elegir",
                  label3: "Acción del enlace",
                  description3:
                    "Especifica si el enlace se abrirá en la pestaña actual o en otra pestaña.",
                  placeholder3: "Elegir",
                  callBackComplete: "Texto que aparecerá al completar la acción",
                  callBacklinkTo: "Enlace a",
                  completeDescription:
                    "Añade un breve mensaje de confirmación que las personas verán después de enviar su información.",
                  linkDescription:
                    "La persona será redirigida a este enlace después de hacer clic en el botón.",
                  label4: "Enlace a la sección de espacio",
                  description4: "Especifique si el enlace se abrirá en la pestaña actual del navegador o en otra pestaña o si el enlace saltará a una sección de esta página.",
                  selectSection: "Seleccionar sección",
                  description5: "Esta opción le permite saltar a una sección específica de esta página cuando se hace clic en el enlace.",
                  description6: "Para seleccionar la sección, haga clic en el botón a continuación y luego haga clic en la sección a la que desea vincularse.",
                  label5: "¡La sección está vinculada!",
                  label6: "URL del enlace",
                  description7: "Especifique el enlace URL al que se dirigirá al usuario.",
                  label7: "Se abre en la pestaña actual",
                  label8: "Abre otra pestaña",
                  label9: "Hacer nada",
                  label10: "Redirigir al usuario a",
                  label11: "Seleccione una opción",
                  label12: "Otro espacio",
                  label13: "Enlace personalizado",
                  label14: "Haga clic en una sección de la página",
                },
                buttonBackgroundColor: {
                  button: "Limpiar color",
                },
                buttonText: {
                  placeholder: "Texto del botón",
                  label: "Texto del botón",
                },
                fileName: {
                  placeholder: "Nombre de archivo",
                  label: "Nombre de archivo",
                },
                interactiveWaitTime: {
                  label1: "Selecciona una opción",
                  label2: "Esperar hasta una fecha",
                  label3: "Esperar una cantidad de días",
                  label4: "Fecha de la muestra de contenido",
                  placeholder: "Fecha de la muestra de contenido",
                  label5: "1 día",
                  label6: "2 días",
                  label7: "3 días",
                  label8: "4 días",
                  label9: "5 días",
                  label10: "6 días",
                  label11: "1 semana",
                  label12: "2 semanas",
                  label13: "1 mes",
                },
                linkAction: {
                  label: "Acción del enlace",
                  description:
                    "Especificar si el enlace se abre en la misma  pestaña o en una pestaña nueva.",
                  placeholder: "Elegir",
                  label2: "Se abre en la misma pestaña",
                  label3: "Se abre en una pestaña nueva",
                },
                margin: {
                  topMargin: "Margen superior -",
                  text: "Margen inferior -",
                  bottom: "Inferior",
                  left: "Izquierda",
                  leftMargin: "Margen izquierdo -",
                  right: "Derecha",
                  rightMargin: "Margen derecho",
                },
                padding: {
                  top: "Superior",
                  topPadding: "Relleno superior",
                  bottom: "Inferior",
                  bottomPadding: "Relleno inferior",
                  left: "Izquierda",
                  leftPadding: "Relleno izquierdo",
                  right: "Derecha",
                  rightPadding: "Relleno derecho",
                },
                radius: {
                  square: "Cuadrado",
                  round: "Redondeado",
                  circle: "Circular",
                },
                spaceAttrs: {
                  addCover: "Añadir imagen de portada",
                  text: "Las dimensiones recomendadas para las imágenes de portada son 1200 x 300 píxeles",
                  addCover2: "Añadir portada",
                  text2:
                    "Las dimensiones recomendadas para las imágenes de portada son 1200 x 300 píxeles",
                  removeCover: "Eliminar portada",
                },
                verticalAllignent: {
                  top: "Superior",
                  center: "Centro",
                  bottom: "Inferior",
                },
                width: {
                  full: "Completo",
                  size: "3/4",
                  size2: "1/2",
                  actual: "Actual",
                },
              },
              blockMenuActions: {
                label1: "Añadir bloque",
                label2: "Añadir emoji",
                label3: "Configuraciones",
                label4: "Eliminar",
                label5: "Mover",
                label6: "Ir al espacio o hacer doble clic en el bloque",
                label7: "Sustituir",
                label8: "Alineación",
                left: "Izquierda",
                center: "Centrar",
                right: "Derecha",
                write: "Escribir algo...",
                label9: "Añadir bloque",
                label10: "Encabezado 1",
                h1: "H1",
                label11: "Encabezado 2",
                h2: "H2",
                label12: "Añadir espacio",
                label13: "Añadir imagen",
                label14: "Añadir video",
                label15: "Añadir cuadrícula",
                label16: "Añadir plantilla",
                label17: "Añadir bloque",
                label18: "Duplicado",
              },
              blocksMenu: {
                basicBlock: "Bloques básicos",
                mediaBlock: "Bloques Medios",
                contentBlock: "Bloques de contenido",
              },
              blocksMenuInline: {
                file: "Archivo",
                image: "Imagen",
                video: "Video",
                label1: "Añadir emoji",
                label2: "Añadir imagen",
                label3: " Desplegar/Plegar la barra de formato",
              },
              blocksMenuSimple: {
                label1: "Añadir",
                file: "Archivo",
                image: "Imagen",
                video: "Video",
                label2: "Añadir emoji",
                label3: "Añadir imagen",
                label4: "Mostrar formato",
                cancelButton: "Cancelar",
              },
              emptyMessage: {
                text: "Este espacio de contenido está vacío. Puedes",
                button1: "editar esta página",
                text2: "o",
                button2: "utilizar una plantilla",
                text3: "para empezar",
                text4: "Un espacio vacío lleno de potencial...",
              },
              fixedBottomMenu: {
                label1: "Añadir elementos como encabezados, imágenes y videos",
                addBlock: "Añadir bloque",
                label2: "Añadir un espacio de contenido, discusión o evento",
                space: "Espacio",
                label3:
                  "Añadir una cuadrícula para utilizar múltiples columnas para el contenido",
                grid: "Red",
                label4: "Añadir una opción de diseño precreada",
                layouts: "Diseños",
                label5:
                  "Añadir una plantilla para configurar tu espacio automáticamente",
                template: "Plantillas",
              },
              formatMenu: {
                text: "Texto",
                smallText: "Texto pequeño",
                largeText: "Texto grande",
                heading1: "Encabezado 1",
                heading2: "Encabezado 2",
                heading3: "Encabezado 3",
                bulletedList: "Lista de viñetas",
                orderedList: "Lista ordenada",
                text2: "Texto",
                label1: "Formato",
                text3: "Texto",
                numberedList: "Lista numerada",
                label2: "Alineación",
                left: "Izquierda",
                center: "Centro",
                right: "Derecha",
                label3: "Acción del enlace",
                description:
                  "Especificar si el enlace se abre en la misma  pestaña o en una pestaña nueva.",
                placeholder: "Elegir",
                label4: "Se abre en la misma pestaña ",
                label5: "Se abre en una pestaña nueva",
                label6: "Formato del enlace",
                description2:
                  "Especifica si el enlace se mostrará como un texto plano o como un enlace destacado.",
                placeholder2: "Elegir",
                label7: "Mostrar como texto",
                label8: "Mostrar como enlace destacado",
                save: "Guardar",
                delete: "Eliminar",
                label9: "Tachado",
                label10: "Subrayado",
                label11: "Bloque de código",
                label12: "Color de texto",
                placeholder3: "Valor HEX",
                unsetColor: "Quitar color",
                label13: "Color de resaltado",
                label14: "Enlace a la sección de espacio",
                description3: "Especifique si el enlace se abrirá en la pestaña actual del navegador o en otra pestaña o si el enlace saltará a una sección de esta página.",
                selectSection: "Seleccionar sección",
                description4: "Esta opción le permite saltar a una sección específica de esta página cuando se hace clic en el enlace.",
                description5: "Para seleccionar la sección, haga clic en el botón a continuación y luego haga clic en la sección a la que desea vincularse.",
                label15: "¡La sección está vinculada!",
                label16: "URL del enlace",
                description6: "Especifique el enlace URL al que se dirigirá al usuario.",
              },
              formatMenuSimple: {
                t: "T",
                h1: "H1",
                h2: "H2",
                h3: "H3",
                label1: "Acción del enlace",
                description1:
                  "Especificar si el enlace se abre en la misma  pestaña o en una pestaña nueva.",
                placeholder1: "Elegir",
                label2: "Se abre en la misma pestaña",
                label3: "Se abre en una pestaña nueva",
                label4: "Formato del enlace",
                description2:
                  "Especifica si el enlace se mostrará como un texto plano o como un enlace destacado",
                placeholder2: "Elegir",
                label5: "Mostrar como texto",
                label6: "Mostrar como enlace destacado",
                save: "Guardar",
                delete: "Eliminar",
                label7: "Formato",
                left: "Izquierda",
                center: "Centro",
                right: "Derecha",
                placeholder3: "Valor HEX",
                placeholder4: "Color HEX",
                unsetColor: "Quitar color",
              },
              inlineMenu: {
                h1: "H1",
                h2: "H2",
                bulletList: "Lista de viñetas",
                checkList: "Checklist",
                circle: "Círculo",
                moreOption: "Más opciones",
              },
              interactiveInput: {
                hideResponses: "Ocultar otras respuestas",
                showResponses: "Mostrar otras respuestas",
                hideResponses2: "Ocultar respuestas",
                hidePrivateMessages: "Ocultar mensajes privados",
                showPrivateMessages: "Mostrar mensajes privados",
                showResponses2: "Mostrar respuestas",
                loadMoreResponse: "Cargar más respuestas",
                placeholder: "Escribe algo...",
                text: "¿Quieres que esta respuesta sea visible para otros miembros?",
              },
              newMedia: {
                add: "Añadir",
              },
              regPageColor: {
                clearColor: "Limpiar color",
              },
              regPageColors: {
                text: "Apariencia de la página de registro",
                applyAppearance: "Aplicar apariencia",
                title1: "Seleccionar color de fondo",
                title2: "Seleccionar color de texto",
                label1: "Color de fondo",
                label2: "Color de texto predeterminado",
                label3: "Aumentar el ancho de la página",
              },
              resource: {
                areYouSure: "¿Estás seguro/a?",
                label1: "Se publicará en esta fecha",
                edit: "Editar",
                addPage: "Añadir página",
              },
              sideBlocksMenuBak: {
                addBlock: "Añadir bloque",
                back: "Volver",
              },
              sideBlocksMenu: {
                text: "Los diseños son opciones de diseño ya listas para añadir a tu espacio.",
                text2:
                  "Empieza por una selección de plantillas personalizables de páginas completas",
                layout: "Diseños",
                template: "Plantillas",
                addBlock: "AÑADIR BLOQUE",
                block: "Bloques",
              },
              sideConfigurationsMenu: {
                title: "CONFIGURACIONES DEL BLOQUE",
                label1: "Nombre del espacio e imagen de portada",
                label2: "Atributos del archivo",
                label3: "Opciones de entrada interactiva",
                attrPlaceholder1: "Escribe algo...",
                attrLabel: "Marcador de posición",
                attrLabel2:
                  "Permitir que los miembros vean todas las respuestas",
                attrLabel3:
                  "Mostrar las respuestas de otros antes de enviar",
                attrLabel4:
                  "Permitir que los miembros elijan si su respuesta será visible a los demás",
                attrLabel5: "Permitir múltiples respuestas por persona",
                attrLabel6:
                  "Permitir que el participante omita esta solicitud",
                attrLabel7:
                  "Notificar a los administradores sobre nuevas publicaciones",
                label4: "Opciones de entrada interactiva",
                label5: "Relleno",
                label6: "BLOQUE",
                label7: "COLUMNA",
                label8: "Margen",
                label9: "CUADRÍCULA",
                label10: "Alineación vertical",
                label11: "Opciones del botón",
                label12: "Color de fondo del botón",
                label13: "Color de texto del botón",
                text: "Esquinas del botón",
                label14: "Fondo",
                label15: "Color",
                label16: "Imagen",
                lael17: "Restablecer",
                resetBlock: "Restablecer bloque",
                label18: "Alineación",
                label19: "Radio del borde",
                label20: "esquinas",
                label21: "Nombre del bloque",
                label22: "Opciones de notificación por correo electrónico",
                label23: "Enviar correo electrónico de notificación",
                attrLabel8: "Incluir mensaje personalizado",
                attrLabel9: "Incluir el contenido de la siguiente sección",
                attrLabel10: "Incluir contenido completo",
                attrLabel11: "Vista previa breve",
                attrLabel12: "Sujeta",
                attrLabel13: "Mensaje",
                defaultWaitLabel: "La siguiente sección ha sido lanzada en {{space_name}}",
                defaultWaitMessage: "Hola {{first_name}}. Solo quería informarte que se lanzó la siguiente sección en {{space_name}}. {{space_link_button}}"
              },
              takeSurvey: {
                text: "Esta encuesta está vacía",
              },
              tiptapAi: {
                placeholder: "Escribe una breve descripción",
                label:
                  "Por favor, escribe 2 o 3 oraciones para describir tu oferta.",
                placeholder2: "por ejemplo, coaches, consultores y otros profesionales de terapias holísticas.",
                label2: "¿Para quién es esta oferta?",
                text: "Hola! Estamos experimentando con una nueva función de IA. ¿Quieres que Sutra genere automáticamente el texto de tu plantilla utilizando IA?",
              },
              uploader: {
                label1: "Enlace",
                label2: "Código",
                placeholder: "Pega el enlace de la imagen o del archivo",
                label3: "Incrustar enlace",
                placeholder2:
                  "Pega el código incrustado -> <iframe>...</iframe>",
                label4: "Incrustar código",
                saveEmbed: "Guardar",
                placeholder3: "Buscar imágenes",
                saveImage: "Guardar imagen",
              },
              viewPage: {
                text: "Esta página está vacía",
              },
            },
          },
          users: {
            payment: {
              completePayment: "Completar el pago",
              join: "Unirse al espacio",
              pay: "Pagar",
            },
            stepper: {
              step1: "Paso 1",
              step1Description: "Inicia sesión",
              step2: "Paso 2",
              step2Description: "Completa el pago",
              step3: "Paso 3",
              step3Description: "Obtén el acceso",
            },
            start: {
              title: "Bienvenido/a",
              subtitle:
                "Por favor, introduce tu correo electrónico abajo.",
              email: "Correo electrónico",
              submit: "Continuar",
              invalidEmailErrorMsg: "Correo electrónico no válido",
            },
            signIn: {
              title: "Iniciar sesión con tu cuenta",
              subtitle: "¿No tienes una cuenta?",
              subtitleLink: "Registrarse",
              email: "Correo electrónico",
              password: "Contraseña",
              submit: "Iniciar sesión",
              magicLink: "Obtener un enlace de acceso directo",
              forgotPassword: "¿Has olvidado la contraseña?",
              invalidEmailErrorMsg: "Correo electrónico no válido",
            },
            signUp: {
              invalidEmailErrorMsg: "Correo electrónico no válido",
              invalidPasswordErrorMsg:
                "La contraseña debe tener al menos 8 caracteres",
              invalidFirstNameErrorMsg:
                "El nombre debe tener al menos 3 caracteres",
              title: "Crea una nueva cuenta",
              subtitle: "¿Ya tienes una cuenta?",
              subtitleLink: "Iniciar sesión",
              firstName: "Nombre",
              lastName: "Apellido",
              username: "Nombre de usuario",
              email: "Correo electrónico",
              password: "Contraseña",
              emailNewsletterConfirmation:
                "Suscríbete a la newsletter de Sutra para enterarte de nuestros seminarios web gratuitos sobre procesos grupales, enseñanzas transformadoras y negocios basados en relaciones.",
              submit: "Suscribirse",
              legalPrefix: "Suscribiendote a Sutra aceptas nuestros",
              terms: "Términos de servicio",
              privacy: "Política de privacidad",
            },
            spaceReference: {
              total: "Total de hoy",
            },
            planReference: {
              total: "Total",
              total_today: "Total de hoy",
              due_in: "Próximo pago en 14 días",
              paymentDetails: {
                annual: "Suscripción anual de",
                month: "Suscripción mensual de",
              },
            },
            resetPassword: {
              title: "Restablece tu contraseña",
              subtitle:
                "Por favor, introduce una contraseña nueva para tu account de Sutra abajo.",
              password: "Contraseña",
              passwordConfirmation: "Confirmar contraseña",
              submit: "Restablecer contraseña",
            },
            recover: {
              title: "¿Has olvidado tu contraseña?",
              subtitle:
                "Por favor, introduce tu dirección de correo electrónico y te enviaremos las instrucciones sobre como restablecer tu contraseña.",
              email: "Email",
              submit: "Envíame instrucciones ",
              footer: "¿Ya tienes una cuenta?",
              footerLink: "Iniciar sesión",
            },
            magicLink: {
              invalidEmailErrorMsg: "Correo electrónico no válido",
              title: "Solicitar enlace de acceso directo",
              subtitle:
                "Por favor, introduce tu correo electrónico y te enviaremos un enlace de acceso directo que te permitirá iniciar sesión automáticamente.",
              email: "Email",
              submit: "Continuar",
              footer: "Iniciar sesión con tu email",
              alertTitle: "Ya casi...",
              alertMessage:
                "Hemos enviado un enlace de acceso directo a tu email. ESTO ES UNA PRUEBA, NO SE HA ENVIADO NINGÚN EMAIL REAL.",
            },
            edit: {
              title: "Por favor, completa tu perfil",
              addProfileImage: "Subir foto del perfil",
              removeProfileImage: "Eliminar foto del perfil",
              bioPlaceholder: "Comparte un poco sobre ti...",
              bio: "Presentación",
              submit: "Guardar",
              skip: "Omitir y hacerlo después ",
            },
            magicSignin: {
              text: "Iniciando sesión...",
            },
            profile: {
              button: "Mensaje",
              button2: "Editar perfil",
              title: "Espacios",
            },
            profileModal: {
              profile: "Perfil",
              message: "Mensaje",
              editProfile: "Editar perfil",
              openProfile: "Visualizar perfil",
              spaces: "Espacios",
            },
            signOut: {
              loggedOUt: "Sesión cerrada",
              title: "Has cerrado sesión",
              message: "¡Hasta luego!",
            },
          },
          errors: {
            basic: "¡Vaya, algo ha salido mal!",
          },
        },
      },
      ru: {
        translation: {
          premiumFeatures: {
            personal: {
              description1:
                "Неограниченное количество спейсов и участников для размещения контента и общения для всех ваших предложений.",
              description2:
                "Красивые страницы для регистрации и лендинги для всех ваших курсов и мероприятий на одной платформе.",
              description3:
                "Отправляйте рассылки всем вашим участникам с помощью нашей мощной функцией рассылки",
            },
            professional: {
              description1: "Отсутствие комиссий за проведение платежей",
              description2:
                "Настройка фирменного стиля и оформления, а также персонализированные поддомены.",
              description3:
                "Неограниченное количество автоматизаций и процессов для обучения через функцию авто-разговора.",
              description4: "До 2 админов для всех ваших спейсов",
            },
            organization: {
              description1: "Отсутствие комиссий за проведение платежей",
              description2: "Расширенная служба поддержки клиентов",
              description3: "Безлимитное количество админов",
              description4: "Возможность интегрировать с Single Sign-on",
            },
          },
          premiumMessages: {
            can_auto_tag: {
              title: "Upgrade to use this feature",
              message: "Auto tagging with AI is a premium feature available on the Professional plan."
            },
            can_use_zoom_recordings: {
              title: "Upgrade to use this feature",
              message: "Zoom recordings is a premium feature available on the Organization plan."
            },
            can_create_quizzes: {
              title: "Upgrade to use this feature",
              message: "Quizzes are a premium feature available on the Organization plan."
            },
            can_duplicate_spaces: {
              title: "Upgrade to use this feature",
              message:
                "Duplicating a space is a premium feature available on the Professional plan.",
            },
            can_save_templates: {
              title: "Upgrade to use this feature",
              message:
                "Saving as space as a reusable template is a premium feature available on the Professional plan.",
            },
            can_edit_shareable_link: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Персонализированные ссылки для общего доступа — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_upload_cover: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                " Загрузка персонализированной обложки  — это премиальная функция, доступная на тарифном плане Индивидуальный.",
            },

            can_upload_logo: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Настройки логотипа — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_set_layout: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Настройка оформления вашего спейса — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_set_theme: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Настройка цветовой схемы вашего спейса — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_set_format: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Изменение формата вашего спейса  — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_add_step_send_to_pending: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Присвоение новым участникам статуса ожидающих - это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_add_step_send_email: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Автоматическая Email-рассылка — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_add_add_to_existing_space: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Автоматическое добавление участников в выбранные спейсы — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_add_step_wait: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Добавление условий ожидания для дополнительных шагов   — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_add_step_webhook: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Добавление вебхуков — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_add_step_survey_person: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Опрос новых участников   — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_schedule_states: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Опции автоматического планирования вашего спейса  — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_broadcast: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Отправка Email-рассылок — это премиальная функция, доступная на тарифном плане Индивидуальный.",
            },

            can_survey: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Создание опросов - это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_use_interest_form_block: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                " Добавление форм сбора заявок  — это премиальная функция, доступная на тарифном плане Профессионал.",
            },
            can_use_interactive_block: {
              title: "Обновите, чтобы использовать эту функцию",
              message:
                "Добавление интерактивных блоков — это премиальная функция, доступной на тарифном плане Профессионал.",
            },

            can_set_flexible_payments: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Настройка гибких опций оплаты — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_activate_completions: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Возможность отмечать спейсы пройденными — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            max_allowed_spaces: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Привет! Похоже, что вы использовали все свои бесплатные спейсы! Переход на план Индивидуальный даcт вам неограниченное количество спейсов и участников, чтобы вы могли создавать практически всё, что угодно.",
            },
            max_allowed_ij: {
              title: "Upgrade to use this feature",
              message:
                "You can only add up to 5 discussion prompts per space on the free plan. Please upgrade to the Professional plan to add unlimited discussion prompts to any space.",
            },

            max_allowed_managers_pro: {
              title: "Смените тариф, чтобы добавить дополнительных админов",
              message:
                "Перейдите на план Профессионал, чтобы добавить до двух админов для каждого спейса, или на план Организация, чтобы иметь доступ к неограниченному количеству админов.",
            },

            max_allowed_managers_org: {
              title: "Смените тариф, чтобы добавить дополнительных админов",
              message:
                "Перейдите на план Организация, чтобы добавить неограниченное количество админов.",
            },

            can_see_participation_tab: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Метрики участников — это премиальная функция, доступная на тарифном плане Профессионал.",
            },

            can_send_selective_broadcast: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Выборочная рассылка — это премиальная функция, доступная на тарифном плане Организация.",
            },

            can_send_message_broadcast: {
              title: "Смените тариф, чтобы использовать эту функцию",
              message:
                "Рассылка сообщений — это премиум-функция, доступная на тарифном плане Организация.",
            },
          },
          home: {
            sidebar: {
              createButton: "Новый спейс",
              spaces: "Ваши спейсы",
              invitations: "Приглашения",
              directMessages: "Личные сообщения",
              gettingStarted: "Начало работы",
              scheduleDemo: "Записаться на демонстрацию",
              upgrade: "Улучшите ваш тариф",
              promoBoxTitle:
                "Присоединяйтесь к сотням создателей, которые пользуются расширенными функциями Sutra для проведения мощных групповых программ онлайн.",
              promoBoxItem1: "Неограниченное количество спейсов",
              promoBoxItem2: "Полные возможности фирменного стиля",
              promoBoxItem3: "Дополнительные админы",
              promoBoxButton: "Улучшить тариф сейчас",
            },
            spacesList: {
              yourSpaces: "Ваши спейсы",
              directMessagesTitle: "Личные сообщения",
              invitationsTitle: "Приглашения",
              topTitle: "Верхние спейсы",
              managedTitle: "Управляемые спейсы",
              allTitle: "Все спейсы",
              archivedTitle: "Архивированные спейсы",
              deletedTitle: "Удалённые спейсы",
              pendingTitle: "Запланированные спейсы",
              directMessages: "Личные сообщения",
              top: "Верхние",
              managed: "Управляемые",
              all: "Все",
              archived: "Архивированные",
              invitations: "Приглашения",
              deleted: "Удалённые",
              pending: "Запланированные",
              empty: "У вас нет",
              tabTitle: "Sutra - Панель управления",
              learn: "Изучить основы",
              createFirst: "Создайте ваш первый спейс",
            },
          },
          app: {
            payment: {
              payment: {
                tabTitle: "Sutra - Форма оплаты",
                title: "Завершить оплату",
              },
              planOption: {
                frequencyDescription1: "за месяц",
                frequencyDescription2: "Цена за месяц при оформлении годовой подписки",
                currentPlan: "Текущий тариф",
                billedMonthly: "Ежемесячная подписка",
                billedAnnually: "Годовая подписка",
              },
              stripeForm: {
                due_in_trial: "Оплата через 14 дней",
                pay: "Оплатить",
              },
            },
            gettingStarted: {
              items: {
                setup: "Настроить спейс вашей программы",
                privacy: "Настройки доступа и оплаты",
                registration: "Создание страницы регистрации",
                invitations: "Приглашение участников",
                settings: "Как работают настройки спейса",
                interactive: "Интерактивное обучение",
                interest: "Формы сбора заявок",
                appearance: "Оформление и схема цвета",
                grids: "Настройки плитки и блока",
                broadcasts: "Email-рассылки",
                tagging: "Теги",
                emojis: "Эмодзи на боковой панели",
              },
              templates: {
                groupprogram: {
                  label: "Групповая программа",
                  description:
                    "Спейс для программы, где вы можете организовать всё необходимое для совместного обучения группы людей.",
                },
                zoomworkshop: {
                  label: "Zoom Семинар",
                  description:
                    "Организуйте всё, что нужно для проведения простого семинара с помощью Zoom.",
                },
                mastermind: {
                  label: "Мастермайнд",
                  description:
                    "Пригласите группу участников вместе вступить в процесс мастермайнда.",
                },
                selfpacedcourse: {
                  label: "Курс в собственном темпе",
                  description:
                    "Гибкий шаблон для создания курса, который можно проходить в собственном темпе.",
                },
                communityspace: {
                  label: "Спейс для сообщества",
                  description:
                    "Объединяйте людей в динамичном сообществе онлайн, которое будет расти вместе с вашим комьюнити.",
                },
                oneononecoaching: {
                  label: "Коучинг один на один",
                  description:
                    "Создайте отдельный спейс для каждого вашего клиента коучинга.",
                },
              },
              loading: {
                text: "Ваш шаблон загружается, пожалуйста, подождите...",
              },
              tabTitle: "Sutra - Начало работы",
              mainTitle: "Гайд по началу работы",
              selectTemplate: "Выберите этот шаблон",
              advanceFeatures: "Дополнительные возможности",
              welcome: "Добро пожаловать",
              description1:
                "Sutra позволяет вам легко организовать контент и общение для курсов, сообществ, коучинга один на один и для других вариантов использования. Ознакомьтесь с основами платформы в этом пятиминутном видео.",
              watchVideo: "Посмотреть видео",
              createFirstSpace: "Создайте ваш первый спейс",
              gettingStartedGuide: "Гайд по началу работы",
              description2:
                "Узнайте больше о том, как работает Sutra: начните с чек-листа запуска и более подробного гайда.",
              checkOutTheGuide: "Ознакомиться с гайдом",
              startWithATemplate: "Начните с шаблона",
              description3:
                "Узнайте больше о том, как работает Sutra: начните с чек-листа запуска и более подробного гайда.",
              learnAbout: "       Узнать подробнее о дополнительных возможностях",
              description4:
                "Sutra разработана таким образом, чтобы быть гибкой и мощной практически для любого варианта использования. На Sutra можно создавать уникальные взаимодействия онлайн, привлекать интерес к вашим различным предложениям, отправлять информационные рассылки и собирать коллективные знания.",
              learnmore: "Узнать подробнее",
            },
            premium: {
              try: " Попробовать Sutra",
              include: "Функции, включающие",
              upgrade: "Перейти на другой тариф сейчас",
            },
            pricing: {
              tabTitle: "Sutra - Тарифы",
              upgrade: "Смените ваш тариф",
              text: "Что бы вам ни понадобилось для вашего курса или сообщества, мы здесь, чтобы поддержать вашу деятельность. Обращайтесь к нам по любым вопросам, которые могут у вас возникнуть, по адресу support@sutra.co",
            },
            scheduleDemo: {
              tabTitle: "Sutra - Записаться на демонстрацию",
            },
            welcome: {
              tabTitle: "Sutra - Добро пожаловать",
              title: "Добро пожаловать на Sutra!",
              description1:
                "Мы рады, что вы здесь. Пожалуйста, найдите минутку, чтобы посмотреть видео ниже...",
              description2:
                "Хотите попробовать бесплатно один из наших платных тарифных планов?",
              description3:
                "Выберете один из тарифных планов, указанных ниже, чтобы начать 14-дневную бесплатную пробную версию. По окончании пробного периода вам автоматически будет выставлен счёт за выбранный вами тарифный план.",
              yes: "Да, показать тарифы",
              no: "Нет, спасибо",
            },
          },
          layout: {
            userButton: {
              home: "Главная",
              viewProfile: "Посмотреть профиль",
              settings: "Настройки",
              support: "Поддержка",
              language: "Язык",
              terms: "Условия и конфиденциальность",
              logout: "Выйти",
              copyright: "Sutra Spaces, LLC © 2022",
            },
            directMessages: {
              label: "Личные сообщения",
              actionButton: "Посмотреть все",
              empty: "У вас нет личных сообщений",
              directMessage: "Личные сообщения",
            },
            notifications: {
              label: "Уведомления",
              actionButton: "Очистить всё",
              empty: "У вас нет уведомлений",
              notification: "Уведомления",
            },
            headerContent: {
              registrationPage: "Страница регистрации",
              nonManagersHidden: "Шапка страницы скрыта для всех, кроме админов",
            },
            search: {
              reflection: "Ответ",
              comment: "Комментировать",
              allSpaces: "Все спейсы",
              withinTop: "Внутри верхнего спейса",
              withinSpace: "Внутри этого спейса",
              everything: "Всё",
              titlesOnly: "Только заголовки",
              tagsOnly: "Только теги",
              content: "Контент",
              discussion: "Чат",
              events: "Мероприятия",
              users: "Пользователи",
              placeholder: "Поиск...",
              nothingFound: "Ничего не найдено",
              search: "Поиск",
            },
            uploader: {
              pending: "В статусе ожидания",
              used: "Использовано",
              uploading: "Загрузка",
              finishing: "Завершение",
              processing: "Обработка",
              completed: "Завершено",
            },
          },
          resources: {
            pages: {
              form: {
                label: "Заголовок",
                placeholder: "О чем ваша страница?",
                create: "Создать страницу",
                save: "Сохранить страницу",
              },
            },
            form: {
              title: {
                label: "Заголовок",
                placeholder: "О чем ваша тема?",
              },
              publish: {
                label: "Опубликовать в определённую дату",
              },
              date: {
                label: "Выбрать дату",
              },
              update: "Обновить коллекцию страниц",
              create: "Создать коллекцию страниц",
            },
          },
          settings: {
            editProfile: {
              tabTitle: "Sutra - Редактировать профиль",
              editProfile: "Редактировать профиль",
              firstName: "Имя",
              lastName: "Фамилия",
              username: "Имя пользователя",
              email: "Email",
              removeProfileImage: "Удалить фото профиля",
              addProfileImage: "Загрузить фото профиля",
              about: "О себе",
              location: "Местонахождение",
              bio: "Биография",
              bioPlaceholder: "Расскажите немного о себе...",
              updateProfile: "Обновить профиль",
            },
            accountSettings: {
              accountSettings: "Настройки аккаунта",
              changePassword: "Изменить пароль",
              currentPassword: "Текущий пароль",
              newPassword: "Новый пароль",
              newPasswordConfirmation: "Подтвердите новый пароль",
              updatePassword: "Обновить пароль",
              advanced: "Дополнительно",
              deleteAccount: "Удалить аккаунт",
              warning: "Предупреждение",
              deleteAccountWarning:
                "Удаление вашего аккаунта необратимо. Данные вашего аккаунта  будут удалены навсегда из нашей системы.",
              closeYourAccount: "Удалить ваш аккаунт",
              invalidCurrentPasswordErrorMsg: "Поле не может быть пустым",
              invalidPasswordErrorMsg:
                "Пароль должен содержать не менее 8 символов",
              invalidPasswordConfirmationErrorMsg: "Пароли не совпадают",
              deleteAccountAlert: {
                title: "Важно",
                message:
                  "Удаление вашего аккаунта необратимо. Данные вашего аккаунта  будут удалены навсегда из нашей системы.",
                input:
                  "Пожалуйста, введите “удалить мой аккаунт”, чтобы подтвердить это действие.",
                confirmButton: "Удалить навсегда",
                cancelButton: "Отменить",
              },
              cancelSubscriptionAlert: {
                title: "Важно",
                message: "Пожалуйста, сначала отмените вашу подписку. ",
                confirmButton: "Перейти к тарифному плану",
                cancelButton: "Сделать это позже",
              },
              stripeAccount: {
                title: "Сбросить подключение к Stripe",
                alertTitle: "Вы уверены?",
                alertMessage:
                  "Если вы сбросите подключение к Stripe, ваши данные будут удалены с вашего аккаунта на Sutra, и вы сможете подключить новый аккаунт со Stripe.",
                buttonText: "Сбросить подключение",
                confirmButton: "Да",
                cancelButton: "Нет",
                confirmTitle: "Вы уверены?",
                confirmMessage:
                  "Ваши данные Stripe будут удалены с вашего аккаунта на Sutra",
              },
            },
            emailPreferences: {
              tabTitle: "Sutra - Настройки уведомлений",
              emailPreferences: "Настройки уведомлений",
              emailDigestPreferences: "Настройки новостных рассылок",
              emailDigestFrequency: "Частота новостных рассылок",
              emailDigestMessage:
                "Чтобы настроить параметры рассылок для каждого спейса, перейдите в раздел настроек рассылок. Вы можете полностью отказаться от подписки на спейс, покинув его, или же вы можете кликнуть на «Отключить уведомления» в настройках рассылок.",
              receiveWeeklyDigest:
                "Получайте еженедельные новостные рассылки о новых отмеченных элементах и обсуждениях",
              receiveAtMentions:
                "Получать уведомление, когда кто-то @упомянул вас",
            },
            subscriptions: {
              frquencyOptions: "Параметры частоты",
              otherActions: "Другие действия",
              subscriptions: "Особые предпочтения спейса",
              searchBySpaceName: "Поиск по названию спейса",
              search: "Поиск",
              clear: "Очистить",
              options: "Параметры",
              otherActions: "Другие действия",
              emptySubscriptions: "У вас нет подписок.",
              leaveSpaceConfirmationTitle:
                "Вы уверены, что хотите покинуть этот спейс?",
              leaveSpaceConfirmationHtml:
                "Вы больше не будете участником этого спейса и, возможно, не сможете присоединиться снова без согласия админа.",
              leaveSpaceButtonText: "Да, покинуть",
              leaveSpaceDenyButtonText: "Нет",
              leaveSpace: "Покинуть спейс",
              unsubscribe: "Отписаться",
              unsubscribed: "Отписан",
            },
            digestPreferences: {
              digestedLabel: "Сгруппировать все обновления в одну новостную рассылку",
              digestedDescription:
                "Новые сообщения из всех ваших спейсов будут сгруппированы в одной рассылке.",
              undigestedLabel: "Сгруппировать обновления по каждому спейсу",
              undigestedDescription:
                "Вы будете получать отдельные рассылки для каждого спейса, с которого будут поступать новые сообщения.",
            },
            billing: {
              statuses: {
                pending: "В статусе ожидания",
                processing: "Обработка",
                paid: "Оплачено",
              },
              paymentMethod: {
                currentPaymentMethod: "Текущий способ оплаты",
                noCurrentPaymentMethod: "Нет текущего способа оплаты",
                addNewPaymentMethod: "Добавить новый способ оплаты",
              },
              stripe: {
                updatePaymentMethod: "Обновить способ оплаты",
              },
              tabTitle: "Sutra - Тарифный план",
              updatePaymentMethodMayus: "ОБНОВИТЬ СПОСОБ ОПЛАТЫ",
              payments: "ПЛАТЕЖИ",
              pdf: "PDF",
              noPayments: "У вас нет истории платежей",
              billing: "Тарифный план",
              upgradePlan: "Сменить тариф",
              changePlan: "Сменить тариф",
              updatePaymentMethod: "Обновить способ оплаты",
              cancelSubscription: "Отменить подписку",
              cancelConfirmationTitle:
                "Вы уверены, что хотите отменить подписку?",
              cancelConfirmationHtml:
                "У вас больше не будет доступа к премиальным функциям вашего тарифного плана.",
              cancelConfirmButtonText: "Да, отменить",
              cancelDenyButtonText: "Нет",
              billingHistory: "История платежей",
              tableDate: "Дата",
              tableAmount: "Сумма",
              tableStatus: "Статус",
              tableInvoice: "Счет",
              planPrefix: "Вы сейчас подписаны на",
              planSuffix: "тарифный план",
              updatePayment: {
                success: {
                  title: "Успешно",
                  message: "Ваш способ оплаты был обновлен",
                },
                error: {
                  title: "Ой, что-то пошло не так!",
                },
              },
            },
            frequencyOptions: {
              mute: "Отключить уведомления",
              always: "На каждом сообщении",
              hour1: "Каждый час",
              hour2: "Каждые 2 часа",
              hour4: "Каждые 4 часа",
              hour8: "Каждые 8 часов",
              hour24: "Раз в день",
              hour168: "Раз в неделю",
              hour336: "Раз в две недели",
            },
            affiliates: {
              empty: "У вас отсутствует история партнерских выплат",
              history: {
                table: {
                  referralName: "Имя реферала",
                  startAt: "Начало",
                  endAt: "Окончание",
                  createdAt: "Создано",
                  percent: "Процент",
                  total: "Общая заработанная сумма",
                  status: "Статус",
                  value: "Стоимость",
                  revenue: "Доход",
                  payAt: "Дата выплаты",
                  status: "Статус",
                },
                statuses: {
                  null: "Вожидании",
                  pending: "Вожидании",
                  processing: "Вобработке",
                  paid: "Выплачено",
                },
                paymentsHistory: "История платежей",
              },
              tabTitle: "Sutra - Партнерская программа",
              title: "Партнерская программа",
              affiliateLink: "Это ваша партнерская ссылка",
              copyMessage: "Ссылка скопирована в буфер обмена",
              stripeTitle: "Stripe подключен к",
              stripeConnected:
                "Это ваш идентификатор аккаунта Stripe. Чтобы найти этот идентификатор в Stripe, пожалуйста, перейдите по ссылке для входа в Stripe и прокрутите вниз страницу настроек вашего аккаунта.",
              stripeDisconnect: "Отключить",
              description:
                "Чтобы получить оплату за партнерскую программу, пожалуйста, подключите ваш Stripe аккаунт, нажав на кнопку ниже.",
              description2:
                "Когда вы поделитесь этой ссылкой с другими, вы автоматически получите 40% дохода, полученного за первый год от тех, кто подпишется на платный план на Sutra.",
              connect: "Подключить Stripe",
              history: "История партнерских выплат",
              historyLabel: "Партнерская история",
            },
          },
          spaces: {
            settings: {
              layout: {
                navbarContent: {
                  mainSettings: "Общие настройки",
                  members: "Участники",
                  broadcast: "Рассылки",
                  surveys: "Опросы",
                  structures: "Структуры",
                  sutraAdmin: "Sutra Админ",
                  scheduled: "Запланированные",
                  archived: "Архивированные",
                  participation: "Метрики участников",
                },
                headerActions: {
                  shareableLink: "Ссылка для общего доступа",
                },
              },
              main: {
                title: "Общие настройки",
                main: {
                  invite: "Пригласить",
                  shareableLink: "Ссылка для общего доступа",
                  cancelLink: "Отменить",
                  saveLink: "Сохранить",
                  editButton: "Редактировать ссылку",
                  subdomain: "Ваш поддомен",
                  sutra_co: ".sutra.co",
                  label1: "Ссылка скопирована в буфер обмена",
                  privacySetting: "Выберите настройки доступа",
                  title1: "Сделать этот спейс открытым",
                  description1:
                    "Этот спейс находится в пределах закрытого спейса и доступен только участникам этого спейса. Вы можете сделать его открытым за пределами этого спейса, включив эту функцию.",
                  title2: "Показать страницу регистрации",
                  description2:
                    "Пользователи, не являющиеся участниками, увидят страницу регистрации при попытке получить доступ к этому спейсу, если эта функция включена.",
                  title3: "Использовать страницу регистрации с устаревшими данными",
                  description3:
                    "Если этот параметр включен, в этом спейсе будет использоваться страница регистрации, созданная с помощью устаревшей версии.",
                  onButton: "ON",
                  offButton: "OFF",
                  editButton: "Редактировать страницу",
                  previewButton: "Предварительный просмотр страницы",
                  label2: "Настройки доступа и оплаты",
                  label3: "Настройки страницы регистрации",
                  description4:
                    "Включено - Участники попадут на страницу регистрации перед доступом к этому спейсу",
                  label4: "Aвтоматизация",
                  description5:
                    "Запланируйте рассылки и автоматическое добавление участников в спейсы при их регистрации.",
                  label5: "Настройки планирования",
                  description6:
                    "Выберите дату мероприятия или дату публикации и опции архивирования",
                  label6: "Дополнительные настройки",
                  description7:
                    "Разрешения на создание контента, видимость участников, уведомления админа и исходные спейсы",
                  label7: "Настройки SEO",
                  description8:
                    "Создайте мета-тег title, мета-описание и изображение, для поисковой оптимизации, которые будут отображаться в общих публикациях в социальных сетях.",
                  label8: "Открытый",
                  description9:
                    "Этот спейс доступен всем",
                  label9: "Закрытый",
                  description10:
                    "Этот спейс закрытый - участники должны быть приглашены в него.",
                  label10: "Платный",
                  description11:
                    "Этот спейс платный и закрытый. Участники должны заплатить, чтобы присоединиться.",
                  description12:
                    "Участники будут попадать на страницу регистрации перед тем, как они смогут присоединиться к этому открытому спейсу.",
                  description13:
                    "Участники будут напрямую попадать в этот спейс, когда они присоединятся к нему.",
                  description14:
                    " Участники должны быть приглашены в этот закрытый спейс, чтобы получить доступ к нему. Если они приглашены, то они увидят страницу регистрации.",
                  description15:
                    "Участники должны быть приглашены напрямую в этот закрытый спейс, чтобы получить доступ к нему. Если они приглашены, то они увидят страницу для создания аккаунта, а не страницу регистрации.",
                  description16:
                    "Участники будут попадать на страницу регистрации перед тем, как они смогут присоединиться к этому платному спейсу.",
                  description17:
                    "Страница для создания аккаунта и оплаты будет показана перед тем, как участники смогут присоединиться к этому приватному спейсу. Они не увидят страницу регистрации.",
                  on: "ON",
                  off: "OFF",
                  label11: "Участники",
                  label12: "В статусе ожидания",
                  label13: "Заинтересованные",
                  label14: "Приглашенные",
                  label15: "Платежи",
                  label16: "Брендинг",
                },
                additional: {
                  allowMembersEdit:
                    "Разрешить участникам редактировать содержание на этой странице",
                  membersWillAddContent:
                    "Участники смогут добавлять и редактировать содержание на этой странице",
                  allowMembersCreatePosts:
                    "Разрешить участникам создавать новые публикации",
                  membersWillCreatePosts:
                    "Участники смогут создавать новые публикации в этом спейсе",
                  allowMembersCreateEvents:
                    "Разрешить участникам создавать новые мероприятия",
                  membersWillCreateEvents:
                    "Участники смогут создавать новые мероприятия в этом спейсе",
                  allowMembersCreateSubspaces:
                    "Разрешить участникам создавать новые под-спейсы",
                  membersWillCreateSubspaces:
                    "Участники смогут создавать под-спейсы на этой странице",
                  membersCanCreate: "Участники могут создавать",
                  circleCreation: {
                    options: {
                      discussion: "спейсы для чата",
                      content: "спейсы для контента",
                      event: "спейсы для мероприятия",
                    },
                    placeholder:
                      "Выберите форматы спейсов, которые участники смогут создавать в этом спейсе",
                  },
                  allowMembersToCopy: {
                    description1:
                      "Разрешить участникам копировать участников со спейсов",
                    description2:
                      "Участники смогут автоматически добавлять участников из других спейсов",
                  },
                  podType: {
                    description1:
                      "Все участники могут создавать публикации в этом спейсе",
                    description2:
                      "Если этот параметр отключен, тогда только админы смогут создавать публикации",
                  },
                  allowReflections: {
                    description1: "Включить функцию ответов для этого спейса",
                    description2:
                      "Если этот параметр отключен, то функция ответить не будет отображаться на публикациях",
                  },
                  showMembers: {
                    description1: "Показывать участников",
                    description2:
                      "Показывать список участников этого спейса",
                  },
                  hideMmemberEmails: {
                    description1:
                      "Показывать адреса эл. почты в списке участников",
                    description2:
                      " Если этот параметр отключен, адреса эл. почты участников не будут показаны в списке участников",
                  },
                  newMemberNotifications: {
                    description1: "Уведомлять админов о новых участниках",
                    description2:
                      "Отправлять уведомление по эл. почте при регистрации каждого нового участника",
                  },
                  podSize: {
                    description1:
                      "Ограничить количество участников, которые могут присоединиться к этому спейсу",
                    description2:
                      "Укажите максимальное количество участников, которые могут присоединиться к этому спейсу",
                  },
                  podSizeValue: {
                    description:
                      "Сколько участников могут присоединиться к этому спейсу?",
                  },
                  sidebarDefaultOpened: {
                    description1:
                      "спейс по умолчанию отображен на боковой панели",
                    description2:
                      "Если этот параметр включен, этот спейс будет по умолчанию отображен на боковой панели",
                  },
                  sidebarHideChildren: {
                    description1: "Скрыть под-спейсы на боковой панели",
                    description2:
                      "Если этот параметр включен, созданные в этом спейсе под-спейсы не будут отображаться на боковой панели",
                  },
                  allowCompletions: {
                    description1:
                      "Показывать кнопку “пройдено”",
                    description2:
                      "Если этот параметр включен, участники увидят кнопку “пройдено” в этом спейсе, и иконка на боковой панели будет заменена на галочку",
                  },
                  redirectToSubspace: {
                    description1: "Всегда перенаправлять в под-спейс",
                    description2:
                      "Если этот параметр включен, участники всегда будут попадать в этот под-спейс.",
                  },
                  parentCircles: {
                    description1: "Исходные спейсы",
                    placeholder:
                      "Поиск спейсов, чтобы добавить их как исходный спейс к этому спейсу",
                  },
                },
                scheduling: {
                  showStartDate: {
                    description1: "Отображать этот спейс как мероприятие",
                    description2:
                      "Этот спейс будет отображать дату мероприятия везде, где она указана",
                  },
                  startDateTime: {
                    title: "Дата и время начала",
                    placeholder: "Выберите дату",
                  },
                  endDateTime: {
                    title: "Дата и время окончания",
                    placeholder: "Выберите дату",
                  },
                  showLaunchDate: {
                    description1:
                      "Опубликовать это спейс в определенную дату",
                    description2:
                      "Это спейс станет видимым и доступным в указанную дату",
                  },
                  launchDateTime: {
                    placeholder: "Выберите дату",
                  },
                  showArchiveDate: {
                    description1:
                      "Архивировать этот спейс в определенную дату",
                    description2:
                      "Доступ к этому спейсу будет прекращён с указанного числа",
                  },
                  archiveDate: {
                    placeholder: "Выберите дату",
                  },
                  showReadonlyDate: {
                    description1:
                      "Сделать этот спейс доступным только для чтения в определенную дату",
                    description2:
                      "После указанной даты в этом спейсе нельзя будет создавать новые публикации",
                  },
                  readonlyDate: {
                    placeholder: "Выберите дату",
                  },
                },
                seo: {
                  title: {
                    title: "Тег Title",
                    description:
                    "Тег Title  — это заголовок, который должен отражать суть страницы. Он будет отображаться в поисковой выдаче и в общих публикациях в социальных сетях. Оптимальная длина: 60 символов.",
                  },
                  description: {
                    title: "Мета-описание",
                    description:
                      "Это описание, которое будет отображаться в поисковой выдаче и общих публикациях в социальных сетях. Оптимальная длина: от 150 до 350 символов.",
                  },
                  image: {
                    title: "Мета-изображение",
                    description:
                      "Это изображение, которое будет отображаться в общих публикациях в социальных сетях. Рекомендуемые размеры: 1200 px x 627 px (соотношение 1.91/1).",
                  },
                },
              },
              members: {
                title: "Участники",
                members: {
                  download: "Скачать CSV",
                  invite: "Пригласить",
                  member: "Участники",
                  invited: "Приглашенные",
                  interested: "Заинтересованные",
                  payment: "Платежи",
                },
                interested: {
                  interested: {
                    button: "Поиск",
                    placeholder: "Расширенный поиск",
                    broadcast: "Отправить рассылку",
                    noRecordFound: "Записей не найдено             ",
                  },
                  table: {
                    firstname: "Имя",
                    email: "Email",
                    date: "Дата",
                  },
                  interestedrow: {
                    removeFromList: "Удалить из списка",
                  },
                },
                invited: {
                  invited: {
                    button: "Поиск",
                    placeholder: "Расширенный поиск",
                    noRecordFound: "Не найдено записей",
                  },
                  table: {
                    email: "Email",
                    invitationlink: "Ссылка приглашения",
                    amount: "Сумма",
                    date: "Дата отправки",
                  },
                },
                invitedrow: {
                  invitation: {
                    resend: "Повторно отправить приглашение",
                    cancel: "Отменить приглашение",
                  },
                },
                inviteEmail: {
                  invite: {
                    placeholder:
                      "Введите адрес эл. почты или @упомяните людей, которых вы хотели бы добавить в этот спейс. Введенный текст может быть разделен запятыми, пробелами или новыми строками.",
                    message: "Добавить приветствие (это необязательно)",
                    checkboxLabel:
                      "Отправить эл. письмо, уведомляющее людей о приглашении",
                    alertMessage:
                      "В настоящее время вы подписаны на Базовый тарифный план и не можете отправлять приглашения через Sutra. При нажатии кнопки «Пригласить» для каждого добавленного адреса эл. почты будет создана уникальная ссылка приглашения. Вы сможете лично отправить эл. письмо каждому человеку с этой ссылкой.",
                    alertbutton: "Сменить тариф",
                    button: "Пригласить людей",
                  },
                },
                inviteExisting: {
                  invite: {
                    copyMembers:
                      "Скопировать участников из другого спейса",
                    copyMembersPlaceholder:
                      "Выбрать спейсы, из которых нужно скопировать участников",
                    addActiveMembers:
                      "Добавить активных участников из исходных спейсов",
                    addActiveMembersPlaceholder:
                      "Выберите активных участников, чтобы добавить их в этот спейс",
                    addPendingMembers:
                      "Добавить участников из исходного спейса, которые находятся в статусе ожидания",
                    addPendingMembersPlaceholder:
                      "Выбрать участников в статусе ожидания, чтобы добавить их в этот спейс",
                    checkboxLabel:
                      "Отправить эл. письмо, чтобы уведомить людей о том, что они были приглашены",
                    alert:
                      "В настоящее время вы подписаны на Базовый тарифный план и не можете отправлять приглашения через Sutra. При нажатии кнопки «Пригласить» для каждого добавленного эл. адреса будет создана уникальная ссылка приглашения. Вы сможете лично отправить электронное письмо каждому человеку с этой ссылкой.",
                    alertButton: "Сменить тариф",
                    button: "Пригласить людей",
                    thisOption: "Эта функция не включена для этого спейса",
                  },
                },
                inviteLink: {
                  invite: {
                    shareLinkText:
                      "Люди могут напрямую зарегистрироваться в этом спейсе по следующей ссылке. Вы можете поделиться этой ссылкой везде, где хотите (по эл. почте, в социальных сетях и т. д.).",
                    youCannotShare:
                      "Вы не можете поделиться ссылкой на закрытый спейс.",
                  },
                },
                invitePeople: {
                  invite: {
                    invitePeople: "Пригласить людей",
                  },
                },
                membersActivity: {
                  members: {
                    placeholder: "Поиск по ключевым словам",
                    button: "Поиск",
                    emailButton: "Email",
                    messageButton: "Сообщение",
                    noRecordFound: "   Записей не найдено        ",
                  },
                  memberTable: {
                    noRecordFound: "Записей не найдено",
                    lastSeen: "Последнее посещение",
                    lastPosted: "Последняя публикация",
                    date: "Дата присоединения",
                  },
                },
                participationActivity: {
                  participation: {
                    searchPlaceholder: "Поиск по ключевым словам",
                    searchButton: "Поиск",
                    pickDatePlaceholder: "Выберите диапазон дат",
                    emailButton: "Эл. почта",
                    messageButton: "Сообщение",
                    noRecordFound: "   Записей не найдено        ",
                  },
                  memberTable: {
                    member: "Участник",
                    participation: "Участие",
                    posts: "Публикации",
                    words: "Слова",
                  },
                },
                participationActivityRow: {
                  posts: "публикации",
                },
                paymentRow: {
                  payment: {
                    refundPayment: "Возврат оплаты",
                  },
                },
                payments: {
                  payment: {
                    searchPlaceholder: "Поиск по ключевым словам",
                    searchButton: "Поиск",
                    noRecordFound: "   Записей не найдено",
                  },
                  paymentTable: {
                    member: "Участник",
                    amount: "Сумма",
                    date: "Дата",
                    status: "Статус",
                  },
                },
                progressActivity: {
                  progress: {
                    searchPlaceholder: "Поиск по ключевым словам",
                    searchButton: "Поиск",
                    emailButton: "Email",
                    messageButton: "Сообщение",
                    noRecordFound: "Записей не найдено",
                    progressTracking:
                      "Отслеживание прогресса доступно только в спейсах, где есть интерактивное обучение.",
                  },
                  progressTable: {
                    member: "Участник",
                    progress: "Прогресс",
                  },
                },
                registered: {
                  registered: {
                    searchPlaceholder: "Поиск по ключевым словам",
                    searchButton: "Поиск",
                    email: "Эл. почта",
                    message: "Сообщение",
                    noRecordFound: "Записей не найдено",
                    pending: "В статусе ожидания",
                    manager: "Админы",
                    all: "Все",
                    active: "Активные",
                  },
                  registeredTable: {
                    member: "Участник",
                    email: "Эл. почта",
                    status: "Статус",
                    role: "Роль",
                    roleApproveAll: "Одобрить всех",
                  },
                },
                registeredRow: {
                  registered: {
                    roleApprove: "Одобрить",
                    joined: "Присоединился",
                    sendMessage: "Отправить сообщение",
                    stopCharging: "Прекратить списание средств",
                    emailPreferences: "Настройки рассылок",
                    removeMember: "Удалить участника",
                  },
                },
              },
              payment: {
                charging: {
                  NumberOfTimes: {
                    howManyTimes: "Сколько раз?",
                  },
                  UntilDate: {
                    pickDate: "Выбрать дату окончания  списания средств",
                  },
                },
                fees: {
                  description: {
                    fees: "Сборы:",
                    sutraFee: "Сборы SUTRA +: ",
                    stripeProcessingFee: "Сборы STRIPE:",
                  },
                },
                flexible: {
                  flexible: {
                    description:
                      "Чтобы предложить людям гибкую систему оплаты, такую как модель “Плати сколько можешь” или несколько уровней оплаты, вы можете создать опрос по оплате, который люди увидят, когда зарегистрируются в вашем спейсе. Нажмите кнопку редактирования ниже, чтобы настроить опрос.",
                  },
                },
                once: {
                  once: {
                    description: "Укажите цену",
                    number: "10",
                  },
                },
                recurring: {
                  recurring: {
                    description1: "Укажите цену ",
                    description2: "Выберите опцию оплаты",
                  },
                },
              },
              privacy: {
                open: {
                  open: {
                    description:
                      "Этот спейс доступен всем",
                  },
                },
                paid: {
                  paid: {
                    stripeConnected: "Подключено к Stripe",
                    disconnectButton: "Отключить",
                    description:
                      "Для обработки платежей для этого спейса, пожалуйста, подключите свой аккаунт Stripe, нажав на кнопку ниже.",
                    connectButton: "Подключить Stripe",
                  },
                },
                private: {
                  private: {
                    description:
                      "Это закрытый спейс, участники должны получить приглашение, чтобы к нему присоединиться.",
                  },
                },
              },
              registration: {
                steps: {
                  addFromSubSpace: {
                    label: "Выбрать спейс, чтобы добавить участника",
                    description:
                      "В некоторых случаях люди могут присоединяться к под-спейсам без участия в исходном спейсе. Тогда вы хотите быть уверены, что они также добавлены в верхний спейс или какие-то открытые спейсы. Эта функция позволит вам это сделать. Когда кто-нибудь присоединяется к под-спейсу, они автоматически будут добавлены к любым спейсам, которые вы выберите ниже.",
                    placeholder: "Выбрать спейсы",
                    saveButton: "Сохранить шаг",
                    deleteButton: "Удалить шаг",
                    saveButton2: "Сохранить шаг",
                  },
                  addStructure: {
                    label1: "Выбрать структуру",
                    placeholder1: "Выбрать вариант",
                    label2: "Отправить как",
                    placeholder2: "Выбрать вариант",
                    saveButton: "Сохранить шаг",
                  },
                  addToExistingSpace: {
                    label: "Выбрать спейс, чтобы добавить участника",
                    placeholder: "Выбрать спейсы",
                    saveButton1: "Сохранить шаг",
                    deleteButton: "Удалить шаг",
                    saveButton2: "Сохранить шаг",
                  },
                  addToNewSpace: {
                    placeholder: "Название нового спейса",
                    label: "Придумайте название для нового спейса",
                    saveButton1: "Сохранить шаг",
                    deleteButton: "Удалить шаг",
                    saveButton2: "Сохранить шаг",
                  },
                  applyTemplate: {
                    label: "Выбрать спейс, чтобы создать копию",
                    description:
                      "Если вам захочется создать спейс-шаблон и дублировать его каждый раз при создании нового спейса, вы можете создать и настроить его именно так, как вы хотите. Затем весь контент и под-спейсы будут скопированы в любой вновь созданный здесь спейс.",
                    saveButton1: "Сохранить шаг",
                    deleteButton: "Удалить шаг",
                    saveButton2: "Сохранить шаг",
                  },
                  editEmail: {
                    placeholder1: "Тема письма",
                    label1: "Отправить от имени",
                    placeholder2: "Выбрать",
                    label2: "Кому",
                    placeholder3: "Выбрать вариант",
                    placeholder4: "someone@special.com",
                    label3: "Адрес эл. почты",
                    saveButton: "Сохранить шаг",
                    deleteButton: "Удалить шаг",
                  },
                  editStructure: {
                    deleteButton: "Удалить шаг",
                  },
                  editSurvey: {
                    deleteButton: "Удалить шаг",
                  },
                  sendEmail: {
                    label1: "Выберите адрес эл. почты",
                    placeholder1: "Выберите вариант",
                    label2: "Отправить от имени",
                    placeholder2: "Выберите вариант",
                    label3: "Кому",
                    placeholder3: "Выберите вариант",
                    label4: "someone@special.com",
                    placeholder4: "Адрес эл. почты",
                    saveButton: "Сохранить шаг",
                    label5: "Админы",
                    label6: "Настраиваемый адрес эл. почты",
                  },
                  setToPending: {
                    title: "Приветствие",
                    description:
                      "Это контент, который люди увидят сразу после того, как зарегистрируются в вашем спейсе. Это хорошее место для пояснения, когда стартует программа, и что они могут ожидать в этот промежуток времени.",
                    saveButton1: "Сохранить шаг",
                    deleteButton: "Удалить шаг",
                    saveButton1: "Сохранить шаг",
                  },
                  surveyPerson: {
                    placeholder: "Выберите вариант",
                    label: "Выбрать опрос",
                    saveButton1: "Сохранить шаг",
                    deleteButton: "Удалить шаг",
                    saveButton2: "Сохранить шаг",
                  },
                  wait: {
                    description1: "Сколько дней?",
                    description2: "Ждать до",
                    saveButton: "Сохранить шаг",
                    deleteButton: "Удалить шаг",
                    saveButton2: "Сохранить шаг",
                    label1: "Выбрать условие ожидания",
                    placeholder: "Выберите вариант",
                    label2: "Количество дней",
                    label3: "До указанной даты и времени",
                    label4: "Назначить дату",
                  },
                  webhook: {
                    label: "URL вебхука",
                    saveButton: "Сохранить шаг",
                    deleteButton: "Удалить шаг",
                  },
                },
                addStep: {
                  addStep: {
                    description: "Добавить шаг",
                    label: "Пожалуйста, выберите действие для этого шага",
                    placeholder: "Выбрать действие",
                  },
                },
                registrationFlow: {
                  registration: {
                    description1:
                      "Эта функция позволяет вам упорядочивать автоматические действия, которые запускаются, когда человек присоединяется к этому спейсу или проявляет к нему интерес. Здесь вы сможете запланировать время отправки эл. писем, а также автоматически добавить людей в выбранные спейсы при их регистрации.",
                    description2:
                      "Выполнить эти действия после того, как кто-то...",
                    placeholder1: "Выберите начальное условие",
                    addButton: "Добавить шаг",
                    label1:
                      "Становится участником или добавляется к списку заинтересованных",
                    description3:
                      "Следующие шаги будут запущены, когда человек станет участником этого спейса или добавится к списку заинтересованных, отправив свое имя и адрес эл. почты через форму сбора заявок.",
                    label2: "Становится участником этого спейса",
                    description4:
                      "Следующие шаги будут запущены, когда человек станет участником этого спейса",
                    label3: "Проявляет интерес к этому спейсу                    ",
                    description5:
                      "Следующие шаги будут запущены, когда человек добавится к списку заинтересованных, отправив свое имя и адрес эл. почты через форму сбора заявок.",
                  },
                },
              },
              structure: {
                add: {
                  add: {
                    title: "Добавить в спейсы",
                    description: "спейсы",
                    placeholder1: "Выбрать спейс",
                    label1: "Отправить от имени",
                    placeholder2: "Выбрать отправителя",
                    placeholder3: "Выбрать дату",
                    label2: "Дата отправки",
                    label3: "Время отправки",
                    saveButton: "Сохранить",
                    label4: "Запланировать на потом",
                  },
                },
                createStructure: {
                  title: {
                    createStructure: "Создать структуру",
                  },
                },
                structureItem: {
                  item: {
                    addToSpace: "Добавить в спейс",
                    open: "View responses",
                    edit: "Редактировать",
                    duplicate: "Дублировать",
                    delete: "Удалить",
                  },
                },
              },
              survey: {
                add: {
                  add: {
                    title: "Добавить в спейсы",
                    spaces: "спейсы",
                    placeholder1: "Выберите спейс",
                    label: "Отправить как",
                    placeholder2: "Выберите отправителя",
                    saveButton: "Сохранить",
                  },
                },
                answer: {
                  answer: {
                    addOption: "Добавить вариант",
                  },
                },
                createSurvey: {
                  description: "Создать опрос",
                },
                createSurveyForm: {
                  form: {
                    title: "Название опроса",
                    description:
                      "Гибкая платёжная система позволяет вам предлагать несколько способов оплаты для вашей программы. Если вы хотите, чтобы люди устанавливали сами свою цену, то создайте вариант оплаты, не фиксируя стоимость. Этот вариант будет отображаться с полем для ввода собственной суммы. Например, вы можете предложить модель оплаты “Плати сколько можешь” с несколькими  рекомендуемыми уровнями и полностью свободным вариантом. Если вы добавите поясняющий текст ниже, то он будет отображаться прямо над вариантами оплаты.",
                    optionalDescription: "Необязательное описание опроса",
                    addQuestionButton: "Добавить вопрос",
                    saveButton: "Сохранить",
                  },
                },
                individual: {
                  individual: {
                    placeholder: "Выбрать участника",
                  },
                },
                multipleItem: {
                  multipleItem: {
                    placeholder: "Вариант",
                  },
                },
                paymentItem: {
                  paymentItem: {
                    placeholder: "Вариант оплаты",
                  },
                },
                paymentSurveyItem: {
                  item: {
                    editButton: "Редактировать варианты оплаты",
                    viewButton: "Посмотреть ответы",
                  },
                },
                showSurvey: {
                  survey: {
                    title: "Ответы на опрос",
                  },
                },
                singleItem: {
                  item: {
                    placeholder: "Вариант",
                  },
                },
                surveyItem: {
                  item: {
                    addToSpace: "Добавить в спейс",
                    open: "View responses",
                    edit: "Редактировать",
                    take: "Preview survey",
                    duplicate: "Дублировать",
                    download: "Скачать CSV",
                    delete: "Удалить",
                    responses: "Ответы",
                  },
                },
              },
              broadcast: {
                broadcast: {
                  button: {
                    broadcastButton: "Новая рассылка",
                  },
                },
              },
              broadcast: {
                title: "Рассылка",
                broadcastButton: "Новая рассылка",
                membersTypes: {
                  active: "Активные участники",
                  pending: "Участники в статусе ожидания",
                  interested: "Заинтересованные участники",
                },
                sentOn: "Рассылка отправлена",
                onDestroy: {
                  alertTitle: "Вы уверены?",
                  alertMessage: "Эта рассылка будет удалена безвозвратно",
                  confirm: "Удалить рассылку",
                  deny: "Отменить",
                },
                actions: {
                  edit: "Редактировать",
                  duplicate: "Дублировать",
                  delete: "Удалить",
                },
                statuses: {
                  sent: "Отправлено",
                  scheduled: "Запланировано",
                  draft: "Черновик",
                  procesing: "Processing",
                },
                form: {
                  composeBroadcast: "Составить рассылку",
                  contentErrMessage: "Добавьте содержание",
                  targetErrMessage: "Выбрать получателя",
                  type: {
                    email: "Отправить по эл. почте",
                    message: "Отправить сообщение в спейс",
                  },
                  title: {
                    placeholder: "Тема письма",
                  },
                  brackets: {
                    part1: "Использовать",
                    part2: "и",
                    part3: "для автоматического заполнения соответствующей информации",
                  },
                  target: {
                    placeholder: "Выбрать получателей",
                    label: "Кому",
                  },
                  sendAs: {
                    label: "Отправить от имени",
                    placeholder: "Выбрать отправителя",
                  },
                  schedule: {
                    label: "Запланировать отправку",
                  },
                  sendAtDate: {
                    label: "Дата отправки",
                    placeholder: "Выберите дату",
                  },
                  sendAtTime: {
                    label: "Время отправки",
                    placeholder: "",
                  },
                  scheduleBroadcast: "Запланировать рассылку",
                  sendBroadcast: "Отправить рассылку",
                  saveDraft: "Сохранить черновик",
                },
              },
              surveys: {
                title: "Опросы",
                createSurvey: "Создать опрос",
              },
              structures: {
                title: "Структуры",
                createStructure: "Создать структуру",
              },
              sutraAdmin: {
                title: "Sutra Админ",
              },
              scheduled: {
                title: "Запланированные",
              },
              archived: {
                title: "Архивированные",
              },
              participation: {
                title: "Метрики участников",
                label1: "Присутствие",
                description1: "Процент участников, посетивших ресурс за последние 7 дней",
                label2: "Активность",
                description2:
                  "Процент участников, создавших публикации за последние 7 дней",
                label3: "Публикации",
                description3:
                  "Количество публикаций во всех под-спейсах за последние 7 дней",
                label4: "Участие",
                label5: "Активность участников",
                label6: "Прогресс",
              },
            },
            sidebar: {
              iconSelect: {
                placeholder: "Поиск",
              },
              membersNode: {
                member: "Участники",
              },
              nodeIcon: {
                reset: "Сброс",
              },
              sidebarTutorial: {
                gettingStarted: "Начало работы",
              },
            },
            alreadyMember: {
              member: {
                welcome:
                  "Добро пожаловать! Вы уже являетесь участником этого спейса",
                clickHere: "Нажмите сюда, чтобы войти",
              },
            },
            calender: {
              calender: {
                joinButton: "Присоединиться к видеоконференции",
                addButton: "Добавить в календарь",
                joinEventButton: "Зарегистрироваться",
                title: "Подробности мероприятия",
              },
            },
            invitationBar: {
              button: {
                register: "Зарегистрироваться",
              },
            },
            space: {
              sutraLoading: "Sutra - Загрузка...",
            },
            statusBar: {
              restoreButton: "Восстановить",
            },
            tagFilter: {
              resetButton: "Сбросить",
            },
            presentations: {
              discussion: {
                reflectionHeader: "Отражения",
                backToPost: "Вернуться к сообщению",
                pinnedMessageHeader: "Закрепленное сообщение",
                delete: {
                  title: "Вы уверены?",
                  message: "Это действие нельзя отменить",
                  confirmButton: "Да, удалить",
                  cancelButton: "Отмена",
                },
              },
              tag: {
                placeholder: "Поиск тегов или создание нового",
                placeholder2: "Выбрать тег",
                createAction: "Создать",
              },
              message: {
                reply: "Ответить",
                addTags: "Добавить теги",
                tag: "Тег",
                copyToClipboard: "Скопировать в буфер обмена",
                messageCopied: "Сообщение скопировано",
                pinMessageAction: "Закрепить сообщение",
                editMessage: "Редактировать сообщение",
                deleteMessage: "Удалить сообщение",
                makePrivate: "Сделать приватным",
                viewReplies1: "Просмотр",
                viewReplies2single: "ответ",
                viewReplies2plural: "ответы",
                privateMessage:
                  "Сейчас эту публикацию видите только вы и админы этого спейса. Если вы сделаете его открытой, другие участники спейса смогут увидеть его.",
                privateMessage2:
                  "Этот участник решил не делиться своим ответом с другими участниками.",
                unreadMessagesText: "У вас есть непрочитанные сообщения",
                scrollDownButton: "Пролистать вниз",
                newReflections: {
                  part1: "У вас",
                  part2: "непрочитанные ответы в",
                  part3: "сообщения",
                },
                viewNextButton: "Посмотреть следующее",
                pinMessage: {
                  mainText: "Закрепленное сообщение",
                  viewButton: "Посмотреть сообщение",
                },
              },
              content: {
                commentInput: {
                  toggleLabel1:
                    "Комментарии отключены для интерактивного обучения",
                  toggleLabel2: "Включить комментарии для этой публикации",
                  toggleLabel3: "Включить комментарии для этого спейс-мероприятия",
                  toggleLabel4: "Включить комментарии для этого спейс-контента",
                  placeholder1:
                    "Комментарии не могут быть опубликованы во время редактирования",
                  placeholder2: "Напишите что-нибудь...",
                },
              },
              fallback: {
                title: "Проблемы с подключением к Интернету",
                message:
                  "Кажется, что у вас отсутствует подключение к Интернету. Когда связь появится снова, мы вас автоматически подключим.",
              },
              events: {
                placeholder: "Напишите что-нибудь...",
                upcomingButton: "Предстоящие",
                pastButton: "Прошедшие",
                addEvent: "Добавить мероприятие",
                eventSettingsTooltip: "Настройки мероприятия",
                newEventTitle: "НОВОЕ МЕРОПРИЯТИЕ",
              },
              list: {
                placeholder: "Напишите что-нибудь...",
                createButton: "Создать публикацию",
                listSettingsTooltip: "Настройки форума",
                duplicateFormTitle: "ДУБЛИРОВАТЬ СПЕЙС",
              },
              settings: {
                versions: {
                  content: "Содержание страницы",
                  posts: "Сообщения",
                  discussions: "Чаты",
                  link: "Ссылка",
                  template: "Шаблон",
                },
                versionsDescriptions: {
                  content:
                    "Новые элементы будут отображаться в виде страницы контента с полным функционалом.",
                  discussion: "Пусто",
                  posts:
                    "Новые элементы будут отображаться в виде простой публикации с ограниченной функциональностью.",
                  link: "Эта опция позволяет вам отправить ссылку на любой спейс в Sutra, которая затем будет отображаться здесь в виде элемента.",
                  template:
                    "Создайте спейс-шаблон, который будет дублироваться каждый раз, когда здесь создается новый элемент.",
                },
                createNewTemplate: "Создать новый шаблон",
                editTemplate: "Редактировать шаблон",
                title: "НАСТРОЙКИ",
                accordion1: {
                  label: "Отобразить как",
                  description: "Отобразить публикации в виде списка или плитки",
                  listOption: "Список",
                  gridOption: "Плитка",
                  dateCreated: "Дата создания",
                  dateCreatedReversed: "Обратная дата создания",
                  lastActive: "Последняя активность",
                  lastEdited: "Последнее редактирование",
                  alphabetic: "По алфавиту",
                  reverseAlphabetic: "Обратный алфавитный",
                },
                accordion2: {
                  label: "Варианты отображения",
                  description: "",
                  switches: {
                    onlyManagers: "Только админы могут создавать публикации",
                    showAuthor: "Показывать информацию об авторе",
                    showPreview: "Показывать превью текста",
                    showComments: "Показывать количество комментариев",
                    showMembers: "Показывать аватарки участников",
                    showTimestamp:
                      "Показывать время создания публикации",
                    showThumbnail: "Показывать миниатюру",
                    autoThumbnail: "Автоматическое создание миниатюр",
                  },
                },
                accordion3: {
                  label: "Формат новой публикации",
                  description:
                    "Выберите формат для новых публикаций в этом спейсе",
                  templateName: {
                    label: "Название шаблона",
                    placeholder: "Назовите ваш шаблон",
                  },
                  templateDirectOption: "Прямой под-спейс",
                  templateLinkedOption: "Привязанный под-спейс",
                  privacyDescription:
                    "Выберите уровень конфиденциальности по умолчанию для новых публикаций",
                  privacyOpenOption: "Открытая",
                  privacyPrivateOption: "Приватная",
                  privacyAnyOption: "Выбор создателя",
                },
                accordion4: {
                  label: "Текст командной кнопки",
                  createPost: {
                    label: "Создать публикацию",
                    description:
                      "Укажите текст, который будет отображаться на кнопке новой публикации",
                  },
                },
                accordion5: {
                  label: "Теги",
                },
                templateDescription:
                  "Новые шаблоны спейсов могут быть под-спейсами этого спейса или они могут быть привязанными. Привязанные спейсы независимые верхние спейсы, которые будут отображаться на этой странице. Создатель спейса станет полноправным админом созданного им спейса.",
              },
              thumbnail: {
                title: "ДОБАВИТЬ МИНИАТЮРУ",
                message: "изображение загружается...",
              },
              static: {
                broadcastExclude: {
                  unsubscribe: {
                    part1: "Вы уверены, что хотите отписаться от",
                    part2:
                      "Вы больше не будете получать какие-либо рассылки по эл. почте от админов этого спейса.",
                    button: "Отписаться",
                  },
                  subscribe: {
                    part1:
                      "Вы были отписаны, и вы больше не будете получать рассылки по эл. почте от",
                    button: "Подписаться",
                  },
                },
                invitation: {
                  message: "Вас пригласили в это спейс",
                  button: "Принять приглашение",
                },
                pending: {
                  message: "Ваш запрос на присоединение находится на рассмотрении",
                },
                private: {
                  message: {
                    part1: "Это закрытый спейс.",
                    part2: "Если вы являетесь участником, пожалуйста, войдите в систему",
                  },
                  button: "Войти",
                },
                processing: {
                  message: "Этот спейс находится в стадии обработки…",
                },
              },
              gridPost: {
                onDelete: {
                  alertTitle: "Вы уверены?",
                  alertMessage:
                    "Этот спейс больше не будет привязан здесь.",
                  alertMessage2:
                    "Этот спейс больше не будет привязан здесь.",
                  confirm: "Да",
                  deny: "Нет",
                },
                actions: {
                  pin: "Закрепить наверху",
                  unpin: "Открепить",
                  addThumbnail: "Добавить миниатюру",
                  removeThumbnail: "Удалить миниатюру",
                  duplicate: "Дублировать",
                  unlink: "Отвязать",
                  delete: "Удалить",
                },
              },
              listContent: {
                publishTootip: "Будет опубликован в эту дату",
                privateTooltip: "Этот спейс закрытый",
              },
            },
            colors: {
              form: {
                colorTheme: "Цветовая тема",
                customize: "Настроить выбор цвета",
                saveTheme: "Сохранить тему",
                resetColor: "Сброс цвета",
                pageLayout: "Оформление страницы",
                pageFormat: "Формат страницы",
              },
              headerAndLogo: {
                addCover: "Добавить обложку",
                removeCover: "Удалить обложку",
                changeLogo: "Сменить логотип",
                removeLogo: "Удалить логотип",
                dimensions:
                  "Рекомендуемый размер изображения обложки — 1200 x 300 px",
                dimensions2:
                  "Рекомендуемый размер логотипа — 360 x 120 px",
              },
              pageFormat: {
                description:
                  "Вы можете изменить способ оформления контента в этом спейсе, выбрав один из вариантов ниже.",
                contentLabel: "Контент",
                discussionLabel: "Чат",
                contentDescription:
                  "Спейс для учебных модулей, общего контента или для материалов сообщества.",
                discussionDescription:
                  "спейс для чата или объявлений",
                eventsLabel: "Календарь",
                eventsDescription: "Создайте календарь мероприятий",
                listLabel: "Форум",
                listDescription:
                  "Создайте ленту, где люди смогут публиковать темы для обсуждения",
              },
              pageLayout: {
                widePage: "Увеличить ширину страницы",
                hideSidebar: "Скрыть боковую панель",
                hideHeader: "Скрыть шапку страницы",
                hideCoverImage: "Скрыть обложку",
                hidePageTitle: "Скрыть заголовок страницы",
                hideBreadcrumbs: "Скрыть навигационную цепочку",
              },
              header_color: {
                label: "Цвет шапки страницы",
                description: "Описание",
              },
              header_link_color: {
                label: "Цвет логотипа шапки",
                description: "Описание",
              },
              primary_button_background_color: {
                label: "Цвет фона основной кнопки",
                description: "Описание",
              },
              primary_button_text_color: {
                label: "Цвет текста основной кнопки",
                description: "Описание",
              },
              secondary_button_background_color: {
                label: "Цвет фона второстепенной кнопки",
                description: "Описание",
              },
              secondary_button_text_color: {
                label: "Цвет текста  второстепенной кнопки",
                description: "Описание",
              },
              background_color: {
                label: "Цвет фона",
                description: "Описание",
              },
              default_text_color: {
                label: "Цвет текста по умолчанию",
                description: "Описание",
              },
              sidebar_background_color: {
                label: "Цвет фона боковой панели",
                description: "Описание",
              },
              sidebar_text_color: {
                label: "Цвет текста боковой панели",
                description: "Описание",
              },
              default_link_color: {
                label: "Цвет ссылок по умолчанию",
                description: "Описание",
              },
              default_badge_color: {
                label: "Цвет значка уведомлений",
                description: "Описание",
              },
              default_badge_text_color: {
                label: "Цвет текста значка уведомлений",
                description: "Описание",
              },
              registration_page_background_color: {
                label: "Цвет фона страницы регистрации",
                description: "Описание",
              },
              registration_page_default_text_color: {
                label: "Цвет текста на странице регистрации",
                description: "Описание",
              },
            },
            create: {
              invalidNameErrorMsg:
                "Название спейса должно содержать от 3 до 100 символов",
            },
            dummy: {
              magicLink: {
                alertTitle: "Вы почти вошли...",
                alertMessage:
                  "Мы отправили волшебную ссылку на вашу эл. почту. ЭТО ТЕСТОВОЕ СООБЩЕНИЕ, ФАКТИЧЕСКАЯ ЭЛ. ПОЧТА НЕ ОТПРАВЛЯЕТСЯ.",
              },
            },
            pending: {
              message: "Ваш запрос на участие находится на стадии рассмотрения",
            },
            recover: {
              alertTitle: "Вы почти вошли...",
              alertMessage:
                " Мы только что отправили вам электронное письмо с инструкциями, чтобы восстановить ваш пароль. ЭТО ТЕСТОВОЕ СООБЩЕНИЕ, ФАКТИЧЕСКАЯ ЭЛЕКТРОННАЯ ПОЧТА НЕ ОТПРАВЛЯЕТСЯ.",
            },
            form: {
              calendarLinks: {
                text: "Информация, добавленная ниже, будет передана в календарь участника, при добавлении мероприятия.                              ",
                link: {
                  label: "Ссылка на мероприятие (ссылка Zoom и т.д.)",
                  placeholder: "Какая ссылка на вашу видеоконференцию?",
                },
                description: {
                  label:
                    "Добавьте описание вашего мероприятия, которое будет показано в календаре",
                  placeholder: "Описание в календаре",
                },
                eventStartDateAndTime: "Дата и время начала мероприятия",
                date: {
                  placeholder: "Выберите дату",
                },
                eventDateTime: "Дата и время окончания мероприятия (необязательно)",
                allowInterests:
                  "Разрешить людям присоединяться к мероприятию без регистрации в Sutra",
                automatedEmails: "Настройте автоматическую Email-рассылку",
                save: "Сохранить подробности",
              },
              duplicate: {
                prefix: "Копия",
                intention: {
                  label: "Название спейса",
                  placeholder: "Как называется ваш спейс?",
                },
                copyMembers: {
                  label: "Копировать участников",
                },
                copyManagers: {
                  label: "Копировать админов",
                },
                copyContent: {
                  label: "Копировать контент",
                },
                copyPosts: {
                  label: "Copy posts",
                },
                copyBroadcasts: {
                  label: "Копировать рассылки",
                },
                copyAutomation: {
                  label: "Копировать автоматизации",
                },
                copyRegPage: {
                  label: "Копировать страницу регистрации",
                },
                save: "Дублировать спейс",
              },
              form: {
                privacyDescription1:
                  "Спейс, который вы создаете, будет доступен всем, кто находится внутри",
                rootPrivacyDescription1:
                  "Созданный вами спейс будет доступен любому, у кого есть на него ссылка. Вы можете установить варианты оплаты после создания спейса.",
                privacyDescription2:
                  "Созданный вами спейс будет виден только тем, кто в него добавлен.",
                rootPrivacyDescription2:
                  "Созданный вами спейс будет виден только тем, кто в него приглашен. Вы можете установить варианты оплаты после создания спейса.",
                placeholderDescription: "Описание",
                versions: {
                  content: {
                    label: "Контент",
                    description:
                      "Создайте спейс для учебного модуля, общего контента или для материалов сообщества .",
                  },
                  discussion: {
                    label: "Чат",
                    description:
                      "Создайте спейс для чата или объявлений.",
                  },
                  event: {
                    label: "Мероприятие",
                    description: "Создайте разовое мероприятие.",
                  },
                  events: {
                    label: "Календарь",
                    description: "Создайте календарь мероприятий.",
                  },
                  list: {
                    label: "Форум",
                    description:
                      "Создайте ленту, где люди смогут публиковать темы для обсуждения.",
                  },
                },
                segments: {
                  space: {
                    label: "Спейс",
                    description:
                      "Создайте спейс для вашего курса или сообщества. Выберите этот вариант, если хотите создать группу обучения, курс в своем темпе, сообщество или любую группу, которая будет собираться продолжительное время.",
                  },
                  event: {
                    label: "Мероприятие",
                    description:
                      "Создайте простую страницу мероприятия. Выберите этот вариант, если вы просто хотите организовать разовое мероприятие, которым вы можете поделиться публично.",
                  },
                  discussion: {
                    label: "Чат",
                    description:
                      "Создайте простой спейс для чата вашей группы. Выберите этот вариант, если вы просто хотите создать отдельный чат для группы участников.",
                  },
                },
                intention: {
                  label: "Название спейса",
                  placeholder: "Как называется ваш спейс?",
                },
                content: {
                  label: "Название спейса",
                  description: "Напишите что-нибудь...",
                  placeholder: "Напишите что-нибудь...",
                },
                spaceFormat: {
                  label: "Формат спейса",
                  placeholder: "Выберите",
                },
                eventDescription:
                  "Информация, добавленная ниже, будет передана в календарь участника при добавлении мероприятия.",
                hangoutLink: {
                  label: "Ссылка мероприятия (ссылка Zoom и т.д.)",
                  placeholder: "Какая ссылка на вашу видеоконференцию?",
                },
                eventStartTime: "Дата и время начала мероприятия",
                date: {
                  placeholder: "Выберите дату",
                },
                eventEndDate: "Дата и время окончания мероприятия (необязательно)",
                privacyLevelText: "Настройки доступа",
                privacy: {
                  open: "Открытый",
                  private: "Закрытый",
                },
                joinAsMember: {
                  label:
                    "Присоединиться к этому спейсу как участник",
                },
                publishDate: {
                  label: "Опубликовать в назначенную дату",
                },
                publishDateTime: "Время и дата публикации",
                create: "Создать спейс",
              },
              interestedForm: {
                title: "Зарегистрироваться на это мероприятие",
                createAlertTitle: "Спасибо за регистрацию!",
                createAlertButton: "Ок",
                name: {
                  label: "Имя",
                  placeholder: "Введите ваше имя",
                },
                email: {
                  label: "Email",
                  placeholder: "Введите ваш эл. адрес",
                },
                register: "Зарегистрироваться",
                joinWithSutra: "Присоединиться через мой аккаунт Sutra",
              },
              linkForm: {
                linkErrMessage: "это ссылка недействительна",
                link: {
                  label: "",
                  placeholder: "Ссылка на спейс",
                  errors: {
                    space:
                      "Спейс, которым вы хотите поделиться, с закрытым доступом или не существует!",
                    event:
                      "Спейс, которым вы хотите поделиться, с закрытым доступом, не является мероприятием или не существует.",
                  },
                },
              },
              listForm: {
                intention: {
                  placeholder: "Заголовок",
                },
              },
            },
            header: {
              onLeave: {
                title: "Вы уверены?",
                message:
                  "Вы будете удалены, как участник этого спейса, и вам может потребоваться, чтобы вас снова добавили для восстановления доступа.",
                confirm: "Да, пожалуйста, удалите меня",
                deny: "Нет",
              },
              onArchive: {
                title: "Вы уверены?",
                message:
                  "Этот спейс будет заархивирован, и участники больше не будут иметь к нему доступ.",
                confirm: "Да",
                deny: "Нет",
              },
              onUnarchive: {
                title: "Вы уверены?",
                message:
                  "Этот спейс будет разархивирован, и участники снова получат к нему доступ.",
                confirm: "Да",
                deny: "Нет",
              },
              onDelete: {
                title: "Вы уверены?",
                message:
                  "Этот спейс будет разархивирован, и участники снова получат к нему доступ.",
                confirm: "Да",
                deny: "Нет",
              },
              onFix: {
                title: "Вы уверены?",
                message:
                  "Sutra попытается восстановить этот спейс-контента с помощью устаревших данных.",
                confirm: "Да, исправить",
                deny: "Нет",
                confirmationTitle: "Информация",
                confirmationMessage:
                  "Спейс исправлен, идёт перезагрузка...",
                confirmationError: "Этот спейс невозможно исправить",
              },
              buttons: {
                uncomplete: "Не пройдено",
                markComplete: "Отметить как пройдено",
                edit: "Редактировать",
                post: "публикацию",
                page: "страницу",
                space: "спейс",
                returnTo: "Вернуться на",
                editTitle: "Редактировать заголовок",
                viewPage: "Просмотр страницы",
                editDescription: "Редактировать описание",
                appearance: "Внешний вид",
                manage: "Управление",
                members: "Участники",
                leave: "Покинуть спейс",
                delete: "Удалить спейс",
                duplicate: "Дублировать спейс",
                template: "Создать шаблон",
                archive: "Архивировать спейс",
                unarchive: "Разархивировать спейс",
                fix: "Исправить",
                template: "Make a template from this space",
                joinEvent: "Зарегистрироваться",
                joinSpace: "Присоединиться к спейсу",
                preview: "Предварительный просмотр",
                draft: "Черновик",
                published: "Опубликовано",
                publish: "Опубликовать",
                returnToSettings: "Вернуться к настройкам",
                returnToSpace: "Вернуться к спейсу",
                undo: "Отменить",
                openFullScreen: "Открыть в полноэкранном режиме",
                copyToClipboard: "Скопировать в буфер обмена",
                copiedMessage:
                  "Ссылка на спейс скопирована в буфер обмена",
                versionHistory: "История версий",
                brandingOptions: "ВАРИАНТЫ БРЕНДИНГА",
              },
              regPagePublishing:
                "Ваша страница регистрации в процессе публикации. Пожалуйста, подождите...",
              spaceUpdating:
                "Этот спейс обновляется. Пожалуйста, подождите...",
            },
            members: {
              sectionTitle: "Участники",
              manage: "Управление участниками",
              invite: "Пригласить",
              search: {
                button: "Поиск",
                placeholder: "Поиск по ключевым словам",
              },
              emptyMessage: "Записей не найдено",
              messageButton: "Сообщение",
              emailButton: "Email",
              copyClipboard: "скопировать в буфер обмена",
            },
            payment: {
              onCheckIntent: {
                alertTitle: "Ошибка при проведении платежа",
                alertMessage:
                  "Время сеанса истекло. Пожалуйста, обновите страницу, чтобы завершить платеж.",
              },
              onConfirmPayment: {
                alertTitle: "Ой! Что-то пошло не так!",
                alertMessage: "что-то пошло не так!",
              },
              completePayment: "Завершить платеж",
              join: "Присоединиться к спейсу",
            },
            stripe: {
              pay: "Оплатить",
            },
          },
          survey: {
            form: {
              text: "Вы уже ответили на этот опрос.",
              submitButton: "Отправить",
            },
            question: {
              yourAnswer: "Ваш ответ",
            },
          },
          tiptap: {
            blocks: {
              section1: "Основное",
              text: "Текст",
              headline1: "Заголовок 1",
              headline2: "Заголовок 2",
              headline3: "Заголовок 3",
              divider: "Разделитель",
              infobox: "Информационная панель",
              section2: "Пространства",
              content: "Пространство контента",
              discussion: "Пространство обсуждений",
              event: "Пространство событий",
              events: "Пространство календаря",
              list: "Пространство форума",
              showcase: "Showcase Space",
              section3: "Список",
              section8: "Special",
              bulletList: "Список с маркерами",
              orderedList: "Упорядоченный список",
              checklist: "Список задач",
              collapsableSection: "Сворачиваемый раздел",
              survey: "Survey or quiz",
              section4: "Мультимедиа",
              image: "Изображение",
              embed: "Встроенный код",
              video: "Видео",
              audio: "Аудио",
              file: "Файл",
              zoom: "Zoom recording",
              section5: "Сетки",
              grid1: "1 колонка",
              grid2: "2 колонки",
              grid3: "3 колонки",
              grid4: "Левая колонка",
              grid5: "Правая колонка",
              grid6: "4 columns",
              section6: "Кнопки",
              interestForm: "Форма интереса",
              actionButton: "Кнопка действия",
              markComplete: "Отметить как завершено",
              section7: "Интерактив",
              interactiveButton: "Интерактивная кнопка",
              interactiveInput: "Интерактивный ввод",
              interactiveWait: "Интерактивное ожидание"
            },
            components: {
              sidebar: {
                alignment: {
                  left: "Слева",
                  center: "По центру",
                  right: "Справа",
                },
                backgroundColor: {
                  text: "Выберите цвет фона",
                  clearColor: "Убрать цвет",
                },
                backgroundImage: {
                  button: "Выбрать изображение",
                  removeButton: "Удалить изображение",
                  label: "Положение",
                  placeholder: "Выбрать",
                  leftTop: "вверху слева",
                  leftCenter: "слева по центру",
                  leftBottom: "слева снизу",
                  rightTop: "справа вверху",
                  rightCenter: "справа по центру",
                  rightBottom: "справа снизу",
                  centerTop: "центр сверху",
                  centerCenter: "по центру",
                  centerBottom: "центр снизу",
                  label2: "Повторение",
                  placeholder2: "Выбрать",
                  noRepeat: "Без повтора",
                  repeat: "Повторить",
                  "repeat-x": "Повтор по оси X",
                  "repeat-y": "Повтор по оси Y",
                  space: "Space",
                  round: "Round",
                },
                blockId: {
                  placeholder: "ID блока",
                  label: "ID блока",
                  description:
                    "Рекомендуемые идентификаторы (ID) для краткого описания ИИ,  о семинаре, что вы узнаете 1, что вы узнаете 2, что вы узнаете 3, для кого это, про нас, цитата",
                },
                buttonAction: {
                  label: "Завершение действия",
                  description:
                    "Уточните, что происходит после того, как человек отправляет свои данные через выбранную форму.",
                  placeholder: "Выбрать",
                  label2: "Ссылка на",
                  placeholder2: "Выбрать",
                  label3: "Действие ссылке",
                  description3:
                    "Уточните, будет ли ссылка открыта в текущей вкладке браузера или в новой.",
                  placeholder3: "Выбрать",
                  callBackComplete: "Текст при завершении действия",
                  callBacklinkTo: "Ссылка на",
                  completeDescription:
                    "Добавьте короткое сообщение с подтверждением, которое участники увидят после отправки информации.",
                  linkDescription:
                    "Человек будет перенаправлен по этой ссылке после нажатия кнопки.",
                  label4: "Ссылка на раздел",
                  description4: "Укажите, будет ли ссылка открываться в текущей вкладке браузера или на другой вкладке, или ссылка будет вести к разделу на этой странице.",
                  selectSection: "Выберите раздел",
                  description5: "Эта опция позволяет вам перейти к определенному разделу на этой странице при нажатии ссылки.",
                  description6: "Чтобы выбрать раздел, нажмите кнопку ниже, а затем выберите раздел, на который вы хотите создать ссылку.",
                  label5: "Раздел выбран!",
                  label6: "URL-адрес ссылки",
                  description7: "Укажите URL-ссылку, по которой будет перенаправлен пользователь.",
                  label7: "Открывается в текущей вкладке",
                  label8: "Открывает другую вкладку",
                  label9: "Ничего не делать",
                  label10: "Перенаправить пользователя на",
                  label11: "Выберите вариант",
                  label12: "Другое пространство",
                  label13: "Пользовательская ссылка",
                  label14: "Нажмите на раздел на странице",
                },
                buttonBackgroundColor: {
                  button: "Убрать цвет",
                },
                buttonText: {
                  placeholder: "Текст кнопки",
                  label: "Текст кнопки",
                },
                fileName: {
                  placeholder: "Имя файла",
                  label: "Имя файла",
                },
                interactiveWaitTime: {
                  label1: "Выбрать",
                  label2: "Подождать до указанной даты",
                  label3: "Подождать указанное количество дней",
                  label4: "Дата показа контента",
                  placeholder: "Дата показа контента",
                  label5: "1 день",
                  label6: "2 дня",
                  label7: "3 дня",
                  label8: "4 дня",
                  label9: "5 дней",
                  label10: "6 дней",
                  label11: "1 неделя",
                  label12: "2 недели",
                  label13: "1 месяц",
                },
                linkAction: {
                  label: "Действие ссылки",
                  description:
                    "Укажите, будет ли ссылка открыта в текущей вкладке браузера или в новой.",
                  placeholder: "Выбрать",
                  label2: "Открыть в текущей вкладке",
                  label3: "Открыть в новой вкладке",
                },
                margin: {
                  topMargin: "Отступ сверху -",
                  text: "Отступ снизу -",
                  bottom: "Снизу",
                  left: "Слева",
                  leftMargin: "Отступ слева -",
                  right: "Справа",
                  rightMargin: "Отступ справа",
                },
                padding: {
                  top: "Сверху",
                  topPadding: "Внутренний отступ сверху -",
                  bottom: "Снизу",
                  bottomPadding: "Внутренний отступ снизу -",
                  left: "Слева",
                  leftPadding: "Внутренний отступ слева -",
                  right: "Справа",
                  rightPadding: "Внутренний отступ справа -",
                },
                radius: {
                  square: "Квадратные",
                  round: "Закругленные",
                  circle: "Круглые",
                },
                spaceAttrs: {
                  addCover: "Добавить обложку",
                  text: "Рекомендуемый размер изображения обложки — 1200 x 300 px",
                  addCover2: "Добавить обложку",
                  text2: "Рекомендуемый размер изображения обложки — 1200 x 300 px",
                  removeCover: "Удалить обложку",
                },
                verticalAllignment: {
                  top: "Сверху",
                  center: "По центру",
                  bottom: "Снизу",
                },
                width: {
                  full: "Полная",
                  size: "3/4",
                  size2: "1/2",
                  actual: "Фактическая",
                },
              },
              blockMenuActions: {
                label1: "Добавить блок",
                label2: "Добавить эмодзи",
                label3: "Настройки",
                label4: "Удалить",
                label5: "Переместить",
                label6: "Перейти в спейс или дважды кликнуть по блоку",
                label7: "Заменить",
                label8: "Выравнивание",
                left: "Слева",
                center: "По центру",
                right: "Справа",
                write: "Напишите что-нибудь...",
                label9: "Добавить блок",
                label10: "Заголовок 1",
                h1: "H1",
                label11: "Заголовок 2",
                h2: "H2",
                label12: "Добавить спейс",
                label13: "Добавить изображение",
                label14: "Добавить видео",
                label15: "Добавить плитку",
                label16: "Добавить шаблон",
                label17: "Добавить макет",
                label18: "Дублировать",
              },
              blocksMenu: {
                basicBlock: "Основные блоки",
                mediaBlock: "Блоки медия",
                contentBlock: "Блоки контента",
              },
              blocksMenuInline: {
                file: "Файл",
                image: "Изображение",
                video: "Видео",
                label1: "Добавить эмодзи",
                label2: "Добавить изображение",
                label3: "Переключить панель форматирования",
              },
              blocksMenuSimple: {
                label1: "Добавить",
                file: "Файл",
                image: "Изображение",
                video: "Видео",
                audio: "Audio",
                label2: "Добавить эмодзи",
                label3: "Добавить изображение",
                label4: "Показать форматирование",
                cancelButton: "Отменить",
              },
              emptyMessage: {
                text: "Это пустой спейс для контента. Вы можете",
                button1: "редактировать эту страницу",
                text2: "или",
                button2: "использовать шаблон,",
                text3: "чтобы начать",
                text4: "Это пустой спейс, полный потенциала...",
              },
              fixedBottomMenu: {
                label1:
                  "Добавьте такие элементы, как заголовки, изображения и видео",
                addBlock: "Добавить блок",
                label2:
                  "Добавить спейс для контента, чата или для мероприятия",
                space: "Спейс",
                label3:
                  "Добавьте плитку, чтобы использовать колонки для контента",
                grid: "Плитка",
                label4: "Добавить заранее созданный вариант дизайна",
                layouts: "Макеты",
                label5:
                  "Добавить шаблон, чтобы автоматически настроить ваш спейс",
                template: "Шаблоны",
              },
              formatMenu: {
                text: "Текст",
                smallText: "Маленький текст",
                largeText: "Крупный текст",
                heading1: "Заголовок 1",
                heading2: "Заголовок 2",
                heading3: "Заголовок 3",
                bulletedList: "Маркированный список",
                orderedList: "Нумерованный список",
                text2: "Текст",
                label1: "Форматирование",
                text3: "Текст",
                numberedList: "Нумерованный список",
                label2: "Выравнивание",
                left: "Слева",
                center: "По центру",
                right: "Справа",
                label3: "Действие ссылки",
                description:
                  "Укажите, будет ли ссылка открыта в текущей вкладке браузера или в новой.",
                placeholder: "Выбрать",
                label4: "Открывается в текущей вкладке",
                label5: "Открывается в новой вкладке",
                label6: "Формат ссылки",
                description2:
                  "Укажите, будет ли ссылка отображаться в виде текста или как блок-предпросмотр.",
                placeholder2: "Выбрать",
                label7: "Отображать как текст",
                label8: "Отображать как блок-предпросмотр",
                save: "Сохранить",
                delete: "Удалить",
                label9: "Зачеркнуть",
                label10: "Подчеркивание",
                label11: "Кодовый блок",
                label12: "Цвет текста",
                placeholder3: "HEX-код",
                unsetColor: "Убрать цвет",
                label13: "Выделение цветом",
                label14: "Ссылка на раздел",
                description3: "Укажите, будет ли ссылка открываться в текущей вкладке браузера или на другой вкладке, или ссылка будет вести к разделу на этой странице.",
                selectSection: "Выберите раздел",
                description4: "Эта опция позволяет вам перейти к определенному разделу на этой странице при нажатии ссылки.",
                description5: "Чтобы выбрать раздел, нажмите кнопку ниже, а затем выберите раздел, на который вы хотите создать ссылку.",
                label15: "Раздел выбран!",
                label16: "URL-адрес ссылки",
                description6: "Укажите URL-ссылку, по которой будет перенаправлен пользователь.",
              },
              formatMenuSimple: {
                t: "Т",
                h1: "Заголовок 1",
                h2: "Заголовок 2",
                h3: "Заголовок 3",
                label1: "Действие ссылки",
                description1:
                  "Укажите, будет ли ссылка открыта в текущей вкладке браузера или в новой.",
                placeholder1: "Выбрать",
                label2: "Открывается в текущей вкладке",
                label3: "Открывается в новой вкладке",
                label4: "Формат ссылки",
                description2:
                  "Укажите, будет ли ссылка отображаться в виде текста или как блок-предпросмотр.",
                placeholder2: "Выбрать",
                label5: "Отображать как текст",
                label6: "Отображать как блок-предпросмотр",
                save: "Сохранить",
                delete: "Удалить",
                label7: "Форматирование",
                left: "Слева",
                center: "По центру",
                right: "Справа",
                placeholder3: "HEX-код",
                placeholder4: "Цвет HEX",
                unsetColor: " Убрать цвет",
              },
              inlineMenu: {
                h1: "Заголовок 1",
                h2: "Заголовок 2",
                bulletList: "Маркированный список",
                checkList: "Чек-лист",
                circle: "Круг",
                moreOption: "Больше функций",
              },
              interactiveInput: {
                hideResponses: "Скрыть другие ответы",
                showResponses: "Показать другие ответы",
                hideResponses2: "Скрыть ответы",
                hidePrivateMessages: "Скрыть личные сообщения",
                showPrivateMessages: "Показать личные сообщения",
                showResponses2: "Показать ответы",
                loadMoreResponse: "Загрузить больше ответов",
                placeholder: "Напишите что-нибудь...",
                text: "Вы хотите, чтобы этот ответ был виден другим участникам?",
              },
              newMedia: {
                add: "Добавить",
              },
              regPageColor: {
                clearColor: "Убрать цвет",
              },
              regPageColors: {
                text: "Внешний вид страницы регистрации",
                applyAppearance: "Применить внешний вид",
                title1: "Выбрать цвет фона",
                title2: "Выбрать цвет текста",
                label1: "Цвет фона",
                label2: "Цвет текста по умолчанию",
                label3: "Увеличить ширину страницы",
              },
              resource: {
                areYouSure: "Вы уверены?",
                label1: "Будет опубликован в эту дату",
                edit: "Редактировать",
                addPage: "Добавить страницу",
              },
              sideBlocksMenuBak: {
                addBlock: "Добавить блок",
                back: "Вернуться",
              },
              sideBlocksMenu: {
                text: "Макеты - это предварительно созданные варианты дизайна, которые вы можете добавить в ваш спейс.",
                text2:
                  "Начните с выбора настраиваемых полностраничных шаблонов",
                layout: "Макеты",
                template: "Шаблоны",
                addBlock: "Добавить блок",
                block: "Блоки",
              },
              sideConfigurationsMenu: {
                title: "Настройки блока",
                label1: "Название и обложка спейса",
                label2: "Атрибуты файла",
                label3: "Интерактивные настройки",
                attrPlaceholder1: "Напишите что-нибудь...",
                attrLabel: "Плейсхолдер",
                attrLabel2: "Разрешить участнику видеть все ответы",
                attrLabel3: "Показывать ответы до того, как ответит участник",
                attrLabel4:
                  "Разрешить участнику выбирать, будет ли его ответ виден другим",
                attrLabel5: "Разрешить несколько ответов для каждого участника",
                attrLabel6: "Разрешить участникам пропустить этот промпт",
                attrLabel7: "Уведомлять админов о каждой новой публикации",
                label4: "Интерактивные настройки",
                label5: "Внутренний отступ",
                label6: "БЛОК",
                label7: "КОЛОНКА",
                label8: "Внешний отступ",
                label9: "ПЛИТКА",
                label10: "Вертикальное выравнивание",
                label11: "Параметры кнопки",
                label12: "Цвет фона кнопки",
                label13: "Цвет текста кнопки",
                text: "Углы кнопки",
                label14: "Фон",
                label15: "Цвет",
                label16: "Изображение",
                label17: "Сбросить",
                resetBlock: "Сбросить блок",
                label18: "Выравнивание",
                label19: "Радиус закругления углов",
                label20: "углы",
                label21: "Имя блока",
                label22: "Параметры уведомлений по электронной почте",
                label23: "Отправить уведомление по электронной почте",
                attrLabel8: "Включить пользовательское сообщение",
                attrLabel9: "Включить содержимое следующего раздела",
                attrLabel10: "Включить полный контент",
                attrLabel11: "Короткий обзор",
                attrLabel12: "Заголовок",
                attrLabel13: "Сообщение",
                defaultWaitLabel: "Следующий раздел был опубликован в {{space_name}}",
                defaultWaitMessage: "Привет, {{first_name}} — просто хотел сообщить, что следующий раздел в {{space_name}} был опубликован. {{space_link_button}}"
              },
              takeSurvey: {
                text: "Этот опрос пуст",
              },
              tiptapAi: {
                placeholder: "Напишите краткое описание",
                label:
                  "Пожалуйста, напишите 2 или 3 фразы, описывающие ваше предложение.",
                placeholder2: "например, коучи, консультанты и энерготерапевты",
                label2: "Для кого предназначено это предложение?",
                text: "Здравствуйте! Мы тестируем новую функцию искусственного интеллекта. Хотите ли вы, чтобы Sutra автоматически генерировала текст для вашего шаблона с помощью ИИ?",
              },
              uploader: {
                label1: "Ссылка",
                label2: "Embed-код",
                placeholder: "Вставьте ссылку на изображение или файл",
                label3: "Вставить ссылку",
                placeholder2: "Вставить Embed-код -> <iframe>...</iframe>",
                label4: "Вставить Embed-код",
                saveEmbed: "Сохранить  Embed-код",
                placeholder3: "Поиск изображений",
                saveImage: "Сохранить изображение",
              },
              viewPage: {
                text: "Эта страница пуста",
              },
            },
          },
          users: {
            payment: {
              completePayment: "Завершить оплату",
              join: "Присоединиться к спейсу",
              pay: "Оплатить",
            },
            stepper: {
              step1: "Шаг 1",
              step1Description: "Войти в систему",
              step2: "Шаг 2",
              step2Description: "Завершить платеж",
              step3: "Шаг 3",
              step3Description: "Получить доступ",
            },
            start: {
              title: "Добро пожаловать",
              subtitle: "Пожалуйста, введите адрес вашей эл. почты ниже ",
              email: "Email",
              submit: "Продолжить",
              invalidEmailErrorMsg: "Неверный адрес эл. почты",
            },
            signIn: {
              title: "Войти в свой аккаунт",
              subtitle: "Ещё нет аккаунта?",
              subtitleLink: "Зарегистрироваться",
              email: "Email",
              password: "Пароль",
              submit: "Войти",
              magicLink: "Получить волшебную ссылку для входа",
              forgotPassword: "Забыли пароль?",
              invalidEmailErrorMsg: "Неверный адрес эл. почты",
            },
            signUp: {
              invalidEmailErrorMsg: "Неверный адрес эл. почты",
              invalidPasswordErrorMsg:
                "Пароль должен содержать не менее 8 символов",
              invalidFirstNameErrorMsg:
                "Имя должно содержать не менее 3 символов",
              title: "Создать новый аккаунт",
              subtitle: "Уже есть аккаунт?",
              subtitleLink: "Войти",
              firstName: "Имя",
              lastName: "Фамилия",
              username: "Имя пользователя",
              email: "Email",
              password: "Пароль",
              emailNewsletterConfirmation:
                "Подпишитесь на рассылку новостей от Sutra, чтобы узнать о бесплатных вебинарах по групповому процессу, трансформационному обучению и бизнесу, основанному на взаимоотношениях.",
              submit: "Зарегистрироваться",
              legalPrefix: "Регистрируясь на Sutra, вы соглашаетесь с нашими",
              terms: "Условия использования",
              privacy: "Политикой конфиденциальности",
            },
            spaceReference: {
              total: "Итоговая сумма сегодня",
            },
            planReference: {
              total: "Итоговая сумма",
              total_today: "Итоговая сумма сегодня",
              due_in: "Оплата через 14 дней",
              paymentDetails: {
                annual: "Годовая подписка на",
                month: "Ежемесячная подписка на",
              },
            },
            resetPassword: {
              title: "Сбросить пароль",
              subtitle:
                "Пожалуйста, введите новый пароль вашего аккаунта Sutra ниже",
              password: "Пароль",
              passwordConfirmation: "Подтверждение пароля",
              submit: "Сбросить пароль",
            },
            recover: {
              title: "Забыли пароль?",
              subtitle:
                "Пожалуйста, введите адрес своей эл. почты ниже, и мы вышлем вам инструкции по сбросу пароля",
              email: "Email",
              submit: "Отправьте мне инструкции",
              footer: "Уже есть аккаунт?",
              footerLink: "Войти",
            },
            magicLink: {
              invalidEmailErrorMsg: "Неверный адрес эл. почты",
              title: "Запросить волшебную ссылку",
              subtitle:
                "Пожалуйста, введите адрес своей эл. почты ниже, и мы отправим вам ссылку, по которой вы автоматически войдете в систему.",
              email: "Email",
              submit: "Продолжить",
              footer: "Войти с помощью эл. почты",
              alertTitle: "Вы почти вошли...",
              alertMessage:
                "Мы отправили волшебную ссылку на вашу электронную почту. ЭТО ТЕСТОВОЕ СООБЩЕНИЕ, ФАКТИЧЕСКАЯ ЭЛЕКТРОННАЯ ПОЧТА НЕ ОТПРАВЛЯЕТСЯ.",
            },
            edit: {
              title: "Пожалуйста, заполните свой профиль",
              addProfileImage: "Загрузить фото профиля",
              removeProfileImage: "Удалить фото профиля",
              bioPlaceholder: "Расскажите немного о себе...",
              bio: "Биография",
              submit: "Сохранить",
              skip: "Пропустить и сделать это позже",
            },
            magicSignin: {
              text: "Вход в систему...",
            },
            profile: {
              button: "Сообщение",
              button2: "Редактировать профиль",
              title: "Спейсы",
            },
            profileModal: {
              profile: "Профиль",
              message: "Сообщение",
              editProfile: "Редактировать профиль",
              openProfile: "Открыть профиль",
              spaces: "Спейсы",
              selectImage: "Выбрать изображение",
            },
            signOut: {
              loggedOUt: "Вы вышли из аккаунта",
              title: "Вы вышли из аккаунта",
              message: "До скорого!",
            },
          },
          errors: {
            basic: "Ой! Что-то пошло не так!",
          },
        },
      },
      pt: {
        translation: {
          premiumFeatures: {
            personal: {
              description1:
                "Espaços e membros ilimitados para organizar o conteúdo e conversas de todos os seus cursos e comunidades",
              description2:
                "Tenha landing pages e registration pages modernas e responsivas para todos os seus programas e eventos em um só lugar",
              description3:
                "Envie newsletters para todos os seus participantes com nosso poderoso recurso de transmissão",
            },
            professional: {
              description1: "Sem taxas de transação sobre pagamentos recebidos",
              description2:
                "Opções de personalização de branding e layout e customização do subdomínio",
              description3:
                "Automações ilimitadas e experiências de aprendizagem interativas e conversacionais",
              description4: "Até 2 administradores para todos os seus espaços",
            },
            organization: {
              description1: "Sem taxas de transação sobre pagamentos recebidos",
              description2: "Suporte ao cliente avançado",
              description3: "Administradores ilimitados",
              description4: "Opção para integração de SSO (Single Sign On)",
            },
          },
          premiumMessages: {
            can_auto_tag: {
              title: "Upgrade to use this feature",
              message: "Auto tagging with AI is a premium feature available on the Professional plan."
            },
            can_use_zoom_recordings: {
              title: "Upgrade to use this feature",
              message: "Zoom recordings is a premium feature available on the Organization plan."
            },
            can_create_quizzes: {
              title: "Upgrade to use this feature",
              message: "Quizzes are a premium feature available on the Organization plan."
            },
            can_duplicate_spaces: {
              title: "Upgrade to use this feature",
              message:
                "Duplicating a space is a premium feature available on the Professional plan.",
            },
            can_save_templates: {
              title: "Upgrade to use this feature",
              message:
                "Saving as space as a reusable template is a premium feature available on the Professional plan.",
            },
            can_edit_shareable_link: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Personalizar o link do seu espaço é um recurso premium disponível no plano Professional.",
            },

            can_upload_cover: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Personalizar a imagem da capa dos seus espaços é um recurso premium disponível no plano Personal.",
            },

            can_upload_logo: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Personalizar o logotipo é um recurso premium disponível no plano Professional.",
            },

            can_set_layout: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Personalizar o layout do seu espaço é um recurso premium disponível no plano Professional.",
            },

            can_set_theme: {
              title: "Fazer para usar esse recurso",
              message:
                "Personalizar a paleta de cores do seu espaço é um recurso premium disponível no plano Professional.",
            },

            can_set_format: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Alterar o formato da página do seu espaço é um recurso premium disponível no plano Professional.",
            },

            can_add_step_send_to_pending: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Definir novos membros como pendentes é um recurso premium disponível no plano Personal.",
            },

            can_add_step_send_email: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Automatizar o envio de e-mails é um recurso premium disponível no plano Personal.",
            },

            can_add_add_to_existing_space: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Adicionar automaticamente membros a espaços selecionados é um recurso premium disponível no plano Professional.",
            },

            can_add_step_wait: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Adicionar uma condição de espera para etapas adicionais é um recurso premium disponível no plano Professional.",
            },

            can_add_step_webhook: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Adicionar webhooks é um recurso premium disponível no plano Professional.",
            },

            can_add_step_survey_person: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Enviar questionários para novos membros é um recurso premium disponível no plano Professional.",
            },

            can_schedule_states: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Opções de agendamento automatizado para o seu espaço são um recurso premium disponível no plano Professional.",
            },

            can_broadcast: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Transmitir e-mails e mensagens automatizadas para membros é um recurso premium disponível no plano Personal.",
            },

            can_survey: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Criar questionários é um recurso premium disponível no plano Professional.",
            },

            can_use_interest_form_block: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Adicionar formulários de interesse é um recurso premium disponível no plano Professional.",
            },
            can_use_interactive_block: {
              title: "Atualize para usar este recurso",
              message:
                "Adicionar blocos interativos é um recurso premium disponível no plano Professional.",
            },

            can_set_flexible_payments: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Definir opções de pagamento flexíveis é um recurso premium disponível no plano Professional.",
            },

            can_activate_completions: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Marcar módulos como concluídos é um recurso premium disponível no plano Professional.",
            },

            max_allowed_spaces: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Olá! Parece que você já usou todos os seus espaços gratuitos! Atualize para o plano Personal e tenha espaços e membros ilimitados para que você possa criar praticamente qualquer coisa.",
            },
            max_allowed_ij: {
              title: "Upgrade to use this feature",
              message:
                "You can only add up to 5 discussion prompts per space on the free plan. Please upgrade to the Professional plan to add unlimited discussion prompts to any space.",
            },

            max_allowed_managers_pro: {
              title: "Fazer upgrade para adicionar mais administradores",
              message:
                "Faça o upgrade para o plano Professional para adicionar até 2 administradores ao seu espaço ou para o plano Organization para ter um numero ilimitado de administradores.",
            },

            max_allowed_managers_org: {
              title: "Fazer upgrade para adicionar mais administradores",
              message:
                "Faça o upgrade para o plano Organization para ter um numero ilimitado de administradores nos seus espaços.",
            },

            can_see_participation_tab: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Métricas de participação são um recurso premium disponível no plano Professional.",
            },

            can_send_selective_broadcast: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Transmissões seletivas são um recurso premium disponível no plano Organization.",
            },

            can_send_message_broadcast: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Transmissões de mensagens e emails são um recurso premium disponível no plano Organization.",
            },
            can_add_from_children: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Adicionar automaticamente membros de outros espaços secundários é um recurso premium disponível no plano Professional.",
            },
            can_add_to_direct_open_children: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Adicionar automaticamente novos membros a todos os espaços secundários abertos é um recurso premium disponível no plano Professional.",
            },
            can_send_message: {
              title: "Fazer upgrade para usar esse recurso",
              message:
                "Enviar automaticamente mensagens diretas para novos membros é um recurso premium disponível no plano Professional.",
            },
          },
          home: {
            sidebar: {
              createButton: "Novo espaço",
              spaces: "Seus espaços",
              invitations: "Convites",
              directMessages: "Mensagens diretas",
              gettingStarted: "Primeiros passos",
              scheduleDemo: "Agendar uma demonstração",
              upgrade: "Atualizar seu plano",
              promoBoxTitle:
                "Junte-se a centenas de criadores usando os recursos avançados do Sutra para fornecer poderosas experiências em grupo online",
              promoBoxItem1: "Espaços ilimitados",
              promoBoxItem2: "Opções completas de personalização de branding",
              promoBoxItem3: "Administradores extras",
              promoBoxButton: "Fazer upgrade agora",
            },
            spacesList: {
              yourSpaces: "Seus espaços",
              directMessagesTitle: "Mensagens diretas",
              invitationsTitle: "Convites",
              topTitle: "Espaços principais",
              managedTitle: "Espaços administrados",
              allTitle: "Todos os espaços",
              archivedTitle: "Espaços arquivados",
              deletedTitle: "Espaços excluídos",
              pendingTitle: "Espaços agendados",
              directMessages: "Diretas",
              top: "Nível superior",
              managed: "Administrados",
              all: "Todos",
              archived: "Arquivados",
              invitations: "Convites",
              deleted: "Excluídos",
              pending: "Agendados",
              empty: "Você não tem nenhum",
              tabTitle: "Sutra - Painel",
              learn: "Aprenda o básico",
              createFirst: "Crie seu primeiro espaço",
            },
          },
          app: {
            payment: {
              payment: {
                tabTitle: "Sutra - Formulário de Pagamento",
                title: "Finalizar pagamento",
              },
              planOption: {
                frequencyDescription1: "por mês",
                frequencyDescription2: "por mês quando faturado anualmente",
                currentPlan: "Plano atual",
                billedMonthly: "Faturado mensalmente",
                billedAnnually: "Faturado anualmente",
              },
              stripeForm: {
                due_in_trial: "Vencimento em 14 dias",
                pay: "Pagar",
              },
            },
            gettingStarted: {
              items: {
                setup: "Configurando o seu espaço do programa",
                privacy: "Configurações de privacidade e pagamento",
                registration: "Criando uma página de registro",
                invitations: "Convidando participantes",
                settings: "Como funcionam as configurações do espaço",
                interactive: "Jornadas interativas",
                interest: "Formulários de interesse",
                appearance: "Layout e temas de cores",
                grids: "Configurações de grade e bloco",
                broadcasts: "Envio de transmissões por e-mail",
                tagging: "Tagging",
                emojis: "Emojis na barra lateral",
              },
              templates: {
                groupprogram: {
                  label: "Programa em grupo",
                  description:
                    "Um espaço de programa onde você pode organizar tudo o que precisa para um grupo de pessoas que aprendem juntas.",
                },
                zoomworkshop: {
                  label: "Workshop no Zoom",
                  description:
                    "Organize tudo o que você precisa para um workshop simples baseado no Zoom.",
                },
                mastermind: {
                  label: "Mastermind",
                  description:
                    "Convide um grupo de pessoas para participar de um processo de mastermind juntas.",
                },
                selfpacedcourse: {
                  label: "Curso em ritmo próprio",
                  description:
                    "Um modelo flexível para criar um curso em ritmo próprio.",
                },
                communityspace: {
                  label: "Espaço da comunidade",
                  description:
                    "Reúna pessoas em um espaço vibrante de comunidade online que cresce à medida que sua comunidade cresce.",
                },
                oneononecoaching: {
                  label: "Coaching individual",
                  description:
                    "Crie um espaço dedicado para cada um de seus clientes de coaching.",
                },
              },
              loading: {
                text: "Seu modelo está carregando, por favor aguarde...",
              },
              tabTitle: "Sutra - Guia de início",
              mainTitle: "Guia de início",
              selectTemplate: "Selecionar este modelo",
              advanceFeatures: "Recursos avançados",
              welcome: "Bem-vindo",
              description1:
                "O Sutra facilita a organização de conteúdo e conversas para cursos, comunidades, coaching individual e muito mais. Aprenda o básico neste vídeo de cinco minutos.",
              watchVideo: "Assistir ao vídeo",
              createFirstSpace: "Crie seu primeiro espaço",
              gettingStartedGuide: "Guia de início",
              description2:
                "Saiba mais sobre como o Sutra funciona e comece com uma lista de lançamento e orientações mais detalhadas.",
              checkOutTheGuide: "Confira o guia",
              startWithATemplate: "Comece com um modelo",
              description3:
                "Aprenda mais sobre como o Sutra funciona e comece com uma lista de lançamento e orientações mais detalhadas.",
              learnAbout: "Conheça os recursos avançados",
              description4:
                "O Sutra foi projetado para ser flexível e poderoso para acomodar quase todos os casos de uso. Você pode criar experiências interativas exclusivas, capturar o interesse em suas várias ofertas, enviar newsletters por e-mail e colher conhecimento coletivo.",
              learnmore: "aprender mais",
            },
            premium: {
              try: "Experimente o Sutra",
              include: "Recursos incluídos",
              upgrade: "Atualize agora",
            },
            pricing: {
              tabTitle: "Sutra - Preços",
              upgrade: "Atualize seu plano",
              text: "Seja qual for sua necessidade para o seu curso ou comunidade, estamos aqui para apoiar seu trabalho. Entre em contato conosco se tiver alguma dúvida em support@sutra.co.",
            },
            scheduleDemo: {
              tabTitle: "Sutra - Agendar uma demonstração",
            },
            welcome: {
              tabTitle: "Sutra - Bem-vindo",
              title: "Bem-vindo ao Sutra!",
              description1:
                "Estamos muito felizes por tê-lo aqui. Por favor, reserve um momento para assistir ao vídeo abaixo...",
              description2:
                "Gostaria de experimentar um dos nossos planos pagos gratuitamente?",
              description3:
                "Escolha um plano abaixo para iniciar um teste gratuito de 14 dias. Após o término do teste, você será automaticamente cobrado pelo plano selecionado.",
              yes: "Sim, por favor, mostre-me as opções",
              no: "Não, obrigado",
            },
          },
          layout: {
            userButton: {
              home: "Início",
              viewProfile: "Ver perfil",
              settings: "Configurações",
              support: "Suporte",
              language: "Idioma",
              terms: "Termos e privacidade",
              logout: "Sair",
              copyright: "Sutra Spaces, LLC © 2022",
            },
            directMessages: {
              label: "Mensagens diretas",
              actionButton: "Ver todas",
              empty: "Você não possui nenhuma mensagem direta",
              directMessage: "Mensagens diretas",
            },
            notifications: {
              label: "Notificações",
              actionButton: "clara fora",
              empty: "Você não possui nenhuma notificação",
              notification: "Notificações",
            },
            headerContent: {
              registrationPage: "Página de registro",
              nonManagersHidden: "Cabeçalho oculto para não gerentes",
            },
            search: {
              reflection: "Reflexão",
              comment: "Comentário",
              allSpaces: "Todos os espaços",
              withinTop: "Dentro do espaço de nível superior",
              withinSpace: "Dentro deste espaço",
              everything: "Tudo",
              titlesOnly: "Somente títulos",
              tagsOnly: "Somente tags",
              content: "Conteúdo",
              discussion: "Discussão",
              events: "Eventos",
              users: "Usuários",
              placeholder: "Buscar...",
              nothingFound: "Nada encontrado",
              search: "Buscar",
            },
            uploader: {
              pending: "Pendente",
              used: "Utilizado",
              uploading: "Enviando",
              finishing: "Finalizando",
              processing: "Processando",
              completed: "Concluído",
            },
          },
          resources: {
            pages: {
              form: {
                label: "Título",
                placeholder: "Qual é o tópico da sua página?",
                create: "Criar página",
                save: "Salvar página",
              },
            },
            form: {
              title: {
                label: "Título",
                placeholder: "Qual é o tópico?",
              },
              publish: {
                label: "Publicar na data",
              },
              date: {
                label: "Escolha data",
              },
              update: "Atualizar coleção de páginas",
              create: "Criar coleção de páginas",
            },
          },
          settings: {
            editProfile: {
              tabTitle: "Sutra - Editar Perfil",
              editProfile: "Editar perfil",
              firstName: "Primeiro nome",
              lastName: "Sobrenome",
              username: "Nome de usuário",
              email: "E-mail",
              removeProfileImage: "Remover imagem de perfil",
              addProfileImage: "Adicionar imagem de perfil",
              about: "Sobre",
              location: "Localização",
              bio: "Biografia",
              bioPlaceholder: "Conte um pouco sobre você...",
              updateProfile: "Atualizar perfil",
            },
            accountSettings: {
              accountSettings: "Configurações da conta",
              changePassword: "Alterar senha",
              currentPassword: "Senha atual",
              newPassword: "Nova senha",
              newPasswordConfirmation: "Confirmação da nova senha",
              updatePassword: "Atualizar senha",
              advanced: "Avançado",
              deleteAccount: "Excluir conta",
              warning: "Aviso",
              deleteAccountWarning:
                "Excluir sua conta é irreversível e removerá permanentemente seus detalhes da conta de nosso sistema.",
              closeYourAccount: "Encerrar sua conta",
              invalidCurrentPasswordErrorMsg: "Não pode ficar vazio",
              invalidPasswordErrorMsg:
                "A senha deve ter pelo menos 8 caracteres",
              invalidPasswordConfirmationErrorMsg: "As senhas não coincidem",
              deleteAccountAlert: {
                title: "Importante",
                message:
                  "Excluir sua conta é irreversível e removerá permanentemente seus detalhes da conta de nosso sistema",
                input: "Digite 'excluir minha conta' para confirmar esta ação",
                confirmButton: "Excluir permanentemente",
                cancelButton: "Cancelar",
              },
              cancelSubscriptionAlert: {
                title: "Importante",
                message: "Por favor, cancele sua assinatura primeiro",
                confirmButton: "Ir para cobrança",
                cancelButton: "Eu farei isso depois",
              },
              stripeAccount: {
                title: "Redefinir conexão com o Stripe",
                alertTitle: "Você tem certeza?",
                alertMessage:
                  "Se você redefinir sua conexão com o Stripe, suas credenciais do Stripe serão removidas de sua conta de usuário no Sutra e você poderá conectar uma nova conta do Stripe.",
                buttonText: "Redefinir conexão",
                confirmButton: "Sim",
                cancelButton: "Não",
                confirmTitle: "Você tem certeza?",
                confirmMessage:
                  "Suas credenciais do Stripe serão removidas de sua conta de usuário no Sutra",
              },
            },
            emailPreferences: {
              tabTitle: "Sutra - Preferências de E-mail",
              emailPreferences: "Preferências de e-mail",
              emailDigestPreferences: "Preferências de resumo de e-mail",
              emailDigestFrequency: "Frequência do resumo de e-mail",
              emailDigestMessage:
                "Para ajustar as preferências de e-mail por espaço, consulte a área de configurações de assinaturas. Você pode cancelar a assinatura de um espaço completamente deixando-o ou pode definir qualquer espaço individual como 'Silenciar' nas configurações de assinaturas.",
              receiveWeeklyDigest:
                "Receber um resumo semanal de itens e discussões marcados recentemente",
              receiveAtMentions:
                "Receber uma notificação quando alguém mencionar você com @",
            },
            subscriptions: {
              subscriptions: "Preferências específicas do espaço",
              searchBySpaceName: "Buscar por nome do espaço",
              search: "Buscar",
              clear: "Limpar",
              options: "Opções",
              otherActions: "Outras ações",
              emptySubscriptions: "Você não possui nenhuma assinatura.",
              leaveSpaceConfirmationTitle:
                "Tem certeza de que deseja sair deste espaço?",
              leaveSpaceConfirmationHtml:
                "Você deixará de ser membro deste espaço e talvez não consiga participar novamente sem pedir ao gerente.",
              leaveSpaceButtonText: "Sim, sair",
              leaveSpaceDenyButtonText: "Não",
              leaveSpace: "deixe espaço",
              unsubscribe: "Cancelar inscrição",
              unsubscribed: "Cancelada inscrição",
            },
            digestPreferences: {
              digestedLabel: "Agrupar todas as atualizações em um resumo",
              digestedDescription:
                "Novas mensagens em todos os seus espaços serão agrupadas em uma atualização de e-mail.",
              undigestedLabel: "Agrupar atualizações por espaço",
              undigestedDescription:
                "Você receberá resumos de e-mail separados para cada espaço que tiver novas mensagens.",
            },
            
            billing: {
              statuses: {
                pending: "Pendente",
                processing: "Processando",
                paid: "Pago",
              },
              paymentMethod: {
                currentPaymentMethod: "Método de pagamento atual",
                noCurrentPaymentMethod: "Nenhum método de pagamento atual",
                addNewPaymentMethod: "Adicionar novo método de pagamento",
              },
              stripe: {
                updatePaymentMethod: "Atualizar método de pagamento",
              },
              tabTitle: "Sutra - Faturamento",
              updatePaymentMethodMayus: "ATUALIZAR MÉTODO DE PAGAMENTO",
              payments: "PAGAMENTOS",
              pdf: "PDF",
              noPayments: "Você não possui histórico de pagamento",
              billing: "Faturamento",
              upgradePlan: "Atualizar plano",
              changePlan: "Alterar plano",
              updatePaymentMethod: "Atualizar método de pagamento",
              cancelSubscription: "Cancelar assinatura",
              cancelConfirmationTitle:
                "Tem certeza de que deseja cancelar sua assinatura?",
              cancelConfirmationHtml:
                "Você não terá mais acesso aos recursos premium do seu plano.",
              cancelConfirmButtonText: "Sim, cancelar",
              cancelDenyButtonText: "Não",
              billingHistory: "Histórico de faturamento",
              tableDate: "Data",
              tableAmount: "Valor",
              tableStatus: "Status",
              tableInvoice: "Fatura",
              planPrefix: "Você está atualmente no",
              planSuffix: "plano",
              updatePayment: {
                success: {
                  title: "Sucesso",
                  message: "Seu método de pagamento foi atualizado",
                },
                error: {
                  title: "Ops, algo deu errado!",
                },
              },
            },
            subscriptions: {
              frequencyOptions: "Opções de frequência",
              otherActions: "Outras ações",
            },
            frequencyOptions: {
              mute: "Silenciar",
              always: "A cada mensagem",
              hour1: "A cada hora",
              hour2: "A cada 2 horas",
              hour4: "A cada 4 horas",
              hour8: "A cada 8 horas",
              hour24: "Uma vez por dia",
              hour168: "Uma vez por semana",
              hour336: "A cada duas semanas",
            },
            affiliates: {
              history: {
                empty: "Você não tem histórico de pagamento de afiliado",
                table: {
                  referralName: "Referência Nome",
                  startAt: "começar no",
                  endAt: "Fim em",
                  createdAt: "Criado em",
                  percent: "Percentual",
                  total: "Total ganho",
                  status: "Status",
                  value: "Valor",
                  revenue: "Receita",
                  payAt: "Pagar em",
                  status: "Status",
                },
                statuses: {
                  null: "Pendente",
                  pending: "Pendente",
                  processing: "Processando",
                  paid: "Pago",
                },
                paymentsHistory: "Histórico de pagamentos",
              },
              tabTitle: "Sutra - Afiliados",
              title: "Afiliados",
              affiliateLink: "Este é o seu link de afiliado",
              copyMessage: "Link copiado para a área de transferência",
              stripeTitle: "Stripe conectado a",
              stripeConnected:
                "Este é o ID da sua conta Stripe. Para encontrar este ID no Stripe, clique no link para fazer login no Stripe e role até o final da página de configurações da sua conta.",
              stripeDisconnect: "Desconectar",
              description:
                "Para processar pagamentos para o programa de afiliados, conecte sua conta Stripe clicando no botão abaixo.",
              description2:
                "Ao compartilhar este link com outras pessoas, você receberá automaticamente 40% durante o primeiro ano de qualquer receita gerada por qualquer pessoa que se inscrever em uma conta paga do Sutra.",
              connect: "Conectar Stripe",
              historyLabel: "Histórico de afiliados",
            },
          },
          spaces: {
            settings: {
              layout: {
                navbarContent: {
                  mainSettings: "Configurações Gerais",
                  members: "Membros",
                  broadcast: "Transmissão",
                  surveys: "Enquete",
                  structures: "Estruturas",
                  sutraAdmin: "Administração do Sutra",
                  scheduled: "Agendado",
                  archived: "Arquivado",
                  participation: "Participação",
                },
                headerActions: {
                  shareableLink: "LINK COMPARTILHÁVEL",
                },
              },
              main: {
                title: "Configurações Gerais",
                main: {
                  invite: "Convidar",
                  shareableLink: "Link compartilhável",
                  cancelLink: "Cancelar",
                  saveLink: "Salvar",
                  editLinkButton: "Editar link",
                  subdomain: "Seu subdomínio",
                  sutra_co: ".sutra.co",
                  label1: "Link copiado para a área de transferência",
                  privacySetting: "Selecione uma configuração de privacidade",
                  title1: "Tornar este espaço acessível publicamente",
                  description1:
                    "Este espaço está dentro de um espaço privado e só é visível para os membros desse espaço. Você pode torná-lo acessível publicamente fora deste espaço ativando essa configuração.",
                  title2: "Exibir página de registro",
                  description2:
                    "Não membros verão uma página de registro quando tentarem acessar este espaço se essa configuração estiver ativada.",
                  title3: "Usar página de registro legada",
                  description3:
                    "Se essa configuração estiver ativada, este espaço usará a página de registro projetada usando nosso editor legado.",
                  onButton: "ATIVADO",
                  offButton: "DESATIVADO",
                  editButton: "Editar página",
                  previewButton: "Visualizar página",
                  label2: "Configurações de privacidade e pagamento",
                  label3: "Configurações da página de registro",
                  description4:
                    "Ativo - As pessoas serão direcionadas para uma página de registro antes de acessar este espaço",
                  label4: "Automação",
                  description5:
                    "Agende e-mails e adicione automaticamente pessoas a espaços quando elas se registrarem",
                  label5: "Configurações de agendamento",
                  description6:
                    "Defina uma data de evento ou opções de publicação e arquivamento",
                  label6: "Configurações adicionais",
                  description7:
                    "Permissões de criação de conteúdo, visibilidade de membros, notificações de gerentes e espaços pais",
                  label7: "Configurações de SEO",
                  description8:
                    "Defina título, descrição e imagem de meta tag para compartilhamento em redes sociais e otimização de mecanismos de busca",
                  label8: "Aberto",
                  description9: "Qualquer pessoa pode acessar este espaço",
                  label9: "Privado",
                  description10:
                    "Este espaço é privado e as pessoas devem ser convidadas para participar",
                  label10: "Pago",
                  description11:
                    "Este espaço é pago e privado. As pessoas devem pagar para participar",
                  description12:
                    "As pessoas serão direcionadas para uma página de registro antes de acessar este espaço aberto.",
                  description13:
                    "As pessoas serão direcionadas diretamente para este espaço ao acessar este espaço aberto.",
                  description14:
                    "As pessoas devem ser convidadas diretamente para acessar este espaço privado. Se forem convidadas, elas verão uma página de registro.",
                  description15:
                    "As pessoas devem ser convidadas diretamente para acessar este espaço privado. Se forem convidadas, elas verão uma página de criação de conta sem página de registro.",
                  description16:
                    "As pessoas serão direcionadas para uma página de registro antes de acessar este espaço pago.",
                  description17:
                    "As pessoas serão direcionadas para uma página de criação de conta e pagamento antes de poderem acessar este espaço pago. Eles não verão uma página de registro.",
                  on: "ATIVADO",
                  off: "DESATIVADO",
                  label11: "Membros",
                  label12: "Pendentes",
                  label13: "Interessados",
                  label14: "Convidados",
                  label15: "Pagamentos",
                  label16: "Branding",
                },
                additional: {
                  allowMembersEdit:
                    "Permitir que os membros editem o conteúdo desta página",
                  membersWillAddContent:
                    "Os membros poderão adicionar e editar o conteúdo nesta página",
                  allowMembersCreatePosts:
                    "Permitir que os membros criem novas postagens",
                  membersWillCreatePosts:
                    "Os membros poderão criar novas postagens neste espaço",
                  allowMembersCreateEvents:
                    "Permitir que os membros criem novos eventos",
                  membersWillCreateEvents:
                    "Os membros poderão criar novos eventos neste espaço",
                  allowMembersCreateSubspaces:
                    "Permitir que os membros criem novos subespaços",
                  membersWillCreateSubspaces:
                    "Os membros poderão criar subespaços nesta página",
                  membersCanCreate: "Os membros podem criar",
                  circleCreation: {
                    options: {
                      discussion: "Espaços de discussão",
                      content: "Espaços de conteúdo",
                      event: "Espaços de evento",
                    },
                    placeholder:
                      "Selecione os tipos de espaços que os membros podem criar neste espaço",
                  },
                  allowMembersToCopy: {
                    description1:
                      "Permitir que os membros copiem membros entre espaços",
                    description2:
                      "Os membros terão a opção de adicionar automaticamente membros de outros círculos",
                  },
                  podType: {
                    description1:
                      "Todos os membros podem enviar mensagens neste espaço",
                    description2:
                      "Se esta opção estiver desativada, apenas os gerentes poderão enviar mensagens",
                  },
                  allowReflections: {
                    description1: "Ativar reflexões para este espaço",
                    description2:
                      "Se esta opção estiver desativada, a opção de reflexão não será exibida nas mensagens",
                  },
                  showMembers: {
                    description1: "Mostrar membros",
                    description2: "Mostrar o diretório de membros deste espaço",
                  },
                  hideMmemberEmails: {
                    description1:
                      "Exibir e-mails dos membros no diretório de membros",
                    description2:
                      "Se esta opção estiver desativada, os e-mails dos membros não serão listados no diretório",
                  },
                  newMemberNotifications: {
                    description1:
                      "Notificar os gerentes quando novos membros ingressarem",
                    description2:
                      "Enviar um e-mail de notificação para cada novo cadastro de membro",
                  },
                  podSize: {
                    description1:
                      "Limitar o número de pessoas que podem ingressar neste espaço",
                    description2:
                      "Especificar um número máximo de pessoas que podem ingressar neste espaço",
                  },
                  podSizeValue: {
                    description:
                      "Quantas pessoas podem ingressar neste espaço?",
                  },
                  sidebarDefaultOpened: {
                    description1: "Espaço aberto por padrão na barra lateral",
                    description2:
                      "Se esta opção estiver ativada, este espaço será expandido por padrão na barra lateral",
                  },
                  sidebarHideChildren: {
                    description1: "Ocultar subespaços na barra lateral",
                    description2:
                      "Se esta opção estiver ativada, os subespaços criados neste espaço não aparecerão na barra lateral",
                  },
                  allowCompletions: {
                    description1: "Mostrar botão de marcar como concluído",
                    description2:
                      'Se estaopção estiver ativada, os membros verão um botão "Marcar como concluído" para este espaço. Depois de selecionar essa opção, o ícone na barra lateral será substituído por uma marca de seleção.',
                  },
                  redirectToSubspace: {
                    description1: "Redirecionar sempre para o subespaço",
                    description2:
                      "Se esta opção estiver ativada, os membros sempre serão redirecionados para o subespaço selecionado ao acessar este espaço.",
                  },
                  parentCircles: {
                    description1: "espaços pai",
                    placeholder:
                      "Pesquise espaços para adicionar como pais deste espaço",
                  },
                },
                scheduling: {
                  showStartDate: {
                    description1: "Exibir este espaço como um evento",
                    description2:
                      "Este espaço exibirá uma data de evento onde quer que seja listado",
                  },
                  startDateTime: {
                    title: "Data e horário de início",
                    placeholder: "Selecionar data",
                  },
                  endDateTime: {
                    title: "Data e horário de término",
                    placeholder: "Selecionar data",
                  },
                  showLaunchDate: {
                    description1: "Publicar este espaço em uma data",
                    description2:
                      "Este espaço se tornará visível e acessível na data especificada",
                  },
                  launchDateTime: {
                    placeholder: "Selecionar data",
                  },
                  showArchiveDate: {
                    description1: "Arquivar este espaço em uma data",
                    description2:
                      "Este espaço não estará mais acessível na data especificada",
                  },
                  archiveDate: {
                    placeholder: "Selecionar data",
                  },
                  showReadonlyDate: {
                    description1:
                      "Tornar este espaço somente leitura em uma data",
                    description2:
                      "Nenhuma nova mensagem poderá ser enviada após a data especificada",
                  },
                  readonlyDate: {
                    placeholder: "Selecionar data",
                  },
                },
                seo: {
                  title: {
                    title: "Título da tag",
                    description:
                      "Este é o título que será exibido nos resultados dos motores de busca e compartilhamentos nas redes sociais. O tamanho ideal é de 60 caracteres.",
                  },
                  description: {
                    title: "Descrição meta",
                    description:
                      "Esta é a descrição que será exibida nos resultados dos motores de busca e compartilhamentos nas redes sociais. O tamanho ideal é de 150 a 350 caracteres.",
                  },
                  image: {
                    title: "Imagem meta",
                    description:
                      "Esta é a imagem que será exibida nos compartilhamentos nas redes sociais. A dimensão recomendada é de 1200 pixels x 627 pixels (proporção 1.91/1).",
                  },
                },
              },
              members: {
                title: "Membros",
                members: {
                  download: "Baixar CSV",
                  invite: "Convidar",
                  member: "Membros",
                  invited: "Convidados",
                  interested: "Interessados",
                  payment: "Pagamentos",
                },
                interested: {
                  interested: {
                    button: "Procura",
                    placeholder: "Procura para mais",
                    broadcast: "enviar broadcast",
                    noRecordFound: "Nenhum registro encontrado",
                  },
                  table: {
                    firstname: "Primeiro nome",
                    email: "E-mail",
                    date: "Data",
                  },
                  interestedrow: {
                    removeFromList: "Remover da lista",
                  },
                },
                invited: {
                  invited: {
                    button: "Procura",
                    placeholder: "Procura para mais",
                    noRecordFound: "Nenhum registro encontrado",
                  },
                  table: {
                    email: "E-mail",
                    invitationlink: "Link de convite",
                    amount: "Quantia",
                    date: "Data de envio",
                  },
                },
                invitedrow: {
                  invitation: {
                    resend: "Reenviar convite",
                    cancel: "Cancelar convite",
                  },
                },
                inviteEmail: {
                  invite: {
                    placeholder:
                      "Digite o endereço de e-mail ou mencione as pessoas que você deseja adicionar a este espaço. As entradas podem ser separadas por vírgulas, espaços ou novas linhas.",
                    message: "Adicionar uma mensagem de boas-vindas opcional",
                    checkboxLabel:
                      "Enviar e-mail para notificar as pessoas que foram convidadas",
                    alertMessage:
                      "Você está atualmente em um plano básico e não pode enviar convites pelo Sutra. Quando você clicar em convidar, um link de convite exclusivo será gerado para cada endereço de e-mail que você adicionar. Você poderá enviar um e-mail pessoal para cada pessoa com este link.",
                    alertbutton: "Atualizar conta",
                    button: "Convidar pessoas",
                  },
                },
                inviteExisting: {
                  invite: {
                    copyMembers: "Copiar membros de outro espaço",
                    copyMembersPlaceholder:
                      "Selecione os espaços dos quais deseja copiar membros",
                    addActiveMembers:
                      "Adicionar membros ativos de espaços superiores",
                    addActiveMembersPlaceholder:
                      "Selecione os membros ativos para adicionar a este espaço",
                    addPendingMembers:
                      "Adicionar membros pendentes de espaços superiores",
                    addPendingMembersPlaceholder:
                      "Selecione os membros pendentes para adicionar a este espaço",
                    checkboxLabel:
                      "Enviar e-mail para notificar as pessoas que foram convidadas",
                    alert:
                      "Você está atualmente em um plano básico e não pode enviar convites pelo Sutra. Quando você clicar em convidar, um link de convite exclusivo será gerado para cada endereço de e-mail que você adicionar. Você poderá enviar um e-mail pessoal para cada pessoa com este link.",
                    alertButton: "Atualizar conta",
                    button: "Convidar pessoas",
                    thisOption:
                      "Esta opção não está habilitada para este espaço",
                  },
                },
                inviteLink: {
                  invite: {
                    shareLinkText:
                      "As pessoas podem se registrar neste espaço diretamente com o seguinte link. Você pode compartilhar este link onde quiser (e-mail, rede social, etc).",
                    youCannotShare:
                      "Você não pode compartilhar um link para um espaço privado.",
                  },
                },
                invitePeople: {
                  invite: {
                    invitePeople: "Convidar pessoas",
                  },
                },
                membersActivity: {
                  members: {
                    placeholder: "Procurar para",
                    button: "Procurar",
                    emailButton: "E-mail",
                    messageButton: "Mensagem",
                    noRecordFound: "Nenhum registro encontrado",
                  },
                  memberTable: {
                    noRecordFound: "Nenhum registro encontrado",
                    lastSeen: "Última visualização",
                    lastPosted: "Última publicação",
                    date: "Data de entrada",
                  },
                },
                participationActivity: {
                  participation: {
                    searchPlaceholder: "Pesquisar por",
                    searchButton: "Pesquisar",
                    pickDatePlaceholder: "Selecionar intervalo de datas",
                    emailButton: "E-mail",
                    messageButton: "Mensagem",
                    noRecordFound: "Nenhum registro encontrado",
                  },
                  memberTable: {
                    member: "Membro",
                    participation: "Participação",
                    posts: "Publicações",
                    words: "Palavras",
                  },
                },
                participationActivityRow: {
                  posts: "publicações",
                },
                paymentRow: {
                  payment: {
                    refundPayment: "Reembolsar pagamento",
                  },
                },
                payments: {
                  payment: {
                    searchPlaceholder: "Pesquisar por",
                    searchButton: "Pesquisar",
                    noRecordFound: "Nenhum registro encontrado",
                  },
                  paymentTable: {
                    member: "Membro",
                    amount: "Quantia",
                    date: "Data",
                    status: "Status",
                  },
                },
                progressActivity: {
                  progress: {
                    searchPlaceholder: "Pesquisar por",
                    searchButton: "Pesquisar",
                    emailButton: "E-mail",
                    messageButton: "Mensagem",
                    noRecordFound: "Nenhum registro encontrado",
                    progressTracking:
                      "O acompanhamento de progresso está disponível apenas em espaços que possuem prompts interativos.",
                  },
                  progressTable: {
                    member: "Membro",
                    progress: "Progresso",
                  },
                },
                registered: {
                  registered: {
                    searchPlaceholder: "Pesquisar por",
                    searchButton: "Pesquisar",
                    email: "E-mail",
                    message: "Mensagem",
                    noRecordFound: "Nenhum registro encontrado",
                    pending: "Pendente",
                    manager: "Gerentes",
                    all: "Todos",
                    active: "Ativos",
                  },
                  registeredTable: {
                    member: "Membro",
                    email: "E-mail",
                    status: "Status",
                    role: "Função",
                    roleApproveAll: "Aprovar todos",
                  },
                },
                registeredRow: {
                  registered: {
                    roleApprove: "Aprovar",
                    joined: "Ingressou",
                    sendMessage: "Enviar mensagem",
                    stopCharging: "Parar cobrança",
                    emailPreferences: "Preferências de e-mail",
                    removeMember: "Remover membro",
                  },
                },
              },
              payment: {
                charging: {
                  NumberOfTimes: {
                    howManyTimes: "Quantas vezes?",
                  },
                  UntilDate: {
                    pickDate: "Selecionar uma data de término para a cobrança",
                  },
                },
                fees: {
                  description: {
                    fees: "TAXAS:",
                    sutraFee: "TAXA SUTRA +",
                    stripeProcessingFee: "TAXAS DE PROCESSAMENTO DO STRIPE",
                  },
                },
                flexible: {
                  flexible: {
                    description:
                      "Para oferecer opções flexíveis de pagamento, como um modelo de pague o quanto puder ou várias camadas de pagamento, você pode criar uma pesquisa de pagamento que as pessoas verão ao se registrar em seu espaço. Clique no botão de edição abaixo para personalizar a pesquisa.",
                  },
                },
                once: {
                  once: {
                    description: "Quanto você irá cobrar?",
                    number: "10",
                  },
                },
                recurring: {
                  recurring: {
                    description1: "Quanto você irá cobrar?",
                    description2: "Selecione uma opção de cobrança",
                  },
                },
              },
              privacy: {
                open: {
                  open: {
                    description: "Qualquer pessoa pode acessar este espaço",
                  },
                },
                paid: {
                  paid: {
                    stripeConnected: "Conectado ao Stripe",
                    disconnectButton: "Desconectar",
                    description:
                      "Para processar pagamentos para este espaço, por favor conecte sua conta do Stripe clicando no botão abaixo.",
                    connectButton: "Conectar com o Stripe",
                  },
                },
                private: {
                  private: {
                    description:
                      "Este espaço é privado e as pessoas devem ser convidadas para participar",
                  },
                },
              },
              registration: {
                steps: {
                  addFromSubSpace: {
                    label: "Selecionar um espaço para adicionar membro",
                    description:
                      "Em algumas situações, as pessoas podem ingressar em um subespaço sem ingressar no espaço pai. Às vezes, você deseja garantir que elas também sejam adicionadas a um espaço de nível superior ou a algum espaço compartilhado. Essa opção permite fazer isso. Sempre que alguém ingressar em um subespaço aqui, eles serão automaticamente adicionados a quaisquer espaços que você selecionar abaixo.",
                    placeholder: "Selecionar espaços",
                    saveButton: "Salvar etapa",
                    deleteButton: "Excluir etapa",
                    saveButton2: "Salvar etapa",
                  },
                  addStructure: {
                    label1: "Selecionar estrutura",
                    placeholder1: "Selecionar uma opção",
                    label2: "Enviar como",
                    placeholder2: "Selecionar uma opção",
                    saveButton: "Salvar etapa",
                  },
                  addToExistingSpace: {
                    label: "Selecionar um espaço para adicionar membro",
                    placeholder: "Selecionar espaços",
                    saveButton1: "Salvar etapa",
                    deleteButton: "Excluir etapa",
                    saveButton2: "Salvar etapa",
                  },
                  addToNewSpace: {
                    placeholder: "Nome do novo espaço",
                    label: "Criar um nome para o novo espaço",
                    saveButton1: "Salvar etapa",
                    deleteButton: "Excluir etapa",
                    saveButton2: "Salvar etapa",
                  },
                  applyTemplate: {
                    label: "Selecionar um espaço para copiar",
                    description:
                      "Às vezes, você deseja criar um espaço modelo e replicá-lo sempre que um novo espaço for criado. Você pode criar um espaço e configurá-lo exatamente como desejar. Em seguida, todo o conteúdo e subespaços serão copiados para qualquer novo espaço criado aqui.",
                    saveButton1: "Salvar etapa",
                    deleteButton: "Excluir etapa",
                    saveButton2: "Salvar etapa",
                  },
                  editEmail: {
                    placeholder1: "Linha de assunto",
                    label1: "Enviar como",
                    placeholder2: "Selecionar uma opção",
                    label2: "Enviar para",
                    placeholder3: "Selecionar uma opção",
                    placeholder4: "alguem@especial.com",
                    label3: "Endereço de e-mail",
                    saveButton: "Salvar etapa",
                    deleteButton: "Excluir etapa",
                  },
                  editStructure: {
                    deleteButton: "Excluir etapa",
                  },
                  editSurvey: {
                    deleteButton: "Excluir etapa",
                  },
                  sendEmail: {
                    label1: "Selecionar e-mail",
                    placeholder1: "Selecionar uma opção",
                    label2: "Enviar como",
                    placeholder2: "Selecionar uma opção",
                    label3: "Enviar para",
                    placeholder3: "Selecionar uma opção",
                    label4: "alguem@especial.com",
                    placeholder4: "Endereço de e-mail",
                    saveButton: "Salvar etapa",
                    label5: "Gerentes",
                    label6: "Endereço de e-mail personalizado",
                  },
                  setToPending: {
                    title: "Mensagem de boas-vindas",
                    description:
                      "Este é o conteúdo que as pessoas verão logo após secadastrarem no seu espaço. Este é um bom lugar para explicar quando o programa será lançado e o que elas podem esperar enquanto isso.",
                    saveButton1: "Salvar etapa",
                    deleteButton: "Excluir etapa",
                    saveButton2: "Salvar etapa",
                  },
                  surveyPerson: {
                    placeholder: "Selecionar uma opção",
                    label: "Selecionar enquete",
                    saveButton1: "Salvar etapa",
                    deleteButton: "Excluir etapa",
                    saveButton2: "Salvar etapa",
                  },
                  wait: {
                    description1: "Quantos dias?",
                    description2: "Aguardar até",
                    saveButton: "Salvar etapa",
                    deleteButton: "Excluir etapa",
                    saveButton2: "Salvar etapa",
                    label1: "Selecionar condição de espera",
                    placeholder: "Selecionar uma opção",
                    label2: "Número de dias",
                    label3: "Até uma data e hora específicas",
                    label4: "Selecionar data",
                  },
                  webhook: {
                    label: "URL do Webhook",
                    saveButton: "Salvar etapa",
                    deleteButton: "Excluir etapa",
                  },
                },
                addStep: {
                  addStep: {
                    description: "Adicionar etapa",
                    label: "Por favor, selecione uma ação para esta etapa",
                    placeholder: "Selecionar uma ação",
                  },
                },
                registrationFlow: {
                  registration: {
                    description1:
                      "Essa recurso permite que você sequencie ações automatizadas que são acionadas quando uma pessoa se junta ou se interessa por este espaço. Aqui você pode fazer coisas como agendar e-mails e adicionar automaticamente pessoas a espaços selecionados quando elas se cadastram.",
                    description2: "Realizar essas ações após alguém...",
                    placeholder1: "Selecionar uma condição inicial",
                    addButton: "Adicionar etapa",
                    label1:
                      "Torna-se um membro ou se junta à lista de interessados",
                    description3:
                      "As etapas a seguir serão acionadas quando uma pessoa se tornar membro deste espaço ou se juntar à lista de interessados, enviando seu nome e endereço de e-mail por meio de um formulário de interesse.",
                    label2: "Torna-se um membro deste espaço",
                    description4:
                      "As etapas a seguir serão acionadas quando uma pessoa se tornar membro deste espaço.",
                    label3: "Tornar-se interessa-se por este espaço",
                    description5:
                      "As etapas a seguir serão acionadas quando uma pessoa se juntar à lista de interessados, enviando seu nome e endereço de e-mail por meio de um formulário de interesse.",
                  },
                },
              },
              structure: {
                add: {
                  add: {
                    title: "Adicionar a espaços",
                    description: "Espaços",
                    placeholder1: "Selecionar um espaço",
                    label1: "Enviar como",
                    placeholder2: "Escolher remetente",
                    placeholder3: "Selecionar data",
                    label2: "Data de envio",
                    label3: "Enviar tempo",
                    saveButton: "Salvar",
                    label4: "Agendar para mais tarde",
                  },
                },
                createStructure: {
                  title: {
                    createStructure: "Criar estrutura",
                  },
                },
                structureItem: {
                  item: {
                    addToSpace: "Adicionar ao espaço",
                    open: "View responses",
                    edit: "Edita",
                    duplicate: "Duplicar",
                    delete: "Excluir",
                  },
                },
              },
              survey: {
                add: {
                  add: {
                    title: "Adicionar a espaços",
                    spaces: "espaços",
                    placeholder1: "Selecionar um espaço",
                    label: "Enviar como",
                    placeholder2: "Escolher remetente",
                    saveButton: "Salvar",
                  },
                },
                answer: {
                  answer: {
                    addOption: "Adicionar opção",
                  },
                },
                createSurvey: {
                  description: "Criar pesquisa",
                },
                createSurveyForm: {
                  form: {
                    title: "Enquete Título",
                    description:
                      "Pagamentos flexíveis permitem que você ofereça várias opções de pagamento para o seu programa. Se você deseja que as pessoas definam seu próprio preço, basta criar uma opção de pagamento sem definir o valor para essa opção. Essa opção será exibida com um campo de entrada para a pessoa definir o próprio valor. Por exemplo, você pode oferecer um modelo de pague o quanto puder com vários níveis sugeridos e uma opção completamente aberta. Se você adicionar um texto de explicação abaixo, esse texto será exibido logo acima das opções de pagamento.",
                    optionalDescription: "Opcional Enquete descrição",
                    addQuestionButton: "Adicionar pergunta",
                    saveButton: "Salvar",
                  },
                },
                individual: {
                  individual: {
                    placeholder: "Selecionar uma pessoa",
                  },
                },
                multipleItem: {
                  multipleItem: {
                    placeholder: "Opção",
                  },
                },
                paymentItem: {
                  paymentItem: {
                    placeholder: "Opção de pagamento",
                  },
                },
                paymentSurveyItem: {
                  item: {
                    editButton: "Editar opções de pagamento",
                    viewButton: "visualizar respostas",
                  },
                },
                showSurvey: {
                  survey: {
                    title: "Respostas da pesquisa",
                  },
                },
                singleItem: {
                  item: {
                    placeholder: "Opção",
                  },
                },
                surveyItem: {
                  item: {
                    addToSpace: "Adicionar ao espaço",
                    open: "View responses",
                    edit: "Editar",
                    take: "Preview survey",
                    duplicate: "Duplicar",
                    download: "Baixar CSV",
                    delete: "Excluir",
                    responses: "Respostas",
                  },
                },
              },
              broadcast: {
                broadcast: {
                  button: {
                    broadcastButton: "Nova transmissão",
                  },
                },
              },
              broadcast: {
                title: "Transmissão",
                broadcastButton: "Nova transmissão",
                membersTypes: {
                  active: "Membros ativos",
                  pending: "Membros pendentes",
                  interested: "Membros interessados",
                },
                sentOn: "Transmissão enviada em",
                onDestroy: {
                  alertTitle: "Tem certeza?",
                  alertMessage:
                    "Esta transmissão será excluída permanentemente",
                  confirm: "Excluir transmissão",
                  deny: "Cancelar",
                },
                actions: {
                  edit: "Editar",
                  duplicate: "Duplicar",
                  delete: "Excluir",
                },
                statuses: {
                  sent: "Enviada",
                  scheduled: "Agendada",
                  draft: "Rascunho",
                  procesing: "Processing",
                },
                form: {
                  composeBroadcast: "Compor Transmissão",
                  contentErrMessage: "Adicione algum conteúdo",
                  targetErrMessage: "Selecione um destinatário",
                  type: {
                    email: "Enviar e-mail",
                    message: "Enviar mensagem ao espaço",
                  },
                  title: {
                    placeholder: "Assunto linha",
                  },
                  brackets: {
                    part1: "Use",
                    part2: "e",
                    part3:
                      "para preencher automaticamente as informações correspondentes",
                  },
                  target: {
                    placeholder: "Escolha os destinatários",
                    label: "Enviar para",
                  },
                  sendAs: {
                    label: "Enviar como",
                    placeholder: "Escolha o remetente",
                  },
                  schedule: {
                    label: "Agendar para mais tarde",
                  },
                  sendAtDate: {
                    label: "Data de envio",
                    placeholder: "Selecione a data",
                  },
                  sendAtTime: {
                    label: "Horário de envio",
                    placeholder: "",
                  },
                  scheduleBroadcast: "Agendar transmissão",
                  sendBroadcast: "Enviar transmissão",
                  saveDraft: "Salvar rascunho",
                },
              },
              surveys: {
                title: "Pesquisas",
                createSurvey: "Criar pesquisa",
              },
              structures: {
                title: "Estruturas",
                createStructure: "Criar estrutura",
              },
              sutraAdmin: {
                title: "Administração do Sutra",
              },
              scheduled: {
                title: "Agendados",
              },
              archived: {
                title: "Arquivados",
              },
              participation: {
                title: "Participação",
                label1: "Presença",
                description1: "% de membros que visitaram nos últimos 7 dias",
                label2: "Atividade",
                description2: "% de membros que postaram nos últimos 7 dias",
                label3: "Posts",
                description3:
                  "Total de posts em todos os subespaços nos últimos 7 dias",
                label4: "Participação",
                label5: "Atividade dos Membros",
                label6: "Progresso",
              },
            },
            sidebar: {
              iconSelect: {
                placeholder: "Pesquisar",
              },
              membersNode: {
                member: "Membros",
              },
              nodeIcon: {
                reset: "Redefinir",
              },
              sidebarTutorial: {
                gettingStarted: "Primeiros passos",
              },
            },
            alreadyMember: {
              member: {
                welcome: "Bem-vindo! Você já é um membro deste espaço -",
                clickHere: "Clique aqui para entrar",
              },
            },
            calender: {
              calender: {
                joinButton: "Participar da chamada",
                addButton: "Adicionar ao calendário",
                joinEventButton: "Registrar",
                title: "Detalhes do evento",
              },
            },
            invitationBar: {
              button: {
                register: "Registrar",
              },
            },
            space: {
              sutraLoading: "Sutra - Carregando...",
            },
            statusBar: {
              restoreButton: "Restaurar",
            },
            tagFilter: {
              resetButton: "Redefinir",
            },
            presentations: {
              discussion: {
                reflectionHeader: "Reflexões",
                backToPost: "Voltar ao post",
                pinnedMessageHeader: "Mensagem fixada",
                delete: {
                  title: "Tem certeza?",
                  message: "Essa ação não pode ser desfeita",
                  confirmButton: "Sim, excluir",
                  cancelButton: "Cancelar",
                },
              },
              tag: {
                placeholder: "Pesquisar tags ou criar uma nova",
                placeholder2: "Selecione uma tag",
                createAction: "Criar",
              },
              message: {
                reply: "Refletir",
                addTags: "Adicionar tags",
                tag: "Tag",
                copyToClipboard: "Copiar para a área de transferência",
                messageCopied: "Mensagem copiada para a área de transferência",
                pinMessageAction: "Fixar mensagem",
                editMessage: "Editar mensagem",
                deleteMessage: "Excluir mensagem",
                makePrivate: "Tornar privado",
                viewReplies1: "Ver",
                viewReplies2single: "reflexão",
                viewReplies2plural: "reflexões",
                privateMessage:
                  "Esta postagem está atualmente visível apenas para você e os gerentes deste espaço. Se torná-la pública, ela será visível para outros participantes deste espaço.",
                privateMessage2:
                  "Esta pessoa optou por não compartilhar sua resposta com outros participantes.",
                unreadMessagesText: "Você tem mensagens não lidas",
                scrollDownButton: "Rolar para baixo",
                newReflections: {
                  part1: "Você tem",
                  part2: "reflexões não lidas em",
                  part3: "mensagens",
                },
                viewNextButton: "Ver próxima",
                pinMessage: {
                  mainText: "Mensagem fixada",
                  viewButton: "Ver mensagem",
                },
              },
              content: {
                commentInput: {
                  toggleLabel1:
                    "Comentários estão desativados para jornadas interativas",
                  toggleLabel2: "Ativar comentários para esta postagem",
                  toggleLabel3: "Ativar comentários para este espaço de evento",
                  toggleLabel4:
                    "Ativar comentários para este espaço de conteúdo",
                  placeholder1:
                    "Comentários não podem ser postados enquanto você estiver editando.",
                  placeholder2: "Escreva algo...",
                },
              },
              fallback: {
                title: "Problema de conexão",
                message:
                  "Parece que você foi desconectado da internet. Quando estiver online novamente, iremos reconectá-lo automaticamente.",
              },
              events: {
                placeholder: "Escreva algo...",
                upcomingButton: "Próximos",
                pastButton: "Passados",
                addEvent: "Adicionar evento",
                eventSettingsTooltip: "Configurações do evento",
                newEventTitle: "NOVO EVENTO",
              },
              list: {
                placeholder: "Escreva algo...",
                createButton: "Criar postagem",
                listSettingsTooltip: "Configurações do fórum",
                duplicateFormTitle: "DUPLICAR ESPAÇO",
              },
              settings: {
                versions: {
                  content: "Página de conteúdo",
                  posts: "Posts",
                  discussions: "Discussões",
                  link: "Link",
                  template: "Modelo",
                },
                versionsDescriptions: {
                  content:
                    "Novos itens serão exibidos como uma página de conteúdo com funcionalidade completa.",
                  discussion: "Vazio",
                  posts:
                    "Novos itens serão exibidos como um post simples com funcionalidade limitada",
                  link: "Essa opção permite que você envie um link para qualquer espaço no Sutra, que será exibido como um item aqui.",
                  template:
                    "Crie um espaço modelo que será duplicado cada vez que um novo item for criado aqui.",
                },
                createNewTemplate: "Criar novo modelo",
                editTemplate: "Editar modelo",
                title: "CONFIGURAÇÕES",
                accordion1: {
                  label: "Exibir como",
                  description:
                    "Exibir posts em uma visualização em lista ou grade",
                  listOption: "Lista",
                  gridOption: "Grade",
                  dateCreated: "Data de criação",
                  dateCreatedReversed: "Data reversa de criação",
                  lastActive: "Última atividade",
                  lastEdited: "Última edição",
                  alphabetic: "Ordem alfabética",
                  reverseAlphabetic: "Alfabética reversa",
                },
                accordion2: {
                  label: "Opções de exibição",
                  description: "",
                  switches: {
                    onlyManagers: "Apenas os gerentes podem criar um post",
                    showAuthor: "Mostrar informações do autor",
                    showPreview: "Mostrar texto de visualização",
                    showComments: "Mostrar contagem de comentários",
                    showMembers: "Mostrar avatares dos membros",
                    showTimestamp: "Mostrar horário de criação do post",
                    showThumbnail: "Mostrar miniatura",
                    autoThumbnail: "Criar miniatura automaticamente",
                  },
                },
                accordion3: {
                  label: "Formato do novo post",
                  description:
                    "Selecione o formato para novos posts neste espaço",
                  templateName: {
                    label: "Dê um nome ao seu modelo",
                    placeholder: "Nome do modelo",
                  },
                  templateDirectOption: "Subespaço direto",
                  templateLinkedOption: "Espaço vinculado",
                  privacyDescription:
                    "Selecione o nível de privacidade padrão para novos posts",
                  privacyOpenOption: "Aberto",
                  privacyPrivateOption: "Privado",
                  privacyAnyOption: "Escolha do criador",
                },
                accordion4: {
                  label: "Rótulo do botão",
                  createPost: {
                    label: "Criar post",
                    description:
                      "Especifique o texto que aparece no botão de novo post",
                  },
                },
                templateDescription:
                  "Novos espaços modelo podem ser subespaços diretos deste espaço ou podem ser espaços vinculados. Espaços vinculados são espaços independentes de nível superior que serão exibidos nesta página. O criador do espaço se tornará um gerente completo do espaço que eles criarem.",
              },
              thumbnail: {
                title: "ADICIONAR MINIATURA",
                message: "A imagem da miniatura está sendo carregada...",
              },
              static: {
                broadcastExclude: {
                  unsubscribe: {
                    part1: "Tem certeza de que deseja cancelar a inscrição em",
                    part2:
                      "Você não receberá mais e-mails de transmissão direta dos gerentes deste espaço",
                    button: "Cancelar inscrição",
                  },
                  subscribe: {
                    part1:
                      "Você foi cancelado e não receberá mais e-mails de transmissão de",
                    button: "Inscrever-se",
                  },
                },
                invitation: {
                  message: "Você foi convidado para este espaço",
                  button: "Aceitar convite",
                },
                pending: {
                  message: "Sua adesão está pendente",
                },
                private: {
                  message: {
                    part1: "Este é um espaço privado.",
                    part2: "Se você é membro, faça o login.",
                  },
                  button: "Fazer login",
                },
                processing: {
                  message: "Este espaço ainda está sendo processado...",
                },
              },
              gridPost: {
                onDelete: {
                  alertTitle: "Tem certeza?",
                  alertMessage: "Este espaço não estará mais vinculado aqui.",
                  alertMessage2: "Este espaço não estará mais vinculado aqui.",
                  confirm: "Sim",
                  deny: "Não",
                },
                actions: {
                  pin: "Fixar no topo",
                  unpin: "Desafixar",
                  addThumbnail: "Adicionar miniatura",
                  removeThumbnail: "Remover miniatura",
                  duplicate: "Duplicar",
                  unlink: "Desvincular",
                  delete: "Excluir",
                },
              },
              listContent: {
                publishTootip: "Será publicado nesta data",
                privateTooltip: "Este é um espaço privado",
              },
            },
            colors: {
              form: {
                colorTheme: "Tema de cores",
                customize: "Personalizar seleção de cores",
                saveTheme: "Salvar tema",
                resetColor: "Redefinir cor",
                pageLayout: "Layout da página",
                pageFormat: "Formato da página",
              },
              headerAndLogo: {
                addCover: "Adicionar capa",
                removeCover: "Remover capa",
                changeLogo: "Alterar logotipo",
                removeLogo: "Remover logotipo",
                dimensions:
                  "A dimensão recomendada para imagens de capa é de 1200 x 300 pixels",
                dimensions2:
                  "A dimensão recomendada para logotipos é de 360 x 120 pixels",
              },
              pageFormat: {
                description:
                  "Você pode alterar a forma como o conteúdo deste espaço é apresentado selecionando uma opção abaixo.",
                contentLabel: "Conteúdo",
                discussionLabel: "Discussão",
                contentDescription:
                  "Um espaço para módulos de aprendizado, conteúdo geral ou recursos da comunidade.",
                discussionDescription: "Um espaço para discussão ou anúncios",
                eventsLabel: "Calendário",
                eventsDescription: "Criar uma lista de eventos no calendário",
                listLabel: "Fórum",
                listDescription:
                  "Criar um feed onde as pessoas podem publicar tópicos de discussão",
              },
              pageLayout: {
                widePage: "Largura de página ampla",
                hideSidebar: "Ocultar barra lateral",
                hideHeader: "Ocultar cabeçalho",
                hideCoverImage: "Ocultar imagem de capa",
                hidePageTitle: "Ocultar título da página",
                hideBreadcrumbs: "Ocultar trilhas de navegação",
              },
              header_color: {
                label: "Cor do cabeçalho",
                description: "Descrição",
              },
              header_link_color: {
                label: "Cor do logotipo do cabeçalho",
                description: "Descrição",
              },
              primary_button_background_color: {
                label: "Cor de fundo do botão primário",
                description: "Descrição",
              },
              primary_button_text_color: {
                label: "Cor do texto do botão primário",
                description: "Descrição",
              },
              secondary_button_background_color: {
                label: "Cor de fundo do botão secundário",
                description: "Descrição",
              },
              secondary_button_text_color: {
                label: "Cor do texto do botão secundário",
                description: "Descrição",
              },
              background_color: {
                label: "Cor de fundo",
                description: "Descrição",
              },
              default_text_color: {
                label: "Cor do texto padrão",
                description: "Descrição",
              },
              sidebar_background_color: {
                label: "Cor de fundo da barra lateral",
                description: "Descrição",
              },
              sidebar_text_color: {
                label: "Cor do texto da barra lateral",
                description: "Descrição",
              },
              default_link_color: {
                label: "Cor do link padrão",
                description: "Descrição",
              },
              default_badge_color: {
                label: "Cor do distintivo de notificação",
                description: "Descrição",
              },
              default_badge_text_color: {
                label: "Cor do texto do distintivo de notificação",
                description: "Descrição",
              },
              registration_page_background_color: {
                label: "Cor de fundo da página de registro",
                description: "Descrição",
              },
              registration_page_default_text_color: {
                label: "Cor do texto padrão da página de registro",
                description: "Descrição",
              },
            },
            create: {
              invalidNameErrorMsg:
                "O nome do espaço deve ter entre 3 e 100 caracteres",
            },
            dummy: {
              magicLink: {
                alertTitle: "Você está quase lá...",
                alertMessage:
                  "Enviamos o link mágico para o seu e-mail. ISSO É APENAS UM TESTE, NENHUM E-MAIL REAL FOI ENVIADO.",
              },
            },
            pending: {
              message: "Sua associação está pendente",
            },
            recover: {
              alertTitle: "Você está quase lá...",
              alertMessage:
                "Acabamos de enviar um e-mail com instruções para recuperar sua senha. ISSO É APENAS UM TESTE, NENHUM E-MAIL REAL FOI ENVIADO.",
            },
            form: {
              calendarLinks: {
                text: "As informações adicionadas abaixo serão transferidas para o calendário do membro quando ele adicionar o evento.",
                link: {
                  label: "URL do evento (link do Zoom, etc)",
                  placeholder: "Qual é o link da sua chamada de vídeo",
                },
                description: {
                  label:
                    "Adicione uma descrição para o seu evento que aparecerá nas informações do calendário",
                  placeholder: "Descrição do calendário",
                },
                eventStartDateAndTime: "Data e hora de início do evento",
                date: {
                  placeholder: "Escolha a data",
                },
                eventDateTime: "Data e hora de término do evento (opcional)",
                allowInterests:
                  "Permitir que as pessoas participem do evento sem se registrarem no Sutra",
                automatedEmails: "Configurar e-mails automatizados",
                save: "Salvar detalhes",
              },
              duplicate: {
                prefix: "Cópia de",
                intention: {
                  label: "Nome do espaço",
                  placeholder: "Qual é o nome do seu espaço?",
                },
                copyMembers: {
                  label: "Copiar membros",
                },
                copyManagers: {
                  label: "Copiar gerentes",
                },
                copyContent: {
                  label: "Copiar conteúdo",
                },
                copyPosts: {
                  label: "Copy posts",
                },
                copyBroadcasts: {
                  label: "Copiar transmissões",
                },
                copyAutomation: {
                  label: "Copiar automações",
                },
                copyRegPage: {
                  label: "Copiar página de registro",
                },
                save: "Duplicar espaço",
                saveTemplate: "Faça um modelo",
              },
              form: {
                privacyDescription1:
                  "O espaço que você criar será acessível a todos dentro de",
                rootPrivacyDescription1:
                  "O espaço que você criar será acessível a qualquer pessoa que tenha um link para ele. Você pode definir opções de pagamento depois de criar o espaço.",
                privacyDescription2:
                  "O espaço que você criar será visível apenas para as pessoas que forem adicionadas a ele.",
                rootPrivacyDescription2:
                  "O espaço que você criar será visível apenas para as pessoas que forem convidadas para ele. Você pode definir opções de pagamento depois de criar o espaço.",
                placeholderDescription: "Descrição",
                versions: {
                  content: {
                    label: "Conteúdo",
                    description:
                      "Crie um espaço para um módulo de aprendizagem, conteúdo geral ou recursos comunitários.",
                  },
                  discussion: {
                    label: "Discussão",
                    description:
                      "Crie um espaço de chat para discussão ou anúncios",
                  },
                  event: {
                    label: "Evento",
                    description: "Crie um único evento",
                  },
                  events: {
                    label: "Calendário",
                    description: "Crie uma lista de eventos no calendário",
                  },
                  list: {
                    label: "Fórum",
                    description:
                      "Crie um feed onde as pessoas podem postar tópicos de discussão",
                  },
                },
                segments: {
                  space: {
                    label: "Espaço",
                    description:
                      "Crie um espaço para seu curso ou comunidade. Escolha esta opção se você deseja criar uma turma de treinamento, um curso em ritmo próprio, uma comunidade ou qualquer grupo que se reunirá ao longo do tempo.",
                  },
                  event: {
                    label: "Evento",
                    description:
                      "Crie uma página de evento simples. Escolha esta opção se você deseja apenas organizar um evento único que possa ser compartilhado publicamente.",
                  },
                  discussion: {
                    label: "Discussão",
                    description:
                      "Crie um espaço de chat simples para seu grupo. Escolha esta opção se você deseja criar um espaço de discussão único para um grupo de pessoas.",
                  },
                },
                intention: {
                  label: "Nome do espaço",
                  placeholder: "Qual é o nome do seu espaço?",
                },
                content: {
                  label: "Nome do espaço",
                  description: "Escreva algo...",
                  placeholder: "Escreva algo...",
                },
                spaceFormat: {
                  label: "Formato do espaço",
                  placeholder: "Escolha um",
                },
                eventDescription:
                  "As informações adicionadas abaixo serão transferidas para o calendário do membro quando eles adicionarem o evento.",
                hangoutLink: {
                  label: "URL do evento (link do Zoom, etc)",
                  placeholder: "Qual é o link da sua chamada de vídeo",
                },
                eventStartTime: "Data e hora de início do evento",
                date: {
                  placeholder: "Escolha a data",
                },
                eventEndDate: "Data e hora de término do evento (opcional)",
                privacyLevelText: "Nível de privacidade",
                privacy: {
                  open: "Aberto",
                  private: "Privado",
                },
                joinAsMember: {
                  label: "Participar deste espaço como membro",
                },
                publishDate: {
                  label: "Publicar na data",
                },
                publishDateTime: "Data e hora de publicação",
                create: "Criar espaço",
              },
              interestedForm: {
                title: "Registrar para este evento",
                createAlertTitle: "Obrigado por se registrar!",
                createAlertButton: "Ok",
                name: {
                  label: "Nome",
                  placeholder: "Digite seu primeiro nome",
                },
                email: {
                  label: "E-mail",
                  placeholder: "Digite seu e-mail",
                },
                register: "Registrar",
                joinWithSutra: "Entrar com minha Conta Sutra",
              },
              linkForm: {
                linkErrMessage: "Este não é um link de espaço válido",
                link: {
                  label: "",
                  placeholder: "Link para o espaço",
                  errors: {
                    space:
                      "O espaço que você deseja compartilhar é privado ou não existe!",
                    event:
                      "O espaço que você deseja compartilhar é privado, não é um evento ou não existe!",
                  },
                },
              },
              listForm: {
                intention: {
                  placeholder: "Título",
                },
              },
            },
            header: {
              onLeave: {
                title: "São Tem certeza?",
                message:
                  "Você será removido como membro deste espaço e poderá precisar ser adicionado novamente para recuperar o acesso.",
                confirm: "Sim, por favor me remova",
                deny: "Não",
              },
              onArchive: {
                title: "São Tem certeza?",
                message:
                  "Este espaço será arquivado e os membros não terão mais acesso.",
                confirm: "Sim",
                deny: "Não",
              },
              onUnarchive: {
                title: "São Tem certeza?",
                message:
                  "Este espaço será desarquivado e os membros terão acesso novamente.",
                confirm: "Sim",
                deny: "Não",
              },
              onDelete: {
                title: "São Tem certeza?",
                message:
                  "Este espaço será excluído e os membros não terão mais acesso.",
                confirm: "Sim",
                deny: "Não",
              },
              onFix: {
                title: "São Tem certeza?",
                message:
                  "A Sutra tentará recriar este espaço de conteúdo a partir dos dados legados.",
                confirm: "Sim, corrigir",
                deny: "Não",
                confirmationTitle: "Informação",
                confirmationMessage: "Espaço corrigido, recarregando...",
                confirmationError: "Este espaço não pode ser corrigido",
              },
              buttons: {
                uncomplete: "Marcar como incompleto",
                markComplete: "Marcar como completo",
                edit: "Editar",
                post: "publicação",
                page: "página",
                space: "espaço",
                returnTo: "Voltar para",
                editTitle: "Editar título",
                viewPage: "Ver página",
                editDescription: "Editar descrição",
                appearance: "Aparência",
                manage: "Gerenciar",
                members: "Membros",
                leave: "Sair do espaço",
                delete: "Excluir espaço",
                duplicate: "Duplicar espaço",
                template: "Faça um modelo",
                archive: "Arquivar espaço",
                unarchive: "Desarquivar espaço",
                fix: "Corrigir",
                joinEvent: "Registrar",
                joinSpace: "Participar do espaço",
                preview: "Visualizar",
                draft: "Rascunho",
                published: "Publicado",
                publish: "Publicar",
                returnToSettings: "Voltar para as configurações",
                returnToSpace: "Voltar para o espaço",
                undo: "Desfazer",
                openFullScreen: "Abrir em tela cheia",
                copyToClipboard: "Copiar para a área de transferência",
                copiedMessage:
                  "Link do espaço copiado para a área de transferência",
                versionHistory: "Histórico de versões",
                brandingOptions: "OPCJE BRANDINGU",
              },
              regPagePublishing:
                "Sua página de registro está sendo publicada, por favor aguarde...",
              spaceUpdating:
                "Este espaço está sendo atualizado, por favor aguarde...",
            },
            members: {
              sectionTitle: "Membros",
              manage: "Gerenciar membros",
              invite: "Convidar",
              search: {
                button: "Pesquisar",
                placeholder: "Pesquisar por",
              },
              emptyMessage: "Nenhum registro encontrado",
              messageButton: "Mensagem",
              emailButton: "E-mail",
              copyClipboard: "copiar para a área de transferência",
            },
            payment: {
              onCheckIntent: {
                alertTitle: "Erro de pagamento",
                alertMessage:
                  "Sua sessão expirou. Por favor, atualize a página para concluir o pagamento.",
              },
              onConfirmPayment: {
                alertTitle: "Ops, algo deu errado!",
                alertMessage: "algo deu errado!",
              },
              completePayment: "Concluir pagamento",
              join: "Participar do espaço",
            },
            stripe: {
              pay: "Pagar",
            },
          },
          survey: {
            form: {
              text: "Você já respondeu a esta pesquisa.",
              submitButton: "Enviar",
            },
            question: {
              yourAnswer: "Sua resposta",
            },
          },
          tiptap: {
            blocks: {
              section1: "Básico",
              text: "Texto",
              headline1: "Título 1",
              headline2: "Título 2",
              headline3: "Título 3",
              divider: "Divisor",
              infobox: "Caixa de Informação",
              section2: "Espaços",
              content: "Espaço de Conteúdo",
              discussion: "Espaço de Discussão",
              event: "Espaço de Evento",
              events: "Espaço de Calendário",
              list: "Espaço de Fórum",
              showcase: "Showcase Space",
              section3: "Lista",
              section8: "Special",
              bulletList: "Lista com Marcadores",
              orderedList: "Lista Ordenada",
              checklist: "Lista de Verificação",
              collapsableSection: "Seção Recolhível",
              survey: "Survey or quiz",
              section4: "Mídia",
              image: "Imagem",
              embed: "Código de Incorporação",
              video: "Vídeo",
              audio: "Áudio",
              file: "Arquivo",
              zoom: "Zoom recording",
              section5: "Grade",
              grid1: "1 Coluna",
              grid2: "2 Colunas",
              grid3: "3 Colunas",
              grid4: "Coluna à Esquerda",
              grid5: "Coluna à Direita",
              grid6: "4 columns",
              section6: "Botões",
              interestForm: "Formulário de Interesse",
              actionButton: "Botão de Ação",
              markComplete: "Marcar como Concluído",
              section7: "Interativo",
              interactiveButton: "Botão Interativo",
              interactiveInput: "Entrada Interativa",
              interactiveWait: "Aguardar Interativo"
            },
            components: {
              sidebar: {
                alignment: {
                  left: "Esquerda",
                  center: "Centro",
                  right: "Direita",
                },
                backgroundColor: {
                  text: "Selecionar cor de fundo",
                  clearColor: "Limpar cor",
                },
                backgroundImage: {
                  button: "Selecionar imagem",
                  removeButton: "Remover imagem",
                  label: "Posição",
                  placeholder: "Escolha uma",
                  leftTop: "superior esquerdo",
                  leftCenter: "centro esquerdo",
                  leftBottom: "inferior esquerdo",
                  rightTop: "superior direito",
                  rightCenter: "centro direito",
                  rightBottom: "inferior direito",
                  centerTop: "superior centro",
                  centerCenter: "centro",
                  centerBottom: "inferior centro",
                  label2: "Repita",
                  placeholder2: "Escolha uma",
                  noRepeat: "Sem repetição",
                  repeat: "Repita",
                  "repeat-x": "Repetir X",
                  "repeat-y": "Repetir Y",
                  space: "Espaço",
                  round: "Redonda",
                },
                blockId: {
                  placeholder: "ID do bloco",
                  label: "ID do bloco",
                  description:
                    "IDs recomendados para descrição breve de IA, sobre o workshop, o que você vai aprender 1, o que você vai aprender 2, o que você vai aprender 3, para quem é isso, sobre nós, citação",
                },
                buttonAction: {
                  label: "Ação de conclusão",
                  description:
                    "Especifique o que acontece depois que uma pessoa envia suas informações por meio do formulário selecionado.",
                  placeholder: "Escolha uma",
                  label2: "Link para",
                  placeholder2: "Escolha uma",
                  label3: "Ação do link",
                  description3:
                    "Especifique se o link será aberto na mesma aba do navegador ou em uma nova aba.",
                  placeholder3: "Escolha uma",
                  callBackComplete: "Texto de ação de conclusão",
                  callBacklinkTo: "Link para",
                  completeDescription:
                    "Adicione uma mensagem curta de confirmação que as pessoas verão após enviar suas informações.",
                  linkDescription:
                    "A pessoa será redirecionada para este link ao clicar no botão.",
                  label4: "Link para a seção de espaço",
                  description4: "Especifique se o link será aberto na guia atual do navegador ou em outra guia ou se o link irá para uma seção desta página.",
                  selectSection: "Selecione a seção",
                  description5: "Esta opção permite que você vá para uma seção específica desta página quando o link for clicado.",
                  description6: "Para selecionar a seção, clique no botão abaixo e, em seguida, clique na seção à qual deseja vincular.",
                  label5: "A seção está vinculada!",
                  label6: "URL do link",
                  description7: "Especifique o link do URL para o qual o usuário será direcionado",
                  label7: "Abre na guia atual",
                  label8: "Abre outra aba",
                  label9: "Fazer nada",
                  label10: "Redirecionar o usuário para",
                  label11: "Selecione uma opção",
                  label12: "Outro espaço",
                  label13: "Link personalizado",
                  label14: "Clique em uma seção da página",
                },
                buttonBackgroundColor: {
                  button: "Limpar cor",
                },
                buttonText: {
                  placeholder: "Texto do botão",
                  label: "Texto do botão",
                },
                fileName: {
                  placeholder: "Nome do arquivo",
                  label: "Nome do arquivo",
                },
                interactiveWaitTime: {
                  label1: "Selecione uma opção",
                  label2: "Esperar até uma data",
                  label3: "Esperar número de dias",
                  label4: "Data para mostrar conteúdo",
                  placeholder: "Data para mostrar conteúdo",
                  label5: "1 dia",
                  label6: "2 dias",
                  label7: "3 dias",
                  label8: "4 dias",
                  label9: "5 dias",
                  label10: "6 dias",
                  label11: "1 semana",
                  label12: "2 semanas",
                  label13: "1 mês",
                },
                linkAction: {
                  label: "Ação do link",
                  description:
                    "Especifique se o link será aberto na mesma aba ou em uma nova aba.",
                  placeholder: "Escolha uma",
                  label2: "Abrir na mesma aba",
                  label3: "Abrir em uma nova aba",
                },
                margin: {
                  topMargin: "Margem superior -",
                  text: "Margem inferior -",
                  bottom: "Inferior",
                  left: "Esquerda",
                  leftMargin: "Margem esquerda -",
                  right: "Direita",
                  rightMargin: "Margem direita",
                },
                padding: {
                  top: "Superior",
                  topPadding: "Preenchimento superior -",
                  bottom: "Inferior",
                  bottomPadding: "Preenchimento inferior -",
                  left: "Esquerda",
                  leftPadding: "Preenchimento esquerdo",
                  right: "Direita",
                  rightPadding: "Preenchimento direito",
                },
                radius: {
                  square: "Quadrado",
                  round: "Redondo",
                  circle: "Círculo",
                },
                spaceAttrs: {
                  addCover: "Adicionar Capa",
                  text: "A dimensão recomendada para imagens de capa é de 1200 x 300 pixels",
                  addCover2: "Adicionar Capa",
                  text2:
                    "A dimensão recomendada para imagens de capa é de 1200 x 300 pixels",
                  removeCover: "Remover capa",
                },
                verticalAllignment: {
                  top: "Superior",
                  center: "Centro",
                  bottom: "Inferior",
                },
                width: {
                  full: "Completa",
                  size: "3/4",
                  size2: "1/2",
                  actual: "Atual",
                },
              },
              blockMenuActions: {
                label1: "Adicionar bloco",
                label2: "Adicionar emoji",
                label3: "Configurações",
                label4: "Excluir",
                label5: "Mover",
                label6: "Ir para o espaço ou clique duplo no bloco",
                label7: "Substituir",
                label8: "Alinhamento",
                left: "Esquerda",
                center: "Centro",
                right: "Direita",
                write: "Escreva algo...",
                label9: "Adicionar bloco",
                label10: "Título 1",
                h1: "H1",
                label11: "Título 2",
                h2: "H2",
                label12: "Adicionar espaço",
                label13: "Adicionar imagem",
                label14: "Adicionar vídeo",
                label15: "Adicionar grade",
                label16: "Adicionar modelo",
                label17: "Adicionar layout",
                label18: "Duplicado",
              },
              blocksMenu: {
                basicBlock: "Blocos básicos",
                mediaBlock: "Blocos de mídia",
                contentBlock: "Blocos de conteúdo",
              },
              blocksMenuInline: {
                file: "Arquivo",
                image: "Imagem",
                video: "Vídeo",
                label1: "Adicionar emoji",
                label2: "Adicionar imagem",
                label3: "Alternar barra de formatação",
              },
              blocksMenuSimple: {
                label1: "Adicionar",
                file: "Arquivo",
                image: "Imagem",
                video: "Vídeo",
                audio: "Audio",
                label2: "Adicionar emoji",
                label3: "Adicionar imagem",
                label4: "Mostrar formatação",
                cancelButton: "Cancelar",
              },
              emptyMessage: {
                text: "Este é um espaço de conteúdo vazio. Você pode",
                button1: "editar esta página",
                text2: "ou",
                button2: "usar um modelo",
                text3: "para começar",
                text4: "Um espaço vazio cheio de potencial...",
              },
              fixedBottomMenu: {
                label1: "Adicionar elementos como títulos, imagens e vídeos",
                addBlock: "Adicionar bloco",
                label2: "Adicionar um espaço de conteúdo, discussão ou evento",
                space: "Espaço",
                label3:
                  "Adicionar uma grade para usar várias colunas de conteúdo",
                grid: "Grade",
                label4: "Adicionar uma opção de design pré-criada",
                layouts: "Layouts",
                label5:
                  "Adicionar um modelo para configurar automaticamente o espaço",
                template: "Modelos",
              },
              formatMenu: {
                text: "Texto",
                smallText: "Texto pequeno",
                largeText: "Texto Grande",
                heading1: "Título 1",
                heading2: "Título 2",
                heading3: "Título 3",
                bulletedList: "Lista com marcadores",
                orderedList: "Lista numerada",
                text2: "Texto",
                label1: "Formatação",
                text3: "Texto",
                numberedList: "Lista numerada",
                label2: "Alinhamento",
                left: "Esquerda",
                center: "Centro",
                right: "Direita",
                label3: "Ação do link",
                description:
                  "Especifica se o link será aberto na mesma aba do navegador ou em uma nova aba.",
                placeholder: "Escolha uma opção",
                label4: "Abre na mesma aba",
                label5: "Abre em nova aba",
                label6: "Formato do link",
                description2:
                  "Especifica se o link será exibido como texto ou como um link estilizado.",
                placeholder2: "Escolha uma opção",
                label7: "Exibir como texto",
                label8: "Exibir como link estilizado",
                save: "Salvar",
                delete: "Excluir",
                label9: "Riscado",
                label10: "Sublinhado",
                label11: "Bloco de código",
                label12: "Cor do texto",
                placeholder3: "Valor HEX",
                unsetColor: "Remover cor",
                label13: "Selecione cor",
                label14: "Link para a seção de espaço",
                description3: "Especifique se o link será aberto na guia atual do navegador ou em outra guia ou se o link irá pular para uma seção desta página.",
                selectSection: "Selecione a seção",
                description4: "Esta opção permite que você vá para uma seção específica desta página quando o link for clicado.",
                description5: "Para selecionar a seção, clique no botão abaixo e, em seguida, clique na seção à qual deseja vincular.",
                label15: "A seção está vinculada!",
                label16: "URL do link",
                description6: "Especifique o link do URL para o qual o usuário será direcionado",
              },
              formatMenuSimple: {
                t: "T",
                h1: "H1",
                h2: "H2",
                h3: "H3",
                label1: "Ação do link",
                description1:
                  "Especifica se o link será aberto na mesma aba do navegador ou em uma nova aba.",
                placeholder1: "Escolha uma opção",
                label2: "Abre na mesma aba",
                label3: "Abre em nova aba",
                label4: "Formato do link",
                description2:
                  "Especifica se o link será exibido como texto ou como um link estilizado.",
                placeholder2: "Escolha uma opção",
                label5: "Exibir como texto",
                label6: "Exibir como link estilizado",
                save: "Salvar",
                delete: "Excluir",
                label7: "Formatação",
                left: "Esquerda",
                center: "Centro",
                right: "Direita",
                placeholder3: "Valor HEX",
                placeholder4: "Cor HEX",
                unsetColor: "Remover cor",
              },
              inlineMenu: {
                h1: "H1",
                h2: "H2",
                bulletList: "Lista com marcadores",
                checkList: "Lista de controle",
                circle: "Círculo",
                moreOption: "Mais opções",
              },
              interactiveInput: {
                hideResponses: "Ocultar outras respostas",
                showResponses: "Mostrar outras respostas",
                hideResponses2: "Ocultar respostas",
                hidePrivateMessages: "Ocultar mensagens privadas",
                showPrivateMessages: "Mostrar mensagens privadas",
                showResponses2: "Mostrar respostas",
                loadMoreResponse: "Carregar mais respostas",
                placeholder: "Escreva algo...",
                text: "Deseja que esta resposta seja visível para outros participantes?",
              },
              newMedia: {
                add: "Adicionar",
              },
              regPageColor: {
                clearColor: "Limpar cor",
              },
              regPageColors: {
                text: "Aparência da página de registro",
                applyAppearance: "Aplicar Aparência",
                title1: "Selecionar cor de fundo",
                title2: "Selecionar cor do texto",
                label1: "Cor de fundo",
                label2: "Cor do texto padrão",
                label3: "Largura da página ampla",
              },
              resource: {
                areYouSure: "Você tem certeza?",
                label1: "Será publicado nesta data",
                edit: "Editar",
                addPage: "Adicionar Página",
              },
              sideBlocksMenuBak: {
                addBlock: "Adicionar bloco",
                back: "Voltar",
              },
              sideBlocksMenu: {
                text: "Layouts são opções de design pré-criadas que você pode adicionar ao seu espaço.",
                text2:
                  "Comece com uma seleção de modelos de página inteira personalizáveis",
                layout: "Layouts",
                template: "Modelos",
                addBlock: "ADICIONAR BLOCO",
                block: "Blocos",
              },
              sideConfigurationsMenu: {
                title: "CONFIGURAÇÕES DO BLOCO",
                label1: "Nome do espaço e capa",
                label2: "Atributos do arquivo",
                label3: "Opções de entrada interativa",
                attrPlaceholder1: "Escreva algo...",
                attrLabel: "Marcador de posição",
                attrLabel2:
                  "Permitir que o participante veja todas as respostas",
                attrLabel3: "Mostrar respostas antes do participante responder",
                attrLabel4:
                  "Permitir que o participante escolha se a resposta será visível para os outros",
                attrLabel5: "Permitir várias respostas por pessoa",
                attrLabel6: "Permitir que o participante pule esta solicitação",
                attrLabel7: "Notificar os gerentes em cada nova postagem",
                label4: "Opções de entrada interativa",
                label5: "Preenchimento",
                label6: "BLOCO",
                label7: "COLUNA",
                label8: "Margem",
                label9: "GRADE",
                label10: "Alinhamento vertical",
                label11: "Opções do botão",
                label12: "Cor de fundo do botão",
                label13: "Cor do texto do botão",
                text: "Cantos do botão",
                label14: "Fundo",
                label15: "Cor",
                label16: "Imagem",
                label17: "Redefinir",
                resetBlock: "Redefinir bloco",
                label18: "Alinhamento",
                label19: "Raio da borda",
                label20: "cantos",
                label21: "Nazwa bloku",
                label22: "Opcje powiadomień e-mail",
                label23: "Wyślij powiadomienie e-mail",
                attrLabel8: "Dołącz niestandardową wiadomość",
                attrLabel9: "Dołącz zawartość następnej sekcji",
                attrLabel10: "Dołącz pełną zawartość",
                attrLabel11: "Krótki podgląd",
                attrLabel12: "Temat",
                attrLabel13: "Wiadomość",
                defaultWaitLabel: "Następna sekcja została wydana w {{space_name}}",
                defaultWaitMessage: "Cześć {{first_name}} – chciałem tylko dać ci znać, że kolejna sekcja w {{space_name}} została wydana. {{space_link_button}}"
              },
              takeSurvey: {
                text: "Esta enquete está vazia",
              },
              tiptapAi: {
                placeholder: "Escreva uma breve descrição",
                label:
                  "Por favor, escreva 2 ou 3 frases descrevendo sua oferta.",
                placeholder2: "ex. treinadores, consultores e curadores",
                label2: "Para quem é esta oferta?",
                text: "Olá, estamos experimentando um novo recurso de IA. Você deseja que o Sutra gere automaticamente o texto para o seu modelo usando IA?",
              },
              uploader: {
                label1: "Link",
                label2: "Código",
                placeholder: "Cole o link da imagem ou arquivo",
                label3: "Link incorporado",
                placeholder2:
                  "Cole o código de incorporação -> <iframe>...</iframe>",
                label4: "Código de incorporação",
                saveEmbed: "Salvar incorporação",
                placeholder3: "Buscar imagens",
                saveImage: "Salvar imagem",
              },
              viewPage: {
                text: "Esta página está vazia",
              },
            },
          },
          users: {
            payment: {
              completePayment: "Completa pagamento",
              join: "juntar espaço",
              pay: "Pagar",
            },
            stepper: {
              step1: "Passo 1",
              step1Description: "Faça login",
              step2: "Passo 2",
              step2Description: "Completa pagamento",
              step3: "Passo 3",
              step3Description: "Obter acesso",
            },
            start: {
              title: "Bem-vindo",
              subtitle: "Digite seu endereço de e-mail abaixo",
              email: "E-mail",
              submit: "Continuar",
              invalidEmailErrorMsg: "E-mail inválido",
            },
            signIn: {
              title: "Faça login em sua conta",
              subtitle: "Não tem uma conta?",
              subtitleLink: "Cadastre-se",
              email: "E-mail",
              password: "Senha",
              submit: "Entrar",
              magicLink: "Obter um link mágico",
              forgotPassword: "Esqueceu a senha?",
              invalidEmailErrorMsg: "E-mail inválido",
            },
            signUp: {
              invalidEmailErrorMsg: "E-mail inválido",
              invalidPasswordErrorMsg:
                "A senha deve ter pelo menos 8 caracteres",
              invalidFirstNameErrorMsg:
                "O nome deve ter pelo menos 3 caracteres",
              title: "Criar uma nova conta",
              subtitle: "Já tem uma conta?",
              subtitleLink: "Faça login",
              firstName: "Primeiro nome",
              lastName: "Sobrenome",
              username: "Nome de usuário",
              email: "E-mail",
              password: "Senha",
              emailNewsletterConfirmation:
                "Inscreva-se no boletim informativo do Sutra para saber sobre webinars gratuitos sobre processos em grupo, ensino transformacional e negócios relacionais.",
              submit: "Cadastre-se",
              legalPrefix: "Ao se cadastrar no Sutra, você concorda com nossos",
              terms: "Termos de Serviço",
              privacy: "Política de Privacidade",
            },
            spaceReference: {
              total: "Total hoje",
            },
            planReference: {
              total: "Total",
              total_today: "Total hoje",
              due_in: "Vencimento em 14 dias",
              paymentDetails: {
                annual: "Assinatura anual de",
                month: "Assinatura mensal de",
              },
            },
            resetPassword: {
              title: "Redefinir sua senha",
              subtitle:
                "Por favor, insira uma nova senha para sua conta no Sutra abaixo.",
              password: "Senha",
              passwordConfirmation: "Confirmação de senha",
              submit: "Redefinir senha",
            },
            recover: {
              title: "Esqueceu sua senha?",
              subtitle:
                "Por favor, insira seu endereço de e-mail abaixo e enviaremos instruções sobre como redefinir sua senha.",
              email: "E-mail",
              submit: "Enviar instruções",
              footer: "Já tem uma conta?",
              footerLink: "Conecte-se",
            },
            magicLink: {
              invalidEmailErrorMsg: "E-mail inválido",
              title: "Solicitar link mágico",
              subtitle:
                "Por favor, insira seu endereço de e-mail abaixo e enviaremos um link que fará login automaticamente.",
              email: "E-mail",
              submit: "Continuar",
              footer: "Conecte-se e-mail",
              alertTitle: "Você está quase lá...",
              alertMessage:
                "Enviamos o link mágico para o seu e-mail. ISTO É UM TESTE, NENHUM E-MAIL REAL FOI ENVIADO.",
            },
            edit: {
              title: "Por favor, complete seu perfil",
              addProfileImage: "Adicionar imagem de perfil",
              removeProfileImage: "Remover imagem de perfil",
              bioPlaceholder: "Compartilhe um pouco sobre você...",
              bio: "Biografia",
              submit: "Salvar",
              skip: "Pular isso e fazer depois",
            },
            magicSignin: {
              text: "Fazendo login...",
            },
            profile: {
              button: "Mensagem",
              button2: "Editar Perfil",
              title: "Espaços",
            },
            profileModal: {
              profile: "Perfil",
              message: "Mensagem",
              editProfile: "Editar perfil",
              openProfile: "Abrir perfil",
              spaces: "Espaços",
              selectImage: "Selecionar imagem",
            },
            signOut: {
              loggedOUt: "Desconectado",
              title: "Você está desconectado",
              message: "Até logo!",
            },
          },
          errors: {
            basic: "Ops, algo deu errado!",
          },
        },
      },
      de: {
        translation: {
          premiumFeatures: {
            personal: {
              description1:
                "Unbegrenzte Raums und Mitglieder, um Inhalte und Unterhaltungen für alle Ihre Angebote zu organisieren",
              description2:
                "Schöne Anmelde- und Landingpages für all Ihre Programme und Veranstaltungen an einem Ort",
              description3:
                "Senden Sie Newsletter-E-Mails an alle Ihre Teilnehmer mit unserer leistungsstarken Broadcast-Funktion",
            },
            professional: {
              description1:
                "Keine Transaktionsgebühren für eingezahlte Zahlungen",
              description2:
                "Individuelles Branding und Layoutoptionen sowie personalisierte URL-Subdomänen",
              description3:
                "Unbegrenzte Automatisierungen und interaktive Lernerlebnisse",
              description4: "Bis zu 2 Manager für alle Ihre Raum",
            },
            organization: {
              description1:
                "Keine Transaktionsgebühren für eingezahlte Zahlungen",
              description2: "Erweiterte Kundenunterstützung",
              description3: "Unbegrenzte Manager",
              description4: "Option für Single Sign-On-Integration",
            },
          },
          premiumMessages: {
            can_auto_tag: {
              title: "Upgrade to use this feature",
              message: "Auto tagging with AI is a premium feature available on the Professional plan."
            },
            can_use_zoom_recordings: {
              title: "Upgrade to use this feature",
              message: "Zoom recordings is a premium feature available on the Organization plan."
            },
            can_create_quizzes: {
              title: "Upgrade to use this feature",
              message: "Quizzes are a premium feature available on the Organization plan."
            },
            can_duplicate_spaces: {
              title: "Upgrade to use this feature",
              message:
                "Duplicating a space is a premium feature available on the Professional plan.",
            },
            can_save_templates: {
              title: "Upgrade to use this feature",
              message:
                "Saving as space as a reusable template is a premium feature available on the Professional plan.",
            },
            can_edit_shareable_link: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Anpassbare Freigabe-Links sind eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_upload_cover: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Hochladen eines individuellen Cover-Bildes ist eine Premium-Funktion, die im Personal-Plan verfügbar ist.",
            },

            can_upload_logo: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Festlegen eines individuellen Logos ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_set_layout: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Die Anpassung des Layouts Ihres Raums ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_set_theme: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Die Anpassung des Farbthemas Ihres Raums ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_set_format: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Umschalten des Formats Ihres Raums ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_add_step_send_to_pending: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Setzen neuer Mitglieder auf den Status 'Ausstehend' ist eine Premium-Funktion, die im Personal-Plan verfügbar ist.",
            },

            can_add_step_send_email: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Senden automatisierter E-Mails ist eine Premium-Funktion, die im Personal-Plan verfügbar ist.",
            },

            can_add_add_to_existing_space: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das automatische Hinzufügen von Mitgliedern zu ausgewählten Räumen ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_add_step_wait: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Hinzufügen einer Wartebedingung für zusätzliche Schritte ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_add_step_webhook: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Hinzufügen von Webhooks ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_add_step_survey_person: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Die Umfrage neuer Mitglieder ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_schedule_states: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Automatisierte Terminplanungsoptionen für Ihren Bereich sind eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_broadcast: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Senden von E-Mail-Broadcasts ist eine Premium-Funktion, die im Personal-Plan verfügbar ist.",
            },

            can_survey: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Erstellen von Umfragen ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_use_interest_form_block: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Hinzufügen von Interessensformularen ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },
            can_use_interactive_block: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das Hinzufügen von interaktiven Blöcken ist eine Premium-Funktion, die im Professional-Tarif verfügbar ist.",
            },

            can_set_flexible_payments: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Die Einrichtung flexibler Zahlungsoptionen ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_activate_completions: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Die Möglichkeit, Räume als abgeschlossen zu markieren, ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            max_allowed_spaces: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Hallo! Es sieht so aus, als hätten Sie alle Ihre kostenlosen Räume verwendet! Durch ein Upgrade auf den Personal-Plan erhalten Sie unbegrenzte Räume und Mitglieder, sodass Sie praktisch alles erstellen können.",
            },
            max_allowed_ij: {
              title: "Upgrade to use this feature",
              message:
                "You can only add up to 5 discussion prompts per space on the free plan. Please upgrade to the Professional plan to add unlimited discussion prompts to any space.",
            },

            max_allowed_managers_pro: {
              title: "Upgrade, um weitere Manager hinzuzufügen",
              message:
                "Upgrade auf den Professional-Plan, um bis zu 2 Manager zu Ihrem Bereich hinzuzufügen, oder auf den Organization-Plan für unbegrenzte Manager.",
            },

            max_allowed_managers_org: {
              title: "Upgrade, um weitere Manager hinzuzufügen",
              message:
                "Upgrade auf den Organization-Plan, um unbegrenzt viele Manager zu Ihrem Bereich hinzuzufügen.",
            },

            can_see_participation_tab: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Die Teilnahmemetriken sind eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },

            can_send_selective_broadcast: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Selektives Broadcasting ist eine Premium-Funktion, die im Organization-Plan verfügbar ist.",
            },

            can_send_message_broadcast: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Message Broadcasting ist eine Premium-Funktion, die im Organization-Plan verfügbar ist.",
            },
            can_add_from_children: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das automatische Hinzufügen von Mitgliedern aus Unterbereichen ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },
            can_add_to_direct_open_children: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das automatische Hinzufügen eingehender Mitglieder zu allen direkt geöffneten Unterbereichen ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },
            can_send_message: {
              title: "Upgrade, um diese Funktion zu nutzen",
              message:
                "Das automatische Senden von Direktnachrichten an neue Mitglieder ist eine Premium-Funktion, die im Professional-Plan verfügbar ist.",
            },
          },
          home: {
            sidebar: {
              createButton: "Neuer Raum",
              spaces: "Ihre Räume",
              invitations: "Einladungen",
              directMessages: "Direktnachrichten",
              gettingStarted: "Gestartet Bekommen ",
              scheduleDemo: "Demo vereinbaren",
              upgrade: "Upgrade Ihres Plans",
              promoBoxTitle:
                "Werden Sie Teil der Hunderten von Kreativen, die Sutra's fortgeschrittene Funktionen nutzen, um leistungsstarke Gruppenerlebnisse online zu ermöglichen",
              promoBoxItem1: "Unbegrenzte Räume",
              promoBoxItem2: "Volle Branding-Optionen",
              promoBoxItem3: "Zusätzliche Manager",
              promoBoxButton: "Jetzt upgraden",
            },
            spacesList: {
              yourSpaces: "Ihre Räume",
              directMessagesTitle: "Direktnachrichten",
              invitationsTitle: "Einladungen",
              topTitle: "Räume auf oberster Ebene",
              managedTitle: "Verwaltete Bereiche",
              allTitle: "Alle Räume",
              archivedTitle: "Archivierte Räume",
              deletedTitle: "Gelöschte Räume",
              pendingTitle: "Geplante Räume",
              directMessages: "Direkt",
              top: "Höchststufe",
              managed: "Verwaltete",
              all: "Alle",
              archived: "Archiviert",
              invitations: "Einladungen",
              deleted: "Gelöscht",
              pending: "Geplant",
              empty: "Du hast keine",
              tabTitle: "Sutra - Dashboard",
              learn: "Die Grundlagen lernen",
              createFirst: "Erstellen Sie Ihren ersten Raum",
            },
          },
          app: {
            payment: {
              payment: {
                tabTitle: "Sutra - Zahlungsformular",
                title: "Zahlung abschließen",
              },
              planOption: {
                frequencyDescription1: "pro Monat",
                frequencyDescription2: "pro Monat bei jährlicher Abrechnung",
                currentPlan: "Aktueller Plan",
                billedMonthly: "Monatliche Abrechnung",
                billedAnnually: "Jährliche Abrechnung",
              },
              stripeForm: {
                due_in_trial: "Fällig in 14 Tagen",
                pay: "Bezahlen",
              },
            },
            gettingStarted: {
              items: {
                setup: "Einrichten Ihres Programm Raums",
                privacy: "Datenschutz- und Zahlungseinstellungen",
                registration: "Erstellen einer Anmeldeseite",
                invitations: "Teilnehmer einladen",
                settings: "Funktionsweise der Raum-Einstellungen",
                interactive: "Interaktive Reisen",
                interest: "Interessensformulare",
                appearance: "Layout und Farbschemata",
                grids: "Grid- und Blockeinstellungen",
                broadcasts: "E-Mail-Rundsendungen",
                tagging: "Tagging",
                emojis: "Seitenleisten-Emojis",
              },
              templates: {
                groupprogram: {
                  label: "Gruppen programm",
                  description:
                    "Ein Programmbereich, in dem Sie alles organisieren können, was Sie für eine Lerngruppe benötigen.",
                },
                zoomworkshop: {
                  label: "Zoom-Workshop",
                  description:
                    "Organisieren Sie alles, was Sie für einen einfachen Zoom-Workshop benötigen.",
                },
                mastermind: {
                  label: "Mastermind",
                  description:
                    "Laden Sie eine Gruppe von Menschen ein, sich gemeinsam auf einen Mastermind-Prozess einzulassen.",
                },
                selfpacedcourse: {
                  label: "Selbstgesteuertes Kurs",
                  description:
                    "Eine flexible Vorlage zur Erstellung eines selbstgesteuerten Kurses.",
                },
                communityspace: {
                  label: "Community-Raum",
                  description:
                    "Bringen Sie Menschen in einem lebendigen Online-Community-Raum zusammen, der mit Ihrem Community-Wachstum wächst.",
                },
                oneononecoaching: {
                  label: "Einzelcoaching",
                  description:
                    "Erstellen Sie einen dedizierten Raum für jeden Ihrer Coaching-Klienten.",
                },
              },
              loading: {
                text: "Ihre Vorlage wird geladen, bitte warten Sie...",
              },
              tabTitle: "Sutra - Erste Schritte",
              mainTitle: "Sutra - Bekommen gestartet",
              selectTemplate: "Diese Vorlage auswählen",
              advanceFeatures: "Erweiterte Funktionen",
              welcome: "Willkommen",
              description1:
                "Sutra ermöglicht es Ihnen, Inhalte und Gespräche für Kurse, Gemeinschaften, Einzelcoachings und vieles mehr zu organisieren. Erfahren Sie in diesem fünfminütigen Video die Grundlagen.",
              watchVideo: "Video ansehen",
              createFirstSpace: "Erstellen Sie Ihren ersten Raum",
              gettingStartedGuide: "Leitfaden für den Einstieg",
              description2:
                "Erfahren Sie mehr über die Funktionsweise von Sutra und starten Sie mit einer Start-Checkliste und einer detaillierteren Anleitung.",
              checkOutTheGuide: "Den Leitfaden anzeigen",
              startWithATemplate: "Mit einer Vorlage starten",
              description3:
                "Erfahren Sie mehr über die Funktionsweise von Sutra und starten Sie mit einer Start-Checkliste und einer detaillierteren Anleitung.",
              learnAbout: "Erweiterte Funktionen kennenlernen",
              description4:
                "Sutra ist so konzipiert, dass es flexibel und leistungsstark ist und nahezu jeden Anwendungsfall unterstützen kann. Sie können einzigartige interaktive Erlebnisse schaffen, Interesse an Ihren verschiedenen Angeboten wecken, E-Mail-Newsletter versenden und gemeinsames Wissen nutzen.",
              learnmore: "Mehr erfahren",
            },
            premium: {
              try: "Sutra ausprobieren",
              include: "Funktionen umfassen",
              upgrade: "Jetzt upgraden",
            },
            pricing: {
              tabTitle: "Sutra - Preise",
              upgrade: "Upgrade Ihres Plans",
              text: "Was auch immer Sie für Ihren Kurs oder Ihre Gemeinschaft benötigen, wir unterstützen Ihre Arbeit. Bei Fragen können Sie uns unter support@sutra.co kontaktieren.",
            },
            scheduleDemo: {
              tabTitle: "Sutra - Demo vereinbaren",
            },
            welcome: {
              tabTitle: "Sutra - Willkommen",
              title: "Willkommen bei Sutra!",
              description1:
                "Wir freuen uns, Sie hier zu haben. Bitte nehmen Sie sich einen Moment Zeit, um das Video unten anzusehen...",
              description2:
                "Möchten Sie eines unserer kostenpflichtigen Pläne kostenlos ausprobieren?",
              description3:
                "Wählen Sie unten einen Plan aus, um eine 14-tägige kostenlose Testphase zu starten. Nach Ablauf der Testphase werden Sie automatisch für den ausgewählten Plan in Rechnung gestellt.",
              yes: "Ja, zeigen Sie mir die Optionen",
              no: "Nein, danke",
            },
          },
          layout: {
            userButton: {
              home: "Startseite",
              viewProfile: "Profil anzeigen",
              settings: "Einstellungen",
              support: "Support",
              language: "Sprache",
              terms: "Nutzungsbedingungen & Datenschutz",
              logout: "Ausloggen",
              copyright: "Sutra Spaces, LLC © 2022",
            },
            directMessages: {
              label: "Direktnachrichten",
              actionButton: "Alle ansehen",
              empty: "You don't have direct messages",
              directMessage: "Direktnachrichten",
            },
            notifications: {
              label: "Benachrichtigungen",
              actionButton: "Alle löschen",
              empty: "Sie haben keine Benachrichtigungen",
              notification: "Benachrichtigungen",
            },
            headerContent: {
              registrationPage: "Registrierungsseite",
              nonManagersHidden: "Kopfzeile für Nicht-Manager ausgeblendet",
            },
            search: {
              reflection: "Reflexion",
              comment: "Kommentar",
              allSpaces: "Alle Räume",
              withinTop: "Innerhalb des obersten Bereichs",
              withinSpace: " Innerhalb dieses Raums",
              everything: "Alles",
              titlesOnly: "Nur Titel",
              tagsOnly: "Nur Tags",
              content: "Inhalt",
              discussion: "Diskussion",
              events: "Veranstaltungen",
              users: "Benutzer",
              placeholder: "Suche...",
              nothingFound: "Nichts gefunden",
              search: "Suche hier",
            },
            uploader: {
              pending: "Ausstehend",
              used: "Verwendet",
              uploading: "Hochladen",
              finishing: "Abschließen",
              processing: "Verarbeiten",
              completed: "Abgeschlossen",
            },
          },
          resources: {
            pages: {
              form: {
                label: "Titel",
                placeholder: "Worum geht es auf deiner Seite?",
                create: "Seite erstellen",
                save: "Seite speichern",
              },
            },
            form: {
              title: {
                label: "Titel",
                placeholder: "Worum geht es?",
              },
              publish: {
                label: "Am Datum veröffentlichen",
              },
              date: {
                label: "Datum auswählen",
              },
              update: "Seitensammlung aktualisieren",
              create: "Seitensammlung erstellen",
            },
          },
          settings: {
            editProfile: {
              tabTitle: "Sutra - Profil bearbeiten",
              editProfile: "Profil bearbeiten",
              firstName: "Vorname",
              lastName: "Nachname",
              username: "Benutzername",
              email: "E-Mail",
              removeProfileImage: "Profilbild entfernen",
              addProfileImage: "Profilbild hinzufügen",
              about: "Über mich",
              location: "Standort",
              bio: "Biografie",
              bioPlaceholder: "Teilen Sie ein wenig über sich selbst mit.",
              updateProfile: "Profil aktualisieren",
            },
            accountSettings: {
              accountSettings: "Kontoeinstellungen",
              changePassword: "Passwort ändern",
              currentPassword: "Aktuelles Passwort",
              newPassword: "Neues Passwort",
              newPasswordConfirmation: "Bestätigung des neuen Passworts",
              updatePassword: "Passwort aktualisieren",
              advanced: "Erweitert",
              deleteAccount: "Konto löschen",
              warning: "Warnung",
              deleteAccountWarning:
                "Das Löschen Ihres Kontos ist nicht rückgängig zu machen und entfernt Ihre Kontodaten dauerhaft aus unserem System.",
              closeYourAccount: "Konto schließen",
              invalidCurrentPasswordErrorMsg: "Darf nicht leer sein",
              invalidPasswordErrorMsg:
                "Das Passwort muss mindestens 8 Zeichen lang sein",
              invalidPasswordConfirmationErrorMsg:
                "Passwörter stimmen nicht überein",
              deleteAccountAlert: {
                title: "Wichtig",
                message:
                  "Das Löschen Ihres Kontos ist nicht rückgängig zu machen und entfernt Ihre Kontodaten dauerhaft aus unserem System.",
                input:
                  "Bitte geben Sie 'Löschen Sie mein Konto' ein, um diese Aktion zu bestätigen",
                confirmButton: "Für immer löschen",
                cancelButton: "Abbrechen",
              },
              cancelSubscriptionAlert: {
                title: "Wichtig",
                message: "Bitte kündigen Sie zuerst Ihr Abonnement",
                confirmButton: "Zur Rechnungsstellung gehen",
                cancelButton: "Später erledigen",
              },
              stripeAccount: {
                title: "Stripe-Verbindung zurücksetzen",
                alertTitle: "Sind Sie sicher?",
                alertMessage:
                  "Wenn Sie Ihre Stripe-Verbindung zurücksetzen, werden Ihre Stripe-Anmeldedaten aus Ihrem Benutzerkonto auf Sutra gelöscht und Sie können eine neue Stripe-Verbindung herstellen.",
                buttonText: "Verbindung zurücksetzen",
                confirmButton: "Ja",
                cancelButton: "Nein",
                confirmTitle: "Sind Sie sicher?",
                confirmMessage:
                  "Ihre Stripe-Anmeldedaten werden aus Ihrem Benutzerkonto auf Sutra gelöscht",
              },
            },
            emailPreferences: {
              tabTitle: "Sutra - E-Mail-Einstellungen",
              emailPreferences: "E-Mail-Einstellungen",
              emailDigestPreferences: "E-Mail-Digest-Einstellungen",
              emailDigestFrequency: "E-Mail-Digest-Frequenz",
              emailDigestMessage:
                "Um E-Mail-Einstellungen pro Bereich anzupassen, sehen Sie bitte den Bereich 'Abonnementeinstellungen' ein. Sie können sich komplett von einem Bereich abmelden, indem Sie ihn verlassen, oder Sie können jeden einzelnen Bereich in den Abonnementeinstellungen auf 'Stumm' setzen.",
              receiveWeeklyDigest:
                "Erhalten Sie einen wöchentlichen Digest über neu markierte Elemente und Diskussionen",
              receiveAtMentions:
                "Erhalten Sie eine Benachrichtigung, wenn Sie von jemandem @erwähnt werden",
            },
            subscriptions: {
              subscriptions: "Bereichsspezifische Einstellungen",
              searchBySpaceName: "Nach Bereichsnamen suchen",
              search: "Such",
              clear: "Klar",
              options: "Optionen",
              otherActions: "Andere Aktione",
              emptySubscriptions: "Sie haben keine Abonnements.",
              leaveSpaceConfirmationTitle:
                "Sind Sie sicher, dass Sie diesen Bereich verlassen möchten?",
              leaveSpaceConfirmationHtml:
                "Sie sind nicht mehr Mitglied dieses Bereichs und können möglicherweise ohne die Erlaubnis des Managers nicht mehr beitreten.",
              leaveSpaceButtonText: "Ja, verlassen",
              leaveSpaceDenyButtonText: "Nein",
              leaveSpace: "Bereich verlassen",
              unsubscribe: "Abbestellen",
              unsubscribed: "Abbestellt",
            },
            digestPreferences: {
              digestedLabel: "Alle Updates in einem Digest gruppieren",
              digestedDescription:
                "Neue Nachrichten in allen Ihren Bereichen werden in einer einzigen E-Mail-Aktualisierung zusammengefasst.",
              undigestedLabel: "Updates nach Bereich gruppieren",
              undigestedDescription:
                "Sie erhalten für jeden Bereich, der neue Nachrichten enthält, separate E-Mail-Zusammenfassungen.",
            },
            billing: {
              statuses: {
                pending: "Ausstehend",
                processing: "wird bearbeitet",
                paid: "Bezahlt",
              },
              paymentMethod: {
                currentPaymentMethod: "Aktuelle Zahlungsmethode",
                noCurrentPaymentMethod: "Keine aktuelle Zahlungsmethode",
                addNewPaymentMethod: "Neue Zahlungsmethode hinzufügen",
              },
              stripe: {
                updatePaymentMethod: "Zahlungsmethode aktualisieren",
              },
              tabTitle: "Sutra - Abrechnung",
              updatePaymentMethodMayus: "ZAHLUNGSMETHODE AKTUALISIEREN",
              payments: "ZAHLUNGEN",
              pdf: "PDF",
              noPayments: "Sie haben keine Zahlungshistorie",
              billing: "Abrechnung",
              upgradePlan: "Tarif aktualisieren",
              changePlan: "Tarif ändern",
              updatePaymentMethod: "Zahlungsmethode aktualisieren",
              cancelSubscription: "Abonnement kündigen",
              cancelConfirmationTitle:
                "Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten?",
              cancelConfirmationHtml:
                "Sie haben dann keinen Zugriff mehr auf die Premium-Funktionen Ihres Tarifs.",
              cancelConfirmButtonText: "Ja, kündigen",
              cancelDenyButtonText: "Nein",
              billingHistory: "Abrechnungsverlauf",
              tableDate: "Datum",
              tableAmount: "Betrag",
              tableStatus: "Status",
              tableInvoice: "Rechnung",
              planPrefix: "Sie befinden sich derzeit auf dem",
              planSuffix: "Tarif",
              updatePayment: {
                success: {
                  title: "Erfolgreich",
                  message: "Ihre Zahlungsmethode wurde aktualisiert",
                },
                error: {
                  title: "Oops, etwas ist schiefgelaufen!",
                },
              },
            },
            subscriptions: {
              frequencyOptions: "Frequenzoptionen",
              otherActions: "Andere Aktionen",
            },
            frequencyOptions: {
              mute: "Stummschalten",
              always: "Bei jeder Nachricht",
              hour1: "Stündlich",
              hour2: "Alle 2 Stunden",
              hour4: "Alle 4 Stunden",
              hour8: "Alle 8 Stunden",
              hour24: "Einmal täglich",
              hour168: "Einmal wöchentlich",
              hour336: "Alle zwei Wochen",
            },
            affiliates: {
              empty: "Sie haben keine Affiliate-Auszahlungshistorie",
              history: {
                table: {
                  referralName: "Empfehlungsname",
                  startAt: "Startdatum",
                  endAt: "Enddatum",
                  createdAt: "Erstellt am",
                  percent: "Prozent",
                  total: "Insgesamt verdient",
                  status: "Status",
                  value: "Wert",
                  revenue: "Einnahmen",
                  payAt: "Zahlung am",
                  status: "Status",
                },
                statuses: {
                  null: "Ausstehend",
                  pending: "Ausstehend",
                  processing: "In Bearbeitung",
                  paid: "Bezahlt",
                },
                paymentsHistory: "Zahlungshistorie",
              },
              tabTitle: "Sutra - Affiliates",
              title: "Affiliates",
              affiliateLink: "Das ist Ihr Affiliate-Link",
              copyMessage: "Link wurde in die Zwischenablage kopiert",
              stripeTitle: "Mit Stripe verbunden",
              stripeConnected:
                "Dies ist Ihre Stripe-Konto-ID. Um diese ID in Stripe zu finden, klicken Sie bitte auf den Link, um sich bei Stripe einzuloggen, und scrollen Sie zum Ende Ihrer Kontoeinstellungen.",
              stripeDisconnect: "Verbindung trennen",
              description:
                "Um Zahlungen für das Partnerprogramm abzuwickeln, verbinden Sie bitte Ihr Stripe-Konto, indem Sie auf den unten stehenden Button klicken.",
              description2:
                "Wenn Sie diesen Link mit anderen teilen, erhalten Sie automatisch 40% des Umsatzes, der im ersten Jahr von denen erzielt wird, die sich für einen kostenpflichtigen Plan auf Sutra anmelden.",
              connect: "Stripe verbinden",
              historyLabel: "Affiliate-Verlauf",
            },
          },
          spaces: {
            settings: {
              layout: {
                navbarContent: {
                  mainSettings: "Allgemeine Einstellungen",
                  members: "Mitglieder",
                  broadcast: "Broadcast",
                  surveys: "Umfragen",
                  structures: "Strukturen",
                  sutraAdmin: "Sutra Admin",
                  scheduled: "Geplant",
                  archived: "Archiviert",
                  participation: "Teilnahme",
                },
                headerActions: {
                  shareableLink: "TEILBARER LINK",
                },
              },
              main: {
                title: "Allgemeine Einstellunge",
                main: {
                  invite: "Einladen",
                  shareableLink: "Teilbarer Link",
                  cancelLink: "Abbrechen",
                  saveLink: "Speichern",
                  editLinkButton: "Link bearbeiten",
                  subdomain: "Dein Subdomain",
                  sutra_co: ".sutra.co",
                  label1: "Link in Zwischenablage kopiert",
                  privacySetting: "Wähle eine Datenschutzeinstellung",
                  title1: "Diesen Space öffentlich zugänglich machen",
                  description1:
                    "Dieser Space befindet sich innerhalb eines privaten Spaces und ist nur für Mitglieder dieses Spaces sichtbar. Du kannst ihn öffentlich zugänglich machen, indem du diese Einstellung aktivierst.",
                  title2: "Registrierungsseite anzeigen",
                  description2:
                    "Nicht-Mitglieder sehen eine Registrierungsseite, wenn sie auf diesen Space zugreifen möchten, wenn diese Einstellung aktiviert ist.",
                  title3: "Legacy Registrierungsseite verwenden",
                  description3:
                    "Wenn diese Einstellung aktiviert ist, verwendet dieser Space die mit unserem Legacy-Editor erstellte Registrierungsseite.",
                  onButton: "AN",
                  offButton: "AUS",
                  editButton: "Seite bearbeiten",
                  previewButton: "Seite Vorschau",
                  label2: "Datenschutz- und Zahlungseinstellungen",
                  label3: "Einstellungen der Registrierungsseite",
                  description4:
                    "Aktiv - Personen sehen eine Registrierungsseite, bevor sie auf diesen Space zugreifen können",
                  label4: "Automatisierung",
                  description5:
                    "Planen von E-Mails und automatisches Hinzufügen von Personen zu Spaces bei der Registrierung",
                  label5: "Planungseinstellungen",
                  description6:
                    "Eventdatum festlegen oder Veröffentlichungs- und Archivierungsoptionen planen",
                  label6: "Zusätzliche Einstellungen",
                  description7:
                    "Berechtigungen zur Inhalterstellung, Sichtbarkeit der Mitgliedschaft, Managerbenachrichtigungen und übergeordnete Spaces",
                  label7: "SEO-Einstellungen",
                  description8:
                    "Meta-Titel, Beschreibung und Bild für die soziale Freigabe und Suchmaschinenoptimierung festlegen",
                  label8: "Öffentlich",
                  description9: "Jeder kann auf diesen Space zugreifen",
                  label9: "Privat",
                  description10:
                    "Dieser Space ist privat und Personen müssen eingeladen werden, um beizutreten",
                  label10: "Bezahlt",
                  description11:
                    "Dieser Space ist privat und kostenpflichtig. Personen müssen bezahlen, um beizutreten",
                  description12:
                    "Personen sehen eine Registrierungsseite, bevor sie auf diesen öffentlichen Space zugreifen können.",
                  description13:
                    "Personen gelangen direkt in diesen Space, wenn sie auf diesen öffentlichen Space zugreifen.",
                  description14:
                    "Personen müssen direkt eingeladen werden, um auf diesen privaten Space zugreifen zu können. Wenn sie eingeladen werden, sehen sie eine Registrierungsseite.",
                  description15:
                    "Personen müssen direkt eingeladen werden, um auf diesen privaten Space zugreifen zu können. Wenn sie eingeladen werden, sehen sie eine Kontenerstellungseite ohne Registrierungsseite.",
                  description16:
                    "Personen sehen eine Registrierungsseite, bevor sie auf diesen kostenpflichtigen Space zugreifen können.",
                  description17:
                    "Personen sehen eine Kontenerstellungs- und Zahlungsseite, bevor sie auf diesen kostenpflichtigen Space zugreifen können. Sie sehen keine Registrierungsseite.",
                  on: "AN",
                  off: "AUS",
                  label11: "Mitglieder",
                  label12: "Ausstehend",
                  label13: "Interessiert",
                  label14: "Eingeladen",
                  label15: "Zahlungen",
                  label16: "Markenbildung",
                },
                additional: {
                  allowMembersEdit:
                    "Mitgliedern erlauben, den Inhalt auf dieser Seite zu bearbeiten",
                  membersWillAddContent:
                    "Mitglieder können Inhalte auf dieser Seite hinzufügen und bearbeiten",
                  allowMembersCreatePosts:
                    "Mitgliedern erlauben, neue Beiträge zu erstellen",
                  membersWillCreatePosts:
                    "Mitglieder können neue Beiträge in diesem Space erstellen",
                  allowMembersCreateEvents:
                    "Mitgliedern erlauben, neue Veranstaltungen zu erstellen",
                  membersWillCreateEvents:
                    "Mitglieder können neue Veranstaltungen in diesem Space erstellen",
                  allowMembersCreateSubspaces:
                    "Mitgliedern erlauben, neue Unter-Spaces zu erstellen",
                  membersWillCreateSubspaces:
                    "Mitglieder können Unter-Spaces auf dieser Seite erstellen",
                  membersCanCreate: "Mitglieder können erstellen",
                  circleCreation: {
                    options: {
                      discussion: "Diskussions-Spaces",
                      content: "Inhalts-Spaces",
                      event: "Veranstaltungs-Spaces",
                    },
                    placeholder:
                      "Wähle aus, welche Arten von Spaces Mitglieder in diesem Space erstellen können",
                  },
                  allowMembersToCopy: {
                    description1:
                      "Mitgliedern erlauben, Mitglieder zwischen Spaces zu kopieren",
                    description2:
                      "Mitglieder haben die Möglichkeit, Mitglieder automatisch von anderen Kreisen hinzuzufügen",
                  },
                  podType: {
                    description1:
                      "Alle Mitglieder können Nachrichten in diesem Space posten",
                    description2:
                      "Wenn diese Option deaktiviert ist, können nur Manager Nachrichten posten",
                  },
                  allowReflections: {
                    description1: "Reflexionen für diesen Space aktivieren",
                    description2:
                      "Wenn diese Option deaktiviert ist, wird die Reflexions-Option bei Nachrichten nicht angezeigt",
                  },
                  showMembers: {
                    description1: "Mitglieder anzeigen",
                    description2:
                      "Das Mitgliederverzeichnis für diesen Space anzeigen",
                  },
                  hideMmemberEmails: {
                    description1:
                      "E-Mail-Adressen der Mitglieder im Mitgliederverzeichnis anzeigen",
                    description2:
                      "Wenn diese Option deaktiviert ist, werden die E-Mail-Adressen der Mitglieder nicht im Verzeichnis aufgeführt",
                  },
                  newMemberNotifications: {
                    description1:
                      "Manager benachrichtigen, wenn neue Mitglieder beitreten",
                    description2:
                      "Eine Benachrichtigungs-E-Mail bei jeder Anmeldung eines neuen Mitglieds senden",
                  },
                  podSize: {
                    description1:
                      "Anzahl der Personen begrenzen, die diesem Space beitreten können",
                    description2:
                      "Eine maximale Anzahl von Personen angeben, die diesem Space beitreten können",
                  },
                  podSizeValue: {
                    description:
                      "Wie viele Personen können diesem Space beitreten?",
                  },
                  sidebarDefaultOpened: {
                    description1: "Space standardmäßig im Seitenbereich öffnen",
                    description2:
                      "Wenn diese Option aktiviert ist, wird dieser Space standardmäßig im Seitenbereich erweitert angezeigt",
                  },
                  sidebarHideChildren: {
                    description1: "Unter-Spaces im Seitenbereich ausblenden",
                    description2:
                      "Wenn diese Option aktiviert ist, werden in diesem Space erstellte Unter-Spaces nicht im Seitenbereich angezeigt",
                  },
                  allowCompletions: {
                    description1:
                      "Schaltfläche 'Als abgeschlossen markieren' anzeigen",
                    description2:
                      'Wenn diese Option aktiviert ist, sehen Mitglieder eine Schaltfläche "Als abgeschlossen markieren" für diesen Space. Nachdem sie diese Option ausgewählt haben, wird das Symbol im Seitenbereich durch ein Häkchen ersetzt.',
                  },
                  redirectToSubspace: {
                    description1: "Immer zu Unter-Space umleiten",
                    description2:
                      "Wenn diese Option aktiviert ist, gelangen Mitglieder immer direkt zum ausgewählten Unter-Space, wenn sie auf diesen Space zugreifen.",
                  },
                  parentCircles: {
                    description1: "Übergeordnete Bereiche",
                    placeholder:
                      "Suche nach Spaces, die als Eltern für diesen Space hinzugefügt werden sollen",
                  },
                },
                scheduling: {
                  showStartDate: {
                    description1: "Diesen Space als Veranstaltung anzeigen",
                    description2:
                      "Dieser Space zeigt überall ein Veranstaltungsdatum an, wo er aufgelistet ist",
                  },
                  startDateTime: {
                    title: "Startdatum und -zeit",
                    placeholder: "Datum auswählen",
                  },
                  endDateTime: {
                    title: "Enddatum und -zeit",
                    placeholder: "Datum auswählen",
                  },
                  showLaunchDate: {
                    description1:
                      "Diesen Space zu einem bestimmten Datum veröffentlichen",
                    description2:
                      "Dieser Space wird am angegebenen Datum sichtbar und zugänglich",
                  },
                  launchDateTime: {
                    placeholder: "Datum auswählen",
                  },
                  showArchiveDate: {
                    description1:
                      "Diesen Space zu einem bestimmten Datum archivieren",
                    description2:
                      "Dieser Space wird am angegebenen Datum nicht mehr zugänglich sein",
                  },
                  archiveDate: {
                    placeholder: "Datum auswählen",
                  },
                  showReadonlyDate: {
                    description1:
                      "Diesen Space zu einem bestimmten Datum schreibgeschützt machen",
                    description2:
                      "Nach dem angegebenen Datum können keine neuen Nachrichten mehr gepostet werden",
                  },
                  readonlyDate: {
                    placeholder: "Datum auswählen",
                  },
                },
                seo: {
                  title: {
                    title: "Titel-Tag",
                    description:
                      "Dies ist der Titel, der in den Suchmaschinenergebnissen und bei Social-Media-Beiträgen angezeigt wird. Die optimale Länge beträgt 60 Zeichen.",
                  },
                  description: {
                    title: "Meta-Beschreibung",
                    description:
                      "Dies ist die Beschreibung, die in den Suchmaschinenergebnissen und bei Social-Media-Beiträgen angezeigt wird. Die optimale Länge beträgt 150 bis 350 Zeichen.",
                  },
                  image: {
                    title: "Meta-Bild",
                    description:
                      "Dies ist das Bild, das bei Social-Media-Beiträgen angezeigt wird. Die empfohlene Abmessung beträgt 1200 Pixel x 627 Pixel (Seitenverhältnis 1,91/1).",
                  },
                },
              },
              members: {
                title: "Mitglieder",
                members: {
                  download: "CSV herunterladen",
                  invite: "Einladen",
                  member: "Mitglieder",
                  invited: "Eingeladen",
                  interested: "Interessiert",
                  payment: "Zahlungen",
                },
                interested: {
                  interested: {
                    button: "Suchen",
                    placeholder: "Suchen Sie nach mehr",
                    broadcast: "Broadcast senden",
                    noRecordFound: "Keine Einträge gefunden",
                  },
                  table: {
                    firstname: "Vorname",
                    email: "E-Mail",
                    date: "Datum",
                  },
                  interestedrow: {
                    removeFromList: "Aus der Liste entfernen",
                  },
                },
                invited: {
                  invited: {
                    button: "Suchen",
                    placeholder: "Suchen Sie nach mehr",
                    noRecordFound: "Keine Einträge gefunden",
                  },
                  table: {
                    email: "E-Mail",
                    invitationlink: "Einladungslink",
                    amount: "Betrag",
                    date: "Versanddatum",
                  },
                },
                invitedrow: {
                  invitation: {
                    resend: "Einladung erneut senden",
                    cancel: "Einladung abbrechen",
                  },
                },
                inviteEmail: {
                  invite: {
                    placeholder:
                      "Geben Sie die E-Mail-Adresse ein oder erwähnen Sie Personen, die Sie zu diesem Space hinzufügen möchten. Einträge können durch Kommas, Leerzeichen oder Zeilenumbrüche getrennt werden.",
                    message:
                      "Fügen Sie eine optionale Begrüßungsnachricht hinzu",
                    checkboxLabel:
                      "E-Mail senden, um Personen darüber zu informieren, dass sie eingeladen wurden",
                    alertMessage:
                      "Sie verwenden derzeit einen Basic-Tarif und können keine Einladungen über Sutra senden. Wenn Sie auf Einladen klicken, wird für jede E-Mail-Adresse, die Sie hinzufügen, ein eindeutiger Einladungslink generiert. Sie können jede Person persönlich per E-Mail mit diesem Link kontaktieren.",
                    alertbutton: "Konto upgraden",
                    button: "Personen einladen",
                  },
                },
                inviteExisting: {
                  invite: {
                    copyMembers: "Mitglieder von einem anderen Space kopieren",
                    copyMembersPlaceholder:
                      "Wählen Sie Spaces aus, von denen Sie Mitglieder kopieren möchten",
                    addActiveMembers:
                      "Aktive Mitglieder von übergeordneten Spaces hinzufügen",
                    addActiveMembersPlaceholder:
                      "Wählen Sie aktive Mitglieder aus, die Sie zu diesem Space hinzufügen möchten",
                    addPendingMembers:
                      "Ausstehende Mitglieder von übergeordneten Spaces hinzufügen",
                    addPendingMembersPlaceholder:
                      "Wählen Sie ausstehende Mitglieder aus, die Sie zu diesem Space hinzufügen möchten",
                    checkboxLabel:
                      "E-Mail senden, um Personen darüber zu informieren, dass sie eingeladen wurden",
                    alert:
                      "Sie verwenden derzeit einen Basic-Tarif und können keine Einladungen über Sutra senden. Wenn Sie auf Einladen klicken, wird für jede E-Mail-Adresse, die Sie hinzufügen, ein eindeutiger Einladungslink generiert. Sie können jede Person persönlich per E-Mail mit diesem Link kontaktieren.",
                    alertButton: "Konto upgraden",
                    button: "Personen einladen",
                    thisOption:
                      "Diese Option ist für diesen Space nicht aktiviert",
                  },
                },
                inviteLink: {
                  invite: {
                    shareLinkText:
                      "Personen können sich direkt mit dem folgenden Link für diesen Space registrieren. Sie können diesen Link überall teilen, wo Sie möchten (E-Mail, soziale Netzwerke usw.).",
                    youCannotShare:
                      "Sie können keinen Link zu einem privaten Space teilen.",
                  },
                },
                invitePeople: {
                  invite: {
                    invitePeople: "Personen einladen",
                  },
                },
                membersActivity: {
                  members: {
                    placeholder: "Suche nach",
                    button: "Suchen",
                    emailButton: "E-Mail",
                    messageButton: "Nachricht",
                    noRecordFound: "Keine Einträge gefunden",
                  },
                  memberTable: {
                    noRecordFound: "Keine Einträge gefunden",
                    lastSeen: "Zuletzt gesehen",
                    lastPosted: "Zuletzt gepostet",
                    date: "Beitrittsdatum",
                  },
                },
                participationActivity: {
                  participation: {
                    searchPlaceholder: "Suche nach",
                    searchButton: "Suchen",
                    pickDatePlaceholder: "Zeitraum auswählen",
                    emailButton: "E-Mail",
                    messageButton: "Nachricht",
                    noRecordFound: "Keine Einträge gefunden",
                  },
                  memberTable: {
                    member: "Mitglied",
                    participation: "Teilnahme",
                    posts: "Beiträge",
                    words: "Wörter",
                  },
                },
                participationActivityRow: {
                  posts: "Beiträge",
                },
                paymentRow: {
                  payment: {
                    refundPayment: "Zahlung erstatten",
                  },
                },
                payments: {
                  payment: {
                    searchPlaceholder: "Suche nach",
                    searchButton: "Suchen",
                    noRecordFound: "Keine Einträge gefunden",
                  },
                  paymentTable: {
                    member: "Mitglied",
                    amount: "Betrag",
                    date: "Datum",
                    status: "Status",
                  },
                },
                progressActivity: {
                  progress: {
                    searchPlaceholder: "Suche nach",
                    searchButton: "Suchen",
                    emailButton: "E-Mail",
                    messageButton: "Nachricht",
                    noRecordFound: "Keine Einträge gefunden",
                    progressTracking:
                      "Die Verfolgung des Fortschritts ist nur in Spaces verfügbar, die interaktive Aufgaben haben.",
                  },
                  progressTable: {
                    member: "Mitglied",
                    progress: "Fortschritt",
                  },
                },
                registered: {
                  registered: {
                    searchPlaceholder: "Suche nach",
                    searchButton: "Suchen",
                    email: "E-Mail",
                    message: "Nachricht",
                    noRecordFound: "Keine Einträge gefunden",
                    pending: "Ausstehend",
                    manager: "Manager",
                    all: "Alle",
                    active: "Aktiv",
                  },
                  registeredTable: {
                    member: "Mitglied",
                    email: "E-Mail",
                    status: "Status",
                    role: "Rolle",
                    roleApproveAll: "Alle genehmigen",
                  },
                },
                registeredRow: {
                  registered: {
                    roleApprove: "Genehmigen",
                    joined: "Beigetreten",
                    sendMessage: "Nachricht senden",
                    stopCharging: "Abrechnung beenden",
                    emailPreferences: "E-Mail-Einstellungen",
                    removeMember: "Mitglied entfernen",
                  },
                },
              },
              payment: {
                charging: {
                  NumberOfTimes: {
                    howManyTimes: "Wie oft?",
                  },
                  UntilDate: {
                    pickDate: "Wähle ein Enddatum für die Abrechnung",
                  },
                },
                fees: {
                  description: {
                    fees: "GEBÜHREN:",
                    sutraFee: "SUTRA GEBÜHR +",
                    stripeProcessingFee: "STRIPE VERARBEITUNGSGEBÜHREN",
                  },
                },
                flexible: {
                  flexible: {
                    description:
                      "Um den Menschen flexible Zahlungsoptionen anzubieten, wie zum Beispiel ein Bezahlen-was-du-kannst-Modell oder mehrere Zahlungskategorien, kannst du eine Zahlungsumfrage erstellen, die die Menschen sehen, wenn sie sich für deinen Space registrieren. Klicke auf den Bearbeiten-Button unten, um die Umfrage anzupassen.",
                  },
                },
                once: {
                  once: {
                    description: "Wie viel willst du berechnen?",
                    number: "10",
                  },
                },
                recurring: {
                  recurring: {
                    description1: "Wie viel willst du berechnen?",
                    description2: "Wähle eine Abrechnungsoption",
                  },
                },
              },
              privacy: {
                open: {
                  open: {
                    description: "Jeder kann auf diesen Space zugreifen",
                  },
                },
                paid: {
                  paid: {
                    stripeConnected: "Mit Stripe verbunden",
                    disconnectButton: "Trennen",
                    description:
                      "Um Zahlungen für diesen Space abzuwickeln, verbinde bitte dein Stripe-Konto, indem du auf den unten stehenden Button klickst.",
                    connectButton: "Stripe verbinden",
                  },
                },
                private: {
                  private: {
                    description:
                      "Dieser Space ist privat und Personen müssen eingeladen werden, um beizutreten",
                  },
                },
              },
              registration: {
                steps: {
                  addFromSubSpace: {
                    label: "Wähle einen Space aus, um Mitglied hinzuzufügen",
                    description:
                      "In manchen Situationen können Personen einem Unter-Space beitreten, ohne dem übergeordneten Space beizutreten. Manchmal möchtest du sicherstellen, dass sie auch zu einem Top-Level-Space oder einem gemeinsamen Space hinzugefügt werden. Diese Option ermöglicht es dir. Immer wenn jemand einem Unter-Space beitritt, wird er automatisch zu den ausgewählten Spaces unten hinzugefügt.",
                    placeholder: "Spaces auswählen",
                    saveButton: "Schritt speichern",
                    deleteButton: "Schritt löschen",
                    saveButton2: "Schritt speichern",
                  },
                  addStructure: {
                    label1: "Struktur auswählen",
                    placeholder1: "Option auswählen",
                    label2: "Senden als",
                    placeholder2: "Option auswählen",
                    saveButton: "Schritt speichern",
                  },
                  addToExistingSpace: {
                    label: "Wähle einen Space aus, um Mitglied hinzuzufügen",
                    placeholder: "Spaces auswählen",
                    saveButton1: "Schritt speichern",
                    deleteButton: "Schritt löschen",
                    saveButton2: "Schritt speichern",
                  },
                  addToNewSpace: {
                    placeholder: "Name des neuen Spaces",
                    label: "Einen Namen für den neuen Space festlegen",
                    saveButton1: "Schritt speichern",
                    deleteButton: "Schritt löschen",
                    saveButton2: "Schritt speichern",
                  },
                  applyTemplate: {
                    label: "Space zum Kopieren auswählen",
                    description:
                      "Manchmal möchtest du einen Vorlagen-Space erstellen und ihn jedes Mal replizieren, wenn ein neuer Space erstellt wird. Du kannst einen Space erstellen und genau so einrichten, wie du es möchtest. Dann werden alle Inhalte und Unter-Spaces in jedem neu erstellten Space hier kopiert.",
                    saveButton1: "Schritt speichern",
                    deleteButton: "Schritt löschen",
                    saveButton2: "Schritt speichern",
                  },
                  editEmail: {
                    placeholder1: "Betreff",
                    label1: "Senden als",
                    placeholder2: "Option auswählen",
                    label2: "Senden an",
                    placeholder3: "Option auswählen",
                    placeholder4: "someone@special.com",
                    label3: "E-Mail-Adresse",
                    saveButton: "Schritt speichern",
                    deleteButton: "Schritt löschen",
                  },
                  editStructure: {
                    deleteButton: "Schritt löschen",
                  },
                  editSurvey: {
                    deleteButton: "Schritt löschen",
                  },
                  sendEmail: {
                    label1: "E-Mail auswählen",
                    placeholder1: "Option auswählen",
                    label2: "Senden als",
                    placeholder2: "Option auswählen",
                    label3: "Senden an",
                    placeholder3: "Option auswählen",
                    label4: "someone@special.com",
                    placeholder4: "E-Mail-Adresse",
                    saveButton: "Schritt speichern",
                    label5: "Manager",
                    label6: "Benutzerdefinierte E-Mail-Adresse",
                  },
                  setToPending: {
                    title: "Willkommensnachricht",
                    description:
                      "Dies ist der Inhalt, den die Personen unmittelbar nach der Registrierung für deinen Space sehen werden. Dies ist ein guter Ort, um zu erklären, wann das Programm startet und was sie in der Zwischenzeit erwarten können.",
                    saveButton1: "Schritt speichern",
                    deleteButton: "Schritt löschen",
                    saveButton2: "Schritt speichern",
                  },
                  surveyPerson: {
                    placeholder: "Option auswählen",
                    label: "Umfrage auswählen",
                    saveButton1: "Schritt speichern",
                    deleteButton: "Schritt löschen",
                    saveButton2: "Schritt speichern",
                  },
                  wait: {
                    description1: "Wie viele Tage?",
                    description2: "Warten bis",
                    saveButton: "Schritt speichern",
                    deleteButton: "Schritt löschen",
                    saveButton2: "Schritt speichern",
                    label1: "Wartebedingung auswählen",
                    placeholder: "Option auswählen",
                    label2: "Anzahl der Tage",
                    label3:
                      "Bis zu einem bestimmten Datum und einer bestimmten Uhrzeit warten",
                    label4: "Datum auswählen",
                  },
                  webhook: {
                    label: "Webhook-URL",
                    saveButton: "Schritt speichern",
                    deleteButton: "Schritt löschen",
                  },
                },
                addStep: {
                  addStep: {
                    description: "Schritt hinzufügen",
                    label: "Bitte wähle eine Aktion für diesen Schritt aus",
                    placeholder: "Aktion auswählen",
                  },
                },
                registrationFlow: {
                  registration: {
                    description1:
                      "Diese Funktion ermöglicht es dir, automatisierte Aktionen zu sequenzieren, die ausgelöst werden, wenn eine Person diesem Space beitritt oder Interesse zeigt. Hier kannst du beispielsweise E-Mails planen und Personen automatisch zu ausgewählten Spaces hinzufügen, wenn sie sich registrieren.",
                    description2: "Diese Aktionen ausführen, nachdem jemand...",
                    placeholder1: "Startbedingung auswählen",
                    addButton: "Schritt hinzufügen",
                    label1:
                      "Mitglied wird oder sich für die Interessentenliste anmeldet",
                    description3:
                      "Die folgenden Schritte werden ausgelöst, wenn eine Person Mitglied dieses Spaces wird oder sich für die Interessentenliste anmeldet, indem sie ihren Namen und ihre E-Mail-Adresse über ein Interessenformular eingibt.",
                    label2: "Mitglied dieses Spaces wird",
                    description4:
                      "Die folgenden Schritte werden ausgelöst, wenn eine Person Mitglied dieses Spaces wird.",
                    label3: "Interessieren Sie sich für diesen Raum",
                    description5:
                      "Die folgenden Schritte werden ausgelöst, wenn eine Person sich für die Interessentenliste anmeldet, indem sie ihren Namen und ihre E-Mail-Adresse über ein Interessenformular eingibt.",
                  },
                },
              },
              structure: {
                add: {
                  add: {
                    title: "Zu Spaces hinzufügen",
                    description: "Räume",
                    placeholder1: "Space auswählen",
                    label1: "Senden als",
                    placeholder2: "Absender wählen",
                    placeholder3: "Datum auswählen",
                    label2: "Sendedatum",
                    label3: "Zeit senden",
                    saveButton: "Speichern",
                    label4: "Planen Sie für später",
                  },
                },
                createStructure: {
                  title: {
                    createStructure: "Struktur erstellen",
                  },
                },
                structureItem: {
                  item: {
                    addToSpace: "Zu Space hinzufügen",
                    open: "View responses",
                    edit: "Bearbeiten",
                    duplicate: "Duplizieren",
                    delete: "Entferne",
                  },
                },
              },
              survey: {
                add: {
                  add: {
                    title: "Zu Spaces hinzufügen",
                    spaces: "Spaces",
                    placeholder1: "Space auswählen",
                    label: "Senden als",
                    placeholder2: "Absender wählen",
                    saveButton: "Speichern",
                  },
                },
                answer: {
                  answer: {
                    addOption: "Option hinzufügen",
                  },
                },
                createSurvey: {
                  description: "Umfrage erstellen",
                },
                createSurveyForm: {
                  form: {
                    title: "Umfragetitel",
                    description:
                      "Flexible Zahlungen ermöglichen Ihnen, mehrere Zahlungsoptionen für Ihr Programm anzubieten. Wenn Sie möchten, dass Personen ihren eigenen Preis festlegen, erstellen Sie einfach eine Zahlungsoption, ohne den Betrag für diese Option festzulegen. Diese Option wird mit einem Eingabefeld angezeigt, in dem die Person ihren eigenen Betrag festlegen kann. Sie können beispielsweise ein Pay-what-you-can-Modell mit mehreren vorgeschlagenen Stufen und einer völlig offenen Option anbieten. Wenn Sie unten eine Erklärung eingeben, wird dieser Text direkt über den Zahlungsoptionen angezeigt.",
                    optionalDescription: "Optionale Umfragebeschreibung",
                    addQuestionButton: "Frage hinzufügen",
                    saveButton: "Speichern",
                  },
                },
                individual: {
                  individual: {
                    placeholder: "Person auswählen",
                  },
                },
                multipleItem: {
                  multipleItem: {
                    placeholder: "Option",
                  },
                },
                paymentItem: {
                  paymentItem: {
                    placeholder: "Zahlungsoption",
                  },
                },
                paymentSurveyItem: {
                  item: {
                    editButton: "Zahlungsoptionen bearbeiten",
                    viewButton: "Antworten anzeigen",
                  },
                },
                showSurvey: {
                  survey: {
                    title: "Umfrageantworten",
                  },
                },
                singleItem: {
                  item: {
                    placeholder: "Option",
                  },
                },
                surveyItem: {
                  item: {
                    addToSpace: "Zu Space hinzufügen",
                    open: "View responses",
                    edit: "Bearbeiten",
                    take: "Preview survey",
                    duplicate: "Duplizieren",
                    download: "CSV herunterladen",
                    delete: "Entferne",
                    responses: "Antworten",
                  },
                },
              },
              broadcast: {
                broadcast: {
                  button: {
                    broadcastButton: "Neue Sendung",
                  },
                },
              },
              broadcast: {
                title: "Sendung",
                broadcastButton: "Neuer Rundfunk",
                membersTypes: {
                  active: "Aktive Mitglieder",
                  pending: "Ausstehende Mitglieder",
                  interested: "Interessierte Mitglieder",
                },
                sentOn: "Sendung weitergeschickt",
                onDestroy: {
                  alertTitle: "Bist du sicher?",
                  alertMessage: "Dieser Rundfunk wird dauerhaft gelöscht",
                  confirm: "Rundfunk löschen",
                  deny: "Abbrechen",
                },
                actions: {
                  edit: "Bearbeiten",
                  duplicate: "Duplizieren",
                  delete: "Löschen",
                },
                statuses: {
                  sent: "Gesendet",
                  scheduled: "Geplant",
                  draft: "Entwurf",
                  procesing: "Processing",
                },
                form: {
                  composeBroadcast: "Rundfunk erstellen",
                  contentErrMessage: "Füge etwas Inhalt hinzu",
                  targetErrMessage: "Wähle einen Empfänger aus",
                  type: {
                    email: "E-Mail senden",
                    message: "Nachrichtenbereich",
                  },
                  title: {
                    placeholder: "Betreffzeile",
                  },
                  brackets: {
                    part1: "Verwende",
                    part2: "und",
                    part3:
                      "um die entsprechenden Informationen automatisch auszufüllen",
                  },
                  target: {
                    placeholder: "Empfänger auswählen",
                    label: "Senden an",
                  },
                  sendAs: {
                    label: "Senden als",
                    placeholder: "Absender wählen",
                  },
                  schedule: {
                    label: "Später planen",
                  },
                  sendAtDate: {
                    label: "Sendedatum",
                    placeholder: "Datum auswählen",
                  },
                  sendAtTime: {
                    label: "Sendezeit",
                    placeholder: "",
                  },
                  scheduleBroadcast: "Rundfunk planen",
                  sendBroadcast: "Rundfunk senden",
                  saveDraft: "Entwurf speichern",
                },
              },
              surveys: {
                title: "Umfragen",
                createSurvey: "Umfrage erstellen",
              },
              structures: {
                title: "Strukturen",
                createStructure: "Struktur erstellen",
              },
              sutraAdmin: {
                title: "Sutra Admin",
              },
              scheduled: {
                title: "Geplant",
              },
              archived: {
                title: "Archiviert",
              },
              participation: {
                title: "Teilnahme",
                label1: "Anwesenheit",
                description1:
                  "% der Mitglieder, die in den letzten 7 Tagen besucht haben",
                label2: "Aktivität",
                description2:
                  "% der Mitglieder, die in den letzten 7 Tagen Beiträge veröffentlicht haben",
                label3: "Beiträge",
                description3:
                  "Gesamtzahl der Beiträge in allen Unterbereichen in den letzten 7 Tagen",
                label4: "Teilnahme",
                label5: "Mitgliederaktivität",
                label6: "Fortschritt",
              },
            },
            sidebar: {
              iconSelect: {
                placeholder: "Suche",
              },
              membersNode: {
                member: "Mitglieder",
              },
              nodeIcon: {
                reset: "Zurücksetzen",
              },
              sidebarTutorial: {
                gettingStarted: "Erste Schritte",
              },
            },
            alreadyMember: {
              member: {
                welcome:
                  "Willkommen! Du bist bereits Mitglied in diesem Bereich -",
                clickHere: "Klicke hier, um einzutreten",
              },
            },
            calender: {
              calender: {
                joinButton: "Anruf beitreten",
                addButton: "Zum Kalender hinzufügen",
                joinEventButton: "Registrieren",
                title: "Veranstaltungsdetails",
              },
            },
            invitationBar: {
              button: {
                register: "Registrieren",
              },
            },
            space: {
              sutraLoading: "Sutra - Lade...",
            },
            statusBar: {
              restoreButton: "Wiederherstellen",
            },
            tagFilter: {
              resetButton: "Zurücksetzen",
            },
            presentations: {
              discussion: {
                reflectionHeader: "Reflexionen",
                backToPost: "Zurück zum Beitrag",
                pinnedMessageHeader: "Angeheftete Nachricht",
                delete: {
                  title: "Bist du sicher?",
                  message: "Diese Aktion kann nicht rückgängig gemacht werden",
                  confirmButton: "Ja, löschen",
                  cancelButton: "Stornieren",
                },
              },
              tag: {
                placeholder: "Tags durchsuchen oder neuen erstellen",
                placeholder2: "Wähle ein Tag aus",
                createAction: "Erstellen",
              },
              message: {
                reply: "Reflektieren",
                addTags: "Tags hinzufügen",
                tag: "Tag",
                copyToClipboard: "In Zwischenablage kopieren",
                messageCopied: "Nachricht wurde in die Zwischenablage kopiert",
                pinMessageAction: "Nachricht anheften",
                editMessage: "Nachricht bearbeiten",
                deleteMessage: "Nachricht löschen",
                makePrivate: "Privat machen",
                viewReplies1: "Anzeigen",
                viewReplies2single: "Reflexion",
                viewReplies2plural: "Reflexionen",
                privateMessage:
                  "Dieser Beitrag ist derzeit nur für dich und die Manager dieses Bereichs sichtbar. Wenn du ihn öffentlich machst, wird er für andere Teilnehmer in diesem Bereich sichtbar.",
                privateMessage2:
                  "Diese Person hat sich entschieden, ihre Antwort nicht mit anderen Teilnehmern zu teilen.",
                unreadMessagesText: "Du hast ungelesene Nachrichten",
                scrollDownButton: "Nach unten scrollen",
                newReflections: {
                  part1: "Du hast",
                  part2: "ungelesene Reflexionen in",
                  part3: "Nachrichten",
                },
                viewNextButton: "Weiter anzeigen",
                pinMessage: {
                  mainText: "Angeheftete Nachricht",
                  viewButton: "Nachricht anzeigen",
                },
              },
              content: {
                commentInput: {
                  toggleLabel1:
                    "Kommentare sind für interaktive Abläufe deaktiviert",
                  toggleLabel2: "Kommentare für diesen Beitrag aktivieren",
                  toggleLabel3:
                    "Kommentare für diesen Veranstaltungsbereich aktivieren",
                  toggleLabel4:
                    "Kommentare für diesen Veranstaltungsbereich aktivieren",
                  placeholder1:
                    "Kommentare für diesen Inhaltsbereich aktivieren",
                  placeholder2: "Schreibe etwas...",
                },
              },
              fallback: {
                title: "Verbindungsproblem",
                message:
                  "Es scheint, dass du keine Internetverbindung hast. Sobald du wieder online bist, stellen wir automatisch eine Verbindung für dich her.",
              },
              events: {
                placeholder: "Schreibe etwas...",
                upcomingButton: "bevorstehende",
                pastButton: "Vergangene",
                addEvent: "Veranstaltung hinzufügen",
                eventSettingsTooltip: "Veranstaltungseinstellungen",
                newEventTitle: "NEUE VERANSTALTUNG",
              },
              list: {
                placeholder: "Schreibe etwas...",
                createButton: "Beitrag erstellen",
                listSettingsTooltip: "Forumseinstellungen",
                duplicateFormTitle: "BEREICH KOPIEREN",
              },
              settings: {
                versions: {
                  content: "Inhaltseite",
                  posts: "Beiträge",
                  discussions: "Diskussionen",
                  link: "Link",
                  template: "Vorlage",
                },
                versionsDescriptions: {
                  content:
                    "Neue Elemente werden als Inhaltsseite mit voller Funktionalität angezeigt.",
                  discussion: "Leer",
                  posts:
                    "Neue Elemente werden als einfacher Beitrag mit eingeschränkter Funktionalität angezeigt.",
                  link: "Diese Option ermöglicht es Ihnen, einen Link zu einem beliebigen Bereich auf Sutra zu erstellen, der dann als Element hier angezeigt wird.",
                  template:
                    "Erstellen Sie einen Vorlagenbereich, der jedes Mal dupliziert wird, wenn ein neues Element hier erstellt wird.",
                },
                createNewTemplate: "Neue Vorlage erstellen",
                editTemplate: "Vorlage bearbeiten",
                title: "EINSTELLUNGEN",
                accordion1: {
                  label: "Anzeige als",
                  description:
                    "Beiträge in Listen- oder Rasteransicht anzeigen",
                  listOption: "Liste",
                  gridOption: "Raster",
                  dateCreated: "Erstellungsdatum",
                  dateCreatedReversed: "Erstellungsdatum umkehren",
                  lastActive: "Zuletzt aktiv",
                  lastEdited: "Zuletzt bearbeitet",
                  alphabetic: "Alphabetisch",
                  reverseAlphabetic: "Umgekehrtes Alphabet",
                },
                accordion2: {
                  label: "Anzeigeoptionen",
                  description: "",
                  switches: {
                    onlyManagers: "Nur Manager können einen Beitrag erstellen",
                    showAuthor: "Autorinformationen anzeigen",
                    showPreview: "Vorschautext anzeigen",
                    showComments: "Anzahl der Kommentare anzeigen",
                    showMembers: "Mitglieder-Avatare anzeigen",
                    showTimestamp: "Erstellungszeitpunkt anzeigen",
                    showThumbnail: "Miniaturbild anzeigen",
                    autoThumbnail: "Miniaturbild automatisch erstellen",
                  },
                },
                accordion3: {
                  label: "Neues Beitragformat",
                  description:
                    "Format für neue Beiträge in diesem Bereich auswählen",
                  templateName: {
                    label: "Vorlage benennen",
                    placeholder: "Vorlagenname",
                  },
                  templateDirectOption: "Direkter Unterbereich",
                  templateLinkedOption: "Verknüpfter Bereich",
                  privacyDescription:
                    "Standardmäßiger Datenschutzlevel für neue Beiträge auswählen",
                  privacyOpenOption: "Öffentlich",
                  privacyPrivateOption: "Privat",
                  privacyAnyOption: "Entscheidung des Erstellers",
                },
                accordion4: {
                  label: "Schaltflächenbeschriftung",
                  createPost: {
                    label: "Beitrag erstellen",
                    description:
                      "Geben Sie den Text an, der auf der Schaltfläche zum Erstellen eines neuen Beitrags angezeigt wird",
                  },
                },
                templateDescription:
                  "Neue Vorlagenbereiche können entweder direkte Unterbereiche dieses Bereichs sein oder sie können verknüpfte Bereiche sein. Verknüpfte Bereiche sind eigenständige Top-Level-Bereiche, die auf dieser Seite angezeigt werden. Der Ersteller des Bereichs wird zum vollen Manager des von ihm erstellten Bereichs.",
              },
              thumbnail: {
                title: "THUMBNAIL HINZUFÜGEN",
                message: "Das Miniaturbild wird hochgeladen...",
              },
              static: {
                broadcastExclude: {
                  unsubscribe: {
                    part1:
                      "Sind Sie sicher, dass Sie sich abmelden möchten von",
                    part2:
                      "Sie werden keine direkten Rundmails mehr von den Managern dieses Bereichs erhalten",
                    button: "Abmelden",
                  },
                  subscribe: {
                    part1:
                      "Sie wurden abgemeldet und werden keine Rundmails mehr von",
                    button: "Abonnieren",
                  },
                },
                invitation: {
                  message: "Sie wurden zu diesem Bereich eingeladen",
                  button: "Einladung annehmen",
                },
                pending: {
                  message: "Ihre Mitgliedschaft ist ausstehend",
                },
                private: {
                  message: {
                    part1: "Dies ist ein privater Bereich.",
                    part2: "Wenn Sie Mitglied sind, loggen Sie sich bitte ein.",
                  },
                  button: "Einloggen",
                },
                processing: {
                  message: "Dieser Bereich wird noch verarbeitet...",
                },
              },
              gridPost: {
                onDelete: {
                  alertTitle: "Sind Sie sicher?",
                  alertMessage:
                    "Dieser Bereich wird nicht mehr hier verlinkt sein.",
                  alertMessage2:
                    "Dieser Bereich wird nicht mehr hier verlinkt sein.",
                  confirm: "Ja",
                  deny: "Nein",
                },
                actions: {
                  pin: "Oben anheften",
                  unpin: "Lösen",
                  addThumbnail: "Miniaturbild hinzufügen",
                  removeThumbnail: "Miniaturbild entfernen",
                  duplicate: "Duplizieren",
                  unlink: "Verlinkung aufheben",
                  delete: "Löschen",
                },
              },
              listContent: {
                publishTootip: "Wird an diesem Datum veröffentlicht",
                privateTooltip: "Dies ist ein privater Bereich",
              },
            },
            colors: {
              form: {
                colorTheme: "Farbthema",
                customize: "Farbauswahl anpassen",
                saveTheme: "Thema speichern",
                resetColor: "Farbe zurücksetzen",
                pageLayout: "Seitenlayout",
                pageFormat: "Seitenformat",
              },
              headerAndLogo: {
                addCover: "Titelbild hinzufügen",
                removeCover: "Titelbild entfernen",
                changeLogo: "Logo ändern",
                removeLogo: "Logo entfernen",
                dimensions:
                  "Die empfohlene Größe für Titelbilder beträgt 1200 x 300 Pixel",
                dimensions2:
                  "Die empfohlene Größe für Logos beträgt 360 x 120 Pixel",
              },
              pageFormat: {
                description:
                  "Sie können das Erscheinungsbild des Inhalts in diesem Bereich ändern, indem Sie unten eine Option auswählen.",
                contentLabel: "Inhalt",
                discussionLabel: "Diskussion",
                contentDescription:
                  "Ein Bereich für Lernmodule, allgemeinen Inhalt oder Community-Ressourcen.",
                discussionDescription:
                  "Ein Bereich für Diskussionen oder Ankündigungen",
                eventsLabel: "Kalender",
                eventsDescription:
                  "Erstellen Sie eine Liste von Ereignissen im Kalender",
                listLabel: "Forum",
                listDescription:
                  "Erstellen Sie einen Feed, in dem Benutzer Diskussionsthemen veröffentlichen können",
              },
              pageLayout: {
                widePage: "Breite Seitenbreite",
                hideSidebar: "Seitenleiste ausblenden",
                hideHeader: "Kopfzeile ausblenden",
                hideCoverImage: "Titelbild ausblenden",
                hidePageTitle: "Seitentitel ausblenden",
                hideBreadcrumbs: "Brotkrumen ausblenden",
              },
              header_color: {
                label: "Kopfzeilenfarbe",
                description: "Beschreibung",
              },
              header_link_color: {
                label: "Kopfzeilen-Logo-Farbe",
                description: "Beschreibung",
              },
              primary_button_background_color: {
                label: "Hintergrundfarbe des Primärbuttons",
                description: "Beschreibung",
              },
              primary_button_text_color: {
                label: "Textfarbe des Primärbuttons",
                description: "Beschreibung",
              },
              secondary_button_background_color: {
                label: "Hintergrundfarbe des Sekundärbuttons",
                description: "Beschreibung",
              },
              secondary_button_text_color: {
                label: "Textfarbe des Sekundärbuttons",
                description: "Beschreibung",
              },
              background_color: {
                label: "Hintergrundfarbe",
                description: "Beschreibung",
              },
              default_text_color: {
                label: "Standardtextfarbe",
                description: "Beschreibung",
              },
              sidebar_background_color: {
                label: "Hintergrundfarbe der Seitenleiste",
                description: "Beschreibung",
              },
              sidebar_text_color: {
                label: "Textfarbe der Seitenleiste",
                description: "Beschreibung",
              },
              default_link_color: {
                label: "Standard-Linkfarbe",
                description: "Beschreibung",
              },
              default_badge_color: {
                label: "Farbe des Benachrichtigungsabzeichens",
                description: "Beschreibung",
              },
              default_badge_text_color: {
                label: "Textfarbe des Benachrichtigungsabzeichens",
                description: "Beschreibung",
              },
              registration_page_background_color: {
                label: "Hintergrundfarbe der Registrierungsseite",
                description: "Beschreibung",
              },
              registration_page_default_text_color: {
                label: "Textfarbe der Registrierungsseite",
                description: "Beschreibung",
              },
            },
            create: {
              invalidNameErrorMsg:
                "Der Name des Bereichs muss zwischen 3 und 100 Zeichen lang sein",
            },
            dummy: {
              magicLink: {
                alertTitle: "Du bist fast drin...",
                alertMessage:
                  "Wir haben den Magic Link an deine E-Mail gesendet. DIESES IST EIN TEST KEINE TATSÄCHLICHE E-MAIL GESCHICKT.",
              },
            },
            pending: {
              message: "Deine Mitgliedschaft steht aus",
            },
            recover: {
              alertTitle: "Du bist fast drin...",
              alertMessage:
                "Wir haben dir gerade eine E-Mail mit Anweisungen zur Wiederherstellung deines Passworts geschickt. DIESES IST EIN TEST KEINE TATSÄCHLICHE E-MAIL GESCHICKT.",
            },
            form: {
              calendarLinks: {
                text: "Die unten hinzugefügten Informationen werden dem Kalender des Mitglieds übergeben, wenn es das Ereignis hinzufügt.",
                link: {
                  label: "Ereignis-URL (Zoom-Link usw.)",
                  placeholder: "Wie lautet der Link zu Ihrem Videocall",
                },
                description: {
                  label:
                    "Fügen Sie eine Beschreibung für Ihr Ereignis hinzu, die in den Kalenderinformationen angezeigt wird",
                  placeholder: "Kalenderbeschreibung",
                },
                eventStartDateAndTime: "Startdatum und -zeit des Ereignisses",
                date: {
                  placeholder: "Datum auswählen",
                },
                eventDateTime: "Enddatum und -zeit des Ereignisses (optional)",
                allowInterests:
                  "Erlauben Sie Personen, am Ereignis teilzunehmen, ohne sich für Sutra anzumelden",
                automatedEmails: "Automatisierte E-Mails einrichten",
                save: "Details speichern",
              },
              duplicate: {
                prefix: "Kopie von",
                intention: {
                  label: "Raumname",
                  placeholder: "Wie heißt Ihr Bereich?",
                },
                copyMembers: {
                  label: "Mitglieder kopieren",
                },
                copyManagers: {
                  label: "Manager kopieren",
                },
                copyContent: {
                  label: "Inhalt kopieren",
                },
                copyPosts: {
                  label: "Copy posts",
                },
                copyBroadcasts: {
                  label: "Broadcasts kopieren",
                },
                copyAutomation: {
                  label: "Automatisierung kopieren",
                },
                copyRegPage: {
                  label: "Registrierungsseite kopieren",
                },
                save: "Bereich duplizieren",
                saveTemplate: "Erstellen Sie eine Vorlage"
              },
              form: {
                privacyDescription1:
                  "Der von Ihnen erstellte Bereich wird für alle innerhalb von",
                rootPrivacyDescription1:
                  "Der von Ihnen erstellte Bereich ist für alle sichtbar, die einen Link dazu haben. Sie können Zahlungsoptionen festlegen, sobald Sie den Bereich erstellt haben.",
                privacyDescription2:
                  "Der von Ihnen erstellte Bereich ist nur für Personen sichtbar, die hinzugefügt wurden.",
                rootPrivacyDescription2:
                  "Der von Ihnen erstellte Bereich ist nur für Personen sichtbar, die dazu eingeladen wurden. Sie können Zahlungsoptionen festlegen, sobald Sie den Bereich erstellt haben.",
                placeholderDescription: "Beschreibung",
                versions: {
                  content: {
                    label: "Inhalt",
                    description:
                      "Erstellen Sie einen Bereich für ein Lernmodul, allgemeinen Inhalt oder Community-Ressourcen.",
                  },
                  discussion: {
                    label: "Diskussion",
                    description:
                      "Erstellen Sie einen Chat-Bereich für Diskussionen oder Ankündigungen.",
                  },
                  event: {
                    label: "Veranstaltung",
                    description: "Erstellen Sie eine einzelne Veranstaltung.",
                  },
                  events: {
                    label: "Kalender",
                    description:
                      "Erstellen Sie eine Liste von Kalenderereignissen.",
                  },
                  list: {
                    label: "Forum",
                    description:
                      "Erstellen Sie eine Feed, in dem Personen Diskussionsthemen veröffentlichen können.",
                  },
                },
                segments: {
                  space: {
                    label: "BereichRaum",
                    description:
                      "Erstellen Sie einen Bereich für Ihren Kurs oder Ihre Community. Wählen Sie diese Option, wenn Sie eine Schulungskohorte, einen selbstgesteuerten Kurs, eine Community oder eine Gruppe erstellen möchten, die im Laufe der Zeit zusammentrifft.",
                  },
                  event: {
                    label: "Veranstaltung",
                    description:
                      "Erstellen Sie eine einfache Veranstaltungsseite. Wählen Sie diese Option, wenn Sie einfach eine einmalige Veranstaltung organisieren möchten, die Sie öffentlich teilen können.",
                  },
                  discussion: {
                    label: "Diskussion",
                    description:
                      "Erstellen Sie einen einfachen Chat-Bereich für Ihre Gruppe. Wählen Sie diese Option, wenn Sie einfach einen einmaligen Diskussionsbereich für eine Gruppe von Personen erstellen möchten.",
                  },
                },
                intention: {
                  label: "Raumname",
                  placeholder: "Wie heißt Ihr Bereich?",
                },
                content: {
                  label: "Raumname",
                  description: "Etwas schreiben...",
                  placeholder: "Etwas schreiben...",
                },
                spaceFormat: {
                  label: "Bereichsformat",
                  placeholder: "Wählen Sie eins aus",
                },
                eventDescription:
                  "Die unten hinzugefügten Informationen werden dem Kalender des Mitglieds übergeben, wenn es das Ereignis hinzufügt.",
                hangoutLink: {
                  label: "Ereignis-URL (Zoom-Link usw.)",
                  placeholder: "Wie lautet der Link Ihres Videoanrufs?",
                },
                eventStartTime: "Startdatum und -zeit des Ereignisses",
                date: {
                  placeholder: "Datum auswählen",
                },
                eventEndDate: "Enddatum und -zeit des Ereignisses (optional)",
                privacyLevelText: "Datenschutzstufe",
                privacy: {
                  open: "Offen",
                  private: "Privat",
                },
                joinAsMember: {
                  label: "Tritt diesem Bereich als Mitglied bei",
                },
                publishDate: {
                  label: "Am Datum veröffentlichen",
                },
                publishDateTime: "Veröffentlichungsdatum und -zeit",
                create: "Raum schaffen",
              },
              interestedForm: {
                title: "Für diese Veranstaltung registrieren",
                createAlertTitle: "Vielen Dank für Ihre Registrierung!",
                createAlertButton: "OK",
                name: {
                  label: "Vorname",
                  placeholder: "Geben Sie Ihren Vornamen ein",
                },
                email: {
                  label: "E-Mail",
                  placeholder: "Geben Sie Ihre E-Mail-Adresse ein",
                },
                register: "Registrieren",
                joinWithSutra: "Mit meinem Sutra-Konto beitreten",
              },
              linkForm: {
                linkErrMessage: "Dies ist kein gültiger Bereichslink",
                link: {
                  label: "",
                  placeholder: "Link zum Bereich",
                  errors: {
                    space:
                      "Der Bereich, den Sie teilen möchten, ist privat oder existiert nicht!",
                    event:
                      "Der Bereich, den Sie teilen möchten, ist privat, keine Veranstaltung oder existiert nicht!",
                  },
                },
              },
              listForm: {
                intention: {
                  placeholder: "Titel",
                },
              },
            },
            header: {
              onLeave: {
                title: "Bist du sicher?",
                message:
                  "Du wirst aus diesem Bereich entfernt und möglicherweise wieder hinzugefügt werden müssen, um den Zugriff wiederherzustellen.",
                confirm: "Ja, bitte entferne mich",
                deny: "Nein",
              },
              onArchive: {
                title: "Bist du sicher?",
                message:
                  "Dieser Bereich wird archiviert und Mitglieder haben keinen Zugriff mehr.",
                confirm: "Ja",
                deny: "Nein",
              },
              onUnarchive: {
                title: "Bist du sicher?",
                message:
                  "Dieser Bereich wird aus dem Archiv geholt und Mitglieder haben wieder Zugriff.",
                confirm: "Ja",
                deny: "Nein",
              },
              onDelete: {
                title: "Bist du sicher?",
                message:
                  "Dieser Bereich wird gelöscht und Mitglieder haben keinen Zugriff mehr.",
                confirm: "Ja",
                deny: "Nein",
              },
              onFix: {
                title: "Bist du sicher?",
                message:
                  "Sutra wird versuchen, diesen Inhaltsbereich aus den Legacy-Daten neu zu erstellen.",
                confirm: "Ja, reparieren",
                deny: "Nein",
                confirmationTitle: "Information",
                confirmationMessage:
                  "Bereich wurde repariert, Seite wird neu geladen...",
                confirmationError: "Dieser Bereich kann nicht repariert werden",
              },
              buttons: {
                uncomplete: "Als unvollständig markieren",
                markComplete: "Als abgeschlossen markieren",
                edit: "Bearbeiten",
                post: "Post",
                page: "Seite",
                space: "Bereich",
                returnTo: "Zurück zu",
                editTitle: "Titel bearbeiten",
                viewPage: "Seite anzeigen",
                editDescription: "Beschreibung bearbeiten",
                appearance: "Erscheinungsbild",
                manage: "Verwalten",
                members: "Mitglieder",
                leave: "Bereich verlassen",
                delete: "Bereich löschen",
                duplicate: "Bereich duplizieren",
                template: "Erstellen Sie eine Vorlage",
                archive: "Raum archivieren",
                unarchive: "Speicherplatz entarchivieren",
                fix: "Reparieren",
                joinEvent: "Registrieren",
                joinSpace: "Bereich beitreten",
                preview: "Vorschau",
                draft: "Entwurf",
                published: "Veröffentlicht",
                publish: "Veröffentlichen",
                returnToSettings: "Zurück zu den Einstellungen",
                returnToSpace: "Zurück zum Bereich",
                undo: "Rückgängig",
                openFullScreen: "Im Vollbildmodus öffnen",
                copyToClipboard: "In die Zwischenablage kopieren",
                copiedMessage: "Space-Link in die Zwischenablage kopiert",
                versionHistory: "Versionsgeschichte",
                brandingOptions: "BRANDING-OPTIONEN",
              },
              regPagePublishing:
                "Deine Registrierungsseite wird veröffentlicht, bitte warten...",
              spaceUpdating:
                "Dieser Bereich wird aktualisiert, bitte warten...",
            },
            members: {
              sectionTitle: "Mitglieder",
              manage: "Mitglieder verwalten",
              invite: "Einladen",
              search: {
                button: "Suchen",
                placeholder: "Suche nach",
              },
              emptyMessage: "Keine Einträge gefunden",
              messageButton: "Nachricht",
              emailButton: "E-Mail",
              copyClipboard: "In Zwischenablage kopieren",
            },
            payment: {
              onCheckIntent: {
                alertTitle: "Zahlungsfehler",
                alertMessage:
                  "Deine Sitzung ist abgelaufen. Bitte aktualisiere die Seite, um deine Zahlung abzuschließen.",
              },
              onConfirmPayment: {
                alertTitle: "Oops, etwas ist schiefgelaufen!",
                alertMessage: "Etwas ist schiefgelaufen!",
              },
              completePayment: "Zahlung abschließen",
              join: "Raum beitreten",
            },
            stripe: {
              pay: "Bezahlen",
            },
          },
          survey: {
            form: {
              text: "Du hast bereits an dieser Umfrage teilgenommen.",
              submitButton: "Absenden",
            },
            question: {
              yourAnswer: "Deine Antwort",
            },
          },
          tiptap: {
            blocks: {
              section1: "Grundlegend",
              text: "Text",
              headline1: "Überschrift 1",
              headline2: "Überschrift 2",
              headline3: "Überschrift 3",
              divider: "Trennlinie",
              infobox: "Info-Box",
              section2: "Räume",
              content: "Inhaltsraum",
              discussion: "Diskussionsraum",
              event: "Eventraum",
              events: "Kalenderraum",
              list: "Forumraum",
              showcase: "Showcase Space",
              section3: "Liste",
              section8: "Special",
              bulletList: "Aufzählungsliste",
              orderedList: "Nummerierte Liste",
              checklist: "Checkliste",
              collapsableSection: "Ausklappbarer Abschnitt",
              survey: "Survey or quiz",
              section4: "Medien",
              image: "Bild",
              embed: "Einbettungscode",
              video: "Video",
              audio: "Audio",
              file: "Datei",
              zoom: "Zoom recording",
              section5: "Raster",
              grid1: "1 Spalte",
              grid2: "2 Spalten",
              grid3: "3 Spalten",
              grid4: "Linke Spalte",
              grid5: "Rechte Spalte",
              grid6: "4 columns",
              section6: "Schaltflächen",
              interestForm: "Interessensformular",
              actionButton: "Aktions-Schaltfläche",
              markComplete: "Als erledigt markieren",
              section7: "Interaktiv",
              interactiveButton: "Interaktive Schaltfläche",
              interactiveInput: "Interaktive Eingabe",
              interactiveWait: "Interaktive Wartezeit"
            },
            components: {
              sidebar: {
                alignment: {
                  left: "Links",
                  center: "Zentriert",
                  right: "Rechts",
                },
                backgroundColor: {
                  text: "Hintergrundfarbe auswählen",
                  clearColor: "klare Farbe",
                },
                backgroundImage: {
                  button: "Bild auswählen",
                  removeButton: "Bild entfernen",
                  label: "Position",
                  placeholder: "Wähle eine aus",
                  leftTop: "Links oben",
                  leftCenter: "Links zentriert",
                  leftBottom: "Links unten",
                  rightTop: "Rechts oben",
                  rightCenter: "Rechts zentriert",
                  rightBottom: "Rechts unten",
                  centerTop: "Zentriert oben",
                  centerCenter: "Zentriert zentriert",
                  centerBottom: "Zentriert unten",
                  label2: "Wiederholen",
                  placeholder2: "Wähle eine aus",
                  noRepeat: "Keine Wiederholung",
                  repeat: "Wiederholen",
                  "repeat-x": "Wiederholen Sie X",
                  "repeat-y": "Wiederholen Sie Y",
                  space: "Raum",
                  round: "Runden",
                },
                blockId: {
                  placeholder: "Block-ID",
                  label: "Block-ID",
                  description:
                    "Empfohlene IDs für AI briefDescription, aboutWorkshop, whatYouWillLearn1, whatYouWillLearn2, whatYouWillLearn3, whoThisIsFor, aboutUs, quote",
                },
                buttonAction: {
                  label: "Aktion nach Abschluss",
                  description:
                    "Gib an, was nach dem Absenden der Informationen über das ausgewählte Formular geschehen soll.",
                  placeholder: "Wähle eine aus",
                  label2: "Verknüpfung zu",
                  placeholder2: "Wähle eine aus",
                  label3: "Link-Aktion",
                  description3:
                    "Gib an, ob der Link im aktuellen Browser-Tab oder in einem neuen Tab geöffnet wird.",
                  placeholder3: "Wähle eine aus",
                  callBackComplete: "Text nach Abschluss",
                  callBacklinkTo: "Verknüpfung zu",
                  completeDescription:
                    "Füge eine kurze Bestätigungsnachricht hinzu, die Personen nach dem Absenden ihrer Informationen sehen werden.",
                  linkDescription:
                    "Die Person wird nach dem Klicken des Buttons zu diesem Link weitergeleitet.",
                  label4: "Link zum Bereich Weltraum",
                  description4: "Geben Sie an, ob der Link im aktuellen oder einem anderen Browser-Tab geöffnet wird oder ob der Link zu einem Abschnitt auf dieser Seite springt.",
                  selectSection: "Abschnitt auswählen",
                  description5: "Mit dieser Option können Sie beim Klicken auf den Link zu einem bestimmten Abschnitt auf dieser Seite springen.",
                  description6: "Um den Abschnitt auszuwählen, klicken Sie auf die Schaltfläche unten und dann auf den Abschnitt, zu dem Sie eine Verknüpfung herstellen möchten.",
                  label5: "Abschnitt ist verlinkt!",
                  label6: "URL verknüpfen",
                  description7: "Geben Sie den URL-Link an, zu dem der Benutzer weitergeleitet wird",
                  label7: "Wird im aktuellen Tab geöffnet",
                  label8: "Öffnet andere Registerkarte",
                  label9: "Nichts tun",
                  label10: "Leiten Sie den Benutzer weiter zu",
                  label11: "Wähle eine Option",
                  label12: "Ein anderer Raum",
                  label13: "Benutzerdefinierten Link",
                  label14: "Klicken Sie auf einen Abschnitt auf der Seite",
                },
                buttonBackgroundColor: {
                  button: "Farbe entfernen",
                },
                buttonText: {
                  placeholder: "Schaltflächentext",
                  label: "Schaltflächentext",
                },
                fileName: {
                  placeholder: "Dateiname",
                  label: "Dateiname",
                },
                interactiveWaitTime: {
                  label1: "Wähle eine Option",
                  label2: "Warten bis zu einem Datum",
                  label3: "Warten für eine Anzahl von Tagen",
                  label4: "Datum, um den Inhalt anzuzeigen",
                  placeholder: "Datum, um den Inhalt anzuzeigen",
                  label5: "1 Tag",
                  label6: "2 Tage",
                  label7: "3 Tage",
                  label8: "4 Tage",
                  label9: "5 Tage",
                  label10: "6 Tage",
                  label11: "1 Woche",
                  label12: "2 Wochen",
                  label13: "1 Monat",
                },
                linkAction: {
                  label: "Link-Aktion",
                  description:
                    "Gib an, ob der Link im aktuellen Tab oder in einem neuen Tab geöffnet wird.",
                  placeholder: "Wähle eine aus",
                  label2: "Im aktuellen Tab öffnen",
                  label3: "Im neuen Tab öffnen",
                },
                margin: {
                  topMargin: "Oberer Rand -",
                  text: "Unterer Rand - ",
                  bottom: "Unten",
                  left: "Links",
                  leftMargin: "Linker Rand -",
                  right: "Rechts",
                  rightMargin: "Rechter Rand",
                },
                padding: {
                  top: "Oben",
                  topPadding: "Obere Polsterung -",
                  bottom: "Unten",
                  bottomPadding: "Untere Polsterung -",
                  left: "Links",
                  leftPadding: "Linke Polsterung",
                  right: "Rechts",
                  rightPadding: "Rechte Polsterung",
                },
                radius: {
                  square: "Quadrat",
                  round: "Rund",
                  circle: "Kreis",
                },
                spaceAttrs: {
                  addCover: "Cover hinzufügen",
                  text: "Die empfohlene Abmessung für das Titelbild beträgt 1200 x 300 Pixel",
                  addCover2: "Cover hinzufügen",
                  text2:
                    "Die empfohlene Abmessung für das Titelbild beträgt 1200 x 300 Pixel",
                  removeCover: "Cover entfernen",
                },
                verticalAllignment: {
                  top: "Oben",
                  center: "Zentriert",
                  bottom: "Unterseite",
                },
                width: {
                  full: "Voll",
                  size: "3/4",
                  size2: "1/2",
                  actual: "Tatsächlich",
                },
              },
              blockMenuActions: {
                label1: "Block hinzufügen",
                label2: "Emoji hinzufügen",
                label3: "Einstellungen",
                label4: "Löschen",
                label5: "Verschieben",
                label6: "Zum Space gehen oder Doppelklick auf den Block",
                label7: "Ersetzen",
                label8: "Ausrichtung",
                left: "Links",
                center: "Zentriert",
                right: "Rechts",
                write: "Etwas schreiben...",
                label9: "Block hinzufügen",
                label10: "Überschrift 1",
                h1: "H1",
                label11: "Überschrift 2",
                h2: "H2",
                label12: "Space hinzufügen",
                label13: "Bild hinzufügen",
                label14: "Video hinzufügen",
                label15: "Raster hinzufügen",
                label16: "Vorlage hinzufügen",
                label17: "Layout hinzufügen",
                label18: "Duplikat",
              },
              blocksMenu: {
                basicBlock: "Grundlegende Blöcke",
                mediaBlock: "Medienblöcke",
                contentBlock: "Inhaltsblöcke",
              },
              blocksMenuInline: {
                file: "Datei",
                image: "Bild",
                video: "Video",
                label1: "Emoji hinzufügen",
                label2: "Bild hinzufügen",
                label3: "Formatleiste umschalten",
              },
              blocksMenuSimple: {
                label1: "Hinzufügen",
                file: "Datei",
                image: "Bild",
                video: "Video",
                audio: "Audio",
                label2: "Emoji hinzufügen",
                label3: "Bild hinzufügen",
                label4: "Formatierung anzeigen",
                cancelButton: "Abbrechen",
              },
              emptyMessage: {
                text: "Dies ist ein leerer Inhaltsraum. Du kannst",
                button1: "diese Seite bearbeiten",
                text2: "oder",
                button2: "eine Vorlage verwenden",
                text3: "um loszulegen",
                text4: "Ein leerer Raum voller Möglichkeiten...",
              },
              fixedBottomMenu: {
                label1:
                  "Elemente wie Überschriften, Bilder und Videos hinzufügen",
                addBlock: "Block hinzufügen",
                label2:
                  "Einen Inhalts-, Diskussions- oder Event-Raum hinzufügen",
                space: "Space",
                label3:
                  "Ein Raster hinzufügen, um mehrere Spalten für Inhalte zu nutzen",
                grid: "Raster",
                label4: "Eine vordefinierte Designoption hinzufügen",
                layouts: "Layouts",
                label5:
                  "Eine Vorlage hinzufügen, um deinen Raum automatisch einzurichten",
                template: "Vorlagen",
              },
              formatMenu: {
                text: "Text",
                smallText: "Kleiner Text",
                largeText: "Großer Text",
                heading1: "Überschrift 1",
                heading2: "Überschrift 2",
                heading3: "Überschrift 3",
                bulletedList: "Aufzählungsliste",
                orderedList: "Nummerierte Liste",
                text2: "Text",
                label1: "Formatierung",
                text3: "Text",
                numberedList: "Nummerierte Liste",
                label2: "Ausrichtung",
                left: "Links",
                center: "Zentriert",
                right: "Rechts",
                label3: "Link-Aktion",
                description:
                  "Legen Sie fest, ob der Link im aktuellen Browser-Tab oder in einem neuen Tab geöffnet wird.",
                placeholder: "Wählen Sie eine",
                label4: "Im aktuellen Tab öffnen",
                label5: "In anderem Tab öffnen",
                label6: "Link-Format",
                description2:
                  "Legen Sie fest, ob der Link als Text oder als auffälliger Link angezeigt wird.",
                placeholder2: "Wählen Sie eine",
                label7: "Als Text anzeigen",
                label8: "Als auffälligen Link anzeigen",
                save: "Speichern",
                delete: "Löschen",
                label9: "Durchgestrichen",
                label10: "Unterstrichen",
                label11: "Code-Block",
                label12: "Textfarbe",
                placeholder3: "HEX-Wert",
                unsetColor: "Farbe aufheben",
                label13: "Hervorhebungsfarbe",
                label14: "Link zum Space-Bereich",
                description3: "Geben Sie an, ob der Link im aktuellen Browser-Tab oder einem anderen Tab geöffnet wird oder ob der Link zu einem Abschnitt auf dieser Seite springt.",
                selectSection: "Abschnitt auswählen",
                description4: "Mit dieser Option können Sie zu einem bestimmten Abschnitt auf dieser Seite springen, wenn Sie auf den Link klicken.",
                description5: "Um den Abschnitt auszuwählen, klicken Sie auf die Schaltfläche unten und dann auf den Abschnitt, zu dem Sie einen Link erstellen möchten.",
                label15: "Abschnitt ist verlinkt!",
                label16: "URL verknüpfen",
                description6: "Geben Sie den URL-Link an, zu dem der Benutzer weitergeleitet wird",
              },
              formatMenuSimple: {
                t: "T",
                h1: "H1",
                h2: "H2",
                h3: "H3",
                label1: "Link-Aktion",
                description1:
                  "Legen Sie fest, ob der Link im aktuellen Browser-Tab oder in einem neuen Tab geöffnet wird.",
                placeholder1: "Wählen Sie eine",
                label2: "Im aktuellen Tab öffnen",
                label3: "In anderem Tab öffnen",
                label4: "Link-Format",
                description2:
                  "Legen Sie fest, ob der Link als Text oder als auffälliger Link angezeigt wird.",
                placeholder2: "Wählen Sie eine",
                label5: "Als Text anzeigen",
                label6: "Als auffälligen Link anzeigen",
                save: "Speichern",
                delete: "Löschen",
                label7: "Formatierung",
                left: "Links",
                center: "Zentriert",
                right: "Rechts",
                placeholder3: "HEX-Wert",
                placeholder4: "Farb-Hex",
                unsetColor: "Farbe aufheben",
              },
              inlineMenu: {
                h1: "H1",
                h2: "H2",
                bulletList: "Aufzählungsliste",
                checkList: "Checkliste",
                circle: "Kreis",
                moreOption: "Weitere Optionen",
              },
              interactiveInput: {
                hideResponses: "Andere Antworten ausblenden",
                showResponses: "Andere Antworten anzeigen",
                hideResponses2: "Antworten ausblenden",
                hidePrivateMessages: "Private Nachrichten ausblenden",
                showPrivateMessages: "Private Nachrichten anzeigen",
                showResponses2: "Antworten anzeigen",
                loadMoreResponse: "Mehr Antworten laden",
                placeholder: "Schreibe etwas...",
                text: "Soll diese Antwort für andere Teilnehmer sichtbar sein?",
              },
              newMedia: {
                add: "Hinzufügen",
              },
              regPageColor: {
                clearColor: "Farbe aufheben",
              },
              regPageColors: {
                text: "Erscheinungsbild der Registrierungsseite",
                applyAppearance: "Erscheinungsbild anwenden",
                title1: "Hintergrundfarbe auswählen",
                title2: "Textfarbe auswählen",
                label1: "Hintergrundfarbe",
                label2: "Standardtextfarbe",
                label3: "Breite der Seite",
              },
              resource: {
                areYouSure: "Sind Sie sicher?",
                label1: "Wird am angegebenen Datum veröffentlicht",
                edit: "Bearbeiten",
                addPage: "Seite hinzufügen",
              },
              sideBlocksMenuBak: {
                addBlock: "Block hinzufügen",
                back: "Zurück",
              },
              sideBlocksMenu: {
                text: "Layouts sind vordefinierte Designoptionen, die Sie Ihrem Space hinzufügen können.",
                text2:
                  "Starten Sie mit einer Auswahl an anpassbaren Vollseiten-Vorlagen",
                layout: "Layouts",
                template: "Vorlagen",
                addBlock: "BLOCK HINZUFÜGEN",
                block: "Blöcke",
              },
              sideConfigurationsMenu: {
                title: "BLOCKEINSTELLUNGEN",
                label1: "Space-Name und Cover",
                label2: "Dateiattribute",
                label3: "Interaktive Eingabeoptionen",
                attrPlaceholder1: "Etwas schreiben...",
                attrLabel: "Platzhalter",
                attrLabel2: "Teilnehmer dürfen alle Antworten sehen",
                attrLabel3: "Antworten vor Teilnehmerreaktion anzeigen",
                attrLabel4:
                  "Teilnehmer können wählen, ob ihre Antwort für andere sichtbar ist",
                attrLabel5: "Mehrere Antworten pro Person zulassen",
                attrLabel6: "Teilnehmer dürfen diese Aufforderung überspringen",
                attrLabel7: "Manager bei jedem neuen Beitrag benachrichtigen",
                label4: "Interaktive Eingabeoptionen",
                label5: "Padding",
                label6: "BLOCK",
                label7: "SPALTE",
                label8: "Rand",
                label9: "GRID",
                label10: "Vertikale Ausrichtung",
                label11: "Button-Optionen",
                label12: "Button-Hintergrundfarbe",
                label13: "Button-Textfarbe",
                text: "Button-Ecken",
                label14: "Hintergrund",
                label15: "Farbe",
                label16: "Bild",
                label17: "Zurücksetzen",
                resetBlock: "Block zurücksetzen",
                label18: "Ausrichtung",
                label19: "Eckenradius",
                label20: "Ecken",
                label21: "Blockname",
                label22: "E-Mail-Benachrichtigungsoptionen",
                label23: "Benachrichtigungs-E-Mail senden",
                attrLabel8: "Benutzerdefinierte Nachricht einschließen",
                attrLabel9: "Inhalt des nächsten Abschnitts einschließen",
                attrLabel10: "Gesamten Inhalt einschließen",
                attrLabel11: "Kurze Vorschau",
                attrLabel12: "Betreff",
                attrLabel13: "Nachricht",
                defaultWaitLabel: "Der nächste Abschnitt wurde in {{space_name}} veröffentlicht",
                defaultWaitMessage: "Hallo {{first_name}} – ich wollte Sie nur wissen lassen, dass der nächste Abschnitt in {{space_name}} veröffentlicht wurde. {{space_link_button}}"
              },
              takeSurvey: {
                text: "Diese Umfrage ist leer",
              },
              tiptapAi: {
                placeholder: "Geben Sie eine kurze Beschreibung ein",
                label:
                  "Bitte schreiben Sie 2 oder 3 Sätze, die Ihr Angebot beschreiben.",
                placeholder2: "z. B. Coaches, Berater und Heiler",
                label2: "Für wen ist dieses Angebot?",
                text: "Hallo, wir experimentieren mit einer neuen KI-Funktion. Möchten Sie, dass Sutra automatisch Sprache für Ihre Vorlage generiert?",
              },
              uploader: {
                label1: "Link",
                label2: "Code",
                placeholder: "Fügen Sie den Bild- oder Dateilink ein",
                label3: "Einbettungslink",
                placeholder2:
                  "Fügen Sie den Einbettungscode ein -> <iframe>...</iframe>",
                label4: "Einbettungscode",
                saveEmbed: "Einbettung speichern",
                placeholder3: "Bilder durchsuchen",
                saveImage: "Bild speichern",
              },
              viewPage: {
                text: "Diese Seite ist leer",
              },
            },
          },
          users: {
            payment: {
              completePayment: "Zahlung abschließen",
              join: "Space beitreten",
              pay: "Bezahlen",
            },
            stepper: {
              step1: "Schritt 1",
              step1Description: "Anmelden",
              step2: "Schritt 2",
              step2Description: "Zahlung abschließen",
              step3: "Schritt 3",
              step3Description: "Zugriff erhalten",
            },
            start: {
              title: "Willkommen",
              subtitle: "Bitte geben Sie unten Ihre E-Mail-Adresse ein",
              email: "E-Mail",
              submit: "Weitermachen",
              invalidEmailErrorMsg: "Ungültige E-Mail",
            },
            signIn: {
              title: "Melden Sie sich in Ihrem Konto an",
              subtitle: "Sie haben noch kein Konto?",
              subtitleLink: "Registrieren",
              email: "E-Mail",
              password: "Passwort",
              submit: "Anmelden",
              magicLink: "Magischen Link erhalte",
              forgotPassword: "Passwort vergessen?",
              invalidEmailErrorMsg: "Ungültige E-Mail",
            },
            signUp: {
              invalidEmailErrorMsg: "Ungültige E-Mail",
              invalidPasswordErrorMsg:
                "Passwort muss mindestens 8 Zeichen lang sein",
              invalidFirstNameErrorMsg:
                "Der Vorname muss mindestens 3 Zeichen lang sein",
              title: "Neues Konto erstellen",
              subtitle: "Haben Sie bereits ein Konto?",
              subtitleLink: "Einloggen",
              firstName: "Vorname",
              lastName: "Nachname",
              username: "Benutzername",
              email: "E-Mail",
              password: "Passwort",
              emailNewsletterConfirmation:
                "Melden Sie sich für den Sutra-Newsletter an, um Informationen über kostenlose Webinare zu Gruppenprozessen, transformierendem Unterricht und relationalen Geschäftspraktiken zu erhalten.",
              submit: "Registrieren",
              legalPrefix: "Durch die Anmeldung bei Sutra stimmen Sie unseren",
              terms: "Nutzungsbedingungen",
              privacy: "Datenschutzbestimmungen",
            },
            spaceReference: {
              total: "Gesamt heute",
            },
            planReference: {
              total: "Gesamt",
              total_today: "Gesamt heute",
              due_in: "Fällig in 14 Tagen",
              paymentDetails: {
                annual: "Jährliches Abonnement von",
                month: "Monatliches Abonnement von",
              },
            },
            resetPassword: {
              title: "Passwort zurücksetzen",
              subtitle:
                "Geben Sie unten ein neues Passwort für Ihr Sutra-Konto ein.",
              password: "Passwort",
              passwordConfirmation: "Passwort bestätigen",
              submit: "Passwort zurücksetzen",
            },
            recover: {
              title: "Passwort vergessen?",
              subtitle:
                "Geben Sie unten Ihre E-Mail-Adresse ein, und wir senden Ihnen Anweisungen zum Zurücksetzen Ihres Passworts.",
              email: "E-Mail",
              submit: "Anweisungen senden",
              footer: "Haben Sie bereits ein Konto?",
              footerLink: "Anmelden",
            },
            magicLink: {
              invalidEmailErrorMsg: "Ungültige E-Mail",
              title: "Magischen Link anfordern",
              subtitle:
                "Bitte geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen einen Link, mit dem Sie automatisch angemeldet werden.",
              email: "E-Mail",
              submit: "Weiter",
              footer: "Mit E-Mail anmelden",
              alertTitle: "Fast geschafft...",
              alertMessage:
                "Wir haben den magischen Link an Ihre E-Mail gesendet. DAS IST EIN TEST, ES WIRD KEINE TATSÄCHLICHE E-MAIL GESSENDET.",
            },
            edit: {
              title: "Bitte vervollständigen Sie Ihr Profil",
              addProfileImage: "Profilbild hinzufügen",
              removeProfileImage: "Profilbild entfernen",
              bioPlaceholder: "Erzählen Sie etwas über sich...",
              bio: "Biografie",
              submit: "Speichern",
              skip: "Überspringen und später erledigen",
            },
            magicSignin: {
              text: "Anmelden...",
            },
            profile: {
              button: "Nachricht",
              button2: "Profil bearbeiten",
              title: "Räume",
            },
            profileModal: {
              profile: "Profil",
              message: "Nachricht",
              editProfile: "Profil bearbeiten",
              openProfile: "Profil öffnen",
              spaces: "Räume",
              selectImage: "Bild auswählen",
            },
            signOut: {
              loggedOUt: "Abgemeldet",
              title: "Sie sind abgemeldet",
              message: "Auf Wiedersehen!",
            },
          },
          errors: {
            basic: "Upps, da ist etwas schiefgelaufen!",
          },
        },
      },
      it: {
        translation: {
          premiumFeatures: {
            personal: {
              description1:
                "Spazi e membri illimitati per organizzare il contenuto e le conversazioni di tutte le tue offerte",
              description2:
                "Pagine di registrazione e landings bellissime per tutti i tuoi programmi ed eventi su un’unica piattaforma",
              description3:
                "Invia newsletters via email a tutti i membri con la nostra potente funzione di invio massivo",
            },
            professional: {
              description:
                "Senza commissioni per i pagamenti ricevuti",
              description2:
                "Opzioni di personalizzazione di branding, layout e URL dei sottodomini",
              description3:
                "Automazioni illimitate ed esperienze di apprendimento interattive e conversazionali",
              description4: "Fino a 2 amministratori per tutti i tuoi spazi",
            },
            organization: {
              description:
                "Senza commissioni per i pagamenti ricevuti",
              description2: "Supporto avanzato ai clienti",
              description3: "Amministratori illimitati",
              description4: "Opzione di integrazione dell’accesso Single Sign-On (SSO)",
            },
          },
          premiumMessages: {
            can_auto_tag: {
              title: "Upgrade to use this feature",
              message: "Auto tagging with AI is a premium feature available on the Professional plan."
            },
            can_use_zoom_recordings: {
              title: "Upgrade to use this feature",
              message: "Zoom recordings is a premium feature available on the Organization plan."
            },
            can_create_quizzes: {
              title: "Upgrade to use this feature",
              message: "Quizzes are a premium feature available on the Organization plan."
            },
            can_duplicate_spaces: {
              title: "Upgrade to use this feature",
              message:
                "Duplicating a space is a premium feature available on the Professional plan.",
            },
            can_save_templates: {
              title: "Upgrade to use this feature",
              message:
                "Saving as space as a reusable template is a premium feature available on the Professional plan.",
            },
            can_edit_shareable_link: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Personalizzare i link condivisibili è una funzione premium disponibile con il piano Professional.",
            },

            can_upload_cover: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Caricare un'immagine di copertina personalizzata è una funzione premium disponibile con il piano Personal.",
            },

            can_upload_logo: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Impostare un logo personalizzato è una funzione premium disponibile con il piano Professional.",
            },

            can_set_layout: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Personalizzare il layout del tuo spazio è una funzione premium disponibile con il piano Professional.",
            },

            can_set_theme: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Personalizzare il tema di colore del tuo spazio è una funzione premium disponibile con il piano Professional.",
            },

            can_set_format: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Cambiare il formato del tuo spazio è una funzione premium disponibile nel piano Professional.",
            },

            can_add_step_send_to_pending: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Assegnare lo stato di attesa ai nuovi membri è una funzione premium disponibile con il piano Personal.",
            },

            can_add_step_send_email: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "L'invio di email automatiche è una funzione premium disponibile con il piano Personal.",
            },

            can_add_add_to_existing_space: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Aggiungere automaticamente i membri agli spazi selezionati è una funzione premium disponibile con il piano Professional.",
            },

            can_add_step_wait: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Aggiungere una condizione di attesa per passaggi aggiuntivi è una funzione premium disponibile con il piano Professional.",
            },

            can_add_step_webhook: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Aggiungere webhooks è una funzione premium disponibile con il piano Professional.",
            },

            can_add_step_survey_person: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Fare un sondaggio tra i nuovi membri è una funzione premium disponibile con il piano Professional.",
            },

            can_schedule_states: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Le opzioni di pianificazione automatica del tuo spazio sono una funzione premium disponibile con il piano Professionale.",
            },

            can_broadcast: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "L'invio massivo di email è una funzione premium disponibile con il piano Personal.",
            },

            can_survey: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Creare sondaggi è una funzione premium disponibile con il piano Professional.",
            },

            can_use_interest_form_block: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Aggiungere moduli di interesse è una funzione premium disponibile con il piano Professional.",
            },
            can_use_interactive_block: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Aggiungere blocchi interattivi è una funzione premium disponibile con il piano Professional.",
            },

            can_set_flexible_payments: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Impostare opzioni di pagamento flessibile è una funzione premium disponibile con il piano Professional.",
            },

            can_activate_completions: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Segnare gli spazi come completati è una funzione premium disponibile con il piano Professional.",
            },

            max_allowed_spaces: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Ehi, sembra che tu abbia esaurito tutti i tuoi spazi gratuiti! Effettuando l’upgrade al piano Personal avrai spazi e membri illimitati. In questo modo potrai creare praticamente tutto quello che vuoi.",
            },
            max_allowed_ij: {
              title: "Upgrade to use this feature",
              message:
                "You can only add up to 5 discussion prompts per space on the free plan. Please upgrade to the Professional plan to add unlimited discussion prompts to any space.",
            },

            max_allowed_managers_pro: {
              title: "Effettua l’upgrade per aggiungere più amministratori",
              message:
                "Effettua l’upgrade al piano Professional per aggiungere fino a 2 amministratori al tuo spazio o passa al piano Organization per avere accesso ad amministratori illimitati.",
            },

            max_allowed_managers_org: {
              title: "Effettua l’upgrade per aggiungere più amministratori",
              message:
                "Effettua l’upgrade al piano Organization per aggiungere amministratori illimitati al tuo spazio.",
            },

            can_see_participation_tab: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Le metriche di partecipazione sono una funzione premium disponibile con il piano Professional.",
            },

            can_send_selective_broadcast: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "L’invio massivo selettivo è una funzione premium disponibile con il piano Organization.",
            },

            can_send_message_broadcast: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "L’invio massivo dei messaggi è una funzione premium disponibile con il piano Organization.",
            },

            can_add_from_children: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Aggiungere i membri dai sottospazi è una funzione premium disponibile con il piano Professional.",
            },

            can_add_to_direct_open_children: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Aggiungere i nuovi membri a tutti i sottospazi aperti e diretti è una funzione premium disponibile con il piano Professional.",
            },

            can_send_message: {
              title: "Effettua l’upgrade per utilizzare questa funzione",
              message:
                "Inviare messaggi diretti automatici ai nuovi membri è una funzione premium disponibile con il piano Professional.",
            },
          },
          home: {
            sidebar: {
              createButton: "Nuovo spazio",
              spaces: "I tuoi spazi",
              invitations: "Inviti",
              directMessages: "Messaggi diretti",
              gettingStarted: "Primi passi",
              scheduleDemo: "Prenotare una dimostrazione",
              upgrade: "Effettua l’upgrade",
              promoBoxTitle:
                "Unisciti a centinaia di creators che sfruttano le funzionalità avanzate di Sutra per dare vita a coinvolgenti esperienze di gruppo online.",
              promoBoxItem1: "Spazi illimitati",
              promoBoxItem2: "Opzioni di branding completo",
              promoBoxItem3: "Amministratori addizionali",
              promoBoxButton: "Effettua l’upgrade ora",
            },
            spacesList: {
              yourSpaces: "I tuoi spazi",
              directMessagesTitle: "Messaggi diretti",
              invitationsTitle: "Inviti",
              topTitle: "Spazi superiori",
              managedTitle: "Spazi gestiti",
              allTitle: "Tutti gli spazi",
              archivedTitle: "Spazi archiviati",
              deletedTitle: "Spazi eliminati",
              pendingTitle: "Spazi programmati",
              directMessages: "Diretti",
              top: "Superiori",
              managed: "Gestiti",
              all: "Tutti",
              archived: "Archiviati",
              invitations: "Inviti",
              deleted: "Eliminati",
              pending: "Programmati",
              empty: "Non ce ne sono",
              tabTitle: "Sutra - Pannello di controllo",
              learn: "Scopri le basi",
              createFirst: "Crea il tuo primo spazio",
            },
          },
          app: {
            payment: {
              payment: {
                tabTitle: "Sutra - Modulo di pagamento",
                title: "Completa il pagamento",
              },
              planOption: {
                frequencyDescription1: "al mese",
                frequencyDescription2: "al mese con pagamento annuale",
                currentPlan: "Piano attuale",
                billedMonthly: "Pagamento mensile",
                billedAnnually: "Pagamento annuale",
              },
              stripeForm: {
                due_in_trial: "Scade tra 14 giorni",
                pay: "Paga",
              },
            },
            gettingStarted: {
              items: {
                setup: "Configurazione del tuo spazio di programma",
                privacy: "Impostazioni della privacy e di pagamento",
                registration: "Creare una pagina di registrazione",
                invitations: "Invitare membri",
                settings: "Come funzionano le configurazioni dello spazio",
                interactive: "Percorsi interattivi",
                interest: "Moduli di interesse",
                appearance: "Layout e temi di colore",
                grids: "Impostazioni della griglia e dei blocchi",
                broadcasts: "Invio massivo di email",
                tagging: "Tags",
                emojis: "Emoji della barra laterale",
              },
              templates: {
                groupprogram: {
                  label: "Programma di gruppo",
                  description:
                    "Uno spazio del programma dove puoi organizzare tutto quello di cui hai bisogno per un gruppo di persone che imparano insieme.",
                },
                zoomworkshop: {
                  label: "Workshop su Zoom",
                  description:
                    "Organizza tutto quello di cui hai bisogno per un semplice workshop su Zoom.",
                },
                mastermind: {
                  label: "Mastermind",
                  description:
                    "Invita un gruppo di persone ad entrare insieme in un processo di mastermind.",
                },
                selfpacedcourse: {
                  label: "Corso al tuo ritmo",
                  description:
                    "Un modello flessibile per creare un corso al tuo ritmo.",
                },
                communityspace: {
                  label: "Spazio della community",
                  description:
                    "Unisci le persone in un vibrante spazio online che cresce insieme alla tua community.",
                },
                oneononecoaching: {
                  label: "Coaching individuale",
                  description:
                    "Crea uno spazio per ciascuno dei tuoi clienti di coaching.",
                },
              },
              loading: {
                text: "Il tuo template si sta caricando, per favore attendi...",
              },
              tabTitle: "Sutra - Primi passi",
              mainTitle: "Guida dei primi passi",
              selectTemplate: "Seleziona questo template",
              advanceFeatures: "Funzionalità avanzate",
              welcome: "Benvenuto/a",
              description1:
                "Sutra rende semplice l'organizzazione del contenuto e delle conversazioni dei tuoi corsi, community, coaching individuale e molto altro. Scopri le basi in questo video di cinque minuti.",
              watchVideo: "Guarda il video",
              createFirstSpace: "Crea il tuo primo spazio",
              gettingStartedGuide: "Guida dei primi passi",
              description2:
                "Scopri di più su come funziona Sutra e inizia con una checklist di lancio e una guida più dettagliata.",
              checkOutTheGuide: "Scopri la guida",
              startWithATemplate: "Iniziare con un template",
              description3:
                "Scopri di più su come funziona Sutra e inizia con una checklist di lancio e una guida più dettagliata.",
              learnAbout: "Scopri le funzionalità avanzate",
              description4:
                "Sutra è progettata per essere una piattaforma flessibile, potente e per adattarsi a qualsiasi caso d'uso. Puoi creare esperienze interattive uniche, catturare l'interesse per le tue offerte, inviare newsletter via email e raccogliere la conoscenza collettiva.",
              learnmore: "Scopri di più",
            },
            premium: {
              try: "Prova Sutra",
              include: "Funzionalità che includono",
              upgrade: "Effettua l’upgrade ora",
            },
            pricing: {
              tabTitle: "Sutra - Prezzi",
              upgrade: "Effettua l’upgrade",
              text: "Per qualsiasi cosa di cui hai bisogno per il tuo corso o la tua community, puoi contare sul nostro supporto. Contattaci per qualsiasi domanda all'indirizzo supporto@sutra.co.",
            },
            scheduleDemo: {
              tabTitle: "Sutra - Prenota una dimostrazione",
            },
            welcome: {
              tabTitle: "Sutra - Benvenuto/a",
              title: "Benvenuto/a su Sutra!",
              description1:
                "Siamo felici di averti qui. Dedica un momento per guardare il video qui sotto...",
              description2:
                "Ti piacerebbe provare gratuitamente uno dei nostri piani a pagamento?",
              description3:
                "Scegli uno dei piani che trovi qui sotto per iniziare una prova gratuita di 14 giorni. Al termine della prova, ti verrà automaticamente addebitato il costo del piano che hai selezionato.",
              yes: "Sì, mostrami le opzioni",
              no: "No, grazie",
            },
          },
          layout: {
            userButton: {
              home: "Home",
              viewProfile: "Visualizza profilo",
              settings: "Impostazioni",
              support: "Supporto",
              language: "Lingua",
              terms: "Termini & Privacy",
              logout: "Esci",
              copyright: "Sutra Spaces, LLC © 2022",
            },
            directMessages: {
              label: "Messaggi diretti",
              actionButton: "Visualizza tutto",
              empty: "Non ci sono messaggi",
              directMessage: "Messaggi diretti",
            },
            notifications: {
              label: "Notifiche",
              actionButton: "Cancella tutto",
              empty: "Non ci sono notifiche",
              notification: "Notifiche",
            },
            headerContent: {
              registrationPage: "Pagina di registrazione",
              nonManagersHidden:
                "Intestazione nascosta a tutti tranne agli amministratori",
            },
            search: {
              reflection: "Riflessione",
              comment: "Commento",
              allSpaces: "Tutti gli spazi",
              withinTop: "All’interno dello spazio superiore",
              withinSpace: "All'interno di questo spazio",
              everything: "Tutto",
              titlesOnly: "Solo i titoli",
              tagsOnly: "Solo i tag",
              content: "Contenuto",
              discussion: "Discussione",
              events: "Eventi",
              users: "Utenti",
              placeholder: "Cerca qui...",
              nothingFound: "Nessun risultato trovato",
              search: "Cerca",
            },
            uploader: {
              pending: "In attesa",
              used: "Utilizzato",
              uploading: "Caricamento",
              finishing: "Completando",
              processing: "In elaborazione",
              completed: "Completato",
            },
          },
          resources: {
            pages: {
              form: {
                label: "Titolo",
                placeholder: "Qual è il tema della tua pagina?",
                create: "Crea pagina",
                save: "Salva pagina",
              },
            },
            form: {
              title: {
                label: "Titolo",
                placeholder: "Qual è il tuo tema?",
              },
              publish: {
                label: "Pubblica in data",
              },
              date: {
                label: "Scegli la data",
              },
              update: "Aggiorna la raccolta di pagine",
              create: "Crea una raccolta di pagine",
            },
          },
          settings: {
            editProfile: {
              tabTitle: "Sutra - Modifica Profilo",
              editProfile: "Modifica profilo",
              firstName: "Nome",
              lastName: "Cognome",
              username: "Nome utente",
              email: "Email",
              removeProfileImage: "Rimuovi la foto del profilo",
              addProfileImage: "Carica la foto del profilo",
              about: "Su di me",
              location: "Luogo",
              bio: "Presentazione",
              bioPlaceholder: "Racconta qualcosa su di te..",
              updateProfile: "Aggiorna profilo",
            },
            accountSettings: {
              accountSettings: "Impostazioni dell'account",
              changePassword: "Cambia password",
              currentPassword: "Password attuale",
              newPassword: "Nuova password",
              newPasswordConfirmation: "Conferma la nuova password",
              updatePassword: "Aggiorna password",
              advanced: "Avanzate",
              deleteAccount: "Elimina account",
              warning: "Avviso",
              deleteAccountWarning:
                "L'eliminazione del tuo account è un’azione irreversibile ed i dati del tuo account verranno rimossi definitivamente dal nostro sistema.",
              closeYourAccount: "Chiudi il tuo account",
              invalidCurrentPasswordErrorMsg: "Non può essere vuoto",
              invalidPasswordErrorMsg:
                "La password deve contenere almeno 8 caratteri",
              invalidPasswordConfirmationErrorMsg:
                "Le password non coincidono",
              deleteAccountAlert: {
                title: "Importante",
                message:
                  "L'eliminazione del tuo account è un’azione irreversibile ed i dati del tuo account verranno rimossi definitivamente dal nostro sistema.",
                input:
                  "Digita 'elimina il mio account' per confermare questa azione",
                confirmButton: "Elimina definitivamente",
                cancelButton: "Annulla",
              },
              cancelSubscriptionAlert: {
                title: "Importante",
                message: "Per favore, annulla prima il tuo abbonamento",
                confirmButton: "Vai alla fatturazione",
                cancelButton: "Farlo più tardi",
              },
              stripeAccount: {
                title: "Ripristina collegamento Stripe",
                alertTitle: "Sei sicuro/a?",
                alertMessage:
                  "Se ripristini il collegamento con Stripe, le tue credenziali di Stripe verranno cancellate dal tuo account di Sutra e potrai collegare un nuovo account Stripe.",
                buttonText: "Ripristina collegamento",
                confirmButton: "Sì",
                cancelButton: "No",
                confirmTitle: "Sei sicuro/a?",
                confirmMessage:
                  "Le tue credenziali di Stripe verranno rimosse dal tuo account di Sutra.",
              },
            },
            emailPreferences: {
              tabTitle: "Sutra - Preferenze Email",
              emailPreferences: "Preferenze email",
              emailDigestPreferences: "Preferenze di notifica via email",
              emailDigestFrequency: "Preferenze di frequenza di notifica via email",
              emailDigestMessage:
                "Per modificare le preferenze di notifica per spazio, consulta l'area delle impostazioni di iscrizione. Puoi annullare completamente l'iscrizione ad uno spazio lasciandolo o puoi silenziare uno spazio specifico nell'area delle impostazioni di iscrizione.",
              receiveWeeklyDigest:
                "Ricevi un riepilogo settimanale degli elementi e discussioni recentemente taggati.",
              receiveAtMentions:
                "Ricevi una notifica quando qualcuno ti @menziona",
            },
            subscriptions: {
              frequencyOptions: "Opzioni di frequenza",
              otherActions: "Altre azioni",
              subscriptions: "Preferenze specifiche dello spazio",
              searchBySpaceName: "Cerca lo spazio per nome..",
              search: "Cerca",
              clear: "Azzerrare",
              options: "Opzioni",
              otherActions: "Altre azioni",
              emptySubscriptions: "Non ci sono iscrizioni",
              leaveSpaceConfirmationTitle:
                "Sei sicuro che vuoi abbandonare questo spazio?",
              leaveSpaceConfirmationHtml:
                "Non sarai più un membro di questo spazio e potresti non essere in grado di rientrare senza richiedere l’accesso all’amministratore.",
              leaveSpaceButtonText: "Sì, abbandonare",
              leaveSpaceDenyButtonText: "No",
              leaveSpace: "Abbandonare lo spazio",
              unsubscribe: "Annullare l’iscrizione",
              unsubscribed: "Iscrizione annullata",
            },
            digestPreferences: {
              digestedLabel:
                "Raggruppare tutti gli aggiornamenti in un unico riepilogo",
              digestedDescription:
                "I nuovi messaggi di tutti i tuoi spazi verranno raggruppati in un unico aggiornamento via email.",
              undigestedDescription:
                "Raggruppare tutti gli aggiornamenti per spazio",
            },
            billing: {
              statuses: {
                pending: "In attesa",
                processing: "In elaborazione",
                paid: "Pagato",
              },
              paymentMethod: {
                currentPaymentMethod: "Metodo di pagamento attuale",
                noCurrentPaymentMethod: "Nessun metodo di pagamento salvato",
                addNewPaymentMethod: "Aggiungi un nuovo metodo di pagamento",
              },
              stripe: {
                updatePaymentMethod: "Aggiorna il metodo di pagamento",
              },
              tabTitle: "Sutra - Fatturazione",
              updatePaymentMethodMayus: "AGGIORNA IL METODO DI PAGAMENTO",
              payments: "PAGAMENTI",
              pdf: "PDF",
              noPayments: "Non hai uno storico dei pagamenti",
              billing: "Fatturazione",
              upgradePlan: "Effettua l’upgrade",
              changePlan: "Cambia piano",
              updatePaymentMethod: "Aggiorna il metodo di pagamento",
              cancelSubscription: "Annulla abbonamento",
              cancelConfirmationTitle:
                "Sei sicuro che vuoi annullare l’abbonamento?",
              cancelConfirmationHtml:
                "Non avrai più accesso alle funzionalità premium del tuo piano attuale.",
              cancelConfirmButtonText: "Sì, annulla",
              cancelDenyButtonText: "No",
              billingHistory: "Storico fatturazione",
              tableDate: "Data",
              tableAmount: "Importo",
              tableStatus: "Stato",
              tableInvoice: "Fattura",
              planPrefix: "Il tuo abbonamento attuale è",
              planSuffix: "piano",
              updatePayment: {
                success: {
                  title: "Azione andata a buon fine",
                  message: "Il tuo metodo di pagamento è stato aggiornato",
                },
                error: {
                  title: "Ops, qualcosa è andato storto!",
                },
              },
            },
            frequencyOptions: {
              mute: "Silenzia",
              always: "Ogni messaggio",
              hour1: "Ogni ora",
              hour2: "Ogni 2 ore",
              hour4: "Ogni 4 ore",
              hour8: "Ogni 8 ore",
              hour24: "Una volta al giorno",
              hour168: "Una volta alla settimana",
              hour336: "Ogni due settimane",
            },
            affiliates: {
              history: {
                empty: "Non c'è uno storico",
                table: {
                  referralName: "Nome del referito",
                  startAt: "Inizio",
                  endAt: "Fine",
                  createdAt: "Creato il",
                  percent: "Percentuale",
                  total: "Totale guadagnato",
                  status: "Stato",
                  value: "Valore",
                  revenue: "Ricavo",
                  payAt: "Pagato il",
                  status: "Stato",
                },
                statuses: {
                  null: "In attesa",
                  pending: "In attesa",
                  processing: "In elaborazione",
                  paid: "Pagato",
                },
                paymentsHistory: "Storico dei guadagni",
              },
              tabTitle: "Sutra - Programma ambasciatori",
              title: "Programma ambasciatori",
              affiliateLink: "Questo è il tuo link di affiliazione",
              copyMessage: "Link copiato negli appunti",
              stripeTitle: "Stripe collegato a",
              stripeConnected:
                "Questo è l’identificativo del tuo account Stripe. Per trovare questo ID su Stripe, accedi a Stripe tramite il link e scorri fino in fondo alla pagina delle impostazioni del tuo account.",
              stripeDisconnect: "Scollegare",
              description:
                "Per elaborare i pagamenti del programma ambasciatori, collega il tuo account Stripe facendo clic sul pulsante qui sotto.",
              description2:
                "Quando condividi questo link con gli altri, riceverai automaticamente il 40% durante il primo anno di ricavi generati da chiunque faccia abbonamento a uno dei piani a pagamento di Sutra.",
              connect: "Collega Stripe",
              historyLabel: "Storico dei guadagni",
            },
          },
          spaces: {
            settings: {
              layout: {
                navbarContent: {
                  mainSettings: "Configurazioni generali",
                  members: "Membri",
                  broadcast: "Invio massivo",
                  surveys: "Sondaggi",
                  structures: "Strutture",
                  sutraAdmin: "Sutra Admin",
                  scheduled: "Programmati",
                  archived: "Archiviati",
                  participation: "Partecipazione",
                },
                headerActions: {
                  shareableLink: "LINK CONDIVISIBILE",
                },
              },
              main: {
                title: "Configurazioni generali",
                main: {
                  invite: "Invita",
                  shareableLink: "Link condivisibile",
                  cancelLink: "Annulla",
                  saveLink: "Salva",
                  editLinkButton: "Modifica link",
                  subdomain: "Il tuo sottodominio",
                  sutra_co: ".sutra.co",
                  label1: "Link copiato negli appunti",
                  privacySetting: "Seleziona la impostazione sulla privacy",
                  title1: "Rendi questo spazio accessibile pubblicamente",
                  description1:
                    "Questo spazio si trova all'interno di uno spazio privato ed è visibile solo ai membri di questo spazio. Puoi renderlo accessibile al di fuori di questo spazio attivando questa impostazione.",
                  description2:
                    "Se questa opzione è attiva, chi non è membro di questo spazio vedrà una pagina di registrazione quando cercherà di accedervi.",
                  title3: "Utilizza la pagina di registrazione della versione precedente",
                  description3:
                    "Se attivi questa impostazione, lo spazio utilizzerà la pagina di registrazione creata con il nostro editor precedente.",
                  onButton: "ON",
                  offButton: "OFF",
                  editButton: "Modifica pagina",
                  previewButton: "Anteprima della pagina",
                  label2: "Impostazioni della privacy e di pagamento",
                  label3: "Impostazioni della pagina di registrazione",
                  description4:
                    "Attiva - le persone vedranno una pagina di registrazione prima di poter accedere a questo spazio",
                  label4: "Automazione",
                  description5:
                    "Programma l’invio delle email e aggiungi membri automaticamente agli spazi quando si registrano",
                  label5: "Impostazioni di programmazione",
                  description6:
                    "Imposta la data dell’evento o le opzioni di programmazione della pubblicazione e archiviazione ",
                  label6: "Configurazioni aggiuntive",
                  description7:
                    "Autorizzazioni per la creazione di contenuti, visibilità dei membri, notifiche per gli amministratori e spazi genitore",
                  label7: "Impostazioni SEO",
                  description8:
                    "Imposta il meta titolo, la meta descrizione e l'immagine per la condivisione sui social e l'ottimizzazione dei motori di ricerca.",
                  label8: "Aperto",
                  description9: "Chiunque può accedere a questo spazio",
                  label9: "Privato",
                  description10:
                    "Questo spazio è privato e le persone devono essere invitate per accendervi",
                  label10: "A pagamento",
                  description11:
                    "Questo spazio è privato e a pagamento. Le persone devono pagare per accedervi.",
                  description12:
                    "Le persone verranno indirizzate ad una pagina di registrazione prima di accedere a questo spazio aperto.",
                  description13:
                    "Le persone verranno indirizzate direttamente dentro a questo spazio quando accederanno a questo spazio aperto.",
                  description14:
                    "Le persone devono essere direttamente invitate per accedere a questo spazio privato. Se sono state invitate, vedranno una pagina di registrazione.",
                  description15:
                    "Le persone devono essere direttamente invitate per accedere a questo spazio privato. Se sono state invitate, vedranno una pagina di creazione dell’account e non vedranno una pagina di registrazione.",
                  description16:
                    "Le persone verranno indirizzate a una pagina di registrazione prima di poter accedere a questo spazio a pagamento.",
                  description17:
                    "Prima di poter accedere a questo spazio a pagamento le persone verranno indirizzate ad una pagina di creazione dell’account e alla pagina di pagamento. Non vedranno una pagina di registrazione.",
                  on: "ON",
                  off: "OFF",
                  label11: "Membri",
                  label12: "In attesa",
                  label13: "Interessati",
                  label14: "Invitati",
                  label15: "Pagamenti",
                  label16: "Marchio",
                },
                additional: {
                  allowMembersEdit:
                    "Permettere ai membri di modificare i contenuti su questa pagina",
                  membersWillAddContent:
                    "I membri potranno aggiungere e modificare i contenuti su questa pagina",
                  allowMembersCreatePosts:
                    "Permettere ai membri di creare nuovi post",
                  membersWillCreatePosts:
                    "I membri potranno creare nuovi post in questo spazio",
                  allowMembersCreateEvents:
                    "Permettere ai membri di creare nuovi eventi",
                  membersWillCreateEvents:
                    "I membri potranno creare nuovi eventi in questo spazio",
                  allowMembersCreateSubspaces:
                    "Permettere ai membri di creare nuovi sottospazi",
                  membersWillCreateSubspaces:
                    "I membri potranno creare sottospazi su questa pagina",
                  membersCanCreate: "I membri possono creare",
                  circleCreation: {
                    options: {
                      discussion: "Spazi di discussione",
                      content: "Spazi di contenuto",
                      event: "Spazi di evento",
                    },
                    placeholder:
                      "Seleziona i tipi di spazi che i membri possono creare in questo spazio",
                  },
                  allowMembersToCopy: {
                    description1:
                      "Permettere ai membri di invitare persone da altri spazi.",
                    description2:
                      "I membri avranno l'opzione di aggiungere automaticamente membri da altri spazi",
                  },
                  podType: {
                    description1:
                      "Tutti i membri possono postare messaggi in questo spazio",
                    description2:
                      "Se questa opzione è disattivata, solo gli amministratori possono postare messaggi",
                  },
                  allowReflections: {
                    description1: "Abilita riflessioni per questo spazio",
                    description2:
                      "Se questa opzione è disattivata, l'opzione di riflessione non sarà disponibile nei messaggi",
                  },
                  showMembers: {
                    description1: "Mostrare membri",
                    description2:
                      "Mostrare l'elenco dei membri per questo spazio",
                  },
                  hideMmemberEmails: {
                    description1:
                      "Mostrare gli indirizzi email dei membri nell'elenco",
                    description2:
                      "Se questa opzione è disattivata, gli indirizzi email dei membri non verranno mostrati nell'elenco",
                  },
                  newMemberNotifications: {
                    description1:
                      "Notificare gli amministratori quando si uniscono nuovi membri",
                    description2:
                      "Inviare una notifica via mail per ogni nuovo membro",
                  },
                  podSize: {
                    description1:
                      "Limitare il numero di persone che possono unirsi a questo spazio",
                    description2:
                      "Specifica un numero massimo di persone che possono unirsi a questo spazio",
                  },
                  podSizeValue: {
                    description:
                      "Quante persone possono unirsi a questo spazio?",
                  },
                  sidebarDefaultOpened: {
                    description1:
                      "Spazio aperto di default nella barra laterale",
                    description2:
                      "Se questa opzione è attiva, questo spazio sarà aperto di default nella barra laterale",
                  },
                  sidebarHideChildren: {
                    description1: "Nascondere sottospazi nella barra laterale",
                    description2:
                      "Se questa opzione è attiva, gli sottospazi creati in questo spazio non appariranno nella barra laterale",
                  },
                  allowCompletions: {
                    description1: "Mostrare il pulsante “Segna come completato”",
                    description2:
                      "Se questa opzione è attiva, i membri vedranno il pulsante “Segna come completato” in questo spazio. Dopo aver cliccato su questo pulsante, l'icona nella barra laterale verrà sostituita da un segno di spunta.",
                  },
                  redirectToSubspace: {
                    description1: "Reindirizzare sempre al sottospazio",
                    description2:
                      "Se questa opzione è attiva, i membri verranno indirizzati sempre al sottospazio selezionato quando accedono a questo spazio.",
                  },
                  parentCircles: {
                    description1: "Spazi genitore",
                    placeholder:
                      "Cerca spazi da aggiungere come spazi genitore di questo spazio",
                  },
                },
                scheduling: {
                  showStartDate: {
                    description1: "Mostra questo spazio come un evento",
                    description2:
                      "Questo spazio mostrerà la data dell'evento ovunque sia elencato",
                  },
                  startDateTime: {
                    title: "Data e ora di inizio",
                    placeholder: "Seleziona la data",
                  },
                  endDateTime: {
                    title: "Data e ora di fine",
                    placeholder: "Seleziona la data",
                  },
                  showLaunchDate: {
                    description1:
                      "Pubblica questo spazio in una data specifica",
                    description2:
                      "Questo spazio diventerà visibile e accessibile in data specificata",
                  },
                  launchDateTime: {
                    placeholder: "Seleziona la data",
                  },
                  showArchiveDate: {
                    description1:
                      "Archivia questo spazio in una data specifica",
                    description2:
                      "Questo spazio non sarà più accessibile dalla data specificata in poi",
                  },
                  archiveDate: {
                    placeholder: "Seleziona la data",
                  },
                  showReadonlyDate: {
                    description1:
                      "Rendi questo spazio di sola lettura in una data specifica",
                    description2:
                      "Nessun nuovo messaggio potrà essere postato dopo la data specificata",
                  },
                  readonlyDate: {
                    placeholder: "Seleziona la data",
                  },
                },
                seo: {
                  title: {
                    title: "Tag title",
                    description:
                      "Questo è il titolo che apparirà nei risultati dei motori di ricerca e nelle condivisioni sui social. La lunghezza ottimale è di 60 caratteri.",
                  },
                  description: {
                    title: "Meta description",
                    description:
                      "Questa è la descrizione che apparirà nei risultati dei motori di ricerca e nelle condivisioni sui social. La lunghezza ottimale è di 150-350 caratteri.",
                  },
                  image: {
                    title: "Meta immagine",
                    description:
                      "Questa è l'immagine che apparirà nelle condivisioni sui social. Le dimensioni consigliate sono 1200 pixel x 627 pixel (proporzioni 1.91/1).",
                  },
                },
              },
              members: {
                title: "Membri",
                members: {
                  download: "Scarica CSV",
                  invite: "Invita",
                  member: "Membri",
                  invited: "Invitati",
                  interested: "Interessati",
                  payment: "Pagamenti",
                },
                interested: {
                  interested: {
                    button: "Cerca",
                    placeholder: "Ricerca avanzata",
                    broadcast: "Invio massivo",
                    noRecordFound: "Nessun risultato trovato",
                  },
                  table: {
                    firstname: "Nome",
                    email: "Email",
                    date: "Data",
                  },
                  interestedrow: {
                    removeFromList: "Rimuovi dalla lista",
                  },
                },
                invited: {
                  invited: {
                    button: "Cerca",
                    placeholder: "Ricerca avanzata",
                    noRecordFound: "Nessun risultato trovato",
                  },
                  table: {
                    email: "Email",
                    invitationlink: "Link di invito",
                    amount: "Importo",
                    date: "Data di invio",
                  },
                },
                invitedrow: {
                  invitation: {
                    resend: "Rinvia invito",
                    cancel: "Annulla invito",
                  },
                },
                inviteEmail: {
                  invite: {
                    placeholder:
                      "Scrivi l'indirizzo email o menziona le persone che desideri aggiungere a questo spazio. I valori possono essere separati da virgole, spazi o nuove righe.",
                    message: "Aggiungi un messaggio di benvenuto opzionale",
                    checkboxLabel:
                      "Invia un’email per notificare le persone che sono state invitate",
                    alertMessage:
                      "Attualmente stai utilizzando un piano base e non puoi inviare inviti tramite Sutra. Quando fai clic su “Invia”, verrà generato un link di invito unico per ogni indirizzo email che aggiungi. Potrai inviare questo link via email a ciascuna persona personalmente.",
                    alertbutton: "Effettua l’upgrade",
                    button: "Invita persone",
                  },
                },
                inviteExisting: {
                  invite: {
                    copyMembers: "Copia membri da un altro spazio",
                    copyMembersPlaceholder:
                      "Seleziona gli spazi da cui copiare i membri",
                    addActiveMembers:
                      "Aggiungi i membri attivi dagli spazi principali",
                    addActiveMembersPlaceholder:
                      "Seleziona i membri attivi da aggiungere a questo spazio",
                    addPendingMembers:
                      "Aggiungi i membri che sono in attesa dagli spazi genitore",
                    addPendingMembersPlaceholder:
                      "Seleziona i membri che sono in attesa per aggiungerli a questo spazio",
                    checkboxLabel:
                      "Invia un'email per notificare le persone che sono state invitate",
                    alert:
                      "Attualmente stai utilizzando un piano base e non puoi inviare inviti tramite Sutra. Quando fai clic su “Invia”, verrà generato un link di invito unico per ogni indirizzo email che aggiungi. Potrai inviare questo link via email a ciascuna persona personalmente.",
                    alertButton: "Effettua l’upgrade",
                    button: "Invita persone",
                    thisOption:
                      "Questa opzione non è abilitata per questo spazio",
                  },
                },
                inviteLink: {
                  invite: {
                    shareLinkText:
                      "Le persone possono registrarsi a questo spazio direttamente con il seguente link. Puoi condividere questo link ovunque desideri (via email, sui social network, ecc).",
                    youCannotShare:
                      "Non puoi condividere link ad uno spazio privato.",
                  },
                },
                invitePeople: {
                  invite: {
                    invitePeople: "Invita persone",
                  },
                },
                membersActivity: {
                  members: {
                    placeholder: "Cerca",
                    button: "Cerca",
                    emailButton: "Email",
                    messageButton: "Messaggio",
                    noRecordFound: "Nessun risultato trovato",
                  },
                  memberTable: {
                    noRecordFound: "Nessun risultato trovato per",
                    lastSeen: "Ultima visualizzazione",
                    lastPosted: "Ultimo post",
                    date: "Si è unito/a in data",
                  },
                },
                participationActivity: {
                  participation: {
                    searchPlaceholder: "Cerca",
                    searchButton: "Cerca",
                    pickDatePlaceholder: "Seleziona intervallo di date",
                    emailButton: "Email",
                    messageButton: "Messaggio",
                    noRecordFound: "Nessun risultato trovato",
                  },
                  memberTable: {
                    member: "Membro",
                    participation: "Partecipazione",
                    posts: "Post",
                    words: "Parole",
                  },
                },
                participationActivityRow: {
                  posts: "post",
                },
                paymentRow: {
                  payment: {
                    refundPayment: "Rimborsare il pagamento",
                  },
                },
                payments: {
                  payment: {
                    searchPlaceholder: "Cerca",
                    searchButton: "Cerca",
                    noRecordFound: "Nessun risultato trovato",
                  },
                  paymentTable: {
                    member: "Membro",
                    amount: "Importo",
                    date: "Data",
                    status: "Stato",
                  },
                },
                progressActivity: {
                  progress: {
                    searchPlaceholder: "Cerca",
                    searchButton: "Cerca",
                    emailButton: "Email",
                    messageButton: "Messaggio",
                    noRecordFound: "Nessun risultato trovato",
                    progressTracking:
                      "Il monitoraggio del progresso è disponibile solo negli spazi che hanno prompt interattivi.",
                  },
                  progressTable: {
                    member: "Membro",
                    progress: "Progresso",
                  },
                },
                registered: {
                  registered: {
                    searchPlaceholder: "Cerca",
                    searchButton: "Cerca",
                    email: "Email",
                    message: "Messaggio",
                    noRecordFound: "Nessun risultato trovato",
                    pending: "In attesa",
                    manager: "Amministratori",
                    all: "Tutti",
                    active: "Attivi",
                  },
                  registeredTable: {
                    member: "Membro",
                    email: "Email",
                    status: "Stato",
                    role: "Ruolo",
                    roleApproveAll: "Approva tutti",
                  },
                },
                registeredRow: {
                  registered: {
                    roleApprove: "Approva",
                    joined: "Unito/a",
                    sendMessage: "Invia un messaggio",
                    stopCharging: "Sospendi l'addebito",
                    emailPreferences: "Preferenze di notifica",
                    removeMember: "Rimuovi membro",
                  },
                },
              },
              payment: {
                charging: {
                  NumberOfTimes: {
                    howManyTimes: "Quante volte?",
                  },
                  UntilDate: {
                    pickDate: "Scegli una data di fine riscossione del pagamento",
                  },
                },
                fees: {
                  description: {
                    fees: "COMMISSIONI:",
                    sutraFee: "COMMISSIONE SUTRA +",
                    stripeProcessingFee: "COMMISSIONI STRIPE PER L'ELABORAZIONE DEI PAGAMENTI",
                  },
                },
                flexible: {
                  flexible: {
                    description:
                      "Per offrire opzioni di pagamento flessibile come ad esempio il modello “paga quello che puoi” o più livelli di pagamento, puoi creare un sondaggio di pagamento che le persone vedranno quando si registrano al tuo spazio. Fai clic sul pulsante di modifica qui sotto per personalizzare il sondaggio.",
                  },
                },
                once: {
                  once: {
                    description: "Quanto addebiterai?",
                    number: "10",
                  },
                },
                recurring: {
                  recurring: {
                    description1: "Quanto addebiterai?",
                    description2: "Seleziona un'opzione di addebito",
                  },
                },
              },
              privacy: {
                open: {
                  open: {
                    description: "Chiunque può accedere a questo spazio",
                  },
                },
                paid: {
                  paid: {
                    stripeConnected: "Stripe collegato a",
                    disconnectButton: "Scollegare Stripe",
                    description:
                      "Per l’elaborazione dei pagamenti per questo spazio collega il tuo account Stripe cliccando sul bottone qui sotto",
                    connectButton: "Collega Stripe",
                  },
                },
                private: {
                  private: {
                    description:
                      "Questo spazio è privato e le persone devono essere invitate per accedervi",
                  },
                },
              },
              registration: {
                steps: {
                  addFromSubSpace: {
                    label: "Seleziona uno spazio a cui aggiungere il membro",
                    description:
                      "In alcune circostanze, i membri possono unirsi a un sottospazio senza unirsi allo spazio genitore. In questi casi, dovrai assicurarti che i membri vengano aggiunti anche a uno spazio genitore o ad un altro spazio condiviso. Con questa opzione puoi farlo facilmente. Ogni volta che qualcuno si unisce a un sottospazio, verrà automaticamente aggiunto agli spazi selezionati di seguito.",
                    placeholder: "Seleziona gli spazi",
                    saveButton: "Salva passaggio",
                    deleteButton: "Elimina passaggio",
                    saveButton2: "Salva passaggio",
                  },
                  addStructure: {
                    label1: "Seleziona struttura",
                    placeholder1: "Seleziona un'opzione",
                    label2: "Invia come",
                    placeholder2: "Seleziona un'opzione",
                    saveButton: "Salva passaggio",
                  },
                  addToExistingSpace: {
                    label: "Seleziona uno spazio a cui aggiungere il membro",
                    placeholder: "Seleziona spazi",
                    saveButton1: "Salva passaggio",
                    deleteButton: "Elimina passaggio",
                    saveButton2: "Salva passaggio",
                  },
                  addToNewSpace: {
                    placeholder: "Nome del nuovo spazio",
                    label: "Inventa un nome per il nuovo spazio",
                    saveButton1: "Salva passaggio",
                    deleteButton: "Elimina passaggio",
                    saveButton2: "Salva passaggio",
                  },
                  applyTemplate: {
                    label: "Seleziona uno spazio da copiare",
                    description:
                      "In alcuni casi vorrai creare un template di spazio da replicare ogni volta che viene creato un nuovo spazio. Puoi creare uno spazio e configurarlo esattamente come vuoi. Il contenuto e gli sottospazi verranno copiati dal template nello spazio appena creato qui.",
                    saveButton1: "Salva passaggio",
                    deleteButton: "Elimina passaggio",
                    saveButton2: "Salva passaggio",
                  },
                  editEmail: {
                    placeholder1: "Oggetto",
                    label1: "Invia come",
                    placeholder2: "Seleziona un'opzione",
                    label2: "Invia a",
                    placeholder3: "Seleziona un'opzione",
                    placeholder4: "nome@dominio.com",
                    label3: "Indirizzo email",
                    saveButton: "Salva passaggio",
                    deleteButton: "Elimina passaggio",
                  },
                  editStructure: {
                    deleteButton: "Elimina passaggio",
                  },
                  editSurvey: {
                    deleteButton: "Elimina passaggio",
                  },
                  sendEmail: {
                    label1: "Seleziona email",
                    placeholder1: "Seleziona un'opzione",
                    label2: "Invia come",
                    placeholder2: "Seleziona un'opzione",
                    label3: "Invia a",
                    placeholder3: "Seleziona un'opzione",
                    label4: "nome@dominio.com",
                    placeholder4: "Indirizzo email",
                    saveButton: "Salva passaggio",
                    label5: "Amministratori",
                    label6: "Indirizzo email personalizzato",
                  },
                  setToPending: {
                    title: "Messaggio di benvenuto",
                    description:
                      "Questo è il contenuto che le persone vedranno subito dopo essersi registrate al tuo spazio. È il luogo ideale dove spiegare quando inizierà il programma e cosa possono aspettarsi nel frattempo.",
                    saveButton1: "Salva passaggio",
                    deleteButton: "Elimina passaggio",
                    saveButton2: "Salva passaggio",
                  },
                  surveyPerson: {
                    placeholder: "Seleziona un'opzione",
                    label: "Seleziona sondaggio",
                    saveButton1: "Salva passaggio",
                    deleteButton: "Elimina passaggio",
                    saveButton2: "Salva passaggio",
                  },
                  wait: {
                    description1: "Quanti giorni?",
                    description2: "Attendere fino a",
                    saveButton: "Salva passaggio",
                    deleteButton: "Elimina passaggio",
                    saveButton2: "Salva passaggio",
                    label1: "Seleziona condizione di attesa",
                    placeholder: "Seleziona un'opzione",
                    label2: "Numero di giorni",
                    label3: "Fino ad una data e ora specifica",
                    label4: "Seleziona una data",
                  },
                  webhook: {
                    label: "URL webhook",
                    saveButton: "Salva passaggio",
                    deleteButton: "Elimina passaggio",
                  },
                },
                addStep: {
                  addStep: {
                    description: "Aggiungi passaggio",
                    label: "Seleziona un'azione per questo passaggio",
                    placeholder: "Seleziona un'azione",
                  },
                },
                registrationFlow: {
                  registration: {
                    description1:
                      "Questa funzione ti consente di sequenziare azioni automatizzate che vengono attivate quando una persona si unisce o manifesta interesse per questo spazio. Qui puoi eseguire operazioni come pianificare l'invio di email ed aggiungere automaticamente le persone agli spazi selezionati al momento della registrazione.",
                    description2: "Eseguire queste azioni dopo che qualcuno...",
                    placeholder1: "Seleziona una condizione di inizio",
                    addButton: "Aggiungi passaggio",
                    label1: "Diventa membro o si unisce alla lista degli interessati",
                    description3:
                      "I seguenti passaggi verranno attivati quando una persona diventa membro di questo spazio o si unisce alla lista degli interessati inviando il proprio nome e indirizzo email tramite un modulo di interesse.",
                    label2: "Diventa membro di questo spazio",
                    description4:
                      "I seguenti passaggi verranno attivati quando una persona diventa membro di questo spazio.",
                    label3: "Manifesta interesse per questo spazio",
                    description5:
                      "I seguenti passaggi verranno attivati quando una persona si unisce alla lista degli interessati inviando il proprio nome e indirizzo email tramite un modulo di interesse.",
                  },
                },
              },
              structure: {
                add: {
                  add: {
                    title: "Aggiungi agli spazi",
                    description: "Spazi",
                    placeholder1: "Seleziona uno spazio",
                    label1: "Invia come",
                    placeholder2: "Scegli il mittente",
                    placeholder3: "Seleziona una data",
                    label2: "Data di invio",
                    label3: "Ora di invio",
                    saveButton: "Salva",
                    label4: "Pianifica per dopo",
                  },
                },
                createStructure: {
                  title: {
                    createStructure: "Crea struttura",
                  },
                },
                structureItem: {
                  item: {
                    addToSpace: "Aggiungi allo spazio",
                    open: "View responses",
                    edit: "Modifica",
                    duplicate: "Duplica",
                    delete: "Elimina",
                  },
                },
              },
              survey: {
                add: {
                  add: {
                    title: "Aggiungi agli spazi",
                    spaces: "Spazi",
                    placeholder1: "Seleziona uno spazio",
                    label: "Invia come",
                    placeholder2: "Scegli il mittente",
                    saveButton: "Salva",
                  },
                },
                answer: {
                  answer: {
                    addOption: "Aggiungi opzione",
                  },
                },
                createSurvey: {
                  description: "Crea sondaggio",
                },
                createSurveyForm: {
                  form: {
                    title: "Titolo del sondaggio",
                    description:
                      "Le opzioni di pagamento flessibile ti permettono di offrire più opzioni di pagamento per il tuo programma. Se vuoi che le persone impostino quanto pagare, crea semplicemente un'opzione senza impostare un importo fisso. Questa opzione apparirà con un campo dove le persone potranno impostare il proprio importo. Ad esempio, puoi offrire il modello “paga quanto puoi” con vari livelli di prezzo suggerito e un’opzione completamente libera. Se aggiungi un testo di spiegazione a seguito, questo testo verrà mostrato giusto sopra le opzioni di pagamento.",
                    optionalDescription:
                      "Descrizione facoltativa del sondaggio",
                    addQuestionButton: "Aggiungi domanda",
                    saveButton: "Salva",
                  },
                },
                individual: {
                  individual: {
                    placeholder: "Seleziona una persona",
                  },
                },
                multipleItem: {
                  multipleItem: {
                    placeholder: "Opzione",
                  },
                },
                paymentItem: {
                  paymentItem: {
                    placeholder: "Opzione di pagamento",
                  },
                },
                paymentSurveyItem: {
                  item: {
                    editButton: "Modifica le opzioni di pagamento",
                    viewButton: "Visualizza risposte",
                  },
                },
                showSurvey: {
                  survey: {
                    title: "Risposte del sondaggio",
                  },
                },
                singleItem: {
                  item: {
                    placeholder: "Opzione",
                  },
                },
                surveyItem: {
                  item: {
                    addToSpace: "Aggiungi allo spazio",
                    open: "View responses",
                    edit: "Modifica",
                    take: "Preview survey",
                    duplicate: "Duplica",
                    download: "Scarica CSV",
                    delete: "Elimina",
                    responses: "Risposte",
                  },
                },
              },
              broadcast: {
                broadcast: {
                  button: {
                    broadcastButton: "Nuovo invio massivo",
                  },
                },
              },
              broadcast: {
                title: "Invio massivo",
                broadcastButton: "Nuovo invio massivo",
                membersTypes: {
                  active: "Membri attivi",
                  pending: "Membri in attesa",
                  interested: "Membri interessati",
                },
                sentOn: "Invio massivo inviato il",
                onDestroy: {
                  alertTitle: "Sei sicuro/a?",
                  alertMessage:
                    "Questo invio massivo verrà eliminato definitivamente",
                  confirm: "Elimina l'invio massivo",
                  deny: "Annulla",
                },
                actions: {
                  edit: "Modifica",
                  duplicate: "Duplica",
                  delete: "Elimina",
                },
                statuses: {
                  sent: "Inviato",
                  scheduled: "Pianificato",
                  draft: "Bozza",
                  procesing: "Processing",
                },
                form: {
                  composeBroadcast: "Crea l'invio massivo",
                  contentErrMessage: "Aggiungi contenuto",
                  targetErrMessage: "Seleziona un destinatario",
                  type: {
                    email: "Invia per email",
                    message: "Invia come messaggio allo spazio",
                  },
                  title: {
                    placeholder: "Oggetto",
                  },
                  brackets: {
                    part1: "Utilizza",
                    part2: "e",
                    part3: "per autopopolare le informazioni corrispondenti",
                  },
                  target: {
                    placeholder: "Scegli destinatari",
                    label: "Invia a",
                  },
                  sendAs: {
                    label: "Invia come",
                    placeholder: "Scegli mittente",
                  },
                  schedule: {
                    label: "Pianifica per dopo",
                  },
                  sendAtDate: {
                    label: "Data di invio",
                    placeholder: "Seleziona data",
                  },
                  sendAtTime: {
                    label: "Orario di invio",
                    placeholder: "",
                  },
                  scheduleBroadcast: "Pianifica l'invio",
                  sendBroadcast: "Invia",
                  saveDraft: "Salva bozza",
                },
              },
              surveys: {
                title: "Sondaggi",
                createSurvey: "Crea sondaggio",
              },
              structures: {
                title: "Strutture",
                createStructure: "Crea struttura",
              },
              sutraAdmin: {
                title: "Sutra Admin",
              },
              scheduled: {
                title: "Pianificati",
              },
              archived: {
                title: "Archiviati",
              },
              participation: {
                title: "Partecipazione",
                label1: "Presenza",
                description1:
                  "% dei membri che hanno visitato lo spazio negli ultimi 7 giorni",
                label2: "Attività",
                description2:
                  "% dei membri che hanno postato negli ultimi 7 giorni",
                label3: "Post",
                description3:
                  "Numero totale dei post in tutti i sottospazi negli ultimi 7 giorni",
                label4: "Partecipazione",
                label5: "Attività dei membri",
                label6: "Progresso",
              },
            },
            sidebar: {
              iconSelect: {
                placeholder: "Cerca",
              },
              membersNode: {
                member: "Membri",
              },
              nodeIcon: {
                reset: "Ripristina",
              },
              sidebarTutorial: {
                gettingStarted: "Primi passi",
              },
            },
            alreadyMember: {
              member: {
                welcome: "Benvenuto! Sei già un membro di questo spazio -",
                clickHere: "Clicca qui per accedere",
              },
            },
            calender: {
              calender: {
                joinButton: "Partecipa alla videoconferenza",
                addButton: "Aggiungi al calendario",
                joinEventButton: "Registrati",
                title: "Dettagli dell'evento",
              },
            },
            invitationBar: {
              button: {
                register: "Registrati",
              },
            },
            space: {
              sutraLoading: "Sutra - Caricamento...",
            },
            statusBar: {
              restoreButton: "Ripristina",
            },
            tagFilter: {
              resetButton: "Ripristina",
            },
            presentations: {
              discussion: {
                reflectionHeader: "Riflessioni",
                backToPost: "Torna al post",
                pinnedMessageHeader: "Messaggio fissato",
                delete: {
                  title: "Sei sicuro/a?",
                  message: "Questa azione non può essere annullata",
                  confirmButton: "Sì, elimina",
                  cancelButton: "Annulla",
                },
              },
              tag: {
                placeholder: "Cerca tag o crea uno nuovo",
                createAction: "Crea",
              },
              message: {
                reply: "Rifletti",
                addTags: "Aggiungi tag",
                tag: "Tag",
                copyToClipboard: "Copia negli appunti",
                messageCopied: "Messaggio copiato negli appunti",
                pinMessageAction: "Fissa messaggio",
                editMessage: "Modifica messaggio",
                deleteMessage: "Elimina messaggio",
                makePrivate: "Rendi privato",
                viewReplies1: "Visualizza",
                viewReplies2single: "riflessione",
                viewReplies2plural: "riflessioni",
                privateMessage:
                  "Attualmente solo tu e gli amministratori di questo spazio potete vedere questo post. Se decidi di renderlo pubblico, tutti i membri lo vedranno in questo spazio.",
                privateMessage2:
                  "Questa persona ha scelto di non condividere la sua risposta con altri membri.",
                unreadMessagesText: "Hai messaggi non letti",
                scrollDownButton: "Scorri in basso",
                newReflections: {
                  part1: "Hai",
                  part2: "riflessioni non lette in",
                  part3: "messaggi",
                },
                viewNextButton: "Visualizza successivo",
                pinMessage: {
                  mainText: "Messaggio fissato",
                  viewButton: "Visualizza messaggio",
                },
              },
              content: {
                commentInput: {
                  toggleLabel1:
                    "I commenti sono disabilitati per i percorsi interattivi",
                  toggleLabel2: "Attiva i commenti per questo post",
                  toggleLabel3: "Attiva i commenti per questo spazio di evento",
                  toggleLabel4: "Attiva i commenti per questo spazio di contenuto",
                  placeholder1:
                    "I commenti non possono essere pubblicati durante la modifica.",
                  placeholder2: "Scrivi qualcosa...",
                },
              },
              fallback: {
                title: "Problema di connessione",
                message:
                  "Sembra che tu non abbia Internet. Quando sarai di nuovo online, ti ricollegheremo automaticamente.",
              },
              events: {
                placeholder: "Scrivi qualcosa...",
                upcomingButton: "In programma",
                pastButton: "Passati",
                addEvent: "Aggiungi evento",
                eventSettingsTooltip: "Impostazioni dell'evento",
                newEventTitle: "NUOVO EVENTO",
              },
              list: {
                placeholder: "Scrivi qualcosa...",
                createButton: "Crea post",
                listSettingsTooltip: "Impostazioni del forum",
                duplicateFormTitle: "DUPLICA SPAZIO",
              },
              settings: {
                versions: {
                  content: "Pagina di contenuto",
                  posts: "Post",
                  discussions: "Discussioni",
                  link: "Link",
                  template: "Template",
                },
                versionsDescriptions: {
                  content:
                    "I nuovi elementi verranno visualizzati come una pagina di contenuto con funzionalità complete.",
                  discussion: "Vuoto",
                  posts:
                    "I nuovi elementi verranno visualizzati come un semplice post con funzionalità limitate",
                  link: "Questa opzione ti consente di inviare un link a qualsiasi spazio su Sutra che verrà mostrato qui come un elemento.",
                  template:
                    "Crea un template di spazio che verrà duplicato ogni volta che viene creato un nuovo elemento qui.",
                },
                createNewTemplate: "Crea nuovo template",
                editTemplate: "Modifica template",
                title: "IMPOSTAZIONI",
                accordion1: {
                  label: "Visualizza come",
                  description: "Visualizza i post in modalità elenco o griglia",
                  listOption: "Elenco",
                  gridOption: "Griglia",
                  dateCreated: "Data di creazione",
                  dateCreatedReversed: "Data di creazione inversa",
                  lastActive: "Ultima attività",
                  lastEdited: "Ultima modifica",
                  alphabetic: "Ordine alfabetico",
                  reverseAlphabetic: "Alfabetico inverso",
                },
                accordion2: {
                  label: "Opzioni di visualizzazione",
                  description: "",
                  switches: {
                    onlyManagers: "Solo i manager possono creare un post",
                    showAuthor: "Mostra informazioni sull'autore",
                    showPreview: "Mostra anteprima del testo",
                    showComments: "Mostra numero di commenti",
                    showMembers: "Mostra avatar dei membri",
                    showTimestamp: "Mostra timestamp di creazione del post",
                    showThumbnail: "Mostra miniatura",
                    autoThumbnail: "Crea miniatura automaticamente",
                  },
                },
                accordion3: {
                  label: "Formato dei nuovi post",
                  description:
                    "Seleziona il formato per i nuovi post in questo spazio",
                  templateName: {
                    label: "Dai un nome al tuo template",
                    placeholder: "Nome del template",
                  },
                  templateDirectOption: "Sottospazio diretto",
                  templateLinkedOption: "Spazio collegato",
                  privacyDescription:
                    "Seleziona le configurazioni di default sulla privacy per i nuovi post",
                  privacyOpenOption: "Aperto",
                  privacyPrivateOption: "Privato",
                  privacyAnyOption: "Scelta del creatore",
                },
                accordion4: {
                  label: "Etichetta del pulsante",
                  createPost: {
                    label: "Crea post",
                    description:
                      "Specifica il testo che appare sul pulsante per creare un nuovo post",
                  },
                },
                accordion5: {
                  label: "Tag",
                },
                templateDescription:
                  "I nuovi template di spazi possono essere sottospazi diretti di questo spazio o possono essere spazi collegati. Gli spazi collegati sono spazi superiori ed indipendenti che appariranno in questa pagina. Il creatore dello spazio diventerà amministratore completo dello spazio che crea.",
              },
              thumbnail: {
                title: "AGGIUNGI MINIATURA",
                message: "La miniatura si sta caricando...",
              },
              static: {
                broadcastExclude: {
                  unsubscribe: {
                    part1: "Sei sicuro che vuoi annullare l’iscrizione a",
                    part2:
                      "Non riceverai più email dirette di invio massivo dagli amministratori di questo spazio",
                    button: "Annulla iscrizione",
                  },
                  subscribe: {
                    part1:
                      "Sei stato disiscritto e non riceverai più email di invio massivo da",
                    button: "Iscriviti",
                  },
                },
                invitation: {
                  message: "Sei stato invitato a questo spazio",
                  button: "Accetta l'invito",
                },
                pending: {
                  message: "La tua richiesta è in attesa di approvazione",
                },
                private: {
                  message: {
                    part1: "Questo è uno spazio privato.",
                    part2: "Se sei un membro, effettua l'accesso.",
                  },
                  button: "Accedi",
                },
                processing: {
                  message: "Questo spazio è ancora in fase di elaborazione...",
                },
              },
              gridPost: {
                onDelete: {
                  alertTitle: "Sei sicuro/a?",
                  alertMessage: "Questo spazio non sarà più collegato qui.",
                  alertMessage2: "Questo spazio non sarà più collegato qui.",
                  confirm: "Sì",
                  deny: "No",
                },
                actions: {
                  pin: "Fissare in alto",
                  unpin: "Non fissare",
                  addThumbnail: "Aggiungi miniatura",
                  removeThumbnail: "Rimuovi miniatura",
                  duplicate: "Duplica",
                  unlink: "Scollega",
                  delete: "Elimina",
                },
              },
              listContent: {
                publishTootip: "Sarà pubblicato in questa data",
                privateTooltip: "Questo è uno spazio privato",
              },
            },
            colors: {
              form: {
                colorTheme: "Tema di colore",
                customize: "Personalizza la selezione dei colori",
                saveTheme: "Salva tema",
                resetColor: "Ripristina colore",
                pageLayout: "Layout della pagina",
                pageFormat: "Formato della pagina",
              },
              headerAndLogo: {
                addCover: "Aggiungi immagine di copertina",
                removeCover: "Rimuovi immagine di copertina",
                changeLogo: "Cambia logo",
                removeLogo: "Rimuovi logo",
                dimensions:
                  "La dimensione consigliata per le immagini di copertina è di 1200 x 300 pixel",
                dimensions2:
                  "La dimensione consigliata per i loghi è di 360 x 120 pixel",
              },
              pageFormat: {
                description:
                  "Puoi cambiare il modo in cui i contenuti vengono presentati in questo spazio selezionando un'opzione sotto.",
                contentLabel: "Contenuto",
                discussionLabel: "Discussione",
                contentDescription:
                  "Uno spazio per moduli di apprendimento, contenuti generali o risorse della community.",
                discussionDescription: "Uno spazio di discussione o di annunci",
              },
              pageLayout: {
                widePage: "Amplia la larghezza della pagina",
                hideSidebar: "Nascondi la barra laterale",
                hideHeader: "Nascondi intestazione",
                hideCoverImage: "Nascondi immagine di copertina",
                hidePageTitle: "Nascondi titolo della pagina",
                hideBreadcrumbs: "Nascondi i breadcrumb",
              },
              header_color: {
                label: "Colore d'intestazione",
                description: "Descrizione",
              },
              header_link_color: {
                label: "Colore del logo d'intestazione",
                description: "Descrizione",
              },
              primary_button_background_color: {
                label: "Colore di sfondo pulsante primario",
                description: "Descrizione",
              },
              primary_button_text_color: {
                label: "Colore testo pulsante primario",
                description: "Descrizione",
              },
              secondary_button_background_color: {
                label: "Colore di sfondo pulsante secondario",
                description: "Descrizione",
              },
              secondary_button_text_color: {
                label: "Colore testo pulsante secondario",
                description: "Descrizione",
              },
              background_color: {
                label: "Colore di sfondo",
                description: "Descrizione",
              },
              default_text_color: {
                label: "Colore testo predefinito",
                description: "Descrizione",
              },
              sidebar_background_color: {
                label: "Colore di sfondo barra laterale",
                description: "Descrizione",
              },
              sidebar_text_color: {
                label: "Colore testo barra laterale",
                description: "Descrizione",
              },
              default_link_color: {
                label: "Colore link predefinito",
                description: "Descrizione",
              },
              default_badge_color: {
                label: "Colore badge di notifica",
                description: "Descrizione",
              },
              default_badge_text_color: {
                label: "Colore testo badge di notifica",
                description: "Descrizione",
              },
              registration_page_background_color: {
                label: "Colore di sfondo della pagina di registrazione",
                description: "Descrizione",
              },
              registration_page_default_text_color: {
                label: "Colore testo della pagina di registrazione",
                description: "Descrizione",
              },
            },
            create: {
              invalidNameErrorMsg:
                "Il nome dello spazio deve avere una lunghezza tra 3 e 100 caratteri",
            },
            dummy: {
              magicLink: {
                alertTitle: "Ci sei quasi...",
                alertMessage:
                  "Abbiamo inviato il link magico al tuo indirizzo email. QUESTO È UN TEST, NON VIENE INVIATA NESSUNA EMAIL EFFETTIVA.",
              },
            },
            pending: {
              message: "La tua richiesta è in attesa",
            },
            recover: {
              alertTitle: "Ci sei quasi...",
              alertMessage:
                "Ti abbiamo appena inviato un'email con le istruzioni per recuperare la tua password. QUESTO È UN TEST, NON VIENE INVIATA NESSUNA EMAIL EFFETTIVA.",
            },
            form: {
              calendarLinks: {
                text: "Le informazioni aggiunte di seguito verranno trasferite al calendario dei membri quando aggiungeranno l'evento.",
                link: {
                  label: "URL dell'evento (link Zoom, ecc.)",
                  placeholder: "Qual è il link della tua videochiamata",
                },
                description: {
                  label:
                    "Aggiungi una descrizione del tuo evento che verrà visualizzata nelle informazioni del calendario",
                  placeholder: "Descrizione del calendario",
                },
                eventStartDateAndTime: "Data e ora di inizio dell'evento",
                date: {
                  placeholder: "Scegli una data",
                },
                eventDateTime: "Data e ora di fine dell'evento (opzionale)",
                allowInterests:
                  "Consenti alle persone di partecipare all'evento senza registrarsi su Sutra",
                automatedEmails: "Configura email automatizzate",
                save: "Salva dettagli",
              },
              duplicate: {
                prefix: "Copia di",
                intention: {
                  label: "Nome dello spazio",
                  placeholder: "Come si chiama il tuo spazio?",
                },
                copyMembers: {
                  label: "Copia membri",
                },
                copyManagers: {
                  label: "Copia amministratori",
                },
                copyContent: {
                  label: "Copia contenuto",
                },
                copyPosts: {
                  label: "Copy posts",
                },
                copyBroadcasts: {
                  label: "Copia invio massivo",
                },
                copyAutomation: {
                  label: "Copia automazioni",
                },
                copyRegPage: {
                  label: "Copia la pagina di registrazione",
                },
                save: "Duplica spazio",
                saveTemplate: "Crea un modello"
              },
              form: {
                privacyDescription1:
                  "Lo spazio che crei sarà accessibile a tutti all'interno di",
                rootPrivacyDescription1:
                  "Lo spazio che crei sarà accessibile a chiunque abbia un link a questo spazio. Puoi impostare le opzioni di pagamento una volta che hai creato lo spazio.",
                privacyDescription2:
                  "Lo spazio che crei sarà visibile solo alle persone che vengono aggiunte.",
                rootPrivacyDescription2:
                  "Lo spazio che crei sarà visibile solo alle persone che vengono invitate. Puoi impostare le opzioni di pagamento una volta creato lo spazio.",
                placeholderDescription: "Descrizione",
                versions: {
                  content: {
                    label: "Contenuto",
                    description:
                      "Crea uno spazio per un modulo di apprendimento, contenuto generale o risorse della community.",
                  },
                  discussion: {
                    label: "Discussione",
                    description:
                      "Crea uno spazio di chat per discussioni o annunci.",
                  },
                  event: {
                    label: "Evento",
                    description: "Crea un evento singolo.",
                  },
                  events: {
                    label: "Calendario",
                    description: "Crea un elenco di eventi nel calendario.",
                  },
                  list: {
                    label: "Forum",
                    description:
                      "Crea un feed in cui le persone possono pubblicare argomenti di discussione.",
                  },
                },
                segments: {
                  space: {
                    label: "Spazio",
                    description:
                      "Crea uno spazio per il tuo corso o la tua community. Scegli questa opzione se vuoi creare un gruppo di formazione, un corso a tuo ritmo, una community o qualsiasi tipo di gruppo che si riunirà periodicamente nel tempo.",
                  },
                  event: {
                    label: "Evento",
                    description:
                      "Crea una semplice pagina di evento. Scegli questa opzione se vuoi organizzare un evento singolo che potrai condividere pubblicamente.",
                  },
                  discussion: {
                    label: "Discussione",
                    description:
                      "Crea un semplice spazio di chat per il tuo gruppo. Scegli questa opzione se vuoi creare uno spazio di discussione per un gruppo di persone.",
                  },
                },
                intention: {
                  label: "Nome dello spazio",
                  placeholder: "Come si chiama il tuo spazio?",
                },
                content: {
                  label: "Nome dello spazio",
                  description: "Scrivi qualcosa...",
                  placeholder: "Scrivi qualcosa...",
                },
                spaceFormat: {
                  label: "Formato dello spazio",
                  placeholder: "Scegli uno",
                },
                eventDescription:
                  "Le informazioni aggiunte di seguito verranno passate al calendario del membro quando aggiunge l'evento.",
                hangoutLink: {
                  label: "URL dell'evento (link Zoom, ecc.)",
                  placeholder: "Qual è il link della tua videoconferenza",
                },
                eventStartTime: "Data e ora di inizio dell'evento",
                date: {
                  placeholder: "Seleziona una data",
                },
                eventEndDate: "Data e ora di fine dell'evento (opzionale)",
                privacyLevelText: "Configurazione sulla privacy",
                privacy: {
                  open: "Aperto",
                  private: "Privato",
                },
                joinAsMember: {
                  label: "Unisciti a questo spazio come membro",
                },
                publishDate: {
                  label: "Pubblica in data",
                },
                publishDateTime: "Data e ora di pubblicazione",
                create: "Crea spazio",
              },
              interestedForm: {
                title: "Registrati a questo evento",
                createAlertTitle: "Grazie per esserti registrato/a!",
                createAlertButton: "Ok",
                name: {
                  label: "Nome",
                  placeholder: "Inserisci il tuo nome",
                },
                email: {
                  label: "Email",
                  placeholder: "Inserisci il tuo indirizzo email",
                },
                register: "Registrati",
                joinWithSutra: "Accedi con il tuo account Sutra",
              },
              linkForm: {
                linkErrMessage: "Questo non è un link valido per lo spazio",
                link: {
                  label: "",
                  placeholder: "Link allo spazio",
                  errors: {
                    space:
                      "Lo spazio che vuoi condividere è privato o non esiste!",
                    event:
                      "Lo spazio che vuoi condividere è privato, non è un evento o non esiste!",
                  },
                },
              },
              listForm: {
                intention: {
                  placeholder: "Titolo",
                },
              },
            },
            header: {
              onLeave: {
                title: "Sei sicuro/a?",
                message:
                  "Sarai rimosso come membro da questo spazio e potrebbe essere necessario che tu venga aggiunto nuovamente per ripristinare l'accesso a questo spazio.",
                confirm: "Sì, rimuovimi",
                deny: "No",
              },
              onArchive: {
                title: "Sei sicuro/a?",
                message:
                  "Questo spazio verrà archiviato e i membri non vi avranno più accesso.",
                confirm: "Sì",
                deny: "No",
              },
              onUnarchive: {
                title: "Sei sicuro/a?",
                message:
                  "L’archiviazione di questo spazio verrà annullata e ai membri verrà ridato l'accesso a questo spazio.",
                confirm: "Sì",
                deny: "No",
              },
              onDelete: {
                title: "Sei sicuro/a?",
                message:
                  "Questo spazio verrà eliminato e i membri non vi avranno più accesso.",
                confirm: "Sì",
                deny: "No",
              },
              onFix: {
                title: "Sei sicuro/a?",
                message:
                  "Sutra proverà a ricreare lo spazio di contenuto a partire dai dati delle versioni precedenti.",
                confirm: "Sì, ripara",
                deny: "No",
                confirmationTitle: "Informazioni",
                confirmationMessage: "Spazio riparato, ricaricando...",
                confirmationError: "Questo spazio non può essere riparato",
              },
              buttons: {
                uncomplete: "Non completato",
                markComplete: "Segna come completato",
                edit: "Modifica",
                post: "post",
                page: "pagina",
                space: "spazio",
                returnTo: "Torna a",
                editTitle: "Modifica titolo",
                viewPage: "Visualizza pagina",
                editDescription: "Modifica descrizione",
                appearance: "Aspetto",
                manage: "Gestisci",
                members: "Membri",
                leave: "Abbandona spazio",
                delete: "Elimina spazio",
                duplicate: "Duplica spazio",
                template: "Crea un modello",
                archive: "Archivia spazio",
                unarchive: "Annulla archiviazione",
                fix: "Ripara",
                joinEvent: "Unisciti",
                joinSpace: "Unisciti allo spazio",
                preview: "Anteprima",
                draft: "Bozza",
                published: "Pubblicato",
                publish: "Pubblica",
                returnToSettings: "Torna alle configurazioni",
                returnToSpace: "Torna allo spazio",
                undo: "Annulla",
                openFullScreen: "Schermo intero",
                copyToClipboard: "Copia negli appunti",
                copiedMessage: "Link dello spazio copiato negli appunti",
                versionHistory: "Cronologia delle versioni",
                brandingOptions: "OPZIONI DI BRANDING",
              },
              regPagePublishing:
                "La tua pagina di registrazione si sta pubblicando, per favore, attendi...",
              spaceUpdating:
                "Questo spazio si sta aggiornando, per favore, attendi...",
            },
            members: {
              sectionTitle: "Membri",
              manage: "Gestisci membri",
              invite: "Invita",
              search: {
                button: "Cerca",
                placeholder: "Cerca per",
              },
              emptyMessage: "Nessun risultato trovato",
              messageButton: "Messaggio",
              emailButton: "Email",
              copyClipboard: "copia negli appunti",
            },
            payment: {
              onCheckIntent: {
                alertTitle: "Errore di pagamento",
                alertMessage:
                  "La sessione è scaduta. Aggiorna la pagina per completare il pagamento.",
              },
              onConfirmPayment: {
                alertTitle: "Ops, qualcosa è andato storto!",
                alertMessage: "Qualcosa è andato storto!",
              },
              completePayment: "Completa il pagamento",
              join: "Unisciti allo spazio",
            },
            stripe: {
              pay: "Paga",
            },
          },
          survey: {
            form: {
              text: "Hai già risposto a questo sondaggio.",
              submitButton: "Invia",
            },
            question: {
              yourAnswer: "La tua risposta",
            },
          },
          tiptap: {
            blocks: {
              section1: "Base",
              text: "Testo",
              headline1: "Titolo 1",
              headline2: "Titolo 2",
              headline3: "Titolo 3",
              divider: "Divisore",
              infobox: "Casella delle informazioni",
              section2: "Spazi",
              content: "Spazio del contenuto",
              discussion: "Spazio delle discussioni",
              event: "Spazio degli eventi",
              events: "Spazio del calendario",
              list: "Spazio del forum",
              showcase: "Showcase Space",
              section3: "Lista",
              section8: "Special",
              bulletList: "Lista puntata",
              orderedList: "Lista ordinata",
              checklist: "Lista di controllo",
              collapsableSection: "Sezione espandibile",
              survey: "Survey or quiz",
              section4: "Media",
              image: "Immagine",
              embed: "Codice di incorporamento",
              video: "Video",
              audio: "Audio",
              file: "File",
              zoom: "Zoom recording",
              section5: "Griglie",
              grid1: "1 colonna",
              grid2: "2 colonne",
              grid3: "3 colonne",
              grid4: "Colonna sinistra",
              grid5: "Colonna destra",
              grid6: "4 columns",
              section6: "Pulsanti",
              interestForm: "Modulo di interesse",
              actionButton: "Pulsante di azione",
              markComplete: "Segna come completato",
              section7: "Interattivo",
              interactiveButton: "Pulsante interattivo",
              interactiveInput: "Input interattivo",
              interactiveWait: "Attesa interattiva"
            },
            components: {
              sidebar: {
                alignment: {
                  left: "Sinistra",
                  center: "Centro",
                  right: "Destra",
                },
                backgroundColor: {
                  text: "Seleziona colore di sfondo",
                  clearColor: "Resetta colore",
                },
                backgroundImage: {
                  button: "Seleziona immagine",
                  removeButton: "Rimuovi immagine",
                  label: "Posizione",
                  placeholder: "Scegli un'opzione",
                  leftTop: "sinistra in alto",
                  leftCenter: "sinistra al centro",
                  leftBottom: "sinistra in basso",
                  rightTop: "destra in alto",
                  rightCenter: "destra al centro",
                  rightBottom: "destra in basso",
                  centerTop: "centro in alto",
                  centerCenter: "centro centro",
                  centerBottom: "centro in basso",
                  label2: "Ripetizione",
                  placeholder2: "Scegli un'opzione",
                  noRepeat: "Nessuna ripetizione",
                  repeat: "Ripeti",
                  "repeat-x": "Ripeti X",
                  "repeat-y": "Ripeti Y",
                  space: "Space",
                  round: "Round",
                },
                blockId: {
                  placeholder: "ID blocco",
                  label: "ID blocco",
                  description:
                    "Gli identificativi consigliati per IA: breve descrizione, riguardo al workshop, cosa imparerai 1, cosa imparerai 2, cosa imparerai 3, a chi è dedicato, chi siamo, citazione",
                },
                buttonAction: {
                  label: "Azione al completamento",
                  description:
                    "Specifica cosa succede dopo che una persona ha inviato le proprie informazioni tramite il modulo selezionato.",
                  placeholder: "Scegli un'opzione",
                  label2: "Link a",
                  placeholder2: "Scegli un'opzione",
                  label3: "Azione del link",
                  description3:
                    "Specifica se il link verrà aperto nella scheda corrente o in una nuova scheda.",
                  placeholder3: "Scegli un'opzione",
                  callBackComplete: "Testo che apparirà al completamento dell'azione",
                  callbacklinkTo: "Link a",
                  completeDescription:
                    "Aggiungi un breve messaggio di conferma che le persone vedranno dopo aver inviato le proprie informazioni.",
                  linkDescription:
                    "La persona verrà reindirizzata a questo link dopo aver cliccato sul pulsante.",
                  label4: "Link alla sezione dedicata allo spazio",
                  description4: "Specifica se il collegamento sarà aperto nella scheda corrente del browser o in un'altra scheda o se il collegamento passerà a una sezione di questa pagina.",
                  selectSection: "Seleziona sezione",
                  description5: "Questa opzione ti consente di passare a una sezione specifica di questa pagina quando si fa clic sul collegamento.",
                  description6: "Per selezionare la sezione, fare clic sul pulsante in basso e quindi fare clic sulla sezione a cui si desidera collegarsi.",
                  label5: "La sezione è collegata!",
                  label6: "URL del collegamento",
                  description7: "Specificare il collegamento URL a cui verrà indirizzato l'utente",
                  label7: "Si apre nella scheda corrente",
                  label8: "Apre un'altra scheda",
                  label9: "Fare niente",
                  label10: "Reindirizzare l'utente a",
                  label11: "Seleziona un'opzione",
                  label12: "Un altro spazio",
                  label13: "Collegamento personalizzato",
                  label14: "Fare clic su una sezione della pagina",
                },
                buttonBackgroundColor: {
                  button: "Resetta colore",
                },
                buttonText: {
                  placeholder: "Testo pulsante",
                  label: "Testo pulsante",
                },
                fileName: {
                  placeholder: "Nome file",
                  label: "Nome file",
                },
                interactiveWaitTime: {
                  label1: "Seleziona un'opzione",
                  label2: "Attendi fino ad una data",
                  label3: "Attendi un numero di giorni",
                  label4: "Data per mostrare il contenuto",
                  placeholder: "Data per mostrare il contenuto",
                  label5: "1 giorno",
                  label6: "2 giorni",
                  label7: "3 giorni",
                  label8: "4 giorni",
                  label9: "5 giorni",
                  label10: "6 giorni",
                  label11: "1 settimana",
                  label12: "2 settimane",
                  label13: "1 mese",
                },
                linkAction: {
                  label: "Azione del link",
                  description:
                    "Specifica se il link verrà aperto nella scheda corrente o in una nuova scheda.",
                  placeholder: "Scegli un'opzione'",
                  label2: "Apri nella scheda corrente",
                  label3: "Apri in una nuova scheda",
                },
                margin: {
                  topMargin: "Margine superiore -",
                  text: "Margine inferiore -",
                  bottom: "Inferiore",
                  left: "Sinistra",
                  leftMargin: "Margine sinistro -",
                  right: "Destra",
                  rightMargin: "Margine destro",
                },
                padding: {
                  top: "Superiore",
                  topPadding: "Spaziatura interna superiore -",
                  bottom: "Inferiore",
                  bottomPadding: "Spaziatura interna inferiore -",
                  left: "Sinistra",
                  leftPadding: "Spaziatura interna a sinistra",
                  right: "Destra",
                  rightPadding: "Spaziatura interna a destra",
                },
                radius: {
                  square: "Quadrato",
                  round: "Arrotondato",
                  circle: "Circolare",
                },
                spaceAttrs: {
                  addCover: "Aggiungi immagine di copertina",
                  text: "La dimensione consigliata per le immagini di copertina è 1200 x 300 pixel",
                  addCover2: "Aggiungi immagine di copertina",
                  text2:
                    "La dimensione consigliata per le immagini di copertina è 1200 x 300 pixel",
                  removeCover: "Rimuovi copertina",
                },
                verticalAllignment: {
                  top: "In alto",
                  center: "Centro",
                  bottom: "In basso",
                },
                width: {
                  full: "Intera",
                  size: "3/4",
                  size2: "1/2",
                  actual: "Effettiva",
                },
              },
              blockMenuActions: {
                label1: "Aggiungi blocco",
                label2: "Aggiungi emoji",
                label3: "Configurazioni",
                label4: "Elimina",
                label5: "Sposta",
                label6: "Vai allo spazio o fai doppio clic sul blocco",
                label7: "Sostituisci",
                label8: "Allineamento",
                left: "Sinistra",
                center: "Centro",
                right: "Destra",
                write: "Scrivi qualcosa...",
                label9: "Aggiungi blocco",
                label10: "Intestazione 1",
                h1: "H1",
                label11: "Intestazione 2",
                h2: "H2",
                label12: "Aggiungi spazio",
                label13: "Aggiungi immagine",
                label14: "Aggiungi video",
                label15: "Aggiungi griglia",
                label16: "Aggiungi template",
                label17: "Aggiungi layout",
                label18: "Duplicare",
              },
              blocksMenu: {
                basicBlock: "Blocchi base",
                mediaBlock: "Blocchi Media",
                contentBlock: "Blocchi contenuto",
              },
              blocksMenuInline: {
                file: "File",
                image: "Immagine",
                video: "Video",
                label1: "Aggiungi emoji",
                label2: "Aggiungi immagine",
                label3: "Attiva/disattiva barra di strumenti",
              },
              blocksMenuSimple: {
                label1: "Aggiungi",
                file: "File",
                image: "Immagine",
                video: "Video",
                audio: "Audio",
                label2: "Aggiungi emoji",
                label3: "Aggiungi immagine",
                label4: "Mostra formattazione",
                cancelButton: "Annulla",
              },
              emptyMessage: {
                text: "Questo è uno spazio di contenuto vuoto. Puoi",
                button1: "modificare questa pagina",
                text2: "o",
                button2: "usare un template",
                text3: "per iniziare",
                text4: "Uno spazio vuoto con enorme potenziale...",
              },
              fixedBottomMenu: {
                label1: "Aggiungi elementi come titoli, immagini e video",
                addBlock: "Aggiungi blocco",
                label2:
                  "Aggiungi uno spazio di contenuto, discussione o evento",
                space: "Spazio",
                label3:
                  "Aggiungi una griglia per utilizzare più colonne per il contenuto",
                grid: "Griglia",
                label4: "Aggiungi un'opzione di design pre-creata",
                layouts: "Layout",
                label5:
                  "Aggiungi un modello per configurare automaticamente il tuo spazio",
                template: "Template",
              },
              formatMenu: {
                text: "Testo",
                smallText: "Piccolo testo",
                largeText: "Grande testo",
                heading1: "Intestazione 1",
                heading2: "Intestazione 2",
                heading3: "Intestazione 3",
                bulletedList: "Elenco puntato",
                orderedList: "Elenco numerato",
                text2: "Testo",
                label1: "Formato",
                text3: "Testo",
                numberedList: "Elenco numerato",
                label2: "Allineamento",
                left: "Sinistra",
                center: "Centro",
                right: "Destra",
                label3: "Azione del link",
                description:
                  "Specifica se il link verrà aperto nella scheda corrente o in una nuova scheda.",
                placeholder: "Scegli un'opzione",
                label4: "Si apre nella scheda corrente",
                label5: "Si apre in una nuova scheda",
                label6: "Formato del link",
                description2:
                  "Specifica se il link verrà visualizzato come testo o come un link decorativo.",
                placeholder2: "Scegli un'opzione",
                label7: "Mostra come testo",
                label8: "Mostra come link stilizzato",
                save: "Salva",
                delete: "Elimina",
                label9: "Barrato",
                label10: "Sottolineato",
                label11: "Blocco codice",
                label12: "Colore del testo",
                placeholder3: "Valore HEX",
                unsetColor: "Rimuovi colore",
                label13: "Colore evidenziazione",
                label14: "Link alla sezione dedicata allo spazio",
                description3: "Specifica se il collegamento sarà aperto nella scheda corrente del browser o in un'altra scheda o se il collegamento passerà a una sezione di questa pagina.",
                selectSection: "Seleziona sezione",
                description4: "Questa opzione ti consente di passare a una sezione specifica di questa pagina quando si fa clic sul collegamento.",
                description5: "Per selezionare la sezione, fare clic sul pulsante in basso e quindi fare clic sulla sezione a cui si desidera collegarsi.",
                label15: "La sezione è collegata!",
                label16: "URL del collegamento",
                description6: "Specificare il collegamento URL a cui verrà indirizzato l'utente",
              },
              formatMenuSimple: {
                t: "T",
                h1: "H1",
                h2: "H2",
                h3: "H3",
                label1: "Azione del link",
                description1:
                  "Specifica se il link verrà aperto nella scheda corrente o in una nuova scheda.",
                placeholder1: "Scegli un'opzione",
                label2: "Si apre nella scheda corrente",
                label3: "Si apre in una nuova scheda",
                label4: "Formato del link",
                description2:
                  "Specifica se il link verrà visualizzato come testo o come un link stilizzato.",
                placeholder2: "Scegli un'opzione",
                label5: "Mostra come testo",
                label6: "Mostra come link stilizzato",
                save: "Salva",
                delete: "Elimina",
                label7: "Formato",
                left: "Sinistra",
                center: "Centro",
                right: "Destra",
                placeholder3: "Valore HEX",
                placeholder4: "Colore HEX",
                unsetColor: "Rimuovi colore",
              },
              inlineMenu: {
                h1: "H1",
                h2: "H2",
                bulletList: "Elenco puntato",
                checkList: "Checklist",
                circle: "Cerchio",
                moreOption: "Altre opzioni",
              },
              interactiveInput: {
                hideResponses: "Nascondi altre risposte",
                showResponses: "Mostra altre risposte",
                hideResponses2: "Nascondi risposte",
                hidePrivateMessages: "Nascondi messaggi privati",
                showPrivateMessages: "Mostra messaggi privati",
                showResponses2: "Mostra risposte",
                loadMoreResponse: "Carica altre risposte",
                placeholder: "Scrivi qualcosa...",
                text: "Vuoi che questa risposta sia visibile agli altri membri?",
              },
              newMedia: {
                add: "Aggiungi",
              },
              regPageColor: {
                clearColor: "Rimuovi colore",
              },
              regPageColors: {
                text: "Aspetto della pagina di registrazione",
                applyAppearance: "Applica aspetto",
                title1: "Seleziona colore di sfondo",
                title2: "Seleziona colore del testo",
                label1: "Colore di sfondo",
                label2: "Colore predefinito del testo ",
                label3: "Amplia larghezza della pagina",
              },
              resource: {
                areYouSure: "Sei sicuro/a?",
                label1: "Si pubblicherà in questa data",
                edit: "Modifica",
                addPage: "Aggiungi pagina",
              },
              sideBlocksMenuBak: {
                addBlock: "Aggiungi blocco",
                back: "Indietro",
              },
              sideBlocksMenu: {
                text: "I layout sono opzioni di design già pronte da aggiungere al tuo spazio.",
                text2:
                  "Inizia con una selezione di template a pagina intera personalizzabili",
                layout: "Layout",
                template: "Template",
                addBlock: "AGGIUNGI BLOCCO",
                block: "Blocchi",
              },
              sideConfigurationsMenu: {
                title: "IMPOSTAZIONI BLOCCO",
                label1: "Nome spazio e copertina",
                label2: "Attributi del file",
                label3: "Opzioni di input interattivo",
                attrPlaceholder1: "Scrivi qualcosa...",
                attrLabel: "Segnaposto",
                attrLabel2:
                  "Permettere al membro di vedere tutte le risposte",
                attrLabel3:
                  "Mostrare le risposte prima che il membro risponda",
                attrLabel4:
                  "Permettere al membro di scegliere se la sua risposta sarà visibile agli altri membri",
                attrLabel5: "Permettere risposte multiple per persona",
                attrLabel6:
                  "Permettere al membro di saltare questo prompt",
                label4: "Opzioni di input interattivo",
                label5: "Spaziatura interna",
                label6: "BLOCCO",
                label7: "COLONNA",
                label8: "Margine",
                label9: "GRIGLIA",
                label10: "Allineamento verticale",
                label11: "Opzioni del pulsante",
                label12: "Colore di sfondo del pulsante",
                label13: "Colore testo del pulsante",
                text: "Angoli del pulsante",
                label14: "Sfondo",
                label15: "Colore",
                label16: "Immagine",
                label17: "Ripristina",
                resetBlock: "Ripristina blocco",
                label18: "Allineamento",
                label19: "Raggio bordo",
                label20: "angoli",
                label21: "Nome blocco",
                label22: "Opzioni di notifica e-mail",
                label23: "Invia e-mail di notifica",
                attrLabel8: "Includi messaggio personalizzato",
                attrLabel9: "Includi contenuto sezione successiva",
                attrLabel10: "Includi contenuto completo",
                attrLabel11: "Anteprima breve",
                attrLabel12: "Oggetto",
                attrLabel13: "Messaggio",
                defaultWaitLabel: "La prossima sezione è stata rilasciata in {{space_name}}",
                defaultWaitMessage: "Ciao {{first_name}}, volevo solo farti sapere che è stata rilasciata la prossima sezione in {{space_name}}. {{space_link_button}}"
              },
              takeSurvey: {
                text: "Questo sondaggio è vuoto",
              },
              tiptapAi: {
                placeholder: "Scrivi una breve descrizione",
                label:
                  "Per favore, scrivi 2 o 3 frasi per descrivere la tua offerta",
                placeholder2: "ad esempio, coach, consulenti ed altri professionisti di terapia olistica.",
                label2: "Per chi è questa offerta?",
                text: "Ciao, stiamo sperimentando la nuova funzionalità di Intelligenza Artificiale. Vuoi che Sutra utilizzi l'IA per generare automaticamente il testo del tuo template?",
              },
              uploader: {
                label1: "Link",
                label2: "Codice",
                placeholder: "Incolla il link dell'immagine o del file",
                label3: "Incorpora link",
                placeholder2:
                  "Incolla il codice da incorporare -> <iframe>...</iframe>",
                label4: "Incorpora codice",
                saveEmbed: "Salva incorporamento",
                placeholder3: "Cerca immagini",
                saveImage: "Salva immagine",
              },
              viewPage: {
                text: "Questa pagina è vuota",
              },
            },
          },
          users: {
            payment: {
              completePayment: "Completa il pagamento",
              join: "Unisciti allo spazio",
              pay: "Pagare",
            },
            stepper: {
              step1: "Passaggio 1",
              step1Description: "Accedi",
              step2: "Passaggio 2",
              step2Description: "Completa il pagamento",
              step3: "Passaggio 3",
              step3Description: "Ottieni l'accesso",
            },
            start: {
              title: "Benvenuto/a",
              subtitle: "Inserisci il tuo indirizzo email",
              email: "Email",
              submit: "Continua",
              invalidEmailErrorMsg: "Indirizzo email non valido",
            },
            signIn: {
              title: "Accedi al tuo account",
              subtitle: "Non hai un account?",
              subtitleLink: "Registrati",
              email: "Email",
              password: "Password",
              submit: "Accedi",
              magicLink: "Richiedi un link magico",
              forgotPassword: "Password dimenticata?",
              invalidEmailErrorMsg: "Indirizzo email non valido",
            },
            signUp: {
              invalidEmailErrorMsg: "Indirizzo email non valido",
              invalidPasswordErrorMsg:
                "La password deve contenere almeno 8 caratteri",
              invalidFirstNameErrorMsg:
                "Il nome deve contenere almeno 3 caratteri",
              title: "Crea un nuovo account",
              subtitle: "Hai già un account?",
              subtitleLink: "Accedi",
              firstName: "Nome",
              lastName: "Cognome",
              username: "Nome utente",
              email: "Email",
              password: "Password",
              emailNewsletterConfirmation:
                "Iscriviti alla newsletter di Sutra per scoprire i nostri webinar gratuiti sui processi di gruppo, insegnamento trasformativo, e business basato sulle relazioni.",
              submit: "Registrati",
              legalPrefix: "Iscrivendoti a Sutra accetti i nostri",
              terms: "Termini di servizio",
              privacy: "Politica sulla Privacy",
            },
            spaceReference: {
              total: "Totale oggi",
            },
            planReference: {
              total: "Totale",
              total_today: "Totale oggi",
              due_in: "Dovuto in 14 giorni",
              paymentDetails: {
                annual: "Abbonamento annuale di",
                month: "Abbonamento mensile di",
              },
            },
            resetPassword: {
              title: "Reimposta la tua password",
              subtitle:
                "Inserisci una nuova password per il tuo account Sutra qui di seguito.",
              password: "Password",
              passwordConfirmation: "Conferma la password",
              submit: "Reimposta password",
            },
            recover: {
              title: "Hai dimenticato la password?",
              subtitle:
                "Inserisci il tuo indirizzo email e ti invieremo le istruzioni su come recuperare la tua password.",
              email: "Email",
              submit: "Inviami le istruzioni",
              footer: "Hai già un account?",
              footerLink: "Accedi",
            },
            magicLink: {
              invalidEmailErrorMsg: "Email non valido",
              title: "Richiedi un link magico",
              subtitle:
                "Inserisci il tuo indirizzo email e ti invieremo un link che ti permetterà di accedere automaticamente.",
              email: "Email",
              submit: "Continua",
              footer: "Accedi con email",
              alertTitle: "Ci sei quasi...",
              alertMessage:
                "Abbiamo inviato un link di accesso diretto alla tua email. QUESTO È UN TEST, NON È STATA INVIATA NESSUNA EMAIL EFFETTIVA.",
            },
            edit: {
              title: "Completa il tuo profilo",
              addProfileImage: "Carica la foto del profilo",
              removeProfileImage: "Rimuovi la foto del profilo",
              bioPlaceholder: "Racconta qualcosa su di te...",
              bio: "Presentazione",
              submit: "Salva",
              skip: "Salta e fai questo in seguito",
            },
            magicSignin: {
              text: "Accesso in corso...",
            },
            profile: {
              button: "Messaggio",
              button2: "Modifica profilo",
              title: "Spazi",
            },
            profileModal: {
              profile: "Profilo",
              message: "Messaggio",
              editProfile: "Modifica profilo",
              openProfile: "Visualizza profilo",
              spaces: "Spazi",
              selectImage: "Seleziona immagine",
            },
            signOut: {
              loggedOUt: "Disconnesso",
              title: "Ti sei disconnesso",
              message: "A presto!",
            },
          },
          errors: {
            basic: "Ops, qualcosa è andato storto!",
          },
        },
      },
      fr: {
        translation: {
          premiumFeatures: {
            personal: {
              description1:
                "Des espaces et des membres illimités pour organiser des contenus et des conversations pour toutes vos offres",
              description2:
                "De belles pages d'inscription et de destination pour tous vos programmes et événements au même endroit",
              description3:
                "Envoyez des newsletters par e-mail à tous vos participants avec notre puissante fonction d'envoi en masse",
            },
            professional: {
              description:
                "Aucun frais de transaction sur les paiements collectés",
              description2:
                "Options de personnalisation de la marque et du design, et sous-domaines URL personnalisés",
              description3:
                "Automatisations illimitées et expériences d'apprentissage interactives et conversationnelles",
              description4: "Jusqu'à 2 responsables pour tous vos espaces",
            },
            organization: {
              description:
                "Aucun frais de transaction sur les paiements collectés",
              description2: "Assistance client avancée",
              description3: "Gestionnaires illimités",
              description4: "Option d'intégration Single Sign On",
            },
          },
          premiumMessages: {
            can_auto_tag: {
              title: "Upgrade to use this feature",
              message: "Auto tagging with AI is a premium feature available on the Professional plan."
            },
            can_use_zoom_recordings: {
              title: "Upgrade to use this feature",
              message: "Zoom recordings is a premium feature available on the Organization plan."
            },
            can_create_quizzes: {
              title: "Upgrade to use this feature",
              message: "Quizzes are a premium feature available on the Organization plan."
            },
            can_duplicate_spaces: {
              title: "Upgrade to use this feature",
              message:
                "Duplicating a space is a premium feature available on the Professional plan.",
            },
            can_save_templates: {
              title: "Upgrade to use this feature",
              message:
                "Saving as space as a reusable template is a premium feature available on the Professional plan.",
            },
            can_edit_shareable_link: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "Les liens partageables personnalisés sont une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_upload_cover: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "Le téléchargement d'une image de couverture personnalisée est une fonctionnalité premium disponible dans le plan Personnel.",
            },

            can_upload_logo: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La définition d'un logo personnalisé est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_set_layout: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La personnalisation de la mise en page de votre espace est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_set_theme: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La personnalisation du thème couleur de votre espace est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_set_format: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La modification du format de votre espace est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_add_step_send_to_pending: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La définition des nouveaux membres comme étant en attente est une fonctionnalité premium disponible dans le plan Personnel.",
            },

            can_add_step_send_email: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "L'envoi d'e-mails automatisés est une fonctionnalité premium disponible dans le plan Personnel.",
            },

            can_add_add_to_existing_space: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "L'ajout automatique de membres à des espaces spécifiques est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_add_step_wait: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "L'ajout d'une condition d'attente pour des étapes supplémentaires est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_add_step_webhook: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "L'ajout de webhooks est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_add_step_survey_person: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La réalisation de sondages auprès des nouveaux membres est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_schedule_states: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "Les options de planification automatisée pour votre espace sont une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_broadcast: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "L'envoi de diffusions par e-mail est une fonctionnalité premium disponible dans le plan Personnel.",
            },

            can_survey: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La création de sondages est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_use_interest_form_block: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "L'ajout de formulaires d'intérêt est une fonctionnalité premium disponible dans le plan Professionnel.",
            },
            can_use_interactive_block: {
              title: "Mettez à niveau pour utiliser cette fonction",
              message:
                "L'ajout de blocs interactifs est une fonctionnalité premium disponible dans le plan professionnel.",
            },

            can_set_flexible_payments: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La définition d'options de paiement flexibles est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_activate_completions: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La possibilité de marquer des espaces comme étant terminés est une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            max_allowed_spaces: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "Salut ! Il semble que vous ayez utilisé tous vos espaces gratuits. La mise à niveau vers le plan Personnel vous offre des espaces et des membres illimités pour que vous puissiez créer pratiquement tout.",
            },
            max_allowed_ij: {
              title: "Upgrade to use this feature",
              message:
                "You can only add up to 5 discussion prompts per space on the free plan. Please upgrade to the Professional plan to add unlimited discussion prompts to any space.",
            },

            max_allowed_managers_pro: {
              title: "Mettez à niveau pour ajouter plus de responsables",
              message:
                "Mettez à niveau vers le plan Professionnel pour ajouter jusqu'à 2 responsables à votre espace ou vers le plan Organisation pour des responsables illimités.",
            },

            max_allowed_managers_org: {
              title: "Mettez à niveau pour ajouter plus de responsables",
              message:
                "Mettez à niveau vers le plan Organisation pour ajouter des responsables illimités à votre espace.",
            },

            can_see_participation_tab: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "Les statistiques de participation sont une fonctionnalité premium disponible dans le plan Professionnel.",
            },

            can_send_selective_broadcast: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La diffusion sélective est une fonctionnalité premium disponible dans le plan Organisation.",
            },

            can_send_message_broadcast: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "La diffusion de messages est une fonctionnalité premium disponible dans le plan Organisation.",
            },
            can_add_from_children: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "L'ajout automatique de membres à partir des sous-espaces est une fonctionnalité premium disponible dans le plan Professionnel.",
            },
            can_add_to_direct_open_children: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "L'ajout automatique des nouveaux membres à tous les sous-espaces directs ouverts est une fonctionnalité premium disponible dans le plan Professionnel.",
            },
            can_send_message: {
              title: "Mettez à niveau pour utiliser cette fonctionnalité",
              message:
                "L'envoi automatique de messages directs aux nouveaux membres est une fonctionnalité premium disponible dans le plan Professionnel.",
            },
          },
          home: {
            sidebar: {
              createButton: "Nouvel espace",
              spaces: "Vos espaces",
              invitations: "Invitations",
              directMessages: "Messages directs",
              gettingStarted: "Obtenir commencé",
              scheduleDemo: "Planifier une démo",
              upgrade: "Mettre à niveau votre plan",
              promoBoxTitle:
                "Rejoignez des centaines de créateurs utilisant les fonctionnalités avancées de Sutra pour offrir de puissantes expériences de groupe en ligne",
              promoBoxItem1: "Espaces illimités",
              promoBoxItem2: "Options de personnalisation complètes",
              promoBoxItem3: "Gestionnaires supplémentaires",
              promoBoxButton: "Mettre à niveau maintenant",
            },
            spacesList: {
              yourSpaces: "Vos espaces",
              directMessagesTitle: "Messages directs",
              invitationsTitle: "Invitations",
              topTitle: "Espaces de niveau supérieur",
              managedTitle: "Espaces gérés",
              allTitle: "Tous les espaces",
              archivedTitle: "Espaces archivés",
              deletedTitle: "Espaces supprimés",
              pendingTitle: "Espaces programmés",
              directMessages: "Direct",
              top: "Niveau supérieur",
              managed: "Géré",
              all: "Tous",
              archived: "Archivé",
              invitations: "Invitations",
              deleted: "Supprimé",
              pending: "Programmé",
              empty: "Vous n'avez rien",
              tabTitle: "Sutra - Tableau de bord",
              learn: "Apprenez les bases",
              createFirst: "Créez votre premier espace",
            },
          },
          app: {
            payment: {
              payment: {
                tabTitle: "Sutra - Formulaire de paiement",
                title: "Paiement complet",
              },
              planOption: {
                frequencyDescription1: "par mois",
                frequencyDescription2: "par mois avec facturation annuelle",
                currentPlan: "Plan actuel",
                billedMonthly: "Facturé mensuellement",
                billedAnnually: "Facturé annuellement",
              },
              stripeForm: {
                due_in_trial: "Dû dans 14 jours",
                pay: "Payer",
              },
            },
            gettingStarted: {
              items: {
                setup: "Configuration de votre espace de programme",
                privacy: "Paramètres de confidentialité et de paiement",
                registration: "Création d'une page d'inscription",
                invitations: "Invitation des participants",
                settings: "Fonctionnement des paramètres d'espace",
                interactive: "Parcours interactifs",
                interest: "Formulaires d'intérêt",
                appearance: "Mise en page et thèmes de couleur",
                grids: "Paramètres de grille et de blocs",
                broadcasts: "Envoi de diffusions par e-mail",
                tagging: "Balises",
                emojis: "Emojis de la barre latérale",
              },

              templates: {
                groupprogram: {
                  label: "Programme de groupe",
                  description:
                    "Un espace de programme où vous pouvez organiser tout ce dont vous avez besoin pour un groupe de personnes qui apprennent ensemble.",
                },
                zoomworkshop: {
                  label: "Atelier Zoom",
                  description:
                    "Organisez tout ce dont vous avez besoin pour un atelier simple basé sur Zoom.",
                },
                mastermind: {
                  label: "Mastermind",
                  description:
                    "Invitez un groupe de personnes à participer à un processus de mastermind ensemble.",
                },
                selfpacedcourse: {
                  label: "Cours à votre rythme",
                  description:
                    "Un modèle flexible pour créer un cours à votre rythme.",
                },
                communityspace: {
                  label: "Espace communautaire",
                  description:
                    "Réunissez les gens dans un espace communautaire en ligne dynamique qui grandit à mesure que votre communauté se développe.",
                },
                oneononecoaching: {
                  label: "Coaching individuel",
                  description:
                    "Créez un espace dédié pour chacun de vos clients en coaching.",
                },
              },
              loading: {
                text: "Votre modèle se charge, veuillez patienter...",
              },
              tabTitle: "Sutra - Démarrage",
              mainTitle: "Guide de démarrage",
              selectTemplate: "Sélectionner ce modèle",
              advanceFeatures: "Fonctionnalités avancées",
              welcome: "Bienvenue",
              description1:
                "Sutra facilite l'organisation de contenu et de conversations pour les cours, les communautés, le coaching individuel et bien plus encore. Apprenez les bases dans cette vidéo de cinq minutes.",
              watchVideo: "Regarder la vidéo",
              createFirstSpace: "Créer votre premier espace",
              gettingStartedGuide: "Guide de démarrage",
              description2:
                "Découvrez comment Sutra fonctionne et commencez avec une liste de lancement et des conseils plus détaillés.",
              checkOutTheGuide: "Consulter le guide",
              startWithATemplate: "Commencer avec un modèle",
              description3:
                "Découvrez comment Sutra fonctionne et commencez avec une liste de lancement et des conseils plus détaillés.",
              learnAbout: "Découvrez les fonctionnalités avancées",
              description4:
                "Sutra est conçu pour être flexible et puissant afin de répondre à presque tous les cas d'utilisation. Vous pouvez créer des expériences interactives uniques, capturer l'intérêt pour vos différentes offres, envoyer des newsletters par e-mail et recueillir des connaissances collectives.",
              learnmore: "En savoir plus",
            },
            premium: {
              try: "Essayez Sutra",
              include: "Les fonctionnalités incluent",
              upgrade: "Mettre à niveau maintenant",
            },
            pricing: {
              tabTitle: "Sutra - Tarification",
              upgrade: "Mettez à niveau votre plan",
              text: "Quelles que soient vos besoins pour votre cours ou votre communauté, nous sommes là pour soutenir votre travail. N'hésitez pas à nous contacter si vous avez des questions à support@sutra.co.",
            },
            scheduleDemo: {
              tabTitle: "Sutra - Planifier une démonstration",
            },
            welcome: {
              tabTitle: "Sutra - Bienvenue",
              title: "Bienvenue sur Sutra !",
              description1:
                "Nous sommes ravis de vous accueillir ici. Prenez un moment pour regarder la vidéo ci-dessous...",
              description2:
                "Aimeriez-vous essayer l'un de nos plans payants gratuitement ?",
              description3:
                "Sélectionnez un plan ci-dessous pour commencer un essai gratuit de 14 jours. Une fois l'essai terminé, vous serez automatiquement facturé pour le plan que vous avez sélectionné.",
              yes: "Oui, montrez-moi les options",
              no: "Non, merci",
            },
          },
          layout: {
            userButton: {
              home: "Accueil",
              viewProfile: "Voir le profil",
              settings: "Paramètres",
              support: "Support",
              language: "Langue",
              terms: "Conditions et confidentialité",
              logout: "Déconnexion",
              copyright: "Sutra Spaces, LLC © 2022",
            },
            directMessages: {
              label: "Messages directs",
              actionButton: "Voir tous",
              empty: "Vous n'avez aucun message direct",
              directMessage: "Messages directs",
            },
            notifications: {
              label: "Notifications",
              actionButton: "Tout effacer",
              empty: "Vous n'avez aucune notification",
              notification: "Notifications",
            },
            headerContent: {
              registrationPage: "Page d'inscription",
              nonManagersHidden: "En-tête masqué pour les non-gestionnaires",
            },
            search: {
              reflection: "Réflexion",
              comment: "Commentaire",
              allSpaces: "Tous les espaces",
              withinTop: "Dans l'espace de niveau supérieur",
              withinSpace: "Dans cet espace",
              everything: "Tout",
              titlesOnly: "Titres uniquement",
              tagsOnly: "Balises uniquement",
              content: "Contenu",
              discussion: "Discussion",
              events: "Événements",
              users: "Utilisateurs",
              placeholder: "Recherche...",
              nothingFound: "Rien trouvé",
              search: "Rechercher",
            },
            uploader: {
              pending: "En attente",
              used: "Utilisé",
              uploading: "Téléchargement en cours",
              finishing: "Finition",
              processing: "en cours",
              completed: "complété",
            },
          },
          resources: {
            pages: {
              form: {
                label: "Titre",
                placeholder: "Quel est le sujet de votre page ?",
                create: "Créer une page",
                save: "Enregistrer la page",
              },
            },
            form: {
              title: {
                label: "Titre",
                placeholder: "Quel est votre sujet ?",
              },
              publish: {
                label: "Publier à la date",
              },
              date: {
                label: "Sélectionner une date",
              },
              update: "Mettre à jour la collection de pages",
              create: "Créer une collection de pages",
            },
          },
          settings: {
            editProfile: {
              tabTitle: "Sutra - Modifier le profil",
              editProfile: "Modifier le profil",
              firstName: "Prénom",
              lastName: "Nom de famille",
              username: "Nom d'utilisateur",
              email: "Email",
              removeProfileImage: "Supprimer l'image de profil",
              addProfileImage: "Ajouter une image de profil",
              about: "À propos de",
              location: "Emplacement",
              bio: "Bio",
              bioPlaceholder: "Parlez un peu de vous...",
              updateProfile: "Mettre à jour le profil",
            },
            accountSettings: {
              accountSettings: "Paramètres du compte",
              changePassword: "Changer le mot de passe",
              currentPassword: "Mot de passe actuel",
              newPassword: "Nouveau mot de passe",
              newPasswordConfirmation: "Confirmation du nouveau mot de passe",
              updatePassword: "Mettre à jour le mot de passe",
              advanced: "Avancé",
              deleteAccount: "Supprimer le compte",
              warning: "Avertissement",
              deleteAccountWarning:
                "La suppression de votre compte est irréversible et supprimera définitivement vos informations de compte de notre système.",
              closeYourAccount: "Fermer votre compte",
              invalidCurrentPasswordErrorMsg: "Ne peut pas être vide",
              invalidPasswordErrorMsg:
                "Le mot de passe doit contenir au moins 8 caractères",
              invalidPasswordConfirmationErrorMsg:
                "Les mots de passe ne correspondent pas",
              deleteAccountAlert: {
                title: "Important",
                message:
                  "La suppression de votre compte est irréversible et supprimera définitivement vos informations de compte de notre système",
                input:
                  "Veuillez saisir 'supprimer mon compte' pour confirmer cette action",
                confirmButton: "Supprimer définitivement",
                cancelButton: "Annuler",
              },
              cancelSubscriptionAlert: {
                title: "Important",
                message: "Veuillez d'abord annuler votre abonnement",
                confirmButton: "Aller à la facturation",
                cancelButton: "Je le ferai plus tard",
              },
              stripeAccount: {
                title: "Réinitialiser la connexion Stripe",
                alertTitle: "Êtes-vous sûr ?",
                alertMessage:
                  "Si vous réinitialisez votre connexion Stripe, vos identifiants Stripe seront supprimés de votre compte utilisateur sur Sutra et vous pourrez connecter un nouveau compte Stripe.",
                buttonText: "Réinitialiser la connexion",
                confirmButton: "Oui",
                cancelButton: "Non",
                confirmTitle: "Êtes-vous sûr ?",
                confirmMessage:
                  "Vos identifiants Stripe seront supprimés de votre compte utilisateur sur Sutra",
              },
            },
            emailPreferences: {
              tabTitle: "Sutra - Préférences de messagerie",
              emailPreferences: "Préférences de messagerie",
              emailDigestPreferences: "Préférences du résumé par e-mail",
              emailDigestFrequency: "Fréquence du résumé par e-mail",
              emailDigestMessage:
                "Pour ajuster les préférences de messagerie par espace, veuillez consulter la zone des paramètres d'abonnement. Vous pouvez vous désabonner complètement d'un espace en le quittant, ou vous pouvez définir chaque espace individuel sur 'Muet' dans la zone des paramètres d'abonnement.",
              receiveWeeklyDigest:
                "Recevoir un résumé hebdomadaire des nouveaux éléments marqués et des discussions",
              receiveAtMentions:
                "Recevoir une notification lorsque quelqu'un vous mentionne avec @",
            },
            subscriptions: {
              frequencyOptions: "Options de fréquence",
              otherActions: "Autres actions",
              subscriptions: "Préférences spécifiques à l'espace",
              searchBySpaceName: "Rechercher par nom d'espace",
              search: "Rechercher",
              clear: "Effacer",
              options: "Options",
              otherActions: "Autres actions",
              emptySubscriptions: "Vous n'avez aucune souscription.",
              leaveSpaceConfirmationTitle:
                "Êtes-vous sûr de vouloir quitter cet espace ?",
              leaveSpaceConfirmationHtml:
                "Vous ne serez plus membre de cet espace et il se peut que vous ne puissiez pas le rejoindre sans demander à l'administrateur.",
              leaveSpaceButtonText: "Oui, quitter",
              leaveSpaceDenyButtonText: "Non",
              leaveSpace: "Quitter l'espace",
              unsubscribe: "Se désabonner",
              unsubscribed: "Désabonné",
            },
            digestPreferences: {
              digestedLabel:
                "Groupe toutes les mises à jour dans un seul résumé",
              digestedDescription:
                "Les nouveaux messages de tous vos espaces seront regroupés dans une seule mise à jour par e-mail.",
              undigestedLabel: "Groupe les mises à jour par espace",
              undigestedDescription:
                "Vous recevrez des résumés d'e-mails distincts pour chaque espace contenant de nouveaux messages.",
            },
            billing: {
              statuses: {
                pending: "En attente",
                processing: "En cours de traitement",
                paid: "Payé",
              },
              paymentMethod: {
                currentPaymentMethod: "Méthode de paiement actuelle",
                noCurrentPaymentMethod: "Aucune méthode de paiement actuelle",
                addNewPaymentMethod: "Ajouter une nouvelle méthode de paiement",
              },
              stripe: {
                updatePaymentMethod: "Mettre à jour la méthode de paiement",
              },
              tabTitle: "Sutra - Facturation",
              updatePaymentMethodMayus: "METTRE À JOUR LA MÉTHODE DE PAIEMENT",
              payments: "PAIEMENTS",
              pdf: "PDF",
              noPayments: "Vous n'avez aucun historique de paiement",
              billing: "Facturation",
              upgradePlan: "Passer à un plan supérieur",
              changePlan: "Changer de plan",
              updatePaymentMethod: "Mettre à jour la méthode de paiement",
              cancelSubscription: "Annuler l'abonnement",
              cancelConfirmationTitle:
                "Êtes-vous sûr de vouloir annuler votre abonnement ?",
              cancelConfirmationHtml:
                "Vous n'aurez plus accès aux fonctionnalités premium de votre plan.",
              cancelConfirmButtonText: "Oui, annuler",
              cancelDenyButtonText: "Non",
              billingHistory: "Historique de facturation",
              tableDate: "Date",
              tableAmount: "Montant",
              tableStatus: "Statut",
              tableInvoice: "Facture",
              planPrefix: "Vous êtes actuellement sur le",
              planSuffix: "plan",
              updatePayment: {
                success: {
                  title: "Succès",
                  message: "Votre méthode de paiement a été mise à jour",
                },
                error: {
                  title: "Oops, quelque chose s'est mal passé !",
                },
              },
            },
            frequencyOptions: {
              mute: "Muet",
              always: "À chaque message",
              hour1: "Toutes les heures",
              hour2: "Toutes les 2 heures",
              hour4: "Toutes les 4 heures",
              hour8: "Toutes les 8 heures",
              hour24: "Une fois par jour",
              hour168: "Une fois par semaine",
              hour336: "Toutes les deux semaines",
            },
            affiliates: {
              history: {
                empty: "Vous n'avez aucun historique de paiement d'affilié",
                table: {
                  referralName: "Nom du parrainage",
                  startAt: "Commencer à",
                  endAt: "Fin",
                  createdAt: "Créé le",
                  percent: "Pourcentage",
                  total: "Total gagné",
                  status: "Statut",
                  value: "Valeur",
                  revenue: "Revenu",
                  payAt: "Payer le",
                  status: "Statut",
                },
                statuses: {
                  null: "En attente",
                  pending: "En attente",
                  processing: "En cours de traitement",
                  paid: "Payé",
                },
                paymentsHistory: "Historique de paiement",
              },
              tabTitle: "Sutra - Affiliés",
              title: "Affiliés",
              affiliateLink: "Voici votre lien d'affilié",
              copyMessage: "Lien copié dans le presse-papiers",
              stripeTitle: "Stripe connecté à",
              stripeConnected:
                "Il s'agit de l'identifiant de votre compte Stripe. Pour trouver cet identifiant sur Stripe, veuillez cliquer sur le lien pour vous connecter à Stripe et faire défiler jusqu'au bas de la page des paramètres de votre compte.",
              stripeDisconnect: "Déconnecter",
              description:
                "Pour traiter les paiements du programme d'affiliation, veuillez connecter votre compte Stripe en cliquant sur le bouton ci-dessous.",
              description2:
                "Lorsque vous partagez ce lien avec d'autres personnes, vous recevrez automatiquement 40% la première année de revenus générés par toute personne qui s'inscrit à un compte payant Sutra.",
              connect: "Connecter Stripe",
              historyLabel: "Historique des affiliés",
            },
          },
          spaces: {
            settings: {
              layout: {
                navbarContent: {
                  mainSettings: "Paramètres généraux",
                  members: "Membres",
                  broadcast: "Diffusion",
                  surveys: "Sondages",
                  structures: "Structures",
                  sutraAdmin: "Administrateur Sutra",
                  scheduled: "Planifié",
                  archived: "Archivé",
                  participation: "Participation",
                },
                headerActions: {
                  shareableLink: "LIEN PARTAGEABLE",
                },
              },
              main: {
                title: "Réglages généraux",
                main: {
                  invite: "Inviter",
                  shareableLink: "Lien partageable",
                  cancelLink: "Annuler",
                  saveLink: "Enregistrer",
                  editLinkButton: "Modifier le lien",
                  subdomain: "Votre sous-domaine",
                  sutra_co: ".sutra.co",
                  label1: "Lien copié dans le presse-papiers",
                  privacySetting:
                    "Sélectionnez un paramètre de confidentialité",
                  title1: "Rendre cet espace accessible au public",
                  description1:
                    "Cet espace se trouve dans un espace privé et n'est visible que par les membres de cet espace. Vous pouvez le rendre accessible au public en dehors de cet espace en activant ce paramètre.",
                  title2: "Afficher la page d'inscription",
                  description2:
                    "Les non-membres verront une page d'inscription lorsqu'ils essaieront d'accéder à cet espace si ce paramètre est activé.",
                  title3: "Utiliser la page d'inscription héritée",
                  description3:
                    "Si ce paramètre est activé, cet espace utilisera la page d'inscription conçue avec notre éditeur hérité.",
                  onButton: "ACTIVÉ",
                  offButton: "DÉSACTIVÉ",
                  editButton: "Modifier la page",
                  previewButton: "Aperçu de la page",
                  label2: "Paramètres de confidentialité et de paiement",
                  label3: "Paramètres de la page d'inscription",
                  description4:
                    "Actif - Les personnes arriveront sur une page d'inscription avant de pouvoir accéder à cet espace",
                  label4: "Automatisation",
                  description5:
                    "Planifiez des e-mails et ajoutez automatiquement des personnes à des espaces lors de leur inscription",
                  label5: "Paramètres de planification",
                  description6:
                    "Définissez une date d'événement ou des options de publication et d'archivage",
                  label6: "Paramètres supplémentaires",
                  description7:
                    "Autorisations de création de contenu, visibilité des membres, notifications des gestionnaires et espaces parent",
                  label7: "Paramètres de référencement (SEO)",
                  description8:
                    "Définissez le titre, la description et l'image des balises méta pour le partage social et l'optimisation des moteurs de recherche",
                  label8: "Ouvert",
                  description9: "Tout le monde peut accéder à cet espace",
                  label9: "Privé",
                  description10:
                    "Cet espace est privé et les personnes doivent être invitées à le rejoindre",
                  label10: "Payant",
                  description11:
                    "Cet espace est payant et privé. Les personnes doivent payer pour y accéder",
                  description12:
                    "Les personnes arriveront sur une page d'inscription avant de pouvoir accéder à cet espace ouvert.",
                  description13:
                    "Les personnes arriveront directement dans cet espace lorsqu'elles accèdent à cet espace ouvert.",
                  description14:
                    "Les personnes doivent être directement invitées à accéder à cet espace privé. Si elles sont invitées, elles verront une page d'inscription.",
                  description15:
                    "Les personnes doivent être directement invitées à accéder à cet espace privé. Si elles sont invitées, elles verront une page de création de compte sans page d'inscription.",
                  description16:
                    "Les personnes arriveront sur une page d'inscription avant de pouvoir accéder à cet espace payant.",
                  description17:
                    "Les personnes arriveront sur une page de création de compte et de paiement avant de pouvoir accéder à cet espace payant. Elles ne verront pas de page d'inscription.",
                  on: "ACTIVÉ",
                  off: "DÉSACTIVÉ",
                  label11: "Membres",
                  label12: "En attente",
                  label13: "Intéressés",
                  label14: "Invités",
                  label15: "Paiements",
                  label16: "Image de marque",
                },
                additional: {
                  allowMembersEdit:
                    "Autoriser les membres à modifier le contenu de cette page",
                  membersWillAddContent:
                    "Les membres pourront ajouter et modifier le contenu de cette page",
                  allowMembersCreatePosts:
                    "Autoriser les membres à créer de nouveaux articles",
                  membersWillCreatePosts:
                    "Les membres pourront créer de nouveaux articles dans cet espace",
                  allowMembersCreateEvents:
                    "Autoriser les membres à créer de nouveaux événements",
                  membersWillCreateEvents:
                    "Les membres pourront créer de nouveaux événements dans cet espace",
                  allowMembersCreateSubspaces:
                    "Autoriser les membres à créer de nouveaux sous-espaces",
                  membersWillCreateSubspaces:
                    "Les membres pourront créer des sous-espaces sur cette page",
                  membersCanCreate: "Les membres peuvent créer",
                  circleCreation: {
                    options: {
                      discussion: "Espaces de discussion",
                      content: "Espaces de contenu",
                      event: "Espaces d'événement",
                    },
                    placeholder:
                      "Sélectionnez les types d'espaces que les membres peuvent créer dans cet espace",
                  },
                  allowMembersToCopy: {
                    description1:
                      "Autoriser les membres à copier des membres entre les espaces",
                    description2:
                      "Les membres auront la possibilité d'ajouter automatiquement des membres d'autres cercles",
                  },
                  podType: {
                    description1:
                      "Tous les membres peuvent publier des messages dans cet espace",
                    description2:
                      "Si cette option est désactivée, seuls les gestionnaires peuvent publier des messages",
                  },
                  allowReflections: {
                    description1: "Activer les réflexions pour cet espace",
                    description2:
                      "Si cette option est désactivée, l'option de réflexion n'apparaîtra pas sur les messages",
                  },
                  showMembers: {
                    description1: "Afficher les membres",
                    description2:
                      "Afficher le répertoire des membres pour cet espace",
                  },
                  hideMmemberEmails: {
                    description1:
                      "Afficher les e-mails des membres dans le répertoire des membres",
                    description2:
                      "Si cette option est désactivée, les e-mails des membres ne seront pas répertoriés dans le répertoire",
                  },
                  newMemberNotifications: {
                    description1:
                      "Notifier les gestionnaires lors de l'arrivée de nouveaux membres",
                    description2:
                      "Envoyer un e-mail de notification à chaque nouvelle inscription de membre",
                  },
                  podSize: {
                    description1:
                      "Limite du nombre de personnes pouvant rejoindre cet espace",
                    description2:
                      "Spécifiez un nombre maximum de personnes pouvant rejoindre cet espace",
                  },
                  podSizeValue: {
                    description:
                      "Combien de personnes peuvent rejoindre cet espace ?",
                  },
                  sidebarDefaultOpened: {
                    description1:
                      "Espace ouvert par défaut dans la barre latérale",
                    description2:
                      "Si cette option est activée, cet espace sera automatiquement développé dans la barre latérale",
                  },
                  sidebarHideChildren: {
                    description1:
                      "Masquer les sous-espaces dans la barre latérale",
                    description2:
                      "Si cette option est activée, les sous-espaces créés dans cet espace n'apparaîtront pas dans la barre latérale",
                  },
                  allowCompletions: {
                    description1:
                      "Afficher le bouton de marquage comme terminé",
                    description2:
                      'Si cette option est activée, les membres verront un bouton "Marquer comme terminé" pour cet espace. Après avoir sélectionné cette option, l\'icône dans la barre latérale sera remplacée par une coche.',
                  },
                  redirectToSubspace: {
                    description1: "Rediriger toujours vers le sous-espace",
                    description2:
                      "Si cette option est activée, les membres seront toujours redirigés vers le sous-espace sélectionné lorsqu'ils accèdent à cet espace.",
                  },
                  parentCircles: {
                    description1: "Espaces parent",
                    placeholder:
                      "Recherchez les espaces à ajouter en tant que parents de cet espace",
                  },
                },
                scheduling: {
                  showStartDate: {
                    description1: "Afficher cet espace en tant qu'événement",
                    description2:
                      "Cet espace affichera une date d'événement partout où il est répertorié",
                  },
                  startDateTime: {
                    title: "Date et heure de début",
                    placeholder: "Sélectionnez une date",
                  },
                  endDateTime: {
                    title: "Date et heure de fin",
                    placeholder: "Sélectionnez une date",
                  },
                  showLaunchDate: {
                    description1: "Publier cet espace à une date spécifique",
                    description2:
                      "Cet espace deviendra visible et accessible à la date spécifiée",
                  },
                  launchDateTime: {
                    placeholder: "Sélectionnez une date",
                  },
                  showArchiveDate: {
                    description1: "Archiver cet espace à une date spécifique",
                    description2:
                      "Cet espace ne sera plus accessible à la date spécifiée",
                  },
                  archiveDate: {
                    placeholder: "Sélectionnez une date",
                  },
                  showReadonlyDate: {
                    description1:
                      "Rendre cet espace en lecture seule à une date spécifique",
                    description2:
                      "Aucun nouveau message ne pourra être publié après la date spécifiée",
                  },
                  readonlyDate: {
                    placeholder: "Sélectionnez une date",
                  },
                },
                seo: {
                  title: {
                    title: "Titre de balise",
                    description:
                      "Ceci est le titre qui apparaîtra dans les résultats des moteurs de recherche et les partages sociaux. La longueur optimale est de 60 caractères.",
                  },
                  description: {
                    title: "Meta description",
                    description:
                      "Ceci est la description qui apparaîtra dans les résultats des moteurs de recherche et les partages sociaux. La longueur optimale est de 150 à 350 caractères.",
                  },
                  image: {
                    title: "Image méta",
                    description:
                      "Ceci est l'image qui apparaîtra dans les partages sur les réseaux sociaux. Les dimensions recommandées sont de 1200 pixels x 627 pixels (ratio 1.91/1).",
                  },
                },
              },
              members: {
                title: "Membres",
                members: {
                  download: "Télécharger le CSV",
                  invite: "Inviter",
                  member: "Membres",
                  invited: "Invités",
                  interested: "Intéressés",
                  payment: "Paiements",
                },
                interested: {
                  interested: {
                    button: "Rechercher",
                    placeholder: "Rechercher d'autres",
                    broadcast: "Envoyer une diffusion",
                    noRecordFound: "Aucun enregistrement trouvé",
                  },
                  table: {
                    firstname: "Prénom",
                    email: "Email",
                    date: "Date",
                  },
                  interestedrow: {
                    removeFromList: "Retirer de la liste",
                  },
                },
                invited: {
                  invited: {
                    button: "Rechercher",
                    placeholder: "Rechercher d'autres",
                    noRecordFound: "Aucun enregistrement trouvé",
                  },
                  table: {
                    email: "Email",
                    invitationlink: "Lien d'invitation",
                    amount: "Montant",
                    date: "Date d'envoi",
                  },
                },
                invitedrow: {
                  invitation: {
                    resend: "Renvoyer l'invitation",
                    cancel: "Annuler l'invitation",
                  },
                },
                inviteEmail: {
                  invite: {
                    placeholder:
                      "Saisissez l'adresse e-mail ou mentionnez les personnes que vous souhaitez ajouter à cet espace. Les entrées peuvent être séparées par des virgules, des espaces ou des sauts de ligne.",
                    message: "Ajoutez un message de bienvenue facultatif",
                    checkboxLabel:
                      "Envoyer un e-mail pour informer les personnes qu'elles ont été invitées",
                    alertMessage:
                      "Vous êtes actuellement sur un plan de base et ne pouvez pas envoyer d'invitations via Sutra. Lorsque vous cliquez sur Inviter, un lien d'invitation unique sera généré pour chaque adresse e-mail que vous ajoutez. Vous pourrez envoyer personnellement chaque personne avec ce lien.",
                    alertbutton: "Mettre à niveau le compte",
                    button: "Inviter des personnes",
                  },
                },
                inviteExisting: {
                  invite: {
                    copyMembers: "Copier les membres depuis un autre espace",
                    copyMembersPlaceholder:
                      "Sélectionnez les espaces à partir desquels copier les membres",
                    addActiveMembers:
                      "Ajouter les membres actifs des espaces parents",
                    addActiveMembersPlaceholder:
                      "Sélectionnez les membres actifs à ajouter à cet espace",
                    addPendingMembers:
                      "Ajouter les membres en attente des espaces parents",
                    addPendingMembersPlaceholder:
                      "Sélectionnez les membres en attente à ajouter à cet espace",
                    checkboxLabel:
                      "Envoyer un e-mail pour informer les personnes qu'elles ont été invitées",
                    alert:
                      "Vous êtes actuellement sur un plan de base et ne pouvez pas envoyer d'invitations via Sutra. Lorsque vous cliquez sur Inviter, un lien d'invitation unique sera généré pour chaque adresse e-mail que vous ajoutez. Vous pourrez envoyer personnellement chaque personne avec ce lien.",
                    alertButton: "Mettre à niveau le compte",
                    button: "Inviter des personnes",
                    thisOption:
                      "Cette option n'est pas disponible pour cet espace",
                  },
                },
                inviteLink: {
                  invite: {
                    shareLinkText:
                      "Les gens peuvent s'inscrire directement à cet espace en utilisant le lien suivant. Vous pouvez partager ce lien où vous le souhaitez (e-mail, réseaux sociaux, etc).",
                    youCannotShare:
                      "Vous ne pouvez pas partager de lien vers un espace privé.",
                  },
                },
                invitePeople: {
                  invite: {
                    invitePeople: "Inviter des personnes",
                  },
                },
                membersActivity: {
                  members: {
                    placeholder: "Rechercher",
                    button: "Rechercher",
                    emailButton: "Email",
                    messageButton: "Message",
                    noRecordFound: "Aucun enregistrement trouvé",
                  },
                  memberTable: {
                    noRecordFound: "Aucun enregistrement trouvé",
                    lastSeen: "Dernière connexion",
                    lastPosted: "Dernière publication",
                    date: "Date d'inscription",
                  },
                },
                participationActivity: {
                  participation: {
                    searchPlaceholder: "Rechercher",
                    searchButton: "Rechercher",
                    pickDatePlaceholder: "Sélectionner une période",
                    emailButton: "Email",
                    messageButton: "Message",
                    noRecordFound: "Aucun enregistrement trouvé",
                  },
                  memberTable: {
                    member: "Membre",
                    participation: "Participation",
                    posts: "Publications",
                    words: "Mots",
                  },
                },
                participationActivityRow: {
                  posts: "publications",
                },
                paymentRow: {
                  payment: {
                    refundPayment: "Rembourser le paiement",
                  },
                },
                payments: {
                  payment: {
                    searchPlaceholder: "Rechercher",
                    searchButton: "Rechercher",
                    noRecordFound: "Aucun enregistrement trouvé",
                  },
                  paymentTable: {
                    member: "Membre",
                    amount: "Montant",
                    date: "Date",
                    status: "Statut",
                  },
                },
                progressActivity: {
                  progress: {
                    searchPlaceholder: "Rechercher",
                    searchButton: "Rechercher",
                    emailButton: "Email",
                    messageButton: "Message",
                    noRecordFound: "Aucun enregistrement trouvé",
                    progressTracking:
                      "Le suivi des progrès n'est disponible que dans les espaces qui ont des interactions.",
                  },
                  progressTable: {
                    member: "Membre",
                    progress: "Progrès",
                  },
                },
                registered: {
                  registered: {
                    searchPlaceholder: "Rechercher",
                    searchButton: "Rechercher",
                    email: "Email",
                    message: "Message",
                    noRecordFound: "Aucun enregistrement trouvé",
                    pending: "En attente",
                    manager: "Gestionnaires",
                    all: "Tous",
                    active: "Actif",
                  },
                  registeredTable: {
                    member: "Membre",
                    email: "Email",
                    status: "Statut",
                    role: "Rôle",
                    roleApproveAll: "Approuver tout",
                  },
                },
                registeredRow: {
                  registered: {
                    roleApprove: "Approuver",
                    joined: "Rejoint",
                    sendMessage: "Envoyer un message",
                    stopCharging: "Arrêter la facturation",
                    emailPreferences: "Préférences d'email",
                    removeMember: "Supprimer le membre",
                  },
                },
              },
              payment: {
                charging: {
                  NumberOfTimes: {
                    howManyTimes: "Combien de fois ?",
                  },
                  UntilDate: {
                    pickDate: "Choisir une date de fin de facturation",
                  },
                },
                fees: {
                  description: {
                    fees: "FRAIS :",
                    sutraFee: "FRAIS SUTRA +",
                    stripeProcessingFee: "FRAIS DE TRAITEMENT STRIPE",
                  },
                },
                flexible: {
                  flexible: {
                    description:
                      "Pour offrir aux personnes des options de paiement flexibles telles qu'un modèle de paiement selon vos moyens ou plusieurs niveaux de paiement, vous pouvez créer une enquête de paiement que les personnes verront lorsqu'elles s'inscrivent à votre espace. Cliquez sur le bouton d'édition ci-dessous pour personnaliser l'enquête.",
                  },
                },
                once: {
                  once: {
                    description: "Combien allez-vous facturer ?",
                    number: "10",
                  },
                },
                recurring: {
                  recurring: {
                    description1: "Combien allez-vous facturer ?",
                    description2: "Sélectionnez une option de facturation",
                  },
                },
              },
              privacy: {
                open: {
                  open: {
                    description: "Tout le monde peut accéder à cet espace",
                  },
                },
                paid: {
                  paid: {
                    stripeConnected: "Stripe connecté à",
                    disconnectButton: "Déconnecter",
                    description:
                      "Pour traiter les paiements pour cet espace, veuillez connecter votre compte Stripe en cliquant sur le bouton ci-dessous.",
                    connectButton: "Connecter Stripe",
                  },
                },
                private: {
                  private: {
                    description:
                      "Cet espace est privé et les personnes doivent être invitées pour rejoindre",
                  },
                },
              },
              registration: {
                steps: {
                  addFromSubSpace: {
                    label: "Sélectionnez un espace où ajouter le membre",
                    description:
                      "Dans certaines situations, les personnes peuvent rejoindre un sous-espace sans rejoindre l'espace parent. Parfois, vous souhaitez vous assurer qu'ils sont également ajoutés à un espace de niveau supérieur ou à un espace partagé. Cette option vous permet de le faire. Chaque fois que quelqu'un rejoint un sous-espace ici, il sera automatiquement ajouté à tous les espaces que vous sélectionnez ci-dessous.",
                    placeholder: "Sélectionner des espaces",
                    saveButton: "Enregistrer l'étape",
                    deleteButton: "Supprimer l'étape",
                    saveButton2: "Enregistrer l'étape",
                  },
                  addStructure: {
                    label1: "Sélectionnez une structure",
                    placeholder1: "Sélectionnez une option",
                    label2: "Envoyer en tant que",
                    placeholder2: "Sélectionnez une option",
                    saveButton: "Enregistrer l'étape",
                  },
                  addToExistingSpace: {
                    label: "Sélectionnez un espace où ajouter le membre",
                    placeholder: "Sélectionner des espaces",
                    saveButton1: "Enregistrer l'étape",
                    deleteButton: "Supprimer l'étape",
                    saveButton2: "Enregistrer l'étape",
                  },
                  addToNewSpace: {
                    placeholder: "Nom du nouvel espace",
                    label: "Créez un nom pour le nouvel espace",
                    saveButton1: "Enregistrer l'étape",
                    deleteButton: "Supprimer l'étape",
                    saveButton2: "Enregistrer l'étape",
                  },
                  applyTemplate: {
                    label: "Sélectionnez un espace à copier",
                    description:
                      "Parfois, vous souhaitez créer un espace modèle et le reproduire chaque fois qu'un nouvel espace est créé. Vous pouvez créer un espace et le configurer exactement comme vous le souhaitez. Ensuite, tout le contenu et les sous-espaces seront copiés dans tous les espaces nouvellement créés ici.",
                    saveButton1: "Enregistrer l'étape",
                    deleteButton: "Supprimer l'étape",
                    saveButton2: "Enregistrer l'étape",
                  },
                  editEmail: {
                    placeholder1: "Sujette ",
                    label1: "Envoyer en tant que",
                    placeholder2: "Sélectionnez une option",
                    label2: "Envoyer à",
                    placeholder3: "Sélectionnez une option",
                    placeholder4: "quelqu'un@special.com",
                    label3: "Adresse e-mail",
                    saveButton: "Enregistrer l'étape",
                    deleteButton: "Supprimer l'étape",
                  },
                  editStructure: {
                    deleteButton: "Supprimer l'étape",
                  },
                  editSurvey: {
                    deleteButton: "Supprimer l'étape",
                  },
                  sendEmail: {
                    label1: "Sélectionnez un e-mail",
                    placeholder1: "Sélectionnez une option",
                    label2: "Envoyer en tant que",
                    placeholder2: "Sélectionnez une option",
                    label3: "Envoyer à",
                    placeholder3: "Sélectionnez une option",
                    label4: "quelqu'un@special.com",
                    placeholder4: "Adresse e-mail",
                    saveButton: "Enregistrer l'étape",
                    label5: "Gestionnaires",
                    label6: "Adresse e-mail personnalisée",
                  },
                  setToPending: {
                    title: "Message de bienvenue",
                    description:
                      "C'est le contenu que les personnes verront juste après leur inscription à votre espace. C'est un bon endroit pour expliquer quand le programme sera lancé et à quoi s'attendre en attendant.",
                    saveButton1: "Enregistrer l'étape",
                    deleteButton: "Supprimer l'étape",
                    saveButton2: "Enregistrer l'étape",
                  },
                  surveyPerson: {
                    placeholder: "Sélectionnez une option",
                    label: "Sélectionnez un sondage",
                    saveButton1: "Enregistrer l'étape",
                    deleteButton: "Supprimer l'étape",
                    saveButton2: "Enregistrer l'étape",
                  },
                  wait: {
                    description1: "Combien de jours ?",
                    description2: "Attendre jusqu'à",
                    saveButton: "Enregistrer l'étape",
                    deleteButton: "Supprimer l'étape",
                    saveButton2: "Enregistrer l'étape",
                    label1: "Sélectionnez une condition d'attente",
                    placeholder: "Sélectionnez une option",
                    label2: "Nombre de jours",
                    label3: "Jusqu'à une date et heure spécifiques",
                    label4: "Choisir une date",
                  },
                  webhook: {
                    label: "URL du webhook",
                    saveButton: "Enregistrer l'étape",
                    deleteButton: "Supprimer l'étape",
                  },
                },
                addStep: {
                  addStep: {
                    description: "Ajouter étape",
                    label: "Veuillez sélectionner une action pour cette étape",
                    placeholder: "Sélectionnez une action",
                  },
                },
                registrationFlow: {
                  registration: {
                    description1:
                      "Cette fonctionnalité vous permet de séquencer des actions automatisées qui sont déclenchées lorsqu'une personne rejoint ou s'intéresse à cet espace. Ici, vous pouvez effectuer des actions telles que la planification d'e-mails et l'ajout automatique de personnes à des espaces sélectionnés lors de leur inscription.",
                    description2:
                      "Effectuer ces actions après que quelqu'un...",
                    placeholder1: "Sélectionnez une condition de départ",
                    addButton: "Ajouter une étape",
                    label1: "Devient membre ou rejoint la liste des intéressés",
                    description3:
                      "Les étapes suivantes seront déclenchées lorsqu'une personne devient membre de cet espace ou rejoint la liste des intéressés en soumettant son nom et son adresse e-mail via un formulaire d'intérêt.",
                    label2: "Devient membre de cet espace",
                    description4:
                      "Les étapes suivantes seront déclenchées lorsqu'une personne devient membre de cet espace.",
                    label3: "S'intéresse à cet espace",
                    description5:
                      "Les étapes suivantes seront déclenchées lorsqu'une personne rejoint la liste des intéressés en soumettant son nom et son adresse e-mail via un formulaire d'intérêt.",
                  },
                },
              },
              structure: {
                add: {
                  add: {
                    title: "Ajouter aux espaces",
                    description: "Espaces",
                    placeholder1: "Sélectionnez un espace",
                    label1: "Envoyer en tant que",
                    placeholder2: "Choisissez l'expéditeur",
                    placeholder3: "Sélectionnez une date",
                    label2: "Date d'envoi",
                    label3: "Heure d'envoi",
                    saveButton: "Enregistrer",
                    label4: "Planifier ultérieurement",
                  },
                },
                createStructure: {
                  title: {
                    createStructure: "Créer une structure",
                  },
                },
                structureItem: {
                  item: {
                    addToSpace: "Ajouter à l'espace",
                    open: "View responses",
                    edit: "Modifier",
                    duplicate: "Dupliquer",
                    delete: "Supprimer",
                  },
                },
              },
              survey: {
                add: {
                  add: {
                    title: "Ajouter aux espaces",
                    spaces: "espaces",
                    placeholder1: "Sélectionnez un espace",
                    label: "Envoyer en tant que",
                    placeholder2: "Choisissez l'expéditeur",
                    saveButton: "Enregistrer",
                  },
                },
                answer: {
                  answer: {
                    addOption: "Ajouter une option",
                  },
                },
                createSurvey: {
                  description: "Créer un sondage",
                },
                createSurveyForm: {
                  form: {
                    title: "Titre du sondage",
                    description:
                      "Les paiements flexibles vous permettent d'offrir plusieurs options de paiement pour votre programme. Si vous souhaitez permettre aux personnes de fixer leur propre prix, créez simplement une option de paiement sans définir le montant. Cette option apparaîtra avec un champ de saisie permettant à la personne de définir son propre montant. Par exemple, vous pouvez proposer un modèle de paiement selon vos moyens avec plusieurs niveaux suggérés et une option totalement libre. Si vous ajoutez un texte d'explication ci-dessous, ce texte apparaîtra juste au-dessus des options de paiement.",
                    optionalDescription: "Description facultative du sondage",
                    addQuestionButton: "Ajouter une question",
                    saveButton: "Enregistrer",
                  },
                },
                individual: {
                  individual: {
                    placeholder: "Sélectionnez une personne",
                  },
                },
                multipleItem: {
                  multipleItem: {
                    placeholder: "Option",
                  },
                },
                paymentItem: {
                  paymentItem: {
                    placeholder: "Option de paiement",
                  },
                },
                paymentSurveyItem: {
                  item: {
                    editButton: "Modifier les options de paiement",
                    viewButton: "Voir les réponses",
                  },
                },
                showSurvey: {
                  survey: {
                    title: "Réponses au sondage",
                  },
                },
                singleItem: {
                  item: {
                    placeholder: "Option",
                  },
                },
                surveyItem: {
                  item: {
                    addToSpace: "Ajouter à l'espace",
                    open: "View responses",
                    edit: "Modifier",
                    take: "Preview survey",
                    duplicate: "Dupliquer",
                    download: "Télécharger en CSV",
                    delete: "Supprimer",
                    responses: "Réponses",
                  },
                },
              },
              broadcast: {
                broadcast: {
                  button: {
                    broadcastButton: "Nouvelle diffusion",
                  },
                },
              },
              broadcast: {
                title: "Diffuser",
                broadcastButton: "Nouvelle diffusion",
                membersTypes: {
                  active: "Membres actifs",
                  pending: "Membres en attente",
                  interested: "Membres intéressés",
                },
                sentOn: "Diffusion envoyée le",
                onDestroy: {
                  alertTitle: "Es-tu sûr?",
                  alertMessage: "Cette diffusion sera supprimée définitivement",
                  confirm: "Supprimer la diffusion",
                  deny: "Annuler",
                },
                actions: {
                  edit: "Modifier",
                  duplicate: "Dupliquer",
                  delete: "Supprimer",
                },
                statuses: {
                  sent: "Envoyée",
                  scheduled: "Planifiée",
                  draft: "Brouillon",
                  procesing: "Processing",
                },
                form: {
                  composeBroadcast: "Composer une diffusion",
                  contentErrMessage: "Ajoutez du contenu",
                  targetErrMessage: "Sélectionnez un destinataire",
                  type: {
                    email: "Envoyer un e-mail",
                    message: "Message dans l'espace",
                  },
                  title: {
                    placeholder: "Objet du message",
                  },
                  brackets: {
                    part1: "Utilisez",
                    part2: "et",
                    part3:
                      "pour remplir automatiquement les informations correspondantes",
                  },
                  target: {
                    placeholder: "Choisir les destinataires",
                    label: "Envoyer à",
                  },
                  sendAs: {
                    label: "Envoyer en tant que",
                    placeholder: "Choisir l'expéditeur",
                  },
                  schedule: {
                    label: "Planifier pour plus tard",
                  },
                  sendAtDate: {
                    label: "Date d'envoi",
                    placeholder: "Sélectionner une date",
                  },
                  sendAtTime: {
                    label: "Heure d'envoi",
                    placeholder: "",
                  },
                  scheduleBroadcast: "Planifier la diffusion",
                  sendBroadcast: "Envoyer la diffusion",
                  saveDraft: "Enregistrer le brouillon",
                },
              },
              surveys: {
                title: "Sondages",
                createSurvey: "Créer un sondage",
              },
              structures: {
                title: "Structures",
                createStructure: "Créer une structure",
              },
              sutraAdmin: {
                title: "Administration Sutra",
              },
              scheduled: {
                title: "Planifié",
              },
              archived: {
                title: "Archivé",
              },
              participation: {
                title: "Participation",
                label1: "Présence",
                description1:
                  "% des membres qui ont visité au cours des 7 derniers jours",
                label2: "Activité",
                description2:
                  "% des membres qui ont publié au cours des 7 derniers jours",
                label3: "Publications",
                description3:
                  "Total des publications dans tous les sous-espaces au cours des 7 derniers jours",
                label4: "Participation",
                label5: "Activité des membres",
                label6: "Progression",
              },
            },
            sidebar: {
              iconSelect: {
                placeholder: "Rechercher",
              },
              membersNode: {
                member: "Membres",
              },
              nodeIcon: {
                reset: "Réinitialiser",
              },
              sidebarTutorial: {
                gettingStarted: "Premiers pas",
              },
            },
            alreadyMember: {
              member: {
                welcome: "Bienvenue ! Vous êtes déjà membre de cet espace -",
                clickHere: "Cliquez ici pour accéder",
              },
            },
            calender: {
              calender: {
                joinButton: "Rejoindre l'appel",
                addButton: "Ajouter au calendrier",
                joinEventButton: "S'inscrire",
                title: "Détails de l'événement",
              },
            },
            invitationBar: {
              button: {
                register: "S'inscrire",
              },
            },
            space: {
              sutraLoading: "Sutra - Chargement en cours...",
            },
            statusBar: {
              restoreButton: "Restaurer",
            },
            tagFilter: {
              resetButton: "Réinitialiser",
            },
            presentations: {
              discussion: {
                reflectionHeader: "Réflexions",
                backToPost: "Retour au message",
                pinnedMessageHeader: "Message épinglé",
                delete: {
                  title: "Êtes-vous sûr(e) ?",
                  message: "Cette action est irréversible",
                  confirmButton: "Oui, supprimer",
                  cancelButton: "Annuler",
                },
              },
              tag: {
                placeholder: "Rechercher des tags ou en créer un nouveau",
                placeholder2: "Sélectionner un tag",
                createAction: "Créer",
              },
              message: {
                reply: "Réfléchir",
                addTags: "Ajouter des tags",
                tag: "Tag",
                copyToClipboard: "Copier dans le presse-papiers",
                messageCopied: "Message copié dans le presse-papiers",
                pinMessageAction: "Épingler le message",
                editMessage: "Modifier le message",
                deleteMessage: "Supprimer le message",
                makePrivate: "Rendre privé",
                viewReplies1: "Voir",
                viewReplies2single: "réflexion",
                viewReplies2plural: "réflexions",
                privateMessage:
                  "Ce message est actuellement visible uniquement par vous et les responsables de cet espace. S'il est rendu public, il sera visible par les autres participants de cet espace.",
                privateMessage2:
                  "Cette personne a choisi de ne pas partager sa réponse avec les autres participants.",
                unreadMessagesText: "Vous avez des messages non lus",
                scrollDownButton: "Faire défiler vers le bas",
                newReflections: {
                  part1: "Vous avez",
                  part2: "Réflexions non lues à travers",
                  part3: "messages",
                },
                viewNextButton: "Voir le suivant",
                pinMessage: {
                  mainText: "Message épinglé",
                  viewButton: "Voir le message",
                },
              },
              content: {
                commentInput: {
                  toggleLabel1:
                    "Les commentaires sont désactivés pour les parcours interactifs",
                  toggleLabel2: "Activer les commentaires pour ce message",
                  toggleLabel3:
                    "Activer les commentaires pour cet espace événementiel",
                  toggleLabel4:
                    "Activer les commentaires pour cet espace de contenu",
                  placeholder1:
                    "Les commentaires ne peuvent pas être publiés pendant que vous effectuez une modification.",
                  placeholder2: "Écrire quelque chose...",
                },
              },
              fallback: {
                title: "Problème de connexion",
                message:
                  "Il semble que vous soyez déconnecté(e) d'internet. Une fois que vous serez de nouveau en ligne, nous vous reconnecterons automatiquement.",
              },
              events: {
                placeholder: "Écrire quelque chose...",
                upcomingButton: "À venir",
                pastButton: "Passés",
                addEvent: "Ajouter un événement",
                eventSettingsTooltip: "Paramètres de l'événement",
                newEventTitle: "NOUVEL ÉVÉNEMENT",
              },
              list: {
                placeholder: "Écrire quelque chose...",
                createButton: "Créer un message",
                listSettingsTooltip: "Paramètres du forum",
                duplicateFormTitle: "DUPLIQUER L'ESPACE",
              },
              settings: {
                versions: {
                  content: "Page de contenu",
                  posts: "Publications",
                  discussions: "Discussions",
                  link: "Lien",
                  template: "Modèle",
                },
                versionsDescriptions: {
                  content:
                    "Les nouveaux éléments s'afficheront comme une page de contenu avec toutes les fonctionnalités.",
                  discussion: "Vide",
                  posts:
                    "Les nouveaux éléments s'afficheront comme une simple publication avec des fonctionnalités limitées.",
                  link: "Cette option vous permet de soumettre un lien vers n'importe quel espace sur Sutra qui sera ensuite affiché comme un élément ici.",
                  template:
                    "Créez un modèle d'espace qui sera dupliqué à chaque fois qu'un nouvel élément est créé ici.",
                },
                createNewTemplate: "Créer un nouveau modèle",
                editTemplate: "Modifier le modèle",
                title: "PARAMÈTRES",
                accordion1: {
                  label: "Affichage en tant que",
                  description:
                    "Afficher les publications sous forme de liste ou de grille",
                  listOption: "Liste",
                  gridOption: "Grille",
                  dateCreated: "Date de création",
                  dateCreatedReversed: "Inverser la date de création",
                  lastActive: "Dernière activité",
                  lastEdited: "Dernière modification",
                  alphabetic: "Ordre alphabétique",
                  reverseAlphabetic: "Alphabétique inversé",
                },
                accordion2: {
                  label: "Options d'affichage",
                  description: "",
                  switches: {
                    onlyManagers:
                      "Seuls les gestionnaires peuvent créer une publication",
                    showAuthor: "Afficher les informations de l'auteur",
                    showPreview: "Afficher un aperçu du texte",
                    showComments: "Afficher le nombre de commentaires",
                    showMembers: "Afficher les avatars des membres",
                    showTimestamp:
                      "Afficher l'horodatage de création de la publication",
                    showThumbnail: "Afficher une miniature",
                    autoThumbnail: "Créer automatiquement une miniature",
                  },
                },
                accordion3: {
                  label: "Nouveau format de publication",
                  description:
                    "Sélectionnez le format des nouvelles publications dans cet espace",
                  templateName: {
                    label: "Nommez votre modèle",
                    placeholder: "Nom du modèle",
                  },
                  templateDirectOption: "Sous-espace direct",
                  templateLinkedOption: "Espace lié",
                  privacyDescription:
                    "Sélectionnez le niveau de confidentialité par défaut des nouvelles publications",
                  privacyOpenOption: "Public",
                  privacyPrivateOption: "Privé",
                  privacyAnyOption: "Choix du créateur",
                },
                accordion4: {
                  label: "Libellé du bouton",
                  createPost: {
                    label: "Créer une publication",
                    description:
                      "Spécifiez le texte qui s'affiche sur le bouton de nouvelle publication",
                  },
                },
                accordion5: {
                  label: "Tags",
                },
                templateDescription:
                  "Les nouveaux espaces modèles peuvent être des sous-espaces directs de cet espace ou des espaces liés. Les espaces liés sont des espaces indépendants de premier niveau qui s'afficheront sur cette page. Le créateur de l'espace deviendra un gestionnaire complet de l'espace qu'il crée.",
              },
              thumbnail: {
                title: "AJOUTER UNE MINIATURE",
                message: "L'image miniature est en cours de téléchargement...",
              },
              static: {
                broadcastExclude: {
                  unsubscribe: {
                    part1: "Êtes-vous sûr de vouloir vous désabonner de",
                    part2:
                      "Vous ne recevrez plus d'e-mails de diffusion directe de la part des gestionnaires de cet espace",
                    button: "Se désabonner",
                  },
                  subscribe: {
                    part1:
                      "Vous avez été désabonné et vous ne recevrez plus d'e-mails de diffusion de",
                    button: "S'abonner",
                  },
                },
                invitation: {
                  message: "Vous avez été invité à cet espace",
                  button: "Accepter l'invitation",
                },
                pending: {
                  message: "Votre adhésion est en attente",
                },
                private: {
                  message: {
                    part1: "C'est un espace privé.",
                    part2: "Si vous êtes membre, veuillez vous connecter.",
                  },
                  button: "Se connecter",
                },
                processing: {
                  message: "Cet espace est encore en cours de traitement...",
                },
              },
              gridPost: {
                onDelete: {
                  alertTitle: "Êtes-vous sûr ?",
                  alertMessage: "Cet espace ne sera plus lié ici.",
                  alertMessage2: "Cet espace ne sera plus lié ici.",
                  confirm: "Oui",
                  deny: "Non",
                },
                actions: {
                  pin: "Épingler en tête",
                  unpin: "Détacher",
                  addThumbnail: "Ajouter une miniature",
                  removeThumbnail: "Supprimer la miniature",
                  duplicate: "Dupliquer",
                  unlink: "Détacher",
                  delete: "Supprimer",
                },
              },
              listContent: {
                publishTootip: "Sera publié à cette date",
                privateTooltip: "C'est un espace privé",
              },
            },
            colors: {
              form: {
                colorTheme: "Thème de couleur",
                customize: "Personnaliser la sélection de couleurs",
                saveTheme: "Enregistrer le thème",
                resetColor: "Réinitialiser la couleur",
                pageLayout: "Disposition de la page",
                pageFormat: "Format de la page",
              },
              headerAndLogo: {
                addCover: "Ajouter une couverture",
                removeCover: "Supprimer la couverture",
                changeLogo: "Changer le logo",
                removeLogo: "Supprimer le logo",
                dimensions:
                  "Les dimensions recommandées pour les images de couverture sont de 1200 x 300 pixels",
                dimensions2:
                  "Les dimensions recommandées pour les logos sont de 360 x 120 pixels",
              },
              pageFormat: {
                description:
                  "Vous pouvez modifier la présentation du contenu dans cet espace en sélectionnant une option ci-dessous.",
                contentLabel: "Contenu",
                discussionLabel: "Discussion",
                contentDescription:
                  "Un espace pour les modules d'apprentissage, le contenu général ou les ressources communautaires.",
                discussionDescription:
                  "Un espace pour les discussions ou les annonces",
                eventsLabel: "Calendrier",
                eventsDescription: "Créer une liste d'événements",
                listLabel: "Forum",
                listDescription:
                  "Créer un flux où les gens peuvent publier des sujets de discussion",
              },
              pageLayout: {
                widePage: "Largeur de page étendue",
                hideSidebar: "Masquer la barre latérale",
                hideHeader: "Masquer l'en-tête",
                hideCoverImage: "Masquer l'image de couverture",
                hidePageTitle: "Masquer le titre de la page",
                hideBreadcrumbs: "Masquer les fil d'Ariane",
              },
              header_color: {
                label: "Couleur de l'en-tête",
                description: "Description",
              },
              header_link_color: {
                label: "Couleur du lien de l'en-tête",
                description: "Description",
              },
              primary_button_background_color: {
                label: "Couleur de fond du bouton principal",
                description: "Description",
              },
              primary_button_text_color: {
                label: "Couleur du texte du bouton principal",
                description: "Description",
              },
              secondary_button_background_color: {
                label: "Couleur de fond du bouton secondaire",
                description: "Description",
              },
              secondary_button_text_color: {
                label: "Couleur du texte du bouton secondaire",
                description: "Description",
              },
              background_color: {
                label: "Couleur de fond",
                description: "Description",
              },
              default_text_color: {
                label: "Couleur du texte par défaut",
                description: "Description",
              },
              sidebar_background_color: {
                label: "Couleur de fond de la barre latérale",
                description: "Description",
              },
              sidebar_text_color: {
                label: "Couleur du texte de la barre latérale",
                description: "Description",
              },
              default_link_color: {
                label: "Couleur du lien par défaut",
                description: "Description",
              },
              default_badge_color: {
                label: "Couleur de badge de notification",
                description: "Description",
              },
              default_badge_text_color: {
                label: "Couleur du texte de badge de notification",
                description: "Description",
              },
              registration_page_background_color: {
                label: "Couleur de fond de la page d'inscription",
                description: "Description",
              },
              registration_page_default_text_color: {
                label: "Couleur du texte par défaut de la page d'inscription",
                description: "Description",
              },
            },
            create: {
              invalidNameErrorMsg:
                "Le nom de l'espace doit comporter entre 3 et 100 caractères",
              invalidName2ErrorMsg:
                "Le titre doit comporter entre 3 et 100 caractères",
            },
            dummy: {
              magicLink: {
                alertTitle: "Vous y êtes presque...",
                alertMessage:
                  "Nous avons envoyé le lien magique à votre adresse e-mail. CECI EST UN TEST, AUCUN E-MAIL RÉEL N'A ÉTÉ ENVOYÉ.",
              },
            },
            pending: {
              message: "Votre adhésion est en attente",
            },
            recover: {
              alertTitle: "Vous y êtes presque...",
              alertMessage:
                "Nous venons de vous envoyer un e-mail avec des instructions pour récupérer votre mot de passe. CECI EST UN TEST, AUCUN E-MAIL RÉEL N'A ÉTÉ ENVOYÉ.",
            },
            form: {
              calendarLinks: {
                text: "Les informations ajoutées ci-dessous seront transmises au calendrier du membre lorsqu'il ajoutera l'événement.",
                link: {
                  label: "URL de l'événement (lien Zoom, etc.)",
                  placeholder: "Quel est le lien de votre appel vidéo",
                },
                description: {
                  label:
                    "Ajouter une description pour votre événement qui s'affichera dans les informations du calendrier",
                  placeholder: "Description du calendrier",
                },
                eventStartDateAndTime: "Date et heure de début de l'événement",
                date: {
                  placeholder: "Choisir une date",
                },
                eventDateTime:
                  "Date et heure de fin de l'événement (optionnel)",
                allowInterests:
                  "Permettre aux personnes de participer à l'événement sans s'inscrire sur Sutra",
                automatedEmails: "Configurer des e-mails automatisés",
                save: "Enregistrer les détails",
              },
              duplicate: {
                prefix: "Copie de",
                intention: {
                  label: "Nom de l'espace",
                  placeholder: "Comment s'appelle votre espace ?",
                },
                copyMembers: {
                  label: "Copier les membres",
                },
                copyManagers: {
                  label: "Copier les responsables",
                },
                copyContent: {
                  label: "Copier le contenu",
                },
                copyPosts: {
                  label: "Copy posts",
                },
                copyBroadcasts: {
                  label: "Copier les diffusions",
                },
                copyAutomation: {
                  label: "Copier les automatisations",
                },
                copyRegPage: {
                  label: "Copier la page d'inscription",
                },
                save: "Dupliquer l'espace",
                saveTemplate: "Créer un modèle"
              },
              form: {
                privacyDescription1:
                  "L'espace que vous créez sera accessible à tous dans",
                rootPrivacyDescription1:
                  "L'espace que vous créez sera accessible à quiconque possède un lien vers celui-ci. Vous pouvez définir les options de paiement une fois que vous avez créé l'espace.",
                privacyDescription2:
                  "L'espace que vous créez ne sera visible que par les personnes qui y sont ajoutées.",
                rootPrivacyDescription2:
                  "L'espace que vous créez ne sera visible que par les personnes qui y sont invitées. Vous pouvez définir les options de paiement une fois que vous avez créé l'espace.",
                placeholderDescription: "Description",
                versions: {
                  content: {
                    label: "Contenu",
                    description:
                      "Créez un espace pour un module d'apprentissage, du contenu général ou des ressources communautaires.",
                  },
                  discussion: {
                    label: "Discussion",
                    description: "Créez un espace de discussion ou d'annonces.",
                  },
                  event: {
                    label: "Événement",
                    description: "Créez un événement unique.",
                  },
                  events: {
                    label: "Calendrier",
                    description: "Créez une liste de calendrier d'événements.",
                  },
                  list: {
                    label: "Forum",
                    description:
                      "Créez un flux où les gens peuvent publier des sujets de discussion.",
                  },
                },
                segments: {
                  space: {
                    label: "Espace",
                    description:
                      "Créez un espace pour votre cours ou votre communauté. Choisissez cette option si vous souhaitez créer une cohorte de formation, un cours à votre rythme, une communauté ou tout groupe qui se réunira sur une période donnée.",
                  },
                  event: {
                    label: "Événement",
                    description:
                      "Créez une page d'événement simple. Choisissez cette option si vous souhaitez simplement organiser un événement unique que vous pouvez partager publiquement.",
                  },
                  discussion: {
                    label: "Discussion",
                    description:
                      "Créez un espace de discussion simple pour votre groupe. Choisissez cette option si vous souhaitez simplement créer un espace de discussion ponctuel pour un groupe de personnes.",
                  },
                },
                intention: {
                  label: "Nom de l'espace",
                  placeholder: "Comment s'appelle votre espace ?",
                },
                content: {
                  label: "Nom de l'espace",
                  description: "Écrivez quelque chose...",
                  placeholder: "Écrivez quelque chose...",
                },
                spaceFormat: {
                  label: "Format de l'espace",
                  placeholder: "Choisissez une option",
                },
                eventDescription:
                  "Les informations ajoutées ci-dessous seront transmises au calendrier du membre lorsqu'il ajoutera l'événement.",
                hangoutLink: {
                  label: "URL de l'événement (lien Zoom, etc.)",
                  placeholder: "Quel est le lien de votre appel vidéo",
                },
                eventStartTime: "Date et heure de début de l'événement",
                date: {
                  placeholder: "Choisir une date",
                },
                eventEndDate: "Date et heure de fin de l'événement (optionnel)",
                privacyLevelText: "Niveau de confidentialité",
                privacy: {
                  open: "Ouvert",
                  private: "Privé",
                },
                joinAsMember: {
                  label: "Rejoindre cet espace en tant que membre",
                },
                publishDate: {
                  label: "Publier à la date",
                },
                publishDateTime: "Date et heure de publication",
                create: "Créer l'espace",
              },
              interestedForm: {
                title: "S'inscrire à cet événement",
                createAlertTitle: "Merci de vous être inscrit !",
                createAlertButton: "OK",
                name: {
                  label: "Prénom",
                  placeholder: "Indiquez votre prénom",
                },
                email: {
                  label: "E-mail",
                  placeholder: "Indiquez votre adresse e-mail",
                },
                register: "S'inscrire",
                joinWithSutra: "Rejoindre avec mon compte Sutra",
              },
              linkForm: {
                linkErrMessage: "Ce n'est pas un lien d'espace valide",
                link: {
                  label: "",
                  placeholder: "Lien vers l'espace",
                  errors: {
                    space:
                      "L'espace que vous souhaitez partager est privé ou n'existe pas !",
                    event:
                      "L'espace que vous souhaitez partager est privé, n'est pas un événement ou n'existe pas !",
                  },
                },
              },
              listForm: {
                intention: {
                  placeholder: "Titre",
                },
              },
            },
            header: {
              onLeave: {
                title: "Êtes-vous sûr(e) ?",
                message:
                  "Vous serez supprimé(e) en tant que membre de cet espace et devrez être réajouté(e) pour y avoir à nouveau accès.",
                confirm: "Oui, veuillez me supprimer",
                deny: "Non",
              },
              onArchive: {
                title: "Êtes-vous sûr(e) ?",
                message:
                  "Cet espace sera archivé et les membres n'y auront plus accès.",
                confirm: "Oui",
                deny: "Non",
              },
              onUnarchive: {
                title: "Êtes-vous sûr(e) ?",
                message:
                  "Cet espace sera désarchivé et les membres y auront à nouveau accès.",
                confirm: "Oui",
                deny: "Non",
              },
              onDelete: {
                title: "Êtes-vous sûr(e) ?",
                message:
                  "Cet espace sera supprimé et les membres n'y auront plus accès.",
                confirm: "Oui",
                deny: "Non",
              },
              onFix: {
                title: "Êtes-vous sûr(e) ?",
                message:
                  "Sutra va essayer de recréer cet espace de contenu à partir des données héritées.",
                confirm: "Oui, réparer",
                deny: "Non",
                confirmationTitle: "Information",
                confirmationMessage: "Espace réparé, rechargement en cours...",
                confirmationError: "Cet espace ne peut pas être réparé",
              },
              buttons: {
                uncomplete: "Marquer comme incomplet",
                markComplete: "Marquer comme complet",
                edit: "Modifier",
                post: "message",
                page: "page",
                space: "l'espace",
                returnTo: "Retour à",
                editTitle: "Modifier le titre",
                viewPage: "Voir la page",
                editDescription: "Modifier la description",
                appearance: "Apparence",
                manage: "Gérer",
                members: "Membres",
                leave: "Quitter l'espace",
                delete: "Supprimer l'espace",
                duplicate: "Dupliquer l'espace",
                template: "Créer un modèle",
                archive: "Archiver l'espace",
                unarchive: "Désarchiver l'espace",
                fix: "Réparer",
                joinEvent: "S'inscrire",
                joinSpace: "Rejoindre l'espace",
                preview: "Aperçu",
                draft: "Brouillon",
                published: "Publié",
                publish: "Publier",
                returnToSettings: "Retour aux paramètres",
                returnToSpace: "Retour à l'espace",
                undo: "Annuler",
                openFullScreen: "Ouvrir en plein écran",
                copyToClipboard: "Copier dans le presse-papiers",
                copiedMessage: "Lien de l'espace copié dans le presse-papiers",
                versionHistory: "Historique des versions",
                brandingOptions: "OPTIONS DE MARQUE",
              },
              regPagePublishing:
                "Votre page d'inscription est en cours de publication, veuillez patienter...",
              spaceUpdating:
                "Cet espace est en cours de mise à jour, veuillez patienter...",
            },
            members: {
              sectionTitle: "Membres",
              manage: "Gérer les membres",
              invite: "Inviter",
              search: {
                button: "Rechercher",
                placeholder: "Rechercher",
              },
              emptyMessage: "Aucun enregistrement trouvé",
              messageButton: "Message",
              emailButton: "Email",
              copyClipboard: "Copier dans le presse-papiers",
            },
            payment: {
              onCheckIntent: {
                alertTitle: "Erreur de paiement",
                alertMessage:
                  "Votre session a expiré. Veuillez rafraîchir la page pour finaliser votre paiement.",
              },
              onConfirmPayment: {
                alertTitle: "Oops, quelque chose s'est mal passé !",
                alertMessage: "Quelque chose s'est mal passé !",
              },
              completePayment: "Finaliser le paiement",
              join: "Rejoindre l'espace",
            },
            stripe: {
              pay: "Payer",
            },
          },
          survey: {
            form: {
              text: "Vous avez déjà répondu à cette enquête.",
              submitButton: "Soumettre",
            },
            question: {
              yourAnswer: "Votre réponse",
            },
          },
          tiptap: {
            blocks: {
              section1: "Base",
              text: "Texte",
              headline1: "Titre 1",
              headline2: "Titre 2",
              headline3: "Titre 3",
              divider: "Séparateur",
              infobox: "Info-bulle",
              section2: "Espaces",
              content: "Espace de contenu",
              discussion: "Espace de discussion",
              event: "Espace d'événement",
              events: "Espace de calendrier",
              list: "Espace de forum",
              showcase: "Showcase Space",
              section3: "Liste",
              section8: "Special",
              bulletList: "Liste à puces",
              orderedList: "Liste ordonnée",
              checklist: "Liste de contrôle",
              collapsableSection: "Section pliable",
              survey: "Survey or quiz",
              section4: "Média",
              image: "Image",
              embed: "Code d'intégration",
              video: "Vidéo",
              audio: "Audio",
              file: "Fichier",
              zoom: "Zoom recording",
              section5: "Grilles",
              grid1: "1 colonne",
              grid2: "2 colonnes",
              grid3: "3 colonnes",
              grid4: "Colonne de gauche",
              grid5: "Colonne de droite",
              grid6: "4 columns",
              section6: "Boutons",
              interestForm: "Formulaire d'intérêt",
              actionButton: "Bouton d'action",
              markComplete: "Marquer comme complet",
              section7: "Interactif",
              interactiveButton: "Bouton interactif",
              interactiveInput: "Entrée interactive",
              interactiveWait: "Attente interactive"
            },
            components: {
              
              sidebar: {
                alignment: {
                  left: "Gauche",
                  center: "Centre",
                  right: "Droite",
                },
                backgroundColor: {
                  text: "Sélectionner la couleur de fond",
                  clearColor: "Effacer la couleur",
                },
                backgroundImage: {
                  button: "Sélectionner une image",
                  removeButton: "Supprimer l'image",
                  label: "Position",
                  placeholder: "Choisissez une option",
                  leftTop: "en haut à gauche",
                  leftCenter: "au centre à gauche",
                  leftBottom: "en bas à gauche",
                  rightTop: "en haut à droite",
                  rightCenter: "au centre à droite",
                  rightBottom: "en bas à droite",
                  centerTop: "en haut au centre",
                  centerCenter: "centre centre",
                  centerBottom: "en bas au centre",
                  label2: "Répéter",
                  placeholder2: "Choisissez une option",
                  noRepeat: "Pas de répétition",
                  repeat: "Répéter",
                  "repeat-x": "Répéter horizontalement",
                  "repeat-y": "Répéter verticalement",
                  space: "Espace",
                  round: "Arrondi",
                },
                blockId: {
                  placeholder: "ID du bloc",
                  label: "ID du bloc",
                  description:
                    "ID recommandés pour AI briefDescription, aboutWorkshop, whatYouWillLearn1, whatYouWillLearn2, whatYouWillLearn3, whoThisIsFor, aboutUs, quote",
                },
                buttonAction: {
                  label: "Action après soumission",
                  description:
                    "Spécifiez ce qui se produit après que la personne a soumis ses informations via le formulaire sélectionné.",
                  placeholder: "Choisissez une option",
                  label2: "Lien vers",
                  placeholder2: "Choisissez une option",
                  label3: "Action du lien",
                  description3:
                    "Spécifiez si le lien sera ouvert dans l'onglet du navigateur actuel ou dans un nouvel onglet.",
                  placeholder3: "Choisissez une option",
                  callBackComplete: "Texte de l'action d'achèvement",
                  callbacklinkTo: "Lien vers",
                  completeDescription:
                    "Ajoutez un court message de confirmation que les personnes verront après avoir soumis leurs informations.",
                  linkDescription:
                    "La personne sera redirigée vers ce lien après avoir cliqué sur le bouton.",
                  label4: "Lien vers la rubrique espace",
                  description4: "Spécifiez si le lien sera ouvert dans l'onglet actuel du navigateur ou dans un autre onglet ou si le lien renverra vers une section de cette page.",
                  selectSection: "Sélectionner une rubrique",
                  description5: "Cette option vous permet d'accéder à une section spécifique de cette page lorsque vous cliquez sur le lien.",
                  description6: "Pour sélectionner la section, cliquez sur le bouton ci-dessous puis cliquez sur la section vers laquelle vous souhaitez créer un lien.",
                  label5: "La rubrique est liée !",
                  label6: "URL du lien",
                  description7: "Spécifiez le lien URL vers lequel l'utilisateur sera redirigé",
                  label7: "S'ouvre dans l'onglet actuel",
                  label8: "Ouvre un autre onglet",
                  label9: "Ne fais rien",
                  label10: "Rediriger l'utilisateur vers",
                  label11: "Choisir une option",
                  label12: "Un autre espace",
                  label13: "Lien personnalisé",
                  label14: "Cliquez sur une section de la page",
                },
                buttonBackgroundColor: {
                  button: "Effacer la couleur",
                },
                buttonText: {
                  placeholder: "Texte du bouton",
                  label: "Texte du bouton",
                },
                fileName: {
                  placeholder: "Nom du fichier",
                  label: "Nom du fichier",
                },
                interactiveWaitTime: {
                  label1: "Choisissez une option",
                  label2: "Attendre jusqu'à une date",
                  label3: "Attendre un certain nombre de jours",
                  label4: "Date pour afficher le contenu",
                  placeholder: "Date pour afficher le contenu",
                  label5: "1 jour",
                  label6: "2 jours",
                  label7: "3 jours",
                  label8: "4 jours",
                  label9: "5 jours",
                  label10: "6 jours",
                  label11: "1 semaine",
                  label12: "2 semaines",
                  label13: "1 mois",
                },
                linkAction: {
                  label: "Action du lien",
                  description:
                    "Spécifiez si le lien s'ouvrira dans l'onglet actuel ou dans un nouvel onglet.",
                  placeholder: "Choisissez une option",
                  label2: "Ouvrir dans l'onglet actuel",
                  label3: "Ouvrir dans un nouvel onglet",
                },
                margin: {
                  topMargin: "Marge supérieure -",
                  text: "Marge inférieure -",
                  bottom: "Inférieure",
                  left: "Gauche",
                  leftMargin: "Marge gauche -",
                  right: "Droite",
                  rightMargin: "Marge droite",
                },
                padding: {
                  top: "Supérieure",
                  topPadding: "Rembourrage supérieur -",
                  bottom: "Inférieure",
                  bottomPadding: "Rembourrage inférieur -",
                  left: "Gauche",
                  leftPadding: "Rembourrage gauche",
                  right: "Droite",
                  rightPadding: "Rembourrage droit",
                },
                radius: {
                  square: "Carré",
                  round: "Ronde",
                  circle: "Cercle",
                },
                spaceAttrs: {
                  addCover: "Ajouter une couverture",
                  text: "La dimension recommandée pour les images de couverture est de 1200 x 300 pixels",
                  addCover2: "Ajouter une couverture",
                  text2:
                    "La dimension recommandée pour les images de couverture est de 1200 x 300 pixels",
                  removeCover: "Supprimer la couverture",
                },
                verticalAllignment: {
                  top: "Haut",
                  center: "Centre",
                  bottom: "Bas",
                },
                width: {
                  full: "Pleine largeur",
                  size: "3/4",
                  size2: "1/2",
                  actual: "Réelle",
                },
              },
              blockMenuActions: {
                label1: "Ajouter un bloc",
                label2: "Ajouter un emoji",
                label3: "Paramètres",
                label4: "Supprimer",
                label5: "Déplacer",
                label6: "Aller à l'espace ou double-cliquer sur le bloc",
                label7: "Remplacer",
                label8: "Alignement",
                left: "Gauche",
                center: "Centre",
                right: "Droite",
                write: "Écrire quelque chose...",
                label9: "Ajouter un bloc",
                label10: "Titre 1",
                h1: "H1",
                label11: "Titre 2",
                h2: "H2",
                label12: "Ajouter un espace",
                label13: "Ajouter une image",
                label14: "Ajouter une vidéo",
                label15: "Ajouter une grille",
                label16: "Ajouter un modèle",
                label17: "Ajouter une mise en page",
                label18: "Dupliquer",
              },
              blocksMenu: {
                basicBlock: "Blocs de base",
                mediaBlock: "Blocs multimédias",
                contentBlock: "Blocs de contenu",
              },
              blocksMenuInline: {
                file: "Fichier",
                image: "Image",
                video: "Vidéo",
                label1: "Ajouter un emoji",
                label2: "Ajouter une image",
                label3: "Afficher la barre de formatage",
              },
              blocksMenuSimple: {
                label1: "Ajouter",
                file: "Fichier",
                image: "Image",
                video: "Vidéo",
                audio: "Audio",
                label2: "Ajouter un emoji",
                label3: "Ajouter une image",
                label4: "Afficher la mise en forme",
                cancelButton: "Annuler",
              },
              emptyMessage: {
                text: "Ceci est un espace de contenu vide. Vous pouvez",
                button1: "modifier cette page",
                text2: "ou",
                button2: "utiliser un modèle",
                text3: "pour commencer",
                text4: "Un espace vide rempli de potentiel...",
              },
              fixedBottomMenu: {
                label1:
                  "Ajouter des éléments tels que des titres, des images et des vidéos",
                addBlock: "Ajouter un bloc",
                label2:
                  "Ajouter un espace de contenu, de discussion ou d'événement",
                space: "Espace",
                label3:
                  "Ajouter une grille pour utiliser plusieurs colonnes pour le contenu",
                grid: "Grille",
                label4: "Ajouter une option de conception pré-créée",
                layouts: "Mises en page",
                label5:
                  "Ajouter un modèle pour configurer automatiquement votre espace",
                template: "Modèles",
              },
              formatMenu: {
                text: "Texte",
                smallText: "Petit texte",
                largeText: "Grand texte",
                heading1: "Titre 1",
                heading2: "Titre 2",
                heading3: "Titre 3",
                bulletedList: "Liste à puces",
                orderedList: "Liste ordonnée",
                text2: "Texte",
                label1: "Mise en forme",
                text3: "Texte",
                numberedList: "Liste numérotée",
                label2: "Alignement",
                left: "Gauche",
                center: "Centre",
                right: "Droit",
                label3: "Action du lien",
                description:
                  "Spécifiez si le lien sera ouvert dans l'onglet actuel du navigateur ou dans un nouvel onglet.",
                placeholder: "Choisissez",
                label4: "S'ouvre dans l'onglet actuel",
                label5: "S'ouvre dans un autre onglet",
                label6: "Format du lien",
                description2:
                  "Spécifiez si le lien apparaîtra sous forme de texte ou de lien décoratif.",
                placeholder2: "Choisissez",
                label7: "Afficher comme texte",
                label8: "Afficher comme lien décoratif",
                save: "Enregistrer",
                delete: "Supprimer",
                label9: "Barré",
                label10: "Souligné",
                label11: "Bloc de code",
                label12: "Couleur du texte",
                placeholder3: "Valeur HEX",
                unsetColor: "Supprimer la couleur",
                label13: "Couleur de surlignage",
                label14: "Lien vers la rubrique espace",
                description3: "Spécifiez si le lien sera ouvert dans l'onglet actuel du navigateur ou dans un autre onglet ou si le lien renverra vers une section de cette page.",
                selectSection: "Sélectionner une rubrique",
                description4: "Cette option vous permet d'accéder à une section spécifique de cette page lorsque vous cliquez sur le lien.",
                description5: "Pour sélectionner la section, cliquez sur le bouton ci-dessous puis cliquez sur la section vers laquelle vous souhaitez créer un lien.",
                label15: "La rubrique est liée !",
                label16: "URL du lien",
                description6: "Spécifiez le lien URL vers lequel l'utilisateur sera redirigé",
              },
              formatMenuSimple: {
                t: "T",
                h1: "H1",
                h2: "H2",
                h3: "H3",
                label1: "Action du lien",
                description1:
                  "Spécifiez si le lien sera ouvert dans l'onglet actuel du navigateur ou dans un nouvel onglet.",
                placeholder1: "Choisissez",
                label2: "S'ouvre dans l'onglet actuel",
                label3: "S'ouvre dans un autre onglet",
                label4: "Format du lien",
                description2:
                  "Spécifiez si le lien apparaîtra sous forme de texte ou de lien décoratif.",
                placeholder2: "Choisissez",
                label5: "Afficher comme texte",
                label6: "Afficher comme lien décoratif",
                save: "Enregistrer",
                delete: "Supprimer",
                label7: "Mise en forme",
                left: "Gauche",
                center: "Centre",
                right: "Droite",
                placeholder3: "Valeur HEX",
                placeholder4: "Couleur HEX",
                unsetColor: "Supprimer la couleur",
              },
              inlineMenu: {
                h1: "H1",
                h2: "H2",
                bulletList: "Liste à puces",
                checkList: "Liste de contrôle",
                circle: "Cercle",
                moreOption: "Plus d'options",
              },
              interactiveInput: {
                hideResponses: "Masquer les autres réponses",
                showResponses: "Afficher les autres réponses",
                hideResponses2: "Masquer les réponses",
                hidePrivateMessages: "Masquer les messages privés",
                showPrivateMessages: "Afficher les messages privés",
                showResponses2: "Afficher les réponses",
                loadMoreResponse: "Charger plus de réponses",
                placeholder: "Écrire quelque chose...",
                text: "Voulez-vous que cette réponse soit visible pour les autres participants ?",
              },
              newMedia: {
                add: "Ajouter",
              },
              regPageColor: {
                clearColor: "Claire couleur",
              },
              regPageColors: {
                text: "Apparence de la page d'inscription",
                applyAppearance: "Appliquer l'apparence",
                title1: "Sélectionner la couleur de fond",
                title2: "Sélectionner la couleur du texte",
                label1: "Couleur de fond",
                label2: "Couleur du texte par défaut",
                label3: "Large largeur de page",
              },
              resource: {
                areYouSure: "Êtes-vous sûr(e) ?",
                label1: "Sera publié à cette date",
                edit: "Modifier",
                addPage: "Ajouter une page",
              },
              sideBlocksMenuBak: {
                addBlock: "Ajouter un bloc",
                back: "Retour",
              },
              sideBlocksMenu: {
                text: "Les mises en page sont des options de conception pré-créées que vous pouvez ajouter à votre espace.",
                text2:
                  "Commencez avec une sélection de modèles de page complète personnalisables",
                layout: "Mises en page",
                template: "Modèles",
                addBlock: "AJOUTER UN BLOC",
                block: "Blocs",
              },
              sideConfigurationsMenu: {
                title: "PARAMÈTRES DU BLOC",
                label1: "Nom de l'espace et image de couverture",
                label2: "Attributs du fichier",
                label3: "Options de saisie interactive",
                attrPlaceholder1: "Écrire quelque chose...",
                attrLabel: "Placeholder",
                attrLabel2:
                  "Permettre aux participants de voir toutes les réponses",
                attrLabel3:
                  "Afficher les réponses avant que le participant ne réponde",
                attrLabel4:
                  "Permettre au participant de choisir si sa réponse est visible par les autres",
                attrLabel5: "Autoriser plusieurs réponses par personne",
                attrLabel6:
                  "Permettre au participant de passer cette indication",
                label4: "Options de saisie interactive",
                label5: "Rembourrage",
                label6: "BLOC",
                label7: "COLONNE",
                label8: "Marge",
                label9: "GRILLE",
                label10: "Alignement vertical",
                label11: "Options du bouton",
                label12: "Couleur de fond du bouton",
                label13: "Couleur du texte du bouton",
                text: "Coins du bouton",
                label14: "Fond",
                label15: "Couleur",
                label16: "Image",
                label17: "Réinitialiser",
                resetBlock: "Réinitialiser le bloc",
                label18: "Alignement",
                label19: "Rayon de bordure",
                label20: "Coins",
                label21: "Nom du bloc",
                label22: "Options de notification par e-mail",
                label23: "Envoyer un e-mail de notification",
                attrLabel8: "Inclure un message personnalisé",
                attrLabel9: "Inclure le contenu de la section suivante",
                attrLabel10: "Inclure le contenu complet",
                attrLabel11: "Aperçu court",
                attrLabel12: "Sujet",
                attrLabel13: "Message",
                defaultWaitLabel: "La section suivante a été publiée dans {{space_name}}",
                defaultWaitMessage: "Bonjour {{first_name}} - je voulais juste vous faire savoir que la prochaine section de {{space_name}} a été publiée. {{space_link_button}}"
              },
              takeSurvey: {
                text: "Ce sondage est vide",
              },
              tiptapAi: {
                placeholder: "Écrire une brève description",
                label: "Veuillez rédiger 2 ou 3 phrases décrivant votre offre.",
                placeholder2: "ex. coachs, consultants et thérapeutes",
                label2: "Pour qui cette offre est-elle destinée ?",
                text: "Bonjour, nous expérimentons une nouvelle fonctionnalité d'IA. Voulez-vous que Sutra génère automatiquement du contenu pour votre modèle en utilisant l'IA ?",
              },
              uploader: {
                label1: "Lien",
                label2: "Code",
                placeholder: "Coller le lien de l'image ou du fichier",
                label3: "Lien intégré",
                placeholder2: "Coller le code intégré -> <iframe>...</iframe>",
                label4: "Code intégré",
                saveEmbed: "Enregistrer l'intégration",
                placeholder3: "Rechercher des images",
                saveImage: "Enregistrer l'image",
              },
              viewPage: {
                text: "Cette page est vide",
              },
            },
          },
          users: {
            payment: {
              completePayment: "Finaliser le paiement",
              join: "Rejoindre l'espace",
              pay: "Payer",
            },
            stepper: {
              step1: "Étape 1",
              step1Description: "Se connecter",
              step2: "Étape 2",
              step2Description: "Finaliser le paiement",
              step3: "Étape 3",
              step3Description: "Obtenir l'accès",
            },
            start: {
              title: "Bienvenue",
              subtitle: "Veuillez saisir votre adresse e-mail ci-dessous",
              email: "Email",
              submit: "Continuer",
              invalidEmailErrorMsg: "Adresse e-mail invalide",
            },
            signIn: {
              title: "Connectez-vous à votre compte",
              subtitle: "Vous n'avez pas de compte ?",
              subtitleLink: "S'inscrire",
              email: "Email",
              password: "Mot de passe",
              submit: "Se connecter",
              magicLink: "Obtenir un lien magique",
              forgotPassword: "Mot de passe oublié ?",
              invalidEmailErrorMsg: "Adresse e-mail invalide",
            },
            signUp: {
              invalidEmailErrorMsg: "Adresse e-mail invalide",
              invalidPasswordErrorMsg:
                "Le mot de passe doit comporter au moins 8 caractères",
              invalidFirstNameErrorMsg:
                "Le prénom doit comporter au moins 3 caractères",
              title: "Créer un nouveau compte",
              subtitle: "Vous avez déjà un compte ?",
              subtitleLink: "Se connecter",
              firstName: "Prénom",
              lastName: "Nom de famille",
              username: "Nom d'utilisateur",
              email: "Email",
              password: "Mot de passe",
              emailNewsletterConfirmation:
                "Inscrivez-vous à la newsletter de Sutra pour être informé(e) des webinaires gratuits sur les processus de groupe, l'enseignement transformationnel et les affaires relationnelles.",
              submit: "S'inscrire",
              legalPrefix: "En vous inscrivant à Sutra, vous acceptez nos",
              terms: "Conditions d'utilisation",
              privacy: "Politique de confidentialité",
            },
            spaceReference: {
              total: "Total aujourd'hui",
            },
            planReference: {
              total: "Total",
              total_today: "Total aujourd'hui",
              due_in: "Dû dans 14 jours",
              paymentDetails: {
                annual: "Abonnement annuel de",
                month: "Abonnement mensuel de",
              },
            },
            resetPassword: {
              title: "Réinitialisez votre mot de passe",
              subtitle:
                "Veuillez entrer un nouveau mot de passe pour votre compte Sutra ci-dessous.",
              password: "Mot de passe",
              passwordConfirmation: "Confirmation du mot de passe",
              submit: "Réinitialiser le mot de passe",
            },
            recover: {
              title: "Mot de passe oublié ?",
              subtitle:
                "Veuillez saisir votre adresse e-mail ci-dessous et nous vous enverrons des instructions pour réinitialiser votre mot de passe.",
              email: "Email",
              submit: "Envoyez-moi les instructions",
              footer: "Vous avez déjà un compte ?",
              footerLink: "S'identifier",
            },
            magicLink: {
              invalidEmailErrorMsg: "Adresse e-mail invalide",
              title: "Demander un lien magique",
              subtitle:
                "Veuillez saisir votre adresse e-mail ci-dessous et nous vous enverrons un lien qui vous connectera automatiquement.",
              email: "Email",
              submit: "Continuer",
              footer: "Se connecter avec un e-mail",
              alertTitle: "Vous y êtes presque...",
              alertMessage:
                "Nous avons envoyé le lien magique à votre adresse e-mail. CECI EST UN TEST, AUCUN E-MAIL RÉEL N'A ÉTÉ ENVOYÉ.",
            },
            edit: {
              title: "Veuillez compléter votre profil",
              addProfileImage: "Ajouter une image de profil",
              removeProfileImage: "Supprimer l'image de profil",
              bioPlaceholder: "Parlez un peu de vous...",
              bio: "Biographie",
              submit: "Enregistrer",
              skip: "Passer et le faire plus tard",
            },
            magicSignin: {
              text: "Connectez-vous...",
            },
            profile: {
              button: "Message",
              button2: "Modifier le profil",
              title: "Espaces",
            },
            profileModal: {
              profile: "Profil",
              message: "Message",
              editProfile: "Modifier le profil",
              openProfile: "Ouvrir le profil",
              spaces: "Espaces",
              selectImage: "Sélectionner une image",
            },
            signOut: {
              loggedOUt: "Déconnecté",
              title: "Vous êtes déconnecté",
              message: "À bientôt !",
            },
          },
          errors: {
            basic: "Oups, quelque chose s'est mal passé !",
          },
        },
      },
    },
  });

export default i18n;
