import React from 'react';
import { useSelector } from 'react-redux';

import { useMediaQuery } from '@mantine/hooks';

import { ScrollArea, createStyles } from '@mantine/core';

import NavbarContent from './NavbarContent'
import useSidebarStyles from "../../utils/SidebarStyles"

const useStyles = createStyles((theme, {spaceView, settingsView}) => ({
  appSideFrame: {
    width: theme.other.sideFrameWidth,
    height: `calc(100vh - ${theme.other.headerHeight}px)`,
    boxSizing: "border-box",
    overflow: "hidden",
    backgroundColor: "#fff",
    borderRight: !spaceView < 0 && !settingsView ? "1px solid #ececec" : "none"
  },
  appSideFrameHover: {
    display: "block",
    width: theme.other.sideFrameWidth,
    height: `calc(100vh - ${theme.other.headerHeight}px)`,
    boxSizing: "border-box",
    overflow: "hidden",
    backgroundColor: "#fff",
    position: "absolute",
    boxShadow: "0px 15px 20px 5px #999",
    zIndex: 299
  },
}))

export default function Navbar() {

  const { spaceView, settingsView } = useSidebarStyles();
  const { classes, cx, theme } = useStyles({spaceView, settingsView});
  const hiddenSidebar = useSelector(state => state.hiddenSidebar);
  const hoverSidebar = useSelector(state => state.hoverSidebar);
  const mediumScreen = useMediaQuery(`(max-width: ${theme.other.breakpoints.md}px)`);

  const isStaticSpaceRoute = () => {
    return (window.location.pathname.indexOf("/space") >= 0 || window.location.pathname.indexOf("/user") >= 0 || window.location.pathname.indexOf("/app/pay") >= 0) && (window.location.pathname.indexOf("/register") >= 0 || window.location.pathname.indexOf("/registration") >= 0 || window.location.pathname.indexOf("/private") >= 0 || window.location.pathname.indexOf("/pending") >= 0 || (window.location.pathname.indexOf("/survey") >= 0 && window.location.pathname.indexOf("/surveys") == 0) || window.location.pathname.indexOf("/demo") >= 0 || window.location.pathname.indexOf("/pay") >= 0 || window.location.href.split("/").length == 5)
  }

  const alwaysHide = () => {
    return window.location.pathname.indexOf("/home") >= 0 || (window.location.pathname.indexOf("/setting") >= 0 && window.location.pathname.indexOf("/space") < 0) || window.location.pathname.indexOf("/app") >= 0
  }
  
  if ((hiddenSidebar.hidden && !alwaysHide()) || (mediumScreen && !hoverSidebar.hover) || isStaticSpaceRoute()){

    return null
  }

  return (
    <div
      className={ cx(classes.appSideFrame, { [classes.appSideFrameHover]: hoverSidebar.hover }) }>
      <ScrollArea
        scrollbarSize={12}
        style={{height: "calc(100vh - 60px)"}}>
        <NavbarContent />
      </ScrollArea>
    </div>
  );
}
