import React, { useState, useEffect, useRef } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setHiddenSidebar, setHiddenHeader } from "../../../store/app";

import SpaceReference from "../../users/SpaceReference";
import StripeForm from "./StripeForm";

import StripeModel from "../../../models/Stripe";
import SpaceModel from "../../../models/Space";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

import {
  Paper,
  Title,
  Container,
  Button,
  Box,
  Divider,
  Stepper,
  Grid,
  ScrollArea,
  Center,
  Loader,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

export default function Payment(props) {
  const { t, i18n } = useTranslation();

  const space = useSelector((state) => state.space);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const intent = useRef(null);

  const [spaceReference, setSpaceReference] = useState();

  const location = useLocation();

  const intervalId = useRef(null);
  const currency = useRef(null);
  const frequency = useRef(null);
  const stripePromise = useRef(null);
  const stripeOptions = useRef(null);
  const price = useRef(null);
  const returnUrl = useRef(null);
  const [loaded, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(0);
  // const [hideSteps, setHideSteps] = useState(false);
  const freeByCoupon = useRef(false);
  const coupon = useRef(false);

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 1000);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    const isMobile = new URLSearchParams(location.search).get("mobile");
    if (isMobile) {
      // setHideSteps(true)
      dispatch(setHiddenHeader({ hidden: true }));
      setTimeout(() => {
        dispatch(setHiddenSidebar({ hidden: true }));
      }, 1000);
    }
  }, [location]);

  const onCreate = () => {
    StripeModel.onCreate(
      space.slug,
      parseFloat(price.current),
      frequency.current,
      (data) => {
        if (data.intent_id == "error") {
          setError(true);
          setLoading(false);
          return;
        } else {
          setError(false);
        }
        stripePromise.current = loadStripe(data.publishable_key);
        stripeOptions.current = { clientSecret: data.client_secret };
        returnUrl.current = data.return_url;
        intent.current = data.intent_id;
        if (data.coupon.id) {
          freeByCoupon.current = data.coupon.free;
          coupon.current = data.coupon;
        } else {
          freeByCoupon.current = false;
          coupon.current = null;
        }
        setLoading(false);
      },
      (data) => {}
    );
  };

  const onPriceChange = (_price, _currency, _frequency) => {
    price.current = _price;
    currency.current = _currency;
    frequency.current = _frequency;
    setLoading(true);

    onCreate();
  };

  const onPay = async (stripe, elements) => {
    if (!stripe || !elements) {
      return;
    }

    StripeModel.onCheckIntent(
      space.slug,
      intent.current,
      (data) => {
        if (!data.data.is_valid) {
          Swal.fire(
            t("spaces.payment.onCheckIntent.alertTitle"),
            t("spaces.payment.onCheckIntent.alertMessage"),
            "error"
          );
          setProcessing(processing+1);
          return;
        }

        window.$selectedCouponCode = null;
        window.$selectedCoupon = null;

        if (intent.current.indexOf("seti") >= 0) {
          stripe
            .confirmSetup({
              elements,
              confirmParams: {
                return_url: returnUrl.current,
              },
            })
            .then(function (result) {
              if (result.error) {
                Swal.fire(t("errors.basic"), result.error.message, "error");
              }
              setProcessing(processing+1);
            });
        } else {
          stripe
            .confirmPayment({
              //`Elements` instance that was used to create the Payment Element
              elements,
              confirmParams: {
                return_url: returnUrl.current,
              },
            })
            .then(function (result) {
              if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                Swal.fire(
                  t("spaces.payment.onConfirmPayment.alertTitle"),
                  result.error.message,
                  "error"
                );
              }
              setProcessing(processing+1);
            });
        }
      },
      (data) => {}
    );
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  const setStepper = () => {};
  const setShowSpaceReference = () => {};

  const onJoin = () => {
    if (coupon.current && coupon.current.id) {
      SpaceModel.onJoinWithCoupon(
        space.slug,
        coupon.current.code,
        price.current,
        (data) => {
          navigate(`${data.redirect_to.url}`);
        },
        (data) => {}
      );
    } else {
      SpaceModel.onJoin(
        space.slug,
        (data) => {
          navigate(`${data.redirect_to.url}`);
        },
        (data) => {}
      );
    }
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        {!smallScreen && space.slug ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={1} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : (
          ""
        )}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                <Box pt={20} pb={5} pl={35} pr={35}>
                  <Title
                    sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                    order={1}
                  >
                    {t("spaces.payment.completePayment")}
                  </Title>
                </Box>
                <Divider
                  sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                  my="sm"
                />
                <Box
                  pt={20}
                  pb={5}
                  pl={35}
                  pr={35}
                  key={`strip-payment-container-${new Date()}`}
                >
                  {loaded && (
                    <Center>
                      <Loader />
                    </Center>
                  )}
                  {error && price.current > 0 && (
                    <Center mb={30}>
                      Payments are temporarly disabled for this space
                    </Center>
                  )}
                  {space.slug &&
                  stripePromise.current &&
                  stripeOptions.current &&
                  price.current > 0 &&
                  !loaded &&
                  !error &&
                  !freeByCoupon.current ? (
                    <Elements
                      stripe={stripePromise.current}
                      options={stripeOptions.current}
                    >
                      <StripeForm
                        onPay={onPay}
                        amount={price.current}
                        currency={currency.current}
                        frequency={frequency.current}
                        processing={processing}
                      />
                      
                    </Elements>
                  ) : (
                    space.slug &&
                    !loaded &&
                    (!error || price.current == 0) && (
                      <Button
                        fullWidth
                        mt={0}
                        radius="xl"
                        size="lg"
                        mb={15}
                        onClick={() => onJoin()}
                      >
                        {t("spaces.payment.join")}
                      </Button>
                    )
                  )}
                </Box>
              </Paper>
            </Container>
          </Grid.Col>
          {space.slug && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                slug={space.slug}
                setSpaceReference={setSpaceReference}
                onPriceChange={onPriceChange}
                setStepper={setStepper}
                setShowSpaceReference={setShowSpaceReference}
              />
            </Grid.Col>
          )}
        </Grid>
      </Container>
    </ScrollArea>
  );
}
