import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Drawer, Text } from "@mantine/core";

import Form from "../../spaces_neo/form/Form";

import { setTiptapSidemenu } from "../../../store/app";

import { addDummyCursor, removeDummyCursor } from "../utils/dummyCursor";

const NewSpace = (props) => {
  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);

  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);
  const [spaceType, setSpaceType] = useState("content");

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "newSpace") {
      setOpened(true);
      setSpaceType(tiptapSidemenu.option);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "newSpace") {
        dispatch(setTiptapSidemenu({ opened: null }));
        removeDummyCursor();
      }
    } else {
      addDummyCursor();
    }
  }, [opened]);

  const onSave = (spaceId, spaceLink, spaceName = "") => {
    document.activeElement.blur();

    setTimeout(() => {
      tiptap.editor
        .chain()
        .focus()
        // .enter()

        .insertContent(
          `<circle id="${spaceId}" circle_id="${space.slug}" buttonText="${spaceName}" radius="circle-radius" class="tiptap-size-actual" buttonBackgroundColor="${colors.primary_button_background_color}" buttonBorderColor="${colors.primary_button_background_color}" buttonTextColor="${colors.primary_button_text_color}"></circle>`
        )
        // .enter()
        //
        // .enter()
        .run();

      setOpened(false);
      tiptap.editor
        .chain()
        .setTextSelection(tiptap.editor.view.state.selection.to - 1)
        .enter()
        .run();

      setTimeout(() => {
        tiptap.editor
          .chain()
          .setTextSelection(tiptap.editor.view.state.selection.to + 1)
          .run();
      }, 500);
    }, 1000);
  };

  return (
    <Drawer
      position="left"
      opened={opened}
      onClose={() => setOpened(false)}
      title={<Text size="xs">{`NEW SPACE`}</Text>}
      padding="xl"
      size={"400px"}
      overlayOpacity={0.1}
      zIndex={99999}
    >
      <Form
        parentId={space.slug}
        onSave={onSave}
        version={spaceType}
        padding={true}
      />
    </Drawer>
  );
};

export default NewSpace;
