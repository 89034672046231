import Broadcaster from "../../../helpers/Broadcaster";

const isVideoURLValid = (url, upload) => {
  try {
    return fetch(`/api/v4/proxy?url=${url}`, { method: "head" })
      .then((response) => {
        // Check if the response status is in the 200-299 range, indicating a successful request.
        if (response.ok) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        // An error occurred, which likely means the URL is not valid or doesn't exist.
        return false;
      });
  } catch {
    return false;
  }
};

export function checkUploadStatus(resize, editor, getPos, attributes, type) {
  let timeoutId = null;
  let intervalId = null;
  let intervalCount = null;

  const uploadTag = document.createElement("span");
  if (type != "video") {
    uploadTag.innerHTML = "Uploading...";
  } else {
    if (attributes.uploader.status.indexOf("error") >= 0) {
      uploadTag.innerHTML = `This video cannot be processed, please try again...`;
      uploadTag.classList.add("video-processing-tag");
      uploadTag.style.width = resize.style.width;
      uploadTag.style.backgroundColor = "#e53935";
      uploadTag.style.textAlign = "center";
    }
    else if (attributes.uploader.status.indexOf("zoom-pending") >= 0) {
      uploadTag.innerHTML = `Your recording will be posted here after the meeting ends.`;
      uploadTag.classList.add("video-processing-tag");
      uploadTag.style.width = resize.style.width;
      uploadTag.style.textAlign = "center";
    } else {
      uploadTag.innerHTML = `This video is ${attributes.uploader.status}...`;
      uploadTag.classList.add("video-processing-tag");
      uploadTag.style.width = resize.style.width;
      uploadTag.style.textAlign = "center";

      if (!intervalCount) {
        intervalCount = 0;
        intervalId = setInterval(() => {
          intervalCount++;
          if (intervalCount < 100) {
            const videoURL = `${attributes.uploader.url}`;
            isVideoURLValid(videoURL).then((valid) => {
              if (valid) {
                //splice upload
                attributes.uploader.status = "completed";

                Broadcaster.send(
                  "set_uploadFile",
                  document.getElementById(attributes.uploader.id),
                  {
                    uploader: { ...attributes.uploader },
                    src: attributes.uploader.url,
                  }
                );
                // upload.status = "used";
                // markUploadAs(upload);
              } else {
                console.log("Video URL is not valid or does not exist.");
              }
            });
          } else {
            //splice upload
            attributes.uploader.status = "completed";

            Broadcaster.send(
              "set_uploadFile",
              document.getElementById(attributes.uploader.id),
              {
                uploader: { ...attributes.uploader },
                src: attributes.uploader.url,
              }
            );
          }
        }, 5000);
      }
    }
  }

  uploadTag.setAttribute("id", attributes.uploader.id);

  resize.style.position = "relative";
  resize.append(uploadTag);

  let uploadingStatus = null;

  // if (editor.isEditable) {
  if (window.$currentUploads) {
    for (let i = 0; i < window.$currentUploads.length; i++) {
      const upload = window.$currentUploads[i];
      if (upload.id == attributes.uploader.id) {
        uploadingStatus = "pending";

        if (
          upload.status == "completed" &&
          attributes.uploader.status != "completed"
        ) {
          uploadingStatus = "completed";

          Broadcaster.send("new_uploadFile", document.body, {
            id: upload.id,
            status: "used",
            trigger: "checkUploadStatus 1",
          });

          setTimeout(() => {
            const element = document.getElementById(attributes.uploader.id);
            if (element) {
              const rect = element.getBoundingClientRect();
              const auxPos = editor.view.posAtCoords({
                left: rect.left,
                top: rect.top,
              });
              if (auxPos && auxPos.inside && auxPos.inside >= 0) {
                const { tr } = editor.view.state;
                tr.setNodeMarkup(auxPos.inside, undefined, {
                  ...attributes,
                  src: upload.url,
                  uploader: { ...upload },
                });
                editor.view.dispatch(tr);
              }
            }
          }, 300);
        }

        break;
      }
    }
  }

  if (!uploadingStatus && editor.isEditable && type != "video") {
    uploadTag.innerHTML = "This upload was cancelled";
  }

  // if (
  //   uploadingStatus == "pending" ||
  //   uploadingStatus == "uploading" ||
  //   uploadingStatus == "processing"
  // ) {
  let element = document.getElementById(attributes.uploader.id);

  if (element) {
    try {
      Broadcaster.unRegisterElement("set_uploadFile", element);
    } catch (error) {}
  }

  setTimeout(() => {
    element = document.getElementById(attributes.uploader.id);
    if (element) {
      Broadcaster.receive("set_uploadFile", element, (event, data) => {
        if (attributes.uploader.id != data.uploader.id) {
          return;
        }
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          try {
            const rect = element.getBoundingClientRect();
            const auxPos = editor.view.posAtCoords({
              left: rect.left,
              top: rect.top,
            });
            if (auxPos) {
              // editor.commands.setNodeSelection(auxPos.inside);
              // editor.commands.updateAttributes(type, {
              //   src: data.src,
              //   uploader: { ...data.uploader },
              // });
              // debugger

              const theNode = editor.view.state.doc.nodeAt(auxPos.inside);

              if (
                theNode.attrs.uploader &&
                theNode.attrs.uploader.id == attributes.uploader.id
              ) {
                const { tr } = editor.view.state;
                tr.setNodeMarkup(auxPos.inside, undefined, {
                  ...attributes,
                  src: data.src,
                  uploader: { ...data.uploader },
                });
                editor.view.dispatch(tr);
              }
            }
          } catch {}
        }, 1000);

        if (data.uploader.status == "completed") {
          Broadcaster.send("new_uploadFile", document.body, {
            id: data.uploader.id,
            status: "used",
            trigger: "checkUploadStatus 2",
          });
        }
        if (data.uploader.status.indexOf("error") >= 0) {
          uploadTag.innerHTML = `This video cannot be processed, please try again...`;
          uploadTag.classList.add("video-processing-tag");
          uploadTag.style.width = resize.style.width;
          uploadTag.style.backgroundColor = "#e53935";
          uploadTag.style.textAlign = "center";
        }
      });
    }
  }, 500);
  // }
  // }
}
