import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import useSidebarStyles from "../../../utils/SidebarStyles";
import defaultSpaceColors from "../../../collections/defaultSpaceColors";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import tinycolor from "tinycolor2";

import isSpaceThemed from "../../../utils/IsSpaceThemed";

import { setActions, setTiptapSidemenu  } from "../../../store/app";

import { isEmptySelection } from "../../tiptap/utils/selection";

import { createStyles, Box, Text, Group } from "@mantine/core";

const useStyles = createStyles((theme, { colors, settingsView }) => ({
  spaceNameWrap: {
    padding: "0px 0px 0px 20px",
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
    cursor: "pointer",
    minHeight: 47,
    // borderTop: !settingsView
    //   ? `1px solid ${tinycolor(colors.sidebar_background_color)
    //       .darken(5)
    //       .toString()}`
    //   : `1px solid ${tinycolor(defaultSpaceColors.sidebar_background_color)
    //       .darken(5)
    //       .toString()}`,
    // color: colors.sidebar_text_color,
    // color: !settingsView ? colors.sidebar_text_color : defaultSpaceColors.sidebar_text_color,
    // color: !settingsView && isSpaceThemed(colors) ? colors.sidebar_text_color : theme.colors.gray[7],
    flexWrap: "nowrap",

    "&:hover": {
      backgroundColor: !settingsView
        ? tinycolor(colors.sidebar_background_color).darken(4).toString()
        : tinycolor(defaultSpaceColors.sidebar_background_color)
            .lighten(4)
            .toString(),
    },
  },
  spaceNameClose: {
    color: "#fff",
    fontWeight: 600,
    padding: "5px 8px",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(15)
        .toString(),
    },
  },
  nodeIconWrap: {
    display: "inline-block",
    width: "20px",
    textAlign: "center",
    color:
      !settingsView && isSpaceThemed(colors)
        ? colors.sidebar_text_color
        : theme.colors.gray[7],
  },
  nodeItemLink: {
    display: "inline-block",
    width: "100%",
    paddingTop: "11px",
    paddingBottom: "11px",
    textDecoration: "none",
    // marginLeft: "5px",
    fontWeight: 400,
    // color: colors.sidebar_text_color,
    // color: !settingsView ? colors.sidebar_text_color : defaultSpaceColors.sidebar_text_color,
    color:
      !settingsView && isSpaceThemed(colors)
        ? colors.sidebar_text_color
        : theme.colors.gray[7],

    "&:hover": {
      textDecoration: "none",
    },
  },
  textStyle: {
    color:
      !settingsView && isSpaceThemed(colors)
        ? colors.sidebar_text_color
        : "#000",
  },
}));

export default function NewSpaceNode(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const mainActions = useSelector((state) => state.mainActions);
  const tiptap = useSelector((state) => state.tiptap);

  const { t, i18n } = useTranslation();
  const { settingsView } = useSidebarStyles();
  const { classes, cx } = useStyles({ colors, settingsView });

  const onNewSpace = () => {
    if (mainActions.editPage){
      AddLineBelowSelection();

      dispatch(
        setTiptapSidemenu({ opened: "newSpace", option: "content" })
      );
    }
    else{
      dispatch(setActions({ editPage: true }));
        window.$openCreateSpaceOnEditMode = true;
    }
  }

  const AddLineBelowSelection = () => {
    const { from, to, $anchor } = tiptap.editor.view.state.selection;

    if (!isEmptySelection(tiptap.editor)) {
      tiptap.editor.commands.addNewEmptyLine();
      window.$tiptapLastSelectionRange = { from: to + 1, to: to + 1 };

      // tiptap.editor.chain().focus().setTextSelection({ from: to+1, to: to+1 }).run()
    } else {
      window.$tiptapLastSelectionRange = { from: to, to: to };
    }
  };

  if (!space.slug ) {
    return null;
  }

  if (!space.permissions.can_edit) {
    return null;
  }

  if (space.version != "content") {
    return null;
  }


  return (
    <Box
      className={classes.spaceNameWrap}
      onClick={(event) => onNewSpace()}
    >
      <Group spacing={10}>
        <Box className={classes.nodeIconWrap}>
          <FontAwesomeIcon icon={solid("plus")} />
        </Box>
        <Text className={classes.textStyle}>{"Create new space"}</Text>
      </Group>
    </Box>
  );
}
