// import axios from 'axios';

class Interaction {
  constructor() {
    if (this instanceof Interaction) {
      throw Error('Interaction class cannot be instantiated.');
    }
  }

  static onCreate = async (slug, {view_id, position, wait}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      view_id,
      position,
      wait
    }

    await window.$api.post(`/api/v4/spaces/${slug}/interactions.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default Interaction;
