import React, { useState, useEffect } from "react";
import { Text, Table, Badge, MantineProvider, Box } from "@mantine/core";

import StripeModel from "../../../models/SutraStripe";

import { useTranslation } from "react-i18next";

export default function History() {
  const { t, i18n } = useTranslation();

  const [accountPayments, setAccountPayments] = useState([]);

  useEffect(() => {
    onFetchPayments();
  }, []);

  const onFetchPayments = () => {
    StripeModel.onFetchPayments(
      (data) => {
        setAccountPayments(data);
      },
      (data) => {}
    );
  };

  const StatusBadge = (props) => {
    const statusOptions = {
      paid: "lime",
      unpaid: "red",
    };

    return (
      <MantineProvider theme={{ primaryShade: 6 }}>
        <Badge color={statusOptions[props.status]} variant="filled">
          {t(`settings.billing.statuses.${props.status}`)}
        </Badge>
      </MantineProvider>
    );
  };

  return (
    <div>
      {accountPayments.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>{t("settings.billing.tableDate")}</th>
              <th>{t("settings.billing.tableAmount")}</th>
              <th>{t("settings.billing.tableStatus")}</th>
              <th>{t("settings.billing.tableInvoice")}</th>
            </tr>
          </thead>
          <tbody>
            {accountPayments.map((element) => (
              <tr key={element.date}>
                <td>{element.date}</td>
                <td>{element.amount}</td>
                <td>
                  <StatusBadge status={element.status} />
                </td>
                <td>
                  <Text>
                    <a
                      href={element.invoice_pdf ? element.invoice_pdf : "#"}
                      target="_blank"
                    >
                      {t("settings.billing.pdf")}
                    </a>
                  </Text>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Box
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "center",
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          {t("settings.billing.noPayments")}
        </Box>
      )}
    </div>
  );
}
