import React, { useState, useEffect } from "react";

import { Text, Box, Drawer, SegmentedControl, ScrollArea } from "@mantine/core";

import { useTranslation } from "react-i18next";

import Summary from "../../spaces_neo/settings/surveys/Summary";
import Individual from "../../spaces_neo/settings/surveys/Individual";

import SurveyModel from "../../../models/Survey";
import { setTiptapSidemenu } from "../../../store/app";

import { useDispatch, useSelector } from "react-redux";

export default function ShowSurvey(props) {
  const dispatch = useDispatch();


  const space = useSelector((state) => state.space);
  const [surveyType, setSurveyType] = useState("summary");
  const { t, i18n } = useTranslation();
  const [survey, setSurvey] = useState(null);
  const [opened, setOpened] = useState(false);

  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);

  useEffect(() => {
    if (props.survey && props.survey.id) {
      onFetch();
    }
  }, [props]);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "showSurvey") {
      
      if (tiptapSidemenu.option && tiptapSidemenu.option.survey) {
        onFetch(tiptapSidemenu.option.survey.id)
      }
      setOpened(true);
    } else {
      if (!window.$modalSpaceOpened){
        setOpened(false);
      }
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "showSurvey") {
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    }
  }, [opened]);

  const onFetch = (id) => {
    SurveyModel.onFetchById(
      id,
      space.id,
      (data) => {
        setSurvey(data);
      },
      (data) => {}
    );
  };

  const displaySurveyType = () => {
    if (surveyType == "summary") {
      return <Summary spaceId={space.id} survey={survey} />;
    } else if (surveyType == "individual") {
      return <Individual spaceId={space.id} survey={survey} context="settings" />;
    } else {
      return <Summary spaceId={space.id} survey={survey} />;
    }
  };

  return (
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {t("spaces.settings.survey.showSurvey.survey.title")}
        </Text>
      }
      padding="xl"
      size={600}
    >
      <ScrollArea style={{ height: "100%" }}>
        <SegmentedControl
          fullWidth
          value={surveyType}
          onChange={setSurveyType}
          defaultValue="summary"
          size="md"
          data={[
            { value: "summary", label: "Summary" },
            { value: "individual", label: "Individual" },
          ]}
        />

        {displaySurveyType()}

        <Box sx={{ height: 100 }} />
      </ScrollArea>
    </Drawer>
  );
}
