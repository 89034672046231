import React, { useState, useEffect } from "react";

import {
  Text,
  Group,
  Button,
  Tooltip,
  Box,
  Paper,
  createStyles,
} from "@mantine/core";

import { useSelector, useDispatch } from "react-redux";

import { CopyToClipboard } from "react-copy-to-clipboard";

import DefaultAvatar from "../../../src/images/fallback/default.png";

import DirectMessageModel from "../../../models/DirectMessage";

import Avatar from "../../utils/LazyAvatar";

import { setTiptapSidemenu } from "../../../store/app";

import tinycolor from "tinycolor2";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme, { colors, props }) => ({
  drawer: {
    backgroundColor: colors.background_color,
  },
  drawerHeader: {
    color: colors.default_text_color,
    fontWeight: 700,
    paddingBottom: 10,
    // borderBottom: `1px solid ${colors.default_text_color}`
  },
  formStyle: {
    "&:focus": {
      border: `1px solid ${tinycolor(colors.background_color)
        .darken(20)
        .toString()}`,
    },
  },
  buttonStyle: {
    backgroundColor: "none",
    color: colors.primary_button_background_color,
    border: `1px solid ${colors.primary_button_background_color}`,
    "&:hover": {
      backgroundColor: tinycolor(colors.background_color).darken(1).toString(),
    },
  },
}));

export default function RegisteredRow(props) {
  const { t, i18n } = useTranslation();
  const [emailCopiedToClipboard, setEmailCopiedToClipboard] = useState(false);

  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors, props });

  const dispatch = useDispatch();

  const [member, setMember] = useState({});
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (
      !member.id ||
      member.id != props.member.id ||
      member.status == "deleted" ||
      (member.status != "active" && props.member.status == "active")
    ) {
      setMember(props.member);
      setRole(props.member.is_manager ? "Manager" : "Member");
    }
  }, [props]);

  useEffect(() => {
    if (
      role &&
      ((member.is_manager == true && role == "Member") ||
        (member.is_manager == false && role == "Manager"))
    ) {
      onSelectedRole(role);
    }
  }, [role]);

  const onDirectMessage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    DirectMessageModel.onFetchOrCreate(
      member.user_id,
      (data) => {
        dispatch(
          setTiptapSidemenu({ opened: "direct", option: data.space.slug })
        );
      },
      (data) => {}
    );
  };

  const onCopyEmail = (event) => {
    setEmailCopiedToClipboard(true);
    setTimeout(() => {
      setEmailCopiedToClipboard(false);
    }, 2000);
  };

  const onClickEmailButton = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const openProfile = () => {
    dispatch(setTiptapSidemenu({ opened: "profile", option: member.username }));
  };

  return (
    <Paper
      onClick={() => openProfile()}
      shadow="sm"
      radius="md"
      p={20}
      key={member.username}
      className={props.memberItemClass}
    >
      <Box
        sx={{
          marginTop: 10,
          width: "100%",
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        
        <Avatar
          size={150}
          src={
            member.avatar && member.avatar.url
              ? member.avatar.url
              : DefaultAvatar
          }
          radius={300}
        />
        <Text
          size="lg"
          mt={10}
          weight={700}
          className={props.memberItemText}
          lineClamp={2}
        >
          {member.full_name}
        </Text>
        <Text size="sm" className={props.memberItemTextDimmed} mb={10}>
          @{member.username}
        </Text>
        {props.user.id !== member.user_id && (
          <Group spacing={5} mb={10}>
            <Button
              className={classes.buttonStyle}
              variant="outline"
              radius="xl"
              compact
              color="dark"
              onClick={(event) => onDirectMessage(event)}
            >
              {t("spaces.members.messageButton")}
            </Button>
            {props.showEmail && (
              <Tooltip
                label={`${member.email} ${t("spaces.members.copyClipboard")}`}
                opened={emailCopiedToClipboard}
                position="bottom"
                withArrow
              >
                <CopyToClipboard
                  // onClick={(event) => onCopyEmail(event)}
                  onCopy={(event) => onCopyEmail(event)}
                  text={member.email}
                >
                  <Button
                    className={classes.buttonStyle}
                    variant="outline"
                    radius="xl"
                    compact
                    color="dark"
                    onClick={(event) => onClickEmailButton(event)}
                  >
                    {t("spaces.members.emailButton")}
                  </Button>
                </CopyToClipboard>
              </Tooltip>
            )}
          </Group>
        )}
        <Text
          sx={{
            width: "100%",
            lineHeight: 1.3
          }}
          lineClamp={2}
        >
          {member.bio}
        </Text>
        
      </Box>
    </Paper>
  );
}
