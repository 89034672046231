import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

import {setHiddenSidebar} from '../../../store/app';

import SpaceReference from '../../users/SpaceReference';
import StripeForm from './StripeForm';

import StripeModel from "../../../models/Stripe";
import SpaceModel from "../../../models/Space";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Swal from 'sweetalert2'

import { useTranslation } from "react-i18next";

import {
  Paper,
  Title,
  Container,
  Button,
  Box,
  Divider,
  Stepper,
  Grid,
  ScrollArea
} from '@mantine/core';

import { useMediaQuery } from '@mantine/hooks';

export default function Payment(props) {

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const intent = useRef(null)
  const intervalId = useRef(null)
  const currency = useRef(null)
  const frequency = useRef(null)
  const loaded = useRef(null)
  const stripePromise = useRef(null)
  const stripeOptions = useRef(null)
  const price = useRef(null)

  const [spaceReference, setSpaceReference] = useState()

  // const [stripePromise, setStripePromise] = useState()
  // const [stripeOptions, setStripeOptions] = useState({clientSecret: ""})
  const [returnUrl, setReturnUrl] = useState()
  // const [price, setPrice] = useState()
  // const [currency, setCurrency] = useState()
  // const [frequency, setFrequency] = useState()
  // const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    dispatch(setHiddenSidebar({hidden: true}))
    setTimeout(() => {
      dispatch(setHiddenSidebar({hidden: true}))
    }, 1000)
    return () => {
      dispatch(setHiddenSidebar({hidden: false}))
    }
  }, [])

  useEffect(() => {
    if (props.space.slug){
      
    }
  }, [props])

  // useEffect(() => {
  //   if (props.space.slug){
  //     onCreate()
  //   }
  // }, [price.current])

  const onCreate = () => {


      StripeModel.onCreate(props.space.slug, parseFloat(price), (data) => {
        stripePromise.current = loadStripe(data.publishable_key)
        stripeOptions.current = {clientSecret: data.client_secret}
        setReturnUrl(data.return_url)
        intent.current = data.intent_id
  
      }, (data) => {
  
      })
  }

  const onPriceChange = (_price, _currency, _frequency) => {
    price.current = _price
    currency.current = _currency
    frequency.current = _frequency
    loaded.current = true
    
    onCreate()
  }

  const onPay = async (stripe, elements) => {

    if (!stripe || !elements) {
      return;
    }

    StripeModel.onCheckIntent(props.space.slug, intent.current, (data) => {

      if (!data.data.is_valid){
        Swal.fire(t("spaces.payment.onCheckIntent.alertTitle"), t("spaces.payment.onCheckIntent.alertMessage"), "error")
        return
      }

      stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      }).then(function(result) {
        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          Swal.fire(t("spaces.payment.onConfirmPayment.alertTitle"), result.error.message, "error")
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      });

    }, (data) => {

    })


  }

  const smallScreen = useMediaQuery('(max-width: 768px)');

  const setStepper = () => {

  }
  const setShowSpaceReference = () => {

  }

  const onJoin = () => {
    SpaceModel.onJoin(props.space.slug, (data) => {
      navigate(`${data.redirect_to}?refresh=true`)
    }, (data) => {

    })
  }

  return (
    <ScrollArea
      style={{height: "100%"}}>
      <Container
        style={{marginBottom: "50px"}}
        size={1000}>
        {!smallScreen && props.space ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={1} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : ('')}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                <Box pt={20} pb={5} pl={35} pr={35}>
                  <Title
                    sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                    order={1}
                    >
                    {t("spaces.payment.completePayment")}
                  </Title>
                </Box>
                <Divider
                  sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                  my="sm"
                  />
                <Box pt={20} pb={5} pl={35} pr={35}
                  key={`strip-payment-container-${new Date()}`}>
                  {props.space && stripePromise.current && stripeOptions.current && price.current > 0 ? (
                    <Elements
                      stripe={stripePromise.current}
                      options={stripeOptions.current}>
                      <StripeForm
                        onPay={onPay}
                        amount={price.current}
                        currency={currency.current}
                        frequency={frequency.current}/>
                    </Elements>
                  ) : (
                    props.space && stripePromise && stripeOptions && loaded.current && (
                      <Button
                        fullWidth
                        mt={0}
                        radius="xl"
                        size="lg"
                        mb={15}
                        onClick={() => onJoin()}>
                        {t("spaces.payment.join")}
                      </Button>
                    )
                  )}
                </Box>
              </Paper>
            </Container>
          </Grid.Col>
          {props.space && props.space.slug && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                slug={props.space.slug}
                setSpaceReference={setSpaceReference}
                onPriceChange={onPriceChange}
                setStepper={setStepper}
                setShowSpaceReference={setShowSpaceReference}/>
            </Grid.Col>
          )}
        </Grid>
      </Container>
    </ScrollArea>
  )
}
