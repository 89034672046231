import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Button,
  ScrollArea,
  Stack,
  TextInput,
  Group,
  Text,
  Switch,
  Box,
  Textarea,
} from "@mantine/core";
import SpaceModel from "../../../models/Space";
import SpaceSettingModel from "../../../models/SpaceSetting";

import { useForm } from "@mantine/form";
import { TimeInput, DatePicker } from "@mantine/dates";

import { useFocusTrap } from "@mantine/hooks";

import { useTranslation } from "react-i18next";

export default function Form(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const space2 = useSelector((state) => state.space2);

  const focusTrapRef = useFocusTrap();

  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [allowInterests, setAllowInterests] = useState("");

  const form = useForm({
    initialValues: {
      hangout_link: "",
      description: "",
    },
  });

  useEffect(() => {
    onFetch();
  }, []);

  const getSpace = () => {
    if (props.useSpace2) {
      return space2;
    } else {
      return space;
    }
  };

  const mergeDateAndTime = (date, time) => {
    if (date && time) {
      const _date = new Date(date);
      const _time = new Date(time);

      const merged = new Date(
        `${_date.toLocaleDateStringAbs()}, ${_time.toLocaleTimeString()}`
      ).toISOString();

      return `${merged}`;
    } else if (date) {
      return `${date.toISOString()}`;
    }
  };

  const onFetch = () => {
    setAllowInterests(getSpace().calendar.allow_interested_on_join);

    const plain_description = getSpace().calendar.plain_description;

    form.setFieldValue("hangout_link", getSpace().calendar.hangout_link);
    // form.setFieldValue("description", getSpace().calendar.plain_description);
    form.setFieldValue("description", typeof plain_description === "string" ? plain_description.replace(/<br>/g, "\n") : "");

    if (getSpace().calendar.has_start_date) {
      setStartDate(new Date(getSpace().calendar.raw_start_date));
      setStartTime(new Date(getSpace().calendar.raw_start_date));
    }
    if (getSpace().calendar.has_end_date) {
      setEndDate(new Date(getSpace().calendar.raw_end_date));
      setEndTime(new Date(getSpace().calendar.raw_end_date));
    }
  };

  const onSave = () => {
    const params = { ...form.values };
    params.pod_settings_attributes = {};
    if (startDate) {
      params.pod_settings_attributes.show_start_date = true;
      params.pod_settings_attributes.start_date = mergeDateAndTime(
        startDate,
        startTime
      );
    }
    if (endDate) {
      params.pod_settings_attributes.show_start_date = true;
      params.pod_settings_attributes.end_date = mergeDateAndTime(
        endDate,
        endTime
      );
    }
    params.pod_settings_attributes.allow_interested_on_join = allowInterests;
    SpaceSettingModel.onUpdate(
      getSpace().slug,
      params,
      (data) => {
        props.onSave(
          form.values.hangout_link,
          form.values.description,
          params.pod_settings_attributes.start_date,
          params.pod_settings_attributes.end_date
        );
      },
      (data) => {}
    );
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <form
        onSubmit={form.onSubmit((values) => onSave(values))}
        ref={focusTrapRef}
      >
        <Stack>
          {/* <Text color="dimmed">{t("spaces.form.calendarLinks.text")}</Text> */}
          <TextInput
            {...form.getInputProps("hangout_link")}
            label={t("spaces.form.calendarLinks.link.label")}
            placeholder={t("spaces.form.calendarLinks.link.placeholder")}
            data-autofocus
          />
          <Textarea
            autosize={true}
            mb={5}
            {...form.getInputProps("description")}
            placeholder={t("spaces.form.calendarLinks.description.label")}
            label={t("spaces.form.calendarLinks.description.placeholder")}
          />
          <Box>
            <Text size="sm" weight={500} mb={4}>
              {t("spaces.form.calendarLinks.eventStartDateAndTime")}
            </Text>
            <Group>
              <DatePicker
                value={startDate}
                placeholder={t("spaces.form.calendarLinks.date.placeholder")}
                onChange={(date) => setStartDate(date)}
              />
              <TimeInput
                value={startTime}
                format="12"
                onChange={(time) => setStartTime(time)}
              />
            </Group>
            <Text size="sm" weight={500} mb={4} mt={20}>
              {t("spaces.form.calendarLinks.eventDateTime")}
            </Text>
            <Group>
              <DatePicker
                value={endDate}
                placeholder={t("spaces.form.calendarLinks.date.placeholder")}
                onChange={(date) => setEndDate(date)}
              />
              <TimeInput
                value={endTime}
                format="12"
                onChange={(time) => setEndTime(time)}
              />
            </Group>
          </Box>
          <Switch
            mt={0}
            mb={10}
            checked={allowInterests}
            onChange={(event) => setAllowInterests(event.currentTarget.checked)}
            label={t("spaces.form.calendarLinks.allowInterests")}
          />
        </Stack>
        <Box>
          <Text
            size="sm"
            weight={500}
            mb={4}
            component={Link}
            to={`/space/${space.slug}/settings/main?section=2`}
          >
            {t("spaces.form.calendarLinks.automatedEmails")}
          </Text>
        </Box>
        <Button radius="xl" size="md" mt={25} type="submit">
          {t("spaces.form.calendarLinks.save")}
        </Button>
      </form>
      <Box mb={200}></Box>
    </ScrollArea>
  );
}
