import * as React from "react"

const SvgComponent = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 755.67 772.32"
    style={{
      enableBackground: "new 0 0 755.67 772.32",
      width: props.width ? props.width : 35,
      height: props.height ? props.height : 35
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {".st0{fill:none;stroke:currentColor;stroke-width:35;stroke-miterlimit:35}"}
    </style>
    <path
      className="st0"
      d="M385.18 381.62c29.69-49.28 71.9-91.97 125.25-122.77 55.5-32.04 116.1-47.19 175.88-47.12C621.21 99.17 503.25 36.22 381.92 36.12c-.01.02-.03.05-.04.08 63.7 110.52 60.63 241.24 3.3 345.42zM382.62 386.16c.86-1.51 1.72-3.02 2.56-4.54-.91 1.51-1.82 3.02-2.7 4.54h.14zM380.97 386.14h.05c-64.07-112.34-59.43-245.27.86-349.95-.02-.03-.03-.05-.05-.08-59.72-.04-120.26 15.15-175.7 47.16-54.44 31.43-97.28 75.23-127.05 125.79.24.42.46.84.7 1.25.32.56.66 1.1.98 1.65 119.64 1.25 235.54 63.55 300.21 174.18zM381.82 387.57c.02-.02.03-.05.04-.07h-.08c.02.02.03.05.04.07z"
    />
    <path
      className="st0"
      d="M380.97 386.14C261.34 384.9 145.43 322.6 80.76 211.96c-1.13-.01-2.26.02-3.39.02-60.52 105.08-64.99 238.64-.14 351.26 29.83-51.74 73.24-96.57 128.68-128.58 55.47-32.03 116.03-47.21 175.79-47.16.01-.02.02-.04.03-.07-.25-.43-.51-.86-.76-1.29zM381.74 387.43c.01.02.03.05.04.07-.01-.02-.02-.05-.04-.07zM382.47 386.16c-.48 0-.97-.01-1.45-.01.25.42.48.86.72 1.28.25-.43.49-.85.73-1.27zM77.35 563.45c.03.05.05.1.08.15 65.06 112.69 183.08 175.72 304.47 175.86-.06-.1-.11-.19-.17-.29-63.84-110.57-60.79-241.39-3.42-345.64-29.69 49.28-71.89 91.97-125.25 122.77-55.45 32.01-115.99 47.19-175.71 47.15zM381.82 387.58c.03.06.07.11.1.17-.03-.06-.06-.12-.1-.17zM381.92 387.75c.01.01.01.03.02.04s.01.03.02.04c-.01-.01-.01-.03-.02-.04 0-.02-.01-.03-.02-.04zM381.97 387.83c65.05 112.7 60.62 246.44.01 351.63 59.75.05 120.31-15.13 175.78-47.16 55.44-32.01 98.85-76.84 128.69-128.58a.3.3 0 0 1-.04-.08c-121.38-.13-239.37-63.15-304.44-175.81zM687.16 213.22c-29.77 50.56-72.6 94.37-127.04 125.8-56 32.33-117.19 47.48-177.49 47.14-.26.45-.5.9-.76 1.34 121.36.16 239.34 63.2 304.38 175.86.05.1.11.2.16.29h.09c60.39-104.82 64.99-237.98.66-350.43z"
    />
    <path
      className="st0"
      d="M381.7 387.5c-1.15 2-2.29 4.01-3.4 6.03 1.19-1.98 2.37-3.96 3.52-5.95-.01-.03-.03-.05-.04-.08h-.08z"
    />
  </svg>
)

export default SvgComponent
