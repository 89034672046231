import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Table,
  Badge,
  MantineProvider,
  Box,
} from "@mantine/core";

import { useSelector } from "react-redux";

import UserAffiliateModel from "../../../models/UserAffiliate";
import Payments from "./Payments";

import { useTranslation } from "react-i18next";

export default function History() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);

  const [affiliates, setAffiliates] = useState([]);
  const [selectedAffiliate, setSelectedAffiliate] = useState();

  useEffect(() => {
    onFetchPayments();
  }, []);

  const onFetchPayments = () => {
    UserAffiliateModel.onFetch(
      user.id,
      (data) => {
        setAffiliates(data);
      },
      (data) => {}
    );
  };

  const StatusBadge = (props) => {
    const statusOptions = {
      paid: "lime",
      unpaid: "red",
      pending: "orange",
    };

    return (
      <MantineProvider theme={{ primaryShade: 6 }}>
        <Badge color={statusOptions[props.status]} variant="filled">
          {t(`settings.affiliates.history.statuses.${props.status}`)}
        </Badge>
      </MantineProvider>
    );
  };

  return (
    <div>
      {affiliates.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>{t("settings.affiliates.history.table.referralName")}</th>
              <th>{t("settings.affiliates.history.table.startAt")}</th>
              <th>{t("settings.affiliates.history.table.endAt")}</th>
              <th>{t("settings.affiliates.history.table.percent")}</th>
              <th>{t("settings.affiliates.history.table.total")}</th>
              <th>{t("settings.affiliates.history.table.status")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {affiliates.map((affiliate) => (
              <tr>
                <td>{affiliate.user.full_name}</td>
                <td>{affiliate.start_at}</td>
                <td>{affiliate.end_at}</td>
                <td>{affiliate.percent}%</td>
                <td>{affiliate.total_earned}</td>
                <td>
                  <StatusBadge status={affiliate.status} />
                </td>
                <td>
                  <Button
                    compact
                    radius="xl"
                    variant="outline"
                    onClick={() => setSelectedAffiliate(affiliate)}
                  >
                    {t("settings.affiliates.history.paymentsHistory")}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Box
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "center",
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          {t("settings.affiliates.history.empty")}
        </Box>
      )}
      <Drawer
        opened={selectedAffiliate != null}
        onClose={() => setSelectedAffiliate(null)}
        title={`Payments for ${
          selectedAffiliate && selectedAffiliate.user.full_name
        }`}
        padding="xl"
        size="700"
      >
        <Payments affiliate={selectedAffiliate} />
      </Drawer>
    </div>
  );
}
