import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
  Text,
  Box,
  createStyles,
  NumberInput,
  ActionIcon
} from '@mantine/core'

import { Plus, Minus } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]}`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,

    '&:focus-within': {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]}`,

    '&:disabled': {
      borderColor: theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: 'transparent',
    },
  },

  input: {
    textAlign: 'center',
    paddingRight: `${theme.spacing.sm}px !important`,
    paddingLeft: `${theme.spacing.sm}px !important`,
    height: 28,
    flex: 1,
  },
}));

export default function NumberOfTimes(props) {

  const { classes } = useStyles();
  const handlers = useRef();
  const [value, setValue] = useState(1);
  const min = 1;
  const max = 10;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setValue(props.numberOfTimes ? props.numberOfTimes : 1)
  }, [props])

  return (
    <Box mt={20} mb={20}>

    <Text
      mb={8}
      color="dimmed"
    >
      {t("spaces.settings.payment.charging.NumberOfTimes.howManyTimes")}
    </Text>

    <div className={classes.wrapper}>
      <ActionIcon
        size={28}
        variant="transparent"
        onClick={() => handlers.current.decrement()}
        disabled={value === min}
        className={classes.control}
        onMouseDown={(event) => event.preventDefault()}
      >
        <Minus size={16} />
      </ActionIcon>

      <NumberInput
        variant="unstyled"
        min={min}
        max={max}
        handlersRef={handlers}
        value={props.numberOfTimes}
        onChange={props.setNumberOfTimes}
        classNames={{ input: classes.input }}
      />

      <ActionIcon
        size={28}
        variant="transparent"
        onClick={() => handlers.current.increment()}
        disabled={value === max}
        className={classes.control}
        onMouseDown={(event) => event.preventDefault()}
      >
        <Plus size={16} />
      </ActionIcon>
    </div>

    </Box>
  )
}
