import React from "react";
import { Text, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";

export default function Fees(props) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Group mt={10} spacing={0}>
        <Text ml={5} size="xs" color="dimmed" weight={500}>
        {t("spaces.settings.payment.fees.description.fees")}
        </Text>
        <Text ml={5} size="sm" color="dimmed">
          {`${props.fee}%`}
        </Text>
        <Text ml={5} size="xs" color="dimmed" weight={500}>
        {t("spaces.settings.payment.fees.description.sutraFee")}
        </Text>
        <Text
          ml={5}
          size="xs"
          color="dimmed"
          sx={{ textDecoration: "underline" }}
          weight={500}
        >
          <a
            href="https://stripe.com/pricing"
            style={{ color: "inherit" }}
            target="_blank"
          >
          {t("spaces.settings.payment.fees.description.stripeProcessingFee")}
          </a>
        </Text>
      </Group>
    </>
  );
}
