import React, { useState, useRef, useEffect } from "react";

import { useDispatch } from "react-redux";

import {
  Drawer,
  Stack,
  Textarea,
  Text,
  TextInput,
  ScrollArea,
  Box,
  Button
} from "@mantine/core";

import SpaceModel from "../../../../models/Space";
import { setListSpaceToUpdate } from "../../../../store/app";

import { useTranslation } from "react-i18next";

const Edit = (props) => {
  const { t, i18n } = useTranslation();
  const [mediaType, setMediaType] = useState("image");
  const [loading, setLoading] = useState(false);
  const [spaceName, setSpaceName] = useState("");
  const [spaceAbstract, setSpaceAbstract] = useState("");
  const dispatch = useDispatch();

  const fetchTimeoutId = useRef();
  const timeoutId = useRef(null);

  useEffect(() => {
    if (props.spaceId) {
      setLoading(true);
      if (fetchTimeoutId.current) {
        clearTimeout(fetchTimeoutId.current);
      }
      fetchTimeoutId.current = setTimeout(() => {
        onFetch();
      }, 500);
    }
  }, [props]);

  const onFetch = (file) => {
    setLoading(true);
    SpaceModel.onFetch(
      props.spaceId,
      ["tree", "members"],
      (data) => {
        setSpaceName(data.space.name);
        setSpaceAbstract(data.abstract);
        setLoading(false);
      },
      (data) => {}
    );
  };

  const onUpdateName = (value) => {
    SpaceModel.onUpdate(
      props.spaceId,
      { intention: value },
      (data) => {
        onSave();
      },
      (data) => {}
    );
  };

  const onUpdateAbstract = (value) => {
    SpaceModel.onUpdate(
      props.spaceId,
      { abstract_text: value },
      (data) => {
        onSave();
      },
      (data) => {}
    );
  };

  const onSave = () => {
    SpaceModel.onUpdate(
      props.spaceId,
      {  intention: spaceName, abstract_text: spaceAbstract },
      (data) => {
        
        dispatch(setListSpaceToUpdate({ slug: props.spaceId }));
      },
      (data) => {}
    );
  };

  return (
    <Drawer
      position="left"
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={<Text size="xs">{"EDIT DETAILS"}</Text>}
      padding="xl"
      size={"400px"}
      overlayOpacity={0.1}
      zIndex={99999}
    >
      <ScrollArea style={{ height: "100%" }}>
        <Box mb={80}>
          <TextInput
            label={t("spaces.form.form.intention.label")}
            disabled={loading}
            mt={20}
            mb={20}
            value={spaceName}
            onChange={(event) => setSpaceName(event.currentTarget.value)}
          />

          <Textarea
            label={t("spaces.form.form.abstract.label")}
            disabled={loading}
            mt={20}
            mb={20}
            value={spaceAbstract}
            onChange={(event) => setSpaceAbstract(event.currentTarget.value)}
          />
          <Button  radius="xl" onClick={(e) => onSave()}>
            {"Save"}
          </Button>
        </Box>
      </ScrollArea>
    </Drawer>
  );
};

export default Edit;
