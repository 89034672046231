import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import "add-to-calendar-button/assets/css/atcb.css";

import useLangUser from "../../utils/LangUser";

import Broadcaster from "../../helpers/Broadcaster.js";

import { Text, Paper, Group, createStyles } from "@mantine/core";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
  },
  textColor: {
    color: colors.sidebar_text_color,
  },
  buttonColor: {
    border: isSpaceThemed(colors)
      ? `1px solid ${colors.sidebar_text_color}`
      : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : colors.primary_button_background_color,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
        : tinycolor(colors.sidebar_background_color).darken(1).toString(),
    },
  },
}));

export default function InteractionsBar(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const { language, setLangUser } = useLangUser();

  const [progress, setProgress] = useState(0);
  const [interactions, setInteractions] = useState(0);

  useEffect(() => {
    Broadcaster.receive(
      "open_InteractionsBar",
      document.body,
      (event, data) => {
        if (data.progress <= 100) {
          setProgress(data.progress);
        }
      }
    );

    return () => {
      Broadcaster.unRegisterElement("open_InteractionsBar", document.body);
    };
  }, []);

  useEffect(() => {
    if (props.space.slug && props.space.permissions) {
      setInteractions(props.space.interactions.count);
    }
  }, [props]);

  if (
    !props.space.slug ||
    (props.space && props.space.slug && !props.space.interactions)
  ) {
    return null;
  }

  return (
    <>
      {props.space.interactions.can_manage ? (
        <Paper
          radius={10}
          mt={15}
          mb={15}
          pt={12}
          pb={12}
          pl={20}
          pr={20}
          className={classes.panelWrap}
        >
          <Group position="apart">
            <Text align="center" weight={500} className={classes.textColor}>
              {`${interactions} interactive journey${
                interactions == 1 ? "" : "s"
              } currently in progress`}
            </Text>
          </Group>
        </Paper>
      ) : (
        ""
        // <Box
        //   mt={15}
        //   mb={10}
        //   sx={{
        //     width: "100%"
        //   }}>
        //     <Progress radius="xl" size="xl" value={parseInt(progress)} />
        // </Box>
      )}
    </>
  );
}

// <Paper
//   radius={5}
//   mt={15}
//   mb={15}
//   pt={12} pb={12} pl={20} pr={20}
//   className={classes.panelWrap}
//   >
//   {props.space.interactions.can_manage ? (
//     <Group
//       position="apart"
//       >
//       <Text
//         align="center"
//         weight={500}
//         className={classes.textColor}>
//         {`This space has ${interactions} interactive journey${interactions == 1 ? "" : "s"} in progress`}
//       </Text>
//     </Group>
//   ) : (
//     <Group
//       position="apart"
//       >
//       <Text>
//         {`Your progress`}:
//       </Text>
//       <Box
//         sx={{
//           width: "100%"
//         }}>
//         <Progress radius="xl" size="xl" value={parseInt(progress)} />
//       </Box>
//     </Group>
//   )}
// </Paper>
