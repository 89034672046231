import React, { useState, useEffect, useRef } from "react";

import {
  Text,
  Box,
  Group,
  Drawer,
  Select,
  ScrollArea,
  Button,
  NumberInput,
  MantineProvider,
} from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TriggerModel from "../../../../models/EngagementTrigger";
import SurveyModel from "../../../../models/Survey";

import StepIcon from "./StepIcon";

import PremiumModal from "../../../app/PremiumModal";

import Swal from "sweetalert2";

export default function AddTrigger({
  onUpdateTriggers,
  oriFunctions,
  functions,
  functionsType,
}) {
  const { t, i18n } = useTranslation();

  const space = useSelector((state) => state.space);

  const [trigger, setTrigger] = useState();
  const [triggers, setTriggers] = useState([]);
  // const [functions, setFunctions] = useState([])
  // const [oriFunctions, setOriFunctions] = useState([])
  const [opened, setOpened] = useState(false);
  const [params, setParams] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState({ params: [] });

  useEffect(() => {
    setParams([]);
    setSelectedFunction({ params: [] });
    getSelectTrigger();
    onFetch();
  }, [trigger]);

  const onCreate = () => {
    const trigger_params = {};

    for (let i = 0; i < params.length; i++) {
      const ele = params[i];
      trigger_params[ele.key] = ele.value;
    }

    TriggerModel.onCreate(
      space.slug,
      trigger,
      trigger_params,
      functionsType,
      (data) => {
        setOpened(false);
        onUpdateTriggers(true);
      },
      (data) => {
        Swal.fire(t("errors.basic"), data.message, "error");
      }
    );
  };

  const SelectItem = ({ label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <StepIcon value={others.value} />
        <div>
          <Text size="lg" weight={600}>
            {label}
          </Text>
          <Text size="md" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  const getSelectTrigger = () => {
    const _selectedFunction = oriFunctions.filter(
      (item) => item.slug == trigger
    )[0];
    if (_selectedFunction) {
      setSelectedFunction(_selectedFunction);

      setParams(
        _selectedFunction.params.map((item) => ({
          value: item.default,
          key: item.key,
        }))
      );
    }
  };

  const onSetParamsValue = (index, val) => {
    const aux = [...params];
    aux[index].value = val;
    setParams(aux);
  };

  const onFetch = () => {
    SurveyModel.onFetch(
      space.slug,
      { page: 1, per: 100 },
      (data) => {
        const _surveys = data.data.map((item, index) => ({
          value: `${item.id}`,
          label: item.title,
          group: "Select an existing survey or quiz",
        }));
        setSurveys([..._surveys]);
      },
      (data) => {}
    );
  };

  return (
    <Box mt={25}>
      <Button radius={"xl"} onClick={() => setOpened(true)}>
        {"Add trigger"}
      </Button>
      <Drawer
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        title={
          <Text size="xl" weight="800">
            {"Add trigger"}
          </Text>
        }
        padding="xl"
        size={600}
        offsetScrollbars
      >
        <ScrollArea style={{ height: "100%" }}>
          <Select
            label={"Select trigger"}
            value={trigger}
            onChange={setTrigger}
            placeholder={"Select trigger"}
            // itemComponent={SelectItem}
            data={functions}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
          />
          {selectedFunction.params.map((item, index) => {
            return (
              <>
                {params[index] && item.type == "integer" && (
                  <NumberInput
                    mt={20}
                    defaultValue={item.default}
                    placeholder={item.placeholder}
                    label={item.label}
                    value={params[index].value}
                    onChange={(val) => onSetParamsValue(index, val)}
                    radius="sm"
                    size="md"
                  />
                )}
                {params[index] && item.type == "survey" && (
                  <Select
                    defaultValue={item.default}
                    placeholder={item.placeholder}
                    maxDropdownHeight={400}
                    radius="sm"
                    size="md"
                    mt={20}
                    data={surveys}
                    value={params[index].value}
                    onChange={(val) => onSetParamsValue(index, val)}
                  />
                )}
              </>
            );
          })}
          <Group position="apart" mt={20}>
            <Button radius={"xl"} onClick={() => onCreate()}>
              {"Save trigger"}
            </Button>
          </Group>
          <Box mt={100} />
        </ScrollArea>
      </Drawer>
      <PremiumModal opened={opened} setOpened={setOpened} />
    </Box>
  );
}
