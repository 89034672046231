// import axios from 'axios';

class Webhook {
  constructor() {
    if (this instanceof Webhook) {
      throw Error("Webhook class cannot be instantiated.");
    }
  }

  static onFetch = async (slug, paginator, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ...paginator,
    };

    await window.$api
      .get(`/api/v4/spaces/${slug}/webhooks.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onFetchById = async (
    webhook_id,
    slug,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await window.$api
      .get(`/api/v4/spaces/${slug}/webhooks/${webhook_id}.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onCreate = async (slug, webhook, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      webhook,
    };

    await window.$api
      .post(`/api/v4/spaces/${slug}/webhooks.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onUpdate = async (
    webhook_id,
    slug,
    webhook,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      webhook,
    };

    await window.$api
      .put(`/api/v4/spaces/${slug}/webhooks/${webhook_id}.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onDestroy = async (
    webhook_id,
    slug,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await window.$api
      .delete(`/api/v4/spaces/${slug}/webhooks/${webhook_id}.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };
}

export default Webhook;
