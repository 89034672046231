import React from "react";
import { useEditor, EditorContent } from "@tiptap/react";

import StarterKit from "@tiptap/starter-kit";

import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";

import TaskItem from "@tiptap/extension-task-item";

import UniqueID from "../plugins/CustomUniqId";
import TextAlign from "@tiptap/extension-text-align";
import Details from "@tiptap-pro/extension-details";
import DetailsSummary from "@tiptap-pro/extension-details-summary";
import DetailsContent from "@tiptap-pro/extension-details-content";
import Focus from "@tiptap/extension-focus";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";

import Link from "@tiptap/extension-link";
import CircleNode from "../nodes/CircleNode";
import Grid4Node from "../nodes/Grid4Node";
import Grid3Node from "../nodes/Grid3Node";
import Grid2Node from "../nodes/Grid2Node";
import Grid1Node from "../nodes/Grid1Node";
import InfoBoxNode from "../nodes/InfoBoxNode";
import ColumnNode from "../nodes/ColumnNode";
import ParagraphNode from "../nodes/ParagraphNode";
import HeadingNode from "../nodes/HeadingNode";
import ImageNode from "../nodes/ImageNode";
import VideoNode from "../nodes/VideoNode";
import AudioNode from "../nodes/AudioNode";
import EmbedNode from "../nodes/EmbedNode";
import FileNode from "../nodes/FileNode";
import ListItemNode from "../nodes/ListItemNode";
import ResourceNode from "../nodes/ResourceNode";
import SurveyNode from "../nodes/SurveyNode";
import FormNode from "../nodes/FormNode";
import ButtonNode from "../nodes/ButtonNode";
import InteractiveInputNode from "../nodes/InteractiveInputNode";
import InteractiveButtonNode from "../nodes/InteractiveButtonNode";
import InteractiveWaitNode from "../nodes/InteractiveWaitNode";
import FancyLinkNode from "../nodes/FancyLinkNode";
import HorizontalRule from "../nodes/HorizontalRuleNode";
import TagsFeedNode from "../nodes/TagsFeedNode";

import TaskList from "../nodes/TaskList";
import BulletList from "../nodes/BulletList";
import OrderedList from "../nodes/OrderedList";

import ViewPage from "./ViewPage";
import ViewVideo from "./ViewVideo";
import TakeSurvey from "./TakeSurvey";
import CreateSurvey from "./CreateSurvey";
import ShowSurvey from "./ShowSurvey";

import EmptyMessage from "./EmptyMessage";
import EventHandler from "../plugins/EventHandler";
import EditorEvents from "../plugins/EditorEvents";
import CustomStyles from "../plugins/CustomStyles";
import ListStyles from "../plugins/ListStyles";
import ColumnStyles from "../plugins/ColumnStyles";
import CustomSize from "../plugins/CustomSize";
import CustomResizeable from "../plugins/CustomResizeable";
import CustomButton from "../plugins/CustomButton";
import CustomRadius from "../plugins/CustomRadius";
import CustomNestedRadius from "../plugins/CustomNestedRadius";
import Placeholder from "../plugins/Placeholder";
import Interactive from "../plugins/Interactive";
import Incrementor from "../plugins/Incrementor";
import CustomBlockId from "../plugins/CustomBlockId";
import { isDocumentEmpty } from "../utils/selection";

import { Box, Container } from "@mantine/core";

import { EditorView } from "prosemirror-view";

import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";

import { Mention } from "../plugins/Mention";
import mentionSuggestion from "../plugins/mentionSuggestion";

import Comments from "../../spaces_neo/presentations/content/Comments";
import CommentInput from "../../spaces_neo/presentations/content/CommentInput";

import LikeButton from "../../likes/Button";
import TiptapLikeButtonWrapper from "./TiptapLikeButtonWrapper";

// Tiptap -> prosemirror bug / issue
//https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState(state) {
  try {
    if (!this.docView) return; // This prevents the matchesNode error on hot reloads
    this.updateStateInner(state, this.state.plugins != state.plugins);
  } catch {
    return;
  }
};

const Tiptap = (props) => {
  const editor = useEditor({
    onCreate({ editor }) {
      // applyDevTools(editor.view);
    },
    editable: false,
    content: props.content,
    extensions: [
      StarterKit.configure({
        history: false,
        paragraph: false,
        heading: false,
        listItem: false,
        orderedList: false,
        bulletList: false,
        blockquote: false,
      }),
      UniqueID.configure({
        types: [
          "heading",
          "paragraph",
          "grid1",
          "grid2",
          "grid3",
          "grid4",
          "column",
          "tasklist",
          "taskItem",
          "circle",
          "resource",
          "video",
          "audio",
          "image",
          "embed",
          "file",
          "detailsSummary",
          "details",
          "detailsContent",
          "bulletList",
          "orderedList",
          "listItem",
          "taskItem",
          "resource",
          "actionbutton",
          "customform",
          "survey",
          "interactiveInput",
          "interactiveButton",
          "interactiveWait",
          "fancyLink",
          "horizontalRule",
          "infobox",
          "tagsFeed",
        ],
      }),
      Incrementor.configure({
        types: [
          "heading",
          "paragraph",
          "grid1",
          "grid2",
          "grid3",
          "grid4",
          "column",
          "tasklist",
          "taskItem",
          "circle",
          "resource",
          "video",
          "audio",
          "image",
          "embed",
          "file",
          "detailsSummary",
          "details",
          "detailsContent",
          "bulletList",
          "orderedList",
          "listItem",
          "taskItem",
          "resource",
          "actionbutton",
          "customform",
          "survey",
          "interactiveInput",
          "interactiveButton",
          "interactiveWait",
          "fancyLink",
          "horizontalRule",
          "infobox",
          "tagsFeed",
        ],
      }),
      CustomBlockId.configure({
        types: ["heading", "paragraph", "bulletList", "orderedList"],
      }),
      CustomNestedRadius.configure({
        types: [
          "paragraph",
          "heading",
          "bulletList",
          "orderedList",
          "taskList",
          "gird1",
          "gird2",
          "gird3",
          "gird4",
          "column",
          "infobox",
        ],
      }),
      Highlight.configure({
        multicolor: true,
      }),
      HorizontalRule,
      Underline,
      // History,
      ListItemNode,
      TextStyle,
      Color,
      ParagraphNode,
      // LeafParagraphNode,
      HeadingNode,
      CircleNode,
      ResourceNode,
      SurveyNode,
      FormNode,
      ButtonNode,
      InteractiveInputNode,
      InteractiveButtonNode,
      InteractiveWaitNode,
      TagsFeedNode,
      Grid4Node,
      Grid3Node,
      Grid2Node,
      Grid1Node,
      InfoBoxNode,
      ColumnNode,
      VideoNode,
      AudioNode,
      EmbedNode,
      FancyLinkNode,
      EventHandler,
      EditorEvents,
      FileNode,
      CustomStyles.configure({
        types: [
          "heading",
          "paragraph",
          "image",
          "video",
          "audio",
          "file",
          "embed",
          "grid1",
          "grid2",
          "grid3",
          "grid4",
          "circle",
          "resource",
          "details",
          "detailsSummary",
          "resource",
          "actionbutton",
          "customform",
          "survey",
          "interactiveInput",
          "interactiveButton",
          "interactiveWait",
          "fancyLink",
          "infobox",
          "horizontalRule",
          "tagsFeed",
        ],
      }),
      Interactive.configure({
        types: ["interactiveInput", "interactiveButton", "interactiveWait"],
      }),
      ListStyles.configure({
        types: ["tasklist", "orderedlist", "bulletList"],
      }),
      ColumnStyles.configure({
        types: ["column"],
      }),
      CustomSize.configure({
        types: [
          "file",
          "audio",
          "actionbutton",
          "customform",
          "survey",
          "interactiveButton",
          "fancyLink",
          "circle",
        ],
      }),
      CustomResizeable.configure({
        types: ["image", "video", "embed"],
      }),
      CustomRadius.configure({
        types: [
          "image",
          "video",
          "embed",
          "file",
          "audio",
          "actionbutton",
          "customform",
          "survey",
          "interactiveButton",
          "interactiveInput",
          "circle",
        ],
      }),
      CustomButton.configure({
        types: [
          "actionbutton",
          "customform",
          "interactiveInput",
          "interactiveButton",
          "circle",
          "survey",
        ],
      }),
      OrderedList,
      BulletList,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading") {
            return "";
          }
          if (node.type.name === "paragraph") {
            return "";
          }
          if (node.type.name === "detailsSummary") {
            return "";
          }

          return "";
        },
        showOnlyCurrent: false,
        includeChildren: true,
      }),
      Focus.configure({
        className: "is-focus",
      }),
      Link.configure({
        openOnClick: false,
      }),
      ImageNode,
      TextAlign.configure({
        types: [
          "heading",
          "paragraph",
          "detailsSummary",
          "image",
          "video",
          "embed",
          "file",
          "audio",
          "actionbutton",
          "customform",
          "survey",
          "interactiveButton",
          "fancyLink",
          "circle",
        ],
      }),
      Details.configure({
        HTMLAttributes: {
          openClassName: "is-open",
          class: "tiptap-details",
        },
      }),
      DetailsSummary.configure({
        HTMLAttributes: {
          class: "tiptap-details-summary",
        },
      }),
      DetailsContent.configure({
        HTMLAttributes: {
          class: "tiptap-details-content",
        },
      }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        // suggestion,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: mentionSuggestion,
        renderLabel({ options, node }) {
          const value = node.attrs.label ?? node.attrs.id.split("|")[1];
          return `${options.suggestion.char}${value}`;
        },
      }),
    ],
  });

  return (
    <div id="tiptap-main-readonly" className={`content-tiptap tiptap-wrapper`}>
      {editor && isDocumentEmpty(editor) && (
        <EmptyMessage useSpace2={props.useSpace2} />
      )}
      <ViewPage editor={editor} />
      <ViewVideo editor={editor} />
      <TakeSurvey editor={editor} />
      <CreateSurvey editor={editor} />
      <ShowSurvey editor={editor} />

      <div id="tiptap-main-wrapper">
        <Box ml={props.ml ? props.ml : 0} mr={props.mr ? props.mr : 0}>
          <EditorContent editor={editor} />
        </Box>
        <Box mt={30} />
        {props.space && props.space.list_allow_likes && (
          <Container
            ml={5}
            mr={5}
            size={props.spaceWidth == "wide" ? 960 : 801}
          >
            <LikeButton
              spaceId={props.space.slug}
              liked={props.space.is_liked}
              count={props.space.likes_count}
              useSpace2={props.useSpace2}
            />
          </Container>
        )}
        {props.allowComments && (
          <Box>
            <Container size={props.spaceWidth == "wide" ? 960 : 801}>
              <TiptapLikeButtonWrapper useSpace2={props.useSpace2} />
              <Comments
                useSpace2={props.useSpace2}
                buttonsZIndex={props.buttonsZIndex}
              />
              <CommentInput editable={false} useSpace2={props.useSpace2} />
            </Container>
          </Box>
        )}
        {!props.noPaddingBottom && (
          <Box
            sx={{
              height: "200px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Tiptap;
