import React, { useState, useEffect } from "react";
import {
  Button,
  Title,
  Container,
  Divider,
  Text,
  Group,
  Tooltip,
  MantineProvider,
  Box,
  ScrollArea,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import History from "./affiliates/History";

import SutraStripeModel from "../../models/SutraStripe";
import UserModel from "../../models/User";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { sutraTheme } from "../../collections/sutraTheme";

import { Helmet } from "react-helmet";

export default function Affiliates(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [settings, setSettings] = useState({});

  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const colors = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.authenticated) {
      onFetchSettings();
    }
  }, [user]);

  const onFetchSettings = () => {
    UserModel.onSettings(
      user.id,
      (data) => {
        setSettings({ ...data });
      },
      (data) => {}
    );
  };

  const getAffiliateLink = () => {
    return `https://sutra.co?a=${user.username}`;
  };

  const onCopyLink = () => {
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  const onConnect = () => {
    SutraStripeModel.onConnect(
      (data) => {
        if (data.url) {
          window.location.href = data.url;
        } else {
          onFetchSettings();
        }
      },
      (data) => {}
    );
  };

  const onDisconnect = () => {
    Swal.fire({
      title: "Are you sure?",
      html: "You won't be able to collect any payments.",
      showDenyButton: true,
      confirmButtonText: "Yes, disconnect",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        SutraStripeModel.onDisconnect(
          (data) => {
            onFetchSettings();
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const onStripeAccount = () => {
    window.open("https://dashboard.stripe.com/settings/user", "_blank");
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{t("settings.affiliates.tabTitle")}</title>
      </Helmet>
      <Container
        style={{ marginBottom: "50px" }}
        size={1000}
        pt={20}
        pb={40}
        pl={40}
        pr={40}
      >
        <Title sx={(theme) => ({ fontWeight: 900 })}>
          {t("settings.affiliates.title")}
        </Title>

        <Divider
          sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
          mt={10}
          mb={20}
        />

        <Group spacing={5}>
          <Text>{`${t("settings.affiliates.affiliateLink")}:`}</Text>
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            label={t("settings.affiliates.copyMessage")}
            opened={copiedToClipboard}
            position="bottom"
            withArrow
          >
            <CopyToClipboard
              onCopy={() => onCopyLink()}
              text={getAffiliateLink()}
            >
              <Text bl={20} mr={20} color="dimmed" align={"left"}>
                {`${getAffiliateLink()}`}
              </Text>
            </CopyToClipboard>
          </Tooltip>
        </Group>
        <Text mt={10} size="sm" color="dimmed">
          {t("settings.affiliates.description2")}
        </Text>
        {settings && settings.can_accept_charges ? (
          <Box mt={20}>
            <Text align="left" color={"dimmed"} size="sm">
              {t("settings.affiliates.stripeTitle")}
              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label={t("settings.affiliates.stripeConnected")}
                withArrow
                wrapLines
                position="bottom"
                width={300}
              >
                <Button
                  variant="subtle"
                  radius="xl"
                  compact
                  onClick={() => onStripeAccount()}
                >
                  {settings &&
                    settings.stripe &&
                    settings.stripe.stripe_user_id}
                </Button>
              </Tooltip>
              &nbsp;&nbsp;&bull;&nbsp;&nbsp;
              <Button
                variant="subtle"
                radius="xl"
                compact
                onClick={() => onDisconnect()}
              >
                {t("settings.affiliates.stripeDisconnect")}
              </Button>
            </Text>
          </Box>
        ) : (
          <Box mt={20}>
            <Text mb={15}>{t("settings.affiliates.description")}</Text>
            <MantineProvider
              theme={sutraTheme}
              // theme={{ primaryShade: 5 }}
            >
              <Button
                // leftIcon={<FontAwesomeIcon icon={['fab', 'stripe-s']} />}
                // color="green"
                radius="xl"
                onClick={() => onConnect()}
              >
                {t("settings.affiliates.connect")}
              </Button>
            </MantineProvider>
          </Box>
        )}
        <Title sx={(theme) => ({ fontWeight: 600 })} order={2} mt={40}>
          {t("settings.affiliates.historyLabel")}
        </Title>
        <Divider
          sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
          mt={10}
          mb={20}
        />
        <History />
      </Container>
    </ScrollArea>
  );
}
