// import axios from 'axios';
import { getCookie } from '../utils/Cookies';

class Auth {
  constructor() {
    if (this instanceof Auth) {
      throw Error('Auth class cannot be instantiated.');
    }
  }

  static onMagicSignIn = async (magic_token, {slug, plan}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      magic_token,
      slug,
      plan,
      join_slug: localStorage.getItem('join_slug'),
      gateway_slug: localStorage.getItem('gateway_slug'),
      invitation_slug: getCookie("invitation_slug"),
    }
    await window.$api.post(`/api/v4/auth/magic_sign_in.json`, params)
    .then(res => {
      localStorage.removeItem('join_slug');
      localStorage.removeItem('gateway_slug');
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onSignIn = async (email, password, {slug, plan}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      email,
      password,
      slug,
      plan,
      join_slug: localStorage.getItem('join_slug'),
      gateway_slug: localStorage.getItem('gateway_slug'),
      invitation_slug: getCookie("invitation_slug"),
    }
    await window.$api.post(`/api/v4/auth/sign_in.json`, params)
    .then(res => {
      localStorage.removeItem('join_slug');
      localStorage.removeItem('gateway_slug');
      sucessCallback(res.data)

    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onSignUp = async (userParams, {slug, plan}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      user: {...userParams},
      slug,
      plan,
      join_slug: localStorage.getItem('join_slug'),
      gateway_slug: localStorage.getItem('gateway_slug'),
      invitation_slug: getCookie("invitation_slug"),
      affiliate: getCookie("affiliate_sutra"),

    }
    await window.$api.post(`/api/v4/auth/sign_up.json`, params)
    .then(res => {

      localStorage.removeItem('join_slug');
      localStorage.removeItem('gateway_slug');
      sucessCallback(res.data)

    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onStart = async (email, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      email,
      slug
    }
    await window.$api.post(`/api/v4/auth/start.json`, params)
    .then(res => {

      sucessCallback(res.data)
    })
    .catch((error) => {

      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onMagicLink = async (email, {slug, plan}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      email,
      slug
    }
    await window.$api.post(`/api/v4/auth/magic_link.json`, params)
    .then(res => {

      sucessCallback(res.data)
    })
    .catch((error) => {

      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onRecoverPasswordInstructions = async (email, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      email
    }
    await window.$api.post(`/api/v4/auth/recover_password_instructions.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onResetPassword = async (reset_password_token, password, password_confirmation, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      user: {
        reset_password_token,
        password,
        password_confirmation
      }
    }
    await window.$api.post(`/api/v4/auth/reset_password.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onAffiliateData = async (username, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
    }
    await window.$api.get(`/api/v4/users/${username}/affiliate_data.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }



}

export default Auth;
