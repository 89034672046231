import React, { useEffect, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";
import { DateTime } from "luxon";

import InteractionModel from "../../../models/Interaction";

import { Text, createStyles, Paper, Group } from "@mantine/core";

import { showNextInteraction, setUniqId } from "../utils/selection";

import { wideOrNarrow } from "../../../utils/PageWidth";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
  },
  textColor: {
    color: colors.sidebar_text_color,
  },
}));

export default function InteractiveWait(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const navigate = useNavigate();
  const params = useParams();

  const checked = useRef();

  const formattedDate = DateTime.fromFormat(props.node.attrs.interactiveWaitValue, "yyyy/M/d").toFormat("LLLL d, yyyy");

  //setUniqId(props.editor, props.node, props.getPos);

  useEffect(() => {
    if (
      !checked.current &&
      window.$spaceUserInteractions &&
      !window.$spaceUserInteractions.can_manage
    ) {
      checked.current = true;
      checkInteraction();
    }
  }, [props]);

  const displayToTheUser = () => {
    if (window.$spaceUserInteractions) {
      if (window.$spaceUserInteractions.can_manage) {
        return true;
      }

      return false;
    }
    return true;
  };

  const checkInteraction = () => {
    if (window.$spaceUserInteractions.can_manage) {
      return;
    }
    if (!window.$spaceUserInteractions.logged_in) {
      return;
    }
    if (props.editor && !props.editor.isEditable) {
      InteractionModel.onCreate(
        params.slug,
        {
          view_id: `circle-content-${params.slug}`,
          position: props.node.attrs.interactivePosition,
          wait: props.node.attrs.interactiveWaitValue,
        },
        (data) => {
          if (data.interacted) {
            window.$spaceUserInteractions = {
              count: window.$spaceUserInteractions
                ? window.$spaceUserInteractions.count
                : 1,
              position: props.node.attrs.interactivePosition,
              logged_in: window.$spaceUserInteractions
                ? window.$spaceUserInteractions.logged_in
                : false,
            };
            showNextInteraction(props.editor, props.getPos());
          }
        },
        (data) => {}
      );
    }
  };

  const getHoursToDays = () => {
    return (
      parseInt(props.node.attrs.interactiveWaitValue.replace("h", "")) / 24
    );
  };

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      style={{
        maxWidth: wideOrNarrow(),
      }}
    >
      {displayToTheUser() && (
        <NodeViewContent className={`node-content`} contentEditable="false">
          <Paper
            radius={5}
            mt={15}
            mb={15}
            pt={12}
            pb={12}
            pl={20}
            pr={20}
            className={classes.panelWrap}
          >
            <Group position="apart">
              {props.node.attrs.interactiveWaitType == "datetime" ? (
                <Text align="center" weight={500} className={classes.textColor}>
                  {`Wait until ${formattedDate}`}
                </Text>
              ) : (
                <Text align="center" weight={500} className={classes.textColor}>
                  {`Wait for ${getHoursToDays()} days`}
                </Text>
              )}
            </Group>
          </Paper>
        </NodeViewContent>
      )}
    </NodeViewWrapper>
  );
}
