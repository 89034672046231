import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { Stack, TextInput } from "@mantine/core";

export default function InteractiveText(props) {
  const timeoutId = useRef(null);

  const loading = useRef(false);

  const tiptap = useSelector((state) => state.tiptap);
  const [text, setText] = useState();

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newText = props.element.node.attrs[`${props.attrName}`] ? text : null;

    if (newText) {
      const data = {};
      data[`${props.attrName}`] = newText;

      tiptap.editor.commands.updateAttributes(
        props.element.node.type.name,
        data
      );

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        props.saveNodeData();
      }, 2000);
    }
  }, [text]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      setText(props.element.node.attrs[`${props.attrName}`]);
    }
  }, [props.element]);

  return (
    <Stack mt={20}>
      <TextInput
        value={text}
        onChange={(event) => setText(event.currentTarget.value)}
        placeholder={`${props.attrPlaceholder}`}
        label={`${props.attrLabel}`}
        styles={{
          label: {
            fontWeight: 400,
            color: "#000",
          },
        }}
      />
    </Stack>
  );
}
