import React from "react";

import { Link } from "react-router-dom";

import { Title, Text, Group } from "@mantine/core";

export default function SectionHeader(props) {
  return (
    <Group
      noWrap
      position="apart"
      mb={20}
      pb={10}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <div>
        <Text
          component={Link}
          to={`/space/${props.spaceId}/${props.spaceVersion}`}
          className={props.subtitleClass}
          style={
            {
              "&:hover": {
                textDecoration: "underline"
              }
            }
          }
        >
          {props.spaceName}
        </Text>
        <Title
          className={props.titleClass}
          sx={(theme) => ({ fontWeight: 900 })}
        >
          {props.title}
        </Title>
      </div>
      {props.rightSide}
    </Group>
  );
}
