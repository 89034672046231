import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Broadcaster from "../../../helpers/Broadcaster";

import { Modal, AspectRatio, ScrollArea } from "@mantine/core";

import { getFullDomain } from "../../../utils/Host";

import {  setTiptapSidemenu } from "../../../store/app";



const ViewVideo = (props) => {
  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);
  const [node, setNode] = useState({});
  const video = useRef();

  useEffect(() => {
    Broadcaster.receive("open_VideVideo", document.body, (event, data) => {
      console.log("open_VideVideo IN");
      setNode(data.node);
      setOpened(true);
    });

    return () => {
      Broadcaster.unRegisterElement("open_VideVideo", document.body);
    };
  }, []);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "viewVideo") {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "viewVideo") {
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    }
    else{

      setTimeout(() => {
        if (!video.current){
          return
        }
        if (Hls.isSupported()) {
          let thumbnail = `${node.attrs.src
            .replace(".m3u8", "")
            .replace(
              "sutra-video-output.s3.us-west-1.amazonaws.com",
              "sutra-video-thumbnails.s3.us-west-1.amazonaws.com/thumbnails"
            )}_00001.png`;
          if (node.attrs.link.thumbnail && node.attrs.link.thumbnail.url){
            thumbnail = node.attrs.link.thumbnail.url
          }
          video.current.setAttribute("poster", thumbnail);

          const hls_config = {
            debug: false,
            xhrSetup: function (xhr, url) {
              // xhr.withCredentials = true; // do send cookie
              // xhr.setRequestHeader("Access-Control-Allow-Headers","Content-Type, Accept, X-Requested-With");
              xhr.setRequestHeader(
                "Access-Control-Allow-Origin",
                `${getFullDomain()}`
              );
              // xhr.setRequestHeader("Access-Control-Allow-Credentials","true");
            },
          };

          try {
            const hls = new Hls(hls_config);

            hls.loadSource(
              `/api/v4/proxy?url=https://sutra-video-output.s3.us-west-1.amazonaws.com/${node.attrs.src
                .split("/")
                .pop()}`
            );
            hls.attachMedia(video.current);
            debugger
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
              video.current.play().catch(function (error) {
                console.error("Failed to start video playback:", error);
              });
            });
          } catch (e) {}
        }
      }, 1000)
    }
  }, [opened]);

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      padding="sm"
      size={900}
      styles={{
        modal: {
          backgroundColor: colors.background_color,
          color: colors.default_text_color,
        },
      }}
      zIndex={99999}
    >
      <ScrollArea style={{ height: "100%" }}>
        {node && node.type && node.type.name == "embed" && (
          <AspectRatio ratio={16 / 9}>
            <iframe
              src={node.attrs.src}
              title="Video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </AspectRatio>
        )}
        {node && node.type && node.type.name == "video" && (
          <video ref={video} style={{ width: "100%" }} controls={true}>
            <source src={node.attrs.src}></source>
          </video>
        )}
      </ScrollArea>
    </Modal>
  );
};

export default ViewVideo;
