import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  Modal,
  Text,
  Grid,
  ScrollArea,
  Timeline,
  Loader,
  Center,
  Group,
  Button,
  Box,
  MantineProvider,
} from "@mantine/core";

import { setTiptapSidemenu } from "../../../store/app";

import ViewModel from "../../../models/View";

import TiptapNoHP from "./TiptapNoHP";

const VersionControl = (props) => {
  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);
  const [content, setContent] = useState({});
  const [backups, setBackups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingB, setLoadingB] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const paginator = useRef({ page: 1, per: 30 });

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "versionControl") {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "versionControl") {
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    } else {
      onFetchBackups();
    }
  }, [opened]);

  const onNextPage = () => {
    onFetchBackups(paginator.current.page + 1);
  };

  const onFetchBackups = (page = 1) => {
    setLoadingB(true);
    ViewModel.onFetchBackups(
      props.viewId,
      space.slug,
      { page, per: paginator.current.per },
      (data) => {
        if (data.length > 0) {
          paginator.current.page = page;
          if (page == 1) {
            setBackups([...data]);
            onFetchBackupById(data[0].id, 0);
          } else {
            const newData = [...backups];
            setBackups(newData.concat(data));
          }
        }
        setLoadingB(false);
      },
      (data) => {
        setLoadingB(false);
      }
    );
  };

  const onFetchBackupById = (backupId, index) => {
    setActiveIndex(index);
    setLoading(true);
    ViewModel.onFetchBackupById(
      props.viewId,
      backupId,
      space.slug,
      (data) => {
        setContent(data.content);
        setLoading(false);
      },
      (data) => {
        setLoading(false);
      }
    );
  };

  const onFetchBackupRestore = (backupId, index) => {
    ViewModel.onRestoreBackup(
      props.viewId,
      backupId,
      space.slug,
      (data) => {
        setOpened(false);
      },
      (data) => {}
    );
  };

  return (
    <Modal
      // overflow="inside"
      size={"80%"}
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {t("spaces.header.buttons.versionHistory")}
        </Text>
      }
      padding="xl"
      styles={{
        inner: {
          overflow: "hidden",
          height: "90vh",
        },
        modal: {
          backgroundColor: colors.background_color,
          color: colors.default_text_color,
        },
      }}
      zIndex={99999}
    >
      <Grid>
        <Grid.Col span={9}>
          <ScrollArea style={{ height: "90vh" }}>
            {loading ? (
              <Center mt={40}>
                <Loader />
              </Center>
            ) : (
              <TiptapNoHP
                key={`tiptap-${new Date()}`}
                content={content.default}
                spaceWidth={"narrow"}
              />
            )}
            <Box mb={200}></Box>
          </ScrollArea>
        </Grid.Col>
        <Grid.Col span={3}>
          <ScrollArea style={{ height: "90vh" }}>
            <Timeline
              active={activeIndex}
              bulletSize={24}
              lineWidth={2}
              styles={{ item: { color: colors.default_text_color } }}
            >
              {backups.map((backup, index) => {
                return (
                  <Timeline.Item
                    title={backup.created_at}
                    bullet={
                      activeIndex == index && (
                        <FontAwesomeIcon size="md" icon={regular("eye")} />
                      )
                    }
                  >
                    <Text
                      color="dimmed"
                      size="sm"
                      style={{ color: colors.default_text_color }}
                    >
                      {/* <Text variant="link" component="strong" inherit>
                        Size
                      </Text>
                      {": "}
                      {backup.size} */}

                      <Group mt={10}>
                        {activeIndex != index && (
                          <Button
                            radius="xl"
                            size="xs"
                            onClick={() => onFetchBackupById(backup.id, index)}
                          >
                            View
                          </Button>
                        )}
                        {activeIndex == index && (
                          <MantineProvider theme={{ primaryShade: 5 }}>
                            <Button
                              radius="xl"
                              size="xs"
                              color="green"
                              onClick={() => onFetchBackupRestore(backup.id)}
                            >
                              Restore
                            </Button>
                          </MantineProvider>
                        )}
                      </Group>
                    </Text>
                  </Timeline.Item>
                );
              })}
            </Timeline>
            {loadingB && (
              <Box mt={20}>
                <Center mt={40}>
                  <Loader />
                </Center>
              </Box>
            )}
            <Box mt={20}>
              <Center>
                <Button
                  variant="outline"
                  radius="xl"
                  size="xs"
                  onClick={() => onNextPage()}
                >
                  Load more
                </Button>
              </Center>
            </Box>

            <Box mb={200}></Box>
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default VersionControl;
