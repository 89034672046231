import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import {
  stylesToString,
  fixStringToJSON,
} from "../../../../utils/StylesToString";

import {
  Group,
  Popover,
  Text,
  Button,
  Slider,
  createStyles,
} from "@mantine/core";

const useStyles = createStyles((theme, { withinGrid }, getRef) => ({
  buttonControl: {
    fontSize: 9,
    backgroundColor: "#fff",
    width: withinGrid ? "" : 122,
    // paddingLeft: 4,
    // paddingRight: 4
  },
  buttonControlLabel: {
    fontSize: 9,
    fontWeight: 800,
    marginRight: 4,
  },
}));

export default function Margin(props) {
  const loading = useRef(false);
  const { t, i18n } = useTranslation();

  const { classes, cx, theme } = useStyles({ withinGrid: props.withinGrid });
  let topDefault = 0;
  let bottomDefault = 0;
  let leftDefault = 0;
  let rightDefault = 0;

  const [top, setTop] = useState(topDefault);
  const [bottom, setBottom] = useState(bottomDefault);
  const [left, setLeft] = useState(leftDefault);
  const [right, setRight] = useState(rightDefault);

  const [sTop, setSTop] = useState(topDefault);
  const [sBottom, setSBottom] = useState(bottomDefault);
  const [sLeft, setSLeft] = useState(leftDefault);
  const [sRight, setSRight] = useState(rightDefault);

  const [topOpened, setTopOpened] = useState(false);
  const [bottomOpened, setBottomOpened] = useState(false);
  const [leftOpened, setLeftOpened] = useState(false);
  const [rightOpened, setRightOpened] = useState(false);

  useEffect(() => {
    const node = props.element;
    if (!node) {
      return;
    }

    let newStyles = node.node.attrs.styles
      ? { ...node.node.attrs.styles }
      : null;
    if (newStyles) {
      const stylesKeys = Object.keys(newStyles);
      if (stylesKeys && stylesKeys[0] == "0") {
        console.log(newStyles);
        console.log(stylesToString(newStyles));
        newStyles = fixStringToJSON(stylesToString(newStyles));
        console.log(node.node.type);
        console.log(newStyles);
        props.saveNodeData({ newStyles, _node: node }, "styles");
        return;
      }
    }

    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    newStyles["margin-top"] = `${top}px`;
    newStyles["margin-bottom"] = `${bottom}px`;
    newStyles["margin-left"] = `${left}px`;
    newStyles["margin-right"] = `${right}px`;

    if (newStyles) {
      props.saveNodeData({ newStyles, _node: node }, "styles");
    }
  }, [top, bottom, left, right]);

  useEffect(() => {
    if (
      props.element &&
      props.element.node.attrs.styles &&
      typeof props.element.node.attrs.styles["margin-top"] !== "undefined" &&
      typeof props.element.node.attrs.styles["margin-bottom"] !== "undefined" &&
      typeof props.element.node.attrs.styles["margin-left"] !== "undefined" &&
      typeof props.element.node.attrs.styles["margin-right"] !== "undefined"
    ) {
      loading.current = props.element.node.attrs.uid;
      setTop(
        parseInt(
          props.element.node.attrs.styles["margin-top"].replace("px", "")
        )
      );
      setSTop(
        parseInt(
          props.element.node.attrs.styles["margin-top"].replace("px", "")
        )
      );
      setBottom(
        parseInt(
          props.element.node.attrs.styles["margin-bottom"].replace("px", "")
        )
      );
      setSBottom(
        parseInt(
          props.element.node.attrs.styles["margin-bottom"].replace("px", "")
        )
      );
      setLeft(
        parseInt(
          props.element.node.attrs.styles["margin-left"].replace("px", "")
        )
      );
      setSLeft(
        parseInt(
          props.element.node.attrs.styles["margin-left"].replace("px", "")
        )
      );
      setRight(
        parseInt(
          props.element.node.attrs.styles["margin-right"].replace("px", "")
        )
      );
      setSRight(
        parseInt(
          props.element.node.attrs.styles["margin-right"].replace("px", "")
        )
      );
    }
  }, [props.element]);

  return (
    <Group mt={0} spacing={3} position="apart" noWrap>
      <Popover
        opened={topOpened}
        onClose={() => setTopOpened(false)}
        target={
          <Button
            className={classes.buttonControl}
            color="dark"
            variant="outline"
            radius="sm"
            compact
            onClick={() => setTopOpened((o) => !o)}
          >
            <Text className={classes.buttonControlLabel}>Top</Text> {top}
          </Button>
        }
        width={260}
        position="bottom"
        placement="start"
        withArrow
        zIndex={999999}
      >
        <Slider
          min={0}
          max={200}
          value={sTop}
          onChange={setSTop}
          onChangeEnd={setTop}
          size="xl"
          mb={20}
          marks={[
            { value: 50, label: "50" },
            { value: 100, label: "100" },
            { value: 150, label: "150" },
          ]}
        />

        <Text align="center" size="xs" weight="500" color="dimmed" pt={10}>
          {t("tiptap.components.sidebar.margin.topMargin")}
          {props.type}
        </Text>
      </Popover>
      <Popover
        opened={bottomOpened}
        onClose={() => setBottomOpened(false)}
        target={
          <Button
            className={classes.buttonControl}
            color="dark"
            variant="outline"
            radius="sm"
            compact
            onClick={() => setBottomOpened((o) => !o)}
          >
            <Text className={classes.buttonControlLabel}>
              {t("tiptap.components.sidebar.margin.bottom")}
            </Text>{" "}
            {bottom}
          </Button>
        }
        width={260}
        position="bottom"
        placement="start"
        withArrow
        zIndex={999999}
      >
        <Slider
          min={0}
          max={200}
          value={sBottom}
          onChange={setSBottom}
          onChangeEnd={setBottom}
          size="xl"
          mb={20}
          marks={[
            { value: 50, label: "50" },
            { value: 100, label: "100" },
            { value: 150, label: "150" },
          ]}
        />

        <Text align="center" size="xs" weight="500" color="dimmed" pt={10}>
          {t("tiptap.components.sidebar.margin.text")}
          {props.type}
        </Text>
      </Popover>
      {props.withinGrid && (
        <>
          <Popover
            opened={leftOpened}
            onClose={() => setLeftOpened(false)}
            target={
              <Button
                className={classes.buttonControl}
                color="dark"
                variant="outline"
                radius="sm"
                compact
                onClick={() => setLeftOpened((o) => !o)}
              >
                <Text className={classes.buttonControlLabel}>
                  {t("tiptap.components.sidebar.margin.left")}
                </Text>{" "}
                {left}
              </Button>
            }
            width={260}
            position="bottom"
            placement="center"
            withArrow
            zIndex={999999}
          >
            <Slider
              min={0}
              max={200}
              value={sLeft}
              onChange={setSLeft}
              onChangeEnd={setLeft}
              size="xl"
              mb={20}
              marks={[
                { value: 50, label: "50" },
                { value: 100, label: "100" },
                { value: 150, label: "150" },
              ]}
            />

            <Text align="center" size="xs" weight="500" color="dimmed" pt={10}>
              {t("tiptap.components.sidebar.margin.leftMargin")}
              {props.type}
            </Text>
          </Popover>

          <Popover
            opened={rightOpened}
            onClose={() => setRightOpened(false)}
            target={
              <Button
                className={classes.buttonControl}
                color="dark"
                variant="outline"
                radius="sm"
                compact
                onClick={() => setRightOpened((o) => !o)}
              >
                <Text className={classes.buttonControlLabel}>
                  {t("tiptap.components.sidebar.margin.right")}
                </Text>{" "}
                {right}
              </Button>
            }
            width={260}
            position="bottom"
            placement="end"
            withArrow
            zIndex={999999}
          >
            <Slider
              min={0}
              max={200}
              value={sRight}
              onChange={setSRight}
              onChangeEnd={setRight}
              size="xl"
              mb={20}
              marks={[
                { value: 50, label: "50" },
                { value: 100, label: "100" },
                { value: 150, label: "150" },
              ]}
            />

            <Text align="center" size="xs" weight="500" color="dimmed" pt={10}>
              {t("tiptap.components.sidebar.margin.rightMargin")}
              {props.type}
            </Text>
          </Popover>
        </>
      )}
    </Group>
  );
}
