import { Extension } from '@tiptap/core'
import {stylesToString} from '../../../utils/StylesToString'

// https://tiptap.dev/guide/custom-extensions#attributes

const CustomStyles = Extension.create({
  name: "customStyles",
  addOptions() {
    return {
      types: [],
    }
  },
  addGlobalAttributes() {

    return [
      {
        // Extend the following extensions
        types: [
          'heading',
          'paragraph',
          'image',
          'video',
          'audio',
          'file',
          'embed',
          'grid1',
          'grid2',
          'grid3',
          // 'column',
          'tasklist',
          'orderedlist',
          'bulletList',
          'circle',
          'resource',
          'details',
          'detailsSummary',
          'resource',
          'actionbutton',
          'customform',
          'survey',
          'interactiveInput',
          'interactiveWait',
          'interactiveButton',
          'horizontalRule'
        ],
        // … with those attributes
        attributes: {
          styles: {
            default: {
              "margin-top": "0px",
              "margin-bottom": "0px",
              "margin-left": "0px",
              "margin-right": "0px",
              "padding-top": "7px",
              "padding-bottom": "0px",
              "padding-left": "0px",
              "padding-right": "0px",
              "background-color": "",
              "background-image": "none",
              "background-position": "center center",
              "background-size": "cover",
              "background-repeat": "no-repeat",
            },
            renderHTML: attributes => ({
              style: stylesToString(attributes.styles),
              styles: stylesToString(attributes.styles),
            })
          }
        },
      },
    ]
  },
  addCommands() {
    return {
      setCustomStyles: (styles) => ({ commands, chain, view }) => {
        if (!window.$tiptapSelectionRange){
          return
        }
        const {from, to} = window.$tiptapSelectionRange

        const { selection } = view.state

        const content = selection.node ? selection.node : selection.$anchor.parent

        return commands.updateAttributes(content.type.name, { styles: styles })


      },
      setPListStyles: () => ({ commands, chain, view }) => {
        if (!window.$tiptapSelectionRange){
          return
        }
        const {from, to} = window.$tiptapSelectionRange


        const { selection } = view.state

        const content = selection.node ? selection.node : selection.$anchor.parent
        let pos = selection.to
        if (!view.state.doc.nodeAt(selection.to)){
          pos = selection.to-1
        }
        const node = view.state.doc.nodeAt(pos)
        // debugger
        if (node && node.type.name == "paragraph"){
          const {tr} = view.state
          tr.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            styles: {
              "margin-top": "0px",
              "margin-bottom": "0px",
              "margin-left": "0px",
              "margin-right": "0px",
              "padding-top": "4px",
              "padding-bottom": "4px",
              "padding-left": "0px",
              "padding-right": "0px",
              "background-color": "",
              "background-image": "none",
              "background-position": "center center",
              "background-size": "cover",
              "background-repeat": "no-repeat",
            },
            insideList: true
          })

          setTimeout(() => {
            view.dispatch(tr)
          }, 200)
        }


      },
    }
  },
})

export default CustomStyles;
