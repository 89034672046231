// import axios from 'axios';

class Participation {
  constructor() {
    if (this instanceof Invitation) {
      throw Error('Invitation class cannot be instantiated.');
    }
  }

  static onFetchCounts = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.get(`/api/v4/spaces/${slug}/participation.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onSearchByDateRange = async (slug, {dates, term, page}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      start_at: dates[0],
      end_at: dates[1],
      term,
      page
    }

    await window.$api.get(`/api/v4/spaces/${slug}/participation/in_date_range.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default Participation;
