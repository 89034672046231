import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setAlert } from "../../store/app";

import Uploady, {
  useItemProgressListener,
  useItemFinishListener,
} from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";
import DefaultAvatar from "../../src/images/fallback/default.png";
import UserModel from "../../models/User";

import { setHiddenSidebar } from "../../store/app";

import SpaceReference from "./SpaceReference";
import { useTranslation } from "react-i18next";

import { useForm } from "@mantine/form";

import useAuthUser from "../../utils/AuthUser";

import { Helmet } from "react-helmet";

import {
  TextInput,
  PasswordInput,
  Checkbox,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Box,
  Divider,
  Image,
  Stepper,
  Grid,
  Textarea,
  ScrollArea,
  Progress,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

const LogProgress = (props) => {
  const [progress, setProgress] = useState(0);

  useItemProgressListener((item) => {
    setProgress(item.completed);
  });
  useItemFinishListener((item) => {
    props.onUploadCallback(item.uploadResponse.data);
  });

  return progress > 0 && progress < 100 ? <Progress value={progress} /> : "";
};

const CustomUploadButton = asUploadButton((props) => {
  const { t, i18n } = useTranslation();
  return (
    <Button
      {...props}
      variant="default"
      color="green"
      radius="xl"
      mt={20}
      mb={10}
    >
      {t("users.edit.addProfileImage")}
    </Button>
  );
});

export default function Edit(props) {
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.user);
  const space = useSelector((state) => state.space);

  const [dialog, setDialog] = useState({
    opened: false,
    title: "",
    message: "",
    color: "indigo",
  });

  const [spaceReference, setSpaceReference] = useState();

  const didMount = useRef(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { setAuthUser } = useAuthUser();

  const [showStepper, setStepper] = useState(false);

  const form = useForm({
    initialValues: {
      bio: "",
    },
  });

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 1000);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    initFormData();
  }, [user]);

  const initFormData = () => {
    form.setFieldValue("bio", user.bio);
  };

  const onAvatarUploaded = (data) => {
    dispatch(
      setAlert({
        opened: true,
        title: data.title,
        message: data.message,
        color: "green",
      })
    );

    // const newUser = { ...user };
    // newUser.avatar = { url: data.url };
    // setAuthUser(newUser);
    // dispatch(setUser(newUser));
  };

  const onRemoveAvatar = () => {
    UserModel.onRemoveAvatar(
      user.id,
      (data) => {
        // Success callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );

        // const newUser = { ...user };
        // newUser.avatar.url = null;
        // setAuthUser(newUser);
        // dispatch(setUser(newUser));
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onSave = (values) => {
    UserModel.onUpdate(
      user.id,
      {
        bio: values.bio,
      },
      (data) => {
        // Success callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );
        // const newUser = { ...user };
        // user.bio = values.bio;
        // setAuthUser(newUser);
        // dispatch(setUser(newUser));

        if (props.spaceId) {
          navigate(`/space/${props.spaceId}/${space.version}`);
        } else {
          navigate(onSkip());
        }
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onSkip = () => {
    return user.open_invitations
      ? `/home/invitations`
      : user.space_count
      ? `/home/top`
      : `/app/getting_started`;
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{`Sutra - Complete profile`}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        {!smallScreen && props.spaceId && showStepper ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={0} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : (
          ""
        )}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <form onSubmit={form.onSubmit((values) => onSave(values))}>
                <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                  <Box pt={20} pb={5} pl={35} pr={35}>
                    <Title
                      sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                      order={1}
                    >
                      {t("users.edit.title")}
                    </Title>
                  </Box>
                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                  <Box pt={10} pb={25} pl={35} pr={35}>
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          width: 130,
                          marginLeft: "auto",
                          marginRight: "auto",
                          textAlign: "center",
                        }}
                      >
                        <Image
                          radius={128}
                          src={
                            user.avatar.url ? user.avatar.url : DefaultAvatar
                          }
                          alt={user.username}
                        />
                      </div>
                      {!user.avatar.url ||
                      user.avatar.url.indexOf("assets/fallback") >= 0 ? (
                        <Uploady
                          destination={{
                            url: `/api/v4/users/${user.id}/upload_avatar.json?auth_token=${window.$currentUserAuthToken}&locale=${window.$currentUserLanguage}`,
                          }}
                        >
                          <CustomUploadButton />
                          <LogProgress onUploadCallback={onAvatarUploaded} />
                        </Uploady>
                      ) : (
                        <Button
                          variant="default"
                          color="gray"
                          radius="xl"
                          mt={20}
                          onClick={() => onRemoveAvatar()}
                        >
                          {t("users.edit.removeProfileImage")}
                        </Button>
                      )}
                    </div>
                    <Textarea
                      {...form.getInputProps("bio")}
                      placeholder={t("users.edit.bioPlaceholder")}
                      label={t("users.edit.bio")}
                      autosize
                      minRows={3}
                      maxRows={8}
                    />
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      type="submit"
                    >
                      {t("users.edit.submit")}
                    </Button>
                    {props.spaceId ? (
                      <Text size="md" align="center" mt={15}>
                        <Link to={`/space/${props.spaceId}/${space.version}`}>
                          {t("users.edit.skip")}
                        </Link>
                      </Text>
                    ) : (
                      <Text size="md" align="center" mt={15}>
                        <Link to={onSkip()}>{t("users.edit.skip")}</Link>
                      </Text>
                    )}
                  </Box>
                </Paper>
              </form>
            </Container>
          </Grid.Col>
          {props.spaceId ? (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                slug={props.spaceId}
                setSpaceReference={setSpaceReference}
                setStepper={setStepper}
              />
            </Grid.Col>
          ) : (
            <></>
          )}
        </Grid>
        <Box sx={{height: "100px"}}></Box>
      </Container>
    </ScrollArea>
  );
}
