import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useEditor, EditorContent } from "@tiptap/react";

import StarterKit from "@tiptap/starter-kit";

import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";

import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";

import Placeholder from "@tiptap/extension-placeholder";

import UniqueID from "../plugins/CustomUniqId";
import TextAlign from "@tiptap/extension-text-align";
import Details from "@tiptap-pro/extension-details";
import DetailsSummary from "@tiptap-pro/extension-details-summary";
import DetailsContent from "@tiptap-pro/extension-details-content";

import Link from "@tiptap/extension-link";
import ParagraphNode from "../nodes/ParagraphNode";
import HeadingNode from "../nodes/HeadingNode";
import ImageNode from "../nodes/ImageNode";
import VideoNode from "../nodes/VideoNode";
import AudioNode from "../nodes/AudioNode";
import EmbedNode from "../nodes/EmbedNode";
import FileNode from "../nodes/FileNode";
import SurveyNode from "../nodes/SurveyNode";
import ListItemNode from "../nodes/ListItemNode";
import FancyLinkNode from "../nodes/FancyLinkNode";

import TakeSurvey from "./TakeSurvey";

import FormatMenuSimple from "./FormatMenuSimple";
import BlocksMenuSimple from "./BlocksMenuSimple";

import CustomStyles from "../plugins/CustomStyles";
import EventHandler from "../plugins/EventHandler";
import EditorEvents from "../plugins/EditorEventsSimple";
import CustomSize from "../plugins/CustomSize";
import CustomResizeable from "../plugins/CustomResizeable";
import ColumnStyles from "../plugins/ColumnStyles";
import CustomButton from "../plugins/CustomButton";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import tinycolor from "tinycolor2";

import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";

import { Mention } from "../plugins/Mention";
import mentionSuggestion from "../plugins/mentionSuggestion";

import { Box, createStyles, ScrollArea } from "@mantine/core";

import { storeJSON, getJSON } from "../../../utils/LocalDB";

const useStyles = createStyles((theme, { colors }) => ({
  panelWrap: {
    border: `1px solid ${
      !isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).darken(10).toString()
        : colors.sidebar_background_color
    }`,
    borderRadius: 10,
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
}));

const Tiptap = (props) => {
  const colors = useSelector((state) => state.colors);
  const [showFormatting, setShowFormatting] = useState(false);
  const { classes, cx, theme } = useStyles({ colors });

  const timeoutId = useRef();
  const contextGet = useRef();

  const editor = useEditor({
    onCreate({ editor }) {
      // applyDevTools(editor.view);
    },
    editable: props.editable,
    content: props.content
      ? { type: "doc", content: props.content.content }
      : "",
    extensions: [
      StarterKit.configure({
        history: true,
        paragraph: false,
        heading: false,
        listItem: false,
        blockquote: false
      }),
      UniqueID.configure({
        types: [
          "heading",
          "paragraph",
          "grid2",
          "grid3",
          "column",
          "tasklist",
          "taskItem",
          "circle",
          "video",
          "audio",
          "image",
          "embed",
          "file",
          "detailsSummary",
          "details",
          "detailsContent",
          "bulletList",
          "orderedList",
          "listItem",
          "taskItem",
          "survey",
          "fancyLink",
        ],
      }),
      Highlight.configure({
        multicolor: true,
      }),
      Underline,
      TextStyle,
      Color,
      VideoNode,
      AudioNode,
      EmbedNode,
      FileNode,
      ListItemNode,
      ParagraphNode,
      HeadingNode,
      SurveyNode,
      FancyLinkNode,
      EventHandler,
      EditorEvents,
      ColumnStyles.configure({
        types: ["column"],
      }),
      CustomSize.configure({
        types: ["file", "audio", "survey", "fancyLink"],
      }),
      CustomResizeable.configure({
        types: ["image", "video", "embed"],
      }),
      CustomButton.configure({
        types: ["survey"],
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        placeholder: ({ node, pos }) => {
          if (node.type.name === "paragraph" && pos == 0) {
            return "Write something...";
          }

          return "";
        },
        showOnlyCurrent: true,
      }),
      Link.configure({
        openOnClick: false,
      }),
      ImageNode,
      TextAlign.configure({
        types: [
          "heading",
          "paragraph",
          "detailsSummary",
          "image",
          "video",
          "embed",
          "file",
          "audio",
          "survey",
          "fancyLink"
        ],
      }),
      Details.configure({
        HTMLAttributes: {
          openClassName: "is-open",
          class: "tiptap-details",
        },
      }),
      DetailsSummary.configure({
        HTMLAttributes: {
          class: "tiptap-details-summary",
        },
      }),
      DetailsContent.configure({
        HTMLAttributes: {
          class: "tiptap-details-content",
        },
      }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        // suggestion,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: mentionSuggestion,
        renderLabel({ options, node }) {
          const value = node.attrs.label ?? node.attrs.id.split("|")[1];
          return `${options.suggestion.char}${value}`;
        },
      }),
    ],
  });

  const chatHeight = () => {
    // TODO: NO JQUERY
    const height =
      $(window).height() - $("#fixed-height-is-main").height() - 80;
    if (height < 400) {
      return `${height - 80}px`;
    }

    return "350px";
  };

  useEffect(() => {
    return () => {
      
      contextGet.current = false
    }
  }, [])

  useEffect(() => {
    if (editor) {
      editor.off("update", onUpdate);
      editor.on("update", onUpdate);
      editor.off("transaction", onUpdate);
      editor.on("transaction", onUpdate);
      editor.off("selectionUpdate", onUpdate);
      editor.on("selectionUpdate", onUpdate);
      editor.off("create", onCreate);
      editor.on("create", onCreate);
    }
  }, [editor]);

  const onCreate = () => {
    contextGet.current = false
    
    getJSON(`spaces`, props.contextDb, (data) => {
      setTimeout(() => {
        contextGet.current = true
      }, 2000)
      if (data && data.json) {
        editor.commands.setContent(typeof data.json == "string" ? JSON.parse(data.json) : data.json)
      }
    })
  }
  
  const onUpdate = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      const json_data = editor.getJSON()
      const html_data = $(editor.view.dom).html()
      if (props.contextDb && contextGet.current){
        storeJSON("spaces", props.contextDb, { json: JSON.stringify(json_data), html: html_data });
      }
    }, 500);
  };

  return (
    <Box
      className={cx(classes.panelWrap, "tiptap-wrapper")}
      // mt={10}
      withBorder={true}
    >
      {props.editable && showFormatting ? (
        <FormatMenuSimple minimal={true} editor={editor} exclude={[]} />
      ) : (
        ""
      )}
      <Box
        style={{ maxHeight: chatHeight(), overflowY: "scroll" }}
        component={ScrollArea}
      >
        <EditorContent
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 0,
            paddingBottom: 0,
            color: colors.default_text_color,
          }}
          editor={editor}
        />
      </Box>
      {props.editable && (
        <BlocksMenuSimple
          editor={editor}
          onSave={props.onSave}
          onCancel={props.onCancel}
          cancellable={props.cancellable}
          editable={props.editable}
          setShowFormatting={setShowFormatting}
          showFormatting={showFormatting}
          saveTitle={props.saveTitle ? props.saveTitle : "Send"}
        />
      )}
      {props.modals && <TakeSurvey editor={editor} />}
    </Box>
  );
};

export default Tiptap;
