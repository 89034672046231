import React from "react";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";

import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

import { stylesToCamelCase } from "../../../utils/StylesToString";
import InterestModel from "../../../models/Interest";

import { checkInteractionAndHideIfNeededReact, setUniqId} from "../utils/selection";

import { Button, Stack, TextInput, createStyles } from "@mantine/core";
import { useForm } from "@mantine/form";

import { useTranslation } from "react-i18next";

import { wideOrNarrow } from "../../../utils/PageWidth";

import Swal from "sweetalert2";

import tinycolor from "tinycolor2";

const useStyles = createStyles((theme, { colors, props }) => ({
  formStyle: {
    "&:focus": {
      border: `1px solid ${tinycolor(colors.background_color)
        .darken(20)
        .toString()}`,
    },
  },
  buttonStyle: {
    backgroundColor: props.node.attrs.buttonBackgroundColor,
    color: props.node.attrs.buttonTextColor,
    border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
    "&:hover": {
      backgroundColor: tinycolor(props.node.attrs.buttonBackgroundColor)
        .darken(5)
        .toString(),
    },
  },
}));

export default function Form(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors, props });

  const { t, i18n } = useTranslation();
  const location = useLocation();

  //setUniqId(props.editor, props.node, props.getPos);

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.replace(/\s/g, '')) ? null : t("users.signUp.invalidEmailErrorMsg"),
      name: (value) =>
        value.length >= 2 ? null : t("users.signUp.invalidFirstNameErrorMsg"),
    },
  });

  const onSave = () => {
    const params = {
      ...form.values,
    };

    const slug = location.pathname.split("/")[2];

    InterestModel.onCreate(
      slug,
      params,
      (data) => {
        if (props.node.attrs.actionCallback == "link") {
          window.location.href= props.node.attrs.actionCallbackValue;
          return;
        } else {
          Swal.fire({
            icon: "success",
            html: props.node.attrs.actionCallbackValue,
            showDenyButton: false,
            confirmButtonText: "Ok",
            confirmButtonColor: props.node.attrs.buttonBackgroundColor,
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }

        onResetForm();
      },
      (data) => {}
    );
  };

  const onResetForm = () => {
    form.setFieldValue("email", "");
    form.setFieldValue("name", "");
  };

  const getRadius = () => {
    if (props.node.attrs.radius == "no-radius") {
      return "xs";
    } else if (props.node.attrs.radius == "circle-radius") {
      return "xl";
    } else if (props.node.attrs.radius == "with-radius") {
      return "sm";
    } else {
      return "xs";
    }
  };

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      style={{
        maxWidth: wideOrNarrow(),
      }}
    >
      {checkInteractionAndHideIfNeededReact(props.editor, props.getPos) && (
        <NodeViewContent
          className={`node-content ${props.node.attrs.class}`}
          contentEditable="false"
          style={{
            ...stylesToCamelCase(props.node.attrs.styles),
            textAlign: props.node.attrs.textAlign,
          }}
          radius={props.node.attrs.radius}
        >
          <form onSubmit={form.onSubmit((values) => onSave(values))}>
            <Stack justify="space-between">
              {props.node.attrs.fields &&
                Array.isArray(props.node.attrs.fields) &&
                props.node.attrs.fields.map((field) => {
                  return (
                    <TextInput
                      {...form.getInputProps(field.name)}
                      placeholder={field.placeholder}
                      label={field.label}
                      disabled={props.editor && props.editor.isEditable}
                      radius={getRadius()}
                      styles={{
                        input: classes.formStyle,
                      }}
                      // error={`Invalid ${field.name}`}
                      required
                    />
                  );
                })}

              <Button
                radius={getRadius()}
                type={`${
                  props.editor && props.editor.isEditable ? "button" : "submit"
                }`}
                className={classes.buttonStyle}
              >
                {props.node.attrs.buttonText}
              </Button>
            </Stack>
          </form>
        </NodeViewContent>
      )}
    </NodeViewWrapper>
  );
}
