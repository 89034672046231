import React, { useState, useEffect, useRef } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { ScrollArea, Box } from "@mantine/core";
import SpaceModel from "../../models/Space";
import SpaceListItem from "./presentations/List";

import { useTranslation } from "react-i18next";

const paginator = {
  per: 10,
  page: 1,
};

const scrollTimeoutIds = [];

export default function ChildrenList(props) {
  const { t, i18n } = useTranslation();
  // const scroll = useSelector(state => state.scroll);
  const navigate = useNavigate();

  const params = useParams();
  const didMount = useRef(false);

  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSpaceFilter, setSpaceFilter] = useState(true);

  const viewport = useRef();

  useEffect(() => {
    if (!didMount.current && props.spaceId) {
      didMount.current = true;
      onFetch();
    }
  }, [props]);

  const onFetch = () => {
    setLoading(true);

    SpaceModel.onFetchChildren(
      props.spaceId,
      ["members"],
      paginator,
      [props.scope],
      (data) => {
        setSpaces(data.data);
      },
      (data) => {
        setLoading(false);
      }
    );
  };

  const onScrollPositionChange = (pos) => {
    const div = viewport.current;
    // const div = document.getElementsByClassName("app-main-frame")[0]
    clearScrollTimeouts();

    if (pos.y + div.clientHeight >= div.scrollHeight - 50) {
      console.log("On bottom");
      const scrollTimeoutId = setTimeout(() => {
        console.log("On bottom timer");
        // At bottom
        onScrollBottom();
      }, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else if (pos.y == 0) {
      const scrollTimeoutId = setTimeout(() => {
        // At Top
      }, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else {
      // In between
    }
  };

  const clearScrollTimeouts = () => {
    for (let i = 0; i < scrollTimeoutIds.length; i++) {
      const id = scrollTimeoutIds[i];
      clearTimeout(id);
    }
  };

  const onScrollBottom = () => {
    if (!loading) {
      paginator.page++;

      onFetch();
    }
  };

  const getScopedTitle = () => {
    // const scope = params.scope ? params.scope : "top"
    // if (scope == "direct"){
    //   return t('home.spacesList.directMessagesTitle')
    // } else {
    //   return t('home.spacesList.yourSpaces')
    // }

    const scope = props.scope ? props.scope : "top";
    if (scope == "top") {
      return t("home.spacesList.topTitle");
    } else if (scope == "managed") {
      return t("home.spacesList.managedTitle");
    } else if (scope == "pending") {
      return t("home.spacesList.managedTitle");
    } else if (scope == "all") {
      return t("home.spacesList.allTitle");
    } else if (scope == "direct") {
      return t("home.spacesList.directMessagesTitle");
    } else if (scope == "archived") {
      return t("home.spacesList.archivedTitle");
    } else if (scope == "deleted") {
      return t("home.spacesList.deletedTitle");
    } else if (scope == "invitations") {
      return t("home.spacesList.invitations");
    }
  };

  return (
    <ScrollArea
      style={{ height: "100%" }}
      onScrollPositionChange={onScrollPositionChange}
      viewportRef={viewport}
    >
      {spaces.map((space) => {
        return (
          <Box
            sx={(theme) => ({
              margin: "15px 0px",
            })}
          >
            <SpaceListItem
              key={`space-list-item-${space.slug}`}
              space={space}
            />
          </Box>
        );
      })}
      {spaces.length == 0 && !loading ? (
        <Box
          mt={30}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "center",
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          {`You don't have any `}
          <b>{getScopedTitle().toLowerCase()}</b>
        </Box>
      ) : (
        ""
      )}
      {props.addMargin && <Box mb={300}></Box>}
    </ScrollArea>
  );
}
