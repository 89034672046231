import { useMantineTheme } from '@mantine/core';

export default function useNotificationsStyle() {

  const theme = useMantineTheme();

  const notificationsStyle = {
    notificationItem: {
      textDecoration: "none",
      padding: "10px 20px",
      borderBottom: `1px solid ${theme.colors.gray[1]}`,
      'a:hover': {
        textDecoration: "none"
      },
      '&:hover': {
        backgroundColor: theme.colors.gray[0]
      },
      cursor: "pointer"
    },
    badge: {
      justifyContent: "center",
      height: "100%",
      color: theme.colors.sutrapink[4]
    },
    empty: {
      padding: "20px 20px"
    }
  }

  return {
    notificationsStyle
  }

}