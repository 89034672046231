import axios from 'axios';

class UserAffiliate {
  constructor() {
    if (this instanceof UserAffiliate) {
      throw Error('UserAffiliate class cannot be instantiated.');
    }
  }

  static onFetch = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    }
    await axios.get(`/api/v4/users/${id}/affiliates.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

}

export default UserAffiliate;
