import React from 'react';
import { useTranslation } from "react-i18next";
import {
  Text,
  Box,
  MantineProvider,
  Alert
} from '@mantine/core'

export default function Open() {
  const { t, i18n } = useTranslation();
  return (
    <Box>
      <MantineProvider
        theme={{ primaryShade: 6 }}
        >
        <Alert
          color="gray"
          radius="md"
          mt={15}
          >
          <Text>
          {t("spaces.settings.privacy.open.open.description")}
          </Text>
        </Alert>
      </MantineProvider>
    </Box>
  )
}
