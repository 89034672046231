import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import LikeModel from "../../models/Like";

import { Button, Box, createStyles } from "@mantine/core";

import tinycolor from "tinycolor2";

import isSpaceThemed from "../../utils/IsSpaceThemed";
import defaultSpaceColors from "../../collections/defaultSpaceColors";

const useStyles = createStyles((theme, { colors }) => ({
  button: {
    borderRadius: theme.radius.md,
    color: tinycolor(colors.default_text_color).lighten(20).toString(),
    paddingLeft: 12,
    paddingRight: 12,

    "&:hover": {
      backgroundColor: !isSpaceThemed(colors)
        ? theme.colors.gray[1]
        : tinycolor(colors.background_color).lighten(3).toString(),
    },
  },
}));

export default function LikeButton(props) {
  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  const navigate = useNavigate()
  const params = useParams()

  const user = useSelector((state) => state.user);
  const colors = useSelector((state) => state.colors);
  const colors2 = useSelector((state) => state.colors2);

  const { classes, cx, theme } = useStyles({
    colors: getColors(),
  });

  const [liked, setLiked] = useState(false);
  const [count, setCount] = useState(0);
  const [hovered, setHovered] = useState(false);

  const propsLoaded = useRef();
  const likedR = useRef();
  const loaded = useRef();

  useEffect(() => {
    // if (propsLoaded.current){
    //   return
    // }
    propsLoaded.current = true
    setLiked(props.liked);
    likedR.current = props.liked;
    setCount(props.count);
  }, [props]);

  const onLike = (event) => {
    event.stopPropagation();

    if (!user.authenticated) {

      localStorage.setItem("join_slug", props.spaceId || params.slug);
      navigate(`/user/start?s=${props.spaceId || params.slug}`);
      return;
    }

    if (loaded.current){
      clearTimeout(loaded.current)
    }

    loaded.current = setTimeout(() => {
      LikeModel.onCreate(
        {
          pod_id: props.spaceId,
          pod_message_id: props.messageId,
          reflection_reflection_id: props.reflectionId,
        },
        (data) => {
          likedR.current = data.data.liked;
          setLiked(likedR.current);
          let newCount = likedR.current ? count + 1 : count - 1
          if (newCount < 0){
            newCount = 0
          }
          setCount(newCount);
          if (props.callback) {
            props.callback(data.data.liked);
          }
        },
        (data) => {}
      );
    }, 1000)

  };

  const onMouseEnter = (event) => {
    setHovered(true);
  };

  const onMouseLeave = (event) => {
    setHovered(false);
  };

  return (
    <Button
      className={props.customClass ? props.customClass : classes.button}
      variant="subtle"
      leftIcon={
        <Box sx={{
          width: "15px"
        }}>
          {
            liked ? (
              hovered ? (
                <FontAwesomeIcon size="lg" icon={solid("heart")} />
              ) : (
                <FontAwesomeIcon size="md" icon={solid("heart")} />
              )
            ) : hovered ? (
              <FontAwesomeIcon size="lg" icon={regular("heart")} />
            ) : (
              <FontAwesomeIcon size="md" icon={regular("heart")} />
            )
          }
        </Box>
      }
      onClick={(event) => onLike(event)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      Like
    </Button>
  );
}
