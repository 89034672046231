import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Chips, Chip, createStyles } from "@mantine/core";

const useStyles = createStyles((theme, _params, getRef) => ({
  chipChecked: {
    backgroundColor: `${theme.colors.dark[3]} !important`,
    color: theme.white,
    [`& .${getRef("iconWrapper")}`]: {
      color: theme.white,
    },
  },
  chipOutline3: {
    width: "80px",
    border: `1px solid ${theme.colors.dark[3]}`,
  },
  chipOutline4: {
    border: `1px solid ${theme.colors.dark[3]}`,
  },
  chipLabel: {
    textAlign: "center",
    fontSize: 9,
    fontWeight: 800,
    color: theme.colors.dark[3],
    height: 26,
  },
}));

export default function VerticalAlignment(props) {
  const loading = useRef(false);
  const { t, i18n } = useTranslation();
  const { classes, cx, theme } = useStyles();

  const tiptap = useSelector((state) => state.tiptap);
  const [verticalAlign, setVerticalAlign] = useState("flex-start");

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    let newStyles = node.node.attrs.styles
      ? { ...node.node.attrs.styles }
      : null;

    if (!newStyles) {
      newStyles = {};
    }

    newStyles["align-items"] = verticalAlign;

    if (newStyles) {
      // tiptap.editor.commands.setColumnStyles(newStyles)
      props.saveNodeData({ newStyles, _node: node }, "styles");
    }
  }, [verticalAlign]);

  useEffect(() => {
    if (props.element && props.element.node.attrs) {
      loading.current = props.element.node.attrs.uid;
      setVerticalAlign(props.element.node.attrs.styles["align-items"]);
    }
  }, [props.element]);

  return (
    <Chips
      mt={10}
      sx={{ width: "100%" }}
      color="dark"
      radius="sm"
      size="sm"
      multiple={false}
      value={verticalAlign}
      onChange={setVerticalAlign}
      classNames={{
        checked: classes.chipChecked,
        iconWrapper: classes.iconWrapper,
        outline: classes.chipOutline3,
        label: classes.chipLabel,
      }}
    >
      <Chip value="flex-start">
        {t("tiptap.components.sidebar.verticalAllignment.top")}
      </Chip>
      <Chip value="center">
        {t("tiptap.components.sidebar.verticalAllignment.center")}
      </Chip>
      <Chip value="flex-end">
        {t("tiptap.components.sidebar.verticalAllignment.bottom")}
      </Chip>
    </Chips>
  );
}
