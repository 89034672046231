import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";
import tinycolor from "tinycolor2";
import ReflectionModel from "../../../../models/Reflection";
import MessageModel from "../../../../models/Message";

import { setTiptapSidemenu } from "../../../../store/app";

import {
  Button,
  Badge,
  Box,
  Text,
  Menu,
  Popover,
  Group,
  Tooltip,
  createStyles,
} from "@mantine/core";

import DefaultAvatar from "../../../../src/images/fallback/default.png";
import TiptapSimple from "../../../tiptap/components/TiptapSimple";
import TiptapReadonly from "../../../tiptap/components/TiptapReadonly";
import LikeButton from "../../../likes/Button";
import MessageLikeButton from "../../../likes/MessageButton";
import Tag from "./Tag";

import { DateTime } from "luxon";

import useLangUser from "../../../../utils/LangUser";

import Avatar from "../../../utils/LazyAvatar";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { useInView } from "react-intersection-observer";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  button: {
    borderRadius: theme.radius.md,
    color: tinycolor(colors.default_text_color).lighten(20).toString(),
    // backgroundColor: "red",
    // border: '1px solid red',
    paddingLeft: 12,
    paddingRight: 12,

    "&:hover": {
      backgroundColor: !isSpaceThemed(colors)
        ? theme.colors.gray[1]
        : tinycolor(colors.background_color).lighten(3).toString(),
    },

    // '&:not(:first-of-type)': {
    //   borderRadius: 0,
    // },
    //
    // '&:first-of-type': {
    //   borderTopLeftRadius: theme.radius.md,
    //   borderBottomLeftRadius: theme.radius.md,
    // },
    //
    // '&:last-of-type': {
    //   borderTopRightRadius: theme.radius.md,
    //   borderBottomRightRadius: theme.radius.md,
    // },
  },
  reflectionButton: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  badgeClass: {
    backgroundColor: colors.default_badge_color,
    color: colors.default_badge_text_color,
    height: 15,
    padding: "0 8px",
  },
  messagePanel: {
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 5,
    "&:hover": {
      // backgroundColor: theme.colors.gray[0]
      backgroundColor: !isSpaceThemed(colors)
        ? theme.colors.gray[0]
        : tinycolor(colors.background_color).lighten(3).toString(),
    },

    [`&:hover .${getRef("toolPanel")}`]: {
      visibility: "visible",
    },
  },
  messagePanelEditable: {
    backgroundColor: !isSpaceThemed(colors)
      ? theme.colors.gray[0]
      : tinycolor(colors.background_color).lighten(3).toString(),
  },
  messagePanelNotActive: {
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    marginBottom: 5,
  },
  messageTagging: {
    backgroundColor: !isSpaceThemed(colors)
      ? theme.colors.gray[0]
      : tinycolor(colors.background_color).lighten(3).toString(),
    [`.${getRef("toolPanel")}`]: {
      visibility: "visible",
    },
  },
  toolPanel: {
    ref: getRef("toolPanel"),
    visibility: "hidden",
    backgroundColor: colors.background_color,
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
    borderRadius: theme.radius.md,
  },
  toolPanelTaggingNotActive: {
    visibility: "hidden",
  },
  username: {
    color: `${colors.default_text_color} !important`,
  },
  dateStamp: {
    color: !isSpaceThemed(colors)
      ? theme.colors.gray[5]
      : isDark(colors.default_text_color)
      ? tinycolor(colors.default_text_color).lighten(30).toString()
      : tinycolor(colors.default_text_color).darken(30).toString(),
  },
  abstract: {
    textDecoration: "none",
    // color: "#000",
    color: isSpaceThemed(colors) ? colors.default_text_color : "#000",
    margin: 0,

    "&:hover": {
      textDecoration: "none",
    },
  },

  footerElements: {
    color: isDark(colors.default_text_color)
      ? tinycolor(colors.default_text_color).lighten(30).toString()
      : tinycolor(colors.default_text_color).darken(30).toString(),
  },

  footerButtons: {
    fontSize: 14,
  },
}));

export default function Message(props) {
  const { t } = useTranslation();

  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const [editable, setEditable] = useState(false);
  const [tag, setTag] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [liked, setLiked] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const { language, setLangUser } = useLangUser();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { ref, inView } = useInView({ triggerOnce: true });

  const [reflections, setReflections] = useState(null);

  const loaded = useRef()

  useEffect(() => {
    if (props.tagging == props.messageId) {
      setTag(true);
    } else {
      setTag(false);
    }
    if (!loaded.current){
      loaded.current = true
      setLikesCount(props.likesCount);
      setLiked(props.isLiked)
    }
  }, [props]);

  useEffect(() => {
    if (inView && !reflections) {
      onFetchReflections();
    }
  }, [inView]);

  const onFetchReflections = () => {
    ReflectionModel.onFetchUnread(
      props.messageId,
      null,
      (data) => {
        setReflections({ ...data.reflections });
      },
      (data) => {}
    );
  };

  const onTag = (tags) => {
    // if (props.type == "pod_message"){
    //   MessageModel.onUpdate(props.messageId, tags, (data) => {}, (data) => {});
    // }
    // else{
    //   ReflectionModel.onUpdate(props.messageId, tags, (data) => {}, (data) => {});
    // }
  };

  const onSave = (json, html) => {
    if (props.type == "pod_message") {
      MessageModel.onUpdate(
        props.messageId,
        { json, html },
        (data) => {
          onUpdated();
          setTimeout(() => {
            setEditable(false);
          }, 500);
        },
        (data) => {}
      );
    } else {
      ReflectionModel.onUpdate(
        props.messageId,
        { json, html },
        (data) => {
          onUpdated();
          setTimeout(() => {
            setEditable(false);
          }, 500);
        },
        (data) => {}
      );
    }
  };

  const onMakePublic = () => {
    MessageModel.onMakePublic(
      props.messageId,
      (data) => {
        onUpdated();
      },
      (data) => {}
    );
  };

  const onMakePrivate = () => {
    MessageModel.onMakePrivate(
      props.messageId,
      (data) => {
        onUpdated();
      },
      (data) => {}
    );
  };

  const onUpdated = () => {
    if (props.type == "pod_message") {
      props.onUpdated(props.messageId);
    } else {
      props.onUpdated(props.parentId);
    }
  };

  const onDestroy = (json) => {
    Swal.fire({
      title: t("spaces.presentations.discussion.delete.title"),
      html: t("spaces.presentations.discussion.delete.message"),
      showDenyButton: true,
      confirmButtonText: t(
        "spaces.presentations.discussion.delete.confirmButton"
      ),
      denyButtonText: t("spaces.presentations.discussion.delete.cancelButton"),
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        onDestroyConfirmation(json);
      } else if (result.isDenied) {
      }
    });
  };

  const onDestroyConfirmation = (json) => {
    if (props.type == "pod_message") {
      MessageModel.onDestroy(
        props.messageId,
        (data) => {},
        (data) => {}
      );
    } else {
      ReflectionModel.onDestroy(
        props.messageId,
        (data) => {},
        (data) => {}
      );
    }
  };

  const onPinMessage = () => {
    if (props.type == "pod_message") {
      MessageModel.onPinMessage(
        props.messageId,
        (data) => {},
        (data) => {}
      );
    }
  };

  const onCloseTags = () => {
    setTag(false);
    // onUpdated()
  };

  const onCopy = () => {
    Swal.fire("Done", "Text copied to clipboard", "success");
  };

  const onTagFilter = (tag) => {
    navigate(`/space/${props.spaceId}/${props.spaceVersion}?tag=${tag.name}`);
  };

  const onCopyLink = () => {
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  const openProfile = () => {
    dispatch(
      setTiptapSidemenu({ opened: "profile", option: props.user.username })
    );
  };

  const onLike = (liked) => {
    setLikesCount(liked ? likesCount + 1 : likesCount - 1);
    setLiked(liked)
  };

  return (
    <Box
      className={cx(
        props.tagging !== 0 && props.tagging !== props.messageId
          ? classes.messagePanelNotActive
          : classes.messagePanel,
        { [classes.messageTagging]: tag },
        { [classes.messagePanelEditable]: editable }
      )}
      id={props.htmlId}
      ref={ref}
    >
      <Group
        spacing={0}
        noWrap
        sx={{
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
          }}
        >
          <Avatar
            className={classes.username}
            onClick={() => openProfile()}
            radius="xl"
            size={45}
            mt={7}
            src={
              props.user && props.user.avatar && props.user.avatar.url
                ? props.user.avatar.url
                : DefaultAvatar
            }
          />
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Group noWrap position="apart" ml={16}>
            <Group spacing={0} noWrap>
              <Text
                className={classes.username}
                onClick={() => openProfile()}
                size="md"
                weight="700"
                pb={2}
                sx={{ lineHeight: 1.2, cursor: "pointer" }}
              >
                {props.user.full_name}
              </Text>
              <Text
                ml={10}
                size="sm"
                className={classes.dateStamp}
                lineClamp={1}
              >
                {new Date(props.messageCreatedAt).getYear() ==
                new Date().getYear()
                  ? `${DateTime.fromISO(props.messageCreatedAt)
                      .setLocale(language)
                      .toFormat("EEE, MMM d, h:mm a")}`
                  : `${DateTime.fromISO(props.messageCreatedAt)
                      .setLocale(language)
                      .toFormat("EEE, MMM d y, h:mm a")}`}
              </Text>
            </Group>
            <Box
              // radius="md"
              // shadow="xs"
              className={
                (props.tagging !== 0 && props.tagging !== props.messageId) ||
                editable
                  ? classes.toolPanelTaggingNotActive
                  : classes.toolPanel
              }
            >
              <Group noWrap position="right" spacing={0}>
                {props.canLike && (
                  <MessageLikeButton
                    messageId={
                      props.type == "pod_message" ? props.messageId : null
                    }
                    reflectionId={
                      props.type == "reflection" ? props.messageId : null
                    }
                    liked={liked}
                    count={likesCount}
                    callback={onLike}
                  />
                )}

                {props.canReflect ? (
                  <Button
                    className={classes.button}
                    variant="subtle"
                    leftIcon={<FontAwesomeIcon icon={solid("comments")} />}
                    onClick={() => props.onOpenReflections(props.messageId)}
                  >
                    {t("spaces.presentations.message.reply")}
                  </Button>
                ) : (
                  ""
                )}
                {props.canTag ? (
                  <Popover
                    opened={tag}
                    onClose={() => {
                      onCloseTags();
                      props.setTagging(0);
                    }}
                    withCloseButton
                    title={t("spaces.presentations.message.addTags")}
                    closeButtonLabel="Close popover"
                    closeOnClickOutside={false}
                    styles={{
                      inner: {
                        padding: 0,
                      },
                    }}
                    target={
                      <Button
                        className={classes.button}
                        onClick={() => {
                          setTag(!tag);
                          props.setTagging(props.messageId);
                        }}
                        variant="subtle"
                        leftIcon={<FontAwesomeIcon icon={solid("tag")} />}
                      >
                        {t("spaces.presentations.message.tag")}
                      </Button>
                    }
                    width={320}
                    position="bottom"
                    placement="end"
                    withArrow
                  >
                    <Tag
                      type={props.type}
                      spaceId={props.spaceId}
                      messageId={props.messageId}
                      tags={props.tags}
                      onSave={onTag}
                      onCancel={setTag}
                      onUpdatedMessage={onUpdated}
                    />
                  </Popover>
                ) : (
                  ""
                )}
                {props.canEdit || props.canDelete || props.canPin ? (
                  <Tooltip
                    label={t("spaces.presentations.message.messageCopied")}
                    opened={copiedToClipboard}
                    position="bottom"
                    withArrow
                  >
                    <Menu
                      withArrow
                      placement="end"
                      control={
                        <Button className={classes.button} variant="subtle">
                          <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
                        </Button>
                      }
                    >
                      <Menu.Item onClick={() => onPinMessage()}>
                        <CopyToClipboard
                          onCopy={() => onCopyLink()}
                          text={props.text}
                        >
                          <span>
                            {t("spaces.presentations.message.copyToClipboard")}
                          </span>
                        </CopyToClipboard>
                      </Menu.Item>

                      {props.canPin && (
                        <Menu.Item onClick={() => onPinMessage()}>
                          {props.isPinned
                            ? "Unpin message"
                            : t(
                                "spaces.presentations.message.pinMessageAction"
                              )}
                        </Menu.Item>
                      )}
                      {props.canEdit && (
                        <Menu.Item onClick={() => setEditable(true)}>
                          {t("spaces.presentations.message.editMessage")}
                        </Menu.Item>
                      )}
                      {props.canDelete && (
                        <Menu.Item onClick={() => onDestroy()}>
                          {t("spaces.presentations.message.deleteMessage")}
                        </Menu.Item>
                      )}
                      {props.canSetPrivate && !props.isPrivate && (
                        <Menu.Item onClick={() => onMakePrivate()}>
                          {t("spaces.presentations.message.makePrivate")}
                        </Menu.Item>
                      )}
                    </Menu>
                  </Tooltip>
                ) : (
                  <Box mt={36} />
                )}
              </Group>
            </Box>
          </Group>
          <div>
            {props.version == "new" ? (
              <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                {editable == true ? (
                  <TiptapSimple
                    editable={true}
                    onSave={onSave}
                    cancellable={true}
                    onCancel={setEditable}
                    content={props.messageContent}
                    saveTitle={"Save"}
                    modals={false}
                  />
                ) : (
                  <TiptapReadonly content={props.messageContent} />
                )}
              </div>
            ) : (
              <div
                style={{
                  margin: "-17px 16px 0 16px",
                }}
                dangerouslySetInnerHTML={{ __html: props.messageContent }}
              ></div>
            )}

            {/*
              <Group
                ml={18}
                className={cx(classes.abstract, classes.footerElements)}
                spacing={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {true && (
                <Box
                  mr={5}
                >
                  <LikeButton
                    messageId={props.messageId}
                    liked={props.isLiked}
                    count={props.likesCount}
                  />
                </Box>
                )}
                {true && (
                  <Box
                    sx={{
                      borderRadius: 50,
                      paddingLeft: 0,
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingBottom: 2,
                      marginRight: 25,
                      width: 40,
                      // "&:hover": {
                      //   backgroundColor: theme.colors.gray[0],
                      // }
                    }}
                  >
                    <Text
                      className={cx(classes.abstract, classes.footerElements, classes.footerButtons)}
                    >
                      Reflect
                    </Text>
                  </Box>
                )}
                {true && (
                  <Box
                    sx={{
                      borderRadius: 50,
                      paddingLeft: 0,
                      paddingRight: 5,
                      paddingTop: 2,
                      paddingBottom: 2,
                      marginRight: 8,
                      width: 40,
                      // "&:hover": {
                      //   backgroundColor: theme.colors.gray[0],
                      // }
                    }}
                  >
                    <Text
                      className={cx(classes.abstract, classes.footerElements, classes.footerButtons)}
                    >
                      Tag
                    </Text>
                  </Box>
                )}
              </Group>
              */}

            {props.tags && (
              <Box ml={16} mr={16} mt={5} mb={5}>
                {props.tags.map((tag) => {
                  return (
                    <Button
                      sx={(theme) => ({
                        backgroundColor:
                          theme.other.tagColors[tag.color].backgroundColor,
                        color: theme.other.tagColors[tag.color].textColor,
                        "&:hover": {
                          backgroundColor:
                            theme.other.tagColors[tag.color].hoverColor,
                        },
                      })}
                      styles={{
                        root: {
                          height: 20,
                          marginRight: 5,
                        },
                        label: { fontSize: 10 },
                      }}
                      size="xs"
                      radius="xl"
                      onClick={() => onTagFilter(tag)}
                    >
                      {tag.name}
                    </Button>
                  );
                })}
              </Box>
            )}
            {props.canSetOpen && (
              <Box ml={16} mr={16}>
                <Tooltip
                  width={300}
                  wrapLines
                  position="bottom"
                  label={t("spaces.presentations.message.privateMessage")}
                  withArrow
                >
                  <Button
                    sx={(theme) => ({
                      backgroundColor:
                        theme.other.tagColors[13].backgroundColor,
                      color: theme.other.tagColors[13].textColor,
                      "&:hover": {
                        backgroundColor: theme.other.tagColors[13].hoverColor,
                      },
                    })}
                    styles={{
                      root: {
                        height: 20,
                        marginRight: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                      },
                      label: { fontSize: 10 },
                    }}
                    size="xs"
                    radius="xl"
                    leftIcon={<FontAwesomeIcon icon={regular("eye-slash")} />}
                    onClick={() => onMakePublic()}
                  >
                    Make public
                  </Button>
                </Tooltip>
              </Box>
            )}
            {props.isManager && props.isPrivate && (
              <Box ml={16} mr={16}>
                <Tooltip
                  width={300}
                  wrapLines
                  position="bottom"
                  label={t("spaces.presentations.message.privateMessage2")}
                  withArrow
                >
                  <Button
                    sx={(theme) => ({
                      backgroundColor:
                        theme.other.tagColors[13].backgroundColor,
                      color: theme.other.tagColors[13].textColor,
                      "&:hover": {
                        backgroundColor: theme.other.tagColors[13].hoverColor,
                      },
                    })}
                    styles={{
                      root: {
                        height: 20,
                        marginRight: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                      },
                      label: { fontSize: 10 },
                    }}
                    size="xs"
                    radius="xl"
                    leftIcon={<FontAwesomeIcon icon={regular("eye-slash")} />}
                  >
                    Private
                  </Button>
                </Tooltip>
              </Box>
            )}
            <Group>
              {props.reflections.length > 0 ? (
                <Button
                  mt={5}
                  ml={16}
                  variant="filled"
                  radius="xl"
                  compact
                  className={classes.reflectionButton}
                  onClick={() => props.onOpenReflections(props.messageId)}
                >
                  {t("spaces.presentations.message.viewReplies1")}{" "}
                  {props.reflections.length}{" "}
                  {props.reflections.length > 1
                    ? t("spaces.presentations.message.viewReplies2plural")
                    : t("spaces.presentations.message.viewReplies2single")}
                  {reflections && reflections.unread > 0 ? (
                    <Badge
                      ml={10}
                      classNames={{
                        root: classes.badgeClass,
                      }}
                    >
                      {reflections.unread}
                    </Badge>
                  ) : (
                    ""
                  )}
                </Button>
              ) : (
                ""
              )}
              {likesCount > 0 && (
                <Box mt={5} ml={props.reflections.length > 0 ? 0 : 16}>
                  <LikeButton
                    messageId={
                      props.type == "pod_message" ? props.messageId : null
                    }
                    reflectionId={
                      props.type == "reflection" ? props.messageId : null
                    }
                    liked={liked}
                    count={likesCount}
                    callback={onLike}
                  />
                </Box>
              )}
            </Group>
          </div>
        </Box>
      </Group>
    </Box>
  );
}
