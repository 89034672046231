import React from 'react';

import { Routes, Route } from "react-router-dom";

import SidebarHome from '../home/Sidebar'
import SidebarUser from '../settings/Sidebar'
import SidebarSpace from '../spaces/sidebar/Sidebar'
import SidebarSpaceNeo from '../spaces_neo/sidebar/Sidebar'

export default function NavbarContent() {

  return (
    <Routes>
      <Route path="/" element={<SidebarHome />} />
      <Route path="/home/:scope" element={<SidebarHome />} />
      <Route path="/settings/*" element={<SidebarUser />} />
      <Route path="/space_legacy/*" element={<SidebarSpace />} />
      <Route path="/space/*" element={<SidebarSpaceNeo />} />
      <Route path="/circle/*" element={<SidebarSpaceNeo />} />
      <Route path="/app/*" element={<SidebarHome />} />
      <Route path="/zoom/*" element={<SidebarHome />} />
    </Routes>
  )
}
