const isDark = (bgColor) => {
  try {

    if (typeof bgColor !== 'undefined') {
      var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
        false : true;
    } else return false
  }
  catch {
    return false
  }
}

export default isDark