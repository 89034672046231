import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import {
  Text,
  Box,
  NativeSelect,
  TextInput,
  Group
} from '@mantine/core'

import Fees from './Fees'

const data = [
  { value: 'usd', label: '🇺🇸 USD' },
  { value: 'eur', label: '🇪🇺 EUR' },
  { value: 'cad', label: '🇨🇦 CAD' },
  { value: 'aud', label: '🇦🇺 AUD' },
  { value: 'gbp', label: '🇬🇧 GBP' },
  { value: 'sek', label: '🇸🇪 SEK' },
  { value: 'mxn', label: '🇲🇽 MXN' },
  { value: 'inr', label: '🇮🇳 INR' },
  { value: 'chf', label: '🇨🇭 CHF' },
  { value: 'nzd', label: '🇳🇿 NZD' },
];

export default function Once(props) {

  const [currency, setCurrency] = useState("usd");
  const [defaultPaymentAmount, setDefaultPaymentAmount] = useState("");
  const [disableActions, setDisableActions] = useState(false);
  const propsLoaded = useRef()
  const lastSpaceId = useRef();
  const timoutId = useRef();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    return () => {
      propsLoaded.current = false
    };
  }, []);

  useEffect(() => {
    if (props.settings){

      if (propsLoaded.current && props.settings.slug == lastSpaceId.current && props.settings.payment_settings.default_payment_frequency == "once"){
        return
      }
      propsLoaded.current = props.settings.payment_settings.default_payment_frequency != "once"
      // setDisableActions(true)
      lastSpaceId.current = props.settings.slug

      setCurrency(props.settings.payment_settings.currency)
      setDefaultPaymentAmount(props.settings.payment_settings.default_payment_amount)
      if (props.settings.payment_settings.default_payment_frequency == "once"){
        setTimeout(() => {
          propsLoaded.current = true
          // setDisableActions(false)
        }, 500)
      }
      else{
        // setDisableActions(false)
      }
    }
  }, [props])

  useEffect(() => {
    if (propsLoaded.current && currency){

      if (props.updateAll){
        props.onUpdate({pod_payment_settings_attributes: {currency: currency, default_payment_amount: defaultPaymentAmount, default_payment_frequency: "once"}})
      }
      else{
        props.onUpdate({pod_payment_settings_attributes: {currency: currency}})
      }

      

    }
  }, [currency])

  useEffect(() => {
    if (propsLoaded.current && defaultPaymentAmount){
      if (timoutId.current){
        clearTimeout(timoutId.current)
      }

      timoutId.current = setTimeout(() => {
        props.onUpdate({pod_payment_settings_attributes: {default_payment_amount: defaultPaymentAmount, default_payment_frequency: "once"}, circle_payment: "paid"})
      }, 1000)
      
    }
  }, [defaultPaymentAmount])

  return (
    <Box mt={20} mb={20}>
      <Text
        mb={8}
        color="dimmed"
        >
        {t("spaces.settings.payment.once.once.description")}
      </Text>

      <Group
        noWrap
        spacing={0}
        >
        <NativeSelect
          disabled={disableActions}
          value={currency}
          onChange={(event) => setCurrency(event.currentTarget.value)}
          data={data}
          size="lg"
          radius="md"
          styles={{
            input: {
              fontWeight: 500,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
          />

        <TextInput
          disabled={disableActions}
          value={defaultPaymentAmount}
          onChange={(event) => setDefaultPaymentAmount(event.target.value)}
          type="number"
          placeholder={t("spaces.settings.payment.once.once.number")}
          size="lg"
          radius="md"
          styles={{
            input: {
              fontWeight: 500,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeft: "none"
            },
          }}
          />
      </Group>
      {props.settings && (
        <Fees
          fee={props.settings.fee}/>
      )}

    </Box>
  )
}
