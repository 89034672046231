import { Extension } from "@tiptap/core";

// https://tiptap.dev/guide/custom-extensions#attributes

const Incrementor = Extension.create({
  name: "incrementor",
  addOptions() {
    return {
      types: [],
    };
  },
  addGlobalAttributes() {
    return [
      {
        // Extend the following extensions
        types: [
          "heading",
          "paragraph",
          "grid1",
          "grid2",
          "grid3",
          "grid4",
          "infobox",
          "column",
          "tasklist",
          "taskItem",
          "circle",
          "resource",
          "video",
          "audio",
          "image",
          "embed",
          "file",
          "detailsSummary",
          "details",
          "detailsContent",
          "bulletList",
          "orderedList",
          "listItem",
          "resource",
          "actionbutton",
          "customform",
          "survey",
          "interactiveInput",
          "interactiveButton",
          "interactiveWait",
          "fancyLink",
        ],
        // … with those attributes
        attributes: {
          incrementor: {
            default: 0,
          },
        },
      },
    ];
  },
});

export default Incrementor;
