import React, { useState, useEffect } from 'react';

import {
  Text,
  Box,
  Drawer,
  SegmentedControl,
  ScrollArea,
} from '@mantine/core';


import { useTranslation } from 'react-i18next'


import Summary from './Summary'
import Individual from './Individual'

import SurveyModel from "../../../../models/Survey";

export default function ShowSurvey(props) {

  const [surveyType, setSurveyType] = useState("summary");
  const { t, i18n } = useTranslation();
  const [survey, setSurvey] = useState(null)

  useEffect(() => {
    
    if (props.survey && props.survey.id){
      onFetch()
    }
  }, [props])

  const onFetch = () => {
    
    SurveyModel.onFetchById(props.survey.id, props.spaceId, (data) => {
      setSurvey(data)
    }, (data) => {

    })
  }

  const displaySurveyType = () => {
    if (surveyType == "summary") {
      return (
        <Summary
          spaceId={props.spaceId}
          survey={survey}/>
      )
    } else if (surveyType == "individual") {
      return (
        <Individual
          spaceId={props.spaceId}
          survey={survey}
          context="settings"/>
      )
    } else {
      return (
        <Summary
          spaceId={props.spaceId}
          survey={survey}/>
      )
    }
  }

    return (
        <Drawer
          opened={props.opened}
          onClose={() => props.setOpened(false)}
          title={<Text size="xl" weight="800">{t("spaces.settings.survey.showSurvey.survey.title")}</Text>}
          padding="xl"
          size={600}
        >
          <ScrollArea style={{ height: "100%" }}>
            <SegmentedControl
              fullWidth
              value={surveyType}
              onChange={setSurveyType}
              defaultValue="summary"
              size="md"
              data={[
                { value: 'summary', label: 'Summary' },
                { value: 'individual', label: 'Individual' },
              ]}
            />

            {displaySurveyType()}

            <Box
              sx={{ height: 100 }}
             />
          </ScrollArea>
        </Drawer>
    )
}
