import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack, TextInput } from "@mantine/core";

export default function BlockId(props) {
  const timeoutId = useRef(null);

  const loading = useRef(false);
  const { t, i18n } = useTranslation();
  const tiptap = useSelector((state) => state.tiptap);
  const [blockId, setBlockId] = useState();

  useEffect(() => {
    
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newBlockId = node.node.attrs.blockId != null ? blockId : null;
    
    if (newBlockId) {
      tiptap.editor.commands.setCustomBlockId(newBlockId);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        props.saveNodeData();
      }, 2000);
    }
  }, [blockId]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.blockId != null) {
      loading.current = props.element.node.attrs.uid;

      setBlockId(props.element.node.attrs.blockId);
    }
  }, [props.element]);

  return (
    <Stack mt={20}>
      <TextInput
        value={blockId}
        onChange={(event) => setBlockId(event.currentTarget.value)}
        placeholder={t("tiptap.components.sidebar.blockId.placeholder")}
        label={t("tiptap.components.sidebar.blockId.label")}
        description={t("tiptap.components.sidebar.blockId.description")}
        styles={{
          label: {
            fontWeight: 400,
            color: "#000",
          },
        }}
      />
    </Stack>
  );
}
