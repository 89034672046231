import { setResizeable } from "./setResizeable";

export function setLazyLoad(
  element,
  src,
  editor,
  resize = null,
  getPos = null
) {
  if (!window.$loadedSources) {
    window.$loadedSources = [];
  }

  if (window.$loadedSources.indexOf(src)){
    element.setAttribute("src", src);

    if (resize && editor.isEditable) {
      setTimeout(() => {
        setResizeable(resize, editor, getPos());
      }, 1000);
    }
    return
  }

  setTimeout(() => {
    const options = {
      rootMargin: "0px",
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.setAttribute("src", src);
          element.removeAttribute("data-src");
          observer.unobserve(element);

          if (resize && editor.isEditable) {
            setTimeout(() => {
              setResizeable(resize, editor, getPos());
            }, 1000);
          }

          window.$loadedSources.push(src)
        }
      });
    }, options);

    element.setAttribute("data-src", src);
    element.style.minHeight = "unset";
    observer.observe(element);
  }, 500);
}
