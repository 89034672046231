import React, { useEffect } from "react";

import {
  Button,
  ScrollArea,
  Stack,
  TextInput,
  Checkbox,
} from "@mantine/core";
import SpaceModel from "../../../models/Space";

import { useForm } from "@mantine/form";

import { useTranslation } from "react-i18next";

import { useFocusTrap } from "@mantine/hooks";

let newSpaceLoading = false;

export default function Form(props) {

  const { t, i18n } = useTranslation();

  const focusTrapRef = useFocusTrap();

  const form = useForm({
    initialValues: {
      intention: "",
      copy_members: false,
      copy_managers: false,
      copy_resources: true,
      copy_sub_circles: false,
      copy_structures: false,
      copy_surveys: false,
      copy_broadcasts: false,
      copy_journeys: false,
      copy_reg_page: false,
    },

    validate: {
      intention: (value) =>
        value.length >= 3 ? null : t("spaces.create.invalidNameErrorMsg"),
    },
  });

  useEffect(() => {
    form.setFieldValue(
      "intention",
      `${t("spaces.form.duplicate.prefix")} ${props.spaceName.substring(0, 100)}`
    );
  }, [props]);

  const onSave = () => {
    if (newSpaceLoading) {
      return;
    }
    newSpaceLoading = true;
    const spaceParams = { ...form.values };

    SpaceModel.onDuplicate(
      spaceParams,
      props.spaceId,
      (data) => {
        props.onDuplicate(data.data.slug, data.data.link);
        setTimeout(() => {
          newSpaceLoading = false;
        }, 2000);
      },
      (data) => {
        setTimeout(() => {
          newSpaceLoading = false;
        }, 500);
      }
    );
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <form
        onSubmit={form.onSubmit((values) => onSave(values))}
        ref={focusTrapRef}
      >
        <Stack>
          <TextInput
            {...form.getInputProps("intention")}
            label={t("spaces.form.duplicate.intention.label")}
            placeholder={t("spaces.duplicate.intention.placeholder")}
            data-autofocus
          />
          <Checkbox
            {...form.getInputProps("copy_members")}
            label={t("spaces.form.duplicate.copyMembers.label")}
          />
          <Checkbox
            {...form.getInputProps("copy_managers")}
            label={t("spaces.form.duplicate.copyManagers.label")}
          />
          <Checkbox
            {...form.getInputProps("copy_resources")}
            label={t("spaces.form.duplicate.copyContent.label")}
          />
          <Checkbox
            {...form.getInputProps("copy_broadcasts")}
            label={t("spaces.form.duplicate.copyBroadcasts.label")}
          />
          <Checkbox
            {...form.getInputProps("copy_journeys")}
            label={t("spaces.form.duplicate.copyAutomation.label")}
          />
          <Checkbox
            {...form.getInputProps("copy_reg_page")}
            label={t("spaces.form.duplicate.copyRegPage.label")}
          />
          <Button radius="xl" size="md" mt={10} type="submit">
          {t("spaces.form.duplicate.save")}
          </Button>
        </Stack>
      </form>
    </ScrollArea>
  );
}
