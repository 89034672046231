import axios from "axios";

class Search {
  constructor() {
    if (this instanceof Search) {
      throw Error("Search class cannot be instantiated.");
    }
  }

  static onSearch = async (query, scope, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      query,
      top_slug: window.$fetchedTopSlug,
      current_slug: window.$currentSlug,
      scope
    };

    await axios
      .get(`/api/v4/search.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };
}

export default Search;
