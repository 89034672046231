import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  Button,
  ScrollArea,
  Container,
  Box,
  Text,
  Title,
  Group,
  createStyles,
  Grid,
  Image,
  BackgroundImage,
  UnstyledButton,
} from "@mantine/core";
import SpaceModel from "../../models/Space";

import NewSpace from "../spaces_neo/New";
import trackMixpanel from "../../utils/TrackMixpanel";

import { setHiddenSidebar } from "../../store/app";

import WelcomeVideo from "../../src/images/onboarding/welcome_video_lorenz.jpg";
import GettingStartedGuide from "../../src/images/onboarding/welcome_video.jpg";
import AdvancedFeatures from "../../src/images/onboarding/advanced_features.jpg";
import Template01 from "../../src/images/onboarding/templates_01.jpg";
import Template02 from "../../src/images/onboarding/templates_02.jpg";
import Template03 from "../../src/images/onboarding/templates_03.jpg";
import Template04 from "../../src/images/onboarding/templates_04.jpg";
import Template05 from "../../src/images/onboarding/templates_05.jpg";
import Template06 from "../../src/images/onboarding/templates_06.jpg";

import VideoEmbed from "../utils/videoEmbed";

import { useTranslation } from "react-i18next";

import { setActions, setTiptapSidemenu } from "../../store/app";

import { Helmet } from "react-helmet";

const useStyles = createStyles((theme, _params, getRef) => ({
  control: {
    display: "block",
    width: "100%",
    borderRadius: `${theme.radius.md}px`,
    padding: `10px 15px`,

    "&:hover": {
      backgroundColor: theme.colors.gray[1],
      color: theme.black,
      textDecoration: "none",
    },
  },
  controlActive: {
    backgroundColor: theme.colors.gray[1],
  },
  linkText: {
    fontWeight: 500,
    color: theme.black,
    fontSize: 16,
    lineHeight: 1.4,
  },
  linkTextActive: {
    // fontWeight: 600,
  },
}));

export default function GettingStarted(props) {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const space = useSelector((state) => state.space);
  const navigate = useNavigate();
  const [videoOpened, setVideoOpened] = useState(false);
  const [gettingStartedOpened, setGettingStartedOpened] = useState(false);
  const [gettingStartedSection, setGettingStartedSection] = useState("setup");
  const [advancedOpened, setAdvancedOpened] = useState(false);
  const [advancedSection, setAdvancedSection] = useState("interactive");
  const [templatesOpened, setTemplatesOpened] = useState(false);
  const [templatesSection, setTemplatesSection] = useState("groupprogram");
  const [newSpaceOpened, setNewSpaceOpened] = useState(false);
  const duplicateFrom = useRef();
  const addTemplateIntervalId = useRef();
  const addTemplateIntervalCounter = useRef(0);

  const mainActions = useSelector((state) => state.mainActions);

  const dispatch = useDispatch();

  const gettingStartedItems = [
    {
      key: "setup",
      label: t("app.gettingStarted.items.setup"),
      service: "loom",
      embedId: "b90402f1c31040d594b28fc41285ff9a",
    },
    {
      key: "privacy",
      label: t("app.gettingStarted.items.privacy"),
      service: "loom",
      embedId: "8198aeae20404781983e65f4fd31f118",
    },
    {
      key: "registration",
      label: t("app.gettingStarted.items.registration"),
      service: "loom",
      embedId: "8509a8d2ef3c4d40a3ddfdbf1138ac2f",
    },
    {
      key: "invitations",
      label: t("app.gettingStarted.items.invitations"),
      service: "loom",
      embedId: "491b55bbbe004870a31dae686efd318d",
    },
    {
      key: "settings",
      label: t("app.gettingStarted.items.settings"),
      service: "loom",
      embedId: "65791663e0514cdb8208ef6bdb18ed7d",
    },
  ];

  const advancedItems = [
    {
      key: "interactive",
      label: t("app.gettingStarted.items.interactive"),
      service: "loom",
      embedId: "b50be22a6bc041958a4c520f86ed40d7",
    },
    {
      key: "interest",
      label: t("app.gettingStarted.items.interest"),
      service: "loom",
      embedId: "db49f279b82d46a3bfa40420a741f93c",
    },
    {
      key: "appearance",
      label: t("app.gettingStarted.items.appearance"),
      service: "loom",
      embedId: "040025cd10784cc9a0b029c8b03628d1",
    },
    {
      key: "grids",
      label: t("app.gettingStarted.items.grids"),
      service: "loom",
      embedId: "b2639a79e1614531b77fda4a752c979f",
    },
    {
      key: "broadcasts",
      label: t("app.gettingStarted.items.broadcasts"),
      service: "loom",
      embedId: "855c8be8b6fc4f95a69b13a4baf4460d",
    },
    {
      key: "tagging",
      label: t("app.gettingStarted.items.tagging"),
      service: "loom",
      embedId: "228c918d435e458ebc87c2f7821a44a3",
    },
    {
      key: "emojis",
      label: t("app.gettingStarted.items.emojis"),
      service: "loom",
      embedId: "3aaae52a77414f72865af2ef05e19827",
    },
  ];

  const templateItems = [
    {
      key: "groupprogram",
      label: t("app.gettingStarted.templates.groupprogram.label"),
      image: Template01,
      service: "loom",
      embedId: "f10e56a164794078bed2a84e853ddc65",
      description: t("app.gettingStarted.templates.groupprogram.description"),
      templateSlug: "l07q98",
    },
    {
      key: "zoomworkshop",
      label: t("app.gettingStarted.templates.zoomworkshop.label"),
      image: Template02,
      service: "loom",
      embedId: "8240f20f96404ec98851f71291b28f73",
      description: t("app.gettingStarted.templates.zoomworkshop.description"),
      templateSlug: "m3of52",
    },
    {
      key: "mastermind",
      label: t("app.gettingStarted.templates.mastermind.label"),
      image: Template03,
      service: "loom",
      embedId: "19d4fb887f10447096cde8bd57123939",
      description: t("app.gettingStarted.templates.mastermind.description"),
      templateSlug: "7x47t6",
    },
    {
      key: "selfpacedcourse",
      label: t("app.gettingStarted.templates.selfpacedcourse.label"),
      image: Template04,
      service: "loom",
      embedId: "730e755bc044474bb7cd0fe9351507ff",
      description: t(
        "app.gettingStarted.templates.selfpacedcourse.description"
      ),
      templateSlug: "uy6i4e",
    },
    {
      key: "communityspace",
      label: t("app.gettingStarted.templates.communityspace.label"),
      image: Template05,
      service: "loom",
      embedId: "c3b7446ade5e4ca69def0bb324eebf88",
      description: t("app.gettingStarted.templates.communityspace.description"),
      templateSlug: "wi0wls",
    },
    {
      key: "oneononecoaching",
      label: t("app.gettingStarted.templates.oneononecoaching.label"),
      image: Template06,
      service: "loom",
      embedId: "afbfffa7ed3f483e88ddec044113c800",
      description: t(
        "app.gettingStarted.templates.oneononecoaching.description"
      ),
      templateSlug: "rsv4be",
    },
  ];

  useEffect(() => {
    if (space.slug){
      return
    }

    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: false }));

    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {

    if (space.slug){
      return
    }

    dispatch(setHiddenSidebar({ hidden: false }));
    // Triple checking that sidebar is displayed
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";

      dispatch(setHiddenSidebar({ hidden: false }));
    }, 1000);
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";

      dispatch(setHiddenSidebar({ hidden: false }));
    }, 2000);
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";

      dispatch(setHiddenSidebar({ hidden: false }));
    }, 3000);
  }, [user]);

  const selectTemplate = (key) => {
    setTemplatesOpened(true);
    setTemplatesSection(key);
  };

  const onDuplicate = (id, link) => {
    setNewSpaceOpened(false);
    duplicateFrom.current = null;
    navigate(link);
  };

  const onSelectThisTemplate = (slug) => {
    if (space.slug) {
      dispatch(setActions({ editPage: true }));
      navigate(`/space/${space.slug}/${space.version}`);

      dispatch(
        setTiptapSidemenu({
          opened: "loading",
          text: t("app.gettingStarted.loading.text"),
        })
      );

      addTemplateIntervalCounter.current = 0;

      addTemplateIntervalId.current = setInterval(() => {
        addTemplateIntervalCounter.current++;
        if (addTemplateIntervalCounter.current > 20) {
          clearInterval(addTemplateIntervalId.current);
        }

        const tiptapCont = document.getElementById("tiptap-main");
        if (tiptapCont) {
          if (tiptapCont.style.display == "block") {
            clearInterval(addTemplateIntervalId.current);
            setTimeout(() => {
              onAddTemplate(slug);
            }, 1000);
          }
        }
      }, 500);
    } else {
      setTemplatesOpened(false);
      duplicateFrom.current = slug;
      setNewSpaceOpened(true);
    }
  };

  const onAddTemplate = (slug) => {
    SpaceModel.onCopyTemplate(
      slug,
      space.slug,
      (data) => {},
      (data) => {
        // setLoadingModalOpened(false)
      }
    );
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{t("app.gettingStarted.tabTitle")}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }}>
        <Modal
          opened={videoOpened}
          onClose={() => setVideoOpened(false)}
          size="xl"
        >
          <VideoEmbed service="vimeo" embedId="999773158?h=8ffc72152a" />
        </Modal>

        <Modal
          opened={gettingStartedOpened}
          onClose={() => setGettingStartedOpened(false)}
          size="1000px"
          title={
            <Text ml={15} size="xl" weight={700}>
              {t("app.gettingStarted.mainTitle")}
            </Text>
          }
        >
          <Grid>
            <Grid.Col span={4}>
              {gettingStartedItems.map((item) => (
                <Box>
                  <UnstyledButton
                    key={item.key}
                    onClick={() => setGettingStartedSection(item.key)}
                    className={cx(classes.control, {
                      [classes.controlActive]:
                        item.key == gettingStartedSection,
                    })}
                  >
                    <Group position="apart" spacing={0} noWrap>
                      <Text
                        className={cx(classes.linkText, {
                          [classes.linkTextActive]:
                            item.key == gettingStartedSection,
                        })}
                      >
                        {item.label}
                      </Text>
                    </Group>
                  </UnstyledButton>
                </Box>
              ))}
            </Grid.Col>
            <Grid.Col span={8}>
              <VideoEmbed
                service={
                  gettingStartedItems.filter(
                    (item) => item.key === gettingStartedSection
                  )[0].service
                }
                embedId={
                  gettingStartedItems.filter(
                    (item) => item.key === gettingStartedSection
                  )[0].embedId
                }
              />
            </Grid.Col>
          </Grid>
        </Modal>

        <Modal
          opened={templatesOpened}
          onClose={() => setTemplatesOpened(false)}
          size="1000px"
          title={
            <Text size="xl" weight={700}>
              {
                templateItems.filter((item) => item.key === templatesSection)[0]
                  .label
              }
            </Text>
          }
        >
          <Grid>
            <Grid.Col span={8}>
              <VideoEmbed
                service={
                  templateItems.filter(
                    (item) => item.key === templatesSection
                  )[0].service
                }
                embedId={
                  templateItems.filter(
                    (item) => item.key === templatesSection
                  )[0].embedId
                }
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Text>
                {
                  templateItems.filter(
                    (item) => item.key === templatesSection
                  )[0].description
                }
              </Text>
              <Button
                mt={20}
                mr={10}
                color="dark"
                radius="xl"
                onClick={() => {
                  trackMixpanel(user.username, "Guide Template Selected");
                  onSelectThisTemplate(
                    templateItems.filter(
                      (item) => item.key === templatesSection
                    )[0].templateSlug
                  );
                }}
              >
                {t("app.gettingStarted.selectTemplate")}
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>

        <Modal
          opened={advancedOpened}
          onClose={() => setAdvancedOpened(false)}
          size="1000px"
          title={
            <Text ml={15} size="xl" weight={700}>
              {t("app.gettingStarted.advanceFeatures")}
            </Text>
          }
        >
          <Grid>
            <Grid.Col span={4}>
              {advancedItems.map((item) => (
                <Box>
                  <UnstyledButton
                    key={item.key}
                    onClick={() => setAdvancedSection(item.key)}
                    className={cx(classes.control, {
                      [classes.controlActive]: item.key == advancedSection,
                    })}
                  >
                    <Group position="apart" spacing={0} noWrap>
                      <Text
                        className={cx(classes.linkText, {
                          [classes.linkTextActive]: item.key == advancedSection,
                        })}
                      >
                        {item.label}
                      </Text>
                    </Group>
                  </UnstyledButton>
                </Box>
              ))}
            </Grid.Col>
            <Grid.Col span={8}>
              <VideoEmbed
                service={
                  advancedItems.filter(
                    (item) => item.key === advancedSection
                  )[0].service
                }
                embedId={
                  advancedItems.filter(
                    (item) => item.key === advancedSection
                  )[0].embedId
                }
              />
            </Grid.Col>
          </Grid>
        </Modal>

        <NewSpace
          opened={newSpaceOpened}
          setOpened={setNewSpaceOpened}
          duplicateFrom={duplicateFrom.current}
          onDuplicate={onDuplicate}
        />

        <Box
          mt={30}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          <Grid>
            <Grid.Col
              span={8}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box>
                <Title sx={(theme) => ({ fontWeight: 900 })} mb={10}>
                  {t("app.gettingStarted.welcome")} {user.first_name}!
                </Title>

                <Text mb={10}>{t("app.gettingStarted.description1")}</Text>
                <Button
                  mt={10}
                  mr={10}
                  variant="outline"
                  radius="xl"
                  onClick={() => {
                    trackMixpanel(user.username, "Welcome Video Watched");
                    setVideoOpened(true);
                  }}
                >
                  {t("app.gettingStarted.watchVideo")}
                </Button>
                {!space.slug && (
                  <Button
                    mt={10}
                    variant="outline"
                    radius="xl"
                    onClick={() => {
                      trackMixpanel(user.username, "Guide Space Created");
                      setNewSpaceOpened(true);
                    }}
                  >
                    {t("app.gettingStarted.createFirstSpace")}
                  </Button>
                )}
              </Box>
            </Grid.Col>
            <Grid.Col span={4}>
              <Image
                onClick={() => {
                  trackMixpanel(user.username, "Welcome Video Watched");
                  setVideoOpened(true);
                }}
                radius="md"
                sx={{
                  cursor: "pointer",
                }}
                src={WelcomeVideo}
              />
            </Grid.Col>
          </Grid>
        </Box>

        <Box
          mt={30}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          <Grid>
            <Grid.Col
              span={8}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box>
                <Text mb={5} size="xl" weight="bold">
                  {t("app.gettingStarted.gettingStartedGuide")}
                </Text>
                <Text mb={20}>{t("app.gettingStarted.description2")}</Text>
                <Button
                  variant="outline"
                  radius="xl"
                  onClick={() => {
                    trackMixpanel(user.username, "Guide Opened");
                    setGettingStartedOpened(true);
                  }}
                >
                  {t("app.gettingStarted.checkOutTheGuide")}
                </Button>
              </Box>
            </Grid.Col>
            <Grid.Col span={4}>
              <Image
                onClick={() => {
                  trackMixpanel(user.username, "Guide Opened");
                  setGettingStartedOpened(true);
                }}
                radius="md"
                sx={{
                  cursor: "pointer",
                }}
                src={GettingStartedGuide}
              />
            </Grid.Col>
          </Grid>
        </Box>

        <Box
          mt={30}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          <Box>
            <Text mb={5} size="xl" weight="bold">
              {t("app.gettingStarted.startWithATemplate")}
            </Text>
            <Text mb={20}>{t("app.gettingStarted.description3")}</Text>
          </Box>
          <Grid>
            {templateItems.map((item) => (
              <Grid.Col span={4}>
                <BackgroundImage
                  onClick={() => selectTemplate(item.key)}
                  radius="md"
                  sx={{
                    height: 100,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  src={item.image}
                >
                  <Text p={10} weight="bold" color="white">
                    {item.label}
                  </Text>
                </BackgroundImage>
              </Grid.Col>
            ))}
          </Grid>
        </Box>

        <Box
          mt={30}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          <Grid>
            <Grid.Col
              span={8}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box>
                <Text mb={5} size="xl" weight="bold">
                  {t("app.gettingStarted.learnAbout")}
                </Text>
                <Text mb={20}>{t("app.gettingStarted.description4")}</Text>
                <Button
                  variant="outline"
                  radius="xl"
                  onClick={() => {
                    trackMixpanel(user.username, "Advanced Guide Opened");
                    setAdvancedOpened(true);
                  }}
                >
                  {t("app.gettingStarted.learnmore")}
                </Button>
              </Box>
            </Grid.Col>
            <Grid.Col span={4}>
              <Image
                onClick={() => {
                  trackMixpanel(user.username, "Advanced Guide Opened");
                  setAdvancedOpened(true);
                }}
                radius="md"
                sx={{
                  cursor: "pointer",
                }}
                src={AdvancedFeatures}
              />
            </Grid.Col>
          </Grid>
        </Box>
      </Container>
    </ScrollArea>
  );
}
