import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import AuthModel from "../../models/Auth";
import useAuthUser from "../../utils/AuthUser";
import { setUser, setAlert, setHiddenSidebar } from "../../store/app";
import { useTranslation } from "react-i18next";

import SpaceReference from "./SpaceReference";
import PlanReference from "../app/payment/PlanReference";

import { setCookie } from "../../utils/Cookies";

import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Box,
  Divider,
  Stepper,
  Grid,
  ScrollArea,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useMediaQuery, useFocusTrap } from "@mantine/hooks";

import { Helmet } from "react-helmet";

export default function SignIn(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const queryParams = useLocation().search;

  const { setAuthUser } = useAuthUser();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim())
          ? null
          : t("users.signIn.invalidEmailErrorMsg"),
    },
  });

  const focusTrapRef = useFocusTrap();

  const [spaceReference, setSpaceReference] = useState();
  const [showSpaceReference, setShowSpaceReference] = useState(true);
  const [planReference, setPlanReference] = useState();
  const [showStepper, setStepper] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [providers, setProviders] = useState([]);

  const [dialog, setDialog] = useState({
    opened: false,
    title: "",
    message: "",
    color: "indigo",
  });

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 500);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    const spaceReference = new URLSearchParams(queryParams).get("s");
    const planReference = new URLSearchParams(queryParams).get("p");
    setIsMember(new URLSearchParams(queryParams).get("m") == "true");
    let email = decodeURIComponent(
      new URLSearchParams(queryParams).get("email")
    );
    email = email === null || email === "null" ? "" : email.replace(/ /g, "+");
    form.setFieldValue("email", email);
    setSpaceReference(spaceReference);
    setPlanReference(planReference);
  }, [queryParams]);

  const onSignIn = () => {
    const email = form.values.email.trim();
    AuthModel.onSignIn(
      email,
      form.values.password,
      { slug: spaceReference, plan: planReference },
      (data) => {
        // Success callback
        // dispatch(setAlert({opened: true, title: data.title, message: data.message, color: "green"}))

        data.user.redirect_to = data.redirect_to;

        setAuthUser(data.user);
        dispatch(setUser(data.user));
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const getMagicLinkPath = () => {
    if (form.values.email) {
      if (spaceReference) {
        return `/user/magic_link?email=${form.values.email}&s=${spaceReference}`;
      } else if (planReference) {
        return `/user/magic_link?email=${form.values.email}&p=${planReference}`;
      } else {
        return `/user/magic_link?email=${form.values.email}`;
      }
    } else {
      if (spaceReference) {
        return `/user/magic_link?s=${spaceReference.slug}`;
      } else if (planReference) {
        return `/user/magic_link?p=${planReference}`;
      } else {
        return `/user/magic_link`;
      }
    }
  };

  const onProviderSignIn = (link, redirect_to) => {
    setCookie("sso_redirect_to", redirect_to, 365 * 10);
    window.location.href= link;
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{`Sutra - Sign in`}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        {!smallScreen && spaceReference && showStepper ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={0} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : (
          ""
        )}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <form
                onSubmit={form.onSubmit((values) => onSignIn(values))}
                ref={focusTrapRef}
              >
                <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                  <Box pt={20} pb={5} pl={35} pr={35}>
                    <Title
                      sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                      order={1}
                    >
                      {t("users.signIn.title")}
                    </Title>
                    <Text color="dimmed" size="md">
                      {t("users.signIn.subtitle")}{" "}
                      <Link to={"/user/sign_up" + queryParams} size="sm">
                        {t("users.signIn.subtitleLink")}
                      </Link>
                    </Text>
                  </Box>
                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                  <Box pt={10} pb={25} pl={35} pr={35}>
                    <TextInput
                      {...form.getInputProps("email")}
                      label={t("users.signIn.email")}
                      radius="sm"
                      size="md"
                      mb={15}
                      data-autofocus
                    />
                    <PasswordInput
                      {...form.getInputProps("password")}
                      label={t("users.signIn.password")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      type="submit"
                    >
                      {t("users.signIn.submit")}
                    </Button>
                    <Divider
                      sx={(theme) => ({
                        borderTopColor: theme.colors.gray[2],
                        color: theme.colors.gray[2],
                      })}
                      my="sm"
                      label="OR"
                      labelPosition="center"
                      labelProps={{ color: "#666" }}
                    />
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      color="dark"
                      component={Link}
                      to={getMagicLinkPath()}
                    >
                      {t("users.signIn.magicLink")}
                    </Button>
                    {providers.map((provider) => {
                      return (
                        <Button
                          fullWidth
                          mt="xl"
                          radius="xl"
                          size="md"
                          mb={15}
                          color="dark"
                          sx={(theme) => ({
                            backgroundColor: provider.button_color,
                            color: provider.button_text_color,
                            "&:hover": {
                              backgroundColor: provider.button_color,
                              color: provider.button_text_color,
                            },
                          })}
                          onClick={() => onProviderSignIn(provider.link, provider.redirect_to)}
                        >
                          {`Sign in with ${provider.button_label}`}
                        </Button>
                      );
                    })}
                    <Text size="md" align="center" mt={15}>
                      <Link to="/user/recover">
                        {t("users.signIn.forgotPassword")}
                      </Link>
                    </Text>
                  </Box>
                </Paper>
              </form>
            </Container>
          </Grid.Col>
          {spaceReference && showSpaceReference && spaceReference != "zoom" && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                hideCoupon
                isMember={isMember}
                slug={spaceReference}
                setSpaceReference={setSpaceReference}
                setShowSpaceReference={setShowSpaceReference}
                setStepper={setStepper}
                setProviders={setProviders}
              />
            </Grid.Col>
          )}
          {planReference && (
            <Grid.Col xs={12} sm={6}>
              <PlanReference plan={planReference} />
            </Grid.Col>
          )}
        </Grid>
        <Box sx={{height: "100px"}}></Box>
      </Container>
    </ScrollArea>
  );
}
