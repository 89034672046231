import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Drawer } from "@mantine/core";

import Form from "../../resources/Form";

import { setTiptapSidemenu } from "../../../store/app";

import { addDummyCursor, removeDummyCursor } from "../utils/dummyCursor";
import tinycolor from "tinycolor2";

const NewResource = (props) => {
  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);

  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "newResource") {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "newResource") {
        tiptap.editor.commands.focus();
        window.$closingSideMenu = true;
        dispatch(setTiptapSidemenu({ opened: null }));
        removeDummyCursor();
      }
    } else {
      addDummyCursor();
    }
  }, [opened]);

  const onSave = (resourceId, resourceLink = "") => {
    window.$reloadResourceId = resourceId 
    if (tiptapSidemenu.option && tiptapSidemenu.option.resource) {
      
      tiptap.editor.chain().focus().updateAttributes("id", resourceId).blur().run();
    } else {
      
      tiptap.editor
        .chain()
        .focus()
        // .enter()
        .insertContent(
          `<resource id="${resourceId}" circle_id="${space.slug}"></resource>`
        ).run();
    }

    setTimeout(() => {
      setOpened(false);
    }, 250);
  };

  return (
    <Drawer
      size={700}
      position="left"
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        tiptapSidemenu.option && tiptapSidemenu.option.resource
          ? `Edit collapsable section`
          : `New collapsable section`
      }
      padding="xl"
      overlayOpacity={0.1}
      zIndex={99999}
      styles={{
        drawer: {
          backgroundColor: colors.background_color,
          color: colors.default_text_color,
        },
        closeButton: {
          backgroundColor: colors.background_color,
          color: colors.default_text_color,
          "&:hover": {
            backgroundColor: tinycolor(colors.background_color)
              .lighten(10)
              .toString(),
          },
        },
      }}
    >
      <Form
        parentId={space.slug}
        resource={
          tiptapSidemenu.option && tiptapSidemenu.option.resource
            ? tiptapSidemenu.option.resource
            : null
        }
        onSave={onSave}
      />
    </Drawer>
  );
};

export default NewResource;
