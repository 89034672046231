import React, { useState, useEffect } from "react";
import { Text, Group, Box, Select } from "@mantine/core";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import TagButton from "./TagButton";

import TagModel from "../../../../models/Tag";

export default function Tag(props) {
  const [tags, setTags] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const { t } = useTranslation();
  const space = useSelector((state) => state.space);

  useEffect(() => {
    const tagsNames = props.tags.map((i) => i["name"]);
    setSelectedTags(tagsNames);
    onFetch();
  }, [props]);

  const onFetch = () => {
    TagModel.onFetch(
      props.spaceId,
      (data) => {
        setTags([...data]);
      },
      (data) => {}
    );
  };

  const onAdd = (tag) => {
    if (selectedTags.indexOf(tag) === -1) {
      setSelectedTags(selectedTags.concat(tag));
    }
    setSelectValue("");
  };

  const onRemove = (tag) => {
    const index = selectedTags.indexOf(tag);
    if (index >= 0) {
      const newSelectedTags = [...selectedTags];
      newSelectedTags.splice(index, 1);
      setSelectedTags(newSelectedTags);
      props.onUpdatedMessage("delete");
    }
  };

  const getIdForTagName = (tag) => {
    for (let i = 0; i < props.tags.length; i++) {
      if (props.tags[i].name == tag) {
        return props.tags[i].id;
      }
    }
    return null;
  };

  const getColorForTagName = (tag) => {
    for (let i = 0; i < props.tags.length; i++) {
      if (props.tags[i].name == tag) {
        return props.tags[i].color;
      }
    }
    return null;
  };

  const Item = ({ label, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  );

  return (
    <Box>
      {!space.settings.allow_members_to_create_tags && tags.length == 0 && window.$currentRole != "manager" && window.$currentRole != "admin"  ? (
        <Text sx={(theme) => ({
          // backgroundColor: theme.colors.gray[1],
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 15,
          paddingRight: 15,
        })} color={"dimmed"}>There are no tags avaiable</Text>
      ) : (
        <>
          <Box
            sx={(theme) => ({
              // backgroundColor: theme.colors.gray[1],
              padding: 10,
            })}
          >
            <Select
              zIndex={99999}
              searchable
              placeholder={t("spaces.presentations.tag.placeholder")}
              itemComponent={Item}
              creatable={space.settings.allow_members_to_create_tags || window.$currentRole == "manager" || window.$currentRole == "admin"}
              getCreateLabel={(query) =>
                `+ ${t("spaces.presentations.tag.createAction")} ${query}`
              }
              data={tags}
              maxDropdownHeight={400}
              onChange={(tag) => onAdd(tag)}
              value={selectValue}
            />
          </Box>
          <Group spacing={5} p={selectedTags.length > 0 ? 10 : 0}>
            {selectedTags.map((tag) => {
              return (
                <TagButton
                  type={props.type}
                  tagId={getIdForTagName(tag)}
                  messageId={props.messageId}
                  spaceId={props.spaceId}
                  onCreate={props.onUpdatedMessage}
                  onUpdate={props.onUpdatedMessage}
                  onDestroy={onRemove}
                  name={tag}
                  color={getColorForTagName(tag)}
                />
              );
            })}
          </Group>
        </>
      )}
    </Box>
  );
}
