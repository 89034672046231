// import axios from 'axios';

class Hocuspocus {
  constructor() {
    if (this instanceof Payment) {
      throw Error('Payment class cannot be instantiated.');
    }
  }

  static onLoad = async (documentName, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      documentName,
      event: "load"
    }

    await window.$api.post(`/api/v4/hocuspocus/event.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }

  static onPublish = async (documentName, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      documentName,
      event: "publish"
    }

    await window.$api.post(`/api/v4/hocuspocus/event.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }
}

export default Hocuspocus;
