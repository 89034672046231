import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import {stylesToString} from '../../../utils/StylesToString'

import Resource from '../components/Resource';

import {v4 as uuidv4} from 'uuid';

const ResourceNode = Node.create({
  name: 'resource',
  allowGapCursor: false,
  group: "block",
  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    }
  },

  inline() {
    return this.options.inline
  },

  group() {
    return this.options.inline ? 'inline' : 'block columnable'
  },
  draggable: false,
  addAttributes() {
    return {
      id: {
        default: null,
      },
      circle_id: {
        default: null,
      },
      viewAs: {
        default: "list",
      },
      idName: {
        default: "resource-node",
      },
      forceUpdate: {
        default: false
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "8px",
          "padding-bottom": "8px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: attributes => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        })
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'resource',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {

    return ['resource', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addNodeView() {
    return ReactNodeViewRenderer(Resource)
  },
})

export default ResourceNode;
