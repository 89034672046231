import React, { useState, useEffect, useContext, useRef } from "react";

import {
  Container,
  Text,
  Group,
  ScrollArea,
  SegmentedControl,
  MantineProvider,
  Box,
  SimpleGrid,
  Center,
  Paper,
  RingProgress,
  useMantineTheme,
} from "@mantine/core";

import { useLocation, useParams } from "react-router-dom";

import SectionHeader from "./layout/SectionHeader";
import ParticipationActivity from "./members/ParticipationActivity";
import MemberActivity from "./members/MemberActivity";
import ProgressActivity from "./members/ProgressActivity";
import ProgressActivityV2 from "./members/ProgressActivityV2";

import { ActionCableContext } from "../../../components/Index";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ParticipationModel from "../../../models/Participation";

import ComposeBroadcast from "./broadcast/ComposeBroadcast";

import PremiumModal from "../../app/PremiumModal";
import Premium from "../../app/Premium";

export default function Participation() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const theme = useMantineTheme();
  const [pageSection, setPageSection] = useState("participationactivity");
  const [counts, setCounts] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [channelData, setChannelData] = useState({ data: [] });
  const [opened, setOpened] = useState(false);
  const [openedPremium, setOpenedPremium] = useState(false);
  const [canSendSelective, setCanSendSelective] = useState([]);
  const [canSeeTab, setCanSeeTab] = useState([]);

  const participationChannel = useRef();
  const sendToUsers = useRef([]);
  const broadcastType = useRef("email");
  const cable = useContext(ActionCableContext);

  const viewport = useRef();

  useEffect(() => {
    cableConnect();
    return () => {
      if (participationChannel.current) {
        participationChannel.current.unsubscribe();
        participationChannel.current = null;
      }
    };
  }, []);

  useEffect(() => {
    checkSearchParams();
  }, [location]);

  useEffect(() => {
    localStorage.setItem("lastMetricsSection", pageSection);
    
  }, [pageSection]);

  useEffect(() => {
    setCanSendSelective(
      space &&
        space.permissions &&
        space.permissions.can_send_selective_broadcast
    );
    setCanSeeTab(
      space && space.permissions && space.permissions.can_see_participation_tab
    );

    onFetchCounts();
  }, [space]);

  const onSendBroadcast = (type, users, selected) => {
    if (!canSendSelective[0]) {
      setOpenedPremium([true, canSendSelective[1], canSendSelective[2]]);
      return;
    }

    sendToUsers.current = [];
    for (let i = 0; i < users.length; i++) {
      if (
        selected.indexOf(users[i].username) >= 0 &&
        window.$currentUserId != users[i].user_id
      ) {
        sendToUsers.current.push({ ...users[i] });
      }
    }
    broadcastType.current = type;
    setOpened(true);
  };

  const onBroadcastUpdate = () => {};

  const cableConnect = () => {
    if (!participationChannel.current) {
      participationChannel.current = cable.subscriptions.create(
        {
          channel: "ParticipationChannel",
          user_token: window.$currentUserAuthToken,
          token: window.$currentUserAuthToken,
        },
        {
          connected: () => {
            console.log("participationChannel connected ============>");
          },
          disconnected: () => {},
          received: (data) => {
            console.log("participationChannel ============>");
            console.log(data);
            console.log("participationChannel ============>");
            setChannelData(data);
          },
        }
      );
    }
  };

  const checkSearchParams = () => {
    const search = location.search;
    let section = new URLSearchParams(search).get("section");

    if (!section){
      section = localStorage.getItem("lastMetricsSection");
    }

    if (section) {
      setPageSection(section);
    }
  };

  const displayPageSection = () => {
    if (pageSection == "memberactivity") {
      return (
        <MemberActivity
          theme={theme}
          refresh={refresh}
          setRefresh={setRefresh}
          counts={counts}
          onSendBroadcast={onSendBroadcast}
        />
      );
    } else if (pageSection == "participationactivity") {
      return (
        <ParticipationActivity
          theme={theme}
          refresh={refresh}
          setRefresh={setRefresh}
          counts={counts}
          channelData={channelData}
          setChannelData={setChannelData}
          onSearchByDateRange={onSearchByDateRange}
          onSendBroadcast={onSendBroadcast}
        />
      );
    } else if (pageSection == "progress") {
      return (
        <ProgressActivityV2
          theme={theme}
          refresh={refresh}
          setRefresh={setRefresh}
          counts={counts}
          onSendBroadcast={onSendBroadcast}
        />
      );
    }
  };

  const onFetchCounts = () => {
    ParticipationModel.onFetchCounts(
      space.slug,
      (data) => {
        setCounts(data);
      },
      (data) => {}
    );
  };

  const onSearchByDateRange = (dates, term, page) => {
    ParticipationModel.onSearchByDateRange(
      space.slug,
      { dates, term, page },
      (data) => {
        if (data.wait) {
        }
      },
      (data) => {}
    );
  };

  const getStats = () => {
    return [
      {
        label: t("spaces.settings.participation.label1"),
        description: t("spaces.settings.participation.description1"),
        count: counts.presence,
        stats: `${counts.presence}`,
        color: counts.presence < 40 ? "red" : "green",
        type: "percent",
      },
      {
        label: t("spaces.settings.participation.label2"),
        description: t("spaces.settings.participation.description2"),
        count: counts.activity,
        stats: `${counts.activity}`,
        color: counts.activity < 40 ? "red" : "green",
        type: "percent",
      },
      {
        label: t("spaces.settings.participation.label3"),
        description: t("spaces.settings.participation.description3"),
        count: counts.posts,
        stats: `${counts.posts}`,
        color: "red",
        type: "number",
      },
    ];
  };

  const stats = getStats().map((stat) => {
    return (
      <Paper withBorder radius="md" p="xs" key={stat.label}>
        <Group noWrap>
          {stat.type == "percent" ? (
            <RingProgress
              size={80}
              roundCaps
              thickness={8}
              sections={[{ value: stat.count, color: stat.color }]}
              label={
                <Center>
                  <Text weight={700} size="xl">
                    {stat.stats}
                  </Text>
                </Center>
              }
            />
          ) : (
            <Box
              sx={{
                paddingTop: 30,
                paddingBottom: 30,
                width: 100,
              }}
            >
              <Center>
                <Text weight={700} size="xl">
                  {stat.stats}
                </Text>
              </Center>
            </Box>
          )}

          <div>
            <Text size="xs" transform="uppercase" weight={700}>
              {stat.label}
            </Text>
            <Text color="dimmed" size="xs" weight={300}>
              {stat.description}
            </Text>
          </div>
        </Group>
      </Paper>
    );
  });

  const onScrollPositionChange = (pos) => {
    // const div = viewport.current;
    // console.log(
    //   `${div.scrollTop} + ${div.clientHeight} (${
    //     div.scrollTop + div.clientHeight
    //   }) = ${div.scrollHeight}`
    // );
    // console.log(
    //   `${pos.y} + ${div.clientHeight} (${pos.y + div.clientHeight}) = ${
    //     div.scrollHeight - 50
    //   }`
    // );
    // if (pos.y + div.clientHeight >= div.scrollHeight - 50) {
    //   setRefresh(`more-${pageSection}`);
    // }
  };

  if (!canSeeTab[0]) {
    return <Premium opened={[true, canSeeTab[1], canSeeTab[2]]} />;
  }

  return (
    <ScrollArea
      onScrollPositionChange={onScrollPositionChange}
      viewportRef={viewport}
      style={{ height: "100%" }}
    >
      <Container
        style={{ marginBottom: "50px" }}
        pt={20}
        pb={40}
        pl={40}
        pr={40}
        sx={(theme) => ({
          // width: "960px",
          // maxWidth: "960px",
        })}
      >
        <SectionHeader
          spaceId={space.slug}
          spaceVersion={space.version}
          spaceName={space.name}
          title={t("spaces.settings.layout.navbarContent.participation")}
        />

        <SimpleGrid
          mb={20}
          cols={3}
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          {counts.presence != null && counts.presence >= 0 ? stats : null}
        </SimpleGrid>

        <SegmentedControl
          fullWidth
          value={pageSection}
          onChange={(value) => setPageSection(value)}
          data={[
            {
              label: t("spaces.settings.participation.label4"),
              value: "participationactivity",
            },
            {
              label: t("spaces.settings.participation.label5"),
              value: "memberactivity",
            },
            {
              label: t("spaces.settings.participation.label6"),
              value: "progress",
            },
          ]}
        />

        <MantineProvider theme={{ primaryShade: 6 }}>
          {displayPageSection()}
        </MantineProvider>
      </Container>
      <ComposeBroadcast
        opened={opened}
        setOpened={setOpened}
        onUpdate={onBroadcastUpdate}
        sendToUsers={sendToUsers.current}
        broadcastType={broadcastType.current}
        parentName={space.name}
        isTopLevel={space.is_top_level}
        isEmailReady={window.$fetchedTopSpace.is_email_ready}
      />
      <PremiumModal opened={openedPremium} setOpened={setOpenedPremium} />
    </ScrollArea>
  );
}
