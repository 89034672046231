import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setHiddenSidebar } from "../../../store/app";
import { useTranslation } from "react-i18next";

import SpaceReference from "../../users/SpaceReference";
import FixedHeader from "../FixedHeader";

import {
  TextInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Box,
  Divider,
  Grid,
  ScrollArea,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useMediaQuery } from "@mantine/hooks";

export default function Start(props) {
  const space = useSelector((state) => state.space);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim())
          ? null
          : t("users.start.invalidEmailErrorMsg"),
    },
  });

  const [spaceReference, setSpaceReference] = useState();
  const [showSpaceReference, setShowSpaceReference] = useState(true);

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 1000);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    if (space.slug){
      setSpaceReference(space.slug);
    }
  }, [space]);

  const onStart = () => {
    navigate(`/space/${space.slug}/demo/sign_up`);
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={[
            "dummy",
          ]}
        />

        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <form onSubmit={form.onSubmit((values) => onStart(values))}>
                <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                  <Box pt={20} pb={5} pl={35} pr={35}>
                    <Title
                      sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                      order={1}
                    >
                      {t("users.start.title")}
                    </Title>
                    <Text size="lg" color="dimmed">
                      {t("users.start.subtitle")}
                    </Text>
                  </Box>
                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                  <Box pt={10} pb={25} pl={35} pr={35}>
                    <TextInput
                      {...form.getInputProps("email")}
                      label={t("users.start.email")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      type="submit"
                    >
                      {t("users.start.submit")}
                    </Button>
                  </Box>
                </Paper>
              </form>
            </Container>
          </Grid.Col>
          {spaceReference && showSpaceReference && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                isMember={true}
                slug={spaceReference}
                setSpaceReference={setSpaceReference}
                setShowSpaceReference={setShowSpaceReference}
              />
            </Grid.Col>
          )}
        </Grid>
      </Container>
    </ScrollArea>
  );
}
