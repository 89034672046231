import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setAlert, setHiddenSidebar } from "../../../store/app";
import FixedHeader from "../FixedHeader";
import { useTranslation } from "react-i18next";

import {
  TextInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Box,
  Divider,
  ScrollArea,
} from "@mantine/core";

export default function Recover(props) {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    user: {
      email: "",
    },
  });

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 250);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  const onRecover = () => {
    dispatch(
      setAlert({
        opened: true,
        title: t("users.recover.alertTitle"),
        message: t("users.recover.alertMessage"),
        color: "green",
      })
    );
  };

  const onInputChange = (event, field) => {
    form.user[field] = event.target.value;
    setForm({ ...form });
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container style={{ marginBottom: "50px" }} size={500} my={40}>
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={["dummy"]}
        />
        <Paper withBorder shadow="md" p={0} mt={30} radius="md">
          <Box pt={20} pb={5} pl={35} pr={35}>
            <Title sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}>
              {t("users.recover.title")}
            </Title>
            <Text color="dimmed" size="md">
              {t("users.recover.subtitle")}
            </Text>
          </Box>
          <Divider
            sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
            my="sm"
          />
          <Box pt={10} pb={25} pl={35} pr={35}>
            <TextInput
              value={form.user.email}
              onChange={(event) => onInputChange(event, "email")}
              label={t("users.recover.email")}
              radius="sm"
              size="md"
              mb={15}
            />
            <Button
              fullWidth
              mt="xl"
              radius="xl"
              size="md"
              mb={15}
              onClick={(event) => onRecover()}
            >
              {t("users.recover.submit")}
            </Button>
            <Text color="dimmed" size="md" align="center">
              {t("users.recover.footer")}{" "}
              <Link to={`/space/${props.space.slug}/demo/sign_in`} size="sm">
                {t("users.recover.footerLink")}
              </Link>
            </Text>
          </Box>
        </Paper>
      </Container>
    </ScrollArea>
  );
}
