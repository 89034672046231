import axios from 'axios';
import { getCookie } from '../utils/Cookies';

class Stripe {
  constructor() {
    if (this instanceof Stripe) {
      throw Error('Stripe class cannot be instantiated.');
    }
  }

  static onCreate = async (slug, price, frequency, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      price,
      invitation_slug: getCookie("invitation_slug"),
      coupon_code: window.$selectedCouponCode,
      bundle_id: window.$selectedBundleId,
      frequency,
      affiliate: getCookie("affiliate_space")
    }

    await window.$api.post(`/api/v4/spaces/${slug}/stripe.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {

        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onCheckIntent = async (slug, payment_intent_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      payment_intent_id
    }

    await window.$api.post(`/api/v4/spaces/${slug}/stripe/check_intent.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {

        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onCancelSubscription = async (user_id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      user_id
    }

    await window.$api.post(`/api/v4/spaces/${slug}/stripe/cancel_subscription.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onFetchKeys = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.post(`/api/v4/spaces/${slug}/stripe/setup_intent.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onFetchPayments = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      by_user: true
    }

    

    await window.$api.get(`/api/v4/spaces/${slug}/payments.json`, {params})
    .then(res => {
 
      sucessCallback(res.data)
    })
    .catch((error) => {
    
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onAttachPaymentMethod = async (slug, pm_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      pm_id: pm_id
    }

    await window.$api.post(`/api/v4/spaces/${slug}/stripe/attach_payment_method.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onConnect = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.post(`/api/v4/spaces/${slug}/stripe/connect.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onDisconnect = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/stripe/disconnect.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onDisconnect2 = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/stripe/disconnect2.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onRefund = async (slug, payment_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      payment_id
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/stripe/refund.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onApplyCode = async (slug, coupon_code, price, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      coupon_code,
      price
    }

    await window.$api.post(`/api/v4/spaces/${slug}/stripe/apply_coupon.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }
}

export default Stripe;
