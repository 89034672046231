import React, { useState, useEffect, useRef } from "react";

import {
  Divider,
  Text,
  Table,
  Group,
  ScrollArea,
  Button,
  Pagination,
  Box,
  TextInput,
  Loader,
} from "@mantine/core";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Row from "./InvitedRow";

import InvitationModel from "../../../../models/Invitation";

const paginator = {
  page: 1,
  per: 30,
};

export default function Invited(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  // const theme = useMantineTheme();
  const theme = props.theme;
  const { height, width } = useViewportSize();

  const [invitations, setInvitations] = useState({
    total: 0,
    page: 1,
    pages: 1,
    data: [],
  });
  const [term, setTerm] = useState(null);

  const [loading, setLoading] = useState(false);
  const timeoutId = useRef();

  const largeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.lg}px)`
  );
  const extraLargeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );

  const tableWidth = () => {
    if (largeScreen) {
      return width - 300;
    } else if (extraLargeScreen) {
      return width - 500;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [space]);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [term]);

  useEffect(() => {
    if (props.refresh) {
      onFetch();
      props.setRefresh(false);
    }
  }, [props]);

  const onFetch = () => {
    setLoading(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      InvitationModel.onFetch(
        space.slug,
        paginator,
        { term },
        (data) => {
          console.log("invitations: ", data);
          setInvitations(data);
          setLoading(false);
        },
        (data) => {
          setLoading(false);
        }
      );
    }, 1000);
  };

  const onInvitationDestroy = (invitation) => {
    InvitationModel.onDestroy(
      invitation.id,
      space.slug,
      (data) => {
        const newInvitations = { ...invitations };
        for (var i = 0; i < newInvitations.data.length; i++) {
          if (newInvitations.data[i].id == invitation.id) {
            newInvitations.data.splice(i, 1);
            break;
          }
        }
        setInvitations(newInvitations);
      },
      (data) => {}
    );
  };

  const onKeyPress = (event) => {
    onKeyGeneral(event);
  };
  const onKeyDown = (event) => {
    onKeyGeneral(event);
  };

  const onKeyGeneral = (event) => {
    if (event.which == 13) {
      event.preventDefault();
      onSearch();
    }
  };

  const onSearch = () => {
    setTerm(document.getElementById("search-input").value);
  };

  const onClearSearch = () => {
    document.getElementById("search-input").value = "";
    setTerm("");
  };

  const changePage = (page) => {
    paginator.page = page;
    onFetch();
  };

  return (
    <div>
      <Group position="apart" mt={20}>
        <Group position="left" spacing="xs">
          <TextInput
            icon={<FontAwesomeIcon icon={solid("search")} />}
            placeholder={t(
              "spaces.settings.members.invited.invited.placeholder"
            )}
            id="search-input"
            radius="xl"
            onKeyPress={(event) => onKeyPress(event)}
            onKeyDown={(event) => onKeyDown(event)}
            rightSection={
              term ? (
                <Text
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => onClearSearch()}
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </Text>
              ) : (
                <Text>{"  "}</Text>
              )
            }
          />
          <Button
            variant="filled"
            radius="xl"
            onClick={(event) => onSearch()}
            sx={{
              backgroundColor: theme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: theme.colors.sutrablue[4],
              },
            }}
          >
            {t("spaces.settings.members.invited.invited.button")}
          </Button>
          {loading && <Loader size="xs" />}
        </Group>
      </Group>

      <ScrollArea mt={10} style={{ width: tableWidth() }}>
        {invitations.data.length == 0 ? (
          <Box
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              textAlign: "center",
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
              cursor: "pointer",

              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[5]
                    : theme.colors.gray[1],
              },
            })}
          >
            {t("spaces.settings.members.invited.invited.noRecordFound")}
          </Box>
        ) : (
          <Table verticalSpacing="xs" highlightOnHover>
            <thead>
              <tr>
                <th>{t("spaces.settings.members.invited.table.email")}</th>
                <th>
                  {t("spaces.settings.members.invited.table.invitationlink")}
                </th>
                <th>{t("spaces.settings.members.invited.table.amount")}</th>
                <th>{t("spaces.settings.members.invited.table.date")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invitations.data.map((invitation) => {
                return (
                  <Row
                    spaceId={space.slug}
                    invitation={invitation}
                    onDestroy={onInvitationDestroy}
                    isPaid={space.is_paid}
                  />
                );
              })}
            </tbody>
          </Table>
        )}
      </ScrollArea>
      <Divider sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: 20,
          justifyContent: "center",
        }}
      >
        <Pagination
          color="gray"
          page={paginator.page}
          onChange={changePage}
          total={invitations.pages}
        />
      </Box>
    </div>
  );
}
