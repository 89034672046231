import { Extension } from '@tiptap/core'

// https://tiptap.dev/guide/custom-extensions#attributes

const CustomUniqId = Extension.create({
  name: "uniqId",
  addOptions() {

    return {
      types: [],
    }
  },
  addGlobalAttributes() {

    return [
      {
        // Extend the following extensions
        types: [
          "heading",
          "paragraph",
          "grid1",
          "grid2",
          "grid3",
          "grid4",
          "column",
          "tasklist",
          "taskItem",
          "circle",
          "resource",
          "video",
          "audio",
          "image",
          "embed",
          "file",
          "detailsSummary",
          "details",
          "detailsContent",
          "bulletList",
          "orderedList",
          "listItem",
          "taskItem",
          "resource",
          "actionbutton",
          "customform",
          "survey",
          "interactiveInput",
          "interactiveButton",
          "interactiveWait",
          "fancyLink",
          "horizontalRule",
          "infobox",
          "tagsFeed"
        ],
        // … with those attributes
        attributes: {
          uid: {
            default: "",
          },
        },
      },
    ]
  },
})

export default CustomUniqId;
