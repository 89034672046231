import React, { useState, useEffect, useRef } from "react";

import {
  createStyles,
  Text,
  ActionIcon,
  Button,
  MantineProvider,
  Group,
  Box,
  Menu,
  Accordion,
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Trash } from "tabler-icons-react";

import Swal from "sweetalert2";
import { DateTime } from "luxon";

import useLangUser from "../../../../utils/LangUser";
import StepModel from "../../../../models/EngagementStep";
import TriggerModel from "../../../../models/EngagementTrigger";

import AddStep from "./AddStep";
import EditStep from "./EditStep";
import StepIcon from "./StepIcon";

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    padding: `15px 20px 15px 5px`,
    // paddingRight: theme.spacing.xs, // to offset drag handle
    backgroundColor: "#fff",
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: 700,
    width: 80,
  },

  settings: {
    color: theme.colors.gray[6],
    fontSize: 18,
    width: 30,

    "&:hover": {
      color: theme.colors.gray[7],
    },
  },

  dragHandle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: theme.colors.gray[6],
    paddingLeft: 10,
    paddingRight: 0,

    "&:hover": {
      color: theme.colors.gray[7],
    },
  },

  stepFooterPadding: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "spaceBetween",
  },

  stepFooterLeft: {
    borderRight: `1px solid ${theme.colors.gray[4]}`,
    height: 10,
    width: "100%",
  },

  stepFooterRight: {
    borderLeft: `1px solid ${theme.colors.gray[4]}`,
    height: 10,
    width: "100%",
  },

  stepFooterAddStep: {
    display: "flex",
    justifyContent: "center",
    fontSize: 16,
  },

  addStepHidden: {
    visibility: "hidden",
  },

  addStepHover: {
    "&:hover": {
      color: "#fff",
      border: `1px solid ${theme.colors.sutrablue[3]}`,
      backgroundColor: theme.colors.sutrablue[3],
    },
  },

  bottomConnector: {
    fontSize: 20,
    color: theme.colors.gray[4],
    marginTop: -15,
    textAlign: "center",
    height: 20,
  },

  addStepButtonWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
  },

  addStepButtonHover: {
    "&:hover": {
      backgroundColor: theme.colors.sutrablue[3],
      border: `1px solid ${theme.colors.sutrablue[3]}`,
      color: "#fff",
    },
  },
}));

export default function Step({
  trigger,
  onUpdateTriggers,
  functions,
  functionsType,
  setOpenAddStep,
  openAddStep
}) {
  const { classes, cx, theme } = useStyles();
  const { language, setLangUser } = useLangUser();
  const space = useSelector((state) => state.space);
  const { t, i18n } = useTranslation();
  // const [trigger, setTrigger] = useState([]);
  const [addStepOpened, setAddStepOpened] = useState(false);
  const [editStepOpened, setEditStepOpened] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [steps, handlers] = useListState([]);
  const [selectedStep, setSelectedStep] = useState("");
  const [stepPosition, setStepPosition] = useState(null);
  const [triggerEdit, setTriggerEdit] = useState(null);
  const mounted = useRef(false);

  // useEffect(() => {
  //   setTrigger({...props.trigger})
  // }, [props]);

  useEffect(() => {
    if (openAddStep == trigger.id){
      setAddStepOpened(true)
      setOpenAddStep(false)
    }
  }, [openAddStep]);

  useEffect(() => {
    onFetch();
  }, [trigger]);

  useEffect(() => {
    if (selectedStep) {
      setEditStepOpened(true);
    }
  }, [selectedStep]);

  useEffect(() => {
    if (!editStepOpened) {
      setSelectedStep("");
    }
  }, [editStepOpened]);

  useEffect(() => {
    if (!addStepOpened) {
      // setSelectedStep("");
    }
  }, [addStepOpened]);

  useEffect(() => {
    if (triggerEdit) {
      onFetch(triggerEdit);
    }
  }, [triggerEdit]);

  useEffect(() => {
    if (mounted.current) {
      onReorder();
    } else {
      mounted.current = true;
    }
  }, [steps]);

  const onDestroy = (event, step) => {
    event.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      html: "This step will be permanently deleted",
      showDenyButton: true,
      confirmButtonText: "Delete step",
      denyButtonText: "Cancel",
      confirmButtonColor: theme.colors.red[8],
      denyButtonColor: theme.colors.gray[6],
    }).then((result) => {
      if (result.isConfirmed) {
      } else if (result.isDenied) {
      }
    });
  };

  const onFetch = (stepId=null) => {
    
    if (!space.slug) {
      return;
    }
    StepModel.onFetch(
      space.slug,
      trigger.id,
      (data) => {
        handlers.setState(data);
        mounted.current = true;
        if (stepId) {
          const newStep = data.filter((step) => step.id === stepId)[0]
          setSelectedStep(newStep);
        }
        setTriggerEdit(null);
      },
      (data) => {}
    );
  };

  const onUpdate = () => {
    onFetch();
  };

  const displayName = (step) => {
    return step.name;
  };

  const AddStepBlock = (position) => {
    return (
      <div>
        <div className={cx({ [classes.addStepHidden]: isDragging })}>
          <div>
            <div className={classes.stepFooterPadding}>
              <div className={classes.stepFooterLeft}></div>
              <div className={classes.stepFooterRight}></div>
            </div>
            <div className={classes.stepFooterAddStep}>
              <MantineProvider theme={{ primaryShade: 5 }}>
                <ActionIcon
                  size="sm"
                  radius="xl"
                  variant="outline"
                  color="gray"
                  onClick={() => {
                    setSelectedStep("");
                    setAddStepOpened(true);
                    setStepPosition(position);
                  }}
                  classNames={{
                    outline: classes.addStepHover,
                  }}
                >
                  <FontAwesomeIcon icon={solid("plus")} />
                </ActionIcon>
              </MantineProvider>
            </div>
            <div className={classes.stepFooterPadding}>
              <div className={classes.stepFooterLeft}></div>
              <div className={classes.stepFooterRight}></div>
            </div>
            <div className={classes.bottomConnector}>
              <FontAwesomeIcon icon={solid("caret-down")} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onReorder = () => {
    if (steps.length == 0) {
      return;
    }

    const order = steps.map((step, index) => {
      return { id: step.id, position: index + 1 };
    });

    StepModel.onReorder(
      space.slug,
      trigger.id,
      order,
      (data) => {},
      (data) => {}
    );
  };

  const onDelete = () => {
    TriggerModel.onDestroy(
      trigger.id,
      space.slug,
      (data) => {
        onUpdateTriggers();
      },
      (data) => {}
    );
  };

  const items = steps.map((item, index) => (
    <Draggable
      key={item.id.toString()}
      index={index}
      draggableId={item.id.toString()}
    >
      {(provided, snapshot) => (
        <div>
          <div
            className={cx(classes.item, {
              [classes.itemDragging]: snapshot.isDragging,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <StepIcon icon={item.type} />
            {/*displayStepIcon(item.action)*/}
            <Group position="apart" sx={{ width: "100%" }} noWrap>
              <Box sx={{ width: "90%" }}>
                <Text weight={600}>{displayName(item)}</Text>
                <Text color="dimmed" size="sm">
                  {item.description}
                </Text>
              </Box>
              <Group spacing={0} noWrap>
                <ActionIcon
                  size="sm"
                  radius="xl"
                  onClick={() => {
                    setSelectedStep(item);
                  }}
                  classNames={{
                    hover: classes.settings,
                    outline: classes.settings,
                  }}
                >
                  <FontAwesomeIcon icon={solid("cog")} />
                </ActionIcon>
                <div
                  {...provided.dragHandleProps}
                  className={classes.dragHandle}
                >
                  <FontAwesomeIcon icon={solid("grip-vertical")} />
                </div>
              </Group>
            </Group>
          </div>
          {AddStepBlock(index + 1)}
        </div>
      )}
    </Draggable>
  ));

  return (
    <Box pt={20}>
      {/* {AddStepBlock(0)} */}
      <DragDropContext
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragEnd={({ destination, source }) => {
          handlers.reorder({ from: source.index, to: destination.index });
          setIsDragging(false);
        }}
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <MantineProvider theme={{ primaryShade: 5 }}>
        <div className={classes.addStepButtonWrap}>
          <Button
            onClick={() => {
              setSelectedStep("");
              setAddStepOpened(true);
              setStepPosition(null);
            }}
            classNames={{
              outline: classes.addStepButtonHover,
            }}
            variant="outline"
            color="gray"
            radius="xl"
          >
            {t(
              "spaces.settings.registration.registrationFlow.registration.addButton"
            )}
          </Button>
        </div>
      </MantineProvider>
      <Group position="right">
        <Menu>
          <Menu.Item
            color="red"
            icon={<Trash size={14} />}
            onClick={() => onDelete()}
          >
            Delete
          </Menu.Item>
        </Menu>
      </Group>
      <EditStep
        opened={editStepOpened}
        setOpened={setEditStepOpened}
        step={selectedStep}
        triggerId={trigger.id}
        settings={space}
        onUpdate={onUpdate}
        functions={functions}
        functionsType={functionsType}
        functionSlug={trigger.function_slug}
      />
      <AddStep
        opened={addStepOpened}
        setOpened={setAddStepOpened}
        position={stepPosition}
        settings={space}
        triggerId={trigger.id}
        onUpdate={onUpdate}
        selectedStep={selectedStep}
        setTriggerEdit={setTriggerEdit}
        functions={functions}
        functionsType={functionsType}
        functionSlug={trigger.function_slug}
      />
    </Box>
  );
}
