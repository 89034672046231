import React from 'react';
import {

  Box,
  TextInput,

  ActionIcon,
  Checkbox,
  createStyles
} from '@mantine/core'

import {  X } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next'


const useStyles = createStyles((theme) => ({
  dragHandle: {
    ...theme.fn.focusStyles(),
    height: '100%',
    color: theme.colors.gray[6],
    cursor: 'move',
    paddingTop: 3,
    width: 20,
    maxWidth: 20
  },
}));

let timerId = null;

export default function MultipleItem(props) {

  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <>
    <Box
      sx={{
        width: 30
      }} >
      <Checkbox
        readOnly={true}
        checked={false}
        styles={{
          root: {
          },
          input: {
            marginTop: -1,
          },
        }} />
      </Box>
      <TextInput
        value={props.content}
        onChange={(event) => props.onContentChange(event.currentTarget.value)}
        placeholder={t("spaces.settings.survey.multipleItem.multipleItem.placeholder")}
        radius="sm"
        size="md"
        sx={(theme) => ({
          width: "calc(100% - 50px)",
        })}
        rightSection={
          <ActionIcon
            variant="transparent"
            onClick={() => props.onDestroy()}>
            <X size={18} />
          </ActionIcon>
        }
        rightSectionWidth={40} />
      </>
  )
}
