import React, { useState, useEffect, useRef } from "react";
import TiptapNoHP from "../../../tiptap/components/TiptapNoHP";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  setHiddenSidebar,
  setHiddenHeader,
  setForceLayoutUpdate,
} from "../../../../store/app";
import SpaceModel from "../../../../models/Space";
import ViewModel from "../../../../models/View";

import FixedBottomMenu from "../../../tiptap/components/FixedBottomMenu";
import AlreadyMember from "../../AlreadyMember";
import InvitationBar from "../../InvitationBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Loading from "../static/Loading";

import { Stack, ScrollArea, createStyles } from "@mantine/core";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";

// import { IndexeddbPersistence } from 'y-indexeddb'

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.registration_page_background_color,
    color: colors.registration_page_default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color,
    },
    ".ProseMirror .file-node svg": {
      stroke: colors.default_text_color,
    },
    ".ProseMirror .file-node a": {
      color: colors.default_text_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.sidebar_text_color,
      backgroundColor: colors.sidebar_background_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.default_text_color,
      backgroundColor: isSpaceThemed(colors)
        ? isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(8).toString()
          : tinycolor(colors.background_color).darken(8).toString()
        : tinycolor(colors.background_color).darken(2).toString(),
      border: isSpaceThemed(colors)
        ? `1px solid ${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(20).toString()
              : tinycolor(colors.background_color).darken(20).toString()
          }`
        : `1px solid #e9ecef`,
      "&:hover": {
        // backgroundColor: theme.colors.gray[0],
        backgroundColor: isSpaceThemed(colors)
          ? isDark(colors.background_color)
            ? tinycolor(colors.background_color).lighten(10).toString()
            : tinycolor(colors.background_color).darken(10).toString()
          : tinycolor(colors.background_color).darken(2).toString(),
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        cursor: "pointer",
        // color: "#000",
        textDecoration: "none",
      },
    },
  },
}));

export default function RegistationPage(props) {
  const [invitation, setInvitation] = useState(null);

  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const forceLayoutUpdate = useSelector((state) => state.forceLayoutUpdate);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlesIntervalId = useRef();
  const layoutUpdateForced = useRef();

  const [loaded, setLoaded] = useState(false);

  const content = useRef({});

  const fetchTimeout = useRef({});
  const lastSlug = useRef();
  const width = useRef();

  // const lastEditState = useRef(false)

  const { classes, cx, theme } = useStyles({ colors });

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    // debugger;
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: true }));
    dispatch(setHiddenHeader({ hidden: true }));

    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
      dispatch(setHiddenHeader({ hidden: true }));
    }, 500);

    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
      dispatch(setHiddenHeader({ hidden: false }));
      dispatch(setForceLayoutUpdate({ update: false }));
      setLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (!space.slug) {
      return;
    }
    const header =
      space.permissions && space.permissions.can_manage ? "admin" : false;

    if (location.pathname.indexOf("/register") >= 0) {
      if (header) {
        dispatch(setHiddenSidebar({ hidden: true }));
        dispatch(setHiddenHeader({ hidden: true }));

        setTimeout(() => {
          dispatch(setHiddenSidebar({ hidden: true }));
          dispatch(setHiddenHeader({ hidden: true }));
        }, 500);
      }
    } else {
      if (header) {
        dispatch(setHiddenSidebar({ hidden: true }));
        dispatch(setHiddenHeader({ hidden: header }));

        setTimeout(() => {
          dispatch(setHiddenSidebar({ hidden: true }));
          dispatch(setHiddenHeader({ hidden: header }));
        }, 500);
      }
    }

    if (props.invitationSlug) {
      onFetchInvitation();
    }

    // Refactor for more elegant wway
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
    }, 500);
  }, [space]);

  useEffect(() => {
    if (params.slug && lastSlug.current != params.slug) {
      lastSlug.current = params.slug;
      setLoaded(false);
      onFetch();
    }
  }, [params]);

  useEffect(() => {
    if (!forceLayoutUpdate.update && !layoutUpdateForced.current) {
      layoutUpdateForced.current = true;
      dispatch(setForceLayoutUpdate({ update: true }));
    }
  }, [forceLayoutUpdate]);

  const onFetch = () => {
    ViewModel.onFetch(
      "registration_page",
      params.slug,
      (data) => {
        content.current = data.content;
        width.current = data.view_width;
        window.$registrationWideWidth = data.view_width;
        setLoaded(true);
      },
      (data) => {}
    );
  };

  const onFetchInvitation = () => {
    SpaceModel.onFetchSimple(
      params.slug,
      "payment",
      (data) => {
        setInvitation(data);
      },
      (data) => {}
    );
  };

  if (!loaded) {
    return <Loading type="content" />;
  }

  return (
    <>
      {space &&
        space.slug &&
        location &&
        location.pathname.indexOf("/register") >= 0 && (
          <>
            <AlreadyMember
              spaceId={space.slug}
              isMember={space.permissions.is_member}
            />
            {(invitation || space.permissions.is_full) && (
              <InvitationBar
                invitation={{ ...invitation }}
                spaceId={space.slug}
                isMember={space.permissions.is_member}
                isFull={space.permissions.is_full}
              />
            )}
          </>
        )}
      <Stack
        className={classes.spaceContainer}
        spacing={0}
        style={{ height: "100%" }}
      >
        <ScrollArea className="content-scrollview" style={{ height: "100%" }}>
          {loaded && (
            <TiptapNoHP
              key={`tiptap-${new Date()}`}
              spaceId={params.slug}
              content={content.current.default}
              spaceWidth={width.current}
            />
          )}
        </ScrollArea>
      </Stack>
    </>
  );
}
