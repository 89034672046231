import React, { useState, useEffect } from "react";

import { Text, Drawer, ScrollArea, useMantineTheme } from "@mantine/core";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import CreateSurveyForm from "../../spaces_neo/settings/surveys/CreateSurveyForm";

import { setTiptapSidemenu } from "../../../store/app";

import SurveyPerson from "./SurveyPerson";

export default function CreateSurvey(props) {
  const theme = useMantineTheme();

  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [survey, setSurvey] = useState();
  const [step, setStep] = useState(0);
  const colors = useSelector((state) => state.colors);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "createSurvey") {
      setOpened(true);
      if (tiptapSidemenu.option && tiptapSidemenu.option.survey) {
        setStep(1)
      }
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "createSurvey") {
        dispatch(setTiptapSidemenu({ opened: null, option: null }));
      }
      setStep(0)
    }
  }, [opened]);

  const onCreate = (surveyId) => {
    if (surveyId == "new_survey"){
      setStep(1)
      return
    }
    onUpdate(surveyId)
  };

  const onUpdate = (surveyId) => {
    
    window.$reloadSurveyId = surveyId
    if (tiptapSidemenu.option && tiptapSidemenu.option.survey) {
      tiptap.editor
        .chain()
        .focus()
        .updateAttributes("id", surveyId)
        .blur()
        .run();
    } else {
      tiptap.editor
        .chain()
        .focus()
        // .enter()
        .insertContent(
          `<survey id="${surveyId}" circle_id="${space.slug}" class="tiptap-size-100" radius="circle-radius" buttonText="Take survey"></survey>`
        )
        .run();
    }

    setTimeout(() => {
      setOpened(false);
    }, 250);
  };

  return (
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {t("spaces.settings.survey.createSurvey.description")}
        </Text>
      }
      padding="xl"
      size={600}
      overlayOpacity={0.1}
      // zIndex={99999}
    >
      <ScrollArea style={{ height: "100%" }}>
        {step == 0 ? (
          <SurveyPerson onCreate={onCreate} mode="add" />
        ) : (
          <CreateSurveyForm
            opened={opened}
            setOpened={setOpened}
            survey={tiptapSidemenu.option && tiptapSidemenu.option.survey ? tiptapSidemenu.option.survey : null}
            spaceId={space.id}
            onUpdate={onUpdate}
            onAdd={onUpdate}
            acceptPayments={false}
          />
        )}
      </ScrollArea>
    </Drawer>
  );
}
