import React, { useState, useEffect } from "react";

import { Text, Drawer, ScrollArea, useMantineTheme } from "@mantine/core";

import { useTranslation } from "react-i18next";

import Broadcaster from "../../../../helpers/Broadcaster";

// import jquery from "jquery";

export default function CreateStructure(props) {
  const [html, setHtml] = useState("");

  const { t, i18n } = useTranslation();
  const theme = useMantineTheme();

  useEffect(() => {
    Broadcaster.receive("settings_closeModal", document.body, () => {
      props.setOpened(false);
    });

    return () => {
      Broadcaster.unRegisterElement("settings_closeModal", document.body);
    };
  }, []);

  // const onCloseModal = useCallback((event, data) => {
  //   debugger;
  //   props.setOpened(false)
  // })

  useEffect(() => {
    // SettingsModel.onFetch(`/api/v4/space/1nzywu/structures/new`, "get", (data) => {
    if (props.spaceId) {
      // SettingsModel.onFetch(`/circle/${props.spaceId}/structures/new`, "get", (data) => {
      //   setHtml(data)
      // })
    }
  }, [props]);

  useEffect(() => {
    // debugger;
    // if ($){
    // jquery.ajax({
    //   url: `/circle/${props.spaceId}/structures/new?scripts=true&auth_token=${window.$currentUserAuthToken}&from_site_api=true`,
    //   type: "GET"
    // });
    // }
  }, [html]);

  return (
    <Drawer
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {t("spaces.settings.structure.createStructure.title.createStructure")}
        </Text>
      }
      padding="xl"
      size={600}
    >
      <ScrollArea style={{ height: "100%" }}>
        {props.structure && props.structure.id ? (
          <iframe
            src={`/structures/${props.structure.id}/edit?auth_token=${window.$currentUserAuthToken}&pod_slug=${props.spaceId}`}
            style={{
              height: "calc(100vh - 80px)",
              border: "none",
              width: "100%",
            }}
          />
        ) : (
          <iframe
            src={`/circle/${props.spaceId}/structures/new?auth_token=${window.$currentUserAuthToken}`}
            style={{
              height: "calc(100vh - 80px)",
              border: "none",
              width: "100%",
            }}
          />
        )}
      </ScrollArea>
    </Drawer>
  );
}
