import axios from 'axios';

class Stripe {
  constructor() {
    if (this instanceof Stripe) {
      throw Error('Stripe class cannot be instantiated.');
    }
  }

  static onSubscriptionIntent = async (sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.post(`/api/v4/sutra_stripe/subscription_setup_intent.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {

        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onCreate = async (plan_id, payment_method_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      plan_id,
      payment_method_id
    }

    await window.$api.post(`/api/v4/sutra_stripe.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {

        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onApplyCode = async (coupon_code, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      coupon_code
    }

    await window.$api.post(`/api/v4/sutra_stripe/apply_coupon.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {

        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onCheckIntent = async (slug, payment_intent_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      payment_intent_id
    }

    await window.$api.post(`/api/v4/sutra_stripe/check_intent.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {

        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onCancelSubscription = async (user_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      user_id
    }

    await window.$api.post(`/api/v4/sutra_stripe/cancel_subscription.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onFetchKeys = async (sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.post(`/api/v4/sutra_stripe/setup_intent.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {

        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onAttachPaymentMethod = async (pm_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      pm_id: pm_id
    }

    await window.$api.post(`/api/v4/sutra_stripe/attach_payment_method.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onFetchPayments = async (sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.get(`/api/v4/account_payments.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onConnect = async (sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.post(`/api/v4/sutra_stripe/connect.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onDisconnect = async (sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/sutra_stripe/disconnect.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }

  static onRefund = async (slug, payment_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      payment_id
    }

    await window.$api.delete(`/api/v4/sutra_stripe/refund.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        try {
          errorCallback(error.response.data)

        } catch (e) {

        }
      }
    });
  }
}

export default Stripe;
