import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Title,
  Group,
  Menu,
  ActionIcon,
  Tooltip,
  createStyles,
} from "@mantine/core";

import { useDispatch, useSelector } from "react-redux";
import SpaceModel from "../../models/Space";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";
import defaultSpaceColors from "../../collections/defaultSpaceColors";
import isDark from "../../utils/IsDarkColor";

import { useTranslation } from "react-i18next";

import { CopyToClipboard } from "react-copy-to-clipboard";

let timeoutId = null;

const useStyles = createStyles(
  (theme, { colors, isDark, listFlatView }, getRef) => ({
    datePosted: {
      color: tinycolor(colors.default_text_color).lighten(10).toString(),
    },
    abstract: {
      textDecoration: "none",
      // color: "#000",
      color: isSpaceThemed(colors) ? colors.default_text_color : "#000",
      margin: 0,

      "&:hover": {
        textDecoration: "none",
      },
    },
    buttonStyle: {
      // borderRadius: theme.radius.md,
      color: tinycolor(colors.default_text_color).lighten(50).toString(),
      // backgroundColor: "red",
      // border: '1px solid red',
      // paddingLeft: 12,
      // paddingRight: 12,

      "&:hover": {
        backgroundColor: !isSpaceThemed(colors)
          ? theme.colors.gray[1]
          : tinycolor(colors.background_color).lighten(3).toString(),
      },
    },
  })
);

export default function HeaderTitle(props) {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const space = useSelector((state) => state.space);
  const space2 = useSelector((state) => state.space2);

  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  const colors = useSelector((state) => state.colors);
  const colors2 = useSelector((state) => state.colors2);
  const tiptap = useSelector((state) => state.tiptap);

  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const { classes, cx, theme } = useStyles({
    colors: getColors(),
    isDark: isDark,
    listFlatView: props.listFlatView,
  });

  const [title, setTitle] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carretPos = useRef(null);
  const titleInput = useRef(null);

  useEffect(() => {
    return () => {
      carretPos.current = null;
    };
  }, []);

  useEffect(() => {
    if (window.$updateTitle && window.$updateTitle.slug == getSpace().slug) {
      setTitle(window.$updateTitle.title);
    } else {
      setTitle(getSpace().name);
    }
    if (carretPos.current) {
      titleInput.current.selectionStart = carretPos.current;
      titleInput.current.selectionEnd = carretPos.current;
    }
  }, [space, space2]);

  const getSpace = () => {
    if (props.useSpace2) {
      return space2;
    } else {
      return space;
    }
  };

  const onClick = (event) => {
    // To avoid focusing editor
    event.stopPropagation();
  };

  const onKeyPress = (event) => {
    onKeyGeneral(event);
  };
  const onKeyDown = (event) => {
    onKeyGeneral(event);
  };

  const onKeyGeneral = (event) => {
    const titleValue = event.target.innerText;

    if (titleValue.length >= 100 && event.which != 8) {
      return false;
    }
    if (event.which == 13 || event.which == 40) {
      event.preventDefault();
      if (tiptap.editor) {
        tiptap.editor
          .chain()
          .focus()
          .setTextSelection(0)
          .insertContentAt(0, `<p></p>`)
          .setTextSelection(0)
          .run();
      }
    }
  };

  const onPaste = (event) => {
    event.preventDefault();
    const input = event.target;
    const currentTitle = input.innerText + "";
    input.innerText = "";

    const pastedTitle = event.clipboardData.getData("text");

    input.innerText = `${currentTitle}${pastedTitle}`;

    placeCaretAtEnd(input);
  };

  const placeCaretAtEnd = (el) => {
    el.focus();
    if (
      typeof window.getSelection != "undefined" &&
      typeof document.createRange != "undefined"
    ) {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  };

  const onUpdate = (event) => {
    carretPos.current = event.target.selectionStart;
    console.log("CARET: " + event.target.selectionStart);
    const titleValue = event.target.innerText;
    window.$updateTitle = { title: titleValue, slug: getSpace().slug };
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      SpaceModel.onUpdate(
        getSpace().slug,
        { intention: titleValue },
        (data) => {},
        (data) => {}
      );
    }, 500);
  };

  const getDirectMessageName = () => {
    const otherUserName = getSpace()
      .name.replace(`${user.name}`, ``)
      .replace(`and`, ``)
      .trim();
    return `Conversation with ${otherUserName}`;
  };

  const onEdit = () => {
    navigate(`${getLink()}?edit=true`);
  };

  const onOpen = () => {
    navigate(getLink());
  };

  const getLink = () => {
    return `/space/${getSpace().slug}/${getSpace().version}`;
  };

  const onCopyLink = () => {
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  return (
    <Box>
      {props.useSpace2 ? (
        <Group
          position="apart"
          noWrap
          sx={{
            alignItems: "flex-start",
            display: "flex",
          }}
        >
          <Title
            ref={titleInput}
            className="space-title"
            contentEditable={props.editable}
            sx={(theme) => ({
              fontSize: 38,
              fontWeight: 800,
              color: getColors().default_text_color,
            })}
            order={1}
            onInput={(event) => onUpdate(event)}
            onClick={(event) => onClick(event)}
            onKeyPress={(event) => onKeyPress(event)}
            onKeyDown={(event) => onKeyDown(event)}
            onPaste={(event) => onPaste(event)}
          >
            {getSpace().is_direct ? getDirectMessageName() : title}
          </Title>
          <Group spacing={0} noWrap mt={10}>
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              label={t("spaces.header.buttons.openFullScreen")}
              withArrow
            >
              <ActionIcon
                component={Link}
                to={getLink()}
                classNames={{ root: classes.buttonStyle }}
              >
                <FontAwesomeIcon icon={solid("arrow-up-right-from-square")} />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              label={t("spaces.header.buttons.copiedMessage")}
              opened={copiedToClipboard}
              position="bottom"
              withArrow
            >
              <Menu
                size={110}
                onClick={(event) => event.stopPropagation()}
                withArrow
                placement="end"
                control={
                  <ActionIcon
                    onClick={(event) => event.stopPropagation()}
                    classNames={{ root: classes.buttonStyle }}
                  >
                    <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
                  </ActionIcon>
                }
              >
                {getSpace().permissions.can_edit && (
                  <Menu.Item onClick={() => onEdit()}>
                    {t("spaces.header.buttons.edit")}{" "}
                    {`${
                      getSpace().is_post
                        ? t("spaces.header.buttons.post")
                        : t("spaces.header.buttons.page")
                    }`}
                  </Menu.Item>
                )}
                <Menu.Item>
                  <CopyToClipboard
                    onCopy={() => onCopyLink()}
                    text={`https://sutra.co/space/${getSpace().slug}/${
                      getSpace().version
                    }`}
                  >
                    <span>{t("spaces.header.buttons.copyToClipboard")}</span>
                  </CopyToClipboard>
                </Menu.Item>
              </Menu>
            </Tooltip>
          </Group>
        </Group>
      ) : (
        <Title
          className="space-title"
          contentEditable={props.editable}
          sx={(theme) => ({
            fontSize: 38,
            fontWeight: 800,
            color: getColors().default_text_color,
          })}
          order={1}
          onInput={(event) => onUpdate(event)}
          onClick={(event) => onClick(event)}
          onKeyPress={(event) => onKeyPress(event)}
          onKeyDown={(event) => onKeyDown(event)}
          onPaste={(event) => onPaste(event)}
        >
          {getSpace().is_direct ? getDirectMessageName() : title}
        </Title>
      )}
    </Box>
  );
}
