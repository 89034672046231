import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setHiddenSidebar } from "../../../../store/app";

import BroadcastModel from "../../../../models/Broadcast";

import { useTranslation } from "react-i18next";

import {
  Container,
  Stack,
  ScrollArea,
  Box,
  Text,
  Button,
  Center,
  Title,
  createStyles,
} from "@mantine/core";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
  linkStyle: {
    color: "#000",
  },
}));

export default function BroadcastExclude(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const params = useParams();

  const [excluded, setExcluded] = useState(false);
  const [email, setEmail] = useState(false);

  const colors = useSelector((state) => state.colors);

  const { classes, cx, theme } = useStyles({ colors });

  useEffect(() => {

    const email = new URLSearchParams(location.search).get("email");
    setEmail(email)
    const b = document.getElementsByTagName("body")[0];
    // debugger;
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: true }));

    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 500);

    return () => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
    };
  }, []);

  useEffect(() => {
    if (email){
      onIsExcluded();
    }
  }, [email]);

  const onInclude = () => {
    BroadcastModel.onInclude(
      params.slug,
      email,
      (data) => {
        setExcluded(false);
      },
      (data) => {}
    );
  };
  const onExclude = () => {
    BroadcastModel.onExclude(
      params.slug,
      email,
      (data) => {
        setExcluded(true);
      },
      (data) => {}
    );
  };
  const onIsExcluded = () => {
    BroadcastModel.onIsExcluded(
      params.slug,
      email,
      (data) => {
        setExcluded(data.data.excluded);
      },
      (data) => {}
    );
  };

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea className="content-scrollview" style={{ height: "100%" }}>
        <Container
          sx={(theme) => ({
            height: "100%",
            maxWidth: 600,
          })}
        >
          <Box p={20} pt={40}>
            <Box
              sx={(theme) => ({
                border: `1px solid ${colors.default_text_color}`,
                borderRadius: 10,
                paddingTop: 30,
                paddingBottom: 40,
                paddingLeft: 40,
                paddingRight: 40,
                marginBottom: 40,
              })}
            >
              <Link
                to={`/space/${props.space.slug}`}
                className={classes.linkStyle}
              >
                <Title
                  sx={(theme) => ({
                    marginBottom: 10,
                    fontSize: 24,
                    fontWeight: 900,
                  })}
                >
                  {props.space.name}
                </Title>
              </Link>

              {!excluded ? (
                <Center>
                  <Stack>
                    <Text>
                      {`${t(
                        "spaces.presentations.static.broadcastExclude.unsubscribe.part1"
                      )} ${props.space.name}?  ${t(
                        "spaces.presentations.static.broadcastExclude.unsubscribe.part2"
                      )}`}
                    </Text>
                    <Box>
                      <Button
                        radius="xl"
                        color="dark"
                        onClick={() => onExclude()}
                      >
                        {t(
                          "spaces.presentations.static.broadcastExclude.unsubscribe.button"
                        )}
                      </Button>
                    </Box>
                  </Stack>
                </Center>
              ) : (
                <Center>
                  <Stack>
                    <Text>
                      {`${t(
                        "spaces.presentations.static.broadcastExclude.subscribe.part1"
                      )} ${props.space.name}.`}
                    </Text>
                    <Text weight="bold">{`Changed your mind?`}</Text>
                    <Box>
                      <Button radius="xl" onClick={() => onInclude()}>
                        {t(
                          "spaces.presentations.static.broadcastExclude.subscribe.button"
                        )}
                      </Button>
                    </Box>
                  </Stack>
                </Center>
              )}
            </Box>
          </Box>
        </Container>
      </ScrollArea>
    </Stack>
  );
}
