import Image from "@tiptap/extension-image";
import { mergeAttributes } from "@tiptap/core";
import { setResizeable } from "../utils/setResizeable";
import { setLazyLoad } from "../utils/setLazyLoad";
import { checkInteractionAndHideIfNeeded, setUniqId} from "../utils/selection";
import { checkUploadStatus } from "../utils/uploaderStatus";
import { stylesToString } from "../../../utils/StylesToString";
import { wideOrNarrow } from "../../../utils/PageWidth";
// import $ from 'jquery'
// import jUI from 'webpack-jquery-ui'
import { v4 as uuidv4 } from "uuid";

const ImageNode = Image.extend({
  draggable: false,
  selectable: true,
  allowGapCursor: false,
  group: "block columnable",
  inline: false,
  addAttributes() {
    return {
      src: {
        default: null,
      },
      uploader: {
        default: {
          id: null,
          status: null,
          progress: null,
        },
      },
      link: {
        default: {
          url: null,
          target: "_current",
        },
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "15px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: (attributes) => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        }),
      },
    };
  },

  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("section");
      const span = document.createElement("div");
      span.style.maxWidth = wideOrNarrow();
      span.classList.add("image-node");

      const resize = document.createElement("div");

      resize.style.display = "inline-block";

      const image = document.createElement("img");
      const attributes = mergeAttributes(HTMLAttributes, {});

      span.setAttribute("id", attributes.uid);

      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );

      if (attributes.resizeableWidth) {
        resize.style.width = attributes.resizeableWidth;
        resize.style.height = attributes.resizeableHeight;
        span.style.height = attributes.resizeableParentHeight;
      }

      console.log("TIPTAP RENDER IMAGE");

      if (!attributes.src) {
        checkUploadStatus(resize, editor, getPos, node.attrs, "image");
      } else {
        image.style.width = "100%";
        image.style.height = "100%";
        if (window.location.pathname.indexOf("/discussion") >= 0) {
          //image.style.minHeight = $(window).width() / 2 + "px";
        }

        if (location.href.indexOf("broadcast") >= 0) {
          image.setAttribute("src", attributes.src);
        }

        image.style.display = "block";
        image.style["object-fit"] = "cover";

        resize.append(image);
        if (location.href.indexOf("broadcast") < 0) {
          setLazyLoad(image, attributes.src, editor, resize, getPos);
        }
      }

      span.append(resize);
      dom.append(span);

      if (editor.isEditable && attributes.src != null) {
        setTimeout(() => {
          setResizeable(resize, editor, getPos());
        }, 1000);
      }

      if (!editor.isEditable && node.attrs.link.url) {
        image.style.cursor = "pointer";
        image.addEventListener("click", (event) => {
          if (
            node.attrs.link.target == "_blank" ||
            event.ctrlKey ||
            event.metaKey
          ) {
            window.open(node.attrs.link.url, "_blank");
          } else {
            window.location.href = node.attrs.link.url;
          }
        });
      }

      checkInteractionAndHideIfNeeded(editor, dom, getPos);
      //setUniqId(editor, node, getPos, dom);

      return {
        dom,
        contentDOM: span,
        ignoreMutation(mutation) {
          return true;
        },
      };
    };
  },
});

export default ImageNode;
