import React, { useState, useEffect } from "react";

import { Box, Group, Button, createStyles } from "@mantine/core";

import { useTranslation } from "react-i18next";

import { sutraTheme } from "../../../../collections/sutraTheme";

const useStyles = createStyles((theme, sutraTheme) => ({
  primaryButtonStyle: {
    backgroundColor: sutraTheme.colors.sutrablue[3],
    color: "#fff",
    "&:hover": {
      backgroundColor: sutraTheme.colors.sutrablue[4],
    },
  },
}));

export default function SurveyItem(props) {
  const { classes, cx, theme } = useStyles(sutraTheme);
  const { t, i18n } = useTranslation();
  const [survey, setSurvey] = useState({});

  useEffect(() => {
    // if (props.survey && props.survey.id != survey.id){
    setSurvey(props.survey);
    // }
  }, [props]);

  const onEdit = () => {
    props.onEdit(survey);
  };

  return (
    <Box>
      <Group
        noWrap
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={() => onEdit()}
          className={classes.primaryButtonStyle}
          radius="xl"
        >
          {t("spaces.settings.survey.paymentSurveyItem.item.editButton")}
        </Button>
        {false && (
          <Button
            onClick={() => props.onSelect(survey)}
            className={classes.primaryButtonStyle}
            radius="xl"
          >
            {t("spaces.settings.survey.paymentSurveyItem.item.viewButton")}
          </Button>
        )}
      </Group>
    </Box>
  );
}
