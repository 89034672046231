import React, { useRef, useEffect, useState } from "react";
import {
  Text,
  Box,
  MantineProvider,
  Select,
  Button,
  Group,
  createStyles,
  NumberInput,
  ActionIcon,
} from "@mantine/core";

import { DatePicker, TimeInput } from "@mantine/dates";
import { Plus, Minus } from "tabler-icons-react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useForm } from "@mantine/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${theme.colors.gray[3]}`,
    backgroundColor: "#fff",

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    textAlign: "center",
    paddingRight: `${theme.spacing.sm}px !important`,
    paddingLeft: `${theme.spacing.sm}px !important`,
    height: 28,
    flex: 1,
  },
}));

export default function Wait(props) {
  const [waitCondition, setWaitCondition] = useState(null);
  const [numberOfDays, setNumberOfDays] = useState(1);
  const [waitDate, setWaitDate] = useState("");
  const [waitTime, setWaitTime] = useState("");
  const { classes } = useStyles();
  const handlers = useRef();
  const [value, setValue] = useState(1);
  const { t, i18n } = useTranslation();
  const min = 1;
  const max = 1000;
  const mounted = useRef(false);

  const form = useForm({
    initialValues: {
      action: "",
      wait: 1,
      wait_until: null,
    },

    validate: {
      action: (value) =>
        value.length > 0 ? null : "Please select a wait condition",
    },
  });

  useEffect(() => {
    if (props.mode == "edit" && props.step) {
      form.setFieldValue("action", props.step.action);
      form.setFieldValue("wait", props.step.wait);

      if (props.step.action == "wait_until") {
        const waitUntil = new Date(props.step.wait_until);
        // const waitDate = waitUntil.split("T")[0] +"T"+ waitUntil.split("T")[1]
        setWaitDate(waitUntil);
        setWaitTime(waitUntil);
      }
    }
  }, []);

  useEffect(() => {
    if (waitDate && waitTime) {
      const _waitDate = new Date(waitDate);
      const _waitTime = new Date(waitTime);
      const waitUntil = new Date(
        `${_waitDate.toLocaleDateStringAbs()}, ${_waitTime.toLocaleTimeString()}`
      ).toISOString();
      form.setFieldValue("wait_until", waitUntil);
    }
  }, [waitDate, waitTime]);

  const onSend = () => {
    if (props.mode === "edit") {
      props.onUpdate(form.values);
    } else {
      props.onCreate(form.values);
    }
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSend(values))}>
        <MantineProvider theme={{ primaryShade: 6 }}>
          <Select
            {...form.getInputProps("action")}
            label={t("spaces.settings.registration.steps.wait.label1")}
            placeholder={t(
              "spaces.settings.registration.steps.wait.placeholder"
            )}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
            mt={20}
            data={[
              {
                value: "wait",
                label: t("spaces.settings.registration.steps.wait.label2"),
              },
              {
                value: "wait_until",
                label: t("spaces.settings.registration.steps.wait.label3"),
              },
            ]}
          />

          {form.values.action === "wait" && form.values.action !== null && (
            <Box>
              <Text
                mt={20}
                mb={5}
                sx={(theme) => ({
                  fontSize: 18,
                  fontWeight: 500,
                  color: theme.colors.gray[9],
                })}
              >
                {t("spaces.settings.registration.steps.wait.description1")}
              </Text>

              <div className={classes.wrapper}>
                <ActionIcon
                  size={28}
                  variant="transparent"
                  onClick={() => handlers.current.decrement()}
                  disabled={value === min}
                  className={classes.control}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  <Minus size={16} />
                </ActionIcon>

                <NumberInput
                  size="lg"
                  variant="unstyled"
                  min={min}
                  max={max}
                  handlersRef={handlers}
                  {...form.getInputProps("wait")}
                  classNames={{ input: classes.input }}
                />

                <ActionIcon
                  size={28}
                  variant="transparent"
                  onClick={() => handlers.current.increment()}
                  disabled={value === max}
                  className={classes.control}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  <Plus size={16} />
                </ActionIcon>
              </div>
            </Box>
          )}

          {form.values.action === "wait_until" &&
            form.values.action !== null && (
              <Box mt={10}>
                <Text
                  mt={20}
                  mb={5}
                  sx={(theme) => ({
                    fontSize: 18,
                    fontWeight: 500,
                    color: theme.colors.gray[9],
                  })}
                >
                  {t("spaces.settings.registration.steps.wait.description2")}
                </Text>
                <Group>
                  <DatePicker
                    radius="sm"
                    size="md"
                    value={waitDate}
                    placeholder={t(
                      "spaces.settings.registration.steps.wait.label4"
                    )}
                    onChange={(date) => setWaitDate(date)}
                  />
                  <TimeInput
                    radius="sm"
                    size="md"
                    value={waitTime}
                    format="12"
                    onChange={(time) => setWaitTime(time)}
                  />
                </Group>
              </Box>
            )}
        </MantineProvider>
        {props.mode === "edit" ? (
          <Group position="apart">
            <Button
              mt={25}
              radius="xl"
              // size="md"
              type="submit"
              leftIcon={<FontAwesomeIcon icon={solid("check")} />}
            >
              {t("spaces.settings.registration.steps.wait.saveButton")}
            </Button>
            <Button
              onClick={(event) => props.onDestroy(event)}
              mt={25}
              radius="xl"
              // size="md"
              color="dark"
              leftIcon={<FontAwesomeIcon icon={regular("trash-can")} />}
            >
              {t("spaces.settings.registration.steps.wait.deleteButton")}
            </Button>
          </Group>
        ) : (
          <Button
            type="submit"
            // onClick={() => props.onCreate(params)}
            leftIcon={<FontAwesomeIcon icon={solid("check")} />}
            mt={25}
            radius="xl"
            // size="md"
            fullWidth
          >
            {t("spaces.settings.registration.steps.wait.saveButton2")}
          </Button>
        )}
      </form>
    </Box>
  );
}
