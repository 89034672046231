import React, { useState, useEffect, useRef } from "react";

import {
  Box,
  TextInput,
  Textarea,
  SegmentedControl,
  ActionIcon,
  createStyles,
  Text
} from "@mantine/core";

import { GripVertical, X } from "tabler-icons-react";

import { useTranslation } from "react-i18next";

import Answers from "./Answers";

import QuestionModel from "../../../../models/Question";
import AnswerModel from "../../../../models/Answer";

import { Draggable } from "react-beautiful-dnd";

import { colors } from "../../../../collections/colors";

import Swal from "sweetalert2";

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "flex-start",
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 15,
    backgroundColor: theme.white,
    marginTop: 15,
    width: "100%",
  },

  questionWrap: {
    width: "100%",
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    cursor: "move",
    color: theme.colors.gray[6],
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
  },
}));

const questionOptions = [
  { value: "multiple", label: "Multiple choice" },
  { value: "single", label: "Single choice" },
  { value: "freeform", label: "Short Answer" },
  { value: "payment", label: "Payment" },
];

let timerId = null;

const SurveyQuestion = (props) => {
  const { classes, cx } = useStyles();

  const [questionType, setQuestionType] = useState("multiple");
  const [answerKey, setAnswerKey] = useState("");
  const [content, setContent] = useState("");
  const { t, i18n } = useTranslation();
  const [questionId, setQuestionId] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const loading = useRef(false);

  useEffect(() => {
    return () => {
      loading.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      !loading.current &&
      props.question &&
      !props.question.id &&
      props.question.new
    ) {
      onCreate();
    } else if (props.question) {
      setQuestionId(null);
      onFetchById();
    }
  }, [props]);

  useEffect(() => {
    if (loading.current) {
      onUpdate(content, questionType);
    }
  }, [answerKey]);

  const onFetchById = () => {
    QuestionModel.onFetchById(
      props.question.id,
      props.surveyId,
      props.spaceId,
      (data) => {
        setQuestionId(props.question.id);
        if (data.question_type) {
          setQuestionType(
            !props.acceptPayments ? data.question_type : "payment"
          );
        } else {
          setQuestionType(!props.acceptPayments ? "multiple" : "payment");
        }
        if (data.content) {
          setContent(data.content);
        } else {
          if (props.acceptPayments) {
            setContent("Payment question");
          }
        }
        if (data.answers) {
          setAnswers(data.answers);
        }
        setAnswerKey(data.answer_key);
        setTimeout(() => {
          loading.current = true;
        }, 1000);
      },
      (data) => {}
    );
  };

  const getQuestionOptions = () => {
    if (props.acceptPayments) {
      return questionOptions;
    } else {
      const aux = [...questionOptions];
      aux.pop();
      return aux;
    }
  };

  const onQuestionTypeChange = (value) => {
    // onResetCorrectAnswer/s()
    if (value == "freeform") {
      onDestroyAll();
    } else {
      setQuestionType(value);
    }
    // else{
    //   onUpdate(content, value)
    // }
    onUpdate(content, value);
  };

  const onContentChange = (value) => {
    setContent(value);
    onUpdate(value, questionType);
  };

  const onCreate = () => {
    setDisabled(true)
    QuestionModel.onCreate(
      props.spaceId,
      props.surveyId,
      { content: props.question.content },
      (data) => {
        setQuestionId(data.id);
        props.onUpdateLast(data.id);
        setTimeout(() => {
          loading.current = true;
          onPushNewAnswer()
          setDisabled(false)
        }, 1000);
      },
      (data) => {}
    );
  };

  const onUpdate = (content, type) => {
    if (!questionId) {
      return;
    }
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      QuestionModel.onUpdate(
        questionId,
        props.spaceId,
        props.surveyId,
        { question_type: type, content, answer_key: answerKey },
        (data) => {
          // onAnswersUpdate(answers, type, content)
          // const auxQuestion = {...props.question}
          // auxQuestion.content = content
          // auxQuestion.question_type = type
          // auxQuestion.answers
          // props.onUpdate({})
        },
        (data) => {}
      );
    }, 1000);
  };

  const onDestroy = () => {
    if (!questionId) {
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      html: "All collected question responses will be deleted.",
      showDenyButton: true,
      confirmButtonText: "Yes, delete",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        QuestionModel.onDestroy(
          questionId,
          props.spaceId,
          props.surveyId,
          (data) => {
            props.onDestroy({ id: questionId });
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const onDestroyAll = () => {
    if (!questionId) {
      return;
    }

    AnswerModel.onDestroyAll(
      props.spaceId,
      props.surveyId,
      questionId,
      (data) => {
        // onAnswersUpdate([], "freeform", content)

        setQuestionType("freeform");
        // setTimeout(() => {
        //
        //   setAnswers([{new: true}])
        // }, 500)
      },
      (data) => {}
    );
  };

  const onPushNewAnswer = () => {
    const newAnswers = [...answers];
    if (!window.$tmpAnswersId){
      window.$tmpAnswersId = 0
    }

    const answer_text = props.question.question_type == "payment" ? "Option" : "Answer"

    newAnswers.push({ new: true, content: `Answer ${answers.length + 1}`, tmp_id: window.$tmpAnswersId, frequency: "once" });
    setAnswers(newAnswers);
  };

  const onResetCorrectAnswers = () => {
    const newQuestion = { ...props.question };
    const newAnswers = [...newQuestion.answers]
    for (let i = 0; i < newAnswers.length; i++) {
      const answer = newAnswers[i];
      answer.is_correct = false
    }
    newQuestion.answers = newAnswers;
    setAnswers(newAnswers);
    props.onUpdate(newQuestion, props.index);
  }

  const onAnswersUpdate = (_answers, _questionType = null, _content = null) => {
    const newAnswers = [..._answers];

    setAnswers(newAnswers);
    const newQuestion = { ...props.question };
    newQuestion.answers = newAnswers;
    newQuestion.content = _content ? _content : content;
    newQuestion.question_type = _questionType ? _questionType : questionType;

    props.onUpdate(newQuestion, props.index);
  };

  const onDrag = () => {};

  return (
    <Draggable
      key={`question-${questionId}`}
      index={props.index}
      draggableId={`question-${questionId}`}
    >
      {(provided, snapshot) => (
        <Box
          className={cx(classes.item, {
            [classes.itemDragging]: snapshot.isDragging,
          })}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {!props.acceptPayments && (
            <div className={classes.dragHandle} {...provided.dragHandleProps}>
              <GripVertical size={18} />
            </div>
          )}
          <div className={classes.questionWrap}>
            {!props.acceptPayments && (
              <TextInput
                value={content}
                onChange={(event) => onContentChange(event.currentTarget.value)}
                placeholder={`Question`}
                radius="sm"
                size="md"
                rightSection={
                  <ActionIcon variant="transparent" onClick={() => onDestroy()}>
                    <X size={18} />
                  </ActionIcon>
                }
                rightSectionWidth={40}
                disabled={disabled}
              />
            )}
            {!props.acceptPayments && (
              <SegmentedControl
                mt={10}
                value={questionType}
                onChange={onQuestionTypeChange}
                data={getQuestionOptions()}
                radius="md"
                size="sm"
                fullWidth
              />
            )}

            {props.quizMode && questionType != "freeform" && (
              <Text mt={10} size="sm" color={"dimmed"}>{`Select the correct answer below`}</Text>
            )}

            <Answers
              type={questionType}
              answers={answers}
              surveyId={props.surveyId}
              spaceId={props.spaceId}
              questionId={questionId}
              onUpdate={onAnswersUpdate}
              currency={props.currency}
              quizMode={props.quizMode}
            />

            {props.showKeys && (
              <Textarea
                label="Answer key (optional)"
                description="The answer key appears after the participant has submitted the entire quiz"
                value={answerKey}
                onChange={(event) => setAnswerKey(event.currentTarget.value)}
                // placeholder={`Answer key (optional)`}
                radius="sm"
                size="md"
                autosize={true}
              />
            )}
          </div>
        </Box>
      )}
    </Draggable>
  );
};

export default SurveyQuestion;
