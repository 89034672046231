import { Node, mergeAttributes } from "@tiptap/core";

import { checkInteractionAndHideIfNeeded, setUniqId } from "../utils/selection";
import { checkUploadStatus } from "../utils/uploaderStatus";

import { stylesToString } from "../../../utils/StylesToString";
import { wideOrNarrow } from "../../../utils/PageWidth";

export const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

const AudioNode = Node.create({
  name: "audio",

  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block columnable";
  },

  draggable: false,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      controls: {
        default: true,
      },
      uploader: {
        default: {
          id: null,
          status: null,
          progress: null,
        },
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "0px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: (attributes) => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "audio",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "audio",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setAudio:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("section");

      const span = document.createElement("div");
      span.classList.add("audio-node");
      span.style.maxWidth = wideOrNarrow();

      const audio = document.createElement("audio");
      const attributes = mergeAttributes(HTMLAttributes, {});

      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );

      if (!attributes.src) {
        checkUploadStatus(span, editor, getPos, node.attrs, "audio");
      } else {
        audio.setAttribute("width", "100%");
        audio.setAttribute("controls", attributes.controls);
        audio.setAttribute("preload", "auto");

        const source = document.createElement("source");
        source.src = attributes.src;

        audio.append(source);
        // setLazyLoad(source, attributes.src, editor)
        audio.append("Your browser does not support the audio tag.");

        span.append(audio);
      }

      dom.append(span);

      audio.onfocus = (event) => {
        setTimeout(() => {
          editor.chain().focus().selectParentNode().run();
        }, 500);
      };

      checkInteractionAndHideIfNeeded(editor, dom, getPos);

      //setUniqId(editor, node, getPos, span);
      
      return {
        dom,
        contentDOM: span,
      };
    };
  },

  // addInputRules() {
  //   return [
  //     nodeInputRule({
  //       find: inputRegex,
  //       type: this.type,
  //       getAttributes: match => {
  //         const [,, alt, src, title] = match
  //
  //         return { src, alt, title }
  //       },
  //     }),
  //   ]
  // },
});

export default AudioNode;
