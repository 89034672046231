import React, { useState } from "react";

import {
  isEmptySelection,
  isParagraph,
  isInsideNode,
  isDocumentFocused,
  isMultilineSelection,
} from "../utils/selection";

import { FloatingMenu } from "@tiptap/react";

import BlockMenuActions from "./BlockMenuActions";

const ActionsMenu = (props) => {
  // const [shouldShow, setShouldShow] = useState(false);
  const [activeType, setActiveType] = useState(null);
  const [showInsides, setShowInsides] = useState(false);

  const shouldShowMenu = (view, state) => {
    const show =
      isDocumentFocused(props.editor) &&
      isEmptySelection(props.editor) &&
      isParagraph(props.editor) &&
      !isInsideNode(props.editor, ["listItem", "taskItem"]) &&
      !isMultilineSelection(props.editor);
    setShowInsides(show);
    return show;
  };

  if (!props.editor) {
    return null;
  }

  return (
    <FloatingMenu
      shouldShow={({ view, state }) => shouldShowMenu(view, state)}
      editor={props.editor}
      tippyOptions={{ duration: 100, maxWidth: "none" }}
    >
      {showInsides && (
        <BlockMenuActions
          excludedFound={false}
          isMultiple={false}
          isASpace={false}
          isEmpty={true}
          editor={props.editor}
        />
      )}
    </FloatingMenu>
  );
};

export default ActionsMenu;
