import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box } from "@mantine/core";

import ListContent from "./ListContent";
import ListPost from "./ListPost";
import GridPost from "./GridPost";

import { getRoot } from "../../../utils/Host";
import { setTiptapSidemenu } from "../../../store/app";

export default function List(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLink = () => {
    if (props.space.version) {
      return `/space/${props.space.slug}/${props.space.version}`;
    } else {
      return `/space/${props.space.slug}`;
    }
  };

  const goToLink = () => {
    if (window.$templatesModalIsOpened){
      return
    }
    if (props.space.is_linked) {
      window.open(`${getRoot()}${getLink()}`, "_blank");
    } else if (props.openInModal) {
      
      dispatch(
        setTiptapSidemenu({ opened: "space-content", option: props.space.slug })
      );
      
    } else {
      navigate(getLink());
    }
  };

  return (
    <>
      {props.noLink ? (
        <Box
          component={Link}
          to={getLink()}
          sx={{
            height: props.viewAs == "grid" ? "100%" : "",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          {!props.viewAs && <ListContent space={props.space} />}
          {props.viewAs == "post" && <ListPost space={props.space} />}
          {props.viewAs == "grid" && <GridPost space={props.space} />}
          {props.viewAs == "card" && <GridPost space={props.space} />}
          {props.viewAs == "link" && `${getRoot()}${getLink()}`}
        </Box>
      ) : (
        <Box
          onClick={(event) => goToLink()}
          sx={{
            height: props.viewAs == "grid" ? "100%" : "",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          {!props.viewAs && <ListContent space={props.space} />}
          {props.viewAs == "post" && (
            <ListPost
              space={props.space}
              onUpdate={props.onUpdate}
              showAuthor={props.showAuthor}
              canPin={props.canPin}
              {...props.settings}
              onDuplicate={props.onDuplicate}
            />
          )}
          {props.viewAs == "grid" && (
            <GridPost
              space={props.space}
              onUpdate={props.onUpdate}
              showAuthor={props.showAuthor}
              canPin={props.canPin}
              {...props.settings}
              onDuplicate={props.onDuplicate}
            />
          )}
          {props.viewAs == "card" && (
            <GridPost
              space={props.space}
              onUpdate={props.onUpdate}
              showAuthor={props.showAuthor}
              canPin={props.canPin}
              {...props.settings}
              onDuplicate={props.onDuplicate}
            />
          )}
          {props.viewAs == "link" && (
            `${getRoot()}${getLink()}`
          )}
        </Box>
      )}
    </>
  );
}
