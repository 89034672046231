import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Group, Text, MantineProvider, Select } from "@mantine/core";

import SpaceModel from "../../../models/Space";

import PremiumModal from "../../app/PremiumModal";

import { useTranslation } from "react-i18next";

import { deleteJSON } from "../../../utils/LocalDB";

const SelectItem = ({ image, label, description, ...others }) => (
  <div {...others}>
    <Group noWrap>
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </div>
    </Group>
  </div>
);

export default function PageFormat(props) {
  const { t, i18n } = useTranslation();
  const [format, setFormat] = useState();

  const space = useSelector((state) => state.space);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  const loading = useRef();

  useEffect(() => {
    if (space && space.slug) {
      setFormat(space.version);
    }
    setValidatePremium(
      space && space.permissions && space.permissions.can_set_format
    );
  }, [space]);

  useEffect(() => {
    if (!loading.current) {
      loading.current = true;
      return;
    }
    onChangeFormat();
  }, [format]);

  const onChangeFormat = () => {
    if (space && format && format != space.version) {
      if (!validatePremium[0]) {
        setOpened([true, validatePremium[1], validatePremium[2]]);
        return;
      }
      SpaceModel.onUpdate(
        space.slug,
        { version: format },
        (data) => {
          props.setOpened(false);

          deleteJSON("spaces", space.slug);

          setTimeout(() => {
            window.location.href= `/space/${space.slug}/${format}`;
          }, 1000);
        },
        (data) => {}
      );
    }
  };

  const getData = () => {
    
    const array = [];
    if (space.version != "list") {
      array.push({
        value: "list",
        label: t("spaces.colors.pageFormat.listLabel"),
        description: t("spaces.colors.pageFormat.listDescription"),
      });
    }
    if (
      (space.version_history.indexOf("content") >= 0 &&
        ["list", "events"].indexOf(space.version) >= 0) ||
      space.version == "discussion"
    ) {
      array.push({
        value: "content",
        label: t("spaces.colors.pageFormat.contentLabel"),
        description: t("spaces.colors.pageFormat.contentDescription"),
      });
    }
    if (space.version != "discussion") {
      array.push({
        value: "discussion",
        label: t("spaces.colors.pageFormat.discussionLabel"),
        description: t("spaces.colors.pageFormat.discussionDescription"),
      });
    }
    if (space.version != "events") {
      array.push({
        value: "events",
        label: t("spaces.colors.pageFormat.eventsLabel"),
        description: t("spaces.colors.pageFormat.eventsDescription"),
      });
    }
    return array
  };

  return (
    <MantineProvider theme={{ primaryShade: 6 }}>
      <Text size="sm" color="dimmed" mb={15}>
        {t("spaces.colors.pageFormat.description")}
      </Text>
      <Select
        value={format}
        onChange={setFormat}
        placeholder="Pick one"
        itemComponent={SelectItem}
        maxDropdownHeight={400}
        zIndex={99999}
        defaultValue={"content"}
        data={getData()}
      />
      <PremiumModal opened={opened} setOpened={setOpened} />
    </MantineProvider>
  );
}
