import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import {stylesToString} from '../../../utils/StylesToString'
import Button from '../components/Button';


const ButtonNode = Node.create({
  name: 'actionbutton',
  draggable: false,
  allowGapCursor: true,
  selectable: true,
  allowGapCursor: false,
  group: "block columnable",
  inline: false,

  addAttributes() {
    return {
      text: {
        default: "Text",
      },
      linkTo: {
        default: "https://sutra.co",
      },
      idName: {
        default: "actionbutton-node",
      },
      isMarkComplete: {
        default: false
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "10px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: attributes => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        })
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'actionbutton',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {

    return ['actionbutton', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addNodeView() {
    
    return ReactNodeViewRenderer(Button)
    // return ({ editor, getPos, node, HTMLAttributes, }) => {
    //   const dom = document.createElement("section")
    //
    //   const span = document.createElement("div")
    //   span.classList.add("button-node")
    //
    //   const button = document.createElement('button');
    //   const attributes = mergeAttributes(HTMLAttributes, {});
    //
    //   Object.entries(attributes).forEach(([key, value]) => dom.setAttribute(key, value));
    //   button.innerHTML = "Button"
    //   span.append(button)
    //   dom.append(span)
    //
    //   return {
    //       dom,
    //       contentDOM: span,
    //
    //   };
    // }
  },
})

export default ButtonNode;
