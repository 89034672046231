import ListItem from '@tiptap/extension-list-item'
import {v4 as uuidv4} from 'uuid';

const ListItemNode = ListItem.extend({
  draggable: false,
  content: 'paragraph columnable*',
  addAttributes() {
    return {
      uid: {
        default: uuidv4()
      }
    }
  },
})

export default ListItemNode;
