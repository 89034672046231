import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SpaceModel from "../../../models/Space";

import isSpaceThemed from "../../../utils/IsSpaceThemed";
import useSidebarStyles from "../../../utils/SidebarStyles";

import { createStyles, Popover, Box, Tabs, Group } from "@mantine/core";

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import data from "@emoji-mart/data";

import Picker from "@emoji-mart/react";

import IconSelect from "./IconSelect";

const useStyles = createStyles((theme, { colors }) => ({
  nodeIconWrap: {
    display: "inline-block",
    width: "20px",
    textAlign: "center",
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : theme.colors.gray[7],
  },
  nodeIconWrapSettings: {
    color: theme.colors.gray[7],
  },
  nodeIconWrapSettingsActive: {
    color: "#fff",
  },
  markComplete: {
    color: theme.colors.green[6],
  },
}));

export default function NodeIcon(props) {
  // library.add(far, fas)

  const colors = useSelector((state) => state.colors);
  const { settingsView } = useSidebarStyles();
  const { t, i18n } = useTranslation();
  const { theme, classes, cx } = useStyles({ colors });
  const [opened, setOpened] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [emojicon, setEmojicon] = useState();

  useEffect(() => {
    setMarkComplete(props.completed);
    // setEmojicon(getEmojiCode(props.emojicon))
    setEmojicon(props.emojicon);
  }, [props]);

  const onEmojiSelect = (emoji) => {
    // const native = getEmojiCode(emoji.id)
    // setEmojicon(native)
    setEmojicon(`emoji/${emoji.id}`);
    saveEmojicon(`emoji/${emoji.id}`);
    setOpened(false);
  };

  const getEmojiCode = (emoji) => {
    if (emoji) {
      return data.emojis[emoji].skins[0].native;
    } else {
      return null;
    }
  };

  const onIconSelect = (icon) => {
    setEmojicon(`icon/${icon}`);
    saveEmojicon(`icon/${icon}`);
    setOpened(false);
  };

  const saveEmojicon = (emoji) => {
    SpaceModel.onUpdate(
      props.spaceId,
      { emojicon: emoji },
      (data) => {},
      (data) => {}
    );
  };

  const onOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpened(!opened);
  };

  const displayEmoji = () => {
    if (emojicon && emojicon != "") {
      if (emojicon.split("/")[0] === "emoji") {
        return getEmojiCode(emojicon.split("/")[1]);
      } else if (emojicon.split("/")[0] === "icon") {
        const iconType = emojicon.split("/")[1].split("#")[0];
        const iconValue = emojicon.split("/")[1].split("#")[1];
        if (iconValue === "bolt") {
          return <FontAwesomeIcon icon={solid("bolt")} />;
        }
        return <FontAwesomeIcon icon={[iconType, iconValue]} />;
        // return (<FontAwesomeIcon icon={solid(iconValue)} />)
      } else {
        return getEmojiCode(emojicon);
      }
    }
    return <FontAwesomeIcon icon={solid("bolt")} />;
  };

  const onReset = (event) => {
    if (event === 2) {
      setEmojicon("");
      saveEmojicon("");
      setOpened(false);
    }
  };

  return (
    <Popover
      styles={{
        root: {
          marginRight: 10,
          // width: 350,
        },
        inner: {
          padding: 0,
        },
        popover: {
          width: 355,
        },
      }}
      opened={opened}
      onClose={() => setOpened(false)}
      target={
        <Box
          className={cx(
            classes.nodeIconWrap,
            { [classes.markComplete]: markComplete },
            { [classes.nodeIconWrapSettings]: settingsView },
            {
              [classes.nodeIconWrapSettingsActive]:
                settingsView && props.spaceId === props.activeId,
            }
          )}
          onClick={(event) => {
            props.editable && onOpen(event);
          }}
        >
          {markComplete ? (
            <FontAwesomeIcon icon={solid("circle-check")} />
          ) : (
            <>{displayEmoji()}</>
          )}
        </Box>
      }
      width={260}
      position="right"
      placement="start"
      withArrow
    >
      <Tabs
        styles={{
          tabsList: {
            flexWrap: "nowrap",
          },
          tabActive: {
            fontWeight: "bold",
            color: `${theme.colors.dark[9]} !important`,
            borderBottomColor: `${theme.colors.sutrablue[4]} !important`,
          },
          tabLabel: { color: theme.colors.gray[7] },
        }}
        onTabChange={(event) => onReset(event)}
      >
        <Tabs.Tab label="Emoji">
          <Picker
            data={data}
            onEmojiSelect={onEmojiSelect}
            navPosition={"bottom"}
          />
        </Tabs.Tab>
        <Tabs.Tab label="Icons">
          <IconSelect onIconSelect={onIconSelect} />
        </Tabs.Tab>
        <Group
          position="apart"
          noWrap
          sx={{
            width: "100%",
          }}
        >
          <div></div>
          <Tabs.Tab label={t("spaces.sidebar.nodeIcon.reset")}></Tabs.Tab>
        </Group>
      </Tabs>
    </Popover>
  );
}
