import axios from 'axios';
import { getCookie, setCookie } from "../utils/Cookies";

class User {
  constructor() {
    if (this instanceof User) {
      throw Error('User class cannot be instantiated.');
    }
  }

  static onFetch = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }
    await axios.get(`/api/v4/users/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onSettings = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }
    await axios.get(`/api/v4/users/${id}/settings.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onAuth = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      oauth_client_id: getCookie("oauth_client_id"),
      oauth_client_secret: getCookie("oauth_client_secret"),
      oauth_state: getCookie("oauth_state"),
      oauth_redirect_uri: getCookie("oauth_redirect_uri"),
      oauth_response_type: getCookie("oauth_response_type"),
    }
    
    await axios.post(`/api/v4/users/${id}/auth.json`, params)
    .then(res => {

      setCookie("oauth_client_id", "", 0)
      setCookie("oauth_client_secret", "", 0)
      setCookie("oauth_state", "", 0)
      setCookie("oauth_redirect_uri", "", 0)
      setCookie("oauth_response_type", "", 0)

      if (res.data.redirect_uri){
        window.location.href= res.data.redirect_uri;
        return
      }

      sucessCallback(res.data)

      
    })
    .catch((error) => {
      try {
        if (error.response.data && error.response.data.type == "oauth"){
          document.write(error.response.data.error)
          return
        }
        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onUpdate = async (id, user, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      user: {...user}
    }
    await axios.put(`/api/v4/users/${id}.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onChangePassword = async (id, user, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      user: {...user}
    }
    await axios.put(`/api/v4/users/${id}/change_password.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onDestroy = async (id, confirm, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      confirm: confirm
    }
    await axios.delete(`/api/v4/users/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onUploadAvatar = async (id, file, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      file
    }
    await axios.post(`/api/v4/users/${id}/upload_avatar.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onRemoveAvatar = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      user: {...params}
    }
    await axios.delete(`/api/v4/users/${id}/remove_avatar.json`, {params: params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onGateway = async (plan, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      plan
    }
    await axios.post(`/api/v4/users/gateway.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onFetchSubs = async (sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    }
    await axios.post(`/api/v4/users/current/paid_subscriptions.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onFetchStripeConnections = async (sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    }
    await axios.post(`/api/v4/users/current/stripe_connections.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

}

export default User;
