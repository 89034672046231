import React from 'react';

import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';

import {
  Button,
} from '@mantine/core';

import getSymbolFromCurrency from 'currency-symbol-map'

import { useTranslation } from "react-i18next";

export default function Payment(props) {
  const { t, i18n } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (event) => {
    event.preventDefault()

    await props.onPay(stripe, elements)
  }

  const getFrequency = () => {

    if (!props.frequency){
      return ""
    }
    return props.frequency != "once" ? ` / ${props.frequency}` : ""
  }

  const buttonText = () => {
    const amount = props.amount ? parseFloat(props.amount) : 0
    
    return `${t("spaces.stripe.pay")} ${getSymbolFromCurrency(props.currency)}${amount.toFixed(2).replace(".00", "")}${getFrequency()}`
  }

  return (
    <form
      onSubmit={onSubmit}>
      <PaymentElement />
      <Button
        fullWidth
        mt="xl"
        radius="xl"
        size="lg"
        mb={15}
        type="submit">
        {buttonText()}
      </Button>
    </form>
  )
}
