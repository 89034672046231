import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Modal, Text, Avatar, Group, createStyles } from "@mantine/core";

import Form from "../../form/ListForm";
import LinkForm from "../../form/LinkForm";
import DefaultAvatar from "../../../../src/images/fallback/default.png";
import isSpaceThemed from "../../../../utils/IsSpaceThemed";

const useStyles = createStyles((theme, { colors, publishOnDate }, getRef) => ({
  abstract: {
    textDecoration: "none",
    // color: "#000",
    color: isSpaceThemed(colors) ? colors.default_text_color : "#000",
    margin: 0,

    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const NewSpace = (props) => {
  const space = useSelector((state) => state.space);

  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const [spaceType, setSpaceType] = useState("content");

  const user = useSelector((state) => state.user);

  useEffect(() => {
    setSpaceType(props.spaceType);
  }, [props]);

  const onSave = (spaceId, spaceLink, spaceName="") => {
    props.setOpened(false);
    props.onSave(spaceId, spaceLink);
  };

  return (
    <Modal
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Group spacing={10}>
          <Avatar
            radius="xl"
            size={40}
            key={`member-avatar-${user.id}`}
            src={user.avatar ? user.avatar.url : DefaultAvatar}
            component="span"
          />

          <Text
            // className={classes.abstract}
            weight={600}
            sx={{
              lineHeight: "1",
            }}
            mb={6}
          >
            {`${user.first_name} ${user.last_name}`}
          </Text>
        </Group>
      }
      padding="xl"
      size="lg"
      overlayOpacity={0.1}
      zIndex={99999}
    >
      {props.linkOnly ? (
        <LinkForm
          parentId={space.slug}
          onSave={onSave}
          actionButtonText={props.actionButtonText}
        />
      ) : (
        props.opened && (
          <Form
            parentId={space.slug}
            onSave={onSave}
            version={spaceType}
            content={props.content}
            noAdvance={props.noAdvance}
            duplicateFrom={props.duplicateFrom}
            actionButtonText={props.actionButtonText}
            privacyControl={props.privacyControl}
          />
        )
      )}
    </Modal>
  );
};

export default NewSpace;
