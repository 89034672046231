import { Extension } from '@tiptap/core'

// https://tiptap.dev/guide/custom-extensions#attributes

const CustomResizeable = Extension.create({
  name: "customResizeable",
  addOptions() {
    return {
      types: [],
    }
  },
  addGlobalAttributes() {

    return [
      {
        types: [
          'image',
          'video',
          'embed'
        ],
        attributes: {
          resizeableWidth: {
            default: "50%"
          },
          resizeableHeight: {
            default: "100%"
          },
          resizeableParentHeight: {
            default: "unset"
          },
          radius:  {
            default: "no-radius",
            renderHTML: attributes => ({
              radius: `${attributes.radius}`,
            })
          }
        },
      },
    ]
  },
  addCommands() {
    return {
      setCustomResizeable: ({resizeableWidth, resizeableHeight, resizeableParentHeight}) => ({ commands, chain, view }) => {
        // if (!window.$tiptapSelectionRange){
        //   return
        // }
        // const {from, to} = window.$tiptapSelectionRange
        const { selection } = view.state

        const content = selection.node ? selection.node : selection.$anchor.parent

        return commands.updateAttributes(content.type.name, { resizeableWidth, resizeableHeight, resizeableParentHeight})
      },
    }
  },
})

export default CustomResizeable;
