import React, { useState, useEffect, useRef } from "react";

import {
  Divider,
  Text,
  Table,
  Group,
  ScrollArea,
  Button,
  Pagination,
  Box,
  TextInput,
  Loader,
} from "@mantine/core";

import SpaceModel from "../../../../models/Space";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

import { Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import InterestModel from "../../../../models/Interest";

import ComposeBroadcast from "../broadcast/ComposeBroadcast";

import Row from "./InterestedRow";

const paginator = {
  page: 1,
  per: 30,
};

export default function Interested(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  // const theme = useMantineTheme();
  const theme = props.theme;
  const { height, width } = useViewportSize();

  const [interests, setInterests] = useState({
    total: 0,
    page: 1,
    pages: 1,
    data: [],
  });
  const [term, setTerm] = useState(null);

  const largeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.lg}px)`
  );
  const extraLargeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );

  const [opened, setOpened] = useState(false);

  const [loading, setLoading] = useState(false);
  const timeoutId = useRef();

  const tableWidth = () => {
    if (largeScreen) {
      return width - 300;
    } else if (extraLargeScreen) {
      return width - 500;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [space]);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [term]);

  useEffect(() => {
    if (props.refresh) {
      onFetch();
      props.setRefresh(false);
    }
  }, [props]);

  const onFetch = () => {
    setLoading(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      InterestModel.onFetch(
        space.slug,
        paginator,
        { term },
        (data) => {
          setInterests(data);
          setLoading(false);
        },
        (data) => {
          setLoading(false);
        }
      );
    }, 1000);
  };

  const onInterestDestroy = (interest) => {
    InterestModel.onDestroy(
      interest.id,
      space.slug,
      (data) => {
        const newInterest = { ...interests };
        for (var i = 0; i < newInterest.data.length; i++) {
          if (newInterest.data[i].id == interest.id) {
            newInterest.data.splice(i, 1);
            break;
          }
        }
        setInterests(newInterest);
      },
      (data) => {}
    );
  };

  const onKeyPress = (event) => {
    onKeyGeneral(event);
  };
  const onKeyDown = (event) => {
    onKeyGeneral(event);
  };

  const onKeyGeneral = (event) => {
    if (event.which == 13) {
      event.preventDefault();
      onSearch();
    }
  };

  const onSearch = () => {
    setTerm(document.getElementById("search-input").value);
  };

  const onClearSearch = () => {
    document.getElementById("search-input").value = "";
    setTerm("");
  };

  const changePage = (page) => {
    paginator.page = page;
    onFetch();
  };

  const onBroadcastUpdate = () => {
    // onFetch()
  };

  return (
    <div>
      <Group position="apart" mt={20}>
        <Group position="left" spacing="xs">
          <TextInput
            icon={<FontAwesomeIcon icon={solid("search")} />}
            placeholder={t(
              "spaces.settings.members.interested.interested.placeholder"
            )}
            id="search-input"
            radius="xl"
            onKeyPress={(event) => onKeyPress(event)}
            onKeyDown={(event) => onKeyDown(event)}
            rightSection={
              term ? (
                <Text
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => onClearSearch()}
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </Text>
              ) : (
                <Text>{"  "}</Text>
              )
            }
          />
          <Button
            variant="filled"
            radius="xl"
            onClick={(event) => onSearch()}
            sx={{
              backgroundColor: theme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: theme.colors.sutrablue[4],
              },
            }}
          >
            {t("spaces.settings.members.interested.interested.button")}
          </Button>
          {loading && <Loader size="xs" />}
        </Group>

        <Button
          variant="filled"
          radius="xl"
          leftIcon={<FontAwesomeIcon icon={regular("envelope")} />}
          sx={{
            backgroundColor: theme.colors.sutrablue[3],
            "&:hover": {
              backgroundColor: theme.colors.sutrablue[4],
            },
          }}
          onClick={() => setOpened(true)}
        >
          {t("spaces.settings.members.interested.interested.broadcast")}
        </Button>
      </Group>

      <ScrollArea mt={10} style={{ width: tableWidth() }}>
        {interests.data.length == 0 ? (
          <Box
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              textAlign: "center",
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
              cursor: "pointer",

              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[5]
                    : theme.colors.gray[1],
              },
            })}
          >
            {t("spaces.settings.members.interested.interested.noRecordFound")}
          </Box>
        ) : (
          <Table verticalSpacing="xs" highlightOnHover>
            <thead>
              <tr>
                <th>
                  {t("spaces.settings.members.interested.table.firstname")}
                </th>
                <th>{t("spaces.settings.members.interested.table.email")}</th>
                <th>{t("spaces.settings.members.interested.table.date")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {interests.data.map((interest) => {
                return (
                  <Row
                    spaceId={space.slug}
                    interest={interest}
                    onDestroy={onInterestDestroy}
                  />
                );
              })}
            </tbody>
          </Table>
        )}
      </ScrollArea>
      <Divider sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: 20,
          justifyContent: "center",
        }}
      >
        <Pagination
          color="gray"
          page={paginator.page}
          onChange={changePage}
          total={interests.pages}
        />
      </Box>

      <ComposeBroadcast
        opened={opened}
        setOpened={setOpened}
        sendTo={["interested"]}
        onUpdate={onBroadcastUpdate}
        parentName={space.name}
        isTopLevel={space.is_top_level}
        isEmailReady={window.$fetchedTopSpace.is_email_ready}
      />
    </div>
  );
}
