import React, { useState, useEffect } from 'react';
import {

  Box,
  MantineProvider,
  Select,
  Button,

  Group
} from '@mantine/core'

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'

import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import SpaceModel from '../../../../../models/Space'

export default function ApplyTemplate(props) {

  const space = useSelector(state => state.space);
  const [spaces, setSpaces] = useState([]);
  const { t, i18n } = useTranslation();
  const form = useForm({
    initialValues: {
      object_type: "pod",
      object_id: ""
    },

    validate: {
      object_id: (value) => value.length > 0 ? null : "Please select a space",
    }
  });

  useEffect(() => {
    onFetchSpaces()
  }, [])

  const onFetchSpaces = () => {
    const _spaces = []

    SpaceModel.onFetchChildren(space.slug, ["members"], {page: 1, per: 50}, ["active"], (data) => {
      for (let i = 0; i < data.data.length; i++) {
        _spaces.push({value: `${data.data[i].id}`, label: `${data.data[i].name} (${data.data[i].slug})`, group: "Sub spaces"})
      }

      if (props.mode === "edit") {
        form.setFieldValue("object_id", `${props.step.object_id}`)
      }
    }, (data) => {

    })

    SpaceModel.onFetchByScope("managed", ["members", "simple"], {page: 1, per: 100}, (data) => {

      for (let i = 0; i < data.length; i++) {
        var index = _spaces.findIndex(x => x.value==data[i].id);
        index === -1 && (
          _spaces.push({value: `${data[i].id}`, label: `${data[i].name} (${data[i].slug})`, group: "All spaces"})
        )

      }

      if (props.mode === "edit") {
        form.setFieldValue("object_id", `${props.step.object_id}`)
      }
    }, (data) => {

    })

    setSpaces(_spaces)

  }

  const onSend = () => {
    if (props.mode === "edit") {
      props.onUpdate(form.values)
    } else {
      props.onCreate(form.values)
    }
  }

  return (
    <Box>
      <form
        onSubmit={form.onSubmit((values) => onSend(values))}>
        <MantineProvider
          theme={{ primaryShade: 6 }}
          >
            <Select
              // searchable clearable
              label={t("spaces.settings.registration.steps.applyTemplate.label")}
              description={t("spaces.settings.registration.steps.applyTemplate.description")}
              searchable
              mt={20}
              data={spaces}
              placeholder="Select a space"
              {...form.getInputProps('object_id')}
              maxDropdownHeight={400}
              radius="sm"
              size="md"
              />
          </MantineProvider>

          {
            props.mode === "edit" ? (
              <Group
                position="apart"
              >
                <Button
                  mt={25}
                  radius="xl"
                  // size="md"
                  type="submit"
                  leftIcon={<FontAwesomeIcon icon={solid('check')} />}
                >
                  {t("spaces.settings.registration.steps.applyTemplate.saveButton1")}
                </Button>
                <Button
                  onClick={(event) => props.onDestroy(event)}
                  mt={25}
                  radius="xl"
                  // size="md"
                  color="dark"
                  leftIcon={<FontAwesomeIcon icon={regular('trash-can')} />}
                >
                  {t("spaces.settings.registration.steps.applyTemplate.deleteButton")}
                </Button>
              </Group>
            ) : (
              <Button
                type="submit"
                // onClick={() => props.onCreate(params)}
                leftIcon={<FontAwesomeIcon icon={solid('check')} />}
                mt={25}
                radius="xl"
                // size="md"
                fullWidth
              >
                {t("spaces.settings.registration.steps.applyTemplate.saveButton2")}
              </Button>
            )
          }
        </form>
    </Box>
  )
}
