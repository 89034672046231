import { useEffect, memo, useRef } from "react";
import manager from "./manager";
import { useDispatch } from "react-redux";

import { setConnections } from "../store/app";

const ConnectionsChannel = memo(({username, isAdmin}) => {
  const dispatch = useDispatch();

  const connectedTo = useRef();

  useEffect(() => {

    if (!username) {
      return;
    }

    if (connectedTo.current == username) {
      return;
    }

    const messageCallback = (data) => {
      console.log(
        `SOCKETS - > Message received to channel ${getChannelName()}: ${JSON.stringify(data)}`
      );

      const { users } = data;

      connectedTo.current = username;
      // if (getIsAdmin()){
        dispatch(setConnections({ connections: [...users] }));
      // }
    };

    const channelName = getChannelName();

    manager.subscribe(channelName, messageCallback);

    return () => {
      manager.unsubscribe(channelName);
      connectedTo.current = null;
    };
  }, [username]);

  const getChannelName = () => {
    return `connections`;
  };

  const getIsAdmin = () => {
    return isAdmin
  }


  return null;
});

export default ConnectionsChannel;
