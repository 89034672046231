import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { Stack, Textarea } from "@mantine/core";

export default function InteractiveTextArea(props) {
  const timeoutId = useRef(null);

  const loading = useRef(false);

  const tiptap = useSelector((state) => state.tiptap);
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const _checked = props.element.node.attrs[`${props.attrName}`];

    if (_checked != value) {
      const data = {};
      data[`${props.attrName}`] = value;

      tiptap.editor.commands.updateAttributes(
        props.element.node.type.name,
        data
      );
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        props.saveNodeData();
      }, 2000);

    }
  }, [value]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      setValue(props.element.node.attrs[`${props.attrName}`]);
    }
  }, [props.element]);

  return (
    <Stack mt={20}>
      <Textarea
        autosize
        maxRows={5}
        placeholder={props.placeholder}
        label={props.label}
        onChange={(event) => setValue(event.currentTarget.value)}
      >
        {props.attrLabel}
      </Textarea>
    </Stack>
  );
}
