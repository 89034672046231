import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SpaceModel from "../../../../models/Space";

import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
}));

export default function Gateway(props) {
  const navigate = useNavigate();

  const colors = useSelector((state) => state.colors);

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    // debugger;
    b.style.display = "none";

    return () => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
    };
  }, []);

  useEffect(() => {
    if (props.space && props.space.slug) {
      SpaceModel.onGateway(
        props.space.slug,
        (data) => {
          localStorage.setItem("gateway_slug", props.space.slug);
          navigate(data.redirect_to.url);
        },
        (data) => {}
      );
    }
  }, [props]);

  return <p></p>;
}
