import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  ScrollArea,
  Stack,
  TextInput,
  Box,
} from "@mantine/core";
import SpaceModel from "../../../models/Space";

import { useForm } from "@mantine/form";

import { useTranslation } from "react-i18next";

import { useFocusTrap } from "@mantine/hooks";

import PremiumModal from "../../app/PremiumModal";

let regexSutraLink = /^(http|https):\/\/([\w-]+\.)+[\w-]+\/space\/[\w-]+\/?.*$/;
let regexSutraLink2 = /^(http|https):\/\/([a-zA-Z0-9]+)\.sutra\.co\/?.*$/;


export default function Form(props) {
  const [opened, setOpened] = useState(false);
  const space = useSelector((state) => state.space);
  const newSpaceLoading = useRef(false);
  const [canLink, setCanLink] = useState(true)

  const { t, i18n } = useTranslation();

  const focusTrapRef = useFocusTrap();

  const form = useForm({
    initialValues: {
      link: "",
    },

    validate: {
      link: (value) =>
        regexSutraLink.test(value) || regexSutraLink2.test(value) ? null : t("spaces.form.linkForm.linkErrMessage"),
    },
  });

  useEffect(() => {
    return () => {
      newSpaceLoading.current = false;
    };
  }, []);

  useEffect(() => {
    setCanLink(true)
  }, [form.values.link]);

  function extractSlug() {
    let parts = []
    if (regexSutraLink.test(form.values.link)){
      parts = form.values.link.split("/");
      return parts[4];
    }
    else{
      parts = form.values.link.replace("https://", "").replace("http://", "").split(".");
      return parts[0];
    }
  }

  const onSave = () => {


    SpaceModel.onCanBeLinked(
      extractSlug(),
      props.parentId,
      (data) => {
        setCanLink(data.can_be_linked)
        if (data.can_be_linked) {
          onLink();
        }
      },
      (data) => {

      }
    );
  };

  const onLink = () => {

    if (newSpaceLoading.current) {
      return;
    }
    newSpaceLoading.current = true;

    SpaceModel.onLink(
      extractSlug(),
      props.parentId,
      (data) => {
        props.onSave(data.slug, data.link);

        setTimeout(() => {
          newSpaceLoading.current = false;
        }, 2000);
      },
      (data) => {

        setTimeout(() => {
          newSpaceLoading.current = false;
        }, 500);
      }
    );
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <form
        onSubmit={form.onSubmit((values) => onSave(values))}
        ref={focusTrapRef}
      >
        <Stack>
          <TextInput
            {...form.getInputProps("link")}
            size="lg"
            radius="md"
            placeholder={t("spaces.form.linkForm.link.placeholder")}
            data-autofocus
            styles={{
              input: {
                fontWeight: "bold",
              },
            }}
          />
          {!canLink && (
            <Box
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
              textAlign: 'center',
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
              cursor: 'pointer',

              '&:hover': {
                backgroundColor:
                  theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
              },
            })}
          >
            {space.version != "events" ? t("spaces.form.linkForm.link.errors.space") : t("spaces.form.linkForm.link.errors.event")}

          </Box>
          )}
          <Button radius="xl" size="md" mt={10} type="submit" disabled={!canLink}>
            {`${props.actionButtonText}`}
          </Button>
        </Stack>
      </form>
      <PremiumModal
        opened={opened}
        setOpened={setOpened}
        onUpgrade={props.onSave}
      />
    </ScrollArea>
  );
}
