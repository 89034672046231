import OrderedList from "@tiptap/extension-ordered-list";
import { mergeAttributes } from "@tiptap/core";
import { checkInteractionAndHideIfNeeded, setUniqId} from "../utils/selection";
import { wideOrNarrow } from "../../../utils/PageWidth";

const CustomOrderedList = OrderedList.extend({
  group: "block list columnable",
  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("section");
      const content = document.createElement("ol");
      content.style.maxWidth = wideOrNarrow();

      const attributes = mergeAttributes(HTMLAttributes, {});
      content.setAttribute("id", attributes["data-uid"]);
      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );
      dom.append(content);

      checkInteractionAndHideIfNeeded(editor, dom, getPos);
      //setUniqId(editor, node, getPos, dom);

      return {
        dom,
        contentDOM: content,
      };
    };
  },
});

export default CustomOrderedList;
