import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { stylesToString, fixStringToJSON } from "../../../../utils/StylesToString";
import colorPickerSwatches from "../../../../utils/ColorSwatches";

import tinycolor from 'tinycolor2'
import ColorPicker from 'react-best-gradient-color-picker'

import {
  Stack,
  Group,
  Popover,
  Text,
  Button,
  createStyles,
  MantineProvider,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  
}));

export default function BackgroundColor(props) {

  const loading = useRef(false)
  const { t, i18n } = useTranslation();
  const colors = useSelector(state => state.colors);
  const [color, setColor] = useState('rgba(255,255,255,1)');
  const [bgColorOpened, setBgColorOpened] = useState(false)

  const { cx, theme, classes } = useStyles();

  useEffect(() => {

    if (!props.element || !props.element.node.attrs || (props.element && props.element.node.attrs && loading.current != props.element.node.attrs.uid)){
      return
    }

    const node = props.element

    let newStyles = node.node.attrs.styles
      ? { ...node.node.attrs.styles }
      : null;
    if (newStyles) {
      const stylesKeys = Object.keys(newStyles);
      if (stylesKeys && stylesKeys[0] == "0") {
        console.log(newStyles);
        console.log(stylesToString(newStyles));
        newStyles = fixStringToJSON(stylesToString(newStyles));
        console.log(node.node.type);
        console.log(newStyles);
        props.saveNodeData({ newStyles, _node: node }, "styles");
        return;
      }
    }

    // newStyles['background-color'] = color
    if (color && color.indexOf("linear-gradient") >= 0){
      newStyles["background-image"] = color
      newStyles["background-color"] = ""
    }
    else{
      newStyles["background-color"] = color
      newStyles["background-image"] = "none"
    }

    if (newStyles) {
      props.saveNodeData({ newStyles, _node: node }, "styles")
    }
  }, [color])

  const onRemoveBackgroundColor = (event) => {
    setColor("")
  }

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles){
      loading.current = props.element.node.attrs.uid;

      if (props.element.node.attrs.styles["background-image"] && props.element.node.attrs.styles["background-image"].indexOf("linear-gradient") >= 0){
        setColor(props.element.node.attrs.styles["background-image"])
      }
      else{
        setColor(props.element.node.attrs.styles["background-color"])
      }
    }
  }, [props.element])

  return (

    <Stack>
      <Popover
        opened={bgColorOpened}
        onClose={() => setBgColorOpened(false)}
        target={
          <Group
            position="apart"
            >
            <Text size="sm">
            {t("tiptap.components.sidebar.backgroundColor.text")}
            </Text>
            {color && color.indexOf("linear-gradient") >= 0 ? (
              <Button
                key={color}
                radius="xl"
                onClick={() => setBgColorOpened((o) => !o)}
                sx={(theme)=>({
                  background: color,
                  color: colors.default_text_color,
                  border: `1px solid ${theme.colors.gray[6]}`,
                  width: 70,
                  // padding: 10,
                  "&:hover": {
                    backgroundColor: tinycolor(color).darken(5).toString()
                  }
                })}
                >
              </Button>
            ) : (
              <Button
                key={color}
                radius="xl"
                onClick={() => setBgColorOpened((o) => !o)}
                sx={(theme)=>({
                  backgroundColor: color == "" || color == "none" ? "white" : color,
                  color: colors.default_text_color,
                  border: `1px solid ${theme.colors.gray[6]}`,
                  width: 70,
                  // padding: 10,
                  "&:hover": {
                    backgroundColor: tinycolor(color).darken(5).toString()
                  }
                })}
                >
              </Button>
            )}

          </Group>
        }
        gutter={15}
        zIndex={999999}
        width={326}
        >
        <div
          className="gradient-color-picker">
          <ColorPicker
            value={color}
            onChange={setColor}
            hideAdvancedSliders
            hideColorGuide
            hideInputType
            presets={colorPickerSwatches(theme, "light")}
            />
        </div>
      </Popover>
      <MantineProvider
        theme={{ primaryShade: 6 }}
        >
        <Button
          radius="xl"
          variant="outline"
          color="dark"
          onClick={event => onRemoveBackgroundColor(event)}>
          {t("tiptap.components.sidebar.backgroundColor.clearColor")}
        </Button>
      </MantineProvider>
    </Stack>
  )
}
