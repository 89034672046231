import React, { useState, useEffect, useRef } from "react";
import SpaceNeo4jModel from "../../models/neo4j/Space";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CirclePack from "circlepack-chart";
import {
  Button,
  createStyles,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  
}));

export default function TreeView(props) {
  const space = useSelector((state) => state.space);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { classes, theme } = useStyles();
  const chart = useRef()
  const [selectedNode, setSelectedNode] = useState(null)

  useEffect(() => {
    onLoadTree();
  }, []);

  const swatches = [
    '#FFD700', // Pastel Yellow
    '#FF9AA2', // Pastel Salmon
    '#9AC8EB', // Pastel Sky Blue
    '#9EFFA3', // Pastel Mint Green
    '#B0E0E6', // Pastel Powder Blue
    '#FFC0CB', // Pastel Pink
    '#FFDEAD', // Pastel Peach
    '#D8BFD8', // Pastel Thistle
    '#AFEEEE', // Pastel Turquoise
    '#FFA07A', // Pastel Light Salmon
    '#40E0D0', // Pastel Turquoise
    '#00CED1', // Pastel Dark Turquoise
    '#FFE4B5', // Pastel Moccasin
    '#98FB98', // Pastel Pale Green
];
// Now you can use the 'swatches' array in your code as needed.


  const onNodeClick = (node) => {
    setSelectedNode(node);
    props.setTreeViewSelectedNode({...node})
    chart.current.zoomToNode(node)
  }

  

  function setNodeValues(tree, level = 1) {
    const newTree = {
      name: tree.name,
      value: level,
      color: swatches[level - 1] || theme.other.tagColors[13].backgroundColor,
      children: tree.parent_of ? [...tree.parent_of] : [],
      slug: tree.slug,
      version: tree.version
    }
    

    if (newTree.children) {
        for (let i = 0; i < newTree.children.length; i ++){
          let child = newTree.children[i];
          
          if (child){
            newTree.children[i] = setNodeValues(child, level + 1);
          }
        }
    }
    return newTree
}

  const onLoadTree = () => {
    SpaceNeo4jModel.onFetchTree(
      window.$fetchedTopSlug,
      (data) => {
        
        const newTree = setNodeValues(data.data.tree);
        
        setTimeout(() => {
          chart.current = CirclePack().data(newTree).color('color').onClick((node) => onNodeClick(node))(
            document.getElementById("tree-chart")
          );
        }, 500);
      },
      (data) => {}
    );
  };

  return (
    <>
    
    <div id="tree-chart"></div>
    </>
  );
}
