/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import ReactOnRails from 'react-on-rails';
// import 'jquery-ujs';
// import { addLocaleData } from 'react-intl'; // DO we need locale support?
// import MenuSidebar from '../bundles/MenuSidebar/components/MenuSidebar';
// import RightSidebar from '../bundles/RightSidebar/components/RightSidebar';
// import CircleTopNav from '../bundles/CircleTopNav/components/CircleTopNav';
// import HeaderImage from '../bundles/CircleHeader/components/HeaderImage';
// import RouterApp from '../bundles/startup/RouterApp';
// import appStore from '../bundles/store/defaultStore';
// import Logo from '../bundles/Header/components/Logo';
// import Subheader from '../bundles/Subheader/components/Subheader';
// import Breadcrumbs from '../bundles/Breadcrumbs/components/Breadcrumbs';
// import EditorJS from '../bundles/EditorJS/components/Editor';
// import Settings from '../bundles/EditorJS/components/Settings';
// import Modal from '../bundles/Modal/components/Modal';
// import Notifications from '../bundles/Header/components/Notifications';
import Index from '../bundles/v2/components/Index';

ReactOnRails.register({
  // MenuSidebar,
  // CircleTopNav,
  // RightSidebar,
  // HeaderImage,
  // RouterApp,
  // EditorJS,
  // Breadcrumbs,
  // Subheader,
  // Settings,
  // Logo,
  // Modal,
  // Notifications,
  Index
});

// ReactOnRails.registerStore({
//   appStore
// });
