import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  Box,
  Text,
  TextInput,
  Switch,
  Select,
  Loader,
  createStyles,
  Button,
  MultiSelect,
} from "@mantine/core";

import { DatePicker, TimeInput } from "@mantine/dates";

import { sutraTheme } from "../../../../collections/sutraTheme";

import BundleModel from "../../../../models/Bundle";

import TiptapInline from "../../../tiptap/components/TiptapInline";

import SpaceModel from "../../../../models/Space";

import Payment from "../privacy/Paid";

import { useForm } from "@mantine/form";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    // borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    width: "100%",
  },
}));

export default function BundlesForm(props) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();

  const [disableActions, setDisableActions] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState({ content: "" });
  const [parentCircles, setParentCircles] = useState([]);

  const [loading, setLoading] = useState(true);
  const [spaces, setSpaces] = useState([]);
  const [searching, setSearching] = useState(false);
  const [showInCheckout, setShowInCheckout] = useState(false);

  const space = useSelector((state) => state.space);

  const form = useForm({
    initialValues: {
      name: "",
      amount: "",
      frequency: "",
      description: "",
      default_payment_amount: "",
      default_payment_frequency: "",
      currency: "",
      subscription_type: "",
      until_date: "",
      number_of_times: "",
      space_bundle_pods_ids: [],
      redirect_to_space: "",
      trial_period: "",
    },

    validate: {
      // description: (value) => (value.length > 0 ? null : "Add some content"),
      name: (value) => (value.length > 0 ? null : "Add a name"),
      // frequency: (value) => (value.length > 0 ? null : "Select a duration"),
      // default_payment_amount: (value) =>
      //   value.length > 0 ? null : "Add a price",
      space_bundle_pods_ids: (value) =>
        value.length > 0 ? null : "Select some spaces",
      redirect_to_space: (value) =>
        value.length > 0 ? null : "Select a space to redirect",
    },
  });

  const [settings, setSettings] = useState({
    slug: "",
    can_accept_charges: true,
    circle_payment: "paid",
    payment_settings: {
      default_payment_amount: "",
      default_payment_frequency: "",
      currency: "",
      subscription_type: "",
      until_date: "",
      number_of_times: "",
      show_in_checkout: false,
      trial_period: ""
    },
  });

  const selectedParents = useRef([]);
  const searchTimeoutId = useRef();

  useEffect(() => {
    onResetForm();

    if (props.bundle && props.bundle.id) {
      onFetch();
    } else {
      setLoading(false);
    }
  }, [props]);

  useEffect(() => {
    if (!props.bundle.id && space.slug) {
      setSpaces([{ label: space.name, value: space.slug }]);
      setParentCircles([space.slug]);
      form.setFieldValue("space_bundle_pods_ids", [space.slug]);
      form.setFieldValue("redirect_to_space", space.slug);
      setSettings({
        slug: "",
        can_accept_charges: true,
        circle_payment: "paid",
        fee: space.fee,
        payment_settings: {
          default_payment_amount: "",
          default_payment_frequency: "",
          currency: "",
          subscription_type: "",
          until_date: "",
          number_of_times: "",
          show_in_checkout: false,
          trial_period: "",
        },
      });
    }
  }, [space]);

  useEffect(() => {
    onSelectedParents(form.values.space_bundle_pods_ids);
  }, [form.values.space_bundle_pods_ids]);

  useEffect(() => {
    form.setFieldValue("show_in_checkout", showInCheckout);
  }, [showInCheckout]);

  const onResetForm = () => {
    form.setFieldValue("name", "");
    form.setFieldValue("description", "");
    form.setFieldValue("frequency", "");
    form.setFieldValue("default_payment_amount", "");
    form.setFieldValue("default_payment_frequency", "");
    form.setFieldValue("currency", "");
    form.setFieldValue("subscription_type", "");
    form.setFieldValue("until_date", "");
    form.setFieldValue("number_of_times", "");
    form.setFieldValue("circle_payment", "paid");
    form.setFieldValue("space_bundle_pods_ids", []);
    form.setFieldValue("show_in_checkout", false);
    form.setFieldValue("trial_period", "");
  };

  const onFetch = () => {
    setLoading(true);
    BundleModel.onFetchById(
      space.slug,
      props.bundle.id,
      (data) => {
        form.setFieldValue("name", data.name);
        form.setFieldValue("description", data.description);
        form.setFieldValue("frequency", data.frequency);
        form.setFieldValue(
          "default_payment_amount",
          data.default_payment_amount
        );
        form.setFieldValue(
          "default_payment_frequency",
          data.default_payment_frequency
        );
        form.setFieldValue("currency", data.currency);
        form.setFieldValue("subscription_type", data.subscription_type);
        form.setFieldValue("until_date", data.until_date);
        form.setFieldValue("number_of_times", data.number_of_times);
        form.setFieldValue("circle_payment", data.circle_payment);
        form.setFieldValue("trial_period", data.trial_period);

        setShowInCheckout(data.show_in_checkout ? true : false);

        form.setFieldValue("space_bundle_pods_ids", [
          ...data.space_bundle_pods_ids,
        ]);
        form.setFieldValue("redirect_to_space", data.redirect_to_space);
        setSpaces([...data.space_bundle_pods]);
        setParentCircles([...data.space_bundle_pods_ids]);
        selectedParents.current = [...data.space_bundle_pods];

        setDescription(data.description);

        setSettings({
          slug: space.slug,
          can_accept_charges: true,
          circle_payment: data.circle_payment,
          fee: space.fee,
          payment_settings: {
            default_payment_amount: data.default_payment_amount,
            default_payment_frequency: data.default_payment_frequency,
            currency: data.currency,
            subscription_type: data.subscription_type,
            until_date: data.until_date,
            number_of_times: data.number_of_times,
            trial_period: data.trial_period,
          },
        });
        setLoading(false);
      },
      (data) => {}
    );
  };

  const onSave = () => {
    const params = {
      ...form.values,
    };

    if (!props.bundle.id) {
      BundleModel.onCreate(
        space.slug,
        params,
        (data) => {
          props.onUpdate(data.id);
        },
        (data) => {}
      );
    } else {
      BundleModel.onUpdate(
        space.slug,
        props.bundle.id,
        params,
        (data) => {
          props.onUpdate(data.id);
        },
        (data) => {}
      );
    }
  };

  const onSelectedParents = (selection) => {
    setParentCircles(selection);
    selectedParents.current = [];
    for (let i = 0; i < spaces.length; i++) {
      if (selection.indexOf(spaces[i].value) >= 0) {
        selectedParents.current.push(spaces[i]);
      }
    }
  };

  const onSearchSpaces = (query) => {
    setSearching(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query);
      setSearching(false);
    }, 1000);
  };

  const onFetchSpaces = (query = null) => {
    const include = ["members", "simple"];
    if (query) {
      include.push(`q=${query}`);
    }
    SpaceModel.onFetchManaged(
      null,
      include,
      { page: 1, per: 10 },
      (data) => {
        const _spaces = [...selectedParents.current];
        for (let i = 0; i < data.length; i++) {
          _spaces.push({
            value: data[i].slug,
            label: `${data[i].name} (${data[i].slug})`,
          });
        }

        setSpaces(_spaces);
      },
      (data) => {}
    );
  };

  const onContentUpdate = (content, html, callback, isEmpty) => {
    form.setFieldValue("description", content);
  };

  const onUpdatePayment = (params) => {
    if (!params.pod_payment_settings_attributes) {
      return;
    }

    const auxSettings = { ...settings };

    auxSettings.payment_settings.currency =
      params.pod_payment_settings_attributes.currency;
    auxSettings.payment_settings.default_payment_amount =
      params.pod_payment_settings_attributes.default_payment_amount;
    auxSettings.payment_settings.default_payment_frequency =
      params.pod_payment_settings_attributes.default_payment_frequency;
    auxSettings.payment_settings.subscription_type =
      params.pod_payment_settings_attributes.subscription_type;
    auxSettings.payment_settings.until_date =
      params.pod_payment_settings_attributes.until_date;
    auxSettings.payment_settings.number_of_times =
      params.pod_payment_settings_attributes.number_of_times;
    auxSettings.payment_settings.trial_period =
      params.pod_payment_settings_attributes.trial_period;

    if (params.circle_payment) {
      form.setFieldValue("circle_payment", params.circle_payment);
    }
    if (params.pod_payment_settings_attributes.frequency) {
      form.setFieldValue(
        "frequency",
        params.pod_payment_settings_attributes.frequency
      );
    }
    if (params.pod_payment_settings_attributes.default_payment_amount) {
      form.setFieldValue(
        "default_payment_amount",
        params.pod_payment_settings_attributes.default_payment_amount
      );
    }
    if (params.pod_payment_settings_attributes.default_payment_frequency) {
      form.setFieldValue(
        "default_payment_frequency",
        params.pod_payment_settings_attributes.default_payment_frequency
      );
    }
    if (params.pod_payment_settings_attributes.currency) {
      form.setFieldValue(
        "currency",
        params.pod_payment_settings_attributes.currency
      );
    }
    if (params.pod_payment_settings_attributes.subscription_type) {
      form.setFieldValue(
        "subscription_type",
        params.pod_payment_settings_attributes.subscription_type
      );
    }
    if (params.pod_payment_settings_attributes.until_date) {
      form.setFieldValue(
        "until_date",
        params.pod_payment_settings_attributes.until_date
      );
    }
    if (params.pod_payment_settings_attributes.number_of_times) {
      form.setFieldValue(
        "number_of_times",
        params.pod_payment_settings_attributes.number_of_times
      );
    }
    if (params.pod_payment_settings_attributes.trial_period) {
      form.setFieldValue(
        "trial_period",
        params.pod_payment_settings_attributes.trial_period
      );
    }

    setSettings(auxSettings);
  };

  const onSelectedRedirectSpace = () => {};

  return (
    <form onSubmit={form.onSubmit((values) => onSave())}>
      <Box>
        <Box className={classes.item}>
          <TextInput
            {...form.getInputProps("name")}
            label="Name"
            mt={10}
            className={classes.input}
            disabled={disableActions}
          />
        </Box>
        <Box className={classes.item}>
          <MultiSelect
            label="Select spaces"
            description={
              "Select the spaces that will be included in your bundle.  The person registering will be added as a member to each space."
            }
            icon={
              searching ? (
                <Loader size="xs" />
              ) : (
                <FontAwesomeIcon icon={solid("search")} />
              )
            }
            disabled={disableActions}
            searchable
            limit={100}
            mt={5}
            data={spaces}
            // onChange={(selection) => onSelectedParents(selection)}
            placeholder={"Select spaces to add"}
            // value={parentCircles}
            onSearchChange={(query) => onSearchSpaces(query)}
            {...form.getInputProps("space_bundle_pods_ids")}
            zIndex={99999}
          />
        </Box>
        <Box className={classes.item}>
          <Select
            label="Landing page"
            disabled={disableActions}
            searchable
            limit={100}
            mt={5}
            data={selectedParents.current}
            description={
              "This is the space where people will land after payment is completed."
            }
            // onChange={(selection) => onSelectedRedirectSpace(selection)}
            // value={redirectSpace}
            {...form.getInputProps("redirect_to_space")}
            zIndex={99999}
          />
        </Box>
        {!loading && (
          <Box className={classes.item}>
            <Text size="sm" style={{ color: "#212529" }}>
              Description
            </Text>
            <Text color="dimmed" size="xs" mb={5}>
              Please add a description of your bundle that will be visible to
              people when they are paying.
            </Text>
            <TiptapInline
              editable={true}
              content={description && description.content}
              modals={true}
              onUpdate={onContentUpdate}
              exclude={["link-action", "link-format"]}
            />
          </Box>
        )}
        <Box className={classes.item}>
          <Switch
            label="Show bundle as an option on checkout page"
            checked={showInCheckout}
            onChange={(event) => setShowInCheckout(event.currentTarget.checked)}
          />
          <Text color="dimmed" size="xs" mb={5} mt={5}>
            If you select this option, this bundle will be visible on the
            payment page for this space as an additional purchase option.
          </Text>
        </Box>
        {!loading && (
          <Box className={classes.item}>
            <Text size="sm" style={{ color: "#212529" }}>
              Payment
            </Text>

            <Payment
              settings={settings}
              // updateAll={true}
              onUpdate={onUpdatePayment}
              space={space}
              options={["paid", "recurring"]}
              inner={true}
            />
          </Box>
        )}
        <Box>
          <Button
            variant="filled"
            radius="xl"
            type="submit"
            sx={{
              backgroundColor: sutraTheme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: sutraTheme.colors.sutrablue[4],
              },
            }}
          >
            {!props.bundle ? "Create bundle" : "Update bundle"}
          </Button>
        </Box>
        <Box sx={{ height: "200px" }}></Box>
      </Box>
    </form>
  );
}
