import axios from 'axios';
import Broadcaster from '../helpers/Broadcaster.js';

class SubscriptionModel {
  constructor() {
    if (this instanceof SubscriptionModel) {
      throw Error('SubscriptionModel class cannot be instantiated.');
    }
  }

  static onFetch = async (filters, page, callback) => {

    const params = {
      auth_token: window.$currentUserAuthToken,
      page: page,
      term: filters.term,
      parent: filters.parent
    };

    await axios.get(`/api/v2/subscriptions`, {params: params})
    .then(res => {
      callback(res.data)
    })
    .catch((error) => {
    });
  }

  static onUpdateFrequency = async (circleId, frequency, callback) => {

    const params = {
      auth_token: window.$currentUserAuthToken,
      frequency: frequency
    };

    await axios.put(`/api/v2/subscriptions/${circleId}/frequency`, params)
    .then(res => {
      callback(res.data)
    })
    .catch((error) => {
    });
  }

  static onLeave = async (circleId, callback) => {

    const params = {
      auth_token: window.$currentUserAuthToken
    };

    await axios.delete(`/api/v2/subscriptions/${circleId}/leave`, {params: params})
    .then(res => {
      callback(res.data)
    })
    .catch((error) => {
    });
  }
}

export default SubscriptionModel;
