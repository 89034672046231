import { useState } from 'react';

export default function useLangUser() {
  const getLangUser = () => {
    const locale = localStorage.getItem('locale');
    if (locale){
      window.$currentUserLanguage = locale ? locale : "en";
    }
    return locale ? locale : "en"
  };

  const [language, setLanguage] = useState(getLangUser());

  const saveLangUser = lang => {
    localStorage.setItem('locale', lang);
    window.$currentUserLanguage = lang;
    setLanguage(lang);
  };

  return {
    setLangUser: saveLangUser,
    language
  }
}
