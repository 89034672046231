import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import AuthModel from "../../models/Auth";
import useAuthUser from "../../utils/AuthUser";
import { setAlert, setHiddenSidebar } from "../../store/app";
import { useTranslation } from "react-i18next";

import {
  TextInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Box,
  Divider,
  ScrollArea,
} from "@mantine/core";

import { Helmet } from "react-helmet";

export default function Recover(props) {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { setAuthUser } = useAuthUser();

  const [form, setForm] = useState({
    user: {
      email: "",
    },
  });

  const [dialog, setDialog] = useState({
    opened: false,
    title: "",
    message: "",
    color: "indigo",
  });

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 250);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  const onRecover = () => {
    const email = form.user.email.trim();
    AuthModel.onRecoverPasswordInstructions(
      email,
      (data) => {
        // Success callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onInputChange = (event, field) => {
    form.user[field] = event.target.value;
    setForm({ ...form });
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{`Sutra - Recover password`}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }} size={500} my={40}>
        <Paper withBorder shadow="md" p={0} mt={30} radius="md">
          <Box pt={20} pb={5} pl={35} pr={35}>
            <Title sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}>
              {t("users.recover.title")}
            </Title>
            <Text color="dimmed" size="md">
              {t("users.recover.subtitle")}
            </Text>
          </Box>
          <Divider
            sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
            my="sm"
          />
          <Box pt={10} pb={25} pl={35} pr={35}>
            <TextInput
              value={form.user.email}
              onChange={(event) => onInputChange(event, "email")}
              label={t("users.recover.email")}
              radius="sm"
              size="md"
              mb={15}
            />
            <Button
              fullWidth
              mt="xl"
              radius="xl"
              size="md"
              mb={15}
              onClick={(event) => onRecover()}
            >
              {t("users.recover.submit")}
            </Button>
            <Text color="dimmed" size="md" align="center">
              {t("users.recover.footer")}{" "}
              <Link to="/user/sign_in" size="sm">
                {t("users.recover.footerLink")}
              </Link>
            </Text>
          </Box>
        </Paper>
      </Container>
    </ScrollArea>
  );
}
