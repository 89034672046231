import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';


import { AlertCircle } from 'tabler-icons-react';

import {
  Dialog,
  Alert,
  MantineProvider
} from '@mantine/core';

export default function SystemAlert(){
  const location = useLocation();

  const alert = useSelector(state => state.alert);

  const [dialog, setDialog] = useState({opened: false, title: "", message: "", color: "indigo"})

  useEffect(() => {
    setDialog({...alert})
    setTimeout(() => {
      setDialog({...alert, opened: false})
    }, 5000)
  }, [alert])

  useEffect(() => {
    const queryParams = location.search
    const title = new URLSearchParams(queryParams).get("at")
    const message = new URLSearchParams(queryParams).get("am")
    const color = new URLSearchParams(queryParams).get("ac")
    if (message){
      setDialog({opened: true, title: title ? title : "Alert", message, color: color ? color : "indigo"})
      setTimeout(() => {
        setDialog({...alert, opened: false})
      }, 5000)
    }
  }, [location])

  return (
    <MantineProvider
      theme={{ primaryShade: 8 }}
    >
      <Dialog
        sx={(theme) => ({ backgroundColor: dialog.color })}
        opened={dialog.opened}
        withBorder
        shadow="md"
        size="lg"
        radius="md"
        p={0}
        position={{ top: 30, right: 30 }}
        >
        <Alert
          icon={<AlertCircle size={16} />}
          title={dialog.title}
          color={dialog.color}
          withCloseButton
          onClose={() => setDialog({...dialog, opened: false})}
        >
          {dialog.message}
        </Alert>
      </Dialog>
    </MantineProvider>

  )

}
