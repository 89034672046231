import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Modal } from '@mantine/core';

import Form from "./form/Form"



const NewSpace = (props) => {

  const navigate = useNavigate()

  const onSave = (spaceId=null, spaceLink=null, spaceName=null) => {

    props.setOpened(false)
    if (spaceLink){
      navigate(spaceLink)
    }
  }

  return (
    <Modal
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      withCloseButton={false}
      >
      <Form
        parentId={null}
        onSave={onSave}
        version={"content"}
        isRoot={true}
        duplicateFrom={props.duplicateFrom}
        onDuplicate={props.onDuplicate}/>

    </Modal>
  )
}

export default NewSpace
