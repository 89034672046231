import React, { useRef, useState, useEffect } from "react";

import {
  Select,
  Text,
  Switch,
  Group,
  MultiSelect,
  Box,
  ActionIcon,
  NumberInput,
  createStyles,
  Loader,
} from "@mantine/core";

import { Plus, Minus, ListDetails } from "tabler-icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import SpaceModel from "../../../../models/Space";

import PremiumModal from "../../../app/PremiumModal";

import Swal from "sweetalert2";

import { colors } from "../../../../collections/colors";

import Tag from "../../presentations/lists/Tag";
import Tag2 from "../../presentations/discussion/Tag";

import TagModel from "../../../../models/Tag";
import SpaceTagModel from "../../../../models/SpaceTag";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    textAlign: "center",
    paddingRight: `${theme.spacing.sm}px !important`,
    paddingLeft: `${theme.spacing.sm}px !important`,
    height: 28,
    flex: 1,
  },
}));

const podSizeMin = 0;
const podSizeMax = 1000;

export default function Additional({ onUpdate }) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const space = useSelector((state) => state.space);

  const [spaces, setSpaces] = useState([]);
  const [children, setChildren] = useState([]);
  const [resourceEditing, setResourceEditing] = useState(false);
  const [circleCreation, setCircleCreation] = useState(false);
  const [circleCreationTypes, setCircleCreationTypes] = useState([]);
  const [allowMemberCopy, setAllowMemberCopy] = useState(false);
  const [podType, setPodType] = useState(false);
  const [allowReflections, setAllowReflections] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [hideMemberEmails, setHideMemberEmails] = useState(false);
  const [newMemberNotifications, setNewMemberNotifications] = useState(false);
  const [parentCircles, setParentCircles] = useState([]);
  const [podSize, setPodSize] = useState(false);
  const [podSizeValue, setPodSizeValue] = useState(0);
  const [sidebarDefaultOpened, setSidebarDefaultOpened] = useState(false);
  const [sidebarHideChildren, setSidebarHideChildren] = useState(false);
  const [allowCompletions, setAllowCompletions] = useState(false);
  const [showMarkCompleteButtonInHeader, setShowMarkCompleteButtonInHeader] =
    useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searching2, setSearching2] = useState(false);
  const [redirectToSpace, setRedirectToSpace] = useState("");
  const [listAutoTag, setListAutoTag] = useState(false);
  const [allowMembersToCreateTags, setAllowMembersToCreateTags] =
    useState(false);

  const [canActivateCompletions, setCanActivateCompletions] = useState(false);
  const [allowComments, setAllowComments] = useState(false);
  const [opened, setOpened] = useState(false);

  const [tags, setTags] = useState([]);

  const handlers = useRef();
  const propsLoaded = useRef();
  const lastSpaceId = useRef();
  const searchTimeoutId = useRef();
  const selectedParents = useRef([]);
  const originalParents = useRef([]);

  useEffect(() => {
    return () => {
      propsLoaded.current = false;
    };
  }, []);

  useEffect(() => {
    if (!space.settings) {
      return;
    }

    propsLoaded.current = false;
    // setDisableActions(true);

    setResourceEditing(space.resource_editing == "open");
    setCircleCreation(space.circle_creation ? true : false);
    setCircleCreationTypes(
      space.circle_creation ? space.circle_creation.split(",") : []
    );

    setShowMembers(space.show_members);
    setHideMemberEmails(space.settings.hide_member_emails);

    setAllowMemberCopy(space.settings.allow_member_copy);
    setPodType(space.pod_type == "standard" ? true : false);
    setAllowReflections(space.allow_reflections);

    setSpaces([...space.parents]);
    selectedParents.current = [...space.parents];
    originalParents.current = [...space.parents];

    setNewMemberNotifications(
      space.settings.new_member_notifications == "manager"
    );
    setPodSize(space.size ? true : false);
    setPodSizeValue(space.size ? parseInt(space.size) : space.size);
    setParentCircles(space.parents_slugs);
    setSidebarDefaultOpened(space.settings.sidebar_default_opened);
    setSidebarHideChildren(space.settings.sidebar_hide_children);
    setAllowCompletions(space.settings.allow_completions);
    setShowMarkCompleteButtonInHeader(
      space.settings.show_mark_complete_button_in_header
    );
    setRedirectToSpace(space.settings.redirect_to_space);
    setListAutoTag(space.settings.list_auto_tag);
    setAllowMembersToCreateTags(space.settings.allow_members_to_create_tags);
    setAllowComments(space.settings.allow_comments);

    setCanActivateCompletions(
      space && space.permissions && space.permissions.can_activate_completions
    );

    onFetchTags();

    setTimeout(() => {
      propsLoaded.current = true;
      // setDisableActions(false);
    }, 1000);
  }, [space]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ resource_editing: resourceEditing ? "open" : "closed" });
    }
  }, [resourceEditing]);

  useEffect(() => {
    if (propsLoaded.current && !circleCreation) {
      onUpdate({ circle_creation: "" });
    }
  }, [circleCreation]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ circle_creation: circleCreationTypes.join(",") });
    }
  }, [circleCreationTypes]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: { allow_member_copy: allowMemberCopy },
      });
    }
  }, [allowMemberCopy]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ pod_type: podType ? "standard" : "readonly" });
    }
  }, [podType]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ allow_reflections: allowReflections });
    }
  }, [allowReflections]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ show_members: showMembers });
    }
  }, [showMembers]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: { hide_member_emails: hideMemberEmails },
      });
    }
  }, [hideMemberEmails]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: {
          new_member_notifications: newMemberNotifications ? "manager" : "none",
        },
      });
    }
  }, [newMemberNotifications]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: {
          list_auto_tag: listAutoTag,
        },
      });
    }
  }, [listAutoTag]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: {
          allow_members_to_create_tags: allowMembersToCreateTags,
        },
      });
    }
  }, [allowMembersToCreateTags]);

  useEffect(() => {
    if (propsLoaded.current && !podSize) {
      onUpdate({ size: null });
    }
  }, [podSize]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ size: podSizeValue });
    }
  }, [podSizeValue]);

  useEffect(() => {
    const originalParentsSlugs = originalParents.current.map(
      (item) => item.value
    );
    if (
      propsLoaded.current &&
      !arraysAreEqual(parentCircles, originalParentsSlugs)
    ) {
      let message = `This space will become a child of: <p>${selectedParents.current
        .map((item) => `${item.label}`)
        .join("</p><p>")}</p></ul>`;

      if (selectedParents.current.length == 0) {
        message =
          "This action will make this space a <strong>top level</strong> space";
      }

      Swal.fire({
        title: "Are you sure?",
        html: message,
        showDenyButton: true,
        confirmButtonText: "Yes, update",
        denyButtonText: "Cancel",
        confirmButtonColor: colors.blue,
        denyButtonColor: colors.gray,
      }).then((result) => {
        if (result.isConfirmed) {
          onUpdate({ parent_circles: parentCircles });
          originalParents.current = [...selectedParents.current];
        } else {
          setParentCircles(originalParentsSlugs);
          selectedParents.current = [...originalParents.current];
        }
      });
    }
  }, [parentCircles]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: {
          sidebar_default_opened: sidebarDefaultOpened,
        },
      });
    }
  }, [sidebarDefaultOpened]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: { sidebar_hide_children: sidebarHideChildren },
      });
    }
  }, [sidebarHideChildren]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: { allow_comments: allowComments },
      });
    }
  }, [allowComments]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: { redirect_to_space: redirectToSpace },
      });
    }
  }, [redirectToSpace]);

  useEffect(() => {
    if (allowCompletions && !canActivateCompletions[0] && propsLoaded.current) {
      setAllowCompletions(false);
      setOpened([true, canActivateCompletions[1], canActivateCompletions[2]]);
      return;
    }

    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: { allow_completions: allowCompletions },
      });
    }
  }, [allowCompletions]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({
        pod_settings_attributes: {
          show_mark_complete_button_in_header: showMarkCompleteButtonInHeader,
        },
      });
    }
  }, [showMarkCompleteButtonInHeader]);

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false; // If the arrays have different lengths, they can't be the same.
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false; // If any elements don't match, the arrays are not equal.
      }
    }

    return true; // If no mismatches are found, the arrays are equal.
  };

  const onSearchSpaces = (query) => {
    setSearching(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query);
      setSearching(false);
    }, 1000);
  };

  const onSearchChildren = (query) => {
    setSearching2(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchChildren(query);
      setSearching2(false);
    }, 1000);
  };

  const onFetchSpaces = (query = null) => {
    const include = ["members", "simple"];
    if (query) {
      include.push(`q=${query}`);
    }
    SpaceModel.onFetchManaged(
      space.slug,
      include,
      { page: 1, per: 10 },
      (data) => {
        const _spaces = [...selectedParents.current];
        for (let i = 0; i < data.length; i++) {
          _spaces.push({
            value: data[i].slug,
            label: `${data[i].name} (${data[i].slug})`,
          });
        }
        setSpaces(_spaces);
      },
      (data) => {}
    );
  };

  const onFetchChildren = (query = null) => {
    const ctrl = new AbortController();
    SpaceModel.onFetchDirectChildren(
      space.slug,
      ["order=pods.created_at DESC", "abstract"],
      { page: 1, per: 10 },
      ["active"],
      (data) => {
        const _spaces = [...selectedParents.current];
        for (let i = 0; i < data.data.length; i++) {
          _spaces.push({
            value: data.data[i].slug,
            label: `${data.data[i].name} (${data.data[i].slug})`,
          });
        }
        setChildren(_spaces);
      },
      (data) => {},
      ctrl.signal
    );
  };

  const onSelectedParents = (selection) => {
    setParentCircles(selection);
    selectedParents.current = [];
    for (let i = 0; i < spaces.length; i++) {
      if (selection.indexOf(spaces[i].value) >= 0) {
        selectedParents.current.push(spaces[i]);
      }
    }
  };

  const onFetchTags = () => {
    if (space.version == "discussion" || space.version == "content") {
      TagModel.onFetch(
        space.slug,
        (data) => {
          setTags([...data]);
        },
        (data) => {}
      );
    } else {
      setTags([...space.tags]);
    }
  };

  const onTag = (action="") => {
    if (action == "delete"){
      onFetchTags()
    }
  };

  const onUpdated = () => {};

  return (
    <Box>
      {space && space.slug && space.version == "content" && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.allowMembersEdit")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.membersWillAddContent")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={resourceEditing}
            onChange={(event) =>
              setResourceEditing(event.currentTarget.checked)
            }
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      {space &&
        space.slug &&
        (space.version == "list" || space.version == "showcase") && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {t("spaces.settings.main.additional.allowMembersCreatePosts")}
              </Text>
              <Text className={classes.itemDescription}>
                {t("spaces.settings.main.additional.membersWillCreatePosts")}
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={resourceEditing}
              onChange={(event) =>
                setResourceEditing(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}
      {space && space.slug && space.version == "events" && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.allowMembersCreateEvents")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.membersWillCreateEvents")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={resourceEditing}
            onChange={(event) =>
              setResourceEditing(event.currentTarget.checked)
            }
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      {space && space.slug && space.version == "content" && false && (
        <Box className={classes.item}>
          <Group position="apart" noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {t(
                  "spaces.settings.main.additional.allowMembersCreateSubspaces"
                )}
              </Text>
              <Text className={classes.itemDescription}>
                {t(
                  "spaces.settings.main.additional.membersWillCreateSubspaces"
                )}
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={circleCreation}
              onChange={(event) =>
                setCircleCreation(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
          {circleCreation && (
            <Box className={classes.subOptionWrap}>
              <Box className={classes.subOptionItem}>
                <Text className={classes.itemTitle}>
                  {t("spaces.settings.main.additional.membersCanCreate")}:
                </Text>
                <MultiSelect
                  disabled={disableActions}
                  searchable
                  mt={5}
                  data={[
                    {
                      value: "discussion",
                      label: t(
                        "spaces.settings.main.additional.circleCreation.options.discussion"
                      ),
                    },
                    {
                      value: "content",
                      label: t(
                        "spaces.settings.main.additional.circleCreation.options.content"
                      ),
                    },
                    {
                      value: "event",
                      label: t(
                        "spaces.settings.main.additional.circleCreation.options.event"
                      ),
                    },
                  ]}
                  placeholder={t(
                    "spaces.settings.main.additional.circleCreation.placeholder"
                  )}
                  value={circleCreationTypes}
                  onChange={(selection) => setCircleCreationTypes(selection)}
                />
              </Box>

              <Group position="apart" noWrap spacing="xl">
                <div>
                  <Text className={classes.itemTitle}>
                    {t(
                      "spaces.settings.main.additional.allowMembersToCopy.description1"
                    )}
                  </Text>
                  <Text className={classes.itemDescription}>
                    {t(
                      "spaces.settings.main.additional.allowMembersToCopy.description2"
                    )}
                  </Text>
                </div>
                <Switch
                  disabled={disableActions}
                  checked={allowMemberCopy}
                  onChange={(event) =>
                    setAllowMemberCopy(event.currentTarget.checked)
                  }
                  onLabel="ON"
                  offLabel="OFF"
                  className={classes.switch}
                  size="lg"
                />
              </Group>
            </Box>
          )}
        </Box>
      )}
      {space && space.slug && space.version == "discussion" && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.podType.description1")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.podType.description2")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={podType}
            onChange={(event) => setPodType(event.currentTarget.checked)}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      {space && space.slug && space.version == "discussion" && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t(
                "spaces.settings.main.additional.allowReflections.description1"
              )}
            </Text>
            <Text className={classes.itemDescription}>
              {t(
                "spaces.settings.main.additional.allowReflections.description2"
              )}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={allowReflections}
            onChange={(event) =>
              setAllowReflections(event.currentTarget.checked)
            }
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      <Box className={classes.item}>
        <Group position="apart" noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.showMembers.description1")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.showMembers.description2")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={showMembers}
            onChange={(event) => setShowMembers(event.currentTarget.checked)}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
        {showMembers ? (
          <Box className={classes.subOptionWrap}>
            <Group position="apart" noWrap spacing="xl">
              <div>
                <Text className={classes.itemTitle}>
                  {t(
                    "spaces.settings.main.additional.hideMmemberEmails.description1"
                  )}
                </Text>
                <Text className={classes.itemDescription}>
                  {t(
                    "spaces.settings.main.additional.hideMmemberEmails.description2"
                  )}
                </Text>
              </div>
              <Switch
                disabled={disableActions}
                checked={!hideMemberEmails}
                onChange={(event) =>
                  setHideMemberEmails(!event.currentTarget.checked)
                }
                onLabel="ON"
                offLabel="OFF"
                className={classes.switch}
                size="lg"
              />
            </Group>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Group position="apart" className={classes.item} noWrap spacing="xl">
        <div>
          <Text className={classes.itemTitle}>
            {t(
              "spaces.settings.main.additional.newMemberNotifications.description1"
            )}
          </Text>
          <Text className={classes.itemDescription}>
            {t(
              "spaces.settings.main.additional.newMemberNotifications.description2"
            )}
          </Text>
        </div>
        <Switch
          disabled={disableActions}
          checked={newMemberNotifications}
          onChange={(event) =>
            setNewMemberNotifications(event.currentTarget.checked)
          }
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
        />
      </Group>
      <Box className={classes.item}>
        <Group position="apart" noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.podSize.description1")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.podSize.description2")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={podSize}
            onChange={(event) => setPodSize(event.currentTarget.checked)}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
        {podSize ? (
          <Box className={classes.subOptionWrap}>
            <Text className={classes.itemDescription} mb={10}>
              {t("spaces.settings.main.additional.podSizeValue.description")}
            </Text>
            <div className={classes.wrapper}>
              <ActionIcon
                size={28}
                variant="transparent"
                onClick={() => handlers.current.decrement()}
                disabled={podSizeValue === podSizeMin}
                className={classes.control}
                onMouseDown={(event) => event.preventDefault()}
              >
                <Minus size={16} />
              </ActionIcon>

              <NumberInput
                disabled={disableActions}
                variant="unstyled"
                min={podSizeMin}
                max={podSizeMax}
                handlersRef={handlers}
                value={podSizeValue}
                onChange={setPodSizeValue}
                classNames={{ input: classes.input }}
              />

              <ActionIcon
                size={28}
                variant="transparent"
                onClick={() => handlers.current.increment()}
                disabled={podSizeValue === podSizeMax}
                className={classes.control}
                onMouseDown={(event) => event.preventDefault()}
              >
                <Plus size={16} />
              </ActionIcon>
            </div>
          </Box>
        ) : (
          ""
        )}
      </Box>
      {space &&
        space.slug &&
        (space.version == "content") && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {"Show comment thread"}
              </Text>
              <Text className={classes.itemDescription}>
                {"Display a comment thread at the bottom of this content page"}
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={allowComments}
              onChange={(event) =>
                setAllowComments(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}
      {space &&
        space.slug &&
        (space.version == "content" ||
          space.version == "list" ||
          space.version == "showcase" ||
          space.version == "events") &&
        (!space.permissions.is_top_level || space.pod_state == "model") && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {t(
                  "spaces.settings.main.additional.sidebarDefaultOpened.description1"
                )}
              </Text>
              <Text className={classes.itemDescription}>
                {t(
                  "spaces.settings.main.additional.sidebarDefaultOpened.description2"
                )}
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={sidebarDefaultOpened}
              onChange={(event) =>
                setSidebarDefaultOpened(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}
      {space &&
        space.slug &&
        (space.version == "content" ||
          space.version == "list" ||
          space.version == "showcase" ||
          space.version == "events") &&
        (!space.permissions.is_top_level || space.pod_state == "model") && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {t(
                  "spaces.settings.main.additional.sidebarHideChildren.description1"
                )}
              </Text>
              <Text className={classes.itemDescription}>
                {t(
                  "spaces.settings.main.additional.sidebarHideChildren.description2"
                )}
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={sidebarHideChildren}
              onChange={(event) =>
                setSidebarHideChildren(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}
      {/* {space && space.slug && (!space.permissions.is_top_level || space.pod_state == "model") && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t(
                "spaces.settings.main.additional.allowCompletions.description1"
              )}
            </Text>
            <Text className={classes.itemDescription}>
              {t(
                "spaces.settings.main.additional.allowCompletions.description2"
              )}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={allowCompletions}
            onChange={(event) =>
              setAllowCompletions(event.currentTarget.checked)
            }
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )} */}
      {space &&
        space.slug &&
        (!space.permissions.is_top_level || space.pod_state == "model") && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {t(
                  "spaces.settings.main.additional.allowCompletions.description1"
                )}
              </Text>
              <Text className={classes.itemDescription}>
                {t(
                  "spaces.settings.main.additional.allowCompletions.description2"
                )}
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={showMarkCompleteButtonInHeader}
              onChange={(event) =>
                setShowMarkCompleteButtonInHeader(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}

      {space &&
        space.slug &&
        (space.version == "discussion" ||
        space.version == "content" ||
          space.version == "list" ||
          space.version == "events" ||
          space.version == "showcase") && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {"Allow members to create their own tags"}
              </Text>
              <Text className={classes.itemDescription}>
                {
                  "If this setting is turned on members can create their own tags"
                }
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={allowMembersToCreateTags}
              onChange={(event) =>
                setAllowMembersToCreateTags(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}

      {space &&
        space.slug &&
        (space.version == "discussion" ||
          space.version == "content" ||
          space.version == "list" ||
          space.version == "events" ||
          space.version == "showcase") && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {"Automatically tag posts with AI"}
              </Text>
              <Text className={classes.itemDescription}>
                {
                  "If this setting is turned on, new posts will get automatically tagged using AI"
                }
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={listAutoTag}
              onChange={(event) => setListAutoTag(event.currentTarget.checked)}
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}

      {space &&
        space.slug &&
        (space.version == "discussion" ||
        space.version == "content" ||
          space.version == "list" ||
          space.version == "events" ||
          space.version == "showcase") && (
          <Box position="apart" className={classes.item} noWrap spacing="xl">
            <Text className={classes.itemTitle}>
              {"List of tags"}
            </Text>
            <Text className={classes.itemDescription}>
              {
                "Manage the tags that are available in this space"
              }
            </Text>

            {(space.version == "discussion" || space.version == "content") ? (
              <Tag2
                spaceId={space.slug}
                tags={tags}
                parentTags={tags}
                onSave={onTag}
                onUpdatedMessage={onTag}
                action="create"
                creatable={true}
              />
            ) : (
              <Tag
                spaceId={space.slug}
                tags={tags}
                parentTags={tags}
                onSave={onTag}
                onUpdatedMessage={onTag}
                action="create"
                creatable={true}
              />
            )}
          </Box>
        )}

      <Box className={classes.item}>
        <Text className={classes.itemTitle}>
          {t("spaces.settings.main.additional.redirectToSubspace.description1")}
        </Text>
        <Text className={classes.itemDescription}>
          {t("spaces.settings.main.additional.redirectToSubspace.description2")}
        </Text>
        <Select
          icon={
            searching2 ? (
              <Loader size="xs" />
            ) : (
              <FontAwesomeIcon icon={solid("search")} />
            )
          }
          disabled={disableActions}
          searchable
          clearable
          limit={100}
          mt={5}
          data={children}
          onChange={setRedirectToSpace}
          placeholder={t(
            "spaces.settings.main.additional.parentCircles.placeholder"
          )}
          value={redirectToSpace}
          onSearchChange={(query) => onSearchChildren(query)}
        />
      </Box>

      <Box>
        <Text className={classes.itemTitle}>
          {t("spaces.settings.main.additional.parentCircles.description1")}
        </Text>
        <MultiSelect
          icon={
            searching ? (
              <Loader size="xs" />
            ) : (
              <FontAwesomeIcon icon={solid("search")} />
            )
          }
          disabled={disableActions}
          searchable
          limit={100}
          mt={5}
          data={spaces}
          onChange={(selection) => onSelectedParents(selection)}
          placeholder={t(
            "spaces.settings.main.additional.parentCircles.placeholder"
          )}
          value={parentCircles}
          onSearchChange={(query) => onSearchSpaces(query)}
        />
      </Box>

      <PremiumModal opened={opened} setOpened={setOpened} />
    </Box>
  );
}
