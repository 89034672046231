// import axios from 'axios';

class Structure {
  constructor() {
    if (this instanceof Structure) {
      throw Error('Structure class cannot be instantiated.');
    }
  }

  static onFetch = async (slug, paginator, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ...paginator
    }

    await window.$api.get(`/api/v4/spaces/${slug}/structures.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onFetchById = async (structure_id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.get(`/api/v4/spaces/${slug}/structures/${survey_id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }



  static onCreate = async (slug, structure, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      structure
    }

    await window.$api.post(`/api/v4/spaces/${slug}/structures.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onUpdate = async (structure_id, slug, structure, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      structure
    }

    await window.$api.put(`/api/v4/spaces/${slug}/structures/${structure_id}.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDestroy = async (structure_id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/structures/${structure_id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDuplicate = async (structure_id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.post(`/api/v4/spaces/${slug}/structures/${structure_id}/duplicate.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onAddToSpace = async (structure_id, slug, _params, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ..._params
    }

    await window.$api.post(`/api/v4/spaces/${slug}/structures/${structure_id}/add_to_spaces.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default Structure;
