import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { createStyles, Group, Avatar, Stack, Text } from "@mantine/core";

import { useDispatch, useSelector } from "react-redux";

import { setTiptapSidemenu } from "../../store/app";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";
import defaultSpaceColors from "../../collections/defaultSpaceColors";
import isDark from "../../utils/IsDarkColor";

import useLangUser from "../../utils/LangUser";
import formatDate from "../../utils/formatDate";

import { useTranslation } from "react-i18next";

let timeoutId = null;

const useStyles = createStyles(
  (theme, { colors, isDark, listFlatView }, getRef) => ({
    datePosted: {
      color: tinycolor(colors.default_text_color).lighten(10).toString(),
    },
    abstract: {
      textDecoration: "none",
      // color: "#000",
      color: isSpaceThemed(colors) ? colors.default_text_color : "#000",
      margin: 0,

      "&:hover": {
        textDecoration: "none",
      },
    },
    buttonStyle: {
      // borderRadius: theme.radius.md,
      color: tinycolor(colors.default_text_color).lighten(50).toString(),
      // backgroundColor: "red",
      // border: '1px solid red',
      // paddingLeft: 12,
      // paddingRight: 12,

      "&:hover": {
        backgroundColor: !isSpaceThemed(colors)
          ? theme.colors.gray[1]
          : tinycolor(colors.background_color).lighten(3).toString(),
      },
    },
  })
);

export default function HeaderAuthor(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const space2 = useSelector((state) => state.space2);

  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  const colors = useSelector((state) => state.colors);
  const colors2 = useSelector((state) => state.colors2);

  const { language, setLangUser } = useLangUser();
  const navigate = useNavigate();

  const { classes, cx, theme } = useStyles({
    colors: getColors(),
    isDark: isDark,
    listFlatView: props.listFlatView,
  });

  const dispatch = useDispatch();

  const getSpace = () => {
    if (props.useSpace2) {
      return space2;
    } else {
      return space;
    }
  };

  if (!getSpace().list_show_author) {
    return null;
  }

  const openProfile = (event) => {
    event.stopPropagation();
    dispatch(
      setTiptapSidemenu({ opened: "profile", option: getSpace().user.username })
    );
  };

  return (
    <Group spacing={10} mb={12} mt={20}>
      <Avatar
        onClick={(event) => openProfile(event)}
        radius="xl"
        size={40}
        key={`member-avatar-${getSpace().user.id}`}
        src={
          getSpace().user.avatar.url
            ? getSpace().user.avatar.url
            : DefaultAvatar
        }
        component="span"
      />
      <Stack spacing={0}>
        <Group spacing={10}>
          <Text
            onClick={(event) => openProfile(event)}
            className={classes.abstract}
            weight={600}
            sx={{
              lineHeight: "1",
            }}
            mb={6}
          >
            {getSpace().user.full_name}
          </Text>
        </Group>

        {getSpace().list_show_timestamp && (
          <Text
            className={cx(classes.abstract, classes.datePosted)}
            size="xs"
            sx={{
              lineHeight: "1",
            }}
          >
            {/* March 31 at 5:23 AM */}
            {formatDate(
              { raw_start_date: getSpace().created_at },
              language,
              "LLL d",
              "LLL d"
            )}
          </Text>
        )}
      </Stack>
    </Group>
  );
}
