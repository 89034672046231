import React, { useState, useEffect } from 'react';
import {

  Box,
  MantineProvider,
  Select,
  Button,

} from '@mantine/core'

import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import StructureModel from '../../../../../models/Structure'
import ManagerModel from '../../../../../models/Manager'

export default function AddStructure(props) {

  const space = useSelector(state => state.space);
  const user = useSelector(state => state.user);
  const [structureOption, setStructureOption] = useState(null);
  const [structures, setStructures] = useState([]);
  const [options, setOptions] = useState([])
  const { t, i18n } = useTranslation();
  const form = useForm({
    initialValues: {
      object_type: "structure",
      send_as: "",
      object_id: ""
    },

    validate: {
      object_id: (value) => value.length > 0 ? null : "Please select at least one space",
    }
  });

  useEffect(() => {
    onFetch()
  }, [props])

  const onFetch = () => {

    StructureModel.onFetch(space.slug, {page: 1, per: 100}, (data) => {
      const _structures = data.data.map((item, index) => (
        {
          value: `${item.id}`,
          label: item.title,
          group: 'Select an existing structure'
        }
      ))
      setStructures(
        [
          { value: 'new_structure', label: 'Create a new structure', group: 'New structure' },
          ..._structures
        ]
      )
    }, (data) => {

    })

    ManagerModel.onFetch(space.slug, {page: 1, per: 1000}, {scope: "", term: ""}, (data) => {
      const sutrabot = [{
        value: "474",
        label: "sutrabot"
      }]
      const managers = data.data.map((item, index) => (
        {
          value: `${item.user_id}`,
          label: item.full_name
        }
      ))

      setOptions([...sutrabot, ...managers])
    }, (data) => {

    })
  }

  const onSend = () => {
    props.onCreate(form.values)
  }

  return (
    <Box>
    <form
      onSubmit={form.onSubmit((values) => onSend(values))}>
        <MantineProvider
          theme={{ primaryShade: 6 }}
          >
            <Select
              {...form.getInputProps('object_id')}
              label={t("spaces.settings.registration.steps.addStructure.label1")}
              placeholder={t("spaces.settings.registration.steps.addStructure.placeholder1")}
              maxDropdownHeight={400}
              radius="sm"
              size="md"
              mt={20}
              data={structures}
            />


              <Select
                label={t("spaces.settings.registration.steps.addStructure.label2")}
                placeholder={t("spaces.settings.registration.steps.addStructure.placeholder2")}
                maxDropdownHeight={400}
                radius="sm"
                size="md"
                mt={20}
                data={options}
                {...form.getInputProps('send_as')}
              />


          </MantineProvider>
          <Button
            type="submit"
            mt={25}
            leftIcon={<FontAwesomeIcon icon={solid('check')} />}
            radius="xl"
            fullWidth
          >
            {t("spaces.settings.registration.steps.addStructure.saveButton")}
          </Button>
      </form>
    </Box>
  )
}
