export function setResizeable(element, editor, pos) {
  setTimeout(() => {
    const gridCol = $(element).parents(".c-grid-col");
    const inlineCol = $(element).parents("#tiptap-inline-container");

    window.$(element).resizable({
      aspectRatio: $(element).width() / $(element).height(),
      handles: "e, w",
      // handles: "e, w, s",
      // autoHide: true,
      // containment: "parent",
      maxWidth:
        gridCol.length > 0
          ? gridCol.width() - 10
          : inlineCol.length > 0
          ? inlineCol.width() - 10
          : null,
      minWidth: 40,
      resize: function (event, ui) {
        if (!window.$auxPosResizeX) {
          window.$auxPosResizeX = event.clientX;
        }

        apply(event, ui, editor, pos, false, element);
      },
      start: function (event, ui) {
        console.log(
          `START P${$(ui.element).parent().width()} - UI${ui.size.width}`
        );
        if (ui.size.width < 50) {
          $(ui.element).css({
            top: "unset",
            left: "unset",
            width: $(ui.element).parent().width(),
            height: "100%",
          });
        }
      },
      stop: function (event, ui) {
        apply(event, ui, editor, pos, true, element);
      },
    });
  }, 500);
}

function apply(event, ui, editor, pos, save, element) {
  ui.position.top = "unset";
  ui.position.left = "unset";

  const parentWidth = $(ui.element).parent().width();
  const parentHeight = $(ui.element).parent().height();

  let fixHeight = false;
  if (parentWidth - 12 <= ui.size.width) {
    fixHeight = true;
    ui.size.width = parentWidth;
  }
  if (parentHeight <= ui.size.height) {
    fixHeight = true;
    ui.size.height = parentHeight;
  }

  let percentWidth = (ui.size.width * 100.0) / parentWidth;
  let percentHeight = (ui.size.height * 100.0) / parentHeight;

  // if (percentWidth < 40){
  //   percentWidth = 40 * 100.0 / parentWidth
  // }

  const gridCol = $(element).parents(".c-grid-col");

  // const edgeHeight = gridCol.length > 0 ? "200px" : "400px";
  const edgeHeight = "unset";

  $(ui.element)
    .parent()
    .css({
      height: fixHeight ? edgeHeight : "unset",
    });

  $(ui.element).css({
    top: "unset",
    left: "unset",
    width: ui.size.width,
    height: "100%",
  });

  const auxPos = editor.view.posAtCoords({
    left: window.$auxPosResizeX,
    top: event.clientY,
  });
  if (auxPos) {
    window.$auxPosResize = auxPos;
  }

  if (save) {
    if (window.$auxPosResize) {
      editor.commands.setNodeSelection(window.$auxPosResize.inside);
      console.log(fixHeight ? edgeHeight : "unset")
      setTimeout(() => {
        editor.commands.setCustomResizeable({
          resizeableWidth: `${Math.round(percentWidth)}%`,
          resizeableHeight: `${Math.round(percentHeight)}%`,
          resizeableParentHeight: fixHeight ? edgeHeight : "unset",
        });
        window.$auxPosResizeX = null;
      }, 100);
    }
  }
}
