import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

const circleRegex = /\/(circle|space)\/(\w|\d)*(\/(\w|\d)*|.*)/
const circleSettingsRegex = /\/(circle|space)\/(\w|\d)*\/settings\/(\w|\d)*/
const circleRegistrationRegex = /\/(circle|space)\/(\w|\d)*\/registration\/(\w|\d)*/
const circleListRegex = /\/(circle|space)\/(\w|\d)*\/(list|events)/

export default function useSidebarStyles() {

  const location = useLocation()

  const [spaceView, setSpaceView] = useState();
  const [settingsView, setSettingsView] = useState();
  const [registrationView, setRegistrationView] = useState();
  const [listView, setListView] = useState();

  useEffect(() => {
    if (circleRegex.test(location.pathname)){
      setSpaceView(true)
      if (circleSettingsRegex.test(location.pathname)){
        setSettingsView(true)
      }
      else{
        setSettingsView(false)
      }

      if (circleRegistrationRegex.test(location.pathname)){
        setRegistrationView(true)
      }
      else{
        setRegistrationView(false)
      }

      if (circleListRegex.test(location.pathname)){
        setListView(true)
      }
      else{
        setListView(false)
      }
    }
    else{
      setSpaceView(false)
      setSettingsView(false)
      setRegistrationView(false)
      setListView(false)
    }
  }, [location])

  return {
    spaceView,
    settingsView,
    registrationView,
    listView
  }
}
