import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActions } from "../../../../store/app";
import { storeJSON, getJSON } from "../../../../utils/LocalDB";

import { useParams } from "react-router-dom";

import {
  Stack,
  Box,
  ScrollArea,
  createStyles,
  Container,
  Button,
  Text,
  Group,
  MantineProvider,
  Tooltip,
  ActionIcon,
  SimpleGrid,
  Center,
  Loader,
  Chips,
  Chip,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";

import SpaceModel from "../../../../models/Space";
import ViewModel from "../../../../models/View";
import TagModel from "../../../../models/SpaceTag";

import HeaderImage from "../../HeaderImage";
import FixedHeader from "../../FixedHeader";
import Loading from "../static/Loading";
import SpaceListItem from "../List";

import NewSpace from "./NewSpace";
import Settings from "./Settings";
import Thumbnail from "./Thumbnail";

import { useNavigate } from "react-router-dom";

import tinycolor from "tinycolor2";
import TiptapChannel from "../../../tiptap/components/TiptapChannel";
import TiptapInline from "../../../tiptap/components/TiptapInline";
import TiptapReadonly from "../../../tiptap/components/TiptapReadonly";

import { useTranslation } from "react-i18next";

const paginator = {
  per: 15,
  page: 1,
};

const scrollTimeoutIds = [];

const useStyles = createStyles((theme, { colors }, getRef) => ({
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  filterButtonActive: {
    border: `2px solid ${colors.primary_button_background_color}`,
    color: colors.primary_button_background_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.background_color).darken(3).toString(),
    },
  },
  filterButtonPassive: {
    border: `1px solid ${tinycolor(colors.default_text_color).toString()}`,
    color: tinycolor(colors.default_text_color).toString(),
    "&:hover": {
      backgroundColor: tinycolor(colors.background_color).darken(3).toString(),
    },
  },
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color,
    },
    ".ProseMirror .file-node svg": {
      stroke: colors.default_text_color,
    },
    ".ProseMirror .file-node a": {
      color: colors.default_text_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.sidebar_text_color,
      backgroundColor: colors.sidebar_background_color,
      borderRadius: "5px",
    },
  },
  settingsButtonStyle: {
    color: colors.default_text_color,
    "&:hover": {
      backgroundColor: colors.background_color,
      color: colors.primary_button_background_color,
    },
  },
}));

export default function Events(props) {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newSpaceOpened, setNewSpaceOpened] = useState(false);
  const [filter, setFilter] = useState(["upcoming", "ASC"]);
  const [filteredSpaces, setFilterSpaces] = useState([]);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [thumbnailOpened, setThumbnailOpened] = useState(false);

  const [settings, setSettings] = useState({});
  const [content, setContent] = useState({});

  const [loadingMore, setLoadingMore] = useState(false);

  const [tags, setTags] = useState(null);
  const [tagsList, setTagsList] = useState([]);

  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const mainActions = useSelector((state) => state.mainActions);
  const { classes, cx, theme } = useStyles({ colors });

  const user = useSelector((state) => state.user);

  const viewport = useRef();
  const selectedSpace = useRef(null);
  const spacesArray = useRef([]);
  const reload = useRef(false);
  const contentEmpty = useRef(true);
  const noMore = useRef(false);
  const lastSlug = useRef();
  const tagFiltering = useRef();
  const tagTimeout = useRef();
  const tagsTimeout = useRef();
  const ctrlTags = useRef();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActions({ editPage: false }));
    return () => {
      paginator.page = 1;
      setSpaces([]);
      spacesArray.current = [];
      setContent({});
    };
  }, []);

  useEffect(() => {
    if (params.slug) {
      if (params.slug && lastSlug.current != params.slug) {
        lastSlug.current = params.slug;
        setLoading(true);
        paginator.page = 1;
        const ctrl = new AbortController();
        fetchSpacesIDB();
        onFetchView();
        onFetchTags();
      }
    }
  }, [params]);

  useEffect(() => {
    if (props.hideLayout) {
      props.hideLayout();
      setTimeout(() => {
        props.hideLayout();
      }, 500);
    }

    if (space && space.slug) {
      setSettings({
        openPost: space.resource_editing == "open",
        visualType: space.present_as,
        authors: space.settings.list_show_author,
        listChildVersion: space.settings.list_child_version,
        listActionText: space.settings.list_action_text,
        listTemplateId: space.settings.list_template_id,
        listLinkAction: space.settings.list_link_action,
        listShowPreviewText: space.settings.list_show_preview_text,
        listShowThumbnail: space.settings.list_show_thumbnail,
        listShowComments: space.settings.list_show_comments_count,
        listShowMembers: space.settings.list_show_members,
        listShowTimestamp: space.settings.list_show_timestamp,
        listAutoThumbnail: space.settings.list_auto_thumbnail,
        listPrivacyControl: space.settings.list_privacy_control,
        listFilterBy: space.settings.list_filter_by,
        listFlatView: space.settings.list_flat_view,
        spaceWidth: space.settings.space_width,
        listAllowLikes: space.settings.list_allow_likes,
      });
    }
  }, [props]);

  useEffect(() => {
    if (reload.current == true) {
      paginator.page = 1;
      const ctrl = new AbortController();
      fetchSpaces(ctrl);
    }
  }, [settings]);

  useEffect(() => {
    paginator.page = 1;
    const ctrl = new AbortController();
    fetchSpaces(ctrl);
  }, [filter]);

  useEffect(() => {
    if (!tags) {
      return;
    }
    const tagsFilter = tags.length > 0 ? `tags=${JSON.stringify(tags)}` : "";
    if (tagTimeout.current) {
      clearTimeout(tagTimeout.current);
    }

    tagTimeout.current = setTimeout(() => {
      tagFiltering.current = true;
      paginator.page = 1;
      const ctrl = new AbortController();
      fetchSpaces(ctrl, false, [], tagsFilter);
    }, 800);
  }, [tags]);

  useEffect(() => {
    if (spaces.length > 0) {

      const auxSpaces = spaces.sort((a, b) => {
        const itemDayA = new Date(a.calendar.raw_start_date).getDate();
        const itemDayB = new Date(b.calendar.raw_start_date).getDate();
        return itemDayA - itemDayB;
      });

      const firstSpace = auxSpaces[0];
      const lastSpace = auxSpaces[spaces.length - 1];

      const currentDate = new Date(firstSpace.calendar.raw_event_date);
      const pastDate = new Date(lastSpace.calendar.raw_end_date);
      
      const currentYear = new Date().getFullYear();
      const months = [];
      if (filter[0] == "past") {
        for (let d = currentDate; d >= pastDate; d.setMonth(d.getMonth() - 1)) {
          const year = d.getFullYear();
          const month = d.getMonth();
          const monthName = new Date(0, month).toLocaleString("default", {
            month: "long",
          });

          const data = {
            name: currentYear != year ? `${monthName} / ${year}` : monthName,
            spaces: [],
          };

          data.spaces = spaces.filter((space) => {
            const itemYear = new Date(
              space.calendar.raw_event_date
            ).getFullYear();
            const itemMonth = new Date(
              space.calendar.raw_event_date
            ).getMonth();
            return itemYear === year && itemMonth === month;
          });
          data.spaces = data.spaces.sort((a, b) => {
            const itemDayA = new Date(a.calendar.raw_start_date).getDate();
            const itemDayB = new Date(b.calendar.raw_start_date).getDate();
            return itemDayB - itemDayA;
          });

          months.push(data);
        }
      } else {
        for (let d = currentDate; d <= pastDate; d.setMonth(d.getMonth() + 1)) {
          const year = d.getFullYear();
          const month = d.getMonth();
          const monthName = new Date(0, month).toLocaleString("default", {
            month: "long",
          });

          const data = {
            name: currentYear != year ? `${monthName} / ${year}` : monthName,
            spaces: [],
          };

          data.spaces = spaces.filter((space) => {
            const itemYear = new Date(
              space.calendar.raw_event_date
            ).getFullYear();
            const itemMonth = new Date(
              space.calendar.raw_event_date
            ).getMonth();
            return itemYear === year && itemMonth === month;
          });
          data.spaces = data.spaces.sort((a, b) => {
            const itemDayA = new Date(a.calendar.raw_start_date).getDate();
            const itemDayB = new Date(b.calendar.raw_start_date).getDate();
            return itemDayA - itemDayB;
          });

          months.push(data);
        }
      }
      setFilterSpaces(months);
    } else {
      setFilterSpaces([]);
    }
  }, [spaces]);

  useEffect(() => {
    console.log(filteredSpaces);
  }, [filteredSpaces]);

  const newDataFromApi = (arr1, arr2) => {
    // Check if lengths are different
    if (arr1.length !== arr2.length) {
      return true;
    }

    // Compare elements
    for (let i = 0; i < arr1.length; i++) {
      if (
        arr1[i].slug !== arr2[i].slug ||
        arr1[i].name !== arr2[i].name ||
        arr1[i].abstract !== arr2[i].abstract ||
        arr1[i].comments_count !== arr2[i].comments_count ||
        arr1[i].likes_count !== arr2[i].likes_count ||
        JSON.stringify(arr1[i].tagged_with) !=
          JSON.stringify(arr2[i].tagged_with) ||
        arr1[i].activity != arr2[i].activity
      ) {
        return true;
      }
    }

    for (let i = 0; i < arr2.length; i++) {
      if (
        arr1[i].slug !== arr2[i].slug ||
        arr1[i].name !== arr2[i].name ||
        arr1[i].abstract !== arr2[i].abstract ||
        arr1[i].comments_count !== arr2[i].comments_count ||
        arr1[i].likes_count !== arr2[i].likes_count ||
        JSON.stringify(arr1[i].tagged_with) !=
          JSON.stringify(arr2[i].tagged_with) ||
        arr1[i].activity != arr2[i].activity
      ) {
        return true;
      }
    }

    // If all elements are equal, return true
    return false;
  };

  const fetchSpacesIDB = () => {
    getJSON(`spaces`, `events_children_${params.slug}`, (data) => {
      const ctrl = new AbortController();
      if (data && data.length) {
        const oldData = [...data];
        setSpaces(oldData);
        spacesArray.current = [...data];
        onFinishedLoading();
        fetchSpaces(ctrl, true, oldData);
      } else {
        fetchSpaces(ctrl);
      }
    });
  };

  const fetchSpaces = (ctrl, check = false, oldData = [], tagsFilter = "") => {
    if (loading) {
      return;
    }
    if (paginator.page == 1) {
    } else {
      if ((!tagsFilter && noMore.current) || loadingMore) {
        return;
      }
      setLoadingMore(true);

      setTimeout(() => {
        setLoadingMore(false);
      }, 1500);

      // scrollToBottom();
    }

    SpaceModel.onFetchDirectChildren(
      params.slug,
      [`order=pod_settings.start_date ${filter[1]}`, tagsFilter],
      paginator,
      ["active", filter[0]],
      (data) => {
        if (data.page == 1) {
          const newData = [...data.data];
          if (!tagsFilter) {
            storeJSON("spaces", `list_children_${params.slug}`, newData);
            if (!check || (check && newDataFromApi(newData, oldData))) {
              spacesArray.current = [...newData];
              setSpaces(data.data);
            }
            paginator.page = 2;
            onFinishedLoading();
          } else {
            spacesArray.current = [...newData];
            setSpaces(newData);
            paginator.page = 2;
            onFinishedLoading(false);
          }
        } else {
          if (data.data.length > 0) {
            spacesArray.current = spacesArray.current.concat(data.data);
            setSpaces(spacesArray.current.concat(data.data));
            paginator.page = data.page + 1;
          } else {
            noMore.current = true;
            setTimeout(() => {
              noMore.current = false;
            }, 3000);
          }
          onFinishedLoading(tagsFilter == "");
        }
      },
      (data) => {
        onFinishedLoading();
      },
      ctrl.signal
    );
  };

  const onFinishedLoading = (loadmore = true) => {
    // if (loading) {
    setTimeout(() => {
      setLoading(false);
      setTimeout(() => {
        if ((paginator.page == 2 || paginator.page == 3) && loadmore) {
          // onScrollBottom();
        }
      }, 300);
    }, 300);
    // }
  };

  const onScrollPositionChange = (pos) => {
    const div = viewport.current;
    // const div = document.getElementsByClassName("app-main-frame")[0]
    clearScrollTimeouts();

    if (pos.y + div.clientHeight >= div.scrollHeight - 200) {
      console.log("On bottom");
      const scrollTimeoutId = setTimeout(() => {
        console.log("On bottom timer");
        // At bottom
        onScrollBottom();
      }, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else if (pos.y == 0) {
      const scrollTimeoutId = setTimeout(() => {
        // At Top
      }, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else {
      // In between
    }
  };

  const clearScrollTimeouts = () => {
    for (let i = 0; i < scrollTimeoutIds.length; i++) {
      const id = scrollTimeoutIds[i];
      clearTimeout(id);
    }
  };

  const onScrollBottom = () => {
    if (!loading) {
      const ctrl = new AbortController();
      fetchSpaces(ctrl);
    }
  };

  const onThumbnailSave = (spaceId, url) => {
    const auxSpaces = [...spaces];
    for (let i = 0; i < auxSpaces.length; i++) {
      if (auxSpaces[i].slug == spaceId) {
        auxSpaces[i].update_count = auxSpaces[i].update_count
          ? auxSpaces[i].update_count + 1
          : 1;
        auxSpaces[i].gallery_feature = { url: url };

        setTimeout(() => {
          setSpaces(auxSpaces);
          spacesArray.current = [...auxSpaces];
        }, 100);

        return;
      }
    }
  };

  const onRemoveThumbnail = (spaceId) => {
    SpaceModel.onRemoveThumbnail(
      spaceId,
      (data) => {
        setTimeout(() => {
          onSave(spaceId);
        }, 1000);
      },
      (data) => {}
    );
  };

  const onSettingsSave = (_settings) => {
    reload.current = settings.listFilterBy != _settings.listFilterBy;
    onFetchView();
    if (settings.spaceWidth != _settings.spaceWidth) {
    }
    setSettings(_settings);
  };

  const onSetSettingsOpened = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setSettingsOpened(value);
  };

  const onListActionTextChanged = (text) => {
    setListActionText(text);
  };

  const onListTemplateIdChanged = (id) => {
    setListTemplateId(id);
  };

  const onSave = (spaceId, spaceLink, spaceName = null) => {
    // paginator.page = 1;
    // const ctrl = new AbortController();
    // fetchSpaces(ctrl);
    setNewSpaceOpened(false);

    SpaceModel.onFetchSimple(
      spaceId,
      "simple",
      (data) => {
        navigate(`/space/${data.slug}/${data.version}`);
        return;
        // const auxSpaces = [...spacesArray.current];
        // for (let i = 0; i < auxSpaces.length; i++) {
        //   if (auxSpaces[i].slug == spaceId) {
        //     auxSpaces[i] = { ...data };
        //     auxSpaces[i].update_count = auxSpaces[i].update_count
        //       ? auxSpaces[i].update_count + 1
        //       : 1;
        //     setSpaces([]);
        //     setTimeout(() => {
        //       setSpaces(auxSpaces);
        //       spacesArray.current = [...auxSpaces];
        //     }, 100);
        //     return;
        //   }
        // }
        // auxSpaces.push({ ...data });

        // setSpaces([]);
        // setTimeout(() => {
        //   setSpaces(auxSpaces);
        //   spacesArray.current = [...auxSpaces];
        // }, 100);
      },
      (data) => {}
    );
  };

  const onUpdate = (spaceId, action) => {
    const auxSpaces = [...spacesArray.current];

    if (action == "pin" || action == "unpin") {
      for (let i = 0; i < auxSpaces.length; i++) {
        const space = auxSpaces[i];
        if (space.id == spaceId) {
          if (action == "pin") {
            space.is_pinned.is_pinned = true;
            space.is_pinned.date = new Date();
          } else {
            space.is_pinned.is_pinned = false;
          }
          space.update_count = space.update_count ? space.update_count + 1 : 1;
          break;
        }
      }
      setSpaces(auxSpaces);
      spacesArray.current = [...auxSpaces];
    } else if (action == "delete") {
      const auxSpaces = [...spaces];
      console.log(`AUX SPACES: ${auxSpaces.length}`);
      for (let i = 0; i < auxSpaces.length; i++) {
        const space = auxSpaces[i];
        if (space.id == spaceId) {
          console.log(`AUX SPACES: ${i}`);
          auxSpaces.splice(i, 1);
          break;
        }
      }
      setSpaces(auxSpaces);
      spacesArray.current = [...auxSpaces];
    } else if (action == "thumbnail-upload") {
      selectedSpace.current = spaceId;
      setThumbnailOpened(true);
    } else if (action == "thumbnail-delete") {
      selectedSpace.current = spaceId;
      onRemoveThumbnail(spaceId);
    }
  };

  const onJoin = () => {
    if (!user.authenticated) {
      localStorage.setItem("join_slug", params.slug);
      navigate(`/user/start?s=${params.slug}`);
      return;
    }
    SpaceModel.onJoin(
      params.slug,
      (data) => {
        navigate(`${data.redirect_to}?refresh=true`);
      },
      (data) => {}
    );
  };

  const onFetchTags = () => {
    if (tagsTimeout.current) {
      clearTimeout(tagsTimeout.current);
    }
    tagsTimeout.current = setTimeout(() => {
      TagModel.onFetch(
        props.spaceId,
        (data) => {
          setTagsList([...data]);
        },
        (data) => {}
      );
    }, 2000);
  };

  const onFetchView = () => {
    ViewModel.onFetch(
      "description_view",
      params.slug,
      (data) => {
        contentEmpty.current = data.is_empty;
        window.$spaceWideWidth = data.view_width;
        setContent({ ...data.content });
      },
      (data) => {
        setContent({});
      }
    );
  };

  const onContentUpdate = (_content, html) => {
    ViewModel.onUpdate(
      "description_view",
      params.slug,
      _content,
      (data) => {
        contentEmpty.current = data.is_empty;
        setContent({ ..._content });
      },
      (data) => {}
    );
  };

  const capitalize = (str) => {
    return str.replace(/^(.)(.*)$/, function (_, firstChar, restOfString) {
      return firstChar.toUpperCase() + restOfString;
    });
  };

  if (loading) {
    return <Loading type="content" />;
  }

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea
        style={{ height: "100%" }}
        onScrollPositionChange={onScrollPositionChange}
        viewportRef={viewport}
      >
        <HeaderImage />
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={mainActions.editPage}
          components={[
            "breadcrumbs",
            "title",
            "calendar",
            "status",
            "interactions",
            "tag",
          ]}
        />

        {mainActions.editPage && (
          <Container size={space.space_width == "wide" ? 960 : 801} mt={10}>
            <Box>
              <TiptapInline
                editable={true}
                content={""}
                modals={true}
                exclude={["formatbar"]}
                onUpdate={onContentUpdate}
                placeholder={t("spaces.presentations.events.placeholder")}
                canToggleFormatbar={true}
                contextDb={`list-space-${params.slug}`}
              />
            </Box>
          </Container>
        )}
        {!mainActions.editPage && content && !contentEmpty.current && (
          <Container size={window.$spaceWideWidth == "wide" ? 960 : 801} mt={10}>
            <Box>
              <TiptapReadonly content={content} />
            </Box>
          </Container>
        )}

        <Container size={space.space_width == "wide" ? 960 : 801} mt={10}>
          <Group position="apart" spacing={0}>
            <Group spacing={10}>
              <MantineProvider theme={{ primaryShade: 5 }}>
                <Button
                  radius="xl"
                  variant="outline"
                  // color={filter[0] == "upcoming" ? "dark" : "gray"}
                  classNames={{
                    root:
                      filter[0] == "upcoming"
                        ? classes.filterButtonActive
                        : classes.filterButtonPassive,
                  }}
                  onClick={() => setFilter(["upcoming", "ASC"])}
                >
                  {t("spaces.presentations.events.upcomingButton")}
                </Button>
                <Button
                  radius="xl"
                  variant="outline"
                  classNames={{
                    root:
                      filter[0] == "past"
                        ? classes.filterButtonActive
                        : classes.filterButtonPassive,
                  }}
                  onClick={() => setFilter(["past", "DESC"])}
                >
                  {t("spaces.presentations.events.pastButton")}
                </Button>
              </MantineProvider>
            </Group>
            {(settings.openPost ||
              (space.slug && space.permissions.can_manage)) && (
              <Group spacing={10}>
                {space.slug && space.permissions.can_edit ? (
                  <Button
                    leftIcon={
                      <FontAwesomeIcon icon={regular("calendar-plus")} />
                    }
                    className={classes.primaryButtonStyle}
                    radius="xl"
                    onClick={() => setNewSpaceOpened(true)}
                  >
                    {settings.listActionText ||
                      t("spaces.presentations.events.addEvent")}
                  </Button>
                ) : (
                  <Button
                    leftIcon={
                      <FontAwesomeIcon icon={regular("calendar-plus")} />
                    }
                    className={classes.primaryButtonStyle}
                    radius="xl"
                    onClick={() => onJoin()}
                  >
                    {settings.listActionText ||
                      t("spaces.presentations.events.addEvent")}
                  </Button>
                )}
                {space && space.permissions && space.permissions.can_manage && (
                  <Tooltip
                    label={t(
                      "spaces.presentations.events.eventSettingsTooltip"
                    )}
                    withArrow
                  >
                    <ActionIcon
                      className={classes.settingsButtonStyle}
                      size={20}
                      radius="xl"
                      onClick={(event) => onSetSettingsOpened(event, true)}
                    >
                      <FontAwesomeIcon size="lg" icon={solid("gear")} />
                    </ActionIcon>
                  </Tooltip>
                )}
              </Group>
            )}
          </Group>
        </Container>

        <Container size={space.space_width == "wide" ? 960 : 801}>
          {tagsList && tagsList.length > 0 && (
            <Box sx={{ width: "100%" }}>
              <Box
                mt={20}
                mb={10}
                ml={0}
                mr={0}
                spacing={0}
                noWrap
                sx={{
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    overflowX: "hidden",
                  }}
                >
                  <Chips
                    styles={{
                      outline: {
                        color: colors.default_text_color,
                        backgroundColor: colors.background_color,
                        height: "auto",
                        border: isSpaceThemed(colors)
                          ? `1px solid ${
                              isDark(colors.background_color)
                                ? tinycolor(colors.background_color)
                                    .lighten(40)
                                    .toString()
                                : tinycolor(colors.background_color)
                                    .darken(40)
                                    .toString()
                            }`
                          : `1px solid #ced4da`,
                        "&:hover": {
                          backgroundColor: isSpaceThemed(colors)
                            ? `${
                                isDark(colors.background_color)
                                  ? tinycolor(colors.background_color)
                                      .lighten(5)
                                      .toString()
                                  : tinycolor(colors.background_color)
                                      .darken(5)
                                      .toString()
                              }`
                            : `#e9ecef`,
                        },
                      },
                      checked: {
                        border: isSpaceThemed(colors)
                          ? `2px solid ${
                              isDark(colors.background_color)
                                ? tinycolor(colors.background_color)
                                    .lighten(40)
                                    .toString()
                                : tinycolor(colors.background_color)
                                    .darken(40)
                                    .toString()
                            } !important`
                          : ``,
                        color: colors.default_text_color,
                      },
                      checkIcon: {
                        color: isSpaceThemed(colors)
                          ? `${
                              isDark(colors.background_color)
                                ? tinycolor(colors.background_color)
                                    .lighten(40)
                                    .toString()
                                : tinycolor(colors.background_color)
                                    .darken(40)
                                    .toString()
                            }`
                          : ``,
                      },
                    }}
                    multiple
                    value={tags}
                    onChange={setTags}
                  >
                    {tagsList.map((tag) => {
                      return <Chip value={tag.name}>{tag.name}</Chip>;
                    })}
                  </Chips>
                </Box>
              </Box>
            </Box>
          )}
        </Container>

        <Container
          size={space.space_width == "wide" ? 960 : 801}
          style={{ marginBottom: "50px" }}
        >
          {filteredSpaces.map((month) => {
            return (
              <>
                {month.spaces.length > 0 && (
                  <Box>
                    <Text size={"xl"} mb={20} mt={20} weight={700}>
                      {capitalize(month.name)}
                    </Text>
                    {settings.visualType == "list" &&
                      month.spaces.map((space) => {
                        return (
                          space.can_view && (
                            <Box
                              sx={(theme) => ({
                                margin: "15px 0px",
                              })}
                            >
                              <SpaceListItem
                                key={`space-list-item-${space.slug}`}
                                space={space}
                                settings={settings}
                                onUpdate={onUpdate}
                                showAuthor={settings.authors}
                                {...settings}
                                canPin={false}
                              />
                            </Box>
                          )
                        );
                      })}
                    {settings.visualType == "grid" && (
                      <SimpleGrid
                        cols={3}
                        spacing={20}
                        mt={0}
                        breakpoints={[
                          { maxWidth: 980, cols: 3, spacing: 15 },
                          { maxWidth: 755, cols: 2, spacing: 15 },
                          { maxWidth: 600, cols: 1, spacing: 15 },
                        ]}
                      >
                        {month.spaces.map((space) => {
                          return (
                            space.can_view && (
                              <Box
                                sx={(theme) => ({
                                  margin: "0px 0px",
                                })}
                              >
                                <SpaceListItem
                                  key={`space-list-item-${space.slug}`}
                                  space={space}
                                  settings={settings}
                                  viewAs={"grid"}
                                  onUpdate={onUpdate}
                                  showAuthor={settings.authors}
                                  {...settings}
                                  canPin={false}
                                />
                              </Box>
                            )
                          );
                        })}
                      </SimpleGrid>
                    )}
                  </Box>
                )}
              </>
            );
          })}
        </Container>
        {loadingMore && (
          <Center mb={40}>
            <Loader />
          </Center>
        )}
        <NewSpace
          opened={newSpaceOpened}
          setOpened={setNewSpaceOpened}
          spaceType={"event"}
          formTitle={t("spaces.presentations.events.newEventTitle")}
          onSave={onSave}
          content={false}
          noAdvance={true}
          linkOnly={settings.listChildVersion == "link"}
          duplicateFrom={
            settings.listTemplateId != "create" ? settings.listTemplateId : null
          }
          actionButtonText={
            settings.listActionText
              ? settings.listActionText
              : t("spaces.presentations.events.addEvent")
          }
          privacyControl={"any"}
        />
        {space && space.permissions && space.permissions.can_manage && (
          <Settings
            opened={settingsOpened}
            setOpened={setSettingsOpened}
            onSave={onSettingsSave}
            spaceId={params.slug}
            onListActionTextChanged={onListActionTextChanged}
            onListTemplateIdChanged={onListTemplateIdChanged}
            parentVersion="events"
            onFetchTags={onFetchTags}
          />
        )}
        <Thumbnail
          opened={thumbnailOpened}
          setOpened={setThumbnailOpened}
          onSave={onThumbnailSave}
          spaceId={selectedSpace.current}
        />
        <TiptapChannel onActionCallback={onSave} />
      </ScrollArea>
    </Stack>
  );
}
