import React from 'react';
import {
  Text,
  createStyles
} from '@mantine/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const useStyles = createStyles((theme) => ({

  symbol: {
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 700,
    width: 60,
  }

}));

export default function StepIcon(props) {

  const { classes, cx, theme } = useStyles();

  const displayStepIcon = () => {
    const value = props.value

    if (value == "send_email" || value == "send_message") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme)=>({color: theme.colors.blue[5]})}
        >
          <FontAwesomeIcon icon={solid('envelope')} />
        </Text>
      )
    } else if (
      value == "add_to_existing_space" ||
      value == "add_to_new_space" ||
      value == "add_from_sub_space" ||
      value == "add_from_children" ||
      value == "add_to_direct_open_children" ||
      value == "add_sub_circle_members"
    ) {
      return (
        <Text
          className={classes.symbol}
          sx={(theme)=>({color: theme.colors.green[5]})}
        >
          <FontAwesomeIcon icon={solid('user')} />
        </Text>
      )
    } else if (value == "add_structure") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme)=>({color: theme.colors.cyan[5]})}
        >
          <FontAwesomeIcon icon={solid('list-ol')} />
        </Text>
      )
    } else if (value == "survey_person") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme)=>({color: theme.colors.violet[5]})}
        >
          <FontAwesomeIcon icon={solid('square-poll-horizontal')} />
        </Text>
      )
    } else if (value == "set_to_pending") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme)=>({color: theme.colors.red[5]})}
        >
          <FontAwesomeIcon icon={solid('circle-pause')} />
        </Text>
      )
    } else if (
      value == "wait" ||
      value == "wait_until" ||
      value == "wait_until_structure_complete"
    ) {
      return (
        <Text
          className={classes.symbol}
          sx={(theme)=>({color: theme.colors.gray[5]})}
        >
          <FontAwesomeIcon icon={solid('clock')} />
        </Text>
      )
    } else if (value == "apply_template") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme)=>({color: theme.colors.yellow[5]})}
        >
          <FontAwesomeIcon icon={solid('map')} />
        </Text>
      )
    } else if (value == "webhook") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme)=>({color: theme.colors.yellow[5]})}
        >
          <FontAwesomeIcon icon={solid('link')} />
        </Text>
      )
    }
  }

  return (
    <div>
      {displayStepIcon()}
    </div>
  )
}
