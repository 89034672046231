import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

import ListContent from "../../spaces_neo/presentations/ListContent";
import ListPost from "../../spaces_neo/presentations/ListPost";
import GridPost from "../../spaces_neo/presentations/GridPost";
// import CircleList from '../../spaces/presentations/List';

import { stylesToCamelCase } from "../../../utils/StylesToString";
import SpaceModel from "../../../models/Space";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import isDark from "../../../utils/IsDarkColor";

import { checkInteractionAndHideIfNeededReact, setUniqId} from "../utils/selection";

import { useInView } from "react-intersection-observer";

import { storeJSON, getJSON } from "../../../utils/LocalDB";

import { wideOrNarrow } from "../../../utils/PageWidth";

import { getRoot } from "../../../utils/Host";

import Broadcaster from "../../../helpers/Broadcaster";

import {
  Box,
  Group,
  Stack,
  createStyles,
  Button,
  Skeleton,
  Text,
} from "@mantine/core";

const useStyles = createStyles((theme, { colors }) => ({
  spaceListItem: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: "5px",
    // backgroundColor: theme.colors.gray[0],
    backgroundColor: isSpaceThemed(colors)
      ? isDark(colors.background_color)
        ? tinycolor(colors.background_color).lighten(8).toString()
        : tinycolor(colors.background_color).darken(8).toString()
      : tinycolor(colors.background_color).darken(2).toString(),
    border: isSpaceThemed(colors)
      ? `1px solid ${
          isDark(colors.background_color)
            ? tinycolor(colors.background_color).lighten(20).toString()
            : tinycolor(colors.background_color).darken(20).toString()
        }`
      : `1px solid #e9ecef`,
    color: isSpaceThemed(colors)
      ? tinycolor(colors.default_text_color).toString()
      : "#000",
    // background: !publishOnDate ? "repeating-linear-gradient(-45deg,#ddd,#ddd 1px,#f9f9f9 1px,#f9f9f9 10px)" : "",
    height: 95,
    padding: "0px 25px",
    // margin: "15px 0px",
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    width: "100%",

    "&:hover": {
      // backgroundColor: theme.colors.gray[0],
      backgroundColor: isSpaceThemed(colors)
        ? isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(10).toString()
          : tinycolor(colors.background_color).darken(10).toString()
        : tinycolor(colors.background_color).darken(2).toString(),
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
      cursor: "pointer",
      // color: "#000",
      textDecoration: "none",
    },
  },
}));

export default function Circle(props) {
  const navigate = useNavigate();

  const [space, setSpace] = useState(null);

  const colors = useSelector((state) => state.colors);
  const children = useSelector((state) => state.children);

  const { classes, cx, theme } = useStyles({ colors });

  const loaded = useRef();
  const loadingInView = useRef(false);
  const force = useRef(false);

  const [canView, setCanView] = useState(true);
  const [isWide, setIsWide] = useState(true);

  const { ref, inView } = useInView({ triggerOnce: true });

  //setUniqId(props.editor, props.node, props.getPos);

  // Only hit when needed
  useEffect(() => {
    if (
      loaded.current &&
      space &&
      props.node.attrs.id != space.slug &&
      (window.location.href.split("/")[4] == props.node.attrs.circle_id ||
      window.$modalSpaceOpened == props.node.attrs.circle_id  || 
        window.$templatesModalIsOpened)
    ) {
      onFetch();
    }
  }, [props]);

  useEffect(() => {
    if (
      inView &&
      !space &&
      (window.location.href.split("/")[4] == props.node.attrs.circle_id ||
      window.$modalSpaceOpened == props.node.attrs.circle_id  || 
        window.$templatesModalIsOpened)
    ) {
      onFetch();
    }
  }, [inView]);

  useEffect(() => {
    if (window.location.href.split("/")[4] == props.node.attrs.circle_id || window.$modalSpaceOpened == props.node.attrs.circle_id) {
      onFetch();
    }
  }, [children]);

  useEffect(() => {
    // setIsWide(ref.innerWidth >= parseFloat(wideOrNarrow("")));
  }, [ref]);

  const isUnpublished = () => {
    return (
      space && space.launch_on_date && new Date() < new Date(space.launch_time)
    );
  };

  const onFetch = () => {
    if (location.pathname.indexOf("space_legacy") >= 0) {
      getJSON(`spaces`, `simple_${props.node.attrs.id}`, (data) => {
        if (data && data.space && data.space.slug) {
          onSpaceFetched(data);
          onFetchSpaceFromAPI(data.stored_at);
        } else {
          onFetchSpaceFromAPI();
        }
      });
      // onFetchSpaceFromAPI();
    } else {
      let spaceFound = false;
      if (window.$modalSpaceOpened){
        
        if (window.$space2Children && window.$space2Children.children) {
          for (let i = 0; i < window.$space2Children.children.length; i++) {
            const child = window.$space2Children.children[i];
            if (child.slug == props.node.attrs.id) {
              setCanView(true);
              onSpaceFetchedNeo4j(child);
              spaceFound = true;
              break;
            }
          }
          if (!spaceFound) {
            setCanView(false);
          }
        }
      }
      else{
        if (window.$spaceChildren && window.$spaceChildren.children) {
          for (let i = 0; i < window.$spaceChildren.children.length; i++) {
            const child = window.$spaceChildren.children[i];
            if (child.slug == props.node.attrs.id) {
              setCanView(true);
              onSpaceFetchedNeo4j(child);
              spaceFound = true;
              break;
            }
          }
          if (!spaceFound) {
            setCanView(false);
          }
        }
      }

      if (!spaceFound) {
        if (window.$selectedTemplateChildren) {
          for (let i = 0; i < window.$selectedTemplateChildren.length; i++) {
            const child = window.$selectedTemplateChildren[i];
            child.permissions.can_view = true ;
            
            if (child.slug == props.node.attrs.id) {
              
              setCanView(true);
              onSpaceFetchedNeo4j(child);
              return;
            }
          }
        }
      }
    }
  };

  const onFetchSpaceFromAPI = (storedAt) => {
    setTimeout(() => {
      const include = [];

      const ctrl = new AbortController();
      SpaceModel.onFetchSimpleShow(
        props.node.attrs.id,
        include,
        (data) => {
          storeJSON("spaces", `simple_${props.node.attrs.id}`, { ...data });

          const updatedAtLocalDate = new Date(Date.parse(data.updated_at));

          if (!storedAt || (storedAt && updatedAtLocalDate > storedAt)) {
            console.log("SILENT UPDATE DONE SPACE");

            onSpaceFetched(data);
          }

          onSpaceFetched(data);
        },
        (data) => {
          // Something went wrong
        },
        ctrl.signal
      );
    }, 150);
  };

  const onSpaceFetched = (data) => {
    setSpaceData({
      ...data.space,
      permissions: { ...data.permissions },
      abstract: data.abstract ? data.abstract : data.space.abstract,
    });
  };

  const onSpaceFetchedNeo4j = (data) => {
    const role = data.role ? data.role : window.$currentRole;
    
    setSpaceData({
      ...data,
      permissions: { ...data.permissions[role] },
      abstract: data.abstract,
    });
  };

  const setSpaceData = (data) => {
    loaded.current = true;
    setSpace({ ...data });
    setCanView(data.permissions.can_view);
  };

  const goToLink = () => {
    navigate(getLink());
  };

  const getLink = (root = false) => {
    if (root) {
      return `${getRoot()}/space/${space.slug}/${space.version}`;
    } else {
      return `/space/${space.slug}/${space.version}`;
    }
  };

  const onSelect = (event) => {
    if (window.$templatesModalIsOpened){
      return
    }
    switch (event.detail) {
      case 1: {
        if (props.editor && !props.editor.isEditable) {
          navigate(`/space/${props.node.attrs.id}/${space.version}`);
        }
        break;
      }
      case 2: {
        if (props.editor && props.editor.isEditable) {
          navigate(`/space/${props.node.attrs.id}/${space.version}`);
        }
        break;
      }
      case 3: {
        // if (props.editor && props.editor.isEditable){
        //   navigate(`/space/${props.node.attrs.id}`)
        // }
        break;
      }
      default: {
        break;
      }
    }
  };

  const getRadius = () => {
    if (props.node.attrs.radius == "no-radius") {
      return "xs";
    } else if (props.node.attrs.radius == "circle-radius") {
      return "xl";
    } else if (props.node.attrs.radius == "with-radius") {
      return "sm";
    } else {
      return "xs";
    }
  };

  const onDragClick = (event) => {};

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      style={{
        display: canView ? "block" : "none",
        width: "100%",
        maxWidth: wideOrNarrow(),
      }}
      ref={ref}
    >
      {checkInteractionAndHideIfNeededReact(props.editor, props.getPos) && (
        <NodeViewContent
          draggable="true"
          data-drag-handle
          className={`node-content ${
            props.node.attrs.viewAs == "button" && props.node.attrs.class
          }`}
          radius={props.node.attrs.radius}
          contentEditable="false"
          style={{
            ...stylesToCamelCase(props.node.attrs.styles),
            textAlign: props.node.attrs.textAlign,
          }}
        >
          {space &&
            space.slug == props.node.attrs.id &&
            space.permissions.can_view &&
            (props.editor.isEditable ||
              !isUnpublished() ||
              window.$currentRole == "manager" || window.$currentRole == "admin") && (
              <div
                id={`circle-node-${props.node.attrs.id}`}
                onClick={(event) => onSelect(event)}
                uid={props.node.attrs.uid}
              >
                {!props.node.attrs.viewAs ||
                  (props.node.attrs.viewAs == "list" && (
                    <ListContent space={space} />
                  ))}
                {props.node.attrs.viewAs == "hcard" && (
                  <ListPost
                    space={space}
                    listFlatView={false}
                    listShowThumbnail={true}
                    listShowPreviewText={true}
                    showAuthor={false}
                    listShowComments={false}
                    listAllowLikes={false}
                    listShowMembers={false}
                    noActions={true}
                    onSpaceFetched={onSpaceFetched}
                  />
                )}
                {props.node.attrs.viewAs == "vcard" && (
                  <GridPost
                    space={space}
                    listFlatView={false}
                    listShowThumbnail={true}
                    listShowPreviewText={true}
                    showAuthor={false}
                    listShowComments={false}
                    listAllowLikes={false}
                    listShowMembers={false}
                    noActions={true}
                    onSpaceFetched={onSpaceFetched}
                  />
                )}
                {props.node.attrs.viewAs == "button" && (
                  <form>
                    {props.editor && props.editor.isEditable ? (
                      <Button
                        fullWidth
                        radius={getRadius()}
                        sx={(theme) => ({
                          backgroundColor:
                            props.node.attrs.buttonBackgroundColor,
                          color: props.node.attrs.buttonTextColor,
                          border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                          "&:hover": {
                            backgroundColor: tinycolor(
                              props.node.attrs.buttonBackgroundColor
                            )
                              .darken(5)
                              .toString(),
                          },
                        })}
                      >{`${props.node.attrs.buttonText || space.name}`}</Button>
                    ) : (
                      <Button
                        onClick={() => goToLink()}
                        fullWidth
                        radius={getRadius()}
                        sx={(theme) => ({
                          backgroundColor:
                            props.node.attrs.buttonBackgroundColor,
                          color: props.node.attrs.buttonTextColor,
                          border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                          "&:hover": {
                            backgroundColor: tinycolor(
                              props.node.attrs.buttonBackgroundColor
                            )
                              .darken(5)
                              .toString(),
                          },
                        })}
                      >{`${props.node.attrs.buttonText || space.name}`}</Button>
                    )}
                  </form>
                )}
                {props.node.attrs.viewAs == "link" &&
                  (props.editor.isEditable ? (
                    <Text
                      to={getLink()}
                      component={Link}
                      onClick={(event) => event.preventDefault()}
                    >
                      {`${props.node.attrs.buttonText || space.name}`}
                    </Text>
                  ) : (
                    <Text to={getLink()} component={Link}>
                      {`${props.node.attrs.buttonText || space.name}`}
                    </Text>
                  ))}
              </div>
            )}
          {!space &&
            (props.editor.isEditable ||
              !isUnpublished() ||
              space.permissions.can_manage) && (
              <div>
                <Box
                  id={`circle-node-${props.node.attrs.id}`}
                  className={cx(classes.spaceListItem)}
                >
                  <Group
                    sx={{
                      width: "100%",
                    }}
                    position="apart"
                    spacing="sm"
                    noWrap
                  >
                    <Stack
                      spacing={0}
                      sx={(theme) => ({
                        height: "100%",
                      })}
                    >
                      <Group spacing="sm">
                        <Skeleton height={30} width="50px" radius="sm" />
                      </Group>
                    </Stack>
                    <Group noWrap position="right" spacing={10}>
                      <Skeleton height={30} width="30px" radius="sm" />
                      <Skeleton height={30} width="30px" radius="sm" />
                      <Skeleton height={30} width="30px" radius="sm" />
                    </Group>
                  </Group>
                </Box>
              </div>
            )}
        </NodeViewContent>
      )}
    </NodeViewWrapper>
  );
}
