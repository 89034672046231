import React from "react";

import { Box, Title, createStyles } from "@mantine/core";

import { useTranslation } from "react-i18next";

import { PieChart } from "react-chartkick";
import "chartkick/chart.js";

import TextBased from "./responses/TextBased.jsx";

const useStyles = createStyles((theme) => ({
  item: {
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 15,
    paddingLeft: 15,
    backgroundColor: theme.white,
    marginTop: 15,
    width: "100%",
  },
}));

export default function Summary(props) {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Box mt={20}>
      {props.survey
        ? props.survey.questions.map((question) => {
            return (
              <Box className={classes.item}>
                <Title order={5}>{question.content}</Title>
                {question.question_type == "freeform"
                  ? question.choices.map((choice) => {
                      return <TextBased response={choice.content} />;
                    })
                  : ""}
                {question.question_type != "freeform" &&
                question.graph_data.length > 0 ? (
                  <PieChart legend="bottom" data={question.graph_data} />
                ) : (
                  ""
                )}
                {question.choices.length == 0 ? (
                  <TextBased response="No response" />
                ) : (
                  ""
                )}
              </Box>
            );
          })
        : ""}
    </Box>
  );
}
