import React, { useState, useEffect } from "react";

import {
  Box,
  Divider,
  Text,
  Group,
  Card,
  UnstyledButton,
  Button,
  createStyles,
  Input,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { CopyToClipboard } from "react-copy-to-clipboard";

import SectionHeader from "../layout/SectionHeader";

import Swal from "sweetalert2";

import SpaceSettingModel from "../../../../models/SpaceSetting";
import MemberModel from "../../../../models/Member";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colors.gray[0],
  },
  //
  // inner: {
  //   display: 'flex',
  //
  //   [theme.fn.smallerThan(350)]: {
  //     flexDirection: 'column',
  //   },
  // },
  //
  // ring: {
  //   flex: 1,
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //
  //   [theme.fn.smallerThan(350)]: {
  //     justifyContent: 'center',
  //     marginTop: theme.spacing.md,
  //   },
  // },

  metricItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    padding: 10,
    backgroundColor: theme.colors.gray[0],
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: `${theme.shadows.xs} !important`,
      backgroundColor: theme.white,
      transform: "scale(1.05)",
    },
  },
  customLinkRight: {
    backgroundColor: theme.colors.gray[2],
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 5,
    height: 36,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    borderLeft: `1px solid ${theme.colors.gray[4]}`,
    color: theme.colors.gray[7],
  },
  customLinkLeft: {
    backgroundColor: theme.colors.gray[2],
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 5,
    height: 36,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    borderRight: `1px solid ${theme.colors.gray[4]}`,
    color: theme.colors.gray[7],
  },
  accordionTitle: {
    fontWeight: 800,
    fontSize: 40,
    color: "red",
    root: {
      fontWeight: 800,
      fontSize: 40,
    },
    child: {
      fontWeight: 800,
      fontSize: 40,
    },
    "& > div > div": {
      fontWeight: "800 !important",
      fontSize: "40px !important",
    },
  },
  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },
}));

export default function Top({ setInviteOpened }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useMantineTheme();
  const { classes } = useStyles({ theme });
  const space = useSelector((state) => state.space);
  const [editLink, setEditLink] = useState(false);
  const [opened, setOpened] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  const [urlHandle, setUrlHandle] = useState("");
  const [sharebleLink, setSharebleLink] = useState("");

  const smallScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );

  const [counts, setCounts] = useState({
    all: 0,
    active: 0,
    pending: 0,
    managers: 0,
    interested: 0,
    invitations: 0,
    payments: 0,
    earnings: 0,
    formatted_earnings: "$0",
  });

  useEffect(() => {
    if (!space.settings) {
      return;
    }

    setUrlHandle(space.url_handle);
    setSharebleLink(space.shareble_link);
    onFetchCounts();

    setValidatePremium(
      space && space.permissions && space.permissions.can_edit_shareable_link
    );
  }, [space]);

  useEffect(() => {
    if (editLink && !validatePremium[0]) {
      setEditLink(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
    }
  }, [editLink]);

  const onUpdateUrlHandle = () => {
    // const urlHandle = document.getElementById("url_handle").value;

    SpaceSettingModel.onUpdateUrlHandle(
      space.slug,
      urlHandle,
      (data) => {
        setEditLink(false);
        setSharebleLink(data.shareable_link);
        Swal.fire(data.title, data.message, "success");
      },
      (data) => {
        Swal.fire(data.title, data.message, "error");
      }
    );
  };

  const onCopyLink = () => {
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  const goToMembers = (stat) => {
    let link = `/space/${space.slug}/settings/members?section=${stat.section}`;
    if (stat.scope) {
      link += `?scope=${stat.scope}`;
    }
    navigate(link);
  };

  const onFetchCounts = () => {
    MemberModel.onFetchCounts(
      space.slug,
      (data) => {
        setCounts(data);
      },
      (data) => {}
    );
  };

  const getStats = () => {
    const keys = Object.keys(counts);
    const stats = [];

    if (counts.active > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label11"),
        value: counts.active,
        section: "registered",
        scope: "all",
      });
    }
    if (counts.pending > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label12"),
        value: counts.pending,
        section: "registered",
        scope: "pending",
      });
    }
    if (counts.interested > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label13"),
        value: counts.interested,
        section: "interested",
      });
    }
    if (counts.invitations > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label14"),
        value: counts.invitations,
        section: "invited",
      });
    }
    if (counts.earnings > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label15"),
        value: counts.formatted_earnings,
        section: "payments",
      });
    }

    return stats;
  };

  return (
    <>
      <SectionHeader
        spaceId={space.slug}
        spaceVersion={space.version}
        spaceName={space.name}
        title={t("spaces.settings.main.title")}
        rightSide={
          <Group>
            <Button
              variant="filled"
              radius="xl"
              onClick={(event) => setInviteOpened(true)}
              leftIcon={<FontAwesomeIcon icon={solid("user-plus")} />}
              sx={{
                backgroundColor: theme.colors.sutrablue[3],
                "&:hover": {
                  backgroundColor: theme.colors.sutrablue[4],
                },
              }}
            >
              {t("spaces.settings.main.main.invite")}
            </Button>
          </Group>
        }
      />

      <Card withBorder p="lg" mb={10} radius="md" className={classes.card}>
        <div>
          <Group
            position={smallScreen ? "center" : "apart"}
            sx={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box>
              <Group position="apart" noWrap>
                <Text weight={500} align={smallScreen ? "center" : "left"}>
                  {t("spaces.settings.main.main.shareableLink")}
                </Text>
                {editLink ? (
                  <Group spacing={10}>
                    <Button
                      variant="outline"
                      color="red"
                      radius="xl"
                      size="xs"
                      compact
                      onClick={() => setEditLink(false)}
                    >
                      {t("spaces.settings.main.main.cancelLink")}
                    </Button>
                    <Button
                      variant="outline"
                      color="blue"
                      radius="xl"
                      size="xs"
                      compact
                      onClick={() => onUpdateUrlHandle()}
                    >
                      {t("spaces.settings.main.main.saveLink")}
                    </Button>
                  </Group>
                ) : (
                  <Button
                    variant="outline"
                    color="dark"
                    radius="xl"
                    size="xs"
                    compact
                    onClick={() => setEditLink(true)}
                  >
                    {t("spaces.settings.main.main.editLinkButton")}
                  </Button>
                )}
              </Group>
              {editLink ? (
                <>
                  <Group spacing={0} mt={5} mb={5}>
                    <Box className={classes.customLinkRight}>
                      <Text>https://</Text>
                    </Box>
                    <Input
                      // id="url_handle"
                      value={urlHandle}
                      onChange={(event) =>
                        setUrlHandle(event.currentTarget.value)
                      }
                      placeholder={t("spaces.settings.main.main.subdomain")}
                      radius={0}
                      sx={(theme) => ({
                        width: 140,
                      })}
                    />
                    <Box className={classes.customLinkLeft}>
                      <Text>{t("spaces.settings.main.main.sutra_co")}</Text>
                    </Box>
                  </Group>
                </>
              ) : (
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={t("spaces.settings.main.main.label1")}
                  opened={copiedToClipboard}
                  position="bottom"
                  withArrow
                >
                  <CopyToClipboard
                    onCopy={() => onCopyLink()}
                    text={sharebleLink}
                  >
                    <Text
                      size="lg"
                      color="dimmed"
                      align={smallScreen ? "center" : "left"}
                    >
                      {`${sharebleLink}`}
                    </Text>
                  </CopyToClipboard>
                </Tooltip>
              )}
            </Box>
            {smallScreen ? (
              <Divider
                sx={(theme) => ({
                  borderTopColor: theme.colors.gray[2],
                  width: "100%",
                })}
              />
            ) : (
              ""
            )}

            <Group spacing={5} position={smallScreen ? "center" : "right"}>
              {getStats().map((stat) => (
                <UnstyledButton
                  key={stat.label}
                  className={classes.metricItem}
                  onClick={() => goToMembers(stat)}
                >
                  <Text weight={700} align="center">
                    {stat.value}
                  </Text>
                  <Text size="sm" color="dimmed">
                    {stat.label}
                  </Text>
                </UnstyledButton>
              ))}
            </Group>
          </Group>
        </div>
      </Card>
    </>
  );
}
