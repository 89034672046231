import React, { useState } from 'react';

import {
  Text,
  Textarea,
  Box,
  Button,
  Divider
} from '@mantine/core';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

export default function InvitePeople(props) {

  const { t, i18n } = useTranslation();

  const space = useSelector(state => state.space);

  const onCopy = () => {
    Swal.fire("Done", "Text copied to clipboard", "success")
  }

  if (space && space.slug && space.parents.length > 0){
    return null
  }

  return (
    <Box mt={5} pb={20}>
      {/* if space is top level open or paid, or public open or paid */}
      {(space && space.slug && (space.privacy_level != "private")) ? (
        <>
        <Text size="md" sx={{fontWeight: 500}}>
          {"Share link"}
        </Text>
        <Text size="md" color="dimmed">
          {t("spaces.settings.members.inviteLink.invite.shareLinkText")}
        </Text>

        <Text
          size="lg"
          sx={(theme) => ({
            marginTop: 8,
            backgroundColor: theme.colors.gray[1],
            color: theme.colors.dark[4],
            borderRadius: "5px",
            padding: "8px 15px",
            "&:hover": {
              backgroundColor: theme.colors.gray[2],
              cursor: "pointer"
            }
          })}
          >
          <CopyToClipboard
            onCopy={() => onCopy()}
            text={space.shareble_link}>
            <span>
              {space.shareble_link}
            </span>
          </CopyToClipboard>
        </Text>
        </>
      ) : (
        <Text size="md">
          {"This space is private and people must be invited to access it."}
        </Text>
      )}

    </Box>
  )
}
