import React, { useState, useEffect, useRef } from "react";

import {
  Divider,
  Text,
  Table,
  Group,
  Center,
  Button,
  Box,
  Switch,
  TextInput,
  Checkbox,
  Loader,
  Pagination
} from "@mantine/core";

import { DateRangePicker } from "@mantine/dates";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Sorter from "./Sorter";
import Row from "./ParticipationActivityRow";
import MemberModel from "../../../../models/Member";
import ManagerModel from "../../../../models/Manager";

import PremiumModal from "../../../app/PremiumModal";

const loader = {
  loading: false,
  oldScope: "all",
};

export default function ParticipationActivity(props) {
  const paginator = useRef({
    page: 1,
    per: 30,
  });

  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const location = useLocation();
  // const theme = useMantineTheme();
  const theme = props.theme;
  const { height, width } = useViewportSize();

  const [members, setMembers] = useState({
    total: 0,
    page: 1,
    pages: 1,
    data: [],
  });
  const [scope, setScope] = useState(null);
  const [term, setTerm] = useState(null);
  const [counts, setCounts] = useState({
    all: 0,
    active: 0,
    pending: 0,
    managers: 0,
  });
  const [value, setValue] = useState(["", ""]);
  const [showWords, setShowWords] = useState(false);
  const [orderBy, setOrderBy] = useState({ by: "name", as: "asc" });

  const largeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.lg}px)`
  );
  const extraLargeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );

  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  const timeoutId = useRef();
  const noMore = useRef();

  const onSelect = (id) => {
    const newSelected = [...selected];
    const index = selected.indexOf(id);
    if (index >= 0) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  const onSelectAll = (all) => {
    const newSelected = [];
    if (all) {
      for (let i = 0; i < members.data.length; i++) {
        newSelected.push(members.data[i].username);
      }
    }
    setSelected(newSelected);
  };

  const areAllSelected = () => {
    return members.data.length == selected.length;
  };

  const hasSelections = () => {
    return selected.length > 0;
  };

  const isAnySelected = () => {
    return !areAllSelected() && selected.length > 0;
  };

  const isSelected = (id) => {
    return selected.indexOf(id) >= 0;
  };

  const tableWidth = () => {
    if (largeScreen) {
      return width - 300;
    } else if (extraLargeScreen) {
      return width - 500;
    } else {
      return "";
    }
  };

  const canAddNewManagers = () => {
    const valid =
      counts.managers < space.permissions.max_allowed_managers[0] ||
      space.permissions.max_allowed_managers[0] == -1;
    if (!valid) {
      setOpened([
        true,
        space.permissions.max_allowed_managers[1],
        space.permissions.max_allowed_managers[2],
      ]);
    }
    return valid;
  };

  useEffect(() => {
    if (space && space.slug) {
      paginator.current.page = 1;
      onFetch();
      // onFetchCounts();
    }
  }, [space, scope]);

  useEffect(() => {
    if (space && space.slug) {
      if (value && value[0] && value[1]) {
        props.onSearchByDateRange(value, term, paginator.current.page);
      } else {
        onFetch();
      }
    }
  }, [term]);

  useEffect(() => {
    if (value && value[0] && value[1]) {
      props.onSearchByDateRange(
        value,
        document.getElementById("search-input").value,
        paginator.current.page
      );
    } else {
      onFetch();
    }
  }, [value]);

  useEffect(() => {
    // paginator.current.page = 1;
    if (
      props.channelData &&
      props.channelData.data &&
      props.channelData.data.length > 0
    ) {
      setMembers({ ...props.channelData });
      props.setChannelData({});
      return;
    }
    // if (props.refresh) {
    //   onFetch(props.refresh);
    //   // onFetchCounts();
    //   props.setRefresh(false);
    // }
  }, [props]);

  const onFetch = (refresh = null) => {
    setLoading(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      if (refresh == "more-participationactivity") {
      }

      if (loader.oldScope != scope) {
        paginator.current.page = 1;
        loader.oldScope = scope;
      }
      const Model = scope != "managers" ? MemberModel : ManagerModel;
      Model.onFetch(
        space.slug,
        paginator.current,
        { scope, term },
        (data) => {
          setLoading(false);
          const newData = { ...data };
          if (paginator.current.page == 1) {
            newData.data = [].concat(data.data);
            paginator.current.page = 2;
          } else {
            newData.data = members.data.concat(data.data);
            paginator.current.page++;
          }
          if (data.data.length == 0 && paginator.current.page > 1) {
            paginator.current.page--;
            noMore.current = true;
            setTimeout(() => {
              noMore.current = false;
            }, 10000);
          }
          setMembers(newData);
        },
        (data) => {
          setLoading(false);
        }
      );
    }, 1000);
  };

  const onFetchCounts = () => {
    MemberModel.onFetchCounts(
      space.slug,
      (data) => {
        setCounts(data);
      },
      (data) => {}
    );
  };

  const onKeyPress = (event) => {
    onKeyGeneral(event);
  };
  const onKeyDown = (event) => {
    onKeyGeneral(event);
  };

  const onKeyGeneral = (event) => {
    if (event.which == 13) {
      event.preventDefault();
      onSearch();
    }
  };

  const onSearch = () => {
    setTerm(document.getElementById("search-input").value);
  };

  const onClearSearch = () => {
    document.getElementById("search-input").value = "";
    setTerm("");
  };

  const changePage = (page) => {
    paginator.current.page = page;
    onFetch();
  };

  const onMemberUpdate = (member) => {
    onFetchCounts();
  };

  const onSort = (sortBy) => {
    const aux = { ...orderBy };
    aux.by = sortBy;
    aux.as = aux.as == "asc" ? "desc" : "asc";
    setOrderBy(aux);

    const auxMembers = { ...members };
    if (aux.as == "asc") {
      auxMembers.data = auxMembers.data.sort((a, b) => {
        const nameA =
          typeof a[getSortKey(sortBy)] === "string"
            ? a[getSortKey(sortBy)].toUpperCase()
            : a[getSortKey(sortBy)];
        const nameB =
          typeof b[getSortKey(sortBy)] === "string"
            ? b[getSortKey(sortBy)].toUpperCase()
            : b[getSortKey(sortBy)];

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0; // names must be equal
      });
    } else {
      auxMembers.data = auxMembers.data.sort((a, b) => {
        const nameA =
          typeof a[getSortKey(sortBy)] === "string"
            ? a[getSortKey(sortBy)].toUpperCase()
            : a[getSortKey(sortBy)];
        const nameB =
          typeof b[getSortKey(sortBy)] === "string"
            ? b[getSortKey(sortBy)].toUpperCase()
            : b[getSortKey(sortBy)];

        if (nameA < nameB) {
          return 1;
        }

        if (nameA > nameB) {
          return -1;
        }

        return 0; // names must be equal
      });
    }

    console.log(auxMembers.data);
    console.log(getSortKey(sortBy));
    console.log(aux);
    setMembers(auxMembers);
  };

  const getSortKey = (sortBy) => {
    return sortBy == "name"
      ? "full_name"
      : sortBy == "participation"
      ? showWords
        ? "words_count"
        : "posts_count"
      : sortBy;
  };

  return (
    <div>
      <Group position="apart" mt={20}>
        <Group position="left" spacing="xs">
          <TextInput
            icon={<FontAwesomeIcon icon={solid("search")} />}
            placeholder={t(
              "spaces.settings.members.participationActivity.participation.searchPlaceholder"
            )}
            id="search-input"
            radius="xl"
            onKeyPress={(event) => onKeyPress(event)}
            onKeyDown={(event) => onKeyDown(event)}
            rightSection={
              term ? (
                <Text
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => onClearSearch()}
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </Text>
              ) : (
                <Text>{"  "}</Text>
              )
            }
          />
          <Button
            variant="filled"
            radius="xl"
            onClick={(event) => onSearch()}
            sx={{
              backgroundColor: theme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: theme.colors.sutrablue[4],
              },
            }}
          >
            {t(
              "spaces.settings.members.participationActivity.participation.searchButton"
            )}
          </Button>
          {loading && <Loader size="xs" />}
        </Group>

        <Group position="right" spacing="xs">
          {!hasSelections() && (
            <DateRangePicker
              radius="xl"
              sx={{ width: 300 }}
              placeholder={t(
                "spaces.settings.members.participationActivity.participation.pickDatePlaceholder"
              )}
              value={value}
              onChange={setValue}
            />
          )}
          {hasSelections() && (
            <>
              <Button
                radius="xl"
                sx={{
                  backgroundColor: theme.colors.sutrablue[3],
                  "&:hover": {
                    backgroundColor: theme.colors.sutrablue[4],
                  },
                }}
                onClick={(event) =>
                  props.onSendBroadcast("email", members.data, selected)
                }
              >
                {t(
                  "spaces.settings.members.participationActivity.participation.emailButton"
                )}
              </Button>
              <Button
                radius="xl"
                sx={{
                  backgroundColor: theme.colors.sutrablue[3],
                  "&:hover": {
                    backgroundColor: theme.colors.sutrablue[4],
                  },
                }}
                onClick={(event) =>
                  props.onSendBroadcast("message", members.data, selected)
                }
              >
                {t(
                  "spaces.settings.members.participationActivity.participation.messageButton"
                )}
              </Button>
            </>
          )}
        </Group>
      </Group>

      <Box mt={10} style={{ width: tableWidth() }}>
        {members.data.length == 0 && !loader.loading ? (
          <Box
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              textAlign: "center",
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
              cursor: "pointer",

              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[5]
                    : theme.colors.gray[1],
              },
            })}
          >
            {t(
              "spaces.settings.members.participationActivity.participation.noRecordFound"
            )}
          </Box>
        ) : (
          <>
            <Table verticalSpacing="xs" highlightOnHover>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      checked={areAllSelected()}
                      indeterminate={isAnySelected()}
                      onChange={(event) =>
                        onSelectAll(event.currentTarget.checked)
                      }
                    />
                  </th>
                  <th>
                    <Group>
                      {t(
                        "spaces.settings.members.participationActivity.memberTable.member"
                      )}{" "}
                      <Sorter
                        orderBy={orderBy}
                        onSort={onSort}
                        sortBy={"name"}
                      />
                    </Group>
                  </th>
                  <th>
                    <Group>
                      {t(
                        "spaces.settings.members.participationActivity.memberTable.participation"
                      )}{" "}
                      <Sorter
                        orderBy={orderBy}
                        onSort={onSort}
                        sortBy={"participation"}
                      />
                      <Group
                        sx={{
                          padding: "4px 10px",
                          border: "solid 1px #ddd",
                          borderRadius: 10,
                        }}
                      >
                        {t(
                          "spaces.settings.members.participationActivity.memberTable.posts"
                        )}
                        <Switch
                          checked={showWords}
                          onChange={(event) =>
                            setShowWords(event.currentTarget.checked)
                          }
                        />
                        {t(
                          "spaces.settings.members.participationActivity.memberTable.words"
                        )}
                      </Group>
                    </Group>
                  </th>
                </tr>
              </thead>
              <tbody>
                {members.data.map((member) => {
                  return (
                    <Row
                      hasSubscriptions={
                        space && space.permissions
                          ? space.permissions.can_accept_charges
                          : false
                      }
                      spaceId={space.slug}
                      onUpdate={onMemberUpdate}
                      member={member}
                      canAddNewManagers={canAddNewManagers}
                      counts={props.counts}
                      showWords={showWords}
                      isSelected={isSelected}
                      onSelect={onSelect}
                    />
                  );
                })}
              </tbody>
            </Table>
            {loading && !noMore.current && (
              <Center mb={20}>
                <Loader />
              </Center>
            )}
          </>
        )}
      </Box>
      <Divider sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: 20,
          justifyContent: "center",
        }}
      >
        <Pagination
          color="gray"
          page={paginator.page}
          onChange={changePage}
          total={members.pages}
        />
      </Box>

      <PremiumModal opened={opened} setOpened={setOpened} />
    </div>
  );
}
