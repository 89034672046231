import { combineReducers } from 'redux';
import defaultSpaceColors from '../collections/defaultSpaceColors';

const SET_SPACE = 'SET_SPACE';
const SET_SPACE2 = 'SET_SPACE2';
const SET_USER = 'SET_USER';
const SET_PERMISSIONS = 'SET_PERMISSIONS';
const SET_ALERT = 'SET_ALERT';
const SET_SIDEBAR_DRAGGING = 'SET_SIDEBAR_DRAGGING';
const SET_SIDEBAR_DRAGGED_OVER = 'SET_SIDEBAR_DRAGGED_OVER';
const SET_SCROLL = 'SET_SCROLL';
const SET_TIPTAP = 'SET_TIPTAP';
const SET_TIPTAP_SIDEMENU = 'SET_TIPTAP_SIDEMENU';
const SET_MAIN_ACTIONS = 'SET_MAIN_ACTIONS';
const SET_COUNTERS = 'SET_COUNTERS';
const SET_COLORS = 'SET_COLORS';
const SET_COLORS2 = 'SET_COLORS2';
const SET_SIDEBAR_CHILDREN_A = 'SET_SIDEBAR_CHILDREN_A';
const SET_SIDEBAR_CHILDREN_B = 'SET_SIDEBAR_CHILDREN_B';
const SET_FIXED_HEADER_STATUS = 'SET_FIXED_HEADER_STATUS';
const SET_TREE = 'SET_TREE';
const SET_CHILDREN = 'SET_CHILDREN';
const SET_PIN_MESSAGE = 'SET_PIN_MESSAGE';
const SET_CALENDAR = 'SET_CALENDAR';
const SET_INTERACTIONS = 'SET_INTERACTIONS';
const SET_SETTINGS_TABS = 'SET_SETTINGS_TABS';
const SET_PATH = 'SET_PATH';
const SET_PATHS = 'SET_PATHS';
const SET_SETTINGS = 'SET_SETTINGS';
const SET_JOURNEYS = 'SET_JOURNEYS';
const SET_SURVEY = 'SET_SURVEY';
const SET_COMPLETED = 'SET_COMPLETED';
const SET_SPACE_LOADING = 'SET_SPACE_LOADING';
const SET_NEO4j_VERSION = 'SET_NEO4j_VERSION';
const SET_CONNECTIONS = 'SET_CONNECTIONS';

const SET_HOVER_SIDEBAR = 'SET_HOVER_SIDEBAR';
const SET_HIDDEN_SIDEBAR = 'SET_HIDDEN_SIDEBAR';
const SET_HIDDEN_HEADER = 'SET_HIDDEN_HEADER';
const SET_HIDDEN_COVER = 'SET_HIDDEN_COVER';
const SET_HIDDEN_TITLE = 'SET_HIDDEN_TITLE';
const SET_HIDDEN_BREADCRUMBS = 'SET_HIDDEN_BREADCRUMBS';
const SET_WIDE_WIDTH = 'SET_WIDE_WIDTH';
const SET_LIST_SPACE_TO_UPDATE = 'SET_LIST_SPACE_TO_UPDATE';
const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
const SET_FORCE_LAYOUT_UPDATE = 'SET_FORCE_LAYOUT_UPDATE';
const SET_SELECTED_DISCUSSION_TAGS = 'SET_SELECTED_DISCUSSION_TAGS';

export function setSelectedDiscussionTags(tags) {
  return {
    type: SET_SELECTED_DISCUSSION_TAGS,
    tags,
  }
}

export function setNeo4jVersion(neo4jVersion) {
  return {
    type: SET_NEO4j_VERSION,
    neo4jVersion,
  }
}

export function setConnections(connections) {
  return {
    type: SET_CONNECTIONS,
    connections,
  }
}

export function setSpace(space) {
  return {
    type: SET_SPACE,
    space,
  }
}

export function setSpace2(space2) {
  return {
    type: SET_SPACE2,
    space2,
  }
}


export function setUser(user) {
  window.$currentUserAuthToken = user.authentication_token;
  return {
    type: SET_USER,
    user,
  }
}

export function setAlert(alert) {
  return {
    type: SET_ALERT,
    alert,
  }
}

export function setCompleted(completed) {
  return {
    type: SET_COMPLETED,
    completed,
  }
}

export function setSpaceLoading(loading) {
  return {
    type: SET_SPACE_LOADING,
    loading,
  }
}

export function setSettingsTabs(settingsTabs) {
  return {
    type: SET_SETTINGS_TABS,
    settingsTabs,
  }
}

export function setPath(path) {
  return {
    type: SET_PATH,
    path,
  }
}

export function setPaths(paths) {
  return {
    type: SET_PATHS,
    paths,
  }
}

export function setSettings(settings) {
  return {
    type: SET_SETTINGS,
    settings,
  }
}

export function setJourneys(journeys) {
  return {
    type: SET_JOURNEYS,
    journeys,
  }
}

export function setSurvey(survey) {
  return {
    type: SET_SURVEY,
    survey,
  }
}




export function setPermissions(permissions) {
  return {
    type: SET_PERMISSIONS,
    permissions,
  }
}

export function setPinMessage(message) {
  return {
    type: SET_PIN_MESSAGE,
    message,
  }
}

export function setCalendar(calendar) {
  return {
    type: SET_CALENDAR,
    calendar,
  }
}

export function setInteractions(interactions) {
  return {
    type: SET_INTERACTIONS,
    interactions,
  }
}

export function setSidebarDragging(node) {
  return {
    type: SET_SIDEBAR_DRAGGING,
    node,
  }
}

export function setSidebarDraggedOver(node) {
  return {
    type: SET_SIDEBAR_DRAGGED_OVER,
    node,
  }
}

export function setScroll(scroll) {
  return {
    type: SET_SCROLL,
    scroll,
  }
}

export function setTiptap(tiptap) {
  return {
    type: SET_TIPTAP,
    tiptap,
  }
}

export function setTiptapSidemenu(tiptap_sidemenu) {
  window.$tiptapSidemenu = tiptap_sidemenu.opened
  
  return {
    type: SET_TIPTAP_SIDEMENU,
    tiptap_sidemenu,
  }
}

export function setColors(colors=defaultSpaceColors) {
  return {
    type: SET_COLORS,
    colors,
  }
}

export function setColors2(colors2=defaultSpaceColors) {
  return {
    type: SET_COLORS2,
    colors2,
  }
}

export function setActions(mainActions) {
  const newActions = {...defaultMainActions, ...mainActions}

  return {
    type: SET_MAIN_ACTIONS,
    mainActions: newActions,
  }
}

export function setCounters(counters) {
  return {
    type: SET_COUNTERS,
    counters,
  }
}

export function setSidebarChildrenA(sidebar_children_a) {
  return {
    type: SET_COUNTERS,
    sidebar_children_a,
  }
}

export function setSidebarChildrenB(sidebar_children_b) {
  return {
    type: SET_COUNTERS,
    sidebar_children_b,
  }
}

export function setFixedHeaderStatus(fixed_header_status) {
  return {
    type: SET_FIXED_HEADER_STATUS,
    fixed_header_status,
  }
}

export function setTree(tree) {
  return {
    type: SET_TREE,
    tree,
  }
}

export function setChildren(tree) {
  return {
    type: SET_CHILDREN,
    tree,
  }
}

export function setHoverSidebar(hover) {
  return {
    type: SET_HOVER_SIDEBAR,
    hover,
  }
}

export function setHiddenSidebar(hidden) {
  return {
    type: SET_HIDDEN_SIDEBAR,
    hidden,
  }
}

export function setHiddenHeader(hidden) {
  return {
    type: SET_HIDDEN_HEADER,
    hidden,
  }
}

export function setHiddenCover(hidden) {
  return {
    type: SET_HIDDEN_COVER,
    hidden,
  }
}

export function setHiddenTitle(hidden) {
  return {
    type: SET_HIDDEN_TITLE,
    hidden,
  }
}

export function setHiddenBreadcrumbs(hidden) {
  return {
    type: SET_HIDDEN_BREADCRUMBS,
    hidden,
  }
}

export function setWideWidth(on) {
  return {
    type: SET_WIDE_WIDTH,
    on,
  }
}

export function setListSpaceToUpdate(update) {
  return {
    type: SET_LIST_SPACE_TO_UPDATE,
    update,
  }
}

export function setNewMessage(newMessage) {
  return {
    type: SET_NEW_MESSAGE,
    newMessage,
  }
}

export function setForceLayoutUpdate(forceLayoutUpdate) {
  return {
    type: SET_FORCE_LAYOUT_UPDATE,
    forceLayoutUpdate,
  }
}

const defaultSpace = {
  id: null,
  name: null,
  slug: null,
  url_handle: null,
};

const defaultUser = {
  id: null,
  authentication_token: null,
  username: null,
  name: null,
  permissions: null,
  authenticated: null,
  avatar: {
    url: null
  }
};

const defaultNode = { id: null }

const defaultAlert = {opened: false, title: "", message: "", color: "indigo"}

const defaultScroll = { atBottom: false, top: 0, scrollTop: 0 }

const defaultTiptap = { editor: null }

const defaultTiptapSidemenu = {opened: null}

const defaultMainActions = { editPage: false, editRegPage: false, invite: false, manage: false }

const defaultCounters = { direct_messages_count: 0, notifications_count: 0 }

const defaultColors = defaultSpaceColors

const defaultEmpty = {}
const defaultChildrenA = {}
const defaultChildrenB = {}
const defaultFixedHeaderStatus = {show: false}
const defaultTree = {slug: null, children: [], name: "Loading", loading: true}
const defaultHidden = false
const defaultHover = false
const defaultListSpaceToUpdate = {slug: null}
const defaultNewMessage = {show: false}
const defaultForceLayoutUpdate = {update: false}
const defaultOn = {on: false}
const defaultPermissions = {}
const defaultLoading = {loading: false}
const defaultCompleted = {complete: false}
const defaultVersion = {version: "loading"}
const defaultConnections = {connections: []}
const defaultTags = {tags: []}

function selectedDiscussionTags(state=defaultTags, action) {
  switch (action.type) {
    case SET_SELECTED_DISCUSSION_TAGS:
    return {
      ...action.tags,
    }
    default:
      return state;
  }
}

function neo4jVersion(state=defaultVersion, action) {
  switch (action.type) {
    case SET_NEO4j_VERSION:
    return {
      ...action.neo4jVersion,
    }
    default:
      return state;
  }
}

function connections(state=defaultConnections, action) {
  switch (action.type) {
    case SET_CONNECTIONS:
    return {
      ...action.connections,
    }
    default:
      return state;
  }
}

function space(state=defaultSpace, action) {
  switch (action.type) {
    case SET_SPACE:
    return {
      ...action.space,
    }
    default:
      return state;
  }
}

function space2(state=defaultSpace, action) {
  switch (action.type) {
    case SET_SPACE2:
    return {
      ...action.space2,
    }
    default:
      return state;
  }
}

function user(state=defaultUser, action) {
  switch (action.type) {
    case SET_USER:
    return {
      ...action.user,
      authenticated: action.user.id && action.user.authentication_token ? true : false
    }
    default:
      return state;

  }
}

function completed(state=defaultCompleted, action) {
  switch (action.type) {
    case SET_COMPLETED:
    return {
      ...action.completed,
    }
    default:
      return state;

  }
}

function spaceLoading(state=defaultLoading, action) {
  switch (action.type) {
    case SET_SPACE_LOADING:
    return {
      ...action.loading,
    }
    default:
      return state;

  }
}

function permissions(state=defaultPermissions, action) {
  switch (action.type) {
    case SET_PERMISSIONS:
    return {
      ...action.permissions,
    }
    default:
      return state;

  }
}

function settingsTabs(state=defaultEmpty, action) {
  switch (action.type) {
    case SET_SETTINGS_TABS:
    return {
      ...action.settingsTabs,
    }
    default:
      return state;

  }
}

function path(state=defaultEmpty, action) {
  switch (action.type) {
    case SET_PATH:
    return {
      ...action.path,
    }
    default:
      return state;

  }
}

function paths(state=defaultEmpty, action) {
  switch (action.type) {
    case SET_PATHS:
    return {
      ...action.paths,
    }
    default:
      return state;

  }
}

function settings(state=defaultEmpty, action) {
  switch (action.type) {
    case SET_SETTINGS:
    return {
      ...action.settings,
    }
    default:
      return state;

  }
}

function journeys(state=defaultEmpty, action) {
  switch (action.type) {
    case SET_JOURNEYS:
    return {
      ...action.journeys,
    }
    default:
      return state;

  }
}

function survey(state=defaultEmpty, action) {
  switch (action.type) {
    case SET_SURVEY:
    return {
      ...action.survey,
    }
    default:
      return state;

  }
}

function pinMessage(state=defaultEmpty, action) {
  switch (action.type) {
    case SET_PIN_MESSAGE:
    return {
      ...action.message,
    }
    default:
      return state;

  }
}

function calendar(state=defaultEmpty, action) {
  switch (action.type) {
    case SET_CALENDAR:
    return {
      ...action.calendar,
    }
    default:
      return state;

  }
}

function interactions(state=defaultEmpty, action) {
  switch (action.type) {
    case SET_INTERACTIONS:
    return {
      ...action.interactions,
    }
    default:
      return state;

  }
}

function alert(state=defaultAlert, action) {
  switch (action.type) {
    case SET_ALERT:
    return {
      ...action.alert,
    }
    default:
      return state;
  }
}

function sidebarDragging(state=defaultNode, action) {
  switch (action.type) {
    case SET_SIDEBAR_DRAGGING:
    return {
      ...action.node,
    }
    default:
      return state;
  }
}

function sidebarDraggedOver(state=defaultNode, action) {
  switch (action.type) {
    case SET_SIDEBAR_DRAGGED_OVER:
    return {
      ...action.node,
    }
    default:
      return state;
  }
}

function scroll(state=defaultScroll, action) {
  switch (action.type) {
    case SET_SCROLL:
    return {
      ...action.scroll,
    }
    default:
      return state;
  }
}

function tiptap(state=defaultTiptap, action) {
  switch (action.type) {
    case SET_TIPTAP:
    return {
      ...action.tiptap,
    }
    default:
      return state;
  }
}

function tiptapSidemenu(state=defaultTiptapSidemenu, action) {
  switch (action.type) {
    case SET_TIPTAP_SIDEMENU:
    return {
      ...action.tiptap_sidemenu,
    }
    default:
      return state;
  }
}

function mainActions(state=defaultMainActions, action) {
  switch (action.type) {
    case SET_MAIN_ACTIONS:
    return {
      ...action.mainActions,
    }
    default:
      return state;
  }
}

function counters(state=defaultCounters, action) {
  switch (action.type) {
    case SET_COUNTERS:
    return {
      ...action.counters,
    }
    default:
    return state;
  }
}

function colors(state=defaultColors, action) {
  switch (action.type) {
    case SET_COLORS:
    return {
      ...action.colors,
    }
    default:
      return state;
  }
}

function colors2(state=defaultColors, action) {
  switch (action.type) {
    case SET_COLORS2:
    return {
      ...action.colors2,
    }
    default:
      return state;
  }
}

function sidebarChildrenA(state=defaultChildrenA, action) {
  switch (action.type) {
    case SET_SIDEBAR_CHILDREN_A:
    return {
      ...action.sidebar_children_a,
    }
    default:
      return state;
  }
}

function sidebarChildrenB(state=defaultChildrenB, action) {
  switch (action.type) {
    case SET_SIDEBAR_CHILDREN_B:
    return {
      ...action.sidebar_children_b,
    }
    default:
      return state;
  }
}

function fixedHeaderStatus(state=defaultFixedHeaderStatus, action) {
  switch (action.type) {
    case SET_FIXED_HEADER_STATUS:
    return {
      ...action.fixed_header_status,
    }
    default:
      return state;
  }
}

function tree(state=defaultTree, action) {
  switch (action.type) {
    case SET_TREE:
    return {
      ...action.tree,
    }
    default:
      return state;
  }
}

function children(state=defaultTree, action) {
  switch (action.type) {
    case SET_CHILDREN:
    return {
      ...action.tree,
    }
    default:
      return state;
  }
}

function hoverSidebar(state=defaultHover, action) {
  switch (action.type) {
    case SET_HOVER_SIDEBAR:
    return {
      ...action.hover,
    }
    default:
      return state;
  }
}

function hiddenSidebar(state=defaultHidden, action) {
  switch (action.type) {
    case SET_HIDDEN_SIDEBAR:
    return {
      ...action.hidden,
    }
    default:
      return state;
  }
}

function hiddenHeader(state=defaultHidden, action) {
  switch (action.type) {
    case SET_HIDDEN_HEADER:
    return {
      ...action.hidden,
    }
    default:
      return state;
  }
}

function hiddenCover(state=defaultHidden, action) {
  switch (action.type) {
    case SET_HIDDEN_COVER:
    return {
      ...action.hidden,
    }
    default:
      return state;
  }
}

function hiddenTitle(state=defaultHidden, action) {
  switch (action.type) {
    case SET_HIDDEN_TITLE:
    return {
      ...action.hidden,
    }
    default:
      return state;
  }
}

function hiddenBreadcrumbs(state=defaultHidden, action) {
  switch (action.type) {
    case SET_HIDDEN_BREADCRUMBS:
    return {
      ...action.hidden,
    }
    default:
      return state;
  }
}

function wideWidth(state=defaultOn, action) {
  switch (action.type) {
    case SET_WIDE_WIDTH:
    return {
      ...action.on,
    }
    default:
      return state;
  }
}

function listSpaceToUpdate(state=defaultListSpaceToUpdate, action) {
  switch (action.type) {
    case SET_LIST_SPACE_TO_UPDATE:
    return {
      ...action.update,
    }
    default:
      return state;
  }
}

function newMessage(state=defaultNewMessage, action) {
  switch (action.type) {
    case SET_NEW_MESSAGE:
    return {
      ...action.newMessage,
    }
    default:
      return state;
  }
}

function forceLayoutUpdate(state=defaultForceLayoutUpdate, action) {
  switch (action.type) {
    case SET_FORCE_LAYOUT_UPDATE:
    return {
      ...action.setForceLayoutUpdate,
    }
    default:
      return state;
  }
}

const appStore = combineReducers({
  space,
  space2,
  user,
  alert,
  sidebarDragging,
  sidebarDraggedOver,
  scroll,
  tiptap,
  tiptapSidemenu,
  mainActions,
  counters,
  colors,
  colors2,
  sidebarChildrenA,
  sidebarChildrenB,
  fixedHeaderStatus,
  tree,
  children,
  hoverSidebar,
  hiddenSidebar,
  hiddenHeader,
  hiddenCover,
  hiddenTitle,
  hiddenBreadcrumbs,
  listSpaceToUpdate,
  newMessage,
  forceLayoutUpdate,
  wideWidth,
  permissions,
  pinMessage,
  calendar,
  interactions,
  settingsTabs,
  path,
  paths,
  settings,
  journeys,
  survey,
  spaceLoading,
  completed,
  neo4jVersion,
  connections,
  selectedDiscussionTags
});

export default appStore;
