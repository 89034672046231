import React, { useState, useEffect, useRef, memo } from "react";
import TiptapNoHP from "../../../tiptap/components/TiptapNoHP";

import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import HeaderImage from "../../HeaderImage";
import FixedHeader from "../../FixedHeader";
import Loading from "../static/Loading";

import { Stack, ScrollArea, createStyles, Button, Box } from "@mantine/core";

import trackMixpanel from "../../../../utils/TrackMixpanel";
import ViewModel from "../../../../models/View";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";

import { HocuspocusProvider } from "@hocuspocus/provider";
import { getHocuspocusHost, getEnv } from "../../../../utils/Host";
import * as Y from "yjs";

import { IndexeddbPersistence } from "y-indexeddb";

import _ from "lodash";
import TextAlign from "@tiptap/extension-text-align";
import BackgroundColor from "../../../tiptap/components/sidebar/BackgroundColor";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color,
    },
    ".ProseMirror .file-node svg": {
      stroke: colors.default_text_color,
    },
    ".ProseMirror .file-node a": {
      color: colors.default_text_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.default_text_color,
      backgroundColor: isSpaceThemed(colors)
        ? isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(8).toString()
          : tinycolor(colors.background_color).darken(8).toString()
        : tinycolor(colors.background_color).darken(2).toString(),
      border: isSpaceThemed(colors)
        ? `1px solid ${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(20).toString()
              : tinycolor(colors.background_color).darken(20).toString()
          }`
        : `1px solid #e9ecef`,
      "&:hover": {
        // backgroundColor: theme.colors.gray[0],
        backgroundColor: isSpaceThemed(colors)
          ? isDark(colors.background_color)
            ? tinycolor(colors.background_color).lighten(10).toString()
            : tinycolor(colors.background_color).darken(10).toString()
          : tinycolor(colors.background_color).darken(2).toString(),
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        cursor: "pointer",
        // color: "#000",
        textDecoration: "none",
      },
    },
  },
}));

let disconnectInterval = null;

const Content = memo((props) => {
  const user = useSelector((state) => state.colors);
  const colors = useSelector((state) => state.colors);

  const params = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const { classes, cx, theme } = useStyles({ colors });

  const viewport = useRef();
  const helpScouteLoaded = useRef();

  const [loaded, setLoaded] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showAbsScrollButton, setAbsShowScrollButton] = useState(true);
  const [tiptapKey, setTiptapKey] = useState(`tiptap-${new Date()}`);
  const content = useRef({});
  const lastSlug = useRef();
  const width = useRef();
  const synced = useRef();
  const refreshInterval = useRef();
  const scrollTimeoutIds = useRef([]);

  const provider = useRef({
    provider: null,
    status: null,
    documentName: null,
    editable: false,
    unSyncCount: 0,
    forcedDisconnected: false,
  });

  useEffect(() => {
    setLoaded(false);
    return () => {
      clearTimeout(refreshInterval.current);
      console.log("CONTENT READONLY UNMOUNT");
      setLoaded(false);
      window.Beacon("destroy");
      helpScouteLoaded.current = false;
      unMount();
    };
  }, []);

  useEffect(() => {
    console.log("CONTENT READONLY PARAMS");
    if (params.slug && lastSlug.current != params.slug) {
      lastSlug.current = params.slug;
      setLoaded(false);
      onFetch();
    }
  }, [params]);

  useEffect(() => {
    if (window.$currentUserAuthToken) {
      trackMixpanel(window.$currentUsername, "Content edited");

      // if (!helpScouteLoaded.current && window.$currentUsername) {
      //   helpScouteLoaded.current = true;

      //   setTimeout(() => {
      //     window.Beacon("init", "8baee135-5597-476a-8269-cb1460d638e4");

      //     window.Beacon("identify", {
      //       name: window.$currentName,
      //       email: window.$currentEmail,
      //       account_type: window.$currentAccountType,
      //     });
      //   }, 2000);
      // }
    }
  }, [user]);

  useEffect(() => {
    window.$contentScrollViewport = viewport.current;

    setTimeout(() => {
      const div = viewport.current;

      if (div && div.scrollHeight < 2000){
        setShowScrollButton(false);
        return
      }
      else{
        setShowScrollButton(true);
      }
    }, 500)

  }, [viewport.current]);

  useEffect(() => {
    const handleScroll = () => {
      if (location.hash && location.hash.indexOf("jumpTo") !== -1) {
        const uid = location.hash.replace("#jumpTo=", "");

        const checkElementAndScroll = () => {
          let element =
            document.querySelector(`[data-uid="${uid}"]`) ||
            document.querySelector(`[uid="${uid}"]`);

          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            window.history.replaceState(null, null, location.pathname);
            return true;
          }
          return false;
        };

        // Check immediately
        if (checkElementAndScroll()) return;

        // Use setInterval to keep checking periodically
        const intervalId = setInterval(() => {
          if (checkElementAndScroll()) {
            clearInterval(intervalId);
          }
        }, 1000);

        // Stop checking after 10 seconds
        const timeoutId = setTimeout(() => {
          clearInterval(intervalId);
        }, 10000);

        // Clear interval and timeout when the component unmounts
        return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };
      }
    };

    handleScroll();

    window.addEventListener("hashchange", handleScroll);

    return () => {
      window.removeEventListener("hashchange", handleScroll);
    };
  }, [location]);

  const onFetch = () => {
    ViewModel.onFetch(
      "tiptap",
      params.slug,
      (data) => {
        if (
          data.can_manage &&
          synced.current &&
          !_.isEqual(data.content, content.current)
        ) {
          // window.location.reload();
          return;
        }

        content.current = data.content;
        window.$spaceUserInteractions = data.interactions;
        width.current = data.view_width;
        window.$spaceWideWidth = data.view_width;
        window.$themeColors = { ...data.colors };
        window.$tiptapContentLastUpdatedAt = data.last_updated_at;
        // setTimeout(() => {

        //   if (data.can_manage){

        //     const newDocumentName = `circle-content-${params.slug}`;

        //     if (newDocumentName != provider.current.documentName) {
        //       provider.current.documentName = `circle-content-${params.slug}`;

        //       resetProvider();
        //     }
        //   }
        setLoaded(true);

        // }, 250);
      },
      (data) => {
        navigate(`/home/top`);
      }
    );
  };

  const unMount = () => {
    provider.current.documentName = null;
    provider.current.status = null;
    provider.current.editable = false;
    clearProvider();
  };

  const clearProvider = () => {
    if (provider.current.provider) {
      provider.current.provider.off("destroy");
      provider.current.provider.off("disconnect");
      provider.current.provider.off("connect");
      provider.current.provider.off("synced");

      provider.current.provider.disconnect();
      provider.current.provider.destroy();
    }
  };

  const resetProvider = () => {
    const yDocument = new Y.Doc();

    // new IndexeddbPersistence(provider.current.documentName, yDocument);

    clearProvider();

    provider.current.provider = new HocuspocusProvider({
      url: getHocuspocusHost(),
      name: provider.current.documentName,
      document: yDocument,
      token: window.$currentUserAuthToken,
      broadcast: getEnv() == "production",
      delay: 500,
      factor: 0,
      forceSyncInterval: 600,
    });

    provider.current.provider.on("synced", () => {
      console.log("MANAGER INDEXDB SYNC");
      synced.current = true;
      refreshInterval.current = setTimeout(() => {
        onFetch();
      }, 3000);
    });
  };

  const onScrollToBottom = () => {
    setTimeout(() => {
      const viewport = document.querySelector(
        "#main-scroll-area .mantine-ScrollArea-viewport"
      );
      
      if (viewport) {
        viewport.scrollTo({ top: viewport.scrollHeight });
      }
    }, 100);
  };

  const onScrollPositionChange = (pos) => {
    const div = viewport.current;

    clearScrollTimeouts();

    if (div.scrollHeight < 2000){
      setShowScrollButton(false);
      return
    }
    else{
      setShowScrollButton(true);
    }
    
    if (pos.y + div.clientHeight >= div.scrollHeight - 2000) {
      const scrollTimeoutId = setTimeout(() => {
        setAtBottom(true);
      }, 1000);
      scrollTimeoutIds.current.push(scrollTimeoutId);
    } else {
      // In between
      setAtBottom(false);
    }
  };

  const clearScrollTimeouts = () => {
    for (let i = 0; i < scrollTimeoutIds.current.length; i++) {
      const id = scrollTimeoutIds.current[i];
      clearTimeout(id);
    }
  };

  const onHideScrollButton = (event) => {
    event.stopPropagation();
    setAbsShowScrollButton(false)
  }

  if (!loaded) {
    return <Loading type="content" />;
  }

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea
        id="main-scroll-area"
        className="content-scrollview"
        style={{ height: "100%" }}
        viewportRef={viewport}
        onScrollPositionChange={onScrollPositionChange}
      >
        <HeaderImage />
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={[
            "breadcrumbs",
            "title",
            "calendar",
            "status",
            "interactions",
            "tag",
            "redirect",
            // "topActions"
          ]}
        />

        <TiptapNoHP
          key={tiptapKey}
          content={content.current.default}
          allowComments={true}
          spaceWidth={width.current}
          viewId={"tiptap"}
        />
        {!atBottom && showScrollButton && showAbsScrollButton && window.$spaceUserInteractions && (
          <Box
            sx={{
              position: "fixed",
              bottom: "20px",
              left: "0px",
              width: "100%",
              textAlign: "center",
              zIndex: 100,
            }}
          >
            <Button
              radius={"xl"}
              onClick={(event) => onScrollToBottom()}
              leftIcon={
                <FontAwesomeIcon icon={solid("arrow-down")} />
              }
              rightIcon={
                <FontAwesomeIcon icon={solid("close")} onClick={(event) => onHideScrollButton(event)} />
              }
            >
              {"Scroll to bottom"}
            </Button>
          </Box>
        )}
      </ScrollArea>
    </Stack>
  );
});

export default Content;
