import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import useSidebarStyles from "../../../utils/SidebarStyles";
import defaultSpaceColors from "../../../collections/defaultSpaceColors";

import tinycolor from "tinycolor2";

import { createStyles, Box, Text, Skeleton } from "@mantine/core";

const useStyles = createStyles((theme, { colors, settingsView }) => ({
  spaceNameWrap: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  spaceName: {
    paddingLeft: theme.other.appShellPadding,
    paddingRight: theme.other.appShellPadding,
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 25,
    lineHeight: 1.3,
    fontWeight: 400,
    width: "100%",
    display: "block",
    // color: colors.sidebar_text_color,
    color: !settingsView
      ? colors.sidebar_text_color
      : defaultSpaceColors.sidebar_text_color,
    borderBottom: !settingsView
      ? `1px solid ${tinycolor(colors.sidebar_background_color)
          .darken(5)
          .toString()}`
      : `1px solid ${tinycolor(defaultSpaceColors.sidebar_background_color)
          .darken(5)
          .toString()}`,
    textDecoration: "none",

    "&:hover": {
      // backgroundColor: tinycolor(colors.sidebar_background_color).darken(4).toString(),
      backgroundColor: !settingsView
        ? `1px solid ${tinycolor(colors.sidebar_background_color)
            .darken(5)
            .toString()}`
        : `1px solid ${tinycolor(defaultSpaceColors.sidebar_background_color)
            .darken(5)
            .toString()}`,
    },
  },

  nodeItemInsideActiveSettingsView: {
    backgroundColor: theme.colors.gray[7],
    color: "#fff",
    // fontWeight: "300",

    "&:hover, & a, & a:hover": {
      backgroundColor: theme.colors.gray[7],
    },
  },
}));

export default function SidebarTitle(props) {
  const location = useLocation();

  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);

  const { settingsView } = useSidebarStyles();
  const { classes, cx } = useStyles({ colors, settingsView });

  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.name) {
      setTitle(props.name);
    }

    setLoading(props.loading);
  }, [props]);

  const getLinkPath = (slug, version) => {
    if (settingsView) {
      const setttingsSection = location.pathname.split("/").pop();
      return `/space/${slug}/settings/${setttingsSection}`;
    } else {
    }
    return `/space/${slug}/${version}`;
  };

  return (
    <Box
      component={Link}
      to={getLinkPath(props.slug, props.version)}
      className={`${classes.spaceNameWrap}`}
    >
      {loading ? (
        <Box pl={20} pr={20} pt={20}>
          <Skeleton width={"100%"} height={46} mb={20} radius="md" />
          <Skeleton width={"100%"} height={1} mt={6} radius="md" />
          <Skeleton width={"100%"} height={30} mt={20} radius="md" />
          <Skeleton width={"100%"} height={30} mt={10} radius="md" />
          <Skeleton width={"100%"} height={30} mt={10} radius="md" />
        </Box>
      ) : (
        <Text
          className={`${classes.spaceName} ${
            props.slug == space.slug && settingsView
              ? classes.nodeItemInsideActiveSettingsView
              : ""
          }`}
        >
          {title}
        </Text>
      )}
    </Box>
  );
}
