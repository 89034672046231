import React, { useState, useEffect } from "react";
import { Box, MantineProvider, Select, Button, Group } from "@mantine/core";

import { useSelector } from "react-redux";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import SurveyModel from "../../../models/Survey";

export default function SurveyPerson(props) {
  const space = useSelector((state) => state.space);
  const [surveys, setSurveys] = useState([]);
  const { t, i18n } = useTranslation();
  const form = useForm({
    initialValues: {
      object_type: "survey",
      object_id: "",
    },

    validate: {
      object_id: (value) =>
        value.length > 0 ? null : "Please select a survey or quiz",
    },
  });

  useEffect(() => {
    onFetch();
  }, [props]);

  const onFetch = () => {
    SurveyModel.onFetch(
      space.slug,
      { page: 1, per: 100 },
      (data) => {
        const _surveys = data.data.map((item, index) => ({
          value: `${item.id}`,
          label: item.title,
          group: "Select an existing survey",
        }));
        setSurveys([
          {
            value: "new_survey",
            label: "Create a new survey or quiz",
            group: "New survey or quiz",
          },
          ..._surveys,
        ]);
      },
      (data) => {}
    );
  };

  const onSend = () => {
    props.onCreate(form.values.object_id);
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSend(values))}>
        <MantineProvider theme={{ primaryShade: 6 }}>
          <Select
            label={t("spaces.settings.registration.steps.surveyPerson.label")}
            placeholder={t(
              "spaces.settings.registration.steps.surveyPerson.placeholder"
            )}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
            mt={20}
            data={surveys}
            {...form.getInputProps("object_id")}
            zIndex={99999}
          />
        </MantineProvider>
        <Button
          type="submit"
          // onClick={() => props.onCreate(params)}
          leftIcon={<FontAwesomeIcon icon={solid("check")} />}
          mt={25}
          radius="xl"
          size="md"
          fullWidth
        >
          {`Add survey`}
        </Button>
      </form>
    </Box>
  );
}
