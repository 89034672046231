import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import {
  setSpace,
  setColors,
  setTree,
  setSettingsTabs,
  setPath,
  setPaths,
  setSettings,
  setJourneys,
  setSurvey,
  setPermissions,
  setPinMessage,
  setCalendar,
  setCompleted,
  setInteractions,
  setHiddenSidebar,
  setHiddenHeader,
  setHiddenCover,
  setHiddenTitle,
  setHiddenBreadcrumbs,
  setForceLayoutUpdate,
  setActions
} from "../../store/app";
import SpaceModel from "../../models/Space";
import { storeJSON, getJSON, deleteJSON } from "../../utils/LocalDB";
import SpaceSettings from "./settings/Settings";
import { useTranslation } from "react-i18next";

import Discussion from "./presentations/discussion/Discussion";
import Content from "./presentations/content/Content";
import ContentReadonly from "./presentations/content/ContentReadonly";
import BackupPreview from "./presentations/content/BackupPreview";
import RegistrationPage from "./presentations/registration/RegistrationPage";
import RegistrationPageReadonly from "./presentations/registration/RegistrationPageReadonly";
import Payment from "./payment/Payment";
import UserEdit from "../users/Edit";
import Private from "./presentations/static/Private";
import Pending from "./presentations/static/Pending";
import Processing from "./presentations/static/Processing";
import Invitation from "./presentations/static/Invitation";
import Survey from "./presentations/static/Survey";
import Gateway from "./presentations/static/Gateway";
import BroadcastExclude from "./presentations/static/BroadcastExclude";

import Events from "./presentations/lists/Events";
import List from "./presentations/lists/List";

import GettingStarted from "../app/GettingStarted";
import Members from "./members/List";
import DummyStart from "./dummy/Start";
import DummySignIn from "./dummy/SignIn";
import DummySignUp from "./dummy/SignUp";
import DummyMagicLink from "./dummy/MagicLink";
import DummyRecover from "./dummy/Recover";
import DummyEditProfile from "./dummy/Edit";
import DummyPay from "./dummy/Payment";
import DummyPending from "./dummy/Pending";

import { setCookie, getCookie } from "../../utils/Cookies";

import { Helmet } from "react-helmet";

export default function Space(props) {
  const params = useParams();

  const space = useSelector((state) => state.space);
  const permissions = useSelector((state) => state.permissions);

  const user = useSelector((state) => state.user);

  const mainActions = useSelector((state) => state.mainActions);
  const { t, i18n } = useTranslation();
  const didMount = useRef(false);
  const refreshed = useRef(false);
  const preview = useRef(false);
  const invitationSlug = useRef(null);
  const editable = useRef(null);
  const forceLayoutUpdate = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const paramsChangeTimeout = useRef([]);
  const lastColorTheme = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(true);
    return () => {
      dispatch(setColors());
      dispatch(setHiddenSidebar({ hidden: false }));
      dispatch(setHiddenHeader({ hidden: false }));
      dispatch(setHiddenCover({ hidden: false }));
      dispatch(setHiddenTitle({ hidden: false }));
      dispatch(setHiddenBreadcrumbs({ hidden: false }));
      dispatch(setSpace({ slug: null }));
      refreshed.current = false;
      window.$currentTopSlug = null;
      window.$fetchedTopSlug = null;
    };
  }, []);

  useEffect(() => {
    console.log("LOCATION CHANGE ================>");
    // setLoading(true);
    if (paramsChangeTimeout.current) {
      clearTimeout(paramsChangeTimeout.current);
    }
    paramsChangeTimeout.current = setTimeout(() => {
      console.log("LOCATION CHANGE TIMEOUT ================>");
      onSlugChange();
    }, 300);
  }, [location.pathname]);

  const onSlugChange = () => {
    const edit = new URLSearchParams(location.search).get("edit");
    if (edit) {
      dispatch(setActions({ editPage: true }));

      searchParams.delete('edit');
      setSearchParams(searchParams);
    }
    invitationSlug.current = new URLSearchParams(location.search).get(
      "invitation_slug"
    );

    if (invitationSlug.current) {
      setCookie("invitation_slug", invitationSlug.current, 1);
      // localStorage.setItem("invitation_slug", invitationSlug.current)
    } else {
      // invitationSlug.current = localStorage.getItem("invitation_slug")
      invitationSlug.current = getCookie("invitation_slug");
    }

    if (
      location.pathname.split("/")[0] == "circle" ||
      location.pathname.split("/")[1] == "circle"
    ) {
      const newUrl = location.pathname.replace("circle", "space");
      navigate(newUrl);
      return;
    }
    preview.current =
      location.pathname.indexOf("/register") >= 0 ||
      location.pathname.indexOf("/registration") >= 0;

    // if (params.slug && (!space || (space && space.slug != params.slug) || (refresh && !refreshed.current))){
    refreshed.current = true;
    fetchSpace();
    // }
  };

  const hideLayout = (hidden_layout_elements = null) => {
    if (space && !space.slug) {
      return;
    }

    if (!hidden_layout_elements) {
      hidden_layout_elements = space.hidden_layout_elements;
    }

    dispatch(
      setHiddenSidebar({
        hidden:
          hidden_layout_elements.indexOf("sidebar") >= 0 ||
          space.pod_type == "user_message",
      })
    );

    if (hidden_layout_elements.indexOf("header") >= 0) {
      const hide = space.permissions.can_manage ? "admin" : true;
      dispatch(
        setHiddenHeader({
          hidden: hide,
        })
      );
      if (!forceLayoutUpdate.current) {
        forceLayoutUpdate.current = true;
        setTimeout(() => {
          dispatch(setForceLayoutUpdate({ update: true }));
        }, 1000);
      }
    } else {
      dispatch(setHiddenHeader(false));
    }
    dispatch(
      setHiddenCover({
        hidden: hidden_layout_elements.indexOf("cover") >= 0,
      })
    );
    dispatch(
      setHiddenTitle({
        hidden: hidden_layout_elements.indexOf("title") >= 0,
      })
    );
    dispatch(
      setHiddenBreadcrumbs({
        hidden: hidden_layout_elements.indexOf("breadcrumbs") >= 0,
      })
    );

    if (mainActions.editPage) {
      window.$spaceWideWidth = space.space_width;
      window.$registrationWideWidth = space.registration_width;
    }
  };

  const fetchSpace = () => {
    // setLoading(true);
    console.log("ON ACCESS SPACE")
    try {
      $(`#collapse-button-node-${params.slug}-closed`).click();
    } catch {}

    getJSON(`spaces`, params.slug, (data) => {
      if (data && data.space && data.space.slug) {
        SpaceModel.onAccess(
          params.slug,
          (accessData) => {
            window.$spaceUserInteractions = accessData.interactions;
            data.redirect_to = { ...accessData.redirect_to };
            onSpaceFetched(data);
            onFetchSpaceFromAPI(data.stored_at);
          },
          (data) => {}
        );
      } else {
        onFetchSpaceFromAPI();
      }
    });
  };

  const onFetchSpaceFromAPI = (storedAt = null) => {

    const include = ["tree", "members", "views", "redirect_to", "survey"];

    SpaceModel.onFetch(
      params.slug,
      include,
      (data) => {
        // window.$spaceUserInteractions = data.interactions;
        window.$fetchedTopSlug = data.tree.slug;
        window.$currentSlug = params.slug;
        window.$showMarkCompleteButton = data.permissions.show_mark_complete_button_in_header

        storeJSON("spaces", params.slug, { ...data });

        if (!data.tree.nosave) {
          storeJSON("spaces", `tree_${data.tree.slug}`, { ...data.tree });
        }

        const updatedAtLocalDate = new Date(Date.parse(data.updated_at));

        console.log("CHECKING: SILENT UPDATE DONE SPACE");
        console.log(updatedAtLocalDate);
        console.log(storedAt);
        if (
          !storedAt ||
          (storedAt && updatedAtLocalDate > storedAt) ||
          space.slug == null
        ) {
          console.log("SILENT UPDATE DONE SPACE");

          onSpaceFetched(data);
        }
      },
      (data) => {
        navigate(`/home/top`);
      }
    );
  };

  const onSetSpaceData = (data, tree, force = false) => {
    if (mainActions.editPage) {
      window.$spaceWideWidth = data.space.space_width;
      window.$registrationWideWidth = data.space.registration_width;
    }

    window.$spaceIsPost = data.space.is_post && !data.permissions.can_manage;

    window.$spacePath = data.space.path;

    window.$currentTopSlug = tree.slug;
    window.$currentSpaceName = data.space.name;

    refreshed.current = false;

    if (
      data.colors &&
      JSON.stringify(data.colors) != JSON.stringify(lastColorTheme.current)
    ) {
      lastColorTheme.current = { ...data.colors };
      window.$themeColors = { ...data.colors };
      dispatch(setColors(data.colors));
    } else if (!data.colors) {
      lastColorTheme.current = null;
      dispatch(setColors());
    }

    const oldTree = space.tree;

    dispatch(
      setSpace({
        ...data.space,
        completed: data.completed,
        survey: data.survey ? { ...data.survey } : null,
        permissions: { ...data.permissions },
        colors: { ...data.colors },
        interactions: data.interactions ? { ...data.interactions } : false,
        tree: { ...tree },
        settings_tabs: [...data.settings_tabs],
        path: [...data.path],
        paths: { ...data.space.path },
        settings: { ...data.settings },
        journeys: data.journeys ? [...data.journeys] : [],
        is_top_level: data.is_top_level,
        tags: [...data.tags],
        tagged_with: [...data.tagged_with],
        is_liked: data.is_liked
      })
    );

    dispatch(setCompleted({ completed: data.completed }));

    // dispatch(
    //   setSpaceLoading({loading: false})
    // );

    dispatch(setSettingsTabs([...data.settings_tabs]));

    dispatch(setPath([...data.path]));

    dispatch(setPaths({ ...data.space.path }));

    dispatch(setSettings({ ...data.settings }));

    dispatch(setJourneys(data.journeys ? [...data.journeys] : []));

    dispatch(setSurvey(data.survey ? { ...data.survey } : null));

    dispatch(setPermissions({ ...data.permissions }));

    dispatch(setCalendar({ ...data.space.calendar }));

    dispatch(setPinMessage({ ...data.space.pin_message }));

    dispatch(
      setInteractions(data.interactions ? { ...data.interactions } : false)
    );

    if (
      !space.slug ||
      (space.slug && space.tree && space.tree.slug != tree.slug) ||
      force
    ) {
      const auxTree = { ...tree };
      auxTree.permissions = data.permissions;
      window.$forceTreeUpdate = true;
      dispatch(setTree(auxTree));
      // console.log("TREE SET: " + auxTree.children.length);
    }
  };

  const onSpaceFetched = (data) => {
    getJSON(
      `spaces`,
      `tree_${
        window.$fetchedTopSlug && window.$fetchedTopSlug == data.tree.slug
          ? window.$fetchedTopSlug
          : data.tree.slug
      }`,
      (treeData) => {
        if (treeData && treeData.slug) {
          if (
            data.tree &&
            data.tree.children &&
            treeData.children.length < data.tree.children.length
          ) {
            onSetSpaceData(data, data.tree, true);
          } else {
            if (
              window.$sidebarTree &&
              window.$sidebarTree.children &&
              treeData &&
              treeData.children &&
              window.$sidebarTree.children.length < treeData.children.length
            ) {
              onSetSpaceData(data, treeData, true);
            } else {
              onSetSpaceData(data, treeData);
            }
          }
        } else {
          onSetSpaceData(data, data.tree, true);
        }
      }
    );

    window.$hiddenLayoutElements = null;

    if (
      data.redirect_to &&
      data.redirect_to.action == "react" &&
      data.redirect_to.url.indexOf("/processing") >= 0 &&
      location.pathname.indexOf("processing") < 0
    ) {
      navigate(data.redirect_to.url);
      return;
    }

    setTimeout(() => {
      // setLoading(false);
    }, 250);

    // Validate on second
    if (
      location.pathname.indexOf("/members") >= 0 &&
      ((data.permissions.show_members &&
        (data.space.is_open || data.permissions.is_member)) ||
        data.permissions.can_manage ||
        data.permissions.is_admin)
    ) {
      return;
    }

    if (location.pathname.indexOf("/broadcast/exclude") >= 0) {
      return;
    }

    if (location.pathname.indexOf("/getting_started") >= 0) {
      return;
    }

    if (location.pathname.indexOf("/gateway") >= 0) {
      return;
    }

    if (location.pathname.indexOf("/pay") >= 0 && !data.permissions.is_member) {
      return;
    }

    if (location.pathname.indexOf("/edit") >= 0 && location.pathname.indexOf("/registration") < 0) {
      return;
    }

    if (location.pathname.indexOf("/demo") >= 0) {
      if (data.permissions.can_manage) {
        return;
      } else {
        navigate(`/space/${data.slug}`);
      }
    }

    if (
      preview.current &&
      ((data.space.block_until_registered && !data.permissions.is_member) ||
        data.permissions.can_manage ||
        data.permissions.is_admin)
    ) {
      if (
        location.pathname.indexOf("/circle") < 0 &&
        data.permissions.use_legacy_registration_page
      ) {
        window.location.href= `/circle/${data.space.slug}/register`;
      } else {
        if (
          !data.permissions.can_manage &&
          !data.permissions.is_admin &&
          location.pathname.indexOf("/registration") >= 0
        ) {
          if (data.space.block_until_registered) {
            window.location.href= `/space/${data.space.slug}/register`;
          } else {
            window.location.href= `/space/${data.space.slug}`;
          }
        }
      }
      return;
    }

    if (
      data.redirect_to &&
      data.redirect_to.action == "react" &&
      ((!data.permissions.can_manage && !data.permissions.is_admin) ||
        data.redirect_to.force_admin)
    ) {
      const search = window.location.search;
      const _pod_message_id = new URLSearchParams(search).get("pod_message_id");
      const _reflection_id = new URLSearchParams(search).get("reflection_id");
      const _interactive_slug = new URLSearchParams(search).get(
        "interactive_slug"
      );
      const _tag = new URLSearchParams(search).get("tag");
      if (_pod_message_id) {
        const symbol = data.redirect_to.url.indexOf("?") >= 0 ? "&" : "?";
        data.redirect_to.url += `${symbol}pod_message_id=${_pod_message_id}`;
      }
      if (_reflection_id) {
        const symbol = data.redirect_to.url.indexOf("?") >= 0 ? "&" : "?";
        data.redirect_to.url += `${symbol}reflection_id=${_reflection_id}`;
      }
      if (_tag) {
        const symbol = data.redirect_to.url.indexOf("?") >= 0 ? "&" : "?";
        data.redirect_to.url += `${symbol}tag=${_tag}`;
      }
      if (_interactive_slug) {
        const symbol = data.redirect_to.url.indexOf("?") >= 0 ? "&" : "?";
        data.redirect_to.url += `${symbol}interactive_slug=${_interactive_slug}`;
      }

      if (data.redirect_to.url.indexOf("user") >= 0) {
        deleteJSON("spaces", data.space.slug);
        deleteJSON("spaces", `tree_${data.space.slug}`);
      }

      navigate(data.redirect_to.url);
    } else if (data.redirect_to && data.redirect_to.action == "redirect") {
      window.location.href= data.redirect_to.url;
      return;
    } else if (
      data.space.version != location.pathname.split("/").pop() &&
      location.pathname.indexOf("/settings") < 0 &&
      location.pathname.indexOf("/processing") < 0
    ) {
      navigate(`/space/${data.space.slug}/${data.space.version}`);
    }
    if (
      data.space.version == location.pathname.split("/").pop() &&
      data.permissions.is_member
    ) {
      localStorage.removeItem("invitation_slug");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {space.slug ? space.name : t("spaces.space.sutraLoading")}
        </title>
      </Helmet>
      <Routes>
        <Route
          path="events"
          element={
            <Events
              hideLayout={hideLayout}
              showHeader={true}
              spaceId={params.slug}
              editable={mainActions.editPage}
            />
          }
        />

        <Route
          path="list"
          element={
            <List
              hideLayout={hideLayout}
              showHeader={true}
              spaceId={params.slug}
              editable={mainActions.editPage}
            />
          }
        />

        <Route
          path="discussion"
          element={
            <Discussion
              editable={mainActions.editPage}
              hideLayout={hideLayout}
              showHeader={true}
            />
          }
        />
        {space.permissions &&
        space.permissions.can_edit &&
        mainActions.editPage ? (
          <Route
            path="content"
            element={<Content editable={true} hideLayout={hideLayout} />}
          />
        ) : (
          <Route
            path="content"
            element={<ContentReadonly hideLayout={hideLayout} />}
          />
        )}

        <Route
          path="backup_preview/:id"
          element={
            <BackupPreview
              key={`tiptap-${new Date()}`}
              type="content"
              space={space}
              user={user}
              hideLayout={hideLayout}
            />
          }
        />

        <Route
          path="register"
          element={
            <RegistrationPageReadonly invitationSlug={invitationSlug.current} />
          }
        />

        {space.permissions && space.permissions.can_manage && (
          <Route
            path="registration/edit"
            element={
              <RegistrationPage
                documentName={"editable-registration-page"}
                editable={true}
              />
            }
          />
        )}
        {space.permissions && space.permissions.can_manage && (
          <Route
            path="registration/view"
            element={
              <RegistrationPage
                documentName={"editable-registration-page"}
                editable={false}
              />
            }
          />
        )}
        {space.permissions && space.permissions.can_manage && (
          <Route path="settings/*" element={<SpaceSettings />} />
        )}

        <Route path="pay" element={<Payment space={space} />} />

        {space.permissions && !space.permissions.can_manage && (
          <Route path=":username/edit" element={<UserEdit space={space} />} />
        )}
        <Route path="private" element={<Private space={space} user={user} />} />
        <Route path="pending" element={<Pending space={space} user={user} />} />
        <Route
          path="processing"
          element={<Processing space={space} user={user} reset={fetchSpace} />}
        />
        <Route path="members" element={<Members space={space} user={user} />} />
        <Route
          path="invitation"
          element={<Invitation space={space} user={user} />}
        />
        <Route
          path="getting_started"
          element={<GettingStarted space={space} />}
        />
        <Route path="survey" element={<Survey space={space} user={user} />} />
        <Route path="gateway" element={<Gateway space={space} user={user} />} />
        <Route
          path="broadcast/exclude/:email"
          element={<BroadcastExclude space={space} user={user} />}
        />

        <Route
          path="demo/start"
          element={<DummyStart space={space} user={user} />}
        />
        <Route
          path="demo/sign_in"
          element={<DummySignIn space={space} user={user} />}
        />
        <Route
          path="demo/sign_up"
          element={<DummySignUp space={space} user={user} />}
        />
        <Route
          path="demo/magic_link"
          element={<DummyMagicLink space={space} user={user} />}
        />
        <Route
          path="demo/recover"
          element={<DummyRecover space={space} user={user} />}
        />
        <Route
          path="demo/pay"
          element={<DummyPay space={space} user={user} />}
        />
        <Route
          path="demo/edit_profile"
          element={<DummyEditProfile space={space} user={user} />}
        />
        <Route
          path="demo/pending"
          element={<DummyPending space={space} user={user} />}
        />
      </Routes>
    </>
  );
}
