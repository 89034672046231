import React, { useState, useEffect } from "react";
import { Button, Group, Stack, createStyles, Text } from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useMediaQuery } from "@mantine/hooks";

import { useDispatch, useSelector } from "react-redux";
import tinycolor from "tinycolor2";

import { setHoverSidebar } from "../../store/app";
import isSpaceThemed from "../../utils/IsSpaceThemed";
import useSidebarStyles from "../../utils/SidebarStyles";

import Logo from "./Logo";
import HeaderActions from "./HeaderActions";
import UserButton from "./UserButton";
import Uploader from "./Uploader";

import DefaultAvatar from "../../src/images/fallback/default.png";

import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";

const useStyles = createStyles((theme, { hiddenHeader, registrationView }) => ({
  appHeaderInner: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    paddingLeft: theme.other.appShellPadding,
    paddingRight: theme.other.appShellPadding,
    background:
      hiddenHeader && !registrationView
        ? "repeating-linear-gradient(-45deg,#ddd,#ddd 1px,#f9f9f9 1px,#f9f9f9 10px)"
        : "",
  },
  appHeaderLogoWrap: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function HeaderContent(props) {
  const { t, i18n } = useTranslation();
  const { registrationView } = useSidebarStyles();
  const { classes, cx, theme } = useStyles({
    hiddenHeader: props.hiddenHeader.hidden,
    registrationView,
  });
  const colors = useSelector((state) => state.colors);

  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const hoverSidebar = useSelector((state) => state.hoverSidebar);
  const hiddenSidebar = useSelector((state) => state.hiddenSidebar);

  const location = useLocation()

  const [showLogo, setShowLogo] = useState(false)

  const dispatch = useDispatch();
  const mediumScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.md}px)`
  );
  const smallScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.sm}px)`
  );

  const toggleHover = () => {
    if (hoverSidebar.hover) {
      dispatch(setHoverSidebar({ hover: false }));
    } else {
      dispatch(setHoverSidebar({ hover: true }));
    }
  };

  const DisplayBurger = () => {
    return (
      <Button
        compact
        variant="subtle"
        onClick={() => toggleHover()}
        color="dark"
        size="xl"
        style={{
          width: 30,
          textAlign: "center",
          margin: 5,
          padding: 5,
          color: isSpaceThemed(colors)
            ? colors.primary_button_background_color
            : "#000",
          height: "auto",
        }}
        sx={(theme) => ({
          "&:hover": {
            backgroundColor: isSpaceThemed(colors)
              ? tinycolor(colors.header_color).lighten(10).toString()
              : theme.colors.gray[1],
          },
        })}
      >
        {hoverSidebar.hover ? (
          <FontAwesomeIcon icon={solid("xmark")} />
        ) : (
          <FontAwesomeIcon icon={solid("bars")} />
        )}
      </Button>
    );
  };

  useEffect(() => {
    if (window.location.href.split("/").length >= 6 && !showLogo){
      setTimeout(() => {
        setShowLogo(true)
      }, 1000)
    }
    else if (window.location.href.split("/").length >= 6 && showLogo){
      setShowLogo(true)
    }
    else{
      setShowLogo(false)
    }
  }, [location])

  return (
    <div className={classes.appHeaderInner}>
      <div className={classes.appHeaderLogoWrap}>
        {mediumScreen && !hiddenSidebar.hidden && <DisplayBurger />}
        {props.hiddenHeader.hidden == "admin" ? (
          <>
            {registrationView ? (
              <Stack spacing={0}>
                <Text size="sm" color="dimmed" sx={{ lineHeight: 1 }}>
                  {t("layout.headerContent.registrationPage")}
                </Text>
                <Text
                  size="xl"
                  weight={800}
                  lineClamp={1}
                  sx={{ lineHeight: 1.5 }}
                >
                  {space.name}
                </Text>
              </Stack>
            ) : (
              <Text color="dimmed" size="md">
                {t("layout.headerContent.nonManagersHidden")}
              </Text>
            )}
          </>
        ) : (
          <>{!smallScreen && (window.location.pathname.indexOf("/space") < 0 || showLogo) && <Logo />}</>
        )}
      </div>
      <Group position="right" spacing={10} noWrap>
        <HeaderActions authenticated={user.authenticated} />
        {location && location.pathname.indexOf("/registration") < 0 ? (
          <UserButton
            authenticated={user.authenticated}
            image={user.avatar ? user.avatar.url : DefaultAvatar}
            username={`@${user.username}`}
          />
        ) : (
          <Uploader />
        )}
      </Group>
    </div>
  );
}
