import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  ScrollArea,
  Stack,
  TextInput,
  Group,
  Text,
  Select,
  SegmentedControl,
  Switch,
  MantineProvider,
  Box,
  Textarea,
} from "@mantine/core";
import SpaceModel from "../../../models/Space";
import TagModel from "../../../models/SpaceTag";

import { useForm } from "@mantine/form";
import { TimeInput, DatePicker } from "@mantine/dates";

import { useTranslation } from "react-i18next";

import { useFocusTrap } from "@mantine/hooks";

import PremiumModal from "../../app/PremiumModal";

import TiptapInline from "../../tiptap/components/TiptapInline";

import { storeJSON, getJSON } from "../../../utils/LocalDB";

import Tag from "../presentations/lists/Tag";

const SelectItem = ({ image, label, description, ...others }) => (
  <div {...others}>
    <Group noWrap>
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </div>
    </Group>
  </div>
);

export default function Form(props) {
  const [publish, setPublish] = useState(false);
  const [join, setJoin] = useState(true);
  const [privacyDescription, setPrivacyDescription] = useState("");
  const [rootPrivacyDescription, setRootPrivacyDescription] = useState("");
  const [opened, setOpened] = useState(false);
  const [tags, setTags] = useState(null);
  const user = useSelector((state) => state.user);
  const space = useSelector((state) => state.space);
  const newSpaceLoading = useRef(false);

  const { t, i18n } = useTranslation();

  const focusTrapRef = useFocusTrap();

  const form = useForm({
    initialValues: {
      intention: "",
      version: "",
      privacy_level: "open",
      join_on_create: true,
      launch_on_date: null,
      launch_time: null,
      show_start_date: false,
      start_date: new Date(new Date().getTime() + 86400000),
      end_date: new Date(new Date().getTime() + 90000000),
      start_time: new Date(new Date().getTime() + 86400000),
      end_time: new Date(new Date().getTime() + 90000000),
      content: "",
      hangout_link: "",
      description: "",
      tags: [],
    },

    validate: {
      intention: (value) =>
        value.length >= 3 && value.length <= 100
          ? null
          : t("spaces.create.invalidName2ErrorMsg"),
    },
  });

  useEffect(() => {
    return () => {
      newSpaceLoading.current = false;
    };
  }, []);

  useEffect(() => {
    form.setFieldValue("version", props.version);
    if (props.privacyControl != null) {
      form.setFieldValue(
        "privacy_level",
        props.privacyControl == "any" ? "open" : props.privacyControl
      );
    }
    if (!tags){
      onFetchTags();
    }
  }, [props]);

  useEffect(() => {
    form.setFieldValue("join_on_create", join);
  }, [join]);

  useEffect(() => {
    if (form.values.privacy_level === "open") {
      setPrivacyDescription(
        `${t("spaces.form.form.privacyDescription1")} ${space.name}.`
      );
      setRootPrivacyDescription(
        `${t("spaces.form.form.rootPrivacyDescription1")}`
      );
    } else if (form.values.privacy_level === "private") {
      setPrivacyDescription(`${t("spaces.form.form.privacyDescription2")}`);
      setRootPrivacyDescription(
        `${t("spaces.form.form.rootPrivacyDescription2")}`
      );
    }
  }, [form.values.privacy_level]);

  const onFetchTags = () => {
    TagModel.onFetch(
      space.slug,
      (data) => {
        setTags([...data]);
      },
      (data) => {}
    );
  };

  const onSave = () => {
    if (newSpaceLoading.current) {
      return;
    }
    newSpaceLoading.current = true;
    const spaceParams = { ...form.values };
    if (spaceParams.version == "event") {
      spaceParams.version = "content";
      spaceParams.show_start_date = true;

      if (spaceParams.start_date) {
        const _startDate = new Date(spaceParams.start_date);
        const _startTime = spaceParams.start_time
          ? new Date(spaceParams.start_time)
          : new Date();

        spaceParams.start_date = new Date(
          `${_startDate.toLocaleDateStringAbs()}, ${_startTime.toLocaleTimeString()}`
        ).toISOString();
      }
      if (spaceParams.end_date) {
        const _endDate = new Date(spaceParams.end_date);
        const _endTime = spaceParams.end_time
          ? new Date(spaceParams.end_time)
          : new Date();

        spaceParams.end_date = new Date(
          `${_endDate.toLocaleDateStringAbs()}, ${_endTime.toLocaleTimeString()}`
        ).toISOString();
      }
    }
    if (spaceParams.launch_on_date) {
      const _launchDate = new Date(spaceParams.launch_on_date);
      const _launchTime = spaceParams.launch_time
        ? new Date(spaceParams.launch_time)
        : new Date();

      spaceParams.launch_time = new Date(
        `${_launchDate.toLocaleDateStringAbs()}, ${_launchTime.toLocaleTimeString()}`
      ).toISOString();

      spaceParams.launch_on_date = true;
    }

    if (props.duplicateFrom) {
      spaceParams.copy_resources = true;
      spaceParams.copy_sub_circles = true;
      spaceParams.parent_slug = space.slug;

      SpaceModel.onDuplicate(
        spaceParams,
        props.duplicateFrom,
        (data) => {
          props.onSave(data.data.slug, data.data.link);
          setTimeout(() => {
            newSpaceLoading.current = false;
          }, 2000);
        },
        (data) => {
          setTimeout(() => {
            newSpaceLoading.current = false;
          }, 500);
        }
      );
    } else {
      SpaceModel.onCreate(
        spaceParams,
        props.parentId,
        (data) => {
          props.onSave(data.slug, data.link);
          storeJSON("spaces", `new-space-${props.parentId}`, {
            json: "",
            html: "",
          });
          setTimeout(() => {
            newSpaceLoading.current = false;
          }, 2000);
        },
        (data) => {
          if (data.permissions) {
            setOpened([true, data.permissions[1], data.permissions[2]]);
            // props.onSave()
          }
          setTimeout(() => {
            newSpaceLoading.current = false;
          }, 500);
        }
      );
    }
  };

  const spacesVersions = () => {
    let versions = [
      {
        value: "content",
        label: t("spaces.form.form.versions.content.label"),
        description: t("spaces.form.form.versions.content.description"),
      },
      {
        value: "list",
        label: t("spaces.form.form.versions.list.label"),
        description: t("spaces.form.form.versions.list.description"),
      },
      {
        value: "discussion",
        label: t("spaces.form.form.versions.discussion.label"),
        description: t("spaces.form.form.versions.discussion.description"),
      },
      {
        value: "event",
        label: t("spaces.form.form.versions.event.label"),
        description: t("spaces.form.form.versions.event.description"),
      },
      {
        value: "events",
        label: t("spaces.form.form.versions.events.label"),
        description: t("spaces.form.form.versions.events.description"),
      },
    ];

    return versions;
  };

  const onContentUpdate = (content, html) => {
    form.setFieldValue("content", content.content);
  };

  const onTag = ({ name, color, action, rTags }) => {
    if (action == "add") {
      const selectedTags = [...form.values.tags];
      selectedTags.push({ name: name, color: color });
      form.setFieldValue("tags", selectedTags);
    } else if (action == "replace") {
      form.setFieldValue("tags", rTags);
    }
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <form
        onSubmit={form.onSubmit((values) => onSave(values))}
        ref={focusTrapRef}
      >
        <Stack>
          <TextInput
            {...form.getInputProps("intention")}
            size="lg"
            radius="sm"
            placeholder={t("spaces.form.listForm.intention.placeholder")}
            data-autofocus
            styles={{
              input: {
                fontWeight: "bold",
              },
            }}
          />
          {props.content && !props.duplicateFrom && (
            <Box>
              <TiptapInline
                editable={true}
                content={""}
                modals={true}
                exclude={["formatbar"]}
                onUpdate={onContentUpdate}
                placeholder={
                  space.version == "events"
                    ? t("spaces.form.form.placeholderDescription")
                    : t("spaces.form.form.content.placeholder")
                }
                canToggleFormatbar={true}
                contextDb={`new-space-${props.parentId}`}
              />
            </Box>
          )}
          {!props.isRoot ? (
            <MantineProvider theme={{ primaryShade: 6 }}>
              {space && space.slug && space.version == "content" && (
                <Select
                  {...form.getInputProps("version")}
                  label={t("spaces.form.form.spaceFormat.label")}
                  placeholder={t("spaces.form.form.spaceFormat.placeholder")}
                  itemComponent={SelectItem}
                  maxDropdownHeight={400}
                  zIndex={99999}
                  defaultValue={"content"}
                  data={spacesVersions()}
                />
              )}

              {form.getInputProps("version").value == "event" ? (
                <Stack>
                  {/* <Text color="dimmed">
                    {t("spaces.form.form.eventDescription")}
                  </Text> */}
                  <TextInput
                    {...form.getInputProps("hangout_link")}
                    label={t("spaces.form.form.hangoutLink.label")}
                    placeholder={t("spaces.form.form.hangoutLink.placeholder")}
                    data-autofocus
                  />
                  <Box>
                    <Text size="sm" weight={500} mb={4} mt={5}>
                      {t("spaces.form.form.eventStartTime")}
                    </Text>
                    <Group>
                      <DatePicker
                        minDate={new Date()}
                        {...form.getInputProps("start_date")}
                        placeholder={t("spaces.form.form.date.placeholder")}
                        zIndex={99999}
                      />
                      <TimeInput
                        minDate={new Date()}
                        defaultValue={new Date()}
                        format="12"
                        {...form.getInputProps("start_time")}
                      />
                    </Group>
                    <Text size="sm" weight={500} mb={4} mt={20}>
                      {t("spaces.form.form.eventEndDate")}
                    </Text>
                    <Group>
                      <DatePicker
                        {...form.getInputProps("end_date")}
                        placeholder={t("spaces.form.form.date.placeholder")}
                        zIndex={99999}
                        minDate={
                          form.values.start_date ? form.values.start_date : null
                        }
                      />
                      <TimeInput
                        defaultValue={new Date()}
                        format="12"
                        {...form.getInputProps("end_time")}
                      />
                    </Group>
                  </Box>
                </Stack>
              ) : (
                ""
              )}
            </MantineProvider>
          ) : (
            ""
          )}

          <Stack>
            {props.privacyControl == "any" && (
              <Box>
                <Text size="sm" weight={500} mb={4} mt={5}>
                  {t("spaces.form.form.privacyLevelText")}
                </Text>
                <SegmentedControl
                  fullWidth
                  {...form.getInputProps("privacy_level")}
                  data={[
                    {
                      label: t("spaces.form.form.privacy.open"),
                      value: "open",
                    },
                    {
                      label: t("spaces.form.form.privacy.private"),
                      value: "private",
                    },
                  ]}
                />
                {!props.isRoot && (
                  <Text mt={10} size="sm" color="dimmed">
                    {privacyDescription}
                  </Text>
                )}
                {props.isRoot && (
                  <Text mt={10} size="sm" color="dimmed">
                    {rootPrivacyDescription}
                  </Text>
                )}
              </Box>
            )}
            {!props.noAdvance && !props.isRoot && (
              <>
                <Switch
                  mt={10}
                  checked={join}
                  onChange={(event) => setJoin(event.target.checked)}
                  label={t("spaces.form.form.joinAsMember.label")}
                />
                <Switch
                  checked={publish}
                  onChange={(event) => setPublish(event.target.checked)}
                  label={t("spaces.form.form.publishDate.label")}
                />
                {publish ? (
                  <div>
                    <Text size="sm" weight={500} mb={4} mt={5}>
                      {t("spaces.form.form.publishDateTime")}
                    </Text>
                    <Group>
                      <DatePicker
                        {...form.getInputProps("launch_on_date")}
                        placeholder={t("spaces.form.form.date.placeholder")}
                        zIndex={99999}
                      />
                      <TimeInput
                        defaultValue={new Date()}
                        format="12"
                        {...form.getInputProps("launch_time")}
                      />
                    </Group>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {tags && tags.length > 0 && (
              <Tag
                spaceId={space.slug}
                tags={tags}
                onSave={onTag}
                onUpdatedMessage={onTag}
                action="select"
                creatable={false}
              />
            )}
          </Stack>

          <Button radius="xl" size="md" mt={0} type="submit">
            {`${props.actionButtonText}`}
          </Button>
        </Stack>
      </form>
      <PremiumModal
        opened={opened}
        setOpened={setOpened}
        onUpgrade={props.onSave}
      />
    </ScrollArea>
  );
}
