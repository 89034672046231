import React, { useState, useEffect } from "react";
import {
  Text,
  Box,
  MantineProvider,

  Button,

  Group,
  useMantineTheme,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import SpaceSettingModel from "../../../../../models/SpaceSetting";
import TiptapInline from "../../../../tiptap/components/TiptapInline";

export default function SetToPending(props) {
  const theme = useMantineTheme();
  const [settings, setSettings] = useState(null);
  const [pendingMessage, setPendingMessage] = useState("");
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);

  const form = useForm({
    initialValues: {
      pending_message: "",
    },

    validate: {
      pending_message: (value) =>
        value.length > 0 ? null : "Please add a pending message",
    },
  });

  useEffect(() => {
    console.log(pendingMessage);
  }, [pendingMessage]);

  useEffect(() => {
    if (props.settings && props.settings.slug) {
      setPendingMessage([...props.settings.settings.pending_message]);
      form.setFieldValue("pending_message", [
        ...props.settings.settings.pending_message,
      ]);
      setSettings({ ...props.settings });
    }
  }, [props]);

  // const onFetch = () => {

  //   SpaceSettingModel.onFetch(space.slug, (data) => {

  //   }, (data) => {

  //   })
  // }

  const onSend = () => {
    const params = {
      block_until_approved: true,
      pod_settings_attributes: {
        pending_message: form.values.pending_message,
      },
    };
    SpaceSettingModel.onUpdate(
      space.slug,
      params,
      (data) => {
        if (props.mode === "edit") {
          props.onUpdate();
        } else {
          props.onCreate();
        }
      },
      (data) => {}
    );
  };

  const onDestroy = (event) => {
    const params = { block_until_approved: false };
    SpaceSettingModel.onUpdate(
      space.slug,
      params,
      (data) => {},
      (data) => {}
    );

    props.onDestroy(event);
  };

  const onContentUpdate = (contentJson) => {
    form.setFieldValue("pending_message", contentJson.content);
    // setPendingMessage(contentJson)
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSend(values))}>
        <MantineProvider theme={{ primaryShade: 6 }}>
          <Text mt={20} size={16} weight={500} color={theme.colors.gray[9]}>
          {t("spaces.settings.registration.steps.setToPending.title")}
          </Text>
          <Text size="sm" color="dimmed" sx={{ lineHeight: 1.2 }} mb={15}>
          {t("spaces.settings.registration.steps.setToPending.description")}
          </Text>
          {/*<Textarea
    mt={20}
    autosize
    minRows={4}
    label="Welcome message"
    description="This is the content that people will see right after they register for your space.  This is a good place to explain when the program will launch and what they can expect in the meantime."
    placeholder="Write something..."
    radius="sm"
    size="md"
    {...form.getInputProps('pending_message')}
    />
  */}
          {pendingMessage && (
            <TiptapInline
              editable={true}
              content={[...pendingMessage]}
              modals={true}
              onUpdate={onContentUpdate}
              exclude={[]}
            />
          )}
          <Text mt={10} size="xs" color="dimmed">
            {
              "Use {{first_name}}, {{last_name}}, {{space_name}}, {{space_link}}, and {{space_name_link}} to autopopulate the corresponding information"
            }
          </Text>
        </MantineProvider>
        {props.mode === "edit" ? (
          <Group position="apart">
            <Button
              mt={25}
              radius="xl"
              // size="md"
              type="submit"
              leftIcon={<FontAwesomeIcon icon={solid("check")} />}
            >
              {t("spaces.settings.registration.steps.setToPending.saveButton1")}
            </Button>
            <Button
              onClick={(event) => onDestroy(event)}
              mt={25}
              radius="xl"
              // size="md"
              color="dark"
              leftIcon={<FontAwesomeIcon icon={regular("trash-can")} />}
            >
              {t("spaces.settings.registration.steps.setToPending.deleteButton")}
            </Button>
          </Group>
        ) : (
          <Button
            type="submit"
            // onClick={() => props.onCreate(params)}
            leftIcon={<FontAwesomeIcon icon={solid("check")} />}
            mt={25}
            radius="xl"
            fullWidth
          >
            {t("spaces.settings.registration.steps.setToPending.saveButton2")}
          </Button>
        )}
      </form>
    </Box>
  );
}
