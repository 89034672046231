import React, { useState, useEffect } from "react";

import { Text, Group, Box, Stack, Checkbox, createStyles } from "@mantine/core";

import { DateTime } from "luxon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { colors } from "../../../../collections/colors";

import Avatar from "../../../utils/LazyAvatar";

import { useDispatch } from "react-redux";

import {setTiptapSidemenu} from '../../../../store/app';

const useStyles = createStyles((theme, { colors }) => ({
  markComplete: {
    color: theme.colors.green[6],
  },
}));

export default function MemberActivityRow(props) {
  const { theme, classes, cx } = useStyles({ colors });

  const [member, setMember] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setMember(props.member);
  }, [props]);

  const openProfile = () => {
    dispatch(setTiptapSidemenu({ opened: "profile", option: member.username}));
  }

  return (
    <tr>
      <td style={{ width: "20px" }}>
        {window.$currentUserId != member.user_id && (
          <Checkbox
            checked={props.isSelected(member.username)}
            onChange={(event) => props.onSelect(member.username)}
          />
        )}
      </td>
      <td style={{ width: "200px" }}>
        <Group
          spacing="sm"
          onClick={() => openProfile()}
          sx={{
            color: "#000",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          noWrap
        >
          <Avatar
            size={30}
            src={member && member.avatar ? member.avatar.url : ""}
            radius={30}
          />
          <Stack spacing="0">
            <Text size="sm" weight={500}>
              {member.full_name}
            </Text>
            <Text size="xs" color="dimmed" mt={-3}>
              @{member.username}
            </Text>
          </Stack>
        </Group>
      </td>

      <td style={{ width: "20px" }}>
        <Box className={classes.markComplete}>
          {member.completed && (
            <FontAwesomeIcon size="lg" icon={solid("circle-check")} />
          )}
        </Box>
      </td>

      <td style={{ textAlign: "right" }}>
        {member.last_seen_at
          ? DateTime.fromISO(member.last_seen_at).toFormat("LLL dd, yyyy")
          : ""}
      </td>

      <td style={{ textAlign: "right" }}>
        {member.last_posted_at
          ? DateTime.fromISO(member.last_posted_at).toFormat("LLL dd, yyyy")
          : ""}
      </td>

      <td style={{ textAlign: "right" }}>
        {member.joined
          ? DateTime.fromISO(member.joined).toFormat("LLL dd, yyyy")
          : ""}
      </td>
    </tr>
  );
}
