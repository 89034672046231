import React, { useState, useEffect } from "react";

import AuthModel from "../../models/Auth";

import { useTranslation } from "react-i18next";

import { Box } from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import { getCookie } from '../../utils/Cookies';

export default function Start(props) {
  const { t, i18n } = useTranslation();

  const [affiliate, setAffiliate] = useState();

  useEffect(() => {
    const username = getCookie("affiliate_sutra")
    if (username) {
      onAffiliateData(username);
    }
  }, [props]);

  const onAffiliateData = (username) => {
    AuthModel.onAffiliateData(
      username,
      (data) => {
        setAffiliate(data);
      },
      (data) => {}
    );
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  if (!affiliate){
    return null
  }

  if (!affiliate.full_name){
    return null
  }

  return (
    <Box
      sx={{
        backgroundColor: "#eee",
        textAlign: "center",
        padding: "15px 5px",
      }}
    >
      {`Referred by ${affiliate.full_name}`}
    </Box>
  );
}
