import React, { useState } from 'react';

import {
  Text,
  Textarea,
  Box,
  Button,
  Divider
} from '@mantine/core';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

export default function InvitePeople(props) {

  const { t, i18n } = useTranslation();

  const space = useSelector(state => state.space);

  const onCopy = () => {
    Swal.fire("Done", "Text copied to clipboard", "success")
  }

  return (
    <Box mt={5} p={10}>
      {/* if space is top level open or paid, or public open or paid */}
      {props.settings && !props.settings.is_private_one_level ? (
        <>
        <Text size="sm">
          {t("spaces.settings.members.inviteLink.invite.shareLinkText")}
        </Text>

        <Text
          size="lg"
          sx={(theme) => ({
            marginTop: 15,
            backgroundColor: theme.colors.gray[1],
            color: theme.colors.dark[4],
            borderRadius: "5px",
            padding: "8px 15px",
            "&:hover": {
              backgroundColor: theme.colors.gray[2],
              cursor: "pointer"
            }
          })}
          >
          <CopyToClipboard
            onCopy={() => onCopy()}
            text={`https://sutra.co/space/${space.slug}/register`}>
            <span>
              {`https://sutra.co/space/${space.slug}/register`}
            </span>
          </CopyToClipboard>
        </Text>
        </>
      ) : (
        <Text size="sm">
          {t("spaces.settings.members.inviteLink.invite.youCannotShare")}
        </Text>
      )}

    </Box>
  )
}
