import React from "react";
import { useSelector } from "react-redux";
import { useEditor, EditorContent } from "@tiptap/react";

import StarterKit from "@tiptap/starter-kit";

import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";

import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";

import UniqueID from "../plugins/CustomUniqId";
import TextAlign from "@tiptap/extension-text-align";
import Details from "@tiptap-pro/extension-details";
import DetailsSummary from "@tiptap-pro/extension-details-summary";
import DetailsContent from "@tiptap-pro/extension-details-content";

import Link from "@tiptap/extension-link";
import CircleNode from "../nodes/CircleNode";
import ParagraphNode from "../nodes/ParagraphNode";
import HeadingNode from "../nodes/HeadingNode";
import ImageNode from "../nodes/ImageNode";
import VideoNode from "../nodes/VideoNode";
import AudioNode from "../nodes/AudioNode";
import EmbedNode from "../nodes/EmbedNode";
import FileNode from "../nodes/FileNode";
import SurveyNode from "../nodes/SurveyNode";
import ListItemNode from "../nodes/ListItemNode";
import FancyLinkNode from "../nodes/FancyLinkNode";


import CustomSize from "../plugins/CustomSize";
import CustomResizeable from "../plugins/CustomResizeable";
import ColumnStyles from "../plugins/ColumnStyles";
import CustomButton from "../plugins/CustomButton";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import useSidebarStyles from "../../../utils/SidebarStyles";
import defaultSpaceColors from "../../../collections/defaultSpaceColors";
import tinycolor from "tinycolor2";

import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";

import { Mention } from "../plugins/Mention";
import mentionSuggestion from "../plugins/mentionSuggestion";

import { Box, createStyles } from "@mantine/core";

const useStyles = createStyles((theme, { colors }) => ({
  panelWrap: {
    border: `1px solid ${
      !isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).darken(5).toString()
        : colors.sidebar_background_color
    }`,
    borderRadius: 5,
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
}));

const Tiptap = (props) => {
  const { settingsView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  colors = !settingsView ? colors : defaultSpaceColors;
  const { classes, cx, theme } = useStyles({ colors });

  const editor = useEditor({
    onCreate({ editor }) {},
    editable: false,
    content: props.content
      ? {
          type: "doc",
          content: Array.isArray(props.content)
            ? props.content
            : props.content.content,
        }
      : "",
    extensions: [
      StarterKit.configure({
        history: false,
        paragraph: false,
        heading: false,
        listItem: false,
        blockquote: false
      }),
      UniqueID.configure({
        types: [
          "heading",
          "paragraph",
          "grid2",
          "grid3",
          "column",
          "tasklist",
          "taskItem",
          "circle",
          "video",
          "audio",
          "image",
          "embed",
          "file",
          "detailsSummary",
          "details",
          "detailsContent",
          "bulletList",
          "orderedList",
          "listItem",
          "taskItem",
          "survey",
          "fancyLink",
        ],
      }),
      Highlight.configure({
        multicolor: true,
      }),
      Underline,
      TextStyle,
      Color,
      VideoNode,
      AudioNode,
      EmbedNode,
      FileNode,
      ListItemNode,
      ParagraphNode,
      HeadingNode,
      CircleNode,
      SurveyNode,
      FancyLinkNode,
      
      ColumnStyles.configure({
        types: ["column"],
      }),
      CustomSize.configure({
        types: ["file", "audio", "survey", "fancyLink"],
      }),
      CustomResizeable.configure({
        types: ["image", "video", "embed"],
      }),
      CustomButton.configure({
        types: ["survey"],
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      // Placeholder.configure({
      //   placeholder: ({ node }) => {
      //     if (node.type.name === 'heading') {
      //       return 'Heading'
      //     }
      //     if (node.type.name === 'paragraph') {
      //       return 'Write something'
      //     }
      //     if (node.type.name === 'detailsSummary') {
      //       return 'Summary'
      //     }
      //
      //     return ''
      //   },
      //   showOnlyCurrent: true
      // }),
      Link.configure({
        openOnClick: false,
      }),
      ImageNode,
      TextAlign.configure({
        types: [
          "heading",
          "paragraph",
          "detailsSummary",
          "image",
          "video",
          "embed",
          "file",
          "audio",
          "survey",
          "fancyLink"
        ],
      }),
      Details.configure({
        HTMLAttributes: {
          openClassName: "is-open",
          class: "tiptap-details",
        },
      }),
      DetailsSummary.configure({
        HTMLAttributes: {
          class: "tiptap-details-summary",
        },
      }),
      DetailsContent.configure({
        HTMLAttributes: {
          class: "tiptap-details-content",
        },
      }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        // suggestion,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: mentionSuggestion,
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${
            node.attrs.label ?? node.attrs.id.split("|")[1]
          }`;
        },
      }),
    ],
  });

  return (
    <Box
      className="tiptap-wrapper tiptap-comments"
      mb={0}
      radius="md"
      withBorder={false}
      id="tiptap-inline-container"
    >
      <EditorContent
        style={{
          paddingLeft: props.editable ? 10 : 0,
          paddingRight: 10,
          paddingTop: props.editable ? 10 : 0,
          paddingBottom: props.editable ? 10 : 0,
          color: colors.default_text_color,
        }}
        editor={editor}
      />
    </Box>
  );
};

export default Tiptap;
