import React, { useState, useEffect } from "react";
import {

  Table,
  Badge,
  MantineProvider,

  Box,
} from "@mantine/core";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

export default function Payments(props) {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);

  const [affiliates, setAffiliates] = useState([]);
  const [selectedAffiliate, setSelectedAffiliate] = useState();

  useEffect(() => {
    
  }, []);

  const StatusBadge = (props) => {
    const statusOptions = {
      paid: "lime",
      unpaid: "red",
      pending: "orange",
    };

    return (
      <MantineProvider theme={{ primaryShade: 6 }}>
        <Badge  color={statusOptions[props.status]} variant="filled">
          {t(`settings.affiliates.history.statuses.${props.status}`)}
        </Badge>
      </MantineProvider>
    );
  };

  return (
    <div>
      {props.affiliate && props.affiliate.payments.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>{t("settings.affiliates.history.table.value")}</th>
              <th>{t("settings.affiliates.history.table.percent")}</th>
              <th>{t("settings.affiliates.history.table.revenue")}</th>
              <th>{t("settings.affiliates.history.table.createdAt")}</th>
              <th>{t("settings.affiliates.history.table.payAt")}</th>
              <th>{t("settings.affiliates.history.table.status")}</th>
            </tr>
          </thead>
          <tbody>
            {props.affiliate.payments.map((payment) => (
              <tr>
                <td>{payment.value_formatted}</td>
                <td>{payment.percent}%</td>
                <td>{payment.revenue_formatted}</td>
                <td>{payment.created_at}</td>
                <td>{payment.pay_at}</td>
                <td>
                  <StatusBadge status={payment.status} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Box
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "center",
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          {t("settings.billing.noPayments")}
        </Box>
      )}
    </div>
  );
}
