import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createStyles,
  Title,
  Text,
  Container,
  ScrollArea,
} from "@mantine/core";

import trackMixpanel from "../../utils/TrackMixpanel";

import PlanOptions from "./payment/PlanOptions";

import { setHiddenSidebar } from "../../store/app";

import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    maxWidth: 800,
    margin: "auto",
  },

  description: {
    maxWidth: 600,
    margin: "auto",
  },
}));

export default function Pricing() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { classes, theme } = useStyles();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    trackMixpanel(user.username, "Pricing Visited");

    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";

    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    if (!user.authenticated) {
      dispatch(setHiddenSidebar({ hidden: true }));
      setTimeout(() => {
        const b = document.getElementsByTagName("body")[0];
        b.style.display = "";
        dispatch(setHiddenSidebar({ hidden: true }));
      }, 500);
    }
    if (user.authenticated) {
      dispatch(setHiddenSidebar({ hidden: false }));
      setTimeout(() => {
        const b = document.getElementsByTagName("body")[0];
        b.style.display = "";

        dispatch(setHiddenSidebar({ hidden: false }));
      }, 500);
    }
  }, [user]);

  return (
    <ScrollArea style={{ height: "100vh" }}>
      <Helmet>
        <title>{t("app.pricing.tabTitle")}</title>
      </Helmet>
      <Container py="xl" mb={100}>
        <Title order={2} className={classes.title} align="center" mt="sm">
          {t("app.pricing.upgrade")}
        </Title>

        <Text
          color="dimmed"
          className={classes.description}
          align="center"
          mt="md"
        >
          {t("app.pricing.text")}
        </Text>

        <PlanOptions />
      </Container>
    </ScrollArea>
  );
}
