export const getBreadcrumbs = (path) => {
  return collapsedBreadcrumbs(path)
}

const collapsedBreadcrumbs = (breadcrumbs) => {

  if (breadcrumbs.length <= 3){
    return breadcrumbs;
  }
  else{
    const root = breadcrumbs[0];
    const collapsed = {...root}
    collapsed.name = "...";
    collapsed.version = "collapsed"
    const collapsedBreadcrumbs = [root, collapsed];
    for (var i = 1; i < breadcrumbs.length; i++) {
      if (i > breadcrumbs.length - 3){
        const breadcrumb = breadcrumbs[i];
        collapsedBreadcrumbs.push(breadcrumb);
      }
    }
    return collapsedBreadcrumbs;
  }
}

export const getCollapsed = (path) => {
  const collapsed = []
  for (var i = 1; i < path.length - 2; i++) {
    collapsed.push(path[i])
  }
  return collapsed
}
