import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

import { stylesToCamelCase } from "../../../utils/StylesToString";
import ResourceModel from "../../../models/Resource";
import PageModel from "../../../models/Page";

import { setTiptapSidemenu } from "../../../store/app";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import isDark from "../../../utils/IsDarkColor";

import { useListState } from "@mantine/hooks";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { GripVertical } from "tabler-icons-react";

import { useInView } from "react-intersection-observer";

import { checkInteractionAndHideIfNeededReact, setUniqId} from "../utils/selection";

import { wideOrNarrow } from "../../../utils/PageWidth";

import TiptapReadonly from "./TiptapReadonly";

import {
  Accordion,
  Group,
  Text,
  Badge,
  Tooltip,
  MantineProvider,
  createStyles,
  Box,
} from "@mantine/core";

import tinycolor from "tinycolor2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import useLangUser from "../../../utils/LangUser";
import { DateTime } from "luxon";

import Swal from "sweetalert2";

import { colors } from "../../../collections/colors";

const useStyles = createStyles(
  (theme, { colorsTheme, publishOnDate }, getRef) => ({
    item: {
      borderBottom: "none",
      borderRadius: 10,
      // borderdTopLeftRadius: 5,
      // borderTopRightRadius: 5,
      backgroundColor: isSpaceThemed(colorsTheme)
        ? isDark(colorsTheme.background_color)
          ? tinycolor(colorsTheme.background_color).lighten(5).toString()
          : tinycolor(colorsTheme.background_color).darken(5).toString()
        : tinycolor(colorsTheme.background_color).darken(2).toString(),

      // backgroundColor: !isSpaceThemed(colorsTheme)
      //   ? theme.colors.gray[0]
      //   : colorsTheme.sidebar_background_color,
      border: isSpaceThemed(colorsTheme)
        ? `1px solid ${
            isDark(colorsTheme.background_color)
              ? tinycolor(colorsTheme.background_color).lighten(20).toString()
              : tinycolor(colorsTheme.background_color).darken(20).toString()
          }`
        : `1px solid #e9ecef`,
    },
    itemOpened: {
      backgroundColor: isSpaceThemed(colorsTheme)
        ? isDark(colorsTheme.background_color)
          ? tinycolor(colorsTheme.background_color).lighten(5).toString()
          : tinycolor(colorsTheme.background_color).darken(5).toString()
        : tinycolor(colorsTheme.background_color).darken(4).toString(),
      borderdTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      boxShadow: "none",
      // backgroundColor: '#fff',
    },
    control: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 0,
      borderRadius: 10,

      backgroundColor: isSpaceThemed(colorsTheme)
        ? isDark(colorsTheme.background_color)
          ? tinycolor(colorsTheme.background_color).lighten(8).toString()
          : tinycolor(colorsTheme.background_color).darken(8).toString()
        : tinycolor(colorsTheme.background_color).darken(2).toString(),

      // boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
      // backgroundColor: !isSpaceThemed(colors) ? theme.colors.gray[0] : colors.sidebar_background_color,

      // border: isSpaceThemed(colorsTheme)
      // ? `1px solid ${
      //     isDark(colorsTheme.background_color)
      //       ? tinycolor(colorsTheme.background_color).lighten(20).toString()
      //       : tinycolor(colorsTheme.background_color).darken(20).toString()
      //   }`
      // : `1px solid #e9ecef`,

      // color: isSpaceThemed(colors) && !publishOnDate ? colors.sidebar_text_color : "#000",
      color: isSpaceThemed(colorsTheme)
        ? colorsTheme.default_text_color
        : "#000",

      "&:hover": {
        // borderRadius: 10,
        // backgroundColor: theme.colors.gray[1],
        // boxShadow: '1px 1px 1px 0px rgba(0,0,0,0.1)'
        cursor: "pointer !important",
        // backgroundColor: isSpaceThemed(colorsTheme)
        //   ? (isDark(colorsTheme.background_color)
        //     ? tinycolor(colorsTheme.background_color).lighten(8).toString()
        //     : tinycolor(colorsTheme.background_color).darken(8).toString())
        //   : tinycolor(colorsTheme.background_color).darken(1).toString(),
        backgroundColor: isSpaceThemed(colorsTheme)
          ? isDark(colorsTheme.background_color)
            ? tinycolor(colorsTheme.background_color).lighten(10).toString()
            : tinycolor(colorsTheme.background_color).darken(10).toString()
          : tinycolor(colorsTheme.background_color).darken(2).toString(),
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        // backgroundColor: !isSpaceThemed(colorsTheme)
        //   ? theme.colors.gray[0]
        //   : colorsTheme.sidebar_background_color,
        // background: publishOnDate ? "repeating-linear-gradient(-45deg,#ddd,#ddd 1px,#f9f9f9 1px,#f9f9f9 10px)" : "",
        // border: isSpaceThemed(colorsTheme)
        //   ? `1px solid ${
        //       isDark(colorsTheme.background_color)
        //         ? tinycolor(colorsTheme.background_color).lighten(20).toString()
        //         : tinycolor(colorsTheme.background_color).darken(20).toString()
        //     }`
        //   : `1px solid #e9ecef`,
      },
    },
    icon: {
      // marginRight: 8,
      // marginLeft: -5
    },
    content: {
      backgroundColor: colors.background_color,
      // borderLeft: `1px solid ${
      //   !isSpaceThemed(colorsTheme)
      //     ? theme.colors.gray[1]
      //     : colorsTheme.sidebar_background_color
      // }`,
      // borderRight: `1px solid ${
      //   !isSpaceThemed(colorsTheme)
      //     ? theme.colors.gray[1]
      //     : colorsTheme.sidebar_background_color
      // }`,
      // borderBottom: `1px solid ${
      //   !isSpaceThemed(colorsTheme)
      //     ? theme.colors.gray[1]
      //     : colorsTheme.sidebar_background_color
      // }`,
      // boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      paddingLeft: 25,
      paddingRight: 20,
      paddingTop: 10,
      color: colorsTheme.default_text_color,
    },
    contentInner: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    itemStyle: {
      color: !isSpaceThemed(colorsTheme)
        ? "#000"
        : colorsTheme.default_text_color,
    },
    iconWrapper: {
      color: !isSpaceThemed(colorsTheme)
        ? "#000"
        : colorsTheme.default_text_color,
      ref: getRef("iconWrapper"),
    },
    linkHover: {
      color: colorsTheme.default_text_color,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    dateStyle: {
      textTransform: "none",
    },
    badgeStyle: {
      textTransform: "none",
      cursor: "pointer",
      // backgroundColor: theme.colors.gray[6],
      color: isSpaceThemed(colorsTheme)
        ? colorsTheme.primary_button_text_color
        : "#fff",
      backgroundColor: isSpaceThemed(colorsTheme)
        ? colorsTheme.primary_button_background_color
        : theme.colors.gray[6],
      "&:hover": {
        color: isSpaceThemed(colorsTheme)
          ? tinycolor(colorsTheme.primary_button_text_color)
              .lighten(6)
              .toString()
          : "#fff",
        backgroundColor: isSpaceThemed(colorsTheme)
          ? tinycolor(colorsTheme.primary_button_background_color)
              .lighten(6)
              .toString()
          : theme.colors.gray[7],
        // backgroundColor: theme.colors.gray[7],
      },
    },
    trashStyle: {
      cursor: "pointer",
      // backgroundColor: theme.colors.gray[6],
      color: isSpaceThemed(colorsTheme)
        ? colorsTheme.primary_button_background_color
        : theme.colors.gray[6],
      "&:hover": {
        color: isSpaceThemed(colorsTheme)
          ? tinycolor(colorsTheme.primary_button_background_color).lighten(6).toString()
          : theme.colors.gray[7],
        // backgroundColor: theme.colors.gray[7],
      },
    },
    itemDragging: {
      // boxShadow: theme.shadows.sm,
    },

    symbol: {
      fontSize: 30,
      fontWeight: 700,
      width: 60,
    },

    dragHandle: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      color: isSpaceThemed(colorsTheme)
        ? tinycolor(colorsTheme.sidebar_text_color).toString()
        : theme.colors.gray[7],
      paddingLeft: 10,
      paddingRight: 10,
    },
  })
);

export default function Resource(props) {
  const isUnpublished = () => {
    return (
      resource.publish_on_date && new Date() < new Date(resource.publish_time)
    );
  };
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [resource, setResource] = useState({
    id: null,
    pages: [],
    title: "",
    permissions: {},
  });
  const [pages, handlers] = useListState([]);
  const colorsTheme = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({
    colorsTheme,
    publishOnDate: isUnpublished() ? resource.publish_on_date : "",
  });
  const { language, setLangUser } = useLangUser();
  const loaded = useRef(false);
  const opened = useRef(null);

  const { ref, inView } = useInView({ triggerOnce: true });

  //setUniqId(props.editor, props.node, props.getPos);

  useEffect(() => {
    return () => {
      loaded.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      inView &&
      (window.location.href.split("/")[4] == props.node.attrs.circle_id  ||
      window.$modalSpaceOpened == props.node.attrs.circle_id  || 
      window.$templatesModalIsOpened) &&
      (!resource.id || window.$reloadResourceId == resource.id)
    ) {
      window.$reloadResourceId = null
      onFetch();
    }
  }, [props]);

  useEffect(() => {
    if (
      inView &&
      (window.location.href.split("/")[4] == props.node.attrs.circle_id  || 
      window.$modalSpaceOpened == props.node.attrs.circle_id  || 
      window.$templatesModalIsOpened)
    ) {
      onFetch();
    }
  }, [inView]);

  useEffect(() => {
    handlers.setState([...resource.pages]);
  }, [resource]);

  useEffect(() => {
    if (loaded.current) {
      onReorder();
    } else if (resource.id) {
      loaded.current = true;
    }
  }, [pages]);

  const onFetch = () => {
    setResource({
      id: null,
      pages: [],
      title: "",
      permissions: {},
    })
    
    ResourceModel.onFetch(
      props.node.attrs.id,
      props.node.attrs.circle_id,
      (data) => {
        
        if (opened.current == null){
          opened.current = data.default_opened ? true : false;
        }
        setResource(data);
      },
      (data) => {}
    );
  };

  const onReorder = () => {
    PageModel.onReorder(
      props.node.attrs.id,
      props.node.attrs.circle_id,
      pages.map((p) => p.id),
      (data) => {},
      (data) => {}
    );
  };

  const onEdit = (event) => {
    event.stopPropagation();
    dispatch(
      setTiptapSidemenu({
        opened: "newResource",
        option: { resource: { ...resource } },
      })
    );
  };

  const onAddPage = (event) => {
    event.stopPropagation();
    dispatch(
      setTiptapSidemenu({
        opened: "newPage",
        option: { resourceId: props.node.attrs.id },
      })
    );
  };

  const onViewPage = (event, page) => {
    event.stopPropagation();
    dispatch(
      setTiptapSidemenu({
        opened: "viewPage",
        option: { resource: { ...resource }, page: { ...page } },
      })
    );
  };

  const onEditPage = (event, page) => {
    event.stopPropagation();
    dispatch(
      setTiptapSidemenu({
        opened: "newPage",
        option: { resourceId: props.node.attrs.id, page: { ...page } },
      })
    );
  };

  const onDestroyPage = (event, page) => {
    event.stopPropagation();
    Swal.fire({
      title: t("tiptap.components.resource.areYouSure"),
      html: "Your page content will be deleted",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        PageModel.onDestroy(
          page.id,
          props.node.attrs.id,
          props.node.attrs.circle_id,
          (data) => {
            onFetch();
          },
          (data) => {}
        );
        return;
      } else if (result.isDenied) {
        return;
      }
    });
  };

  const formatDateTime = (timestamp) => {
    if (resource.publish_time) {
      const d = new Date(resource.publish_time).toISOString();
      return DateTime.fromISO(d).toFormat("ff");
    }
  };

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      ref={ref}
      style={{
        maxWidth: wideOrNarrow(),
      }}
    >
      {checkInteractionAndHideIfNeededReact(props.editor, props.getPos) && (
        <NodeViewContent
          className={``}
          contentEditable="false"
          style={{ ...stylesToCamelCase(props.node.attrs.styles) }}
        >
          {(props.editor.isEditable ||
            !isUnpublished() ||
            resource.permissions.can_manage) && (
            <Box>
              
              {resource.id && (
                <Accordion
                  onChange={(state) => {opened.current = state[0]}}
                  initialState={{ 0: opened.current }}
                  classNames={{
                    item: classes.item,
                    itemOpened: classes.itemOpened,
                    control: classes.control,
                    icon: classes.icon,
                    content: classes.content,
                    contentInner: classes.contentInner,
                  }}
                >
                  <Accordion.Item
                    label={
                      <Group position="apart" noWrap>
                        <Text>{`${resource.title}`}</Text>
                        <Group>
                          {isUnpublished() && (
                            <MantineProvider theme={{ primaryShade: 6 }}>
                              <Tooltip
                                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                                label={t("tiptap.components.resource.label1")}
                                withArrow
                              >
                                <Badge
                                  color="green"
                                  variant="filled"
                                  className={classes.dateStyle}
                                  leftSection={
                                    <FontAwesomeIcon icon={regular("clock")} />
                                  }
                                >
                                  {" "}
                                  {formatDateTime()}
                                </Badge>
                              </Tooltip>
                            </MantineProvider>
                          )}
                          {props.editor.isEditable && (
                            <>
                              <Badge
                                variant="filled"
                                className={classes.badgeStyle}
                                onClick={(event) => onEdit(event)}
                              >
                                {t("tiptap.components.resource.edit")}
                              </Badge>
                            </>
                          )}
                        </Group>
                      </Group>
                    }
                  >
                    {resource &&
                      resource.content &&
                      (resource.content.length > 1 ||
                        (resource.content.length == 1 &&
                          resource.content[0])) && (
                        <>
                          <TiptapReadonly
                            key={`resource-description-${new Date()}`}
                            content={{ type: "doc", content: resource.content }}
                          />
                          <Box mb={20}></Box>
                        </>
                      )}
                    {resource &&
                      resource.pages &&
                      resource.pages.length > 0 && (
                        <DragDropContext
                          onDragEnd={({ destination, source }) =>
                            handlers.reorder({
                              from: source.index,
                              to: destination?.index || 0,
                            })
                          }
                        >
                          <Droppable
                            droppableId="dnd-list"
                            direction="vertical"
                          >
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {pages.map((page, index) => {
                                  return (
                                    <Draggable
                                      key={`resource-${resource.id}-page-${page.id}`}
                                      index={index}
                                      draggableId={`resource-${resource.id}-page-${page.id}`}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className={cx({
                                            [classes.itemDragging]:
                                              snapshot.isDragging,
                                          })}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                        >
                                          <Group
                                            noWrap
                                            position="apart"
                                            mt={10}
                                            mb={10}
                                            sx={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <Group
                                              noWrap
                                              sx={(theme) => ({
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "flex-start",
                                              })}
                                              className={classes.itemStyle}
                                              ml={2}
                                              spacing={0}
                                              onClick={(event) =>
                                                onViewPage(event, page)
                                              }
                                            >
                                              <Box>
                                                <FontAwesomeIcon
                                                  icon={regular("file-lines")}
                                                />
                                              </Box>
                                              <Text
                                                className={classes.linkHover}
                                                ml={15}
                                              >
                                                {page.title}
                                              </Text>
                                            </Group>
                                            {props.editor.isEditable && (
                                              <Group
                                                noWrap
                                                sx={{
                                                  justifyContent: "center",
                                                }}
                                                position="right"
                                                spacing={0}
                                                // mt={10}
                                                // mb={10}
                                              >
                                                <Badge
                                                  variant="filled"
                                                  className={classes.badgeStyle}
                                                  onClick={(event) =>
                                                    onEditPage(event, page)
                                                  }
                                                >
                                                  {t(
                                                    "tiptap.components.resource.edit"
                                                  )}
                                                </Badge>
                                                <Box
                                                  ml={10}
                                                  className={classes.trashStyle}
                                                  onClick={(event) =>
                                                    onDestroyPage(event, page)
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={solid("trash")}
                                                  />
                                                </Box>
                                                <div
                                                  {...provided.dragHandleProps}
                                                  className={classes.dragHandle}
                                                >
                                                  <GripVertical size={18} />
                                                </div>
                                              </Group>
                                            )}
                                          </Group>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}

                    {props.editor.isEditable && (
                      <Group
                        sx={(theme) => ({
                          color: colorsTheme.default_text_color,
                          cursor: "pointer",
                          paddingTop: "5px",
                        })}
                        onClick={(event) => onAddPage(event)}
                      >
                        <FontAwesomeIcon icon={solid("plus")} />
                        <Text className={classes.linkHover}>
                          {t("tiptap.components.resource.addPage")}
                        </Text>
                      </Group>
                    )}
                  </Accordion.Item>
                </Accordion>
              )}
            </Box>
          )}
        </NodeViewContent>
      )}
    </NodeViewWrapper>
  );
}
