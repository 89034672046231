import { useMediaQuery, useViewportSize } from '@mantine/hooks';

export function addDummyCursor(){

  return
  if (!window.$tiptapSelectionCoords){
  }

  const dummyCursor = document.createElement('div');
  dummyCursor.id = "dummyCursor"
  dummyCursor.style.position = "absolute";

  const sideFrame = document.getElementsByClassName("app-side-frame")[0]
  const sideFrameWidth = sideFrame.offsetWidth

  if (window.$sidebarIsOpen){
    dummyCursor.style.left = window.$tiptapSelectionCoords.left - sideFrameWidth +'px';
  }
  else{
    dummyCursor.style.left = window.$tiptapSelectionCoords.left + 'px';
  }
  dummyCursor.style.top = window.$tiptapSelectionCoords.top - window.$skipHeight + 16 +'px';
  dummyCursor.style.width = "5px"
  dummyCursor.style.height = "25px"
  dummyCursor.style.backgroundColor = "black";
  document.getElementsByClassName("tiptap-wrapper")[0].appendChild(dummyCursor)
}

export function removeDummyCursor(){

  const dummyCursor = document.getElementById('dummyCursor');
  if (dummyCursor){
    dummyCursor.remove();
  }

}
