import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import {
  Text,
  Box
} from '@mantine/core'

import Surveys from '../Surveys'

export default function Flexible(props) {
  const { t, i18n } = useTranslation();
  const frequency = useRef()

  // useEffect(() => {
  //   return () => {
  //     debugger
  //     frequency.current = null
  //   }
  // })

  useEffect(() => {
    if (props.settings){
      if (props.settings.payment_settings.default_payment_frequency != "once"){

        props.onUpdate({pod_payment_settings_attributes: {default_payment_frequency: "once"}, circle_payment: "flexible"})
      }

    }
  }, [props])

  return (
    <Box mt={20}>
      
      {props.inner ? <Text color="dimmed">{`Please note that changing flexible payment options for an invite also changes them for the whole space.`}</Text> : <Text
        color="dimmed"
      >
        {t("spaces.settings.payment.flexible.flexible.description")}
      </Text>}
      
      <Surveys
        scope={"payment"}/>
    </Box>
  )
}
