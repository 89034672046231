import React, {useEffect, useState, useRef} from 'react';

import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';

import {
  Button,
  Center,
  Loader
} from '@mantine/core';

import getSymbolFromCurrency from 'currency-symbol-map'

import { useTranslation } from "react-i18next";

export default function Payment(props) {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false)
  const processing = useRef(0)

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (processing.current < props.processing){
      setLoading(false);
    }
  }, [props])

  const onSubmit = async (event) => {
    event.preventDefault()
    setLoading(true);
    await props.onPay(stripe, elements)
  }

  const getFrequency = () => {

    if (!props.frequency){
      return ""
    }
    if (props.frequency != "once" && window.$selectedCoupon){
      return ""
    }
    return props.frequency != "once" ? ` / ${props.frequency}` : ""
  }

  const buttonText = () => {
    let amount = props.amount ? parseFloat(props.amount) : 0

    if (window.$selectedCoupon){
      if (window.$selectedCoupon.type == "amount"){

        amount = amount - window.$selectedCoupon.value
      }
      else{
        amount = amount - (amount * window.$selectedCoupon.value / 100.0)
      }

      amount = amount < 0 ? 0 : amount
    }
    
    return `${t("spaces.stripe.pay")} ${getSymbolFromCurrency(props.currency)}${amount.toFixed(2).replace(".00", "")}${getFrequency()}`
  }

  return (
    <form
      onSubmit={onSubmit}>
      <PaymentElement />
      <Button
        fullWidth
        mt="xl"
        radius="xl"
        size="lg"
        mb={15}
        type="submit"
        disabled={loading}>
        {loading ? <Loader /> : buttonText()}
      </Button>
    </form>
  )
}
