import React, { useEffect, useState } from "react";

import {
  Button,
  ScrollArea,
  Stack,
  TextInput,
  Checkbox,
  Loader,
  Center,
} from "@mantine/core";
import SpaceModel from "../../../models/Space";

import { useForm } from "@mantine/form";

import { useTranslation } from "react-i18next";

import { useFocusTrap } from "@mantine/hooks";

import PremiumModal from "../../app/PremiumModal";

export default function Form(props) {
  const [newSpaceLoading, setNewSpaceLoading] = useState(false);

  const [opened, setOpened] = useState(false);

  const { t, i18n } = useTranslation();

  const focusTrapRef = useFocusTrap();

  const form = useForm({
    initialValues: {
      intention: "",
      copy_members: false,
      copy_managers: false,
      copy_resources: true,
      copy_sub_circles: false,
      copy_posts: false,
      copy_structures: false,
      copy_surveys: false,
      copy_broadcasts: false,
      copy_journeys: false,
      copy_reg_page: false,
      as_template: false,
    },

    validate: {
      intention: (value) =>
        value.length >= 3 ? null : t("spaces.create.invalidNameErrorMsg"),
    },
  });

  useEffect(() => {
    if (!form.values.intention){

      form.setFieldValue(
        "intention",
        `${t("spaces.form.duplicate.prefix")} ${props.spaceName.substring(
          0,
          100
        )}`
      );
    }
  }, [props]);

  const onSave = () => {
    if (newSpaceLoading) {
      return;
    }
    setNewSpaceLoading(true);
    const spaceParams = { ...form.values };

    if (props.createTemplate) {
      spaceParams.as_template = props.createTemplate;
      spaceParams.copy_members = false;
      spaceParams.copy_managers = false;
    }

    SpaceModel.onDuplicate(
      spaceParams,
      props.spaceId,
      (data) => {
        props.onDuplicate(data.data.slug, data.data.link);
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 2000);
      },
      (data) => {
        if (data.permissions) {
          // props.onSave()
          setTimeout(() => {
            setOpened([true, data.permissions[1], data.permissions[2]]);
          }, 1000);
        }
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 500);
      }
    );
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <form
        onSubmit={form.onSubmit((values) => onSave(values))}
        ref={focusTrapRef}
      >
        <Stack>
          <TextInput
            {...form.getInputProps("intention")}
            label={!props.createTemplate ? t("spaces.form.duplicate.intention.label") : "Template name"}
            placeholder={t("spaces.duplicate.intention.placeholder")}
            data-autofocus
          />
          {!props.createTemplate && (
            <>
              <Checkbox
                {...form.getInputProps("copy_members")}
                label={t("spaces.form.duplicate.copyMembers.label")}
              />
              <Checkbox
                {...form.getInputProps("copy_managers")}
                label={t("spaces.form.duplicate.copyManagers.label")}
              />
              <Checkbox
                {...form.getInputProps("copy_resources")}
                label={t("spaces.form.duplicate.copyContent.label")}
              />
              <Checkbox
                {...form.getInputProps("copy_posts")}
                label={t("spaces.form.duplicate.copyPosts.label")}
              />
              <Checkbox
                {...form.getInputProps("copy_broadcasts")}
                label={t("spaces.form.duplicate.copyBroadcasts.label")}
              />
              <Checkbox
                {...form.getInputProps("copy_journeys")}
                label={t("spaces.form.duplicate.copyAutomation.label")}
              />
              <Checkbox
                {...form.getInputProps("copy_reg_page")}
                label={t("spaces.form.duplicate.copyRegPage.label")}
              />
            </>
          )}

          {newSpaceLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <Button radius="xl" size="md" mt={10} type="submit">
              {!props.createTemplate ? t("spaces.form.duplicate.save") : t("spaces.form.duplicate.saveTemplate")}
            </Button>
          )}
        </Stack>
      </form>
      <PremiumModal
        opened={opened}
        setOpened={setOpened}
        onUpgrade={props.onSave}
      />
    </ScrollArea>
  );
}
