import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import LikeModel from "../../models/Like";

import {
  Text,
  Group,
  Box,
  createStyles,
  Tooltip,
  Loader,
  Center,
} from "@mantine/core";

import tinycolor from "tinycolor2";

import isSpaceThemed from "../../utils/IsSpaceThemed";
import isDark from "../../utils/IsDarkColor";
import defaultSpaceColors from "../../collections/defaultSpaceColors";

const useStyles = createStyles((theme, { colors }) => ({
  footerElements: {
    cursor: "pointer",
    color: isDark(colors.default_text_color)
      ? tinycolor(colors.default_text_color).lighten(30).toString()
      : tinycolor(colors.default_text_color).darken(30).toString(),
    "&:hover": {
      color: colors.default_text_color,
    },
  },
  abstract: {
    minWidth: "40px",
    textDecoration: "none",
    color: colors.default_text_color,
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default function LikeButton(props) {
  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  const navigate = useNavigate();
  const params = useParams();

  const user = useSelector((state) => state.user);
  const colors = useSelector((state) => state.colors);
  const colors2 = useSelector((state) => state.colors2);

  const { classes, cx, theme } = useStyles({
    colors: getColors(),
  });

  const [liked, setLiked] = useState(false);
  const [count, setCount] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [users, setUsers] = useState([]);
  const [tooltip, setTooltip] = useState(false);

  const propsLoaded = useRef();
  const loaded = useRef();
  const likedR = useRef();
  const usersLoaded = useRef(false);
  const usersLoadedTimeoutId = useRef();

  useEffect(() => {
    // if (propsLoaded.current){
    //   return
    // }
    propsLoaded.current = true
    setLiked(props.liked);
    likedR.current = props.liked;
    setCount(parseInt(props.count || "0"));
  }, [props]);

  const onLike = (event) => {
    event.stopPropagation();

    if (!user.authenticated) {
      localStorage.setItem("join_slug", props.spaceId || params.slug);
      navigate(`/user/start?s=${props.spaceId || params.slug}`);
      return;
    }

    if (loaded.current){
      clearTimeout(loaded.current)
    }

    loaded.current = setTimeout(() => {
      LikeModel.onCreate(
        {
          pod_id: props.spaceId,
          pod_message_id: props.messageId,
          reflection_reflection_id: props.reflectionId,
        },
        (data) => {
          
          likedR.current = data.data.liked;
          setLiked(likedR.current);
          let newCount = likedR.current ? count + 1 : count - 1
          if (newCount < 0){
            newCount = 0
          }
          setCount(newCount);
          if (props.callback) {
            props.callback(data.data.liked);
          }
        },
        (data) => {}
      );
    }, 50)

  };

  const onUsers = () => {
    if (usersLoadedTimeoutId.current) {
      clearTimeout(usersLoadedTimeoutId.current);
    }

    usersLoadedTimeoutId.current = setTimeout(() => {
      LikeModel.onUsers(
        {
          pod_id: props.spaceId,
          pod_message_id: props.messageId,
          reflection_reflection_id: props.reflectionId,
        },
        (data) => {
          usersLoaded.current = true;
          setUsers(data);
        },
        (data) => {}
      );
    }, 500);
  };

  const onMouseEnter = (event) => {
    onUsers();
    setHovered(true);
    if (count > 0){
      setTooltip(true)
    }
  };

  const onMouseLeave = (event) => {
    setHovered(false);
    if (count > 0){
      setTooltip(false)
    }
  };

  const getUsers = () => {
    const array = [];
    const size = users.length > 10 ? 10 : users.length
    for (let i = 0; i < size; i++) {
      array.push(<Text size="sm">{users[i]}</Text>);
    }
    if (users.length > 10){
      array.push(<Text size="sm">{`plus ${users.length - 10} more`}</Text>);
    }
    return array;
  };

  return (
    <Tooltip
      opened={tooltip}
      wrapLines
      width={180}
      withArrow
      transition="fade"
      transitionDuration={200}
      label={
        users.length > 0 ? (
          getUsers()
        ) : usersLoaded.current ? (
          <Text size="sm" align="center">No likes yet</Text>
        ) : (
          <Center>
            <Loader size="xs" />
          </Center>
        )
      }
    >
      <Box
        sx={{
          borderRadius: 50,
          paddingLeft: 0,
          paddingRight: 5,
          paddingTop: 2,
          paddingBottom: 2,
          marginRight: 0,
          width: 40,
          // "&:hover": {
          //   backgroundColor: theme.colors.gray[0],
          // }
        }}
      >
        <Group
          className={classes.footerElements}
          spacing={5}
          noWrap
          sx={{
            display: "flex",
            alignItems: "center",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={(event) => onLike(event)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Box>
            {liked ? (
              hovered ? (
                <FontAwesomeIcon size="lg" icon={solid("heart")} />
              ) : (
                <FontAwesomeIcon size="md" icon={solid("heart")} />
              )
            ) : hovered ? (
              <FontAwesomeIcon size="lg" icon={regular("heart")} />
            ) : (
              <FontAwesomeIcon size="md" icon={regular("heart")} />
            )}
          </Box>

          <Text className={cx(classes.abstract, classes.footerElements)} lineClamp={1}>
            {count ? count : 0}
          </Text>
        </Group>
      </Box>
    </Tooltip>
  );
}
