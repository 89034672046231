import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setTiptapSidemenu } from "../../../store/app";

import SpaceModel from "../../../models/Space";
import ViewModel from "../../../models/View";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import SpaceNeo4jModel from "../../../models/neo4j/Space";

import pako from "pako";

import {
  Modal,
  ScrollArea,
  Box,
  Stack,
  SegmentedControl,
  Center,
  Loader,
  Button,
  Group,
  Text,
  MultiSelect,
  Grid,
  Image,
  createStyles,
} from "@mantine/core";

import TiptapNoHP from "./TiptapNoHP";

import data from "@emoji-mart/data";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  sidebarItem: {
    padding: "10px 20px",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#fafafa",
    },
  },
  image: {
    "&:hover": {
      backgroundColor: "#fafafa",
      boxShadow: "0 2px 2px rgba(0, 0, 0, 0.3)",
      // border: "1px solid #efefef"
      borderRadius: "5px"
    },
  }
}));

const TemplatesModal = (props) => {
  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const navigate = useNavigate();

  const { classes, cx, theme } = useStyles({ colors: colors });

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);
  const [type, setType] = useState("templates");
  const [templates, setTemplates] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [models, setModels] = useState([]);
  const [activeTemplate, setActiveTemplate] = useState({});
  const [activeLayout, setActiveLayout] = useState({});
  const [activeModels, setActiveModels] = useState({});
  const [activeTemplateTab, setActiveTemplateTab] = useState(0);
  const [activeLayoutTab, setActiveLayoutTab] = useState(0);
  const [activeModelsTab, setActiveModelsTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const [templatesTags, setTemplatesTags] = useState([]);
  const [layoutsTags, setLayoutsTags] = useState([]);
  const [modelsTags, setModelsTags] = useState([]);
  const [selectedTemplateTags, setSelectedTemplateTags] = useState([]);
  const [selectedLayoutsTags, setSelectedLayoutsTags] = useState([]);
  const [selectedModelsTags, setSelectedModalsTags] = useState([]);

  useEffect(() => {
    if (type == "templates") {
      onFetchTemplates();
    }
    if (type == "layouts") {
      onFetchLayouts();
    }
    if (type == "models") {
      onFetchModels();
    }
  }, [type]);

  useEffect(() => {
    onFetchView(filterTemplates()[activeTemplateTab]);
  }, [activeTemplateTab]);

  useEffect(() => {
    onFetchView(filterLayouts()[activeLayoutTab]);
  }, [activeLayoutTab]);

  useEffect(() => {
    onFetchView(filterModals()[activeModelsTab]);
  }, [activeModelsTab]);

  useEffect(() => {
    document.activeElement.blur();
  }, [selectedTemplateTags, selectedLayoutsTags]);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "templatesModal") {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "templatesModal") {
        if (tiptap && tiptap.editor) {
          tiptap.editor.commands.focus();
        }
        window.$closingSideMenu = true;
        dispatch(setTiptapSidemenu({ opened: null }));
      }
      window.$selectedTemplateChildren = null
      
      setType("templates")
    } else {
      if (tiptapSidemenu.option){
        setType(tiptapSidemenu.option.toLowerCase())
      }
      else{
        setType("templates")
      }
      onFetchView(templates[0]);
    }
    window.$templatesModalIsOpened = opened;
  }, [opened]);

  const onFetchLayouts = () => {
    // if (window.$contentLayouts) {
    //   setLayouts([...window.$contentLayouts]);
    //   return;
    // }
    SpaceModel.onFetchLayouts(
      space.slug,
      (data) => {
        // let array = [];
        // for (let i = 0; i < data.categories.length; i++) {
        //   array = array.concat(data.categories[i].blocks);
        // }

        setLayouts([...data.categories]);
        if (
          data.categories.length > 0 &&
          data.categories[0].blocks.length > 0
        ) {
          onFetchView(data.categories[0].blocks[0]);
        }
      },
      (data) => {}
    );
  };

  const onFetchTemplates = () => {
    // if (window.$contentTemplates) {
    //   setTemplates([...window.$contentTemplates]);
    //   return;
    // }
    SpaceModel.onFetchTemplates(
      space.slug,
      (data) => {
        setTemplatesTags([...data.tags].filter(Boolean));
        setTemplates([...data.categories]);
        if (data.categories.length > 0) {
          onFetchView(data.categories[0]);
        }
        window.$contentTemplates = [...data.categories];
      },
      (data) => {}
    );
  };

  const onFetchModels = () => {
    SpaceModel.onFetchModels(
      (data) => {
        setModels([...data]);
        if (data.length > 0) {
          onFetchView(data[0]);
        }
        window.$contentModels = [...data.categories];
      },
      (data) => {}
    );
  };

  const decompressGzip = (compressedData) => {
      
    try {
      const decompressed = pako.ungzip(compressedData.data, { to: "string" });
      return JSON.parse(decompressed);
    } catch (err) {
      console.error("Error during decompression:", err);
      return null;
    }
    
  };

  const onFetchView = (object) => {
    if (!object) {
      return;
    }
    onFetchViewData(object);
    SpaceNeo4jModel.onFetchChildren(
      object.slug,
      { topSlug: "", role: "member" },
      (res) => {
        
        onFetchViewData(object);

        const auxData = {...res.data}
        auxData.children = [...decompressGzip(res.data.children)]
        window.$selectedTemplateChildren = [...auxData.children];
      },
      (data) => {
        onFetchViewData(object);
      }
    );
  };

  const onFetchViewData = (object) => {
    setLoading(true);
    ViewModel.onFetch(
      "tiptap",
      object.slug,
      (data) => {
        const auxObject = { ...object };
        auxObject.content = data.content;
        if (type == "templates") {
          setActiveTemplate(auxObject);
        } else if (type == "layouts") {
          setActiveLayout(auxObject);
        } else if (type == "models") {
          setActiveModels(auxObject);
        }
        setTimeout(() => {
          setLoading(false);
        }, 250);
      },
      (data) => {
        setLoading(false);
      }
    );
  };

  const onUseView = () => {
    if (type == "templates") {
      props.onAddTemplate(activeTemplate);
    } else if (type == "layouts") {
      props.onAddLayout(activeLayout.content.default);
    } else if (type == "models") {
      props.onAddTemplate(activeModels);
    }
    setOpened(false);
  };

  const onSelectedName = () => {
    if (type == "templates") {
      return activeTemplate.name;
    } else if (type == "layouts") {
      return activeLayout.name;
    } else if (type == "models") {
      return activeModels.name;
    }
    setOpened(false);
  };

  const onButtonName = () => {
    if (type == "templates") {
      return "template";
    } else if (type == "layouts") {
      return "Layout";
    } else if (type == "models") {
      return "template";
    }
    setOpened(false);
  };

  const getEmojiCode = (emoji) => {
    if (emoji) {
      return data.emojis[emoji].skins[0].native;
    } else {
      return null;
    }
  };

  const displayEmoji = (emojicon) => {
    if (emojicon && emojicon != "") {
      if (emojicon.split("/")[0] === "emoji") {
        return <Text>{getEmojiCode(emojicon.split("/")[1])}</Text>;
      } else if (emojicon.split("/")[0] === "icon") {
        const iconType = emojicon.split("/")[1].split("#")[0];
        const iconValue = emojicon.split("/")[1].split("#")[1];
        if (iconValue === "bolt") {
          return <FontAwesomeIcon icon={solid("bolt")} />;
        }
        return <FontAwesomeIcon icon={[iconType, iconValue]} />;
        // return (<FontAwesomeIcon icon={solid(iconValue)} />)
      } else {
        return <Text>{getEmojiCode(emojicon)}</Text>;
      }
    }
    return <FontAwesomeIcon icon={solid("bolt")} />;
  };

  const filterTemplates = () => {
    if (selectedTemplateTags.length == 0) {
      return templates;
    }
    return templates.filter((item) => {
      return sharesTags(selectedTemplateTags, item.tagged_with);
    });
  };

  const filterLayouts = () => {
    if (selectedLayoutsTags.length == 0) {
      return layouts;
    }
    return layouts.filter((item) => {
      return sharesCategories(selectedLayoutsTags, item.name);
    });
  };

  const filterModals = () => {
    if (selectedModelsTags.length == 0) {
      return models;
    }
    return models.filter((item) => {
      return sharesTags(selectedModelsTags, item.tagged_with);
    });
  };

  const sharesTags = (selected, tagged) => {
    // Check if at least one element in arr1 has a matching key in arr2
    return selected.some((item1) =>
      tagged.some((item2) => item1 === item2.name)
    );
  };

  const sharesCategories = (selected, category) => {
    // Check if at least one element in arr1 has a matching key in arr2
    return selected.indexOf(category) >= 0;
  };

  const onEdit = () => {
    if (type == "models") {
      window.open(`/space/${activeModels.slug}/content?edit=true`, "_blank");
    }
  };

  const getTagsNames = (template) => {
    let tags = [];
    for (let i = 0; i < template.tagged_with.length; i++) {
      tags.push(`${template.tagged_with[i].name}`);
    }

    return tags.join(", ");
  };

  return (
    <Modal
      styles={{
        title: { width: "100%" },
        inner: {
          overflow: "hidden",
          height: "90vh",
        },
        header: {
          marginRight: "-15px",
          marginBottom: "0px",
        },
      }}
      withCloseButton={false}
      opened={opened}
      onClose={() => setOpened(false)}
      // overflow="inside"
      title={
        <Box sx={{ padding: "20px", backgroundColor: "#f1f3f5" }}>
          <SegmentedControl
            value={type}
            onChange={setType}
            fullWidth
            size="lg"
            radius="xl"
            data={[
              { label: "Templates", value: "templates" },
              { label: "Layouts", value: "layouts" },
              { label: "Saved", value: "models" },
            ]}
          />
        </Box>
      }
      padding={0}
      size="90%"
      zIndex={99999}
    >
      <Grid>
        <Grid.Col span={3} sx={{ borderRight: "1px solid #f1f3f5" }}>
          <ScrollArea style={{ height: "90vh" }} pl={30} pr={30}>
            {type == "templates" && (
              <MultiSelect
                // variant="unstyled"
                data={templatesTags.map((item) => ({
                  label: item.name,
                  value: item.name,
                }))}
                placeholder="Filter by tags"
                zIndex={99999}
                value={selectedTemplateTags}
                onChange={setSelectedTemplateTags}
                mt={20}
                mb={20}
                radius={"xl"}
                size="lg"
              />
            )}
            {type == "layouts" && (
              <MultiSelect
                data={[
                  { label: "Content", value: "Content" },
                  { label: "People", value: "People" },
                  { label: "Form", value: "Forms" },
                  { label: "Features", value: "Features" },
                  { label: "Call to action", value: "Call to action" },
                ]}
                placeholder="Filter by category"
                zIndex={99999}
                value={selectedLayoutsTags}
                onChange={setSelectedLayoutsTags}
                mt={20}
                mb={20}
                radius={"xl"}
                size="lg"
              />
            )}
            {type == "models" && <Box pt={25}></Box>}

            {type == "templates" &&
              filterTemplates().map((template, index) => {
                return (
                  <Group
                    noWrap
                    spacing={10}
                    className={classes.sidebarItem}
                    onClick={() => setActiveTemplateTab(index)}
                    align="top"
                  >
                    <Box sx={{ width: "20px" }}>
                      {displayEmoji(template.emojicon)}
                    </Box>
                    <Stack spacing={0}>
                      <Text align="left" weight={600}>
                        {`${template.name}`}
                      </Text>
                      <Text
                        size="sm"
                        color="dimmed"
                        lineClamp={1}
                        align="left"
                        mt={-5}
                      >
                        {`${getTagsNames(template)}`}
                      </Text>
                    </Stack>
                  </Group>
                );
              })}
            {type == "layouts" &&
              layouts.length > 0 &&
              filterLayouts().map((layout, index) => {
                return (
                  <Stack noWrap spacing={10}>
                    <Text align="left" weight={600}>{`${layout.name}`}</Text>
                    <Box>
                      {layout.blocks.map((block, index) => {
                        return (
                          <Group
                            noWrap
                            spacing={10}
                            mb={5}
                            pb={5}
                            sx={{ borderBottom: "1px solid #efefef" }}
                            
                          >
                            <Box className={classes.image} >
                            <Image
                              radius={5}
                              src={block.image}
                              onClick={() => onFetchView(block)}
                              sx={{ cursor: "pointer" }}
                            />

                            </Box>
                          </Group>
                        );
                      })}
                    </Box>
                  </Stack>
                );
              })}

            {type == "models" &&
              filterModals().map((model, index) => {
                return (
                  <Group
                    noWrap
                    spacing={10}
                    className={classes.sidebarItem}
                    onClick={() => setActiveModelsTab(index)}
                  >
                    <Box sx={{ width: "20px" }}>
                      {displayEmoji(model.emojicon)}
                    </Box>
                    <Text align="left" weight={600}>{`${model.name}`}</Text>
                  </Group>
                );
              })}
              {type == "models" && filterModals().length == 0 && (
                <Center>You have no saved templates</Center>
              )}
            <Box mb={200}></Box>
          </ScrollArea>
        </Grid.Col>
        <Grid.Col span={9}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              zIndex: 99999999,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                paddingLeft: 35,
                paddingRight: 35,
                paddingBottom: 10,
              }}
            >
              <Box
                sx={{
                  // backgroundColor: "#f8f8f8",
                  padding: 20,
                  // borderRadius: 10,
                  borderBottom: "1px solid #f1f3f5",
                }}
                mt={10}
                pb={30}
              >
                <Group position="apart">
                  <Text weight="700" size="lg">
                    {type != "layouts" ? onSelectedName() : ""}
                  </Text>
                  <Group position={"right"}>
                    <Box>
                      <Button
                        disabled={filterModals().length == 0 && type == "models"}
                        radius="xl"
                        onClick={() => onUseView()}
                      >{`Use ${onButtonName()}`}</Button>
                    </Box>
                    {type == "models" && (
                      <Box>
                        <Button
                          disabled={filterModals().length == 0}
                          radius="xl"
                          onClick={() => onEdit()}
                        >{`Edit ${onButtonName()}`}</Button>
                      </Box>
                    )}
                  </Group>
                </Group>
              </Box>
            </Box>
          </Box>
          <ScrollArea
            style={{ height: "90vh" }}
            sx={{
              paddingTop: 100,
            }}
          >
            {loading ? (
              <Center mt={40}>
                <Loader />
              </Center>
            ) : (
              <Box
                sx={{
                  paddingLeft: 35,
                  paddingRight: 35,
                  paddingTop: 20,
                }}
              >
                {activeTemplate.content && type == "templates" && (
                  <TiptapNoHP
                    key={`tiptap-${new Date()}`}
                    content={activeTemplate.content.default}
                    spaceWidth={"narrow"}
                  />
                )}
                {activeLayout.content && type == "layouts" && (
                  <TiptapNoHP
                    key={`tiptap-${new Date()}`}
                    content={activeLayout.content.default}
                    spaceWidth={"narrow"}
                  />
                )}
                {activeModels.content && type == "models" && (
                  <TiptapNoHP
                    key={`tiptap-${new Date()}`}
                    content={activeModels.content.default}
                    spaceWidth={"narrow"}
                  />
                )}
              </Box>
            )}
            <Box mb={200}></Box>
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default TemplatesModal;
