import * as React from "react"

const SvgComponent = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 301.39 334.88"
    style={{
      enableBackground: "new 0 0 301.39 334.88",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st0,.st1{stroke:currentColor;stroke-miterlimit:10}.st0{fill:none;stroke-width:25}.st1{stroke-width:5}.st1,.st2{fill:none}.st2,.st3{fill:currentColor;stroke:currentColor;stroke-miterlimit:10},.st5{stroke:currentColor}"
      }
    </style>
    <path
      className="st0"
      d="M298.96 105.42c-.1-.17-.19-.34-.29-.51-26.67-46.19-75.04-72.02-124.8-72.07M49.11 249.05c.03.05.06.1.08.15 26.67 46.19 75.04 72.03 124.8 72.07M173.74 33.31c-24.47-.01-49.28 6.21-72 19.33-22.31 12.88-39.87 30.83-52.07 51.55.1.17.19.34.29.51"
    />
    <path
      className="st0"
      d="M49.4 103.09c.1.17.19.34.29.51 26.54 45.97 74.59 71.78 124.1 72.06M298.77 249.23c-.03-.05-.06-.1-.08-.15-26.67-46.19-75.04-72.02-124.8-72.07M174.09 321.37c.02.04.05.08.07.12 24.5.03 49.33-6.2 72.07-19.32 22.72-13.12 40.51-31.49 52.74-52.69-.03-.05-.05-.1-.08-.15"
    />
    <path
      className="st1"
      d="M175.38 174.74c12.17-20.2 29.46-37.69 51.33-50.31 22.74-13.13 47.58-19.34 72.08-19.31C272.11 59 223.77 33.2 174.05 33.16c-.01.01-.01.02-.02.03 26.1 45.28 24.84 98.85 1.35 141.55z"
    />
    <path
      className="st2"
      d="m174.33 176.6 1.05-1.86c-.37.62-.75 1.24-1.11 1.86h.06z"
    />
    <path
      className="st1"
      d="M173.65 176.59h.02c-26.26-46.04-24.35-100.51.35-143.41-.01-.01-.01-.02-.02-.03-24.47-.02-49.28 6.21-72 19.33-22.3 12.88-39.86 30.83-52.06 51.54.1.17.19.34.29.51.13.23.27.45.4.67 49.02.52 96.52 26.05 123.02 71.39z"
    />
    <path
      className="st2"
      d="M174 177.18c.01-.01.01-.02.02-.03h-.03c0 .01.01.02.01.03z"
    />
    <path
      className="st1"
      d="M173.65 176.59c-49.03-.51-96.52-26.04-123.02-71.38-.46 0-.92.01-1.39.01-24.8 43.06-26.63 97.79-.06 143.94 12.22-21.2 30.02-39.58 52.73-52.69 22.73-13.12 47.55-19.35 72.04-19.33 0-.01.01-.02.01-.03-.1-.17-.21-.34-.31-.52z"
    />
    <path
      className="st2"
      d="M173.97 177.12c.01.01.01.02.02.03-.01-.01-.02-.02-.02-.03zM174.27 176.6c-.2 0-.4 0-.6-.01.1.17.2.35.29.53.11-.18.21-.35.31-.52z"
    />
    <path
      className="st1"
      d="m49.23 249.25.03.06c26.66 46.18 75.02 72.01 124.77 72.07-.02-.04-.05-.08-.07-.12-26.16-45.31-24.91-98.92-1.4-141.64-12.17 20.2-29.46 37.69-51.33 50.31-22.72 13.12-47.53 19.34-72 19.32z"
    />
    <path
      className="st2"
      d="M174 177.18c.01.02.03.05.04.07-.01-.02-.02-.05-.04-.07zM174.04 177.25c0 .01.01.01.01.02s.01.01.01.02c0-.01-.01-.01-.01-.02s0-.02-.01-.02z"
    />
    <path
      className="st1"
      d="M174.06 177.28c26.66 46.18 24.84 100.99 0 144.1 24.49.02 49.3-6.2 72.04-19.32 22.72-13.12 40.51-31.49 52.74-52.69-.01-.01-.01-.02-.02-.03-49.74-.06-98.09-25.89-124.76-72.06zM299.13 105.73c-12.2 20.72-29.75 38.67-52.06 51.55-22.95 13.25-48.02 19.46-72.73 19.32-.11.18-.21.37-.31.55 49.73.07 98.08 25.9 124.73 72.07.02.04.04.08.07.12h.04c24.73-42.96 26.62-97.53.26-143.61z"
    />
    <path
      className="st2"
      d="M173.95 177.15c-.47.82-.94 1.64-1.39 2.47.49-.81.97-1.62 1.44-2.44-.01-.01-.01-.02-.02-.03h-.03z"
    />
    <path
      className="st3"
      d="M187.96 23.09c5.03 8.72 2.05 19.87-6.67 24.91-8.72 5.03-19.87 2.05-24.9-6.67-5.03-8.72-2.05-19.87 6.67-24.9 8.71-5.05 19.86-2.06 24.9 6.66zM314.39 95.7c5.03 8.72 2.05 19.87-6.67 24.91-8.72 5.03-19.87 2.05-24.9-6.67-5.03-8.72-2.04-19.87 6.67-24.9 8.71-5.05 19.86-2.06 24.9 6.66zM65.18 93.97c5.03 8.72 2.05 19.87-6.67 24.91-8.72 5.03-19.87 2.05-24.91-6.67-5.03-8.72-2.05-19.87 6.67-24.9 8.72-5.04 19.87-2.06 24.91 6.66zM189.3 166.7c5.03 8.72 2.05 19.87-6.67 24.91-8.72 5.03-19.87 2.05-24.9-6.67-5.03-8.72-2.05-19.87 6.67-24.9 8.72-5.05 19.87-2.06 24.9 6.66zM188.78 312.88c5.03 8.72 2.05 19.87-6.67 24.91-8.72 5.03-19.87 2.05-24.9-6.67-5.04-8.72-2.05-19.87 6.67-24.9 8.72-5.05 19.87-2.06 24.9 6.66zM64.56 239.34c5.03 8.72 2.05 19.87-6.67 24.91-8.72 5.03-19.87 2.05-24.9-6.67-5.03-8.72-2.05-19.87 6.67-24.9 8.71-5.04 19.87-2.05 24.9 6.66zM313.58 240.67c5.03 8.72 2.05 19.87-6.67 24.9-8.72 5.03-19.87 2.05-24.9-6.67-5.03-8.72-2.05-19.87 6.67-24.9 8.72-5.03 19.87-2.04 24.9 6.67z"
    />

  </svg>
)

export default SvgComponent
