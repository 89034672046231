import React, { useState, useEffect, useRef } from "react";

import {
  Divider,
  Text,
  Table,
  Group,
  ScrollArea,
  Button,

  Box,
  TextInput,
  Checkbox,
  Loader,
} from "@mantine/core";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Row from "./ProgressActivityRow";
import MemberModel from "../../../../models/Member";
import ManagerModel from "../../../../models/Manager";

import PremiumModal from "../../../app/PremiumModal";

import Sorter from "./Sorter";

const paginator = {
  page: 1,
  per: 30,
};

const loader = {
  loading: false,
  oldScope: "all",
};

export default function ProgressActivity(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const location = useLocation();
  // const theme = useMantineTheme();
  const theme = props.theme;
  const { height, width } = useViewportSize();

  const [orderBy, setOrderBy] = useState({ by: "name", as: "asc" });

  const [members, setMembers] = useState({
    total: 0,
    page: 1,
    pages: 1,
    data: [],
  });
  const [scope, setScope] = useState(null);
  const [term, setTerm] = useState(null);
  const [counts, setCounts] = useState({
    all: 0,
    active: 0,
    pending: 0,
    managers: 0,
  });

  const largeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.lg}px)`
  );
  const extraLargeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );

  const [value, setValue] = useState([
    new Date(2021, 11, 1),
    new Date(2021, 11, 5),
  ]);

  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  const timeoutId = useRef();

  const onSelect = (id) => {
    const newSelected = [...selected];
    const index = selected.indexOf(id);
    if (index >= 0) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  const onSelectAll = (all) => {
    const newSelected = [];
    if (all) {
      for (let i = 0; i < members.data.length; i++) {
        newSelected.push(members.data[i].username);
      }
    }
    setSelected(newSelected);
  };

  const areAllSelected = () => {
    return members.data.length == selected.length;
  };

  const hasSelections = () => {
    return selected.length > 0;
  };

  const isAnySelected = () => {
    return !areAllSelected() && selected.length > 0;
  };

  const isSelected = (id) => {
    return selected.indexOf(id) >= 0;
  };

  const tableWidth = () => {
    if (largeScreen) {
      return width - 300;
    } else if (extraLargeScreen) {
      return width - 500;
    } else {
      return "";
    }
  };

  const checkSearchParams = () => {
    const search = location.search;
    const scope = new URLSearchParams(search).get("scope");

    if (scope) {
      setScope(scope);
    }
  };

  const canAddNewManagers = () => {
    const valid =
      counts.managers < space.permissions.max_allowed_managers[0] ||
      space.permissions.max_allowed_managers[0] == -1;
    if (!valid) {
      setOpened([
        true,
        space.permissions.max_allowed_managers[1],
        space.permissions.max_allowed_managers[2],
      ]);
    }
    return valid;
  };

  useEffect(() => {
    if (space && space.slug) {
      paginator.page = 1;
      onFetch();
      // onFetchCounts();
    }
  }, [space, scope]);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [term]);

  useEffect(() => {
    if (props.refresh) {
      
      onFetch(props.refresh);
      // onFetchCounts();
      props.setRefresh(false);
    }
  }, [props]);

  const onFetch = (refresh=null) => {
    setLoading(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {

      if (refresh == "more") {
        paginator.page++;
      }

      if (loader.oldScope != scope) {
        paginator.page = 1;
        loader.oldScope = scope;
      }
      const Model = scope != "managers" ? MemberModel : ManagerModel;
      Model.onFetch(
        space.slug,
        paginator,
        { scope, term },
        (data) => {
          setLoading(false)
          const newData = { ...data };

          if (paginator.page == 1) {
            newData.data = [].concat(data.data);
          } else {
            newData.data = members.data.concat(data.data);
          }

          if (data.data.length == 0 && paginator.page > 1) {
            paginator.page--;
          }

          setMembers(newData);
        },
        (data) => {
          setLoading(false)
        }
      );
    }, 1000);
  };

  const onFetchCounts = () => {
    MemberModel.onFetchCounts(
      space.slug,
      (data) => {
        setCounts(data);
      },
      (data) => {}
    );
  };

  const onKeyPress = (event) => {
    onKeyGeneral(event);
  };
  const onKeyDown = (event) => {
    onKeyGeneral(event);
  };

  const onKeyGeneral = (event) => {
    if (event.which == 13) {
      event.preventDefault();
      onSearch();
    }
  };

  const onSearch = () => {
    setTerm(document.getElementById("search-input").value);
  };

  const onClearSearch = () => {
    document.getElementById("search-input").value = "";
    setTerm("");
  };

  const changePage = (page) => {
    paginator.page = page;
    onFetch();
  };

  const onMemberUpdate = (member) => {
    onFetchCounts();
  };

  const onSort = (sortBy) => {
    const aux = { ...orderBy };
    aux.by = sortBy;
    aux.as = aux.as == "asc" ? "desc" : "asc";
    setOrderBy(aux);

    const auxMembers = { ...members };
    if (aux.as == "asc") {
      auxMembers.data = auxMembers.data.sort((a, b) => {
        const nameA =
          typeof a[getSortKey(sortBy)] === "string"
            ? a[getSortKey(sortBy)].toUpperCase()
            : a[getSortKey(sortBy)];
        const nameB =
          typeof b[getSortKey(sortBy)] === "string"
            ? b[getSortKey(sortBy)].toUpperCase()
            : b[getSortKey(sortBy)];

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0; // names must be equal
      });
    } else {
      auxMembers.data = auxMembers.data.sort((a, b) => {
        const nameA =
          typeof a[getSortKey(sortBy)] === "string"
            ? a[getSortKey(sortBy)].toUpperCase()
            : a[getSortKey(sortBy)];
        const nameB =
          typeof b[getSortKey(sortBy)] === "string"
            ? b[getSortKey(sortBy)].toUpperCase()
            : b[getSortKey(sortBy)];

        if (nameA < nameB) {
          return 1;
        }

        if (nameA > nameB) {
          return -1;
        }

        return 0; // names must be equal
      });
    }

    console.log(auxMembers.data);
    console.log(getSortKey(sortBy));
    console.log(aux);
    setMembers(auxMembers);
  };

  const getSortKey = (sortBy) => {
    return sortBy == "name"
      ? "full_name"
      : sortBy == "participation"
      ? showWords
        ? "avg_post_size"
        : "posts_count"
      : sortBy;
  };

  return (
    <div>
      <Group position="apart" mt={20}>
        <Group position="left" spacing="xs">
          <TextInput
            icon={<FontAwesomeIcon icon={solid("search")} />}
            placeholder={t("spaces.settings.members.progressActivity.progress.searchPlaceholder")}
            id="search-input"
            radius="xl"
            onKeyPress={(event) => onKeyPress(event)}
            onKeyDown={(event) => onKeyDown(event)}
            rightSection={
              term ? (
                <Text
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => onClearSearch()}
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </Text>
              ) : (
                <Text>{"  "}</Text>
              )
            }
          />
          <Button
            variant="filled"
            radius="xl"
            onClick={(event) => onSearch()}
            sx={{
              backgroundColor: theme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: theme.colors.sutrablue[4],
              },
            }}
          >
            {t("spaces.settings.members.progressActivity.progress.searchButton")}
          </Button>
          {loading && <Loader size="xs"/>}
        </Group>
        {hasSelections() && (
          <Group position="right" spacing="xs">
            <Button
              radius="xl"
              sx={{
                backgroundColor: theme.colors.sutrablue[3],
                "&:hover": {
                  backgroundColor: theme.colors.sutrablue[4],
                },
              }}
              onClick={(event) =>
                props.onSendBroadcast("email", members.data, selected)
              }
            >
              {t("spaces.settings.members.progressActivity.progress.emailButton")}
            </Button>
            <Button
              radius="xl"
              sx={{
                backgroundColor: theme.colors.sutrablue[3],
                "&:hover": {
                  backgroundColor: theme.colors.sutrablue[4],
                },
              }}
              onClick={(event) =>
                props.onSendBroadcast("message", members.data, selected)
              }
            >
              {t("spaces.settings.members.progressActivity.progress.messageButton")}
            </Button>
          </Group>
        )}
      </Group>

      <ScrollArea mt={10} style={{ width: tableWidth() }}>
        {(members.data.length == 0 && !loader.loading) ||
        (members.data.length > 0 && !members.data[0].has_progress) ? (
          <Box
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              textAlign: "center",
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
              cursor: "pointer",

              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[5]
                    : theme.colors.gray[1],
              },
            })}
          >
            {t("spaces.settings.members.progressActivity.progress.noRecordFound")}
            {members.data.length > 0 && !members.data[0].has_progress && (
              <p>
                {t("spaces.settings.members.progressActivity.progress.progressTracking")}
              </p>
            )}
          </Box>
        ) : (
          <Table verticalSpacing="xs" highlightOnHover>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={areAllSelected()}
                    indeterminate={isAnySelected()}
                    onChange={(event) =>
                      onSelectAll(event.currentTarget.checked)
                    }
                  />
                </th>
                <th>
                {t("spaces.settings.members.progressActivity.progressTable.member")}{" "}
                  <Sorter orderBy={orderBy} onSort={onSort} sortBy={"name"} />
                </th>
                <th>
                {t("spaces.settings.members.progressActivity.progressTable.progress")}{" "}
                  <Sorter
                    orderBy={orderBy}
                    onSort={onSort}
                    sortBy={"progress"}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {members.data.map((member) => {
                return (
                  <Row
                    hasSubscriptions={
                      space && space.permissions
                        ? space.permissions.can_accept_charges
                        : false
                    }
                    spaceId={space.slug}
                    onUpdate={onMemberUpdate}
                    member={member}
                    canAddNewManagers={canAddNewManagers}
                    counts={props.counts}
                    isSelected={isSelected}
                    onSelect={onSelect}
                  />
                );
              })}
            </tbody>
          </Table>
        )}
      </ScrollArea>
      <Divider sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: 20,
          justifyContent: "center",
        }}
      ></Box>

      <PremiumModal opened={opened} setOpened={setOpened} />
    </div>
  );
}
