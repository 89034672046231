import {
  Plugin,
  PluginKey,
  TextSelection,
  NodeSelection,
} from "prosemirror-state";
import { serializeForClipboard, parseFromClipboard } from "../utils/clipboard";
import { Extension } from "@tiptap/core";

import { isEmptySelection } from "../utils/selection";

import Swal from "sweetalert2";
import { colors } from "../../../collections/colors";

import { v4 as uuidv4 } from "uuid";

const brokenClipboardAPI = !(navigator.userAgent.indexOf("Chrome") != -1); // (browser.ie && browser.ie_version < 15) || (browser.ios && browser.webkit_version < 604)

const nameToBlocks = {
  circle: "space",
  grid1: "grid",
  grid2: "grid",
  grid3: "grid",
  grid4: "grid",
  column: "column",
  resource: "collapsable section",
  survey: "survey",
  interactiveInput: "interactive input",
  tagsFeed: "tag feed",
  infobox: "info box",
};

export function getNode(node, coords) {
  try {
    node = node.node;

    while (node && node.parentNode) {
      if (
        (node.parentNode.classList &&
          node.parentNode.classList.contains("ProseMirror")) ||
        (node.dataset && node.dataset.uid)
      ) {
        // todo
        break;
      }
      node = node.parentNode;
    }
  } catch (e) {}
  return node;
}

export function blockPosAtCoords(coords, view) {
  let pos = view.posAtCoords(coords);
  if (!pos) {
    return null;
  }
  let node = view.domAtPos(pos.pos);

  node = getNode(node, coords);

  if (node && node.nodeType === 1) {
    let desc = view.docView.nearestDesc(node, true);
    if (!(!desc || desc === view.docView)) {
      return desc.posBefore;
    }
  }
  return null;
}

export function blockAtPos(pos, view) {
  let node = view.domAtPos(pos);

  node = getNode(node, view.coordsAtPos(pos));

  if (node && node.nodeType === 1) {
    let desc = view.docView.nearestDesc(node, true);
    if (!(!desc || desc === view.docView)) {
      return desc.posBefore;
    }
  }
  return null;
}

export function onDragStart(e, pos, editor) {
  if (!e.dataTransfer) return;

  e.preventDefault();
  e.stopPropagation();

  pos = editor.view.state.selection.from;

  let slice = editor.view.state.selection.content();
  let node = editor.view.state.selection.node;

  if (!node && slice.content.content.length == 0) {
    editor.commands.selectTextblockStart();
    pos = editor.view.state.selection.from;
    let selectBack = false;
    try {
      
      if (pos > 0) {
        editor.commands.setNodeSelection(pos - 1);
        selectBack = true;
      }
    } catch (e) {
      editor.commands.setNodeSelection(pos);

      const auxSelection = editor.view.state.selection;
      if (
        auxSelection.$anchor.depth > 0 &&
        auxSelection.$anchor.path[3].type.name == "heading"
      ) {
        editor.commands.selectParentNode();
      }
      selectBack = false;
    }

    node = editor.view.state.selection.node;

    if (node && node.type.name == "text") {
      editor.commands.selectParentNode();
    }

    try {
      if (pos > 1) {
        let auxNode = editor.view.state.doc.nodeAt(pos - 2);

        if (
          auxNode &&
          (auxNode.type.name == "listItem" || auxNode.type.name == "taskItem")
        ) {
          editor.commands.setNodeSelection(pos - 2);
        }
      }
    } catch (e) {
    } finally {
    }

    slice = editor.view.state.selection.content();
  }

  let { dom, text } = serializeForClipboard(editor.view, slice);
  console.log(dom);

  // e.dataTransfer.clearData()
  try {
    e.dataTransfer.effectAllowed = "all";

    e.dataTransfer.setData(
      brokenClipboardAPI ? "text" : "text/html",
      dom.innerHTML
    );
    if (!brokenClipboardAPI)
      e.dataTransfer.setData("text/plain", dom.innerText);

    editor.view.dragging = { slice, move: true };

    return slice;
  } catch (e) {
  } finally {
  }

  // }
}

function onPlusClick(e, view) {
  let coords = { left: e.clientX + 50, top: e.clientY };
  let pos = view.posAtCoords(coords);
  let node = view.domAtPos(pos.pos);

  node = getNode(node, coords);

  const isRootDepth = view.state.selection.$anchor.depth === 1;
  const isEmptyTextBlock =
    view.state.selection.$anchor.parent.isTextblock &&
    !view.state.selection.$anchor.parent.type.spec.code &&
    !view.state.selection.$anchor.parent.textContent;

  view.dom.editor.commands.focus(pos.pos);
  if (view.state.selection.empty && isEmptyTextBlock) {
    view.dom.editor.chain().focus(pos.pos).run();
  } else {
    if (view.state.selection.$anchor.parent.isTextblock) {
      view.dom.editor
        .chain()
        .insertContentAt(
          pos.pos + view.state.selection.$anchor.parent.content.size,
          { type: "paragraph" }
        )
        .focus()
        .run();
    } else {
      // view.dom.editor.chain().insertContentAt(view.state.selection.$anchor.pos + view.state.selection.$anchor.parent.content.size, {type: "paragraph"}).focus().run()
      view.dom.editor
        .chain()
        .insertContentAt(
          view.state.selection.$anchor.pos +
            view.state.selection.$anchor.parent.content.size,
          { type: "paragraph" }
        )
        .focus()
        .run();
    }
  }

  setTimeout(() => {
    let clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    document
      .getElementById("btn-tiptap-more-options")
      .dispatchEvent(clickEvent);
  }, 50);
}

export function onDragClick(e, view) {
  let coords = { left: e.clientX + 50, top: e.clientY };
  let pos = view.posAtCoords(coords);
  let node = view.domAtPos(pos.pos);

  node = getNode(node, coords);
  view.dom.editor.commands.focus(pos.pos);

  if (
    !view.state.selection.$anchor.parent.isTextblock &&
    view.state.selection.$anchor.parent.type.name != "circle"
  ) {
    view.dom.editor.commands.focus(
      view.state.selection.$anchor.pos +
        view.state.selection.$anchor.parent.content.size
    );
  }
}

function onDeletedNode(node) {}

let dropElement;
let addElement;
const WIDTH = 24;

const EventHandler = Extension.create({
  name: "eventHandler",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("eventHandler"),

        props: {
          handleDOMEvents: {
            copy: (view, event) => {
              
              if (brokenClipboardAPI) {
                console.log("brokenClipboardAPI");
                return;
              }
              event.preventDefault();
              const slice = view.state.selection.content();

              console.log(slice);
              let { dom, text } = serializeForClipboard(view, slice);
              console.log(dom);
              console.log("COPYING");
              console.log(dom.innerHTML);

              event.clipboardData.clearData();
              try {
                event.clipboardData.effectAllowed = "all";

                event.clipboardData.setData(
                  brokenClipboardAPI ? "text" : "text/html",
                  dom.innerHTML
                );

                if (!brokenClipboardAPI)
                  event.clipboardData.setData("text/plain", dom.innerText);

                view.dragging = { slice, move: true };

                return slice;
              } catch (e) {
                console.log(e);
              } finally {
              }
            },
            cut: (view, event) => {
              
              const dontDelete = [
                "grid1",
                "grid2",
                "grid3",
                "grid4",
                "column",
                "resource",
                "circle",
                "survey",
                "interactiveInput",
                "tagsFeed",
                "infobox",
                "orderedList",
                "bulletList",
                "listItem",
              ];

              const { state, dispatch } = view;
              const { selection } = state;
              const { $from, $to } = selection;
              let tr = state.tr;
  
              // Check if the selection is not empty
              let positionsToDelete = [];
              if (!$from.sameParent($to)) {
                state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
                  if (node.isBlock && dontDelete.indexOf(node.type.name) < 0) {
                    let startPos = pos;
                    let endPos = pos + node.nodeSize;
  
                    if ($from.pos > startPos && $from.pos < endPos) {
                      startPos = $from.pos;
                    }
  
                    if ($to.pos > startPos && $to.pos < endPos) {
                      endPos = $to.pos;
                    }
                    positionsToDelete.push({
                      start: startPos,
                      end: endPos,
                      name: node.type.name,
                    });
                  }
                });
  
                console.log(positionsToDelete);
                let changed = false;
                for (let i = positionsToDelete.length - 1; i >= 0; i--) {
                  try{
                    const { start, end, name } = positionsToDelete[i];
                    tr = tr.delete(start, end);
                    changed = true;
                  }
                  catch{

                  }
                }
  
                if (changed) {
                  event.preventDefault();
                  document.execCommand("copy");
                  view.dispatch(tr);
                  return true;
                }
                return false;
              } else {
                // Handle single node cut
                try {
                  const posBefore = $from.pos + 1;
                  const posBefore2 = $from.pos + 2;
                  const currentNode = state.doc.nodeAt($from.pos);
                  const nodeBefore = state.doc.nodeAt(posBefore);
                  const nodeBefore2 = state.doc.nodeAt(posBefore2);
                  let nodeToDelete = null;
                  let nodeToDeletePos = null;
  
                  if (
                    currentNode &&
                    dontDelete.indexOf(currentNode.type.name) >= 0 && currentNode.type.name != "listItem"
                  ) {
                    nodeToDelete = currentNode;
                    nodeToDeletePos = $from.pos;
                  } else if (
                    nodeBefore &&
                    dontDelete.indexOf(nodeBefore.type.name) >= 0 && nodeBefore.type.name != "listItem"
                  ) {
                    nodeToDelete = nodeBefore;
                    nodeToDeletePos = posBefore;
                  } else if (
                    nodeBefore2 &&
                    dontDelete.indexOf(nodeBefore2.type.name) >= 0 && nodeBefore2.type.name != "listItem"
                  ) {
                    nodeToDelete = nodeBefore2;
                    nodeToDeletePos = posBefore2;
                  }
  
                  if (nodeToDelete) {
                    event.preventDefault();
                    Swal.fire({
                      title: "Are you sure?",
                      html: `You are about to cut a content block.`,
                      showDenyButton: true,
                      confirmButtonText: "Yes, cut",
                      denyButtonText: "No",
                      confirmButtonColor: colors.red,
                      denyButtonColor: colors.gray,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        document.execCommand("copy");
                        tr = tr.delete(nodeToDeletePos, nodeToDeletePos + 1);
                        view.dispatch(tr);
                        onDeletedNode(nodeToDelete);
                      } else if (result.isDenied) {
                        // If denied, do nothing
                      }
                    });
                    return true;
                  }
                } catch (e) {
                  console.error("Error handling cut event:", e);
                }
              }
              return false;
            }
          },
          handleKeyDown: (view, event) => {
            const dontDelete = [
              "grid1",
              "grid2",
              "grid3",
              "grid4",
              "column",
              "resource",
              "circle",
              "survey",
              "interactiveInput",
              "interactiveButton",
              "interactiveWait",
              "tagsFeed",
              "infobox",
              "orderedList",
              "bulletList",
              "listItem",
              "video", 
            ];

            const normalEnter = [
              "grid1",
              "grid2",
              "grid3",
              "grid4",
              "column",
              "resource",
              "circle",
              "survey",
              "interactiveInput",
              "interactiveButton",
              "interactiveWait",
              "tagsFeed",
              "infobox",
              "orderedList",
              "bulletList",
              "listItem",
              "video", 
              "image", 
              "file", 
              "audio", 
              "embed", 
              "actionbutton", 
              "customform", 
            ];

            const { selection } = view.state;
            const { $anchor, empty } = selection;

            if (event.key == "Enter") {
              // event.preventDefault();

              if (
                !(
                  selection.$anchor.depth > 1 &&
                  selection.$anchor.path[3] &&
                  selection.$anchor.path[3].type.name.indexOf("List")
                )
              ) {
                if (!isEmptySelection(view.dom.editor)) {
                  let node = view.state.doc.nodeAt(selection.$anchor.pos);
                  if (!node){
                    node = selection.$anchor.parent;
                  }
                  
                  if (normalEnter.indexOf(node.type.name) >= 0){
                    return false;
                  }
                  view.dom.editor.commands.customEnter();
                  return true;
                }
              }
              // return true;
            }

            if (
              selection.$anchor.depth > 1 &&
              selection.$anchor.path[3] &&
              selection.$anchor.path[3].type.name.indexOf("List") >= 0
            ) {
              return false;
            }

            if (event.key == "Backspace") {
              const { state, dispatch } = this.editor;
              const { selection } = state;
              const { $from, $to } = selection;
              let tr = state.tr;

              // Check if the selection is not empty
              let positionsToKeep = [];
              let positionsToDelete = [];

              if (!$from.sameParent($to)) {
                state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
                  // Check if the node is a 'circle' node
                  if (node.isBlock) {
                    if (dontDelete.indexOf(node.type.name) < 0) {
                      let startPos = pos;
                      let endPos = pos + node.nodeSize;

                      if ($from.pos > startPos && $from.pos < endPos) {
                        startPos = $from.pos;
                      }

                      if ($to.pos > startPos && $to.pos < endPos) {
                        endPos = $to.pos;
                      }
                      positionsToDelete.push({
                        start: startPos,
                        end: endPos,
                        name: node.type.name,
                      });
                    }
                  }
                });
                console.log(positionsToKeep);
                console.log(positionsToDelete);
                let changed = false;
                for (let i = positionsToDelete.length - 1; i >= 0; i--) {
                  try{
                    const { start, end, name } = positionsToDelete[i];
                    tr = tr.delete(start, end);
                    changed = true;
                  }
                  catch{

                  }
                }

                if (changed) {
                  this.editor.view.dispatch(tr);
                  return true;
                }
                return true;
              } else {
                // Handle single backspace (single character or node deletion)

                try {
                  const posBefore = $from.pos - 1;
                  const posBefore2 = $from.pos - 2;
                  const currentNode = state.doc.nodeAt($from.pos);
                  const nodeBefore = state.doc.nodeAt(posBefore);
                  const nodeBefore2 = state.doc.nodeAt(posBefore2);

                  console.log(`${currentNode.type.name}`)
                  console.log(`${nodeBefore.type.name}`)
                  console.log(`${nodeBefore2.type.name}`)

                  let nodeToDelete = null;
                  let nodeToDeletePos = null;
                  if (
                    currentNode &&
                    dontDelete.indexOf(currentNode.type.name) >= 0 && currentNode.type.name != "listItem"
                  ) {
                    nodeToDelete = currentNode;
                    nodeToDeletePos = $from.pos;
                  } else if (
                    nodeBefore &&
                    dontDelete.indexOf(nodeBefore.type.name) >= 0 && nodeBefore.type.name != "listItem"
                  ) {
                    nodeToDelete = nodeBefore;
                    nodeToDeletePos = posBefore;
                  } else if (
                    nodeBefore2 &&
                    dontDelete.indexOf(nodeBefore2.type.name) >= 0 && nodeBefore2.type.name != "listItem"
                  ) {
                    nodeToDelete = nodeBefore2;
                    nodeToDeletePos = posBefore2;
                  }
                  console.log(`-----------------`)
                  console.log(`${nodeToDelete.type.name}`)
                  if (
                    (currentNode &&
                      dontDelete.indexOf(currentNode.type.name) >= 0) ||
                    (nodeBefore &&
                      dontDelete.indexOf(nodeBefore.type.name) >= 0) ||
                    (nodeBefore2 &&
                      dontDelete.indexOf(nodeBefore2.type.name) >= 0)
                  ) {
                    Swal.fire({
                      title: "Are you sure?",
                      html: `You are about to delete a content block.`,
                      showDenyButton: true,
                      confirmButtonText: "Yes, delete",
                      denyButtonText: "No",
                      confirmButtonColor: colors.red,
                      denyButtonColor: colors.gray,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        console.log(nodeToDelete);
                        console.log(nodeToDeletePos);
                        tr = tr.delete(nodeToDeletePos, nodeToDeletePos + 1);
                        this.editor.view.dispatch(tr);
                        onDeletedNode(nodeToDelete);
                      } else if (result.isDenied) {
                      }
                    });
                    return true;
                  } else {
                    // Default backspace behavior for other nodes or text
                    return false;
                  }
                } catch (e) {
                  console.log(e)
                }
              }
              return false;
            }
            if (event.key == "Delete") {
              const { state, dispatch } = this.editor;
              const { selection } = state;
              const { $from, $to } = selection;
              let tr = state.tr;

              // Check if the selection is not empty
              let positionsToKeep = [];
              let positionsToDelete = [];
              if (!$from.sameParent($to)) {
                state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
                  // Check if the node is a 'circle' node
                  if (node.isBlock) {
                    if (dontDelete.indexOf(node.type.name) < 0) {
                      let startPos = pos;
                      let endPos = pos + node.nodeSize;

                      if ($from.pos > startPos && $from.pos < endPos) {
                        startPos = $from.pos;
                      }

                      if ($to.pos > startPos && $to.pos < endPos) {
                        endPos = $to.pos;
                      }
                      positionsToDelete.push({
                        start: startPos,
                        end: endPos,
                        name: node.type.name,
                      });
                    }
                  }
                });
                console.log(positionsToKeep);
                console.log(positionsToDelete);
                let changed = false;
                for (let i = positionsToDelete.length - 1; i >= 0; i--) {
                  try{
                    const { start, end, name } = positionsToDelete[i];
                    tr = tr.delete(start, end);
                    changed = true;
                  }
                  catch{

                  }
                }

                if (changed) {
                  this.editor.view.dispatch(tr);
                  return true;
                }
                return true;
              } else {
                // Handle single backspace (single character or node deletion)
                try {
                  const posBefore = $from.pos + 1;
                  const posBefore2 = $from.pos + 2;
                  const currentNode = state.doc.nodeAt($from.pos);
                  const nodeBefore = state.doc.nodeAt(posBefore);
                  const nodeBefore2 = state.doc.nodeAt(posBefore2);
                  let nodeToDelete = null;
                  let nodeToDeletePos = null;
                  if (
                    currentNode &&
                    dontDelete.indexOf(currentNode.type.name) >= 0 && currentNode.type.name != "listItem"
                  ) {
                    nodeToDelete = currentNode;
                    nodeToDeletePos = $from.pos;
                  } else if (
                    nodeBefore &&
                    dontDelete.indexOf(nodeBefore.type.name) >= 0 && nodeBefore.type.name != "listItem"
                  ) {
                    nodeToDelete = nodeBefore;
                    nodeToDeletePos = posBefore;
                  } else if (
                    nodeBefore2 &&
                    dontDelete.indexOf(nodeBefore2.type.name) >= 0 && nodeBefore2.type.name != "listItem"
                  ) {
                    nodeToDelete = nodeBefore2;
                    nodeToDeletePos = posBefore2;
                  }

                  if (
                    (currentNode &&
                      dontDelete.indexOf(currentNode.type.name) >= 0) ||
                    (nodeBefore &&
                      dontDelete.indexOf(nodeBefore.type.name) >= 0) ||
                    (nodeBefore2 &&
                      dontDelete.indexOf(nodeBefore2.type.name) >= 0)
                  ) {
                    Swal.fire({
                      title: "Are you sure?",
                      html: `You are about to delete a ${nodeToDelete.type.name} block.`,
                      showDenyButton: true,
                      confirmButtonText: "Yes, delete",
                      denyButtonText: "No",
                      confirmButtonColor: colors.red,
                      denyButtonColor: colors.gray,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        console.log(nodeToDelete);
                        console.log(nodeToDeletePos);
                        tr = tr.delete(nodeToDeletePos, nodeToDeletePos + 1);
                        this.editor.view.dispatch(tr);
                        onDeletedNode(nodeToDelete);
                      } else if (result.isDenied) {
                      }
                    });
                    return true;
                  } else {
                    // Default backspace behavior for other nodes or text
                    return false;
                  }
                } catch {}
              }
              return false;
            }
          },
          handleKeyPress: (view, event) => {
            const { selection } = view.state;
            const { $anchor, empty } = selection;

            // if (event.key == "Enter") {
            //   event.preventDefault();
            //   view.dom.editor.commands.customEnter()
            //   return true;
            // }
          },
          handlePaste(view, event, slice) {
            const { clipboardData } = event;
            const parser =
              view.someProp("clipboardParser") || parseFromClipboard;

            if (clipboardData.types.indexOf("text/html") > -1) {
              const html = clipboardData.getData("text/html");
              const text = clipboardData.getData("text/plain").trim();

              const doc = parser(
                view,
                text,
                html,
                false,
                view.state.selection.$anchor
              );

              view.dispatch(view.state.tr.replaceSelection(doc));
              return true;
            }

            return false;
          },
        },
      }),
    ];
  },
});

export default EventHandler;
