import React, { useState, useEffect } from "react";

import { Divider, Text, Group, Menu, Tooltip } from "@mantine/core";

import { useTranslation } from "react-i18next";

import { DateTime } from "luxon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";

import InvitationModel from "../../../../models/Invitation";

import getSymbolFromCurrency from "currency-symbol-map";

export default function InvitedRow(props) {
  const [invitation, setInvitation] = useState({});
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (!invitation.id || invitation.id != props.invitation.id) {
      setInvitation(props.invitation);
    }
  }, [props]);

  const onResend = () => {
    InvitationModel.onResend(
      invitation.id,
      props.spaceId,
      (data) => {
        Swal.fire(
          "Invitation sent",
          "Your invitation has been resent",
          "success"
        );
      },
      (data) => {
        Swal.fire(
          data.title,
          data.message,
          "info"
        );
      }
    );
  };

  const onCopy = () => {
    // Swal.fire("Done", "Text copied to clipboard", "success")
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  const getFrequency = () => {
    if (!invitation) {
      return "";
    }
    if (!invitation.price) {
      return "";
    }
    if (!invitation.price.frequency) {
      return "";
    }
    return invitation.price.frequency != "once"
      ? ` / ${invitation.price.frequency.replace("per ", "")}`
      : "";
  };

  const ensure2Dec = (value) => {
    value = value ? parseFloat(value) : 0;
    return value.toFixed(2).replace(".00", "");
  };

  return (
    <tr>
      <td>
        <Text size="sm" weight={500}>
          {invitation.email}
        </Text>
      </td>

      <td>
        <Text
          size="sm"
          sx={(theme) => ({
            backgroundColor: theme.colors.gray[1],
            color: theme.colors.dark[4],
            borderRadius: "15px",
            padding: "3px 15px",
            // display: "inline",
            "&:hover": {
              backgroundColor: "#fff",
              cursor: "pointer",
            },
          })}
        >
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            label="Link copied to clipboard"
            opened={copiedToClipboard}
            position="bottom"
            withArrow
          >
            <CopyToClipboard onCopy={() => onCopy()} text={invitation.link}>
              <span>{invitation.link}</span>
            </CopyToClipboard>
          </Tooltip>
        </Text>
      </td>

      {invitation.price && (
        <td>
          {invitation.price.is_flexible
            ? "Flexible"
            : invitation.price.currency &&
              (invitation.price.price || invitation.price.price == 0)
            ? `${getSymbolFromCurrency(invitation.price.currency)}${ensure2Dec(
                invitation.price.price
              )}${getFrequency()}`
            : ""}
        </td>
      )}
      {!invitation.price && <td></td>}

      <td>{new Date(invitation.sent_at).toLocaleString(DateTime.DATE_MED)}</td>

      <td>
        <Group spacing="sm" position="right">
          <Menu transition="pop" withArrow placement="end">
            <Menu.Item
              onClick={() => onResend()}
              icon={<FontAwesomeIcon icon={regular("envelope")} />}
            >
              {t("spaces.settings.members.invitedrow.invitation.resend")}
            </Menu.Item>
            <Divider />
            <Menu.Item
              onClick={() => props.onDestroy(invitation)}
              icon={<FontAwesomeIcon icon={regular("trash-can")} />}
              color="red"
            >
              {t("spaces.settings.members.invitedrow.invitation.cancel")}
            </Menu.Item>
          </Menu>
        </Group>
      </td>
    </tr>
  );
}
