import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import tinycolor from "tinycolor2";
import ColorPicker from "react-best-gradient-color-picker";
import colorPickerSwatches from "../../../../utils/ColorSwatches";

import {
  Stack,
  Group,
  Popover,
  Text,
  Button,
  createStyles,
  MantineProvider,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({}));

export default function ButtonColor(props) {
  const timeoutId = useRef(null);
  const { t, i18n } = useTranslation();
  const loading = useRef(false);

  const tiptap = useSelector((state) => state.tiptap);
  const colors = useSelector((state) => state.colors);
  const [color, setColor] = useState();
  const [bgColorOpened, setBgColorOpened] = useState(false);

  const { cx, theme, classes } = useStyles();

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newColor = node.node.attrs[props.attrName] ? color : null;

    if (newColor) {
      tiptap.editor.commands.setCustomButtonAttr(props.attrName, newColor);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        props.saveNodeData();
      }, 2000);
    }
  }, [color]);

  const onRemoveBackgroundColor = (event) => {
    setColor("");
  };

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      if (
        props.element.node.attrs[props.attrName] &&
        props.element.node.attrs[props.attrName].indexOf("linear-gradient") >= 0
      ) {
        setColor(props.element.node.attrs[props.attrName]);
      } else {
        setColor(props.element.node.attrs[props.attrName]);
      }
    }
  }, [props.element]);

  return (
    <Stack mt={10}>
      <Popover
        opened={bgColorOpened}
        onClose={() => setBgColorOpened(false)}
        target={
          <Group position="apart">
            <Text size="sm">{props.label}</Text>
            {color && color.indexOf("linear-gradient") >= 0 ? (
              <Button
                key={color}
                radius="xl"
                onClick={() => setBgColorOpened((o) => !o)}
                sx={(theme) => ({
                  background: color,
                  color: colors.default_text_color,
                  border: `1px solid ${theme.colors.gray[6]}`,
                  width: 70,
                  // padding: 10,
                  "&:hover": {
                    backgroundColor: tinycolor(color).darken(5).toString(),
                  },
                })}
              ></Button>
            ) : (
              <Button
                key={color}
                radius="xl"
                onClick={() => setBgColorOpened((o) => !o)}
                sx={(theme) => ({
                  backgroundColor:
                    color == "" || color == "none" ? "white" : color,
                  color: colors.default_text_color,
                  border: `1px solid ${theme.colors.gray[6]}`,
                  width: 70,
                  // padding: 10,
                  "&:hover": {
                    backgroundColor: tinycolor(color).darken(5).toString(),
                  },
                })}
              ></Button>
            )}
          </Group>
        }
        gutter={15}
        zIndex={999999}
        width={326}
      >
        <div className="gradient-color-picker">
          <ColorPicker
            value={color}
            onChange={setColor}
            hideAdvancedSliders
            hideColorGuide
            hideInputType
            presets={colorPickerSwatches(
              theme,
              props.attrName.indexOf("Text") < 0 ? "dark" : "light"
            )}
          />
        </div>
      </Popover>
      {false && (
        <MantineProvider theme={{ primaryShade: 6 }}>
          <Button
            radius="xl"
            variant="outline"
            color="dark"
            onClick={(event) => onRemoveBackgroundColor(event)}
          >
            {t("tiptap.components.sidebar.buttonBackgroundColor.button")}
          </Button>
        </MantineProvider>
      )}
    </Stack>
  );
}
