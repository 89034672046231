import React, { useState, useEffect, useRef } from "react";

import {
  Group,
  Text,
  Switch,
  Button,
  createStyles,
  useMantineTheme,
} from "@mantine/core";

import Scripts from "./Scripts";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },
}));

export default function Registration({
  onUpdate,
  blockUntilRegistered,
  setBlockUntilRegistered,
}) {
  const { t, i18n } = useTranslation();
  const theme = useMantineTheme();
  const space = useSelector((state) => state.space);

  const { classes } = useStyles({ theme });

  const propsLoaded = useRef();

  const [useLegacyRegistrationPage, setUseLegacyRegistrationPage] =
    useState(false);
    const [blockUntilRegistered2, setBlockUntilRegistered2] =
    useState(false);

  useEffect(() => {
    if (!space.settings) {
      return;
    }

    propsLoaded.current = false;
    setBlockUntilRegistered2(space.block_until_registered);

    setUseLegacyRegistrationPage(space.use_legacy_registration_page);

    setTimeout(() => {
      propsLoaded.current = true;
      // setDisableActions(false);
    }, 1000);
  }, [space]);

  useEffect(() => {
    
    if (propsLoaded.current) {
      onUpdate({ block_until_registered: blockUntilRegistered2 });
      setBlockUntilRegistered(blockUntilRegistered2)
    }
  }, [blockUntilRegistered2]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ use_legacy_registration_page: useLegacyRegistrationPage });
    }
  }, [useLegacyRegistrationPage]);

  const onEditRegistrationPage = () => {
    if (space && space.use_legacy_registration_page) {
      window.location.href= `/circle/${space.slug}/pages/manage/1?auth_token=${window.$currentUserAuthToken}`;
    } else {
      window.location.href= `/space/${space.slug}/registration/edit`;
    }
  };

  const onViewRegistrationPage = () => {
    if (space && space.use_legacy_registration_page) {
      window.open(
        `/circle/${space.slug}/register?preview=true&auth_token=${window.$currentUserAuthToken}`,
        `_blank`
      );
    } else {
      window.open(`/space/${space.slug}/registration/view`, `_blank`);
    }
  };

  return (
    <>
      <Group position="apart" noWrap spacing="xl" mb={20}>
        <div>
          <Text className={classes.itemTitle}>
            {t("spaces.settings.main.main.title2")}
          </Text>
          <Text className={classes.itemDescription}>
            {t("spaces.settings.main.main.description2")}
          </Text>
        </div>
        <Switch
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
          checked={blockUntilRegistered2}
          onChange={(event) =>
            setBlockUntilRegistered2(event.currentTarget.checked)
          }
        />
      </Group>
      {space && space.has_legacy_registration_page && (
        <Group position="apart" noWrap spacing="xl" mb={20}>
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.main.title3")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.main.description3")}
            </Text>
          </div>
          <Switch
            onLabel={t("spaces.settings.main.main.onButton")}
            offLabel={t("spaces.settings.main.main.offButton")}
            className={classes.switch}
            size="lg"
            checked={useLegacyRegistrationPage}
            onChange={(event) =>
              setUseLegacyRegistrationPage(event.currentTarget.checked)
            }
          />
        </Group>
      )}

      <Group mb={10}>
        <Button onClick={() => onEditRegistrationPage()} radius="xl">
          {t("spaces.settings.main.main.editButton")}
        </Button>
        <Button onClick={() => onViewRegistrationPage()} radius="xl">
          {t("spaces.settings.main.main.previewButton")}
        </Button>
      </Group>
      <Scripts onUpdate={onUpdate} />
    </>
  );
}
