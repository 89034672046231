import React from "react";
import { Link } from "react-router-dom";
import premiumMessages from "../../collections/premiumMessages";
import premiumFeatures from "../../collections/premiumFeatures";

import {
  createStyles,
  Group,
  Text,
  Modal,
  Button,
  Box,
  Tooltip,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  featureItem: {
    alignItems: "baseline",
    marginTop: 5,
  },
}));

export default function PremiumModal(props) {
  const premiumMsg = premiumMessages();
  const premiumFeat = premiumFeatures();

  const { t, i18n } = useTranslation();
  const { classes, theme } = useStyles();
  const title = props.opened[1] ? premiumMsg[props.opened[1]].title : "";
  const message = props.opened[1] ? premiumMsg[props.opened[1]].message : "";
  const plan = props.opened[1]
    ? premiumMsg[props.opened[1]].plan.charAt(0).toUpperCase() +
      premiumMsg[props.opened[1]].plan.slice(1)
    : "";

  const features = () => {
    if (props.opened[1]) {
      return premiumFeat[plan.toLowerCase()].map((feature) => (
        <Group noWrap className={classes.featureItem} spacing={10} ml={2}>
          <Box mt={5}>{feature.icon}</Box>
          <Text sx={{ lineHeight: 1.3 }}>
            {feature.description}{" "}
            {feature.explanation && feature.explanation !== "" && (
              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label={feature.explanation}
                withArrow
                wrapLines
                width={220}
              >
                <FontAwesomeIcon size="sm" icon={solid("circle-info")} />
              </Tooltip>
            )}
          </Text>
        </Group>
      ));
    }
  };

  const onUpgrade = () => {
    props.setOpened(false);
    if (props.onUpgrade) {
      props.onUpgrade();
    }
  };

  const getNewPlanName = (plan) => {
    if (plan.toLowerCase() == "personal"){
      return "Bronze"
    } else if (plan.toLowerCase() == "professional"){
      return "Silver"
    } else if (plan.toLowerCase() == "organization"){
      return "Gold"
    }
  };

  return (
    <Modal
      zIndex={999999}
      title={
        <Group spacing={8}>
          <Box
            sx={(theme) => ({
              color: theme.colors.green[6],
            })}
          >
            <FontAwesomeIcon icon={solid("star")} />
          </Box>
          <Text size="sm" weight={600}>
            {title}
          </Text>
        </Group>
      }
      opened={props.opened[0]}
      onClose={() => props.setOpened(false)}
    >
      <Text size="xl" weight={800}>
        {t("app.premium.try")} {getNewPlanName(plan)}
      </Text>
      {props.opened[1] && <Text mt={10}>{message}</Text>}
      <Text mt={15} weight="600">
        {t("app.premium.include")}:{" "}
      </Text>
      {features()}
      <Button
        onClick={() => onUpgrade()}
        mt={25}
        radius="xl"
        component={Link}
        to="/app/pricing"
      >
        {t("app.premium.upgrade")}
      </Button>
    </Modal>
  );
}
