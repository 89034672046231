import React, { useState, useEffect } from "react";

import {
  Checkbox,
  Divider,
  Text,
  Badge,
  Group,
  Menu,
  Button,
  Tooltip,
  Stack,
  Select,
  useMantineTheme,
} from "@mantine/core";

import useFrequencyOptions from "../../../../collections/FrequencyOptions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import MemberModel from "../../../../models/Member";
import ManagerModel from "../../../../models/Manager";
import DirectMessageModel from "../../../../models/DirectMessage";
import StripeModel from "../../../../models/Stripe";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";

import Avatar from "../../../utils/LazyAvatar";

import {setTiptapSidemenu} from '../../../../store/app';

const statusColors = {
  active: "green",
  pending: "orange",
  deleted: "red",
  registration_survey: "blue",
  survey_payment: "yellow",
};

const statusString = {
  active: "Active",
  pending: "Pending",
  deleted: "Removed",
  registration_survey: "Survey",
  survey_payment: "Payment",
};

const statusMessage = {
  active: "This member is active and has access to all space features",
  pending:
    "This member is currently pending and must be approved to access the space",
  deleted: "You removed this member from this space",
  registration_survey:
    "This member has registered but hasn't completed the registration survey",
  survey_payment:
    "This member has registered but hasn't completed the survey payment",
};

const rolesData = ["Member", "Editor", "Manager", "Moderator"];

export default function RegisteredRow(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const { t, i18n } = useTranslation();

  const { frequencyOptions } = useFrequencyOptions();

  const [member, setMember] = useState({});
  const [role, setRole] = useState(null);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (
      !member.id ||
      member.id != props.member.id ||
      member.status == "deleted" ||
      (member.status != "active" && props.member.status == "active")
    ) {
      setMember(props.member);
      setRole(
        props.member.role.charAt(0).toUpperCase() +
          props.member.role.slice(1).toLowerCase()
      );
    }
  }, [props]);

  useEffect(() => {
    if (
      role &&
      ((member.role != "member" && role == "Member") ||
        (member.role != "manager" && role == "Manager") ||
        (member.role != "editor" && role == "Editor") ||
        (member.role != "moderator" && role == "Moderator")
      )
    ) {
      onSelectedRole(role);
    }
  }, [role]);

  const onSetRole = (nextRole) => {
    if (
      nextRole == "Member" ||
      nextRole == "Editor" ||
      nextRole == "Moderator" ||
      (nextRole == "Manager" && props.canAddNewManagers())
    ) {
      setRole(nextRole);
    }
  };

  const onCopyLink = () => {
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  const onSelectedRole = (role) => {
    const newMember = { ...member };
    if (role == "Manager") {
      // newMember.is_manager = true
      ManagerModel.onCreate(
        props.spaceId,
        member.user_id,
        (data) => {
          // setMember(newMember)
          props.onUpdate(member);
        },
        (data) => {}
      );
    } else if (role == "Editor") {
      MemberModel.onUpdateRole(
        member.user_id,
        props.spaceId,
        "editor",
        (data) => {
          props.onUpdate(member);
        },
        (data) => {}
      );
    } else if (role == "Moderator") {
      MemberModel.onUpdateRole(
        member.user_id,
        props.spaceId,
        "moderator",
        (data) => {
          props.onUpdate(member);
        },
        (data) => {}
      );
    } else {
      if (member.role == "manager") {
        ManagerModel.onDestroy(
          member.user_id,
          props.spaceId,
          (data) => {
            props.onUpdate(member);
          },
          (data) => {}
        );
      } else {
        MemberModel.onUpdateRole(
          member.user_id,
          props.spaceId,
          "member",
          (data) => {
            props.onUpdate(member);
          },
          (data) => {}
        );
      }
    }
  };

  const onEmailFrequency = (frequency) => {
    const newMember = { ...member };
    newMember.email_frequency = frequency;
    
    MemberModel.onEmailFrequency(
      member.user_id,
      props.spaceId,
      frequency,
      (data) => {
        setMember(newMember);
      },
      (data) => {}
    );
  };

  const onApprove = () => {
    const newMember = { ...member };
    newMember.status = "active";
    MemberModel.onApprove(
      member.user_id,
      props.spaceId,
      (data) => {
        props.onUpdate(member);
        setMember(newMember);
      },
      (data) => {}
    );
  };

  const onDestroy = () => {
    const newMember = { ...member };
    newMember.status = "deleted";

    const Model = props.scope != "managers" ? MemberModel : ManagerModel;
    Model.onDestroy(
      member.user_id,
      props.spaceId,
      (data) => {
        // props.onUpdate(member)
        setMember(newMember);
      },
      (data) => {}
    );
  };

  const onAdd = () => {
    MemberModel.onCreate(
      props.spaceId,
      member.user_id,
      (data) => {
        // props.onUpdate(data)
        setMember(data);
      },
      (data) => {}
    );
  };

  const onSendMessage = () => {
    DirectMessageModel.onFetchOrCreate(
      member.user_id,
      (data) => {
        dispatch(setTiptapSidemenu({ opened: "direct", option: data.space.slug}));
      },
      (data) => {}
    );
  };

  const openProfile = () => {
    dispatch(setTiptapSidemenu({ opened: "profile", option: member.username}));
  }

  const onCancelSubscription = () => {
    Swal.fire({
      title: "Are you sure?",
      html: "You are about to cancel this subscription",
      showDenyButton: true,
      confirmButtonText: "Delete subscription",
      denyButtonText: "Cancel",
      confirmButtonColor: theme.colors.red[8],
      denyButtonColor: theme.colors.gray[6],
    }).then((result) => {
      if (result.isConfirmed) {
        StripeModel.onCancelSubscription(
          member.user_id,
          props.spaceId,
          (data) => {
            Swal.fire(data.title, data.message, "success");
          },
          (data) => {
            Swal.fire(data.title, data.message, "error");
          }
        );
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <tr>
      <td style={{ width: "20px" }}>
        {window.$currentUserId != member.user_id && (
          <Checkbox
            checked={props.isSelected(member.username)}
            onChange={(event) => props.onSelect(member.username)}
          />
        )}
      </td>
      <td>
        <Group
          spacing="sm"
          onClick={() => openProfile()}
          sx={{
            color: "#000",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          noWrap
        >
          <Avatar
            size={30}
            src={member && member.avatar ? member.avatar.url : ""}
            radius={30}
          />
          <Stack spacing="0">
            <Text size="sm" weight={500}>
              {member.full_name}
            </Text>
            <Text size="xs" color="dimmed" mt={-3}>
              @{member.username}
            </Text>
          </Stack>
        </Group>
      </td>

      <td>
        <Tooltip
          disabled={window.$isTouchDevice && window.$isTouchDevice()}
          label="Email copied to clipboard"
          opened={copiedToClipboard}
          position="bottom"
          withArrow
        >
          <CopyToClipboard onCopy={() => onCopyLink()} text={member.email}>
            <Text
            sx={{
              overflowWrap: "break-word",
              wordWrap: "break-word",
              wordBreak: "break-all",
              wordBreak: "break-word"
            }}
              size="sm"
              weight={500}>
              {member.email}
            </Text>
          </CopyToClipboard>
        </Tooltip>
      </td>

      <td>
        <Tooltip
          disabled={window.$isTouchDevice && window.$isTouchDevice()}
          wrapLines
          width={220}
          label={statusMessage[member.status]}
          withArrow
        >
          <Badge color={statusColors[member.status]} variant={"light"}>
            {statusString[member.status]}
          </Badge>
        </Tooltip>
      </td>

      <td>

        <Select
          data={rolesData}
          value={role}
          onChange={onSetRole}
          variant="unstyled"
          sx={{ minWidth: 100, width: 100 }}
        />
      </td>

      <td>
        <Group spacing="sm" position="right" noWrap>
          {member.status !== "active" && member.status !== "deleted" ? (
            <Button
              color="green"
              radius="xl"
              size="xs"
              variant="outline"
              compact
              onClick={() => onApprove()}
            >
              {t("spaces.settings.members.registeredRow.registered.roleApprove")}
            </Button>
          ) : (
            ""
          )}
          {/*
              member.status == "deleted" ?
              (
                <Button
                  color="red"
                  radius="xl"
                  size="xs"
                  variant="outline"
                  compact
                  onClick={() => onAdd()}>
                  Undo
                </Button>
              ) : ("")
            */}
          <Menu transition="pop" withArrow placement="end">
            <Menu.Label>
            {t("spaces.settings.members.registeredRow.registered.joined")} {new Date(member.joined).toLocaleString(DateTime.DATE_MED)}
            </Menu.Label>
            <Divider />
            <Menu.Item
              onClick={() => onSendMessage()}
              icon={<FontAwesomeIcon icon={regular("message")} />}
            >
              {t("spaces.settings.members.registeredRow.registered.sendMessage")}
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                onSetRole(role == "Manager" ? "Member" : "Manager")
              }
              icon={<FontAwesomeIcon icon={regular("circle-user")} />}
            >
              {role == "Manager" ? "Remove manager" : "Make manager"}
            </Menu.Item>
            {props.hasSubscriptions && member.is_subscribed ? (
              <Menu.Item
                onClick={() => onCancelSubscription()}
                icon={<FontAwesomeIcon icon={regular("circle-stop")} />}
              >
                {t("spaces.settings.members.registeredRow.registered.stopCharging")}
              </Menu.Item>
            ) : (
              ""
            )}
            <Menu.Item
              component={Menu}
              trigger="hover"
              position="left"
              placement="end"
              gutter={-30}
              withArrow
              size="md"
              sx={{ padding: 0 }}
              control={
                <Group spacing="xs" sx={{ padding: "10px 12px" }}>
                  <FontAwesomeIcon icon={regular("envelope")} />
                  <Text size="sm" color="dark">
                  {t("spaces.settings.members.registeredRow.registered.emailPreferences")}
                  </Text>
                </Group>
              }
            >
              {frequencyOptions.map((option) => {
                return (
                  <Menu.Item
                    icon={
                      option.value == member.email_frequency ? (
                        <FontAwesomeIcon icon={solid("check")} />
                      ) : (
                        ""
                      )
                    }
                    onClick={(event) => onEmailFrequency(option.value)}
                  >
                    {option.label}
                  </Menu.Item>
                );
              })}
            </Menu.Item>
            <Divider />
            <Menu.Item
              onClick={() => onDestroy()}
              icon={<FontAwesomeIcon icon={regular("trash-can")} />}
              color="red"
            >
              {t("spaces.settings.members.registeredRow.registered.removeMember")}
            </Menu.Item>
          </Menu>
        </Group>
      </td>
    </tr>
  );
}
