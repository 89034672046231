import React from 'react';
import { useSelector } from 'react-redux';
import { Box,
        Text,
        Button,
        Group,
        Paper,
        createStyles,
      } from '@mantine/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import MessageModel from "../../../../models/Message";

import isSpaceThemed from '../../../../utils/IsSpaceThemed'
import tinycolor from 'tinycolor2'

import { useTranslation } from "react-i18next";


const useStyles = createStyles((theme, {colors}, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color
  },
  textColor: {
    color: colors.sidebar_text_color
  },
  buttonColor: {
    border: isSpaceThemed(colors) ? `1px solid ${colors.sidebar_text_color}` : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors) ? colors.sidebar_text_color : colors.primary_button_background_color,
    '&:hover': {
      backgroundColor: isSpaceThemed(colors) ? tinycolor(colors.sidebar_background_color).lighten(5).toString() : tinycolor(colors.sidebar_background_color).darken(1).toString()
    }
  }
}));

export default function PinMessage(props) {
  const { t, i18n } = useTranslation();
  const colors = useSelector(state => state.colors);
  const { classes, cx, theme } = useStyles({colors});

  const onFetch = () => {
    MessageModel.onFetchById(props.messageId, props.spaceId, (data) => {
      props.onViewPinnedMessage({...data})
    }, (data) => {

    });
  }

  return (
    <Paper
      radius={10}
      mt={10}
      mb={0}
      pt={12} pb={12} pl={20} pr={20}
      className={classes.panelWrap}
      >
      <Group
        position="apart"
        >
        <Text
          weight={500}
          className={classes.textColor}>
          {t("spaces.presentations.message.pinMessage.mainText")}
        </Text>
        <Box>
        <Button
          radius="xl"
          size="xs"
          variant="outline"
          className={classes.buttonColor}
          leftIcon={
            <FontAwesomeIcon icon={solid('thumbtack')} />
          }
          onClick={() => onFetch()}>
          {t("spaces.presentations.message.pinMessage.viewButton")}
        </Button>
        </Box>
      </Group>
    </Paper>
  );

}
