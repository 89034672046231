import { useState } from 'react';
import { setCookie, getCookie } from './Cookies';

import DefaultAvatar from "../src/images/fallback/default.png";

export default function useAuthUser() {
  const getAuthUser = () => {
    let userString = getCookie("auth_user");
    const user = JSON.parse(userString ? atob(userString) : "{}");
    if (user){
      window.$currentUserId = user.id;
      window.$currentName = user.full_name;
      window.$currentUserAuthToken = user.authentication_token;
      window.$currentUserId = user.id;
      window.$currentUsername = user.username;
      window.$currentEmail = user.email;
      window.$currentAccountType = user.account_type;
      window.$videoUploadKey = user.video_upload_key;
      window.$currentUserIsAdmin = user.is_admin;
      window.$currentUserAvatar = user.avatar && user.avatar.url ? user.avatar.url : DefaultAvatar;
    }
    return user ? user : {}
  };

  const [authUser, setAuthUser] = useState(getAuthUser());

  const saveAuthUser = user => {

    const saveUser = {
      id: user.id,
      username: user.username,
      email: user.email,
      authentication_token: user.authentication_token,
      video_upload_key: user.video_upload_key,
      account_type: user.account_type
    }
    setCookie("auth_user", btoa(JSON.stringify(saveUser)), 365*10)
    window.$currentUserId = user.id;
    window.$currentName = user.full_name;
    window.$currentUserAuthToken = user.authentication_token;
    window.$currentUserId = user.id;
    window.$currentUsername = user.username;
    window.$currentEmail = user.email;
    window.$currentAccountType = user.account_type;
    window.$videoUploadKey = user.video_upload_key;
    setAuthUser(user);
  };

  const removeAuthUser = user => {
    setCookie("auth_user", btoa(""), 0)
    window.$currentUserAuthToken = null;
    setAuthUser({});
  };

  return {
    setAuthUser: saveAuthUser,
    removeAuthUser,
    authUser
  }
}
