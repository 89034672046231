// import axios from 'axios';

class Resource {
  constructor() {
    if (this instanceof Resource) {
      throw Error('Resource class cannot be instantiated.');
    }
  }

  static onFetch = async (id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.get(`/api/v4/spaces/${slug}/resources/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onCreate = async (slug, resource, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      resource
    }

    await window.$api.post(`/api/v4/spaces/${slug}/resources.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onUpdate = async (id, slug, resource, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      resource
    }

    await window.$api.put(`/api/v4/spaces/${slug}/resources/${id}.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDestroy = async (id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/resources/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default Resource;
