import React from "react";
import {
  Box,
  TextInput,
  ActionIcon,
  Radio,
  createStyles,
  Checkbox,
} from "@mantine/core";

import { X } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
const useStyles = createStyles((theme) => ({
  dragHandle: {
    ...theme.fn.focusStyles(),
    height: "100%",
    color: theme.colors.gray[6],
    cursor: "move",
    paddingTop: 3,
    width: 20,
    maxWidth: 20,
  },
}));

export default function SingleItem(props) {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <>
      {props.quizMode && (
        <Box
          sx={{
            width: 30,
          }}
        >
          <Checkbox
            checked={props.quizMode ? props.answers.length == 1 || props.isCorrect : false}
            onChange={(event) =>
              props.onIsCorrectChange(props.answers.filter(item => item.is_correct == true).length > 1 ? event.currentTarget.checked : true)
            }
            styles={{
              root: {},
              input: {
                marginTop: -1,
              },
            }}
          />
        </Box>
      )}

      <TextInput
        placeholder={t("spaces.settings.survey.singleItem.item.placeholder")}
        value={props.content}
        onChange={(event) => props.onContentChange(event.currentTarget.value)}
        radius="sm"
        size="md"
        sx={(theme) => ({
          width: `calc(100% - ${props.quizMode ? 50 : 20}px)`,
        })}
        rightSection={
          <ActionIcon variant="transparent" onClick={() => props.onDestroy()}>
            <X size={18} />
          </ActionIcon>
        }
        rightSectionWidth={40}
        disabled={props.disabled}
      />
    </>
  );
}
