import React from 'react';
import { useTranslation } from "react-i18next";
import {
  Text,
  Box
} from '@mantine/core'

import { DatePicker } from '@mantine/dates';

export default function UntilDate(props) {
  const { t, i18n } = useTranslation();
  return (
    <Box mt={20} mb={20}>
      <Text
        mb={8}
        color="dimmed"
      >
        {t("spaces.settings.payment.charging.UntilDate.pickDate")}
      </Text>
      <DatePicker
        value={props.untilDate}
        onChange={props.setUntilDate}
        placeholder="End date"
        size="lg"
        radius="md"
        />
    </Box>
  )
}
