import React, { useState, useEffect } from "react";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AuthModel from "../../models/Auth";
import useAuthUser from "../../utils/AuthUser";
import { setUser, setAlert, setHiddenSidebar } from "../../store/app";
import { useTranslation } from "react-i18next";
import { Box, Modal, Loader, Text } from "@mantine/core";

import { getCookie } from "../../utils/Cookies";

export default function MagicSignIn(props) {
  const params = useParams();
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const queryParams = useLocation().search;
  const { setAuthUser } = useAuthUser();

  const [spaceReference, setSpaceReference] = useState();
  const [showSpaceReference, setShowSpaceReference] = useState(true);
  const [planReference, setPlanReference] = useState();

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: false }));

    const spaceReference = new URLSearchParams(queryParams).get("s");
    const planReference = new URLSearchParams(queryParams).get("p");

    setSpaceReference(spaceReference);
    setPlanReference(planReference);

    setTimeout(() => {
      onMagicSignIn(spaceReference);
    }, 1000);

    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: false }));

    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: false }));
    }, 1000);

    if (user.authenticated == true) {
      if (spaceReference) {
        navigate(`/space/${spaceReference}`);
        return;
      }
      if (planReference) {
        navigate(`/app/pay/${planReference}`);
        return;
      }
      navigate(`/home/top`);
    }
  }, [user]);

  const onMagicSignIn = (spaceReference) => {

    const cookieSlug = getCookie(`sso_redirect_to`)

    AuthModel.onMagicSignIn(
      params.magic_token,
      { slug: cookieSlug ? cookieSlug : spaceReference, plan: planReference },
      (data) => {
        // Success callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );

        data.user.redirect_to = data.redirect_to;

        setAuthUser(data.user);
        dispatch(setUser(data.user));
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
        navigate("/user/sign_out");
      }
    );
  };

  return (
    <Modal
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      onClose={() => {}}
      opened={true}
      zIndex={99999}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
        }}
        pt={10}
      >
        <Loader />
        <Text mt={30}>{t("users.magicSignin.text")}</Text>
      </Box>
    </Modal>
  );
}
