import React, { useState, useEffect } from "react";

import { Container, Button, ScrollArea } from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SectionHeader from "./layout/SectionHeader";

import ComposeBroadcast from "./broadcast/ComposeBroadcast";
import BroadcastItem from "./broadcast/BroadcastItem";
import BroadcastDetails from "./broadcast/Broadcast";

import BroadcastModel from "../../../models/Broadcast";
import PremiumModal from "../../app/PremiumModal";

const data = [
  { value: "active", label: "Active members" },
  { value: "pending", label: "Pending members" },
  { value: "interested", label: "Interested members" },
];

export default function Broadcast() {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const [opened, setOpened] = useState(false);
  const [openedDetails, setOpenedDetails] = useState(false);
  const [broadcasts, setBroadcasts] = useState({
    total: 0,
    page: 1,
    pages: 1,
    data: [],
  });
  const [selectedBroadcast, setSelectedBroadcast] = useState(null);
  const [validateOpened, setValidateOpened] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
    setValidatePremium(
      space && space.permissions && space.permissions.can_broadcast
    );
  }, [space]);

  useEffect(() => {
    if (space && opened && !validatePremium[0]) {
      setOpened(false);
      setValidateOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }
    if (!opened) {
      setSelectedBroadcast(null);
      onFetch();
    }
  }, [opened]);

  useEffect(() => {
    if (!openedDetails) {
      setSelectedBroadcast(null);
    }
  }, [openedDetails]);

  const onFetch = () => {
    BroadcastModel.onFetch(
      space.slug,
      { page: 1, per: 100 },
      (data) => {
        setBroadcasts(data);
      },
      (data) => {}
    );
  };

  const onBroadcastUpdate = () => {
    setBroadcasts({ data: [] });
    setSelectedBroadcast(null);
    onFetch();
  };

  const onBroadcastEdit = (broadcast) => {
    setSelectedBroadcast(broadcast);
    if (broadcast.is_sent) {
      setOpenedDetails(true);
    } else {
      setOpened(true);
    }
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container
        pt={20}
        pb={40}
        pl={40}
        pr={40}
        sx={(theme) => ({
          // width: "960px",
          // maxWidth: "960px",
        })}
      >
        <SectionHeader
          spaceId={space.slug}
          spaceVersion={space.version}
          spaceName={space.name}
          title={t("spaces.settings.broadcast.title")}
          rightSide={
            <Button
              variant="filled"
              radius="xl"
              onClick={() => setOpened(true)}
              leftIcon={<FontAwesomeIcon icon={solid("pencil")} />}
              sx={(theme) => ({
                backgroundColor: theme.colors.sutrablue[3],
                "&:hover": {
                  backgroundColor: theme.colors.sutrablue[4],
                },
              })}
            >
              {t("spaces.settings.broadcast.broadcastButton")}
            </Button>
          }
        />
        {broadcasts.data.map((broadcast) => {
          return (
            <BroadcastItem
              onEdit={onBroadcastEdit}
              onUpdate={onBroadcastUpdate}
              spaceId={space.slug}
              broadcast={broadcast}
            />
          );
        })}
        <ComposeBroadcast
          opened={opened}
          setOpened={setOpened}
          broadcast={selectedBroadcast}
          onUpdate={onBroadcastUpdate}
          parentName={space.name}
          isTopLevel={space.is_top_level}
        />
        <BroadcastDetails
          opened={openedDetails}
          setOpened={setOpenedDetails}
          broadcast={selectedBroadcast}
        />
        <PremiumModal opened={validateOpened} setOpened={setValidateOpened} />
      </Container>
    </ScrollArea>
  );
}
