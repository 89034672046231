import React from "react";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { Button, Box } from "@mantine/core";

import { useTranslation } from "react-i18next";

export default function Payment(props) {
  const { t, i18n } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (event) => {
    event.preventDefault();

    await props.onChangePayment(stripe, elements);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#000000",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#888",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form onSubmit={onSubmit}>
      <Box
        sx={{
          padding: 12,
          border: "1px solid #cccccc",
          backgroundColor: "#f8f8f8",
          borderRadius: 5,
        }}
      >
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </Box>
      <Button fullWidth mt="xl" radius="xl" size="md" mb={15} type="submit">
        {t("settings.billing.stripe.updatePaymentMethod")}
      </Button>
    </form>
  );
}
