import React, { useRef, useState, useEffect } from "react";

import {
  Select,
  Text,
  Switch,
  Group,
  MultiSelect,
  Box,
  ActionIcon,
  NumberInput,
  createStyles,
  Loader,
} from "@mantine/core";

import { Plus, Minus } from "tabler-icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import SpaceModel from "../../../../models/Space";

import PremiumModal from "../../../app/PremiumModal";

import Swal from "sweetalert2";

import { colors } from "../../../../collections/colors";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    textAlign: "center",
    paddingRight: `${theme.spacing.sm}px !important`,
    paddingLeft: `${theme.spacing.sm}px !important`,
    height: 28,
    flex: 1,
  },
}));

const podSizeMin = 0;
const podSizeMax = 1000;

export default function Additional(props) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const space = useSelector((state) => state.space);

  const [spaces, setSpaces] = useState([]);
  const [children, setChildren] = useState([]);
  const [resourceEditing, setResourceEditing] = useState(false);
  const [circleCreation, setCircleCreation] = useState(false);
  const [circleCreationTypes, setCircleCreationTypes] = useState([]);
  const [allowMemberCopy, setAllowMemberCopy] = useState(false);
  const [podType, setPodType] = useState(false);
  const [allowReflections, setAllowReflections] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [hideMemberEmails, setHideMemberEmails] = useState(false);
  const [newMemberNotifications, setNewMemberNotifications] = useState(false);
  const [parentCircles, setParentCircles] = useState([]);
  const [podSize, setPodSize] = useState(false);
  const [podSizeValue, setPodSizeValue] = useState(0);
  const [sidebarDefaultOpened, setSidebarDefaultOpened] = useState(false);
  const [sidebarHideChildren, setSidebarHideChildren] = useState(false);
  const [allowCompletions, setAllowCompletions] = useState(false);
  const [showMarkCompleteButtonInHeader, setShowMarkCompleteButtonInHeader] =
    useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searching2, setSearching2] = useState(false);
  const [redirectToSpace, setRedirectToSpace] = useState("");

  const [canActivateCompletions, setCanActivateCompletions] = useState(false);
  const [opened, setOpened] = useState(false);

  const handlers = useRef();
  const propsLoaded = useRef();
  const lastSpaceId = useRef();
  const searchTimeoutId = useRef();
  const selectedParents = useRef([]);
  const originalParents = useRef([]);

  useEffect(() => {
    return () => {
      propsLoaded.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.settings) {
      return;
    }
    if (propsLoaded.current && props.settings.id == lastSpaceId.current) {
      return;
    }
    propsLoaded.current = false;
    // setDisableActions(true);
    lastSpaceId.current = props.settings.id;

    setResourceEditing(props.settings.resource_editing == "open");
    setCircleCreation(props.settings.circle_creation ? true : false);
    setCircleCreationTypes(
      props.settings.circle_creation
        ? props.settings.circle_creation.split(",")
        : []
    );

    setShowMembers(props.settings.show_members);
    setHideMemberEmails(props.settings.settings.hide_member_emails);

    setAllowMemberCopy(props.settings.settings.allow_member_copy);
    setPodType(props.settings.pod_type == "standard" ? true : false);
    setAllowReflections(props.settings.allow_reflections);

    setSpaces([...props.settings.parents]);
    selectedParents.current = [...props.settings.parents];
    originalParents.current = [...props.settings.parents];


    setNewMemberNotifications(
      props.settings.settings.new_member_notifications == "manager"
    );
    setPodSize(props.settings.size ? true : false);
    setPodSizeValue(props.settings.size);
    setParentCircles(props.settings.parents_slugs);
    setSidebarDefaultOpened(props.settings.settings.sidebar_default_opened);
    setSidebarHideChildren(props.settings.settings.sidebar_hide_children);
    setAllowCompletions(props.settings.settings.allow_completions);
    setShowMarkCompleteButtonInHeader(
      props.settings.settings.show_mark_complete_button_in_header
    );
    setRedirectToSpace(props.settings.settings.redirect_to_space);

    setTimeout(() => {
      propsLoaded.current = true;
      // setDisableActions(false);
    }, 1000);
  }, [props]);

  useEffect(() => {
    setCanActivateCompletions(
      space && space.permissions && space.permissions.can_activate_completions
    );
  }, [space]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({ resource_editing: resourceEditing ? "open" : "closed" });
    }
  }, [resourceEditing]);

  useEffect(() => {
    if (propsLoaded.current && !circleCreation) {
      props.onUpdate({ circle_creation: "" });
    }
  }, [circleCreation]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({ circle_creation: circleCreationTypes.join(",") });
    }
  }, [circleCreationTypes]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: { allow_member_copy: allowMemberCopy },
      });
    }
  }, [allowMemberCopy]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({ pod_type: podType ? "standard" : "readonly" });
    }
  }, [podType]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({ allow_reflections: allowReflections });
    }
  }, [allowReflections]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({ show_members: showMembers });
    }
  }, [showMembers]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: { hide_member_emails: hideMemberEmails },
      });
    }
  }, [hideMemberEmails]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: {
          new_member_notifications: newMemberNotifications ? "manager" : "none",
        },
      });
    }
  }, [newMemberNotifications]);

  useEffect(() => {
    if (propsLoaded.current && !podSize) {
      props.onUpdate({ size: null });
    }
  }, [podSize]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({ size: podSizeValue });
    }
  }, [podSizeValue]);

  useEffect(() => {
    const originalParentsSlugs = originalParents.current.map(item => item.value)
    if (propsLoaded.current && !arraysAreEqual(parentCircles, originalParentsSlugs)) {

      let message = `This space will become a child of: <p>${selectedParents.current.map(item => `${item.label}`).join("</p><p>")}</p></ul>`

      if (selectedParents.current.length == 0){
        message = "This action will make this space a <strong>top level</strong> space"
      }

      Swal.fire({
        title: "Are you sure?",
        html: message,
        showDenyButton: true,
        confirmButtonText: "Yes, update",
        denyButtonText: "Cancel",
        confirmButtonColor: colors.blue,
        denyButtonColor: colors.gray,
      }).then((result) => {
        if (result.isConfirmed) {
          props.onUpdate({ parent_circles: parentCircles });
          originalParents.current = [...selectedParents.current]
        } else {
          setParentCircles(originalParentsSlugs)
          selectedParents.current = [...originalParents.current]
        }
      });


    }
  }, [parentCircles]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: {
          sidebar_default_opened: sidebarDefaultOpened,
        },
      });
    }
  }, [sidebarDefaultOpened]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: { sidebar_hide_children: sidebarHideChildren },
      });
    }
  }, [sidebarHideChildren]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: { redirect_to_space: redirectToSpace },
      });
    }
  }, [redirectToSpace]);

  useEffect(() => {
    if (allowCompletions && !canActivateCompletions[0] && propsLoaded.current) {
      setAllowCompletions(false);
      setOpened([true, canActivateCompletions[1], canActivateCompletions[2]]);
      return;
    }

    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: { allow_completions: allowCompletions },
      });
    }
  }, [allowCompletions]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: {
          show_mark_complete_button_in_header: showMarkCompleteButtonInHeader,
        },
      });
    }
  }, [showMarkCompleteButtonInHeader]);

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false; // If the arrays have different lengths, they can't be the same.
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false; // If any elements don't match, the arrays are not equal.
      }
    }

    return true; // If no mismatches are found, the arrays are equal.
  }

  const onSearchSpaces = (query) => {
    setSearching(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query);
      setSearching(false);
    }, 1000);
  };

  const onSearchChildren = (query) => {
    setSearching2(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchChildren(query);
      setSearching2(false);
    }, 1000);
  };

  const onFetchSpaces = (query = null) => {
    const include = ["members", "simple"];
    if (query) {
      include.push(`q=${query}`);
    }
    SpaceModel.onFetchManaged(
      space.slug,
      include,
      { page: 1, per: 10 },
      (data) => {
        const _spaces = [...selectedParents.current];
        for (let i = 0; i < data.length; i++) {
          _spaces.push({
            value: data[i].slug,
            label: `${data[i].name} (${data[i].slug})`,
          });
        }
        setSpaces(_spaces);
      },
      (data) => {}
    );
  };

  const onFetchChildren = (query = null) => {
    const ctrl = new AbortController();
    SpaceModel.onFetchDirectChildren(
      space.slug,
      ["order=pods.created_at DESC", "abstract"],
      { page: 1, per: 10 },
      ["active"],
      (data) => {
        const _spaces = [...selectedParents.current];
        for (let i = 0; i < data.data.length; i++) {
          _spaces.push({
            value: data.data[i].slug,
            label: `${data.data[i].name} (${data.data[i].slug})`,
          });
        }
        setChildren(_spaces);
      },
      (data) => {},
      ctrl.signal
    );
  };

  const onSelectedParents = (selection) => {
    setParentCircles(selection);
    selectedParents.current = [];
    for (let i = 0; i < spaces.length; i++) {
      if (selection.indexOf(spaces[i].value) >= 0) {
        selectedParents.current.push(spaces[i]);
      }
    }
  };

  return (
    <Box>
      {space && space.slug && space.version == "content" && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.allowMembersEdit")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.membersWillAddContent")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={resourceEditing}
            onChange={(event) =>
              setResourceEditing(event.currentTarget.checked)
            }
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      {space && space.slug && space.version == "list" && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.allowMembersCreatePosts")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.membersWillCreatePosts")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={resourceEditing}
            onChange={(event) =>
              setResourceEditing(event.currentTarget.checked)
            }
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      {space && space.slug && space.version == "events" && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.allowMembersCreateEvents")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.membersWillCreateEvents")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={resourceEditing}
            onChange={(event) =>
              setResourceEditing(event.currentTarget.checked)
            }
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      {space && space.slug && space.version == "content" && false && (
        <Box className={classes.item}>
          <Group position="apart" noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {t(
                  "spaces.settings.main.additional.allowMembersCreateSubspaces"
                )}
              </Text>
              <Text className={classes.itemDescription}>
                {t(
                  "spaces.settings.main.additional.membersWillCreateSubspaces"
                )}
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={circleCreation}
              onChange={(event) =>
                setCircleCreation(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
          {circleCreation && (
            <Box className={classes.subOptionWrap}>
              <Box className={classes.subOptionItem}>
                <Text className={classes.itemTitle}>
                  {t("spaces.settings.main.additional.membersCanCreate")}:
                </Text>
                <MultiSelect
                  disabled={disableActions}
                  searchable
                  mt={5}
                  data={[
                    {
                      value: "discussion",
                      label: t(
                        "spaces.settings.main.additional.circleCreation.options.discussion"
                      ),
                    },
                    {
                      value: "content",
                      label: t(
                        "spaces.settings.main.additional.circleCreation.options.content"
                      ),
                    },
                    {
                      value: "event",
                      label: t(
                        "spaces.settings.main.additional.circleCreation.options.event"
                      ),
                    },
                  ]}
                  placeholder={t(
                    "spaces.settings.main.additional.circleCreation.placeholder"
                  )}
                  value={circleCreationTypes}
                  onChange={(selection) => setCircleCreationTypes(selection)}
                />
              </Box>

              <Group position="apart" noWrap spacing="xl">
                <div>
                  <Text className={classes.itemTitle}>
                    {t(
                      "spaces.settings.main.additional.allowMembersToCopy.description1"
                    )}
                  </Text>
                  <Text className={classes.itemDescription}>
                    {t(
                      "spaces.settings.main.additional.allowMembersToCopy.description2"
                    )}
                  </Text>
                </div>
                <Switch
                  disabled={disableActions}
                  checked={allowMemberCopy}
                  onChange={(event) =>
                    setAllowMemberCopy(event.currentTarget.checked)
                  }
                  onLabel="ON"
                  offLabel="OFF"
                  className={classes.switch}
                  size="lg"
                />
              </Group>
            </Box>
          )}
        </Box>
      )}
      {space && space.slug && space.version == "discussion" && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.podType.description1")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.podType.description2")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={podType}
            onChange={(event) => setPodType(event.currentTarget.checked)}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      {space && space.slug && space.version == "discussion" && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t(
                "spaces.settings.main.additional.allowReflections.description1"
              )}
            </Text>
            <Text className={classes.itemDescription}>
              {t(
                "spaces.settings.main.additional.allowReflections.description2"
              )}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={allowReflections}
            onChange={(event) =>
              setAllowReflections(event.currentTarget.checked)
            }
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      <Box className={classes.item}>
        <Group position="apart" noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.showMembers.description1")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.showMembers.description2")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={showMembers}
            onChange={(event) => setShowMembers(event.currentTarget.checked)}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
        {showMembers ? (
          <Box className={classes.subOptionWrap}>
            <Group position="apart" noWrap spacing="xl">
              <div>
                <Text className={classes.itemTitle}>
                  {t(
                    "spaces.settings.main.additional.hideMmemberEmails.description1"
                  )}
                </Text>
                <Text className={classes.itemDescription}>
                  {t(
                    "spaces.settings.main.additional.hideMmemberEmails.description2"
                  )}
                </Text>
              </div>
              <Switch
                disabled={disableActions}
                checked={!hideMemberEmails}
                onChange={(event) =>
                  setHideMemberEmails(!event.currentTarget.checked)
                }
                onLabel="ON"
                offLabel="OFF"
                className={classes.switch}
                size="lg"
              />
            </Group>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Group position="apart" className={classes.item} noWrap spacing="xl">
        <div>
          <Text className={classes.itemTitle}>
            {t(
              "spaces.settings.main.additional.newMemberNotifications.description1"
            )}
          </Text>
          <Text className={classes.itemDescription}>
            {t(
              "spaces.settings.main.additional.newMemberNotifications.description2"
            )}
          </Text>
        </div>
        <Switch
          disabled={disableActions}
          checked={newMemberNotifications}
          onChange={(event) =>
            setNewMemberNotifications(event.currentTarget.checked)
          }
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
        />
      </Group>
      <Box className={classes.item}>
        <Group position="apart" noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.additional.podSize.description1")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.additional.podSize.description2")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={podSize}
            onChange={(event) => setPodSize(event.currentTarget.checked)}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
        {podSize ? (
          <Box className={classes.subOptionWrap}>
            <Text className={classes.itemDescription} mb={10}>
              {t("spaces.settings.main.additional.podSizeValue.description")}
            </Text>
            <div className={classes.wrapper}>
              <ActionIcon
                size={28}
                variant="transparent"
                onClick={() => handlers.current.decrement()}
                disabled={podSizeValue === podSizeMin}
                className={classes.control}
                onMouseDown={(event) => event.preventDefault()}
              >
                <Minus size={16} />
              </ActionIcon>

              <NumberInput
                disabled={disableActions}
                variant="unstyled"
                min={podSizeMin}
                max={podSizeMax}
                handlersRef={handlers}
                value={podSizeValue}
                onChange={setPodSizeValue}
                classNames={{ input: classes.input }}
              />

              <ActionIcon
                size={28}
                variant="transparent"
                onClick={() => handlers.current.increment()}
                disabled={podSizeValue === podSizeMax}
                className={classes.control}
                onMouseDown={(event) => event.preventDefault()}
              >
                <Plus size={16} />
              </ActionIcon>
            </div>
          </Box>
        ) : (
          ""
        )}
      </Box>
      {space &&
        space.slug &&
        space.version == "content" &&
        !space.permissions.is_top_level && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {t(
                  "spaces.settings.main.additional.sidebarDefaultOpened.description1"
                )}
              </Text>
              <Text className={classes.itemDescription}>
                {t(
                  "spaces.settings.main.additional.sidebarDefaultOpened.description2"
                )}
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={sidebarDefaultOpened}
              onChange={(event) =>
                setSidebarDefaultOpened(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}
      {space &&
        space.slug &&
        (space.version == "content" ||
          space.version == "list" ||
          space.version == "events") &&
        !space.permissions.is_top_level && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {t(
                  "spaces.settings.main.additional.sidebarHideChildren.description1"
                )}
              </Text>
              <Text className={classes.itemDescription}>
                {t(
                  "spaces.settings.main.additional.sidebarHideChildren.description2"
                )}
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={sidebarHideChildren}
              onChange={(event) =>
                setSidebarHideChildren(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}
      {space && space.slug && !space.permissions.is_top_level && (
        <Group position="apart" className={classes.item} noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t(
                "spaces.settings.main.additional.allowCompletions.description1"
              )}
            </Text>
            <Text className={classes.itemDescription}>
              {t(
                "spaces.settings.main.additional.allowCompletions.description2"
              )}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            checked={allowCompletions}
            onChange={(event) =>
              setAllowCompletions(event.currentTarget.checked)
            }
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
          />
        </Group>
      )}
      {space &&
        space.slug &&
        !space.permissions.is_top_level &&
        allowCompletions && (
          <Group position="apart" className={classes.item} noWrap spacing="xl">
            <div>
              <Text className={classes.itemTitle}>
                {"Show mark complete button in header"}
              </Text>
              <Text className={classes.itemDescription}>
                {
                  "The mark complete button will be visible from the header for all members"
                }
              </Text>
            </div>
            <Switch
              disabled={disableActions}
              checked={showMarkCompleteButtonInHeader}
              onChange={(event) =>
                setShowMarkCompleteButtonInHeader(event.currentTarget.checked)
              }
              onLabel="ON"
              offLabel="OFF"
              className={classes.switch}
              size="lg"
            />
          </Group>
        )}

      <Box className={classes.item}>
        <Text className={classes.itemTitle}>
          {t("spaces.settings.main.additional.redirectToSubspace.description1")}
        </Text>
        <Text className={classes.itemDescription}>
          {t("spaces.settings.main.additional.redirectToSubspace.description2")}
        </Text>
        <Select
          icon={
            searching2 ? (
              <Loader size="xs" />
            ) : (
              <FontAwesomeIcon icon={solid("search")} />
            )
          }
          disabled={disableActions}
          searchable
          clearable
          limit={100}
          mt={5}
          data={children}
          onChange={setRedirectToSpace}
          placeholder={t(
            "spaces.settings.main.additional.parentCircles.placeholder"
          )}
          value={redirectToSpace}
          onSearchChange={(query) => onSearchChildren(query)}
        />
      </Box>

      <Box>
        <Text className={classes.itemTitle}>
          {t("spaces.settings.main.additional.parentCircles.description1")}
        </Text>
        <MultiSelect
          icon={
            searching ? (
              <Loader size="xs" />
            ) : (
              <FontAwesomeIcon icon={solid("search")} />
            )
          }
          disabled={disableActions}
          searchable
          limit={100}
          mt={5}
          data={spaces}
          onChange={(selection) => onSelectedParents(selection)}
          placeholder={t(
            "spaces.settings.main.additional.parentCircles.placeholder"
          )}
          value={parentCircles}
          onSearchChange={(query) => onSearchSpaces(query)}
        />
      </Box>

      <PremiumModal opened={opened} setOpened={setOpened} />
    </Box>
  );
}
