import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import tinycolor from "tinycolor2";
import ColorPicker from "react-best-gradient-color-picker";

import {
  Stack,
  Group,
  Popover,
  Text,
  Button,
  MantineProvider,
} from "@mantine/core";

export default function RegPageColor(props) {
  const loading = useRef(false);
  const { t, i18n } = useTranslation();
  const colors = useSelector((state) => state.colors);
  const [color, setColor] = useState("rgba(255,255,255,1)");
  const [bgColorOpened, setBgColorOpened] = useState(false);

  useEffect(() => {
    props.onChange(props.attr, color);
  }, [color]);

  const onRemoveBackgroundColor = (event) => {
    setColor("");
  };

  useEffect(() => {
    setColor(props.color);
  }, [props]);

  return (
    <Stack>
      <Popover
        opened={bgColorOpened}
        onClose={() => setBgColorOpened(false)}
        target={
          <Group position="apart">
            <Text size="sm">{props.title}</Text>
            {color && color.indexOf("linear-gradient") >= 0 ? (
              <Button
                key={color}
                radius="xl"
                onClick={() => setBgColorOpened((o) => !o)}
                sx={(theme) => ({
                  background: color,
                  color: colors.default_text_color,
                  border: `1px solid ${theme.colors.gray[6]}`,
                  width: 70,
                  // padding: 10,
                  "&:hover": {
                    backgroundColor: tinycolor(color).darken(5).toString(),
                  },
                })}
              ></Button>
            ) : (
              <Button
                key={color}
                radius="xl"
                onClick={() => setBgColorOpened((o) => !o)}
                sx={(theme) => ({
                  backgroundColor:
                    color == "" || color == "none" ? "white" : color,
                  color: colors.default_text_color,
                  border: `1px solid ${theme.colors.gray[6]}`,
                  width: 70,
                  // padding: 10,
                  "&:hover": {
                    backgroundColor: tinycolor(color).darken(5).toString(),
                  },
                })}
              ></Button>
            )}
          </Group>
        }
        gutter={15}
        zIndex={999999}
        width={326}
      >
        <div className="gradient-color-picker">
          <ColorPicker
            value={color}
            onChange={setColor}
            hidePresets
            hideAdvancedSliders
            hideColorGuide
            hideInputType
          />
        </div>
      </Popover>
      <MantineProvider theme={{ primaryShade: 6 }}>
        <Button
          radius="xl"
          variant="outline"
          color="dark"
          onClick={(event) => onRemoveBackgroundColor(event)}
        >
          {t("tiptap.components.regPageColor.clearColor")}
        </Button>
      </MantineProvider>
    </Stack>
  );
}
