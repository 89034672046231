import React from "react";
import { useSelector } from "react-redux";

import {
  Stack,
  ScrollArea,
  Container,
  createStyles,
  Skeleton,
} from "@mantine/core";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
}));

import { useTranslation } from "react-i18next";

export default function Loading(props) {
  const { t, i18n } = useTranslation();

  const colors = useSelector((state) => state.colors);

  const { classes, cx, theme } = useStyles({ colors });

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea className="content-scrollview" style={{ height: "100%" }}>
        <Container
          sx={(theme) => ({
            height: "100%",
          })}
        >
          <Skeleton height={25} width="40%" mt={30} />
          <Skeleton height={40} width="60%" mt={20} mb={20} />
        </Container>
      </ScrollArea>
    </Stack>
  );
}
