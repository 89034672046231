import React from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import "add-to-calendar-button/assets/css/atcb.css";

import { useTranslation } from "react-i18next";
import useLangUser from "../../utils/LangUser";

import { Box, Text, createStyles } from "@mantine/core";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  panelWrap: {
    // boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
  },
  textStyle: {
    fontSize: 18,
    color: colors.sidebar_text_color,
  },
  linkStyle: {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  buttonColor: {
    border: isSpaceThemed(colors)
      ? `1px solid ${colors.sidebar_text_color}`
      : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : colors.primary_button_background_color,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
        : tinycolor(colors.sidebar_background_color).darken(1).toString(),
    },
  },
}));

export default function StatusBar(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });
  const { t, i18n } = useTranslation();
  const { language, setLangUser } = useLangUser();

  if (!props.isMember) {
    return null;
  }

  return (
    <Box
      radius={5}
      // mt={15}
      // mb={10}
      pt={20}
      pb={20}
      pl={10}
      pr={10}
      className={classes.panelWrap}
    >
      <Text align="center" weight={500} className={classes.textStyle}>
        {t(`spaces.alreadyMember.member.welcome`)}
        <Link
          className={classes.linkStyle}
          to={`/space/${props.spaceId}?refresh=true`}
        >
          {t(`spaces.alreadyMember.member.clickHere`)}
        </Link>
      </Text>
    </Box>
  );
}
