import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Title, Box, Select, createStyles, Text, Group } from "@mantine/core";

import { useTranslation } from "react-i18next";

import tinycolor from "tinycolor2";

import TextBased from "./responses/TextBased.jsx";

import SurveyModel from "../../../../models/Survey";
import isSpaceThemed from "../../../../utils/IsSpaceThemed";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const useStyles = createStyles((theme, { colors, context }) => ({
  item: {
    borderRadius: theme.radius.md,
    border: `1px solid ${
      isSpaceThemed(colors) && window.location.pathname.indexOf("/settings/surveys") < 0 && context != "settings"
        ? tinycolor(colors.sidebar_background_color).toString()
        : theme.colors.gray[4]
    }`,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 15,
    paddingLeft: 15,
    // backgroundColor: theme.white,
    backgroundColor: isSpaceThemed(colors) && window.location.pathname.indexOf("/settings/surveys") < 0 && context != "settings"
      ? tinycolor(colors.sidebar_background_color).toString()
      : theme.white,
    color: isSpaceThemed(colors) && window.location.pathname.indexOf("/settings/surveys") < 0 && context != "settings" ? colors.sidebar_text_color : "#000",
    marginTop: 15,
    width: "100%",
  },
}));

export default function Individual(props) {

  const colors = useSelector((state) => state.colors);
  const { classes, cx } = useStyles({ colors, context: props.context });
  const { t, i18n } = useTranslation();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    if (props.survey && props.survey.id && props.spaceId) {
      getUsers();
    }
    if (props.selectedUser) {
      setSelectedUser(props.selectedUser);
    }
  }, [props]);

  useEffect(() => {
    if (selectedUser) {
      getChoices();
    }
  }, [selectedUser]);

  const getUsers = () => {
    SurveyModel.onFetchUsers(
      props.survey.id,
      props.spaceId,
      (data) => {
        setUsers(
          data.map((user) => {
            return { value: user.id, label: user.full_name };
          })
        );
      },
      (data) => {}
    );
  };

  const getChoices = () => {
    SurveyModel.onFetchByUser(
      props.survey.id,
      props.spaceId,
      selectedUser,
      (data) => {
        setChoices(data);
      },
      (data) => {}
    );
  };

  const getQuizResults = () => {
    const total = groupQuestions().length;
    const correct = groupQuestions().filter((choice) => isQuestionCorrect(choice)).length;

    return `You got ${correct} out of ${total} correct`;
  };

  const groupQuestions = () => {
    const groups = []
    for (let i = 0; i < choices.length; i++) {
      const choice = choices[i];
      let exists = false
      for (let j = 0; j < groups.length; j++) {
        const group = groups[j];
        if (group.question.id == choice.question.id){
          group.answers.push({...choice.answer, content: choice.content})
          exists = true
        }
      }
      if (!exists){
        
        groups.push({...choice, answers: [{...choice.answer, content: choice.content}]})
      }
    }
    return groups
  }

  const isQuestionCorrect = (choice) => {
    
    for (let i = 0; i < choice.answers.length; i++) {
      const answer = choice.answers[i];
      if (!answer.is_correct && choice.question.question_type != "freeform"){
        return false
      }
    }

    return true
  }

  return (
    <Box>
      {!props.singleUser && (
        <Select
          placeholder={t(
            "spaces.settings.survey.individual.individual.placeholder"
          )}
          value={selectedUser}
          onChange={setSelectedUser}
          mt={15}
          size="md"
          data={users}
        />
      )}
      {props.survey.quiz_mode && <Title mt={10} order={4}>{getQuizResults()}</Title>}

      <Box mt={10}>
        {groupQuestions().filter(choice => choice.content != null && choice.content != "").map((choice) => {
          return (
            <Box className={classes.item}>
              <Group position={"apart"}>
                <Title order={5}>{`${choice.question.content}`}</Title>
                {props.survey.quiz_mode && (
                  <Group spacing={10} sx={{
                    color: isQuestionCorrect(choice) ? "green" : "red",
                    backgroundColor: "#fff",
                    padding: "2px 10px",
                    borderRadius: "15px"
                  }}>
                    {isQuestionCorrect(choice) ? (
                      <FontAwesomeIcon size="sm" icon={solid("check")} />
                    ) : (
                      <FontAwesomeIcon size="sm" icon={solid("x")} />
                    )}
                    <Title order={5}>
                      {`${isQuestionCorrect(choice) ? choice.question.question_type == "freeform" ? "Open" : "Correct" : choice.question.question_type == "freeform" ? "Open" : "Incorrect"}`}
                    </Title>
                  </Group>
                )}
              </Group>
              {choice.answers.map(answer => <TextBased response={answer.content} context={props.context} />)}
              
              {props.survey.after_submit_show_score_and_keys &&
                choice.question.answer_key && (
                  <Text
                    color="dimmed"
                    size="sm"
                    mt={10}
                  >{`${choice.question.answer_key}`}</Text>
                )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
