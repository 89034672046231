import React from "react";

import { Container } from "@mantine/core";

import SectionHeader from "./layout/SectionHeader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ChildrenList from "../ChildrenList";

export default function Archived() {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);

  return (
    <Container
      pt={20}
      pb={40}
      pl={40}
      pr={40}
      sx={(theme) => ({
        height: "100%"
        // width: "960px",
          // maxWidth: "960px",
      })}
    >
      <SectionHeader
        spaceName={space.name}
        spaceId={space.slug}
        spaceVersion={space.version}
        title={t("spaces.settings.archived.title")}
      />
      <ChildrenList spaceId={space.id} scope="archived" addMargin={true}/>
      
    </Container>
  );
}
