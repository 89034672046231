import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Drawer,
  Button,
  Text,
  Select,
  ScrollArea,
  createStyles,
  MantineProvider,
  Accordion,
  Tabs,
  Box,
} from "@mantine/core";

import { setTiptapSidemenu } from "../../../store/app";
import { addDummyCursor, removeDummyCursor } from "../utils/dummyCursor";
import { getListOfNodes, getAllParents } from "../utils/selection";

import Padding from "./sidebar/Padding";
import Margin from "./sidebar/Margin";
import Alignment from "./sidebar/Alignment";
import Width from "./sidebar/Width";
import Radius from "./sidebar/Radius";
import NestedRadius from "./sidebar/NestedRadius";
import VerticalAlignment from "./sidebar/VerticalAlignment";
import BackgroundColor from "./sidebar/BackgroundColor";
import BackgroundImage from "./sidebar/BackgroundImage";
import ButtonBackgroundColor from "./sidebar/ButtonBackgroundColor";
import ButtonText from "./sidebar/ButtonText";
import ButtonAction from "./sidebar/ButtonAction";
import BundleAction from "./sidebar/BundleAction";
import SpaceAttrs from "./sidebar/SpaceAttrs";
import InteractiveText from "./sidebar/InteractiveText";
import InteractiveSwitch from "./sidebar/InteractiveSwitch";
import InteractiveWaitTime from "./sidebar/InteractiveWaitTime";
import InteractiveTextArea from "./sidebar/InteractiveTextArea";
import InteractiveRadio from "./sidebar/InteractiveRadio";
import FileName from "./sidebar/FileName";
import BlockId from "./sidebar/BlockId";
import LinkAction from "./sidebar/LinkAction";
import DisplayAs from "./sidebar/DisplayAs";
import CircleDisplayAs from "./sidebar/CircleDisplayAs";
import VideoDisplayAs from "./sidebar/VideoDisplayAs";
import LinkTitle from "./sidebar/LinkTitle";
import LinkDescription from "./sidebar/LinkDescription";
import LinkImage from "./sidebar/LinkImage";
import TagsFeedAttrs from "./sidebar/TagsFeedAttrs";
import InteractiveTags from "./sidebar/InteractiveTags";
import BlockTitle from "./sidebar/BlockTitle";
import Transcript from "./sidebar/Transcript";

const useStyles = createStyles((theme, _params, getRef) => ({
  tabInner: {
    justifyContent: "flex-start",
  },
  tabControl: {
    padding: 0,
    height: "30px",
    fontSize: 10,
    marginRight: 20,
    // color: 'red',
  },
  tabsList: {
    marginBottom: -5,
  },
  tabActive: {
    fontWeight: "bold",
    color: `${theme.colors.dark[9]} !important`,
    borderBottomColor: `${theme.colors.sutrablue[4]} !important`,
    // color: `${theme.colors.sutrablue[3]} !important`,
    // borderBottomColor: `${theme.colors.sutrablue[3]} !important`
  },
  item: {
    borderBottom: "none",
  },
  control: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 16,
    // backgroundColor: theme.colors.gray[0],
    // borderRadius: 5,
    // marginLeft: -5,
    // marginRight: "-20px !important",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    // marginRight: 8,
    // marginLeft: -5
  },
  content: {
    backgroundColor: theme.colors.gray[0],
    paddingLeft: 20,
    paddingRight: 20,
  },
  contentInner: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconWrapper: {
    ref: getRef("iconWrapper"),
  },
  chipChecked: {
    backgroundColor: `${theme.colors.dark[3]} !important`,
    color: theme.white,
    [`& .${getRef("iconWrapper")}`]: {
      color: theme.white,
    },
  },
  chipOutline3: {
    width: "80px",
    border: `1px solid ${theme.colors.dark[3]}`,
  },
  chipOutline4: {
    border: `1px solid ${theme.colors.dark[3]}`,
  },
  chipLabel: {
    textAlign: "center",
    fontSize: 9,
    fontWeight: 800,
    color: theme.colors.dark[3],
    height: 26,
  },
}));

let loadingStyles = false;
let updateIntervalId = null;

const SideConfigurationsMenu = (props) => {
  const { classes, cx, theme } = useStyles();

  const lastNodeSaved = useRef(null);
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.user);

  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);

  const [opened, setOpened] = useState(false);
  const [styles, setStyles] = useState({});

  const [bgType, setBgType] = useState("color");
  const [gridBgType, setGridBgType] = useState("color");
  const [columnBgType, setColumnBgType] = useState("color");

  const [tNode, setTNode] = useState({ type: "none" });
  const [update, setUpdate] = useState(false);
  const [grid, setGrid] = useState();
  const [column, setColumn] = useState();
  const [node, setNode] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    updateIntervalId = setInterval(() => {
      if (window.$selectionUpdateForConfigurations) {
        if (
          lastNodeSaved.current &&
          lastNodeSaved.current.count < 2 &&
          (lastNodeSaved.current.node.node.type.name.indexOf("grid") >= 0 ||
            lastNodeSaved.current.node.node.type.name.indexOf("column") >= 0)
        ) {
          lastNodeSaved.current.count++;

          return;
        }
        window.$selectionUpdateForConfigurations = false;

        setUpdate(true);
      }
    }, 250);

    return () => {
      clearInterval(updateIntervalId);
    };
  }, []);

  useEffect(() => {
    if (update) {
      abstractNodesFromSelection();
      setUpdate(false);
    }
  }, [update]);

  useEffect(() => {
    
    if (tiptapSidemenu && tiptapSidemenu.opened == "configuration") {
      setOpened(true);
      abstractNodesFromSelection();
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "configuration") {
        dispatch(setTiptapSidemenu({ opened: null }));
        removeDummyCursor();
      }
    } else {
      addDummyCursor();
    }
  }, [opened]);

  const saveNodeData = (
    { newStyles, newClass, newRadius, newTextAlign, _node },
    type
  ) => {
    if (loadingStyles) {
      return;
    }
    lastNodeSaved.current = {
      node: _node,
      count: 0,
      range: { ...window.$tiptapSelectionRange },
      leafNode: node,
      time: new Date().getTime(),
    };
    if (newStyles && type == "styles") {
      if (
        _node.node.type.name.indexOf("grid") < 0 &&
        _node.node.type.name != "column"
      ) {
        tiptap.editor.commands.setCustomStyles(newStyles);
      } else {
        try {
          const { tr } = tiptap.editor.view.state;
          tr.setNodeMarkup(_node.pos, undefined, {
            ..._node.node.attrs,
            styles: newStyles,
          });

          tiptap.editor.view.dispatch(tr);
        } catch (e) {
        } finally {
        }
      }

      abstractNodesFromSelection();
    }
  };

  const abstractNodesFromSelection = () => {
    if (!props.editor) {
      return;
    }

    if (lastNodeSaved.current && lastNodeSaved.current.count >= 2) {
      if (
        window.$tiptapSelectionRange.from != lastNodeSaved.current.range.from &&
        window.$tiptapSelectionRange.to != lastNodeSaved.current.range.to
      ) {
        if (window.$tiptapSelectionRange.time < lastNodeSaved.current.time) {
          try {
            if (lastNodeSaved.current.leafNode.node.isTextblock) {
              props.editor.commands.setTextSelection({
                from: lastNodeSaved.current.range.from,
                to: lastNodeSaved.current.range.to,
              });
            } else {
              props.editor.commands.setNodeSelection(
                lastNodeSaved.current.range.from
              );
            }
            window.$tiptapSelectionRange = { ...lastNodeSaved.current.range };
          } catch {}
        } else {
          try {
            if (lastNodeSaved.current.leafNode.node.isTextblock) {
              props.editor.commands.setTextSelection({
                from: window.$tiptapSelectionRange.from,
                to: window.$tiptapSelectionRange.to,
              });
            } else {
              props.editor.commands.setNodeSelection(
                window.$tiptapSelectionRange.from
              );
            }
          } catch {}
        }

        lastNodeSaved.current = null;
        return;
      }
    }

    loadingStyles = true;
    const { from, to } = window.$tiptapSelectionRange
      ? window.$tiptapSelectionRange
      : { from: 0, to: 0 };

    const { selection } = props.editor.view.state;
    const { $anchor, node } = selection;
    const { parent, path } = $anchor;
    const contParent = { node: null };

    const actNode = node
      ? { node: node, pos: selection.anchor }
      : { node: parent, pos: $anchor.pos };

    if (
      actNode.node.type.name.indexOf("grid") >= 0 ||
      actNode.node.type.name == "column"
    ) {
      // props.editor.editor.commands.selectTextblockEnd()
      return;
    }

    if ($anchor.depth > 1) {
      if (
        path[3].type.name.indexOf("grid") >= 0 ||
        path[3].type.name.indexOf("infobox") >= 0
      ) {
        const cleanStylesColumn = cleanStylesForEdition(path[6].attrs.styles);
        const cleanStylesGrid = cleanStylesForEdition(path[3].attrs.styles);
        if (cleanStylesColumn && cleanStylesColumn["background-image"]) {
          setColumnBgType(
            cleanStylesColumn["background-image"].indexOf("https") >= 0 ||
              cleanStylesColumn["background-image"].indexOf("uploads") >= 0
              ? "image"
              : "color"
          );
        }
        if (cleanStylesGrid && cleanStylesGrid["background-image"]) {
          setGridBgType(
            cleanStylesGrid["background-image"].indexOf("https") >= 0 ||
              cleanStylesGrid["background-image"].indexOf("uploads") >= 0
              ? "image"
              : "color"
          );
        }

        setColumn({ node: path[6], pos: path[5] });
        setGrid({ node: path[3], pos: path[2] });
      } else {
        setColumn(null);
        setGrid(null);
      }
    } else {
      if (
        (actNode.node.isTextblock || actNode.node.isText) &&
        !actNode.node.textContent
      ) {
        setOpened(false);
        return;
      }
      setColumn(null);
      setGrid(null);
    }

    const cleanStylesNode = cleanStylesForEdition(node ? node : parent);

    if (cleanStylesNode && cleanStylesNode["background-image"]) {
      setBgType(
        cleanStylesNode["background-image"].indexOf("https") >= 0 ||
          cleanStylesNode["background-image"].indexOf("uploads") >= 0
          ? "image"
          : "color"
      );
    }

    setTimeout(() => {
      setNode(actNode);
    }, 100);
    setTimeout(() => {
      loadingStyles = false;
    }, 350);
  };

  const cleanStylesForEdition = (_styles) => {
    if (!_styles) {
      return;
    }

    const cleanStyles = { ..._styles };

    const stylesKeys = Object.keys(_styles);

    for (var i = 0; i < stylesKeys.length; i++) {
      const key = stylesKeys[i];
      const value = _styles[key];
      if (key.indexOf("padding") >= 0) {
        cleanStyles[key] = parseInt(value.replace("px", ""));
      } else if (key == "background-image") {
        if (value == "none" || !value) {
          cleanStyles[key] = null;
          // setColor('rgba(255,255,255,1)')
        } else {
          if (
            cleanStyles[key] &&
            !(
              cleanStyles[key].indexOf("https") >= 0 ||
              cleanStyles[key].indexOf("uploads") >= 0
            )
          ) {
          } else {
            cleanStyles[key] = cleanStyles[key]
              .replace("url(", "")
              .replace(")", "")
              .replaceAll("'", "");
          }
        }
        // Remove url() and leave only the URL PATH
      }
    }
    return cleanStyles;
  };

  const fixStylesForEdition = (_styles) => {
    const fixedStyles = { ...styles };
    const stylesKeys = Object.keys(styles);

    for (var i = 0; i < stylesKeys.length; i++) {
      const key = stylesKeys[i];
      const value = styles[key];
      if (key.indexOf("padding") >= 0) {
        fixedStyles[key] += "px";
      } else if (key == "background-image") {
        if (value == null) {
          fixedStyles[key] = `none`;
        } else {
          if (fixedStyles[key].indexOf("linear-gradient") < 0) {
            if (
              fixedStyles[key].indexOf("https") >= 0 ||
              fixedStyles[key].indexOf("uploads") >= 0
            ) {
              fixedStyles[key] = `url('${fixedStyles[key]}')`;
            } else {
              fixedStyles["background-color"] = fixedStyles[key];
            }
          }
        }
      }
    }

    return fixedStyles;
  };

  // const onBackgroundImageUpload = (image_url) => {
  //   setBgImage(image_url)
  // }

  // const onRemoveBackgroundImage = (event) => {
  //   setBgImage(null)
  // }

  // const onRemoveBackgroundColor = (event) => {
  //   setColor("")
  // }

  const onGridBackgroundImageUpload = (image_url) => {
    setGridBgImage(image_url);
  };

  const onGridRemoveBackgroundImage = (event) => {
    setGridBgImage(null);
  };

  const onGridRemoveBackgroundColor = (event) => {
    setGridColor("");
  };

  const onColumnBackgroundImageUpload = (image_url) => {
    setColumnBgImage(image_url);
  };

  const onColumnRemoveBackgroundImage = (event) => {
    setColumnBgImage(null);
  };

  const onColumnRemoveBackgroundColor = (event) => {
    setColumnColor("");
  };

  const onResetNode = (_node) => {
    const attrToReset = [];

    if (_node.node.attrs.textAlign) {
      attrToReset.push("textAlign");
    }
    if (_node.node.attrs.styles) {
      attrToReset.push("styles");
    }
    if (_node.node.attrs.class) {
      attrToReset.push("class");
    }
    if (_node.node.attrs.radius) {
      attrToReset.push("radius");
    }
    if (_node.node.attrs.nestedRadius) {
      attrToReset.push("nestedRadius");
    }
    if (_node.node.attrs.buttonText) {
      attrToReset.push("buttonText");
      attrToReset.push("buttonBackgroundColor");
      attrToReset.push("buttonBorderColor");
      attrToReset.push("buttonTextColor");
      attrToReset.push("actionCallback");
      attrToReset.push("actionCallbackValue");
    }

    tiptap.editor.commands.resetAttributes(_node.node.type.name, attrToReset);
  };

  const onReset = (event) => {
    if (node) {
      onResetNode(node);
    }

    if (column) {
      onResetNode(column);
    }

    if (grid) {
      onResetNode(grid);
    }
  };

  const getLNodes = () => {
    try {
      return getListOfNodes(props.editor, tNode).join(",");
    } catch (e) {
      return e;
    } finally {
    }
  };

  const getHumanNodeType = (type, translation = "") => {
    const newType = type.charAt(0).toUpperCase() + node.node.type.name.slice(1);
    if (translation) {
      translation = t(translation);
    }
    if (newType == "Actionbutton") {
      return `Button ${translation}`;
    } else if (newType == "Customform") {
      return `Form ${translation}`;
    } else {
      return `${newType} ${translation}`;
    }
  };

  return (
    <Drawer
      position="left"
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text size="xs">
          {t("tiptap.components.sideConfigurationsMenu.title")}
        </Text>
      }
      size={"300px"}
      withOverlay={false}
      closeOnClickOutside={false}
      overlayOpacity={0.1}
      zIndex={99999}
      styles={{
        header: {
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        },
      }}
    >
      <ScrollArea style={{ height: "100%" }}>
        <Box
          sx={(theme) => ({
            marginLeft: 0,
            marginRight: 0,
          })}
        >
          <Accordion
            offsetIcon={false}
            multiple
            iconPosition="right"
            mb={200}
            initialState={{
              0: true,
              1: true,
              2: true,
              3: true,
              4: true,
              5: true,
              6: true,
              7: true,
              8: true,
              9: true,
            }}
            classNames={{
              item: classes.item,
              control: classes.control,
              icon: classes.icon,
              content: classes.content,
              contentInner: classes.contentInner,
            }}
          >
            {node && node.node && node.node.type.name == "circle" && (
              <Accordion.Item
                label={t("tiptap.components.sideConfigurationsMenu.label1")}
              >
                <CircleDisplayAs
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                />
                <SpaceAttrs
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                />
                {node &&
                  node.node &&
                  node.node.type.name == "circle" &&
                  node.node.attrs.viewAs == "button" && (
                    <ButtonText
                      label={
                        node.node.attrs.viewAs == "link"
                          ? "Link text"
                          : t("tiptap.components.sidebar.buttonText.label")
                      }
                      saveNodeData={abstractNodesFromSelection}
                      element={node}
                    />
                  )}
              </Accordion.Item>
            )}

            {node && node.node && node.node.type.name == "tagsFeed" && (
              <Accordion.Item
                label={t("tiptap.components.sideConfigurationsMenu.label1")}
              >
                <TagsFeedAttrs
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                />
              </Accordion.Item>
            )}

            {node &&
              node.node &&
              node.node.attrs.src &&
              (node.node.type.name == "video" ||
                node.node.type.name == "embed") &&
              (node.node.attrs.src.indexOf("youtube") ||
                node.node.attrs.src.indexOf("vimeo") ||
                node.node.attrs.src.indexOf("loom") ||
                node.node.attrs.src.indexOf("sutra")) && (
                <Accordion.Item label={"Display options"}>
                  <VideoDisplayAs
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                </Accordion.Item>
              )}

            {node && node.node && node.node.type.name == "file" && (
              <Accordion.Item
                label={t("tiptap.components.sideConfigurationsMenu.label2")}
              >
                <FileName
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                />
                {node.node.attrs.displayAs && (
                  <DisplayAs
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                )}
              </Accordion.Item>
            )}

            {node &&
              node.node &&
              node.node.attrs.link &&
              (node.node.type.name == "video" ||
                node.node.type.name == "embed") &&
              node.node.attrs.viewAs.indexOf("card") >= 0 && (
                <Accordion.Item label={"Card details"}>
                  <LinkTitle
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                  <LinkDescription
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                  <LinkImage
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                </Accordion.Item>
              )}

            {node &&
              node.node &&
              // node.node.attrs.title &&
              node.node.type.name == "survey" && (
                <Accordion.Item
                  label={`${
                    node.node.type.name.charAt(0).toUpperCase() +
                    node.node.type.name.slice(1)
                  } details`}
                >
                  <BlockTitle
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                </Accordion.Item>
              )}

            {node &&
              node.node &&
              node.node.attrs.link &&
              node.node.type.name == "video" &&
              node.node.attrs.viewAs.indexOf("card") < 0 && (
                <Accordion.Item label={"Video details"}>
                  <LinkImage
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                </Accordion.Item>
              )}

            {node &&
              node.node &&
              node.node.attrs.link &&
              (node.node.type.name == "image" ||
                node.node.type.name == "fancyLink") && (
                <Accordion.Item
                  label={
                    node.node.type.name == "image" ? "Link image" : "Fancy link"
                  }
                >
                  <LinkAction
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                  {node.node.type.name == "fancyLink" && (
                    <>
                      <LinkTitle
                        saveNodeData={abstractNodesFromSelection}
                        element={node}
                      />
                      <LinkDescription
                        saveNodeData={abstractNodesFromSelection}
                        element={node}
                      />
                      <LinkImage
                        saveNodeData={abstractNodesFromSelection}
                        element={node}
                      />
                    </>
                  )}
                </Accordion.Item>
              )}

            {node && node.node && node.node.type.name == "interactiveInput" && (
              <Accordion.Item
                label={t("tiptap.components.sideConfigurationsMenu.label3")}
              >
                <InteractiveText
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                  attrPlaceholder={t(
                    "tiptap.components.sideConfigurationsMenu.attrPlaceholder1"
                  )}
                  attrLabel={t(
                    "tiptap.components.sideConfigurationsMenu.attrLabel"
                  )}
                  attrName="interactiveInputPlaceholder"
                />
                <InteractiveSwitch
                  saveNodeData={() => {
                    tiptap.editor.commands.updateAttributes(
                      node.node.type.name,
                      { interactiveAdvancedEngagement: !node.node.attrs.interactiveInputShowAll }
                    );
                    abstractNodesFromSelection();
                  }}
                  element={node}
                  attrLabel={t(
                    "tiptap.components.sideConfigurationsMenu.attrLabel2"
                  )}
                  attrName="interactiveInputShowAll"
                />
                {node.node.attrs.interactiveInputShowAll && (
                  <Box
                    sx={{
                      border: "1px solid #dfdfdf",
                      padding: "0 10px 20px 20px",
                      marginTop: 20,
                      borderRadius: 5,
                    }}
                  >
                    <InteractiveSwitch
                      saveNodeData={abstractNodesFromSelection}
                      element={node}
                      attrLabel={"Show highlights and summary"}
                      attrName="interactiveAdvancedEngagement"
                      global={true}
                    />
                    <InteractiveSwitch
                      saveNodeData={abstractNodesFromSelection}
                      element={node}
                      attrLabel={t(
                        "tiptap.components.sideConfigurationsMenu.attrLabel4"
                      )}
                      attrName="interactiveInputWithPrivacyCheck"
                    />
                    <InteractiveSwitch
                      saveNodeData={abstractNodesFromSelection}
                      element={node}
                      attrLabel={t(
                        "tiptap.components.sideConfigurationsMenu.attrLabel3"
                      )}
                      attrName="interactiveInputPreShowAll"
                    />
                  </Box>
                )}

                <InteractiveSwitch
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                  attrLabel={t(
                    "tiptap.components.sideConfigurationsMenu.attrLabel5"
                  )}
                  attrName="interactiveInputAllowMultipost"
                />

                <InteractiveSwitch
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                  attrLabel={t(
                    "tiptap.components.sideConfigurationsMenu.attrLabel6"
                  )}
                  attrName="interactiveInputAllowSkip"
                />

                <InteractiveSwitch
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                  attrLabel={t(
                    "tiptap.components.sideConfigurationsMenu.attrLabel7"
                  )}
                  attrName="interactiveInputNotificationOnEachPost"
                />

                <InteractiveSwitch
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                  attrLabel={"Hide next content"}
                  attrName="isBlocking"
                />

                <InteractiveSwitch
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                  attrLabel={"Show progress bar"}
                  attrName="showProgressBar"
                  global={true}
                />

                <InteractiveTags
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                  attrLabel={"Apply tags to each message"}
                  attrName="autoTag"
                />
              </Accordion.Item>
            )}

            {node && node.node && node.node.type.name == "interactiveWait" && (
              <Accordion.Item
                label={t("tiptap.components.sideConfigurationsMenu.label22")}
              >
                <InteractiveSwitch
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                  attrLabel={t(
                    "tiptap.components.sideConfigurationsMenu.label23"
                  )}
                  attrName="interactiveWaitSendNotificationEmail"
                />
                {node.node.attrs.interactiveWaitSendNotificationEmail && (
                  <>
                    <InteractiveSwitch
                      saveNodeData={abstractNodesFromSelection}
                      element={node}
                      attrLabel={t(
                        "tiptap.components.sideConfigurationsMenu.attrLabel8"
                      )}
                      attrName="interactiveWaitNotificationIncludeCustomMessage"
                    />
                    {node.node.attrs.interactiveWaitNotificationIncludeCustomMessage && (
                      <InteractiveTextArea
                        saveNodeData={abstractNodesFromSelection}
                        element={node}
                        placeholder={t("tiptap.components.sideConfigurationsMenu.attrPlaceholder1")}
                        label={t("tiptap.components.sideConfigurationsMenu.attrLabel12")}
                        attrLabel={
                          node.node.attrs.interactiveWaitNotificationCustomSubject ? node.node.attrs.interactiveWaitNotificationCustomSubject : t(
                          "tiptap.components.sideConfigurationsMenu.defaultWaitLabel"
                        )}
                        attrName="interactiveWaitNotificationCustomSubject"
                      />
                    )}
                    {node.node.attrs.interactiveWaitNotificationIncludeCustomMessage && (
                      <InteractiveTextArea
                        saveNodeData={abstractNodesFromSelection}
                        element={node}
                        placeholder={t("tiptap.components.sideConfigurationsMenu.attrPlaceholder1")}
                        label={t("tiptap.components.sideConfigurationsMenu.attrLabel13")}
                        attrLabel={
                          node.node.attrs.interactiveWaitNotificationCustomMessage ? node.node.attrs.interactiveWaitNotificationCustomMessage : t(
                          "tiptap.components.sideConfigurationsMenu.defaultWaitMessage"
                        )}
                        attrName="interactiveWaitNotificationCustomMessage"
                      />
                    )}
                    <InteractiveSwitch
                      saveNodeData={abstractNodesFromSelection}
                      element={node}
                      attrLabel={t(
                        "tiptap.components.sideConfigurationsMenu.attrLabel9"
                      )}
                      attrName="interactiveWaitNotificationIncludeContent"
                    />
                    {node.node.attrs.interactiveWaitNotificationIncludeContent && (
                      <Box
                        sx={{
                          border: "1px solid #dfdfdf",
                          padding: "10px 10px 20px 20px",
                          marginTop: 20,
                          borderRadius: 5,
                        }}
                      >
                        <InteractiveRadio
                          saveNodeData={abstractNodesFromSelection}
                          element={node}
                          radioData={[
                            { label: t("tiptap.components.sideConfigurationsMenu.attrLabel10"), value: "full" },
                            { label: t("tiptap.components.sideConfigurationsMenu.attrLabel11"), value: "short" }
                          ]}
                          attrName="interactiveWaitNotificationIncludeContentValue"
                        />
                      </Box>
                    )}
                  </>
                )}
              </Accordion.Item>
            )}

            {node && node.node && node.node.type.name == "interactiveWait" && (
              <Accordion.Item
                label={t("tiptap.components.sideConfigurationsMenu.label4")}
              >
                <InteractiveWaitTime
                  saveNodeData={abstractNodesFromSelection}
                  element={node}
                />
              </Accordion.Item>
            )}

            {node &&
              node.node &&
              node.node.attrs.buttonBackgroundColor &&
              (node.node.type.name != "circle" ||
                (node.node.type.name == "circle" &&
                  node.node.attrs.viewAs == "button")) && (
                <Accordion.Item
                  label={t("tiptap.components.sideConfigurationsMenu.label11")}
                >
                  <ButtonBackgroundColor
                    attrName="buttonBackgroundColor"
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                    label={t(
                      "tiptap.components.sideConfigurationsMenu.label12"
                    )}
                  />
                  {/*<ButtonBackgroundColor
                    attrName="buttonBorderColor"
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                    label="Button border color"
                    />*/}
                  <ButtonBackgroundColor
                    attrName="buttonTextColor"
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                    label={t(
                      "tiptap.components.sideConfigurationsMenu.label13"
                    )}
                  />
                  <Text size="sm" mt={15}>
                    {t("tiptap.components.sideConfigurationsMenu.text")}
                  </Text>
                  <Radius
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                  {node && node.node && node.node.type.name != "circle" && (
                    <ButtonText
                      label={t("tiptap.components.sidebar.buttonText.label")}
                      saveNodeData={abstractNodesFromSelection}
                      element={node}
                    />
                  )}
                  {node.node.type.name != "circle" &&
                    node.node.type.name != "survey" &&
                    node.node.attrs.actionCallback != "bundle" && (
                      <ButtonAction
                        saveNodeData={abstractNodesFromSelection}
                        element={node}
                      />
                    )}
                  {node.node.attrs.actionCallback == "bundle" && (
                    <BundleAction
                      saveNodeData={abstractNodesFromSelection}
                      element={node}
                    />
                  )}
                </Accordion.Item>
              )}

            {node &&
              node.node &&
              (!grid || (grid && grid.node.type.name != "infobox")) && (
                <Accordion.Item
                  label={t("tiptap.components.sideConfigurationsMenu.label5")}
                >
                  {grid ? (
                    <Tabs
                      classNames={{
                        tabsList: classes.tabList,
                        tabControl: classes.tabControl,
                        tabActive: classes.tabActive,
                        tabInner: classes.tabInner,
                      }}
                    >
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label6"
                        )}
                      >
                        <Padding
                          saveNodeData={saveNodeData}
                          element={node}
                          type="block"
                          withinGrid={grid ? true : false}
                        />
                      </Tabs.Tab>
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label7"
                        )}
                      >
                        <Padding
                          saveNodeData={saveNodeData}
                          element={column}
                          type="column"
                          withinGrid={grid ? true : false}
                        />
                      </Tabs.Tab>
                      <Tabs.Tab label="GRID">
                        <Padding
                          saveNodeData={saveNodeData}
                          element={grid}
                          type="grid"
                          withinGrid={grid ? true : false}
                        />
                      </Tabs.Tab>
                    </Tabs>
                  ) : (
                    <Box mt={10}>
                      <Padding
                        saveNodeData={saveNodeData}
                        element={node}
                        type="block"
                        withinGrid={grid ? true : false}
                      />
                    </Box>
                  )}
                </Accordion.Item>
              )}

            {node &&
              node.node &&
              (!grid || (grid && grid.node.type.name != "infobox")) && (
                <Accordion.Item
                  label={t("tiptap.components.sideConfigurationsMenu.label8")}
                >
                  {grid ? (
                    <Tabs
                      classNames={{
                        tabsList: classes.tabList,
                        tabControl: classes.tabControl,
                        tabActive: classes.tabActive,
                        tabInner: classes.tabInner,
                      }}
                    >
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label6"
                        )}
                      >
                        <Margin
                          saveNodeData={saveNodeData}
                          element={node}
                          type="block"
                          withinGrid={grid ? true : false}
                        />
                      </Tabs.Tab>
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label7"
                        )}
                      >
                        <Margin
                          saveNodeData={saveNodeData}
                          element={column}
                          type="column"
                          withinGrid={grid ? true : false}
                        />
                      </Tabs.Tab>
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label9"
                        )}
                      >
                        <Margin
                          saveNodeData={saveNodeData}
                          element={grid}
                          type="grid"
                          withinGrid={grid ? true : false}
                        />
                      </Tabs.Tab>
                    </Tabs>
                  ) : (
                    <Box mt={10}>
                      <Margin
                        saveNodeData={saveNodeData}
                        element={node}
                        type="block"
                        withinGrid={grid ? true : false}
                      />
                    </Box>
                  )}
                </Accordion.Item>
              )}
            {node &&
              node.node &&
              node.node.attrs.textAlign &&
              (node.node.type.name != "circle" ||
                (node.node.type.name == "circle" &&
                  node.node.attrs.viewAs == "button")) && (
                <Accordion.Item
                  label={t("tiptap.components.sideConfigurationsMenu.label18")}
                >
                  <Alignment
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                </Accordion.Item>
              )}

            {/*NEW COLUMN V ALIGN*/}
            {column &&
              node &&
              node.node &&
              (!grid || (grid && grid.node.type.name != "infobox")) && (
                <Accordion.Item
                  label={t("tiptap.components.sideConfigurationsMenu.label10")}
                >
                  <VerticalAlignment
                    saveNodeData={saveNodeData}
                    element={column}
                  />
                </Accordion.Item>
              )}
            {/*NEW COLUMN V ALIGN*/}

            {node &&
              node.node &&
              (node.node.attrs.class || node.node.attrs.resizeableWidth) &&
              (node.node.type.name != "circle" ||
                (node.node.type.name == "circle" &&
                  node.node.attrs.viewAs == "button")) && (
                <Accordion.Item label="Width">
                  <Width
                    grid={grid}
                    resizeable={node.node.attrs.resizeableWidth != null}
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                </Accordion.Item>
              )}

            {node &&
              node.node &&
              node.node.attrs.radius &&
              !node.node.attrs.buttonBackgroundColor && (
                <Accordion.Item
                  label={`${getHumanNodeType(
                    node.node.type.name,
                    "tiptap.components.sideConfigurationsMenu.label20"
                  )}`}
                >
                  {grid ? (
                    <Tabs
                      classNames={{
                        tabsList: classes.tabList,
                        tabControl: classes.tabControl,
                        tabActive: classes.tabActive,
                        tabInner: classes.tabInner,
                      }}
                    >
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label6"
                        )}
                      >
                        <Radius
                          saveNodeData={abstractNodesFromSelection}
                          element={node}
                        />
                      </Tabs.Tab>
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label7"
                        )}
                      >
                        <Radius
                          saveNodeData={abstractNodesFromSelection}
                          element={column}
                        />
                      </Tabs.Tab>
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label9"
                        )}
                      >
                        <Radius
                          saveNodeData={abstractNodesFromSelection}
                          element={grid}
                        />
                      </Tabs.Tab>
                    </Tabs>
                  ) : (
                    <Radius
                      saveNodeData={abstractNodesFromSelection}
                      element={node}
                    />
                  )}
                </Accordion.Item>
              )}

            {node &&
              node.node &&
              grid &&
              node.node.attrs.nestedRadius &&
              (!grid || (grid && grid.node.type.name != "infobox")) && (
                <Accordion.Item
                  label={t("tiptap.components.sideConfigurationsMenu.label19")}
                >
                  <Tabs
                    classNames={{
                      tabsList: classes.tabList,
                      tabControl: classes.tabControl,
                      tabActive: classes.tabActive,
                      tabInner: classes.tabInner,
                    }}
                  >
                    <Tabs.Tab
                      label={t(
                        "tiptap.components.sideConfigurationsMenu.label6"
                      )}
                    >
                      <NestedRadius
                        saveNodeData={abstractNodesFromSelection}
                        element={node}
                      />
                    </Tabs.Tab>
                    <Tabs.Tab
                      label={t(
                        "tiptap.components.sideConfigurationsMenu.label7"
                      )}
                    >
                      <NestedRadius
                        saveNodeData={abstractNodesFromSelection}
                        element={column}
                      />
                    </Tabs.Tab>
                    {/* <Tabs.Tab label={t("tiptap.components.sideConfigurationsMenu.label9")}>
                        <NestedRadius
                          saveNodeData={abstractNodesFromSelection}
                          element={grid}
                        />
                      </Tabs.Tab> */}
                  </Tabs>
                </Accordion.Item>
              )}

            {node &&
              node.node &&
              // node.node.type.name != "horizontalRule" &&
              node.node.type.name != "circle" &&
              node.node.type.name != "resource" && (
                <Accordion.Item
                  label={t("tiptap.components.sideConfigurationsMenu.label14")}
                >
                  {grid && grid.node.type.name != "infobox" ? (
                    <Tabs
                      classNames={{
                        tabsList: classes.tabList,
                        tabControl: classes.tabControl,
                        tabActive: classes.tabActive,
                        tabInner: classes.tabInner,
                      }}
                    >
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label6"
                        )}
                      >
                        <Select
                          mt={0}
                          mb={10}
                          value={bgType}
                          onChange={setBgType}
                          zIndex={99999}
                          data={[
                            {
                              label: t(
                                "tiptap.components.sideConfigurationsMenu.label15"
                              ),
                              value: "color",
                            },
                            {
                              label: t(
                                "tiptap.components.sideConfigurationsMenu.label16"
                              ),
                              value: "image",
                            },
                          ]}
                        />
                        {bgType == "color" ? (
                          <BackgroundColor
                            saveNodeData={saveNodeData}
                            element={node}
                          />
                        ) : (
                          <BackgroundImage
                            saveNodeData={saveNodeData}
                            element={node}
                          />
                        )}
                      </Tabs.Tab>
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label7"
                        )}
                      >
                        <Select
                          mt={0}
                          mb={10}
                          value={columnBgType}
                          onChange={setColumnBgType}
                          zIndex={99999}
                          data={[
                            {
                              label: t(
                                "tiptap.components.sideConfigurationsMenu.label15"
                              ),
                              value: "color",
                            },
                            {
                              label: t(
                                "tiptap.components.sideConfigurationsMenu.label16"
                              ),
                              value: "image",
                            },
                          ]}
                        />
                        {columnBgType == "color" ? (
                          <BackgroundColor
                            saveNodeData={saveNodeData}
                            element={column}
                          />
                        ) : (
                          <BackgroundImage
                            saveNodeData={saveNodeData}
                            element={column}
                          />
                        )}
                      </Tabs.Tab>
                      <Tabs.Tab
                        label={t(
                          "tiptap.components.sideConfigurationsMenu.label9"
                        )}
                      >
                        <Select
                          mt={0}
                          mb={10}
                          value={gridBgType}
                          onChange={setGridBgType}
                          zIndex={99999}
                          data={[
                            {
                              label: t(
                                "tiptap.components.sideConfigurationsMenu.label15"
                              ),
                              value: "color",
                            },
                            {
                              label: t(
                                "tiptap.components.sideConfigurationsMenu.label16"
                              ),
                              value: "image",
                            },
                          ]}
                        />

                        {gridBgType == "color" ? (
                          <BackgroundColor
                            saveNodeData={saveNodeData}
                            element={grid}
                          />
                        ) : (
                          <BackgroundImage
                            saveNodeData={saveNodeData}
                            element={grid}
                          />
                        )}
                      </Tabs.Tab>
                    </Tabs>
                  ) : (
                    <>
                      <Select
                        mt={10}
                        mb={10}
                        value={bgType}
                        onChange={setBgType}
                        zIndex={99999}
                        data={[
                          {
                            label: t(
                              "tiptap.components.sideConfigurationsMenu.label15"
                            ),
                            value: "color",
                          },
                          {
                            label: t(
                              "tiptap.components.sideConfigurationsMenu.label16"
                            ),
                            value: "image",
                          },
                        ]}
                      />

                      {bgType == "color" ? (
                        <BackgroundColor
                          saveNodeData={saveNodeData}
                          element={
                            grid && grid.node.type.name == "infobox"
                              ? column
                              : node
                          }
                        />
                      ) : (
                        <BackgroundImage
                          saveNodeData={saveNodeData}
                          element={
                            grid && grid.node.type.name == "infobox"
                              ? column
                              : node
                          }
                        />
                      )}
                    </>
                  )}
                </Accordion.Item>
              )}

            <Accordion.Item
              label={t("tiptap.components.sideConfigurationsMenu.label17")}
            >
              <MantineProvider theme={{ primaryShade: 6 }}>
                <Button
                  mt={10}
                  sx={{ width: "100%" }}
                  radius="xl"
                  variant="outline"
                  color="dark"
                  onClick={(event) => onReset(event, props)}
                >
                  {t("tiptap.components.sideConfigurationsMenu.resetBlock")}
                </Button>
              </MantineProvider>
            </Accordion.Item>

            {window.$currentUserIsAdmin &&
              node &&
              node.node &&
              node.node.attrs.blockId != null && (
                <Accordion.Item label="AI (Admins only)">
                  <BlockId
                    saveNodeData={abstractNodesFromSelection}
                    element={node}
                  />
                </Accordion.Item>
              )}

            {node &&
              node.node &&
              node.node.attrs.src &&
              (node.node.type.name == "video" ||
                node.node.type.name == "embed") &&
              (node.node.attrs.src.indexOf("youtube") ||
                node.node.attrs.src.indexOf("vimeo") ||
                node.node.attrs.src.indexOf("loom") ||
                node.node.attrs.src.indexOf("sutra")) && (
                <Accordion.Item label={"Transcripts"}>
                  <Transcript element={node} />
                </Accordion.Item>
              )}
          </Accordion>
        </Box>
      </ScrollArea>
    </Drawer>
  );
};

export default SideConfigurationsMenu;
