import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { stylesToString, fixStringToJSON } from "../../../../utils/StylesToString";

import Uploady, {
  useItemProgressListener,
  useItemFinishListener,
} from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";

import { Stack, Button, Image, Progress, NativeSelect } from "@mantine/core";

const LogProgress = (props) => {
  const [progress, setProgress] = useState(0);
  const { t, i18n } = useTranslation();
  useItemProgressListener((item) => {
    setProgress(item.completed);
  });
  useItemFinishListener((item) => {
    props.onUploadCallback(item.uploadResponse.data.file.url);
  });

  return (
    <>
      {progress > 0 && progress <= 100 ? (
        <Progress color="green" size="xl" mb={10} value={progress} />
      ) : (
        ""
      )}
    </>
  );
};

const CustomUploadButton = asUploadButton((props) => {
  const { t, i18n } = useTranslation();
  return (
    <Button radius="xl" {...props}>
      {t("tiptap.components.sidebar.backgroundImage.button")}
    </Button>
  );
});

export default function BackgroundImage(props) {
  const { t, i18n } = useTranslation();
  const loading = useRef(false);
  const firstLoad = useRef(false);

  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);

  const [bgImage, setBgImage] = useState();
  const [bgSize, setBgSize] = useState();
  const [bgPosition, setBgPosition] = useState();
  const [bgRepeat, setBgRepeat] = useState();

  const onBackgroundImageUpload = (image_url) => {
    setBgImage(image_url);
  };

  const onRemoveBackgroundImage = (event) => {
    setBgImage(null);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    if (!firstLoad.current) {
      setTimeout(() => {
        firstLoad.current = true;
      }, 500);
      return;
    }

    const node = props.element;
    let newStyles = node.node.attrs.styles
      ? { ...node.node.attrs.styles }
      : null;
    if (newStyles) {
      const stylesKeys = Object.keys(newStyles);
      if (stylesKeys && stylesKeys[0] == "0") {
        console.log(newStyles);
        console.log(stylesToString(newStyles));
        newStyles = fixStringToJSON(stylesToString(newStyles));
        console.log(node.node.type);
        console.log(newStyles);
        props.saveNodeData({ newStyles, _node: node }, "styles");
        return;
      }
    }
    if (bgImage && bgImage != "none") {
      newStyles["background-image"] = `url(${bgImage})`;
    } else {
      newStyles["background-image"] = "";
    }
    newStyles["background-size"] = bgSize;
    newStyles["background-position"] = bgPosition;
    newStyles["background-repeat"] = bgRepeat;
    newStyles["background-color"] = "";
    if (newStyles) {
      props.saveNodeData({ newStyles, _node: node }, "styles");
    }
  }, [bgImage, bgSize, bgPosition, bgRepeat]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      if (
        props.element.node.attrs.styles["background-image"] &&
        (props.element.node.attrs.styles["background-image"] == "url(null)" ||
          props.element.node.attrs.styles["background-image"] == "url(none)" ||
          props.element.node.attrs.styles["background-image"].indexOf(
            "linear-gradient"
          ) >= 0)
      ) {
        setBgImage(null);
      } else if (props.element.node.attrs.styles["background-image"]) {
        setBgImage(
          props.element.node.attrs.styles["background-image"]
            .replace("url", "")
            .replace("(", "")
            .replace(")", "")
        );
      }
      setBgSize(props.element.node.attrs.styles["background-size"]);
      setBgPosition(props.element.node.attrs.styles["background-position"]);
      setBgRepeat(props.element.node.attrs.styles["background-repeat"]);
    }
  }, [props.element]);

  return (
    <Stack>
      {bgImage && bgImage != "none" ? (
        <>
          <Image radius="md" src={bgImage} alt="Background image" />
          <Button
            color="red"
            onClick={(event) => onRemoveBackgroundImage(event)}
          >
            {t("tiptap.components.sidebar.backgroundImage.removeButton")}
          </Button>
          <NativeSelect
            value={bgSize}
            onChange={(event) => setBgSize(event.target.value)}
            label="Size"
            placeholder="Pick one"
            data={[
              { value: "cover", label: "cover" },
              { value: "contain", label: "contain" },
            ]}
          />
          <NativeSelect
            value={bgPosition}
            onChange={(event) => setBgPosition(event.target.value)}
            label={t("tiptap.components.sidebar.backgroundImage.label")}
            placeholder={t(
              "tiptap.components.sidebar.backgroundImage.placeholder"
            )}
            data={[
              {
                value: "left top",
                label: t("tiptap.components.sidebar.backgroundImage.leftTop"),
              },
              {
                value: "left center",
                label: t(
                  "tiptap.components.sidebar.backgroundImage.leftCenter"
                ),
              },
              {
                value: "left bottom",
                label: t(
                  "tiptap.components.sidebar.backgroundImage.leftBottom"
                ),
              },
              {
                value: "right top",
                label: t("tiptap.components.sidebar.backgroundImage.rightTop"),
              },
              {
                value: "right center",
                label: t(
                  "tiptap.components.sidebar.backgroundImage.rightCenter"
                ),
              },
              {
                value: "right bottom",
                label: t(
                  "tiptap.components.sidebar.backgroundImage.rightBottom"
                ),
              },
              {
                value: "center top",
                label: t("tiptap.components.sidebar.backgroundImage.centerTop"),
              },
              {
                value: "center center",
                label: t(
                  "tiptap.components.sidebar.backgroundImage.centerCenter"
                ),
              },
              {
                value: "center bottom",
                label: t(
                  "tiptap.components.sidebar.backgroundImage.centerBottom"
                ),
              },
            ]}
          />
          <NativeSelect
            value={bgRepeat}
            onChange={(event) => setBgRepeat(event.target.value)}
            label={t("tiptap.components.sidebar.backgroundImage.label2")}
            placeholder={t(
              "tiptap.components.sidebar.backgroundImage.placeholder2"
            )}
            data={[
              {
                value: "no-repeat",
                label: t("tiptap.components.sidebar.backgroundImage.noRepeat"),
              },
              {
                value: "repeat",
                label: t("tiptap.components.sidebar.backgroundImage.repeat"),
              },
              {
                value: "repeat-x",
                label: t("tiptap.components.sidebar.backgroundImage.repeat-x"),
              },
              {
                value: "repeat-y",
                label: t("tiptap.components.sidebar.backgroundImage.repeat-y"),
              },
              {
                value: "space",
                label: t("tiptap.components.sidebar.backgroundImage.space"),
              },
              {
                value: "round",
                label: t("tiptap.components.sidebar.backgroundImage.round"),
              },
            ]}
          />
        </>
      ) : (
        <>
          <Image
            width={260}
            height={100}
            src={null}
            alt="No image"
            withPlaceholder
          />
          <Uploady
            destination={{
              url: `/api/v4/spaces/${space.slug}/upload_file?auth_token=${user.authentication_token}`,
            }}
          >
            <LogProgress onUploadCallback={onBackgroundImageUpload} />
            <CustomUploadButton />
          </Uploady>
        </>
      )}
    </Stack>
  );
}
