import React, { useState } from "react";



import {
  Drawer,
  Stack,

  Center,
  Text,
  Progress,

  ScrollArea,
  Box,
} from "@mantine/core";

import Uploader from "../../../tiptap/components/Uploader";
import SpaceModel from "../../../../models/Space";

import { useTranslation } from "react-i18next";

const Thumbnail = (props) => {
  const { t, i18n } = useTranslation();
  const [mediaType, setMediaType] = useState("image");
  const [loading, setLoading] = useState(0);

  const onSave = (file) => {
    
    setLoading(0);
    SpaceModel.onUploadThumbnail(
      props.spaceId,
      file,
      (data) => {
        setTimeout(() => {
          props.onSave(props.spaceId, data.url);
          props.setOpened(false);
          setLoading(0);
        }, 1000);
      },
      (progress) => {
        setLoading(progress);
      },
      (data) => {
        setLoading(0);
      }
    );
  };

  return (
    <Drawer
      position="left"
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={<Text size="xs">{t("spaces.presentations.thumbnail.title")}</Text>}
      padding="xl"
      size={"400px"}
      overlayOpacity={0.1}
      zIndex={99999}
    >
      <ScrollArea style={{ height: "100%" }}>
        <Box mb={80}>
          {!loading ? (
            <Uploader
              unsplashHeight={400}
              editor={props.editor}
              uploadToEndpoint={onSave}
              uploadType={mediaType}
              exclude={[]}
            />
          ) : (
            <Stack>
              <Center>
                <Progress value={loading} />
              </Center>
              <Center>
                <Text mt={10}>{t("spaces.presentations.thumbnail.message")}</Text>
              </Center>
            </Stack>
          )}
        </Box>
      </ScrollArea>
    </Drawer>
  );
};

export default Thumbnail;
