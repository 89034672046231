import { DateTime } from "luxon";

const abbreviateTimezone = (timezone, date) => {
  const luxonDate = DateTime.fromISO(date);

  if (luxonDate.isInDST) {
    if (timezone == "GMT+2") {
      timezone = "CEST";
    } else if (timezone == "GMT+3") {
      timezone = "EEST";
    }
    return timezone;
  } else {
    if (timezone == "GMT+1") {
      timezone = "CET";
    } else if (timezone == "GMT+2") {
      timezone = "EET";
    }
    return timezone;
  }
};

export const getTimeZone = (date, language) => {
  return abbreviateTimezone(
    DateTime.fromISO(date).setLocale(language).toFormat("ZZZZ"),
    date
  );
};

export const isSameDay = (startAt, endAt, language) => {
  language = "en";
  const startMonth = DateTime.fromISO(startAt)
    .setLocale(language)
    .toFormat("LLL");
  const startDate = DateTime.fromISO(startAt).setLocale(language).toFormat("d");
  const startYear = DateTime.fromISO(startAt).setLocale(language).toFormat("y");

  const endMonth = DateTime.fromISO(endAt).setLocale(language).toFormat("LLL");
  const endDate = DateTime.fromISO(endAt).setLocale(language).toFormat("d");
  const endYear = DateTime.fromISO(endAt).setLocale(language).toFormat("y");

  return startMonth == endMonth && startDate == endDate && startYear == endYear;
};

export const simpleFormatDate = (
  date,
  language,
  format = "LLL d",
  format2 = "LLL d cccc",
  timeFormat = "h:mm a",
  timeZone = false
) => {
  let dateString = "";
  language = "en";
  const currentYear = new Date().getFullYear().toString();
  const startMonth = DateTime.fromISO(date).setLocale(language).toFormat("LLL");
  const startDate = DateTime.fromISO(date).setLocale(language).toFormat("d");
  const startYear = DateTime.fromISO(date).setLocale(language).toFormat("y");

  if (currentYear == startYear) {
    dateString = DateTime.fromISO(date)
      .setLocale(language)
      .toFormat(`${format}${timeFormat}`);
  } else {
    dateString = DateTime.fromISO(date)
      .setLocale(language)
      .toFormat(`${format2}${timeFormat}`);
  }

  if (timeFormat && timeZone) {
    dateString +=
      " " +
      abbreviateTimezone(
        DateTime.fromISO(date).setLocale(language).toFormat("ZZZZ"),
        date
      );
    // dateString += " " + DateTime.fromISO(date).setLocale(language).toFormat('ZZZZ')
  }

  return dateString;
};

const formatDate = (
  spaceCalendar,
  language,
  format1 = "LLL d",
  format2 = "cccc, LLL d"
) => {
  language = "en";

  let dateString = "";
  const currentYear = new Date().getFullYear().toString();
  const startMonth = DateTime.fromISO(spaceCalendar.raw_start_date)
    .setLocale(language)
    .toFormat("LLL");
  const startDate = DateTime.fromISO(spaceCalendar.raw_start_date)
    .setLocale(language)
    .toFormat("d");
  const startYear = DateTime.fromISO(spaceCalendar.raw_start_date)
    .setLocale(language)
    .toFormat("y");
  const startDay = DateTime.fromISO(spaceCalendar.raw_start_date)
    .setLocale(language)
    .toFormat("ccc");
  const startTime = DateTime.fromISO(spaceCalendar.raw_start_date)
    .setLocale(language)
    .toFormat("h:mm");
  const startMeridiem = DateTime.fromISO(spaceCalendar.raw_start_date)
    .setLocale(language)
    .toFormat("a");
  const startTimezone = DateTime.fromISO(spaceCalendar.raw_start_date)
    .setLocale(language)
    .toFormat("ZZZZ");

  if (spaceCalendar.has_start_date && spaceCalendar.has_end_date) {
    const endMonth = DateTime.fromISO(spaceCalendar.raw_end_date)
      .setLocale(language)
      .toFormat("LLL");
    const endDate = DateTime.fromISO(spaceCalendar.raw_end_date)
      .setLocale(language)
      .toFormat("d");
    const endYear = DateTime.fromISO(spaceCalendar.raw_end_date)
      .setLocale(language)
      .toFormat("y");
    const endDay = DateTime.fromISO(spaceCalendar.raw_end_date)
      .setLocale(language)
      .toFormat("ccc");
    const endTime = DateTime.fromISO(spaceCalendar.raw_end_date)
      .setLocale(language)
      .toFormat("h:mm");

    if (
      startDate === endDate &&
      startMonth === endMonth &&
      startYear === endYear &&
      startYear === currentYear
    ) {
      // same day, month, and year
      dateString =
        DateTime.fromISO(spaceCalendar.raw_start_date)
          .setLocale(language)
          .toFormat(`${format2}, h:mm a`) +
        " - " +
        DateTime.fromISO(spaceCalendar.raw_end_date)
          .setLocale(language)
          .toFormat("h:mm a") +
        " " +
        abbreviateTimezone(
          DateTime.fromISO(spaceCalendar.raw_end_date)
            .setLocale(language)
            .toFormat("ZZZZ"),
          spaceCalendar.raw_end_date
        );
    } else if (
      startMonth === endMonth &&
      startYear === endYear &&
      startYear === currentYear
    ) {
      // same month and year, different days
      dateString =
        DateTime.fromISO(spaceCalendar.raw_start_date)
          .setLocale(language)
          .toFormat(`${format1}, h:mm a`) +
        " - " +
        DateTime.fromISO(spaceCalendar.raw_end_date)
          .setLocale(language)
          .toFormat(`${format1}, h:mm a`) +
        `${endYear === currentYear ? "" : `, ${endYear}`}` +
        " " +
        abbreviateTimezone(
          DateTime.fromISO(spaceCalendar.raw_end_date)
            .setLocale(language)
            .toFormat("ZZZZ"),
          spaceCalendar.raw_end_date
        );
    } else {
      // different month and years
      dateString =
        DateTime.fromISO(spaceCalendar.raw_start_date)
          .setLocale(language)
          .toFormat(`${format1}, h:mm a`) +
        `${startYear === endYear ? "" : `, ${startYear}`}` +
        " - " +
        DateTime.fromISO(spaceCalendar.raw_end_date)
          .setLocale(language)
          .toFormat(`${format1}, h:mm a`) +
        `${endYear === currentYear ? "" : `, ${endYear}`}` +
        " " +
        abbreviateTimezone(
          DateTime.fromISO(spaceCalendar.raw_end_date)
            .setLocale(language)
            .toFormat("ZZZZ"),
          spaceCalendar.raw_end_date
        );
    }

    // dateString = DateTime.fromISO(spaceCalendar.raw_start_date).setLocale(language).toFormat('ccc, LLL d, h:mm a')
    // DateTime.fromISO(spaceCalendar.raw_end_date).setLocale(language).toFormat('ccc, LLL d, h:mm a ZZZZ')
  } else {
    dateString =
      DateTime.fromISO(spaceCalendar.raw_start_date)
        .setLocale(language)
        .toFormat(`${format2}, h:mm a`) +
      " " +
      abbreviateTimezone(
        DateTime.fromISO(spaceCalendar.raw_start_date)
          .setLocale(language)
          .toFormat("ZZZZ"),
        spaceCalendar.raw_start_date
      ) +
      `${startYear === currentYear ? "" : `, ${startYear}`}`;
  }

  return dateString;
};

export default formatDate;
