import React from "react";
import { Box, TextInput, ActionIcon, Radio, createStyles } from "@mantine/core";

import { X } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
const useStyles = createStyles((theme) => ({
  dragHandle: {
    ...theme.fn.focusStyles(),
    height: "100%",
    color: theme.colors.gray[6],
    cursor: "move",
    paddingTop: 3,
    width: 20,
    maxWidth: 20,
  },
}));

export default function SingleItem(props) {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box
        sx={{
          width: 30,
        }}
      >
        <Radio
          checked={false}
          styles={{
            radio: {
              marginTop: -2,
            },
          }}
        />
      </Box>

      <TextInput
        placeholder={t("spaces.settings.survey.singleItem.item.placeholder")}
        value={props.content}
        onChange={(event) => props.onContentChange(event.currentTarget.value)}
        radius="sm"
        size="md"
        sx={(theme) => ({
          width: "calc(100% - 50px)",
        })}
        rightSection={
          <ActionIcon variant="transparent" onClick={() => props.onDestroy()}>
            <X size={18} />
          </ActionIcon>
        }
        rightSectionWidth={40}
      />
    </>
  );
}
