import React from "react";

import SubNodeItem from "./NodeItem";

import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme, getRef) => ({
  topNodeItemChildren: {
    // [`& .${getRef('nodeItem')}`]: {
    paddingLeft: 0,
    // }
  },
}));

export default function NodeContent(props) {
  const { classes, cx } = useStyles();

  return (
    <div
      className={`${!props.parent ? classes.topNodeItemChildren : ""}`}
      style={{
        display:
          (props.opened && !props.node.sidebar_hide_children) || !props.parent
            ? "block"
            : "none",
      }}
    >
      {props.node && props.node.children
        ? props.node.children.map((child, index) => {
            return (
              child && (
                <SubNodeItem
                  key={`node-item-${child.slug}`}
                  current={props.current}
                  node={child}
                  parent={props.node}
                  opened={child.expanded}
                  onTreeUpdate={props.onTreeUpdate}
                  findNodeInTreeBySlug={props.findNodeInTreeBySlug}
                  onUpdateTreeData={props.onUpdateTreeData}
                  pos={index}
                  editable={props.editable}
                />
              )
            );
          })
        : ""}
    </div>
  );
}
