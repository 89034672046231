import React from 'react';

import { Routes, Route } from "react-router-dom";

import { useSelector } from 'react-redux';

import SettingsMain from "../Main"
import SettingsMembers from "../Members"
import SettingsBroadcast from "../Broadcast"
import SettingsSurveys from "../Surveys"
import SettingsStructures from "../Structures"
import SettingsAdmin from "../Admin"
import SettingsScheduled from "../Scheduled"
import SettingsArchived from "../Archived"
import SettingsParticipation from "../Participation"

export default function MainContent() {

  const space = useSelector(state => state.space);

  return (
    <div>
      <Routes>
        <Route path="main" element={<SettingsMain />} />
        <Route path="members" element={<SettingsMembers />} />
        <Route path="broadcast" element={<SettingsBroadcast />} />
        <Route path="surveys" element={<SettingsSurveys />} />
        <Route path="structures" element={<SettingsStructures />} />
        <Route path="admin" element={<SettingsAdmin />} />
        <Route path="scheduled" element={<SettingsScheduled />} />
        <Route path="archived" element={<SettingsArchived />} />
        <Route path="participation" element={<SettingsParticipation />} />
      </Routes>
    </div>
  )
}
