import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import SpaceSettingModel from "../../../../models/SpaceSetting";
import SpaceModel from "../../../../models/Space";

import { useSelector, useDispatch } from "react-redux";

import { setWideWidth, setSpace } from "../../../../store/app";
import { useTranslation } from "react-i18next";

import PremiumModal from "../../../app/PremiumModal";

import Tag from "./Tag";

import {
  Drawer,
  Text,
  Box,
  Stack,
  ScrollArea,
  Accordion,
  Select,
  createStyles,
  Button,
  Switch,
  TextInput,
  Loader,
  Group,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  item: {
    borderBottom: "none",
  },
  control: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 16,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    // marginRight: 8,
    // marginLeft: -5
  },
  content: {
    backgroundColor: theme.colors.gray[0],
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
  },
  contentInner: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const Settings = (props) => {
  const { t, i18n } = useTranslation();
  const { classes, cx, theme } = useStyles();

  const space = useSelector((state) => state.space);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [visualType, setVisualType] = useState("list");
  const [authors, setAuthors] = useState();
  const [templates, setTemplates] = useState([]);
  const [listChildVersion, setListChildVersion] = useState(
    props.parentVersion == "list" ? "post" : ""
  );
  const [listTemplateId, setListTemplateId] = useState("create");
  const [listActionText, setListActionText] = useState(
    props.parentVersion == "list" ? "Create post" : "Add event"
  );
  const [listLinkAction, setListLinkAction] = useState("subspace");
  const [listShowPreviewText, setListShowPreviewText] = useState();
  const [listShowThumbnail, setListShowThumbnail] = useState();
  const [listShowComments, setListShowComments] = useState();
  const [listShowMembers, setListShowMembers] = useState();
  const [listShowTimestamp, setListShowTimestamp] = useState();
  const [listAutoThumbnail, setListAutoThumbnail] = useState();
  const [listPrivacyControl, setListPrivacyControl] = useState();
  const [newTemplateName, setNewTemplateName] = useState();
  const [resourceEditing, setResourceEditing] = useState();
  const [listFilterBy, setListFilterBy] = useState();
  const [listFlatView, setListFlatView] = useState();
  const [listOpenInModal, setListOpenInModal] = useState();
  const [listAllowLikes, setListAllowLikes] = useState();
  const [listTitleLineClamp, setListTitleLineClamp] = useState();
  const [listAbstractLineClamp, setListAbstractLineClamp] = useState();
  const [listAutoTag, setListAutoTag] = useState();
  const [listAddCreatorAsRole, setListAddCreatorAsRole] = useState();
  const [width, setWidth] = useState(false);
  const [tags, setTags] = useState([]);
  const [opened, setOpened] = useState([]);
  const [canAutoTag, setCanAutoTag] = useState([]);

  const [listChildrenCapabilities, setListChildrenCapabilities] = useState([
    "limited",
  ]);

  const creationTypes = [
    {
      label: t("spaces.presentations.settings.versions.posts"),
      value: "posts",
      description: t(
        "spaces.presentations.settings.versionsDescriptions.posts"
      ),
    },
    {
      label: t("spaces.presentations.settings.versions.content"),
      value: "content",
      description: t(
        "spaces.presentations.settings.versionsDescriptions.content"
      ),
    },
    {
      label: t("spaces.presentations.settings.versions.discussions"),
      value: "discussions",
      description: t(
        "spaces.presentations.settings.versionsDescriptions.discussion"
      ),
    },
    {
      label: t("spaces.presentations.settings.versions.link"),
      value: "link",
      description: t("spaces.presentations.settings.versionsDescriptions.link"),
    },
  ];

  if (props.parentVersion == "list" || props.parentVersion == "showcase") {
    creationTypes.push({
      label: t("spaces.presentations.settings.versions.template"),
      value: "template",
      description: t(
        "spaces.presentations.settings.versionsDescriptions.template"
      ),
    });
  }

  const loading = useRef(false);
  const spaceId = useRef();
  const saving = useRef();
  const visualTypeChanged = useRef();
  const tagsTimeout = useRef();
  const [savingLoader, setSavingLoader] = useState(false);

  useEffect(() => {
    loading.current = false;
    onFetchTemplates();
  }, []);

  // useEffect(() => {
  //   if (props.spaceId && props.spaceId != spaceId.current) {
  //     spaceId.current = props.spaceId;
  //     onFetch();
  //   }
  // }, [props]);

  useEffect(() => {
    loading.current = false;
    spaceId.current = space.slug;
    onFetchTags();
    onFetch();

    setCanAutoTag(space && space.permissions && space.permissions.can_auto_tag);
  }, [space]);

  useEffect(() => {
    if (loading.current) {
      setWidth(visualType == "grid");
      setListFlatView(visualType == "list");
    }
  }, [visualType]);

  useEffect(() => {
    if (loading.current) {
      visualTypeChanged.current = true;
    }
  }, [width]);

  useEffect(() => {
    if (loading.current) {
      if (listChildVersion != "posts") {
        setListAllowLikes(false);
        setListOpenInModal(false);
      }
      if (listChildVersion == "posts") {
        setListAllowLikes(true);
        setListOpenInModal(true);
      }
    }
  }, [listChildVersion]);

  useEffect(() => {
    if (loading.current) {
      if (listAutoTag && !canAutoTag[0]) {
        setOpened([true, canAutoTag[1], canAutoTag[2]]);
        setListAutoTag(false);
        return;
      }

      setSavingLoader(true);
      if (
        listChildVersion == "posts" &&
        (props.parentVersion == "list" || props.parentVersion == "showcase")
      ) {
        setListTemplateId("create");
        setListLinkAction("subspace");
        // setListPrivacyControl("open");
        setListChildrenCapabilities("limited");
      }
      if (props.parentVersion == "events") {
        setListTemplateId("create");
        setListLinkAction("subspace");
        // setListPrivacyControl("open");
        setListChildrenCapabilities("full");
      }

      if (saving.current) {
        clearTimeout(saving.current);
      }
      saving.current = setTimeout(() => {
        onSave();
      }, 1000);
    }
  }, [
    visualType,
    authors,
    listChildVersion,
    listActionText,
    listTemplateId,
    listLinkAction,
    listShowPreviewText,
    listShowThumbnail,
    listShowComments,
    listShowMembers,
    listShowTimestamp,
    listAutoThumbnail,
    listPrivacyControl,
    resourceEditing,
    listFilterBy,
    listFlatView,
    width,
    listOpenInModal,
    listAllowLikes,
    listTitleLineClamp,
    listAbstractLineClamp,
    listAutoTag,
    listAddCreatorAsRole,
  ]);

  const onFetchTags = () => {
    if (space.tags) {
      setTags([...space.tags]);
    }
  };

  const onFetch = () => {
    if (!space.slug) {
      return;
    }

    if (!loading.current) {
      setVisualType(space.present_as);
      setWidth(space.space_width == "wide");
      setResourceEditing(space.resource_editing == "closed");

      setAuthors(space.settings.list_show_author);
      setListShowPreviewText(space.settings.list_show_preview_text);
      setListShowThumbnail(space.settings.list_show_thumbnail);
      setListShowComments(space.settings.list_show_comments_count);
      setListShowMembers(space.settings.list_show_members);
      setListShowTimestamp(space.settings.list_show_timestamp);
      setListAutoThumbnail(space.settings.list_auto_thumbnail);
      setListPrivacyControl(space.settings.list_privacy_control);
      setListAddCreatorAsRole(
        space.settings.list_add_creator_as_role
          ? space.settings.list_add_creator_as_role
          : "editor"
      );
      setListTitleLineClamp(
        space.settings.list_title_line_clamp < 20 ? true : false
      );
      setListAbstractLineClamp(
        space.settings.list_abstract_line_clamp < 20 ? true : false
      );
      setListAutoTag(space.settings.list_auto_tag);
      setListFilterBy(
        space.settings.list_filter_by
          ? space.settings.list_filter_by
          : "pods.created_at DESC"
      );
      setListFlatView(space.settings.list_flat_view);
      setListOpenInModal(space.settings.list_open_in_modal);
      setListAllowLikes(space.settings.list_allow_likes);

      setListChildVersion(
        space.settings.list_child_version
          ? space.settings.list_child_version
          : "posts"
      );
      setListActionText(
        space.settings.list_action_text
          ? space.settings.list_action_text
          : props.parentVersion == "list" || props.parentVersion == "showcase"
          ? "Create post"
          : "Add event"
      );
      setListTemplateId(
        space.settings.list_template_id
          ? space.settings.list_template_id
          : "create"
      );
      setListLinkAction(
        space.settings.list_link_action
          ? space.settings.list_link_action
          : "subspace"
      );
      setListChildrenCapabilities(
        space.settings.list_children_capabilities
          ? space.settings.list_children_capabilities
          : "limited"
      );

      setTimeout(() => {
        loading.current = true;
      }, 1000);
    }
  };

  const onSave = () => {
    const auxSettings = {
      space_width: width ? "wide" : "narrow",
      list_action_text: listActionText,
      list_template_id: listTemplateId != "create" ? listTemplateId : null,
      list_child_version: listChildVersion,
      list_link_action: listLinkAction,
      list_show_author: authors,
      list_show_preview_text: listShowPreviewText,
      list_show_thumbnail: listShowThumbnail,
      list_show_comments_count: listShowComments,
      list_show_members: listShowMembers,
      list_show_timestamp: listShowTimestamp,
      list_auto_thumbnail: listAutoThumbnail,
      list_privacy_control: listPrivacyControl,
      list_add_creator_as_role: listAddCreatorAsRole,
      list_filter_by: listFilterBy,
      list_flat_view: listFlatView,
      list_open_in_modal: listOpenInModal,
      list_allow_likes: listAllowLikes,
      list_auto_tag: listAutoTag,
      list_title_line_clamp: listTitleLineClamp ? 2 : 20,
      list_abstract_line_clamp: listAbstractLineClamp ? 2 : 20,
      list_children_capabilities:
        listTemplateId != "create" || listChildVersion == "content"
          ? ["full"]
          : ["limited"],
    };

    SpaceSettingModel.onUpdate(
      space.slug,
      {
        present_as: `${visualType}`,
        resource_editing: `${resourceEditing ? "closed" : "open"}`,
        pod_settings_attributes: { ...auxSettings },
      },
      (data) => {
        onFetchTags();
        setSavingLoader(false);
        if (visualTypeChanged.current) {
          visualTypeChanged.current = false;
          dispatch(setWideWidth({ on: width }));
          const auxSpace = { ...space };
          auxSpace.space_width = width ? "wide" : "narrow";
          auxSpace.present_as = `${visualType}`;
          auxSpace.resource_editing = `${resourceEditing ? "closed" : "open"}`;

          auxSpace.settings = {
            ...auxSpace.settings,
            ...auxSettings,
          };

          window.$spaceWideWidth = width;
          dispatch(setSpace(auxSpace));
        }
      },
      (data) => {}
    );

    props.onSave({
      visualType,
      authors,
      listChildVersion,
      listActionText,
      listTemplateId,
      listLinkAction,
      listShowPreviewText,
      listShowThumbnail,
      listShowComments,
      listShowMembers,
      listShowTimestamp,
      listAutoThumbnail,
      listPrivacyControl,
      resourceEditing,
      listFilterBy,
      listFlatView,
      listOpenInModal,
      listAllowLikes,
      width,
      listTitleLineClamp,
      listAbstractLineClamp,
      listAutoTag,
      listAddCreatorAsRole,
    });
  };

  const onCreateNewTemplate = () => {
    SpaceModel.onCreate(
      {
        version: "content",
        intention: newTemplateName,
        join_on_create: true,
        privacy_level: "private",
        pod_state: "model",
      },
      null,
      (data) => {
        const auxTemplates = [...templates];
        auxTemplates.unshift({ label: data.name, value: data.slug });
        setTemplates(auxTemplates);
        setListTemplateId(data.slug);
        onEditTemplate(data.slug);
      },
      (data) => {}
    );
  };

  const onEditTemplate = (slug) => {
    window.open(`/space/${slug}/content`, "_blank");
    // navigate(`/space/${slug}/content`)
  };

  const onFetchTemplates = () => {
    SpaceModel.onFetchModels(
      (data) => {
        const array = [];
        array.push({
          label: t("spaces.presentations.settings.createNewTemplate"),
          value: "create",
        });
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          array.push({ label: element.name, value: element.slug });
        }
        setTemplates(array);
      },
      (data) => {}
    );
  };

  const SelectItem = ({ image, label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  const onTag = (res) => {
    props.onFetchTags();
  };

  return (
    <>
      <Drawer
        position="left"
        opened={props.opened}
        onClose={() => props.setOpened(false)}
        title={
          <Group apart>
            <Text size="xs">{t("spaces.presentations.settings.title")}</Text>
            {savingLoader && <Loader size="xs" />}
          </Group>
        }
        styles={{
          header: {
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
          },
        }}
        size={"400px"}
        overlayOpacity={0.1}
        zIndex={99999}
      >
        <Box style={{ height: "100%", paddingBottom: "40px" }}>
          <Stack spacing={0} style={{ height: "100%" }}>
            <ScrollArea style={{ height: "100%" }}>
              <Accordion
                offsetIcon={false}
                iconPosition="right"
                multiple
                mb={100}
                initialState={{
                  0: true,
                  1: true,
                  2: true,
                  3: true,
                  4: true,
                  5: true,
                  6: true,
                  7: true,
                  8: true,
                  9: true,
                }}
                classNames={{
                  item: classes.item,
                  control: classes.control,
                  icon: classes.icon,
                  content: classes.content,
                  contentInner: classes.contentInner,
                }}
              >
                <Accordion.Item
                  label={t("spaces.presentations.settings.accordion1.label")}
                >
                  <Select
                    mt={0}
                    mb={10}
                    description={t(
                      "spaces.presentations.settings.accordion1.description"
                    )}
                    value={visualType}
                    onChange={setVisualType}
                    zIndex={99999}
                    data={[
                      {
                        label: t(
                          "spaces.presentations.settings.accordion1.listOption"
                        ),
                        value: "list",
                      },
                      {
                        label: t(
                          "spaces.presentations.settings.accordion1.gridOption"
                        ),
                        value: "grid",
                      },
                    ]}
                  />

                  {(props.parentVersion == "list" ||
                    props.parentVersion == "showcase" ||
                    props.parentVersion == "events") && (
                    <Select
                      mt={20}
                      mb={10}
                      description={"Sort posts by"}
                      value={listFilterBy}
                      onChange={setListFilterBy}
                      zIndex={99999}
                      data={[
                        {
                          label: t(
                            "spaces.presentations.settings.accordion1.dateCreated"
                          ),
                          value: "pods.created_at DESC",
                        },
                        {
                          label: t(
                            "spaces.presentations.settings.accordion1.dateCreatedReversed"
                          ),
                          value: "pods.created_at ASC",
                        },
                        {
                          label: t(
                            "spaces.presentations.settings.accordion1.lastActive"
                          ),
                          value: "pods.last_active DESC",
                        },
                        {
                          label: t(
                            "spaces.presentations.settings.accordion1.lastEdited"
                          ),
                          value: "pods.updated_at ASC",
                        },
                        {
                          label: t(
                            "spaces.presentations.settings.accordion1.alphabetic"
                          ),
                          value: "LOWER(pods.intention) ASC",
                        },
                        {
                          label: t(
                            "spaces.presentations.settings.accordion1.reverseAlphabetic"
                          ),
                          value: "LOWER(pods.intention) DESC",
                        },
                      ]}
                    />
                  )}
                </Accordion.Item>
                {(props.parentVersion == "list" ||
                  props.parentVersion == "events" ||
                  props.parentVersion == "showcase") && (
                  <Accordion.Item
                    label={t("spaces.presentations.settings.accordion2.label")}
                  >
                    <Stack>
                      <Switch
                        mt={0}
                        mb={10}
                        checked={resourceEditing}
                        onChange={(event) =>
                          setResourceEditing(event.currentTarget.checked)
                        }
                        label={t(
                          "spaces.presentations.settings.accordion2.switches.onlyManagers"
                        )}
                      />
                    </Stack>
                    {(props.parentVersion != "events" ||
                      visualType == "grid") && (
                      <>
                        <Stack>
                          <Switch
                            mt={0}
                            mb={10}
                            checked={authors}
                            onChange={(event) =>
                              setAuthors(event.currentTarget.checked)
                            }
                            label={t(
                              "spaces.presentations.settings.accordion2.switches.showAuthor"
                            )}
                          />
                        </Stack>
                        <Stack>
                          <Switch
                            mt={0}
                            mb={10}
                            checked={listShowPreviewText}
                            onChange={(event) =>
                              setListShowPreviewText(
                                event.currentTarget.checked
                              )
                            }
                            label={t(
                              "spaces.presentations.settings.accordion2.switches.showPreview"
                            )}
                          />
                        </Stack>
                        <Stack>
                          <Switch
                            mt={0}
                            mb={10}
                            checked={listShowComments}
                            onChange={(event) =>
                              setListShowComments(event.currentTarget.checked)
                            }
                            label={t(
                              "spaces.presentations.settings.accordion2.switches.showComments"
                            )}
                          />
                        </Stack>
                        <Stack>
                          <Switch
                            mt={0}
                            mb={10}
                            checked={listShowMembers}
                            onChange={(event) =>
                              setListShowMembers(event.currentTarget.checked)
                            }
                            label={t(
                              "spaces.presentations.settings.accordion2.switches.showMembers"
                            )}
                          />
                        </Stack>
                        <Stack>
                          <Switch
                            mt={0}
                            mb={10}
                            checked={listShowTimestamp}
                            onChange={(event) =>
                              setListShowTimestamp(event.currentTarget.checked)
                            }
                            label={t(
                              "spaces.presentations.settings.accordion2.switches.showTimestamp"
                            )}
                          />
                        </Stack>
                        <Stack>
                          <Switch
                            mt={0}
                            mb={10}
                            checked={listAllowLikes}
                            onChange={(event) =>
                              setListAllowLikes(event.currentTarget.checked)
                            }
                            label="Show heart button"
                          />
                        </Stack>
                        <Stack>
                          <Switch
                            mt={0}
                            mb={10}
                            checked={listShowThumbnail}
                            onChange={(event) =>
                              setListShowThumbnail(event.currentTarget.checked)
                            }
                            label={t(
                              "spaces.presentations.settings.accordion2.switches.showThumbnail"
                            )}
                          />
                        </Stack>
                      </>
                    )}
                    {listShowThumbnail && (
                      <Stack>
                        <Switch
                          mt={0}
                          mb={10}
                          checked={listAutoThumbnail}
                          onChange={(event) =>
                            setListAutoThumbnail(event.currentTarget.checked)
                          }
                          label={t(
                            "spaces.presentations.settings.accordion2.switches.autoThumbnail"
                          )}
                        />
                      </Stack>
                    )}

                    <Stack>
                      <Switch
                        mt={0}
                        mb={10}
                        checked={!listFlatView}
                        onChange={(event) =>
                          setListFlatView(!event.currentTarget.checked)
                        }
                        label={`Display as cards`}
                      />
                    </Stack>

                    <Stack>
                      <Switch
                        mt={0}
                        mb={10}
                        checked={width}
                        onChange={(event) =>
                          setWidth(event.currentTarget.checked)
                        }
                        label="Wide page width"
                      />
                    </Stack>

                    <Stack>
                      <Switch
                        mt={0}
                        mb={10}
                        checked={listOpenInModal}
                        onChange={(event) =>
                          setListOpenInModal(event.currentTarget.checked)
                        }
                        label="Open posts in side panel"
                      />
                    </Stack>

                    <Stack>
                      <Switch
                        mt={0}
                        mb={10}
                        checked={listTitleLineClamp}
                        onChange={(event) =>
                          setListTitleLineClamp(event.currentTarget.checked)
                        }
                        label="Limit title length"
                      />
                    </Stack>
                    <Stack>
                      <Switch
                        mt={0}
                        mb={10}
                        checked={listAbstractLineClamp}
                        onChange={(event) =>
                          setListAbstractLineClamp(event.currentTarget.checked)
                        }
                        label="Limit preview length"
                      />
                    </Stack>
                    <Stack>
                      <Switch
                        mt={0}
                        mb={10}
                        checked={listAutoTag}
                        onChange={(event) =>
                          setListAutoTag(event.currentTarget.checked)
                        }
                        label="Allow auto tagging"
                      />
                    </Stack>
                  </Accordion.Item>
                )}

                <Accordion.Item
                  label={t("spaces.presentations.settings.accordion3.label")}
                >
                  <Select
                    mt={10}
                    mb={10}
                    description={t(
                      "spaces.presentations.settings.accordion3.description"
                    )}
                    value={listChildVersion}
                    onChange={setListChildVersion}
                    zIndex={99999}
                    data={creationTypes}
                    itemComponent={SelectItem}
                  />
                  {listChildVersion == "template" && (
                    <>
                      {templates.length > 0 && (
                        <Select
                          mt={0}
                          mb={10}
                          value={listTemplateId}
                          onChange={setListTemplateId}
                          zIndex={99999}
                          data={templates}
                          searchable
                        />
                      )}
                      {!listTemplateId || listTemplateId == "create" ? (
                        <>
                          <TextInput
                            mb={10}
                            mt={20}
                            value={newTemplateName}
                            onChange={(event) =>
                              setNewTemplateName(event.currentTarget.value)
                            }
                            placeholder={t(
                              "spaces.presentations.settings.accordion3.templateName.placeholder"
                            )}
                            description={t(
                              "spaces.presentations.settings.accordion3.templateName.label"
                            )}
                            styles={{
                              label: {
                                fontWeight: 400,
                                color: "#000",
                              },
                            }}
                          />
                          <Button
                            onClick={() => onCreateNewTemplate()}
                            disabled={!newTemplateName}
                          >
                            {t(
                              "spaces.presentations.settings.createNewTemplate"
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button onClick={() => onEditTemplate(listTemplateId)}>
                          {t("spaces.presentations.settings.editTemplate")}
                        </Button>
                      )}
                      <Select
                        mt={20}
                        mb={10}
                        description={t(
                          "spaces.presentations.settings.templateDescription"
                        )}
                        value={listLinkAction}
                        onChange={setListLinkAction}
                        zIndex={99999}
                        data={[
                          {
                            label: t(
                              "spaces.presentations.settings.accordion3.templateDirectOption"
                            ),
                            value: "subspace",
                          },
                          {
                            label: t(
                              "spaces.presentations.settings.accordion3.templateLinkedOption"
                            ),
                            value: "linked",
                          },
                        ]}
                      />
                    </>
                  )}
                  <Select
                    mt={20}
                    mb={10}
                    description={t(
                      "spaces.presentations.settings.accordion3.privacyDescription"
                    )}
                    value={listPrivacyControl}
                    onChange={setListPrivacyControl}
                    zIndex={99999}
                    data={[
                      {
                        label: t(
                          "spaces.presentations.settings.accordion3.privacyOpenOption"
                        ),
                        value: "open",
                      },
                      {
                        label: t(
                          "spaces.presentations.settings.accordion3.privacyPrivateOption"
                        ),
                        value: "private",
                      },
                      {
                        label: t(
                          "spaces.presentations.settings.accordion3.privacyAnyOption"
                        ),
                        value: "any",
                      },
                    ]}
                  />
                  <Select
                    value={listAddCreatorAsRole}
                    onChange={setListAddCreatorAsRole}
                    mt={20}
                    mb={10}
                    zIndex={99999}
                    description="Add creator as"
                    placeholder="Pick one"
                    data={[
                      { value: "member", label: "Member" },
                      { value: "editor", label: "Editor" },
                      { value: "manager", label: "Manager" },
                      { value: "moderator", label: "Moderator" },
                    ]}
                  />
                </Accordion.Item>

                <Accordion.Item
                  label={t("spaces.presentations.settings.accordion4.label")}
                >
                  <TextInput
                    value={listActionText}
                    onChange={(event) =>
                      setListActionText(event.currentTarget.value)
                    }
                    placeholder={t(
                      "spaces.presentations.settings.accordion4.createPost.label"
                    )}
                    description={t(
                      "spaces.presentations.settings.accordion4.createPost.description"
                    )}
                    styles={{
                      label: {
                        fontWeight: 400,
                        color: "#000",
                      },
                    }}
                  />
                </Accordion.Item>

                <Accordion.Item
                  label={t("spaces.presentations.settings.accordion5.label")}
                >
                  <Tag
                    spaceId={space.slug}
                    tags={tags}
                    parentTags={tags}
                    onSave={onTag}
                    onUpdatedMessage={onTag}
                    action="create"
                    creatable={true}
                  />
                </Accordion.Item>
              </Accordion>
            </ScrollArea>
          </Stack>
        </Box>
      </Drawer>
      <PremiumModal opened={opened} setOpened={setOpened} />
    </>
  );
};

export default Settings;
