import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { useSelector } from 'react-redux';

import useSidebarStyles from "../../utils/SidebarStyles"
import defaultSpaceColors from '../../collections/defaultSpaceColors'

import LogoIcon from "../../src/images/logo/logoIcon.jsx"

export default function Logo(){

  const colors = useSelector(state => state.colors);
  const [logoUrl, setLogoUrl] = useState(`/splash-logo3.svg`);
  const [usingSutraLogo, setUsingSutraLogo] = useState(true);
  const [path, setPath] = useState(`/home/top`);
  const { settingsView } = useSidebarStyles();


  const space = useSelector(state => state.space);

  useEffect(() => {
    if (space && space.slug && space.logo.url){
      setLogoUrl(space.logo.url)
      setUsingSutraLogo(false)
    }
    else{
      setLogoUrl(`/splash-logo3.svg`)
      setUsingSutraLogo(true)
    }
    if (space && space.slug && window.$fetchedTopSlug){
      setPath(`/space/${window.$fetchedTopSlug}/${space.version}`)
    }
    else{
      setPath(`/home/top`)
    }
  }, [space])

  return (
    <Link
      to={path}
      className="header-logo"
      style={{
        color: !settingsView ? colors.header_link_color : defaultSpaceColors.header_link_color,
      }}
      >
      { usingSutraLogo ? (
        <>
          <LogoIcon />
          <span>sutra</span>
        </>
      ) : (
        <img
          src={logoUrl}
          alt="Logo"
          />
      ) }
    </Link>

  )

}
