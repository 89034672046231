import React, { useState, useEffect, useRef } from "react";

import PlanModel from "../../../models/Plan";
import { useTranslation } from "react-i18next";

import getSymbolFromCurrency from "currency-symbol-map";

import {
  Paper,
  Title,
  Text,
  Container,
  Group,
  Box,
  Divider,
  Button,
  TextInput,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import StripeModel from "../../../models/SutraStripe";
import { useSelector } from "react-redux";

export default function Start(props) {
  const { t, i18n } = useTranslation();

  const smallScreen = useMediaQuery("(max-width: 768px)");

  const user = useSelector((state) => state.user);

  const [plan, setPlan] = useState(null);

  const [total, setTotal] = useState(0);
  const loaded = useRef(false);

  const [addCoupon, setAddCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  useEffect(() => {
    if (loaded.current) {
      return;
    }
    if (props.plan) {
      onFetchbyId();
    }
  }, [props]);

  const onFetchbyId = () => {
    PlanModel.onFetchbyId(
      props.plan,
      (data) => {
        setPlan(data);

        setTotal(data.price);
        if (props.onPriceChange) {
          props.onPriceChange(
            data.price,
            data.currency,
            data.frequency,
            data.has_trial
          );
        }

        loaded.current = true;
      },
      (data) => {}
    );
  };

  const ensure2Dec = (value) => {
    return value.toFixed(2).replace(".00", "");
  };

  const getFrequency = () => {
    if (!plan) {
      return "";
    }
    return ` / ${plan.frequency.replace("ly", "")}`;
  };

  const getPaymentDetails = () => {
    if (plan.data.description) {
      return plan.data.description;
    } else {
      if (plan.frequency == "month") {
        return `${t(
          "users.planReference.paymentDetails.month"
        )} ${getSymbolFromCurrency(plan.currency)}${ensure2Dec(
          plan.price
        )}${getFrequency()}`;
      } else {
        return `${t(
          "users.planReference.paymentDetails.annual"
        )} ${getSymbolFromCurrency(plan.currency)}${ensure2Dec(
          plan.price
        )}${getFrequency()}`;
      }
    }
  };

  const onApplyCode = (event) => {
    if (event) {
      event.stopPropagation();
    }

    StripeModel.onApplyCode(
      couponCode,
      (data) => {
        const coupon = data.coupon;
        let auxTotal = plan.price;
        if (coupon.amount_off) {
          auxTotal -= coupon.amount_off / 100;
        } else {
          auxTotal -= auxTotal * (coupon.percent_off / 100);
        }
        setTotal(auxTotal);
        if (props.onPriceChange) {
          props.onPriceChange(
            auxTotal,
            plan.currency,
            plan.frequency,
            plan.has_trial
          );
        }
      },
      (data) => {}
    );
  };

  return (
    <Container size={500} mb={40} mt={smallScreen ? 10 : 40}>
      {plan ? (
        <Paper withBorder shadow="xs" p={0} mt={30} radius="md">
          <Box pt={25} pb={8} pl={35} pr={35}>
            <Title
              sx={(theme) => ({ fontSize: 24, fontWeight: 600 })}
              order={2}
              mb={10}
            >
              {`Sutra ${plan.data.title}`}
            </Title>

            <Text mt={5} size="lg" color="dimmed">
              {`${getPaymentDetails()}`}
            </Text>
            {user && user.authenticated && (
              <Group>
                {addCoupon && (
                  <TextInput
                    mt={10}
                    placeholder="DISCOUNT1212"
                    value={couponCode}
                    onChange={(event) =>
                      setCouponCode(event.currentTarget.value)
                    }
                    onClick={(event) => event.stopPropagation()}
                  />
                )}
                {addCoupon ? (
                  <Button
                    mt={10}
                    variant="outline"
                    onClick={(event) => onApplyCode(event)}
                  >
                    Apply coupon
                  </Button>
                ) : (
                  <Button
                    mt={10}
                    variant="outline"
                    onClick={(event) => {
                      event.stopPropagation();
                      setAddCoupon(true);
                    }}
                  >
                    + Add coupon
                  </Button>
                )}
              </Group>
            )}
            <Divider
              sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
              mt={20}
              mb={15}
            />

            {plan && (
              <>
                {plan.has_trial ? (
                  <>
                    <Group position="apart" mb={10}>
                      <Text size="lg">
                        {t("users.planReference.total_today")}
                      </Text>
                      <Text size="lg" sx={(theme) => ({ fontWeight: 600 })}>
                        {`$0`}
                      </Text>
                    </Group>
                    <Group position="apart" mb={10}>
                      <Text size="lg">{t("users.planReference.due_in")}</Text>
                      <Text size="lg" sx={(theme) => ({ fontWeight: 600 })}>
                        {`${getSymbolFromCurrency(plan.currency)}${ensure2Dec(
                          total
                        )}`}
                      </Text>
                    </Group>
                  </>
                ) : (
                  <Group position="apart" mb={10}>
                    <Text size="lg">{t("users.planReference.total")}</Text>
                    <Text size="lg" sx={(theme) => ({ fontWeight: 600 })}>
                      {`${getSymbolFromCurrency(plan.currency)}${ensure2Dec(
                        total
                      )}`}
                    </Text>
                  </Group>
                )}
              </>
            )}
          </Box>
        </Paper>
      ) : (
        ""
      )}
    </Container>
  );
}
