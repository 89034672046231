import Broadcaster from "../../../helpers/Broadcaster";
import { onDragStart } from "../plugins/EventHandler";
import { getSimpleStructure } from "../utils/selection";
import { colors } from "../../../collections/colors";

import Swal from "sweetalert2";

const plusIcon =
  '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path></svg>';

const deleteIcon =
  '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"></path></svg>';

const dragIcon =
  '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrows-up-down-left-right" class="svg-inline--fa fa-arrows-up-down-left-right " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M512 255.1c0 8.188-3.125 16.41-9.375 22.66l-72 72C424.4 356.9 416.2 360 408 360c-18.28 0-32-14.95-32-32c0-8.188 3.125-16.38 9.375-22.62L402.8 288H288v114.8l17.38-17.38C311.6 379.1 319.8 376 328 376c18.28 0 32 14.95 32 32c0 8.188-3.125 16.38-9.375 22.62l-72 72C272.4 508.9 264.2 512 256 512s-16.38-3.125-22.62-9.375l-72-72C155.1 424.4 152 416.2 152 408c0-17.05 13.73-32 32-32c8.188 0 16.38 3.125 22.62 9.375L224 402.8V288H109.3l17.38 17.38C132.9 311.6 136 319.8 136 328c0 17.05-13.73 32-32 32c-8.188 0-16.38-3.125-22.62-9.375l-72-72C3.125 272.4 0 264.2 0 255.1s3.125-16.34 9.375-22.59l72-72C87.63 155.1 95.81 152 104 152c18.28 0 32 14.95 32 32c0 8.188-3.125 16.38-9.375 22.62L109.3 224H224V109.3L206.6 126.6C200.4 132.9 192.2 136 184 136c-18.28 0-32-14.95-32-32c0-8.188 3.125-16.38 9.375-22.62l72-72C239.6 3.125 247.8 0 256 0s16.38 3.125 22.62 9.375l72 72C356.9 87.63 360 95.81 360 104c0 17.05-13.73 32-32 32c-8.188 0-16.38-3.125-22.62-9.375L288 109.3V224h114.8l-17.38-17.38C379.1 200.4 376 192.2 376 184c0-17.05 13.73-32 32-32c8.188 0 16.38 3.125 22.62 9.375l72 72C508.9 239.6 512 247.8 512 255.1z"></path></svg>';

const cloneIcon =
  '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clone" class="svg-inline--fa fa-clone " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 0c26.5 0 48 21.5 48 48v288c0 26.5-21.5 48-48 48H176c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48h288M176 416c-44.1 0-80-35.9-80-80V128H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-48H176z"/></svg>';

export function gridActions(editor) {
  const actions = document.createElement("div");
  actions.classList.add("tiptap-block-actions");
  const innerWrap = document.createElement("div");
  innerWrap.classList.add("tiptap-block-actions-inner");
  actions.append(innerWrap);

  innerWrap.append(
    addButton(plusIcon, (event) => {

      event.stopPropagation();

      let coords = { left: event.clientX, top: event.clientY };
      let pos = editor.view.posAtCoords(coords);

      if (pos.inside == 0) {
        editor
          .chain()
          .focus()
          .setTextSelection(pos.inside)
          .selectParentNode()
          .selectParentNode()
          .selectParentNode()
          .run();
      } else {
        editor.chain().focus().setNodeSelection(pos.inside).run();
      }
      // debugger;
      editor.chain().focus().enter().selectTextblockEnd().run();

      editor.chain().setTextSelection(editor.view.state.selection.to).run();

      const { from, to } = editor.view.state.selection;
      window.$tiptapLastSelectionRange = { from, to };

      Broadcaster.send("open_SideBlockMenu", document.body, {});
    })
  );

  innerWrap.append(
    addButton(deleteIcon, (event) => {
      event.stopPropagation();
      let coords = { left: event.clientX, top: event.clientY };
      let pos = editor.view.posAtCoords(coords);

      if (pos) {
        if (pos.inside == 0) {
          editor
            .chain()
            .focus()
            .setTextSelection(pos.inside)
            .selectParentNode()
            .selectParentNode()
            .selectParentNode()
            .run();
        } else {
          editor.chain().focus().setNodeSelection(pos.inside).run();
        }

        const { from, to, $anchor } = editor.view.state.selection;
        window.$tiptapLastSelectionRange = { from, to };

        
      }

      Swal.fire({
        title: "Are you sure?",
        html: `You are about to delete a content block.`,
        showDenyButton: true,
        confirmButtonText: "Yes, delete",
        denyButtonText: "No",
        confirmButtonColor: colors.red,
        denyButtonColor: colors.gray,
      }).then((result) => {
        if (result.isConfirmed) {
          if (editor.view.state.selection.node) {
            editor.commands.deleteSelection();
          } else {
            editor.commands.deleteNode(
              editor.view.state.selection.$anchor.parent.type.name
            );
          }
          
        } else if (result.isDenied) {
        }
      });
    })
  );

  innerWrap.append(
    addButton(
      dragIcon,
      (event) => {

        event.stopPropagation();
        // CLICK
        let coords = { left: event.clientX, top: event.clientY };
        let pos = editor.view.posAtCoords(coords);

        if (pos) {
          if (pos.inside == 0) {
            editor
              .chain()
              .focus()
              .setTextSelection(pos.inside)
              .selectParentNode()
              .selectParentNode()
              .selectParentNode()
              .run();
          } else {
            editor.chain().focus().setNodeSelection(pos.inside).run();
          }

          const { from, to, $anchor } = editor.view.state.selection;
          window.$tiptapLastSelectionRange = { from, to };

          setTimeout(() => {
            const slice = onDragStart(
              event,
              window.$tiptapLastSelectionRange,
              editor
            );
            const nodes = getSimpleStructure(editor, slice);

            // const draggingNode = {nodes: nodes, parent: "current", outsider: true, selection_type: "multiple"}
            //
            //
            // dispatch(setSidebarDragging(draggingNode));
          }, 200);
        }
      },
      (event) => {
        // HOVER IN
        try {
          let coords = { left: event.clientX, top: event.clientY };
          let pos = editor.view.posAtCoords(coords);

          if (pos) {
            if (pos.inside == 0) {
              editor
                .chain()
                .focus()
                .setTextSelection(pos.inside)
                .selectParentNode()
                .selectParentNode()
                .selectParentNode()
                .run();
            } else {
              editor.chain().focus().setNodeSelection(pos.inside).run();
            }

            const { from, to, $anchor } = editor.view.state.selection;
            window.$tiptapLastSelectionRange = { from, to };
          }
        } catch (e) {}
      },
      (event) => {
        // HOVER OUT
        // try {
        //   let coords = { left: event.clientX, top: event.clientY };
        //   let pos = editor.view.posAtCoords(coords);
        //   if (pos) {
        //     editor
        //       .chain()
        //       .focus()
        //       .setTextSelection(pos.inside)
        //       .selectTextblockStart()
        //       .run();
        //   }
        // } catch (e) {}
      }
    )
  );

  innerWrap.append(
    addButton(cloneIcon, (event) => {
      event.stopPropagation();
      let coords = { left: event.clientX, top: event.clientY };
      let pos = editor.view.posAtCoords(coords);

      if (pos) {
        if (pos.inside == 0) {
          editor
            .chain()
            .focus()
            .setTextSelection(pos.inside)
            .selectParentNode()
            .selectParentNode()
            .selectParentNode()
            .run();
        } else {
          editor.chain().focus().setNodeSelection(pos.inside).run();
        }

        const { $head, node } = editor.view.state.selection;

        const copy = node.copy(node.content);
        const transaction = editor.state.tr.insert($head.pos, copy);

        editor.view.dispatch(transaction);
      }
    })
  );

  return actions;
}

function addButton(
  html,
  clickCallback,
  hoverInCallback = null,
  hoverOutCallback = null
) {
  const button = document.createElement("button");
  button.innerHTML = html;
  button.classList.add("tiptap-block-actions-button");
  button.addEventListener("click", (event) => {
    clickCallback(event);
  });
  if (hoverInCallback) {
    button.addEventListener("mouseover", (event) => {
      hoverInCallback(event);
    });
  }
  if (hoverOutCallback) {
    button.addEventListener("mouseout", (event) => {
      hoverOutCallback(event);
    });
  }
  return button;
}
