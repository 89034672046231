import React from "react";

import SubNodeItem from "./NodeItem";

import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme, getRef) => ({
  topNodeItemChildren: {
    // [`& .${getRef('nodeItem')}`]: {
    paddingLeft: 0,
    // }
  },
}));

export default function NodeContent(props) {
  const { classes, cx } = useStyles();

  const pinned = () => {
    const spaces = props.node.children;
    if (!spaces){
      return [];
    }
    if (["events", "showcase", "list"].indexOf(props.node.version) < 0){
      return [];
    }
    return spaces
      .filter((space) => space.is_pinned.is_pinned == true)
      .sort((a, b) => new Date(a.is_pinned.date) - new Date(b.is_pinned.date));
  };

  const unpinned = () => {
    const spaces = props.node.children;
    if (!spaces){
      return [];
    }
    if (["events", "showcase", "list"].indexOf(props.node.version) < 0){
      return spaces;
    }
    return spaces.filter((space) => space.is_pinned.is_pinned == false);
  };

  return (
    <div
      className={`${!props.parent ? classes.topNodeItemChildren : ""}`}
      style={{
        display:
          (props.opened && props.node && !props.sidebarHideChildren) ||
          !props.parent
            ? "block"
            : "none",
      }}
    >
      {props.node &&
        pinned().map((child, index) => {
          return (
            child && (
              <SubNodeItem
                key={`node-item-${child.slug}`}
                current={props.current}
                node={child}
                parent={props.node}
                opened={child.sidebar_default_opened}
                onTreeUpdate={props.onTreeUpdate}
                findNodeInTreeBySlug={props.findNodeInTreeBySlug}
                onUpdateTreeData={props.onUpdateTreeData}
                pos={index}
                editable={props.editable}
              />
            )
          );
        })}
      {props.node &&
        unpinned().map((child, index) => {
          return (
            child && (
              <SubNodeItem
                key={`node-item-${child.slug}`}
                current={props.current}
                node={child}
                parent={props.node}
                opened={child.sidebar_default_opened}
                onTreeUpdate={props.onTreeUpdate}
                findNodeInTreeBySlug={props.findNodeInTreeBySlug}
                onUpdateTreeData={props.onUpdateTreeData}
                pos={index}
                editable={props.editable}
              />
            )
          );
        })}
    </div>
  );
}
