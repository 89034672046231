import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { faIcons } from "../../../collections/faIcons2";

import { createStyles, Box, Group, ScrollArea, TextInput } from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const useStyles = createStyles((theme, { colors }) => ({
  nodeIconWrap: {
    display: "inline-block",
    width: "20px",
    textAlign: "center",
    color: theme.colors.gray[7],
  },
}));

export default function IconSelect(props) {
  const colors = useSelector((state) => state.colors);
  const { theme, classes, cx } = useStyles({ colors });
  const [iconType, setIconType] = useState("");
  const [iconValue, setIconValue] = useState("");
  const [value, setValue] = useState("");
  const [icons, setIcons] = useState(faIcons);
  const { t, i18n } = useTranslation();
  const testIcon = "stripe-s";

  useEffect(() => {
    filterIcons();
  }, [value]);

  const onIconSelect = (event) => {
    setIconType(event.split("#")[0]);
    setIconValue(event.split("#")[1]);
    props.onIconSelect(event);
  };

  const displayIcons = () => {
    return icons.map((icon) => (
      <Box
        key={`${icon.type}-${icon.value}`}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: 20,
          padding: 5,
          borderRadius: 3,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: theme.colors.gray[2],
          },
        }}
        onClick={() => onIconSelect(`${icon.type}#${icon.value}`)}
      >
        {icon.icon}
      </Box>
    ));
  };

  // const displayIcons = () => {
  //   return icons.map((icon) => (
  //     <Box
  //       key={`${icon.type}-${icon.value}`}
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         width: 20,
  //         padding: 5,
  //         borderRadius: 3,
  //         cursor: "pointer",
  //         "&:hover": {
  //           backgroundColor: theme.colors.gray[2]
  //         }
  //       }}
  //       onClick={() => onIconSelect(`${icon.type}#${icon.value}`)}
  //     >
  //       {<FontAwesomeIcon icon={[icon.type, icon.value]} />}
  //     </Box>
  //   ))
  // }

  const filterIcons = () => {
    setIcons(faIcons.filter((icon) => icon.value.includes(value)));
  };

  return (
    <ScrollArea style={{ height: 440 }} pb={5}>
      <TextInput
        icon={<FontAwesomeIcon size="sm" icon={solid("magnifying-glass")} />}
        radius="md"
        size="sm"
        mx={12}
        my={10}
        placeholder={t("spaces.sidebar.iconSelect.placeholder")}
        rightSectionWidth={42}
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        styles={{
          input: {
            display: "block",
            border: 0,
            outline: 0,
            width: "100%",
            padding: "10px 2em 10px 2.2em",
            borderRadius: 10,
            backgroundColor: "rgba(0, 0, 0, .05)",
            transitionDuration: "225ms",
            transitionProperty: "background-color, box-shadow",
            transitionTimingFunction: "cubic-bezier(.4, .0, .2, 1)",

            "&::placeholder": {
              color: "#777",
            },
          },
          icon: {
            color: "#777",
          },
        }}
      />
      <Group px={15} pb={10} spacing={10}>
        {displayIcons()}
      </Group>
    </ScrollArea>
  );
}
