import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { atcb_action } from "add-to-calendar-button";
import { useNavigate } from "react-router-dom";
import "add-to-calendar-button/assets/css/atcb.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import useLangUser from "../../utils/LangUser";
import SpaceModel from "../../models/Space";
import {
  simpleFormatDate,
  isSameDay,
  getTimeZone,
} from "../../utils/formatDate";
import CalendarLinks from "./form/CalendarLinks";
import InterestedForm from "./form/InterestedForm";

import {
  Stack,
  Popover,
  Box,
  Text,
  Paper,
  Group,
  Button,
  Drawer,
  createStyles,
} from "@mantine/core";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";

import { DateTime } from "luxon";

import { setTiptapSidemenu } from "../../store/app";

import { getCookie } from "../../utils/Cookies";

// import { DateTime } from "luxon";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
  },
  textColor: {
    color: colors.sidebar_text_color,
  },
  buttonColor: {
    border: isSpaceThemed(colors)
      ? `1px solid ${colors.sidebar_text_color}`
      : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : colors.primary_button_background_color,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
        : tinycolor(colors.sidebar_background_color).darken(1).toString(),
    },
  },
  buttonColorSolid: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  ellipsisColor: {
    paddingLeft: 10,
    paddingRight: 10,
    // border: isSpaceThemed(colors) ? `1px solid ${colors.sidebar_text_color}` : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : colors.sidebar_text_color,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
        : tinycolor(colors.sidebar_background_color).darken(1).toString(),
    },
  },

  dateBlockWrap: {
    padding: "7px 16px",
    borderRadius: 5,
    height: "100%",
    // border: "1px solid #ddd",
    border: `1px solid ${
      isSpaceThemed(colors) ? colors.sidebar_text_color : "#000"
    }`,
    marginRight: 5,
    color: isSpaceThemed(colors) ? colors.sidebar_text_color : "#000",
  },

  dateBlockDate: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: 1,
    marginBottom: 5,
  },

  dateBlockMonth: {
    lineHeight: 1,
  },

  appearanceButtonRoot: {
    color: theme.colors.gray[7],
    fontWeight: 400,
    fontSize: 16,
    borderRadius: 5,
    display: "block",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
    paddingLeft: 10,
  },
  appearanceButtonLeftIconGreen: {
    color: theme.colors.green[7],
    width: 20,
    display: "flex",
    justifyContent: "center",
  },
  appearanceButtonLeftIcon: {
    color: theme.colors.gray[7],
    width: 20,
    display: "flex",
    justifyContent: "center",
  },
  appearanceButtonInner: {
    justifyContent: "flex-start",
  },
}));

export default function Calendar(props) {
  const user = useSelector((state) => state.user);
  const colors = useSelector((state) => state.colors);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { classes, cx, theme } = useStyles({ colors });
  const [opened, setOpened] = useState(false);
  const [openGoing, setOpenGoging] = useState(false);

  const { t, i18n } = useTranslation();
  const { language, setLangUser } = useLangUser();
  const [calendar, setCalendar] = useState({});
  const [registered, setRegistered] = useState({});

  // React.useEffect(atcb_init, []);
  const [name, setName] = React.useState("Some event");
  const changeName = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    setCalendar({ ...props.calendar });
    setRegistered(getCookie(`interested_${props.spaceId}`));
  }, [props]);

  useEffect(() => {
    setRegistered(getCookie(`interested_${props.spaceId}`));
  }, [tiptapSidemenu]);

  const getlink = () => {
    return `https://sutra.co/space/${props.spaceId}/${props.spaceVersion}`;
  };

  const concatSpaceLink = (text) => {
    // return `${text}<p>${getlink()}</p>`
    return text;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (calendar.raw_dates_ok) {
      atcb_action({
        name: props.eventName,
        location: calendar.hangout_link,
        description: concatSpaceLink(calendar.description),
        startDate: calendar.raw_start_date,
        endDate: calendar.raw_end_date,
        options: [
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "Outlook.com",
          "Yahoo",
        ],
        timeZone: "UTC",
        iCalFileName: "Reminder-Event",
      });
    } else {
      atcb_action({
        name: props.eventName,
        location: calendar.hangout_link,
        description: concatSpaceLink(calendar.description),
        startDate: calendar.raw_start_date,
        endDate: calendar.raw_start_date,
        options: [
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "Outlook.com",
          "Yahoo",
        ],
        timeZone: "UTC",
        iCalFileName: "Reminder-Event",
      });
    }
  };

  const onJoinCall = () => {
    window.open(calendar.hangout_link, "_blank");
  };

  const onSave = (link, description, start_date, end_date) => {
    setOpened(false);
    const newCalendar = { ...calendar };
    newCalendar.hangout_link = link;
    newCalendar.description = description;
    newCalendar.raw_start_date = start_date;
    newCalendar.raw_end_date = end_date;
    setCalendar(newCalendar);
  };

  const onJoin = () => {
    if (!user.authenticated) {
      if (calendar.allow_interested_on_join) {
        dispatch(setTiptapSidemenu({ opened: "interestedform" }));
        return;
      }

      localStorage.setItem("join_slug", props.spaceId);
      navigate(`/user/start?s=${props.spaceId}`);
      return;
    }
    SpaceModel.onJoin(
      props.spaceId,
      (data) => {
        navigate(`${data.redirect_to}?refresh=true`);
      },
      (data) => {}
    );
  };

  const onLeave = () => {
    SpaceModel.onLeave(
      props.spaceId,
      (data) => {
        window.location.reload();
      },
      (data) => {}
    );
  };

  if (!calendar) {
    return null;
  }

  return (
    <Paper
      radius={10}
      mt={20}
      mb={20}
      pt={20}
      pb={20}
      pl={20}
      pr={20}
      className={classes.panelWrap}
    >
      <Group position="apart">
        <Group>
          <Stack
            spacing={0}
            align="center"
            className={cx(classes.dateBlockWrap)}
          >
            <Box className={classes.dateBlockDate}>
              {DateTime.fromISO(calendar.raw_start_date)
                .setLocale(language)
                .toFormat("d")}
            </Box>
            <Box className={classes.dateBlockMonth}>
              {DateTime.fromISO(calendar.raw_start_date)
                .setLocale(language)
                .toFormat("LLL")}
            </Box>
          </Stack>
          <Stack spacing={0}>
            <Text size="xl" weight={800} className={classes.textColor}>
              {simpleFormatDate(
                calendar.raw_start_date,
                language,
                "EEEE, LLL d",
                "EEEE, LLL d, cccc",
                ""
              )}
            </Text>
            <Text weight={500} className={classes.textColor}>
              {simpleFormatDate(
                calendar.raw_start_date,
                language,
                "",
                "",
                "h:mm a"
              )}
              {calendar.raw_end_date
                ? isSameDay(
                    calendar.raw_start_date,
                    calendar.raw_end_date,
                    language
                  )
                  ? " to " +
                    simpleFormatDate(
                      calendar.raw_end_date,
                      language,
                      "",
                      "",
                      "h:mm a"
                    )
                  : " to " +
                    simpleFormatDate(
                      calendar.raw_end_date,
                      language,
                      "LLL d",
                      "EEEE, LLL d, cccc",
                      ", h:mm a"
                    )
                : ""}
              {" " + getTimeZone(calendar.raw_start_date, language)}
            </Text>
          </Stack>
        </Group>
        <Group spacing={5} position="right">
          {calendar.hangout_link && (props.isMember || registered) && (
            <Button
              radius="xl"
              size="xs"
              variant="outline"
              className={classes.buttonColor}
              type="button"
              onClick={() => onJoinCall()}
            >
              {t("spaces.calender.calender.joinButton")}
            </Button>
          )}
          {(props.isMember || registered) && (
            <Box>
              <form onSubmit={(event) => onSubmit(event)}>
                <Button
                  radius="xl"
                  size="xs"
                  variant="outline"
                  className={classes.buttonColor}
                  type="submit"
                >
                  {t("spaces.calender.calender.addButton")}
                </Button>
              </form>
            </Box>
          )}
          {props.isMember && (
            <Box>
              <Popover
                withArrow
                opened={openGoing}
                onClose={() => setOpenGoging(false)}
                width={props.canEdit ? 200 : 150}
                position="bottom"
                placement="end"
                size="md"
                styles={{
                  inner: {
                    padding: 4,
                  },
                }}
                target={
                  <Button
                    radius="xl"
                    size="xs"
                    variant="outline"
                    className={classes.buttonColor}
                    type="button"
                    onClick={(event) => setOpenGoging(true)}
                  >
                    Going
                  </Button>
                }
              >
                {props.canEdit ? (
                  <Text p={10}>
                    You cannot unregister for this event because you’re a
                    manager.
                  </Text>
                ) : (
                  <>
                    <Button
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIconGreen,
                      }}
                      leftIcon={
                        <FontAwesomeIcon icon={solid("circle-check")} />
                      }
                      radius="xl"
                      onClick={() => onJoin()}
                    >
                      {"Going"}
                    </Button>
                    <Button
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={<FontAwesomeIcon icon={regular("circle")} />}
                      radius="xl"
                      onClick={() => onLeave()}
                    >
                      {"Not going"}
                    </Button>
                  </>
                )}
              </Popover>
            </Box>
          )}
          {!props.isMember && !registered && (
            <Box>
              <Button
                radius="xl"
                size="md"
                className={classes.buttonColorSolid}
                type="button"
                onClick={() => onJoin()}
              >
                {t("spaces.calender.calender.joinEventButton")}
              </Button>
            </Box>
          )}
          {props.canEdit && (
            <Button
              variant="subtle"
              radius="xl"
              className={classes.ellipsisColor}
              onClick={() => setOpened(true)}
            >
              <FontAwesomeIcon icon={solid("gear")} />
            </Button>
          )}
        </Group>
      </Group>

      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("spaces.calender.calender.title")}
        padding="xl"
        size="xl"
      >
        <CalendarLinks onSave={onSave} useSpace2={props.useSpace2} />
      </Drawer>
      <InterestedForm spaceId={props.spaceId} />
    </Paper>
  );
}
