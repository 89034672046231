import { sutraTheme } from "./sutraTheme";
import { DEFAULT_THEME } from "@mantine/core";

const defaultSpaceColors = {
  header_color: "#FFFFFF",
  header_link_color: sutraTheme.colors["sutrapink"][3],
  primary_button_background_color: sutraTheme.colors["sutrablue"][3],
  primary_button_text_color: "#FFFFFF",
  secondary_button_background_color: DEFAULT_THEME.colors.gray[1],
  secondary_button_text_color: DEFAULT_THEME.colors.gray[8],
  background_color: "#FFFFFF",
  default_text_color: "#000000",
  default_link_color: sutraTheme.colors["sutrapink"][3],
  // secondary_background_color: "#FFFFFF",
  // sub_header_text_color: "#000000",
  sidebar_background_color: "#F9F9F9",
  sidebar_text_color: "#000000",
  default_badge_color: sutraTheme.colors["sutrapink"][3],
  default_badge_text_color: "#FFFFFF",
  registration_page_background_color: "#FFFFFF",
  registration_page_default_text_color: "#000000",
};

export default defaultSpaceColors;
