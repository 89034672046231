import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

export const faIcons = [
  { icon: <FontAwesomeIcon icon={solid('address-book')} />,
    type: 'solid',
    value: 'address-book' },
  { icon: <FontAwesomeIcon icon={solid('address-card')} />,
    type: 'solid',
    value: 'address-card' },
  { icon: <FontAwesomeIcon icon={solid('adjust')} />,
    type: 'solid',
    value: 'adjust' },
  { icon: <FontAwesomeIcon icon={solid('align-center')} />,
    type: 'solid',
    value: 'align-center' },
  { icon: <FontAwesomeIcon icon={solid('align-justify')} />,
    type: 'solid',
    value: 'align-justify' },
  { icon: <FontAwesomeIcon icon={solid('align-left')} />,
    type: 'solid',
    value: 'align-left' },
  { icon: <FontAwesomeIcon icon={solid('align-right')} />,
    type: 'solid',
    value: 'align-right' },
  { icon: <FontAwesomeIcon icon={solid('allergies')} />,
    type: 'solid',
    value: 'allergies' },
  { icon: <FontAwesomeIcon icon={solid('ambulance')} />,
    type: 'solid',
    value: 'ambulance' },
  { icon: <FontAwesomeIcon icon={solid('american-sign-language-interpreting')} />,
    type: 'solid',
    value: 'american-sign-language-interpreting' },
  { icon: <FontAwesomeIcon icon={solid('anchor')} />,
    type: 'solid',
    value: 'anchor' },
  { icon: <FontAwesomeIcon icon={solid('angle-double-down')} />,
    type: 'solid',
    value: 'angle-double-down' },
  { icon: <FontAwesomeIcon icon={solid('angle-double-left')} />,
    type: 'solid',
    value: 'angle-double-left' },
  { icon: <FontAwesomeIcon icon={solid('angle-double-right')} />,
    type: 'solid',
    value: 'angle-double-right' },
  { icon: <FontAwesomeIcon icon={solid('angle-double-up')} />,
    type: 'solid',
    value: 'angle-double-up' },
  { icon: <FontAwesomeIcon icon={solid('angle-down')} />,
    type: 'solid',
    value: 'angle-down' },
  { icon: <FontAwesomeIcon icon={solid('angle-left')} />,
    type: 'solid',
    value: 'angle-left' },
  { icon: <FontAwesomeIcon icon={solid('angle-right')} />,
    type: 'solid',
    value: 'angle-right' },
  { icon: <FontAwesomeIcon icon={solid('angle-up')} />,
    type: 'solid',
    value: 'angle-up' },
  { icon: <FontAwesomeIcon icon={solid('archive')} />,
    type: 'solid',
    value: 'archive' },
  { icon: <FontAwesomeIcon icon={solid('arrow-alt-circle-down')} />,
    type: 'solid',
    value: 'arrow-alt-circle-down' },
  { icon: <FontAwesomeIcon icon={solid('arrow-alt-circle-left')} />,
    type: 'solid',
    value: 'arrow-alt-circle-left' },
  { icon: <FontAwesomeIcon icon={solid('arrow-alt-circle-right')} />,
    type: 'solid',
    value: 'arrow-alt-circle-right' },
  { icon: <FontAwesomeIcon icon={solid('arrow-alt-circle-up')} />,
    type: 'solid',
    value: 'arrow-alt-circle-up' },
  { icon: <FontAwesomeIcon icon={solid('arrow-circle-down')} />,
    type: 'solid',
    value: 'arrow-circle-down' },
  { icon: <FontAwesomeIcon icon={solid('arrow-circle-left')} />,
    type: 'solid',
    value: 'arrow-circle-left' },
  { icon: <FontAwesomeIcon icon={solid('arrow-circle-right')} />,
    type: 'solid',
    value: 'arrow-circle-right' },
  { icon: <FontAwesomeIcon icon={solid('arrow-circle-up')} />,
    type: 'solid',
    value: 'arrow-circle-up' },
  { icon: <FontAwesomeIcon icon={solid('arrow-down')} />,
    type: 'solid',
    value: 'arrow-down' },
  { icon: <FontAwesomeIcon icon={solid('arrow-left')} />,
    type: 'solid',
    value: 'arrow-left' },
  { icon: <FontAwesomeIcon icon={solid('arrow-right')} />,
    type: 'solid',
    value: 'arrow-right' },
  { icon: <FontAwesomeIcon icon={solid('arrow-up')} />,
    type: 'solid',
    value: 'arrow-up' },
  { icon: <FontAwesomeIcon icon={solid('arrows-alt')} />,
    type: 'solid',
    value: 'arrows-alt' },
  { icon: <FontAwesomeIcon icon={solid('arrows-alt-h')} />,
    type: 'solid',
    value: 'arrows-alt-h' },
  { icon: <FontAwesomeIcon icon={solid('arrows-alt-v')} />,
    type: 'solid',
    value: 'arrows-alt-v' },
  { icon: <FontAwesomeIcon icon={solid('assistive-listening-systems')} />,
    type: 'solid',
    value: 'assistive-listening-systems' },
  { icon: <FontAwesomeIcon icon={solid('asterisk')} />,
    type: 'solid',
    value: 'asterisk' },
  { icon: <FontAwesomeIcon icon={solid('at')} />,
    type: 'solid',
    value: 'at' },
  { icon: <FontAwesomeIcon icon={solid('audio-description')} />,
    type: 'solid',
    value: 'audio-description' },
  { icon: <FontAwesomeIcon icon={solid('backward')} />,
    type: 'solid',
    value: 'backward' },
  { icon: <FontAwesomeIcon icon={solid('balance-scale')} />,
    type: 'solid',
    value: 'balance-scale' },
  { icon: <FontAwesomeIcon icon={solid('ban')} />,
    type: 'solid',
    value: 'ban' },
  { icon: <FontAwesomeIcon icon={solid('band-aid')} />,
    type: 'solid',
    value: 'band-aid' },
  { icon: <FontAwesomeIcon icon={solid('barcode')} />,
    type: 'solid',
    value: 'barcode' },
  { icon: <FontAwesomeIcon icon={solid('bars')} />,
    type: 'solid',
    value: 'bars' },
  { icon: <FontAwesomeIcon icon={solid('baseball-ball')} />,
    type: 'solid',
    value: 'baseball-ball' },
  { icon: <FontAwesomeIcon icon={solid('basketball-ball')} />,
    type: 'solid',
    value: 'basketball-ball' },
  { icon: <FontAwesomeIcon icon={solid('bath')} />,
    type: 'solid',
    value: 'bath' },
  { icon: <FontAwesomeIcon icon={solid('battery-empty')} />,
    type: 'solid',
    value: 'battery-empty' },
  { icon: <FontAwesomeIcon icon={solid('battery-full')} />,
    type: 'solid',
    value: 'battery-full' },
  { icon: <FontAwesomeIcon icon={solid('battery-half')} />,
    type: 'solid',
    value: 'battery-half' },
  { icon: <FontAwesomeIcon icon={solid('battery-quarter')} />,
    type: 'solid',
    value: 'battery-quarter' },
  { icon: <FontAwesomeIcon icon={solid('battery-three-quarters')} />,
    type: 'solid',
    value: 'battery-three-quarters' },
  { icon: <FontAwesomeIcon icon={solid('bed')} />,
    type: 'solid',
    value: 'bed' },
  { icon: <FontAwesomeIcon icon={solid('beer')} />,
    type: 'solid',
    value: 'beer' },
  { icon: <FontAwesomeIcon icon={solid('bell')} />,
    type: 'solid',
    value: 'bell' },
  { icon: <FontAwesomeIcon icon={solid('bell-slash')} />,
    type: 'solid',
    value: 'bell-slash' },
  { icon: <FontAwesomeIcon icon={solid('bicycle')} />,
    type: 'solid',
    value: 'bicycle' },
  { icon: <FontAwesomeIcon icon={solid('binoculars')} />,
    type: 'solid',
    value: 'binoculars' },
  { icon: <FontAwesomeIcon icon={solid('birthday-cake')} />,
    type: 'solid',
    value: 'birthday-cake' },
  { icon: <FontAwesomeIcon icon={solid('blind')} />,
    type: 'solid',
    value: 'blind' },
  { icon: <FontAwesomeIcon icon={solid('bold')} />,
    type: 'solid',
    value: 'bold' },
  { icon: <FontAwesomeIcon icon={solid('bolt')} />,
    type: 'solid',
    value: 'bolt' },
  { icon: <FontAwesomeIcon icon={solid('bomb')} />,
    type: 'solid',
    value: 'bomb' },
  { icon: <FontAwesomeIcon icon={solid('book')} />,
    type: 'solid',
    value: 'book' },
  { icon: <FontAwesomeIcon icon={solid('bookmark')} />,
    type: 'solid',
    value: 'bookmark' },
  { icon: <FontAwesomeIcon icon={solid('bowling-ball')} />,
    type: 'solid',
    value: 'bowling-ball' },
  { icon: <FontAwesomeIcon icon={solid('box')} />,
    type: 'solid',
    value: 'box' },
  { icon: <FontAwesomeIcon icon={solid('box-open')} />,
    type: 'solid',
    value: 'box-open' },
  { icon: <FontAwesomeIcon icon={solid('boxes')} />,
    type: 'solid',
    value: 'boxes' },
  { icon: <FontAwesomeIcon icon={solid('braille')} />,
    type: 'solid',
    value: 'braille' },
  { icon: <FontAwesomeIcon icon={solid('briefcase')} />,
    type: 'solid',
    value: 'briefcase' },
  { icon: <FontAwesomeIcon icon={solid('briefcase-medical')} />,
    type: 'solid',
    value: 'briefcase-medical' },
  { icon: <FontAwesomeIcon icon={solid('bug')} />,
    type: 'solid',
    value: 'bug' },
  { icon: <FontAwesomeIcon icon={solid('building')} />,
    type: 'solid',
    value: 'building' },
  { icon: <FontAwesomeIcon icon={solid('bullhorn')} />,
    type: 'solid',
    value: 'bullhorn' },
  { icon: <FontAwesomeIcon icon={solid('bullseye')} />,
    type: 'solid',
    value: 'bullseye' },
  { icon: <FontAwesomeIcon icon={solid('burn')} />,
    type: 'solid',
    value: 'burn' },
  { icon: <FontAwesomeIcon icon={solid('bus')} />,
    type: 'solid',
    value: 'bus' },
  { icon: <FontAwesomeIcon icon={solid('calculator')} />,
    type: 'solid',
    value: 'calculator' },
  { icon: <FontAwesomeIcon icon={solid('calendar')} />,
    type: 'solid',
    value: 'calendar' },
  { icon: <FontAwesomeIcon icon={solid('calendar-alt')} />,
    type: 'solid',
    value: 'calendar-alt' },
  { icon: <FontAwesomeIcon icon={solid('calendar-check')} />,
    type: 'solid',
    value: 'calendar-check' },
  { icon: <FontAwesomeIcon icon={solid('calendar-minus')} />,
    type: 'solid',
    value: 'calendar-minus' },
  { icon: <FontAwesomeIcon icon={solid('calendar-plus')} />,
    type: 'solid',
    value: 'calendar-plus' },
  { icon: <FontAwesomeIcon icon={solid('calendar-times')} />,
    type: 'solid',
    value: 'calendar-times' },
  { icon: <FontAwesomeIcon icon={solid('camera')} />,
    type: 'solid',
    value: 'camera' },
  { icon: <FontAwesomeIcon icon={solid('camera-retro')} />,
    type: 'solid',
    value: 'camera-retro' },
  { icon: <FontAwesomeIcon icon={solid('capsules')} />,
    type: 'solid',
    value: 'capsules' },
  { icon: <FontAwesomeIcon icon={solid('car')} />,
    type: 'solid',
    value: 'car' },
  { icon: <FontAwesomeIcon icon={solid('caret-down')} />,
    type: 'solid',
    value: 'caret-down' },
  { icon: <FontAwesomeIcon icon={solid('caret-left')} />,
    type: 'solid',
    value: 'caret-left' },
  { icon: <FontAwesomeIcon icon={solid('caret-right')} />,
    type: 'solid',
    value: 'caret-right' },
  { icon: <FontAwesomeIcon icon={solid('caret-square-down')} />,
    type: 'solid',
    value: 'caret-square-down' },
  { icon: <FontAwesomeIcon icon={solid('caret-square-left')} />,
    type: 'solid',
    value: 'caret-square-left' },
  { icon: <FontAwesomeIcon icon={solid('caret-square-right')} />,
    type: 'solid',
    value: 'caret-square-right' },
  { icon: <FontAwesomeIcon icon={solid('caret-square-up')} />,
    type: 'solid',
    value: 'caret-square-up' },
  { icon: <FontAwesomeIcon icon={solid('caret-up')} />,
    type: 'solid',
    value: 'caret-up' },
  { icon: <FontAwesomeIcon icon={solid('cart-arrow-down')} />,
    type: 'solid',
    value: 'cart-arrow-down' },
  { icon: <FontAwesomeIcon icon={solid('cart-plus')} />,
    type: 'solid',
    value: 'cart-plus' },
  { icon: <FontAwesomeIcon icon={solid('certificate')} />,
    type: 'solid',
    value: 'certificate' },
  { icon: <FontAwesomeIcon icon={solid('chart-area')} />,
    type: 'solid',
    value: 'chart-area' },
  { icon: <FontAwesomeIcon icon={solid('chart-bar')} />,
    type: 'solid',
    value: 'chart-bar' },
  { icon: <FontAwesomeIcon icon={solid('chart-line')} />,
    type: 'solid',
    value: 'chart-line' },
  { icon: <FontAwesomeIcon icon={solid('chart-pie')} />,
    type: 'solid',
    value: 'chart-pie' },
  { icon: <FontAwesomeIcon icon={solid('check')} />,
    type: 'solid',
    value: 'check' },
  { icon: <FontAwesomeIcon icon={solid('check-circle')} />,
    type: 'solid',
    value: 'check-circle' },
  { icon: <FontAwesomeIcon icon={solid('check-square')} />,
    type: 'solid',
    value: 'check-square' },
  { icon: <FontAwesomeIcon icon={solid('chess')} />,
    type: 'solid',
    value: 'chess' },
  { icon: <FontAwesomeIcon icon={solid('chess-bishop')} />,
    type: 'solid',
    value: 'chess-bishop' },
  { icon: <FontAwesomeIcon icon={solid('chess-board')} />,
    type: 'solid',
    value: 'chess-board' },
  { icon: <FontAwesomeIcon icon={solid('chess-king')} />,
    type: 'solid',
    value: 'chess-king' },
  { icon: <FontAwesomeIcon icon={solid('chess-knight')} />,
    type: 'solid',
    value: 'chess-knight' },
  { icon: <FontAwesomeIcon icon={solid('chess-pawn')} />,
    type: 'solid',
    value: 'chess-pawn' },
  { icon: <FontAwesomeIcon icon={solid('chess-queen')} />,
    type: 'solid',
    value: 'chess-queen' },
  { icon: <FontAwesomeIcon icon={solid('chess-rook')} />,
    type: 'solid',
    value: 'chess-rook' },
  { icon: <FontAwesomeIcon icon={solid('chevron-circle-down')} />,
    type: 'solid',
    value: 'chevron-circle-down' },
  { icon: <FontAwesomeIcon icon={solid('chevron-circle-left')} />,
    type: 'solid',
    value: 'chevron-circle-left' },
  { icon: <FontAwesomeIcon icon={solid('chevron-circle-right')} />,
    type: 'solid',
    value: 'chevron-circle-right' },
  { icon: <FontAwesomeIcon icon={solid('chevron-circle-up')} />,
    type: 'solid',
    value: 'chevron-circle-up' },
  { icon: <FontAwesomeIcon icon={solid('chevron-down')} />,
    type: 'solid',
    value: 'chevron-down' },
  { icon: <FontAwesomeIcon icon={solid('chevron-left')} />,
    type: 'solid',
    value: 'chevron-left' },
  { icon: <FontAwesomeIcon icon={solid('chevron-right')} />,
    type: 'solid',
    value: 'chevron-right' },
  { icon: <FontAwesomeIcon icon={solid('chevron-up')} />,
    type: 'solid',
    value: 'chevron-up' },
  { icon: <FontAwesomeIcon icon={solid('child')} />,
    type: 'solid',
    value: 'child' },
  { icon: <FontAwesomeIcon icon={solid('circle')} />,
    type: 'solid',
    value: 'circle' },
  { icon: <FontAwesomeIcon icon={solid('circle-notch')} />,
    type: 'solid',
    value: 'circle-notch' },
  { icon: <FontAwesomeIcon icon={solid('clipboard')} />,
    type: 'solid',
    value: 'clipboard' },
  { icon: <FontAwesomeIcon icon={solid('clipboard-check')} />,
    type: 'solid',
    value: 'clipboard-check' },
  { icon: <FontAwesomeIcon icon={solid('clipboard-list')} />,
    type: 'solid',
    value: 'clipboard-list' },
  { icon: <FontAwesomeIcon icon={solid('clock')} />,
    type: 'solid',
    value: 'clock' },
  { icon: <FontAwesomeIcon icon={solid('clone')} />,
    type: 'solid',
    value: 'clone' },
  { icon: <FontAwesomeIcon icon={solid('closed-captioning')} />,
    type: 'solid',
    value: 'closed-captioning' },
  { icon: <FontAwesomeIcon icon={solid('cloud')} />,
    type: 'solid',
    value: 'cloud' },
  { icon: <FontAwesomeIcon icon={solid('cloud-download-alt')} />,
    type: 'solid',
    value: 'cloud-download-alt' },
  { icon: <FontAwesomeIcon icon={solid('cloud-upload-alt')} />,
    type: 'solid',
    value: 'cloud-upload-alt' },
  { icon: <FontAwesomeIcon icon={solid('code')} />,
    type: 'solid',
    value: 'code' },
  { icon: <FontAwesomeIcon icon={solid('code-branch')} />,
    type: 'solid',
    value: 'code-branch' },
  { icon: <FontAwesomeIcon icon={solid('coffee')} />,
    type: 'solid',
    value: 'coffee' },
  { icon: <FontAwesomeIcon icon={solid('cog')} />,
    type: 'solid',
    value: 'cog' },
  { icon: <FontAwesomeIcon icon={solid('cogs')} />,
    type: 'solid',
    value: 'cogs' },
  { icon: <FontAwesomeIcon icon={solid('columns')} />,
    type: 'solid',
    value: 'columns' },
  { icon: <FontAwesomeIcon icon={solid('comment')} />,
    type: 'solid',
    value: 'comment' },
  { icon: <FontAwesomeIcon icon={solid('comment-alt')} />,
    type: 'solid',
    value: 'comment-alt' },
  { icon: <FontAwesomeIcon icon={solid('comment-dots')} />,
    type: 'solid',
    value: 'comment-dots' },
  { icon: <FontAwesomeIcon icon={solid('comment-slash')} />,
    type: 'solid',
    value: 'comment-slash' },
  { icon: <FontAwesomeIcon icon={solid('comments')} />,
    type: 'solid',
    value: 'comments' },
  { icon: <FontAwesomeIcon icon={solid('compass')} />,
    type: 'solid',
    value: 'compass' },
  { icon: <FontAwesomeIcon icon={solid('compress')} />,
    type: 'solid',
    value: 'compress' },
  { icon: <FontAwesomeIcon icon={solid('copy')} />,
    type: 'solid',
    value: 'copy' },
  { icon: <FontAwesomeIcon icon={solid('copyright')} />,
    type: 'solid',
    value: 'copyright' },
  { icon: <FontAwesomeIcon icon={solid('couch')} />,
    type: 'solid',
    value: 'couch' },
  { icon: <FontAwesomeIcon icon={solid('credit-card')} />,
    type: 'solid',
    value: 'credit-card' },
  { icon: <FontAwesomeIcon icon={solid('crop')} />,
    type: 'solid',
    value: 'crop' },
  { icon: <FontAwesomeIcon icon={solid('crosshairs')} />,
    type: 'solid',
    value: 'crosshairs' },
  { icon: <FontAwesomeIcon icon={solid('cube')} />,
    type: 'solid',
    value: 'cube' },
  { icon: <FontAwesomeIcon icon={solid('cubes')} />,
    type: 'solid',
    value: 'cubes' },
  { icon: <FontAwesomeIcon icon={solid('cut')} />,
    type: 'solid',
    value: 'cut' },
  { icon: <FontAwesomeIcon icon={solid('database')} />,
    type: 'solid',
    value: 'database' },
  { icon: <FontAwesomeIcon icon={solid('deaf')} />,
    type: 'solid',
    value: 'deaf' },
  { icon: <FontAwesomeIcon icon={solid('desktop')} />,
    type: 'solid',
    value: 'desktop' },
  { icon: <FontAwesomeIcon icon={solid('diagnoses')} />,
    type: 'solid',
    value: 'diagnoses' },
  { icon: <FontAwesomeIcon icon={solid('dna')} />,
    type: 'solid',
    value: 'dna' },
  { icon: <FontAwesomeIcon icon={solid('dollar-sign')} />,
    type: 'solid',
    value: 'dollar-sign' },
  { icon: <FontAwesomeIcon icon={solid('dolly')} />,
    type: 'solid',
    value: 'dolly' },
  { icon: <FontAwesomeIcon icon={solid('dolly-flatbed')} />,
    type: 'solid',
    value: 'dolly-flatbed' },
  { icon: <FontAwesomeIcon icon={solid('donate')} />,
    type: 'solid',
    value: 'donate' },
  { icon: <FontAwesomeIcon icon={solid('dot-circle')} />,
    type: 'solid',
    value: 'dot-circle' },
  { icon: <FontAwesomeIcon icon={solid('dove')} />,
    type: 'solid',
    value: 'dove' },
  { icon: <FontAwesomeIcon icon={solid('download')} />,
    type: 'solid',
    value: 'download' },
  { icon: <FontAwesomeIcon icon={solid('edit')} />,
    type: 'solid',
    value: 'edit' },
  { icon: <FontAwesomeIcon icon={solid('eject')} />,
    type: 'solid',
    value: 'eject' },
  { icon: <FontAwesomeIcon icon={solid('ellipsis-h')} />,
    type: 'solid',
    value: 'ellipsis-h' },
  { icon: <FontAwesomeIcon icon={solid('ellipsis-v')} />,
    type: 'solid',
    value: 'ellipsis-v' },
  { icon: <FontAwesomeIcon icon={solid('envelope')} />,
    type: 'solid',
    value: 'envelope' },
  { icon: <FontAwesomeIcon icon={solid('envelope-open')} />,
    type: 'solid',
    value: 'envelope-open' },
  { icon: <FontAwesomeIcon icon={solid('envelope-square')} />,
    type: 'solid',
    value: 'envelope-square' },
  { icon: <FontAwesomeIcon icon={solid('eraser')} />,
    type: 'solid',
    value: 'eraser' },
  { icon: <FontAwesomeIcon icon={solid('euro-sign')} />,
    type: 'solid',
    value: 'euro-sign' },
  { icon: <FontAwesomeIcon icon={solid('exchange-alt')} />,
    type: 'solid',
    value: 'exchange-alt' },
  { icon: <FontAwesomeIcon icon={solid('exclamation')} />,
    type: 'solid',
    value: 'exclamation' },
  { icon: <FontAwesomeIcon icon={solid('exclamation-circle')} />,
    type: 'solid',
    value: 'exclamation-circle' },
  { icon: <FontAwesomeIcon icon={solid('exclamation-triangle')} />,
    type: 'solid',
    value: 'exclamation-triangle' },
  { icon: <FontAwesomeIcon icon={solid('expand')} />,
    type: 'solid',
    value: 'expand' },
  { icon: <FontAwesomeIcon icon={solid('expand-arrows-alt')} />,
    type: 'solid',
    value: 'expand-arrows-alt' },
  { icon: <FontAwesomeIcon icon={solid('external-link-alt')} />,
    type: 'solid',
    value: 'external-link-alt' },
  { icon: <FontAwesomeIcon icon={solid('external-link-square-alt')} />,
    type: 'solid',
    value: 'external-link-square-alt' },
  { icon: <FontAwesomeIcon icon={solid('eye')} />,
    type: 'solid',
    value: 'eye' },
  { icon: <FontAwesomeIcon icon={solid('eye-dropper')} />,
    type: 'solid',
    value: 'eye-dropper' },
  { icon: <FontAwesomeIcon icon={solid('eye-slash')} />,
    type: 'solid',
    value: 'eye-slash' },
  { icon: <FontAwesomeIcon icon={solid('fast-backward')} />,
    type: 'solid',
    value: 'fast-backward' },
  { icon: <FontAwesomeIcon icon={solid('fast-forward')} />,
    type: 'solid',
    value: 'fast-forward' },
  { icon: <FontAwesomeIcon icon={solid('fax')} />,
    type: 'solid',
    value: 'fax' },
  { icon: <FontAwesomeIcon icon={solid('female')} />,
    type: 'solid',
    value: 'female' },
  { icon: <FontAwesomeIcon icon={solid('fighter-jet')} />,
    type: 'solid',
    value: 'fighter-jet' },
  { icon: <FontAwesomeIcon icon={solid('file')} />,
    type: 'solid',
    value: 'file' },
  { icon: <FontAwesomeIcon icon={solid('file-alt')} />,
    type: 'solid',
    value: 'file-alt' },
  { icon: <FontAwesomeIcon icon={solid('file-archive')} />,
    type: 'solid',
    value: 'file-archive' },
  { icon: <FontAwesomeIcon icon={solid('file-audio')} />,
    type: 'solid',
    value: 'file-audio' },
  { icon: <FontAwesomeIcon icon={solid('file-code')} />,
    type: 'solid',
    value: 'file-code' },
  { icon: <FontAwesomeIcon icon={solid('file-excel')} />,
    type: 'solid',
    value: 'file-excel' },
  { icon: <FontAwesomeIcon icon={solid('file-image')} />,
    type: 'solid',
    value: 'file-image' },
  { icon: <FontAwesomeIcon icon={solid('file-medical')} />,
    type: 'solid',
    value: 'file-medical' },
  { icon: <FontAwesomeIcon icon={solid('file-medical-alt')} />,
    type: 'solid',
    value: 'file-medical-alt' },
  { icon: <FontAwesomeIcon icon={solid('file-pdf')} />,
    type: 'solid',
    value: 'file-pdf' },
  { icon: <FontAwesomeIcon icon={solid('file-powerpoint')} />,
    type: 'solid',
    value: 'file-powerpoint' },
  { icon: <FontAwesomeIcon icon={solid('file-video')} />,
    type: 'solid',
    value: 'file-video' },
  { icon: <FontAwesomeIcon icon={solid('file-word')} />,
    type: 'solid',
    value: 'file-word' },
  { icon: <FontAwesomeIcon icon={solid('film')} />,
    type: 'solid',
    value: 'film' },
  { icon: <FontAwesomeIcon icon={solid('filter')} />,
    type: 'solid',
    value: 'filter' },
  { icon: <FontAwesomeIcon icon={solid('fire')} />,
    type: 'solid',
    value: 'fire' },
  { icon: <FontAwesomeIcon icon={solid('fire-extinguisher')} />,
    type: 'solid',
    value: 'fire-extinguisher' },
  { icon: <FontAwesomeIcon icon={solid('first-aid')} />,
    type: 'solid',
    value: 'first-aid' },
  { icon: <FontAwesomeIcon icon={solid('flag')} />,
    type: 'solid',
    value: 'flag' },
  { icon: <FontAwesomeIcon icon={solid('flag-checkered')} />,
    type: 'solid',
    value: 'flag-checkered' },
  { icon: <FontAwesomeIcon icon={solid('flask')} />,
    type: 'solid',
    value: 'flask' },
  { icon: <FontAwesomeIcon icon={solid('folder')} />,
    type: 'solid',
    value: 'folder' },
  { icon: <FontAwesomeIcon icon={solid('folder-open')} />,
    type: 'solid',
    value: 'folder-open' },
  { icon: <FontAwesomeIcon icon={solid('font')} />,
    type: 'solid',
    value: 'font' },
  { icon: <FontAwesomeIcon icon={solid('football-ball')} />,
    type: 'solid',
    value: 'football-ball' },
  { icon: <FontAwesomeIcon icon={solid('forward')} />,
    type: 'solid',
    value: 'forward' },
  { icon: <FontAwesomeIcon icon={solid('frown')} />,
    type: 'solid',
    value: 'frown' },
  { icon: <FontAwesomeIcon icon={solid('futbol')} />,
    type: 'solid',
    value: 'futbol' },
  { icon: <FontAwesomeIcon icon={solid('gamepad')} />,
    type: 'solid',
    value: 'gamepad' },
  { icon: <FontAwesomeIcon icon={solid('gavel')} />,
    type: 'solid',
    value: 'gavel' },
  { icon: <FontAwesomeIcon icon={solid('gem')} />,
    type: 'solid',
    value: 'gem' },
  { icon: <FontAwesomeIcon icon={solid('genderless')} />,
    type: 'solid',
    value: 'genderless' },
  { icon: <FontAwesomeIcon icon={solid('gift')} />,
    type: 'solid',
    value: 'gift' },
  { icon: <FontAwesomeIcon icon={solid('glass-martini')} />,
    type: 'solid',
    value: 'glass-martini' },
  { icon: <FontAwesomeIcon icon={solid('globe')} />,
    type: 'solid',
    value: 'globe' },
  { icon: <FontAwesomeIcon icon={solid('golf-ball')} />,
    type: 'solid',
    value: 'golf-ball' },
  { icon: <FontAwesomeIcon icon={solid('graduation-cap')} />,
    type: 'solid',
    value: 'graduation-cap' },
  { icon: <FontAwesomeIcon icon={solid('h-square')} />,
    type: 'solid',
    value: 'h-square' },
  { icon: <FontAwesomeIcon icon={solid('hand-holding')} />,
    type: 'solid',
    value: 'hand-holding' },
  { icon: <FontAwesomeIcon icon={solid('hand-holding-heart')} />,
    type: 'solid',
    value: 'hand-holding-heart' },
  { icon: <FontAwesomeIcon icon={solid('hand-holding-usd')} />,
    type: 'solid',
    value: 'hand-holding-usd' },
  { icon: <FontAwesomeIcon icon={solid('hand-lizard')} />,
    type: 'solid',
    value: 'hand-lizard' },
  { icon: <FontAwesomeIcon icon={solid('hand-paper')} />,
    type: 'solid',
    value: 'hand-paper' },
  { icon: <FontAwesomeIcon icon={solid('hand-peace')} />,
    type: 'solid',
    value: 'hand-peace' },
  { icon: <FontAwesomeIcon icon={solid('hand-point-down')} />,
    type: 'solid',
    value: 'hand-point-down' },
  { icon: <FontAwesomeIcon icon={solid('hand-point-left')} />,
    type: 'solid',
    value: 'hand-point-left' },
  { icon: <FontAwesomeIcon icon={solid('hand-point-right')} />,
    type: 'solid',
    value: 'hand-point-right' },
  { icon: <FontAwesomeIcon icon={solid('hand-point-up')} />,
    type: 'solid',
    value: 'hand-point-up' },
  { icon: <FontAwesomeIcon icon={solid('hand-pointer')} />,
    type: 'solid',
    value: 'hand-pointer' },
  { icon: <FontAwesomeIcon icon={solid('hand-rock')} />,
    type: 'solid',
    value: 'hand-rock' },
  { icon: <FontAwesomeIcon icon={solid('hand-scissors')} />,
    type: 'solid',
    value: 'hand-scissors' },
  { icon: <FontAwesomeIcon icon={solid('hand-spock')} />,
    type: 'solid',
    value: 'hand-spock' },
  { icon: <FontAwesomeIcon icon={solid('hands')} />,
    type: 'solid',
    value: 'hands' },
  { icon: <FontAwesomeIcon icon={solid('hands-helping')} />,
    type: 'solid',
    value: 'hands-helping' },
  { icon: <FontAwesomeIcon icon={solid('handshake')} />,
    type: 'solid',
    value: 'handshake' },
  { icon: <FontAwesomeIcon icon={solid('hashtag')} />,
    type: 'solid',
    value: 'hashtag' },
  { icon: <FontAwesomeIcon icon={solid('hdd')} />,
    type: 'solid',
    value: 'hdd' },
  { icon: <FontAwesomeIcon icon={solid('heading')} />,
    type: 'solid',
    value: 'heading' },
  { icon: <FontAwesomeIcon icon={solid('headphones')} />,
    type: 'solid',
    value: 'headphones' },
  { icon: <FontAwesomeIcon icon={solid('heart')} />,
    type: 'solid',
    value: 'heart' },
  { icon: <FontAwesomeIcon icon={solid('heartbeat')} />,
    type: 'solid',
    value: 'heartbeat' },
  { icon: <FontAwesomeIcon icon={solid('history')} />,
    type: 'solid',
    value: 'history' },
  { icon: <FontAwesomeIcon icon={solid('hockey-puck')} />,
    type: 'solid',
    value: 'hockey-puck' },
  { icon: <FontAwesomeIcon icon={solid('home')} />,
    type: 'solid',
    value: 'home' },
  { icon: <FontAwesomeIcon icon={solid('hospital')} />,
    type: 'solid',
    value: 'hospital' },
  { icon: <FontAwesomeIcon icon={solid('hospital-alt')} />,
    type: 'solid',
    value: 'hospital-alt' },
  { icon: <FontAwesomeIcon icon={solid('hospital-symbol')} />,
    type: 'solid',
    value: 'hospital-symbol' },
  { icon: <FontAwesomeIcon icon={solid('hourglass')} />,
    type: 'solid',
    value: 'hourglass' },
  { icon: <FontAwesomeIcon icon={solid('hourglass-end')} />,
    type: 'solid',
    value: 'hourglass-end' },
  { icon: <FontAwesomeIcon icon={solid('hourglass-half')} />,
    type: 'solid',
    value: 'hourglass-half' },
  { icon: <FontAwesomeIcon icon={solid('hourglass-start')} />,
    type: 'solid',
    value: 'hourglass-start' },
  { icon: <FontAwesomeIcon icon={solid('i-cursor')} />,
    type: 'solid',
    value: 'i-cursor' },
  { icon: <FontAwesomeIcon icon={solid('id-badge')} />,
    type: 'solid',
    value: 'id-badge' },
  { icon: <FontAwesomeIcon icon={solid('id-card')} />,
    type: 'solid',
    value: 'id-card' },
  { icon: <FontAwesomeIcon icon={solid('id-card-alt')} />,
    type: 'solid',
    value: 'id-card-alt' },
  { icon: <FontAwesomeIcon icon={solid('image')} />,
    type: 'solid',
    value: 'image' },
  { icon: <FontAwesomeIcon icon={solid('images')} />,
    type: 'solid',
    value: 'images' },
  { icon: <FontAwesomeIcon icon={solid('inbox')} />,
    type: 'solid',
    value: 'inbox' },
  { icon: <FontAwesomeIcon icon={solid('indent')} />,
    type: 'solid',
    value: 'indent' },
  { icon: <FontAwesomeIcon icon={solid('industry')} />,
    type: 'solid',
    value: 'industry' },
  { icon: <FontAwesomeIcon icon={solid('info')} />,
    type: 'solid',
    value: 'info' },
  { icon: <FontAwesomeIcon icon={solid('info-circle')} />,
    type: 'solid',
    value: 'info-circle' },
  { icon: <FontAwesomeIcon icon={solid('italic')} />,
    type: 'solid',
    value: 'italic' },
  { icon: <FontAwesomeIcon icon={solid('key')} />,
    type: 'solid',
    value: 'key' },
  { icon: <FontAwesomeIcon icon={solid('keyboard')} />,
    type: 'solid',
    value: 'keyboard' },
  { icon: <FontAwesomeIcon icon={solid('language')} />,
    type: 'solid',
    value: 'language' },
  { icon: <FontAwesomeIcon icon={solid('laptop')} />,
    type: 'solid',
    value: 'laptop' },
  { icon: <FontAwesomeIcon icon={solid('leaf')} />,
    type: 'solid',
    value: 'leaf' },
  { icon: <FontAwesomeIcon icon={solid('lemon')} />,
    type: 'solid',
    value: 'lemon' },
  { icon: <FontAwesomeIcon icon={solid('level-down-alt')} />,
    type: 'solid',
    value: 'level-down-alt' },
  { icon: <FontAwesomeIcon icon={solid('level-up-alt')} />,
    type: 'solid',
    value: 'level-up-alt' },
  { icon: <FontAwesomeIcon icon={solid('life-ring')} />,
    type: 'solid',
    value: 'life-ring' },
  { icon: <FontAwesomeIcon icon={solid('lightbulb')} />,
    type: 'solid',
    value: 'lightbulb' },
  { icon: <FontAwesomeIcon icon={solid('link')} />,
    type: 'solid',
    value: 'link' },
  { icon: <FontAwesomeIcon icon={solid('lira-sign')} />,
    type: 'solid',
    value: 'lira-sign' },
  { icon: <FontAwesomeIcon icon={solid('list')} />,
    type: 'solid',
    value: 'list' },
  { icon: <FontAwesomeIcon icon={solid('list-alt')} />,
    type: 'solid',
    value: 'list-alt' },
  { icon: <FontAwesomeIcon icon={solid('list-ol')} />,
    type: 'solid',
    value: 'list-ol' },
  { icon: <FontAwesomeIcon icon={solid('list-ul')} />,
    type: 'solid',
    value: 'list-ul' },
  { icon: <FontAwesomeIcon icon={solid('location-arrow')} />,
    type: 'solid',
    value: 'location-arrow' },
  { icon: <FontAwesomeIcon icon={solid('lock')} />,
    type: 'solid',
    value: 'lock' },
  { icon: <FontAwesomeIcon icon={solid('lock-open')} />,
    type: 'solid',
    value: 'lock-open' },
  { icon: <FontAwesomeIcon icon={solid('long-arrow-alt-down')} />,
    type: 'solid',
    value: 'long-arrow-alt-down' },
  { icon: <FontAwesomeIcon icon={solid('long-arrow-alt-left')} />,
    type: 'solid',
    value: 'long-arrow-alt-left' },
  { icon: <FontAwesomeIcon icon={solid('long-arrow-alt-right')} />,
    type: 'solid',
    value: 'long-arrow-alt-right' },
  { icon: <FontAwesomeIcon icon={solid('long-arrow-alt-up')} />,
    type: 'solid',
    value: 'long-arrow-alt-up' },
  { icon: <FontAwesomeIcon icon={solid('low-vision')} />,
    type: 'solid',
    value: 'low-vision' },
  { icon: <FontAwesomeIcon icon={solid('magic')} />,
    type: 'solid',
    value: 'magic' },
  { icon: <FontAwesomeIcon icon={solid('magnet')} />,
    type: 'solid',
    value: 'magnet' },
  { icon: <FontAwesomeIcon icon={solid('male')} />,
    type: 'solid',
    value: 'male' },
  { icon: <FontAwesomeIcon icon={solid('map')} />,
    type: 'solid',
    value: 'map' },
  { icon: <FontAwesomeIcon icon={solid('map-marker')} />,
    type: 'solid',
    value: 'map-marker' },
  { icon: <FontAwesomeIcon icon={solid('map-marker-alt')} />,
    type: 'solid',
    value: 'map-marker-alt' },
  { icon: <FontAwesomeIcon icon={solid('map-pin')} />,
    type: 'solid',
    value: 'map-pin' },
  { icon: <FontAwesomeIcon icon={solid('map-signs')} />,
    type: 'solid',
    value: 'map-signs' },
  { icon: <FontAwesomeIcon icon={solid('mars')} />,
    type: 'solid',
    value: 'mars' },
  { icon: <FontAwesomeIcon icon={solid('mars-double')} />,
    type: 'solid',
    value: 'mars-double' },
  { icon: <FontAwesomeIcon icon={solid('mars-stroke')} />,
    type: 'solid',
    value: 'mars-stroke' },
  { icon: <FontAwesomeIcon icon={solid('mars-stroke-h')} />,
    type: 'solid',
    value: 'mars-stroke-h' },
  { icon: <FontAwesomeIcon icon={solid('mars-stroke-v')} />,
    type: 'solid',
    value: 'mars-stroke-v' },
  { icon: <FontAwesomeIcon icon={solid('medkit')} />,
    type: 'solid',
    value: 'medkit' },
  { icon: <FontAwesomeIcon icon={solid('meh')} />,
    type: 'solid',
    value: 'meh' },
  { icon: <FontAwesomeIcon icon={solid('mercury')} />,
    type: 'solid',
    value: 'mercury' },
  { icon: <FontAwesomeIcon icon={solid('microchip')} />,
    type: 'solid',
    value: 'microchip' },
  { icon: <FontAwesomeIcon icon={solid('microphone')} />,
    type: 'solid',
    value: 'microphone' },
  { icon: <FontAwesomeIcon icon={solid('microphone-slash')} />,
    type: 'solid',
    value: 'microphone-slash' },
  { icon: <FontAwesomeIcon icon={solid('minus')} />,
    type: 'solid',
    value: 'minus' },
  { icon: <FontAwesomeIcon icon={solid('minus-circle')} />,
    type: 'solid',
    value: 'minus-circle' },
  { icon: <FontAwesomeIcon icon={solid('minus-square')} />,
    type: 'solid',
    value: 'minus-square' },
  { icon: <FontAwesomeIcon icon={solid('mobile')} />,
    type: 'solid',
    value: 'mobile' },
  { icon: <FontAwesomeIcon icon={solid('mobile-alt')} />,
    type: 'solid',
    value: 'mobile-alt' },
  { icon: <FontAwesomeIcon icon={solid('money-bill-alt')} />,
    type: 'solid',
    value: 'money-bill-alt' },
  { icon: <FontAwesomeIcon icon={solid('moon')} />,
    type: 'solid',
    value: 'moon' },
  { icon: <FontAwesomeIcon icon={solid('motorcycle')} />,
    type: 'solid',
    value: 'motorcycle' },
  { icon: <FontAwesomeIcon icon={solid('mouse-pointer')} />,
    type: 'solid',
    value: 'mouse-pointer' },
  { icon: <FontAwesomeIcon icon={solid('music')} />,
    type: 'solid',
    value: 'music' },
  { icon: <FontAwesomeIcon icon={solid('neuter')} />,
    type: 'solid',
    value: 'neuter' },
  { icon: <FontAwesomeIcon icon={solid('newspaper')} />,
    type: 'solid',
    value: 'newspaper' },
  { icon: <FontAwesomeIcon icon={solid('notes-medical')} />,
    type: 'solid',
    value: 'notes-medical' },
  { icon: <FontAwesomeIcon icon={solid('object-group')} />,
    type: 'solid',
    value: 'object-group' },
  { icon: <FontAwesomeIcon icon={solid('object-ungroup')} />,
    type: 'solid',
    value: 'object-ungroup' },
  { icon: <FontAwesomeIcon icon={solid('outdent')} />,
    type: 'solid',
    value: 'outdent' },
  { icon: <FontAwesomeIcon icon={solid('pallet')} />,
    type: 'solid',
    value: 'pallet' },
  { icon: <FontAwesomeIcon icon={solid('paper-plane')} />,
    type: 'solid',
    value: 'paper-plane' },
  { icon: <FontAwesomeIcon icon={solid('paperclip')} />,
    type: 'solid',
    value: 'paperclip' },
  { icon: <FontAwesomeIcon icon={solid('parachute-box')} />,
    type: 'solid',
    value: 'parachute-box' },
  { icon: <FontAwesomeIcon icon={solid('paragraph')} />,
    type: 'solid',
    value: 'paragraph' },
  { icon: <FontAwesomeIcon icon={solid('paste')} />,
    type: 'solid',
    value: 'paste' },
  { icon: <FontAwesomeIcon icon={solid('pause')} />,
    type: 'solid',
    value: 'pause' },
  { icon: <FontAwesomeIcon icon={solid('pause-circle')} />,
    type: 'solid',
    value: 'pause-circle' },
  { icon: <FontAwesomeIcon icon={solid('paw')} />,
    type: 'solid',
    value: 'paw' },
  { icon: <FontAwesomeIcon icon={solid('pen-square')} />,
    type: 'solid',
    value: 'pen-square' },
  { icon: <FontAwesomeIcon icon={solid('pencil-alt')} />,
    type: 'solid',
    value: 'pencil-alt' },
  { icon: <FontAwesomeIcon icon={solid('people-carry')} />,
    type: 'solid',
    value: 'people-carry' },
  { icon: <FontAwesomeIcon icon={solid('percent')} />,
    type: 'solid',
    value: 'percent' },
  { icon: <FontAwesomeIcon icon={solid('phone')} />,
    type: 'solid',
    value: 'phone' },
  { icon: <FontAwesomeIcon icon={solid('phone-slash')} />,
    type: 'solid',
    value: 'phone-slash' },
  { icon: <FontAwesomeIcon icon={solid('phone-square')} />,
    type: 'solid',
    value: 'phone-square' },
  { icon: <FontAwesomeIcon icon={solid('phone-volume')} />,
    type: 'solid',
    value: 'phone-volume' },
  { icon: <FontAwesomeIcon icon={solid('piggy-bank')} />,
    type: 'solid',
    value: 'piggy-bank' },
  { icon: <FontAwesomeIcon icon={solid('pills')} />,
    type: 'solid',
    value: 'pills' },
  { icon: <FontAwesomeIcon icon={solid('plane')} />,
    type: 'solid',
    value: 'plane' },
  { icon: <FontAwesomeIcon icon={solid('play')} />,
    type: 'solid',
    value: 'play' },
  { icon: <FontAwesomeIcon icon={solid('play-circle')} />,
    type: 'solid',
    value: 'play-circle' },
  { icon: <FontAwesomeIcon icon={solid('plug')} />,
    type: 'solid',
    value: 'plug' },
  { icon: <FontAwesomeIcon icon={solid('plus')} />,
    type: 'solid',
    value: 'plus' },
  { icon: <FontAwesomeIcon icon={solid('plus-circle')} />,
    type: 'solid',
    value: 'plus-circle' },
  { icon: <FontAwesomeIcon icon={solid('plus-square')} />,
    type: 'solid',
    value: 'plus-square' },
  { icon: <FontAwesomeIcon icon={solid('podcast')} />,
    type: 'solid',
    value: 'podcast' },
  { icon: <FontAwesomeIcon icon={solid('poo')} />,
    type: 'solid',
    value: 'poo' },
  { icon: <FontAwesomeIcon icon={solid('pound-sign')} />,
    type: 'solid',
    value: 'pound-sign' },
  { icon: <FontAwesomeIcon icon={solid('power-off')} />,
    type: 'solid',
    value: 'power-off' },
  { icon: <FontAwesomeIcon icon={solid('prescription-bottle')} />,
    type: 'solid',
    value: 'prescription-bottle' },
  { icon: <FontAwesomeIcon icon={solid('prescription-bottle-alt')} />,
    type: 'solid',
    value: 'prescription-bottle-alt' },
  { icon: <FontAwesomeIcon icon={solid('print')} />,
    type: 'solid',
    value: 'print' },
  { icon: <FontAwesomeIcon icon={solid('procedures')} />,
    type: 'solid',
    value: 'procedures' },
  { icon: <FontAwesomeIcon icon={solid('puzzle-piece')} />,
    type: 'solid',
    value: 'puzzle-piece' },
  { icon: <FontAwesomeIcon icon={solid('qrcode')} />,
    type: 'solid',
    value: 'qrcode' },
  { icon: <FontAwesomeIcon icon={solid('question')} />,
    type: 'solid',
    value: 'question' },
  { icon: <FontAwesomeIcon icon={solid('question-circle')} />,
    type: 'solid',
    value: 'question-circle' },
  { icon: <FontAwesomeIcon icon={solid('quidditch')} />,
    type: 'solid',
    value: 'quidditch' },
  { icon: <FontAwesomeIcon icon={solid('quote-left')} />,
    type: 'solid',
    value: 'quote-left' },
  { icon: <FontAwesomeIcon icon={solid('quote-right')} />,
    type: 'solid',
    value: 'quote-right' },
  { icon: <FontAwesomeIcon icon={solid('random')} />,
    type: 'solid',
    value: 'random' },
  { icon: <FontAwesomeIcon icon={solid('recycle')} />,
    type: 'solid',
    value: 'recycle' },
  { icon: <FontAwesomeIcon icon={solid('redo')} />,
    type: 'solid',
    value: 'redo' },
  { icon: <FontAwesomeIcon icon={solid('redo-alt')} />,
    type: 'solid',
    value: 'redo-alt' },
  { icon: <FontAwesomeIcon icon={solid('registered')} />,
    type: 'solid',
    value: 'registered' },
  { icon: <FontAwesomeIcon icon={solid('reply')} />,
    type: 'solid',
    value: 'reply' },
  { icon: <FontAwesomeIcon icon={solid('reply-all')} />,
    type: 'solid',
    value: 'reply-all' },
  { icon: <FontAwesomeIcon icon={solid('retweet')} />,
    type: 'solid',
    value: 'retweet' },
  { icon: <FontAwesomeIcon icon={solid('ribbon')} />,
    type: 'solid',
    value: 'ribbon' },
  { icon: <FontAwesomeIcon icon={solid('road')} />,
    type: 'solid',
    value: 'road' },
  { icon: <FontAwesomeIcon icon={solid('rocket')} />,
    type: 'solid',
    value: 'rocket' },
  { icon: <FontAwesomeIcon icon={solid('rss')} />,
    type: 'solid',
    value: 'rss' },
  { icon: <FontAwesomeIcon icon={solid('rss-square')} />,
    type: 'solid',
    value: 'rss-square' },
  { icon: <FontAwesomeIcon icon={solid('ruble-sign')} />,
    type: 'solid',
    value: 'ruble-sign' },
  { icon: <FontAwesomeIcon icon={solid('rupee-sign')} />,
    type: 'solid',
    value: 'rupee-sign' },
  { icon: <FontAwesomeIcon icon={solid('save')} />,
    type: 'solid',
    value: 'save' },
  { icon: <FontAwesomeIcon icon={solid('search')} />,
    type: 'solid',
    value: 'search' },
  { icon: <FontAwesomeIcon icon={solid('search-minus')} />,
    type: 'solid',
    value: 'search-minus' },
  { icon: <FontAwesomeIcon icon={solid('search-plus')} />,
    type: 'solid',
    value: 'search-plus' },
  { icon: <FontAwesomeIcon icon={solid('seedling')} />,
    type: 'solid',
    value: 'seedling' },
  { icon: <FontAwesomeIcon icon={solid('server')} />,
    type: 'solid',
    value: 'server' },
  { icon: <FontAwesomeIcon icon={solid('share')} />,
    type: 'solid',
    value: 'share' },
  { icon: <FontAwesomeIcon icon={solid('share-alt')} />,
    type: 'solid',
    value: 'share-alt' },
  { icon: <FontAwesomeIcon icon={solid('share-alt-square')} />,
    type: 'solid',
    value: 'share-alt-square' },
  { icon: <FontAwesomeIcon icon={solid('share-square')} />,
    type: 'solid',
    value: 'share-square' },
  { icon: <FontAwesomeIcon icon={solid('shekel-sign')} />,
    type: 'solid',
    value: 'shekel-sign' },
  { icon: <FontAwesomeIcon icon={solid('shield-alt')} />,
    type: 'solid',
    value: 'shield-alt' },
  { icon: <FontAwesomeIcon icon={solid('ship')} />,
    type: 'solid',
    value: 'ship' },
  { icon: <FontAwesomeIcon icon={solid('shipping-fast')} />,
    type: 'solid',
    value: 'shipping-fast' },
  { icon: <FontAwesomeIcon icon={solid('shopping-bag')} />,
    type: 'solid',
    value: 'shopping-bag' },
  { icon: <FontAwesomeIcon icon={solid('shopping-basket')} />,
    type: 'solid',
    value: 'shopping-basket' },
  { icon: <FontAwesomeIcon icon={solid('shopping-cart')} />,
    type: 'solid',
    value: 'shopping-cart' },
  { icon: <FontAwesomeIcon icon={solid('shower')} />,
    type: 'solid',
    value: 'shower' },
  { icon: <FontAwesomeIcon icon={solid('sign')} />,
    type: 'solid',
    value: 'sign' },
  { icon: <FontAwesomeIcon icon={solid('sign-in-alt')} />,
    type: 'solid',
    value: 'sign-in-alt' },
  { icon: <FontAwesomeIcon icon={solid('sign-language')} />,
    type: 'solid',
    value: 'sign-language' },
  { icon: <FontAwesomeIcon icon={solid('sign-out-alt')} />,
    type: 'solid',
    value: 'sign-out-alt' },
  { icon: <FontAwesomeIcon icon={solid('signal')} />,
    type: 'solid',
    value: 'signal' },
  { icon: <FontAwesomeIcon icon={solid('sitemap')} />,
    type: 'solid',
    value: 'sitemap' },
  { icon: <FontAwesomeIcon icon={solid('sliders-h')} />,
    type: 'solid',
    value: 'sliders-h' },
  { icon: <FontAwesomeIcon icon={solid('smile')} />,
    type: 'solid',
    value: 'smile' },
  { icon: <FontAwesomeIcon icon={solid('smoking')} />,
    type: 'solid',
    value: 'smoking' },
  { icon: <FontAwesomeIcon icon={solid('snowflake')} />,
    type: 'solid',
    value: 'snowflake' },
  { icon: <FontAwesomeIcon icon={solid('sort')} />,
    type: 'solid',
    value: 'sort' },
  { icon: <FontAwesomeIcon icon={solid('sort-alpha-down')} />,
    type: 'solid',
    value: 'sort-alpha-down' },
  { icon: <FontAwesomeIcon icon={solid('sort-alpha-up')} />,
    type: 'solid',
    value: 'sort-alpha-up' },
  { icon: <FontAwesomeIcon icon={solid('sort-amount-down')} />,
    type: 'solid',
    value: 'sort-amount-down' },
  { icon: <FontAwesomeIcon icon={solid('sort-amount-up')} />,
    type: 'solid',
    value: 'sort-amount-up' },
  { icon: <FontAwesomeIcon icon={solid('sort-down')} />,
    type: 'solid',
    value: 'sort-down' },
  { icon: <FontAwesomeIcon icon={solid('sort-numeric-down')} />,
    type: 'solid',
    value: 'sort-numeric-down' },
  { icon: <FontAwesomeIcon icon={solid('sort-numeric-up')} />,
    type: 'solid',
    value: 'sort-numeric-up' },
  { icon: <FontAwesomeIcon icon={solid('sort-up')} />,
    type: 'solid',
    value: 'sort-up' },
  { icon: <FontAwesomeIcon icon={solid('space-shuttle')} />,
    type: 'solid',
    value: 'space-shuttle' },
  { icon: <FontAwesomeIcon icon={solid('spinner')} />,
    type: 'solid',
    value: 'spinner' },
  { icon: <FontAwesomeIcon icon={solid('square')} />,
    type: 'solid',
    value: 'square' },
  { icon: <FontAwesomeIcon icon={solid('square-full')} />,
    type: 'solid',
    value: 'square-full' },
  { icon: <FontAwesomeIcon icon={solid('star')} />,
    type: 'solid',
    value: 'star' },
  { icon: <FontAwesomeIcon icon={solid('star-half')} />,
    type: 'solid',
    value: 'star-half' },
  { icon: <FontAwesomeIcon icon={solid('step-backward')} />,
    type: 'solid',
    value: 'step-backward' },
  { icon: <FontAwesomeIcon icon={solid('step-forward')} />,
    type: 'solid',
    value: 'step-forward' },
  { icon: <FontAwesomeIcon icon={solid('stethoscope')} />,
    type: 'solid',
    value: 'stethoscope' },
  { icon: <FontAwesomeIcon icon={solid('sticky-note')} />,
    type: 'solid',
    value: 'sticky-note' },
  { icon: <FontAwesomeIcon icon={solid('stop')} />,
    type: 'solid',
    value: 'stop' },
  { icon: <FontAwesomeIcon icon={solid('stop-circle')} />,
    type: 'solid',
    value: 'stop-circle' },
  { icon: <FontAwesomeIcon icon={solid('stopwatch')} />,
    type: 'solid',
    value: 'stopwatch' },
  { icon: <FontAwesomeIcon icon={solid('street-view')} />,
    type: 'solid',
    value: 'street-view' },
  { icon: <FontAwesomeIcon icon={solid('strikethrough')} />,
    type: 'solid',
    value: 'strikethrough' },
  { icon: <FontAwesomeIcon icon={solid('subscript')} />,
    type: 'solid',
    value: 'subscript' },
  { icon: <FontAwesomeIcon icon={solid('subway')} />,
    type: 'solid',
    value: 'subway' },
  { icon: <FontAwesomeIcon icon={solid('suitcase')} />,
    type: 'solid',
    value: 'suitcase' },
  { icon: <FontAwesomeIcon icon={solid('sun')} />,
    type: 'solid',
    value: 'sun' },
  { icon: <FontAwesomeIcon icon={solid('superscript')} />,
    type: 'solid',
    value: 'superscript' },
  { icon: <FontAwesomeIcon icon={solid('sync')} />,
    type: 'solid',
    value: 'sync' },
  { icon: <FontAwesomeIcon icon={solid('sync-alt')} />,
    type: 'solid',
    value: 'sync-alt' },
  { icon: <FontAwesomeIcon icon={solid('syringe')} />,
    type: 'solid',
    value: 'syringe' },
  { icon: <FontAwesomeIcon icon={solid('table')} />,
    type: 'solid',
    value: 'table' },
  { icon: <FontAwesomeIcon icon={solid('table-tennis')} />,
    type: 'solid',
    value: 'table-tennis' },
  { icon: <FontAwesomeIcon icon={solid('tablet')} />,
    type: 'solid',
    value: 'tablet' },
  { icon: <FontAwesomeIcon icon={solid('tablet-alt')} />,
    type: 'solid',
    value: 'tablet-alt' },
  { icon: <FontAwesomeIcon icon={solid('tablets')} />,
    type: 'solid',
    value: 'tablets' },
  { icon: <FontAwesomeIcon icon={solid('tachometer-alt')} />,
    type: 'solid',
    value: 'tachometer-alt' },
  { icon: <FontAwesomeIcon icon={solid('tag')} />,
    type: 'solid',
    value: 'tag' },
  { icon: <FontAwesomeIcon icon={solid('tags')} />,
    type: 'solid',
    value: 'tags' },
  { icon: <FontAwesomeIcon icon={solid('tape')} />,
    type: 'solid',
    value: 'tape' },
  { icon: <FontAwesomeIcon icon={solid('tasks')} />,
    type: 'solid',
    value: 'tasks' },
  { icon: <FontAwesomeIcon icon={solid('taxi')} />,
    type: 'solid',
    value: 'taxi' },
  { icon: <FontAwesomeIcon icon={solid('terminal')} />,
    type: 'solid',
    value: 'terminal' },
  { icon: <FontAwesomeIcon icon={solid('text-height')} />,
    type: 'solid',
    value: 'text-height' },
  { icon: <FontAwesomeIcon icon={solid('text-width')} />,
    type: 'solid',
    value: 'text-width' },
  { icon: <FontAwesomeIcon icon={solid('th')} />,
    type: 'solid',
    value: 'th' },
  { icon: <FontAwesomeIcon icon={solid('th-large')} />,
    type: 'solid',
    value: 'th-large' },
  { icon: <FontAwesomeIcon icon={solid('th-list')} />,
    type: 'solid',
    value: 'th-list' },
  { icon: <FontAwesomeIcon icon={solid('thermometer')} />,
    type: 'solid',
    value: 'thermometer' },
  { icon: <FontAwesomeIcon icon={solid('thermometer-empty')} />,
    type: 'solid',
    value: 'thermometer-empty' },
  { icon: <FontAwesomeIcon icon={solid('thermometer-full')} />,
    type: 'solid',
    value: 'thermometer-full' },
  { icon: <FontAwesomeIcon icon={solid('thermometer-half')} />,
    type: 'solid',
    value: 'thermometer-half' },
  { icon: <FontAwesomeIcon icon={solid('thermometer-quarter')} />,
    type: 'solid',
    value: 'thermometer-quarter' },
  { icon: <FontAwesomeIcon icon={solid('thermometer-three-quarters')} />,
    type: 'solid',
    value: 'thermometer-three-quarters' },
  { icon: <FontAwesomeIcon icon={solid('thumbs-down')} />,
    type: 'solid',
    value: 'thumbs-down' },
  { icon: <FontAwesomeIcon icon={solid('thumbs-up')} />,
    type: 'solid',
    value: 'thumbs-up' },
  { icon: <FontAwesomeIcon icon={solid('thumbtack')} />,
    type: 'solid',
    value: 'thumbtack' },
  { icon: <FontAwesomeIcon icon={solid('ticket-alt')} />,
    type: 'solid',
    value: 'ticket-alt' },
  { icon: <FontAwesomeIcon icon={solid('times')} />,
    type: 'solid',
    value: 'times' },
  { icon: <FontAwesomeIcon icon={solid('times-circle')} />,
    type: 'solid',
    value: 'times-circle' },
  { icon: <FontAwesomeIcon icon={solid('tint')} />,
    type: 'solid',
    value: 'tint' },
  { icon: <FontAwesomeIcon icon={solid('toggle-off')} />,
    type: 'solid',
    value: 'toggle-off' },
  { icon: <FontAwesomeIcon icon={solid('toggle-on')} />,
    type: 'solid',
    value: 'toggle-on' },
  { icon: <FontAwesomeIcon icon={solid('trademark')} />,
    type: 'solid',
    value: 'trademark' },
  { icon: <FontAwesomeIcon icon={solid('train')} />,
    type: 'solid',
    value: 'train' },
  { icon: <FontAwesomeIcon icon={solid('transgender')} />,
    type: 'solid',
    value: 'transgender' },
  { icon: <FontAwesomeIcon icon={solid('transgender-alt')} />,
    type: 'solid',
    value: 'transgender-alt' },
  { icon: <FontAwesomeIcon icon={solid('trash')} />,
    type: 'solid',
    value: 'trash' },
  { icon: <FontAwesomeIcon icon={solid('trash-alt')} />,
    type: 'solid',
    value: 'trash-alt' },
  { icon: <FontAwesomeIcon icon={solid('tree')} />,
    type: 'solid',
    value: 'tree' },
  { icon: <FontAwesomeIcon icon={solid('trophy')} />,
    type: 'solid',
    value: 'trophy' },
  { icon: <FontAwesomeIcon icon={solid('truck')} />,
    type: 'solid',
    value: 'truck' },
  { icon: <FontAwesomeIcon icon={solid('truck-loading')} />,
    type: 'solid',
    value: 'truck-loading' },
  { icon: <FontAwesomeIcon icon={solid('truck-moving')} />,
    type: 'solid',
    value: 'truck-moving' },
  { icon: <FontAwesomeIcon icon={solid('tty')} />,
    type: 'solid',
    value: 'tty' },
  { icon: <FontAwesomeIcon icon={solid('tv')} />,
    type: 'solid',
    value: 'tv' },
  { icon: <FontAwesomeIcon icon={solid('umbrella')} />,
    type: 'solid',
    value: 'umbrella' },
  { icon: <FontAwesomeIcon icon={solid('underline')} />,
    type: 'solid',
    value: 'underline' },
  { icon: <FontAwesomeIcon icon={solid('undo')} />,
    type: 'solid',
    value: 'undo' },
  { icon: <FontAwesomeIcon icon={solid('undo-alt')} />,
    type: 'solid',
    value: 'undo-alt' },
  { icon: <FontAwesomeIcon icon={solid('universal-access')} />,
    type: 'solid',
    value: 'universal-access' },
  { icon: <FontAwesomeIcon icon={solid('university')} />,
    type: 'solid',
    value: 'university' },
  { icon: <FontAwesomeIcon icon={solid('unlink')} />,
    type: 'solid',
    value: 'unlink' },
  { icon: <FontAwesomeIcon icon={solid('unlock')} />,
    type: 'solid',
    value: 'unlock' },
  { icon: <FontAwesomeIcon icon={solid('unlock-alt')} />,
    type: 'solid',
    value: 'unlock-alt' },
  { icon: <FontAwesomeIcon icon={solid('upload')} />,
    type: 'solid',
    value: 'upload' },
  { icon: <FontAwesomeIcon icon={solid('user')} />,
    type: 'solid',
    value: 'user' },
  { icon: <FontAwesomeIcon icon={solid('user-circle')} />,
    type: 'solid',
    value: 'user-circle' },
  { icon: <FontAwesomeIcon icon={solid('user-md')} />,
    type: 'solid',
    value: 'user-md' },
  { icon: <FontAwesomeIcon icon={solid('user-plus')} />,
    type: 'solid',
    value: 'user-plus' },
  { icon: <FontAwesomeIcon icon={solid('user-secret')} />,
    type: 'solid',
    value: 'user-secret' },
  { icon: <FontAwesomeIcon icon={solid('user-times')} />,
    type: 'solid',
    value: 'user-times' },
  { icon: <FontAwesomeIcon icon={solid('users')} />,
    type: 'solid',
    value: 'users' },
  { icon: <FontAwesomeIcon icon={solid('utensil-spoon')} />,
    type: 'solid',
    value: 'utensil-spoon' },
  { icon: <FontAwesomeIcon icon={solid('utensils')} />,
    type: 'solid',
    value: 'utensils' },
  { icon: <FontAwesomeIcon icon={solid('venus')} />,
    type: 'solid',
    value: 'venus' },
  { icon: <FontAwesomeIcon icon={solid('venus-double')} />,
    type: 'solid',
    value: 'venus-double' },
  { icon: <FontAwesomeIcon icon={solid('venus-mars')} />,
    type: 'solid',
    value: 'venus-mars' },
  { icon: <FontAwesomeIcon icon={solid('vial')} />,
    type: 'solid',
    value: 'vial' },
  { icon: <FontAwesomeIcon icon={solid('vials')} />,
    type: 'solid',
    value: 'vials' },
  { icon: <FontAwesomeIcon icon={solid('video')} />,
    type: 'solid',
    value: 'video' },
  { icon: <FontAwesomeIcon icon={solid('video-slash')} />,
    type: 'solid',
    value: 'video-slash' },
  { icon: <FontAwesomeIcon icon={solid('volleyball-ball')} />,
    type: 'solid',
    value: 'volleyball-ball' },
  { icon: <FontAwesomeIcon icon={solid('volume-down')} />,
    type: 'solid',
    value: 'volume-down' },
  { icon: <FontAwesomeIcon icon={solid('volume-off')} />,
    type: 'solid',
    value: 'volume-off' },
  { icon: <FontAwesomeIcon icon={solid('volume-up')} />,
    type: 'solid',
    value: 'volume-up' },
  { icon: <FontAwesomeIcon icon={solid('warehouse')} />,
    type: 'solid',
    value: 'warehouse' },
  { icon: <FontAwesomeIcon icon={solid('weight')} />,
    type: 'solid',
    value: 'weight' },
  { icon: <FontAwesomeIcon icon={solid('wheelchair')} />,
    type: 'solid',
    value: 'wheelchair' },
  { icon: <FontAwesomeIcon icon={solid('wifi')} />,
    type: 'solid',
    value: 'wifi' },
  { icon: <FontAwesomeIcon icon={solid('window-close')} />,
    type: 'solid',
    value: 'window-close' },
  { icon: <FontAwesomeIcon icon={solid('window-maximize')} />,
    type: 'solid',
    value: 'window-maximize' },
  { icon: <FontAwesomeIcon icon={solid('window-minimize')} />,
    type: 'solid',
    value: 'window-minimize' },
  { icon: <FontAwesomeIcon icon={solid('window-restore')} />,
    type: 'solid',
    value: 'window-restore' },
  { icon: <FontAwesomeIcon icon={solid('wine-glass')} />,
    type: 'solid',
    value: 'wine-glass' },
  { icon: <FontAwesomeIcon icon={solid('won-sign')} />,
    type: 'solid',
    value: 'won-sign' },
  { icon: <FontAwesomeIcon icon={solid('wrench')} />,
    type: 'solid',
    value: 'wrench' },
  { icon: <FontAwesomeIcon icon={solid('x-ray')} />,
    type: 'solid',
    value: 'x-ray' },
  { icon: <FontAwesomeIcon icon={solid('yen-sign')} />,
    type: 'solid',
    value: 'yen-sign' },
  { icon: <FontAwesomeIcon icon={regular('address-book')} />,
    type: 'regular',
    value: 'address-book' },
  { icon: <FontAwesomeIcon icon={regular('address-card')} />,
    type: 'regular',
    value: 'address-card' },
  { icon: <FontAwesomeIcon icon={regular('arrow-alt-circle-down')} />,
    type: 'regular',
    value: 'arrow-alt-circle-down' },
  { icon: <FontAwesomeIcon icon={regular('arrow-alt-circle-left')} />,
    type: 'regular',
    value: 'arrow-alt-circle-left' },
  { icon: <FontAwesomeIcon icon={regular('arrow-alt-circle-right')} />,
    type: 'regular',
    value: 'arrow-alt-circle-right' },
  { icon: <FontAwesomeIcon icon={regular('arrow-alt-circle-up')} />,
    type: 'regular',
    value: 'arrow-alt-circle-up' },
  { icon: <FontAwesomeIcon icon={regular('bell')} />,
    type: 'regular',
    value: 'bell' },
  { icon: <FontAwesomeIcon icon={regular('bell-slash')} />,
    type: 'regular',
    value: 'bell-slash' },
  { icon: <FontAwesomeIcon icon={regular('bookmark')} />,
    type: 'regular',
    value: 'bookmark' },
  { icon: <FontAwesomeIcon icon={regular('building')} />,
    type: 'regular',
    value: 'building' },
  { icon: <FontAwesomeIcon icon={regular('calendar')} />,
    type: 'regular',
    value: 'calendar' },
  { icon: <FontAwesomeIcon icon={regular('calendar-alt')} />,
    type: 'regular',
    value: 'calendar-alt' },
  { icon: <FontAwesomeIcon icon={regular('calendar-check')} />,
    type: 'regular',
    value: 'calendar-check' },
  { icon: <FontAwesomeIcon icon={regular('calendar-minus')} />,
    type: 'regular',
    value: 'calendar-minus' },
  { icon: <FontAwesomeIcon icon={regular('calendar-plus')} />,
    type: 'regular',
    value: 'calendar-plus' },
  { icon: <FontAwesomeIcon icon={regular('calendar-times')} />,
    type: 'regular',
    value: 'calendar-times' },
  { icon: <FontAwesomeIcon icon={regular('caret-square-down')} />,
    type: 'regular',
    value: 'caret-square-down' },
  { icon: <FontAwesomeIcon icon={regular('caret-square-left')} />,
    type: 'regular',
    value: 'caret-square-left' },
  { icon: <FontAwesomeIcon icon={regular('caret-square-right')} />,
    type: 'regular',
    value: 'caret-square-right' },
  { icon: <FontAwesomeIcon icon={regular('caret-square-up')} />,
    type: 'regular',
    value: 'caret-square-up' },
  { icon: <FontAwesomeIcon icon={regular('chart-bar')} />,
    type: 'regular',
    value: 'chart-bar' },
  { icon: <FontAwesomeIcon icon={regular('check-circle')} />,
    type: 'regular',
    value: 'check-circle' },
  { icon: <FontAwesomeIcon icon={regular('check-square')} />,
    type: 'regular',
    value: 'check-square' },
  { icon: <FontAwesomeIcon icon={regular('circle')} />,
    type: 'regular',
    value: 'circle' },
  { icon: <FontAwesomeIcon icon={regular('clipboard')} />,
    type: 'regular',
    value: 'clipboard' },
  { icon: <FontAwesomeIcon icon={regular('clock')} />,
    type: 'regular',
    value: 'clock' },
  { icon: <FontAwesomeIcon icon={regular('clone')} />,
    type: 'regular',
    value: 'clone' },
  { icon: <FontAwesomeIcon icon={regular('closed-captioning')} />,
    type: 'regular',
    value: 'closed-captioning' },
  { icon: <FontAwesomeIcon icon={regular('comment')} />,
    type: 'regular',
    value: 'comment' },
  { icon: <FontAwesomeIcon icon={regular('comment-alt')} />,
    type: 'regular',
    value: 'comment-alt' },
  { icon: <FontAwesomeIcon icon={regular('comments')} />,
    type: 'regular',
    value: 'comments' },
  { icon: <FontAwesomeIcon icon={regular('compass')} />,
    type: 'regular',
    value: 'compass' },
  { icon: <FontAwesomeIcon icon={regular('copy')} />,
    type: 'regular',
    value: 'copy' },
  { icon: <FontAwesomeIcon icon={regular('copyright')} />,
    type: 'regular',
    value: 'copyright' },
  { icon: <FontAwesomeIcon icon={regular('credit-card')} />,
    type: 'regular',
    value: 'credit-card' },
  { icon: <FontAwesomeIcon icon={regular('dot-circle')} />,
    type: 'regular',
    value: 'dot-circle' },
  { icon: <FontAwesomeIcon icon={regular('edit')} />,
    type: 'regular',
    value: 'edit' },
  { icon: <FontAwesomeIcon icon={regular('envelope')} />,
    type: 'regular',
    value: 'envelope' },
  { icon: <FontAwesomeIcon icon={regular('envelope-open')} />,
    type: 'regular',
    value: 'envelope-open' },
  { icon: <FontAwesomeIcon icon={regular('eye-slash')} />,
    type: 'regular',
    value: 'eye-slash' },
  { icon: <FontAwesomeIcon icon={regular('file')} />,
    type: 'regular',
    value: 'file' },
  { icon: <FontAwesomeIcon icon={regular('file-alt')} />,
    type: 'regular',
    value: 'file-alt' },
  { icon: <FontAwesomeIcon icon={regular('file-archive')} />,
    type: 'regular',
    value: 'file-archive' },
  { icon: <FontAwesomeIcon icon={regular('file-audio')} />,
    type: 'regular',
    value: 'file-audio' },
  { icon: <FontAwesomeIcon icon={regular('file-code')} />,
    type: 'regular',
    value: 'file-code' },
  { icon: <FontAwesomeIcon icon={regular('file-excel')} />,
    type: 'regular',
    value: 'file-excel' },
  { icon: <FontAwesomeIcon icon={regular('file-image')} />,
    type: 'regular',
    value: 'file-image' },
  { icon: <FontAwesomeIcon icon={regular('file-pdf')} />,
    type: 'regular',
    value: 'file-pdf' },
  { icon: <FontAwesomeIcon icon={regular('file-powerpoint')} />,
    type: 'regular',
    value: 'file-powerpoint' },
  { icon: <FontAwesomeIcon icon={regular('file-video')} />,
    type: 'regular',
    value: 'file-video' },
  { icon: <FontAwesomeIcon icon={regular('file-word')} />,
    type: 'regular',
    value: 'file-word' },
  { icon: <FontAwesomeIcon icon={regular('flag')} />,
    type: 'regular',
    value: 'flag' },
  { icon: <FontAwesomeIcon icon={regular('folder')} />,
    type: 'regular',
    value: 'folder' },
  { icon: <FontAwesomeIcon icon={regular('folder-open')} />,
    type: 'regular',
    value: 'folder-open' },
  { icon: <FontAwesomeIcon icon={regular('frown')} />,
    type: 'regular',
    value: 'frown' },
  { icon: <FontAwesomeIcon icon={regular('futbol')} />,
    type: 'regular',
    value: 'futbol' },
  { icon: <FontAwesomeIcon icon={regular('gem')} />,
    type: 'regular',
    value: 'gem' },
  { icon: <FontAwesomeIcon icon={regular('hand-lizard')} />,
    type: 'regular',
    value: 'hand-lizard' },
  { icon: <FontAwesomeIcon icon={regular('hand-paper')} />,
    type: 'regular',
    value: 'hand-paper' },
  { icon: <FontAwesomeIcon icon={regular('hand-peace')} />,
    type: 'regular',
    value: 'hand-peace' },
  { icon: <FontAwesomeIcon icon={regular('hand-point-down')} />,
    type: 'regular',
    value: 'hand-point-down' },
  { icon: <FontAwesomeIcon icon={regular('hand-point-left')} />,
    type: 'regular',
    value: 'hand-point-left' },
  { icon: <FontAwesomeIcon icon={regular('hand-point-right')} />,
    type: 'regular',
    value: 'hand-point-right' },
  { icon: <FontAwesomeIcon icon={regular('hand-point-up')} />,
    type: 'regular',
    value: 'hand-point-up' },
  { icon: <FontAwesomeIcon icon={regular('hand-pointer')} />,
    type: 'regular',
    value: 'hand-pointer' },
  { icon: <FontAwesomeIcon icon={regular('hand-rock')} />,
    type: 'regular',
    value: 'hand-rock' },
  { icon: <FontAwesomeIcon icon={regular('hand-scissors')} />,
    type: 'regular',
    value: 'hand-scissors' },
  { icon: <FontAwesomeIcon icon={regular('hand-spock')} />,
    type: 'regular',
    value: 'hand-spock' },
  { icon: <FontAwesomeIcon icon={regular('handshake')} />,
    type: 'regular',
    value: 'handshake' },
  { icon: <FontAwesomeIcon icon={regular('hdd')} />,
    type: 'regular',
    value: 'hdd' },
  { icon: <FontAwesomeIcon icon={regular('heart')} />,
    type: 'regular',
    value: 'heart' },
  { icon: <FontAwesomeIcon icon={regular('hospital')} />,
    type: 'regular',
    value: 'hospital' },
  { icon: <FontAwesomeIcon icon={regular('hourglass')} />,
    type: 'regular',
    value: 'hourglass' },
  { icon: <FontAwesomeIcon icon={regular('id-badge')} />,
    type: 'regular',
    value: 'id-badge' },
  { icon: <FontAwesomeIcon icon={regular('id-card')} />,
    type: 'regular',
    value: 'id-card' },
  { icon: <FontAwesomeIcon icon={regular('image')} />,
    type: 'regular',
    value: 'image' },
  { icon: <FontAwesomeIcon icon={regular('images')} />,
    type: 'regular',
    value: 'images' },
  { icon: <FontAwesomeIcon icon={regular('keyboard')} />,
    type: 'regular',
    value: 'keyboard' },
  { icon: <FontAwesomeIcon icon={regular('lemon')} />,
    type: 'regular',
    value: 'lemon' },
  { icon: <FontAwesomeIcon icon={regular('life-ring')} />,
    type: 'regular',
    value: 'life-ring' },
  { icon: <FontAwesomeIcon icon={regular('lightbulb')} />,
    type: 'regular',
    value: 'lightbulb' },
  { icon: <FontAwesomeIcon icon={regular('list-alt')} />,
    type: 'regular',
    value: 'list-alt' },
  { icon: <FontAwesomeIcon icon={regular('map')} />,
    type: 'regular',
    value: 'map' },
  { icon: <FontAwesomeIcon icon={regular('meh')} />,
    type: 'regular',
    value: 'meh' },
  { icon: <FontAwesomeIcon icon={regular('minus-square')} />,
    type: 'regular',
    value: 'minus-square' },
  { icon: <FontAwesomeIcon icon={regular('money-bill-alt')} />,
    type: 'regular',
    value: 'money-bill-alt' },
  { icon: <FontAwesomeIcon icon={regular('moon')} />,
    type: 'regular',
    value: 'moon' },
  { icon: <FontAwesomeIcon icon={regular('newspaper')} />,
    type: 'regular',
    value: 'newspaper' },
  { icon: <FontAwesomeIcon icon={regular('object-group')} />,
    type: 'regular',
    value: 'object-group' },
  { icon: <FontAwesomeIcon icon={regular('object-ungroup')} />,
    type: 'regular',
    value: 'object-ungroup' },
  { icon: <FontAwesomeIcon icon={regular('paper-plane')} />,
    type: 'regular',
    value: 'paper-plane' },
  { icon: <FontAwesomeIcon icon={regular('pause-circle')} />,
    type: 'regular',
    value: 'pause-circle' },
  { icon: <FontAwesomeIcon icon={regular('play-circle')} />,
    type: 'regular',
    value: 'play-circle' },
  { icon: <FontAwesomeIcon icon={regular('plus-square')} />,
    type: 'regular',
    value: 'plus-square' },
  { icon: <FontAwesomeIcon icon={regular('question-circle')} />,
    type: 'regular',
    value: 'question-circle' },
  { icon: <FontAwesomeIcon icon={regular('registered')} />,
    type: 'regular',
    value: 'registered' },
  { icon: <FontAwesomeIcon icon={regular('save')} />,
    type: 'regular',
    value: 'save' },
  { icon: <FontAwesomeIcon icon={regular('share-square')} />,
    type: 'regular',
    value: 'share-square' },
  { icon: <FontAwesomeIcon icon={regular('smile')} />,
    type: 'regular',
    value: 'smile' },
  { icon: <FontAwesomeIcon icon={regular('snowflake')} />,
    type: 'regular',
    value: 'snowflake' },
  { icon: <FontAwesomeIcon icon={regular('square')} />,
    type: 'regular',
    value: 'square' },
  { icon: <FontAwesomeIcon icon={regular('star')} />,
    type: 'regular',
    value: 'star' },
  { icon: <FontAwesomeIcon icon={regular('star-half')} />,
    type: 'regular',
    value: 'star-half' },
  { icon: <FontAwesomeIcon icon={regular('sticky-note')} />,
    type: 'regular',
    value: 'sticky-note' },
  { icon: <FontAwesomeIcon icon={regular('stop-circle')} />,
    type: 'regular',
    value: 'stop-circle' },
  { icon: <FontAwesomeIcon icon={regular('sun')} />,
    type: 'regular',
    value: 'sun' },
  { icon: <FontAwesomeIcon icon={regular('thumbs-down')} />,
    type: 'regular',
    value: 'thumbs-down' },
  { icon: <FontAwesomeIcon icon={regular('thumbs-up')} />,
    type: 'regular',
    value: 'thumbs-up' },
  { icon: <FontAwesomeIcon icon={regular('times-circle')} />,
    type: 'regular',
    value: 'times-circle' },
  { icon: <FontAwesomeIcon icon={regular('trash-alt')} />,
    type: 'regular',
    value: 'trash-alt' },
  { icon: <FontAwesomeIcon icon={regular('user')} />,
    type: 'regular',
    value: 'user' },
  { icon: <FontAwesomeIcon icon={regular('user-circle')} />,
    type: 'regular',
    value: 'user-circle' },
  { icon: <FontAwesomeIcon icon={regular('window-close')} />,
    type: 'regular',
    value: 'window-close' },
  { icon: <FontAwesomeIcon icon={regular('window-maximize')} />,
    type: 'regular',
    value: 'window-maximize' },
  { icon: <FontAwesomeIcon icon={regular('window-minimize')} />,
    type: 'regular',
    value: 'window-minimize' },
  { icon: <FontAwesomeIcon icon={regular('window-restore')} />,
    type: 'regular',
    value: 'window-restore' }
]
