import React, { useState, useEffect, useContext, useRef } from "react";

import { createStyles, Button, Box } from "@mantine/core";

import { useSelector, useDispatch } from "react-redux";

import NodeItem from "./NodeItem";
import SidebarTitle from "./SidebarTitle";
import SidebarTutorial from "./SidebarTutorial";
import SidebarTemplate from "./SidebarTemplate";
import MembersNode from "./MembersNode";
import NewSpaceNode from "./NewSpaceNode";

import SpaceModel from "../../../models/Space";

import defaultSpaceColors from "../../../collections/defaultSpaceColors";
import useSidebarStyles from "../../../utils/SidebarStyles";
import isSpaceThemed from "../../../utils/IsSpaceThemed";

import tinycolor from "tinycolor2";

import { ActionCableContext } from "../../../components/Index";

import Broadcaster from "../../../helpers/Broadcaster";

const useStyles = createStyles((theme, { colors, settingsView }) => ({
  sidebarContainer: {
    width: theme.other.sideFrameWidth,
    backgroundColor: !settingsView
      ? colors.sidebar_background_color
      : defaultSpaceColors.sidebar_background_color,
    minHeight: "calc(100vh - 60px)",
    borderRight: `1px solid ${
      isSpaceThemed(colors)
        ? "none"
        : !settingsView
        ? tinycolor(colors.sidebar_background_color).darken(5).toString()
        : "none"
    }`,
    paddingBottom: 100,
  },
  settingsView: {
    backgroundColor: theme.colors.gray[7],
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.colors.gray[7],
    },
  },
}));

let newTree = null;
let activeStateSidebarUpdater = null;
let openedNodes = [];

export default function Sidebar(props) {
  const spaceTree = useSelector((state) => state.tree);

  const mainActions = useSelector((state) => state.mainActions);

  const colors = useSelector((state) => state.colors);
  const { settingsView } = useSidebarStyles();
  const { classes, cx } = useStyles({ colors, settingsView });

  const cable = useContext(ActionCableContext);
  const [channel, setChannel] = useState(null);

  const dispatch = useDispatch();

  const interactiveTimeout = useRef();
  const lastInteractivePos = useRef();
  const trimmedTree = useRef();

  // New version
  const currentTopSlug = useRef();
  const currentTree = useRef();
  const [tree, setTree] = useState(null);

  useEffect(() => {
    // onFetchLocalStorage()
    window.$findNodeInTreeBySlug = findNodeInTreeBySlug;
  }, []);

  useEffect(() => {
    if (spaceTree) {
      if (
        !tree ||
        (tree && spaceTree.slug != tree.slug) ||
        window.$forceTreeUpdate
      ) {
        window.$forceTreeUpdate = false;
        window.$sidebarTree = { ...spaceTree };
        reRenderTree();
      }

      return () => {
        if (channel) {
          clearInterval(interactiveTimeout.current);
          interactiveTimeout.current = null;
        }
      };
    }
  }, [spaceTree]); //tree

  const reRenderTree = () => {
    trimmedTree.current = null;
    const auxTree = { ...spaceTree };
    setTree(auxTree);
    currentTree.current = { ...auxTree };
  };

  const onCloseTutorial = () => {
    const auxTree = { ...currentTree.current };
    auxTree.tutorial = null;
    onUpdateTreeData(auxTree);
  };

  const onUpdateTreeData = (node) => {
    const auxTree = { ...currentTree.current };

    if (auxTree.slug == node.slug) {
      setTree({ ...node });
      currentTree.current = { ...node };
      return;
    }

    findNodeInTreeBySlug(auxTree, node.slug, node, true);

    setTree(auxTree);
    currentTree.current = { ...auxTree };
  };

  const onUpdate = (node, prevNode, targetNode, afterNode, position) => {
    // API update on server

    const params = {
      moving_slug: node.slug,
      moving_nodes: !node.slug ? node : null,
      parent_a: {
        slug: prevNode.slug,
        children: prevNode.children.map((c) => c.slug),
      },
      parent_b: {
        slug: targetNode.slug,
        children: targetNode.children.map((c) => c.slug),
      },
      sibling: {
        slug: afterNode ? afterNode.slug : null,
        position: position,
      },
    };

    SpaceModel.onUpdateTree(
      params,
      (data) => {},
      (data) => {}
    );
  };

  const findNodeInTreeBySlug = (
    _tree = null,
    slug = null,
    updateWith = null,
    all = false
  ) => {
    if (!_tree) {
      _tree = tree;
    }
    if (!slug) {
      slug = spaceTree.slug;
    }

    if (_tree.slug == slug) {
      if (updateWith) {
        if (_tree.children && !all) {
          const oldChildren = _tree.children;
          if (oldChildren) {
            updateWith.children = [...oldChildren];
          }
        }

        _tree = updateWith;
      }
      return _tree;
    } else {
      if (_tree.children) {
        for (let i = 0; i < _tree.children.length; i++) {
          if (_tree.children[i] && _tree.children[i].slug == slug) {
            if (updateWith) {
              const oldChildren = _tree.children[i].children;
              if (oldChildren && !all) {
                updateWith.children = [...oldChildren];
              }
              _tree.children[i] = updateWith;
            }

            return _tree.children[i];
          } else {
            if (!_tree.children[i]) {
              return false;
            }
            const found = findNodeInTreeBySlug(
              _tree.children[i],
              slug,
              updateWith,
              all
            );
            if (found) {
              return found;
            }
          }
        }
      }
    }
  };

  return (
    <div className={classes.sidebarContainer}>
      {tree ? (
        <SidebarTitle
          slug={tree.slug}
          version={tree.version}
          name={tree.name}
          loading={tree.loading}
        />
      ) : (
        ""
      )}
      {tree &&
      tree.tutorial &&
      tree.permissions[window.$currentRole].can_manage ? (
        tree.pod_state != "model" ? (
          <SidebarTutorial slug={tree.slug} onCloseTutorial={onCloseTutorial} />
        ) : (
          <SidebarTemplate slug={tree.slug} />
        )
      ) : (
        ""
      )}
      <div className="sidebar-tree">
        {tree &&
          tree.settings &&
          tree.settings.show_members &&
          tree.pod_state != "model" && <MembersNode slug={tree.slug} />}
        {tree ? (
          <NodeItem
            current={spaceTree.slug}
            parent={null}
            node={tree}
            opened={true}
            onTreeUpdate={onUpdate}
            findNodeInTreeBySlug={findNodeInTreeBySlug}
            onUpdateTreeData={onUpdateTreeData}
            pos={0}
            editable={mainActions.editPage}
          />
        ) : (
          ""
        )}
        <NewSpaceNode />
      </div>
    </div>
  );
}
