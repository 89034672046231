import React, { useRef, useState, useEffect } from "react";

import {
  Text,
  Box,
  createStyles,
  TextInput,
  Textarea,
  Progress,
  Button,
  Image,
  MantineProvider,
  Group,
} from "@mantine/core";

import Uploady, {
  useItemProgressListener,
  useItemFinishListener,
} from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import SpaceSettingModel from "../../../../models/SpaceSetting";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    width: "100%",
  },
}));

const podSizeMin = 0;
const podSizeMax = 1000;

const LogProgress = (props) => {
  const [progress, setProgress] = useState(0);

  useItemProgressListener((item) => {
    setProgress(item.completed);
  });
  useItemFinishListener((item) => {
    props.onUploadCallback(item.uploadResponse.data);
  });

  return progress > 0 && progress < 100 ? <Progress value={progress} /> : "";
};

const CustomUploadButton = asUploadButton((props) => {
  const { t, i18n } = useTranslation();
  return (
    <Button
      {...props}
      variant="default"
      color="green"
      radius="xl"
      mt={20}
      mb={10}
    >
      Select image
    </Button>
  );
});

export default function Seo(props) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const space = useSelector((state) => state.space);

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [disableActions, setDisableActions] = useState("");

  const handlers = useRef();
  const propsLoaded = useRef();
  const lastSpaceId = useRef();
  const saveTimeoutId = useRef();

  useEffect(() => {
    return () => {
      propsLoaded.current = false;
    };
  }, []);

  useEffect(() => {
    if (!space.slug) {
      return;
    }
    if (propsLoaded.current && space.slug == lastSpaceId.current) {
      return;
    }

    propsLoaded.current = false;
    // setDisableActions(true);
    lastSpaceId.current = space.slug;

    setMetaTitle(space.settings.meta_title);
    setMetaDescription(space.settings.meta_description);
    setMetaImage(space.settings.meta_image);

    setTimeout(() => {
      propsLoaded.current = true;
      // setDisableActions(false);
    }, 1000);
  }, [space]);

  useEffect(() => {
    if (!propsLoaded.current) {
      return;
    }
    if (saveTimeoutId.current) {
      clearTimeout(saveTimeoutId.current);
    }

    saveTimeoutId.current = setTimeout(() => {
      props.onUpdate({
        pod_settings_attributes: {
          meta_description: metaDescription,
          meta_title: metaTitle,
        },
      });
    }, 1000);
  }, [metaDescription, metaTitle]);

  const onImageUploaded = (data) => {
    setMetaImage(data.url);
  };

  const onRemoveMetaImage = () => {
    SpaceSettingModel.onRemoveMetaImage(
      space.slug,
      (data) => {
        setMetaImage(null);
      },
      (data) => {}
    );
  };

  return (
    <Box>
      <Box className={classes.item}>
        <Text className={classes.itemTitle}>
          {t("spaces.settings.main.seo.title.title")}
        </Text>
        <Text className={classes.itemDescription}>
          {t("spaces.settings.main.seo.title.description")}
        </Text>
        <TextInput
          placeholder={space.slug && space.name}
          mt={10}
          className={classes.input}
          disabled={disableActions}
          value={metaTitle}
          onChange={(event) => setMetaTitle(event.currentTarget.value)}
        />
      </Box>

      <Box className={classes.item}>
        <Text className={classes.itemTitle}>
          {t("spaces.settings.main.seo.description.title")}
        </Text>
        <Text className={classes.itemDescription}>
          {t("spaces.settings.main.seo.description.description")}
        </Text>
        <Textarea
          mt={10}
          className={classes.input}
          disabled={disableActions}
          value={metaDescription}
          onChange={(event) => setMetaDescription(event.currentTarget.value)}
        />
      </Box>
      {space && space.slug && space.settings && (
        <Box className={classes.item}>
          <Text className={classes.itemTitle}>
            {t("spaces.settings.main.seo.image.title")}
          </Text>
          <Text className={classes.itemDescription}>
            {t("spaces.settings.main.seo.image.description")}
          </Text>

          {metaImage && (
            <Image
              mt={10}
              radius={5}
              width={264}
              src={metaImage}
              alt={metaTitle}
            />
          )}
          <Group>
            <Uploady
              destination={{
                url: `/api/v4/spaces/${space.slug}/settings/upload_meta_image.json?auth_token=${window.$currentUserAuthToken}&locale=${window.$currentUserLanguage}`,
              }}
            >
              <CustomUploadButton />
              <LogProgress onUploadCallback={onImageUploaded} />
            </Uploady>
            {metaImage && (
              <MantineProvider theme={{ primaryShade: 6 }}>
                <Button
                  mt={10}
                  variant="filled"
                  color="gray"
                  radius="xl"
                  leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
                  onClick={() => onRemoveMetaImage()}
                >
                  {"Remove image"}
                </Button>
              </MantineProvider>
            )}
          </Group>
        </Box>
      )}
    </Box>
  );
}
