import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import tinycolor from "tinycolor2";

import { Drawer, Box, Text, ScrollArea } from "@mantine/core";

import Form from "../../resources/pages/Form";

import { setTiptapSidemenu } from "../../../store/app";

const NewPage = (props) => {
  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);

  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "newPage") {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "newPage") {
        if (tiptap.editor){
          tiptap.editor.commands.focus();
        }
        window.$closingSideMenu = true;
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    }
  }, [opened]);

  const onSave = (resourceId, resourceLink) => {
    // const oldAttrs = { ...tiptapSidemenu.options };
    window.$reloadResourceId = tiptapSidemenu.option.resourceId
    tiptap.editor.chain().focus().updateAttributes("id", tiptapSidemenu.option.resourceId).blur().run();

    setTimeout(() => {
      setOpened(false);
    }, 250);
  };

  return (
    <Drawer
      position="left"
      opened={opened}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={() => setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {tiptapSidemenu.option && tiptapSidemenu.option.page
            ? `Edit page`
            : `New page`}
        </Text>
      }
      padding="xl"
      size={700}
      // overlayOpacity={0.1}
      styles={{
        drawer: {
          backgroundColor: colors.background_color,
          color: colors.default_text_color,
        },
        closeButton: {
          backgroundColor: colors.background_color,
          color: colors.default_text_color,
          "&:hover": {
            backgroundColor: tinycolor(colors.background_color)
              .lighten(10)
              .toString(),
          },
        },
      }}
      zIndex={99999}
    >
      <ScrollArea style={{ height: "100%" }}>
        <Form
          spaceId={space.slug}
          resourceId={
            tiptapSidemenu.option && tiptapSidemenu.option.resourceId
              ? tiptapSidemenu.option.resourceId
              : null
          }
          page={
            tiptapSidemenu.option && tiptapSidemenu.option.page
              ? tiptapSidemenu.option.page
              : null
          }
          onSave={onSave}
        />
        <Box mt={100} />
      </ScrollArea>
    </Drawer>
  );
};

export default NewPage;
