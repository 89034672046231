import React, { useState, useEffect } from "react";

import { Text, Group, Stack, Progress, Checkbox, Box } from "@mantine/core";

import Avatar from "../../../utils/LazyAvatar";

import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";

import {setTiptapSidemenu} from '../../../../store/app';

export default function ParticipationActivity(props) {
  const [member, setMember] = useState({});
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setMember(props.member);
  }, [props]);

  const openProfile = () => {
    dispatch(setTiptapSidemenu({ opened: "profile", option: member.username}));
  }

  const capToMinValue = (value) => {
    if (value < 20) {
      return 20;
    }
    return value;
  };

  const getProgress = () => {
    if (props.showWords) {
      return {
        value: capToMinValue(
          (member.words_count * 100) / props.counts.max_words_count
        ),
        color: "orange",
        label: <Box pl={10} pr={10}>{`${member.words_count} words`}</Box>,
      };
    } else {
      return {
        value: capToMinValue(
          (member.posts_count * 100) / props.counts.max_posts_count
        ),
        color: "yellow",
        label: <Box pl={10} pr={10}>{`${member.posts_count} ${t("spaces.settings.members.participationActivityRow.posts")}`}</Box>,
      };
    }

    // return [
    //   {
    //     value: props.counts ? member.avg_post_size * 100 / props.counts.max_words_count / 2 : 0,
    //     color: "orange",
    //     label: `${member.avg_post_size} words`
    //   },
    //   {
    //     value: props.counts ? member.posts_count * 100 / props.counts.max_posts_count / 2 : 0,
    //     color: "yellow",
    //     label: `${member.posts_count} posts`,
    //   }
    // ]
  };

  return (
    <tr>
      <td style={{ width: "20px" }}>
        {window.$currentUserId != member.user_id && (
          <Checkbox
            checked={props.isSelected(member.username)}
            onChange={(event) => props.onSelect(member.username)}
          />
        )}
      </td>
      <td style={{ width: "200px" }}>
        <Group
          spacing="sm"
          onClick={() => openProfile()}
          sx={{
            color: "#000",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          noWrap
        >
          <Avatar
            size={30}
            src={member && member.avatar ? member.avatar.url : ""}
            radius={30}
          />
          <Stack spacing="0">
            <Text size="sm" weight={500}>
              {member.full_name}
            </Text>
            <Text size="xs" color="dimmed" mt={-3}>
              @{member.username}
            </Text>
          </Stack>
        </Group>
      </td>

      <td>
        {/* <Progress size="xl" radius="xl" sections={getProgress()} /> */}
        <Progress size="xl" radius="xl" {...getProgress()} />
      </td>
    </tr>
  );
}
