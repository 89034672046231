// import axios from 'axios';

class Step {
  constructor() {
    if (this instanceof Step) {
      throw Error('Step class cannot be instantiated.');
    }
  }

  static onFetch = async (slug, trigger_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      trigger_id
    }

    await window.$api.get(`/api/v4/spaces/${slug}/engagement_steps.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onCreate = async (slug, trigger_id, step, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      trigger_id,
      step
    }

    await window.$api.post(`/api/v4/spaces/${slug}/engagement_steps.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onUpdate = async (step_id, slug, trigger_id, step, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      trigger_id,
      step
    }

    await window.$api.put(`/api/v4/spaces/${slug}/engagement_steps/${step_id}.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onReorder = async (slug, trigger_id, steps, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      trigger_id,
      steps
    }

    await window.$api.post(`/api/v4/spaces/${slug}/engagement_steps/reorder.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDestroy = async (step_id, slug, trigger_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      trigger_id
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/engagement_steps/${step_id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default Step;
