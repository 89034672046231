import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Button, Group, Tooltip, createStyles } from "@mantine/core";

import { useElementSize } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

import Notifications from "./Notifications";
import DirectMessages from "./DirectMessages";
import Uploader from "./Uploader";
import Search from "./Search";
import UserMenu from "./UserMenu";

import isSpaceThemed from "../../utils/IsSpaceThemed";
import useSidebarStyles from "../../utils/SidebarStyles";
import defaultSpaceColors from "../../collections/defaultSpaceColors";


import tinycolor from "tinycolor2";

const useStyles = createStyles((theme, { colors }) => ({
  primaryButtonStyle: {
    backgroundColor: isSpaceThemed(colors)
      ? colors.primary_button_background_color
      : theme.colors.gray[2],
    color: isSpaceThemed(colors) ? colors.primary_button_text_color : "#000000",
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.primary_button_background_color).darken(4).toString()
        : theme.colors.gray[1],
    },
  },
}));

export default function UserButton(props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { ref, width, height } = useElementSize();
  // const theme = useMantineTheme();

  const { settingsView, registrationView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  colors = !settingsView && !registrationView ? colors : defaultSpaceColors;

  const { classes, cx, theme } = useStyles({ colors });

  const trimUsername = () => {
    const username = props.username;
    return username.length > 12 ? username.substring(0, 12) + "..." : username;
  };

  const goToHome = () => {
    navigate(`/home/top`);
  };

  if (!props.authenticated) {
    return null;
  }

  return (
    <Group noWrap spacing={10} position="right">
      {!(window.$isTouchDevice && window.$isTouchDevice()) && (
        <>
          <Tooltip disabled={window.$isTouchDevice && window.$isTouchDevice()} label="Home" withArrow>
            <Button
              variant="light"
              color="dark"
              radius="xl"
              onClick={() => {
                goToHome();
              }}
              className={classes.primaryButtonStyle}
            >
              <FontAwesomeIcon icon={solid("house")} />
            </Button>
          </Tooltip>
          <DirectMessages />
          <Notifications />
          <Uploader />
        </>
      )}
      <Search />
      <UserMenu image={props.image} username={props.username} />
    </Group>
  );
}
