import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import Tiptap from "../../../tiptap/components/Tiptap";
import { useTranslation } from "react-i18next";

import { HocuspocusProvider } from "@hocuspocus/provider";

import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import HeaderImage from "../../HeaderImage";
import FixedHeader from "../../FixedHeader";
import Loading from "../static/Loading";

import {
  Stack,
  ScrollArea,
  createStyles,
  Notification,
  Container,
} from "@mantine/core";

import { getHocuspocusHost, getEnv } from "../../../../utils/Host";

import * as Y from "yjs";

import trackMixpanel from "../../../../utils/TrackMixpanel";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color,
    },
    ".ProseMirror .file-node svg": {
      stroke: colors.default_text_color,
    },
    ".ProseMirror .file-node a": {
      color: colors.default_text_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.sidebar_text_color,
      backgroundColor: colors.sidebar_background_color,
      
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.default_text_color,
      backgroundColor: isSpaceThemed(colors)
        ? isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(8).toString()
          : tinycolor(colors.background_color).darken(8).toString()
        : tinycolor(colors.background_color).darken(2).toString(),
      border: isSpaceThemed(colors)
        ? `1px solid ${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(20).toString()
              : tinycolor(colors.background_color).darken(20).toString()
          }`
        : `1px solid #e9ecef`,
        "&:hover": {
          // backgroundColor: theme.colors.gray[0],
          backgroundColor: isSpaceThemed(colors)
            ? (isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(10).toString()
              : tinycolor(colors.background_color).darken(10).toString())
            : tinycolor(colors.background_color).darken(2).toString(),
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
          cursor: "pointer",
          // color: "#000",
          textDecoration: "none",
        },
    },
  },
}));

let disconnectInterval = null;

const Content = memo((props) => {
  const { t, i18n } = useTranslation();

  const [loaded, setLoaded] = useState();

  const [forcedDisconnected, setForceDisconnect] = useState(false);
  const [unsaveChanges, setUnsaveChanges] = useState(0);

  const provider = useRef({
    provider: null,
    status: null,
    documentName: null,
    editable: false,
    unSyncCount: 0,
    forcedDisconnected: false,
  });

  const user = useSelector((state) => state.user);
  const colors = useSelector((state) => state.colors);

  const params = useParams();
  const navigate = useNavigate();

  const { classes, cx, theme } = useStyles({ colors });

  const viewport = useRef();
  const connectionInterval = useRef();
  const tiptapKey = useRef(`tiptap-${new Date()}`);

  const helpScouteLoaded = useRef();

  useEffect(() => {
    return () => {
      window.Beacon("destroy");
      helpScouteLoaded.current = false;
      unMount();
    };
  }, []);

  useEffect(() => {
    window.$contentScrollViewport = viewport.current;
  }, [viewport.current]);

  useEffect(() => {
    if (user && user.authenticated) {
      trackMixpanel(user.username, "Content edited");
      if (!helpScouteLoaded.current && user.username) {
        helpScouteLoaded.current = true;
        setTimeout(() => {
          window.Beacon("init", "8baee135-5597-476a-8269-cb1460d638e4");
          window.Beacon("identify", {
            name: user.name,
            email: user.email,
            account_type: user.account_type,
            // avatar: user.avatar ? user.avatar.url : "",
          });
        }, 2000);
      }
    }
  }, [user]);

  useEffect(() => {
    if (props.hideLayout) {
      props.hideLayout(window.$hiddenLayoutElements);
      setTimeout(() => {
        props.hideLayout(window.$hiddenLayoutElements);
      }, 500);
    }

    const newDocumentName = `circle-content-${params.slug}`;

    if (newDocumentName != provider.current.documentName) {
      provider.current.documentName = `circle-content-${params.slug}`;

      resetProvider();
    }
  }, [params]);

  const unMount = () => {
    provider.current.documentName = null;
    provider.current.status = null;
    provider.current.editable = false;
    clearProvider();
  };

  const onStatusChange = useCallback((status) => {
    if (status == "connect") {
    } else if (status == "synced") {
      console.log("3. PROVIDER synced: ");

      setTimeout(() => {
        setLoaded(true);
      }, 250);
    } else if (status == "disconnect") {
      console.log("PROVIDER disconnect: ");

      setForceDisconnect(true);
      setUnsaveChanges(provider.current.provider.unsyncedChanges);

      reconnector();
    } else if (status == "destroy") {
    } else if (status == "status") {
    }
  });

  const reconnector = () => {
    if (connectionInterval.current) {
      clearInterval(connectionInterval.current);
    }

    connectionInterval.current = setInterval(() => {
      console.log("PROVIDER reconnecting: " + provider.current.provider.status);
      if (
        provider.current.provider.status == "connected" ||
        provider.current.provider.isSynced
      ) {
        console.log("PROVIDER reconnecting OK: ");
        setForceDisconnect(false);
        clearInterval(connectionInterval.current);
      }
      provider.current.provider.connect();
    }, 1000);
  };

  const clearProvider = () => {
    if (provider.current.provider) {
      provider.current.provider.off("destroy");
      provider.current.provider.off("disconnect");
      provider.current.provider.off("connect");
      provider.current.provider.off("synced");

      provider.current.provider.disconnect();
      provider.current.provider.destroy();
    }
    tiptapKey.current = `tiptap-${new Date()}`;
  };

  const resetProvider = () => {
    const yDocument = new Y.Doc();

    clearProvider();

    provider.current.provider = new HocuspocusProvider({
      url: getHocuspocusHost(),
      name: provider.current.documentName,
      document: yDocument,
      token: user.authentication_token,
      broadcast: getEnv() == "production",
      delay: 1000,
      factor: 0,
      forceSyncInterval: 1000,
    });

    provider.current.provider.on("status", () => {
      onStatusChange("status");
    });
    provider.current.provider.on("connect", () => {
      onStatusChange("connect");
    });
    provider.current.provider.on("synced", () => {
      onStatusChange("synced");
    });

    provider.current.provider.on("disconnect", () => {
      onStatusChange("disconnect");
    });

    provider.current.provider.on("destroy", () => {
      onStatusChange("destroy");
    });
  };

  if (!loaded) {
    return <Loading type="content" />;
  }

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea
        id="main-scroll-area"
        className="content-scrollview"
        style={{ height: "100%" }}
        viewportRef={viewport}
      >
        <HeaderImage />
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={true}
          components={[
            "breadcrumbs",
            "title",
            "calendar",
            "status",
            "interactions",
            "tag",
          ]}
        />
        {forcedDisconnected && (
          <Container size={window.$spaceWideWidth == "wide" ? 960 : 801}>
            <Notification
              title={t("spaces.presentations.fallback.title")}
              color="red"
            >
              {t("spaces.presentations.fallback.message")}
            </Notification>
          </Container>
        )}

        <Tiptap
          key={tiptapKey.current}
          userName={user.username}
          editable={true}
          spaceId={params.slug}
          spaceWidth={window.$spaceWideWidth}
          provider={provider.current.provider}
          allowComments={true}
          viewId={"tiptap"}
        />
      </ScrollArea>
    </Stack>
  );
});

export default Content;
