import React, { useState, useEffect, useRef } from "react";

import {
  Text,
  Box,
  Group,
  Button,
  Drawer,
  TextInput,
  SegmentedControl,
  Switch,
  MultiSelect,
  Select,
  Divider,
  ScrollArea,
  Loader,
  Alert,
  MantineProvider
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DatePicker, TimeInput } from "@mantine/dates";

import { useForm } from "@mantine/form";

import Swal from "sweetalert2";

import ManagerModel from "../../../../models/Manager";
import BroadcastModel from "../../../../models/Broadcast";
import SpaceModel from "../../../../models/Space";
import Premium from "../../../app/Premium";
import TiptapInline from "../../../tiptap/components/TiptapInline";

export default function ComposeBroadcast(props) {
  const [scheduled, setScheduled] = useState(false);
  const { t, i18n } = useTranslation();
  const [managers, setManagers] = useState([]);
  const [sender, setSender] = useState("");

  const [spaces, setSpaces] = useState([]);
  const [searching, setSearching] = useState(false);
  const searchTimeoutId = useRef();
  const fixedUsers = useRef();
  const [loading, setLoading] = useState(false);

  const [canSendMessage, setCanSendMessage] = useState([]);

  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);

  const form = useForm({
    initialValues: {
      title: "",
      content: "",
      send_on_date: "",
      send_date: new Date(new Date().getTime() + 86400000),
      send_time: new Date(new Date().getTime() + 86400000),
      // send_to: ["active"],
      target: [],
      user_id: "",
      broadcast_type: "email",
    },

    validate: {
      content: (value) =>
        value.length > 0
          ? null
          : t("spaces.settings.broadcast.form.contentErrMessage"),
      target: (value) =>
        value.length > 0
          ? null
          : t("spaces.settings.broadcast.form.targetErrMessage"),
    },
  });

  useEffect(() => {
    onFetchManagers();
    onFetchSpaces();
    setCanSendMessage(
      space && space.permissions && space.permissions.can_send_message_broadcast
    );
  }, [space]);

  useEffect(() => {
    form.setFieldValue("user_id", sender);
  }, [sender]);

  useEffect(() => {
    if (props.broadcast && props.broadcast.id) {
      form.setFieldValue("title", props.broadcast.title);
      form.setFieldValue("content_json", props.broadcast.content_json);
      form.setFieldValue("content", props.broadcast.content);
      form.setFieldValue("send_on_date", props.broadcast.send_on_date);
      form.setFieldValue("target", props.broadcast.target);
      if (props.broadcast.send_time) {
        setScheduled(true);
        form.setFieldValue("send_date", new Date(props.broadcast.send_time));
        form.setFieldValue("send_time", new Date(props.broadcast.send_time));
      }
      if (props.broadcast.user && props.broadcast.user.id) {
        setSender(props.broadcast.user.id);
      }
      if (props.broadcast.broadcast_type) {
        form.setFieldValue("broadcast_type", props.broadcast.broadcast_type);
      }
      // form.setFieldValue("send_to", new Date(props.broadcast.send_time))
    } else {
      onResetForm();

      if (props.sendTo) {
        form.setFieldValue("send_to", props.sendTo);
      }
      if (props.sendToUsers && props.sendToUsers.length > 0) {
        const usersIds = props.sendToUsers.map((user) => user.username);
        form.setFieldValue("target", usersIds);
        fixedUsers.current = true;
      } else {
        form.setFieldValue("target", []);
        fixedUsers.current = false;
      }

      if (props.broadcastType) {
        form.setFieldValue("broadcast_type", props.broadcastType);
      }
    }
  }, [props]);

  const getSendToData = () => {
    if (fixedUsers.current) {
      return props.sendToUsers.map((user) => ({
        value: user.username,
        label: user.full_name,
      }));
    }

    const activeLabel = props.isTopLevel
      ? t("spaces.settings.broadcast.membersTypes.active")
      : `${t("spaces.settings.broadcast.membersTypes.active")} in ${
          props.parentName
        }`;

    const pendingLabel = props.isTopLevel
      ? t("spaces.settings.broadcast.membersTypes.pending")
      : `${t("spaces.settings.broadcast.membersTypes.pending")} in ${
          props.parentName
        }`;

    const interestedLabel = props.isTopLevel
      ? t("spaces.settings.broadcast.membersTypes.interested")
      : `${t("spaces.settings.broadcast.membersTypes.interested")} in ${
          props.parentName
        }`;

    const interestedOnlyLabel = props.isTopLevel
      ? "Interested people who are not registered"
      : `Interested people who are not registered in ${props.parentName}`;

    const array = [
      { value: "active", label: activeLabel },
      { value: "pending", label: pendingLabel },
      { value: "interested", label: interestedLabel },
      { value: "interested-only", label: interestedOnlyLabel },
    ];

    if (form.values.broadcast_type == "message") {
      array.splice(2, 1);

      for (let i = 0; i < spaces.length; i++) {
        array.push(spaces[i]);
      }
    }

    return array;
  };

  const onFetchSpaces = (query = null) => {
    const include = ["members", "simple"];
    if (query) {
      include.push(`q=${query}`);
    }

    SpaceModel.onFetchChildren(
      space.slug,
      include,
      { page: 1, per: 10 },
      ["active", "not_journey", "not_interactive"],
      (data) => {
        setSpaces(
          data.data.map((item) => ({ label: item.name, value: item.slug }))
        );
      },
      (data) => {}
    );
  };

  const onSearchSpaces = (query) => {
    setSearching(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query);
      setSearching(false);
    }, 1000);
  };

  const onResetForm = () => {
    form.setFieldValue("title", "");
    form.setFieldValue("content", "");
    form.setFieldValue("send_on_date", false);
    form.setFieldValue("send_date", new Date(new Date().getTime() + 86400000));
    form.setFieldValue("send_time", new Date(new Date().getTime() + 86400000));

    if (user && user.authenticated) {
      setSender(user.id);
    }
  };

  const onFetchManagers = () => {
    ManagerModel.onFetch(
      space.slug,
      { page: 1, per: 1000 },
      {},
      (data) => {
        const _managers = data.data.map((manager) => {
          return {
            label: `${manager.full_name} (${manager.username})`,
            value: `${manager.user_id}`,
          };
        });

        setManagers(_managers);
      },
      (data) => {}
    );
  };

  const onSend = () => {
    onSave("");
  };

  const onSaveDraft = () => {
    onSave("draft");
  };

  const onSave = (status = null) => {
    if (loading) {
      return;
    }

    setLoading(true);

    const send_time = mergeDateAndTime(
      form.values.send_date,
      form.values.send_time
    );

    if (!status && scheduled) {
      status = "scheduled";
    }

    const params = {
      ...form.values,
      send_on_date: status == "scheduled",
      send_time: status == "scheduled" ? send_time : null,
      status,
    };
    if (props.broadcast && props.broadcast.id) {
      BroadcastModel.onUpdate(
        props.broadcast.id,
        space.slug,
        params,
        (data) => {
          Swal.fire(data.title, "", "success");
          onUpdate();
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        },
        (data) => {
          Swal.fire(data.title, data.message, "error");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      );
    } else {
      BroadcastModel.onCreate(
        space.slug,
        params,
        (data) => {
          Swal.fire(data.title, "", "success");
          onUpdate();
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        },
        (data) => {
          Swal.fire(data.title, data.message, "error");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      );
    }
  };

  const onUpdate = () => {
    props.setOpened(false);

    props.onUpdate();
  };

  const mergeDateAndTime = (date, time) => {
    try {
      if (date && time) {
        const _date = new Date(date);
        const _time = new Date(time);

        const merged = new Date(
          `${_date.toLocaleDateStringAbs()}, ${_time.toLocaleTimeString()}`
        ).toISOString();

        return `${merged}`;
      } else if (date) {
        return `${date.toISOString()}`;
      }
    } catch {
      return new Date().toISOString();
    }
  };

  const onContentUpdate = (content, html, callback, isEmpty) => {
    form.setFieldValue("content_json", content.content);
    form.setFieldValue("content", html);
  };

  return (
    <Drawer
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {t("spaces.settings.broadcast.form.composeBroadcast")}
        </Text>
      }
      padding="xl"
      size={600}
      offsetScrollbars
    >
      {!props.isEmailReady ? (
        <Alert color="green" radius="md" mt={15}>
          <Text>
            {"You are currently on a free trial and cannot send broadcasts through Sutra. Email broadcasts can be sent through our system once your first account payment is processed or by making a request to support@sutra.co"}
          </Text>
        </Alert>
      ) : (
        <ScrollArea style={{ height: "100%" }}>
          <form onSubmit={form.onSubmit((values) => onSend(values))}>
            <SegmentedControl
              mb={20}
              fullWidth
              {...form.getInputProps("broadcast_type")}
              data={[
                {
                  label: t("spaces.settings.broadcast.form.type.email"),
                  value: "email",
                },
                {
                  label: t("spaces.settings.broadcast.form.type.message"),
                  value: "message",
                },
              ]}
            />
            {form.values.broadcast_type == "message" && !canSendMessage[0] ? (
              <Box>
                <Premium
                  opened={[true, canSendMessage[1], canSendMessage[2]]}
                />
              </Box>
            ) : (
              <Box>
                <TextInput
                  radius="sm"
                  mb={20}
                  size="md"
                  {...form.getInputProps("title")}
                  placeholder={
                    form.values.broadcast_type == "email"
                      ? t("spaces.settings.broadcast.form.title.placeholder")
                      : "Subject (optional)"
                  }
                  description={
                    form.values.broadcast_type == "email"
                      ? ""
                      : "The subject will only show if you are broadcasting to a forum space."
                  }
                />

                <TiptapInline
                  editable={true}
                  content={props.broadcast ? props.broadcast.content_json : ""}
                  modals={true}
                  onUpdate={onContentUpdate}
                  exclude={
                    form.values.broadcast_type == "message"
                      ? []
                      : ["video", "embed", "link-action", "link-format"]
                  }
                />
                {form.values.broadcast_type == "email" && (
                  <Text mt={10} size="xs">
                    {`${t(
                      "spaces.settings.broadcast.form.brackets.part1"
                    )} {{first_name}}, {{last_name}}, {{start_date}}, {{space_name}}, {{space_link}}, ${t(
                      "spaces.settings.broadcast.form.brackets.part2"
                    )} {{space_name_link}} ${t(
                      "spaces.settings.broadcast.form.brackets.part3"
                    )}`}
                  </Text>
                )}
                {form.values.broadcast_type == "message" &&
                  fixedUsers.current && (
                    <Text mt={10} size="xs">
                      {`${t(
                        "spaces.settings.broadcast.form.brackets.part1"
                      )} {{first_name}}, {{last_name}} ${t(
                        "spaces.settings.broadcast.form.brackets.part3"
                      )}`}
                    </Text>
                  )}

                <MultiSelect
                  icon={
                    searching ? (
                      <Loader size="xs" />
                    ) : (
                      <FontAwesomeIcon icon={solid("search")} />
                    )
                  }
                  {...form.getInputProps("target")}
                  radius="sm"
                  size="md"
                  mt={15}
                  searchable
                  data={getSendToData()}
                  label={t("spaces.settings.broadcast.form.target.label")}
                  placeholder={t(
                    "spaces.settings.broadcast.form.target.placeholder"
                  )}
                  defaultValue={["active"]}
                  maxDropdownHeight={400}
                  onSearchChange={(query) => onSearchSpaces(query)}
                  styles={{
                    label: { whiteSpace: "break-spaces" },
                    value: { height: "auto" },
                  }}
                />

                <Select
                  radius="sm"
                  size="md"
                  value={`${sender}`}
                  onChange={setSender}
                  mt={15}
                  data={managers}
                  label={t("spaces.settings.broadcast.form.sendAs.label")}
                  placeholder={t(
                    "spaces.settings.broadcast.form.sendAs.placeholder"
                  )}
                />

                <Switch
                  mt={20}
                  label={t("spaces.settings.broadcast.form.schedule.label")}
                  size="lg"
                  onLabel="ON"
                  offLabel="OFF"
                  styles={{
                    label: { fontSize: 14, fontWeight: 500 },
                  }}
                  checked={scheduled}
                  onChange={(event) =>
                    setScheduled(event.currentTarget.checked)
                  }
                />
                {scheduled && (
                  <Group grow mt={10}>
                    <DatePicker
                      {...form.getInputProps("send_date")}
                      placeholder={t(
                        "spaces.settings.broadcast.form.sendAtDate.placeholder"
                      )}
                      label={t(
                        "spaces.settings.broadcast.form.sendAtDate.label"
                      )}
                      radius="sm"
                      size="md"
                    />
                    <TimeInput
                      {...form.getInputProps("send_time")}
                      label={t(
                        "spaces.settings.broadcast.form.sendAtTime.label"
                      )}
                      format="12"
                      radius="sm"
                      size="md"
                    />
                  </Group>
                )}

                <Divider
                  mt={15}
                  sx={(theme) => ({
                    borderTop: `1px solid ${theme.colors.gray[2]}`,
                  })}
                />
                <Group mt={15} position="apart">
                  <Button
                    disabled={loading}
                    radius="xl"
                    // size="md"
                    leftIcon={<FontAwesomeIcon icon={solid("paper-plane")} />}
                    type="submit"
                  >
                    {scheduled
                      ? t("spaces.settings.broadcast.form.scheduleBroadcast")
                      : t("spaces.settings.broadcast.form.sendBroadcast")}
                  </Button>
                  {!fixedUsers.current && (
                    <Button
                      disabled={loading}
                      radius="xl"
                      color="dark"
                      // size="md"
                      leftIcon={<FontAwesomeIcon icon={solid("check")} />}
                      onClick={() => onSaveDraft()}
                    >
                      {t("spaces.settings.broadcast.form.saveDraft")}
                    </Button>
                  )}
                </Group>
              </Box>
            )}
          </form>
          <Box mt={100} />
        </ScrollArea>
      )}
    </Drawer>
  );
}
