import React, { useState, useEffect } from "react";

import { Text, Box, Drawer, ScrollArea } from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AddSimple from "./steps/AddSimple";
import EditEmail from "./steps/EditEmail";
import EditMessage from "./steps/EditMessage";
import AddToExistingSpace from "./steps/AddToExistingSpace";
import AddToNewSpace from "./steps/AddToNewSpace";
import AddFromSubSpace from "./steps/AddFromSubSpace";
import EditStructure from "./steps/EditStructure";
import ApplyTemplate from "./steps/ApplyTemplate";
import SetToPending from "./steps/SetToPending";
import EditSurvey from "./steps/EditSurvey";
import Webhook from "./steps/Webhook";
import Wait from "./steps/Wait";
import StepModel from "../../../../models/Step";

export default function EditStep(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const [action, setAction] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (props && props.step) {
      setAction(props.step.action);
      setName(props.step.name);
    }
  }, [props]);

  const onUpdate = (params, closeSettings = true) => {
    StepModel.onUpdate(
      props.step.id,
      space.slug,
      props.step.journey_id,
      { action: props.step.action, position: props.step.position, ...params },
      (data) => {
        if (closeSettings) {
          props.setOpened(false);
          setTimeout(() => props.onSettingsChange(), 1000);
        }
      },
      (data) => {}
    );
  };

  const displayStepSettings = () => {
    switch (action) {
      case "send_email":
        return (
          <EditEmail
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            trigger={props.trigger}
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "send_message":
        return (
          <EditMessage
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            trigger={props.trigger}
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_to_existing_space":
        return (
          <AddToExistingSpace
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_to_new_space":
        return (
          <AddToNewSpace
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_from_sub_space":
        return (
          <AddFromSubSpace
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_structure":
        return (
          <EditStructure
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "apply_template":
        return (
          <ApplyTemplate
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "set_to_pending":
        return (
          <SetToPending
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "survey_person":
        return (
          <EditSurvey
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            opened={props.opened}
            setOpened={props.setOpened}
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "webhook":
        return (
          <Webhook
            webhookId={props.step && props.step.object_id}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "wait":
        return (
          <Wait
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "wait_until":
        return (
          <Wait
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "wait_until_structure_complete":
        return (
          <Wait
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      default:
        return (
          <AddSimple
            step={props.step}
            onUpdate={onUpdate}
            onDestroy={(event) => props.onDestroy(event, props.step)}
            mode="edit"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
    }
  };

  return (
    <Drawer
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {name}
        </Text>
      }
      padding="xl"
      size={600}
      offsetScrollbars
    >
      <ScrollArea
        style={{
          marginTop: -20,
          height: "100%",
        }}
      >
        {displayStepSettings()}
        <Box mt={100} />
      </ScrollArea>
    </Drawer>
  );
}
