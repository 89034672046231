import React, { useState, useEffect, useRef } from "react";

import {
  Text,
  Box,
  Drawer,
  Select,
  ScrollArea,
  Stack,
  Group,
  Button,
  MultiSelect,
  Divider,
  Switch,
  Loader,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { DatePicker, TimeInput } from "@mantine/dates";

import StructureModel from "../../../../models/Structure";
import SpaceModel from "../../../../models/Space";
import ManagerModel from "../../../../models/Manager";

import Swal from "sweetalert2";

export default function Add(props) {
  const { t, i18n } = useTranslation();

  const [sender, setSender] = useState("");
  const [scheduled, setScheduled] = useState("");
  const [managers, setManagers] = useState([]);

  const [spaces, setSpaces] = useState([]);
  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const [searching, setSearching] = useState(false);

  const searchTimeoutId = useRef();

  const form = useForm({
    initialValues: {
      pod_structures: "",
      user_id: "",
      start_on: "",
      start_date: "",
      start_time: "",
      // structure_message: ""
    },

    validate: {
      pod_structures: (value) => (value.length > 0 ? null : "Select a space"),
      user_id: (value) => (value.length > 0 ? null : "Select a sender"),
      // structure_message: (value) => value.length > 0 ? null : "Write a message",
    },
  });

  useEffect(() => {
    if (props.spaceId) {
      onFetchSpaces();
      onFetchManagers();
    }
  }, [props]);

  const onFetchSpaces = (query = null) => {
    const include = ["members", "simple"];
    if (query) {
      include.push(`q=${query}`);
    }

    SpaceModel.onFetchChildren(
      props.spaceId,
      include,
      { page: 1, per: 10 },
      ["active", "not_journey", "discussions"],
      (data) => {
        setSpaces(
          data.data.map((item) => ({ label: item.name, value: item.slug }))
        );
      },
      (data) => {}
    );
  };

  const onFetchManagers = () => {
    ManagerModel.onFetch(
      props.spaceId,
      { page: 1, per: 1000 },
      {},
      (data) => {
        const _managers = data.data.map((manager) => {
          return { label: manager.full_name, value: `${manager.user_id}` };
        });

        setManagers(_managers);
      },
      (data) => {}
    );
  };

  const onSave = () => {
    const start_time = mergeDateAndTime(
      form.values.start_date,
      form.values.start_time
    );

    const params = {
      ...form.values,
      start_time,
      start_on: start_time ? "data" : "now",
    };

    StructureModel.onAddToSpace(
      props.structure.id,
      props.spaceId,
      params,
      (data) => {
        Swal.fire(data.title, data.message, "success");
        props.setOpened(false);
        onResetForm();
      },
      (data) => {}
    );
  };

  const mergeDateAndTime = (date, time) => {
    if (date && time) {
      const _date = new Date(date);
      const _time = new Date(time);

      const merged = new Date(
        `${_date.toLocaleDateStringAbs()}, ${_time.toLocaleTimeString()}`
      ).toISOString();

      return `${merged}`;
    } else if (date) {
      return `${date.toISOString()}`;
    }
  };

  const onResetForm = () => {
    form.setFieldValue("pod_structures", "");
    form.setFieldValue("user_id", "");
    form.setFieldValue("start_on", false);
    form.setFieldValue("start_date", "");
    form.setFieldValue("start_time", "");
    // form.setFieldValue("structure_message", "")
  };

  const onSearchSpaces = (query) => {
    setSearching(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query);
      setSearching(false);
    }, 1000);
  };

  return (
    <Drawer
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {t("spaces.settings.structure.add.add.title")}
        </Text>
      }
      padding="xl"
      size={600}
    >
      <form onSubmit={form.onSubmit((values) => onSave(values))}>
        <Stack justify="space-between">
          <ScrollArea style={{ height: "100%" }}>
            {/*<Textarea
              {...form.getInputProps('structure_message')}
              mt={10}
              autosize
              minRows={4}
              placeholder="Write something..."
              />*/}

            <Box mt={10}>
              <Text size="sm">{t("spaces.settings..add.add.description")}</Text>
              <MultiSelect
                icon={
                  searching ? (
                    <Loader size="xs" />
                  ) : (
                    <FontAwesomeIcon icon={solid("search")} />
                  )
                }
                {...form.getInputProps("pod_structures")}
                searchable
                mt={5}
                data={spaces}
                placeholder={t(
                  "spaces.settings.structure.add.add.placeholder1"
                )}
                maxDropdownHeight={400}
                onSearchChange={(query) => onSearchSpaces(query)}
              />
            </Box>
            <Divider
              mt={20}
              sx={(theme) => ({
                borderTop: `1px solid ${theme.colors.gray[2]}`,
              })}
            />

            <Select
              {...form.getInputProps("user_id")}
              mt={15}
              data={managers}
              label={t("spaces.settings.structure.add.add.label1")}
              placeholder={t("spaces.settings.structure.add.add.placeholder2")}
            />
            <Divider
              mt={20}
              sx={(theme) => ({
                borderTop: `1px solid ${theme.colors.gray[2]}`,
              })}
            />
            <Switch
              mt={20}
              label={t("spaces.settings.structure.add.add.label4")}
              size="lg"
              onLabel="ON"
              offLabel="OFF"
              styles={{
                label: { fontSize: 14, fontWeight: 500 },
              }}
              checked={scheduled}
              onChange={(event) => setScheduled(event.currentTarget.checked)}
            />
            {scheduled ? (
              <Group grow mt={10}>
                <DatePicker
                  {...form.getInputProps("start_date")}
                  placeholder={t(
                    "spaces.settings.structure.add.add.placeholder3"
                  )}
                  label={t("spaces.settings.structure.add.add.label2")}
                />
                <TimeInput
                  {...form.getInputProps("start_time")}
                  label={t("spaces.settings.structure.add.add.label3")}
                  format="12"
                />
              </Group>
            ) : (
              ""
            )}
          </ScrollArea>
          <Group>
            <Button radius="xl" type="submit">
              {t("spaces.settings.structures.add.add.saveButton")}
            </Button>
          </Group>
        </Stack>
      </form>
    </Drawer>
  );
}
