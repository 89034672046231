import Paragraph from "@tiptap/extension-paragraph";
import { mergeAttributes } from "@tiptap/core";
import {
  isEmptySelection,
  checkInteractionAndHideIfNeeded,
  //setUniqId,
} from "../utils/selection";
import { wideOrNarrow } from "../../../utils/PageWidth";

const ParagraphNode = Paragraph.extend({
  draggable: false,
  content: "inline*",
  group: "block columnable",
  // addAttributes() {
  //   return {
  //     incrementer: {
  //       default: 0
  //     }
  //   }
  // },
  addCommands() {
    return {
      addNewEmptyLine: () => (editor) => {
        const { from, to, $anchor } = editor.view.state.selection;
        if (!isEmptySelection(editor)) {
          return editor
            .chain()
            .insertContentAt(to, `<p uid=""></p>`)
            .setTextSelection({ from: to + 1, to: to + 1 })
            .run();
        } else {
          return editor.commands.selectTextblockEnd();
        }
      },
      customEnter: () => (editor) => {
        const { from, to, $anchor } = editor.view.state.selection;
        // debugger
        // console.log('CUSTOM ENTER');
        return editor
          .chain()
          .focus()
          .splitBlock()
          .run();
      },
      setParagraph:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name);
        },
    };
  },
  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("p");
      const content = document.createElement("div");
      content.style.maxWidth = wideOrNarrow();
      content.classList.add("is_text");

      const attributes = mergeAttributes(HTMLAttributes, {});
      content.setAttribute("id", attributes["data-uid"]);
      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );
      dom.append(content);

      checkInteractionAndHideIfNeeded(editor, dom, getPos);
      // //setUniqId(editor, node, getPos, dom);

      const isRegistration = window.location.pathname.indexOf("/registration/view") >= 0;

      if (isRegistration) {
        setTimeout(() => {
          const a = content.querySelectorAll('a');
          if (a[0]) {
            const link = a[0].href;
            const isJumpTo = link.indexOf("#jumpTo=") >= 0;
            a[0].href = isJumpTo ? link.replace('register', 'registration/view') : link;
          }
        }, 200);
      }

      return {
        dom,
        contentDOM: content,
      };
    };
  },
});

export default ParagraphNode;
