import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import InterestModel from "../../../models/Interest";

import { setCookie } from "../../../utils/Cookies";

import {
  Box,
  Button,
  Stack,
  TextInput,
  createStyles,
  Modal,
  Text,
  Divider,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import tinycolor from "tinycolor2";

import { setTiptapSidemenu } from "../../../store/app";

const useStyles = createStyles((theme, { colors, props }) => ({
  drawerWrap: {
    backgroundColor: colors.background_color,
  },
  formStyle: {
    "&:focus": {
      border: `1px solid ${tinycolor(colors.background_color)
        .darken(20)
        .toString()}`,
    },
  },
  formStyleLabel: {
    color: colors.default_text_color,
  },
  formStyleLink: {
    color: colors.default_link_color,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  buttonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    border: `1px solid ${colors.primary_button_background_color}`,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(5)
        .toString(),
    },
  },
  closeButton: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    "&:hover": {
      backgroundColor: colors.background_color,
      color: colors.primary_button_background_color,
    },
  },
}));

export default function Form(props) {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors, props });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);

  const location = useLocation();

  const openedFromUrl = useRef();

  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);

  useEffect(() => {
    if (openedFromUrl.current != location.pathname) {
      setOpened(false);
    }
  }, [location]);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "interestedform") {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "interestedform") {
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    } else {
      openedFromUrl.current = location.pathname;
    }
  }, [opened]);

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.replace(/\s/g, '')) ? null : t("users.signUp.invalidEmailErrorMsg"),
      name: (value) =>
        value.length >= 2 ? null : t("users.signUp.invalidFirstNameErrorMsg"),
    },
  });

  const onSave = () => {
    const params = {
      ...form.values,
    };

    const slug = location.pathname.split("/")[2];

    InterestModel.onCreate(
      slug,
      params,
      (data) => {
        Swal.fire({
          icon: "success",
          html: t("spaces.form.interestedForm.createAlertTitle"),
          showDenyButton: false,
          confirmButtonText: t("spaces.form.interestedForm.createAlertButton"),
          confirmButtonColor: "#41c3f6",
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
        setCookie(`interested_${slug}`, "true", 365);
        dispatch(setTiptapSidemenu({ opened: null }));
        onResetForm();
      },
      (data) => {}
    );
  };

  const onResetForm = () => {
    form.setFieldValue("email", "");
    form.setFieldValue("name", "");
  };

  const onJoin = () => {
    if (!user.authenticated) {
      localStorage.setItem("join_slug", props.spaceId);
      navigate(`/user/start?s=${props.spaceId}`);
      return;
    }

    SpaceModel.onJoin(
      props.spaceId,
      (data) => {
        navigate(`${data.redirect_to.url}`);
      },
      (data) => {}
    );
  };

  return (
    <Modal
      centered
      classNames={{
        modal: classes.drawerWrap,
        closeButton: classes.closeButton,
      }}
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text className={classes.formStyleLabel} weight="bold">
          Register for this event
        </Text>
      }
    >
      <Box>
        <form onSubmit={form.onSubmit((values) => onSave(values))}>
          <Stack justify="space-between">
            <TextInput
              {...form.getInputProps("name")}
              placeholder={t("spaces.form.interestedForm.name.placeholder")}
              label={t("spaces.form.interestedForm.name.label")}
              styles={{
                input: classes.formStyle,
              }}
              classNames={{ label: classes.formStyleLabel }}
              required
            />
            <TextInput
              {...form.getInputProps("email")}
              placeholder={t("spaces.form.interestedForm.email.placeholder")}
              label={t("spaces.form.interestedForm.email.label")}
              styles={{
                input: classes.formStyle,
              }}
              classNames={{ label: classes.formStyleLabel }}
              required
            />
            <Button type={`submit`} className={classes.buttonStyle}>
              {t("spaces.form.interestedForm.register")}
            </Button>
            <Divider mt={15} />
            <Text size="md" align="center" mt={15}>
              <Text className={classes.formStyleLink} onClick={() => onJoin()}>
                {t("spaces.form.interestedForm.joinWithSutra")}
              </Text>
            </Text>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
