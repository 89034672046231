import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import SpaceSettings from "./settings/Settings";

import Discussion from "./presentations/discussion/Discussion";
import ContentContainer from "./presentations/content/ContentContainer";
import RegistrationPage from "./presentations/registration/RegistrationPage";
import RegistrationPageReadonly from "./presentations/registration/RegistrationPageReadonly";
import Payment from "./payment/Payment";
import UserEdit from "../users/Edit";
import Private from "./presentations/static/Private";
import Disabled from "./presentations/static/Disabled";
import ConfirmId from "./presentations/static/ConfirmId";
import Pending from "./presentations/static/Pending";
import Processing from "./presentations/static/Processing";
import ProcessingPayment from "./presentations/static/ProcessingPayment";
import Invitation from "./presentations/static/Invitation";
import Survey from "./presentations/static/Survey";
import Gateway from "./presentations/static/Gateway";
import BroadcastExclude from "./presentations/static/BroadcastExclude";
import Loading from "./presentations/static/Loading";

import Events from "./presentations/lists/Events";
import List from "./presentations/lists/List";
import Showcase from "./presentations/lists/Showcase";

import GettingStarted from "../app/GettingStarted";
import Members from "./members/List";
import DummyStart from "./dummy/Start";
import DummySignIn from "./dummy/SignIn";
import DummySignUp from "./dummy/SignUp";
import DummyMagicLink from "./dummy/MagicLink";
import DummyRecover from "./dummy/Recover";
import DummyEditProfile from "./dummy/Edit";
import DummyPay from "./dummy/Payment";
import DummyPending from "./dummy/Pending";

import Access from "./Access";

export default function Space(props) {
  const params = useParams();
  const [access, setAccess] = useState(false);
  const lastSlug = useRef()

  useEffect(() => {
    // if (lastSlug.current == params.slug)
    console.log("ENTERING SPACE: " + params.slug)
    setAccess(false)
  }, [params.slug])

  return (
    <>
      <Access spaceId={params.slug} setSpaceAccess={setAccess}/>
      {access ? (
        <Routes>
          <Route
            path="events"
            element={<Events showHeader={true} spaceId={params.slug} />}
          />

          <Route
            path="list"
            element={<List showHeader={true} spaceId={params.slug} />}
          />

          <Route
            path="showcase"
            element={<Showcase showHeader={true} spaceId={params.slug} />}
          />

          <Route path="discussion" element={<Discussion showHeader={true} />} />

          <Route
            path="content"
            element={<ContentContainer spaceId={params.slug} />}
          />

          <Route path="register" element={<RegistrationPageReadonly />} />

          <Route
            path="registration/edit"
            element={
              <RegistrationPage
                documentName={"editable-registration-page"}
                editable={true}
              />
            }
          />

          <Route
            path="registration/view"
            element={
              <RegistrationPage
                documentName={"editable-registration-page"}
                editable={false}
              />
            }
          />

          <Route path="settings/*" element={<SpaceSettings />} />

          <Route path="pay" element={<Payment />} />

          <Route
            path=":username/edit"
            element={<UserEdit spaceId={params.slug} />}
          />
          <Route path="private" element={<Private />} />
          <Route path="disabled" element={<Disabled />} />
          <Route path="confirm_id" element={<ConfirmId />} />
          <Route path="pending" element={<Pending />} />
          <Route path="processing" element={<Processing />} />
          <Route path="processing_payment" element={<ProcessingPayment />} />
          <Route path="members" element={<Members />} />
          <Route path="invitation" element={<Invitation />} />
          <Route path="getting_started" element={<GettingStarted />} />
          <Route path="survey" element={<Survey />} />
          <Route path="gateway" element={<Gateway />} />
          <Route
            path="broadcast/exclude/:email"
            element={<BroadcastExclude />}
          />

          <Route path="demo/start" element={<DummyStart />} />
          <Route path="demo/sign_in" element={<DummySignIn />} />
          <Route path="demo/sign_up" element={<DummySignUp />} />
          <Route path="demo/magic_link" element={<DummyMagicLink />} />
          <Route path="demo/recover" element={<DummyRecover />} />
          <Route path="demo/pay" element={<DummyPay />} />
          <Route path="demo/edit_profile" element={<DummyEditProfile />} />
          <Route path="demo/pending" element={<DummyPending />} />
        </Routes>
      ) : ""}
    </>
  );
}
