import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Sorter(props) {
  if (!props.orderBy) {
    return null;
  }

  return (
    <span onClick={(event) => props.onSort(props.sortBy)}>
      {props.orderBy.by == props.sortBy ? (
        props.orderBy.as == "asc" ? (
          <FontAwesomeIcon icon={solid("sort-down")} />
        ) : (
          <FontAwesomeIcon icon={solid("sort-up")} />
        )
      ) : (
        <FontAwesomeIcon icon={solid("sort")} />
      )}
    </span>
  );
}
