import React, { useState, useEffect, useRef } from "react";
import {
  Text,
  Box,
  Group,
  Checkbox,
  Anchor,
  createStyles,
} from "@mantine/core";

import AnswerItem from "./AnswerItem";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useListState } from "@mantine/hooks";

import { sutraTheme } from "../../../../collections/sutraTheme";

import AnswerModel from "../../../../models/Answer";

const useStyles = createStyles((theme) => ({
  dragHandle: {
    ...theme.fn.focusStyles(),
    height: "100%",
    color: theme.colors.gray[6],
    cursor: "move",
    paddingTop: 3,
    width: 20,
    maxWidth: 20,
  },
}));

const Answers = (props) => {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();
  const [answers, handlers] = useListState([]);
  const [type, setType] = useState("multiple");

  const loading = useRef(false);

  useEffect(() => {
    return () => {
      loading.current = false;
    };
  }, []);

  useEffect(() => {
    if (!loading.current) {
      handlers.setState(props.answers);
      setType(props.type);
    } else if (props.type != type) {
      if (props.type == "freeform") {
        handlers.setState([{ new: true }]);
      }
      setType(props.type);
    }
  }, [props]);

  const onReorder = () => {
    if (answers.length == 0) {
      return;
    }
    const order = answers.map((answer, index) => {
      return { id: answer.id, position: index };
    });

    AnswerModel.onReorder(
      props.spaceId,
      props.surveyId,
      props.questionId,
      order,
      (data) => {},
      (data) => {}
    );
  };

  useEffect(() => {
    if (loading.current) {
      onReorder();
    } else if (answers.length > 0) {
      loading.current = true;
    }
  }, [answers]);

  const onAddAnswer = () => {
    handlers.append({ content: "", new: true });
  };

  const onUpdateAnswer = (answer, index) => {
    const newAnswers = [...answers];
    const newAnswer = { ...answers[index] };
    if (!newAnswer.id || newAnswer.id == answer.id) {
      newAnswer.id = answer.id;
      newAnswer.content = answer.content;
      newAnswer.payment_amount = answer.payment_amount;

      handlers.setItem(index, newAnswer);
      newAnswers[index] = newAnswer;

      // props.onUpdate(newAnswers)
    }
  };

  const onDestroyAnswer = (answer) => {
    const newAnswers = [...answers];
    for (let i = 0; i < newAnswers.length; i++) {
      const _answer = newAnswers[i];
      if (_answer.id == answer.id) {
        newAnswers.splice(i, 1);
        break;
      }
    }
    handlers.setState(newAnswers);
    // props.onUpdate(newAnswers)
  };

  return (
    <Box mb={20}>
      <DragDropContext
        onDragEnd={({ destination, source }) => {
          handlers.reorder({ from: source.index, to: destination.index });
        }}
      >
        <Droppable
          droppableId={`dnd-list-answers-${props.questionId}`}
          direction="vertical"
        >
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {answers.map((answer, index) => {
                return (
                  <AnswerItem
                    index={index}
                    spaceId={props.spaceId}
                    surveyId={props.surveyId}
                    questionId={props.questionId}
                    answer={answer}
                    onUpdate={onUpdateAnswer}
                    onDestroy={onDestroyAnswer}
                    type={type}
                    currency={props.currency}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {type != "freeform" ? (
        <Group mt={15} ml={20} spacing={0}>
          <Box
            sx={{
              width: 30,
            }}
          >
            <Checkbox
              readOnly={true}
              checked={false}
              styles={{
                root: {},
                input: {
                  marginTop: -1,
                },
              }}
            />
          </Box>
          <Anchor
            sx={(theme) => ({
              // color: theme.colors.sutrablue[4],
              color: sutraTheme.colors.sutrablue[4],
              "&:hover": {
                // color: theme.colors.sutrablue[4],
                color: sutraTheme.colors.sutrablue[5],
              },
            })}
            onClick={() => onAddAnswer()}
          >
            <Text size="sm" weight="600">
              {t("spaces.settings.survey.answer.answer.addOption")}
            </Text>
          </Anchor>
        </Group>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Answers;
