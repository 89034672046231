export function getDropPosition(x, y, dropTarget){

  const delimiterSize = 6

  const tOffset = dropTarget.getBoundingClientRect()
  const tOffsetX = tOffset.left// + window.scrollX;
  const tOffsetY = tOffset.top// + window.scrollY;

  const tWidth = dropTarget.offsetWidth;
  const tHeight = dropTarget.offsetHeight;

  const upMinLimitX = tOffsetX;
  const upMinLimitY = tOffsetY;
  const upMaxLimitX = tOffsetX + tWidth;
  const upMaxLimitY = tOffsetY + delimiterSize;

  const downMinLimitX = tOffsetX
  const downMinLimitY = tOffsetY + tHeight - delimiterSize;
  const downMaxLimitX = tOffsetX + tWidth
  const downMaxLimitY = tOffsetY + tHeight;

  // Left and right not in use
  const leftMinLimitX = tOffsetX;
  const leftMinLimitY = upMaxLimitY + 1;
  const leftMaxLimitX = tOffsetX + tWidth;
  const leftMaxLimitY = downMinLimitY - 1;

  const rightMinLimitX = leftMaxLimitX + tWidth;
  const rightMinLimitY = upMaxLimitY + 1;
  const rightMaxLimitX = tOffsetX + tWidth;
  const rightMaxLimitY = downMinLimitY - 1;

  if (x >= upMinLimitX && x <= upMaxLimitX && y >= upMinLimitY && y <= upMaxLimitY){

    return "top"
  }
  if (x >= downMinLimitX && x <= downMaxLimitX && y >= downMinLimitY && y <= downMaxLimitY){

    return "bottom"
  }
  if (x >= leftMinLimitX && x <= leftMaxLimitX && y >= leftMinLimitY && y <= leftMaxLimitY){
    // Left and right not in use
    return "center"
  }
  if (x >= rightMinLimitX && x <= rightMaxLimitX && y >= rightMinLimitY && y <= rightMaxLimitY){
    // Left and right not in use
    return "center"
  }
  return "center";
}
