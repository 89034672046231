import React, { useState, useEffect } from "react";

import { Text, Group, Stack, Button, Checkbox, Tooltip } from "@mantine/core";

import Avatar from "../../../utils/LazyAvatar";

import { useDispatch, useSelector } from "react-redux";

import { setTiptapSidemenu } from "../../../../store/app";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import DirectMessageModel from "../../../../models/DirectMessage";

export default function ProgressActivity(props) {
  const user = useSelector((state) => state.user);
  const [member, setMember] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setMember(props.member);
  }, [props]);

  const openProfile = () => {
    dispatch(setTiptapSidemenu({ opened: "profile", option: member.username }));
  };

  const onOpenDiscussion = (item) => {
    if (!user.authenticated) {
      navigate(`/user/start`);
      return;
    }
    if (["list", "events", "showcase"].indexOf(item.version) >= 0) {
      if (props.actions == "commented"){
        dispatch(
          setTiptapSidemenu({ opened: "space-content", option: item.action_data[0][2] })
        );
      }
      else{
        window.open(`/space/${item.slug}/${item.version}`, "_blank")
      }
    } else if (item.version.indexOf("discussion") >= 0) {
      dispatch(
        setTiptapSidemenu({
          opened: "direct",
          option: item.slug,
          allow_reflections: true
          // pod_message_id: notification.pod_message_id,
        })
      );
    } else {
      dispatch(
        setTiptapSidemenu({ opened: "space-content", option: item.slug })
      );
    }
  };

  return (
    <tr>
      <td
        style={{
          width: "20px",
        }}
      >
        {window.$currentUserId != member.user_id && (
          <Checkbox
            checked={props.isSelected(member.username)}
            onChange={(event) => props.onSelect(member.username)}
          />
        )}
      </td>
      <td
        style={{
          position: "sticky",
          left: 0,
          zIndex: 1,
          backgroundColor: "white",
        }}
      >
        <Group
          spacing="sm"
          onClick={() => openProfile()}
          sx={{
            color: "#000",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          noWrap
        >
          <Avatar
            size={30}
            src={member && member.avatar ? member.avatar.url : ""}
            radius={30}
          />
          <Stack spacing="0">
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              label={member.full_name}
              maxWidth={200}
            >
              <Text size="sm" weight={500} lineClamp={1}>
                {member.full_name}
              </Text>
            </Tooltip>
            <Text size="xs" color="dimmed" mt={-3}>
              @{member.username}
            </Text>
          </Stack>
        </Group>
      </td>
      {member.spaces &&
        member.spaces.map((item) => {
          return (
            <td
              style={{
                width: "50px",
                padding: "0px",
                margin: "0px",
                textAlign: "center",
              }}
            >
              {item.action && (
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={item.name}
                  maxWidth={200}
                >
                  <Button
                    variant="subtle"
                    onClick={(event) => onOpenDiscussion(item)}
                  >
                    <FontAwesomeIcon icon={solid("check")} />
                  </Button>
                </Tooltip>
              )}
            </td>
          );
        })}
    </tr>
  );
}
