import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { getEnv } from "../../utils/Host";

import {
  Button,
  ScrollArea,
  Container,
  Box,
  Text,
  Title,
  createStyles,
  Grid,
} from "@mantine/core";
import SpaceModel from "../../models/Space";

import { setHiddenSidebar } from "../../store/app";

import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

const useStyles = createStyles((theme, _params, getRef) => ({
  control: {
    display: "block",
    width: "100%",
    borderRadius: `${theme.radius.md}px`,
    padding: `10px 15px`,

    "&:hover": {
      backgroundColor: theme.colors.gray[1],
      color: theme.black,
      textDecoration: "none",
    },
  },
  controlActive: {
    backgroundColor: theme.colors.gray[1],
  },
  linkText: {
    fontWeight: 500,
    color: theme.black,
    fontSize: 16,
    lineHeight: 1.4,
  },
  linkTextActive: {
    // fontWeight: 600,
  },
}));

export default function GettingStarted(props) {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const space = useSelector((state) => state.space);
  const navigate = useNavigate();

  const [zoomUserId, setZoomUserId] = useState("");
  const [zoomData, setZoomData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: true }));

    onFetchZoomData();

    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: false }));
    // Triple checking that sidebar is displayed
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";

      dispatch(setHiddenSidebar({ hidden: false }));
    }, 1000);
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";

      dispatch(setHiddenSidebar({ hidden: false }));
    }, 2000);
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";

      dispatch(setHiddenSidebar({ hidden: false }));
    }, 3000);
  }, [user]);

  const onReadInstructions = () => {
    window.open(
      "https://support.sutra.co/article/126-zoom-recording-integration",
      "_blank"
    );
  };

  const onConnectZoom = () => {
    if (getEnv() == "production") {
      window.location.href= `https://zoom.us/oauth/authorize?client_id=Iq_GKcCRhWRVRdVaE3W5Q&response_type=code&redirect_uri=https://sutra.co/api/v4/hooks/zoom/redirect?state=${space.slug}_usr_${window.$currentUserAuthToken}`;
    } else {
      window.location.href= `https://zoom.us/oauth/authorize?client_id=YK0Cx_LQ16AVBy0FFvaxQ&response_type=code&redirect_uri=https://sutra.ngrok.dev/api/v4/hooks/zoom/redirect?state=${space.slug}_usr_${window.$currentUserAuthToken}`;
    }
  };

  const onDisconnectZoom = () => {
    SpaceModel.onZoomDisconnect(
      "zoom",
      (data) => {
        setZoomData({});
      },
      (data) => {}
    );
  };

  const onFetchZoomData = () => {
    SpaceModel.onZoomData(
      "zoom",
      zoomUserId,
      (data) => {
        setZoomData({ ...data.zoom_data });
      },
      (data) => {}
    );
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{t("app.gettingStarted.tabTitle")}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }}>
        <Box
          mt={30}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          })}
        >
          <Grid>
            <Grid.Col
              span={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box>
                <Title sx={(theme) => ({ fontWeight: 900 })} mb={10}>
                  {`Zoom connection successful`}
                </Title>

                <Text mb={10}>
                  {
                    "Your Zoom account is now connected to Sutra.  You will not have to do this again unless you disconnect your Zoom account.  Please close this tab and return to the tab where you initiated the connection."
                  }
                </Text>
              </Box>
            </Grid.Col>
          </Grid>
        </Box>
      </Container>
    </ScrollArea>
  );
}
