import React, { useEffect, useRef } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { setCookie } from "../../utils/Cookies";

import UserChannel from "../../sockets/User";
import ConnectionsChannel from "../../sockets/Connections";

export default function UserCtrl() {
  const currentLocation = useLocation();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUser = useRef();

  useEffect(() => {
    cookiesParams();
  }, []);

  useEffect(() => {
    if (user.authenticated == null){
      return
    }
    if (checkMagicToken()) {
      return;
    }

    checkAuthentication();
    
  }, [user]);

  const cookiesParams = () => {
    const search = window.location.search;
    const affiliateSpace = new URLSearchParams(search).get("r");
    const affiliateSutra = new URLSearchParams(search).get("a");
    if (affiliateSpace) {
      setCookie("affiliate_space", affiliateSpace, 90);
      setCookie("affiliate_space_date", new Date().getTime(), 90);
    }
    if (affiliateSutra) {
      setCookie("affiliate_sutra", affiliateSutra, 90);
      setCookie("affiliate_sutra_date", new Date().getTime(), 90);
    }

    // OAUTH PARAMS:
    const oauthClientId = new URLSearchParams(search).get("client_id");
    const oauthClientSecret = new URLSearchParams(search).get("client_secret");
    const oauthState = new URLSearchParams(search).get("state");
    const oauthRedirectUri = new URLSearchParams(search).get("redirect_uri");
    const oauthResponseType = new URLSearchParams(search).get("response_type");

    if (oauthClientId) {
      setCookie("oauth_client_id", oauthClientId, 30);
    }
    if (oauthClientSecret) {
      setCookie("oauth_client_secret", oauthClientSecret, 30);
    }
    if (oauthClientId) {
      setCookie("oauth_state", oauthState, 30);
    }
    if (oauthRedirectUri) {
      setCookie("oauth_redirect_uri", oauthRedirectUri, 30);
    }
    if (oauthResponseType) {
      setCookie("oauth_response_type", oauthResponseType, 30);
    }
  };

  const checkMagicToken = () => {
    const search = currentLocation.search;
    const magic_token = new URLSearchParams(search).get("magic_token");
    if (magic_token) {
      navigate(`/user/magic_sign_in/${magic_token}`);
      return true;
    }
    return false;
  };

  const checkAuthentication = () => {
    const authRoutes = [
      "/user/sign_in",
      "/user/sign_up",
      "/user/recover",
      "/user/start",
      "/user/magic_link",
      "/user/reset_password",
    ];
    let isAuthRoute = false;
    for (let i = 0; i < authRoutes.length; i++) {
      if (location.pathname.indexOf(authRoutes[i]) >= 0) {
        isAuthRoute = true;
        break;
      }
    }

    if (!isAbsSpaceRoute()) {
      window.$currentTopSlug = null;
    }

    
    if (isAuthRoute) {
      if (user.authenticated == true) {
        navigate(user.redirect_to ? user.redirect_to : "/home/top");
        return false;
      } else if (user.authenticated == false) {
        return true;
      }
    } else {
      if (user.authenticated == true) {
        return true;
      } else if (
        !user.authenticated &&
        (
          location.pathname.indexOf("/home/top") >= 0 ||
          location.pathname.indexOf("/home/managed") >= 0 ||
          location.pathname.indexOf("/home/all") >= 0 ||
          location.pathname.indexOf("/home/archived") >= 0 ||
          location.pathname.indexOf("/home/deleted") >= 0 ||
          location.pathname.indexOf("/home/direct") >= 0 ||
          location.pathname.indexOf("/home/invitations") >= 0 ||
          location.pathname.indexOf("/settings/profile") >= 0 ||
          location.pathname.indexOf("/settings/account") >= 0 ||
          location.pathname.indexOf("/settings/email") >= 0 ||
          location.pathname.indexOf("/settings/affiliates") >= 0 ||
          location.pathname.indexOf("/settings/billing") >= 0 ||
          location.pathname.indexOf("/zoom/integration") >= 0)
      ) {
        if (location.pathname.indexOf("/zoom/integration") >= 0) {
          
          // navigate("/user/start?s=zoom");
        } else {
          navigate("/user/start");
        }
        return false;
      } else if (
        !user.authenticated &&
        !isSpaceRoute() &&
        !isAppRoute() &&
        !isUserRoute() &&
        !isProfileRoute() &&
        location.pathname.indexOf("/user/magic_sign_in") < 0
      ) {
        navigate("/user/start");
        return false;
      } else {
        return true;
      }
    }
  };

  const isAbsSpaceRoute = () => {
    return location.pathname.indexOf("/space") >= 0;
  };

  const isSpaceRoute = () => {
    return (
      location.pathname.indexOf("/space") >= 0 &&
      (location.pathname.indexOf("/content") >= 0 ||
        location.pathname.indexOf("/discussion") >= 0 ||
        location.pathname.indexOf("/register") >= 0 ||
        location.pathname.indexOf("/gateway") >= 0 ||
        location.pathname.indexOf("/broadcast/exclude") >= 0)
    );
  };

  const isAppRoute = () => {
    return location.pathname.indexOf("/app/pricing") >= 0;
  };

  const isUserRoute = () => {
    const routeSplit = location.pathname.split("/");
    return (
      routeSplit.length == 2 &&
      ["space", "user", "settings", "app"].indexOf(routeSplit[1]) < 0
    );
  };

  const isProfileRoute = () => {
    return (
      /\/[a-zA-Z0-9]{4,}$/.test(location.pathname) ||
      location.pathname.indexOf("/user/reset_password")
    );
  };

  return (
    <>
      <UserChannel username={user.username} userId={user.id} />
      <ConnectionsChannel username={user.username} isAdmin={user.is_admin} />
    </>
  );
}
