import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Text,
  Checkbox,
  RadioGroup,
  Radio,
  Group,
  NumberInput,
  Box,
  Textarea,
  createStyles,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import tinycolor from "tinycolor2";

const useStyles = createStyles((theme, { colors }) => ({
  questionWrap: {
    marginBottom: 20,
  },
  questionStyle: {
    fontSize: 18,
    color: colors.default_text_color,
    fontWeight: 700,
  },
  answerWrap: {
    marginTop: 10,
  },
  answerStyle: {
    color: colors.default_text_color,
    fontSize: 16,
  },
  radioStyle: {
    "&:checked": {
      backgroundColor: colors.primary_button_background_color,
      borderColor: colors.primary_button_background_color,
    },
  },
  formStyle: {
    "&:focus": {
      border: `1px solid ${tinycolor(colors.background_color)
        .darken(20)
        .toString()}`,
    },
  },
}));

const Question = (props) => {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors, props });
  const { t, i18n } = useTranslation();
  const [question, setQuestion] = useState(null);
  const [choice, setChoice] = useState([]);
  const [priceChoice, setPriceChoice] = useState([]);

  useEffect(() => {
    if (!question) {
      setQuestion({ ...props.question });
      if (props.question.question_type == "freeform") {
        setChoice("");
      } else {
        setChoice([]);
      }
    }
  }, [props]);

  useEffect(() => {
    props.onAddChoice(question, choice);
  }, [choice]);

  const isChosen = (answer_id) => {
    return choice.indexOf(`${answer_id}`) >= 0;
  };

  const unChose = (answer_id) => {
    const auxChoice = [...choice];
    auxChoice.splice(choice.indexOf(`${answer_id}`), 1);
    setChoice(auxChoice);
  };

  const onChose = (answer_id) => {
    if (props.question.question_type == "single") {
      const auxChoice = [];
      auxChoice.push(`${answer_id}`);
      setChoice(auxChoice);
    } else {
      if (isChosen(`${answer_id}`)) {
        unChose(`${answer_id}`);
      } else {
        const auxChoice = [...choice];
        auxChoice.push(`${answer_id}`);
        setChoice(auxChoice);
      }
    }
  };

  return (
    <Box className={classes.questionWrap}>
      <Text className={classes.questionStyle}>{props.question.content}</Text>
      {props.question.question_type == "multiple" &&
        props.question.answers.map((answer) => {
          return (
            <Group>
              <Checkbox
                styles={{
                  root: classes.answerWrap,
                  label: classes.answerStyle,
                  input: classes.radioStyle,
                }}
                checked={isChosen(answer.id)}
                onChange={(event) => onChose(answer.id)}
                label={answer.content}
              />
            </Group>
          );
        })}
      {props.question.question_type == "single" && (
        <RadioGroup
          value={choice[0]}
          onChange={onChose}
          orientation="vertical"
          styles={{
            root: classes.answerWrap,
            label: classes.answerStyle,
            radio: classes.radioStyle,
          }}
        >
          {props.question.answers.map((answer) => {
            return <Radio value={`${answer.id}`} label={answer.content} />;
          })}
        </RadioGroup>
      )}
      {props.question.question_type == "payment" &&
        props.question.answers.map((answer) => {
          return (
            <Group>
              <Checkbox
                checked={isChosen(answer)}
                onChange={(event) => onChose(answer.id)}
                label={answer.content}
              />
              <NumberInput
                disabled
                value={answer.payment_amount}
                onChange={(val) => setPriceChoice([answer.id, val])}
              />
            </Group>
          );
        })}
      {props.question.question_type == "freeform" && (
        <Textarea
          mt={10}
          placeholder={t("survey.question.yourAnswer")}
          styles={{
            input: classes.formStyle,
          }}
          value={choice}
          onChange={(event) => setChoice(event.currentTarget.value)}
        />
      )}
    </Box>
  );
};

export default Question;
