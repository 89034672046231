import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { Stack, RadioGroup, Radio } from "@mantine/core";

export default function InteractiveRadio(props) {
  const timeoutId = useRef(null);

  const loading = useRef(false);

  const tiptap = useSelector((state) => state.tiptap);
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const _checked = props.element.node.attrs[`${props.attrName}`];

    if (_checked != value) {
      const data = {};
      data[`${props.attrName}`] = value;

      tiptap.editor.commands.updateAttributes(
        props.element.node.type.name,
        data
      );
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        props.saveNodeData();
      }, 2000);

    }
  }, [value]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      setValue(props.element.node.attrs[`${props.attrName}`]);
    }
  }, [props.element]);

  return (
    <Stack mt={20}>
      {Array.isArray(props.radioData) && (
         <RadioGroup
          value={props.element.node.attrs.interactiveWaitNotificationIncludeContentValue}
          onChange={setValue}
          styles={{ label: { fontSize: 14 } }}
        >
          {props.radioData.map((item, key) => (
            <Radio key={key} value={item.value} label={item.label} />
          ))}
        </RadioGroup>
      )}
     
    </Stack>
  );
}
