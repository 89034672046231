import Heading from '@tiptap/extension-heading'
import { mergeAttributes } from '@tiptap/core'

import {checkInteractionAndHideIfNeeded, setUniqId} from '../utils/selection';
import { wideOrNarrow } from "../../../utils/PageWidth";

const HeadingNode = Heading.extend({
  draggable: false,
  content: 'inline*',
  group: 'block columnable',
  addAttributes() {
    return {
      level: {
        default: 1
      },
    }
  },
  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes, }) => {

      const hasLevel = this.options.levels.includes(node.attrs.level)

      const level = hasLevel
        ? node.attrs.level
        : this.options.levels[0]

      const dom = document.createElement(`h${level}`)
      const content = document.createElement("div")
      content.style.maxWidth = wideOrNarrow();
      content.classList.add("is_text")

      const attributes = mergeAttributes(HTMLAttributes, {});
      content.setAttribute("id", attributes["data-uid"]);

      Object.entries(attributes).forEach(([key, value]) => dom.setAttribute(key, value));
      dom.append(content)
      checkInteractionAndHideIfNeeded(editor, dom, getPos)

      const isRegistration = window.location.pathname.indexOf("/registration/view") >= 0;

      if (isRegistration) {
        setTimeout(() => {
          const a = content.querySelectorAll('a');
          if (a[0]) {
            const link = a[0].href;
            const isJumpTo = link.indexOf("#jumpTo=") >= 0;
            a[0].href = isJumpTo ? link.replace('register', 'registration/view') : link;
          }
        }, 200);
      }

      return {
          dom,
          contentDOM: content,
          // ignoreMutation(mutation) {
          //     if (mutation.type === 'selection') {
          //         return false;
          //     }
          //     return !dom.contains(mutation.target) || dom === mutation.target;
          // },
          // update: updatedNode => {
          //     if (updatedNode.type !== this.type) {
          //         return false;
          //     }
          //     return true;
          // },
      };
    }
  },
})

export default HeadingNode;
