import mixpanel from 'mixpanel-browser';

const trackMixpanel = (username, action, params = {}) => {
  const mixpanelKey = "b70e7b4e9a911ec7b7a73c7f7bb7aee7"

  mixpanel.init(mixpanelKey);
  mixpanel.identify(username)
  mixpanel.track(action, params);
}

export default trackMixpanel;
