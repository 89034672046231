import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import UserModel from "../../models/User";

export default function Gateway(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    // debugger;
    b.style.display = "none";

    return () => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
    };
  }, []);

  useEffect(() => {
    UserModel.onGateway(
      params.plan,
      (data) => {
        navigate(data.redirect_to.url);
      },
      (data) => {}
    );
  }, [params]);

  return <p></p>;
}
