import { Extension } from '@tiptap/core'
import { TextSelection } from 'prosemirror-state';

import { setUniqId } from "../utils/selection";

import Swal from "sweetalert2";

import { setCookie } from "../../../utils/Cookies";
import { getRoot } from '../../../utils/Host';

const EditorEvents = Extension.create({
  onBeforeCreate({ editor }) {

  },
  onCreate({ editor }) {
    console.log("TIPTAP: onCreate")
    window.$tiptapNeedSaving = {status: false, leave: false, init: true}
    setTimeout(() => {
      console.log("TIPTAP: onInit finished")
      window.$tiptapNeedSaving.init = false
    }, 2000)
  },
  onUpdate({ editor }) {
    if (window.$tiptapNeedSaving && !window.$tiptapNeedSaving.init){
      console.log("TIPTAP: onUpdate")
      window.$tiptapNeedSaving.status = true
      window.$tiptapNeedSaving.leave = false
    }

    setCookie(
      `last_local_updated_at_${window.location.href.split("/")[4]}`,
      Math.floor(Date.now() / 1000),
      365 * 10
    );

    const { tr, doc } = editor.view.state;
    
    const types = [
      "heading",
      "paragraph",
      "grid1",
      "grid2",
      "grid3",
      "grid4",
      "column",
      "tasklist",
      "taskItem",
      "circle",
      "resource",
      "video",
      "audio",
      "image",
      "embed",
      "file",
      "detailsSummary",
      "details",
      "detailsContent",
      "bulletList",
      "orderedList",
      "listItem",
      "taskItem",
      "resource",
      "actionbutton",
      "customform",
      "survey",
      "interactiveInput",
      "interactiveButton",
      "interactiveWait",
      "fancyLink",
      "horizontalRule",
      "infobox",
      "tagsFeed"
    ]
    const usedUuid = [];
    doc.nodesBetween(0, doc.content.size, (node, pos) => {
      if (!node.isText && types.indexOf(node.type.name) >= 0 && (!node.attrs.uid || usedUuid.indexOf(node.attrs.uid) >= 0)) {
        usedUuid.push(setUniqId(editor, pos));
      }
      else{
        usedUuid.push(node.attrs.uid)
      }
    });



  },
  onSelectionUpdate({ editor }) {
    if (!editor.isEditable){
      return
    }

    const {from, to, $anchor} = editor.view.state.selection


    if (window.$sectionParams && window.$sectionParams.isSelection == true) {

      const pos = editor.view.state.doc.resolve(from);
      let parent = null;
      if (pos.depth > 0) {
        parent = pos.node(1);
      }
      if (parent?.type?.name === "doc") {
        parent = pos.node(pos.depth - 1);
      }

      let node = parent ? parent : editor.view.state.selection.$anchor.parent;

      if (node?.type?.name === "doc") {
        node = editor.view.state.doc.nodeAt(from)
      }

      window.$sectionParams.isSelection = false

      const transaction = editor.view.state.tr.setSelection(
        TextSelection.create(editor.view.state.doc, window.$sectionParams.origin.from, window.$sectionParams.origin.to)
      );

      editor.view.dispatch(transaction);

      const path = window.location.pathname.indexOf("/registration") >= 0 ? "register" : "content";

      if (node?.attrs?.uid) {
        const href = `${getRoot()}/space/${window.$currentSlug}/${path}#jumpTo=${node.attrs.uid}`;

        if (typeof window.$sectionParams.onUpdateLink === "function") {
          window.$sectionParams.onUpdateLink(href);
        } else {
          editor.commands.setLink({
            href,
            target: null
          });
        }

        Swal.fire({
          title: `${window.$sectionParams.swalTitle}`,
          icon: "success"
        });
      } else {
        Swal.fire({
          title: `Please try again.`,
          icon: "error"
        });
      }
      
    }
    
    const time = new Date().getTime()

    window.$tiptapSelectionRange = {from, to, time}

    window.$tiptapSelectionCoords = editor.view.coordsAtPos(to)
    
    window.$selectionUpdateForConfigurations = true
    
    
  },
  onTransaction({ editor, transaction }) {
    console.log("TIPTAP: onTransaction")
    console.log(transaction)
    
  },
  onFocus({ editor, event }) {
    if (!editor.isEditable){
      return
    }
    if (window.$tiptapLastSelectionRange && window.$closingSideMenu){

      window.$closingSideMenu = false
      // const {from, to} = window.$tiptapLastSelectionRange
      // window.$tiptapLastSelectionRange = null
      // editor.chain().setTextSelection({ from: to, to: to }).run()
    }

  },
  onBlur({ editor, event }) {

  },
  onDestroy() {
  },
})

export default EditorEvents;
