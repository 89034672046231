import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Drawer, Box, Text, ScrollArea } from "@mantine/core";

import { setTiptapSidemenu } from "../../../store/app";

import TakeForm from "../../surveys/take/Form";

const TakeSurvey = (props) => {
  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [survey, setSurvey] = useState({});
  const colors = useSelector((state) => state.colors);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "takeSurvey") {
      setSurvey({...tiptapSidemenu.option.survey})
      setOpened(true);
    } else {
      if (!window.$modalSpaceOpened){
        setOpened(false);
      }
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "takeSurvey") {
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    }
  }, [opened]);

  const onTaken = () => {
    setOpened(false);
    // Redirect and re-open if apply    
  };

  return (
    <Drawer
      position="left"
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {survey.id
            ? survey.title
            : `Take survey`}
        </Text>
      }
      padding="xl"
      size={"500px"}
      overlayOpacity={0.1}
      styles={{
        drawer: {
          backgroundColor: colors.background_color,
          color: colors.default_text_color,
        },
        closeButton: { color: colors.default_text_color },
      }}
      zIndex={99999}
    >
      {survey.id ? (
        <ScrollArea style={{ height: "100%" }}>
          <TakeForm survey={survey} onTaken={onTaken} />
          <Box mt={150} />
        </ScrollArea>
      ) : (
        <Text>{t("tiptap.components.takeSurvey.text")}</Text>
      )}
    </Drawer>
  );
};

export default TakeSurvey;
