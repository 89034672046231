import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setSpace, setNewMessage } from "../../store/app";
import {
  Stack,
  ScrollArea,
  Drawer,
  Box,
  Text,
  createStyles,
  Group,
  Divider
} from "@mantine/core";

// import { useElementSize } from '@mantine/hooks';
import { storeJSON, getJSON } from "../../utils/LocalDB";
import NotificationModel from "../../../models/NotificationModel";
import MessageModel from "../../models/Message";
import SpaceModel from "../../models/Space";
import Message from "../spaces_neo/presentations/discussion/Message";
import Reflections from "../spaces_neo/presentations/discussion/Reflections";
import TiptapSimple from "../tiptap/components/TiptapSimple";
import TiptapReadonly  from "../tiptap/components/TiptapReadonly";
import { ActionCableContext } from "../../components/Index";
import Loading from "../spaces/presentations/static/Loading";

import { useTranslation } from "react-i18next";

import { setTiptapSidemenu } from "../../store/app";


import isSpaceThemed from "../../utils/IsSpaceThemed";
import tinycolor from "tinycolor2";

const paginator = {
  per: 30,
  page: 1,
};

const queryParams = {
  pod_message_id: null,
  reflection_id: null,
};

const useStyles = createStyles((theme, { colors }, getRef) => ({
  drawerWrap: {
    backgroundColor: colors.background_color,
  },
  drawerHeader: {
    color: colors.default_text_color,
    fontWeight: 700,
    paddingBottom: 10,
    // borderBottom: `1px solid ${colors.default_text_color}`
  },
  themeTextColor: {
    color: colors.default_text_color,
  },
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.sidebar_text_color,
      backgroundColor: colors.sidebar_background_color,
      borderRadius: "5px",
    },
  },
  dividerStyle: {
    marginTop: 10,
    marginBottom: 10,
    borderTop: `1px solid ${
      !isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).darken(5).toString()
        : colors.header_color
    }`,
  },
}));

export default function DirectDiscussion(props) {
  // const { ref, width, height } = useElementSize();
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });
  const { t } = useTranslation();

  const viewport = useRef();
  const dispatch = useDispatch();

  const cable = useContext(ActionCableContext);

  const currentLocation = useLocation();
  const user = useSelector((state) => state.user);

  const [opened, setOpened] = useState(false);
  const [openedReflections, setOpenedReflections] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [receiveData, setReceiveData] = useState(null);
  const [tagging, setTagging] = useState(0);

  const [tag, setTag] = useState(null);
  const [space, setSpace] = useState(null);
  const [aiSummary, setAiSummary] = useState({});

  const pileIntervalId = useRef(null);
  const channelPile = useRef([]);
  const messagesCopy = useRef([]);
  const currentConnectedSlug = useRef();
  const scrollToBottomAfter = useRef();
  const atBottom = useRef(false);
  const scrollTimeoutIds = useRef([]);
  const connectionIntervalId = useRef([]);
  const lastConnectionAttempt = useRef({
    slug: null,
    state: null,
    channel: null,
  });
  const tagFetch = useRef(false);
  const querySearchCount = useRef(0);
  const loadingVariables = useRef(0);

  const params = useRef({ slug: null });

  const openedFromUrl = useRef();

  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);

  const [reflectionsTagging, setReflectionsTagging] = useState(false);

  const [reflectionsDrawerHeader, setReflectionsDrawerHeader] = useState(
    window.$showBackButtonModal
      ? t("spaces.presentations.discussion.backToPost")
      : t("spaces.presentations.discussion.reflectionHeader")
  );

  useEffect(() => {
    if (pileIntervalId.current) {
      clearInterval(pileIntervalId.current);
    }

    pileIntervalId.current = setInterval(() => {
      // console.log(messagesCopy.current)

      if (
        channelPile.current.length > 0 &&
        channelPile.current[0].state == "pending"
      ) {
        channelPile.current[0].state = "in_progress";

        channelPile.current[0].action(channelPile.current[0].id);
        return;
      }
      if (
        channelPile.current.length > 0 &&
        channelPile.current[0].state == "done"
      ) {
        channelPile.current.splice(0, 1);

        return;
      }
      if (
        channelPile.current.length > 0 &&
        channelPile.current[0].state == "in_progress"
      ) {
        return;
      }
    }, 1000);

    return () => {
      setMessages([]);
      messagesCopy.current = [];

      if (lastConnectionAttempt.current.channel) {
        lastConnectionAttempt.current.channel.unsubscribe();
        lastConnectionAttempt.current.channel = null;
        lastConnectionAttempt.current.slug = null;
        lastConnectionAttempt.current.state = null;
      }
      clearInterval(pileIntervalId.current);
      clearTimeout(connectionIntervalId.current);

      scrollToBottomAfter.current = false;
      loadingVariables.current = false;
    };
  }, []);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "direct") {
      params.current = { slug: tiptapSidemenu.option };
      querySearchCount.current = 0;
      checkSearchParams(tiptapSidemenu.pod_message_id);
      fetchSpace();
      cableConnect();
      onFetchContext()
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "direct") {
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    } else {
      openedFromUrl.current = currentLocation.pathname;
    }
  }, [opened]);

  useEffect(() => {
    if (openedFromUrl.current != currentLocation.pathname) {
      setOpened(false);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (receiveData) {
      let changes = receiveData.changes;
      if (receiveData.state != "multi") {
        changes = [receiveData];
      }

      for (var i = 0; i < changes.length; i++) {
        if (changes[i].state == "new") {
          channelPile.current.push({
            id: changes[i].pod_message_id,
            action: onReceivedMessage,
            state: "pending",
          });
          // onReceivedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "update") {
          channelPile.current.push({
            id: changes[i].pod_message_id,
            action: onUpdatedMessage,
            state: "pending",
          });
          // onUpdatedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "destroy") {
          channelPile.current.push({
            id: changes[i].pod_message_id,
            action: onDestroyedMessage,
            state: "pending",
          });
          // onDestroyedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "pin") {
          channelPile.current.push({
            id: changes[i].pod_message_id,
            action: onPinnedMessage,
            state: "pending",
          });
          // onPinnedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "unpin") {
          channelPile.current.push({
            id: null,
            action: onPinnedMessage,
            state: "pending",
          });
          // onPinnedMessage(null);
        }
      }
    }
  }, [receiveData]);

  useEffect(() => {
    if (channelPile.current && channelPile.current.length > 0) {
      return;
    }

    if (scrollToBottomAfter.current == true) {
      scrollToBottomAfter.current = false;

      scrollToBottom();
      return;
    }

    if (!loadingVariables.current && messages.length > 0) {
      loadingVariables.current = true;
      if (!queryParams.pod_message_id) {
        // setIsFirstLoad(true);
        // scrollToBottom();
        return;
      }
    }

    if (queryParams.pod_message_id && messages.length > 0) {
      searchForMessage(queryParams.pod_message_id);
    }
  }, [messages]);

  useEffect(() => {
    paginator.page = 1;
    onFetchMessages();
  }, [tag]);

  const fetchSpace = () => {
    SpaceModel.onFetchSimple(
      params.current.slug,
      "payment",
      (data) => {
        setSpace(data);
      },
      (data) => {}
    );
  };

  const checkSearchParams = (pod_message_id) => {
    if (pod_message_id) {
      queryParams.pod_message_id = pod_message_id;
    }

    if (
      messages.length > 0 &&
      messages[0].pod_slug == params.current.slug &&
      queryParams.pod_message_id
    ) {
      searchForMessage(queryParams.pod_message_id);
    }
  };

  const cableDisconnectIfNeeded = () => {
    if (
      cable &&
      lastConnectionAttempt.current.channel &&
      lastConnectionAttempt.current.slug != params.current.slug
    ) {
      lastConnectionAttempt.current.state = "disconnecting";
      // debugger;
      console.log(
        "ACTION CABLE: disconnecting - " + lastConnectionAttempt.current.slug
      );
      setTimeout(() => {
        console.log(
          "ACTION CABLE: disconnected 1 - " + lastConnectionAttempt.current.slug
        );
        lastConnectionAttempt.current.channel = null;
        lastConnectionAttempt.current.state = null;
        cableConnect();
      }, 100);
      lastConnectionAttempt.current.channel.unsubscribe();
    } else {
      // console.log('ACTION CABLE: unknown state - '  + lastConnectionAttempt.current.state)
    }
  };

  const cableConnect = () => {
    cableDisconnectIfNeeded();

    if (lastConnectionAttempt.current.state == "disconnecting") {
      console.log(
        "ACTION CABLE: disconnecting skipped - " +
          lastConnectionAttempt.current.slug
      );
      return;
    }

    if (
      lastConnectionAttempt.current.slug == params.current.slug &&
      lastConnectionAttempt.current.state == "connected"
    ) {
      console.log(
        "ACTION CABLE: skipping connection - " +
          lastConnectionAttempt.current.slug
      );
      return;
    }

    lastConnectionAttempt.current.slug = params.current.slug;
    lastConnectionAttempt.current.state = "connecting";

    performCableConnection();

    connectionIntervalId.current = setTimeout(() => {
      if (lastConnectionAttempt.current.state == "connecting") {
        console.log(
          "ACTION CABLE: forcing messages fetch 2 - " + params.current.slug
        );
        onFetchMessages(true);
      }
    }, 1000);
  };

  const performCableConnection = () => {
    console.log(
      "ACTION CABLE: connection attempt " + lastConnectionAttempt.current.slug
    );
    lastConnectionAttempt.current.channel = cable.subscriptions.create(
      {
        channel: "PodsChannel",
        pod_slug: params.current.slug,
        token: window.$currentUserAuthToken,
      },
      {
        connected: () => {
          console.log(
            `ACTION CABLE: connection data: ${currentConnectedSlug.current} == ${params.current.slug}`
          );

          if (lastConnectionAttempt.current.state == "connecting") {
            console.log(
              "ACTION CABLE: connected completed - " +
                lastConnectionAttempt.current.slug
            );
            lastConnectionAttempt.current.state = "connected";
            checkSearchParams();
            setTimeout(() => {
              markAsSeen();
            }, 5000);
            onFetchMessages(true);
            return;
          }
          console.log("ACTION CABLE: messages.length - " + messages.length);
          console.log(
            "ACTION CABLE: message slug - " + messages.length > 0 &&
              messages[0].pod_slug
          );
          if (
            messages.length == 0 ||
            (messages.length > 0 &&
              messages[0].pod_slug != lastConnectionAttempt.current.slug)
          ) {
            console.log(
              "ACTION CABLE: forcing messages fetch - " + params.current.slug
            );
            onFetchMessages(true);
          }
          console.log(
            "ACTION CABLE: connected skipped - " +
              lastConnectionAttempt.current.slug
          );
        },
        disconnected: () => {
          console.log(
            "ACTION CABLE: disconnected 2 - " +
              lastConnectionAttempt.current.slug
          );
        },
        received: (data) => {
          console.log(
            "ACTION CABLE: received - " + lastConnectionAttempt.current.slug
          );
          console.log(
            "ACTION CABLE: data - " +
              JSON.stringify(data) +
              " - params: " +
              params.current.slug +
              " - current: " +
              lastConnectionAttempt.current.slug
          );
          if (data.slug == lastConnectionAttempt.current.slug) {
            paginator.newMessage = true;
            setReceiveData(data);
            return;
          }
          console.log(
            "ACTION CABLE: received ignored - " +
              lastConnectionAttempt.current.slug
          );
        },
      }
    );
  };

  const markAsSeen = () => {
    NotificationModel.onMarkAsSeen(
      "pod_message",
      params.current.slug,
      (data) => {}
    );
  };

  const markReflectionAsSeen = (messageId) => {
    NotificationModel.onMarkAsSeen("reflection", messageId, (data) => {});
  };

  const onFetchMessages = (reset = false) => {
    if (channelPile.current && channelPile.current.length > 0) {
      return;
    }
    if (
      reset &&
      messages.length > 0 &&
      messages[0].pod_slug == params.current.slug
    ) {
      return;
    }

    const search = window.location.search;
    const _tag = new URLSearchParams(search).get("tag");
    const _pod_message_id = new URLSearchParams(search).get("pod_message_id");

    if (reset) {
      paginator.page = 1;
      messagesCopy.current = [];
      setMessages([]);
      loadingVariables.current = false;
    }

    if (_tag && tagFetch.current != _tag) {
      tagFetch.current = _tag;
      paginator.page = 1;
      messagesCopy.current = [];
      setMessages([]);
      loadingVariables.current = false;
    }

    if (!_tag && !_pod_message_id && paginator.page == 1) {
      // Only for Page 1 and no tag
      // Check storeJSON and use it always
      // Fetch messages and do silent update of storeJSON
      // Check fetched messages VS stored messages if there is at least one change replace the message State

      if (!queryParams.pod_message_id) {
        scrollToBottomAfter.current = true;
      }

      getJSON(`spaces`, `messages-${params.current.slug}`, (data) => {
        if (data && data.data.length > 0) {
          setMessages([...data.data]);
          messagesCopy.current = [...data.data];
          onFetchMessagesFromAPI(_tag, data.stored_at, data.data);
        } else {
          onFetchMessagesFromAPI(_tag);
        }
      });
    } else {
      onFetchMessagesFromAPI(_tag);
    }
  };

  const onFetchMessagesFromAPI = (_tag, storedAt = null, stored = null) => {
    if (params.current.slug) {
      MessageModel.onFetch(
        params.current.slug,
        { tag: _tag },
        paginator.page,
        (data) => {
          // loadingVariables,firstLoad = true;
          let lookForMessage = true;
          if (data.data.length > 0 && paginator.page > 1) {
            setMessages(data.data.concat(messages));
            messagesCopy.current = [...data.data.concat(messages)];
            lookForMessage = false;
          } else {
            if (data.data.length > 0 && paginator.page == 1) {
              storeJSON("spaces", `messages-${params.current.slug}`, { ...data });
  
              if (
                !storedAt ||
                (storedAt &&
                  anyFetchedMessageChanged(data.data, stored, storedAt))
              ) {
                console.log("SILENT UPDATE DONE MESSAGES");
                setMessages([...data.data]);
                messagesCopy.current = [...data.data];
                lookForMessage = false;
              }
            }
          }
          if (data.data.length == 0 && paginator.page > 1) {
            paginator.page--;
          }
          if (
            lookForMessage &&
            queryParams.pod_message_id &&
            messages.length > 0
          ) {
            searchForMessage(queryParams.pod_message_id);
          }
        },
        (data) => {}
      );
    }
  };

  const anyFetchedMessageChanged = (fetched, stored, storedAt) => {
    for (let i = 0; i < fetched.length; i++) {
      const objectA = fetched[i];
      const objectB = stored.find((obj) => obj.id === objectA.id);
      if (!objectB) {
        if (!atBottom.current) {
          dispatch(setNewMessage({ show: true, viewport: viewport.current }));
        }
        return true;
      }
      if (
        objectB &&
        (new Date(objectA.updated_at) > new Date(objectB.updated_at) ||
          objectA.reflections.length > objectB.reflections.length)
      ) {
        // display bar

        if (!atBottom.current) {
          dispatch(setNewMessage({ show: true, viewport: viewport.current }));
        }
        return true;
      }
    }

    for (let i = 0; i < stored.length; i++) {
      const objectA = stored[i];
      const objectB = fetched.find((obj) => obj.id === objectA.id);
      if (!objectB) {
        if (!atBottom.current) {
          dispatch(setNewMessage({ show: true, viewport: viewport.current }));
        }
        return true;
      }
      if (
        objectB &&
        (new Date(objectB.updated_at) > new Date(objectA.updated_at) ||
          objectB.reflections.length > objectA.reflections.length)
      ) {
        // display bar

        if (!atBottom.current) {
          dispatch(setNewMessage({ show: true, viewport: viewport.current }));
        }
        return true;
      }
    }

    return false;
  };

  const searchForMessage = (messageId) => {
    if (querySearchCount.current > 10) {
      return;
    }

    querySearchCount.current++;

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == messageId) {
        queryParams.pod_message_id = null;
        querySearchCount.current = 99;

        scrollToMessage(messageId);
        if (queryParams.reflection_id) {
          const newMessage = { ...messages[i] };
          newMessage.reflectionId = queryParams.reflection_id;
          queryParams.reflection_id = null;
          setSelectedMessage(newMessage);
          setOpenedReflections(true);
          markReflectionAsSeen(messageId);
          setReflectionsDrawerHeader(
            t("spaces.presentations.discussion.reflectionHeader")
          );
        }
        return;
      }
    }
    paginator.page++;
    onFetchMessages();
  };

  const onReceivedMessage = (messageId) => {
    MessageModel.onFetchById(
      messageId,
      params.current.slug,
      (data) => {
        if (data.user.id != user.id) {
          if (atBottom.current) {
            loadingVariables.current = false;
            scrollToBottomAfter.current = true;

            setTimeout(() => {
              scrollToBottom();
            }, 500);
          } else {
            //Trigger new message
            dispatch(setNewMessage({ show: true, viewport: viewport.current }));
          }
        } else {
          loadingVariables.current = false;
          scrollToBottomAfter.current = true;
          setTimeout(() => {
            scrollToBottom();
          }, 500);
        }

        let newMessages = [...messagesCopy.current];

        if (messagesCopy.current.length == 0) {
          messagesCopy.current = [...messages];
          newMessages = [...messagesCopy.current];
        }

        newMessages.push(data);
        messagesCopy.current.push(data);

        setMessages(newMessages);
        onRemovePileAction();
      },
      (data) => {
        onRemovePileAction();
      }
    );
  };

  const onUpdatedMessage = (messageId) => {
    MessageModel.onFetchById(
      messageId,
      params.current.slug,
      (data) => {
        loadingVariables.current = true;
        const newMessages = [...messages];

        for (let i = 0; i < newMessages.length; i++) {
          if (newMessages[i].id == messageId) {
            newMessages[i] = data;
            if (selectedMessage && messageId == selectedMessage.id) {
              setSelectedMessage({ ...newMessages[i] });
            }
            break;
          }
        }

        setMessages(newMessages);
        messagesCopy.current = [...newMessages];
        onRemovePileAction();
      },
      (data) => {
        onRemovePileAction();
      }
    );
  };

  const onDestroyedMessage = (messageId) => {
    const newMessages = [...messages];

    for (let i = 0; i < newMessages.length; i++) {
      if (newMessages[i].id == messageId) {
        newMessages.splice(i, 1);
        break;
      }
    }

    setMessages(newMessages);
    messagesCopy.current = [...newMessages];
    onRemovePileAction();
  };

  const onPinnedMessage = (messageId) => {
    const newSpace = { ...space };
    newSpace.pin_message = messageId;
    dispatch(setSpace(newSpace));
    onRemovePileAction();
  };

  const onRemovePileAction = () => {
    if (channelPile.current.length > 0) {
      channelPile.current[0].state = "done";
    }
  };

  const onScrollTop = () => {
    paginator.page++;
    onFetchMessages();
  };

  const onSave = (json, html) => {
    json.attrs = { uid: `message-${new Date().getTime()}` };
    json.type = "paragraph";
    MessageModel.onCreate(
      params.current.slug,
      { json, html },
      (data) => {
        storeJSON("spaces", `discussion-input-${params.current.slug}`, {
          json: "",
          html: "",
        });
        if (props.onSave) {
          props.onSave();
        }
      },
      (data) => {}
    );
  };

  const onOpenReflections = (messageId) => {
    setOpenedReflections(true);
    setReflectionsDrawerHeader(
      t("spaces.presentations.discussion.reflectionHeader")
    );

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == messageId) {
        markReflectionAsSeen(messageId);
        setSelectedMessage(messages[i]);
        break;
      }
    }
  };

  const scrollToBottom = () => {
    // setIsFirstLoad(false);
    if (viewport.current) {
      // viewport.current.style.scrollBehavior = "auto";
    }
    setTimeout(() => {
      if (viewport.current) {
        const top = viewport.current.scrollHeight + 2000;
        viewport.current.scrollTo({ top: top, behavior: "instant" });
        // viewport.current.style.scrollBehavior = "smooth";
      }
    }, 500);
  };

  const scrollToMessage = (messageId) => {
    setTimeout(() => {
      try {
        const $scrollTo = document.getElementById(`msg_${messageId}`);
        const top =
          $scrollTo.offsetTop -
          viewport.current.offsetTop +
          viewport.current.scrollTop +
          80;

        viewport.current.scrollTo({ top: top, behavior: "instant" });
      } catch (error) {}
    }, 500);
  };

  const onViewPinnedMessage = (message) => {
    setSelectedMessage(message);
    setOpenedReflections(true);
    setReflectionsDrawerHeader(
      t("spaces.presentations.discussion.pinnedMessageHeader")
    );
  };

  const onScrollPositionChange = (pos) => {
    const div = viewport.current;
    // const div = document.getElementsByClassName("app-main-frame")[0]
    clearScrollTimeouts();

    if (pos.y + div.clientHeight >= div.scrollHeight) {
      const scrollTimeoutId = setTimeout(() => {
        dispatch(setNewMessage({ show: false }));
        atBottom.current = true;
      }, 1000);
      scrollTimeoutIds.current.push(scrollTimeoutId);
    } else if (pos.y == 0) {
      atBottom.current = false;

      const scrollTimeoutId = setTimeout(() => {
        // At top
        onScrollTop();
      }, 300);
      scrollTimeoutIds.current.push(scrollTimeoutId);
    } else {
      // In between
      atBottom.current = false;
    }
  };

  const clearScrollTimeouts = () => {
    for (let i = 0; i < scrollTimeoutIds.current.length; i++) {
      const id = scrollTimeoutIds.current[i];
      clearTimeout(id);
    }
  };

  const getDirectMessageName = () => {
    const otherUserName = space.name
      .replace(`${user.name}`, ``)
      .replace(`and`, ``)
      .trim();
    return `Conversation with ${otherUserName}`;
  };

  const onFetchContext = () => {
    const ctrl2 = new AbortController();
    MessageModel.onFetchAiSummary(
      params.current.slug,
      (data) => {
        setAiSummary({ ...data });
      },
      (data) => {
        // Something went wrong
      },
      ctrl2.signal
    );
  };

  if (props.loading) {
    return <Loading type="discussion" />;
  }

  return (
    <Drawer
      classNames={{
        drawer: classes.drawerWrap,
        closeButton: classes.closeButton,
      }}
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text className={classes.themeTextColor} weight="bold">
          {space && space.name && getDirectMessageName()}
        </Text>
      }
      padding="xl"
      size={700}
    >
      <Stack
        className={classes.spaceContainer}
        spacing={0}
        style={{ height: "100%" }}
      >
        <ScrollArea
          id="main-scroll-area"
          onScrollPositionChange={onScrollPositionChange}
          className="content-scrollview"
          viewportRef={viewport}
          style={{ height: "100%" }}
        >
          {aiSummary.tiptap_short_content && (
            <Box>
              <TiptapReadonly content={aiSummary.tiptap_short_content} />
              <Divider mt={30} mb={30} className={classes.dividerStyle} />
            </Box>
          )}
          {messages &&
            messages.length > 0 &&
            messages[0].pod_slug == params.current.slug && (
              <Box
                sx={(theme) => ({
                  height: "100%",
                  paddingBottom: "15px",
                })}
              >
                {messages.map((message) => {
                  return (
                    <Message
                      key={`message_${message.id}`}
                      spaceId={params.current.slug}
                      spaceVersion={"discussion"}
                      messageId={message.id}
                      htmlId={`${message.type}_${message.id}`}
                      messageCreatedAt={message.created_at}
                      messageTitle={message.post ? message.post.subject : null}
                      messageContent={
                        message.version == "new"
                          ? message.content
                          : message.message_body
                      }
                      messageUnseenCount={message.unseen_count}
                      tags={message.tags}
                      reflections={message.reflections}
                      user={message.user ? message.user : {}}
                      onOpenReflections={onOpenReflections}
                      version={message.version}
                      canPin={false}
                      canTag={false}
                      canLike={message.user ? message.user.can_like : false}
                      canReflect={tiptapSidemenu.allow_reflections}
                      canEdit={message.user ? message.user.can_edit : false}
                      canDelete={message.user ? message.user.can_delete : false}
                      onUpdated={onUpdatedMessage}
                      type={"pod_message"}
                      tagging={tagging}
                      setTagging={setTagging}
                      isPinned={false}
                      text={message.text}
                      isLiked={message.is_liked}
                      likesCount={message.likes_count}
                    />
                  );
                })}
              </Box>
            )}
        </ScrollArea>

        {tiptapSidemenu.allow_reflections && (
          <Drawer
            opened={openedReflections}
            onClose={() => setOpenedReflections(false)}
            title={
              <Group
                sx={{
                  "&:hover": {
                    cursor: window.$showBackButtonModal ? "pointer" : "default",
                  },
                }}
                onClick={(event) =>
                  window.$showBackButtonModal
                    ? setOpenedReflections(false)
                    : null
                }
              >
                {window.$showBackButtonModal && (
                  <FontAwesomeIcon size="md" icon={solid("arrow-left")} />
                )}
                <Text>
                  {window.$showBackButtonModal
                    ? t("spaces.presentations.discussion.backToPost")
                    : t("spaces.presentations.discussion.reflectionHeader")}
                </Text>
              </Group>
            }
            padding="xl"
            size={600}
            classNames={{
              drawer: classes.drawer,
              header: classes.drawerHeader,
            }}
          >
            <Reflections
              spaceId={params.current.slug}
              spaceVersion={"discussion"}
              canReflect={true}
              message={selectedMessage}
              onUpdatedMessage={onUpdatedMessage}
              useReflectCallback={true}
              tagging={reflectionsTagging}
              setTagging={setReflectionsTagging}
            />
          </Drawer>
        )}

        <Box>
          <Box mb={40}>
            <TiptapSimple
              editable={true}
              onSave={onSave}
              modals={true}
              contextDb={`discussion-input-${params.current.slug}`}
            />
          </Box>
        </Box>
      </Stack>
    </Drawer>
  );
}
