import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import useSidebarStyles from "../../../utils/SidebarStyles";
import trackMixpanel from "../../../utils/TrackMixpanel";
import { useTranslation } from "react-i18next";
import SpaceSettingModel from "../../../models/SpaceSetting";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import tinycolor from "tinycolor2";

import {
  createStyles,
  Box,
  Text,
  UnstyledButton,
  Group,
  Tooltip,
} from "@mantine/core";

const useStyles = createStyles((theme, { colors, settingsView }) => ({
  spaceNameWrap: {
    backgroundColor: "#ff0000",
    color: colors.primary_button_text_color,
    margin: "20px 20px 0px 20px",
    padding: "10px 10px 10px 20px",
    borderRadius: 5,
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    fontWeight: 500,
    alignItems: "center",
  },
  spaceNameClose: {
    color: "#fff",
    fontWeight: 600,
    padding: "5px 8px",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(15)
        .toString(),
    },
  },
}));

export default function SidebarTutorial(props) {
  const location = useLocation();

  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const { settingsView } = useSidebarStyles();
  const { classes, cx } = useStyles({ colors, settingsView });
  const navigate = useNavigate();

  if (!space.slug) {
    return null;
  }

  return (
    <Box
      className={classes.spaceNameWrap}
    >
      <Tooltip
        disabled={window.$isTouchDevice && window.$isTouchDevice()}
        placement="start"
        gutter={20}
        label={"This is a a template space that is only visible to you."}
        withArrow
      >
        <Group>
          <Text weight={600}>
            {"Template space"}
          </Text>
        </Group>
      </Tooltip>
    </Box>
  );
}
