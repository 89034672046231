import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Stack, NativeSelect } from "@mantine/core";

export default function DisplayAs(props) {
  const location = useLocation();
  const timeoutId = useRef(null);

  const loading = useRef(false);

  const tiptap = useSelector((state) => state.tiptap);
  const [displayAs, setDisplayAs] = useState("link");

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newDisplayAs = displayAs;

    let edited = false;

    if (newDisplayAs) {
      edited = true;

      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        viewAs: newDisplayAs,
      });
    }

    if (edited) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        if (displayAs) {
          props.saveNodeData();
        }
      }, 2000);
    }
  }, [displayAs]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.viewAs) {
      loading.current = props.element.node.attrs.uid;
      setDisplayAs(props.element.node.attrs.viewAs);
    }
  }, [props.element]);

  return (
    <Stack mt={20}>
      <NativeSelect
        value={displayAs}
        onChange={(event) => setDisplayAs(event.target.value)}
        label="Display as"
        placeholder="Select a display option"
        data={[
          { value: "list", label: "List item" },
          { value: "vcard", label: "Vertical card" },
          { value: "hcard", label: "Horizontal card" },
          { value: "button", label: "Button" },
          { value: "link", label: "Link" },
        ]}
        styles={{
          label: {
            fontWeight: 400,
            color: "#000",
          },
        }}
      />
    </Stack>
  );
}
