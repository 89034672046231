import React, { useState, useEffect, useRef, memo } from "react";

import { useNavigate, Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Image,
  Avatar,
  AvatarsGroup,
  Group,
  Stack,
  Text,
  Menu,
  createStyles,
  Skeleton,
  ActionIcon,
  Tooltip,
  Button,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";

import SpaceModel from "../../../models/Space";
import DefaultAvatar from "../../../src/images/fallback/default.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { DateTime } from "luxon";

import useLangUser from "../../../utils/LangUser";
import formatDate from "../../../utils/formatDate";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import isDark from "../../../utils/IsDarkColor";

import Swal from "sweetalert2";

import { setTiptapSidemenu } from "../../../store/app";
import { useTranslation } from "react-i18next";

import LikeButton from "../../likes/Button";

const useStyles = createStyles(
  (theme, { colors, publishOnDate, isDark, listFlatView }, getRef) => ({
    spaceListItem: {
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: listFlatView ? "0px" : "10px",
      backgroundColor: listFlatView
        ? "none"
        : isDark(colors.background_color)
        ? tinycolor(colors.background_color).lighten(1).toString()
        : tinycolor(colors.background_color).darken(1).toString(),
      // backgroundColor: theme.colors.gray[0],
      // backgroundColor: isSpaceThemed(colors)
      //   ? tinycolor(colors.sidebar_background_color).toString()
      //   : theme.colors.gray[0],
      // background: !publishOnDate ? "repeating-linear-gradient(-45deg,#ddd,#ddd 1px,#f9f9f9 1px,#f9f9f9 10px)" : "",
      // minHeight: 95,
      // height: 210,
      // padding: "15px 20px",
      // margin: "15px 0px",
      // boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",

      // border: isSpaceThemed(colors)
      //   ? // ? `1px solid ${colors.default_text_color}`
      //     `1px solid ${
      //       isDark(colors.background_color)
      //         ? tinycolor(colors.background_color).lighten(20).toString()
      //         : tinycolor(colors.background_color).darken(20).toString()
      //     }`
      //   : `1px solid #e9ecef`,

      border: listFlatView
        ? "none"
        : isSpaceThemed(colors)
        ? `1px solid ${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(20).toString()
              : tinycolor(colors.background_color).darken(20).toString()
          }`
        : `1px solid #e9ecef`,

      // "&:hover": {
      //   // backgroundColor: theme.colors.gray[0],
      //   // backgroundColor: isSpaceThemed(colors)
      //   //   ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
      //   //   : theme.colors.gray[0],
      //   // backgroundColor: tinycolor(colors.background_color).darken(2).toString(),
      //   boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.4)",
      //   cursor: "pointer",
      //   // color: "#000",
      //   textDecoration: "none",
      // },

      "&:hover": {
        // boxShadow: listFlatView ? "none" : "0px 0px 2px 0px rgba(0,0,0,0.4)",
        boxShadow: listFlatView ? "none" : "0px 0px 5px 0px rgba(0,0,0,0.2)",
        // backgroundColor: tinycolor(colors.background_color)
        //   .darken(3)
        //   .toString(),

        backgroundColor: isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(2).toString()
          : tinycolor(colors.background_color).darken(2).toString(),
        cursor: "pointer",
        textDecoration: "none",
      },
    },

    title: {
      textDecoration: "none",
      // color: "#000",
      // color: isSpaceThemed(colors) ? colors.sidebar_text_color : "#000",
      color: colors.default_text_color,
      fontSize: "1.6em",
      lineHeight: "1.3em",
      fontWeight: 700,
      margin: 0,

      // height: 50,
      // backgroundColor: "red",

      "&:hover": {
        textDecoration: "none",
      },
    },

    titleGroup: {
      margin: listFlatView ? "15px 10px 0px" : "15px 20px 0px",
    },

    abstract: {
      textDecoration: "none",
      // color: isSpaceThemed(colors) ? colors.sidebar_text_color : "#000",
      color: colors.default_text_color,
      "&:hover": {
        textDecoration: "none",
      },
    },

    abstractWrap: {
      // height: 60,
      // width: "100%",
      margin: listFlatView ? "5px 10px 10px" : "5px 20px 10px",
      // backgroundColor: "blue",
    },

    titleAndAbstractWrap: {
      width: "100%",
      // height: 150,
    },

    avatarWrap: {
      margin: listFlatView ? "0px 10px 10px" : "0px 20px 10px",
    },

    footerWrap: {
      margin: listFlatView ? "0px 10px 10px 12px" : "0px 10px 10px 22px",
    },

    tagWrap: {
      margin: listFlatView ? "0px 10px 5px 10px" : "0px 10px 5px 15px",
    },

    // titleHasHeader: {
    //   color: "#fff",
    //   textShadow: "1px 1px #000000",
    //   textDecoration: "none",
    //
    //   "&:hover": {
    //     color: "#fff",
    //     textDecoration: "none",
    //   },
    // },

    thumbnailImageStyle: {
      width: "100%",
      // paddingLeft: 10,
      // paddingRight: 10,
      borderTopLeftRadius: listFlatView ? 0 : 10,
      borderTopRightRadius: listFlatView ? 0 : 10,
    },

    footerElements: {
      cursor: "pointer",
      color: isDark(colors.default_text_color)
        ? tinycolor(colors.default_text_color).lighten(30).toString()
        : tinycolor(colors.default_text_color).darken(30).toString(),
      "&:hover": {
        color: colors.default_text_color,
      },
    },

    buttonStyle: {
      // borderRadius: theme.radius.md,
      color: tinycolor(colors.default_text_color).lighten(50).toString(),
      // backgroundColor: "red",
      // border: '1px solid red',
      // paddingLeft: 12,
      // paddingRight: 12,

      "&:hover": {
        backgroundColor: !isSpaceThemed(colors)
          ? theme.colors.gray[1]
          : tinycolor(colors.background_color).lighten(3).toString(),
      },
    },

    date: {
      textDecoration: "none",
      // color: "#000",
      color: isSpaceThemed(colors) ? colors.sidebar_text_color : "#000",

      "&:hover": {
        textDecoration: "none",
      },
    },

    dateHasHeader: {
      color: "#fff",
      textShadow: "1px 1px #000000",

      "&:hover": {
        color: "#fff",
        textDecoration: "none",
      },
    },

    dateSection: {
      margin: listFlatView ? "5px 10px 5px" : "5px 20px 5px",
    },

    dateBlockWrap: {
      padding: "4px 10px",
      borderRadius: 5,
      height: "100%",
      // border: "1px solid #ddd",
      border: `1px solid ${
        isSpaceThemed(colors) ? colors.default_text_color : "#000"
      }`,
      // marginRight: 5,
      color: isSpaceThemed(colors) ? colors.default_text_color : "#000",
    },

    dateBlockWrapHasHeader: {
      color: "#fff",
      textShadow: "1px 1px #000000",
      border: `1px solid #fff`,
      // boxShadow: "1px 1px #000000",
      boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.5)",

      "&:hover": {
        color: "#fff",
        textDecoration: "none",
      },
    },

    dateBlockDate: {
      fontWeight: 800,
      fontSize: 15,
      lineHeight: 1,
      marginBottom: 3,
    },

    dateBlockMonth: {
      lineHeight: 1,
      fontSize: 11,
    },

    dateScheduled: {
      fontSize: 13,
      lineHeight: "1.4",
    },

    titleSm: {
      fontSize: "1.2em",
    },

    dateSm: {
      fontSize: ".9em",
    },
  })
);

const ListContent = (props) => {
  const { t, i18n } = useTranslation();
  const isUnpublished = () => {
    return (
      space && space.launch_on_date && new Date() < new Date(space.launch_time)
    );
  };

  const colors = useSelector((state) => state.colors);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [space, setSpace] = useState(null);
  const [permissions, setPermissions] = useState({ unload: true });
  const { classes, cx, theme } = useStyles({
    colors,
    publishOnDate: isUnpublished() ? space.launch_on_date : "",
    isDark,
    listFlatView: props.listFlatView,
  });
  let _width = theme.other.breakpoints.lg;
  const { ref, width, height } = useElementSize();
  const [avatarLimit, setAvatarLimit] = useState(4);
  const [showAvatars, setShowAvatars] = useState(true);

  const { language, setLangUser } = useLangUser();

  const updateSpace = useSelector((state) => state.listSpaceToUpdate);

  const propsTimeoutId = useRef();
  const updating = useRef();
  const updateCount = useRef(0);

  useEffect(() => {
    if (space && updateSpace.slug == space.slug) {
      updating.current = true;
      onFetchSpace(props.fetchId ? props.fetchId : updateSpace.slug);
    }
  }, [updateSpace]);

  useEffect(() => {
    if (updating.current) {
      updating.current = false;
      return;
    }
    if (props.space) {
      updating.current = true;
      setSpace({ ...props.space });
    } else if (!space && props.fetch) {
      onFetchSpace(props.fetchId);
    }
  }, [space]);

  useEffect(() => {
    if (props.space && updateCount.current != props.space.update_count) {
      updateCount.current = props.space.update_count
        ? props.space.update_count
        : 0;
      // if (propsTimeoutId.current) {
      //   clearTimeout(propsTimeoutId.current);
      // }
      // propsTimeoutId.current = setTimeout(() => {
      // }, 1000);
      updating.current = true;
      setSpace({ ...props.space });
    }
  }, [props]);

  useEffect(() => {
    _width = width > 0 ? width : _width;

    if (_width < theme.other.breakpoints.xs) {
      setShowAvatars(false);
    } else if (_width < theme.other.breakpoints.md) {
      setShowAvatars(true);
      setAvatarLimit(4);
    } else {
      setShowAvatars(true);
      setAvatarLimit(4);
    }
  }, [width]);

  const onFetchSpace = (id) => {
    const ctrl = new AbortController();
    SpaceModel.onFetchSimpleShow(
      id,
      ["members", "last_messages"],
      (data) => {
        try {
          setSpace({
            ...data.space,
            permissions: { ...data.permissions },
            colors: { ...data.colors },
            last_messages: last_messages,
            last_message: last_message,
            abstract: data.abstract ? data.abstract : data.space.abstract,
          });
        } catch {
          setSpace({
            ...data.space,
            permissions: { ...data.permissions },
            abstract: data.abstract ? data.abstract : data.space.abstract,
          });
        }
        if (props.onSpaceFetched) {
          props.onSpaceFetched(data);
        }
      },
      (data) => {
        // Something went wrong
      },
      ctrl.signal
    );
  };

  const onAvatarClick = (event, member) => {
    event.stopPropagation();
    event.preventDefault();
    navigate(member.link);
  };

  const formatDateTime = (timestamp) => {
    if (space && space.launch_time) {
      const d = new Date(space.launch_time).toISOString();
      return DateTime.fromISO(d).toFormat("ff");
    }
  };

  const onPin = () => {
    SpaceModel.onPin(
      space.slug,
      (data) => {
        if (props.onUpdate) {
          props.onUpdate(space.id, data.is_pinned ? "pin" : "unpin");
        }
      },
      (data) => {}
    );
  };

  const onThumbnail = () => {
    props.onUpdate(space.slug, "thumbnail-upload");
  };

  const onRemoveThumbnail = () => {
    props.onUpdate(space.slug, "thumbnail-delete");
  };

  const onDelete = () => {
    if (space.is_linked) {
      Swal.fire({
        title: t("spaces.presentations.gridPost.onDelete.alertTitle"),
        html: t("spaces.presentations.gridPost.onDelete.alertMessage"),
        showDenyButton: true,
        confirmButtonText: t("spaces.presentations.gridPost.onDelete.confirm"),
        denyButtonText: t("spaces.presentations.gridPost.onDelete.deny"),
        confirmButtonColor: colors.red,
        denyButtonColor: colors.gray,
      }).then((result) => {
        if (result.isConfirmed) {
          SpaceModel.onUnlink(
            space.slug,
            window.location.href.split("/")[4],
            (data) => {
              if (props.onUpdate) {
                props.onUpdate(space.id, "delete");
              }
            },
            (data) => {}
          );
        } else if (result.isDenied) {
        }
      });
    } else {
      Swal.fire({
        title: t("spaces.presentations.gridPost.onDelete.alertTitle"),
        html: t("spaces.presentations.gridPost.onDelete.alertMessage2"),
        showDenyButton: true,
        confirmButtonText: t("spaces.presentations.gridPost.onDelete.confirm"),
        denyButtonText: t("spaces.presentations.gridPost.onDelete.deny"),
        confirmButtonColor: colors.red,
        denyButtonColor: colors.gray,
      }).then((result) => {
        if (result.isConfirmed) {
          SpaceModel.onDestroy(
            space.slug,
            (data) => {
              if (props.onUpdate) {
                props.onUpdate(space.id, "delete");
              }
            },
            (data) => {}
          );
        } else if (result.isDenied) {
        }
      });
    }
  };

  const openProfile = (event) => {
    event.stopPropagation();
    if (space.user) {
      dispatch(
        setTiptapSidemenu({ opened: "profile", option: space.user.username })
      );
    }
  };

  const getLink = () => {
    return `/space/${space.slug}/${space.version}`;
  };

  const onEdit = () => {
    navigate(`${getLink()}?edit=true`);
  };

  const onMenuOpenned = (event) => {
    event.stopPropagation();
    if (permissions.unload) {
      SpaceModel.onPermissions(
        space.slug,
        (data) => {
          setPermissions(data);
        },
        (data) => {}
      );
    }
  };

  return (
    <>
      {space ? (
        <Stack
          spacing={props.listFlatView ? 10 : 15}
          justify="space-between"
          className={classes.spaceListItem}
          ref={ref}
        >
          <Stack spacing={0} className={classes.titleAndAbstractWrap}>
            {space.gallery_feature &&
              space.gallery_feature.url &&
              props.listShowThumbnail && (
                <Image
                  // width={"100%"}
                  classNames={{
                    image: classes.thumbnailImageStyle,
                  }}
                  src={space.gallery_feature.url}
                  alt={space.name}
                />
              )}
            {space.name.length > 20 ? (
              <Group spacing={10} className={classes.titleGroup}>
                <Tooltip width={250} wrapLines label={space.name} withArrow>
                  <Text
                    className={cx(classes.title, {
                      [classes.titleSm]: width < theme.other.breakpoints.xs,
                    })}
                    lineClamp={2}
                  >
                    {space.activity && space.activity > 0 ? (
                      <Box
                        sx={{
                          display: "inline-block",
                          width: "13px",
                          height: "13px",
                          borderRadius: "13px",
                          marginRight: "10px",
                          backgroundColor: colors.default_badge_color,
                        }}
                      >
                        {""}
                      </Box>
                    ) : (
                      ""
                    )}
                    {space.name}
                  </Text>
                </Tooltip>
              </Group>
            ) : (
              <Group spacing={10} className={classes.titleGroup}>
                <Text
                  className={cx(classes.title, {
                    [classes.titleSm]: width < theme.other.breakpoints.xs,
                  })}
                  lineClamp={2}
                >
                  {space.activity && space.activity > 0 ? (
                    <Box
                      sx={{
                        display: "inline-block",
                        width: "13px",
                        height: "13px",
                        borderRadius: "13px",
                        marginRight: "10px",
                        backgroundColor: colors.default_badge_color,
                      }}
                    >
                      {""}
                    </Box>
                  ) : (
                    ""
                  )}
                  {space.name}
                </Text>
              </Group>
            )}
            {space.calendar.has_start_date && (
              <Group className={classes.dateSection} spacing={10} noWrap>
                <Stack
                  spacing={0}
                  align="center"
                  // className={classes.dateBlockWrap}
                  className={classes.dateBlockWrap}
                >
                  <Box className={classes.dateBlockDate}>
                    {DateTime.fromISO(space.calendar.raw_start_date)
                      .setLocale(language)
                      .toFormat("d")}
                  </Box>
                  <Box className={classes.dateBlockMonth}>
                    {DateTime.fromISO(space.calendar.raw_start_date)
                      .setLocale(language)
                      .toFormat("LLL")}
                  </Box>
                </Stack>

                <Text className={cx(classes.abstract, classes.dateScheduled)}>
                  {formatDate(space.calendar, language)}
                </Text>
              </Group>
            )}
            {props.listShowPreviewText && (
              <Box className={classes.abstractWrap}>
                <Text className={classes.abstract} lineClamp={2}>
                  {space.abstract}
                </Text>
              </Box>
            )}
          </Stack>
          {!props.noActions && (
            <Stack
              justify="flex-end"
              spacing={0}
              sx={{
                width: "100%",
              }}
            >
              {props.showAuthor && (
                <Group
                  spacing={10}
                  className={classes.avatarWrap}
                  noWrap
                  // sx={{ width: "100%" }}
                >
                  <Avatar
                    onClick={(event) => openProfile(event)}
                    radius="xl"
                    size={40}
                    key={`member-avatar-${new Date()}`}
                    src={
                      space.user && space.user.avatar && space.user.avatar.url
                        ? space.user.avatar.url
                        : DefaultAvatar
                    }
                    component="span"
                  />
                  <Stack spacing={0}>
                    <Text
                      onClick={(event) => openProfile(event)}
                      className={classes.abstract}
                      weight={600}
                      sx={{
                        lineHeight: "1",
                      }}
                      mb={6}
                    >
                      {space.user
                        ? space.user.full_name
                        : "No longer registered"}
                    </Text>
                    {props.listShowTimestamp && (
                      <Text
                        className={cx(classes.abstract, classes.datePosted)}
                        size="xs"
                        sx={{
                          lineHeight: "1",
                        }}
                      >
                        {formatDate(
                          { raw_start_date: space.created_at },
                          language,
                          "LLL d",
                          "LLL d"
                        )}
                      </Text>
                    )}
                  </Stack>
                </Group>
              )}
              {space.tagged_with && (
                <Box className={classes.tagWrap}>
                  {space.tagged_with.map((tag) => {
                    return (
                      <Button
                        sx={(theme) => ({
                          backgroundColor:
                            theme.other.tagColors[tag.color].backgroundColor,
                          color: theme.other.tagColors[tag.color].textColor,
                          "&:hover": {
                            backgroundColor:
                              theme.other.tagColors[tag.color].backgroundColor,
                          },
                        })}
                        styles={{
                          root: {
                            height: 20,
                            marginRight: 5,
                          },
                          label: { fontSize: 10 },
                        }}
                        size="xs"
                        radius="xl"
                      >
                        {tag.name}
                      </Button>
                    );
                  })}
                </Box>
              )}
              <Group
                className={classes.footerWrap}
                spacing={0}
                noWrap
                position="apart"
                // sx={{
                //   display: "flex",
                //   alignItems: "center",
                // }}
              >
                <Group>
                  {props.listAllowLikes && (
                    <LikeButton
                      spaceId={space.id}
                      liked={space.is_liked}
                      count={space.likes_count}
                    />
                  )}
                  {props.listShowComments && (
                    <Group
                      className={classes.footerElements}
                      spacing={5}
                      noWrap
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon size="md" icon={regular("comment")} />
                      <Text
                        className={cx(classes.abstract, classes.footerElements)}
                      >
                        {space.comments_count}
                      </Text>
                    </Group>
                  )}

                  {props.listShowMembers ? (
                    <>
                      <AvatarsGroup
                        ml={5}
                        limit={avatarLimit}
                        total={space.members_count}
                        spacing={30}
                        // radius={10}
                        styles={{
                          root: {
                            justifyContent: "right",
                            cursor: "pointer",
                            paddingLeft: 12,
                          },
                          child: {
                            width: "auto",
                            border: `2px solid #fff !important`,
                            minWidth: 25,
                            height: 29,
                          },
                          truncated: {
                            backgroundColor: theme.colors.gray[2],
                            paddingRight: 5,
                            paddingLeft: 5,
                          },
                        }}
                      >
                        {space.members.map((member) => {
                          return (
                            <Avatar
                              styles={{
                                image: {
                                  width: 25,
                                  height: 25,
                                },
                                // root: { border: "none !important" }
                                // root: { border: `2px solid ${colors.header_color} !important` }
                              }}
                              key={`member-avatar-${member.id}`}
                              src={
                                member.avatar.url
                                  ? member.avatar.url
                                  : DefaultAvatar
                              }
                              component="span"
                            />
                          );
                        })}
                      </AvatarsGroup>
                    </>
                  ) : (
                    ""
                  )}
                </Group>

                <Group className={classes.footerElements} spacing={10}>
                  {space.is_pinned.is_pinned && (
                    <FontAwesomeIcon icon={solid("thumbtack")} />
                  )}
                  {!props.noActions && (
                    <Menu
                      onClick={(event) => onMenuOpenned(event)}
                      withArrow
                      placement="end"
                      control={
                        <ActionIcon
                          onClick={(event) => onMenuOpenned(event)}
                          classNames={{ root: classes.buttonStyle }}
                        >
                          <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
                        </ActionIcon>
                      }
                    >
                      <Menu.Item to={getLink()} component={Link}>
                        {t("spaces.header.buttons.openFullScreen")}
                      </Menu.Item>
                      {props.canPin && (
                        <Menu.Item onClick={() => onPin()}>
                          {space.is_pinned.is_pinned
                            ? t("spaces.presentations.gridPost.actions.unpin")
                            : t("spaces.presentations.gridPost.actions.pin")}
                        </Menu.Item>
                      )}
                      {permissions.can_edit && !space.gallery_feature.url && (
                        <Menu.Item onClick={() => onThumbnail()}>
                          {t(
                            "spaces.presentations.gridPost.actions.addThumbnail"
                          )}
                        </Menu.Item>
                      )}
                      {permissions.can_edit && space.gallery_feature.url && (
                        <Menu.Item onClick={() => onRemoveThumbnail()}>
                          {t(
                            "spaces.presentations.gridPost.actions.removeThumbnail"
                          )}
                        </Menu.Item>
                      )}
                      {permissions.can_edit && (
                        <Menu.Item onClick={() => onEdit()}>
                          {t("spaces.header.buttons.edit")}{" "}
                          {`${
                            space.is_post
                              ? t("spaces.header.buttons.post")
                              : t("spaces.header.buttons.page")
                          }`}
                        </Menu.Item>
                      )}
                      {!space.isPost && permissions.can_delete && (
                        <Menu.Item
                          onClick={() =>
                            props.onDuplicate(space.slug, space.name)
                          }
                        >
                          {t("spaces.presentations.gridPost.actions.duplicate")}
                        </Menu.Item>
                      )}
                      {permissions.can_delete && (
                        <Menu.Item onClick={() => onDelete()}>
                          {space.is_linked
                            ? t("spaces.presentations.gridPost.actions.unlink")
                            : t("spaces.presentations.gridPost.actions.delete")}
                        </Menu.Item>
                      )}
                    </Menu>
                  )}
                </Group>
              </Group>
            </Stack>
          )}
        </Stack>
      ) : (
        <Box className={cx(classes.spaceListItem)} ref={ref}>
          <Group
            sx={{
              width: "100%",
            }}
            position="apart"
            spacing="sm"
            noWrap
          >
            <Stack
              spacing={0}
              sx={(theme) => ({
                height: "100%",
              })}
            >
              <Group spacing="sm">
                <Skeleton height={30} width="50px" radius="sm" />
              </Group>
            </Stack>
            <Group noWrap position="right" spacing={10}>
              <Skeleton height={30} width="30px" radius="sm" />
              <Skeleton height={30} width="30px" radius="sm" />
              <Skeleton height={30} width="30px" radius="sm" />
            </Group>
          </Group>
        </Box>
      )}
    </>
  );
};

export default ListContent;
