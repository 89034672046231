import React, { useState, useEffect, useRef } from "react";

import { Text, Switch, Group, Box, createStyles } from "@mantine/core";

import { DatePicker, TimeInput } from "@mantine/dates";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import PremiumModal from "../../../app/PremiumModal";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
}));

export default function Scheduling(props) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const space = useSelector((state) => state.space);

  const [showStartDate, setShowStartDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");

  const [showLaunchDate, setShowLaunchDate] = useState(false);
  const [launchDate, setLaunchDate] = useState("");
  const [launchTime, setLaunchTime] = useState("");

  const [showArchiveDate, setShowArchiveDate] = useState(false);
  const [archiveDate, setArchiveDate] = useState("");
  const [archiveTime, setArchiveTime] = useState("");

  const [showReadonlyDate, setShowReadonlyDate] = useState(false);
  const [readonlyDate, setReadonlyDate] = useState("");
  const [readonlyTime, setReadonlyTime] = useState("");
  const [disableActions, setDisableActions] = useState(false);

  const [opened, setOpened] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  const propsLoaded = useRef();
  const lastSpaceId = useRef();

  useEffect(() => {
    return () => {
      propsLoaded.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.settings) {
      return;
    }

    if (propsLoaded.current && props.settings.id == lastSpaceId.current) {
      return;
    }
    propsLoaded.current = false;
    // setDisableActions(true);
    lastSpaceId.current = props.settings.id;

    setShowStartDate(props.settings.show_start_date);
    if (props.settings.start_date) {
      setStartDate(new Date(props.settings.start_date));
      setStartTime(new Date(props.settings.start_date));
    }
    if (props.settings.end_date) {
      setEndDate(new Date(props.settings.end_date));
      setEndTime(new Date(props.settings.end_date));
    }

    setShowLaunchDate(props.settings.launch_on_date);
    if (props.settings.launch_time) {
      setLaunchDate(new Date(props.settings.launch_time));
      setLaunchTime(new Date(props.settings.launch_time));
    }

    setShowArchiveDate(props.settings.archive_on_date);
    if (props.settings.archive_time) {
      setArchiveDate(new Date(props.settings.archive_time));
      setArchiveTime(new Date(props.settings.archive_time));
    }

    setShowReadonlyDate(props.settings.readonly_on_date);
    if (props.settings.readonly_time) {
      setReadonlyDate(new Date(props.settings.readonly_time));
      setReadonlyTime(new Date(props.settings.readonly_time));
    }

    setTimeout(() => {
      propsLoaded.current = true;
      // setDisableActions(false);
    }, 500);
  }, [props]);

  useEffect(() => {
    setValidatePremium(
      space && space.permissions && space.permissions.can_schedule_states
    );
  }, [space]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: { show_start_date: showStartDate },
      });
    }
  }, [showStartDate]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: {
          start_date: mergeDateAndTime(startDate, startTime),
        },
      });
    }
  }, [startDate, startTime]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: {
          end_date: mergeDateAndTime(endDate, endTime),
        },
      });
    }
  }, [endDate, endTime]);

  useEffect(() => {
    if (showLaunchDate && !validatePremium[0] && propsLoaded.current) {
      setShowLaunchDate(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: { launch_on_date: showLaunchDate },
      });
    }
  }, [showLaunchDate]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: {
          launch_time: mergeDateAndTime(launchDate, launchTime),
          launch_on_date: showLaunchDate,
        },
      });
    }
  }, [launchDate, launchTime]);

  useEffect(() => {
    if (showArchiveDate && !validatePremium[0] && propsLoaded.current) {
      setShowArchiveDate(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: { archive_on_date: showArchiveDate },
      });
    }
  }, [showArchiveDate]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: {
          archive_time: mergeDateAndTime(archiveDate, archiveTime),
          archive_on_date: showLaunchDate,
        },
      });
    }
  }, [archiveDate, archiveTime]);

  useEffect(() => {
    if (showReadonlyDate && !validatePremium[0] && propsLoaded.current) {
      setShowReadonlyDate(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: { readonly_on_date: showReadonlyDate },
      });
    }
  }, [showReadonlyDate]);

  useEffect(() => {
    if (propsLoaded.current) {
      props.onUpdate({
        pod_settings_attributes: {
          readonly_time: mergeDateAndTime(readonlyDate, readonlyTime),
          readonly_on_date: showReadonlyDate,
        },
      });
    }
  }, [readonlyDate, readonlyTime]);

  const mergeDateAndTime = (date, time) => {
    if (date && time) {
      const _date = new Date(date);
      const _time = new Date(time);

      const merged = new Date(
        `${_date.toLocaleDateStringAbs()}, ${_time.toLocaleTimeString()}`
      ).toISOString();

      return `${merged}`;
    } else if (date) {
      return `${date.toISOString()}`;
    }
  };

  return (
    <Box>
      <Box className={classes.item}>
        <Group position="apart" noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.scheduling.showStartDate.description1")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.scheduling.showStartDate.description2")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
            checked={showStartDate}
            onChange={(event) => setShowStartDate(event.currentTarget.checked)}
          />
        </Group>
        {showStartDate ? (
          <Group>
            <Box mt={10}>
              <Text size="sm" weight={500} mb={4}>
                {t("spaces.settings.main.scheduling.startDateTime.title")}
              </Text>
              <Group>
                <DatePicker
                  disabled={disableActions}
                  value={startDate}
                  placeholder={t(
                    "spaces.settings.main.scheduling.startDateTime.placeholder"
                  )}
                  onChange={(date) => setStartDate(date)}
                />
                <TimeInput
                  disabled={disableActions}
                  value={startTime}
                  format="12"
                  onChange={(time) => setStartTime(time)}
                />
              </Group>
            </Box>
            <Box mt={10}>
              <Text size="sm" weight={500} mb={4}>
                {t("spaces.settings.main.scheduling.endDateTime.title")}
              </Text>
              <Group>
                <DatePicker
                  disabled={disableActions}
                  value={endDate}
                  placeholder={t(
                    "spaces.settings.main.scheduling.endDateTime.placeholder"
                  )}
                  onChange={(date) => setEndDate(date)}
                  minDate={startDate ? startDate : null}
                />
                <TimeInput
                  disabled={disableActions}
                  value={endTime}
                  format="12"
                  onChange={(time) => setEndTime(time)}
                />
              </Group>
            </Box>
          </Group>
        ) : (
          ""
        )}
      </Box>

      <Box className={classes.item}>
        <Group position="apart" noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.scheduling.showLaunchDate.description1")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.scheduling.showLaunchDate.description2")}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
            checked={showLaunchDate}
            onChange={(event) => setShowLaunchDate(event.currentTarget.checked)}
          />
        </Group>
        {showLaunchDate ? (
          <Group mt={10}>
            <DatePicker
              disabled={disableActions}
              value={launchDate}
              placeholder={t(
                "spaces.settings.main.scheduling.launchDateTime.placeholder"
              )}
              onChange={(date) => setLaunchDate(date)}
            />
            <TimeInput
              disabled={disableActions}
              value={launchTime}
              format="12"
              onChange={(time) => setLaunchTime(time)}
            />
          </Group>
        ) : (
          ""
        )}
      </Box>

      <Box className={classes.item}>
        <Group position="apart" noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t(
                "spaces.settings.main.scheduling.showArchiveDate.description1"
              )}
            </Text>
            <Text className={classes.itemDescription}>
              {t(
                "spaces.settings.main.scheduling.showArchiveDate.description2"
              )}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
            checked={showArchiveDate}
            onChange={(event) =>
              setShowArchiveDate(event.currentTarget.checked)
            }
          />
        </Group>
        {showArchiveDate ? (
          <Group mt={10}>
            <DatePicker
              disabled={disableActions}
              value={archiveDate}
              placeholder={t(
                "spaces.settings.main.scheduling.archiveDate.placeholder"
              )}
              onChange={(date) => setArchiveDate(date)}
            />
            <TimeInput
              disabled={disableActions}
              value={archiveTime}
              format="12"
              onChange={(time) => setArchiveTime(time)}
            />
          </Group>
        ) : (
          ""
        )}
      </Box>

      <Box className={classes.item}>
        <Group position="apart" noWrap spacing="xl">
          <div>
            <Text className={classes.itemTitle}>
              {t(
                "spaces.settings.main.scheduling.showReadonlyDate.description1"
              )}
            </Text>
            <Text className={classes.itemDescription}>
              {t(
                "spaces.settings.main.scheduling.showReadonlyDate.description2"
              )}
            </Text>
          </div>
          <Switch
            disabled={disableActions}
            onLabel="ON"
            offLabel="OFF"
            className={classes.switch}
            size="lg"
            checked={showReadonlyDate}
            onChange={(event) =>
              setShowReadonlyDate(event.currentTarget.checked)
            }
          />
        </Group>
        {showReadonlyDate ? (
          <Group mt={10}>
            <DatePicker
              disabled={disableActions}
              value={readonlyDate}
              placeholder={t(
                "spaces.settings.main.scheduling.readonlyDate.placeholder"
              )}
              onChange={(date) => setReadonlyDate(date)}
            />
            <TimeInput
              disabled={disableActions}
              value={readonlyTime}
              format="12"
              onChange={(time) => setReadonlyTime(time)}
            />
          </Group>
        ) : (
          ""
        )}
      </Box>
      <PremiumModal opened={opened} setOpened={setOpened} />
    </Box>
  );
}
