import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { Stack, Switch } from "@mantine/core";

import { setAttrsToAllByType } from "../../utils/selection";

export default function InteractiveText(props) {
  const timeoutId = useRef(null);

  const loading = useRef(false);

  const tiptap = useSelector((state) => state.tiptap);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const _checked = props.element.node.attrs[`${props.attrName}`];

    if (_checked != checked) {
      const data = {};
      data[`${props.attrName}`] = checked;

      if (props.global){
        setAttrsToAllByType(tiptap.editor, props.element.node.type.name, data)
      }
      else{
        tiptap.editor.commands.updateAttributes(
          props.element.node.type.name,
          data
        );
      }
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        props.saveNodeData();
      }, 2000);

    }
  }, [checked]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      setChecked(props.element.node.attrs[`${props.attrName}`]);
    }
  }, [props.element]);

  return (
    <Stack mt={20}>
      <Switch
        checked={checked}
        onChange={(event) => setChecked(event.currentTarget.checked)}
        label={`${props.attrLabel}`}
      />
    </Stack>
  );
}
