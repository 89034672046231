import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Title,

  Box,
  Select,
  createStyles
} from '@mantine/core';


import { useTranslation } from 'react-i18next'

import tinycolor from 'tinycolor2'

import TextBased from './responses/TextBased.jsx'

import SurveyModel from "../../../../models/Survey";
import isSpaceThemed from '../../../../utils/IsSpaceThemed'

const useStyles = createStyles((theme, {colors}) => ({
  item: {
    borderRadius: theme.radius.md,
    border: `1px solid ${isSpaceThemed(colors) ? tinycolor(colors.sidebar_background_color).toString() : theme.colors.gray[4]}`,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 15,
    paddingLeft: 15,
    // backgroundColor: theme.white,
    backgroundColor: isSpaceThemed(colors) ? tinycolor(colors.sidebar_background_color).toString() : theme.white,
    color: isSpaceThemed(colors) ? colors.sidebar_text_color : "#000",
    marginTop: 15,
    width: "100%"
  }
}));


export default function Individual(props) {

  const colors = useSelector(state => state.colors);
  const { classes, cx } = useStyles({colors});
  const { t, i18n } = useTranslation();

  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [choices, setChoices] = useState([])

  useEffect(() => {
    if (props.survey && props.survey.id && props.spaceId){
      getUsers()
    }
    if (props.selectedUser){
      setSelectedUser(props.selectedUser)
    }
  }, [props])

  useEffect(() => {
    if (selectedUser){
      getChoices()
    }
  }, [selectedUser])

  const getUsers = () => {
    SurveyModel.onFetchUsers(props.survey.id, props.spaceId, (data) => {
      setUsers(data.map((user) => {return {value: user.id, label: user.full_name}}))
    }, (data) => {

    })
  }

  const getChoices = () => {
    SurveyModel.onFetchByUser(props.survey.id, props.spaceId, selectedUser, (data) => {
      setChoices(data)
    }, (data) => {

    })
  }

  return (
      <Box>
        {!props.singleUser && (
          <Select
            placeholder={t("spaces.settings.survey.individual.individual.placeholder")}
            value={selectedUser}
            onChange={setSelectedUser}
            mt={15}
            size="md"
            data={users}
            />
        )}

        <Box mt={10}>
          {choices.map((choice) => {
            return (
              <Box className={classes.item}>
                <Title order={5}>
                  {choice.question.content}
                </Title>
                <TextBased
                  response={choice.content}
                  />
              </Box>
            )
          })}
        </Box>
      </Box>
  )
}
