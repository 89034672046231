import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  UnstyledButton,
  Group,
  Text,
  createStyles
} from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { Link, useLocation } from "react-router-dom";

const useStyles = createStyles((theme, _params, getRef) => ({
  control: {
    display: 'block',
    width: '100%',
    borderRadius: `${theme.radius.md}px`,
    padding: `15px 0px`,

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
      color: theme.black,
      textDecoration: "none"
    },
  },
  linkText: {
    fontWeight: 500,
    color: theme.black,
    fontSize: theme.fontSizes.lg,
  },
  linkTextActive: {
    fontWeight: 800,
  },

}));


export default function Sidebar() {

  const { t, i18n } = useTranslation();

  const settingsItemsCollection = [
    {
      key: "profile",
      label: t('settings.editProfile.editProfile'),
      to: "/settings/profile",
      active: "profile"
    },
    {
      key: "account",
      label: t('settings.accountSettings.accountSettings'),
      to: "/settings/account",
      active: "account"
    },
    {
      key: "email",
      label: t('settings.emailPreferences.emailPreferences'),
      to: "/settings/email",
      active: "email"
    },

    {
      key: "affiliates",
      label: t('settings.affiliates.title'),
      to: "/settings/affiliates",
      active: "affiliates"
    },
    {
      key: "billing",
      label: t('settings.paid_subscriptions.title'),
      to: "/settings/paid_subscriptions",
      active: "paid_subscriptions"
    },
    {
      key: "billing",
      label: t('settings.billing.billing'),
      to: "/settings/billing",
      active: "billing"
    },
  ]


  const { classes, cx } = useStyles();

  const optionsLoaded = useRef(false)

  const user = useSelector((state) => state.user);

  const location = useLocation()

  const [settingsItems, setSettingsItems] = useState([])

  useEffect(() => {

    const auxItems = [...settingsItemsCollection]
    // if (user.is_admin && !optionsLoaded.current) {

    //   optionsLoaded.current = true
    //   auxItems.push(
    //     {
    //       key: "affiliates",
    //       label: "Affiliate",
    //       to: "/settings/affiliates",
    //       active: "affiliates"
    //     }
    //   )
    // }
    setSettingsItems(auxItems)

  }, [user])

  const getSectionName = () => {

    return location.pathname.split("/").pop()
  }

  return (
    <div>
      <Box
        key="coreNav"
        p={10}>
        {settingsItems.map((item) => (
          <Box>
            <UnstyledButton
              component={Link}
              key={item.key}
              to={item.to}
              className={classes.control}>
              <Group position="apart" spacing={0}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box ml="md">
                    <Text className={cx(classes.linkText, { [classes.linkTextActive]: item.active == getSectionName()}) }>{item.label}</Text>
                  </Box>
                </Box>
              </Group>
            </UnstyledButton>
          </Box>
        ))}
      </Box>

    </div>

  )
}
