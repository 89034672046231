// import axios from 'axios';

class Answer {
  constructor() {
    if (this instanceof Answer) {
      throw Error('Answer class cannot be instantiated.');
    }
  }

  static onCreate = async (slug, survey_id, question_id, answer, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      answer
    }

    await window.$api.post(`/api/v4/spaces/${slug}/surveys/${survey_id}/questions/${question_id}/answers.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onUpdate = async (answer_id, slug, survey_id, question_id, answer, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      answer
    }

    await window.$api.put(`/api/v4/spaces/${slug}/surveys/${survey_id}/questions/${question_id}/answers/${answer_id}.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onReorder = async (slug, survey_id, question_id, answers, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      answers
    }

    await window.$api.post(`/api/v4/spaces/${slug}/surveys/${survey_id}/questions/${question_id}/answers/reorder.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDestroy = async (answer_id, slug, survey_id, question_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/surveys/${survey_id}/questions/${question_id}/answers/${answer_id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDestroyAll = async (slug, survey_id, question_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/surveys/${survey_id}/questions/${question_id}/answers/destroy_all.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default Answer;
