import axios from 'axios';

class Like {
  constructor() {
    if (this instanceof Like) {
      throw Error('Like class cannot be instantiated.');
    }
  }

  static onCreate = async ({pod_id, pod_message_id, reflection_reflection_id}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      pod_id,
      pod_message_id,
      reflection_reflection_id
    }

    await axios.post(`/api/v4/likes.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }

  static onUsers = async ({pod_id, pod_message_id, reflection_reflection_id}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      pod_id,
      pod_message_id,
      reflection_reflection_id
    }

    await axios.get(`/api/v4/likes/users.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }

}

export default Like;
