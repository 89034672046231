import React, { useState } from "react";
import {

  Box,

  Button,

  Group,
} from "@mantine/core";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import SpaceModel from "../../../../../models/Space";

export default function AddSimple(props) {
  const space = useSelector((state) => state.space);
  const [stepSlug, setStepSlug] = useState(null);
  const { t, i18n } = useTranslation();

  const onSend = () => {
    if (props.mode === "edit") {
      props.onUpdate({
        object_type: "pod",
        object_id: space.id,
      });
    } else {
      props.onCreate({
        object_type: "pod",
        object_id: space.id,
      });
    }
  };

  const onDestroy = (event) => {
    SpaceModel.onDestroy(
      props.step.id,
      (data) => {
        props.onDestroy(event);
      },
      (data) => {}
    );
  };

  return (
    <Box>
      {props.mode === "edit" ? (
        <Group position="apart">
          <Button
            mt={25}
            radius="xl"
            onClick={(event) => onSend(event)}
            type="submit"
            leftIcon={<FontAwesomeIcon icon={solid("check")} />}
          >
            {t("spaces.settings.registration.steps.addToNewSpace.saveButton1")}
          </Button>
          <Button
            onClick={(event) => onDestroy(event)}
            mt={25}
            radius="xl"
            color="dark"
            leftIcon={<FontAwesomeIcon icon={regular("trash-can")} />}
          >
            {t("spaces.settings.registration.steps.addToNewSpace.deleteButton")}
          </Button>
        </Group>
      ) : (
        <Button
          type="submit"
          onClick={(event) => onSend(event)}
          leftIcon={<FontAwesomeIcon icon={solid("check")} />}
          mt={25}
          radius="xl"
          fullWidth
        >
          {t("spaces.settings.registration.steps.addToNewSpace.saveButton2")}
        </Button>
      )}
    </Box>
  );
}
