import axios from 'axios';
import Broadcaster from '../helpers/Broadcaster.js';

class NotificationModel {
  constructor() {
    if (this instanceof NotificationModel) {
      throw Error('NotificationModel class cannot be instantiated.');
    }
  }

  static onFetch = async (page, callback) => {


    const params = {
      auth_token: window.$currentUserAuthToken,
      page: page
    };

    await axios.get(`/api/v2/notifications`, {params: params})
    .then(res => {
      callback(res.data)
    })
    .catch((error) => {
    });
  }

  static onFetchNotSeenCount = async (sucessCallback, errorCallback) => {
    const params = {
      auth_token: window.$currentUserAuthToken
    };

    await axios.get(`/api/v2/notifications/not_seen_count`, {params: params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      }
    });
  }

  static onMarkAsSeen = async (notification_type, item_id, callback) => {


    const params = {
      auth_token: window.$currentUserAuthToken,
      notification_type: notification_type,
      item_id: item_id
    };

    await axios.post(`/api/v2/notifications/mark_as_seen`, params)
    .then(res => {
      callback(res.data)
    })
    .catch((error) => {
    });
  }

  static onMarkAllAsSeen = async (callback) => {


    const params = {
      auth_token: window.$currentUserAuthToken
    };

    await axios.post(`/api/v2/notifications/mark_all_as_seen`, params)
    .then(res => {
      callback(res.data)
    })
    .catch((error) => {
    });
  }
}

export default NotificationModel;
