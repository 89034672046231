import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Stack, MantineProvider, Image, Button, Modal } from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

const S3_IMAGE_BUCKET = "sutra-froala";

const REGION = "us-west-1";

import { v4 as uuidv4 } from "uuid";

import Swal from "sweetalert2";


import AWS from "aws-sdk";

export default function LinkImage(props) {
  const timeoutId = useRef(null);
  const { t, i18n } = useTranslation();
  const loading = useRef(false);

  const tiptap = useSelector((state) => state.tiptap);
  const [text, setText] = useState();
  const [isImgEditorHeaderShown, setIsImgEditorHeaderShown] = useState(false);
  const htmlImageHeader = useRef();
  const fileInputHeader = useRef();

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newText = text; //node.node.attrs.link.description ? text : null;

    tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
      link: {
        ...props.element.node.attrs.link,
        thumbnail: { url: text },
      },
    });

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      timeoutId.current = null;
      props.saveNodeData();
    }, 2000);
  }, [text]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      if (props.element.node.attrs.link.thumbnail) {
        setText(props.element.node.attrs.link.thumbnail.url);
      }
    }
  }, [props.element]);

  const onUploadHeader = (file) => {
    const extension = file.fullName.split(".").pop();
    const fileName = escapeFilename(file.fullName).replace(`.${extension}`, "");

    const imageId = `${uuidv4().replace(/-/g, "")}/${encodeURIComponent(
      fileName
    )}.${extension}`;

    const upload = {
      id: imageId,
      file: file,
      type: "image",
      status: "pending",
    };

    uploadFile(upload);
  };

  const uploadFile = (upload) => {
    const client = newClient();
    const key = `uploads/${upload.id}`;

    const folderParams = {
      ACL: "public-read",
      Body: "",
      Bucket: client.config.params.Bucket,
      Key: key.split("/")[0] + "/",
    };

    const params = {
      ACL: "public-read",
      Body: base64(upload.file.imageBase64),
      Bucket: client.config.params.Bucket,
      Key: key,
    };

    client.putObject(folderParams, (err, data) => {
      if (err) {
      } else {
        client
          .putObject(params, (err, data) => {
            setTimeout(() => {
              setText(
                `https://${client.config.params.Bucket}.s3.${REGION}.amazonaws.com/${key}`
              );
            }, 1000);
          })
          .on("httpUploadProgress", (evt) => {})
          .send((err) => {
            if (err) console.log(err);
          });
      }
    });
  };

  const base64 = (base64Image) => {
    const base64Data = base64Image.split(",")[1];

    // Convert base64 to Blob
    const binaryString = window.atob(base64Data);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: "image/png" });
  };

  const escapeFilename = (filename) => {
    // Remove any leading or trailing spaces
    filename = filename.trim();

    // Replace any consecutive whitespace characters with a single space
    filename = filename.replace(/\s+/g, " ");

    // Replace any special characters with underscores
    filename = filename.replace(/[^0-9A-Za-z.\-]/g, "_");

    // Limit the length of the file name to 255 characters
    filename = filename.slice(0, 255);

    return filename;
  };

  const newClient = (type) => {
    return new AWS.S3({
      params: { Bucket: S3_IMAGE_BUCKET },
      region: REGION,
      keyStart: window.$RAILS_ENV.AWS_CONFIG.key_start,
    });
  };

  const openImgEditorHeader = () => {
    setIsImgEditorHeaderShown(true);
  };

  const closeImgEditorHeader = () => {
    setIsImgEditorHeaderShown(false);
    if (fileInputHeader.current) {
      fileInputHeader.current.value = "";
    }
  };

  const onOpenFileSelector = (event) => {
    try {
      event.target.children[0].click();
    } catch {}
  };

  const handleFileInputHeader = (e) => {
    const file = e.target.files[0];

    if (!isFileValid(file, e.target)){
      return
    }

    htmlImageHeader.current = document.createElement("img");

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        htmlImageHeader.current.src = reader.result;
        openImgEditorHeader();
      });

      reader.readAsDataURL(file);
    } else {
      // Clear the value of the file input
      if (fileInputHeader.current) {
        fileInputHeader.current.value = "";
      }
    }
  };

  const isFileValid = (selectedFile, target) => {
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (selectedFile.size > maxSize) {
      Swal.fire("", "Your image must be less than 5MB", "info");
      target.value = ''; // Clear the selected file
      return false;
    }
    return true;
  }

  return (
    <Stack mt={20}>
      {text ? (
        <>
          <Image src={text} />
          <MantineProvider theme={{ primaryShade: 6 }}>
            <Button
              mt={10}
              variant="filled"
              color="gray"
              radius="xl"
              leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
              onClick={() => setText("")}
            >
              {"Remove thumbnail"}
            </Button>
          </MantineProvider>
        </>
      ) : (
        <Button
          radius="xl"
          fullWidth
          leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
          onClick={(event) => onOpenFileSelector(event)}
        >
          <input
            ref={fileInputHeader}
            type="file"
            style={{ display: "none" }}
            accept={"image/*"}
            onChange={handleFileInputHeader}
          />
          {`Change thumbnail`}
        </Button>
      )}
      <Modal
        withCloseButton={false}
        size="xl"
        opened={isImgEditorHeaderShown}
        onClose={() => setIsImgEditorHeaderShown(false)}
        title={""}
        zIndex={99999}
      >
        <FilerobotImageEditor
          source={htmlImageHeader.current}
          onSave={(editedImageObject, designState) =>
            onUploadHeader(editedImageObject, designState)
          }
          onClose={closeImgEditorHeader}
          Rotate={{ angle: 90, componentType: "slider" }}
          Crop={{
            ratio: 160 / 120,
            ratioTitleKey: "Thumbnail",
            noPresets: true,
            autoResize: true,
          }}
          defaultSavedImageQuality={0.7}
          defaultSavedImageName={"thumb"}
          tabsIds={[TABS.ADJUST]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ADJUST} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
          onBeforeSave={() => false}
        />
      </Modal>
    </Stack>
  );
}
