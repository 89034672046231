import React, {useEffect} from 'react';

import { createStyles } from '@mantine/core';
import { useSelector } from 'react-redux';

import HeaderContent from './HeaderContent'
import MainContent from './MainContent'
import SystemAlert from './SystemAlert'
import Navbar from './Navbar'
import ProfileModal from '../users/ProfileModal'
import ContentReadonlyModal from "../spaces_neo/presentations/content/ContentReadonlyModal";
import Direct from "../users/Direct";

import isSpaceThemed from '../../utils/IsSpaceThemed'
// import useSidebarStyles from "../../utils/SidebarStyles"

import tinycolor from 'tinycolor2'

const useStyles = createStyles((theme, { registrationView }) => ({
  appBody: {
    display: "flex",
    flexFlow: "row"
  },

  appSideFrameHidden: {
    display: "none"
  },
  appSideFooter: {
    display: "flex",
    width: theme.other.sideFrameWidth,
    alignItems: "center",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  appMainFrame: {
    width: "100vw",
    height: `calc(100vh - ${registrationView ? 0 : theme.other.headerHeight}px)`,
    flex: 1,
    boxSizing: "border-box",
  },
  appMainFrameSettingsView: {
    borderLeft: `1px solid ${theme.colors.gray[7]}`,
    backgroundColor: "#FFF"
  },
  appMainFrameInnerWrap: {
    maxWidth: 1000,
    margin: "0px auto",
    paddingLeft: 20,
    paddingRight: 20,
  },
  appSideFrameSm: {
    width: theme.other.sideFrameWidthSm
  }
}))

export default function Body() {

  const registrationView = (window.location.pathname.indexOf("/registration/view") >= 0 || window.location.pathname.indexOf("/register") >= 0) ? true : false;
  const { classes, cx, theme } = useStyles({registrationView});

  return (
    <div
      className={classes.appBody}>
      <Navbar />
      <div
        className={classes.appMainFrame}
        >
        <MainContent />
        <SystemAlert />
        <ContentReadonlyModal />
        <ProfileModal />
        <Direct />


      </div>
    </div>
  );
}
