import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Text,
  Box,
  Menu,
  Group,
  Stack,
  Button,
  Divider,
  useMantineTheme,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Copy, Edit } from "tabler-icons-react";

import { sutraTheme } from "../../../../collections/sutraTheme";

import Swal from "sweetalert2";

import StructureModel from "../../../../models/Structure";

export default function StructureItem(props) {
  const theme = useMantineTheme();
  const { t, i18n } = useTranslation();
  const [structure, setStructure] = useState({});

  useEffect(() => {
    if (props.structure && props.structure.id != structure.id) {
      setStructure(props.structure);
    }
  }, [props]);

  const onEdit = () => {
    props.onEdit(structure);
  };

  const onDuplicate = () => {
    StructureModel.onDuplicate(
      structure.id,
      props.spaceId,
      (data) => {
        props.onUpdate(structure);
      },
      (data) => {}
    );
  };

  const onDestroy = () => {
    Swal.fire({
      title: "Are you sure?",
      html: "This structure will be permanently deleted",
      showDenyButton: true,
      confirmButtonText: "Delete structure",
      denyButtonText: "Cancel",
      confirmButtonColor: theme.colors.red[8],
      denyButtonColor: theme.colors.gray[6],
    }).then((result) => {
      if (result.isConfirmed) {
        StructureModel.onDestroy(
          structure.id,
          props.spaceId,
          (data) => {
            props.onUpdate(structure);
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <Box
      mb={17}
      pb={20}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <Group
        position="apart"
        noWrap
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Text
            onClick={() => props.onSelect(structure)}
            sx={(theme) => ({
              color: sutraTheme.colors.sutrablue[3],
              fontWeight: 500,
              fontSize: 20,
              cursor: "pointer",
            })}
          >
            {structure.title}
          </Text>
        </Box>
        <Box>
          <Stack justify="flex-start">
            <Menu
              transition="pop"
              withArrow
              placement="end"
              control={
                <Button
                  compact
                  variant="subtle"
                  color="dark"
                  sx={(theme) => ({
                    "&:hover": {
                      backgroundColor: theme.colors.gray[1],
                    },
                  })}
                >
                  <FontAwesomeIcon icon={solid("ellipsis")} />
                </Button>
              }
            >
              <Menu.Item
                icon={<Edit size={18} strokeWidth={2} color={"black"} />}
                onClick={() => props.onAdd(structure)}
              >
                {t("spaces.settings.structure.structureItem.item.addToSpace")}
              </Menu.Item>
              <Menu.Item
                icon={<Edit size={18} strokeWidth={2} color={"black"} />}
                onClick={() => onEdit()}
              >
                {t("spaces.settings.structure.structureItem.item.edit")}
              </Menu.Item>
              <Menu.Item
                icon={<Copy size={18} strokeWidth={2} color={"black"} />}
                onClick={() => onDuplicate()}
              >
                {t("spaces.settings.structure.structureItem.item.duplicate")}
              </Menu.Item>
              <Divider />
              <Menu.Item
                icon={<FontAwesomeIcon icon={regular("trash-can")} />}
                color="red"
                onClick={() => onDestroy()}
              >
                {t("spaces.settings.structure.structureItem.item.delete")}
              </Menu.Item>
            </Menu>
          </Stack>
        </Box>
      </Group>
    </Box>
  );
}
