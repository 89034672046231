import { Extension } from "@tiptap/core";

// https://tiptap.dev/guide/custom-extensions#attributes

const CustomButton = Extension.create({
  name: "customButton",
  addOptions() {
    return {
      types: [],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: [
          "actionbutton",
          "customform",
          "interactiveInput",
          "interactiveButton",
          "circle",
          "survey"
        ],
        attributes: {
          buttonText: {
            default: "Register",
          },
          buttonBackgroundColor: {
            default: "#41C3F6",
          },
          buttonBorderColor: {
            default: "#41C3F6",
          },
          buttonTextColor: {
            default: "#FFFFFF",
          },
          actionCallback: {
            default: "message",
          },
          actionCallbackValue: {
            default: "",
          },
          actionCallbackTarget: {
            default: "_current",
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setCustomButtonAttr:
        (attr, value) =>
        ({ commands, chain, view }) => {
          if (!window.$tiptapSelectionRange) {
            return;
          }
          const { from, to } = window.$tiptapSelectionRange;
          const { selection } = view.state;

          const content = selection.node
            ? selection.node
            : selection.$anchor.parent;

          const newAttrs = {};
          newAttrs[`${attr}`] = value;

          return commands.updateAttributes(content.type.name, newAttrs);
        },
    };
  },
});

export default CustomButton;
