// import axios from 'axios';
import { getNeo4jHost } from "../utils/Host";

class Member {
  constructor() {
    if (this instanceof Member) {
      throw Error("Member class cannot be instantiated.");
    }
  }

  static onFetch = async (
    slug,
    paginator,
    { scope, term },
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      scope,
      term,
      ...paginator,
    };

    await window.$api
      .get(`/api/v4/spaces/${slug}/members.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onFetchNeo4j = async (
    slug,
    paginator,
    { scope, term },
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      scope,
      term,
      ...paginator,
    };

    await window.$api
      .get(`${getNeo4jHost()}/api/v1/spaces/${slug}/members`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onFetchCounts = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await window.$api
      .get(`/api/v4/spaces/${slug}/members/counts.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onFetchMembersToCopy = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await window.$api
      .get(`/api/v4/spaces/${slug}/members/members_to_copy.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onFetchMembersBySlugs = async (
    slug,
    slugs,
    user_ids = [],
    query,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      slugs,
      user_ids: user_ids.length > 0 ? user_ids : null,
      query,
    };

    await window.$api
      .get(`/api/v4/spaces/${slug}/members/members_by_slugs.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onFetchMembersProgress = async (
    slug,
    slugs,
    user_ids = [],
    includeSubspaces,
    actions,
    date_range,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      slugs,
      user_ids: user_ids.length > 0 ? user_ids : null,
      include_subspaces: includeSubspaces ? true : null,
      actions,
      date_range,
    };

    await window.$api
      .get(`/api/v4/spaces/${slug}/members/members_progress.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static encodeParams(params) {
    const keys = Object.keys(params);
    return keys.flatMap(key => {
        const value = params[key];
        if (value === null || value === undefined) {
            return []; // Skip null and undefined values
        } else if (Array.isArray(value)) {
            return value.filter(item => item !== null && item !== undefined)
                        .map(item => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`);
        } else if (value instanceof Date) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(this.formatDate(value))}`;
        } else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
    }).join('&');
}

  static formatDate(date) {
    const pad = (num) => num.toString().padStart(2, "0");

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  static onDownloadProgressExcel = async (
    slug,
    slugs,
    user_ids = [],
    includeSubspaces,
    actions,
    date_range
  ) => {
    const baseUrl = `/api/v4/spaces/${slug}/members/members_progress.xlsx`;

    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      slugs,
      user_ids: user_ids.length > 0 ? user_ids : null,
      include_subspaces: includeSubspaces ? true : null,
      actions,
      start_at: date_range[0],
      end_at: date_range[1],
    };

    const queryString = this.encodeParams(params);

    const fullUrl = `${baseUrl}?${queryString}`;

    window.open(fullUrl, "_blank").focus();
  };

  // TODO:
  static onCreate = async (slug, user_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      user_id,
    };

    await window.$api
      .post(`/api/v4/spaces/${slug}/members.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onUpdate = async (
    pod_member_id,
    slug,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await window.$api
      .put(`/api/v4/spaces/${slug}/members/${pod_member_id}.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onUpdateRole = async (
    pod_member_id,
    slug,
    role,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      role,
    };

    await window.$api
      .put(
        `/api/v4/spaces/${slug}/members/${pod_member_id}/update_role.json`,
        params
      )
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onEmailFrequency = async (
    pod_member_id,
    slug,
    frequency,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      frequency: frequency,
    };

    await window.$api
      .post(
        `/api/v4/spaces/${slug}/members/${pod_member_id}/email_frequency.json`,
        params
      )
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onApprove = async (
    pod_member_id,
    slug,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await window.$api
      .post(
        `/api/v4/spaces/${slug}/members/${pod_member_id}/approve.json`,
        params
      )
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onApproveAll = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await window.$api
      .post(`/api/v4/spaces/${slug}/members/approve_all.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onDestroy = async (
    pod_member_id,
    slug,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await window.$api
      .delete(`/api/v4/spaces/${slug}/members/${pod_member_id}.json`, {
        params,
      })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onDestroyAll = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await window.$api
      .delete(`/api/v4/spaces/${slug}/members/destroy_all.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {}
      });
  };

  static onDownloadExcel = async (slug) => {
    window
      .open(
        `/api/v4/spaces/${slug}/members/excel.xlsx?auth_token=${window.$currentUserAuthToken}`,
        "_blank"
      )
      .focus();
  };
}

export default Member;
