import React, { useState, useEffect, useRef } from "react";
import { Box, Popover, createStyles, Button, Group } from "@mantine/core";

import { useDispatch, useSelector } from "react-redux";

import isSpaceThemed from "../../utils/IsSpaceThemed";
import defaultSpaceColors from "../../collections/defaultSpaceColors";
import isDark from "../../utils/IsDarkColor";

import tinycolor from "tinycolor2";

import Tag from "./presentations/lists/Tag";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

let timeoutId = null;

const useStyles = createStyles((theme, { colors }, getRef) => ({
  button: {
    // border: `1px solid ${
    //   !isSpaceThemed(colors)
    //     ? theme.colors.gray[4]
    //     : tinycolor(colors.background_color).lighten(3).toString()
    // }`,
    // border: `1px solid ${tinycolor(colors.default_text_color).lighten(20).toString()}`,
    color: tinycolor(colors.default_text_color).lighten(20).toString(),
    paddingLeft: 8,
    paddingRight: 8,
    height: 21,
    marginTop: 7,
    fontSize: 12,
    border: isSpaceThemed(colors)
      ? `1px solid ${
          isDark(colors.background_color)
            ? tinycolor(colors.background_color).lighten(40).toString()
            : tinycolor(colors.background_color).darken(40).toString()
        }`
      : `1px solid #ced4da`,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? `${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(5).toString()
              : tinycolor(colors.background_color).darken(5).toString()
          }`
        : `#e9ecef`,
    },

    // "&:hover": {
    //   backgroundColor: !isSpaceThemed(colors)
    //     ? theme.colors.gray[1]
    //     : tinycolor(colors.background_color).lighten(3).toString(),
    // },
  },
  leftIcon: {
    marginRight: 5,
  },
}));

export default function HeaderTags(props) {
  const { t } = useTranslation();

  const space = useSelector((state) => state.space);
  const space2 = useSelector((state) => state.space2);

  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  const colors = useSelector((state) => state.colors);
  const colors2 = useSelector((state) => state.colors2);

  const { classes, cx, theme } = useStyles({ colors: getColors() });

  const [tag, setTag] = useState(false);
  const [tags, setTags] = useState([]);
  const [parentTags, setParentTags] = useState([]);

  const dispatch = useDispatch();

  const tagsTimeout = useRef();
  const tagsTimeout2 = useRef();

  useEffect(() => {}, [props]);

  useEffect(() => {
    if (getSpace().slug) {
      onFetchParentsTags();
      onFetchTags();
    }
  }, [space, space2]);

  const getSpace = () => {
    if (props.useSpace2) {
      return space2;
    } else {
      return space;
    }
  };

  const onFetchTags = () => {
    setTags([...getSpace().tagged_with].filter(Boolean));
  };

  const onFetchParentsTags = () => {
    setParentTags([...getSpace().parent_tags].filter(Boolean));
  };

  const onTagFilter = (tags) => {};
  const onTag = (tags) => {
    onFetchTags();
  };

  const onCloseTags = () => {
    setTag(false);
    // onUpdated()
  };

  if (parentTags && parentTags.length == 0) {
    return null;
  }

  if (
    getSpace() &&
    (getSpace().version == "list" || getSpace().version == "events" || getSpace().version == "showcase")
  ) {
    return null;
  }

  return (
    <Group spacing={0}>
      {tags && tags.length > 0 && (
        <Box>
          {tags.map((tag) => {
            return (
              <Button
                sx={(theme) => ({
                  backgroundColor: theme.other.tagColors[tag.color]
                    ? theme.other.tagColors[tag.color].backgroundColor
                    : "white",
                  color: theme.other.tagColors[tag.color]
                    ? theme.other.tagColors[tag.color].textColor
                    : "black",
                  "&:hover": {
                    backgroundColor: theme.other.tagColors[tag.color]
                      ? theme.other.tagColors[tag.color].backgroundColor
                      : "white",
                  },
                })}
                styles={{
                  root: {
                    height: 20,
                    marginRight: 5,
                  },
                  label: { fontSize: 10 },
                }}
                size="xs"
                radius="xl"
                onClick={() => onTagFilter(tag)}
              >
                {tag.name}
              </Button>
            );
          })}
        </Box>
      )}
      {getSpace() &&
        getSpace().permissions &&
        (getSpace().permissions.can_edit ||
          getSpace().permissions.can_manage) && (
          <Popover
            opened={tag}
            onClose={() => {
              onCloseTags();
            }}
            withCloseButton
            title={t("spaces.presentations.message.addTags")}
            closeButtonLabel="Close popover"
            closeOnClickOutside={false}
            styles={{
              inner: {
                // padding: 10,
              },
            }}
            target={
              <Button
                classNames={{
                  root: classes.button,
                  leftIcon: classes.leftIcon,
                }}
                onClick={() => {
                  setTag(!tag);
                }}
                variant="outline"
                radius="xl"
                compact
                leftIcon={<FontAwesomeIcon icon={solid("tag")} />}
              >
                {t("spaces.presentations.message.tag")}
              </Button>
            }
            width={320}
            position="bottom"
            placement="end"
          >
            <Tag
              spaceId={getSpace().slug}
              tags={tags}
              parentTags={parentTags}
              onSave={onTag}
              onUpdatedMessage={onTag}
              action="tag"
              creatable={false}
              parent={true}
            />
          </Popover>
        )}
    </Group>
  );
}
