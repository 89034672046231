import React, { useState, useEffect } from "react";

import {
  Text,
  Box,
  Menu,
  Group,
  Stack,
  Button,
  Badge,
  Divider,
  MantineProvider,
  useMantineTheme,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Copy, Edit } from "tabler-icons-react";

import { useTranslation } from "react-i18next";
import SurveyModel from "../../../../models/Survey";
import { sutraTheme } from "../../../../collections/sutraTheme";
import { useDispatch } from "react-redux";
import { setTiptapSidemenu } from "../../../../store/app";

import Swal from "sweetalert2";

export default function SurveyItem(props) {
  const dispatch = useDispatch()
  const theme = useMantineTheme();
  const { t, i18n } = useTranslation();
  const [survey, setSurvey] = useState({});

  useEffect(() => {
    if (props.survey) {
      setSurvey(props.survey);
    }
  }, [props]);

  const onEdit = () => {
    props.onEdit(survey);
  };

  const onDuplicate = () => {
    SurveyModel.onDuplicate(
      survey.id,
      props.spaceId,
      (data) => {
        props.onUpdate(survey);
      },
      (data) => {}
    );
  };

  const onDownload = () => {
    SurveyModel.onDownload(
      survey.id,
      props.spaceId,
      (data) => {
        Swal.fire(data.title, data.message, "success");
      },
      (data) => {}
    );
  };

  const onDownloadExcel = () => {
    // SurveyModel.onDownloadExcel(props.spaceId, survey.id);
    onDownload();
  };

  const onTake = () => {
    dispatch(
      setTiptapSidemenu({
        opened: "takeSurvey",
        option: {
          survey: { ...survey },
        },
      })
    );
  }

  const onDestroy = () => {
    Swal.fire({
      title: "Are you sure?",
      html: "This survey will be permanently deleted",
      showDenyButton: true,
      confirmButtonText: "Delete survey",
      denyButtonText: "Cancel",
      confirmButtonColor: theme.colors.red[8],
      denyButtonColor: theme.colors.gray[6],
    }).then((result) => {
      if (result.isConfirmed) {
        SurveyModel.onDestroy(
          survey.id,
          props.spaceId,
          (data) => {
            props.onUpdate(survey);
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <Box
      mb={17}
      pb={20}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <Group
        position="apart"
        noWrap
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Text
            onClick={() => props.onSelect(survey)}
            sx={(theme) => ({
              color: sutraTheme.colors.sutrablue[3],
              fontWeight: 500,
              fontSize: 20,
              cursor: "pointer",
            })}
          >
            {survey.title}
          </Text>
        </Box>
        <Box>
          <Stack justify="flex-start">
            <Menu
              transition="pop"
              withArrow
              placement="end"
              control={
                <Button
                  compact
                  variant="subtle"
                  color="dark"
                  sx={(theme) => ({
                    "&:hover": {
                      backgroundColor: theme.colors.gray[1],
                    },
                  })}
                >
                  <FontAwesomeIcon icon={solid("ellipsis")} />
                </Button>
              }
            >
              <Menu.Item
                icon={<FontAwesomeIcon icon={solid("plus")} />}
                onClick={() => props.onAdd(survey)}
              >
                {t("spaces.settings.survey.surveyItem.item.addToSpace")}
              </Menu.Item>
              <Menu.Item
                icon={<FontAwesomeIcon icon={solid("list")} />}
                onClick={() => props.onSelect(survey)}
              >
                {t("spaces.settings.survey.surveyItem.item.open")}
              </Menu.Item>
              <Menu.Item
                icon={<FontAwesomeIcon icon={solid("circle-play")} />}
                onClick={() => onTake()}
              >
                {t("spaces.settings.survey.surveyItem.item.take")}
              </Menu.Item>
              <Menu.Item
                icon={<Edit size={18} strokeWidth={2} color={"black"} />}
                onClick={() => onEdit()}
              >
                {t("spaces.settings.survey.surveyItem.item.edit")}
              </Menu.Item>
              <Menu.Item
                icon={<Copy size={18} strokeWidth={2} color={"black"} />}
                onClick={() => onDuplicate()}
              >
                {t("spaces.settings.survey.surveyItem.item.duplicate")}
              </Menu.Item>
              <Menu.Item
                icon={<FontAwesomeIcon icon={solid("download")} />}
                onClick={() => onDownloadExcel()}
              >
                {t("spaces.settings.survey.surveyItem.item.download")}
              </Menu.Item>
              <Divider />
              <Menu.Item
                icon={<FontAwesomeIcon icon={regular("trash-can")} />}
                color="red"
                onClick={() => onDestroy()}
              >
                {t("spaces.settings.survey.surveyItem.item.delete")}
              </Menu.Item>
            </Menu>
          </Stack>
        </Box>
      </Group>
      <Group
        mt={5}
        spacing="sm"
        sx={(theme) => ({ color: theme.colors.gray[6] })}
      >
        <MantineProvider theme={{ primaryShade: 6 }}>
          <Badge
            onClick={() => props.setOpened(true)}
            color="green"
            sx={(theme) => ({
              "&:hover": {
                textDecoration: "none",
                cursor: "pointer",
              },
            })}
          >
            {survey.all_choices_count}{" "}
            {t("spaces.settings.survey.surveyItem.item.responses")}
          </Badge>
        </MantineProvider>
      </Group>
    </Box>
  );
}
