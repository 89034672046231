import React, { useState, useEffect } from "react";
import {

  Box,
  MantineProvider,
  Select,
  Button,
  TextInput,
} from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "@mantine/form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import BroadcastModel from "../../../../../models/Broadcast";
import ManagerModel from "../../../../../models/Manager";

export default function SendEmail(props) {
  const [options, setOptions] = useState([]);
  // const [sendEmailTo, setSendEmailTo] = useState(null)
  // const [sendAs, setSendAs] = useState(null)
  // const [selectedBroadcast, setSelectedBroadcast] = useState(null)
  // const [emailAddress, setEmailAddress] = useState(null)
  const [managers, setManagers] = useState([]);
  const [params, setParams] = useState({});
  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation()

  useEffect(() => {
    console.log("trigger: ", props.trigger);
    if (space && space.slug) {
      onFetch();
    }
  }, [space]);

  const sendEmailTo = () => {
    return props.trigger === "interested"
      ? "interested"
      : props.trigger === "registered"
      ? "members"
      : "interested-members";
  };

  const form = useForm({
    initialValues: {
      object_type: "broadcast",
      send_email_to: sendEmailTo(),
      send_as: `${user.id}`,
      email_address: "",
      object_id: "",
    },

    validate: {
      send_email_to: (value) =>
        value.length > 0 ? null : "Please select a value",
      send_as: (value) => (value.length > 0 ? null : "Please select a sender"),
      object_id: (value) =>
        value.length > 0 ? null : "Please select an email",
    },
  });

  // useEffect(() => {
  //   setParams({
  //     object_type: "broadcast",
  //     object_id: selectedBroadcast,
  //     send_email_to: sendEmailTo,
  //     send_as: sendAs,
  //     email_address: emailAddress })
  // }, [sendEmailTo, sendAs, selectedBroadcast, emailAddress])

  const onFetch = () => {
    BroadcastModel.onFetchSimple(
      space.slug,
      { page: 1, per: 100 },
      (data) => {
        const broadcasts = data.map((item, index) => ({
          value: `${item.id}`,
          label: item.title,
          group: "Select a previously drafted broadcast",
        }));
        setOptions([
          {
            value: "new_email",
            label: "Write a new email",
            group: "New email",
          },
          ...broadcasts,
        ]);
      },
      (data) => {}
    );

    ManagerModel.onFetch(
      space.slug,
      { page: 1, per: 1000 },
      { scope: "", term: "" },
      (data) => {
        const _managers = data.data.map((item, index) => ({
          value: `${item.user_id}`,
          label: item.full_name,
        }));
        setManagers(_managers);
      },
      (data) => {}
    );
  };

  const onSend = () => {
    props.onCreate(form.values);
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSend(values))}>
        <MantineProvider theme={{ primaryShade: 6 }}>
          <Select
            label={t("spaces.settings.registration.steps.sendEmail.label1")}
            placeholder={t("spaces.settings.registration.steps.sendEmail.placeholder1")}
            // onChange={setSelectedBroadcast}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
            mt={20}
            data={options}
            {...form.getInputProps("object_id")}
          />

          <Select
            // value={sendAs}
            // onChange={setSendAs}
            label={t("spaces.settings.registration.steps.sendEmail.label2")}
            placeholder={t("spaces.settings.registration.steps.sendEmail.placeholder2")}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
            mt={20}
            data={managers}
            {...form.getInputProps("send_as")}
          />

          <Select
            // value={sendEmailTo}
            // onChange={setSendEmailTo}
            label={t("spaces.settings.registration.steps.sendEmail.label3")}
            placeholder={t("spaces.settings.registration.steps.sendEmail.placeholder3")}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
            mt={20}
            data={[
              { value: sendEmailTo(), label: sendEmailTo().split("-").reverse().join(" or ").charAt(0).toUpperCase() + sendEmailTo().split("-").reverse().join(" or ").slice(1) },
              { value: "managers", label: t("spaces.settings.registration.steps.sendEmail.label5") },
              { value: "custom", label: t("spaces.settings.registration.steps.sendEmail.label6") },
            ]}
            {...form.getInputProps("send_email_to")}
          />

          {form.values["send_email_to"] == "custom" && (
            <TextInput
              // onChange={(event) => setEmailAddress(event.currentTarget.value)}
              mt={20}
              placeholder={t("spaces.settings.registration.steps.sendEmail.placeholder4")}
              label={t("spaces.settings.registration.steps.sendEmail.label4")}
              radius="sm"
              size="md"
              {...form.getInputProps("email_address")}
            />
          )}
        </MantineProvider>
        <Button
          type="submit"
          leftIcon={<FontAwesomeIcon icon={solid("check")} />}
          mt={25}
          radius="xl"
          fullWidth
        >
          {t("spaces.settings.registration.steps.sendEmail.saveButton")}
        </Button>
      </form>
    </Box>
  );
}
