import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

export default function HeaderImage(props) {
  const space = useSelector((state) => state.space);
  const space2 = useSelector((state) => state.space2);
  const hiddenCover = useSelector((state) => state.hiddenCover);

  const [image, setImage] = useState({ url: null });

  useEffect(() => {
    if (getSpace() && getSpace().header) {
      setImage(getSpace().header);
    }
  }, [space, space2]);

  const getSpace = () => {
    if (props.useSpace2) {
      return space2;
    } else {
      return space;
    }
  };

  if (hiddenCover.hidden) {
    return null;
  }

  return (
    <>
      {image.url ? (
        <div
          className={`header-image`}
          style={{ backgroundImage: `url(${image.url})`, borderRadius: props.radius ? `${props.radius}px` : '0px' }}
        ></div>
      ) : (
        ""
      )}
    </>
  );
}
