import React from "react";

import {
  Text,
  Title,
  Box,
  Drawer,
  ScrollArea,
  Group,
  createStyles,
} from "@mantine/core";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  item: {
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 15,
    paddingLeft: 15,
    backgroundColor: theme.white,
    marginTop: 15,
    width: "100%",
  },
  freeformResponse: {
    display: "flex",
    alignItems: "flex-start",
    // borderRadius: theme.radius.md,
    // backgroundColor: theme.colors.gray[1],
    // paddingTop: 10,
    // paddingBottom: 10,
    // paddingRight: 30,
    // paddingLeft: 30,
    // marginTop: 10,
    width: "100%",
    fontSize: 14,
  },
}));

export default function ShowStructure(props) {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();
  // const [structure, setStructure] = useState(null)

  return (
    <Drawer
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Text size="xl" weight="800">
          Structure
        </Text>
      }
      padding="xl"
      size={600}
    >
      {props.structure && props.structure.id && (
        <ScrollArea style={{ height: "100%" }}>
          <Title
            sx={{
              fontWeight: 300,
            }}
          >
            {props.structure.title}
          </Title>
          {props.structure.description && (
            <Text color="dimmed" mt={10}>
              {props.structure.description}
            </Text>
          )}
          <Box mb={100}>
            {props.structure.steps.map((step, index) => {
              return (
                <>
                  <Box className={classes.item}>
                    <Group
                      noWrap
                      sx={{
                        alignItems: "flex-start",
                      }}
                    >
                      <Title order={5}>{index + 1}</Title>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Text size="sm" color="dimmed" mb={10} mt={1}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: step.wait_display,
                            }}
                          ></div>
                        </Text>

                        {step.title && <Title order={5}>{step.title}</Title>}

                        <Text className={classes.freeformResponse}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: step.description,
                            }}
                          ></div>
                        </Text>
                      </Box>
                    </Group>
                  </Box>
                </>
              );
            })}
          </Box>
        </ScrollArea>
      )}
    </Drawer>
  );
}
