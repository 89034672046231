import React from "react";

const VideoEmbed = ({ service, embedId }) => {
  if (service == "youtube") {
    return (
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://www.vimeo.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        >
        </iframe>
      </div>
    );
  } else if (service == "vimeo") {
    return (
      <div className="video-responsive">
        <iframe
          src={`https://player.vimeo.com/video/${embedId}`}
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>
    )
  } else if (service == "loom") {
    return (
      <div className="video-responsive">
        <iframe src={`https://www.loom.com/embed/${embedId}`}
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen>
        </iframe>
      </div>
    )
  }

}

export default VideoEmbed;
