import { Avatar } from '@mantine/core';
import  React, { useRef, useEffect, useState } from 'react';

export default function LazyAvatar(props) {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setLoaded(true);
          try {
            observer.unobserve(ref.current);
          }
          catch (e) {}
        }
      });
    });

    observer.observe(ref.current);

    return () => {
      if (ref.current){
        try {
          observer.unobserve(ref.current);
        }
        catch (e) {}
      }
    };
  }, []);

  return (
    <Avatar
      ref={ref}
      src={loaded ? props.src : ''}
      alt={props.alt}
      radius={props.radius}
      sizes={props.sizes}
      styles={props.styles}
      {...props}
    />
  );
}
