import React from "react";

import { useSelector } from "react-redux";

import "add-to-calendar-button/assets/css/atcb.css";

import { Text, Paper, Group, createStyles } from "@mantine/core";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
  },
  textColor: {
    color: colors.sidebar_text_color,
  },
  buttonColor: {
    border: isSpaceThemed(colors)
      ? `1px solid ${colors.sidebar_text_color}`
      : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : colors.primary_button_background_color,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
        : tinycolor(colors.sidebar_background_color).darken(1).toString(),
    },
  },
}));

export default function RedirectBar(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  return (
    <Paper
      radius={10}
      mt={15}
      mb={15}
      pt={12}
      pb={12}
      pl={20}
      pr={20}
      className={classes.panelWrap}
    >
      <Group position="apart">
        <Text align="center" weight={500} className={classes.textColor}>
          {`This space is being redirected to ${props.space.name}`}
        </Text>
      </Group>
    </Paper>
  );
}
