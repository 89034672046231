import React, { useState, useEffect } from 'react';
import {
  Box,
  TextInput,
  Group,
  ActionIcon,
  createStyles
} from '@mantine/core'

import { GripVertical, X } from 'tabler-icons-react';

import { Draggable } from 'react-beautiful-dnd';

import AnswerModel from '../../../../models/Answer'

import MultipleItem from './MultipleItem'
import SingleItem from './SingleItem'
import PaymentItem from './PaymentItem'

import { colors } from "../../../../collections/colors";

import Swal from 'sweetalert2'

const useStyles = createStyles((theme) => ({
  dragHandle: {
    ...theme.fn.focusStyles(),
    height: '100%',
    color: theme.colors.gray[6],
    cursor: 'move',
    paddingTop: 3,
    width: 20,
    maxWidth: 20
  },
}));

let timerId = null;

export default function AnswerItem(props) {

  const { classes, cx } = useStyles();

  const [content, setContent] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [answerId, setAnswerId] = useState("");

  useEffect(() => {
    if (props.answer && !props.answer.id && props.answer.new){

      onCreate()
    }
    else if (props.answer){
      setAnswerId(props.answer.id)

      if (props.answer.content){
        setContent(props.answer.content)
      }

      if (props.answer.payment_amount){
        setPaymentAmount(props.answer.payment_amount)
      }
    }
  }, [props])

  const onContentChange = (value) => {

    setContent(value)
    onUpdate(value, paymentAmount)
  }

  const onPaymentAmountChange = (value) => {
    setPaymentAmount(value)
    onUpdate(content, value)
  }

  const onCreate = () => {

    AnswerModel.onCreate(props.spaceId, props.surveyId, props.questionId, {content}, (data) => {
      setAnswerId(data.id)

      props.onUpdate({id: data.id, content: ""}, props.index)
    }, (data) => {

    })
  }

  const onUpdate = (_content, _payment_amount) => {
    if (!answerId){
      return
    }
    if (timerId){
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {

      AnswerModel.onUpdate(answerId, props.spaceId, props.surveyId, props.questionId, {content: _content, payment_amount: _payment_amount}, (data) => {

        props.onUpdate({id: answerId, content: _content, payment_amount: _payment_amount}, props.index)
      }, (data) => {

      })
    }, 1300)
  }

  const onDestroy = () => {
    if (!answerId){
      return
    }

    Swal.fire({
      title: "Are you sure?",
      html: props.type !== "payment" ? ("All collected option responses will be deleted.") : "",
      showDenyButton: true,
      confirmButtonText: "Yes, delete",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {

        AnswerModel.onDestroy(answerId, props.spaceId, props.surveyId, props.questionId, (data) => {
          props.onDestroy({id: answerId})
        }, (data) => {

        })

      } else if (result.isDenied) {

      }
    })
  }

  return (
    <Draggable key={`answer-${answerId}`} index={props.index} draggableId={`answer-${answerId}`}>
      {(provided, snapshot) => (
        <Group
          mt={15}
          spacing={0}
          {...provided.draggableProps}
          ref={provided.innerRef}>
          <Box
            className={classes.dragHandle}
            {...provided.dragHandleProps}>
            <GripVertical size={18} />
          </Box>
          {props.type == "multiple" ? (
            <MultipleItem
              content={content}
              onContentChange={onContentChange}
              onDestroy={onDestroy} />
          ) : ('')}
          {props.type == "single" ? (
            <SingleItem
              content={content}
              onContentChange={onContentChange}
              onDestroy={onDestroy} />
          ) : ('')}
          {props.type == "payment" ? (
            <PaymentItem
              content={content}
              paymentAmount={paymentAmount}
              onContentChange={onContentChange}
              onPaymentAmountChange={onPaymentAmountChange}
              onDestroy={onDestroy}
              currency={props.currency} />
          ) : ('')}
          {props.type == "freeform" ? (
            <TextInput
              placeholder="Response text"
              disabled
              radius="sm"
              size="md"
              sx={(theme) => ({
                width: "calc(100% - 20px)",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              })}
              rightSection={
                <ActionIcon
                  variant="transparent"
                  onClick={() => onDestroy()}>
                  <X size={18} />
                </ActionIcon>
              }
              rightSectionWidth={40}
              />
          ) : ('')}
        </Group>
      )}
    </Draggable>
  )
}
