import React, { useState, useEffect } from "react";

import { Container, Button, useMantineTheme, ScrollArea } from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SectionHeader from "./layout/SectionHeader";

import CreateSurvey from "./surveys/CreateSurvey";
import ShowSurvey from "./surveys/ShowSurvey";
import SurveyItem from "./surveys/SurveyItem";
import PaymentSurveyItem from "./surveys/PaymentSurveyItem";
import AddToSpaces from "./surveys/Add";

import SurveyModel from "../../../models/Survey";

import { sutraTheme } from "../../../collections/sutraTheme";

import PremiumModal from "../../app/PremiumModal";

export default function Surveys(props) {
  const theme = useMantineTheme();
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const [surveys, setSurveys] = useState([]);
  const [createSurveyOpened, setCreateSurveyOpened] = useState(false);
  const [showSurveyOpened, setShowSurveyOpened] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [showAddToSpaces, setShowAddToSpaces] = useState(false);

  const [validateOpened, setValidateOpened] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }

    if (props.scope == "payment") {
      setValidatePremium(
        space &&
          space.permissions &&
          space.permissions.can_set_flexible_payments
      );
    } else {
      setValidatePremium(
        space && space.permissions && space.permissions.can_survey
      );
    }
  }, [space]);

  useEffect(() => {
    if (space && createSurveyOpened && !validatePremium[0]) {
      setCreateSurveyOpened(false);
      setValidateOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (!createSurveyOpened) {
      setSelectedSurvey(null);
      if (space && space.slug) {
        onFetch();
      }
    }
  }, [createSurveyOpened]);

  const onFetch = () => {
    if (props.scope != "payment") {
      SurveyModel.onFetch(
        space.slug,
        { page: 1, per: 10000 },
        (data) => {
          setSurveys([...data.data]);
        },
        (data) => {}
      );
    } else {
      onFetchPayment();
    }
  };

  const onFetchPayment = () => {
    SurveyModel.onFetchPayment(
      space.slug,
      (data) => {
        setSurveys([...data.data]);
      },
      (data) => {}
    );
  };

  const onUpdateSurvey = (survey) => {
    onFetch();
  };

  const onEditSurvey = (survey) => {
    setSelectedSurvey(survey);
    setCreateSurveyOpened(survey);
  };

  const onSelectedSurvey = (survey) => {
    setSelectedSurvey(survey);
    setShowSurveyOpened(survey);
  };

  const onAddSurvey = (survey) => {
    setSelectedSurvey(survey);
    setShowAddToSpaces(survey);
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container
        pt={20}
        pb={props.scope != "payment" ? 40 : 20}
        pl={props.scope != "payment" ? 40 : 0}
        pr={props.scope != "payment" ? 40 : 0}
        sx={(theme) => ({
          // width: "960px",
          // maxWidth: "960px",
        })}
      >
        {props.scope != "payment" && (
          <SectionHeader
            spaceId={space.slug}
            spaceVersion={space.version}
            spaceName={space.name}
            title={t("spaces.settings.surveys.title")}
            rightSide={
              <Button
                variant="filled"
                radius="xl"
                onClick={() => setCreateSurveyOpened(true)}
                leftIcon={<FontAwesomeIcon icon={solid("pencil")} />}
                sx={{
                  backgroundColor: sutraTheme.colors.sutrablue[3],
                  "&:hover": {
                    backgroundColor: sutraTheme.colors.sutrablue[4],
                  },
                }}
              >
                {t("spaces.settings.surveys.createSurvey")}
              </Button>
            }
          />
        )}
        {surveys.map((survey) => {
          return (
            <>
              {props.scope != "payment" ? (
                <SurveyItem
                  spaceId={space.slug}
                  onUpdate={onUpdateSurvey}
                  onEdit={onEditSurvey}
                  survey={survey}
                  opened={showSurveyOpened}
                  setOpened={setShowSurveyOpened}
                  onSelect={onSelectedSurvey}
                  onAdd={onAddSurvey}
                />
              ) : (
                <PaymentSurveyItem
                  spaceId={space.slug}
                  onUpdate={onUpdateSurvey}
                  onEdit={onEditSurvey}
                  survey={survey}
                  opened={showSurveyOpened}
                  setOpened={setShowSurveyOpened}
                  onSelect={onSelectedSurvey}
                />
              )}
            </>
          );
        })}
        <CreateSurvey
          opened={createSurveyOpened}
          setOpened={setCreateSurveyOpened}
          survey={selectedSurvey}
          spaceId={space.slug}
          onUpdate={onUpdateSurvey}
          acceptPayments={props.scope == "payment"}
        />
        <ShowSurvey
          opened={showSurveyOpened}
          setOpened={setShowSurveyOpened}
          survey={selectedSurvey}
          spaceId={space.slug}
        />
        <AddToSpaces
          opened={showAddToSpaces}
          setOpened={setShowAddToSpaces}
          survey={selectedSurvey}
          spaceId={space.slug}
        />
        <PremiumModal opened={validateOpened} setOpened={setValidateOpened} />
      </Container>
    </ScrollArea>
  );
}
