import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Drawer,
  Box,
  Accordion,
  Button,
  Text,
  Switch,
  createStyles,
  ScrollArea,
} from "@mantine/core";

import { setTiptapSidemenu } from "../../../store/app";
import SpaceModel from "../../../models/Space";
import SpaceSettingModel from "../../../models/SpaceSetting";

import RegPageColor from "./RegPageColor";

const useStyles = createStyles((theme, _params, getRef) => ({
  tabInner: {
    justifyContent: "flex-start",
  },
  tabControl: {
    padding: 0,
    height: "30px",
    fontSize: 10,
    marginRight: 20,
    // color: 'red',
  },
  tabsList: {
    marginBottom: -5,
  },
  tabActive: {
    fontWeight: "bold",
    color: `${theme.colors.dark[9]} !important`,
    borderBottomColor: `${theme.colors.sutrablue[4]} !important`,
    // color: `${theme.colors.sutrablue[3]} !important`,
    // borderBottomColor: `${theme.colors.sutrablue[3]} !important`
  },
  item: {
    borderBottom: "none",
  },
  control: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 16,
    // backgroundColor: theme.colors.gray[0],
    // borderRadius: 5,
    // marginLeft: -5,
    // marginRight: "-20px !important",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    // marginRight: 8,
    // marginLeft: -5
  },
  content: {
    backgroundColor: theme.colors.gray[0],
    paddingLeft: 20,
    paddingRight: 20,
  },
  contentInner: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconWrapper: {
    ref: getRef("iconWrapper"),
  },
  chipChecked: {
    backgroundColor: `${theme.colors.dark[3]} !important`,
    color: theme.white,
    [`& .${getRef("iconWrapper")}`]: {
      color: theme.white,
    },
  },
  chipOutline3: {
    width: "80px",
    border: `1px solid ${theme.colors.dark[3]}`,
  },
  chipOutline4: {
    border: `1px solid ${theme.colors.dark[3]}`,
  },
  chipLabel: {
    textAlign: "center",
    fontSize: 9,
    fontWeight: 800,
    color: theme.colors.dark[3],
    height: 26,
  },
}));

const RegPageColors = (props) => {
  const { t, i18n } = useTranslation();
  const { classes, cx, theme } = useStyles();
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const auxColors = useRef({});
  const loading = useRef(false);
  const loaded = useRef(false);
  const [width, setWidth] = useState(false);

  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    return () => {
      loaded.current = false;
    };
  }, []);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "regPageColors") {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "regPageColors") {
        loaded.current = false;
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    }
  }, [opened]);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      auxColors.current = { ...colors };
      setOpened(opened);
    }
  }, [colors]);

  useEffect(() => {
    if (space && space.slug) {
      loading.current = true;

      setWidth(space.registration_width == "wide");

      setTimeout(() => {
        loading.current = false;
      }, 1000);
    }
  }, [space]);

  const onChange = (attr, color) => {
    auxColors.current[attr] = color;
    console.log(attr);
    console.log(color);
  };

  const onSave = () => {
    SpaceModel.onSaveColors(
      space.slug,
      auxColors.current,
      (data) => {
        onSaveWidth();
      },
      (data) => {
        Swal.fire(data.title, data.message, "error");
      }
    );
  };

  const onSaveWidth = () => {
    SpaceSettingModel.onUpdate(
      space.slug,
      {
        pod_settings_attributes: {
          registration_width: width ? "wide" : "narrow",
        },
      },
      (data) => {
        window.location.reload();
        // const auxSpace = { ...space };
        // auxSpace.registration_width = width ? "wide" : "narrow";
        // dispatch(setSpace(auxSpace));
        // window.$registrationWideWidth = width;

        // dispatch(setColors({ ...auxColors.current }));
        // dispatch(setTiptapSidemenu({ opened: null }));
      },
      (data) => {}
    );
  };

  return (
    <Drawer
      position="left"
      opened={opened}
      onClose={() => setOpened(false)}
      title={<Text size="xs">{t("tiptap.components.regPageColors.text")}</Text>}
      size={"300px"}
      withOverlay={false}
      closeOnClickOutside={false}
      overlayOpacity={0.1}
      zIndex={99999}
      styles={{
        header: {
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        },
      }}
    >
      <ScrollArea style={{ height: "100%" }}>
        <Box
          sx={(theme) => ({
            marginLeft: 0,
            marginRight: 0,
          })}
        >
          <Accordion
            offsetIcon={false}
            multiple
            iconPosition="right"
            mb={20}
            initialState={{
              0: true,
              1: true,
              2: true,
              3: true,
              4: true,
              5: true,
              6: true,
              7: true,
              8: true,
              9: true,
            }}
            classNames={{
              item: classes.item,
              control: classes.control,
              icon: classes.icon,
              content: classes.content,
              contentInner: classes.contentInner,
            }}
          >
            <Accordion.Item label={t("tiptap.components.regPageColors.label1")}>
              <RegPageColor
                attr={"registration_page_background_color"}
                title={t("tiptap.components.regPageColors.title1")}
                color={
                  auxColors.current.registration_page_background_color
                    ? auxColors.current.registration_page_background_color
                    : "#FFFFFF"
                }
                onChange={onChange}
              />
            </Accordion.Item>
            <Accordion.Item label={t("tiptap.components.regPageColors.label2")}>
              <RegPageColor
                attr={"registration_page_default_text_color"}
                title={t("tiptap.components.regPageColors.title2")}
                color={
                  auxColors.current.registration_page_default_text_color
                    ? auxColors.current.registration_page_default_text_color
                    : "#000000"
                }
                onChange={onChange}
              />
            </Accordion.Item>
            <Accordion.Item label="Layout">
              <Switch
                mt={10}
                checked={width}
                onChange={(event) => setWidth(event.currentTarget.checked)}
                label={t("tiptap.components.regPageColors.label3")}
              />
            </Accordion.Item>
          </Accordion>
          <Box pr={20} pl={20}>
            <Button fullWidth={true} onClick={(event) => onSave()} radius="xl">
              {t("tiptap.components.regPageColors.applyAppearance")}
            </Button>
          </Box>
        </Box>
      </ScrollArea>
    </Drawer>
  );
};

export default RegPageColors;
