import React, { useState, useEffect, useRef } from "react";

import {
  Checkbox,
  Divider,
  Text,
  Table,
  Group,
  ScrollArea,
  SegmentedControl,
  Button,
  Pagination,
  Box,
  TextInput,
  Loader,
} from "@mantine/core";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Row from "./RegisteredRow";
import MemberModel from "../../../../models/Member";
import ManagerModel from "../../../../models/Manager";

import PremiumModal from "../../../app/PremiumModal";

const statusColors = {
  active: "green",
  pending: "red",
  registration_survey: "blue",
  survey_payment: "yellow",
};

const statusString = {
  active: "Active",
  pending: "Pending",
  registration_survey: "Survey",
  survey_payment: "Payment",
};

const statusMessage = {
  active: "This member is active and has access to all space features",
  pending:
    "This member is currently pending and must be approved to access the space",
  registration_survey:
    "This member has registered but hasn't completed the registration survey",
  survey_payment:
    "This member has registered but hasn't completed the survey payment",
};

const paginator = {
  page: 1,
  per: 30,
};

const loader = {
  loading: false,
  oldScope: "all",
};

export default function Registered(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const location = useLocation();
  // const theme = useMantineTheme();
  const theme = props.theme;
  const { height, width } = useViewportSize();

  const [members, setMembers] = useState({
    total: 0,
    page: 1,
    pages: 1,
    data: [],
  });
  const [scope, setScope] = useState(null);
  const [term, setTerm] = useState(null);
  const [counts, setCounts] = useState({
    all: 0,
    active: 0,
    pending: 0,
    managers: 0,
  });

  const largeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.lg}px)`
  );
  const extraLargeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );

  const [opened, setOpened] = useState(false);

  const [selected, setSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  const timeoutId = useRef();

  const onSelect = (id) => {
    const newSelected = [...selected];
    const index = selected.indexOf(id);
    if (index >= 0) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  const onSelectAll = (all) => {
    const newSelected = [];
    if (all) {
      for (let i = 0; i < members.data.length; i++) {
        newSelected.push(members.data[i].username);
      }
    }
    setSelected(newSelected);
  };

  const areAllSelected = () => {
    return members.data.length == selected.length;
  };

  const hasSelections = () => {
    return selected.length > 0;
  };

  const isAnySelected = () => {
    return !areAllSelected() && selected.length > 0;
  };

  const isSelected = (id) => {
    return selected.indexOf(id) >= 0;
  };

  const tableWidth = () => {
    if (largeScreen) {
      return width - 300;
    } else if (extraLargeScreen) {
      return width - 500;
    } else {
      return "";
    }
  };

  const checkSearchParams = () => {
    const search = location.search;
    const scope = new URLSearchParams(search).get("scope");

    if (scope) {
      setScope(scope);
    }
  };

  const canAddNewManagers = () => {
    const valid =
      counts.managers < space.permissions.max_allowed_managers[0] ||
      space.permissions.max_allowed_managers[0] == -1;
    if (!valid) {
      setOpened([
        true,
        space.permissions.max_allowed_managers[1],
        space.permissions.max_allowed_managers[2],
      ]);
    }
    return valid;
  };

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
      onFetchCounts();
    }
  }, [space, scope]);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [term]);

  useEffect(() => {
    if (props.refresh) {
      setLoading(true);
      setTimeout(() => {
        onFetch();
        onFetchCounts();
        props.setRefresh(false);
      }, 2000);
    }
  }, [props]);

  const onFetch = () => {
    setLoading(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      if (loader.oldScope != scope) {
        paginator.page = 1;
        loader.oldScope = scope;
      }
      const Model = scope != "managers" ? MemberModel : ManagerModel;
      Model.onFetch(
        space.slug,
        paginator,
        { scope, term },
        (data) => {
          setLoading(false);
          setMembers(data);
        },
        (data) => {
          setLoading(false);
        }
      );
    }, 1000);
  };

  const onFetchCounts = () => {
    MemberModel.onFetchCounts(
      space.slug,
      (data) => {
        setCounts(data);
      },
      (data) => {}
    );
  };

  const onKeyPress = (event) => {
    onKeyGeneral(event);
  };
  const onKeyDown = (event) => {
    onKeyGeneral(event);
  };

  const onKeyGeneral = (event) => {
    if (event.which == 13) {
      event.preventDefault();
      onSearch();
    }
  };

  const onSearch = () => {
    setTerm(document.getElementById("search-input").value);
  };

  const onClearSearch = () => {
    document.getElementById("search-input").value = "";
    setTerm("");
  };

  const changePage = (page) => {
    paginator.page = page;
    onFetch();
  };

  const onMemberUpdate = (member) => {
    onFetchCounts();
  };

  const onApproveAll = () => {
    MemberModel.onApproveAll(
      space.slug,
      (data) => {
        onFetch();
        onFetchCounts();
      },
      (data) => {}
    );
  };

  return (
    <div>
      <Group position="apart" mt={20}>
        <Group position="left" spacing="xs">
          <TextInput
            sx={{ width: "200px" }}
            icon={<FontAwesomeIcon icon={solid("search")} />}
            placeholder={t(
              "spaces.settings.members.registered.registered.searchPlaceholder"
            )}
            id="search-input"
            radius="xl"
            // onKeyPress={(event) => onKeyPress(event)}
            onKeyDown={(event) => onKeyDown(event)}
            rightSection={
              term ? (
                <Text
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => onClearSearch()}
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </Text>
              ) : (
                <Text>{"  "}</Text>
              )
            }
          />
          <Button
            variant="filled"
            radius="xl"
            onClick={(event) => onSearch()}
            sx={{
              backgroundColor: theme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: theme.colors.sutrablue[4],
              },
            }}
          >
            {t("spaces.settings.members.registered.registered.searchButton")}
          </Button>
          {loading && <Loader size="xs" />}
        </Group>

        <Group position="right" spacing="xs">
          {!hasSelections() && (
            <SegmentedControl
              value={scope}
              onChange={setScope}
              radius="xl"
              sx={(theme) => ({ backgroundColor: theme.colors.gray[0] })}
              data={[
                {
                  label: `${t(
                    "spaces.settings.members.registered.registered.all"
                  )} (${counts.all})`,
                  value: "all",
                },
                {
                  label: `${t(
                    "spaces.settings.members.registered.registered.active"
                  )} (${counts.active})`,
                  value: "active",
                },
                {
                  label: `${t(
                    "spaces.settings.members.registered.registered.pending"
                  )} (${counts.pending})`,
                  value: "pending",
                },
                {
                  label: `${t(
                    "spaces.settings.members.registered.registered.manager"
                  )} (${counts.managers})`,
                  value: "managers",
                },
              ]}
            />
          )}
          {hasSelections() && (
            <>
              <Button
                radius="xl"
                sx={{
                  backgroundColor: theme.colors.sutrablue[3],
                  "&:hover": {
                    backgroundColor: theme.colors.sutrablue[4],
                  },
                }}
                onClick={(event) =>
                  props.onSendBroadcast("email", members.data, selected)
                }
              >
                {t("spaces.settings.members.registered.registered.email")}
              </Button>
              <Button
                radius="xl"
                sx={{
                  backgroundColor: theme.colors.sutrablue[3],
                  "&:hover": {
                    backgroundColor: theme.colors.sutrablue[4],
                  },
                }}
                onClick={(event) =>
                  props.onSendBroadcast("message", members.data, selected)
                }
              >
                {t("spaces.settings.members.registered.registered.message")}
              </Button>
            </>
          )}
        </Group>
      </Group>

      <ScrollArea mt={10} style={{ width: tableWidth() }}>
        {members.data.length == 0 && !loader.loading ? (
          <Box
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              textAlign: "center",
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
              cursor: "pointer",

              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[5]
                    : theme.colors.gray[1],
              },
            })}
          >
            {t("spaces.settings.members.registered.registered.noRecordFound")}
          </Box>
        ) : (
          <Table verticalSpacing="xs" highlightOnHover>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={areAllSelected()}
                    indeterminate={isAnySelected()}
                    onChange={(event) =>
                      onSelectAll(event.currentTarget.checked)
                    }
                  />
                </th>
                <th>
                  {t(
                    "spaces.settings.members.registered.registeredTable.member"
                  )}
                </th>
                <th>
                  {t(
                    "spaces.settings.members.registered.registeredTable.email"
                  )}
                </th>
                <th>
                  {t(
                    "spaces.settings.members.registered.registeredTable.status"
                  )}
                </th>
                <th colspan="2">
                  <Group position="apart">
                    <Text size="sm">
                      {t(
                        "spaces.settings.members.registered.registeredTable.role"
                      )}
                    </Text>
                    <Button
                      color="green"
                      variant="outline"
                      radius="xl"
                      size="xs"
                      compact
                      onClick={() => onApproveAll()}
                    >
                      {t(
                        "spaces.settings.members.registered.registeredTable.roleApproveAll"
                      )}
                    </Button>
                  </Group>
                </th>
              </tr>
            </thead>
            <tbody>
              {members.data.map((member) => {
                return (
                  <Row
                    hasSubscriptions={
                      space && space.permissions
                        ? space.permissions.can_accept_charges
                        : false
                    }
                    spaceId={space.slug}
                    onUpdate={onMemberUpdate}
                    member={member}
                    canAddNewManagers={canAddNewManagers}
                    isSelected={isSelected}
                    onSelect={onSelect}
                  />
                );
              })}
            </tbody>
          </Table>
        )}
      </ScrollArea>
      <Divider sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: 20,
          justifyContent: "center",
        }}
      >
        <Pagination
          color="gray"
          page={paginator.page}
          onChange={changePage}
          total={members.pages}
        />
      </Box>

      <PremiumModal opened={opened} setOpened={setOpened} />
    </div>
  );
}
