import { Extension } from '@tiptap/core'
import {stylesToString} from '../../../utils/StylesToString'

// https://tiptap.dev/guide/custom-extensions#attributes

const ColumnStyles = Extension.create({
  name: "columnStyles",
  addOptions() {
    return {
      types: [],
    }
  },
  addGlobalAttributes() {

    return [
      {
        // Extend the following extensions
        types: [
          'column',
        ],
        // … with those attributes
        attributes: {
          styles: {
            default: {
              "align-items": "flex-start",
              "background-color": "",
              "background-image": "none",
              "background-position": "center center",
              "background-size": "cover",
              "background-repeat": "no-repeat",
              "margin-top": "0px",
              "margin-bottom": "0px",
              "margin-left": "0px",
              "margin-right": "0px",
              "padding-top": "0px",
              "padding-bottom": "0px",
              "padding-left": "0px",
              "padding-right": "0px",
            },
            renderHTML: attributes => ({
              style: stylesToString(attributes.styles),
              styles: stylesToString(attributes.styles),
            })
          }
        },
      },
    ]
  },
  addCommands() {
    return {
      setColumnStyles: (styles) => ({ commands, chain, view }) => {
        if (!window.$tiptapSelectionRange){
          return
        }
        const {from, to} = window.$tiptapSelectionRange

        const { selection } = view.state

        const content = selection.node ? selection.node : selection.$anchor.parent

        return commands.updateAttributes(content.type.name, { styles: styles })


      },
    }
  },
})

export default ColumnStyles;
