import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Drawer, Text, Box, ScrollArea } from "@mantine/core";

import { setTiptapSidemenu } from "../../../store/app";

import TiptapReadonly from "./TiptapReadonly";

const ViewPage = (props) => {
  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);
  const [page, setPage] = useState({});

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "viewPage") {
      setPage({...tiptapSidemenu.option.page})
      setOpened(true);
    } else {
      if (!window.$modalSpaceOpened){
        setOpened(false);
      }
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "viewPage") {
        if (tiptap && tiptap.editor) {
          tiptap.editor.commands.focus();
        }
        window.$closingSideMenu = true;
        dispatch(setTiptapSidemenu({ opened: null }));
      }
    }
  }, [opened]);

  return (
    <Drawer
      position="left"
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {page.id
            ? page.title
            : `Resource page`}
        </Text>
      }
      padding="xl"
      size={700}
      // overlayOpacity={0.1}
      styles={{
        drawer: {
          backgroundColor: colors.background_color,
          color: colors.default_text_color,
        },
      }}
      zIndex={99999}
    >
      <ScrollArea style={{ height: "100%" }}>
        {page &&
        page.content_json ? (
          <TiptapReadonly
            content={{
              type: "doc",
              content: page.content_json,
            }}
          />
        ) : (
          <Text color="dimmed">{t("tiptap.components.viewPage.text")}</Text>
        )}
        <Box mt={100} />
      </ScrollArea>
    </Drawer>
  );
};

export default ViewPage;
