import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Switch } from "@mantine/core";

import SpaceModel from "../../../models/Space";
import SpaceSettingModel from "../../../models/SpaceSetting";
import {
  setHiddenSidebar,
  setHiddenHeader,
  setHiddenCover,
  setHiddenTitle,
  setHiddenBreadcrumbs,
  setWideWidth,
  setSpace
  
} from "../../../store/app";

import PremiumModal from "../../app/PremiumModal";

import { useTranslation } from "react-i18next";

export default function PageLayout(props) {
  const { t, i18n } = useTranslation();
  const hiddenLayoutElements = useRef([]);
  const loading = useRef(false);

  const [sidebar, setSidebar] = useState(false);
  const [header, setHeader] = useState(false);
  const [cover, setCover] = useState(false);
  const [title, setTitle] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState(true);
  const [opened, setOpened] = useState(false);
  const [width, setWidth] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  const space = useSelector((state) => state.space);
  const dispatch = useDispatch();

  useEffect(() => {
    if (space && space.slug) {
      loading.current = true;
      if (window.$hiddenLayoutElements) {
        hiddenLayoutElements.current = [...window.$hiddenLayoutElements];
      } else {
        hiddenLayoutElements.current = [...space.hidden_layout_elements];
      }

      setSidebar(hiddenLayoutElements.current.indexOf("sidebar") >= 0);
      setHeader(hiddenLayoutElements.current.indexOf("header") >= 0);
      setCover(hiddenLayoutElements.current.indexOf("cover") >= 0);
      setTitle(hiddenLayoutElements.current.indexOf("title") >= 0);
      setBreadcrumbs(hiddenLayoutElements.current.indexOf("breadcrumbs") >= 0);
      setWidth(space.space_width == "wide");

      setValidatePremium(
        space && space.permissions && space.permissions.can_set_layout
      );
      setTimeout(() => {
        loading.current = false;
      }, 1000);
    }
  }, [space]);

  useEffect(() => {
    if (sidebar && !validatePremium[0] && !loading.current) {
      setSidebar(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (sidebar) {
      if (!isHidden("sidebar")) {
        hiddenLayoutElements.current.push("sidebar");
      }
    } else {
      if (isHidden("sidebar")) {
        removeHiddenLayoutElements("sidebar");
      }
    }
    onSave();
  }, [sidebar]);

  useEffect(() => {
    if (header && !validatePremium[0] && !loading.current) {
      setHeader(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (header) {
      if (!isHidden("header")) {
        hiddenLayoutElements.current.push("header");
      }
    } else {
      if (isHidden("header")) {
        removeHiddenLayoutElements("header");
      }
    }
    onSave();
  }, [header]);

  useEffect(() => {
    if (cover && !validatePremium[0] && !loading.current) {
      setCover(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (cover) {
      if (!isHidden("cover")) {
        hiddenLayoutElements.current.push("cover");
      }
    } else {
      if (isHidden("cover")) {
        removeHiddenLayoutElements("cover");
      }
    }
    onSave();
  }, [cover]);

  useEffect(() => {
    if (title && !validatePremium[0] && !loading.current) {
      setTitle(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (title) {
      if (!isHidden("title")) {
        hiddenLayoutElements.current.push("title");
      }
    } else {
      if (isHidden("title")) {
        removeHiddenLayoutElements("title");
      }
    }
    onSave();
  }, [title]);

  useEffect(() => {
    if (breadcrumbs && !validatePremium[0] && !loading.current) {
      setBreadcrumbs(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }

    if (breadcrumbs) {
      if (!isHidden("breadcrumbs")) {
        hiddenLayoutElements.current.push("breadcrumbs");
      }
    } else {
      if (isHidden("breadcrumbs")) {
        removeHiddenLayoutElements("breadcrumbs");
      }
    }
    onSave();
  }, [breadcrumbs]);

  useEffect(() => {
    if (loading.current){
      return
    }
    onSaveWidth();
    const auxSpace = {...space}
    auxSpace.space_width = width ? "wide" : "narrow"
    dispatch(setSpace(auxSpace))
    window.$spaceWideWidth = width
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }, [width]);

  const isHidden = (element) => {
    return hiddenLayoutElements.current.indexOf(element) >= 0;
  };

  const removeHiddenLayoutElements = (element) => {
    const index = hiddenLayoutElements.current.indexOf(element);
    if (index >= 0) {
      hiddenLayoutElements.current.splice(index, 1);
    }
  };

  const onSaveWidth = () => {
    SpaceSettingModel.onUpdate(
      space.slug,
      {
        pod_settings_attributes: {
          space_width: width ? "wide" : "narrow",
        },
      },
      (data) => {
        
      },
      (data) => {}
    )
  }

  const onSave = () => {
    if (loading.current) {
      return;
    }
    window.$hiddenLayoutElements = [...hiddenLayoutElements.current];
    SpaceModel.onUpdate(
      space.slug,
      { hidden_layout_elements: [...hiddenLayoutElements.current], space_width: width ? "wide" : "narrow" },
      (data) => {
        const newSpace = { ...space };

        dispatch(
          setHiddenSidebar({
            hidden: hiddenLayoutElements.current.indexOf("sidebar") >= 0,
          })
        );

        if (hiddenLayoutElements.current.indexOf("header") >= 0) {
          dispatch(
            setHiddenHeader({
              hidden: space.permissions.can_manage ? "admin" : true,
            })
          );
        } else {
          dispatch(setHiddenHeader({ hidden: false }));
        }
        dispatch(
          setHiddenCover({
            hidden: hiddenLayoutElements.current.indexOf("cover") >= 0,
          })
        );
        dispatch(
          setHiddenTitle({
            hidden: hiddenLayoutElements.current.indexOf("title") >= 0,
          })
        );
        dispatch(
          setHiddenBreadcrumbs({
            hidden: hiddenLayoutElements.current.indexOf("breadcrumbs") >= 0,
          })
        );
        dispatch(setWideWidth({ on: width }));
      },
      (data) => {}
    );
  };

  return (
    <>
      <Switch
        checked={width}
        onChange={(event) => setWidth(event.currentTarget.checked)}
        label={t("spaces.colors.pageLayout.widePage")}
        size="lg"
      />

      <Switch
        mt={20}
        checked={sidebar}
        onChange={(event) => setSidebar(event.currentTarget.checked)}
        label={t("spaces.colors.pageLayout.hideSidebar")}
        size="lg"
      />

      <Switch
        mt={20}
        checked={header}
        onChange={(event) => setHeader(event.currentTarget.checked)}
        label={t("spaces.colors.pageLayout.hideHeader")}
        size="lg"
      />
      <Switch
        mt={20}
        checked={cover}
        onChange={(event) => setCover(event.currentTarget.checked)}
        label={t("spaces.colors.pageLayout.hideCoverImage")}
        size="lg"
      />
      <Switch
        mt={20}
        checked={title}
        onChange={(event) => setTitle(event.currentTarget.checked)}
        label={t("spaces.colors.pageLayout.hidePageTitle")}
        size="lg"
      />
      <Switch
        mt={20}
        checked={breadcrumbs}
        onChange={(event) => setBreadcrumbs(event.currentTarget.checked)}
        label={t("spaces.colors.pageLayout.hideBreadcrumbs")}
        size="lg"
      />

      <PremiumModal opened={opened} setOpened={setOpened} />
    </>
  );
}
