import React, { useState, useEffect, useRef } from "react";

import {
  Box,
  Group,
  Button,
  MantineProvider,
  Skeleton,
  useMantineTheme,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";

import SurveyQuestion from "./SurveyQuestion";
import CreateSurveyMain from "./CreateSurveyMain";

import SurveyModel from "../../../../models/Survey";
import QuestionModel from "../../../../models/Question";

import { sutraTheme } from "../../../../collections/sutraTheme";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useListState } from "@mantine/hooks";

export default function CreateSurvey(props) {
  const [scheduled, setScheduled] = useState(false);

  const theme = useMantineTheme();

  // const [questions, setQuestions] = useState([])
  const [surveyId, setSurveyId] = useState(null);
  const { t, i18n } = useTranslation();
  const [questions, handlers] = useListState([]);
  const [currency, setCurrency] = useState(null);

  const [loading, setLoading] = useState(false);

  const form = useRef({});

  useEffect(() => {
    if (props.survey && props.survey.id) {
      setLoading(true);
      setSurveyId(props.survey.id);
      onFetch();
    } else {
      if (!props.survey) {
        setSurveyId(null);
        handlers.setState([]);
        setCurrency(null);
      }
    }
  }, [props]);

  useEffect(() => {
    if (!props.spaceId || !surveyId || questions.length == 0) {
      return;
    }

    const order = questions.map((question, index) => {
      return { id: question.id, position: index };
    });

    QuestionModel.onReorder(
      props.spaceId,
      surveyId,
      order,
      (data) => {},
      (data) => {}
    );
  }, [questions]);

  const onFetch = () => {
    SurveyModel.onFetchById(
      props.survey.id,
      props.spaceId,
      (data) => {
        handlers.setState(data.questions);
        setCurrency(data.currency);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      },
      (data) => {}
    );
  };

  const onCreate = () => {
    SurveyModel.onCreate(
      props.spaceId,
      form.current,
      (data) => {
        setSurveyId(data.id);

        setTimeout(() => {
          onPushNewQuestion();
        }, 100);
      },
      (data) => {}
    );
  };

  const onUpdate = () => {
    if (!surveyId) {
      return;
    }

    SurveyModel.onUpdate(
      surveyId,
      props.spaceId,
      form.current,
      (data) => {},
      (data) => {}
    );
  };

  const onUpdateQuestion = (question, index) => {
    const newQuestion = { ...questions[index] };
    if (!newQuestion.id || newQuestion.id == question.id) {
      newQuestion.id = question.id;
      newQuestion.content = question.content;
      newQuestion.question_type = question.question_type;

      newQuestion.answers = question.answers;
      handlers.setItem(index, newQuestion);
    }
  };

  const onUpdateLastQuestion = (id) => {
    const question = questions[questions.length - 1];
    question.new = false;
    question.id = id;
    handlers.setState([...questions]);
  };

  const onDestroyQuestion = (question) => {
    const newQuestions = [...questions];
    for (let i = 0; i < newQuestions.length; i++) {
      const _question = newQuestions[i];
      if (_question.id == question.id) {
        newQuestions.splice(i, 1);
        break;
      }
    }

    handlers.setState(newQuestions);
  };

  const onAddQuestion = () => {
    if (!surveyId && form.current.title) {
      onCreate();
    } else if (surveyId) {
      onPushNewQuestion();
    }
  };

  const onPushNewQuestion = () => {
    const newQuestions = [...questions];
    newQuestions.push({ new: true, position: questions.length + 1 });
    handlers.setState(newQuestions);
  };

  const onSave = () => {
    if (loading) {
      return;
    }
    if (!surveyId && form.current.title) {
      onCreate();
    } else if (surveyId && form.current.title) {
      onUpdate();
    }
    setTimeout(() => {
      props.setOpened(false);
    }, 500);
  };

  const onUpdateForm = (_form) => {
    form.current = { ..._form };
  };

  if (loading) {
    return (
      <Box>
        <Skeleton height={20} width="100%" mt={20} mb={20} />
        <Skeleton height={20} width="100%" mt={20} mb={20} />
      </Box>
    );
  }

  return (
    <>
      <CreateSurveyMain
        survey={props.survey}
        spaceId={props.spaceId}
        onUpdate={props.onUpdate}
        acceptPayments={props.acceptPayments}
        onUpdateForm={onUpdateForm}
      />
      <DragDropContext
        onDragEnd={({ destination, source }) => {
          handlers.reorder({ from: source.index, to: destination.index });
        }}
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {questions.map((question, index) => {
                return (
                  
                  <SurveyQuestion
                    index={index}
                    spaceId={props.spaceId}
                    surveyId={surveyId}
                    question={question}
                    currency={currency ? currency : {}}
                    onUpdate={onUpdateQuestion}
                    onUpdateLast={onUpdateLastQuestion}
                    onDestroy={onDestroyQuestion}
                    acceptPayments={props.acceptPayments}
                    quizMode={form.current ? form.current.after_submit_show_score_and_keys : false}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Group position="apart">
        {!props.acceptPayments && (
          <Button
            variant="filled"
            radius="xl"
            // size="md"
            mt={15}
            leftIcon={<FontAwesomeIcon icon={solid("plus")} />}
            sx={(theme) => ({
              // backgroundColor: theme.colors.sutrablue[3],
              backgroundColor: sutraTheme.colors.sutrablue[3],
              "&:hover": {
                // backgroundColor: theme.colors.sutrablue[4],
                backgroundColor: sutraTheme.colors.sutrablue[4],
              },
            })}
            onClick={() => onAddQuestion()}
          >
            {t(
              "spaces.settings.survey.createSurveyForm.form.addQuestionButton"
            )}
          </Button>
        )}
        <Group>
          <MantineProvider theme={{ primaryShade: 6 }}>
            <Button
              variant="filled"
              radius="xl"
              mt={15}
              // size="md"
              color="green"
              leftIcon={<FontAwesomeIcon icon={solid("check")} />}
              onClick={() => onSave()}
            >
              {t("spaces.settings.survey.createSurveyForm.form.saveButton")}
            </Button>
          </MantineProvider>
          {props.extraButton && props.extraButton}
        </Group>
      </Group>
      <Box sx={{ height: 100 }} />
    </>
  );
}
