import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Stack,
  NativeSelect,
  Switch,
  MultiSelect,
  Select,
  Loader,
} from "@mantine/core";

import SpaceSettingModel from "../../../../models/SpaceSetting";
import SpaceModel from "../../../../models/Space";
import TagModel from "../../../../models/Tag";

import Tag from "../../../spaces_neo/presentations/discussion/Tag";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function ButtonAction(props) {
  const location = useLocation();
  const timeoutId = useRef(null);
  const { t, i18n } = useTranslation();
  const loading = useRef(false);
  const searchTimeoutId = useRef(false);
  const tagsLoader = useRef(null);
  const tagsAux = useRef([]);

  const space = useSelector((state) => state.space);
  const tiptap = useSelector((state) => state.tiptap);
  const [searching, setSearching] = useState(false);
  const [spaces, setSpaces] = useState([]);

  const [id, setId] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [tagsFeedShowAll, setTagsFeedShowAll] = useState(false);
  const [tagsShowUser, setTagsShowUser] = useState(false);
  const [tagsDate, setTagsDate] = useState(false);
  const [tagsShowMessageActions, setTagsShowMessageActions] = useState(false);
  const [tagsShowMTagsOnEachMessage, setTagsShowMTagsOnEachMessage] =
    useState(false);
  const [tagsNumberOfPosts, setTagsNumberOfPosts] = useState("5");

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    let edited = true;

    if (props.element.node.attrs.id != id) {
      edited = true;
      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        id: id,
      });
      tagsLoader.current = null
      onFetchTags();
    }
    if (props.element.node.attrs.tagsFeedShowAll != tagsFeedShowAll) {
      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        tagsFeedShowAll: tagsFeedShowAll,
      });
    }
    if (props.element.node.attrs.tagsShowUser != tagsShowUser) {
      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        tagsShowUser: tagsShowUser,
      });
    }
    if (props.element.node.attrs.tagsDate != tagsDate) {
      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        tagsDate: tagsDate,
      });
    }
    if (props.element.node.attrs.tagsShowMessageActions != tagsShowMessageActions) {
      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        tagsShowMessageActions: tagsShowMessageActions,
      });
    }
    if (props.element.node.attrs.tagsShowMTagsOnEachMessage != tagsShowMTagsOnEachMessage) {
      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        tagsShowMTagsOnEachMessage: tagsShowMTagsOnEachMessage,
      });
    }
    if (props.element.node.attrs.tagsNumberOfPosts != tagsNumberOfPosts) {
      tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
        tagsNumberOfPosts: tagsNumberOfPosts,
      });
    }
    tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
      tags: tags,
    });

    if (edited) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        if (id) {
          
          window.$reloadTagsFeed = id;
          props.saveNodeData();
        }
      }, 2000);
    }
  }, [id, tagsFeedShowAll, tagsShowUser, tagsDate, tags, tagsShowMessageActions, tagsShowMTagsOnEachMessage, tagsNumberOfPosts]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;
      if (props.element.node.attrs.id instanceof Array){
        setId(props.element.node.attrs.id);
      }
      else if (props.element.node.attrs.id instanceof String){
        setId([props.element.node.attrs.id]);
      }
      else{
        setId([])
      }
      setTags(props.element.node.attrs.tags);
      setTagsFeedShowAll(props.element.node.attrs.tagsFeedShowAll);
      setTagsShowUser(props.element.node.attrs.tagsShowUser);
      setTagsDate(props.element.node.attrs.tagsDate);
      setTagsShowMessageActions(props.element.node.attrs.tagsShowMessageActions);
      setTagsShowMTagsOnEachMessage(props.element.node.attrs.tagsShowMTagsOnEachMessage);
      setTagsNumberOfPosts(props.element.node.attrs.tagsNumberOfPosts);
      tagsLoader.current = null
      onFetchTags();
      onFetchSpaces();
    }
  }, [props.element]);

  const onSearchSpaces = (query) => {
    setSearching(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query);
      setSearching(false);
    }, 1000);
  };

  const onFetchSpaces = (query = null) => {
    const include = [
      "members",
      "simple",
      `priority-context-${window.$fetchedTopSlug}`,
      `include-ids-${id.join(",")}`
    ];
    if (query) {
      include.push(`q=${query}`);
    }
    SpaceModel.onFetchManagedDiscussions(
      null,
      include,
      { page: 1, per: 10 },
      (data) => {
        const _spaces = [];
        for (let i = 0; i < data.length; i++) {
          _spaces.push({
            value: data[i].slug,
            label: `${data[i].name} (${data[i].slug})`,
          });
        }
        setSpaces(_spaces);
      },
      (data) => {}
    );
  };

  const onFetchTags = () => {
    if (tagsLoader.current == null){
      tagsLoader.current = 0
    }
    else{
      tagsLoader.current ++;
    }

    try{
  
      const _id = props.element.node.attrs.id[tagsLoader.current];
      TagModel.onFetch(
        _id,
        (data) => {
          tagsAux.current = mergeArraysUniqueByKey(tagsAux.current, data, 'value')
          setTagsList([...tagsAux.current]);
          onFetchTags()
        },
        (data) => {}
      );
    }
    catch{}
    
  };

  const mergeArraysUniqueByKey = (array1, array2, key) => {
    const merged = {};

    // Add elements from array1
    array1.forEach(item => {
        merged[item[key]] = item;
    });

    // Add elements from array2, overwriting existing ones with the same key
    array2.forEach(item => {
        merged[item[key]] = item;
    });

    // Extract values from the merged object
    const result = Object.values(merged);

    return result;
}

  const onTag = () => {};
  const setTag = () => {};
  const onUpdated = () => {};

  return (
    <Stack mt={20}>
      <MultiSelect
        zIndex={99999}
        icon={
          searching ? (
            <Loader size="xs" />
          ) : (
            <FontAwesomeIcon icon={solid("search")} />
          )
        }
        searchable
        limit={100}
        mt={tagsNumberOfPosts}
        data={spaces}
        onChange={(selection) => setId(selection)}
        label={"Fetch tag messages from"}
        placeholder={"Search space"}
        description={`Select a chat or discussion prompt to fetch the messages from`}
        value={id}
        onSearchChange={(query) => onSearchSpaces(query)}
        styles={{
          description: {
            fontWeight: 600,
          },
          label: {
            whiteSpace: "normal"
          },
          defaultValue: {
            height: "unset"
          }
        }}
      />

      <MultiSelect
        searchable
        value={tags}
        onChange={setTags}
        data={tagsList}
        zIndex={99999}
        label={"Select the tags to filter messages"}
        placeholder={"Search tag"}
        description={`Select tags to filter the messages in the feed`}
        styles={{
          label: {
            whiteSpace: "normal"
          },
          defaultValue: {
            height: "unset"
          }
        }}
      />

      <Switch
        checked={tagsFeedShowAll}
        onChange={(event) => setTagsFeedShowAll(event.target.checked)}
        label={"Show posts from current user only"}
        placeholder={"Toggle to show posts from current user only"}
      />

      <Switch
        checked={tagsShowUser}
        onChange={(event) => setTagsShowUser(event.target.checked)}
        label={"Show message username and avatar"}
        placeholder={
          "Toggle to show the user username and avatar on each message"
        }
      />

      <Switch
        checked={tagsDate}
        onChange={(event) => setTagsDate(event.target.checked)}
        label={"Show message posted date"}
        placeholder={"Toggle to show the date when the messages was posted"}
      />

      <Switch
        checked={tagsShowMessageActions}
        onChange={(event) => setTagsShowMessageActions(event.target.checked)}
        label={"Show message actions"}
        placeholder={"Toggle to show actions for each message"}
      />

      <Switch
        checked={tagsShowMTagsOnEachMessage}
        onChange={(event) =>
          setTagsShowMTagsOnEachMessage(event.target.checked)
        }
        label={"Show message tags"}
        placeholder={"Toggle to show the tags of each  message"}
      />

      <Select
        zIndex={99999}
        mt={5}
        data={[
          {value: "5", label: "5 posts"},
          {value: "10", label: "10 posts"},
          {value: "25", label: "25 posts"},
        ]}
        onChange={(selection) => setTagsNumberOfPosts(selection)}
        label={"Number of posts"}
        placeholder={"Select a number"}
        description={`Select the number of post that will load initially`}
        value={tagsNumberOfPosts}
        styles={{
          description: {
            fontWeight: 600,
          },
        }}
      />
    </Stack>
  );
}
