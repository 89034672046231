import React from 'react';
import {
  Text,
  Box
} from '@mantine/core'

export default function Continuously() {

  return (
    <Box mt={20} mb={20}>
    </Box>
  )
}
