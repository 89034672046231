import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  ScrollArea,
  Stack,
  TextInput,
  Box,
  createStyles,
  Loader,
  Center,
} from "@mantine/core";
import PageModel from "../../../models/Page";

import { useForm } from "@mantine/form";

import { useTranslation } from "react-i18next";

import TiptapInline from "../../tiptap/components/TiptapInline";

import { useFocusTrap } from "@mantine/hooks";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../utils/IsSpaceThemed";

import { storeJSON } from "../../../utils/LocalDB";

const useStyles = createStyles((theme, { colors }) => ({
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
}));

export default function Form(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });
  const [newSpaceLoading, setNewSpaceLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const focusTrapRef = useFocusTrap();

  const form = useForm({
    initialValues: {
      title: "",
      content_json: "",
    },

    validate: {
      title: (value) => (value.length >= 3 ? null : "write a title"),
      // start_date: (value) => value.length >= 3 ? null : t('spaces.create.invalidNameErrorMsg'),
    },
  });

  useEffect(() => {
    if (props.page) {
      form.setFieldValue("title", props.page.title);
      form.setFieldValue("content_json", props.page.content_json);
    }
  }, [props]);

  const onSave = () => {
    if (!props.page) {
      onCreate();
    } else {
      onUpdate();
    }
  };

  const onCreate = () => {
    if (newSpaceLoading) {
      return;
    }
    setNewSpaceLoading(true);
    const pageParams = { ...form.values };

    PageModel.onCreate(
      props.resourceId,
      props.spaceId,
      pageParams,
      (data) => {
        storeJSON("spaces", `new-page-${props.resourceId}-${props.spaceId}`, {
          json: "",
          html: "",
        });
        props.onSave(data.id);
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 500);
      },
      (data) => {
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 500);
      }
    );
  };

  const onUpdate = () => {
    if (newSpaceLoading) {
      return;
    }
    setNewSpaceLoading(true);
    const pageParams = { ...form.values };

    PageModel.onUpdate(
      props.page.id,
      props.resourceId,
      props.spaceId,
      pageParams,
      (data) => {
        props.onSave(data.id);
        storeJSON(
          "spaces",
          `edit-page-${props.resourceId}-${props.spaceId}-${props.page.id}`,
          { json: "", html: "" }
        );
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 500);
      },
      (data) => {
        setTimeout(() => {
          setNewSpaceLoading(false);
        }, 500);
      }
    );
  };

  const onContentUpdate = (content) => {
    form.setFieldValue("content_json", content.content);
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <form
        onSubmit={form.onSubmit((values) => onSave(values))}
        ref={focusTrapRef}
      >
        <Stack>
          <TextInput
            {...form.getInputProps("title")}
            label={t("resources.pages.form.label")}
            styles={{
              label: {
                color: colors.default_text_color,
              },
              input: {
                "&:focus": {
                  borderColor: isSpaceThemed(colors)
                    ? colors.primary_button_background_color
                    : theme.colors.sutrablue[3],
                },
              },
            }}
            placeholder={t("resources.pages.form.placeholder")}
            data-autofocus
          />
          <TiptapInline
            editable={true}
            content={props.page ? props.page.content_json : ""}
            modals={true}
            onUpdate={onContentUpdate}
            exclude={[]}
            contextDb={
              !props.page
                ? `new-page-${props.resourceId}-${props.spaceId}`
                : `edit-page-${props.resourceId}-${props.spaceId}-${props.page.id}`
            }
          />

          {newSpaceLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <Button
              radius="xl"
              size="md"
              mt={10}
              className={classes.primaryButtonStyle}
              type="submit"
            >
              {props.page
                ? t("resources.pages.form.save")
                : t("resources.pages.form.create")}
            </Button>
          )}
        </Stack>
      </form>
      <Box mt={100} />
    </ScrollArea>
  );
}
