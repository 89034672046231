import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Stack,
  NativeSelect,
  Switch,
  TextInput,
  Select,
  Loader,
} from "@mantine/core";

import BundleModel from "../../../../models/Bundle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function BundleAction(props) {
  const timeoutId = useRef(null);
  const { t, i18n } = useTranslation();
  const loading = useRef(false);

  const space = useSelector((state) => state.space);
  const tiptap = useSelector((state) => state.tiptap);
  const [callback, setCallback] = useState("message");

  const [bundles, setBundles] = useState([]);

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const newCallback = `/space/${space.slug}/gateway?bundles=${callback}`;

    let edited = false;

    if (newCallback) {
      edited = true;
      tiptap.editor.commands.setCustomButtonAttr(
        "actionCallbackValue",
        newCallback
      );
    }

    if (edited) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        if (newCallback) {
          props.saveNodeData();
        }
      }, 2000);
    }
  }, [callback]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;
      const vals = props.element.node.attrs.actionCallbackValue.split("bundles=").pop()
      console.log(vals)
      setCallback(vals);
    }
    onFetch();
  }, [props.element]);

  const onFetch = () => {
    BundleModel.onFetch(
      space.slug,
      (data) => {
        const _bundles = data.map((item, index) => ({
          value: `${item.id}`,
          label: item.name,
          group: "Select an existing bundle",
        }));
        setBundles([
          {
            value: "new_bundle",
            label: "Create a new bundle",
            group: "New bundle",
          },
          ..._bundles,
        ]);
      },
      (data) => {}
    );
  };

  return (
    <Stack mt={20}>
      <NativeSelect
        value={callback}
        onChange={(event) => setCallback(event.target.value)}
        label={"Select a bundle"}
        data={bundles}
        styles={{
          label: {
            fontWeight: 400,
            color: "#000",
          },
        }}
      />
    </Stack>
  );
}
