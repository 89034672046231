import React, { useState, useEffect, useRef, useContext } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  Drawer,
  SimpleGrid,
  Button,
  Image,
  Text,
  ScrollArea,
  Box,
  Accordion,
  Tooltip,
  Center,
  Popover,
  createStyles,
} from "@mantine/core";

import { setTiptapSidemenu } from "../../../store/app";

import SpaceModel from "../../../models/Space";
import MediaFileModel from "../../../models/MediaFile";
import SpaceSettingModel from "../../../models/SpaceSetting";
import { sideMenuOptions } from "../collections/sideMenuOptions";
import { addDummyCursor, removeDummyCursor } from "../utils/dummyCursor";
import { isTopNode, getTopParentNode } from "../utils/selection";

import PremiumModal from "../../app/PremiumModal";

import Broadcaster from "../../../helpers/Broadcaster";

import TemplatesModal from "./TemplatesModal";

import Swal from "sweetalert2";

const useStyles = createStyles((theme, _params, getRef) => ({
  blockGridItem: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRight: `1px solid ${theme.colors.gray[3]}`,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    paddingLeft: 10,
    paddingRight: 10,
    height: 100,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.colors.gray[2],
    },
  },

  blockGridItemLayout: {
    height: 110,
    justifyContent: "center",
  },

  blockGridItemTemplate: {
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 100,
    backgroundColor: theme.colors.gray[0],
  },

  blockName: {
    fontSize: 12,
    width: "100%",
    textAlign: "center",
    lineHeight: 1.1,
    height: 40,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    paddingBottom: 4,
  },

  imageWrapper: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  iconWrap: {
    height: "100%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
  },
  item: {
    borderBottom: "none",
  },
  label: {
    fontWeight: 800,
  },
  control: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 16,
    fontWeight: "bold",

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  content: {
    backgroundColor: theme.colors.gray[0],
  },
  contentInner: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
    borderLeft: `1px solid ${theme.colors.gray[3]}`,
  },

  segmentedRoot: {
    backgroundColor: theme.colors.gray[7],
  },

  segmentedControlActive: {
    backgroundColor: theme.colors.sutrablue[3],
    color: "#fff",
  },

  segmentedControlWrap: {
    backgroundColor: theme.colors.gray[2],
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  segmentedLabel: {
    color: "#fff !important",
  },

  segmentedLabelActive: {
    color: `${theme.colors.gray[7]} !important`,
  },

  segmentedControlBlock: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SideBlocksMenu = (props) => {
  const { theme, classes, cx } = useStyles();
  const params = useParams();

  const sideMenuOptionsArray = sideMenuOptions();

  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const { t, i18n } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [openedPremium, setOpenedPremium] = useState(false);
  const [category, setCategory] = useState("blocks");
  const [selectedOption, setSelectedOption] = useState(null);
  const [blocks, setBlocks] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [infoPopoverOpened, setInfoPopoverOpened] = useState(null);
  const [loadingModalOpened, setLoadingModalOpened] = useState(null);

  const viewport = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    onSetBlocks();

    Broadcaster.receive("open_SideBlockMenu", document.body, (event, data) => {
      setOpened(true);
    });

    return () => {
      window.$contentTemplates = null;
      Broadcaster.unRegisterElement("open_SideBlockMenu", document.body);
    };
  }, []);

  useEffect(() => {
    if (opened) {
      onFetchLayouts();
      onFetchTemplates();
    }
  }, [opened])

  useEffect(() => {
    window.$contentLayouts = null;
    window.$contentTemplates = null;
  }, [params]);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "blocks") {
      setOpened(true);
      if (tiptapSidemenu.option) {
        // setSelectedOption(getOptionByName(tiptapSidemenu.option))
        if (
          tiptapSidemenu.option === "Layouts" ||
          tiptapSidemenu.option === "Templates"
        ) {
          setCategory(tiptapSidemenu.option.toLowerCase());
        } else {
          setCategory("blocks");
          if (tiptapSidemenu.option === "Grids") {
            setTimeout(() => {
              if (viewport && viewport.current) {
                viewport.current.scrollTo({
                  top: viewport.current.scrollHeight / 2,
                  behavior: "smooth",
                });
              }
            }, 200);
          }
        }
      } else {
        setCategory("blocks");
      }
    } else {
      onCloseDrawer();
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "blocks") {
        tiptap.editor.commands.focus();
        window.$closingSideMenu = true;
        dispatch(setTiptapSidemenu({ opened: null }));
        removeDummyCursor();
      }
    } else {
      addDummyCursor();
    }
  }, [opened]);

  const onOpenTemplates = (event) => {
    event.stopPropagation();
    dispatch(setTiptapSidemenu({ opened: "templatesModal" }));
  };

  const onSetBlocks = () => {
    if (blocks.length < 4) {
      const newOptions = [...sideMenuOptionsArray];
      if (window.location.pathname.indexOf("/registration") >= 0) {
        newOptions.splice(0, 1);
        newOptions.splice(3, 1);
      }
      if (!window.$currentUserIsAdmin) {
        // newOptions[6].blocks.splice(3, 1);
        // newOptions[2].blocks.splice(5, 1);
      }
      setBlocks([...newOptions]);
    }
  };

  const onFetchLayouts = () => {
    if (window.$contentLayouts) {
      setLayouts([...window.$contentLayouts]);
      return;
    }
    SpaceModel.onFetchLayouts(
      space.slug,
      (data) => {
        setLayouts([...data.categories]);
        window.$contentLayouts = [...data.categories];
      },
      (data) => {}
    );
  };

  const onFetchTemplates = () => {
    if (window.$contentTemplates) {
      setTemplates([...window.$contentTemplates]);
      return;
    }
    SpaceModel.onFetchTemplates(
      space.slug,
      (data) => {
        setTemplates([...data.categories]);
        window.$contentTemplates = [...data.categories];
      },
      (data) => {}
    );
  };

  const onOptionClicked = (event, option) => {
    setSelectedOption(option);
  };

  const onBlockClicked = (event, block, option = true) => {
    if (
      block.type == "content" ||
      block.type == "discussion" ||
      block.type == "event" ||
      block.type == "events" ||
      block.type == "list" ||
      block.type == "showcase"
    ) {
      setSelectedOption(false);
      dispatch(setTiptapSidemenu({ opened: "newSpace", option: option }));
    } else if (block.type == "resource") {
      setSelectedOption(false);
      dispatch(setTiptapSidemenu({ opened: "newResource", option: option }));
    } else if (block.type == "survey") {

      const validatePremium = [
        ...space.permissions.can_survey,
      ];
      if (space.slug && space.permissions && validatePremium[0]) {
        setSelectedOption(false);
        dispatch(setTiptapSidemenu({ opened: "createSurvey", option: option }));
      } else {
        setOpenedPremium([true, validatePremium[1], validatePremium[2]]);
        return;
      }
    } else if (block.type == "bundle_button") {
      setSelectedOption(false);
      dispatch(setTiptapSidemenu({ opened: "createBundle", option: option }));
    } else if (
      block.type == "image" ||
      block.type == "embed" ||
      block.type == "video" ||
      block.type == "audio" ||
      block.type == "file" ||
      block.type == "zoom"
    ) {
      setSelectedOption(false);
      dispatch(setTiptapSidemenu({ opened: "newMedia", option: option }));
    } else if (block.type == "layout") {
      setSelectedOption(false);
      onAddLayout(block.content);
    } else if (block.type == "template") {
      onAddTemplate(block);
    } else if (block.type == "interest_form") {
      const validatePremium = [
        ...space.permissions.can_use_interest_form_block,
      ];
      if (space.slug && space.permissions && validatePremium[0]) {
        setSelectedOption(false);
        block.callback(event, tiptap.editor, colors);
        window.$closingSideMenu = true;
      } else {
        setOpenedPremium([true, validatePremium[1], validatePremium[2]]);
        return;
      }
    } else if (block.type == "interactive_input") {
      if (space.slug && space.permissions) {
        dispatch(
          setTiptapSidemenu({
            opened: "loading",
            text: "Discussion prompt is loading, please wait…",
          })
        );

        const spaceName =
          space.name < 90
            ? `${space.name} prompt`
            : `${space.name.slice(0, 90)}... prompt`;

        SpaceModel.onCreate(
          { intention: spaceName, version: "interactive-discussion" },
          space.id,
          (data) => {
            dispatch(setTiptapSidemenu({ opened: null }));
            setSelectedOption(false);
            block.callback(event, tiptap.editor, data.slug, space.slug, colors);
            window.$closingSideMenu = true;
            if (!window.$spaceUserInteractions) {
              window.$spaceUserInteractions = {
                position: 9999,
                can_manage: true,
                count: 1,
              };
            }
            setTimeout(() => {
              onTranscriptsTrigger();
            }, 3000);
            onCloseDrawer();
          },
          (data) => {
            dispatch(setTiptapSidemenu({ opened: null }));
            if (data.permissions) {
              setOpenedPremium([
                true,
                data.permissions[1],
                data.permissions[2],
              ]);
            }
          }
        );
        return;
      } else {
        setOpenedPremium([true, validatePremium[1], validatePremium[2]]);
        return;
      }
    } else {
      if (
        block.type == "interactive_button" ||
        block.type == "interactive_wait"
      ) {
        const validatePremium = [
          ...space.permissions.can_use_interactive_block,
        ];
        if (space.slug && space.permissions && validatePremium[0]) {
          if (!window.$spaceUserInteractions) {
            window.$spaceUserInteractions = {
              position: 9999,
              can_manage: true,
              count: 1,
            };
          }
        } else {
          setOpenedPremium([true, validatePremium[1], validatePremium[2]]);
          return;
        }
      } else if (block.type == "mark_complete_button") {
        if (space.permissions.is_top_level) {
          Swal.fire(
            "",
            "The mark complete option cannot be added to a top level space",
            "info"
          );
          return;
        }
        SpaceSettingModel.onUpdate(
          space.slug,
          { pod_settings_attributes: { allow_completions: true } },
          (data) => {},
          (data) => {}
        );
      }
      setSelectedOption(false);
      block.callback(event, tiptap.editor, colors);
      window.$closingSideMenu = true;
    }
    onCloseDrawer();
  };

  const onTranscriptsTrigger = () => {
    const doc = tiptap.editor.view.state.doc;

    doc.nodesBetween(0, doc.content.size, (node, pos) => {
      if (node.type.name == "embed") {
        const mediaFile = {
          original_url: node.attrs.src,
          url: node.attrs.src,
          upload_type: "embed",
          slug: window.$currentSlug,
          embed_id: node.attrs.videoId,
          apify: true,
        };

        MediaFileModel.onCreate(
          mediaFile,
          (data) => {
            console.log("MEDIA FILE STORED");
          },
          (data) => {}
        );
      }
    });
  };

  const onAddLayout = (layout) => {
    if (!isTopNode(props.editor)) {
      const parentNode = getTopParentNode(props.editor);
      props.editor
        .chain()
        .setNodeSelection(parentNode.pos)
        .enter()
        .focus()
        .run();
    }

    props.editor.chain().addNewEmptyLine().insertContent(layout).run();
  };

  const onAddTemplate = (template) => {
    window.$waitForTemplateResponse = true;
    dispatch(
      setTiptapSidemenu({
        opened: "loading",
        text: "Your template is loading, please wait…",
      })
    );
    SpaceModel.onCopyTemplate(
      template.slug,
      space.slug,
      (data) => {
        // setTimeout(() => {
        //   dispatch(setTiptapSidemenu({ opened: null }));
        // }, 10000);
      },
      (data) => {
        dispatch(setTiptapSidemenu({ opened: null }));
        if (data.permissions) {
          setTimeout(() => {
            setOpenedPremium([true, data.permissions[1], data.permissions[2]]);
          }, 200);
        }
      }
    );
  };

  const getOptionByName = (optionName) => {
    for (var i = 0; i < blocks.length; i++) {
      const option = blocks[i];
      if (option.name == optionName) {
        return option;
      }
    }
    return sideMenuOptionsArray[0];
  };

  const onCloseDrawer = () => {
    setOpened(false);
    onOptionClicked(null, null);
  };

  const displayBlockCategory = () => {
    if (category === "blocks") {
      return (
        <Accordion
          offsetIcon={false}
          multiple
          iconPosition="right"
          mb={200}
          initialState={{
            0: true,
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
            9: true,
          }}
          classNames={{
            item: classes.item,
            label: classes.label,
            control: classes.control,
            content: classes.content,
            contentInner: classes.contentInner,
          }}
        >
          {blocks.map((option) => {
            return (
              <Accordion.Item
                label={option.name}
                key={`side-menu-option-${option.name}`}
              >
                <SimpleGrid cols={3} spacing={0}>
                  {option.blocks.map((block, index) => {
                    return (
                      <Tooltip
                        // placement={index % 3 == 0 ? "start" : index % 3 == 2 ? "end" : "center"}
                        position="bottom"
                        disabled={
                          window.$isTouchDevice && window.$isTouchDevice()
                        }
                        label={block.tooltip}
                        withArrow
                        width={110}
                        wrapLines
                        styles={{
                          body: { textAlign: "center" },
                        }}
                        zIndex={99999}
                      >
                        <div
                          className={classes.blockGridItem}
                          key={`side-menu-block-${block.name}`}
                          onClick={(event) =>
                            onBlockClicked(event, block, block.type)
                          }
                        >
                          <div className={classes.iconWrap}>
                            <Image width={50} src={block.image} />
                          </div>
                          <div className={classes.blockName}>{block.name}</div>
                        </div>
                      </Tooltip>
                    );
                  })}
                </SimpleGrid>
              </Accordion.Item>
            );
          })}
        </Accordion>
      );
    } else if (category === "layouts") {
      return (
        <>
          <Text size="sm" color="dimmed" pt={20} pr={20} pl={20} pb={10}>
            {t("tiptap.components.sideBlocksMenu.text")}
          </Text>
          <Accordion
            offsetIcon={false}
            multiple
            iconPosition="right"
            mb={200}
            // initialState={{
            //   0: true, 1: true, 2: true, 3: true, 4: true,
            //   5: true, 6: true, 7: true, 8: true, 9: true
            // }}
            classNames={{
              item: classes.item,
              label: classes.label,
              control: classes.control,
              content: classes.content,
              contentInner: classes.contentInner,
            }}
          >
            {layouts.map((option, optionIndex) => {
              return (
                <Accordion.Item
                  label={option.name}
                  key={`side-menu-option-${option.name}`}
                >
                  {option.blocks && (
                    <SimpleGrid cols={1} spacing={0}>
                      {option.blocks.map((block, blockIndex) => {
                        return (
                          <Popover
                            opened={
                              infoPopoverOpened ===
                              `${optionIndex}-${blockIndex}`
                            }
                            onClose={() => setInfoPopoverOpened(null)}
                            position="right"
                            placement="start"
                            trapFocus={false}
                            closeOnEscape={false}
                            transition="pop-top-left"
                            width={500}
                            zIndex={99999}
                            styles={{ body: { pointerEvents: "none" } }}
                            target={
                              <div
                                onMouseEnter={() =>
                                  setInfoPopoverOpened(
                                    `${optionIndex}-${blockIndex}`
                                  )
                                }
                                onMouseLeave={() => setInfoPopoverOpened(null)}
                                className={cx(
                                  classes.blockGridItem,
                                  classes.blockGridItemLayout
                                )}
                                key={`side-menu-block-${block.name}`}
                                onClick={(event) =>
                                  onBlockClicked(event, block, block.type)
                                }
                              >
                                <Image
                                  classNames={{
                                    imageWrapper: classes.imageWrapper,
                                  }}
                                  width={"80%"}
                                  src={block.image}
                                />
                              </div>
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <Image width={"100%"} src={block.image} />
                            </div>
                          </Popover>
                        );
                      })}
                    </SimpleGrid>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        </>
      );
    } else if (category === "templates") {
      return (
        <>
          <Text size="sm" color="dimmed" pt={20} pr={20} pl={20} pb={20}>
            {t("tiptap.components.sideBlocksMenu.text2")}
          </Text>
          {templates.map((option, optionIndex) => {
            return (
              <SimpleGrid cols={1} spacing={0}>
                <Popover
                  opened={infoPopoverOpened === `${optionIndex}`}
                  onClose={() => setInfoPopoverOpened(null)}
                  position="right"
                  placement="start"
                  trapFocus={false}
                  closeOnEscape={false}
                  transition="pop-top-left"
                  width={440}
                  zIndex={99999}
                  styles={{ body: { pointerEvents: "none" } }}
                  target={
                    <div
                      onMouseEnter={() =>
                        setInfoPopoverOpened(`${optionIndex}`)
                      }
                      onMouseLeave={() => setInfoPopoverOpened(null)}
                      className={cx(
                        classes.blockGridItem,
                        classes.blockGridItemTemplate
                      )}
                      key={`side-menu-block-${option.name}`}
                      onClick={(event) =>
                        onBlockClicked(event, option, option.type)
                      }
                    >
                      <Image
                        classNames={{
                          imageWrapper: classes.imageWrapper,
                        }}
                        sx={{
                          border: `1px solid ${theme.colors.gray[2]}`,
                          backgroundColor: "#fff",
                          padding: 2,
                        }}
                        ml={5}
                        mr={12}
                        width={80}
                        src={option.image}
                      />
                      <Text
                        // mt={5}
                        mb={5}
                        weight={600}
                      >
                        {option.name}
                      </Text>
                    </div>
                  }
                >
                  <div style={{ display: "flex" }}>
                    <Box sx={{ width: "100%" }}>
                      <Text
                        // mt={5}
                        mb={15}
                        ml={5}
                        mr={10}
                        sx={{ lineHeight: 1.2 }}
                      >
                        {option.description}
                      </Text>
                      <Center>
                        <Image
                          width={"400px"}
                          src={option.image}
                          sx={(theme) => ({
                            padding: 5,
                            border: `1px solid ${theme.colors.gray[3]}`,
                          })}
                        />
                      </Center>
                    </Box>
                  </div>
                </Popover>
              </SimpleGrid>
            );
          })}
        </>
      );
    }
  };

  return (
    <>
      <Drawer
        position="left"
        opened={opened}
        onClose={() => onCloseDrawer()}
        title={
          <Text size="xs">
            {t("tiptap.components.sideBlocksMenu.addBlock")}
          </Text>
        }
        padding={0}
        size={"300px"}
        overlayOpacity={0.1}
        zIndex={99999}
        styles={{
          header: {
            paddingTop: 20,
            paddingBottom: 0,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 0,
          },
        }}
      >
        <Box className={classes.segmentedControlWrap}>
          <Box
            sx={{
              width: "100%",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Button
              radius={"xl"}
              fullWidth
              size="lg"
              onClick={(event) => onOpenTemplates(event)}
            >
              Templates & visuals
            </Button>
          </Box>
        </Box>

        <ScrollArea style={{ height: "100%" }} viewportRef={viewport}>
          <Box
            sx={(theme) => ({
              marginLeft: 0,
              marginRight: 0,
            })}
          >
            {displayBlockCategory()}
          </Box>
          <Box mt={300} />
        </ScrollArea>
      </Drawer>
      <PremiumModal opened={openedPremium} setOpened={setOpenedPremium} />
      <TemplatesModal
        editor={props.editor}
        onAddTemplate={onAddTemplate}
        onAddLayout={onAddLayout}
      />
    </>
  );
};

export default SideBlocksMenu;
