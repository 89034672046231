import defaultSpaceColors from "../collections/defaultSpaceColors";

const isSpaceThemed = (colors) => {
  if (JSON.stringify(colors) == "{}"){
    return false
  }
  const is_themed = !(
    colors.header_color == defaultSpaceColors.header_color &&
    colors.header_link_color == defaultSpaceColors.header_link_color &&
    colors.primary_button_background_color ==
      defaultSpaceColors.primary_button_background_color &&
    colors.primary_button_text_color ==
      defaultSpaceColors.primary_button_text_color &&
    colors.secondary_button_background_color ==
      defaultSpaceColors.secondary_button_background_color &&
    colors.secondary_button_text_color ==
      defaultSpaceColors.secondary_button_text_color &&
    colors.background_color == defaultSpaceColors.background_color &&
    colors.default_text_color == defaultSpaceColors.default_text_color &&
    colors.sidebar_background_color ==
      defaultSpaceColors.sidebar_background_color &&
    colors.sidebar_text_color == defaultSpaceColors.sidebar_text_color
  );

  return is_themed;
};

// colors.secondary_background_color == defaultSpaceColors.secondary_background_color &&
// colors.sub_header_text_color == defaultSpaceColors.sub_header_text_color &&

export default isSpaceThemed;
