import { mergeAttributes, Node, nodeInputRule } from "@tiptap/core";
import { TextSelection } from "prosemirror-state";
import { checkInteractionAndHideIfNeeded, setUniqId} from "../utils/selection";
import { wideOrNarrow } from "../../../utils/PageWidth";

const HorizontalRule = Node.create({
  name: "horizontalRule",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: "block",

  parseHTML() {
    return [{ tag: "hr" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["hr", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setHorizontalRule:
        () =>
        ({ chain }) => {
          return (
            chain()
              .insertContent({ type: this.name })
              // set cursor after horizontal rule
              .command(({ tr, dispatch }) => {
                if (dispatch) {
                  const { $to } = tr.selection;
                  const posAfter = $to.end();

                  if ($to.nodeAfter) {
                    tr.setSelection(TextSelection.create(tr.doc, $to.pos));
                  } else {
                    // add node after horizontal rule if it’s the end of the document
                    const node =
                      $to.parent.type.contentMatch.defaultType?.create();

                    if (node) {
                      tr.insert(posAfter, node);
                      tr.setSelection(TextSelection.create(tr.doc, posAfter));
                    }
                  }

                  tr.scrollIntoView();
                }

                return true;
              })
              .run()
          );
        },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: /^(?:---|—-|___\s|\*\*\*\s)$/,
        type: this.type,
      }),
    ];
  },

  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("section");
      const content = document.createElement("hr");
      content.style.maxWidth = wideOrNarrow("px", 0, 32);

      const attributes = mergeAttributes(HTMLAttributes, {});
      content.setAttribute("id", attributes["data-uid"]);
      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );
      dom.append(content);

      checkInteractionAndHideIfNeeded(editor, dom, getPos);
      //setUniqId(editor, node, getPos, dom);

      return {
        dom,
        contentDOM: content,
      };
    };
  },
});

export default HorizontalRule;
