import React, { useEffect } from "react";
import { Group, Box, MantineProvider, Button, TextInput } from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "@mantine/form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import WebhookModel from "../../../../../models/Webhook";

const paramsOptions = [
  { label: "First name", value: "first_name" },
  { label: "Last name", value: "last_name" },
  { label: "Username", value: "username" },
  { label: "Email", value: "email" },
  { label: "Event date", value: "created_at" },
];

export default function Webhook(props) {
  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props.webhookId && space && space.slug) {
      onFetchById();
    }
    if (space && space.slug) {
      form.setFieldValue("name", `Webhook ${space.slug}`);
    }
  }, [props]);

  const form = useForm({
    initialValues: {
      name: "",
      url: "",
      params: ["first_name", "last_name", "email", "username", "created_at"],
    },

    validate: {
      name: (value) =>
        value.length > 0 ? null : "Please write a name for this webhook",
      url: (value) => (value.length > 0 ? null : "Please add a webhook url"),
      params: (value) =>
        value.length > 0
          ? null
          : "Please select at least one parameter for this webhook",
    },
  });

  const onFetchById = () => {
    WebhookModel.onFetchById(
      props.webhookId,
      space.slug,
      (data) => {
        form.setFieldValue("name", data.name);
        form.setFieldValue("url", data.url);
        form.setFieldValue("params", data.params);
      },
      (data) => {}
    );
  };

  const onSave = () => {
    if (props.webhookId) {
      WebhookModel.onUpdate(
        props.webhookId,
        space.slug,
        { ...form.values },
        (data) => {
          // form.setFieldValue("name", "");
          // form.setFieldValue("url", "");
          // form.setFieldValue("params", ["first_name", "last_name", "email", "created_at"]);
          props.onUpdate({ object_type: "webhook" });
        },
        (data) => {}
      );
    } else {
      WebhookModel.onCreate(
        space.slug,
        { ...form.values },
        (data) => {
          // form.setFieldValue("name", "");
          // form.setFieldValue("url", "");
          // form.setFieldValue("params", ["first_name", "last_name", "email", "created_at"]);
          props.onCreate({ object_type: "webhook", object_id: data.id });
        },
        (data) => {}
      );
    }
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSave(values))}>
        <MantineProvider theme={{ primaryShade: 6 }}>
          {/* <TextInput
            mt={20}
            placeholder="My awesome webhook"
            label="Webhook name"
            radius="sm"
            size="md"
            {...form.getInputProps("name")}
          /> */}
          <TextInput
            mt={20}
            placeholder="https://example.com/hooks/12345"
            label={t("spaces.settings.registration.steps.webhook.label")}
            radius="sm"
            size="md"
            {...form.getInputProps("url")}
          />
          {/* <MultiSelect
            multiple
            label="Select webhook params"
            placeholder="Select an option"
            maxDropdownHeight={400}
            radius="sm"
            size="md"
            mt={20}
            data={paramsOptions}
            {...form.getInputProps("params")}
          /> */}
        </MantineProvider>
        <Group position="apart">
          <Button
            type="submit"
            leftIcon={<FontAwesomeIcon icon={solid("check")} />}
            mt={25}
            radius="xl"
            fullWidth={!props.webhookId}
          >
            {t("spaces.settings.registration.steps.webhook.saveButton")}
          </Button>
          {props.webhookId && (
            <Button
              onClick={(event) => props.onDestroy(event)}
              mt={25}
              radius="xl"
              // size="md"
              color="dark"
              leftIcon={<FontAwesomeIcon icon={regular("trash-can")} />}
            >
              {t("spaces.settings.registration.steps.webhook.deleteButton")}
            </Button>
          )}
        </Group>
      </form>
    </Box>
  );
}
