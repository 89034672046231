import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setActions } from "../../../store/app";
import ViewModel from "../../../models/View";

import { useNavigate } from "react-router-dom";

import { Text, Button, Group, Container, Box } from "@mantine/core";
import tinycolor from "tinycolor2";


import trackMixpanel from "../../../utils/TrackMixpanel";

import TiptapNoHP from "./TiptapNoHP";

const EmptyMessage = (props) => {
  const { t, i18n } = useTranslation();
  const colors = useSelector((state) => state.colors);

  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);

  const mainActions = useSelector((state) => state.mainActions);

  const tiptap = useSelector((state) => state.tiptap);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const intervalId = useRef();
  const [content, setContent] = useState();

  useEffect(() => {
    onFetch();
  }, [space]);

  const onEditPage = () => {
    dispatch(setActions({ editPage: true }));
  };

  const onUseTemplate = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    dispatch(setActions({ editPage: true }));
    window.$openTemplatesOnEditMode = true;
  };

  const onTryDemo = () => {
    window.open(`https://complexity.sutra.co`, "_blank");
  }

  const onLearnMore = () => {
    if (space.pod_state == "model") {
      return;
    }
    trackMixpanel(user.username, "Tutorial Opened");
    navigate(`/space/${space.slug}/getting_started`);
  }

  const onFetch = () => {
    ViewModel.onFetch(
      "welcome_message",
      space.slug,
      (data) => {
        setContent({ ...data.content });
      },
      (data) => {
        navigate(`/home/top`);
      }
    );
  };

  return (
    <>
      {space.permissions &&
        space.permissions.can_edit &&
        (props.useSpace2 ? (
          ""
        ) : (
          <>
            {space &&
              space.parents.length == 0 &&
              content &&
              content.default && (
                <Box sx={{ paddingTop: "30px" }}>
                  <TiptapNoHP
                    key={`tiptap-${new Date()}`}
                    content={content.default}
                    allowComments={false}
                    spaceWidth={space.space_width}
                    viewId={"tiptap-welcome"}
                    noPaddingBottom={true}
                  />
                </Box>
              )}
            {space && space.parents.length == 0 ? (
              <Container size={space.space_width == "wide" ? 960 : 801}>
                <Group
                  mt={10}
                  spacing={7}
                >
                  <Button
                    radius="xl"
                    variant="outline"
                    compact
                    styles={{
                      outline: {
                        border: `1px solid ${tinycolor(
                          colors.default_text_color
                        )
                          .lighten(55)
                          .toString()}`,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      },
                      label: {
                        color: tinycolor(colors.default_text_color)
                          .lighten(55)
                          .toString(),
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      },
                    }}
                    onClick={() => onEditPage()}
                  >
                    {"Start from scratch"}
                  </Button>
                  <Button
                    radius="xl"
                    variant="outline"
                    compact
                    styles={{
                      outline: {
                        border: `1px solid ${tinycolor(
                          colors.default_text_color
                        )
                          .lighten(55)
                          .toString()}`,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      },
                      label: {
                        color: tinycolor(colors.default_text_color)
                          .lighten(55)
                          .toString(),
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      },
                    }}
                    onClick={() => onUseTemplate()}
                  >
                    {"Use a template"}
                  </Button>
                  <Button
                    radius="xl"
                    variant="outline"
                    compact
                    styles={{
                      outline: {
                        border: `1px solid ${tinycolor(
                          colors.default_text_color
                        )
                          .lighten(55)
                          .toString()}`,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      },
                      label: {
                        color: tinycolor(colors.default_text_color)
                          .lighten(55)
                          .toString(),
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      },
                    }}
                    onClick={() => onTryDemo()}
                  >
                    {"Try a demo course"}
                  </Button>
                  <Button
                    radius="xl"
                    variant="outline"
                    compact
                    styles={{
                      outline: {
                        border: `1px solid ${tinycolor(
                          colors.default_text_color
                        )
                          .lighten(55)
                          .toString()}`,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      },
                      label: {
                        color: tinycolor(colors.default_text_color)
                          .lighten(55)
                          .toString(),
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      },
                    }}
                    onClick={() => onLearnMore()}
                  >
                    {"Learn more"}
                  </Button>
                  
                </Group>
              </Container>
            ) : (
              <Container size={space.space_width == "wide" ? 960 : 801}>
                <Group
                  mt={10}
                  spacing={7}
                  sx={
                    {
                      // maxWidth: 360,
                      // textAlign: "center",
                      // justifyContent: "center",
                      // border: `1px solid #ccc`,
                      // borderRadius: 6,
                      // padding: 30
                    }
                  }
                >
                  <Text
                    sx={(theme) => ({
                      color: tinycolor(colors.default_text_color)
                        .lighten(55)
                        .toString(),
                      // fontWeight: 500
                    })}
                  >
                    {t("tiptap.components.emptyMessage.text")}
                  </Text>
                  <Button
                    radius="xl"
                    variant="outline"
                    compact
                    styles={{
                      outline: {
                        border: `1px solid ${tinycolor(
                          colors.default_text_color
                        )
                          .lighten(55)
                          .toString()}`,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      },
                      label: {
                        color: tinycolor(colors.default_text_color)
                          .lighten(55)
                          .toString(),
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      },
                    }}
                    onClick={() => onEditPage()}
                  >
                    {t("tiptap.components.emptyMessage.button1")}
                  </Button>
                  <Text
                    sx={(theme) => ({
                      color: tinycolor(colors.default_text_color)
                        .lighten(55)
                        .toString(),
                      // fontWeight: 500
                    })}
                  >
                    {t("tiptap.components.emptyMessage.text2")}
                  </Text>
                  <Button
                    radius="xl"
                    variant="outline"
                    compact
                    styles={{
                      outline: {
                        border: `1px solid ${tinycolor(
                          colors.default_text_color
                        )
                          .lighten(55)
                          .toString()}`,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      },
                      label: {
                        color: tinycolor(colors.default_text_color)
                          .lighten(55)
                          .toString(),
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      },
                    }}
                    onClick={() => onUseTemplate()}
                  >
                    {t("tiptap.components.emptyMessage.button2")}
                  </Button>
                  <Text
                    sx={(theme) => ({
                      color: tinycolor(colors.default_text_color)
                        .lighten(65)
                        .toString(),
                      // fontWeight: 500
                    })}
                  >
                    {t("tiptap.components.emptyMessage.text3")}
                  </Text>
                </Group>
              </Container>
            )}
          </>
        ))}
    </>
  );
};

export default EmptyMessage;
