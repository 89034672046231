import {getDomain} from "./Host"

export function setCookie(cname, cvalue, exdays, secure="", http_only="") {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain="+getDomain();
  document.cookie = `${cname}=${cvalue};${expires};path=/${secure};domain=${getDomain()}`
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// SCRIPT HOME PAGE
/*
function _checkSession(){
  let userString = _getCookie("auth_user");
  const user = JSON.parse(userString ? atob(userString) : "{}");
  if (user && user.id){
    location.href = "/home/top"
  }
}

function _getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

_checkSession()
*/
