import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import AuthModel from "../../models/Auth";
import useAuthUser from "../../utils/AuthUser";
import { setUser, setAlert, setHiddenSidebar } from "../../store/app";
import { useTranslation } from "react-i18next";

import {
  PasswordInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Box,
  Divider,
  ScrollArea,
} from "@mantine/core";

import { Helmet } from "react-helmet";

export default function ResetPassword(props) {
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { setAuthUser } = useAuthUser();

  const { t, i18n } = useTranslation();

  const [form, setForm] = useState({
    user: {
      password: "",
      password_confirmation: "",
    },
  });

  const [dialog, setDialog] = useState({
    opened: false,
    title: "",
    message: "",
    color: "indigo",
  });

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 250);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  const onResetPassword = () => {
    AuthModel.onResetPassword(
      params.reset_password_token,
      form.user.password,
      form.user.password_confirmation,
      (data) => {
        // Success callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );

        navigate("/user/sign_in");

        setAuthUser(data.user);
        dispatch(setUser(data.user));
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onInputChange = (event, field) => {
    form.user[field] = event.target.value;
    setForm({ ...form });
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{`Sutra - Reset password`}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }} size={500} my={40}>
        <Paper withBorder shadow="md" p={0} mt={30} radius="md">
          <Box pt={20} pb={5} pl={35} pr={35}>
            <Title sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}>
              {t("users.resetPassword.title")}
            </Title>
            <Text color="dimmed" size="md">
              {t("users.resetPassword.subtitle")}
            </Text>
          </Box>
          <Divider
            sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
            my="sm"
          />
          <Box pt={10} pb={25} pl={35} pr={35}>
            <PasswordInput
              value={form.user.password}
              onChange={(event) => onInputChange(event, "password")}
              label={t("users.resetPassword.password")}
              radius="sm"
              mb={15}
            />
            <PasswordInput
              value={form.user.password_confirmation}
              onChange={(event) =>
                onInputChange(event, "password_confirmation")
              }
              label={t("users.resetPassword.passwordConfirmation")}
              radius="sm"
              mb={15}
            />
            <Button
              fullWidth
              mt="xl"
              radius="xl"
              size="lg"
              mb={15}
              onClick={(event) => onResetPassword()}
            >
              {t("users.resetPassword.submit")}
            </Button>
          </Box>
        </Paper>
        <Box sx={{height: "100px"}}></Box>
      </Container>
    </ScrollArea>
  );
}
