import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Box,
  Text,
  TextInput,
  NumberInput,
  Group,
  NativeSelect,
  createStyles,
  Button,
  Select,
} from "@mantine/core";

import { DatePicker, TimeInput } from "@mantine/dates";

import { sutraTheme } from "../../../../collections/sutraTheme";

import CouponModel from "../../../../models/Coupon";

import { useForm } from "@mantine/form";

import Swal from "sweetalert2";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    width: "100%",
  },
}));

export default function CouponsForm(props) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();

  const [disableActions, setDisableActions] = useState("");

  const [expireDate, setExpireDate] = useState("");
  const [expireTime, setExpireTime] = useState("");

  const form = useForm({
    initialValues: {
      code: "",
      value: "",
      discount_type: "percent",
      frequency: "once",
    },

    validate: {
      code: (value) => (value.length < 3 || value.length > 30 ? "Add a code (between 3 and 30 percent)" : null),
      value: (value) => (value <= 0 || (value > 100 && form.values.discount_type == "percent")  ? `Add a value ${form.values.discount_type == "percent" ? "(between 1 and 100 percent)" : ""}` : null),
    },
  });

  const space = useSelector((state) => state.space);

  const onSave = () => {
    
    const params = {
      ...form.values,
      expire_at: mergeDateAndTime(expireDate, expireTime),
    };
    
    if (!props.coupon.id) {
      CouponModel.onCreate(
        space.slug,
        params,
        (data) => {
          props.onUpdate();
          props.setOpened(false);
        },
        (data) => {
          Swal.fire("Something went wrong", data.errors, "error");
        }
      );
    } else {
      CouponModel.onUpdate(
        space.slug,
        props.coupon.id,
        params,
        (data) => {
          props.onUpdate();
          props.setOpened(false);
        },
        (data) => {
          Swal.fire("Something went wrong", data.errors, "error");
        }
      );
    }
  };

  const mergeDateAndTime = (date, time) => {
    if (date && time) {
      const _date = new Date(date);
      const _time = new Date(time);

      const merged = new Date(
        `${_date.toLocaleDateStringAbs()}, ${_time.toLocaleTimeString()}`
      ).toISOString();

      return `${merged}`;
    } else if (date) {
      return `${date.toISOString()}`;
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => onSave())}>
      <Box>
        <Box className={classes.item}>
          <TextInput
            label="Coupon code"
            placeholder={"SHARETHELOVE"}
            mt={10}
            className={classes.input}
            disabled={disableActions}
            {...form.getInputProps("code")}
          />
        </Box>
        <Box className={classes.item}>
          <NativeSelect
            label="Discount type"
            data={[
              { label: "Percent discount", value: "percent" },
              { label: "Amount discount", value: "amount" },
            ]}
            mt={10}
            className={classes.input}
            disabled={disableActions}
            {...form.getInputProps("discount_type")}
          />
        </Box>
        <Box className={classes.item}>
          <NumberInput
            label="Value"
            mt={10}
            className={classes.input}
            disabled={disableActions}
            {...form.getInputProps("value")}
          />
        </Box>
        <Box className={classes.item}>
          <NativeSelect
            label="Duration"
            data={[
              { value: "once", label: "Once" },
              { value: "forever", label: "Forever" },
            ]}
            description="For recurring payments, this determines if the coupon is only applied for the first payment or for subsequent payments as well."
            mt={10}
            className={classes.input}
            disabled={disableActions}
            {...form.getInputProps("frequency")}
          />
        </Box>
        <Box className={classes.item}>
          <Group mt={10}>
            <DatePicker
              label="Expiration date"
              disabled={disableActions}
              value={expireDate}
              placeholder={t(
                "spaces.settings.main.scheduling.launchDateTime.placeholder"
              )}
              onChange={(date) => setExpireDate(date)}
            />
            <TimeInput
              label="Expiration time"
              disabled={disableActions}
              value={expireTime}
              format="12"
              onChange={(time) => setExpireTime(time)}
            />
          </Group>
        </Box>
        <Box>
          <Button
            variant="filled"
            radius="xl"
            type="submit"
            sx={{
              backgroundColor: sutraTheme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: sutraTheme.colors.sutrablue[4],
              },
            }}
          >
            {"Create coupon"}
          </Button>
        </Box>
      </Box>
    </form>
  );
}
