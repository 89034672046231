import React, {useState, useEffect, useRef} from 'react';
import TiptapNoHP from '../../../tiptap/components/TiptapNoHP';

import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import FixedHeader from '../../FixedHeader'

import BackupModel from '../../../../models/Backup'


import { 
  Stack,
  ScrollArea,
  createStyles
}
from '@mantine/core';

const useStyles = createStyles((theme, {colors}, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color
    },
    ".ProseMirror .file-node a": {
      color: colors.default_text_color
    }
  }
}));

export default function Content(props) {


  const colors = useSelector(state => state.colors);

  const params = useParams();

  const { classes, cx, theme } = useStyles({colors});

  const viewport = useRef();

  const [loaded, setLoaded] = useState(false)
  const [content, setContent] = useState(null)

  useEffect(() => {
    return (() => {
      setLoaded(false)
    })
  }, [])

  useEffect(() => {

    if (props.hideLayout){
      props.hideLayout()
      setTimeout(() => {
        props.hideLayout()
      }, 500)
    }

    if (props.space && props.space.slug == params.slug && !content){
      BackupModel.onFetchById(params.id, props.space.slug, props.type, (data) => {
        setContent(data.content)
        setLoaded(true)
      }, (data) => {

      })
    }

  }, [props])

  useEffect(() => {
    
    window.$contentScrollViewport = viewport.current

  }, [viewport.current])

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{height: "100%"}}>
      <ScrollArea
        className="content-scrollview"
        style={{ height: "100%"}}
        viewportRef={viewport}>
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={["backup_preview"]}/>
        {loaded && content && (
          <TiptapNoHP
            key={`tiptap-${(new Date())}`}
            content={content.default}
            />
        )}
      </ScrollArea>

    </Stack>
  )
}
