import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import {
  createStyles,
  Group,
  Text,
  Card,
  SimpleGrid,
  Button,
  Box,
  Switch,
  Tooltip,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import PlanModel from "../../../models/Plan";

const useStyles = createStyles((theme) => ({
  plan: {
    border: `1px solid ${theme.colors.gray[1]}`,
    backgroundColor: theme.colors.gray[0],
  },

  planTitle: {
    fontSize: 30,
    fontWeight: 800,
  },

  planDescription: {
    minHeight: 110,
  },

  planPriceWrap: {
    alignItems: "flex-start",
  },

  planCurrency: {
    fontSize: 20,
    marginTop: 5,
    marginRight: 3,
  },

  planPrice: {
    fontSize: 30,
    fontWeight: 800,
    marginRight: 7,
  },

  planFrequency: {
    fontSize: 16,
    marginTop: 7,
    lineHeight: 1.2,
  },

  featureIntro: {
    fontWeight: "600",
  },

  featureItem: {
    alignItems: "baseline",
    marginTop: 10,
  },
  tableData: {
    textAlign: "center !important",
  },
}));

export default function PlanOptions() {
  const { t } = useTranslation();

  const { classes, theme } = useStyles();
  const [frequency, setFrequency] = useState("monthly");
  const [plans, setPlans] = useState([]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    onFetch();
  }, []);

  const onFetch = () => {
    PlanModel.onFetch(
      (data) => {
        setPlans(data.data);
      },
      (data) => {}
    );
  };

  const setFrequencyDescription = () => {
    return frequency === "monthly"
      ? t("app.payment.planOption.frequencyDescription1")
      : t("app.payment.planOption.frequencyDescription2");
  };

  const features = (plan) => {
    return plan.features.map((feature) => (
      <Group noWrap className={classes.featureItem} spacing={10}>
        <Box mt={5}>
          <FontAwesomeIcon size="md" icon={regular("circle-check")} />
        </Box>
        <Text>
          {feature.description}{" "}
          {feature.explanation !== "" && (
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              label={feature.explanation}
              withArrow
              wrapLines
              width={220}
            >
              <FontAwesomeIcon size="sm" icon={solid("circle-info")} />
            </Tooltip>
          )}
        </Text>
      </Group>
    ));
  };

  const featuresInfo = (plan) => {
    let featureList = [];

    if (plan.title == "Bronze") {
      return features(plan);
    }

    if (plan.title == "Silver") {
      const personalPlan = plans.filter((plan) => plan.title === "Bronze");
      const personalPlanFeatures = [...personalPlan[0].features];
      personalPlanFeatures.pop();
      const _plan = {
        ...plan,
        features: personalPlanFeatures.concat(plan.features),
      };
      return features(_plan);
    }

    if (plan.title == "Gold") {
      const personalPlan = plans.filter((plan) => plan.title === "Bronze");
      const personalPlanFeatures = [...personalPlan[0].features];
      personalPlanFeatures.pop();
      const professionalPlan = plans.filter(
        (plan) => plan.title === "Silver"
      );
      const professionalPlanFeatures = [...professionalPlan[0].features];
      professionalPlanFeatures.pop();
      const _plan = {
        ...plan,
        features: personalPlanFeatures
          .concat(professionalPlanFeatures)
          .concat(plan.features),
      };
      return features(_plan);
    }
  };

  const getPlanLink = (plan) => {
    if (!user.authenticated) {
      return `/user/start?p=${plan.ids[frequency]}`;
    }

    return plan.user.is_current_plan[getCurrentPlanIndexCheck()]
      ? `/settings/billing`
      : `/app/pay/${plan.ids[frequency]}`;
  };

  const getCurrentPlanIndexCheck = () => {
    return frequency === "annually" ? 1 : 0
  }

  const plansElement = plans.map((plan) => (
    <Card
      key={plan.title}
      shadow="md"
      radius="md"
      className={classes.plan}
      p="xl"
    >
      <Text className={classes.planTitle}>{plan.title}</Text>
      <Group className={classes.planPriceWrap} spacing={0} noWrap>
        <Text className={classes.planCurrency}>$</Text>
        <Text className={classes.planPrice}>
          {frequency === "annually" ? plan.priceAnnually : plan.priceMonthly}
        </Text>
        <Text className={classes.planFrequency}>
          {setFrequencyDescription()}
        </Text>
      </Group>
      <Text mt={20} className={classes.planDescription}>
        {plan.description}
      </Text>
      <Button
        mt={20}
        fullWidth
        radius="xl"
        color={plan.user.is_current_plan[getCurrentPlanIndexCheck()] ? "gray" : null}
        component={Link}
        to={getPlanLink(plan)}
      >
        {plan.user.is_current_plan[getCurrentPlanIndexCheck()]
          ? t("app.payment.planOption.currentPlan")
          : plan.user.action}
      </Button>
      <Text mt={20} className={classes.featureIntro}>
        {plan.featureIntro}
      </Text>
      <Box>{featuresInfo(plan)}</Box>
    </Card>
  ));

  return (
    <>
      <Group mt={30} sx={{ display: "flex", justifyContent: "center" }}>
        <Text size="lg">{t("app.payment.planOption.billedMonthly")}</Text>
        <Switch
          size="xl"
          styles={{
            input: {
              backgroundColor: theme.colors.sutrablue[3],
            },
          }}
          checked={frequency !== "monthly"}
          onChange={(event) =>
            setFrequency(event.target.checked ? "annually" : "monthly")
          }
        />
        <Text size="lg">{t("app.payment.planOption.billedAnnually")}</Text>
      </Group>

      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={30}
        mb={100}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        {plansElement}
      </SimpleGrid>
    </>
  );
}
