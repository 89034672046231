import React, { useState, useEffect } from "react";

import { Text, Group, Menu } from "@mantine/core";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function InterestedRow(props) {
  const [interest, setInterest] = useState({});
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (!interest.id || interest.id != props.interest.id) {
      setInterest(props.interest);
    }
  }, [props]);

  return (
    <tr key={interest.full_name}>
      <td>
        <Text size="sm" weight={500}>
          {interest.first_name}
        </Text>
      </td>

      <td>
        <Text size="sm" weight={500}>
          {interest.email}
        </Text>
      </td>

      <td>{new Date(interest.created_at).toLocaleString(DateTime.DATE_MED)}</td>

      <td>
        <Group spacing="sm" position="right">
          <Menu transition="pop" withArrow placement="end">
            <Menu.Item
              onClick={() => props.onDestroy(interest)}
              icon={<FontAwesomeIcon icon={regular("trash-can")} />}
              color="red"
            >
              {t(
                "spaces.settings.members.interested.interestedrow.removeFromList"
              )}
            </Menu.Item>
          </Menu>
        </Group>
      </td>
    </tr>
  );
}
