import React, { useState, useEffect, useRef } from 'react';

import {  useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";


import {
  Chips,
  Chip,

  createStyles
} from '@mantine/core';


const useStyles = createStyles((theme, _params, getRef) => ({
  chipChecked: {
    backgroundColor: `${theme.colors.dark[3]} !important`,
    color: theme.white,
    [`& .${getRef('iconWrapper')}`]: {
      color: theme.white,
    },
  },
  chipOutline3: {
    width: '80px',
    border: `1px solid ${theme.colors.dark[3]}`
  },
  chipOutline4: {
    border: `1px solid ${theme.colors.dark[3]}`
  },
  chipLabel: {
    textAlign: 'center',
    fontSize: 9,
    fontWeight: 800,
    color: theme.colors.dark[3],
    height: 26
  }
}
));

export default function Alignment(props) {

  const timeoutId = useRef(null)

  const loading = useRef(false)
  const { t, i18n } = useTranslation();
  const { classes, cx, theme } = useStyles();

  const tiptap = useSelector(state => state.tiptap);
  const [textAlign, setTextAlign] = useState('left');

  useEffect(() => {

    if (!props.element || !props.element.node.attrs || (props.element && props.element.node.attrs && loading.current != props.element.node.attrs.uid)){
      return
    }


    const node = props.element
    const newTextAlign = node.node.attrs.textAlign ? {...node.node.attrs.textAlign} : null

    if (newTextAlign) {

      if (node.node.type.name.indexOf("grid") < 0 && node.node.type.name != "column"){
        tiptap.editor.commands.setTextAlign(textAlign)
      }
      else{
        const {tr} = tiptap.editor.view.state
        tr.setNodeMarkup(node.pos, undefined, {
          ...node.node.attrs,
          textAlign: textAlign,
        })
        tiptap.editor.view.dispatch(tr)
      }

      if (timeoutId.current){
        clearTimeout(timeoutId.current)
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null
        props.saveNodeData()
      }, 2000)

    }
  }, [textAlign])

  useEffect(() => {
    if (props.element && props.element.node.attrs){
      loading.current = props.element.node.attrs.uid
      setTextAlign(props.element.node.attrs.textAlign ? props.element.node.attrs.textAlign : null)
    }
  }, [props.element])

  return (

    <Chips
      mt={10}
      sx={{ width: '100%' }}
      color='dark'
      radius="sm"
      size="sm"
      multiple={false}
      value={textAlign}
      onChange={setTextAlign}
      classNames={{
        checked: classes.chipChecked,
        iconWrapper: classes.iconWrapper,
        outline: classes.chipOutline3,
        label: classes.chipLabel
      }}
      >
      <Chip value="left">{t("tiptap.components.sidebar.alignment.left")}</Chip>
      <Chip value="center">{t("tiptap.components.sidebar.alignment.center")}</Chip>
      <Chip value="right">{t("tiptap.components.sidebar.alignment.right")}</Chip>
    </Chips>

  )

}
