import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import InteractiveWait from '../components/InteractiveWait';
import {stylesToString} from '../../../utils/StylesToString'

import {v4 as uuidv4} from 'uuid';

const InteractiveWaitNode = Node.create({
  name: 'interactiveWait',
  draggable: false,
  allowGapCursor: true,
  selectable: true,
  allowGapCursor: false,
  group: "block",
  inline: false,

  addAttributes() {
    return {
      interactiveWaitSendNotificationEmail: {
        default: false,
      },
      interactiveWaitNotificationIncludeCustomMessage: {
        default: false,
      },
      interactiveWaitNotificationCustomMessage: {
        default: null,
      },
      interactiveWaitNotificationCustomSubject: {
        default: null,
      },
      interactiveWaitNotificationIncludeContent: {
        default: false,
      },
      interactiveWaitNotificationIncludeContentValue: {
        default: "full",
      },
      interactiveWaitStatus: {
        default: 'pending'
      },
      interactiveWaitMessage: {
        default: "Please wait...",
      },
      interactiveWaitShowTimeLeft: {
        default: true,
      },
      interactiveWaitType: {
        default: "datetime",
      },
      interactiveWaitValue: {
        default: "1d",
      },
      idName: {
        default: "interactive-input-node",
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "10px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: attributes => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        })
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'interactivewait',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {

    return ['interactivewait', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addNodeView() {
    return ReactNodeViewRenderer(InteractiveWait)

  },
})

export default InteractiveWaitNode;
