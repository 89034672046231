import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Progress,
  Text,
  Image,
  MantineProvider,
  createStyles,
  TextInput,
  Textarea,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Uploady, {
  useItemProgressListener,
  useItemFinishListener,
} from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";

import SpaceModel from "../../../../models/Space";

import PremiumModal from "../../../app/PremiumModal";

import { setSpace, setListSpaceToUpdate } from "../../../../store/app";

import axios from "axios";

import tinycolor from "tinycolor2";

const LogProgress = (props) => {
  const { t, i18n } = useTranslation();
  const [progress, setProgress] = useState(0);

  useItemProgressListener((item) => {
    setProgress(item.completed);
  });
  useItemFinishListener((item) => {
    props.onUploadCallback(item.uploadResponse.data.url);
  });

  return (
    <>
      {progress > 0 && progress <= 100 ? (
        <Progress color="green" size="xl" mb={10} value={progress} />
      ) : (
        ""
      )}
    </>
  );
};

const CustomUploadHeaderButton = asUploadButton((props) => {
  const { t, i18n } = useTranslation();

  return (
    <Button
      variant="filled"
      radius="xl"
      leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
      {...props}
    >
      {window.$uploadMethodSidebutton == "header"
        ? t("tiptap.components.sidebar.spaceAttrs.addCover")
        : "Add thumbnail"}
    </Button>
  );
});

const useStyles = createStyles((theme, { colors }) => ({
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  appearanceButtonRoot: {
    color: theme.colors.gray[7],
    fontWeight: 400,
    fontSize: 16,
    borderRadius: 5,
    display: "block",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
    paddingLeft: 10,
  },
  appearanceButtonLeftIcon: {
    color: theme.colors.gray[7],
    width: 20,
    display: "flex",
    justifyContent: "center",
  },
  appearanceButtonInner: {
    justifyContent: "flex-start",
  },
}));

export default function SpaceAttrs(props) {
  const timeoutId = useRef(null);

  const tiptap = useSelector((state) => state.tiptap);
  const colors = useSelector((state) => state.colors);
  const { classes, cx } = useStyles({ colors });

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [space, setSpace] = useState();
  const [spaceName, setSpaceName] = useState("");
  const [spaceAbstract, setSpaceAbstract] = useState("");

  const fetchTimeoutId = useRef();

  const [loading, setLoading] = useState(false);

  const [canUploadCover, setCanUploadCover] = useState([]);

  const [opened, setOpened] = useState([]);
  const [uploadMethod, setUploadMethod] = useState("header");

  useEffect(() => {
    if (props.element && props.element.node.attrs.id) {
      setUploadMethod(
        props.element.node.attrs.viewAs.indexOf("card") >= 0
          ? "gallery_feature"
          : "header"
      );
      window.$uploadMethodSidebutton =
        props.element.node.attrs.viewAs.indexOf("card") >= 0
          ? "gallery_feature"
          : "header";

      setLoading(true);
      if (fetchTimeoutId.current) {
        clearTimeout(fetchTimeoutId.current);
      }
      fetchTimeoutId.current = setTimeout(() => {
        onFetch();
      }, 500);
    }
  }, [props]);

  const onFetch = () => {
    SpaceModel.onFetch(
      props.element.node.attrs.id,
      ["tree", "members"],
      (data) => {
        setSpace({
          ...data.space,
          permissions: { ...data.permissions },
          colors: { ...data.colors },
        });
        setCanUploadCover(
          data.permissions && data.permissions.can_upload_cover
        );
        setSpaceName(data.space.name);
        setSpaceAbstract(data.abstract);
        setLoading(false);
      },
      (data) => {}
    );
  };

  const onCoverUploadFinished = (url) => {
    const newSpace = { ...space };
    newSpace[uploadMethod].url = url;
    setSpace(newSpace);
    onSave();
  };

  const onRemoveHeader = () => {
    if (uploadMethod == "header") {
      SpaceModel.onRemoveHeader(
        space.slug,
        (data) => {
          onCoverUploadFinished(null);
          onSave();
        },
        (data) => {}
      );
    } else {
      SpaceModel.onRemoveThumbnail(
        space.slug,
        (data) => {
          onCoverUploadFinished(null);
          onSave();
        },
        (data) => {}
      );
    }
  };

  const onChangeName = (value) => {
    setSpaceName(value);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      timeoutId.current = null;
      onUpdateName(value);
    }, 100);
  };

  const onChangeAbstract = (value) => {
    setSpaceAbstract(value);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      timeoutId.current = null;
      onUpdateAbstract(value);
    }, 100);
  };

  const onUpdateName = (value) => {
    SpaceModel.onUpdate(
      space.slug,
      { intention: value },
      (data) => {
        onSave();
      },
      (data) => {}
    );
  };

  const onUpdateAbstract = (value) => {
    SpaceModel.onUpdate(
      space.slug,
      { abstract_text: value },
      (data) => {
        onSave();
      },
      (data) => {}
    );
  };

  const onSave = () => {
    dispatch(setListSpaceToUpdate({ slug: space.slug }));
    setTimeout(() => {
      dispatch(setListSpaceToUpdate({ slug: space.slug }));
    }, 500)
  };

  return (
    <Box>
      {space && space.slug && (
        <>
          {props.element &&
            props.element.node &&
            props.element.node.attrs.viewAs != "link" &&
            props.element.node.attrs.viewAs != "button" && (
              <TextInput
                label={t("spaces.form.form.intention.label")}
                disabled={loading}
                mt={20}
                mb={20}
                value={spaceName}
                onChange={(event) => onChangeName(event.currentTarget.value)}
              />
            )}
          {props.element &&
            props.element.node &&
            props.element.node.attrs.viewAs.indexOf("card") >= 0 && (
              <Textarea
                label={t("spaces.form.form.abstract.label")}
                disabled={loading}
                mt={20}
                mb={20}
                value={spaceAbstract}
                onChange={(event) =>
                  onChangeAbstract(event.currentTarget.value)
                }
              />
            )}
        </>
      )}

      {props.element &&
        props.element.node &&
        props.element.node.attrs.viewAs != "link" &&
        props.element.node.attrs.viewAs != "button" &&
        (space && space[uploadMethod] && !space[uploadMethod].url ? (
          canUploadCover[0] ? (
            <Uploady
              destination={{
                url: `/api/v4/spaces/${space.slug}/upload_${
                  uploadMethod == "header" ? "header" : "thumbnail"
                }?auth_token=${window.$currentUserAuthToken}`,
              }}
              accept="image/*"
            >
              <LogProgress onUploadCallback={onCoverUploadFinished} />
              <CustomUploadHeaderButton
                appearanceButton={classes.appearanceButton}
                uploadMethod={uploadMethod}
              />
              <Text size="xs" color="dimmed" mt={5}>
                {t("tiptap.components.sidebar.spaceAttrs.text")}
              </Text>
            </Uploady>
          ) : (
            <>
              <Button
                variant="filled"
                radius="xl"
                leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
                onClick={() =>
                  setOpened([true, canUploadCover[1], canUploadCover[2]])
                }
              >
                {uploadMethod == "header"
                  ? t("tiptap.components.sidebar.spaceAttrs.addCover2")
                  : "Add thumbnail"}
              </Button>
              <Text size="xs" color="dimmed" mt={5}>
                {t("tiptap.components.sidebar.spaceAttrs.text2")}
              </Text>
            </>
          )
        ) : (
          <Box>
            {space && space[uploadMethod] && space[uploadMethod].url && (
              <Image src={space[uploadMethod].url} />
            )}
            <MantineProvider theme={{ primaryShade: 6 }}>
              <Button
                disabled={loading}
                mt={20}
                variant="filled"
                color="gray"
                radius="xl"
                leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
                // classNames={{
                //   root: classes.appearanceButtonRoot,
                //   inner: classes.appearanceButtonInner,
                //   leftIcon: classes.appearanceButtonLeftIcon
                // }}
                onClick={() => onRemoveHeader()}
              >
                {uploadMethod == "header"
                  ? t("tiptap.components.sidebar.spaceAttrs.removeCover")
                  : "Remove thumbnail"}
              </Button>
            </MantineProvider>
          </Box>
        ))}

      <PremiumModal opened={opened} setOpened={setOpened} />
    </Box>
  );
}
