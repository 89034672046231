import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  Text,
  Group,
  ScrollArea,
  Button,
  Box,
  TextInput,
  createStyles,
  Stack,
  SimpleGrid,
  Loader,
  Center,
} from "@mantine/core";

import MemberModel from "../../../models/Member";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useViewportSize } from "@mantine/hooks";

import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import InvitePeople from "../settings/members/InvitePeople";
import SectionHeader from "../settings/layout/SectionHeader";

import tinycolor from "tinycolor2";

import Member from "./Member";

const paginator = {
  page: 1,
  per: 15,
};

const loader = {
  loading: false,
  oldScope: "all",
};

const useStyles = createStyles((theme, { colors }) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  secondaryButtonStyle: {
    backgroundColor: colors.secondary_button_background_color,
    color: colors.secondary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.secondary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  linkButtonStyle: {
    color: colors.header_link_color,
    "&:hover": {
      color: tinycolor(colors.header_link_color).darken(4).toString(),
    },
  },
  searchBar: {},
  title: {
    color: colors.default_text_color,
  },
  subtitle: {
    color: tinycolor(colors.default_text_color).darken(20).toString(),
  },
  memberItem: {
    color: colors.sidebar_text_color,
    backgroundColor: colors.sidebar_background_color,
    cursor: "pointer",
  },
  memberItemText: {
    color: colors.sidebar_text_color,
    "&:hover": {
      color: colors.sidebar_text_color,
    },
  },
  memberItemTextDimmed: {
    color: tinycolor(colors.sidebar_text_color).darken(5).toString(),
    "&:hover": {
      color: tinycolor(colors.sidebar_text_color).darken(5).toString(),
    },
  },
}));

export default function List(props) {
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const mainActions = useSelector((state) => state.mainActions);
  const location = useLocation();
  const dispatch = useDispatch();
  const { height, width } = useViewportSize();

  const [opennedInvite, setOpenedInvite] = useState(false);

  const [members, setMembers] = useState({
    total: 0,
    page: 1,
    pages: 1,
    data: [],
  });
  const [scope, setScope] = useState(null);
  const [term, setTerm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState({
    all: 0,
    active: 0,
    pending: 0,
    managers: 0,
  });

  const viewport = useRef();
  const timeoutId = useRef();
  const nomore = useRef();

  useEffect(() => {
    return () => {
      nomore.current = false;
    };
  }, []);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [space]);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [term]);

  const onFetch = () => {
    setLoading(true);
    if (loader.oldScope != scope) {
      paginator.page = 1;
      loader.oldScope = scope;
    }

    MemberModel.onFetch(
      space.slug,
      paginator,
      { scope, term },
      (data) => {
        setLoading(false);
        if (paginator.page == 1) {
          setMembers({ ...data });
        } else {
          if (data.data.length > 0) {
            const auxMembers = { ...members };
            auxMembers.data = members.data.concat(data.data);
            setMembers(auxMembers);
          } else {
            paginator.page--;
            nomore.current = true;
          }
        }
      },
      (data) => {
        setLoading(false);
      }
    );
  };

  const onSearch = () => {
    setTerm(document.getElementById("search-input").value);
  };

  const onClearSearch = () => {
    document.getElementById("search-input").value = "";
    setTerm("");
  };

  const changePage = (page) => {
    paginator.page = page;
    onFetch();
  };

  const onKeyPress = (event) => {
    onKeyGeneral(event);
  };
  const onKeyDown = (event) => {
    onKeyGeneral(event);
  };

  const onKeyGeneral = (event) => {
    if (event.which == 13) {
      event.preventDefault();
      onSearch();
    }
  };

  const onScrollPositionChange = (pos) => {
    const div = viewport.current;

    if (pos.y + div.clientHeight >= div.scrollHeight - 100) {
      if (nomore.current) {
        return;
      }
      setLoading(true);
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(() => {
        changePage(paginator.page + 1);
      }, 1000);
    }
  };

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea
        onScrollPositionChange={onScrollPositionChange}
        viewportRef={viewport}
        style={{ height: "100%" }}
        mt={10}
      >
        <Container
          size={space.space_width == "wide" ? 960 : 960}
          mt={20}
          mb={40}
        >
          <SectionHeader
            titleClass={classes.title}
            subtitleClass={classes.subtitle}
            spaceName={space.name}
            spaceId={space.slug}
            spaceVersion={space.version}
            title={t("spaces.members.sectionTitle")}
            rightSide={
              space && space.permissions && space.permissions.can_manage ? (
                <Group>
                  <Button
                    variant="light"
                    color="gray"
                    component={Link}
                    to={`/space/${space.slug}/settings/members`}
                    radius="xl"
                    className={classes.secondaryButtonStyle}
                  >
                    {t("spaces.members.manage")}
                  </Button>
                  <Button
                    variant="filled"
                    radius="xl"
                    onClick={() => setOpenedInvite(true)}
                    leftIcon={<FontAwesomeIcon icon={solid("user-friends")} />}
                    className={classes.primaryButtonStyle}
                  >
                    {t("spaces.members.invite")}
                  </Button>
                </Group>
              ) : null
            }
          />

          <Group position="apart" mt={20}>
            <Group position="left" spacing="xs">
              <TextInput
                icon={<FontAwesomeIcon icon={solid("search")} />}
                placeholder={t("spaces.members.search.placeholder")}
                classNames={{ input: classes.searchBar }}
                id="search-input"
                radius="xl"
                onKeyPress={(event) => onKeyPress(event)}
                onKeyDown={(event) => onKeyDown(event)}
                rightSection={
                  term && (
                    <Text
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => onClearSearch()}
                    >
                      <FontAwesomeIcon icon={solid("xmark")} />
                    </Text>
                  )
                }
              />
              <Button
                variant="filled"
                radius="xl"
                onClick={(event) => onSearch()}
                className={classes.primaryButtonStyle}
              >
                {t("spaces.members.search.button")}
              </Button>
            </Group>
          </Group>

          {members.data.length == 0 && !loader.loading ? (
            <Box
              sx={(theme) => ({
                backgroundColor: colors.sidebar_background_color,
                textAlign: "center",
                padding: theme.spacing.xl,
                borderRadius: theme.radius.md,
                cursor: "pointer",
                marginTop: 20,
                color: colors.sidebar_text_color,
                "&:hover": {
                  backgroundColor: colors.sidebar_background_color,
                },
              })}
            >
              {t("spaces.members.emptyMessage")}
            </Box>
          ) : (
            <SimpleGrid
              cols={3}
              spacing={15}
              mt={20}
              breakpoints={[
                { maxWidth: 980, cols: 3, spacing: 15 },
                { maxWidth: 755, cols: 2, spacing: 15 },
                { maxWidth: 600, cols: 1, spacing: 15 },
              ]}
            >
              {members.data.map((member) => {
                return (
                  <Member
                    memberItemClass={classes.memberItem}
                    memberItemTextClass={classes.memberItemText}
                    memberItemTextDimmed={classes.memberItemTextDimmed}
                    linkButtonStyle={classes.linkButtonStyle}
                    hasSubscriptions={
                      space && space.permissions
                        ? space.permissions.can_accept_charges
                        : false
                    }
                    spaceId={space.slug}
                    showEmail={
                      space && space.permissions
                        ? !space.permissions.hide_member_emails
                        : false
                    }
                    member={member}
                    user={user}
                  />
                );
              })}
            </SimpleGrid>
          )}
          {loading && (
            <Center mt={20}>
              <Loader />
            </Center>
          )}
          <InvitePeople opened={opennedInvite} setOpened={setOpenedInvite} />
        </Container>
      </ScrollArea>
    </Stack>
  );
}
