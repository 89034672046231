import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  Box,
  Divider,
  Text,
  Group,
  Accordion,
  Card,
  UnstyledButton,
  Button,
  Skeleton,
  MantineProvider,
  Select,
  createStyles,
  useMantineTheme,
  Input,
  Tooltip,
  Switch,
  ScrollArea,
} from "@mantine/core";

import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import InvitePeople from "./members/InvitePeople";
import SectionHeader from "./layout/SectionHeader";
import Scheduling from "./main/Scheduling";
import Additional from "./main/Additional";
import Seo from "./main/Seo";
import Scripts from "./main/Scripts";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";

import Open from "./privacy/Open";
import Private from "./privacy/Private";
import Paid from "./privacy/Paid";

import RegistrationFlow from "./registration/RegistrationFlow";

import SpaceSettingModel from "../../../models/SpaceSetting";

import MemberModel from "../../../models/Member";

import PremiumModal from "../../app/PremiumModal";

import Swal from "sweetalert2";

import { CopyToClipboard } from "react-copy-to-clipboard";

import getSymbolFromCurrency from "currency-symbol-map";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colors.gray[0],
  },
  //
  // inner: {
  //   display: 'flex',
  //
  //   [theme.fn.smallerThan(350)]: {
  //     flexDirection: 'column',
  //   },
  // },
  //
  // ring: {
  //   flex: 1,
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //
  //   [theme.fn.smallerThan(350)]: {
  //     justifyContent: 'center',
  //     marginTop: theme.spacing.md,
  //   },
  // },

  metricItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    padding: 10,
    backgroundColor: theme.colors.gray[0],
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: `${theme.shadows.xs} !important`,
      backgroundColor: theme.white,
      transform: "scale(1.05)",
    },
  },
  customLinkRight: {
    backgroundColor: theme.colors.gray[2],
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 5,
    height: 36,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    borderLeft: `1px solid ${theme.colors.gray[4]}`,
    color: theme.colors.gray[7],
  },
  customLinkLeft: {
    backgroundColor: theme.colors.gray[2],
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 5,
    height: 36,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    borderRight: `1px solid ${theme.colors.gray[4]}`,
    color: theme.colors.gray[7],
  },
  accordionTitle: {
    fontWeight: 800,
    fontSize: 40,
    color: "red",
    root: {
      fontWeight: 800,
      fontSize: 40,
    },
    child: {
      fontWeight: 800,
      fontSize: 40,
    },
    "& > div > div": {
      fontWeight: "800 !important",
      fontSize: "40px !important",
    },
  },
  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },
}));

let calendarUpdateTimer = null;

export default function Main() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const paramsUrl = useParams();
  const dispatch = useDispatch();
  const space = useSelector((state) => state.space);
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const smallScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );
  const [settings, setSettings] = useState(null);
  const [privacySelection, setPrivacySelection] = useState("open");
  const [blockUntilRegistered, setBlockUntilRegistered] = useState();
  const [visibility, setVisibility] = useState();
  const [accordionState, setAccordionState] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [editLink, setEditLink] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [sharebleLink, setSharebleLink] = useState("");
  const [counts, setCounts] = useState({
    all: 0,
    active: 0,
    pending: 0,
    managers: 0,
    interested: 0,
    invitations: 0,
    payments: 0,
    earnings: 0,
    formatted_earnings: "$0",
  });
  const [inviteOpened, setInviteOpened] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [urlHandle, setUrlHandle] = useState("");
  const [useLegacyRegistrationPage, setUseLegacyRegistrationPage] =
    useState(false);

  const [opened, setOpened] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  const [loading, setLoading] = useState(false);

  const accordionLoaded = useRef();

  useEffect(() => {
    console.log(accordionState);
    if (accordionLoaded.current) {
      localStorage.setItem(
        "lastOpenedSettingsTabs",
        JSON.stringify(accordionState)
      );
    }
  }, [accordionState]);

  useEffect(() => {
    setLoading(true);
  }, [paramsUrl]);

  useEffect(() => {
    if (space && space.slug) {
      const search = window.location.search;
      const section = new URLSearchParams(search).get("section");
      if (section) {
        const sections = {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
        };
        sections[section] = true;
        setAccordionState(sections);
      } else {
        const auxState = localStorage.getItem("lastOpenedSettingsTabs", "");
        if (auxState && auxState != "null") {
          setAccordionState(JSON.parse(auxState));
        }
      }

      accordionLoaded.current = true;

      setUrlHandle(space.url_handle);
      setSharebleLink(space.shareble_link);
      onFetchCounts();
      onFetch();
    }

    setValidatePremium(
      space && space.permissions && space.permissions.can_edit_shareable_link
    );
  }, [space]);

  useEffect(() => {
    if (settings) {
      setPrivacySelection(settings.privacy_level);
      setBlockUntilRegistered(settings.block_until_registered);
      setVisibility(settings.visibility == "public");
      setUseLegacyRegistrationPage(settings.use_legacy_registration_page);
    }
  }, [settings]);

  useEffect(() => {
    if (settings && settings.privacy_level != privacySelection) {
      onUpdate({ privacy_level: privacySelection });
    }
  }, [privacySelection]);

  useEffect(() => {
    if (settings && settings.block_until_registered != blockUntilRegistered) {
      onUpdate({ block_until_registered: blockUntilRegistered });
    }
  }, [blockUntilRegistered]);

  useEffect(() => {
    if (settings && settings.visibility != visibility) {
      onUpdate({ visibility: visibility ? "public" : "" });
    }
  }, [visibility]);

  useEffect(() => {
    if (
      settings &&
      settings.use_legacy_registration_page != useLegacyRegistrationPage
    ) {
      onUpdate({ use_legacy_registration_page: useLegacyRegistrationPage });
    }
  }, [useLegacyRegistrationPage]);

  useEffect(() => {
    if (editLink && !validatePremium[0]) {
      setEditLink(false);
      setOpened([true, validatePremium[1], validatePremium[2]]);
    }
  }, [editLink]);

  const displayPrivacySelection = () => {
    if (privacySelection == "open") {
      return <Open />;
    } else if (privacySelection == "private") {
      return <Private />;
    } else if (privacySelection == "payment") {
      return (
        <Paid
          showDisconnect={true}
          settings={settings}
          onUpdate={onUpdate}
          onRefresh={onFetch}
          space={space}
          options={["paid", "flexible", "recurring"]}
        />
      );
    } else {
      return <Open />;
    }
  };

  const itemsList = [
    {
      label: t("spaces.settings.main.main.label2"),
      index: 0,
    },

    {
      label: t("spaces.settings.main.main.label3"),
      description: t("spaces.settings.main.main.description4"),
      index: 1,
    },
    {
      label: t("spaces.settings.main.main.label4"),
      description: t("spaces.settings.main.main.description5"),
      index: 2,
    },
    {
      label: t("spaces.settings.main.main.label5"),
      description: t("spaces.settings.main.main.description6"),
      index: 3,
    },
    {
      label: t("spaces.settings.main.main.label6"),
      description: t("spaces.settings.main.main.description7"),
      index: 4,
    },
    {
      label: t("spaces.settings.main.main.label7"),
      description: t("spaces.settings.main.main.description8"),
      index: 5,
    },
  ];

  const privacyOptions = [
    {
      label: t("spaces.settings.main.main.label8"),
      value: "open",
      description: t("spaces.settings.main.main.description9"),
    },

    {
      label: t("spaces.settings.main.main.label9"),
      value: "private",
      description: t("spaces.settings.main.main.description10"),
    },
    {
      label: t("spaces.settings.main.main.label10"),
      value: "payment",
      description: t("spaces.settings.main.main.description11"),
    },
  ];

  const registrationOptions = [
    {
      value: "open and on",
      description: t("spaces.settings.main.main.description12"),
    },

    {
      value: "open and off",
      description: t("spaces.settings.main.main.description13"),
    },
    {
      value: "private and on",
      description: t("spaces.settings.main.main.description14"),
    },
    {
      value: "private and off",
      description: t("spaces.settings.main.main.description15"),
    },
    {
      value: "payment and on",
      description: t("spaces.settings.main.main.description16"),
    },
    {
      value: "payment and off",
      description: t("spaces.settings.main.main.description17"),
    },
  ];

  const AccordionLabel = ({ label, description, index }) => {
    return (
      <Group noWrap>
        <div>
          <Text size="lg" weight={700}>
            {label}
          </Text>

          {!accordionState[index] ? (
            <Text size="sm" color="dimmed" weight={400}>
              {description}
            </Text>
          ) : (
            ""
          )}
        </div>
      </Group>
    );
  };

  const SelectItem = ({ label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <div>
          <Text size="lg" weight={600}>
            {label}
          </Text>
          <Text size="md" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  const onFetchCounts = () => {
    MemberModel.onFetchCounts(
      space.slug,
      (data) => {
        setCounts(data);
      },
      (data) => {}
    );
  };

  const getStats = () => {
    const keys = Object.keys(counts);
    const stats = [];

    if (counts.active > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label11"),
        value: counts.active,
        section: "registered",
        scope: "all",
      });
    }
    if (counts.pending > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label12"),
        value: counts.pending,
        section: "registered",
        scope: "pending",
      });
    }
    if (counts.interested > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label13"),
        value: counts.interested,
        section: "interested",
      });
    }
    if (counts.invitations > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label14"),
        value: counts.invitations,
        section: "invited",
      });
    }
    if (counts.earnings > 0) {
      stats.push({
        label: t("spaces.settings.main.main.label15"),
        value: getSymbolFromCurrency(formatted_earnings),
        section: "payments",
      });
    }

    return stats;
  };

  const onFetch = () => {
    SpaceSettingModel.onFetch(
      space.slug,
      (data) => {
        setSettings({
          ...data.space,
          settings: { ...data.settings },
          payment_settings: { ...data.payment_settings },
        });

        setTimeout(() => {
          setLoading(false);
        }, 250);
      },
      (data) => {}
    );
  };

  const onUpdate = (params) => {
    const main = Object.assign({}, settings, params);
    delete main.pod_settings_attributes;
    delete main.pod_settings_attributes;
    const _settings = Object.assign(
      {},
      settings.settings,
      params.pod_settings_attributes
    );
    const payment_settings = Object.assign(
      {},
      settings.payment_settings,
      params.pod_payment_settings_attributes
    );

    setSettings({
      ...main,
      settings: { ..._settings },
      payment_settings: { ...payment_settings },
    });

    SpaceSettingModel.onUpdate(
      space.slug,
      params,
      (data) => {
        // onFetch();
      },
      (data) => {}
    );
  };

  const onUpdateUrlHandle = () => {
    // const urlHandle = document.getElementById("url_handle").value;

    SpaceSettingModel.onUpdateUrlHandle(
      space.slug,
      urlHandle,
      (data) => {
        setEditLink(false);
        setSharebleLink(data.shareable_link);
        Swal.fire(data.title, data.message, "success");
      },
      (data) => {
        Swal.fire(data.title, data.message, "error");
      }
    );
  };

  const goToMembers = (stat) => {
    let link = `/space/${space.slug}/settings/members?section=${stat.section}`;
    if (stat.scope) {
      link += `?scope=${stat.scope}`;
    }
    navigate(link);
  };

  const getPrivacyMessage = () => {
    const selection = privacyOptions.filter(
      (item) => item.value == privacySelection
    )[0];
    if (selection) {
      return `${selection.label} - ${selection.description}`;
    } else {
      return "";
    }
  };

  const getRegistrationMessage = () => {
    const showPage = blockUntilRegistered ? "on" : "off";
    const registrationSetting = `${privacySelection} and ${showPage}`;
    const selection = registrationOptions.filter(
      (item) => item.value == registrationSetting
    )[0];

    if (selection) {
      return selection.description;
    } else {
      return "";
    }
  };

  const onEditRegistrationPage = () => {
    if (settings && settings.use_legacy_registration_page) {
      window.location.href= `/circle/${space.slug}/pages/manage/1?auth_token=${window.$currentUserAuthToken}`;
    } else {
      // navigate(`/space/${space.slug}/registration/edit`);
      window.location.href= `/space/${space.slug}/registration/edit`;
    }
  };

  const onViewRegistrationPage = () => {
    if (settings && settings.use_legacy_registration_page) {
      window.open(
        `/circle/${space.slug}/register?preview=true&auth_token=${window.$currentUserAuthToken}`,
        `_blank`
      );
    } else {
      window.open(`/space/${space.slug}/registration/view`, `_blank`);
    }
  };

  const onCopyLink = () => {
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  if (loading) {
    return (
      <Container
        sx={(theme) => ({
          // maxWidth: "960px",
          // width: "960px",
          height: "100%",
        })}
      >
        <Skeleton height={25} width="40%" mt={30} ml={30} />
        <Skeleton height={40} width="60%" mt={20} mb={20} ml={30} />
      </Container>
    );
  }

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container
        pt={20}
        pb={40}
        pl={40}
        pr={40}
        sx={(theme) => ({
          // width: "960px",
          // maxWidth: "960px",
        })}
      >
        <SectionHeader
          spaceId={space.slug}
          spaceVersion={space.version}
          spaceName={space.name}
          title={t("spaces.settings.main.title")}
          rightSide={
            <Group>
              <Button
                variant="filled"
                radius="xl"
                onClick={(event) => setInviteOpened(true)}
                leftIcon={<FontAwesomeIcon icon={solid("user-friends")} />}
                sx={{
                  backgroundColor: theme.colors.sutrablue[3],
                  "&:hover": {
                    backgroundColor: theme.colors.sutrablue[4],
                  },
                }}
              >
                {t("spaces.settings.main.main.invite")}
              </Button>
            </Group>
          }
        />

        <Card withBorder p="lg" mb={10} radius="md" className={classes.card}>
          <div>
            <Group
              position={smallScreen ? "center" : "apart"}
              sx={{
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box>
                <Group position="apart" noWrap>
                  <Text weight={500} align={smallScreen ? "center" : "left"}>
                    {t("spaces.settings.main.main.shareableLink")}
                  </Text>
                  {editLink ? (
                    <Group spacing={10}>
                      <Button
                        variant="outline"
                        color="red"
                        radius="xl"
                        size="xs"
                        compact
                        onClick={() => setEditLink(false)}
                      >
                        {t("spaces.settings.main.main.cancelLink")}
                      </Button>
                      <Button
                        variant="outline"
                        color="blue"
                        radius="xl"
                        size="xs"
                        compact
                        onClick={() => onUpdateUrlHandle()}
                      >
                        {t("spaces.settings.main.main.saveLink")}
                      </Button>
                    </Group>
                  ) : (
                    <Button
                      variant="outline"
                      color="dark"
                      radius="xl"
                      size="xs"
                      compact
                      onClick={() => setEditLink(true)}
                    >
                      {t("spaces.settings.main.main.editLinkButton")}
                    </Button>
                  )}
                </Group>
                {editLink ? (
                  <>
                    <Group spacing={0} mt={5} mb={5}>
                      <Box className={classes.customLinkRight}>
                        <Text>https://</Text>
                      </Box>
                      <Input
                        // id="url_handle"
                        value={urlHandle}
                        onChange={(event) =>
                          setUrlHandle(event.currentTarget.value)
                        }
                        placeholder={t("spaces.settings.main.main.subdomain")}
                        radius={0}
                        sx={(theme) => ({
                          width: 140,
                        })}
                      />
                      <Box className={classes.customLinkLeft}>
                        <Text>{t("spaces.settings.main.main.sutra_co")}</Text>
                      </Box>
                    </Group>
                  </>
                ) : (
                  <Tooltip
                    label={t("spaces.settings.main.main.label1")}
                    opened={copiedToClipboard}
                    position="bottom"
                    withArrow
                  >
                    <CopyToClipboard
                      onCopy={() => onCopyLink()}
                      text={sharebleLink}
                    >
                      <Text
                        size="lg"
                        color="dimmed"
                        align={smallScreen ? "center" : "left"}
                      >
                        {`${sharebleLink}`}
                      </Text>
                    </CopyToClipboard>
                  </Tooltip>
                )}
              </Box>
              {smallScreen ? (
                <Divider
                  sx={(theme) => ({
                    borderTopColor: theme.colors.gray[2],
                    width: "100%",
                  })}
                />
              ) : (
                ""
              )}

              <Group spacing={5} position={smallScreen ? "center" : "right"}>
                {getStats().map((stat) => (
                  <UnstyledButton
                    key={stat.label}
                    className={classes.metricItem}
                    onClick={() => goToMembers(stat)}
                  >
                    <Text weight={700} align="center">
                      {stat.value}
                    </Text>
                    <Text size="sm" color="dimmed">
                      {stat.label}
                    </Text>
                  </UnstyledButton>
                ))}
              </Group>
            </Group>
          </div>
        </Card>

        <Accordion
          initialItem={1}
          iconPosition="right"
          multiple
          pl={5}
          state={accordionState}
          onChange={(event) => setAccordionState(event)}
        >
          <Accordion.Item
            label={
              <AccordionLabel
                {...itemsList[0]}
                key={itemsList[0].label}
                description={`${getPrivacyMessage()}`}
              />
            }
          >
            <Box mb={20}>
              <MantineProvider theme={{ primaryShade: 6 }}>
                <Select
                  value={privacySelection}
                  onChange={setPrivacySelection}
                  placeholder={t("spaces.settings.main.main.privacySetting")}
                  itemComponent={SelectItem}
                  data={privacyOptions}
                  maxDropdownHeight={400}
                  radius="md"
                  size="lg"
                />
              </MantineProvider>

              {privacySelection != "private" &&
                space.slug &&
                space.belongs_to_private_or_paid_circles && (
                  <Group position="apart" noWrap spacing="xl" mb={20} mt={20}>
                    <div>
                      <Text className={classes.itemTitle}>
                        {t("spaces.settings.main.main.title1")}
                      </Text>
                      <Text className={classes.itemDescription}>
                        {t("spaces.settings.main.main.description1")}
                      </Text>
                    </div>
                    <Switch
                      onLabel={t("spaces.settings.main.main.on")}
                      offLabel={t("spaces.settings.main.main.off")}
                      className={classes.switch}
                      size="lg"
                      checked={visibility}
                      onChange={(event) =>
                        setVisibility(event.currentTarget.checked)
                      }
                    />
                  </Group>
                )}

              {displayPrivacySelection()}
            </Box>
          </Accordion.Item>

          <Accordion.Item
            label={
              <AccordionLabel
                {...itemsList[1]}
                key={itemsList[1].label}
                description={`${getRegistrationMessage()}`}
              />
            }
          >
            <>
              <Group position="apart" noWrap spacing="xl" mb={20}>
                <div>
                  <Text className={classes.itemTitle}>
                    {t("spaces.settings.main.main.title2")}
                  </Text>
                  <Text className={classes.itemDescription}>
                    {t("spaces.settings.main.main.description2")}
                  </Text>
                </div>
                <Switch
                  onLabel="ON"
                  offLabel="OFF"
                  className={classes.switch}
                  size="lg"
                  checked={blockUntilRegistered}
                  onChange={(event) =>
                    setBlockUntilRegistered(event.currentTarget.checked)
                  }
                />
              </Group>
              {settings && settings.has_legacy_registration_page && (
                <Group position="apart" noWrap spacing="xl" mb={20}>
                  <div>
                    <Text className={classes.itemTitle}>
                      {t("spaces.settings.main.main.title3")}
                    </Text>
                    <Text className={classes.itemDescription}>
                      {t("spaces.settings.main.main.description3")}
                    </Text>
                  </div>
                  <Switch
                    onLabel={t("spaces.settings.main.main.onButton")}
                    offLabel={t("spaces.settings.main.main.offButton")}
                    className={classes.switch}
                    size="lg"
                    checked={useLegacyRegistrationPage}
                    onChange={(event) =>
                      setUseLegacyRegistrationPage(event.currentTarget.checked)
                    }
                  />
                </Group>
              )}

              <Group mb={10}>
                <Button onClick={() => onEditRegistrationPage()} radius="xl">
                  {t("spaces.settings.main.main.editButton")}
                </Button>
                <Button onClick={() => onViewRegistrationPage()} radius="xl">
                  {t("spaces.settings.main.main.previewButton")}
                </Button>
              </Group>
              <Scripts
                settings={settings ? settings : null}
                onUpdate={onUpdate}
                onSettingsChange={onFetch}
              />
            </>
          </Accordion.Item>

          <Accordion.Item
            label={<AccordionLabel {...itemsList[2]} />}
            key={itemsList[2].label}
          >
            <Text size="sm">
              <RegistrationFlow
                settings={settings ? settings : null}
                onUpdate={onUpdate}
                onSettingsChange={onFetch}
              />
            </Text>
          </Accordion.Item>

          <Accordion.Item
            label={<AccordionLabel {...itemsList[3]} />}
            key={itemsList[3].label}
          >
            <Scheduling
              settings={settings ? settings.settings : null}
              onUpdate={onUpdate}
            />
          </Accordion.Item>

          <Accordion.Item
            label={<AccordionLabel {...itemsList[4]} />}
            key={itemsList[4].label}
          >
            <Text size="sm">
              <Additional settings={settings} onUpdate={onUpdate} />
            </Text>
          </Accordion.Item>

          <Accordion.Item
            label={<AccordionLabel {...itemsList[5]} />}
            key={itemsList[5].label}
          >
            <Text size="sm">
              <Seo settings={settings} onUpdate={onUpdate} />
            </Text>
          </Accordion.Item>
        </Accordion>

        <Box mb={50} />

        <InvitePeople
          opened={inviteOpened}
          setOpened={setInviteOpened}
          setRefresh={setRefresh}
        />
        <PremiumModal opened={opened} setOpened={setOpened} />
      </Container>
    </ScrollArea>
  );
}
