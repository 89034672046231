import React from "react";
import {
  Text,
  Box,
  TextInput,
  ActionIcon,
  Radio,
  createStyles,
} from "@mantine/core";

import { X } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
const useStyles = createStyles((theme) => ({
  dragHandle: {
    ...theme.fn.focusStyles(),
    height: "100%",
    color: theme.colors.gray[6],
    cursor: "move",
    paddingTop: 3,
    width: 20,
    maxWidth: 20,
  },
}));

export default function PaymentItem(props) {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box
        sx={{
          width: 30,
        }}
      >
        <Radio
          checked={false}
          styles={{
            radio: {
              marginTop: -2,
            },
          }}
        />
      </Box>

      <TextInput
        placeholder={t(
          "spaces.settings.survey.paymentItem.paymentItem.placeholder"
        )}
        radius="sm"
        size="md"
        value={props.content}
        onChange={(event) => props.onContentChange(event.currentTarget.value)}
        sx={(theme) => ({
          width: "calc(50% - 30px)",
          marginRight: 10,
        })}
      />

      <TextInput
        icon={<Text>{props.currency.symbol}</Text>}
        placeholder={`Amount in ${
          props.currency.currency !== undefined
            ? props.currency.currency.toUpperCase()
            : props.currency.currency
        }`}
        radius="sm"
        size="md"
        value={props.paymentAmount}
        onChange={(event) =>
          props.onPaymentAmountChange(event.currentTarget.value)
        }
        sx={(theme) => ({
          width: "calc(50% - 30px)",
        })}
        rightSection={
          <ActionIcon variant="transparent" onClick={() => props.onDestroy()}>
            <X size={18} />
          </ActionIcon>
        }
        rightSectionWidth={40}
      />
    </>
  );
}
