import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setHiddenSidebar } from "../../../../store/app";
import {
  Text,
  Box,
  Stack,
  ScrollArea,
  Container,
  createStyles,
  Button,
} from "@mantine/core";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
}));

export default function Private(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const colors = useSelector((state) => state.colors);

  const { classes, cx, theme } = useStyles({ colors });

  const user = useSelector((state) => state.user);

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    // debugger;
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: true }));

    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 500);

    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea className="content-scrollview" style={{ height: "100%" }}>
        <Container
          size={props.space.space_width == "wide" ? 960 : 801}
          sx={(theme) => ({
            height: "100%",
          })}
        >
          <Box
            sx={{
              display: "flex",
              height: "calc(100vh - 100px)",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack align="center">
              <Text
                size="xl"
                weight="800"
                align="center"
                color="gray"
                sx={{
                  maxWidth: 400,
                }}
              >
                {t("spaces.presentations.static.private.message.part1")}{" "}
                {!user.authenticated && (
                  <span>
                    {t("spaces.presentations.static.private.message.part2")}
                  </span>
                )}
              </Text>

              {!user ||
                (user && !user.authenticated && (
                  <Button
                    mt={10}
                    radius="xl"
                    component={Link}
                    to={`/user/start?s=${params.slug}`}
                  >
                    {t("spaces.presentations.static.private.button")}
                  </Button>
                ))}
            </Stack>
          </Box>
        </Container>
      </ScrollArea>
    </Stack>
  );
}
