import React from "react";
import { useSelector } from "react-redux";

import LikeButton from "../../likes/Button";

import { Box } from "@mantine/core";

const TiptapLikeButtonWrapper = (props) => {
  const space = useSelector((state) => state.space);
  const space2 = useSelector((state) => state.space2);

  const getSpace = () => {
    if (props.useSpace2) {
      return space2;
    } else {
      return space;
    }
  };

  return (
    <>
      {getSpace().slug && getSpace().list_allow_likes && (
        <Box pl={10} pr={10}>
          <LikeButton
            spaceId={getSpace().slug}
            liked={getSpace().is_liked}
            count={getSpace().likes_count}
            useSpace2={props.useSpace2}
          />
        </Box>
      )}
    </>
  );
};

export default TiptapLikeButtonWrapper;
