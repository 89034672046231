import React, { useState, useEffect, useRef } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";
import {

  Button,
  ScrollArea,
  Container,
  Box,

  Title,
  Group,
  Select,

  Loader,
  Center,

} from "@mantine/core";
import SpaceModel from "../../models/Space";
import SpaceNeo4jModel from "../../models/neo4j/Space";
import SpaceListItem from "../spaces_neo/presentations/List";
import NewSpace from "../spaces/New";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";

const paginator = {
  per: 15,
  page: 1,
};

const scrollTimeoutIds = [];

export default function SpacesList(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const params = useParams();
  const didMount = useRef(false);

  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSpaceFilter, setSpaceFilter] = useState(true);
  const [videoOpened, setVideoOpened] = useState(false);
  const [newSpaceOpened, setNewSpaceOpened] = useState(false);

  const viewport = useRef();

  useEffect(() => {
    if (!window.$currentUserAuthToken) {
      navigate("/user/start");
      return;
    }
    params.scope === "direct" ? setSpaceFilter(false) : setSpaceFilter(true);
    paginator.page = 1;
    setSpaces([]);
    const ctrl = new AbortController();
    fetchSpaces(ctrl);
    return () => ctrl.abort();
  }, [params]);

  const fetchSpaces = (ctrl) => {
    setLoading(true);

    const scope = params.scope ? params.scope : "top";
    if (scope == "invitations" || scope == "direct"){

      SpaceModel.onFetchByScope(
        scope,
        ["permissions", "members", "calendar", "last_messages", "simple"],
        paginator,
        (data) => {
          if (paginator.page == 1) {
            setSpaces(data);
          } else {
            setSpaces(spaces.concat(data));
          }
          setLoading(false);
        },
        (data) => {
          setLoading(false);
        },
        ctrl.signal
      );
    }
    else{
      SpaceNeo4jModel.onFetchByScope(
        scope,
        paginator.page - 1,
        (data) => {
          if (paginator.page == 1) {
            setSpaces(data);
          } else {
            setSpaces(spaces.concat(data));
          }
          setLoading(false);
        },
        (data) => {
          setLoading(false);
        },
        ctrl.signal
      );
    }
  };

  const getScope = () => {
    const scope = params.scope ? params.scope : "top";
    return scope;
  };

  const getScopedTitle = () => {
    const scope = params.scope ? params.scope : "top";
    if (scope == "top") {
      return t("home.spacesList.topTitle");
    } else if (scope == "managed") {
      return t("home.spacesList.managedTitle");
    } else if (scope == "all") {
      return t("home.spacesList.allTitle");
    } else if (scope == "direct") {
      return t("home.spacesList.directMessagesTitle");
    } else if (scope == "archived") {
      return t("home.spacesList.archivedTitle");
    } else if (scope == "deleted") {
      return t("home.spacesList.deletedTitle");
    } else if (scope == "invitations") {
      return t("home.spacesList.invitations");
    }
  };

  const onScrollPositionChange = (pos) => {
    const div = viewport.current;
    // const div = document.getElementsByClassName("app-main-frame")[0]
    clearScrollTimeouts();

    if (pos.y + div.clientHeight >= div.scrollHeight - 50) {
      console.log("On bottom");
      const scrollTimeoutId = setTimeout(() => {
        console.log("On bottom timer");
        // At bottom
        onScrollBottom();
      }, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else if (pos.y == 0) {
      const scrollTimeoutId = setTimeout(() => {
        // At Top
      }, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else {
      // In between
    }
  };

  const clearScrollTimeouts = () => {
    for (let i = 0; i < scrollTimeoutIds.length; i++) {
      const id = scrollTimeoutIds[i];
      clearTimeout(id);
    }
  };

  const onScrollBottom = () => {
    if (!loading) {
      paginator.page++;
      const ctrl = new AbortController();
      fetchSpaces(ctrl);
    }
  };

  const onFilterSelect = (value) => {
    navigate(`/home/${value}`);
  };

  const spacesSelection = [
    { value: "top", label: t("home.spacesList.top") },
    { value: "managed", label: t("home.spacesList.managed") },
    { value: "all", label: t("home.spacesList.all") },
    { value: "archived", label: t("home.spacesList.archived") },
    { value: "deleted", label: t("home.spacesList.deleted") },
    { value: "invitations", label: t("home.spacesList.invitations") },
  ];

  return (
    <ScrollArea
      style={{ height: "100%" }}
      onScrollPositionChange={onScrollPositionChange}
      viewportRef={viewport}
    >
      <Helmet>
        <title>{t("home.spacesList.tabTitle")}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }}>
        <Group position="apart" pt={16} noWrap>
          <Title sx={(theme) => ({ fontWeight: 900 })}>
            {`${getScopedTitle()}`}
          </Title>
          {showSpaceFilter ? (
            <Select
              defaultValue={params.scope ? params.scope : "top"}
              size="xl"
              sx={{ height: 50 }}
              styles={{
                input: {
                  width: 160,
                  border: "none",
                },
                item: { fontSize: 16, paddingTop: 8, paddingBottom: 8 },
              }}
              data={spacesSelection}
              onChange={onFilterSelect}
            ></Select>
          ) : (
            <Box sx={{ height: 50 }}></Box>
          )}
        </Group>
        {spaces.map((space) => {
          return (
            <Box
              sx={(theme) => ({
                margin: "15px 0px",
              })}
            >
              <SpaceListItem
                key={`space-list-item-${space.slug}`}
                space={space}
              />
            </Box>
          );
        })}
        {spaces.length == 0 && !loading ? (
          <Box
            mt={30}
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              textAlign: "center",
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
            })}
          >
            <Box>
              {t("home.spacesList.empty")}
              <b> {getScopedTitle().toLowerCase()}</b>
            </Box>
            {getScope() == "top" && (
              <Box mt={10}>
                <Button
                  mt={10}
                  mr={10}
                  variant="outline"
                  radius="xl"
                  component={Link}
                  to="/app/getting_started"
                >
                  {t("home.spacesList.learn")}
                </Button>
                <Button
                  mt={10}
                  variant="outline"
                  radius="xl"
                  onClick={() => setNewSpaceOpened(true)}
                >
                  {t("home.spacesList.createFirst")}
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          ""
        )}
        {loading && (
          <Center
            style={{
              marginTop: spaces.length == 0 ? "100px" : "0px",
              marginBottom: "40px",
            }}
          >
            <Loader />
          </Center>
        )}
      </Container>

      <NewSpace opened={newSpaceOpened} setOpened={setNewSpaceOpened} />
    </ScrollArea>
  );
}
