// import axios from 'axios';
import { getNeo4jHost } from '../utils/Host';
class Manager {
  constructor() {
    if (this instanceof Manager) {
      throw Error('Manager class cannot be instantiated.');
    }
  }

  static onFetchNeo4j = async (slug, paginator, {scope, term}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      scope,
      term,
      ...paginator
    }

    await window.$api.get(`${getNeo4jHost()}/api/v1/spaces/${slug}/managers`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onFetch = async (slug, paginator, {scope, term}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      scope,
      term,
      ...paginator
    }

    await window.$api.get(`/api/v4/spaces/${slug}/managers.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  // TODO:
  static onCreate = async (slug, user_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      user_id
    }

    await window.$api.post(`/api/v4/spaces/${slug}/managers.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onDestroy = async (pod_manager_id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.delete(`/api/v4/spaces/${slug}/managers/${pod_manager_id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }
}

export default Manager;
