import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";

import { useSelector } from 'react-redux';

import useSidebarStyles from "../../../utils/SidebarStyles"
import defaultSpaceColors from '../../../collections/defaultSpaceColors'

import tinycolor from 'tinycolor2'

import {
  Group,
  Text,
  createStyles,
  Badge,
  Loader
} from '@mantine/core';

import { ChevronDown, ChevronUp } from 'tabler-icons-react';

import useActivityCounter from "../../../utils/ActivityCounter";

import NodeIcon from './NodeIcon'

const useStyles = createStyles((theme, {colors, settingsView}) => ({

  nodeItemInside: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
    paddingLeft: "100%",
    marginLeft: "-100%",
    cursor: "pointer",
    minHeight: 47,
    // color: colors.sidebar_text_color,
    color: !settingsView ? colors.sidebar_text_color : defaultSpaceColors.sidebar_text_color,
    flexWrap: "nowrap",

    "&:hover": {
      backgroundColor: !settingsView ? tinycolor(colors.sidebar_background_color).darken(4).toString() : tinycolor(defaultSpaceColors.sidebar_background_color).lighten(4).toString(),
    },

  },

  nodeLabel: {
    lineHeight: 1.3
  },

  nodeItemInsideLinked: {
    display: "flex",
    alignItems: "center",
  },

  nodeItemInsideActive: {
    // window.location.pathname.indexOf("/settings/") < 0
    // backgroundColor: tinycolor(colors.sidebar_background_color).darken(4).toString(),
    backgroundColor: !settingsView ? tinycolor(colors.sidebar_background_color).darken(4).toString() : tinycolor(defaultSpaceColors.sidebar_background_color).lighten(4).toString(),
    color: !settingsView ? colors.sidebar_text_color : defaultSpaceColors.sidebar_text_color,
    // color: colors.sidebar_text_color,

    "& a": {
      color: colors.sidebar_text_color,
      fontWeight: "500",

    },

  },

  nodeItemInsideActiveSettingsView: {
    // some weird bug here with the theme object losing it's value, so i'm using the hard coded value for theme.colors.gray[7]
    backgroundColor: "#495057",

    "&:hover, & a, & a:hover": {
      backgroundColor: "#495057 !important",
      color: "#fff",
      fontWeight: "500",
    },

    // "&:hover": {
    //   backgroundColor: !settingsView ? tinycolor(colors.sidebar_background_color).darken(4).toString() : tinycolor(defaultSpaceColors.sidebar_background_color).lighten(4).toString(),
    // },
  },

  nodeItemLink: {
    display: "inline-block",
    width: "100%",
    paddingTop: "11px",
    paddingBottom: "11px",
    textDecoration: "none",
    // marginLeft: "5px",
    fontWeight: 400,
    // color: colors.sidebar_text_color,
    color: !settingsView ? colors.sidebar_text_color : defaultSpaceColors.sidebar_text_color,

    "&:hover": {
      textDecoration: "none",
    },
  },

  nodeItemEnd: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },

  toggler: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    backgroundColor: !settingsView ? tinycolor(colors.sidebar_background_color).darken(10).toString() : tinycolor(defaultSpaceColors.sidebar_background_color).lighten(10).toString(),
    // color: tinycolor(colors.sidebar_text_color).lighten(10).toString(),
    color: !settingsView ? tinycolor(colors.sidebar_text_color).lighten(10).toString() : tinycolor(defaultSpaceColors.sidebar_text_color).lighten(10).toString(),
    borderRadius: "4px",
    height: "25px",
    width: "25px",
    cursor: "pointer",
  },


}));

export default function NodeLink(props){

  const location = useLocation()

  const colors = useSelector(state => state.colors);
  const space = useSelector(state => state.space);

  const { settingsView } = useSidebarStyles();

  const { classes, cx } = useStyles({colors, settingsView});

  const [hasChildren, setHasChildren] = useState(false)

  const { activityCount } = useActivityCounter(props.version == "content" || props.version == "discussion" ? ["pod_message", "reflection", "parent_activity", "user_message"] : ["parent_activity"], [props.slug], ["pod_message", "pod", "parents_slugs"], [props.slug]);

  useEffect(() => {
    // console.log(props.name)
    setHasChildren(props.hasChildren)
  }, [props])

  const getLinkPath = (slug, version) => {
    if (settingsView){
      const setttingsSection = location.pathname.split("/").pop()
      return `/space/${slug}/settings/${setttingsSection}`
    }
    else{
    }
    return `/space/${slug}/${version}`
  }

  const selectSettingsViewClasses = () => {
    return (
      settingsView ? classes.nodeItemInsideActiveSettingsView : classes.nodeItemInsideActive
    )
  }

  return (
    <div
      className={`${classes.nodeItemInside} ${space.slug == props.slug ? selectSettingsViewClasses() : ""}`}>
      { props.editable && (
        <NodeIcon
          spaceId={props.slug}
          activeId={space.slug}
          emojicon={props.emojicon}
          completed={props.completed}
          editable={props.editable} />
      )}
      <Link
        className={classes.nodeItemLink}
        to={getLinkPath(props.slug, props.version)}>
        <Group
          noWrap
          spacing={0}
          className={classes.nodeItemInsideLinked}
        >
        { !props.editable && (
          <NodeIcon
            spaceId={props.slug}
            activeId={space.slug}
            emojicon={props.emojicon}
            completed={props.completed}
            editable={props.editable} />
        )}
        <Text
          // lineClamp={1}
          pr={10}
          className={classes.nodeLabel}
        >
          {`${props.name}`}
        </Text>
        </Group>
      </Link>
      <div
        className={classes.nodeItemEnd}
      >
      {activityCount > 0 && (
        <Badge
          variant="filled"
          color="sutrapink"
          styles={{
            root: {
              padding: "0 5px",
              marginRight: hasChildren ? 10 : 20,
              backgroundColor: colors.default_badge_color,
              color: colors.default_badge_text_color,
              "&:hover": {
                textDecoration: "none"
              }
            }
          }}
          >
          { activityCount }
        </Badge>
      )}
      {hasChildren ? (
        <span
          id={`collapse-button-node-${props.slug}-${props.opened ? "opened" : "closed"}`}
          className={classes.toggler}
          onClick={() => props.setOpened(!props.opened)}>
          {props.loading ? <Loader size="xs"/> : props.opened ? <ChevronUp /> : <ChevronDown />}
        </span>
      ) : ('')}
      </div>
    </div>
  )
}
