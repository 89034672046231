import React, { useState, useEffect, useRef } from "react";

import {
  Divider,
  Select,
  Table,
  Group,
  Center,
  Button,
  Box,
  MultiSelect,
  Checkbox,
  Loader,
  Text,
  Stack,
  Switch,
  Accordion,
  Tooltip,
  Badge,
} from "@mantine/core";

import { DateRangePicker } from "@mantine/dates";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

import { useLocation, Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Row from "./ProgressActivityRowV2";
import MemberModel from "../../../../models/Member";
import ManagerModel from "../../../../models/Manager";
import SpaceModel from "../../../../models/Space";

import PremiumModal from "../../../app/PremiumModal";

import Sorter from "./Sorter";

import { storeJSON, getJSON } from "../../../../utils/LocalDB";

const paginator = {
  page: 1,
  per: 30,
};

const loader = {
  loading: false,
  oldScope: "all",
};

const actionsOptions = () => {
  let versions = [
    {
      value: "commented",
      label: "Commented",
      description: "Track all member posts and comments across selected spaces",
    },
    {
      value: "completed",
      label: "Completed",
      description:
        "Track all selected spaces that members have marked complete",
    },
    {
      value: "visited",
      label: "Visited",
      description: "Track all selected spaces that members have visited",
    },
  ];

  return versions;
};

const SelectItem = ({ image, label, description, ...others }) => (
  <div {...others}>
    <Group noWrap>
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </div>
    </Group>
  </div>
);

export default function ProgressActivity(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const location = useLocation();
  // const theme = useMantineTheme();
  const theme = props.theme;
  const { height, width } = useViewportSize();

  const [orderBy, setOrderBy] = useState({ by: "name", as: "asc" });

  const [members, setMembers] = useState([]);
  const [scope, setScope] = useState(null);
  const [term, setTerm] = useState(null);
  const navigate = useNavigate();

  const [counts, setCounts] = useState({
    all: 0,
    active: 0,
    pending: 0,
    managers: 0,
  });

  const largeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.lg}px)`
  );
  const extraLargeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );

  const [value, setValue] = useState([
    new Date(2021, 11, 1),
    new Date(2021, 11, 5),
  ]);

  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searching2, setSearching2] = useState(false);
  const timeoutId = useRef();
  const noMore = useRef();
  const searchTimeoutId = useRef();
  const searchTimeoutId2 = useRef();
  const auxSpaces = useRef([]);
  const auxUsers = useRef([]);
  const indexedDbFetched = useRef(false);

  const [spaces, setSpaces] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [infoSelectedSpaces, setInfoSelectedSpaces] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [includeSubspaces, setIncludeSubspaces] = useState(true);
  const [filterByUsers, setFilterByUsers] = useState(false);

  const [actions, setActions] = useState("commented");
  const [dateRange, setDateRange] = useState(() => {
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    return [sevenDaysAgo, today];
  });

  const onSelect = (id) => {
    const newSelected = [...selected];
    const index = selected.indexOf(id);
    if (index >= 0) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  const onSelectAll = (all) => {
    const newSelected = [];
    if (all) {
      for (let i = 0; i < members.data.length; i++) {
        newSelected.push(members.data[i].username);
      }
    }
    setSelected(newSelected);
  };

  const areAllSelected = () => {
    return members.data.length == selected.length;
  };

  const hasSelections = () => {
    return selected.length > 0;
  };

  const isAnySelected = () => {
    return !areAllSelected() && selected.length > 0;
  };

  const isSelected = (id) => {
    return selected.indexOf(id) >= 0;
  };

  const tableWidth = () => {
    if (largeScreen) {
      return width - 300;
    } else if (extraLargeScreen) {
      return width - 500;
    } else {
      return "";
    }
  };

  const canAddNewManagers = () => {
    const valid =
      counts.managers < space.permissions.max_allowed_managers[0] ||
      space.permissions.max_allowed_managers[0] == -1;
    if (!valid) {
      setOpened([
        true,
        space.permissions.max_allowed_managers[1],
        space.permissions.max_allowed_managers[2],
      ]);
    }
    return valid;
  };

  // useEffect(() => {
  //   if (space && space.slug) {
  //     paginator.page = 1;
  //     onFetch();
  //     // onFetchCounts();
  //   }
  // }, [space, scope]);

  useEffect(() => {
    if (selectedSpaces.length) {
      onFetchUsers();
    }
    if (!indexedDbFetched.current) {
      return;
    }
    storeJSON("spaces", `progress-activity-${space.slug}`, {
      selectedSpaces: selectedSpaces,
      selectedUsers: selectedUsers,
      actions: actions,
      dateRange: dateRange,
      includeSubspaces: includeSubspaces,
      filterByUsers: filterByUsers,
    });
  }, [
    selectedSpaces,
    selectedUsers,
    actions,
    dateRange,
    includeSubspaces,
    filterByUsers,
  ]);

  useEffect(() => {
    // if (props.refresh) {
    //   onFetch(props.refresh);
    //   // onFetchCounts();
    //   props.setRefresh(false);
    // }
  }, [props]);

  useEffect(() => {
    getJSON(`spaces`, `progress-activity-${space.slug}`, (data) => {
      if (data && data.selectedSpaces && data.selectedSpaces.length) {
        setSelectedSpaces([...data.selectedSpaces]);
        onFetchSpaces();
      } else {
        setSelectedSpaces([space.slug]);
        onFetchSpaces();
      }
      if (data && data.selectedUsers && data.selectedUsers.length) {
        // setSelectedUsers([...data.selectedUsers]);
      }
      if (data && data.actions) {
        setActions(data.actions);
      }
      if (data && data.dateRange) {
        setDateRange(data.dateRange);
      }
      if (data && data.includeSubspaces) {
        setIncludeSubspaces(data.includeSubspaces);
      }
      if (data && data.includeSubspaces) {
        setFilterByUsers(data.filterByUsers);
      }
      indexedDbFetched.current = true;
    });
  }, [space]);

  const onFetch = (refresh = null) => {
    setLoading(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      if (refresh == "more") {
        paginator.page++;
      }

      if (loader.oldScope != scope) {
        paginator.page = 1;
        loader.oldScope = scope;
      }
      const Model = scope != "managers" ? MemberModel : ManagerModel;
      Model.onFetch(
        space.slug,
        paginator,
        { scope, term },
        (data) => {
          setLoading(false);
          const newData = { ...data };

          if (paginator.page == 1) {
            newData.data = [].concat(data.data);
          } else {
            newData.data = members.data.concat(data.data);
          }

          if (data.data.length == 0 && paginator.page > 1) {
            paginator.page--;
            noMore.current = true;
            setTimeout(() => {
              noMore.current = false;
            }, 10000);
          }

          setMembers(newData);
        },
        (data) => {
          setLoading(false);
        }
      );
    }, 1000);
  };

  const onFetchCounts = () => {
    MemberModel.onFetchCounts(
      space.slug,
      (data) => {
        setCounts(data);
      },
      (data) => {}
    );
  };

  const onMemberUpdate = (member) => {
    onFetchCounts();
  };

  const onSort = (sortBy) => {
    const aux = { ...orderBy };
    aux.by = sortBy;
    aux.as = aux.as == "asc" ? "desc" : "asc";
    setOrderBy(aux);

    const auxMembers = { ...members };
    if (aux.as == "asc") {
      auxMembers.data = auxMembers.data.sort((a, b) => {
        const nameA =
          typeof a[getSortKey(sortBy)] === "string"
            ? a[getSortKey(sortBy)].toUpperCase()
            : a[getSortKey(sortBy)];
        const nameB =
          typeof b[getSortKey(sortBy)] === "string"
            ? b[getSortKey(sortBy)].toUpperCase()
            : b[getSortKey(sortBy)];

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0; // names must be equal
      });
    } else {
      auxMembers.data = auxMembers.data.sort((a, b) => {
        const nameA =
          typeof a[getSortKey(sortBy)] === "string"
            ? a[getSortKey(sortBy)].toUpperCase()
            : a[getSortKey(sortBy)];
        const nameB =
          typeof b[getSortKey(sortBy)] === "string"
            ? b[getSortKey(sortBy)].toUpperCase()
            : b[getSortKey(sortBy)];

        if (nameA < nameB) {
          return 1;
        }

        if (nameA > nameB) {
          return -1;
        }

        return 0; // names must be equal
      });
    }

    console.log(auxMembers.data);
    console.log(getSortKey(sortBy));
    console.log(aux);
    setMembers(auxMembers);
  };

  const getSortKey = (sortBy) => {
    return sortBy == "name"
      ? "full_name"
      : sortBy == "participation"
      ? showWords
        ? "avg_post_size"
        : "posts_count"
      : sortBy;
  };

  const onSearchSpaces = (query) => {
    setSearching(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query);
      setSearching(false);
    }, 1000);
  };

  const onFetchSpaces = (query) => {
    const include = ["members", "simple"];
    if (query) {
      include.push(`q=${query}`);
    }

    auxSpaces.current = [...infoSelectedSpaces];
    if (
      auxSpaces.current.filter((item) => item.slug == space.slug).length == 0
    ) {
      auxSpaces.current.push({
        value: `${space.slug}`,
        label: `${space.name} (${space.slug})`,
        group: "Sub spaces",
      });
    }

    SpaceModel.onFetchDeepChildren(
      space.slug,
      include,
      { page: 1, per: 10 },
      ["active", "not_posts"],
      (data) => {
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].list_child_version.indexOf("posts") < 0) {
            auxSpaces.current.push({
              value: `${data.data[i].slug}`,
              label: `${data.data[i].name} (${data.data[i].slug})`,
              group: "Sub spaces",
            });
          }
        }

        setSpaces([...auxSpaces.current]);
      },
      (data) => {}
    );
  };

  const onSearchUsers = (query) => {
    setSearching2(true);
    if (searchTimeoutId2.current) {
      clearTimeout(searchTimeoutId2.current);
    }
    searchTimeoutId2.current = setTimeout(() => {
      onFetchUsers(query);
      setSearching2(false);
    }, 1000);
  };

  const onFetchUsers = (query) => {
    auxUsers.current = [];
    MemberModel.onFetchMembersBySlugs(
      space.slug,
      selectedSpaces,
      [],
      query,
      (data) => {
        auxUsers.current = [...data];

        setUsers([...auxUsers.current]);
      },
      (data) => {}
    );
  };

  const onUpdate = () => {
    setLoading(true);
    MemberModel.onFetchMembersProgress(
      space.slug,
      selectedSpaces,
      selectedUsers,
      includeSubspaces,
      actions,
      dateRange,
      (data) => {
        setMembers([...data.members]);
        setInfoSelectedSpaces([...data.spaces]);
        setLoading(false);
      },
      (data) => {
        setLoading(false);
      }
    );
  };

  const onDownload = () => {
    MemberModel.onDownloadProgressExcel(
      space.slug,
      selectedSpaces,
      selectedUsers,
      includeSubspaces,
      actions,
      dateRange
    );
  };

  const onDateChange = (e) => {
    let start_at = null
    let end_at = null
    if (e[0]){
      let day = e[0].getDate();
      if (day < 10){
        day = `0${day}`;
      }
      let month = e[0].getMonth()+1;
      if (month < 10){
        month = `0${month}`;
      }
      start_at = new Date(`${e[0].getFullYear()}-${month}-${day}`);
    }
    if (e[1]){
      let day = e[1].getDate();
      if (day < 10){
        day = `0${day}`;
      }
      let month = e[1].getMonth()+1;
      if (month < 10){
        month = `0${month}`;
      }
      end_at = new Date(`${e[1].getFullYear()}-${month}-${day}`);
    }
    
    setDateRange([start_at, end_at])
  }

  return (
    <div>
      <Stack mt={20}>
        {user && !user.is_admin && !user.is_organization && (
          <Box
            radius="xl"
            sx={(theme) => ({
              backgroundColor: theme.colors.green[0],
              borderRadius: "10px",
              padding: "20px",
            })}
          >
            <Text>
              {`Progress reporting is limited to only 5 people on your account
            level. Please upgrade to the Organization plan to unlock full
            reporting capabilities.`}
            </Text>
            <Button
              sx={{
                backgroundColor: theme.colors.sutrablue[3],
                "&:hover": {
                  backgroundColor: theme.colors.sutrablue[4],
                },
              }}
              mt={10}
              radius="xl"
              component={Link}
              to="/app/pricing"
            >
              {t("app.premium.upgrade")}
            </Button>
          </Box>
        )}
        <Group position="left" spacing="xs">
          <Select
            label={"Action"}
            value={actions}
            onChange={setActions}
            data={actionsOptions()}
            radius="md"
            itemComponent={SelectItem}
            sx={{ width: "47%" }}
          />
          {actions == "commented" && (
            <DateRangePicker
              label={"Date range"}
              radius="md"
              placeholder={t(
                "spaces.settings.members.participationActivity.participation.pickDatePlaceholder"
              )}
              value={dateRange}
              onChange={onDateChange}
              sx={{ width: "47%" }}
            />
          )}
        </Group>
        <MultiSelect
          icon={
            searching ? (
              <Loader size="xs" />
            ) : (
              <FontAwesomeIcon icon={solid("search")} />
            )
          }
          label={"Select spaces"}
          searchable
          data={spaces}
          placeholder={"Select spaces"}
          maxDropdownHeight={400}
          radius="md"
          value={selectedSpaces}
          onChange={setSelectedSpaces}
          onSearchChange={(query) => onSearchSpaces(query)}
          // sx={{ maxHeight: "50px" }}
          styles={{
            description: {
              fontWeight: 600,
            },
            label: {
              whiteSpace: "normal",
            },
            defaultValue: {
              height: "unset",
            },
            values: {
              maxHeight: "150px",
              overflowY: "scroll",
            },
          }}
        />
        <Group position="left" spacing="xs">
          <Switch
            checked={includeSubspaces}
            onChange={(event) =>
              setIncludeSubspaces(event.currentTarget.checked)
            }
          />
          <Text>{`Include direct subspaces`}</Text>
        </Group>

        <Group position="left" spacing="xs">
          <Switch
            checked={filterByUsers}
            onChange={(event) => setFilterByUsers(event.currentTarget.checked)}
          />
          <Text>{`Filter by people`}</Text>
        </Group>
        {filterByUsers && (
          <MultiSelect
            icon={
              searching2 ? (
                <Loader size="xs" />
              ) : (
                <FontAwesomeIcon icon={solid("search")} />
              )
            }
            label={"Filter by people"}
            searchable
            data={users}
            placeholder={"Select people"}
            maxDropdownHeight={400}
            radius="md"
            value={selectedUsers}
            onChange={setSelectedUsers}
            onSearchChange={(query) => onSearchUsers(query)}
            styles={{
              description: {
                fontWeight: 600,
              },
              label: {
                whiteSpace: "normal",
              },
              defaultValue: {
                height: "unset",
              },
              values: {
                maxHeight: "150px",
                overflowY: "scroll",
              },
            }}
          />
        )}
        <Group>
          <Button
            sx={{
              backgroundColor: theme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: theme.colors.sutrablue[4],
              },
            }}
            radius="xl"
            variant="filled"
            onClick={() => onUpdate()}
            disabled={loading}
          >
            {loading ? <Loader size="sm" /> : `Update`}
          </Button>
          <Button
            sx={{
              backgroundColor: theme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: theme.colors.sutrablue[4],
              },
            }}
            radius="xl"
            variant="filled"
            onClick={() => onDownload()}
            disabled={loading}
          >
            {`Download XLS`}
          </Button>
        </Group>
        <Accordion initialItem={0}>
          <Accordion.Item label="Space column keys">
            {infoSelectedSpaces.map((item, index) => {
              return (
                <Group mb={5}>
                  <Badge
                    color="gray"
                    variant="light"
                    size={"lg"}
                    sx={{ width: "50px" }}
                  >{`${index + 1}`}</Badge>
                  <Text>{`${item.label}`}</Text>
                </Group>
              );
            })}
          </Accordion.Item>
        </Accordion>
        {hasSelections() && (
          <Group position="right" spacing="xs">
            <Button
              compact
              radius="xl"
              sx={{
                backgroundColor: theme.colors.sutrablue[3],
                "&:hover": {
                  backgroundColor: theme.colors.sutrablue[4],
                },
              }}
              onClick={(event) =>
                props.onSendBroadcast("email", members, selected)
              }
            >
              {t(
                "spaces.settings.members.progressActivity.progress.emailButton"
              )}
            </Button>
            <Button
              compact
              radius="xl"
              sx={{
                backgroundColor: theme.colors.sutrablue[3],
                "&:hover": {
                  backgroundColor: theme.colors.sutrablue[4],
                },
              }}
              onClick={(event) =>
                props.onSendBroadcast("message", members, selected)
              }
            >
              {t(
                "spaces.settings.members.progressActivity.progress.messageButton"
              )}
            </Button>
          </Group>
        )}
      </Stack>

      <Box mt={10} style={{ width: tableWidth() }}>
        {members.length == 0 ? (
          <Box
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              textAlign: "center",
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
              cursor: "pointer",

              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[5]
                    : theme.colors.gray[1],
              },
            })}
          >
            {t(
              "spaces.settings.members.progressActivity.progress.noRecordFound"
            )}
            {members.length > 0 && (
              <p>
                {t(
                  "spaces.settings.members.progressActivity.progress.progressTracking"
                )}
              </p>
            )}
          </Box>
        ) : (
          <div style={{ overflowX: "auto" }}>
            <Table verticalSpacing="xs" sx={{ minWidth: "100%" }} withBorder>
              <thead>
                <tr>
                  <th>
                    {/* <Checkbox
                      checked={areAllSelected()}
                      indeterminate={isAnySelected()}
                      onChange={(event) =>
                        onSelectAll(event.currentTarget.checked)
                      }
                    /> */}
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "white",
                    }}
                  >
                    <Box sx={{ width: "150px !important" }}>People</Box>
                  </th>
                  {infoSelectedSpaces.map((item, index) => {
                    return (
                      <th
                        style={{
                          width: "50px",
                          padding: "0px",
                          margin: "0px",
                          textAlign: "center",
                        }}
                      >
                        <Tooltip
                          disabled={
                            window.$isTouchDevice && window.$isTouchDevice()
                          }
                          label={item.label}
                          maxWidth={200}
                        >
                          <Badge
                            color="gray"
                            variant="light"
                            ml={2}
                            mr={2}
                            size={"lg"}
                            sx={{ width: "50px" }}
                          >{`${index + 1}`}</Badge>
                        </Tooltip>
                        {/* <Sorter
                          orderBy={orderBy}
                          onSort={onSort}
                          sortBy={"name"}
                        /> */}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {members.map((member) => {
                  return (
                    <Row
                      hasSubscriptions={
                        space && space.permissions
                          ? space.permissions.can_accept_charges
                          : false
                      }
                      spaceId={space.slug}
                      onUpdate={onMemberUpdate}
                      member={member}
                      canAddNewManagers={canAddNewManagers}
                      counts={props.counts}
                      isSelected={isSelected}
                      onSelect={onSelect}
                      actions={actions}
                    />
                  );
                })}
              </tbody>
            </Table>
            {loading && !noMore.current && (
              <Center mb={20}>
                <Loader />
              </Center>
            )}
          </div>
        )}
      </Box>
      <Divider sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: 20,
          justifyContent: "center",
        }}
      ></Box>

      <PremiumModal opened={opened} setOpened={setOpened} />
    </div>
  );
}
