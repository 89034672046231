import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setAlert } from "../../store/app";
import {
  Box,
  Button,
  TextInput,
  Textarea,
  Image,
  Progress,
  ScrollArea,
  Group,
  createStyles,
} from "@mantine/core";
import Uploady, {
  useItemProgressListener,
  useItemFinishListener,
} from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";
import DefaultAvatar from "../../src/images/fallback/default.png";
import UserModel from "../../models/User";
import { useTranslation } from "react-i18next";

import { useForm } from "@mantine/form";
import useAuthUser from "../../utils/AuthUser";

import tinycolor from "tinycolor2";

const useStyles = createStyles((theme, { colors }) => ({
  buttonStyleOutline: {
    backgroundColor: "none",
    color: colors.primary_button_background_color,
    border: `1px solid ${colors.primary_button_background_color}`,
    "&:hover": {
      backgroundColor: tinycolor(colors.background_color).darken(1).toString(),
    },
  },
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  secondaryButtonStyle: {
    backgroundColor: colors.secondary_button_background_color,
    color: colors.secondary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.secondary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  buttonStyle: {
    backgroundColor: "none",
    color: colors.primary_button_background_color,
    border: `1px solid ${colors.primary_button_background_color}`,
    "&:hover": {
      backgroundColor: tinycolor(colors.background_color).darken(1).toString(),
    },
  },
  inputLabelStyle: {
    color: colors.default_text_color,
  },
}));

const LogProgress = (props) => {
  const [progress, setProgress] = useState(0);

  useItemProgressListener((item) => {
    setProgress(item.completed);
  });
  useItemFinishListener((item) => {
    props.onUploadCallback(item.uploadResponse.data);
  });

  return progress > 0 && progress < 100 ? (
    <Progress color="green" size="xl" mb={10} value={progress} />
  ) : (
    ""
  );
};

const CustomUploadButton = asUploadButton((props) => {
  const { t, i18n } = useTranslation();

  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  return (
    <Button
      {...props}
      className={classes.primaryButtonStyle}
      color="green"
      radius="xl"
      mt={20}
    >
      {t("settings.editProfile.addProfileImage")}
    </Button>
  );
});

export default function Profile() {
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.user);

  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const dispatch = useDispatch();

  const { setAuthUser } = useAuthUser();

  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      bio: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  useEffect(() => {
    initFormData();
  }, [user]);

  const initFormData = () => {
    form.setFieldValue("first_name", user.first_name);
    form.setFieldValue("last_name", user.last_name);
    form.setFieldValue("email", user.email);
    form.setFieldValue("username", user.username);
    form.setFieldValue("bio", user.bio);
  };

  const onAvatarUploaded = (data) => {
    dispatch(
      setAlert({
        opened: true,
        title: data.title,
        message: data.message,
        color: "green",
      })
    );

    // const newUser = { ...user };
    // newUser.avatar.url = data.url;
    // setAuthUser(newUser);
    // dispatch(setUser(newUser));
  };

  const onRemoveAvatar = () => {
    UserModel.onRemoveAvatar(
      user.id,
      (data) => {
        // Success callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );

        // const newUser = { ...user };
        // newUser.avatar.url = null;
        // setAuthUser(newUser);
        // dispatch(setUser(newUser));
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onSave = (values) => {
    UserModel.onUpdate(
      user.id,
      {
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.username,
        bio: values.bio,
        email: values.email,
        // location: values.location
      },
      (data) => {
        // Success callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );
        // const newUser = { ...user };

        // user.first_name = values.first_name;
        // user.last_name = values.last_name;
        // user.username = values.username;
        // user.bio = values.bio;
        // user.email = values.email;
        // // user.location = values.location
        // newUser.forceUpdate = true;
        // setAuthUser(newUser);
        // dispatch(setUser(newUser));
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  if (!user) {
    return null;
  }

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Box style={{ marginBottom: "200px" }}>
        <form onSubmit={form.onSubmit((values) => onSave(values))}>
          {user.avatar && (
            <Group mb={20}>
              <div
                style={{
                  width: 100,
                }}
              >
                <Image
                  radius={128}
                  src={
                    user.avatar && user.avatar.url
                      ? user.avatar.url
                      : DefaultAvatar
                  }
                  alt={user.username}
                />
              </div>
              {!user.avatar.url ||
              user.avatar.url.indexOf("assets/fallback") >= 0 ? (
                <Uploady
                  destination={{
                    url: `/api/v4/users/${user.id}/upload_avatar.json?auth_token=${window.$currentUserAuthToken}&locale=${window.$currentUserLanguage}`,
                  }}
                >
                  <CustomUploadButton />
                  <LogProgress onUploadCallback={onAvatarUploaded} />
                </Uploady>
              ) : (
                <Button
                  className={classes.primaryButtonStyle}
                  color="gray"
                  radius="xl"
                  mt={20}
                  onClick={() => onRemoveAvatar()}
                >
                  {t("settings.editProfile.removeProfileImage")}
                </Button>
              )}
            </Group>
          )}

          <TextInput
            classNames={{ label: classes.inputLabelStyle }}
            {...form.getInputProps("first_name")}
            label={t("settings.editProfile.firstName")}
            mb={20}
          />

          <TextInput
            classNames={{ label: classes.inputLabelStyle }}
            {...form.getInputProps("last_name")}
            label={t("settings.editProfile.lastName")}
            mb={20}
          />

          <TextInput
            classNames={{ label: classes.inputLabelStyle }}
            {...form.getInputProps("username")}
            label={t("settings.editProfile.username")}
            mb={20}
          />

          <TextInput
            classNames={{ label: classes.inputLabelStyle }}
            {...form.getInputProps("email")}
            label={t("settings.editProfile.email")}
            mb={20}
          />
          <Textarea
            classNames={{ label: classes.inputLabelStyle }}
            {...form.getInputProps("bio")}
            placeholder={t("settings.editProfile.bioPlaceholder")}
            label={t("settings.editProfile.bio")}
            autosize
            minRows={3}
            maxRows={8}
          />
          <Button
            className={classes.primaryButtonStyle}
            fullWidth
            mt="xl"
            radius="xl"
            size="md"
            mb={15}
            type="submit"
          >
            {t("settings.editProfile.updateProfile")}
          </Button>
        </form>
      </Box>
    </ScrollArea>
  );
}
