import React, { useState, useEffect, useRef } from 'react';
import {
  Text,
  Box,
  MantineProvider,
  Select,
  Button,
  TextInput,

  Group
} from '@mantine/core'

import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import BroadcastModel from '../../../../../models/Broadcast'

import ManagerModel from '../../../../../models/Manager'

import TiptapInline from '../../../../tiptap/components/TiptapInline'

export default function EditEmail(props) {

  // const [sendTo, setSendTo] = useState(null)
  // const [sendAs, setSendAs] = useState(null)
  const [broadcast, setBroadcast] = useState()
  const [managers, setManagers] = useState([])
  const { t, i18n } = useTranslation()
  const space = useSelector(state => state.space);
  const loaded = useRef(false)



  useEffect(() => {
    if (props.step){
      onFetch()
    }
  }, [props])

  const sendEmailTo = () => {
    return props.trigger === "interested" ? "interested" : props.trigger === "registered" ? "members" : "interested-members"
  }

  const form = useForm({
    initialValues: {
      title: "",
      content: "",
      content_json: "",
      send_as: "",
      send_email_to: sendEmailTo(),
      email_address: "",
      status: "journey_draft"
    },

    validate: {
      title: (value) => value.length > 0 ? null : "Write a title",
      content: (value) => value.length > 0 ? null : "Add some content",
    },
  });

  const onFetch = () => {

    form.setFieldValue("send_as", props.step.send_as)
    form.setFieldValue("send_email_to", props.step.send_email_to)
    form.setFieldValue("email_address", props.step.email_address)

    if (props.step.object_id) {
      BroadcastModel.onFetchById(props.step.object_id, space.slug, (data) => {
        loaded.current = true
        setBroadcast({...data})

        form.setFieldValue("title", data.title)
        form.setFieldValue("content", data.content)
        form.setFieldValue("content_json", data.content_json)
      }, (data) => {

      })
    }
    else{
      loaded.current = true
    }

    ManagerModel.onFetch(space.slug, {page: 1, per: 1000}, {scope: "", term: ""}, (data) => {
      const _managers = data.data.map((item, index) => (
        {
          value: `${item.user_id}`,
          label: `${item.full_name} (${item.username})`
        }
      ))
      setManagers(_managers)
    }, (data) => {
    })

  }

  const onSave = (status=null) => {
    const broadcast_params = {
      title: form.values.title,
      content: form.values.content,
      content_json: form.values.content_json,
      user_id: form.values.send_as,
      status: form.values.status
    }

    const step_params = {
      send_as: form.values.send_as,
      send_email_to: form.values.send_email_to,
      email_address: form.values.email_address
    }

    if (props.step && props.step.object_id){
      BroadcastModel.onUpdate(props.step.object_id, space.slug, broadcast_params, (data) => {
        // Swal.fire(data.title, data.message, "success")
        //
      }, (data) => {
        // Swal.fire(data.title, data.message, "error")
      })

      props.onUpdate(step_params)
    }
    else{
      BroadcastModel.onCreate(space.slug, broadcast_params, (data) => {
        const _step_params = { ...step_params, object_id: data.id }
        props.onUpdate(_step_params)
      }, (data) => {
        // Swal.fire(data.title, data.message, "error")
      })

    }
  }

  const onSend = () => {
    onSave("")
  }

  const onDestroy = (event) => {
    props.onDestroy(event)
  }

  const onContentUpdate = (content, html, callback, isEmpty) => {
    form.setFieldValue("content_json", content.content)
    form.setFieldValue("content", html)
  }

  if (!loaded.current){
    return null
  }

  return (
    <Box>
    <form
      onSubmit={form.onSubmit((values) => onSend(values))}>
      <MantineProvider
        theme={{ primaryShade: 6 }}
        >
          <TextInput
            {...form.getInputProps('title')}
            mt={30}
            mb={20}
            placeholder={t("spaces.settings.registration.steps.editEmail.placeholder1")}
            radius="sm"
            size="md"
            />

          <TiptapInline
            editable={true}
            content={broadcast ? broadcast.content_json : ""}
            modals={true}
            onUpdate={onContentUpdate}
            exclude={["video", "embed", "link-action", "link-format"]}/>

          <Text
            mt={10}
            size="xs"
            >
            {"Use {{first_name}}, {{last_name}}, {{start_date}}, {{space_name}}, {{space_link}}, and {{space_name_link}} to autopopulate the corresponding information"}
          </Text>

          <Select
            label={t("spaces.settings.registration.steps.editEmail.label1")}
            placeholder={t("spaces.settings.registration.steps.editEmail.placeholder2")}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
            mt={20}
            data={managers}
            {...form.getInputProps('send_as')}
          />


            <Select
              label={t("spaces.settings.registration.steps.editEmail.label2")}
              placeholder={t("spaces.settings.registration.steps.editEmail.placeholder3")}
              maxDropdownHeight={400}
              radius="sm"
              size="md"
              mt={20}
              data={[
                { value: sendEmailTo(), label: sendEmailTo().split("-").reverse().join(" or ").charAt(0).toUpperCase() + sendEmailTo().split("-").reverse().join(" or ").slice(1) },
                { value: 'managers', label: 'Managers' },
                { value: 'custom', label: 'Custom email address' },
              ]}
              {...form.getInputProps('send_email_to')}
            />


          { form.values.send_email_to == "custom" && (
            <TextInput
              mt={20}
              placeholder={t("spaces.settings.registration.steps.editEmail.placeholder4")}
              label={t("spaces.settings.registration.steps.editEmail.label3")}
              radius="sm"
              size="md"
              {...form.getInputProps('email_address')}
            />
          )}

        </MantineProvider>
        <Group
          position="apart"
        >
          <Button
            mt={25}
            radius="xl"
            // size="md"
            type="submit"
            leftIcon={<FontAwesomeIcon icon={solid('check')} />}
          >
            {t("spaces.settings.registration.steps.editEmail.saveButton")}
          </Button>
          <Button
            onClick={(event) => onDestroy(event)}
            mt={25}
            radius="xl"
            // size="md"
            color="dark"
            leftIcon={<FontAwesomeIcon icon={regular('trash-can')} />}
          >
            {t("spaces.settings.registration.steps.editEmail.deleteButton")}
          </Button>
        </Group>
        </form>
    </Box>
  )
}
