// import axios from 'axios';

class Backup {
  constructor() {
    if (this instanceof Backup) {
      throw Error('Payment class cannot be instantiated.');
    }
  }

  static onFetch = async (spaceId, type, paginator, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      type,
      ...paginator
    }

    await window.$api.get(`/api/v4/spaces/${spaceId}/backups.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }

  static onFetchById = async (id, spaceId, type, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      type
    }

    await window.$api.get(`/api/v4/spaces/${spaceId}/backups/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      // errorCallback(error.response.data)
    });
  }

}

export default Backup;
