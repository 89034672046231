import React from "react";
import { memo } from "react";
import { useSelector } from "react-redux";

import Content from "./Content";
import ContentReadonly from "./ContentReadonly";

const ContentContainer = memo(({ spaceId }) => {
  const mainActions = useSelector((state) => state.mainActions);

  return (
    <>
      {mainActions.editPage ? (
        <Content editable={true} />
      ) : (
        <ContentReadonly />
      )}
    </>
  );
});

export default ContentContainer;
