import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Progress,
  Text,
  Image,
  MantineProvider,
  createStyles,
  Modal,
  Loader,
  Center,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useItemProgressListener, useItemFinishListener } from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";

import SpaceModel from "../../../models/Space";
import PremiumModal from "../../app/PremiumModal";

import { setSpace } from "../../../store/app";

import tinycolor from "tinycolor2";

import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

import Swal from "sweetalert2";

const LogProgress = (props) => {
  const [progress, setProgress] = useState(0);

  useItemProgressListener((item) => {
    setProgress(item.completed);
  });
  useItemFinishListener((item) => {
    props.onUploadCallback(item.uploadResponse.data.url);
  });

  return (
    <>
      {progress > 0 && progress <= 100 ? (
        <Progress color="green" size="xl" mb={10} value={progress} />
      ) : (
        ""
      )}
    </>
  );
};

const CustomUploadHeaderButton = asUploadButton((props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="filled"
      radius="xl"
      leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
      {...props}
    >
      {t("spaces.colors.headerAndLogo.addCover")}
    </Button>
  );
});

const CustomUploadLogoButton = asUploadButton((props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="filled"
      radius="xl"
      leftIcon={<FontAwesomeIcon size="sm" icon={solid("star")} />}
      {...props}
    >
      {t("spaces.colors.headerAndLogo.changeLogo")}
    </Button>
  );
});

const useStyles = createStyles((theme, { colors }) => ({
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  appearanceButtonRoot: {
    color: theme.colors.gray[7],
    fontWeight: 400,
    fontSize: 16,
    borderRadius: 5,
    display: "block",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
    paddingLeft: 10,
  },
  appearanceButtonLeftIcon: {
    color: theme.colors.gray[7],
    width: 20,
    display: "flex",
    justifyContent: "center",
  },
  appearanceButtonInner: {
    justifyContent: "flex-start",
  },
}));

export default function HeaderAndLogo(props) {
  const { t } = useTranslation();
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const { classes, cx } = useStyles({ colors });

  const dispatch = useDispatch();

  const [opened, setOpened] = useState([]);
  const [canUploadCover, setCanUploadCover] = useState([]);
  const [canUploadLogo, setCanUploadLogo] = useState([]);

  const [isImgEditorHeaderShown, setIsImgEditorHeaderShown] = useState(false);
  const [isImgEditorLogoShown, setIsImgEditorLogoShown] = useState(false);
  const htmlImageHeader = useRef();
  const htmlImageLogo = useRef();
  const fileInputHeader = useRef();
  const fileInputLogo = useRef();

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setCanUploadCover(
      space && space.permissions && space.permissions.can_upload_cover
    );
    setCanUploadLogo(
      space && space.permissions && space.permissions.can_upload_logo
    );
  }, [space]);

  const handleFileInputHeader = (e) => {
    const file = e.target.files[0];

    if (!isFileValid(file, e.target)) {
      return;
    }

    htmlImageHeader.current = document.createElement("img");

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        htmlImageHeader.current.src = reader.result;
        openImgEditorHeader();
      });

      reader.readAsDataURL(file);
    } else {
      // Clear the value of the file input
      if (fileInputHeader.current) {
        fileInputHeader.current.value = "";
      }
    }
  };

  const handleFileInputLogo = (e) => {
    const file = e.target.files[0];

    if (!isFileValid(file, e.target)) {
      return;
    }

    htmlImageLogo.current = document.createElement("img");

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        htmlImageLogo.current.src = reader.result;
        openImgEditorLogo();
      });

      reader.readAsDataURL(file);
    } else {
      // Clear the value of the file input
      if (fileInputLogo.current) {
        fileInputLogo.current.value = "";
      }
    }
  };

  const openImgEditorHeader = () => {
    setIsImgEditorHeaderShown(true);
  };

  const closeImgEditorHeader = () => {
    setIsImgEditorHeaderShown(false);
    if (fileInputHeader.current) {
      fileInputHeader.current.value = "";
    }
  };

  const openImgEditorLogo = () => {
    setIsImgEditorLogoShown(true);
  };

  const closeImgEditorLogo = () => {
    setIsImgEditorLogoShown(false);
    if (fileInputLogo.current) {
      fileInputLogo.current.value = "";
    }
  };

  const isFileValid = (selectedFile, target) => {
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (selectedFile.size > maxSize) {
      Swal.fire("", "Your image must be less than 5MB", "info");
      target.value = ""; // Clear the selected file
      return false;
    }
    return true;
  };

  const onUploadHeader = (file) => {
    setUploading(true);
    SpaceModel.onUploadHeader(
      space.slug,
      file.imageBase64,
      (data) => {
        onCoverUploadFinished(data.url);
        setTimeout(() => {
          setUploading(false);
        }, 500);
      },
      (data) => {}
    );
  };

  const onUploadLogo = (file) => {
    setUploading(true);
    SpaceModel.onUploadLogo(
      space.slug,
      file.imageBase64,
      (data) => {
        onLogoUploadFinished(data.url);
        setTimeout(() => {
          setUploading(false);
        }, 500);
      },
      (data) => {}
    );
  };

  const onCoverUploadFinished = (url) => {
    const newSpace = { ...space };
    newSpace.header.url = url;
    dispatch(setSpace(newSpace));
    closeImgEditorHeader();
  };

  const onLogoUploadFinished = (url) => {
    const newSpace = { ...space };
    newSpace.logo.url = url;
    dispatch(setSpace(newSpace));
    closeImgEditorLogo();
  };

  const onRemoveHeader = () => {
    SpaceModel.onRemoveHeader(
      space.slug,
      (data) => {
        onCoverUploadFinished(null);
      },
      (data) => {}
    );
  };

  const onRemoveLogo = () => {
    SpaceModel.onRemoveLogo(
      space.slug,
      (data) => {
        onLogoUploadFinished(null);
      },
      (data) => {}
    );
  };

  const onOpenFileSelector = (event) => {
    try {
      event.target.children[0].click();
    } catch {}
  };

  return (
    <Box>
      {space && space.header && !space.header.url ? (
        <Box mb={20}>
          {canUploadCover[0] ? (
            <>
              <Button
                variant="filled"
                radius="xl"
                leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
                onClick={(event) => onOpenFileSelector(event)}
              >
                <input
                  ref={fileInputHeader}
                  type="file"
                  style={{ display: "none" }}
                  accept={"image/*"}
                  onChange={handleFileInputHeader}
                />
                {t("spaces.colors.headerAndLogo.addCover")}
              </Button>
              <Text size="xs" color="dimmed" mt={5}>
                {t("spaces.colors.headerAndLogo.dimensions")}
              </Text>
            </>
          ) : (
            <>
              <Button
                variant="filled"
                radius="xl"
                leftIcon={<FontAwesomeIcon size="sm" icon={solid("star")} />}
                onClick={() =>
                  setOpened([true, canUploadCover[1], canUploadCover[2]])
                }
              >
                {t("spaces.colors.headerAndLogo.addCover")}
              </Button>
              <Text size="xs" color="dimmed" mt={5}>
                {t("spaces.colors.headerAndLogo.dimensions")}
              </Text>
            </>
          )}
        </Box>
      ) : (
        <Box mb={20}>
          <Image src={space.header.url} />
          <MantineProvider theme={{ primaryShade: 6 }}>
            <Button
              mt={10}
              variant="filled"
              color="gray"
              radius="xl"
              leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
              onClick={() => onRemoveHeader()}
            >
              {t("spaces.colors.headerAndLogo.removeCover")}
            </Button>
          </MantineProvider>
        </Box>
      )}
      {space && space.header && !space.logo.url ? (
        <Box mt={10}>
          {canUploadLogo[0] ? (
            <>
              <Button
                variant="filled"
                radius="xl"
                leftIcon={<FontAwesomeIcon size="sm" icon={solid("image")} />}
                onClick={(event) => onOpenFileSelector(event)}
              >
                <input
                  ref={fileInputLogo}
                  type="file"
                  style={{ display: "none" }}
                  accept={"image/*"}
                  onChange={handleFileInputLogo}
                />
                {t("spaces.colors.headerAndLogo.changeLogo")}
              </Button>
              <Text size="xs" color="dimmed" mt={5}>
                {t("spaces.colors.headerAndLogo.dimensions2")}
              </Text>
            </>
          ) : (
            <>
              <Button
                variant="filled"
                radius="xl"
                leftIcon={
                  <FontAwesomeIcon
                    size="sm"
                    icon={solid("circle-half-stroke")}
                  />
                }
                onClick={() =>
                  setOpened([true, canUploadLogo[1], canUploadLogo[2]])
                }
              >
                {t("spaces.colors.headerAndLogo.changeLogo")}
              </Button>
              <Text size="xs" color="dimmed" mt={5}>
                {t("spaces.colors.headerAndLogo.dimensions2")}
              </Text>
            </>
          )}
        </Box>
      ) : (
        <Box mt={10}>
          <Image src={space.logo.url} />
          <MantineProvider theme={{ primaryShade: 6 }}>
            <Button
              mt={10}
              variant="filled"
              radius="xl"
              color="gray"
              leftIcon={<FontAwesomeIcon size="sm" icon={solid("star")} />}
              onClick={() => onRemoveLogo()}
            >
              {t("spaces.colors.headerAndLogo.removeLogo")}
            </Button>
          </MantineProvider>
        </Box>
      )}

      <PremiumModal opened={opened} setOpened={setOpened} />

      <Modal
        withCloseButton={false}
        size="xl"
        opened={isImgEditorHeaderShown}
        onClose={() => setIsImgEditorHeaderShown(false)}
        title={""}
        zIndex={999999}
      >
        {uploading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <FilerobotImageEditor
            source={htmlImageHeader.current}
            onSave={(editedImageObject, designState) =>
              onUploadHeader(editedImageObject, designState)
            }
            onClose={closeImgEditorHeader}
            Rotate={{ angle: 90, componentType: "slider" }}
            Crop={{
              ratio: 1200 / 300,
              ratioTitleKey: "Profile picture",
              noPresets: true,
              autoResize: true,
            }}
            defaultSavedImageQuality={0.7}
            defaultSavedImageName={"profileImage"}
            tabsIds={[TABS.ADJUST]} // or {['Adjust', 'Annotate', 'Watermark']}
            defaultTabId={TABS.ADJUST} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
            onBeforeSave={() => false}
          />
        )}
      </Modal>

      <Modal
        withCloseButton={false}
        size="xl"
        opened={isImgEditorLogoShown}
        onClose={() => setIsImgEditorLogoShown(false)}
        title={""}
        zIndex={999999}
      >
        {uploading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <FilerobotImageEditor
            source={htmlImageLogo.current}
            onSave={(editedImageObject, designState) =>
              onUploadLogo(editedImageObject, designState)
            }
            onClose={closeImgEditorLogo}
            Rotate={{ angle: 90, componentType: "slider" }}
            Crop={{
              ratio: 360 / 120,
              ratioTitleKey: "Profile picture",
              noPresets: true,
              autoResize: true,
              // lockCropAreaAt: 'top-left'
            }}
            defaultSavedImageQuality={0.7}
            defaultSavedImageName={"profileImage"}
            tabsIds={[TABS.ADJUST]} // or {['Adjust', 'Annotate', 'Watermark']}
            defaultTabId={TABS.ADJUST} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
            onBeforeSave={() => false}
          />
        )}
      </Modal>
    </Box>
  );
}
