import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import {
  createStyles,

  Group,
  Title,
  Text,

  Center,

  Container,
  Button,

  ScrollArea,

} from "@mantine/core";



import trackMixpanel from "../../utils/TrackMixpanel";
import VideoEmbed from "../utils/videoEmbed";

import PlanOptions from "./payment/PlanOptions";
import { setHiddenSidebar } from "../../store/app";

import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    maxWidth: 800,
    margin: "auto",
  },

  description: {
    margin: "auto",
    maxWidth: 600,
  },
}));

export default function Welcome() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { classes, theme } = useStyles();
  const [showPlans, setShowPlans] = useState(false);
  const user = useSelector((state) => state.user);
  const viewport = useRef();

  useEffect(() => {

    dispatch(setHiddenSidebar({ hidden: true }));

  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 500)
  }, [user])

  useEffect(() => {
    if (showPlans){
      setTimeout(() => {
        try {
          const $scrollTo = document.getElementById(`plans-texts`)
          const top = $scrollTo.offsetTop - viewport.current.offsetTop + viewport.current.scrollTop - 250

          viewport.current.scrollTo({ top: top, behavior: 'smooth' });
        } catch (error) {

        }
      }, 1000)
    }
  }, [showPlans])

  const onShowPlans = () => {
    setShowPlans(true);
  };

  return (
    <ScrollArea viewportRef={viewport} style={{ height: "100vh" }}>
      <Helmet>
        <title>{t("app.welcome.tabTitle")}</title>
      </Helmet>
      <Container py="xl" mb={100}>
        <Title order={2} className={classes.title} align="center" mb={10}>
          {t("app.welcome.title")}
        </Title>

        <Text
          color="dimmed"
          // className={classes.description}
          align="center"
          mb={20}
        >
          {t("app.welcome.description1")}
        </Text>

        <VideoEmbed service="vimeo" embedId="791138093?h=60f335d347" />

        <Text weight="600" align="center" mt={20} mb={20}>
          {t("app.welcome.description2")}
        </Text>

        <Center>
          <Group>
            <Button radius="xl" size="md" color="dark" component={Link} to="/app/getting_started">
            {t("app.welcome.no")}
            </Button>
            <Button
              radius="xl"
              size="md"
              variant={showPlans ? "outline" : "filled"}
              onClick={() => {
                trackMixpanel(user.username, "Plan options expanded")
                onShowPlans()
              }}>
              {t("app.welcome.yes")}
            </Button>
          </Group>
        </Center>

        {showPlans && (
          <>
            <Text
              color="dimmed"
              className={classes.description}
              align="center"
              mt={30}
              mb={20}
              id="plans-texts"
            >
              {t("app.welcome.description3")}
            </Text>

            <PlanOptions />
          </>
        )}
      </Container>
    </ScrollArea>
  );
}
