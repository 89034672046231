import React, { useState, useEffect, useRef } from "react";

import {
  Divider,
  Text,
  Table,
  Group,
  ScrollArea,
  Button,
  Pagination,
  Box,
  TextInput,
  Loader,
  SegmentedControl
} from "@mantine/core";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Row from "./PaymentRow";
import PaymentModel from "../../../../models/Payment";
import MemberModel from "../../../../models/Member";

const paginator = {
  page: 1,
  per: 30,
};

const loader = {
  loading: false,
  oldScope: "all",
};

export default function Payments(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  // const theme = useMantineTheme();
  const theme = props.theme;
  const { height, width } = useViewportSize();

  const [payments, setPayments] = useState({
    total: 0,
    page: 1,
    pages: 1,
    data: [],
  });
  const [scope, setScope] = useState(null);
  const [term, setTerm] = useState(null);
  const [counts, setCounts] = useState({
    all: 0,
    active: 0,
    pending: 0,
    managers: 0,
  });

  const largeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.lg}px)`
  );
  const extraLargeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );

  const [loading, setLoading] = useState(false);
  const timeoutId = useRef();

  const tableWidth = () => {
    if (largeScreen) {
      return width - 300;
    } else if (extraLargeScreen) {
      return width - 500;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
      onFetchCounts()
    }
  }, [space, scope]);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [term]);

  // useEffect(() => {
  //   if (props.refresh) {
  //     onFetch();
  //     props.setRefresh(false);
  //   }
  // }, [props]);

  const onFetchCounts = () => {
    MemberModel.onFetchCounts(
      space.slug,
      (data) => {
        setCounts(data);
      },
      (data) => {}
    );
  };

  const onFetch = () => {
    setLoading(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      PaymentModel.onFetch(
        space.slug,
        paginator,
        { scope, term },
        (data) => {
          setPayments(data);
          setLoading(false);
        },
        (data) => {
          setLoading(false);
        }
      );
    }, 1000);
  };

  const onKeyPress = (event) => {
    onKeyGeneral(event);
  };
  const onKeyDown = (event) => {
    onKeyGeneral(event);
  };

  const onKeyGeneral = (event) => {
    if (event.which == 13) {
      event.preventDefault();
      onSearch();
    }
  };

  const onSearch = () => {
    setTerm(document.getElementById("search-input").value);
  };

  const onClearSearch = () => {
    document.getElementById("search-input").value = "";
    setTerm("");
  };

  const changePage = (page) => {
    paginator.page = page;
    onFetch();
  };

  const onPaymentUpdate = () => {};

  return (
    <div>
      <Group position="apart" mt={20}>
        <Group position="left" spacing="xs">
          <TextInput
            icon={<FontAwesomeIcon icon={solid("search")} />}
            placeholder={t(
              "spaces.settings.members.payments.payment.searchPlaceholder"
            )}
            id="search-input"
            radius="xl"
            onKeyPress={(event) => onKeyPress(event)}
            onKeyDown={(event) => onKeyDown(event)}
            rightSection={
              term ? (
                <Text
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => onClearSearch()}
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </Text>
              ) : (
                <Text>{"  "}</Text>
              )
            }
          />
          <Button
            variant="filled"
            radius="xl"
            onClick={(event) => onSearch()}
            sx={{
              backgroundColor: theme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: theme.colors.sutrablue[4],
              },
            }}
          >
            {t("spaces.settings.members.payments.payment.searchButton")}
          </Button>
          {loading && <Loader size="xs" />}
        </Group>

        <Group position="right" spacing="xs">
        <SegmentedControl
          radius="xl"
          sx={(theme) => ({ backgroundColor: theme.colors.gray[0] })}
          data={[
            {
              label: `${counts.formatted_earnings}`,
              value: "all",
            },
          ]}
        />
      </Group>
      </Group>

      <ScrollArea mt={10} style={{ width: tableWidth() }}>
        {payments.data.length == 0 && !loader.loading ? (
          <Box
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              textAlign: "center",
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
              cursor: "pointer",

              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[5]
                    : theme.colors.gray[1],
              },
            })}
          >
            {t("spaces.settings.members.payments.payment.noRecordFound")}
          </Box>
        ) : (
          <Table verticalSpacing="xs" highlightOnHover>
            <thead>
              <tr>
                <th>
                  {t("spaces.settings.members.payments.paymentTable.member")}
                </th>
                <th>
                  {t("spaces.settings.members.payments.paymentTable.amount")}
                </th>
                <th>
                  {t("spaces.settings.members.payments.paymentTable.date")}
                </th>
                <th>
                  {t("spaces.settings.members.payments.paymentTable.status")}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {payments.data.map((payment) => {
                return (
                  <Row
                    spaceId={space.slug}
                    onUpdate={onPaymentUpdate}
                    payment={payment}
                  />
                );
              })}
            </tbody>
          </Table>
        )}
      </ScrollArea>
      <Divider sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: 20,
          justifyContent: "center",
        }}
      >
        <Pagination
          color="gray"
          page={paginator.page}
          onChange={changePage}
          total={payments.pages}
        />
      </Box>
    </div>
  );
}
