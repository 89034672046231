import React, { useState, useEffect } from "react";

import { Text, Box, TextInput, Divider, useMantineTheme } from "@mantine/core";

import { useTranslation } from "react-i18next";

import { useForm } from "@mantine/form";

import { useListState } from "@mantine/hooks";

import TiptapInline from "../../../tiptap/components/TiptapInline";

export default function CreateSurveyMain(props) {
  const [scheduled, setScheduled] = useState(false);

  const theme = useMantineTheme();

  // const [questions, setQuestions] = useState([])
  const [surveyId, setSurveyId] = useState(null);
  const { t, i18n } = useTranslation();
  const [questions, handlers] = useListState([]);
  const [currency, setCurrency] = useState(null);

  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      title: "",
      description: "",
      content: "",
    },

    validate: {
      title: (value) => (value.length > 0 ? null : "Write a title"),
    },
  });

  useEffect(() => {
    if (props.survey && props.survey.id) {
      setLoading(true);
      form.setFieldValue("title", props.survey.title);
      form.setFieldValue("description", props.survey.description);
      form.setFieldValue("content", props.survey.content);
      setSurveyId(props.survey.id);
    } else {
      if (!props.survey) {
        form.setFieldValue("title", "");
        form.setFieldValue("description", "");
        form.setFieldValue("content", "");
        setSurveyId(null);
        setCurrency(null);
      }
    }
  }, [props]);

  useEffect(() => {
    props.onUpdateForm(form.values);
    console.log("CHANGE");
  }, [form.values]);

  const onContentUpdate = (content, html) => {
    form.setFieldValue("content", content.content);
    form.setFieldValue("description", html);
  };

  return (
    <form onSubmit={form.onSubmit((values) => onSend(values))}>
      {!props.acceptPayments && (
        <TextInput
          {...form.getInputProps("title")}
          radius="sm"
          size="md"
          placeholder={t("spaces.settings.survey.createSurveyForm.form.title")}
          styles={{
            input: {
              // fontWeight: 700
            },
          }}
        />
      )}
      {props.acceptPayments && (
        <Text color="dimmed" mb={20}>
          {t("spaces.settings.survey.createSurveyForm.form.description")}
        </Text>
      )}

      <Box mt={20}>
        <TiptapInline
          editable={true}
          content={props.survey ? props.survey.content : ""}
          modals={true}
          onUpdate={onContentUpdate}
          exclude={["link-action"]}
          placeholder={t(
            "spaces.settings.survey.createSurveyForm.form.optionalDescription"
          )}
        />
      </Box>

      <Divider
        mt={15}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colors.gray[2]}`,
        })}
      />
    </form>
  );
}
