import React, { createContext } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { createStore } from "redux";
import { Provider } from "react-redux";
import appStore from "../store/app";
import { initPrototypesFunctions } from "../utils/Prototypes";
import { sutraTheme } from "../collections/sutraTheme";

import api from "../utils/AxiosInterceptor";

import App from "./App";
import "../i18n/i18n";
import actionCable from "actioncable";

import "./styles.css";

import { getHost, getEnv } from "../utils/Host";
import { setCookie } from "../utils/Cookies";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://caaaade4b7ce4b3ab930b30d4efd1b72@o337946.ingest.sentry.io/4504560035168256",
  debug: true,
  ignoreErrors: [
    // Ignore Safari browser errors
    'ResizeObserver loop limit exceeded',
    "Cannot read properties of undefined (reading \'nodeViewWrapper\')",
    "UnhandledRejection",
    "Non-Error promise rejection captured with value: undefined"
  ],
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,

  integrations: [new Sentry.Replay({block: ["iframe"]})],
  beforeSend(event, hint) {
    if (getEnv() == "development" || hint.originalException === "Timeout") return null;

    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
      });
    }
    return event;
  },
});

initPrototypesFunctions();

const CableApp = {};
CableApp.cable = actionCable.createConsumer(getHost("ws"));
export const ActionCableContext = createContext();

if (!window.$api){
  window.$api = api
}

export default function Index(props) {
  if (props.user) {
    const saveUser = {
      id: props.user.id,
      username: props.user.username,
      email: props.user.email,
      authentication_token: props.user.authentication_token,
      account_type: props.user.account_type,
    };
    setCookie("auth_user", btoa(JSON.stringify(saveUser)), 365 * 10);
    window.$currentUserAuthToken = props.user.authentication_token;
  }

  for (let i = 0; i < props.cookies.length; i++) {
    const cookie = props.cookies[i];
    setCookie(cookie.name, cookie.value, 365 * 10, "secure;");
  }

  window.$RAILS_ENV = { ...props.env };

  const myTheme = sutraTheme;

  const store = createStore(appStore);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <MantineProvider
            theme={myTheme}
            withGlobalStyles
            withNormalizeCSS
            defaultProps={{
              Container: {
                sizes: {
                  xs: 540,
                  sm: 720,
                  md: 801,
                  lg: 1140,
                  xl: 1320,
                },
              },
            }}
          >
            <ActionCableContext.Provider value={CableApp.cable}>
              <App />
            </ActionCableContext.Provider>
          </MantineProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}

// Pure REACT (NO RAILS)
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
