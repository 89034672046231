import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack, TextInput } from "@mantine/core";

export default function FileName(props) {
  const timeoutId = useRef(null);

  const loading = useRef(false);
  const { t, i18n } = useTranslation();
  const tiptap = useSelector((state) => state.tiptap);
  const [name, setName] = useState();

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newName = node.node.attrs.name ? name : null;

    if (newName) {
      tiptap.editor.commands.setCustomButtonAttr("name", newName);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        props.saveNodeData();
      }, 2000);
    }
  }, [name]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      setName(props.element.node.attrs.name);
    }
  }, [props.element]);

  return (
    <Stack mt={20}>
      <TextInput
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
        placeholder={t("tiptap.components.sidebar.fileName.placeholder")}
        label={t("tiptap.components.sidebar.fileName.label")}
        styles={{
          label: {
            fontWeight: 400,
            color: "#000",
          },
        }}
      />
    </Stack>
  );
}
