import React, { useState, useEffect } from "react";

import { Text, Drawer, ScrollArea, useMantineTheme } from "@mantine/core";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import BundlesForm from "../../spaces_neo/settings/payment/BundlesForm";

import { setTiptapSidemenu } from "../../../store/app";

import BundleSelection from "./BundleSelection";

export default function CreateBundle(props) {
  const theme = useMantineTheme();

  const tiptap = useSelector((state) => state.tiptap);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const space = useSelector((state) => state.space);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [survey, setSurvey] = useState();
  const [step, setStep] = useState(0);
  const colors = useSelector((state) => state.colors);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "createBundle") {
      setOpened(true);
      if (tiptapSidemenu.option && tiptapSidemenu.option.survey) {
        setStep(1);
      }
    } else {
      setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      if (tiptapSidemenu.opened == "createBundle") {
        dispatch(setTiptapSidemenu({ opened: null, option: null }));
      }
      setStep(0);
    }
  }, [opened]);

  const onCreate = (bundleId) => {
    if (bundleId == "new_bundle") {
      setStep(1);
      return;
    }
    onUpdate(bundleId);
  };

  const onUpdate = (bundleId) => {
    window.$reloadBundleId = bundleId;
    if (tiptapSidemenu.option && tiptapSidemenu.option.bundle) {
      tiptap.editor
        .chain()
        .focus()
        .updateAttributes("id", bundleId)
        .blur()
        .run();
    } else {
      tiptap.editor
        .chain()
        .focus()
        // .enter()
        .insertContent(
          `<actionbutton id="${space.slug}" radius="circle-radius" actionCallbackTarget="current" class="tiptap-size-actual" buttonBackgroundColor="${colors.primary_button_background_color}" buttonBorderColor="${colors.primary_button_background_color}" buttonTextColor="${colors.primary_button_text_color}" actionCallbackValue="/space/${space.slug}/gateway?bundles=${bundleId}" buttonText="Buy bundle" actionCallback="bundle"></actionbutton><p></p>`
        )
        .run();
    }

    setTimeout(() => {
      setOpened(false);
    }, 250);
  };

  return (
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {"New bundle button"}
        </Text>
      }
      padding="xl"
      size={600}
      overlayOpacity={0.1}
      zIndex={99999}
    >
      <ScrollArea style={{ height: "100%" }}>
        <Text color="dimmed">
          {"Bundles let you create a collection of spaces that can be purchased in one go.  A bundle button will link to a checkout page for the collection of spaces associated with the bundle."}
        </Text>
        {step == 0 ? (
          <BundleSelection onCreate={onCreate} mode="add" />
        ) : (
          <BundlesForm bundle={{}} spaceId={space.id} onUpdate={onUpdate} />
        )}
      </ScrollArea>
    </Drawer>
  );
}
