import React from 'react';
import {useEffect} from 'react';

import {createStyles } from '@mantine/core';

import { useDispatch } from 'react-redux';
import {setUser} from '../store/app';

import Header from './layout/Header'
import Body from './layout/Body'

import useAuthUser from '../utils/AuthUser'

import trackMixpanel from '../utils/TrackMixpanel'
import {isTouchDevice} from '../utils/IsTouchDevice'


const useStyles = createStyles((theme) => ({
  appShell: {
    minHeight: "100vh",
    boxSizing: "border-box"
  }
}
));

function App(props) {

  const { classes, cx, theme } = useStyles();

  const { authUser } = useAuthUser();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUser(authUser));
    if (authUser && authUser.username){
      trackMixpanel(authUser.username, "Site visited")
    }
    window.$isTouchDevice = isTouchDevice
  }, [])

  return (

    <div className={classes.appShell}>
      <Header />
      <Body />

    </div>

  );
}

export default App;
