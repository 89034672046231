import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import AuthModel from "../../models/Auth";

import { setHiddenSidebar } from "../../store/app";
import { useTranslation } from "react-i18next";

import SpaceReference from "./SpaceReference";
import PlanReference from "../app/payment/PlanReference";
import AffiliateData from "./AffiliateData";

import { setCookie } from "../../utils/Cookies";

import { Helmet } from "react-helmet";

import {
  TextInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Box,
  Divider,
  Grid,
  ScrollArea,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useMediaQuery } from "@mantine/hooks";

export default function Start(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = useLocation().search;
  const { t, i18n } = useTranslation();

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim())
          ? null
          : t("users.start.invalidEmailErrorMsg"),
    },
  });

  const [spaceReference, setSpaceReference] = useState();
  const [showSpaceReference, setShowSpaceReference] = useState(true);
  const [planReference, setPlanReference] = useState();
  const [providers, setProviders] = useState([]);

  const [dialog, setDialog] = useState({
    opened: false,
    title: "",
    message: "",
    color: "indigo",
  });

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 1000);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    const spaceReference = new URLSearchParams(queryParams).get("s");
    const planReference = new URLSearchParams(queryParams).get("p");

    setSpaceReference(spaceReference);
    setPlanReference(planReference);
  }, [queryParams]);

  const onStart = () => {
    const email = form.values.email.trim();
    AuthModel.onStart(
      email,
      spaceReference,
      (data) => {
        // Success callback
        // dispatch(setAlert({opened: true, title: data.title, message: data.message, color: "green"}))

        if (spaceReference) {
          navigate(
            `/user/sign_in?email=${email}&s=${spaceReference}&m=${data.data.is_member}`
          );
        } else if (planReference) {
          navigate(`/user/sign_in?email=${email}&p=${planReference}`);
        } else {
          navigate(`/user/sign_in?email=${email}`);
        }
      },
      (data) => {
        // Error callback
        // dispatch(setAlert({opened: true, title: data.title, message: data.message, color: "green"}))
        if (spaceReference) {
          navigate(`/user/sign_up?email=${email}&s=${spaceReference}`);
        } else if (planReference) {
          navigate(`/user/sign_up?email=${email}&p=${planReference}`);
        } else {
          navigate(`/user/sign_up?email=${email}`);
        }
      }
    );
  };

  const onProviderSignIn = (link, redirect_to) => {
    setCookie("sso_redirect_to", redirect_to, 365 * 10);
    window.location.href= link;
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{`Sutra - Start`}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <form onSubmit={form.onSubmit((values) => onStart(values))}>
                <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                  <Box pt={20} pb={5} pl={35} pr={35}>
                    <Title
                      sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                      order={1}
                    >
                      {t("users.start.title")}
                    </Title>
                    <Text size="lg" color="dimmed">
                      {t("users.start.subtitle")}
                    </Text>
                  </Box>
                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                  <Box pt={10} pb={25} pl={35} pr={35}>
                    <TextInput
                      {...form.getInputProps("email")}
                      label={t("users.start.email")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      type="submit"
                    >
                      {t("users.start.submit")}
                    </Button>
                    {providers.map((provider) => {
                      return (
                        <Button
                          fullWidth
                          mt="xl"
                          radius="xl"
                          size="md"
                          mb={15}
                          color="dark"
                          sx={(theme) => ({
                            backgroundColor: provider.button_color,
                            color: provider.button_text_color,
                            "&:hover": {
                              backgroundColor: provider.button_color,
                              color: provider.button_text_color,
                            },
                          })}
                          onClick={() => onProviderSignIn(provider.link, provider.redirect_to)}
                        >
                          {`Sign in with ${provider.button_label}`}
                        </Button>
                      );
                    })}
                  </Box>
                  <AffiliateData />
                </Paper>
              </form>
            </Container>
          </Grid.Col>
          {spaceReference && showSpaceReference && spaceReference != "zoom" && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                isMember={true}
                slug={spaceReference}
                setSpaceReference={setSpaceReference}
                setShowSpaceReference={setShowSpaceReference}
                setProviders={setProviders}
              />
            </Grid.Col>
          )}
          {planReference && (
            <Grid.Col xs={12} sm={6}>
              <PlanReference plan={planReference} />
            </Grid.Col>
          )}
        </Grid>
      </Container>
    </ScrollArea>
  );
}
