import React, { useState, useEffect, useRef } from 'react';

import {  useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import {
  Chips,
  Chip,
  createStyles
} from '@mantine/core';


const useStyles = createStyles((theme, _params, getRef) => ({
  chipChecked: {
    backgroundColor: `${theme.colors.dark[3]} !important`,
    color: theme.white,
    [`& .${getRef('iconWrapper')}`]: {
      color: theme.white,
    },
  },
  chipOutline3: {
    width: '80px',
    border: `1px solid ${theme.colors.dark[3]}`
  },
  chipOutline4: {
    border: `1px solid ${theme.colors.dark[3]}`
  },
  chipLabel: {
    textAlign: 'center',
    fontSize: 9,
    fontWeight: 800,
    color: theme.colors.dark[3],
    height: 26
  }
}
));

export default function Width(props) {

  const timeoutId = useRef(null)
  const { t, i18n } = useTranslation();
  const loading = useRef(false)

  const { classes, cx, theme } = useStyles();

  const tiptap = useSelector(state => state.tiptap);
  const [size, setSize] = useState('tiptap-size-100');

  useEffect(() => {

    if (!props.element || !props.element.node.attrs || (props.element && props.element.node.attrs && loading.current != props.element.node.attrs.uid)){
      return
    }

    const node = props.element
    const newClass = node.node.attrs.class ? {...node.node.attrs.class} : null

    if (node.node.type.name.indexOf("grid") < 0 && node.node.type.name != "column"){
      if (props.resizeable && size){
        const auxSize = size.replace("tiptap-size-", "")
        tiptap.editor.commands.updateAttributes(props.element.node.type.name, {
          resizeableWidth: `${auxSize}%`,
          resizeableHeight: "98%",
          resizeableParentHeight: "unset"
        });
        // resizeableParentHeight: auxSize == "100" ? props.grid ? "200px" : "400px" : "unset"
      }
      else{
        tiptap.editor.commands.setCustomSize(size)
      }
    }
    else{
      const {tr} = tiptap.editor.view.state
      tr.setNodeMarkup(node.pos, undefined, {
        ...node.node.attrs,
        class: size,
      })
      tiptap.editor.view.dispatch(tr)
    }

    if (timeoutId.current){
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      timeoutId.current = null
      props.saveNodeData()
    }, 2000)
    
  }, [size])

  useEffect(() => {
    if (props.element && props.element.node.attrs){
      loading.current = props.element.node.attrs.uid
      if (props.resizeable && size){
        const auxSize = props.element.node.attrs.resizeableWidth == "100%" ? "tiptap-size-100" : props.element.node.attrs.resizeableWidth == "75%" ? "tiptap-size-75" : props.element.node.attrs.resizeableWidth == "50%" ? "tiptap-size-50" : null
        // if (auxSize){
          setSize(auxSize)
        // }
      }
      else{
        setSize(props.element.node.attrs.class ? props.element.node.attrs.class : null)
      }
    }
  }, [props.element])

  return (

    <Chips
      mt={10}
      color='dark'
      radius="sm"
      size="sm"
      multiple={false}
      value={size}
      onChange={setSize}
      sx={{
        gap: 4,
        width: '100%'
      }}
      classNames={{
        checked: classes.chipChecked,
        iconWrapper: classes.iconWrapper,
        outline: classes.chipOutline4,
        label: classes.chipLabel
      }}
      >
      <Chip value="tiptap-size-100">{t("tiptap.components.sidebar.width.full")}</Chip>
      <Chip value="tiptap-size-75">{t("tiptap.components.sidebar.width.size")}</Chip>
      <Chip value="tiptap-size-50">{t("tiptap.components.sidebar.width.size2")}</Chip>
      <Chip value="tiptap-size-actual">{t("tiptap.components.sidebar.width.actual")}</Chip>
    </Chips>

  )

}
