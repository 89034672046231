import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { setAlert } from "../../../store/app";

import StripeForm from "./StripeForm";

import StripeModel from "../../../models/SutraStripe";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

import { Text, Box } from "@mantine/core";

export default function PaymentMethod(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [stripePromise, setStripePromise] = useState();
  const [stripeOptions, setStripeOptions] = useState({ clientSecret: "" });
  const [returnUrl, setReturnUrl] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    onFetchKeys();
  }, []);

  const onFetchKeys = () => {
    StripeModel.onFetchKeys(
      (data) => {
        console.log("success");
        console.log(data);
        const appearance = {
          theme: "stripe",
        };

        setStripePromise(loadStripe(data.publishable_key));
        setStripeOptions({
          clientSecret: data.client_secret,
          appearance: appearance,
        });
        setReturnUrl(data.return_url);

        setPaymentMethods([...data.payment_methods]);
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onAttachPaymentMethod = (pm_id) => {
    StripeModel.onAttachPaymentMethod(
      pm_id,
      (data) => {
        Swal.fire(
          t("settings.billing.updatePayment.success.title"),
          t("settings.billing.updatePayment.success.message"),
          "success"
        );
        props.setOpenedPaymentMethod(false);
      },
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onChangePayment = async (stripe, elements) => {
    console.log(elements);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement("card"),
    });

    if (result.error) {
      Swal.fire(
        t("settings.billing.updatePayment.error.title"),
        result.error.message,
        "error"
      );
    } else {
      onAttachPaymentMethod(result.paymentMethod.id);
    }
  };

  return (
    <Box pb={5}>
      {stripePromise && stripeOptions ? (
        <Elements stripe={stripePromise} options={stripeOptions}>
          {paymentMethods && paymentMethods.length > 0 ? (
            <Text weight={600} mb={20}>
              {`${t(
                "settings.billing.paymentMethod.currentPaymentMethod"
              )}: ...${paymentMethods[0].data[0].card.last4}`}
            </Text>
          ) : (
            <Text weight={600} mb={20}>
              {t("settings.billing.paymentMethod.noCurrentPaymentMethod")}
            </Text>
          )}

          <Text color="dimmed" mb={10}>
            {t("settings.billing.paymentMethod.addNewPaymentMethod")}
          </Text>

          <StripeForm onChangePayment={onChangePayment} />
        </Elements>
      ) : (
        ""
      )}
    </Box>
  );
}
