import React, { useState, useEffect } from 'react';
import {

  Box,

  Button,

} from '@mantine/core'


import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import StructureModel from '../../../../../models/Structure';

import Broadcaster from "../../../../../helpers/Broadcaster";


export default function EditStructure(props) {

  const [html, setHtml] = useState("")

  const { t, i18n } = useTranslation();
  const space = useSelector(state => state.space);
  const [structureId, setStructureId] = useState(0)

  useEffect(() => {
    onFetch()
  }, [])

  const onFetch = () => {
    if (props.step && props.step.object_id) {
      setStructureId(props.step.object_id)
    } else {
      const structure_params = { title: "New structure" }

      StructureModel.onCreate(space.slug, structure_params, (data) => {
        setStructureId(data.id)
        props.onUpdate({ object_id: data.id }, false)
      }, (data) => {
        // Swal.fire(data.title, data.message, "error")
      })
    }

    Broadcaster.receive("settings_closeModal", document.body, () => {
      props.onUpdate()
    })

    return (() => {
      Broadcaster.unRegisterElement("settings_closeModal", document.body)
    })
  }

  const onDestroy = (event) => {
    props.onDestroy(event)
  }

  return (
    <div>
      <Box mt={20} />
      {props.step && structureId && structureId !== 0 ? (
        <iframe
          src={`/structures/${structureId}/edit?auth_token=${window.$currentUserAuthToken}&pod_slug=${space.slug}`}
          style={{height: "calc(100vh - 180px)", border: "none", width: "100%"}}/>
      ) : (
        <iframe
          src={`/circle/${space.slug}/structures/new?auth_token=${window.$currentUserAuthToken}`}
          style={{height: "calc(100vh - 180px)", border: "none", width: "100%"}}/>
      )}
      <Button
        onClick={(event) => onDestroy(event)}
        radius="xl"
        // size="md"
        color="dark"
        leftIcon={<FontAwesomeIcon icon={regular('trash-can')} />}
        fullWidth
      >
        {t("spaces.settings.registration.steps.editStructure.deleteButton")}
      </Button>
    </div>
  )

}
