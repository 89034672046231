import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setHiddenSidebar } from "../../../../store/app";

import HeaderImage from "../../HeaderImage";

import FixedHeader from "../../FixedHeader";

import {
  Container,
  Stack,
  ScrollArea,
  Text,
  Button,
  createStyles,
} from "@mantine/core";

import InvitationModel from "../../../../models/Invitation";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
}));

export default function Pending(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const colors = useSelector((state) => state.colors);

  const { classes, cx, theme } = useStyles({ colors });

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    // debugger;
    b.style.display = "none";

    dispatch(setHiddenSidebar({ hidden: true }));

    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 500);

    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  const onAcceptInvitation = () => {
    const slug = new URLSearchParams(location.search).get("slug");

    InvitationModel.onAccept(
      slug,
      props.space.slug,
      (data) => {
        navigate(
          `/space/${props.space.slug}/${props.space.version}?refresh=true`
        );
      },
      (data) => {}
    );
  };

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea className="content-scrollview" style={{ height: "100%" }}>
        <HeaderImage />
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={["breadcrumbs", "title"]}
        />
        <Container
          sx={(theme) => ({
            height: "100%",
          })}
        >
          <Text>{t("spaces.presentations.static.invitation.message")}</Text>
          <Button onClick={() => onAcceptInvitation()}>
            {t("spaces.presentations.static.invitation.button")}
          </Button>
        </Container>
      </ScrollArea>
    </Stack>
  );
}
