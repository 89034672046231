import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box,
        Text,
        Button,
        Group,
        Paper,
        createStyles,
      } from '@mantine/core';

import {setNewMessage} from "../../../../store/app";

import tinycolor from 'tinycolor2'

import { useTranslation } from "react-i18next";


const useStyles = createStyles((theme, {colors}, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color
  },
  textColor: {
    color: colors.sidebar_text_color
  },
  buttonColor: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  }
}));

export default function PinMessage(props) {

  const { t, i18n } = useTranslation();

  const colors = useSelector(state => state.colors);
  const dispatch = useDispatch()
  const { classes, cx, theme } = useStyles({colors});

  const onScrollToBottom = () => {
    setTimeout(() => {
      if (props.newMessage && props.newMessage.viewport){
        const top = props.newMessage.viewport.scrollHeight
        props.newMessage.viewport.scrollTo({ top: top});
      }
    }, 500)
    dispatch(setNewMessage({show: false}))
  }

  return (
    <Paper
      radius={10}
      mt={10}
      mb={0}
      pt={12} pb={12} pl={20} pr={20}
      className={classes.panelWrap}
      >
      <Group
        position="apart"
        >
        <Text
          weight={500}
          className={classes.textColor}>
          {t("spaces.presentations.message.unreadMessagesText")}
        </Text>
        <Box>
        <Button
          radius="xl"
          size="xs"
          className={classes.buttonColor}
          // leftIcon={
          //   <FontAwesomeIcon icon={solid('message')} />
          // }
          onClick={() => onScrollToBottom()}>
          {t("spaces.presentations.message.scrollDownButton")}
        </Button>
        </Box>
      </Group>
    </Paper>
  );

}
