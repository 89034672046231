import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Stack, NativeSelect } from "@mantine/core";

import SpaceModel from "../../../../models/Space";

export default function DisplayAs(props) {
  const location = useLocation();
  const timeoutId = useRef(null);

  const loading = useRef(false);

  const tiptap = useSelector((state) => state.tiptap);
  const [displayAs, setDisplayAs] = useState("link");

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;
    const newDisplayAs = displayAs;

    let edited = false;

    if (newDisplayAs) {
      edited = true;

      if (newDisplayAs == "card" && props.element.node.attrs.link && props.element.node.attrs.link.url){
        tiptap.editor.chain().updateAttributes(props.element.node.type.name, {
          viewAs: newDisplayAs,
        }).run();
      }
      else if (newDisplayAs == "card"){
        onFetchLink()
      }
      else{
        tiptap.editor.chain().updateAttributes(props.element.node.type.name, {
          viewAs: newDisplayAs,
        }).setTextAlign("left").run();
      }

      
    }

    if (edited) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        if (displayAs) {
          props.saveNodeData();
        }
      }, 2000);
    }
  }, [displayAs]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.viewAs) {
      loading.current = props.element.node.attrs.uid;
      setDisplayAs(props.element.node.attrs.viewAs);
    }
  }, [props.element]);

  const transformVimeoLink = (link) => {
    // Extract the video ID and the part after the "?h="
    const match = link.match(/\/(\d+)\?h=(\w+)/);
  
    if (match && match.length === 3) {
      const videoID = match[1];
      const hashPart = match[2];
      const transformedLink = `https://vimeo.com/${videoID}/${hashPart}`;
      return transformedLink;
    }
  
    // Return original link if the pattern doesn't match
    return link;
  }

  const onFetchLink = () => {
    if (props.element.node.type.name == "embed"){
      let link = "";
      let auxVideoId = props.element.node.attrs.videoId

      if (props.element.node.attrs.src.indexOf("youtube") >= 0){
        link = `https://www.youtube.com/watch?v=${auxVideoId}`
      }
      else if (props.element.node.attrs.src.indexOf("vimeo") >= 0){
        auxVideoId = props.element.node.attrs.src.split("/").pop()
        link = transformVimeoLink(`https://vimeo.com/${auxVideoId}`)
      }
      else if (props.element.node.attrs.src.indexOf("loom") >= 0){
        auxVideoId = props.element.node.attrs.src.split("/").pop()
        link = `https://www.loom.com/share/${auxVideoId}`
      }
      
      SpaceModel.onFetchLink(
        link,
        (data) => {
          tiptap.editor.chain().updateAttributes(props.element.node.type.name, {
            viewAs: "card",
            link: {
              url: link,
              target: "_modal",
              title: data.data.title,
              description: data.data.description,
              image: {
                url: data.data.image.url,
              },
            },
          }).setTextAlign("left").run();
  
        },
        (data) => {
          
          tiptap.editor.chain().updateAttributes(props.element.node.type.name, {
            viewAs: "card",
            link: {
              url: link,
              target: "_modal",
              title: "Embeded video",
              description: "",
              image: {
                url: null,
              },
            },
          }).setTextAlign("left").run();
          
        }
      );
    }
    else{

      const thumbnail = `${props.element.node.attrs.src
        .replace(".m3u8", "")
        .replace(
          "sutra-video-output.s3.us-west-1.amazonaws.com",
          "sutra-video-thumbnails.s3.us-west-1.amazonaws.com/thumbnails"
        )}_00001.png`;

      tiptap.editor.chain().updateAttributes(props.element.node.type.name, {
        viewAs: "card",
        link: {
          url: props.element.node.attrs.src,
          target: "_modal",
          title: "Direct upload",
          description: "",
          image: {
            url: thumbnail,
          },
        },
      }).setTextAlign("left").run();
    }
  }

  return (
    <Stack mt={20}>
      <NativeSelect
        value={displayAs}
        onChange={(event) => setDisplayAs(event.target.value)}
        label="Display as"
        placeholder="Select a display option"
        data={[
          { value: "inline", label: "Embed" },
          { value: "card", label: "Card" },
        ]}
        styles={{
          label: {
            fontWeight: 400,
            color: "#000",
          },
        }}
      />
    </Stack>
  );
}
