import { Node, mergeAttributes } from "@tiptap/core";

import { checkInteractionAndHideIfNeeded, setUniqId} from "../utils/selection";

const ColumnNode = Node.create({
  name: "column",
  content: "columnable+",
  group: "inmobible",
  defining: true,
  selectable: false,
  draggable: false,
  allowGapCursor: true,
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addAttributes() {
    return {
      circle_id: {
        default: null,
      },
      size: {
        default: 6,
      },
      position: {
        default: 0,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "column",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "column",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("div");
      const attributes = mergeAttributes(HTMLAttributes, {
        "data-type": this.name,
        draggable: false,
      });

      dom.classList.add(`c-grid-md-${attributes.size}`);
      dom.classList.add(`c-grid-col`);
      dom.classList.add(`col-${attributes.position}`);
      dom.draggable = false;

      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );

      const content = document.createElement("div");
      content.draggable = false;
      content.style.width = "100%";
      dom.append(content);

      dom.addEventListener("click", () => {});

      const actions = document.createElement("div");
      actions.classList.add("tiptap-block-actions");
      actions.draggable = false;

      dom.append(actions);
      //dom.append(`c-grid-md-${attributes.size}`)

      checkInteractionAndHideIfNeeded(editor, dom, getPos, "flex");

      //setUniqId(editor, node, getPos, dom);

      const auxPos = getPos();
      setTimeout(() => {
        try {
          const hasCol0 = $(dom).hasClass("col-0");

          const isSibling8Size = $(dom).siblings().hasClass("c-grid-md-8");
          const isSibling4Size = $(dom).siblings().hasClass("c-grid-md-4");
          const isGrid3 = dom.parentNode.children.length == 3;
          const currentPosInParent = [].indexOf.call(
            dom.parentNode.children,
            dom
          );

          let fixed = false;

          // console.log("TRYING TO FIX COLUMNS: " + currentPosInParent);
          if (
            (isSibling8Size && node.attrs.size != 4) ||
            (isGrid3 && node.attrs.size != 4)
          ) {
            const { tr } = editor.view.state;
            tr.setNodeMarkup(auxPos, undefined, {
              ...node.attrs,
              size: 4,
              position: currentPosInParent + 1,
            });
            editor.view.dispatch(tr);
            fixed = true;
          }

          if (isSibling4Size && node.attrs.size != 8 && !isGrid3) {
            const { tr } = editor.view.state;
            tr.setNodeMarkup(auxPos, undefined, {
              ...node.attrs,
              size: 8,
              position: currentPosInParent + 1,
            });
            editor.view.dispatch(tr);
            fixed = true;
          }

          if (!fixed && hasCol0) {
            const { tr } = editor.view.state;
            tr.setNodeMarkup(auxPos, undefined, {
              ...node.attrs,
              position: currentPosInParent + 1,
            });
            editor.view.dispatch(tr);
          }
        } catch (e) {}
      }, 2000);

      return {
        dom,
        contentDOM: content,
      };
    };
  },
});

export default ColumnNode;
