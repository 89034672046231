import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "add-to-calendar-button/assets/css/atcb.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Box, Text, Paper, Group, Button, createStyles } from "@mantine/core";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../utils/IsSpaceThemed";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
  },
  textColor: {
    color: colors.sidebar_text_color,
  },
  buttonColor: {
    border: isSpaceThemed(colors)
      ? `1px solid ${colors.sidebar_text_color}`
      : `1px solid ${colors.primary_button_background_color}`,
    color: isSpaceThemed(colors)
      ? colors.sidebar_text_color
      : colors.primary_button_background_color,
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).lighten(5).toString()
        : tinycolor(colors.sidebar_background_color).darken(1).toString(),
    },
  },
}));

export default function TagFilter(props) {
  const space = useSelector((state) => state.space);
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const onReset = (event) => {
    navigate(`/space/${space.slug}/${space.version}`);
  };

  return (
    <Paper
      radius={10}
      mt={15}
      mb={10}
      pt={12}
      pb={12}
      pl={20}
      pr={20}
      className={classes.panelWrap}
    >
      <Group position="apart">
        <Text weight={500} className={classes.textColor}>
          {`Messages tagged with ${props.tagName}`}
        </Text>
        <Box>
          <Button
            radius="xl"
            size="xs"
            variant="outline"
            className={classes.buttonColor}
            leftIcon={<FontAwesomeIcon icon={solid("xmark")} />}
            onClick={() => onReset()}
          >
            {t("spaces.tagFilter.resetButton")}
          </Button>
        </Box>
      </Group>
    </Paper>
  );
}
