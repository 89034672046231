import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import {
  Button,
  Box,
  Group,
  Image,
  Text,
  Stack,
  Container,
  Grid,
  Title,
  MantineProvider,
  createStyles,
  ScrollArea,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import UserModel from "../../models/User";
import DirectMessageModel from "../../models/DirectMessage";
import SpaceModel from "../../models/Space";
import DefaultAvatar from "../../src/images/fallback/default.png";
import SpaceListItem from "../spaces_neo/presentations/List";

import { setHiddenSidebar } from "../../store/app";

import { Helmet } from "react-helmet";

const useStyles = createStyles((theme) => ({
  userInfoWrap: {
    backgroundColor: theme.colors.gray[0],
    borderRadius: 10,
    padding: 40,
    paddingTop: 30,
    paddingBottom: 30,
  },
  userInfoMediumScreen: {
    justifyContent: "center",
  },
  avatarWrapMediumScreen: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  avatarMediumScreen: {
    width: 200,
    marginRight: 0,
  },
  avatar: {
    width: 300,
    marginRight: 30,
  },
  userDetailsWrap: {
    alignSelf: "center",
  },
  nameWrapMediumScreen: {
    justifyContent: "center",
  },
  nameWrap: {
    justifyContent: "flex-start",
  },
}));

const paginator = {
  per: 30,
  page: 1,
};

export default function Profile(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { classes, theme } = useStyles();
  const smallScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.sm}px)`
  );
  const mediumScreen = useMediaQuery(`(max-width: 768px)`);
  const largeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.lg}px)`
  );
  const extraLargeScreen = useMediaQuery(
    `(max-width: ${theme.other.breakpoints.xl}px)`
  );

  const user = useSelector((state) => state.user);
  const [profileUser, setProfileUser] = useState({});
  const [spaces, setSpaces] = useState([]);
  const [showAllSpaces, setShowAllSpaces] = useState(false);

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 1000);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    console.log(mediumScreen);
  }, [mediumScreen]);

  useEffect(() => {
    if (params.username) {
      onFetch();
      onFetchSpaces();
    }
  }, [params]);

  const onFetch = () => {
    UserModel.onFetch(
      params.username,
      (data) => {
        setProfileUser(data);
      },
      (data) => {}
    );
  };

  const onFetchSpaces = () => {
    const ctrl = new AbortController();
    SpaceModel.onFetchByUser(
      params.username,
      ["members"],
      paginator,
      (data) => {
        if (paginator.page == 1) {
          setSpaces(data);
        } else {
          setSpaces(spaces.concat(data));
        }
      },
      (data) => {},
      ctrl.signal
    );
  };

  const onDirectMessage = () => {
    if (!user.authenticated) {
      navigate(`/user/start`);
      return;
    }
    DirectMessageModel.onFetchOrCreate(
      profileUser.id,
      (data) => {
        navigate(`/space/${data.space.slug}/discussion`);
      },
      (data) => {}
    );
  };

  if (!user) {
    return null;
  }

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>
          {profileUser.id
            ? `Sutra - ${profileUser.username}`
            : `Sutra - Dashboard`}
        </title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }} mt={20}>
        {/*<MantineProvider
          theme={{ primaryShade: 6 }}>
          <Button
          sx={(theme) => ({
          width: 200,
          '&:hover': {
          backgroundColor: theme.colors.gray[0]
          }
          })}
          variant="outline"
          radius="xl"
          size="sm"
          color="gray"
          mb={20}
          leftIcon={<FontAwesomeIcon icon={solid('arrow-left')} />}
          >
          Return to space
          </Button>
          </MantineProvider>*/}

        <Box className={classes.userInfoWrap}>
          <Group
            noWrap={mediumScreen ? false : true}
            className={mediumScreen ? classes.userInfoMediumScreen : ""}
          >
            <Box className={mediumScreen ? classes.avatarWrapMediumScreen : ""}>
              <Image
                className={
                  mediumScreen ? classes.avatarMediumScreen : classes.avatar
                }
                radius={300}
                src={
                  profileUser.avatar ? profileUser.avatar.url : DefaultAvatar
                }
                alt={profileUser.username}
              />
            </Box>
            <Box className={classes.userDetailsWrap}>
              <Stack spacing={0} align={mediumScreen ? "center" : "flex-start"}>
                <Group
                  spacing={0}
                  mb={10}
                  className={
                    mediumScreen
                      ? classes.nameWrapMediumScreen
                      : classes.nameWrap
                  }
                >
                  <Title
                    sx={(theme) => ({
                      fontWeight: 900,
                      marginRight: 10,
                    })}
                  >
                    {profileUser.full_name}
                  </Title>
                  <Text color="dimmed" size="xl">
                    @{profileUser.username}
                  </Text>
                </Group>
                {profileUser && user.id != profileUser.id ? (
                  <Button
                    mt={10}
                    sx={(theme) => ({
                      width: 200,
                    })}
                    radius="xl"
                    size="md"
                    onClick={() => onDirectMessage()}
                  >
                    {t("users.profile.button")}
                  </Button>
                ) : (
                  <MantineProvider theme={{ primaryShade: 6 }}>
                    <Button
                      mt={10}
                      color={"gray"}
                      sx={(theme) => ({
                        width: 200,
                      })}
                      radius="xl"
                      size="md"
                      component={Link}
                      to="/settings/profile"
                    >
                      {t("users.profile.button2")}
                    </Button>
                  </MantineProvider>
                )}
              </Stack>
            </Box>
          </Group>
        </Box>

        {profileUser.bio && profileUser.bio.length > 0 ? (
          <Text mt={30}>{profileUser.bio}</Text>
        ) : (
          ""
        )}

        {spaces && spaces.length > 0 ? (
          <>
            <Group
              position="apart"
              mt={30}
              mb={15}
              pb={10}
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.colors.gray[2]}`,
              })}
            >
              <div>
                <Title order={2} sx={(theme) => ({ fontWeight: 900 })}>
                  {t("users.profile.title")}
                </Title>
              </div>
              <MantineProvider theme={{ primaryShade: 8 }}>
                <Button
                  variant="subtle"
                  color="gray"
                  radius="xl"
                  onClick={() => setShowAllSpaces(showAllSpaces ? false : true)}
                  className={{}}
                >
                  {spaces.length > 6
                    ? showAllSpaces
                      ? "See less"
                      : `See all`
                    : ""}
                </Button>
              </MantineProvider>
            </Group>
            <Box mb={60}>
              <Grid>
                {spaces
                  .slice(0, showAllSpaces ? spaces.length : 6)
                  .map((space) => {
                    return (
                      <Grid.Col sm={12} md={6}>
                        <SpaceListItem
                          key={`space-list-item-${space.slug}`}
                          space={space}
                        />
                      </Grid.Col>
                    );
                  })}
              </Grid>
            </Box>
          </>
        ) : (
          ""
        )}
        <Box sx={{height: "100px"}}></Box>
      </Container>
    </ScrollArea>
  );
}
