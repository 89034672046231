import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setAlert } from "../../store/app";
import {
  Button,
  Title,
  TextInput,
  Container,
  Textarea,
  Image,
  Progress,
  Divider,
  Grid,
  ScrollArea,
  Modal,
} from "@mantine/core";
import {
  useItemProgressListener,
  useItemFinishListener,
} from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";
import DefaultAvatar from "../../src/images/fallback/default.png";
import UserModel from "../../models/User";
import { useTranslation } from "react-i18next";

import { useForm } from "@mantine/form";
import useAuthUser from "../../utils/AuthUser";

import { Helmet } from "react-helmet";

import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

const LogProgress = (props) => {
  const [progress, setProgress] = useState(0);

  useItemProgressListener((item) => {
    setProgress(item.completed);
  });
  useItemFinishListener((item) => {
    props.onUploadCallback(item.uploadResponse.data);
  });

  return progress > 0 && progress < 100 ? (
    <Progress color="green" size="xl" mb={10} value={progress} />
  ) : (
    ""
  );
};

const CustomUploadButton = asUploadButton((props) => {
  const { t, i18n } = useTranslation();

  return (
    <Button {...props} variant="default" color="green" radius="xl" mt={20}>
      {t("settings.editProfile.addProfileImage")}
    </Button>
  );
});

export default function Profile() {
  const { t, i18n } = useTranslation();

  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  const htmlImage = useRef();
  const fileInput = useRef();

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { setAuthUser } = useAuthUser();

  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      bio: "",
      // location: ""
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  useEffect(() => {
    initFormData();
  }, [user]);

  const initFormData = () => {
    form.setFieldValue("first_name", user.first_name);
    form.setFieldValue("last_name", user.last_name);
    form.setFieldValue("email", user.email);
    form.setFieldValue("username", user.username);
    form.setFieldValue("bio", user.bio);
  };

  const onUploadAvatar = (file) => {
    UserModel.onUploadAvatar(
      user.id,
      file.imageBase64,
      (data) => {
        onAvatarUploaded(data);
      },
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onAvatarUploaded = (data) => {
    closeImgEditor();
    dispatch(
      setAlert({
        opened: true,
        title: data.title,
        message: data.message,
        color: "green",
      })
    );

    // const newUser = { ...user };
    // newUser.avatar.url = data.url;
    // setAuthUser(newUser);
    // dispatch(setUser(newUser));
  };

  const onRemoveAvatar = () => {
    UserModel.onRemoveAvatar(
      user.id,
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );

        // const newUser = { ...user };
        // newUser.avatar.url = null;
        // setAuthUser(newUser);
        // dispatch(setUser(newUser));
      },
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onSave = (values) => {
    UserModel.onUpdate(
      user.id,
      {
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.username,
        bio: values.bio,
        email: values.email,
      },
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );
        // const newUser = { ...user };

        // user.first_name = values.first_name;
        // user.last_name = values.last_name;
        // user.username = values.username;
        // user.bio = values.bio;
        // user.email = values.email;
        // newUser.forceUpdate = true;
        // setAuthUser(newUser);
        // dispatch(setUser(newUser));
      },
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onOpenFileSelector = (event) => {
    try {
      event.target.children[0].click();
    } catch {}
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    htmlImage.current = document.createElement("img");

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        htmlImage.current.src = reader.result;
        openImgEditor();
      });

      reader.readAsDataURL(file);
    } else {
      // Clear the value of the file input
      if (fileInput.current) {
        fileInput.current.value = "";
      }
    }
  };

  const openImgEditor = () => {
    setIsImgEditorShown(true);
  };

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  if (!user) {
    return null;
  }

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{t("settings.editProfile.tabTitle")}</title>
      </Helmet>
      <Container
        style={{ marginBottom: "50px" }}
        size={1000}
        pt={20}
        pb={40}
        pl={40}
        pr={40}
      >
        <form onSubmit={form.onSubmit((values) => onSave(values))}>
          <Title sx={(theme) => ({ fontWeight: 900 })}>
            {t("settings.editProfile.editProfile")}
          </Title>

          <Divider
            sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
            mt={10}
            mb={20}
          />

          <Grid mt={20}>
            <Grid.Col md={4} sm={12} pt={30}>
              {user.avatar && (
                <div
                  style={{
                    width: 220,
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                  }}
                >
                  <Image
                    radius={128}
                    src={
                      user.avatar && user.avatar.url
                        ? user.avatar.url
                        : DefaultAvatar
                    }
                    alt={user.username}
                  />
                  {!user.avatar.url ||
                  user.avatar.url.indexOf("assets/fallback") >= 0 ? (
                    <Button
                      mt={20}
                      radius="xl"
                      fullWidth
                      onClick={(event) => onOpenFileSelector(event)}
                    >
                      <input
                        ref={fileInput}
                        type="file"
                        style={{ display: "none" }}
                        accept={"image/*"}
                        onChange={handleFileInput}
                      />
                      {t("settings.editProfile.addProfileImage")}
                    </Button>
                  ) : (
                    <Button
                      variant="default"
                      color="gray"
                      radius="xl"
                      mt={20}
                      onClick={() => onRemoveAvatar()}
                    >
                      {t("settings.editProfile.removeProfileImage")}
                    </Button>
                  )}
                </div>
              )}
            </Grid.Col>
            <Grid.Col md={8} sm={12}>
              <TextInput
                {...form.getInputProps("first_name")}
                label={t("settings.editProfile.firstName")}
                mb={20}
              />

              <TextInput
                {...form.getInputProps("last_name")}
                label={t("settings.editProfile.lastName")}
                mb={20}
              />

              <TextInput
                {...form.getInputProps("username")}
                label={t("settings.editProfile.username")}
                mb={20}
              />

              <TextInput
                {...form.getInputProps("email")}
                label={t("settings.editProfile.email")}
              />
            </Grid.Col>
          </Grid>

          <Title sx={(theme) => ({ fontWeight: 600 })} order={2} mt={40}>
            {t("settings.editProfile.about")}
          </Title>
          <Divider
            sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
            mt={10}
            mb={20}
          />
          <Textarea
            {...form.getInputProps("bio")}
            placeholder={t("settings.editProfile.bioPlaceholder")}
            label={t("settings.editProfile.bio")}
            autosize
            minRows={3}
            maxRows={8}
          />
          <Button fullWidth mt="xl" radius="xl" size="lg" mb={15} type="submit">
            {t("settings.editProfile.updateProfile")}
          </Button>
        </form>
      </Container>
      <Modal
        withCloseButton={false}
        size="xl"
        opened={isImgEditorShown}
        onClose={() => setIsImgEditorShown(false)}
        title={""}
      >
        <FilerobotImageEditor
          source={htmlImage.current}
          onSave={(editedImageObject, designState) =>
            onUploadAvatar(editedImageObject, designState)
          }
          onClose={closeImgEditor}
          Rotate={{ angle: 90, componentType: "slider" }}
          Crop={{
            ratio: 1 / 1,
            ratioTitleKey: "Profile picture",
            noPresets: true,
            autoResize: true,
          }}
          tabsIds={[TABS.ADJUST]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ADJUST} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
          defaultSavedImageName={"profileImage"}
          onBeforeSave={() => false}
          // moreSaveOptions={[
          //   {
          //     label: "Save directly",
          //     onClick: (triggerSaveModal, triggerSave) => 
          //     triggerSave((...args) => {
          //       onUploadAvatar(args[0])
          //       }),
          //   },
          // ]}
        />
      </Modal>
    </ScrollArea>
  );
}
