import React from 'react';

import {
  Text,
  createStyles
} from '@mantine/core';

import tinycolor from 'tinycolor2'
import { useSelector } from 'react-redux';
import useSidebarStyles from "../../../../../utils/SidebarStyles"
import isSpaceThemed from '../../../../../utils/IsSpaceThemed'

const useStyles = createStyles((theme, {colors, settingsView, context}) => ({
  freeformResponse: {
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: theme.radius.md,
    backgroundColor: isSpaceThemed(colors) && !settingsView && context != "settings" ? tinycolor(colors.sidebar_background_color).lighten(7).toString() : theme.colors.gray[1],
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    marginTop: 10,
    width: "100%",
    fontSize: 14
  }
}));

export default function TextBased(props) {

  const { settingsView } = useSidebarStyles();
  const colors = useSelector(state => state.colors);
  const { classes, cx } = useStyles({colors, settingsView, context: props.context});

  return (
    <Text className={classes.freeformResponse}>
      {props.response}
    </Text>
  )
}
