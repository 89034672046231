import { Extension } from '@tiptap/core'

// https://tiptap.dev/guide/custom-extensions#attributes

const CustomBlockId = Extension.create({
  name: "customBlockId",
  addOptions() {

    return {
      types: [],
    }
  },
  addGlobalAttributes() {

    return [
      {
        // Extend the following extensions
        types: [
          'paragraph',
          'heading',
          'orderedlist',
          'bulletList',
        ],
        // … with those attributes
        attributes: {
          blockId: {
            default: "",
          },
        },
      },
    ]
  },
  addCommands() {
    return {
      setCustomBlockId: (_blockId) => ({ commands, view }) => {

        return this.options.types.every(type => commands.updateAttributes(type, { blockId: _blockId }))
      },
    }
  },
})

export default CustomBlockId;
