import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setHiddenSidebar } from "../../../store/app";

import PlanReference from "./PlanReference";
import StripeForm from "./StripeForm";

import StripeModel from "../../../models/SutraStripe";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Swal from "sweetalert2";

import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";

import AffiliateData from "../../users/AffiliateData";

import {
  Paper,
  Title,
  Container,
  Box,
  Divider,
  Grid,
  ScrollArea,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

export default function Payment(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const params = useParams();

  const [stripePromise, setStripePromise] = useState();
  const [stripeOptions, setStripeOptions] = useState({ clientSecret: "" });
  const [returnUrl, setReturnUrl] = useState();
  const [price, setPrice] = useState();
  const [currency, setCurrency] = useState();
  const [frequency, setFrequency] = useState();
  const [trial, setTrial] = useState();

  const mounted = useRef();

  useEffect(() => {
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 1000);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    if (params.plan && !mounted.current) {
      mounted.current = true;
      onCreate();
    }
  }, [params]);

  const onCreate = () => {
    StripeModel.onSubscriptionIntent(
      (data) => {
        const appearance = {
          theme: "stripe",
        };

        setStripePromise(loadStripe(data.publishable_key));
        setStripeOptions({ clientSecret: data.client_secret, appearance });
        setReturnUrl(data.return_url);
      },
      (data) => {}
    );
  };

  const onPriceChange = (price, currency, frequency, trial) => {
    setTrial(trial);
    setPrice(price);
    setCurrency(currency);
    setFrequency(frequency);
  };

  const onPay = async (stripe, elements) => {
    if (!stripe || !elements) {
      return;
    }

    stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: `${returnUrl}&plan_id=${params.plan}`,
        },
      })
      .then(function (result) {
        if (result.error) {
          Swal.fire(t("errors.basic"), result.error.message, "error");
        } else {
        }
      });
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{t("app.payment.payment.tabTitle")}</title>
      </Helmet>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                <Box pt={20} pb={5} pl={35} pr={35}>
                  <Title
                    sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                    order={1}
                  >
                    {t("app.payment.payment.title")}
                  </Title>
                </Box>
                <Divider
                  sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                  my="sm"
                />
                <Box pt={20} pb={5} pl={35} pr={35}>
                  {params.plan && stripePromise && stripeOptions ? (
                    <Elements stripe={stripePromise} options={stripeOptions}>
                      <StripeForm
                        trial={trial}
                        onPay={onPay}
                        amount={price}
                        currency={currency}
                        frequency={frequency}
                      />
                    </Elements>
                  ) : (
                    ""
                  )}
                </Box>
                <AffiliateData />
              </Paper>
            </Container>
          </Grid.Col>
          {params.plan && (
            <Grid.Col xs={12} sm={6}>
              <PlanReference plan={params.plan} onPriceChange={onPriceChange} />
            </Grid.Col>
          )}
        </Grid>
      </Container>
    </ScrollArea>
  );
}
