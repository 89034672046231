import React, { useState, useEffect, useRef, memo } from "react";
import TiptapNoHP from "../../../tiptap/components/TiptapNoHP";

import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import HeaderImage from "../../HeaderImage";
import FixedHeader from "../../FixedHeader";
import Loading from "../static/Loading";

import { Stack, ScrollArea, createStyles } from "@mantine/core";

import trackMixpanel from "../../../../utils/TrackMixpanel";
import ViewModel from "../../../../models/View";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color,
    },
    ".ProseMirror .file-node svg": {
      stroke: colors.default_text_color,
    },
    ".ProseMirror .file-node a": {
      color: colors.default_text_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.default_text_color,
      backgroundColor: isSpaceThemed(colors)
        ? isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(8).toString()
          : tinycolor(colors.background_color).darken(8).toString()
        : tinycolor(colors.background_color).darken(2).toString(),
      border: isSpaceThemed(colors)
        ? `1px solid ${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(20).toString()
              : tinycolor(colors.background_color).darken(20).toString()
          }`
        : `1px solid #e9ecef`,
        "&:hover": {
          // backgroundColor: theme.colors.gray[0],
          backgroundColor: isSpaceThemed(colors)
            ? (isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(10).toString()
              : tinycolor(colors.background_color).darken(10).toString())
            : tinycolor(colors.background_color).darken(2).toString(),
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
          cursor: "pointer",
          // color: "#000",
          textDecoration: "none",
        },
    },
  },
}));

let disconnectInterval = null;

const Content = memo((props) => {
  const user = useSelector((state) => state.colors);
  const colors = useSelector((state) => state.colors);

  const params = useParams();

  const { classes, cx, theme } = useStyles({ colors });

  const viewport = useRef();
  const helpScouteLoaded = useRef();

  const [loaded, setLoaded] = useState(false);
  const content = useRef({});
  const lastSlug = useRef();
  const width = useRef();

  useEffect(() => {
    setLoaded(false);
    return () => {
      console.log("CONTENT READONLY UNMOUNT");
      setLoaded(false);
      window.Beacon("destroy");
      helpScouteLoaded.current = false;
    };
  }, []);

  useEffect(() => {
    console.log("CONTENT READONLY PARAMS");
    if (props.hideLayout) {
      props.hideLayout();
      setTimeout(() => {
        props.hideLayout();
      }, 500);
    }

    if (params.slug && lastSlug.current != params.slug) {
      lastSlug.current = params.slug;
      setLoaded(false);
      onFetch();
    }
  }, [params]);

  useEffect(() => {
    if (user && user.authenticated) {
      trackMixpanel(user.username, "Content loaded");
      if (!helpScouteLoaded.current && user.username) {
        helpScouteLoaded.current = true;
        setTimeout(() => {
          window.Beacon("init", "8baee135-5597-476a-8269-cb1460d638e4");
          window.Beacon("identify", {
            name: user.name,
            email: user.email,
            account_type: user.account_type,
            // avatar: user.avatar ? user.avatar.url : "",
          });
        }, 2000);
      }
    }
  }, [user]);

  useEffect(() => {
    window.$contentScrollViewport = viewport.current;
  }, [viewport.current]);

  const onFetch = () => {
    ViewModel.onFetch(
      "tiptap",
      params.slug,
      (data) => {
        content.current = data.content;
        window.$spaceUserInteractions = data.interactions;
        width.current = data.view_width;
        window.$spaceWideWidth = data.view_width;
        window.$themeColors = { ...data.colors };
        setTimeout(() => {
          setLoaded(true);
        }, 250);
      },
      (data) => {}
    );
  };

  if (!loaded) {
    return <Loading type="content" />;
  }

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea
        id="main-scroll-area"
        className="content-scrollview"
        style={{ height: "100%" }}
        viewportRef={viewport}
      >
        <HeaderImage />
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={[
            "breadcrumbs",
            "title",
            "calendar",
            "status",
            "interactions",
            "tag",
            "redirect",
            "topActions"
          ]}
        />

        <TiptapNoHP
          key={`tiptap-${new Date()}`}
          content={content.current.default}
          allowComments={true}
          spaceWidth={width.current}
          viewId={"tiptap"}
        />
      </ScrollArea>
    </Stack>
  );
});

export default Content;
