export function getDomain(){
  if (window.location.hostname.indexOf("stablenavel") >= 0){
    return `stablenavel.com`
  }
  else if (window.location.hostname.indexOf("ngrok") >= 0){
    return `sutra.ngrok.io`
  }
  else if (window.location.hostname.indexOf("sutra") >= 0){
    return `sutra.co`
  }
  else{
    return `lvh.me`;
  }
}

export function getRoot(){
  if (window.location.hostname.indexOf("stablenavel") >= 0){
    return `https://stablenavel.com`
  }
  else if (window.location.hostname.indexOf("ngrok") >= 0){
    return `https://sutra.ngrok.io`
  }
  else if (window.location.hostname.indexOf("sutra") >= 0){
    return `https://sutra.co`
  }
  else{
    return `http://lvh.me:3000`;
  }
}

export function getFullDomain(subdomain=""){
  if (window.location.hostname.indexOf("stablenavel") >= 0){
    return `https://${subdomain}stablenavel.com`
  }
  else if (window.location.hostname.indexOf("sutra") >= 0){
    return `https://${subdomain}sutra.co`
  }
  else{
    return `http://${subdomain}lvh.me:3000`;
  }
}

export function getHost(protocol="http"){
  if (window.location.hostname.indexOf("stablenavel") >= 0){
    return `${protocol}s://stablenavel.com/cable`
  }
  else if (window.location.hostname.indexOf("sutra") >= 0){
    return `${protocol}s://sutra.co/cable`
  }
  else{
    return `${protocol}://lvh.me:3000/cable`;
  }
}

export function getHocuspocusHost(){
  if (window.location.hostname.indexOf("stablenavel") >= 0){
    return `wss://sutra-stage-hp.herokuapp.com`
  }
  else if (window.location.hostname.indexOf("sutra") >= 0){
    return `wss://sutra-hp.herokuapp.com`
  }
  else{
    return `ws://localhost:1234`;
  }
}

export function getNeo4jHost(protocol="http"){
  if (window.location.hostname.indexOf("stablenavel") >= 0){
    return `${protocol}s://sutra-neo4j-2ab2e672baeb.herokuapp.com`
  }
  else if (window.location.hostname.indexOf("sutra") >= 0){
    return `${protocol}s://sutra-neo4j-2ab2e672baeb.herokuapp.com`
  }
  else{
    return `${protocol}://localhost:3001`;
  }
}

export function getNeo4jWsHost(protocol="http"){
  if (window.location.hostname.indexOf("stablenavel") >= 0){
    return `${protocol}s://sutra-neo4j-2ab2e672baeb.herokuapp.com`
  }
  else if (window.location.hostname.indexOf("sutra") >= 0){
    return `${protocol}s://sutra-neo4j-2ab2e672baeb.herokuapp.com`
  }
  else{
    return `${protocol}://localhost:3001`;
  }
}

export function getEnv(){
  if (window.location.hostname.indexOf("stablenavel") >= 0){
    return `production`
  }
  else if (window.location.hostname.indexOf("sutra") >= 0){
    return `production`
  }
  else{
    return `development`;
  }
}
