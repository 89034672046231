import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import {
  Box,
  Avatar,
  AvatarsGroup,
  Group,
  Badge,
  Stack,
  Text,
  Tooltip,
  createStyles,
  Skeleton,
  MantineProvider,
  Button,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";

import SpaceModel from "../../../models/Space";
import DefaultAvatar from "../../../src/images/fallback/default.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { DateTime } from "luxon";

import useLangUser from "../../../utils/LangUser";
import formatDate from "../../../utils/formatDate";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import isDark from "../../../utils/IsDarkColor";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme, { colors, publishOnDate }, getRef) => ({
  spaceListItem: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: "10px",
    // backgroundColor: theme.colors.gray[0],
    // backgroundColor: isSpaceThemed(colors)
    //   ? tinycolor(colors.background_color).darken(1).toString()
    //   : theme.colors.gray[0],

    backgroundColor: isSpaceThemed(colors)
      ? isDark(colors.background_color)
        ? tinycolor(colors.background_color).lighten(8).toString()
        : tinycolor(colors.background_color).darken(8).toString()
      : tinycolor(colors.background_color).darken(2).toString(),
    border: isSpaceThemed(colors)
      ? `1px solid ${
          isDark(colors.background_color)
            ? tinycolor(colors.background_color).lighten(20).toString()
            : tinycolor(colors.background_color).darken(20).toString()
        }`
      : `1px solid #e9ecef`,
    minHeight: 95,
    padding: "20px 25px",
    // margin: "15px 0px",
    // boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    width: "100%",

    "&:hover": {
      // backgroundColor: theme.colors.gray[0],
      backgroundColor: isSpaceThemed(colors)
        ? isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(10).toString()
          : tinycolor(colors.background_color).darken(10).toString()
        : tinycolor(colors.background_color).darken(2).toString(),
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
      cursor: "pointer",
      // color: "#000",
      textDecoration: "none",
    },
  },

  spaceListItemHasHeader: {
    "&:hover": {
      color: "#fff",
    },
  },

  title: {
    textDecoration: "none",
    // color: "#000",
    color: isSpaceThemed(colors) ? colors.default_text_color : "#000",
    fontSize: "1.7em",
    lineHeight: "1.1em",
    fontWeight: 550,
    margin: 0,

    "&:hover": {
      textDecoration: "none",
    },
  },

  titleHasHeader: {
    color: "#fff",
    textShadow: "1px 1px #000000",
    textDecoration: "none",

    "&:hover": {
      color: "#fff",
      textDecoration: "none",
    },
  },

  date: {
    textDecoration: "none",
    // color: "#000",
    color: isSpaceThemed(colors) ? colors.default_text_color : "#000",

    "&:hover": {
      textDecoration: "none",
    },
  },

  dateHasHeader: {
    color: "#fff",
    textShadow: "1px 1px #000000",

    "&:hover": {
      color: "#fff",
      textDecoration: "none",
    },
  },

  dateBlockWrap: {
    padding: "7px 16px",
    borderRadius: 5,
    height: "100%",
    // border: "1px solid #ddd",
    border: `1px solid ${
      isSpaceThemed(colors) ? colors.default_text_color : "#000"
    }`,
    marginRight: 5,
    color: isSpaceThemed(colors) ? colors.default_text_color : "#000",
  },

  dateBlockWrapHasHeader: {
    color: "#fff",
    textShadow: "1px 1px #000000",
    border: `1px solid #fff`,
    // boxShadow: "1px 1px #000000",
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.5)",

    "&:hover": {
      color: "#fff",
      textDecoration: "none",
    },
  },

  dateBlockDate: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: 1,
    marginBottom: 5,
  },

  dateBlockMonth: {
    lineHeight: 1,
  },

  titleSm: {
    fontSize: "1.2em",
  },

  dateSm: {
    fontSize: ".9em",
  },
}));

export default function ListContent2(props) {
  const { t, i18n } = useTranslation();

  const isUnpublished = () => {
    return (
      space && space.launch_on_date && new Date() < new Date(space.launch_time)
    );
  };

  const colors = useSelector((state) => state.colors);
  const navigate = useNavigate();
  const [space, setSpace] = useState(null);
  const { classes, cx, theme } = useStyles({
    colors,
    publishOnDate: isUnpublished() ? space.launch_on_date : "",
  });
  let _width = theme.other.breakpoints.lg;
  const { ref, width, height } = useElementSize();
  const [avatarLimit, setAvatarLimit] = useState(4);
  const [showAvatars, setShowAvatars] = useState(true);

  const { language, setLangUser } = useLangUser();

  const updateSpace = useSelector((state) => state.listSpaceToUpdate);

  const propsTimeoutId = useRef();
  const updating = useRef();

  useEffect(() => {
    if (space && updateSpace.slug == space.slug) {
      updating.current = true;
      onFetchSpace(props.fetchId ? props.fetchId : updateSpace.slug);
    }
  }, [updateSpace]);

  useEffect(() => {
    if (updating.current) {
      updating.current = false;
      return;
    }
    if (props.space) {
      if (propsTimeoutId.current) {
        clearTimeout(propsTimeoutId.current);
      }
      propsTimeoutId.current = setTimeout(() => {
        updating.current = true;
        setSpace({ ...props.space });
      }, 200);
    } else if (!space && props.fetch) {
      onFetchSpace(props.fetchId);
    }
  }, [space]);

  useEffect(() => {
    _width = width > 0 ? width : _width;

    if (_width < theme.other.breakpoints.xs) {
      setShowAvatars(false);
    } else if (_width < theme.other.breakpoints.md) {
      setShowAvatars(true);
      setAvatarLimit(4);
    } else {
      setShowAvatars(true);
      setAvatarLimit(4);
    }

    // console.log("width: ", width)
    // console.log("_width: ", _width)
    // console.log("theme.other.breakpoints.xs: ", theme.other.breakpoints.xs )
    // console.log("_width < theme.other.breakpoints.xs: ", _width < theme.other.breakpoints.xs)
    // console.log("showAvatars: ", showAvatars)
  }, [width]);

  const onFetchSpace = (id) => {
    const ctrl = new AbortController();
    SpaceModel.onFetchSimpleShow(
      id,
      ["members", "last_messages"],
      (data) => {
        try {
          setSpace({
            ...data.space,
            permissions: { ...data.permissions },
            colors: { ...data.colors },
            last_messages: last_messages,
            last_message: last_message,
          });
        } catch {
          setSpace({
            ...data.space,
            permissions: { ...data.permissions },
          });
        }

        if (props.onSpaceFetched) {
          props.onSpaceFetched(data);
        }
      },
      (data) => {
        // Something went wrong
      },
      ctrl.signal
    );
  };

  const onAvatarClick = (event, member) => {
    event.stopPropagation();
    event.preventDefault();
    navigate(member.link);
  };

  const formatDateTime = (timestamp) => {
    if (space && space.launch_time) {
      const d = new Date(space.launch_time).toISOString();
      return DateTime.fromISO(d).toFormat("ff");
    }
  };

  return (
    <>
      {space ? (
        <Box
          className={cx(classes.spaceListItem, {
            [classes.spaceListItemHasHeader]: space.header.url,
          })}
          ref={ref}
          sx={{
            backgroundImage: space.header.url
              ? `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.2)), url(${space.header.url})`
              : "none",
          }}
        >
          <Group
            sx={{
              width: "100%",
            }}
            position="apart"
            spacing="sm"
            noWrap
          >
            <Group
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
              }}
              noWrap
            >
              {space.calendar.has_start_date && (
                <Stack
                  spacing={0}
                  align="center"
                  // className={classes.dateBlockWrap}
                  className={cx(classes.dateBlockWrap, {
                    [classes.dateBlockWrapHasHeader]: space.header.url,
                  })}
                >
                  <Box className={classes.dateBlockDate}>
                    {DateTime.fromISO(space.calendar.raw_start_date)
                      .setLocale(language)
                      .toFormat("d")}
                  </Box>
                  <Box className={classes.dateBlockMonth}>
                    {DateTime.fromISO(space.calendar.raw_start_date)
                      .setLocale(language)
                      .toFormat("LLL")}
                  </Box>
                </Stack>
              )}
              <Stack
                spacing={0}
                sx={(theme) => ({
                  height: "100%",
                })}
              >
                <Group spacing="sm">
                  {width <
                    (theme.other.breakpoints.xs ||
                      width < theme.other.breakpoints.md) &&
                  space.name.length > 15 ? (
                    <Tooltip width={250} wrapLines label={space.name} withArrow>
                      <Text
                        className={cx(
                          classes.title,
                          { [classes.titleHasHeader]: space.header.url },
                          {
                            [classes.titleSm]:
                              width < theme.other.breakpoints.xs,
                          }
                        )}
                        lineClamp={1}
                      >
                        {space.name}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text
                      className={cx(
                        classes.title,
                        { [classes.titleHasHeader]: space.header.url },
                        {
                          [classes.titleSm]: width < theme.other.breakpoints.xs,
                        }
                      )}
                      lineClamp={1}
                    >
                      {space.name}
                    </Text>
                  )}
                </Group>
                {space.calendar.has_start_date ? (
                  <Text
                    className={cx(
                      classes.date,
                      { [classes.dateHasHeader]: space.header.url },
                      { [classes.dateSm]: width < theme.other.breakpoints.xs }
                    )}
                    lineClamp={1}
                  >
                    {formatDate(space.calendar, language)}
                  </Text>
                ) : (
                  ""
                )}
                {space.tagged_with && (
                  <Box className={classes.tagWrap}>
                    {space.tagged_with.map((tag) => {
                      return (
                        theme.other.tagColors[tag.color] && (
                          <Button
                            sx={(theme) => ({
                              backgroundColor:
                                theme.other.tagColors[tag.color]
                                  .backgroundColor,
                              color: theme.other.tagColors[tag.color].textColor,
                              "&:hover": {
                                backgroundColor:
                                  theme.other.tagColors[tag.color]
                                    .backgroundColor,
                              },
                            })}
                            styles={{
                              root: {
                                height: 20,
                                marginRight: 5,
                              },
                              label: { fontSize: 10 },
                            }}
                            size="xs"
                            radius="xl"
                          >
                            {tag.name}
                          </Button>
                        )
                      );
                    })}
                  </Box>
                )}
              </Stack>
            </Group>
            <Group noWrap position="right" spacing={10}>
              {isUnpublished() && (
                <MantineProvider theme={{ primaryShade: 6 }}>
                  <Tooltip
                    label={t("spaces.presentations.listContent.publishTootip")}
                    withArrow
                  >
                    <Badge
                      color="green"
                      variant="filled"
                      className={classes.dateStyle}
                      leftSection={<FontAwesomeIcon icon={regular("clock")} />}
                    >
                      {" "}
                      {formatDateTime()}
                    </Badge>
                  </Tooltip>
                </MantineProvider>
              )}
              {space.privacy_level === "private" ? (
                <Tooltip
                  label={t("spaces.presentations.listContent.privateTooltip")}
                  withArrow
                >
                  <Text
                    sx={(theme) => ({
                      color: !space.header.url ? theme.colors.gray[6] : "white",
                    })}
                  >
                    <FontAwesomeIcon icon={solid("eye-slash")} />
                  </Text>
                </Tooltip>
              ) : (
                ""
              )}
              {space.activity && space.activity > 0 ? (
                <Badge
                  variant="filled"
                  color="sutrapink"
                  styles={{
                    root: {
                      padding: "0 5px",

                      "&:hover": {
                        textDecoration: "none",
                      },
                    },
                  }}
                >
                  {space.activity}
                </Badge>
              ) : (
                ""
              )}
              {showAvatars &&
              space &&
              space.members &&
              typeof space.members != "string" &&
              space.members.length > 0 ? (
                <>
                  <AvatarsGroup
                    limit={avatarLimit}
                    total={space.members_count}
                    spacing={30}
                    // radius={10}
                    styles={{
                      root: {
                        justifyContent: "right",
                        cursor: "pointer",
                        paddingLeft: 12,
                      },
                      child: {
                        width: "auto",
                        border: `2px solid #fff !important`,
                      },
                      truncated: {
                        backgroundColor: theme.colors.gray[2],
                        paddingRight: 5,
                        paddingLeft: 5,
                      },
                    }}
                  >
                    {space.members.map((member) => {
                      return (
                        <Avatar
                          styles={{
                            image: {
                              width: 34,
                            },
                            // root: { border: "none !important" }
                            // root: { border: `2px solid ${colors.header_color} !important` }
                          }}
                          key={`member-avatar-${member.id}`}
                          src={
                            member.avatar.url
                              ? member.avatar.url
                              : DefaultAvatar
                          }
                          component="span"
                        />
                      );
                    })}
                  </AvatarsGroup>
                </>
              ) : (
                ""
              )}
            </Group>
          </Group>
        </Box>
      ) : (
        <Box className={cx(classes.spaceListItem)} ref={ref}>
          <Group
            sx={{
              width: "100%",
            }}
            position="apart"
            spacing="sm"
            noWrap
          >
            <Stack
              spacing={0}
              sx={(theme) => ({
                height: "100%",
              })}
            >
              <Group spacing="sm">
                <Skeleton height={30} width="50px" radius="sm" />
              </Group>
            </Stack>
            <Group noWrap position="right" spacing={10}>
              <Skeleton height={30} width="30px" radius="sm" />
              <Skeleton height={30} width="30px" radius="sm" />
              <Skeleton height={30} width="30px" radius="sm" />
            </Group>
          </Group>
        </Box>
      )}
    </>
  );
}
