import React, { useState } from "react";

import { Text, Drawer, ScrollArea, useMantineTheme } from "@mantine/core";

import { useTranslation } from "react-i18next";

import CreateSurveyForm from "./CreateSurveyForm";

export default function CreateSurvey(props) {
  const [scheduled, setScheduled] = useState(false);
  const { t, i18n } = useTranslation();
  const theme = useMantineTheme();

  return (
    <Drawer
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {!props.acceptPayments
            ? t("spaces.settings.survey.createSurvey.description")
            : "Flexible payment options"}
        </Text>
      }
      padding="xl"
      size={600}
    >
      <ScrollArea style={{ height: "100%" }}>
        <CreateSurveyForm
          opened={props.opened}
          setOpened={props.setOpened}
          survey={props.survey}
          spaceId={props.spaceId}
          onUpdate={props.onUpdate}
          acceptPayments={props.acceptPayments}
          isFlexibleOptions={true}
        />
      </ScrollArea>
    </Drawer>
  );
}
