import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import {
  Stack,
  Button,
  Text,
  ScrollArea,
  createStyles,
  Divider,
  Box,
} from "@mantine/core";

import Question from "./Question";

import SurveyModel from "../../../models/Survey";
import { useTranslation } from "react-i18next";
import tinycolor from "tinycolor2";

import Individual from "../../spaces_neo/settings/surveys/Individual";

import TiptapReadonly from "../../tiptap/components/TiptapReadonly";

import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme, { colors }) => ({
  buttonStyle: {
    // marginTop: 10,
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    border: `1px solid ${colors.primary_button_background_color}`,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(5)
        .toString(),
    },
  },
}));

const TakeForm = (props) => {
  const [choices, setChoices] = useState([]);
  const user = useSelector((state) => state.user);
  const colors = useSelector((state) => state.colors);
  const [taken, setTaken] = useState(false);
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false);
  const { classes, cx, theme } = useStyles({ colors, props });

  const navigate = useNavigate();

  useEffect(() => {}, [choices]);

  useEffect(() => {
    setTaken(props.survey && props.survey.id && props.survey.choices_count > 0);
  }, [props]);

  const onTake = () => {
    setErrorMessage("");
    SurveyModel.onTake(
      props.survey.id,
      props.survey.pod_id,
      [...choices],
      (data) => {
        setChoices([]);
        setTaken(true);
        if (props.survey.redirect_to_space) {
          navigate(`/space/${props.survey.redirect_to_space}`);
          return;
        }
        if (tiptapSidemenu.option.survey.after_submit_show_score_and_keys){
          onFetch()
          return;
        }
        props.onTaken();
      },
      (data) => {
        if (data.action == "auth"){
          const spaceSlug = window.location.href.split("/")[4]
          localStorage.setItem("join_slug", spaceSlug);
          navigate(`/user/start?s=${spaceSlug}`);
          return
        }
        setErrorMessage(data.message);
      }
    );
  };

  const onFetch = () => {
    SurveyModel.onFetchById(
      props.survey.id,
      space.id,
      (data) => {
        setSurvey(data);
      },
      (data) => {}
    );
  };

  const onAddChoice = (question, choice) => {
    if (question && choice && choice.length > 0) {
      if (questionAlreadyAnswered(question)) {
        replaceQuestionChoice(question, choice);
      } else {
        addQuestionChoice(question, choice);
      }
    } else if (question && choice && choice.length == 0) {
      removeQuestionChoice(question);
    }
  };

  const questionAlreadyAnswered = (question) => {
    for (let i = 0; i < choices.length; i++) {
      if (choices[i].id == question.id) {
        return true;
      }
    }
    return false;
  };

  const replaceQuestionChoice = (question, choice) => {
    const auxChoices = [...choices];
    for (let i = 0; i < auxChoices.length; i++) {
      if (auxChoices[i].id == question.id) {
        auxChoices[i].answers = choice;
        setChoices(auxChoices);
        break;
      }
    }
  };

  const removeQuestionChoice = (question) => {
    const auxChoices = [...choices];
    for (let i = 0; i < auxChoices.length; i++) {
      if (auxChoices[i].id == question.id) {
        auxChoices.splice(i, 1);
        setChoices(auxChoices);
        break;
      }
    }
  };

  const addQuestionChoice = (question, choice) => {
    const auxChoices = [...choices];
    auxChoices.push({
      id: question.id,
      type: question.question_type,
      answers: choice,
    });
    setChoices(auxChoices);
  };

  const onRetake = () => {
    setTaken(false);
  };

  return (
    <ScrollArea>
      <Stack>
        {!props.survey.is_content_empty && (
          <>
            <TiptapReadonly content={props.survey.content} />
            <Divider
              sx={(theme) => ({
                marginTop: 10,
                marginBottom: 10,
                borderTopColor: colors.default_text_color,
              })}
            />
          </>
        )}

        {user && taken ? (
          <Box>
            <Text>{t("survey.form.text")}</Text>
            <Individual
              spaceId={props.survey.pod_id}
              survey={props.survey}
              singleUser={true}
              selectedUser={user.id}
            />
            {props.survey.allow_retake && (
              <Button
                mt={20}
                className={classes.buttonStyle}
                radius="xl"
                size="md"
                onClick={() => onRetake()}
              >
                {`Re-take`}
              </Button>
            )}
          </Box>
        ) : (
          props.survey &&
          props.survey.questions &&
          props.survey.questions.map((question) => {
            return <Question question={question} onAddChoice={onAddChoice} />;
          })
        )}
        {!taken && (
          <>
            {errorMessage && (
              <Text
                // color="red"
                sx={(theme) => ({
                  backgroundColor: theme.colors.red[9],
                  color: "#fff",
                  padding: 8,
                  borderRadius: 3,
                })}
              >
                {errorMessage}
              </Text>
            )}
            <Button
              className={classes.buttonStyle}
              radius="xl"
              size="md"
              onClick={() => onTake()}
            >
              {t("survey.form.submitButton")}
            </Button>
          </>
        )}
      </Stack>
    </ScrollArea>
  );
};

export default TakeForm;
