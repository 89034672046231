import React, { useState, useEffect, useRef } from 'react';
import {

  Box,
  MantineProvider,
  Select,
  Button,

  Group,
  Loader
} from '@mantine/core'

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'

import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import SpaceModel from '../../../../../models/Space'

export default function AddToExistingSpace(props) {

  const space = useSelector(state => state.space);
  const [spaces, setSpaces] = useState([]);

  const [searching, setSearching] = useState(false)
  const { t, i18n } = useTranslation();
  const searchTimeoutId = useRef();
  const selectedParents = useRef([]);
  const auxSpaces = useRef([])

  const form = useForm({
    initialValues: {
      object_type: "pod",
      object_id: ""
    },

    validate: {
      object_id: (value) => value.length > 0 ? null : "Please select a space",
    }
  });

  useEffect(() => {
    onFetchSpaces()
  }, [props])

  const onFetchSpaces = (query=null) => {
    auxSpaces.current = []

    const include = ["members", "simple"]
    if (query){
      include.push(`q=${query}`)
    }

    SpaceModel.onFetchDeepChildren(space.slug, include, {page: 1, per: 10}, ["active_or_pending"], (data) => {
      for (let i = 0; i < data.data.length; i++) {
        auxSpaces.current.push({value: `${data.data[i].id}`, label: `${data.data[i].name} (${data.data[i].slug})`, group: "Sub spaces"})
      }

      setSpaces([...auxSpaces.current])

      if (props.mode === "edit") {
        form.setFieldValue("object_id", `${props.step.object_id}`)
      }
    }, (data) => {

    })

    SpaceModel.onFetchManaged([], include, {page: 1, per: 10}, (data) => {

      for (let i = 0; i < data.length; i++) {
        var index = auxSpaces.current.findIndex(x => x.value==data[i].id);
        index === -1 && (
          auxSpaces.current.push({value: `${data[i].id}`, label: `${data[i].name} (${data[i].slug})`, group: "All spaces"})
        )
      }

      setSpaces([...auxSpaces.current])

      if (props.mode === "edit") {
        form.setFieldValue("object_id", `${props.step.object_id}`)
      }
    }, (data) => {

    })

  }

  const onSend = () => {
    if (props.mode === "edit") {
      props.onUpdate(form.values)
    } else {
      console.log("form.values 22: ", form.values)
      props.onCreate(form.values)
    }

  }

  const onSearchSpaces = (query) => {
    setSearching(true)
    if (searchTimeoutId.current){
      clearTimeout(searchTimeoutId.current)
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query)
      setSearching(false)
    }, 1000)
  }

  return (
    <Box>
      <form
        onSubmit={form.onSubmit((values) => onSend(values))}>
        <MantineProvider
          theme={{ primaryShade: 6 }}
          >
            <Select
              icon={searching ? <Loader size="xs" /> : <FontAwesomeIcon icon={solid('search')} />}
              label={t("spaces.settings.registration.steps.addToExistingSpace.label")}
              searchable
              mt={20}
              data={spaces}
              placeholder={t("spaces.settings.registration.steps.addToExistingSpace.placeholder")}
              {...form.getInputProps('object_id')}
              maxDropdownHeight={400}
              radius="sm"
              size="md"
              onSearchChange={(query) => onSearchSpaces(query)}
              />
          </MantineProvider>

          {
            props.mode === "edit" ? (
              <Group
                position="apart"
              >
                <Button
                  mt={25}
                  radius="xl"
                  // size="md"
                  type="submit"
                  leftIcon={<FontAwesomeIcon icon={solid('check')} />}
                >
                  {t("spaces.settings.registration.steps.addToExistingSpace.saveButton1")}
                </Button>
                <Button
                  onClick={(event) => props.onDestroy(event)}
                  mt={25}
                  radius="xl"
                  // size="md"
                  color="dark"
                  leftIcon={<FontAwesomeIcon icon={regular('trash-can')} />}
                >
                  {t("spaces.settings.registration.steps.addToExistingSpace.deleteButton")}
                </Button>
              </Group>
            ) : (
              <Button
                type="submit"
                // onClick={() => props.onCreate(params)}
                leftIcon={<FontAwesomeIcon icon={solid('check')} />}
                mt={25}
                radius="xl"
                // size="md"
                fullWidth
              >
                {t("spaces.settings.registration.steps.addToExistingSpace.saveButton2")}
              </Button>
            )
          }
        </form>
    </Box>
  )
}
