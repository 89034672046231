const colorPickerSwatches = (theme, type="light") => {
  if (type == "light"){
    return [
      "#fff",
      "#000",
      theme.colors.dark[2],
      theme.colors.gray[3],
      theme.colors.red[1],
      theme.colors.pink[1],
      theme.colors.grape[1],
      theme.colors.violet[1],
      theme.colors.indigo[1],
      theme.colors.blue[1],
      theme.colors.cyan[1],
      theme.colors.teal[1],
      theme.colors.green[1],
      theme.colors.lime[1],
      theme.colors.yellow[1],
      theme.colors.orange[1],
    ].reverse();
  }
  else{
    return [
      "#fff",
      "#000",
      theme.colors.dark[2],
      theme.colors.gray[3],
      theme.colors.red[6],
      theme.colors.pink[6],
      theme.colors.grape[6],
      theme.colors.violet[6],
      theme.colors.indigo[6],
      theme.colors.blue[6],
      theme.colors.cyan[6],
      theme.colors.teal[6],
      theme.colors.green[6],
      theme.colors.lime[6],
      theme.colors.yellow[6],
      theme.colors.orange[6],
    ].reverse();
  }
}

export default colorPickerSwatches