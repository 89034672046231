import React, { useState, useEffect } from "react";

import { Text, Group, Stack, Progress, Checkbox } from "@mantine/core";


import Avatar from "../../../utils/LazyAvatar";

import { useDispatch } from "react-redux";

import {setTiptapSidemenu} from '../../../../store/app';

export default function ProgressActivity(props) {
  const [member, setMember] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setMember(props.member);
  }, [props]);

  const openProfile = () => {
    dispatch(setTiptapSidemenu({ opened: "profile", option: member.username}));
  }

  return (
    <tr>
      <td style={{ width: "20px" }}>
        {window.$currentUserId != member.user_id && (
          <Checkbox
            checked={props.isSelected(member.username)}
            onChange={(event) => props.onSelect(member.username)}
          />
        )}
      </td>
      <td style={{ width: "200px" }}>
        <Group
          spacing="sm"
          onClick={() => openProfile()}
          sx={{
            color: "#000",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          noWrap
        >
          <Avatar
            size={30}
            src={member && member.avatar ? member.avatar.url : ""}
            radius={30}
          />
          <Stack spacing="0">
            <Text size="sm" weight={500}>
              {member.full_name}
            </Text>
            <Text size="xs" color="dimmed" mt={-3}>
              @{member.username}
            </Text>
          </Stack>
        </Group>
      </td>

      <td>
        <Progress
          size="xl"
          radius="xl"
          sections={[
            {
              value: member.progress,
              color: "green",
              label: `${member.progress}%`,
            },
          ]}
        />
      </td>
    </tr>
  );
}
