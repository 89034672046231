import { DEFAULT_THEME } from '@mantine/core';

export const sutraTheme = {
  primaryColor: 'sutrablue',
  primaryShade: 3,
  colors: {
    'sutrablue': ["#CFF0FD","#99E0FB","#6AD0F9","#41C3F6","#16B6F5","#08A2DE","#068BC0","#0578A6","#03688F","#02597C"],
    'sutrapink': ["#FFEDF6","#FFBBDE","#FF8EC8","#FF69B4","#FF3EA0","#FF1A8F","#FF007F","#E60071","#CD0065","#B60059"]
  },
  headings: {
    sizes: {
      h1: { fontSize: 28 },
      h2: { fontSize: 24 },
      h3: { fontSize: 20 },
      h4: { fontSize: 18 },
      h5: { fontSize: 16 },
      h6: { fontSize: 14 },
    }
  },
  other: {
    headerHeight: 60,
    sideFrameWidth: 300,
    sideFrameWidthSm: 250,
    appShellPadding: 20,
    spaceSidebarDefaultTextColor: "#050505",
    breakpoints: {
      xs:	380,
      sm:	576,
      md: 768,
      lg:	992,
      xl:	1200,
      xxl: 1400
    },
    tagColors: [
      {
        backgroundColor: DEFAULT_THEME.colors.orange[2],
        hoverColor: DEFAULT_THEME.colors.orange[3],
        textColor: DEFAULT_THEME.colors.orange[8],
      },
      {
        backgroundColor: DEFAULT_THEME.colors.yellow[2],
        hoverColor: DEFAULT_THEME.colors.yellow[3],
        textColor: DEFAULT_THEME.colors.yellow[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.lime[2],
        hoverColor: DEFAULT_THEME.colors.lime[3],
        textColor: DEFAULT_THEME.colors.lime[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.green[2],
        hoverColor: DEFAULT_THEME.colors.green[3],
        textColor: DEFAULT_THEME.colors.green[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.teal[2],
        hoverColor: DEFAULT_THEME.colors.teal[3],
        textColor: DEFAULT_THEME.colors.teal[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.blue[2],
        hoverColor: DEFAULT_THEME.colors.blue[3],
        textColor: DEFAULT_THEME.colors.blue[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.indigo[2],
        hoverColor: DEFAULT_THEME.colors.indigo[3],
        textColor: DEFAULT_THEME.colors.indigo[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.cyan[2],
        hoverColor: DEFAULT_THEME.colors.cyan[3],
        textColor: DEFAULT_THEME.colors.cyan[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.violet[2],
        hoverColor: DEFAULT_THEME.colors.violet[3],
        textColor: DEFAULT_THEME.colors.violet[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.grape[2],
        hoverColor: DEFAULT_THEME.colors.grape[3],
        textColor: DEFAULT_THEME.colors.grape[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.pink[2],
        hoverColor: DEFAULT_THEME.colors.pink[3],
        textColor: DEFAULT_THEME.colors.pink[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.red[2],
        hoverColor: DEFAULT_THEME.colors.red[3],
        textColor: DEFAULT_THEME.colors.red[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.gray[2],
        hoverColor: DEFAULT_THEME.colors.gray[3],
        textColor: DEFAULT_THEME.colors.gray[8]
      },
      {
        backgroundColor: DEFAULT_THEME.colors.dark[2],
        hoverColor: DEFAULT_THEME.colors.dark[3],
        textColor: "#fff"
      }
    ]
  }
};
