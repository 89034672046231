import { Node, mergeAttributes } from "@tiptap/core";

import { checkInteractionAndHideIfNeeded, setUniqId} from "../utils/selection";
import { stylesToString } from "../../../utils/StylesToString";
import { wideOrNarrow } from "../../../utils/PageWidth";

const FancyLinkNode = Node.create({
  name: "fancyLink",
  allowGapCursor: false,
  group: "block",

  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block columnable";
  },

  draggable: false,

  addAttributes() {
    return {
      link: {
        default: {
          url: null,
          target: "_current",
          title: "",
          description: "",
          image: {
            url: "",
          },
          thumbnail: {
            url: "",
          },
        },
      },
      uploader: {
        default: {
          id: null,
          status: null,
          progress: null,
        },
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "15px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: (attributes) => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "fancylink",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "fancylink",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("section");

      const span = document.createElement("div");
      span.style.maxWidth = wideOrNarrow();
      span.classList.add("fancylink-node");

      const mainContent = document.createElement("div");
      mainContent.classList.add("main-content");

      const imageCont = document.createElement("div");
      const img = document.createElement("img");
      const container = document.createElement("div");
      const title = document.createElement("div");
      title.classList.add("fancylink-title");
      const description = document.createElement("div");
      const attributes = mergeAttributes(HTMLAttributes, {});

      span.setAttribute("id", attributes["data-uid"]);

      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );

      img.onload = function () {

        imageCont.style.display = "inline";
      };

      img.onerror = function () {
        imageCont.style.display = "none";
      };

      const image_url_ok = (attributes.link.thumbnail && attributes.link.thumbnail.url) || (attributes.link.image && attributes.link.image.url)

      img.setAttribute("src", attributes.link.thumbnail && attributes.link.thumbnail.url ? attributes.link.thumbnail.url : attributes.link.image && attributes.link.image.url ? attributes.link.image.url : "");

      title.innerHTML = attributes.link.title;
      description.innerHTML = attributes.link.description;

      imageCont.classList.add("imageCont")
      
      imageCont.append(img);

      container.append(title);
      container.append(description);

      if (image_url_ok){
        mainContent.append(imageCont);
      }
      mainContent.append(container);

      span.append(mainContent);

      dom.append(span);

      if (!editor.isEditable) {
        span.addEventListener("click", (event) => {
          if (
            node.attrs.link.target == "_blank" ||
            event.ctrlKey ||
            event.metaKey
          ) {
            window.open(node.attrs.link.url, "_blank");
          } else {
            window.location.href= node.attrs.link.url;
          }
        });
      }

      checkInteractionAndHideIfNeeded(editor, dom, getPos);

      //setUniqId(editor, node, getPos, dom);

      return {
        dom,
        contentDOM: span,
        ignoreMutation(mutation) {

          return true
        },
      };
    };
  },

  addCommands() {
    return {
      setFancyLink:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
});

export default FancyLinkNode;
