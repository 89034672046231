import { Extension } from '@tiptap/core'


// https://tiptap.dev/guide/custom-extensions#attributes

const Interactive = Extension.create({
  name: "interactive",
  addOptions() {
    return {
      types: [],
    }
  },
  addGlobalAttributes() {

    return [
      {
        // Extend the following extensions
        types: [
          'interactiveButton',
          'interactiveInput',
          'interactiveWait',
        ],
        // … with those attributes
        attributes: {
          isInteractive: {
            default: true,
          },
          interactivePosition: {
            default: 0,
          },
          isBlocking: {
            default: true
          }
        },
      },
    ]
  },
})

export default Interactive;
