import { Node, mergeAttributes } from "@tiptap/core";

import { checkInteractionAndHideIfNeeded, setUniqId} from "../utils/selection";

import { checkUploadStatus } from "../utils/uploaderStatus";
import { stylesToString } from "../../../utils/StylesToString";
import { wideOrNarrow } from "../../../utils/PageWidth";

import defaultSpaceColors from "../../../collections/defaultSpaceColors";

const fileIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-download" width="24" height="24" viewBox="0 0 24 24" stroke="black" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 3v4a1 1 0 0 0 1 1h4"></path><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path><path d="M12 17v-6"></path><path d="M9.5 14.5l2.5 2.5l2.5 -2.5"></path></svg>';

const downloadIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M256 464a208 208 0 1 1 0-416 208 208 0 1 1 0 416zM256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM376.9 294.6c4.5-4.2 7.1-10.1 7.1-16.3c0-12.3-10-22.3-22.3-22.3H304V160c0-17.7-14.3-32-32-32l-32 0c-17.7 0-32 14.3-32 32v96H150.3C138 256 128 266 128 278.3c0 6.2 2.6 12.1 7.1 16.3l107.1 99.9c3.8 3.5 8.7 5.5 13.8 5.5s10.1-2 13.8-5.5l107.1-99.9z"/></svg>';

const printIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>';

const FileNode = Node.create({
  name: "file",
  allowGapCursor: false,
  group: "block columnable",
  defining: true,
  inline: false,

  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block columnable";
  },

  draggable: false,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      name: {
        default: "",
      },
      size: {
        default: 0,
      },
      extension: {
        default: "",
      },
      displayAs: { default: "" },
      uploader: {
        default: {
          id: null,
          status: null,
          progress: null,
        },
      },
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "15px",
          "padding-bottom": "15px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: (attributes) => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "file",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "file",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addNodeView() {
    return ({ editor, getPos, node, HTMLAttributes }) => {
      const dom = document.createElement("section");

      const span = document.createElement("div");
      span.style.maxWidth = wideOrNarrow();
      span.classList.add("file-node");

      const attributes = mergeAttributes(HTMLAttributes, {});
      span.setAttribute("id", attributes["data-uid"]);

      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );

      let pdfjsLibExists = false

      try {
        pdfjsLibExists = pdfjsLib ? true : false
      }
      catch (e) {
        pdfjsLibExists = false
      }

      if (attributes.displayAs == "embed" && attributes.src && pdfjsLibExists) {
        const colors =
          window.$themeColors && window.$themeColors.background_color
            ? window.$themeColors
            : defaultSpaceColors;

        const embed = document.createElement("div");
        const embedId = `embed-${attributes["data-uid"]}`;
        embed.setAttribute("id", embedId);
        embed.style.width = "100%";
        embed.style.height = "400px";
        embed.style.overflowY = "scroll";
        embed.style.overflowX = "hidden";

        const toolbar = document.createElement("div");
        const filename = document.createElement("span");
        const actions = document.createElement("div");
        const downloadButton = document.createElement("button");
        const printButton = document.createElement("button");

        toolbar.classList.add("pdfToolbar");
        toolbar.style.backgroundColor = colors.sidebar_background_color;
        toolbar.style.display = "flex";
        toolbar.style.justifyContent = "space-between";

        //     backgroundColor: colors.primary_button_background_color,
        // color: colors.primary_button_text_color,
        // "&:hover": {
        //   backgroundColor: tinycolor(colors.primary_button_background_color)
        //     .darken(4)
        //     .toString(),
        // },

        filename.setAttribute("id", "filename");
        filename.style.fontWeight = "bold";
        filename.style.color = colors.sidebar_text_color;

        downloadButton.setAttribute("id", "downloadBtn");
        downloadButton.innerHTML = downloadIcon;
        // downloadButton.innerHTML = "Download";
        downloadButton.style.backgroundColor =
          colors.primary_button_background_color;
        downloadButton.style.color = colors.primary_button_text_color;
        downloadButton.style.border = "none";
        downloadButton.style.borderRadius = "100px";
        downloadButton.style.padding = "5px 10px";
        downloadButton.style.marginLeft = "10px";
        downloadButton.style.cursor = "pointer";
        downloadButton.style.textAlign = "center";
        downloadButton.style.fontSize = "14px";
        // downloadButton.style.width = "100px";
        // downloadButton.querySelector("svg").style.fill = colors.primary_button_text_color

        printButton.setAttribute("id", "printBtn");
        // printButton.innerHTML = printIcon;
        printButton.innerHTML = "Print";
        printButton.style.backgroundColor =
          colors.primary_button_background_color;
        printButton.style.color = colors.primary_button_text_color;
        printButton.style.border = "none";
        printButton.style.borderRadius = "100px";
        printButton.style.padding = "3px 15px";
        printButton.style.marginLeft = "10px";
        printButton.style.cursor = "pointer";
        printButton.style.textAlign = "center";
        printButton.style.fontSize = "14px";
        // printButton.querySelector("svg").style.fill = colors.primary_button_text_color

        actions.append(downloadButton);
        // actions.append(printButton);

        toolbar.append(filename);
        toolbar.append(actions);

        span.append(toolbar);
        span.append(embed);

        span.style.display = "block";

        setTimeout(() => {
          const parentWidth = span.clientWidth - 45;
          
          pdfjsLib
            .getDocument(attributes.src)
            .promise.then((pdf) => {
              const numPages = pdf.numPages;
              let currentPage = 1; // Keep track of the current page being rendered

              function renderNextPage() {
                if (currentPage <= numPages) {
                  pdf.getPage(currentPage).then((page) => {
                    const viewport = page.getViewport({ scale: 1 });

                    const desiredWidth = parentWidth;
                    const scale = desiredWidth / viewport.width;

                    const scaledViewport = page.getViewport({ scale });

                    const canvas = document.createElement("canvas");
                    const context = canvas.getContext("2d");
                    canvas.height = scaledViewport.height;
                    canvas.width = scaledViewport.width;

                    const renderContext = {
                      canvasContext: context,
                      viewport: scaledViewport,
                    };

                    page.render(renderContext).promise.then(() => {
                      const pageContainer = document.createElement("div");
                      pageContainer.classList.add("pdfPage");
                      pageContainer.appendChild(canvas);
                      embed.appendChild(pageContainer);

                      // Move to the next page
                      currentPage++;
                      renderNextPage();
                    });
                  });
                }
              }

              // Start rendering the first page
              renderNextPage();
            })
            .catch((error) => {
              console.error("Error opening the PDF file:", error);
            });
          // Set the filename in the toolbar
          filename.textContent = attributes.name;

          // Download button click event handler
          downloadButton.addEventListener("click", () => {
            const a = document.createElement("a");
            a.href = attributes.src;
            a.download = attributes.name;
            a.click();
          });

          // Print button click event handler
          printButton.addEventListener("click", () => {
            window.print();
          });
        }, 1000);
      } else {
        const anchor = document.createElement("a");

        if (node.attrs.textAlign) {
          span.style.justifyContent = node.attrs.textAlign;
        }

        if (!attributes.src) {
          checkUploadStatus(span, editor, getPos, node.attrs, "file");
        } else {
          anchor.innerHTML = attributes.name;
          if (
            location.href.indexOf("broadcast") >= 0 ||
            location.href.indexOf("settings/main") >= 0
          ) {
            anchor.setAttribute("target", "_blank");
            anchor.setAttribute("href", attributes.src);
            anchor.setAttribute("clicktracking", "off");
          }

          let fileImageIcon = "other";
          const extension = attributes.extension
            ? attributes.extension.toLowerCase()
            : attributes.name.split(".").pop();

          if (extension == "doc" || extension == "docx") {
            fileImageIcon = "doc";
          } else if (extension == "html") {
            fileImageIcon = "html";
          } else if (extension == "key") {
            fileImageIcon = "key";
          } else if (extension == "pages") {
            fileImageIcon = "pages";
          } else if (extension == "pdf") {
            fileImageIcon = "pdf";
          } else if (extension == "ppt" || extension == "pptx") {
            fileImageIcon = "ppt";
          } else if (extension == "txt") {
            fileImageIcon = "txt";
          } else if (extension == "xls" || extension == "xlsx") {
            fileImageIcon = "xls";
          } else if (extension == "zip" || extension == "zip") {
            fileImageIcon = "zip";
          }

          const img = document.createElement("img");
          img.setAttribute(
            "src",
            `https://sutra.co/img/icons/file_icon_${fileImageIcon}.png`
          );
          if (
            location.href.indexOf("broadcast") >= 0 ||
            location.href.indexOf("settings/main") >= 0
          ) {
            img.style.width = "50px";
            img.style.display = "none";
          }

          span.append(img);
          span.append(anchor);

          if (!editor.isEditable) {
            span.addEventListener("click", (event) => {
              const extension = attributes.src.split(".").pop();

              window.open(node.attrs.src, "_blank");
            });
          }
        }
      }

      dom.append(span);

      checkInteractionAndHideIfNeeded(editor, dom, getPos);

      //setUniqId(editor, node, getPos, dom);

      return {
        dom,
        contentDOM: span,
        ignoreMutation(mutation) {
          return true;
        },
      };
    };
  },

  addCommands() {
    return {
      setFile:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
});

export default FileNode;
