import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextInput,
  Group,
  ActionIcon,
  createStyles,
  Checkbox,
} from "@mantine/core";

import { GripVertical, X } from "tabler-icons-react";

import { Draggable } from "react-beautiful-dnd";

import AnswerModel from "../../../../models/Answer";

import MultipleItem from "./MultipleItem";
import SingleItem from "./SingleItem";
import PaymentItem from "./PaymentItem";

import { colors } from "../../../../collections/colors";

import Swal from "sweetalert2";

const useStyles = createStyles((theme) => ({
  dragHandle: {
    ...theme.fn.focusStyles(),
    height: "100%",
    color: theme.colors.gray[6],
    cursor: "move",
    paddingTop: 3,
    width: 20,
    maxWidth: 20,
  },
}));

let timerId = null;

export default function AnswerItem(props) {
  const { classes, cx } = useStyles();

  const [content, setContent] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [answerId, setAnswerId] = useState("");
  const [frequency, setFrequency] = useState("once");
  const [description, setDescription] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const timeoutId = useRef(null);

  useEffect(() => {
    if (props.answer && !props.answer.id && props.answer.new) {
      setDisabled(true);
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(() => {
        onCreate();
      }, 2000);
    } else if (props.answer) {
      setAnswerId(props.answer.id);

      if (props.answer.content) {
        setContent(props.answer.content);
      }

      if (props.answer.payment_amount) {
        setPaymentAmount(props.answer.payment_amount);
      }

      if (props.answer.description) {
        setDescription(props.answer.description);
      }

      if (props.answer.frequency) {
        setFrequency(props.answer.frequency);
      }

      setIsCorrect(props.answer.is_correct);
    }
  }, [props]);

  const onContentChange = (value) => {
    setContent(value);
    onUpdate(value, paymentAmount, isCorrect, frequency,  description);
  };

  const onIsCorrectChange = (value) => {
    setIsCorrect(value);
    onUpdate(content, paymentAmount, value, frequency,  description);
  };

  const onPaymentAmountChange = (value) => {
    setPaymentAmount(value);
    onUpdate(content, value, isCorrect, frequency,  description);
  };

  const onFrequencyChange = (value) => {
    setFrequency(value);
    onUpdate(content, paymentAmount, isCorrect, value,  description);
  };

  const onDescriptionChange = (value) => {
    setDescription(value);
    onUpdate(content, paymentAmount, isCorrect, frequency,  value);
  };

  const onCreate = () => {
    if (props.answer.tmp_id != window.$tmpAnswersId) {
      return;
    }
    setDisabled(true);
    AnswerModel.onCreate(
      props.spaceId,
      props.surveyId,
      props.questionId,
      { content: props.answer.content },
      (data) => {
        setAnswerId(data.id);

        props.onUpdate(
          { id: data.id, content: props.answer.content },
          props.index
        );
        setDisabled(false);
      },
      (data) => {}
    );
  };

  const onUpdate = (
    _content,
    _payment_amount,
    _is_checked,
    _frequency,
    _description
  ) => {
    if (!answerId) {
      return;
    }
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      AnswerModel.onUpdate(
        answerId,
        props.spaceId,
        props.surveyId,
        props.questionId,
        {
          content: _content,
          payment_amount: _payment_amount,
          is_correct: _is_checked,
          frequency: _frequency,
          description: _description,
        },
        (data) => {
          props.onUpdate(
            {
              id: answerId,
              content: _content,
              payment_amount: _payment_amount,
              is_correct: _is_checked,
              frequency: _frequency,
              description: _description,
            },
            props.index
          );
        },
        (data) => {}
      );
    }, 1300);
  };

  const onDestroy = () => {
    if (!answerId || props.answers.length == 1) {
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      html:
        props.type !== "payment"
          ? "All collected option responses will be deleted."
          : "",
      showDenyButton: true,
      confirmButtonText: "Yes, delete",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        AnswerModel.onDestroy(
          answerId,
          props.spaceId,
          props.surveyId,
          props.questionId,
          (data) => {
            props.onDestroy({ id: answerId });
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <Draggable
      key={`answer-${answerId}`}
      index={props.index}
      draggableId={`answer-${answerId}`}
    >
      {(provided, snapshot) => (
        <Group
          mt={15}
          spacing={0}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Box className={classes.dragHandle} {...provided.dragHandleProps}>
            <GripVertical size={18} />
          </Box>
          {props.type == "multiple" ? (
            <MultipleItem
              content={content}
              onContentChange={onContentChange}
              onDestroy={onDestroy}
              quizMode={props.quizMode}
              isCorrect={isCorrect}
              onIsCorrectChange={onIsCorrectChange}
              disabled={disabled}
              answers={props.answers}
            />
          ) : (
            ""
          )}
          {props.type == "single" ? (
            <SingleItem
              content={content}
              onContentChange={onContentChange}
              onDestroy={onDestroy}
              quizMode={props.quizMode}
              isCorrect={isCorrect}
              onIsCorrectChange={onIsCorrectChange}
              disabled={disabled}
              answers={props.answers}
            />
          ) : (
            ""
          )}
          {props.type == "payment" ? (
            <PaymentItem
              content={content}
              paymentAmount={paymentAmount}
              description={description}
              frequency={frequency}
              onContentChange={onContentChange}
              onPaymentAmountChange={onPaymentAmountChange}
              onDescriptionChange={onDescriptionChange}
              onFrequencyChange={onFrequencyChange}
              onDestroy={onDestroy}
              currency={props.currency}
              disabled={disabled}
            />
          ) : (
            ""
          )}
          {props.type == "freeform" ? (
            <>
              {props.quizMode && (
                <Box
                  sx={{
                    width: 30,
                  }}
                >
                  <Checkbox
                    checked={true}
                    styles={{
                      root: {},
                      input: {
                        marginTop: -1,
                      },
                    }}
                  />
                </Box>
              )}
              <TextInput
                placeholder="Response text"
                disabled
                radius="sm"
                size="md"
                sx={(theme) => ({
                  width: `calc(100% - ${props.quizMode ? 50 : 20}px)`,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                })}
                rightSection={
                  <ActionIcon variant="transparent" onClick={() => onDestroy()}>
                    <X size={18} />
                  </ActionIcon>
                }
                rightSectionWidth={40}
              />
            </>
          ) : (
            ""
          )}
        </Group>
      )}
    </Draggable>
  );
}
