// import axios from 'axios';

class DirectMessage {
  constructor() {
    if (this instanceof DirectMessage) {
      throw Error('DirectMessage class cannot be instantiated.');
    }
  }

  static onFetchOrCreate = async (user_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.post(`/api/v4/users/${user_id}/direct_messages.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

}

export default DirectMessage;
