import axios from 'axios';

class Reflection {
  constructor() {
    if (this instanceof Reflection) {
      throw Error('Reflection class cannot be instantiated.');
    }
  }

  static onFetch = async (pod_message_id, page, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      pod_message_id: pod_message_id
    }

    await axios.get(`/api/v4/reflections.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onCreate = async (pod_message_id, {json, html}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      json,
      html,
      pod_message_id: pod_message_id
    }

    await axios.post(`/api/v4/reflections.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onUpdate = async (id, {json, html}, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      json,
      html,
    }

    await axios.put(`/api/v4/reflections/${id}.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onDestroy = async (id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await axios.delete(`/api/v4/reflections/${id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onFetchUnread = async (pod_message_id, space_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      pod_message_id,
      space_id
    }

    await axios.get(`/api/v4/reflections/unread.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }
}

export default Reflection;
