// import axios from 'axios';

class Journey {
  constructor() {
    if (this instanceof Journey) {
      throw Error('Journey class cannot be instantiated.');
    }
  }

  static onFetch = async (slug, paginator, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ...paginator
    }

    await window.$api.get(`/api/v4/spaces/${slug}/journeys.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onFetchById = async (journey_id, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    }

    await window.$api.get(`/api/v4/spaces/${slug}/journeys/${journey_id}.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onFetchByTrigger = async (trigger, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      trigger
    }

    await window.$api.get(`/api/v4/spaces/${slug}/journeys/trigger.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onFetchActions = async (trigger, position, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      trigger,
      position
    }

    await window.$api.get(`/api/v4/spaces/${slug}/journeys/actions.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onCreate = async (slug, journey, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      journey
    }

    await window.$api.post(`/api/v4/spaces/${slug}/journey.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onUpdate = async (journey_id, slug, journey, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      journey
    }

    await window.$api.put(`/api/v4/spaces/${slug}/journeys/${journey_id}.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  // static onDestroy = async (journey_id, slug, sucessCallback, errorCallback) => {
  //   const params = {
  //     locale: window.$currentUserLanguage,
  //     auth_token: window.$currentUserAuthToken
  //   }
  //
  //   await window.$api.delete(`/api/v4/spaces/${slug}/journeys/${journey_id}.json`, {params})
  //   .then(res => {
  //     sucessCallback(res.data)
  //   })
  //   .catch((error) => {
  //     errorCallback(error.response.data)
  //   });
  // }

}

export default Journey;
