export function camelCase(str) {
  return str
    .split("-")
    .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1));
}

export function stylesToString(styles, applyCamelCase = false) {
  if (typeof styles == "string") {
    return styles;
  }

  let stylesStr = ``;
  const stylesKeys = Object.keys(styles);


  
  for (var i = 0; i < stylesKeys.length; i++) {
    const key = stylesKeys[i];
    if (key == "0" || key == 0) {
      
      return Object.values(styles).join("")
    }
    const value = styles[key];
    if (applyCamelCase) {
      stylesStr += `${camelCase(key)}: ${value};`;
    } else {
      stylesStr += `${key}: ${value};`;
    }
  }

  if (typeof stylesStr == "Array") {
    return "";
  }

  return stylesStr;
}

export function stylesToCamelCase(styles) {
  if (typeof styles == "string" || !styles) {
    return {};
  }

  let stylesStr = {};
  const stylesKeys = Object.keys(styles);

  for (var i = 0; i < stylesKeys.length; i++) {
    const key = stylesKeys[i];

    if (key == "0" || key == 0) {
      const fixString = Object.values(styles).join("")
      return stylesToCamelCase(fixStringToJSON(fixString))
    }

    const value = styles[key];

    stylesStr[camelCase(key)] = value;
  }

  return stylesStr;
}

export function fixStringToJSON(string) {
  const styleString = string;

  const styleArray = styleString.split(";");
  const styleObject = {};

  styleArray.forEach((item) => {
    const [key, value] = item.split(":");
    if (key && value){
      styleObject[key.trim()] = value.trim();
    }
  });

  return styleObject;
}
