import React, { useState, useEffect, useRef } from "react";
import { BubbleMenu } from "@tiptap/react";
import { isTextSelection } from "@tiptap/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { onDragStart } from "../plugins/EventHandler";

import {
  createStyles,
  Group,
  Button,
  Menu,
  Paper,
  ActionIcon,
  Box,
  Image,
  Text,
  ColorPicker,
  TextInput,
  Stack,
  Popover,
  Tooltip,
  MantineProvider,
  NativeSelect,
} from "@mantine/core";

import TextIcon from "../../../src/images/buttons/text.png";
import Heading1Icon from "../../../src/images/buttons/heading1.png";
import Heading2Icon from "../../../src/images/buttons/heading2.png";
import Heading3Icon from "../../../src/images/buttons/heading2.png";
import BulletIcon from "../../../src/images/buttons/list_bullet.png";
import NumberedIcon from "../../../src/images/buttons/list_ordered.png";
import SpaceModel from "../../../models/Space";
import colorPickerSwatches from "../../../utils/ColorSwatches";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Swal from "sweetalert2";

import {
  getLeaf,
  getSimpleStructure,
  getListOfNodes,
} from "../utils/selection";

const useStyles = createStyles((theme) => ({
  colorButton: {
    color: theme.colors.gray[7],
    padding: "4px 4px",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  button: {},
  isActive: {
    backgroundColor: theme.colors.gray[2],
  },
  menuButton: {
    border: "none",
    height: 28,
  },
  menuWrap: {
    cursor: "pointer",
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
}));

const FormatMenu = (props) => {
  const { t, i18n } = useTranslation();
  const { cx, theme, classes } = useStyles();
  const [isMultiple, setIsMultiple] = useState(false);
  const [isOnlyText, setIsOnlyText] = useState(false);
  const [colorOpened, setColorOpened] = useState(false);
  const [colorHOpened, setColorHOpened] = useState(false);
  const [textColor, onTextColorChange] = useState("#000");
  const [auxTextColor, setAuxTextColor] = useState("#000");
  const [highlightColor, onHighlightColorChange] = useState("#BBB");
  const [auxHighlightColor, setAuxHighlightColor] = useState("#BBB");
  const [showInsides, setShowInsides] = useState(false);
  const [linkOpened, setLinkOpened] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkTarget, setLinkTarget] = useState("_current");
  const [linkFormat, setLinkFormat] = useState("text");
  const [isMouseDragging, setIsMouseDragging] = useState(false);
  const [sectionID, setSectionID] = useState(null);
  const timeoutId = useRef(null);
  const isMouseDown = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setMouseEvents();

    return () => {
      unsetMouseEvents();
    };
  }, []);

  useEffect(() => {
    if (colorOpened || colorHOpened || linkOpened) {
      setIsMouseDragging(false);
      setShowInsides(true);
    }
  }, [colorOpened, colorHOpened, linkOpened]);

  useEffect(() => {
    if (!showInsides) {
      // setColorOpened(false);
      // setColorHOpened(false);
      // setLinkOpened(false);
    }
  }, [showInsides]);

  useEffect(() => {
    if (props.editor) {
      const actualColor = props.editor.getAttributes("textStyle").color;
      if (actualColor != textColor) {
        props.editor.chain().focus().setColor(textColor).run();
      }
    }
  }, [textColor]);

  useEffect(() => {
    if (props.editor) {
      const actualColor = props.editor.getAttributes("highlight").color;
      if (actualColor != highlightColor) {
        props.editor.commands.setHighlight({ color: highlightColor });
      }
    }
  }, [highlightColor]);

  const handleMouseDown = (event) => {
    isMouseDown.current = true;
    setIsMouseDragging(false);
    setShowInsides(true);
  };

  const handleMouseMove = (event) => {
    if (isMouseDown.current) {
      setIsMouseDragging(true);
      setShowInsides(false);
      // Track the mouse drag event here
      console.log("Mouse dragging");
    }
  };

  const handleMouseUp = (event) => {
    isMouseDown.current = false;
    setIsMouseDragging(false);
    setShowInsides(true);
  };

  const setMouseEvents = () => {
    // document.addEventListener("mousedown", handleMouseDown);
    // document.addEventListener("mousemove", handleMouseMove);
    // document.addEventListener("mouseup", handleMouseUp);
  };

  const unsetMouseEvents = () => {
    // document.removeEventListener("mousedown", handleMouseDown);
    // document.removeEventListener("mousemove", handleMouseMove);
    // document.removeEventListener("mouseup", handleMouseUp);
  };

  const onChangeColor = (value, callback, auxCallback) => {
    auxCallback(value);
    if (timeoutId) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      callback(value);
    }, 1000);
  };

  const shouldShow = (view, state, from, to) => {
    // if (isMouseDragging) {
    //   console.log("NOT SHOW");
    //   return false;
    // }

    const { doc, selection } = state;
    const { empty } = selection;

    const slice = selection.content();

    // Sometime check for `empty` is not enough.
    // Doubleclick an empty paragraph returns a node size of 2.
    // So we check also for an empty text size.
    const isEmptyTextBlock =
      !doc.textBetween(from, to).length && isTextSelection(state.selection);

    const listTypes = getListOfNodes(props.editor);

    const onlyTextNodes = listTypes.some(
      (r) => props.excludedNodes.indexOf(r) < 0
    );
    const exludedFoundAux =
      props.excludedNodes.indexOf(
        selection.node
          ? selection.node.type.name
          : selection.$anchor.parent.type.name
      ) >= 0;

    setIsMultiple(slice.content.content.length > 1);
    setIsOnlyText(onlyTextNodes);

    if (colorHOpened) {
      setColorHOpened(false);
    }

    const actualColor = props.editor.getAttributes("textStyle").color;
    const actualHighlightColor = props.editor.getAttributes("highlight").color;
    setAuxTextColor(actualColor);
    onTextColorChange(actualColor);
    setAuxHighlightColor(actualHighlightColor);
    onHighlightColorChange(actualHighlightColor);
    const link = props.editor.getAttributes("link");
    if (link && !linkOpened) {
      setLinkUrl(link.href);
      setLinkTarget(link.target == "_blank" ? "_blank" : "_current");
    }
    setLinkFormat("text");
    // setAuxHighlightColor(actualColor)

    if (exludedFoundAux) {
      setShowInsides(false);
      return false;
    }

    if (!view.hasFocus() || empty || isEmptyTextBlock) {
      setShowInsides(false);
      return false;
    }

    setShowInsides(true);
    return true;
  };

  const getFormat = () => {
    if (props.editor.isActive("paragraph")) {
      return t("tiptap.components.formatMenu.text");
    } else if (props.editor.isActive("heading", { level: 1 })) {
      return t("tiptap.components.formatMenu.heading1");
    } else if (props.editor.isActive("heading", { level: 2 })) {
      return t("tiptap.components.formatMenu.heading2");
    } else if (props.editor.isActive("heading", { level: 3 })) {
      return t("tiptap.components.formatMenu.heading3");
    } else if (props.editor.isActive("heading", { level: 4 })) {
      return t("tiptap.components.formatMenu.largeText");
    } else if (props.editor.isActive("heading", { level: 5 })) {
      return t("tiptap.components.formatMenu.smallText");
    } else if (props.editor.isActive("bulletList")) {
      return t("tiptap.components.formatMenu.bulletedList");
    } else if (props.editor.isActive("orderedList")) {
      return t("tiptap.components.formatMenu.orderedList");
    } else {
      return t("tiptap.components.formatMenu.text2");
    }
  };

  const getAlignment = () => {
    if (props.editor.isActive({ textAlign: "left" })) {
      return <FontAwesomeIcon size="sm" icon={solid("align-left")} />;
    } else if (props.editor.isActive({ textAlign: "center" })) {
      return <FontAwesomeIcon size="sm" icon={solid("align-center")} />;
    } else if (props.editor.isActive({ textAlign: "right" })) {
      return <FontAwesomeIcon size="sm" icon={solid("align-right")} />;
    } else {
      return <FontAwesomeIcon size="sm" icon={solid("align-left")} />;
    }
  };

  const getOldAttrs = () => {
    // const rangeStr = prompt("range")
    // props.editor.view.state.tr.setBlockType(props.editor.view.state.selection.$head, null, props.editor.view.state.schema.nodes.heading)
    // props.editor.view.dispatch(props.editor.view.state.tr.setBlockType(window.$tiptapAchorPos, null, props.editor.view.state.schema.nodes.heading))
    const { from, to } = props.editor.view.state.selection;

    const leafContent = getLeaf(props.editor);
    const selection = props.editor.view.state.selection;
    const { $anchor, $head, anchor, head } = selection;
    const content = props.editor.view.state.selection.content().content.content;

    const pivot = anchor < head ? $anchor : $head;

    // LOOK FOR LEAF NODE
    if ($anchor.depth > 1) {
      const auxNode = $anchor.path[9];
      return {
        styles: { ...auxNode.attrs.styles },
        textAlign: auxNode.attrs.textAlign,
      };
    } else {
      return {
        styles: { ...content[0].attrs.styles },
        textAlign: content[0].attrs.textAlign,
      };
    }
  };

  const onUnsetLink = () => {
    props.editor.commands.unsetLink();
    setLinkOpened(false);
  };

  const onSetLink = () => {
    if (linkFormat == "text") {
      if (
        linkUrl.indexOf("http://") < 0 &&
        linkUrl.indexOf("https://") < 0 &&
        linkUrl.indexOf("mailto://") < 0 &&
        linkUrl.indexOf("tel://") < 0
      ) {
        props.editor.commands.setLink({
          href: `https://${linkUrl}`,
          target: linkTarget == "_blank" ? "_blank" : null,
        });
      } else {
        props.editor.commands.setLink({
          href: linkUrl,
          target: linkTarget == "_blank" ? "_blank" : null,
        });
      }
    } else {
      SpaceModel.onFetchLink(
        linkUrl,
        (data) => {
          props.editor
            .chain()
            .enter()
            .insertContent({
              type: "fancyLink",
              attrs: {
                link: {
                  url: linkUrl,
                  target: linkTarget == "_blank" ? "_blank" : null,
                  title: data.data.title,
                  description: data.data.description,
                  image: {
                    url: data.data.image.url,
                  },
                },
              },
            })
            .run();
        },
        (data) => {
          Swal.fire(data.title, data.message, "error");
        }
      );
    }
    setLinkOpened(false);
  };

  if (!props.editor) {
    return null;
  }

  const onButtonDragClick = (e) => {
    // onDragClick(e,  props.editor.view)
  };

  const onButtonDragStart = (e) => {
    const { from, to } = props.editor.view.state.selection;
    window.$tiptapLastSelectionRange = { from, to };

    setTimeout(() => {
      const slice = onDragStart(e, window.$tiptapAchorPos, props.editor);
      const nodes = getSimpleStructure(props.editor, slice);

      // - If 1 node check if only 1 circle
      // - - Perform current logic
      // - If 1 node and NOT a circle || Multiple nodes
      // - - Perform backend "cut and paste" logic

      window.$draggingNode = {
        nodes: nodes,
        parent: "current",
        outsider: true,
        selection_type: "multiple",
      };

      // if (nodes.length == 1){
      //   if (isSingleCircleNode(props.editor, slice)){
      //     const nodeLeaf = getLeaf(editor, slice)
      //     draggingNode.nodes.push(nodeLeaf.leaf)
      //     draggingNode.type = "circle"
      //   }
      //   else{
      //     draggingNode.nodes.push(nodeLeaf.leaf.attrs.id)
      //     draggingNode.type = "circle"
      //   }
      // }

      // dispatch(setSidebarDragging(draggingNode));
    }, 200);
  };

  return (
    <BubbleMenu
      editor={props.editor}
      shouldShow={({ view, state, from, to }) =>
        shouldShow(view, state, from, to)
      }
      tippyOptions={{ duration: 100, maxWidth: "none" }}
    >
      {showInsides && (
        <Paper shadow="sm" radius="md" withBorder sx={{ padding: 0 }}>
          <Group spacing="0">
            {isOnlyText && (
              <>
                <Menu
                  position="bottom"
                  placement="start"
                  size="md"
                  p={5}
                  // closeOnItemClick={false}
                  className={classes.menuWrap}
                  gutter={10}
                  styles={{
                    item: {
                      padding: "5px 10px",
                    },
                  }}
                  control={
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label={t("tiptap.components.formatMenu.label1")}
                      position="top"
                      withArrow
                      zIndex={999999}
                    >
                      <Button
                        //onClick={() => props.editor.chain().focus().toggleBold().run()}
                        // className={props.editor.isActive('bold') ? `isActive ${classes.button}` : `${classes.button}`}
                        variant="default"
                        className={classes.menuButton}
                        rightIcon={
                          <FontAwesomeIcon
                            size="sm"
                            color={theme.colors.gray[5]}
                            icon={solid("angle-down")}
                          />
                        }
                        compact
                      >
                        {getFormat()}
                      </Button>
                    </Tooltip>
                  }
                >
                  <Menu.Item
                    onClick={() =>
                      props.editor
                        .chain().toggleHeading({
                          level: 5,
                          ...getOldAttrs(),
                        })
                        .setParagraph()
                        .updateAttributes("paragraph", getOldAttrs())
                        .run()
                    }
                    // className={props.editor.isActive('paragraph') ? `isActive ${classes.button}` : `${classes.button}`}
                  >
                    <Group spacing={10}>
                      <Image width={"20px"} src={TextIcon} />
                      <Text>{t("tiptap.components.formatMenu.text3")}</Text>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      props.editor.commands.toggleHeading({
                        level: 5,
                        ...getOldAttrs(),
                      })
                    }
                    // className={props.editor.isActive('paragraph') ? `isActive ${classes.button}` : `${classes.button}`}
                  >
                    <Group spacing={10}>
                      <Image width={"20px"} src={TextIcon} />
                      <Text>{t("tiptap.components.formatMenu.smallText")}</Text>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      props.editor.commands.toggleHeading({
                        level: 4,
                        ...getOldAttrs(),
                      })
                    }
                    // className={props.editor.isActive('paragraph') ? `isActive ${classes.button}` : `${classes.button}`}
                  >
                    <Group spacing={10}>
                      <Image width={"20px"} src={TextIcon} />
                      <Text>{t("tiptap.components.formatMenu.largeText")}</Text>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      props.editor.commands.toggleHeading({
                        level: 1,
                        ...getOldAttrs(),
                      })
                    }
                    // className={props.editor.isActive('heading', { level: 1 }) ? `isActive ${classes.button}` : `${classes.button}`}
                  >
                    <Group spacing={10}>
                      <Image width={"20px"} src={Heading1Icon} />
                      <Text>{t("tiptap.components.formatMenu.heading1")}</Text>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      props.editor.commands.toggleHeading({
                        level: 2,
                        ...getOldAttrs(),
                      })
                    }
                    // className={props.editor.isActive('heading', { level: 2 }) ? `isActive ${classes.button}` : `${classes.button}`}
                  >
                    <Group spacing={10}>
                      <Image width={"20px"} src={Heading2Icon} />
                      <Text>{t("tiptap.components.formatMenu.heading2")}</Text>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      props.editor.commands.toggleHeading({
                        level: 3,
                        ...getOldAttrs(),
                      })
                    }
                    // className={props.editor.isActive('heading', { level: 3 }) ? `isActive ${classes.button}` : `${classes.button}`}
                  >
                    <Group spacing={10}>
                      <Image width={"20px"} src={Heading3Icon} />
                      <Text>{t("tiptap.components.formatMenu.heading3")}</Text>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      props.editor
                        .chain()
                        .focus()
                        .toggleBulletList()
                        .updateAttributes("bulbulletList", getOldAttrs())
                        .run()
                    }
                    // className={props.editor.isActive('bulletList') ? `isActive ${classes.button}` : `${classes.button}`}
                  >
                    <Group spacing={10}>
                      <Image width={"20px"} src={BulletIcon} />
                      <Text>
                        {t("tiptap.components.formatMenu.bulletedList")}
                      </Text>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      props.editor
                        .chain()
                        .focus()
                        .toggleOrderedList()
                        .updateAttributes("orderedList", getOldAttrs())
                        .run()
                    }
                    // className={props.editor.isActive('orderedList') ? `isActive ${classes.button}` : `${classes.button}`}
                  >
                    <Group spacing={10}>
                      <Image width={"20px"} src={NumberedIcon} />
                      <Text>
                        {t("tiptap.components.formatMenu.numberedList")}
                      </Text>
                    </Group>
                  </Menu.Item>
                </Menu>
                <Menu
                  zIndex={99999}
                  position="bottom"
                  placement="start"
                  size="md"
                  p={5}
                  // closeOnItemClick={false}
                  className={classes.menuWrap}
                  gutter={10}
                  styles={{
                    item: {
                      padding: "5px 10px",
                    },
                  }}
                  control={
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label={t("tiptap.components.formatMenu.label2")}
                      position="top"
                      withArrow
                      zIndex={999999}
                    >
                      <Button
                        variant="default"
                        className={classes.menuButton}
                        rightIcon={
                          <FontAwesomeIcon
                            size="sm"
                            color={theme.colors.gray[5]}
                            icon={solid("angle-down")}
                          />
                        }
                        compact
                      >
                        {getAlignment()}
                      </Button>
                    </Tooltip>
                  }
                >
                  <Menu.Item
                    onClick={() =>
                      props.editor.chain().focus().setTextAlign("left").run()
                    }
                  >
                    <Group spacing={10}>
                      <FontAwesomeIcon size="sm" icon={solid("align-left")} />
                      <Text>{t("tiptap.components.formatMenu.left")}</Text>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      props.editor.chain().focus().setTextAlign("center").run()
                    }
                  >
                    <Group spacing={10}>
                      <FontAwesomeIcon size="sm" icon={solid("align-center")} />
                      <Text>{t("tiptap.components.formatMenu.center")}</Text>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      props.editor.chain().focus().setTextAlign("right").run()
                    }
                  >
                    <Group spacing={10}>
                      <FontAwesomeIcon size="sm" icon={solid("align-right")} />
                      <Text>{t("tiptap.components.formatMenu.right")}</Text>
                    </Group>
                  </Menu.Item>
                </Menu>
              </>
            )}
            <Group p={5} spacing={8}>
              <Popover
                zIndex={999999}
                opened={linkOpened}
                onClose={() => setLinkOpened(false)}
                target={
                  <ActionIcon
                    onClick={() => setLinkOpened(true)}
                    className={cx(classes.colorButton, {
                      [classes.isActive]: props.editor.isActive("link"),
                    })}
                    sx={(theme) => ({
                      cursor: "pointer",
                    })}
                  >
                    {props.editor.isActive("link") ? (
                      <FontAwesomeIcon icon={solid("link")} size="sm" />
                    ) : (
                      <FontAwesomeIcon icon={solid("link")} size="sm" />
                    )}
                  </ActionIcon>
                }
                position="bottom"
                gutter={15}
              >
                <Stack mt={20}>
                  <NativeSelect
                    value={linkTarget}
                    onChange={(event) => setLinkTarget(event.target.value)}
                    label={t("tiptap.components.formatMenu.label3")}
                    description={t(
                      linkTarget === "_section"
                        ? "tiptap.components.formatMenu.description3"
                        : "tiptap.components.formatMenu.description"
                    )}
                    placeholder={t("tiptap.components.formatMenu.placeholder")}
                    data={[
                      {
                        value: "_current",
                        label: t("tiptap.components.formatMenu.label4"),
                      },
                      {
                        value: "_blank",
                        label: t("tiptap.components.formatMenu.label5"),
                      },
                      {
                        value: "_section",
                        label: t("tiptap.components.formatMenu.label14"),
                      },
                    ]}
                    styles={{
                      label: {
                        fontWeight: 400,
                        color: "#000",
                      },
                    }}
                  />
                  {linkTarget === "_section" ? (
                    <>
                      <Text size="xs" mb={0} pb={0}>
                        {t("tiptap.components.formatMenu.description4")}
                      </Text>
                      <Text size="xs" mt={0}>
                        {t("tiptap.components.formatMenu.description5")}
                      </Text>
                      <Button
                        radius="xl"
                        onClick={() => {
                          window.$sectionParams = {
                            origin: {
                              from: props.editor.view.state.selection.from,
                              to: props.editor.view.state.selection.to,
                            },
                            isSelection: true,
                            swalTitle: t(
                              "tiptap.components.formatMenu.label15"
                            ),
                          };
                          setLinkOpened(false);
                        }}
                      >
                        {t("tiptap.components.formatMenu.selectSection")}
                      </Button>
                    </>
                  ) : (
                    <>
                      <TextInput
                        mt={5}
                        value={linkUrl}
                        onChange={(event) =>
                          setLinkUrl(event.currentTarget.value)
                        }
                        placeholder={t("tiptap.components.formatMenu.label16")}
                        label={t("tiptap.components.formatMenu.label16")}
                        description={t(
                          "tiptap.components.formatMenu.description6"
                        )}
                        styles={{
                          label: {
                            fontWeight: 400,
                            color: "#000",
                          },
                        }}
                      />
                      <NativeSelect
                        value={linkFormat}
                        onChange={(event) => setLinkFormat(event.target.value)}
                        label={t("tiptap.components.formatMenu.label6")}
                        description={t(
                          "tiptap.components.formatMenu.description2"
                        )}
                        placeholder={t(
                          "tiptap.components.formatMenu.placeholder2"
                        )}
                        data={[
                          {
                            value: "text",
                            label: t("tiptap.components.formatMenu.label7"),
                          },
                          {
                            value: "fancy",
                            label: t("tiptap.components.formatMenu.label8"),
                          },
                        ]}
                        styles={{
                          label: {
                            fontWeight: 400,
                            color: "#000",
                          },
                        }}
                      />
                    </>
                  )}
                  <Group position="apart">
                    {linkTarget !== "_section" && (
                      <Button radius="xl" onClick={() => onSetLink()}>
                        {t("tiptap.components.formatMenu.save")}
                      </Button>
                    )}
                    {linkUrl && (
                      <Button
                        radius="xl"
                        color="red"
                        onClick={() => onUnsetLink()}
                      >
                        {t("tiptap.components.formatMenu.delete")}
                      </Button>
                    )}
                  </Group>
                </Stack>
              </Popover>
              
              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label="Bold"
                position="top"
                withArrow
                zIndex={999999}
              >
                <ActionIcon
                  onClick={() =>
                    props.editor.chain().focus().toggleBold().run()
                  }
                  className={cx(classes.button, {
                    [classes.isActive]: props.editor.isActive("bold"),
                  })}
                >
                  <FontAwesomeIcon size="sm" icon={solid("bold")} />
                </ActionIcon>
              </Tooltip>

              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label="Italic"
                position="top"
                withArrow
                zIndex={999999}
              >
                <ActionIcon
                  onClick={() =>
                    props.editor.chain().focus().toggleItalic().run()
                  }
                  className={cx(classes.button, {
                    [classes.isActive]: props.editor.isActive("italic"),
                  })}
                >
                  <FontAwesomeIcon size="sm" icon={solid("italic")} />
                </ActionIcon>
              </Tooltip>

              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label={t("tiptap.components.formatMenu.label9")}
                position="top"
                withArrow
                zIndex={999999}
              >
                <ActionIcon
                  onClick={() =>
                    props.editor.chain().focus().toggleStrike().run()
                  }
                  className={cx(classes.button, {
                    [classes.isActive]: props.editor.isActive("strike"),
                  })}
                >
                  <FontAwesomeIcon size="sm" icon={solid("strikethrough")} />
                </ActionIcon>
              </Tooltip>

              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label={t("tiptap.components.formatMenu.label10")}
                position="top"
                withArrow
                zIndex={999999}
              >
                <ActionIcon
                  onClick={() =>
                    props.editor.chain().focus().toggleUnderline().run()
                  }
                  className={cx(classes.button, {
                    [classes.isActive]: props.editor.isActive("underline"),
                  })}
                >
                  <FontAwesomeIcon size="sm" icon={solid("underline")} />
                </ActionIcon>
              </Tooltip>
              {/*<ActionIcon
                onClick={() => props.editor.chain().focus().setTextAlign('left').run()}
                className={cx(classes.button, { [classes.isActive]: props.editor.isActive('align-left') })}
                >
                <FontAwesomeIcon size="sm" icon={solid('align-left')} />
                </ActionIcon>
                <ActionIcon
                onClick={() => props.editor.chain().focus().setTextAlign('center').run()}
                className={cx(classes.button, { [classes.isActive]: props.editor.isActive('align-center') })}
                >
                <FontAwesomeIcon size="sm" icon={solid('align-center')} />
                </ActionIcon>
                <ActionIcon
                onClick={() => props.editor.chain().focus().setTextAlign('right').run()}
                className={cx(classes.button, { [classes.isActive]: props.editor.isActive('align-right') })}
                >
                <FontAwesomeIcon size="sm" icon={solid('align-right')} />
                </ActionIcon>*/}
              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label={t("tiptap.components.formatMenu.label11")}
                position="top"
                withArrow
                zIndex={999999}
              >
                <ActionIcon
                  onClick={() =>
                    props.editor.chain().focus().toggleCode().run()
                  }
                  className={cx(classes.button, {
                    [classes.isActive]: props.editor.isActive("code"),
                  })}
                >
                  <FontAwesomeIcon size="sm" icon={solid("code")} />
                </ActionIcon>
              </Tooltip>
              <Popover
                opened={colorOpened}
                onClose={() => setColorOpened(false)}
                target={
                  /*<ColorSwatch
                    key={textColor}
                    color={textColor}
                    onClick={() => setColorOpened((o) => !o)}
                    size={20} />
                    */
                  <Tooltip
                    disabled={window.$isTouchDevice && window.$isTouchDevice()}
                    label={t("tiptap.components.formatMenu.label12")}
                    position="top"
                    withArrow
                    zIndex={999999}
                  >
                    <Stack
                      onClick={() => setColorOpened((o) => !o)}
                      spacing={1}
                      align="center"
                      className={cx(classes.colorButton)}
                      sx={(theme) => ({
                        cursor: "pointer",
                      })}
                    >
                      <FontAwesomeIcon size="sm" icon={solid("font")} />
                      <Box
                        sx={(theme) => ({
                          backgroundColor: textColor,
                          height: 5,
                          width: 20,
                          borderRadius: 3,
                        })}
                      ></Box>
                    </Stack>
                  </Tooltip>
                }
                position="bottom"
                gutter={15}
              >
                <MantineProvider theme={{ primaryShade: 9 }}>
                  <ColorPicker
                    swatches={colorPickerSwatches(theme, "dark")}
                    swatchesPerRow={8}
                    format="hex"
                    value={textColor}
                    onChange={onTextColorChange}
                  />

                  <TextInput
                    mt={5}
                    mb={10}
                    placeholder={t("tiptap.components.formatMenu.placeholder3")}
                    value={auxTextColor}
                    onChange={(event) => setAuxTextColor(event.target.value)}
                  />
                  {auxTextColor && (
                    <Button
                      mb={8}
                      compact
                      fullWidth
                      variant="light"
                      color="blue"
                      onClick={() =>
                        onChangeColor(
                          auxTextColor,
                          onTextColorChange,
                          setAuxTextColor
                        )
                      }
                    >
                      {"Apply color"}
                    </Button>
                  )}

                  <Button
                    compact
                    fullWidth
                    variant="light"
                    color="gray"
                    onClick={() => props.editor.commands.unsetColor()}
                  >
                    {t("tiptap.components.formatMenu.unsetColor")}
                  </Button>
                </MantineProvider>
              </Popover>
              <Popover
                opened={colorHOpened}
                onClose={() => setColorHOpened(false)}
                target={
                  /*
                    <ColorSwatch
                    key={highlightColor}
                    color={highlightColor}
                    onClick={() => setColorHOpened((o) => !o)}
                    size={20} />
                    */
                  <Tooltip
                    disabled={window.$isTouchDevice && window.$isTouchDevice()}
                    label={t("tiptap.components.formatMenu.label13")}
                    position="top"
                    withArrow
                    zIndex={999999}
                  >
                    <Stack
                      onClick={() => setColorHOpened((o) => !o)}
                      spacing={1}
                      align="center"
                      className={cx(classes.colorButton)}
                      sx={(theme) => ({
                        cursor: "pointer",
                      })}
                    >
                      <FontAwesomeIcon size="sm" icon={solid("highlighter")} />
                      <Box
                        sx={(theme) => ({
                          backgroundColor: highlightColor,
                          height: 5,
                          width: 20,
                          borderRadius: 3,
                        })}
                      ></Box>
                    </Stack>
                  </Tooltip>
                }
                position="bottom"
                gutter={15}
              >
                <MantineProvider theme={{ primaryShade: 9 }}>
                  <ColorPicker
                    swatches={colorPickerSwatches(theme, "light")}
                    swatchesPerRow={8}
                    format="hex"
                    value={highlightColor}
                    onChange={(hex) => {
                      setColorHOpened(false);
                      onHighlightColorChange(hex);
                    }}
                  />
                  <TextInput
                    mt={5}
                    mb={10}
                    placeholder="Color Hex"
                    value={auxHighlightColor}
                    onChange={(event) =>
                      setAuxHighlightColor(event.target.value)
                    }
                  />
                  {auxHighlightColor && (
                    <Button
                      mb={8}
                      compact
                      fullWidth
                      variant="light"
                      color="blue"
                      onClick={() => {
                        onChangeColor(
                          auxHighlightColor,
                          onHighlightColorChange,
                          setAuxHighlightColor
                        );
                        setColorHOpened(false);
                      }}
                    >
                      {"Apply color"}
                    </Button>
                  )}
                  <Button
                    compact
                    fullWidth
                    variant="light"
                    color="gray"
                    onClick={() => {
                      props.editor.commands.unsetHighlight();
                      setColorHOpened(false);
                    }}
                  >
                    {t("tiptap.components.formatMenu.unsetColor")}
                  </Button>
                </MantineProvider>
              </Popover>
              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label="Clear formatting"
                position="top"
                withArrow
                zIndex={999999}
              >
                <ActionIcon
                  onClick={() =>
                    props.editor.chain().unsetAllMarks().unsetLink().unsetColor().unsetHighlight().run()
                  }
                  className={classes.button}
                >
                  <FontAwesomeIcon size="sm" icon={solid("shower")} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
        </Paper>
      )}
    </BubbleMenu>
  );
};

export default FormatMenu;
