// import axios from 'axios';

class EngagementFunction {
  constructor() {
    if (this instanceof EngagementFunction) {
      throw Error('EngagementFunction class cannot be instantiated.');
    }
  }

  static onFetch = async (functions_type, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      functions_type
    }

    await window.$api.get(`/api/v4/engagement_functions.json`, {params})
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

  static onMigrate = async (space_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space_id
    }

    await window.$api.post(`/api/v4/engagement_functions/migrate.json`, params)
    .then(res => {
      sucessCallback(res.data)
    })
    .catch((error) => {
      try {
        errorCallback(error.response.data)

      } catch (e) {

      }
    });
  }

}

export default EngagementFunction;
